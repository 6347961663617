import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { queryClient } from '../../../config/react-query';
import { createCategories } from '../api';
import { useStrapiOnBoardingData } from './useStrapiOnBoardingData';

const useCreateCategoryMutation = () => {
  const { toastMessages } = useStrapiOnBoardingData();
  const { categorySelectionFailureTitle, categorySelectionFailureMessage } = toastMessages;
  const mutation = useMutation({
    mutationFn: async (args: Array<any>) => {
      const response = await createCategories(args);
      return response;
    },
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: [
          {
            scope: 'categories',
            item: 'get-categories',
          },
        ],
      });
    },
    onError: (error) => {
      console.error('Error while selecting categories %o', error);
      toast.custom(
        (t) => (
          <Toast
            variant="error"
            Title={categorySelectionFailureTitle}
            SubTitle={categorySelectionFailureMessage}
            toastInstance={t}
          />
        ),
        {
          duration: 3000,
        }
      );
    },
  });

  return mutation;
};

export default useCreateCategoryMutation;
