import { useParams } from 'react-router-dom';
import StatisticsCard from '../../shared/components/StatisticsCard';
import { calcTimeString } from '../../shared/helpers';
import useGetSingleOrganizationStats from '../hooks/useGetSingleOrganizationStats';
import { useStrapiOrganizationData } from '../hooks/useStrapiOrganizationData';

const SingleOrganisationStatistics = () => {
  let { id: tenantId } = useParams();
  tenantId = tenantId ?? '';
  const singleOrgStatsData = useGetSingleOrganizationStats({ tenantId });
  const { singleView } = useStrapiOrganizationData();
  const {
    courseCompletedCardLabel,
    averageRatingCardLabel,
    engagementCardLabel,
    churnRankLabel,
    title,
  } = singleView.Statistics;

  const avgValue = singleOrgStatsData.data?.data.avgRating;

  const data: any = [
    {
      title: engagementCardLabel,
      value: calcTimeString(singleOrgStatsData.data?.data.totalHoursEngaged * 60),
    },
    {
      title: courseCompletedCardLabel,
      value: singleOrgStatsData?.data?.data.totalCourseCompletions ?? 0,
    },
    {
      title: churnRankLabel,
      value: singleOrgStatsData.data?.data.churnRisk
        ? singleOrgStatsData.data?.data.churnRisk +
            '/' +
            singleOrgStatsData.data?.data.totalOrganizations ?? ''
        : 'No Rank ',
    },
    {
      title: averageRatingCardLabel,
      value: !avgValue
        ? '0'
        : Number.isInteger(avgValue)
        ? avgValue
        : Math.round(avgValue).toFixed(1),
    },
  ];
  return (
    <div className="flex w-full flex-col gap-3">
      <div className="px-1 text-sm text-white">{title}</div>
      <div className="flex w-full flex-col items-center space-y-3  md:flex-row md:space-y-0 md:space-x-3  lg:flex-row lg:space-y-0 lg:space-x-3">
        {data.map((value: any, key: number) => {
          return (
            <StatisticsCard
              key={key}
              data={value}
              className={''}
              isloading={singleOrgStatsData.isLoading}
            />
          );
        })}
      </div>
    </div>
  );
};
export default SingleOrganisationStatistics;
