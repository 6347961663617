import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { queryClient } from '../../../config/react-query';
import { createGoals } from '../api';
import { useStrapiOnBoardingData } from './useStrapiOnBoardingData';
import { Goal } from '../types';

const useCreateGoalsMutation = () => {
  const { toastMessages } = useStrapiOnBoardingData();
  const { goalSelectionFailureTitle, goalSelectionFailureMessage } = toastMessages;
  const mutation = useMutation({
    mutationFn: async (args: Goal[]) => {
      const response = await createGoals(args);
      return response;
    },

    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: [
          {
            scope: 'goals',
            item: 'get-goals',
          },
        ],
      });
    },
    onError: (error) => {
      console.error('Error while selecting goals %o', error);
      toast.custom(
        (t) => (
          <Toast
            variant="error"
            Title={goalSelectionFailureTitle}
            SubTitle={goalSelectionFailureMessage}
            toastInstance={t}
          />
        ),
        {
          duration: 3000,
        }
      );
    },
  });

  return mutation;
};

export default useCreateGoalsMutation;
