import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface NotificationSliceState {
  hasNewNotifications: boolean;
  channelLastMarkedAsReadTimestamp: string | null;
}

const initialState: NotificationSliceState = {
  hasNewNotifications: false,
  channelLastMarkedAsReadTimestamp: null,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    receivedNewNotification: (state) => {
      state.hasNewNotifications = true;
    },
    viewedNewNotifications: (state) => {
      state.hasNewNotifications = false;
    },
    setChannelLastMarkedAsReadTimestamp: (state, action: PayloadAction<string>) => {
      state.channelLastMarkedAsReadTimestamp = action.payload;
    },
  },
});

export const {
  receivedNewNotification,
  viewedNewNotifications,
  setChannelLastMarkedAsReadTimestamp,
} = notificationSlice.actions;

export default notificationSlice.reducer;
