// Requests

import { Option } from '../../../components/Select';

// Filters
export const ENT_ADMIN_REQUEST_STATUS_VALUES = ['open', 'rejected', 'completed'] as const;
export const ENT_ADMIN_VIEW_MEMBERS_STATUS_VALUES = ['ACTIVE', 'INVITED'] as const;
export const ENT_ADMIN_REQUESTS_SORT_BY_VALUES = [
  'CREATED_ON_ASC',
  'CREATED_ON_DESC',
  'TEAM_NAME_ASC',
  'TEAM_NAME_DESC',
  'NO_OF_MEMBERS_ASC',
  'NO_OF_MEMBERS_DESC',
] as const;

export const ENT_ADMIN_DEFAULT_REQUESTS_SORT_BY = 'most_recent';

// Query Params
export const ENT_ADMIN_REQUEST_STATUS_QUERY_PARAM = 'request_status';
export const ENT_ADMIN_VIEW_MEMBERS_STATUS_QUERY_PARAM = 'status';
export const ENT_ADMIN_SORT_BY_QUERY_PARAM = 'sort';
// Query Params

// Filters

// Pagination
export const ENT_ADMIN_PAGE_SIZE_INCREMENT = 10;
// TODO: Get from backend
export const ENT_ADMIN_MAX_PAGE_SIZE = 50;
export const ENT_ADMIN_MIN_PAGE_SIZE = 10;

export const ENT_ADMIN_DEFAULT_PAGE = 0;
export const ENT_ADMIN_VIEW_MEMBERS_DEFAULT_PAGE = 1;
export const ENT_ADMIN_VIEW_MEMBERS_DEFAULT_START = 0;

// Pagination

// Requests

export const ENT_ADMIN_STATUS_SELECT_OPTIONS: Option[] = [
  {
    display: 'Open',
    value: 'open',
  },
  {
    display: 'Completed',
    value: 'completed',
  },
  {
    display: 'Rejected',
    value: 'rejected',
  },
];
export const ENT_ADMIN_STATUS_VIEW_MEMBERS_SELECT_OPTIONS: Option[] = [
  {
    display: 'Active',
    value: 'ACTIVE',
  },
  {
    display: 'Invited',
    value: 'INVITED',
  },
];

export const ENT_ADMIN_SORT_SELECT_OPTIONS: Option[] = [
  {
    display: 'Most Recent',
    value: 'most_recent',
  },
  {
    display: 'Status',
    value: 'status',
  },
  {
    display: 'A-Z',
    value: 'alphabetical_atoz',
  },
  {
    display: 'Z-A',
    value: 'alphabetical_ztoa',
  },
  {
    display: 'Earliest',
    value: 'earliest',
  },
];
