import { DynamicFieldData } from '../../../types/dynamic-control-types';

export const CompanyDetails: DynamicFieldData[] = [
  {
    label: 'Company Name',
    slug: 'companyName',
    type: 'text',
    config: {
      required: {
        value: true,
        message: 'Company Name is required',
      },
      pattern: {
        value: /^[a-zA-Z0-9].*[a-zA-Z0-9]$/,
        message: 'Organization name should start and end with a alphanumeric character',
      },
    },
  },
  {
    label: 'Department',
    slug: 'department',
    type: 'text',
    config: {
      required: {
        value: true,
        message: 'Department Name is required',
      },
      pattern: {
        value: /^[a-zA-Z0-9].*[a-zA-Z0-9]$/,
        message: 'Organization department should start and end with a alphanumeric character',
      },
    },
  },
  {
    label: 'Active Learners',
    slug: 'activeLearners',
    type: 'number',
    config: {
      required: {
        value: true,
        message: 'Active Learners count is required',
      },
    },
    primaryInfo: 'Let us know the number of licences you need',
    secondaryInfo: '',
  },
  {
    label: 'Company Size',
    slug: 'companySize',
    type: 'range',
    min: 0,
    max: 500,
    config: {
      required: {
        value: true,
        message: 'Company Size is required',
      },
      minLength: {
        value: 1,
        message: 'Company Size should be a minimum of 1 people',
      },
      maxLength: {
        value: 50,
        message: 'Company Size should be a maximum upto 500 people',
      },
    },
    primaryInfo: 'Let us know the strength of your company',
    secondaryInfo: 'Upto 500 People',
  },
];
