import { CALENDAR_ITEM_TYPE } from '../../../constants';
import { CalendarItem as CalendarItemType } from '../../../types';
import { CourseDueCalendarItem } from './course';

interface Props {
  calendarItem: CalendarItemType;
}

const CalendarItem = ({ calendarItem }: Props) => {
  const calendarItemTypeType = calendarItem.calendarItemType.type;

  switch (calendarItemTypeType) {
    case CALENDAR_ITEM_TYPE.COURSE.DUE: {
      return <CourseDueCalendarItem calendarItem={calendarItem} />;
    }
    // This shouldn't happen
    default: {
      return <></>;
    }
  }
};

export default CalendarItem;
