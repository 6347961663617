import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { TableHeaderRow } from '../../../../../components/DataTables';
import { useStrapiRequestData } from '../../../hooks/useStrapiRequestData';
import { USHGAdminRequest } from '../../../types';
import {
  RaisedByCell,
  RequestStatusCell,
  RequestTitleCell,
} from '../../USHGAdminRequestsPage/components/RequestsTable/TableCells';
import RequestsTableDataRow from '../../USHGAdminRequestsPage/components/RequestsTableDataRow';

interface Props {
  className?: string;
  data: USHGAdminRequest[];
}

const getTableColumnDefinition = (
  requestTypeHeader: string,
  raisedByHeader: string,
  emailHeader: string,
  statusHeader: string
) => {
  const columnHelper = createColumnHelper<USHGAdminRequest>();

  const columns = [
    columnHelper.accessor('data.title', {
      header: requestTypeHeader,
      cell: (props) => <RequestTitleCell title={props.getValue()} />,
    }),
    columnHelper.accessor('createdByName', {
      header: raisedByHeader,
      cell: (props) => <RaisedByCell request={props.row.original} />,
    }),
    columnHelper.accessor('createdBy', {
      header: emailHeader,
      cell: (props) => <span className="whitespace-nowrap">{props.getValue()}</span>,
    }),
    columnHelper.accessor('status', {
      header: statusHeader,
      cell: (props) => <RequestStatusCell status={props.getValue()} />,
    }),
  ];
  return columns;
};

const DashboardRequestsTable = ({ className, data }: Props) => {
  const { HQTable } = useStrapiRequestData();
  const { statusColumnHeader, requestColumnHeader, raisedByColumnHeader, emailColumnHeader } =
    HQTable;
  const columns = getTableColumnDefinition(
    requestColumnHeader,
    raisedByColumnHeader,
    emailColumnHeader,
    statusColumnHeader
  );
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiSort: true,
  });

  return (
    <table className={clsx('w-full', className)}>
      <thead className="w-full">
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeaderRow header={header} key={header.id} />
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <RequestsTableDataRow row={row} key={row.id} />
        ))}
      </tbody>
    </table>
  );
};

export default DashboardRequestsTable;
