type selectOptionType = { display: string; value: string };

export const formatsData: selectOptionType[] = [
  {
    display: 'Format',
    value: 'none',
  },
  {
    display: 'Document',
    value: 'document',
  },
  {
    display: 'Audio',
    value: 'audio',
  },
  {
    display: 'Video',
    value: 'video',
  },
];

export const sortBy: selectOptionType[] = [
  {
    display: 'Sort',
    value: 'none',
  },
  {
    display: 'A to Z',
    value: 'asc',
  },
  {
    display: 'Z to A',
    value: 'desc',
  },
  // {
  //   display: 'Popular',
  //   value: 'POPULAR',
  // },
];

export const PAGE_LENGTH = 50;
