import { useQuery } from '@tanstack/react-query';
import { ORG_STALE_TIME } from '../../shared/constants';
import { getOrganisationStatistics } from '../api/organisation';
interface props {
  tenantId: string;
}
const useGetSingleOrganizationStats = ({ tenantId }: props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery(
      [
        {
          scope: 'organisation',
          item: 'statistics',
          tenantId,
        },
      ],
      async () => {
        return await getOrganisationStatistics(tenantId);
      },
      {
        keepPreviousData: true,
        staleTime: ORG_STALE_TIME,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch };
};

export default useGetSingleOrganizationStats;
