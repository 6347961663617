import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { queryClient } from '../../../config/react-query';
import { useReduxAuthState } from '../../../hooks';
import { UpdateUserNotificationPreferencesArgs, updateUserNotificationPreferences } from '../api';
import { useStrapiSettingsData } from './useStrapiSettingsData';

/**
 * Wrapper around useMutation to update the user notification preferences
 */
const useUpdateNotificationPreferencesMutation = () => {
  const auth = useReduxAuthState();
  const {
    toastMessage: { updateNotificationPreferenceSuccess, updateNotificationPreferenceFailed },
  } = useStrapiSettingsData();
  const mutation = useMutation({
    mutationFn: async (args: UpdateUserNotificationPreferencesArgs) => {
      const response = await updateUserNotificationPreferences(args);
      return response;
    },
    onSuccess: (response) => {
      // Set the user notifications query data from response
      queryClient.setQueryData(
        [
          {
            scope: 'settings',
            item: 'notification-preferences',
            // The user would be authenticated at this point
            // so this should not be null/undefined
            username: auth.user?.username,
          },
        ],
        {
          notificationPreferences: response.notificationPreferences,
        }
      );

      toast.custom((t) => (
        <Toast variant="success" Title={updateNotificationPreferenceSuccess} toastInstance={t} />
      ));
    },
    onError: (error) => {
      console.error(error);
      toast.custom((t) => (
        <Toast variant="error" Title={updateNotificationPreferenceFailed} toastInstance={t} />
      ));
    },
  });

  return mutation;
};

export default useUpdateNotificationPreferencesMutation;
