import { gql } from '@apollo/client';

export const SpeakerFragment = gql`
  fragment SpeakerFragment on SpeakerEntityResponse {
    data {
      attributes {
        HQ_ADMIN {
          ... on ComponentSpeakersToastMessage {
            createSpeakerSuccess
            createSpeakerFailed
            updateSpeakerSuccess
            updateSpeakerFailed
            deleteSpeakerSuccess
            deleteSpeakerFailed
          }
          ... on ComponentSpeakersSpeakersTable {
            TableHeaders {
              speaker
              description
              position
              courses
            }
            tableProps {
              coursesLabel
              editAction
              deleteAction
              i18nAction
            }
          }
          ... on ComponentSpeakersTopNav {
            title
            addNewSpeakerButton
          }
          ... on ComponentSpeakersCreateAndEditModal {
            editTitle
            createTitle
            firstNameLabel
            firstNamePlaceholder
            lastNameLabel
            lastNamePlaceholder
            descriptionLabel
            descriptionPlaceholder
            positionLabel
            positionPlaceholder
            imageLabel
            imagePlaceholder
            editSpeakerButton
            addNewSpeakerButton
            cancelButton
            firstNameMinError
            firstNameMaxError
            firstNameMinValue
            firstNameMaxValue
            firstNameRequired
            lastNameRequired
            lastNameMinValue
            lastNameMaxValue
            lastNameMinError
            lastNameMaxError
            descriptionRequired
            descriptionMinValue
            descriptionMaxValue
            descriptionMinError
            descriptionMaxError
            positionRequired
            positionMinValue
            positionMaxValue
            positionMinError
            positionMaxError
            speakerImageRequired
            manageLanguagesTitle
          }
          ... on ComponentSpeakersDeleteSpeaker {
            title
            deleteMessage
            cancelButton
            confirmButton
          }
          ... on ComponentSpeakersSpeakerCard {
            coursesLabel
            viewAllCoursesButton
          }
        }
      }
    }
  }
`;
