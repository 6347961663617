import { useMemo } from 'react';
import { isDefined } from '../../../../../../../../../utils';
import { AvatarAndNameCell } from '../../../../../../../../requests/components/common';
import { generateCDNMediaAssetURLWithKey } from '../../../../../../../../shared/helpers';
import { USHGAdminCourseModuleSpeakers } from '../../../../../../../constants/course-module-table';

interface Props {
  speakers: string[];
}

const SpeakerNameCell = ({ speakers }: Props) => {
  const parsedSpeakers = speakers
    .filter(isDefined)
    .map((speakerJSON) => JSON.parse(speakerJSON) as USHGAdminCourseModuleSpeakers);

  const hasAtleastOneSpeaker = parsedSpeakers.length >= 1;
  const hasMoreThanOneSpeaker = parsedSpeakers.length > 1;

  const speaker = hasAtleastOneSpeaker ? parsedSpeakers[0] : undefined;

  const profilePictureURL = useMemo(() => {
    const pictureUrl = speaker?.picture;
    if (!pictureUrl) {
      return undefined;
    }

    const cdnProfilePictureUrl = generateCDNMediaAssetURLWithKey({ key: pictureUrl });
    return cdnProfilePictureUrl;
  }, [speaker?.picture]);

  if (!speaker) {
    return <>-</>;
  }

  return (
    <div
      title={parsedSpeakers.map((speaker) => speaker.name).join(', ')}
      className="flex items-center gap-1"
    >
      <AvatarAndNameCell name={speaker.name} profilePictureURL={profilePictureURL} />
      {hasMoreThanOneSpeaker && (
        <>
          <span>+{speakers.length - 1}</span>
        </>
      )}
    </div>
  );
};

export default SpeakerNameCell;
