import { useStrapiSettingsData } from '../../../hooks/useStrapiSettingsData';
import ActionDialogContent from '../../common/components/ActionDialogContent';

interface Props {
  onConfirm: () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
}

// When we have MFA enabled
// and the user is switching number
// then we have a display a banner
const ConfirmPhoneChange = (props: Props) => {
  const { onConfirm, onCancel } = props;

  //   strapi content

  const { profile: strapiProfile } = useStrapiSettingsData();
  const { PhoneChangeConfirmDialog: phoneChangeConfirmDialogCMS } = strapiProfile as {
    PhoneChangeConfirmDialog: any;
  };

  //   strapi content

  //   handlers
  const onCancelHandler = () => {
    onCancel && onCancel();
  };

  const onConfirmHandler = () => {
    onConfirm && onConfirm();
  };
  //   handlers

  return (
    <ActionDialogContent
      heading={phoneChangeConfirmDialogCMS.title}
      Description={phoneChangeConfirmDialogCMS.description}
      secondaryAction={{
        label: phoneChangeConfirmDialogCMS.cancelBtnLabel,
        handler: onCancelHandler,
      }}
      primaryAction={{
        label: phoneChangeConfirmDialogCMS.confirmBtnLabel,
        handler: onConfirmHandler,
      }}
    />
  );
};

export default ConfirmPhoneChange;
