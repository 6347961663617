import ActionResponse from '../../../../components/ActionResponse';
import { useNavigate } from 'react-router-dom';
import { useResendAccountConfirmationCode } from '../../hooks/use-resend-account-confirmation-code';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';

export type UserConfirmAccountFailureType =
  | 'CODE_MISMATCH'
  | 'EXPIRED_CODE'
  | 'SEARCH_PARAMS_MISSING'
  | 'UNKNOWN_ERROR'
  | 'USER_ALREADY_CONFIRMED';

interface Props {
  type: UserConfirmAccountFailureType;
  username: string;
}

const UserConfirmAccountMessageSubTitle = ({ type, username }: Props) => {
  const data: any = useStrapiDataHelper();
  const strapiErrorMessage = data.errorsMap;
  const attributes = data?.login.data.attributes;
  const { UserConfirmAccountFailure } = attributes;
  const { subTitle, onClickLabel } = UserConfirmAccountFailure;
  const subTitleSplit = subTitle.split('{{onClick}}');

  const navigate = useNavigate();
  const { resendConfirmationCodeHandler } = useResendAccountConfirmationCode();
  const navigateToLogin = () => {
    navigate('/auth/login');
  };

  switch (type) {
    case 'CODE_MISMATCH':
    case 'SEARCH_PARAMS_MISSING':
    case 'UNKNOWN_ERROR': {
      return <>{strapiErrorMessage[type]}</>;
    }

    case 'EXPIRED_CODE': {
      return (
        <>
          {strapiErrorMessage['EXPIRED_CODE']}{' '}
          <button onClick={() => resendConfirmationCodeHandler(username)} className="underline">
            here
          </button>
          .
        </>
      );
    }

    case 'USER_ALREADY_CONFIRMED': {
      return (
        <>
          <b>{username}</b> {subTitleSplit[0]}{' '}
          <button onClick={navigateToLogin} className="underline">
            {onClickLabel}
          </button>{' '}
          {subTitleSplit[1]}
        </>
      );
    }
  }
};

const getUserConfirmAccountMessageTitle = (type: UserConfirmAccountFailureType) => {
  const data: any = useStrapiDataHelper();
  const strapiErrorMessage = data.errorsMap;
  switch (type) {
    case 'CODE_MISMATCH':
    case 'EXPIRED_CODE':
    case 'UNKNOWN_ERROR': {
      return strapiErrorMessage['ACCOUNT_CONFIRMATION_FAILED'];
    }
    case 'USER_ALREADY_CONFIRMED': {
      return strapiErrorMessage['USER_ALREADY_CONFIRMED'];
    }
    case 'SEARCH_PARAMS_MISSING': {
      return strapiErrorMessage['INVALID_REQUEST'];
    }
  }
};

const UserConfirmAccountFailure = ({ type, username }: Props) => {
  const title = getUserConfirmAccountMessageTitle(type);

  return (
    <div className="flex w-full flex-col items-center pt-[6.5vw]">
      <ActionResponse
        title={title}
        subtitle={
          <UserConfirmAccountMessageSubTitle
            username={username}
            type={type}
          ></UserConfirmAccountMessageSubTitle>
        }
        variant="failure"
      />
    </div>
  );
};

export default UserConfirmAccountFailure;
