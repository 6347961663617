import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';

interface Props {
  rating: number;
}

const Ratings = ({ rating }: Props) => {
  const {
    courseInfo: { noRatingsLabel },
  } = useStrapiCoursesData();
  return (
    <span className="whitespace-nowrap">
      {rating != null ? Number(rating).toFixed(2) : `${noRatingsLabel}`}
    </span>
  );
};

export default Ratings;
