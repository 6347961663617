import { useQuery } from '@tanstack/react-query';
import { getAssessmentQuestions } from '../api/courses';

interface Props {
  assessmentId: number | null;
}

const useAssessmentQuestionsQuery = ({ assessmentId }: Props) => {
  const STALE_TIME = 60 * 60 * 1000; // 1hr
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery(
      [
        {
          queryKey: assessmentId,
          scope: 'singleCourse',
          item: 'assessmentQuestion',
        },
      ],
      async () => {
        if (assessmentId) {
          return getAssessmentQuestions(assessmentId.toString());
        } else {
          return null;
        }
      },
      {
        staleTime: STALE_TIME,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch };
};

export default useAssessmentQuestionsQuery;
