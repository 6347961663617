import { X } from 'phosphor-react';
import { useMemo } from 'react';
import { ErrorDisplay } from '../../../../components/DataTables';
import Dialog from '../../../../components/Dialog';
import { BasicSpinner } from '../../../../components/Spinners';
import { ERROR_TYPES } from '../../../../constants/error-types.constants';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import useGetBadgeDetailsByIdQuery from '../../hooks/useGetBadgeDetailsByIdQuery';
import useStrapiBadgesData from '../../hooks/useStrapiBadgeData';
import { BadgeBase, PartialBadgeDetailsFilter } from '../../types/badges';
import BadgeDetailsContent from './BadgeDetailsContent';

interface Props {
  badgeData: BadgeBase;
}

// TODO: Remove this component once we are prefetching the data required to
// display the criterion in the initial call itself
const BadgeDetails = ({ badgeData }: Props) => {
  const filters: PartialBadgeDetailsFilter = useMemo(() => {
    return {
      id: badgeData.badgeId,
    };
  }, [badgeData.badgeId]);

  const badge = useGetBadgeDetailsByIdQuery({ filters });
  const displayLoader = badge.isLoading && !badge.data;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const { singleBadge } = useStrapiBadgesData();

  return (
    <div className="mx-1 flex flex-col rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <div className="flex w-full items-center justify-between">
        <Dialog.Title className="text-lg font-semibold normal-case leading-6 tracking-wide text-white">
          {singleBadge.title}
        </Dialog.Title>
        <Dialog.Close>
          <X
            size={24}
            fill="white"
            color="currentColor"
            className="text-zinc-300 transition-colors hover:text-zinc-100"
          />
        </Dialog.Close>
      </div>
      {/* Loading state */}
      {displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center py-4">
          <BasicSpinner className="text-white" />
        </div>
      )}
      {/* We have data */}
      {badge.data && <BadgeDetailsContent badge={badge.data} renderDefaultLayout={false} />}
      {/* Error state */}
      {badge.isError && !badge.data && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <ErrorDisplay
            refetch={badge.refetch}
            isRefetching={badge.isRefetching}
            allowsRefetch={true}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            message={strapiErrorMessage['SYSTEM_ERROR']}
          />
        </div>
      )}
    </div>
  );
};
export default BadgeDetails;
