import { useCallback, useState } from 'react';
import AlertDialog from '../../../../../../components/AlertDialog';
import {
  EnterpriseBulkInviteUser,
  EnterpriseBulkInviteUserError,
} from '../../../../../shared/components';
import { useInitBulkInviteUserMutation } from '../../../../hooks';
import useStrapiMembersData from '../../../../hooks/useStrapiMembersData';

interface BulkInviteUserDialogProps {
  closeDialog: () => void;
  tenantId?: string;
}

const BulkInviteUserDialog = (props: BulkInviteUserDialogProps) => {
  const { closeDialog, tenantId } = props;

  const [uploadId, setUploadId] = useState<null | string>(null);
  const initBulkInviteUserMutation = useInitBulkInviteUserMutation({ isOnboarding: false });
  const { bulkUploadModal } = useStrapiMembersData();

  // Handle Bulk Upload Members Submit
  const onSubmit = useCallback(async () => {
    if (!uploadId) {
      return;
    }

    try {
      await initBulkInviteUserMutation.mutateAsync({
        uploadId,
        id: tenantId,
      });

      // Close Dialog on success
      closeDialog();
    } catch (error) {
      console.error('Error uploading file to the bulk upload csv', error);
    }
  }, [uploadId, closeDialog]);

  return (
    <AlertDialog
      Title={bulkUploadModal.title}
      contentWrapperClassName="gap-6"
      Content={
        <div className="flex max-h-[70vh] flex-col gap-2 overflow-y-auto">
          <span className="text-sm font-medium text-zinc-300">
            {bulkUploadModal.bulkUploadTitle}
          </span>
          <EnterpriseBulkInviteUser setUploadId={setUploadId} tenantId={tenantId} />
          {initBulkInviteUserMutation.isError && (
            <EnterpriseBulkInviteUserError error={initBulkInviteUserMutation.error as Error} />
          )}
        </div>
      }
      actionItem={{
        label: bulkUploadModal.uploadButton,
        action: onSubmit,
      }}
      cancelItem={{
        label: bulkUploadModal.cancelButton,
      }}
      isActionDiabled={
        !uploadId || initBulkInviteUserMutation.isLoading || initBulkInviteUserMutation.isSuccess
      }
      isLoading={initBulkInviteUserMutation.isLoading}
    ></AlertDialog>
  );
};

export default BulkInviteUserDialog;
