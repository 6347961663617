import { CourseType } from '../../features/dashboard/types/CourseType';
import { CourseCard } from '../Cards';

type CoursesCardDisplayProps = {
  coursesList: CourseType[];
};
const CoursesCardDisplay = ({ coursesList }: CoursesCardDisplayProps) => {
  return (
    <div className="grid grid-cols-[repeat(auto-fill,_minmax(15rem,_1fr))] justify-between gap-5">
      {coursesList.map((c) => (
        <CourseCard key={c.id} course={c} />
      ))}
    </div>
  );
};

export default CoursesCardDisplay;
