import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { PartialMembers } from '../../../types/interface';
import AdminViewTeamMembersTableDataRow from './AdminViewTeamMembersTableDataRow';
import AdminViewTeamMembersTableHeaderRow from './AdminViewTeamMembersTableHeaderRow';
import { BsLayoutThreeColumns } from 'react-icons/bs';
import GenericNameCell from './TableCell/GenericNameCell';
import CommonTextCell from './TableCell/CommonTextCell';
import TimeStampCell from './TableCell/TimeStampCell';
import StatusCell from './TableCell/StatusCell';
import { useStrapiTeamData } from '../../../hooks/useStrapiTeamData';
import { USER_STATUS } from '../../../../members/constants';
import { RootState } from '../../../../../store';
import { useAppSelector } from '../../../../../hooks';
import EnterpriseDropDownCell from '../../../../members/components/common/AllMembersTableMain/components/cells/EnterpriseDropDownCell';
import useUserTypeToDisplay from '../../../../../hooks/useUserTypeToDisplay';

interface Props {
  className?: string;
  data: PartialMembers[];
}

const columnHelper = createColumnHelper<PartialMembers>();
const teamStateSelector = (state: RootState) => state.team;
const AdminViewTeamMembersTable = ({ className, data }: Props) => {
  const { singleTeamTable } = useStrapiTeamData();
  const { USER_ROLE_DISPLAY_NAME_MAP } = useUserTypeToDisplay();
  const { selectedTeam } = useAppSelector(teamStateSelector);
  const {
    singleTeamTableStatusHeader,
    singleTeamTableJobTitleHeader,
    singleTeamTableLastLoginHeader,
    singleTeamTableNameHeader,
    singleTeamTableEmailHeader,
    singleTeamTableRoleHeader,
    activateMemberOption,
    resendInviteOption,
    deactivateMemberOption,
    disableMFAForUserOption,
    changeTeamButton,
    changeRoleButton,
  } = singleTeamTable;
  const columns = [
    columnHelper.accessor('fullName', {
      header: singleTeamTableNameHeader,
      cell: ({ getValue, row }) => <GenericNameCell name={getValue() as string} row={row} />,
    }),
    columnHelper.accessor('username', {
      header: singleTeamTableEmailHeader,
      cell: ({ getValue }) => <CommonTextCell text={getValue() as string} isLowercase={true} />,
    }),
    columnHelper.accessor('lastLogin', {
      header: singleTeamTableLastLoginHeader,
      cell: ({ getValue }) => <TimeStampCell timestamp={getValue() as string} />,
    }),
    columnHelper.accessor('jobTitle', {
      header: singleTeamTableJobTitleHeader,
      cell: ({ getValue }) => <CommonTextCell text={getValue() as string} />,
    }),
    columnHelper.accessor('userType', {
      header: singleTeamTableRoleHeader,
      cell: ({ getValue }) => (
        <CommonTextCell text={USER_ROLE_DISPLAY_NAME_MAP[getValue() as string]} />
      ),
    }),
    columnHelper.accessor('status', {
      header: singleTeamTableStatusHeader,
      cell: ({ getValue }) => <StatusCell status={getValue() as keyof typeof USER_STATUS} />,
    }),
    columnHelper.accessor('username', {
      cell: ({ getValue, row }) => {
        const { status, userType, fullName, mfaPreference } = row.original;
        return (
          <EnterpriseDropDownCell
            username={getValue() as string}
            teamId={selectedTeam.teamId as number}
            status={status as keyof typeof USER_STATUS}
            activateLabel={activateMemberOption}
            deactivateLabel={deactivateMemberOption}
            resendInviteLabel={resendInviteOption}
            disableMFALabel={disableMFAForUserOption}
            userRole={userType as string}
            fullName={fullName as string}
            teamName={selectedTeam.teamName ?? ''}
            changeTeamLabel={changeTeamButton}
            changeRoleLabel={changeRoleButton}
            mfaPreference={mfaPreference}
          />
        );
      },
      id: 'edit',
      header: () => {
        return (
          <BsLayoutThreeColumns
            className="h-3 w-3 text-zinc-100"
            strokeWidth={1.5}
            color="currentColor"
            size={12}
          />
        );
      },
      enableSorting: false,
      enableMultiSort: false,
    }),
  ];
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiSort: true,
    data,
  });

  return (
    <table className={clsx('w-full', className)}>
      <thead className="w-full">
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <AdminViewTeamMembersTableHeaderRow header={header} key={header.id} />
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <AdminViewTeamMembersTableDataRow
            className="cursor-pointer hover:bg-page-bg-dark/20"
            row={row}
            key={row.id}
          />
        ))}
      </tbody>
    </table>
  );
};

export default AdminViewTeamMembersTable;
