import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

export const useStrapiReportsData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const strapiData = data.report.data.attributes;
  const tables = strapiData.ReportsTables;
  const charts = strapiData.ReportsCharts;
  const statistics = strapiData.Statistics;
  const topNav = strapiData.TopNav;
  const genericMessages = strapiData.GenericMessages;
  const ReportTooltipContent = data.reportTooltipContentMap;
  const {
    completionRate,
    AssessmentScore,
    HoursSpentFrequency,
    CompletionRateOverTime,
    CourseAssignedOrCompleted,
    EngagementByCategory,
    LoginFrequency,
    OrganizationHoursLogged,
    OrganizationCoursePerformance,
    TeamHoursLogged,
    TeamCoursePerformance,
    MyViewingActivity,
    MyCoursePerformance,
    AverageWeeklyHours,
  } = charts;
  const {
    IndividualLeaderboard,
    TeamLeaderboard,
    TeamsLeaderboard,
    MostPopularCourses,
    MostPopularInstructor,
    TopEarnedBadges,
    TopChurnRisk,
    OverAllChurnRate,
    CustomerLeaderboard,
  } = tables;
  return {
    genericMessages,
    IndividualLeaderboard,
    TeamLeaderboard,
    TeamsLeaderboard,
    MostPopularCourses,
    MostPopularInstructor,
    TopEarnedBadges,
    completionRate,
    AssessmentScore,
    HoursSpentFrequency,
    CompletionRateOverTime,
    CourseAssignedOrCompleted,
    EngagementByCategory,
    LoginFrequency,
    OrganizationHoursLogged,
    OrganizationCoursePerformance,
    TeamHoursLogged,
    TeamCoursePerformance,
    MyViewingActivity,
    MyCoursePerformance,
    AverageWeeklyHours,
    statistics,
    topNav,
    TopChurnRisk,
    OverAllChurnRate,
    CustomerLeaderboard,
    ReportTooltipContent,
  };
};
