import { ChartData } from 'chart.js';
import clsx from 'clsx';
import { useContext, useMemo, useState } from 'react';
import {
  defaultFontOptions,
  defaultGridOptions,
  defaultLabelOptions,
  defaultTickOptions,
  defaultTitleOptions,
} from '../../config';
import { REPORTS_INTERVAL, REPORT_TYPES } from '../../constants';
import durationContext from '../../context/durationContext';
import { useScoresOnAssessmentsQuery } from '../../hooks';
import { DashboardReportsFilter, LineChartOptions } from '../../types';
import { LineChart } from './baseCharts';
import ChartHeading from './ChartHeading';
import { ReportTooltipDataType, ScoresOnAssessments } from '../../types/reports/reports';
import NoDataFallback from '../NoDataFallback';
import { ErrorDisplay } from '../../../../components/DataTables';
import { InView } from 'react-intersection-observer';
import { useStrapiReportsData } from '../../hooks/useStrapiReportData';
import { useParams } from 'react-router-dom';
import { chartJsLabel } from '../../helpers';
import ScaleLoader from '../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../components/ReportToolTip';
import { useLanguage } from '../../context/languageContext';
interface HoursLoggedInVizProps {
  className?: string;
  heading?: string;
  lazyLoad?: boolean;
  teamId?: string;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}

const AssessmentsScoreViz = ({
  className,
  heading,
  lazyLoad = true,
  teamId,
  reportTooltipContent,
  userPersona,
}: HoursLoggedInVizProps) => {
  const { duration } = useContext(durationContext);
  const { id } = useParams();
  const { language } = useLanguage();

  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);
  // Build the filter
  let filters: Partial<DashboardReportsFilter> = useMemo(() => {
    return {
      fromDate: duration.fromDate,
      interval: duration.interval as keyof typeof REPORTS_INTERVAL,
      teamId: teamId && teamId !== '1' ? teamId : undefined,
    };
  }, [duration, teamId]);
  const { key1, key2, emptyChart, yAxisLabel, errorChart } = useStrapiReportsData().AssessmentScore;

  // include username in filter if exists in route path
  if (id) {
    filters = { ...filters, username: id };
  }

  const scoresOnAssessments = useScoresOnAssessmentsQuery({ filters, isEnabled: isInView });

  const hasScoresOnAssessments =
    scoresOnAssessments.data &&
    scoresOnAssessments.data.results &&
    scoresOnAssessments.data.results.length > 0;

  const hasNoScoresOnAssessments =
    scoresOnAssessments.data &&
    scoresOnAssessments.data.results &&
    scoresOnAssessments.data.results.length === 0;

  const isLoadingScoresOnAssessments = scoresOnAssessments.isLoading;

  const data = useMemo(() => {
    let labels: string[] = [];
    // Avg. Passing Score
    let averagePassingScore: number[] = [];

    // Avg. Earned Score
    let averageEarnedScore: number[] = [];

    if (hasScoresOnAssessments) {
      // Chartjs labels
      labels = scoresOnAssessments.data?.results.map((result: ScoresOnAssessments) =>
        chartJsLabel(duration.interval, result.pool, language)
      );
      averagePassingScore = scoresOnAssessments.data?.results.map(
        (result: ScoresOnAssessments) => +result.averagePassingScore
      );

      averageEarnedScore = scoresOnAssessments.data?.results.map(
        (result: ScoresOnAssessments) => +result.averageEarnedScore
      );
    }
    const data: ChartData<'line', number[], string> = {
      labels,
      datasets: [
        {
          data: averagePassingScore || [],
          label: key2,
          borderColor: '#F7634F',
          backgroundColor: '#F7634F',
          tension: 0,
        },
        {
          data: averageEarnedScore || [],
          label: key1,
          borderColor: '#37990E',
          backgroundColor: '#37990E',
          tension: 0,
        },
      ],
    };
    return data;
  }, [duration, hasScoresOnAssessments, scoresOnAssessments.data]);

  // Chartjs options

  const chartOptions: LineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0,
      },
    },
    scales: {
      x: {
        title: {
          ...defaultTitleOptions,
          text: duration.xAxis,
        },
        grid: { ...defaultGridOptions, borderDash: [] },

        ticks: {
          ...defaultTickOptions,
          autoSkip: true,
          padding: 8,
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        title: {
          ...defaultTitleOptions,
          text: yAxisLabel,
        },
        ticks: {
          ...defaultTickOptions,
          precision: 0,
          padding: 12,
        },
        grid: { ...defaultGridOptions, borderDash: [] },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          ...defaultLabelOptions,
          font: {
            ...defaultFontOptions,
          },
        },
      },
    },
  };

  return (
    <InView
      as="div"
      className={clsx(
        'flex h-full min-h-[420px] flex-col gap-5 rounded-lg border border-zinc-700 bg-card-bg pt-4 pb-7',
        className
      )}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div className="flex items-center gap-2 px-4">
        <ChartHeading heading={heading as string} />
        {reportTooltipContent?.[REPORT_TYPES.ASSESSMENT_SCORES]?.[userPersona] && (
          <ReportTooltip
            content={reportTooltipContent[REPORT_TYPES.ASSESSMENT_SCORES][userPersona]}
          />
        )}
      </div>
      <hr className="h-px border-0 bg-gray-700" />
      {isLoadingScoresOnAssessments && !hasScoresOnAssessments && (
        <div className="flex grow items-center justify-center">
          <ScaleLoader />
        </div>
      )}
      {/* Error State */}
      <>
        {scoresOnAssessments.error && !scoresOnAssessments.data && (
          <div className="flex grow items-center justify-center">
            <ErrorDisplay
              message={errorChart}
              refetch={scoresOnAssessments.refetch}
              allowsRefetch={true}
              isRefetching={scoresOnAssessments.isRefetching}
            />
          </div>
        )}
      </>
      {hasScoresOnAssessments && (
        <LineChart
          className="m-auto max-h-80 min-h-[20rem] w-full px-2 pt-4"
          options={chartOptions}
          data={data}
        />
      )}
      {hasNoScoresOnAssessments && !isLoadingScoresOnAssessments && (
        <NoDataFallback message={emptyChart} />
      )}
    </InView>
  );
};

export default AssessmentsScoreViz;
