import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { useStrapiHQAdminCoursesData } from '../../course-management/hooks/useStrapiHQAdminCourseData';
import { submitAnswers } from '../api/courses';
import { TOAST_DURATION } from '../components/SingleCourseView/constants/constants';
import { SubmitAssessmentArgsType } from '../components/SingleCourseView/types/SingleCourseTypes';

const useSubmitAnswersMutation = () => {
  const {
    toastMessages: { submitAnswersForQuizFailedTitle, submitAnswersForQuizFailedMessage },
  } = useStrapiHQAdminCoursesData();
  const { data, error, isLoading, isError, mutate } = useMutation({
    mutationFn: async (payload: SubmitAssessmentArgsType) => {
      const response = await submitAnswers(payload);
      return response;
    },
    onError: (error) => {
      console.log(error);
      toast.custom(
        (t) => {
          return (
            <Toast
              variant={'error'}
              Title={submitAnswersForQuizFailedTitle}
              SubTitle={submitAnswersForQuizFailedMessage}
              toastInstance={t}
            />
          );
        },
        {
          duration: TOAST_DURATION,
        }
      );
    },
  });
  return { data, error, isLoading, isError, mutate };
};

export default useSubmitAnswersMutation;
