import { twMerge } from 'tailwind-merge';
import { generateCDNMediaAssetURLWithKey } from '../../shared/helpers';
import { BadgeItemType } from '../types/badges';
import clsx from 'clsx';

type Props = BadgeItemType & {
  className?: string;
  classNameImage?: string;
};

const BadgeItem = ({
  profile,
  name,
  roundedImage,
  description,
  className,
  classNameImage,
}: Props) => {
  return (
    <div className={twMerge(clsx('my-5 flex w-full flex-row items-center', className))}>
      <div className="w-max rounded-lg">
        <img
          src={generateCDNMediaAssetURLWithKey({ key: profile })}
          className={`max-h-[80px] min-h-[85px] min-w-[85px] max-w-[85px] object-cover p-2 ${classNameImage} ${
            roundedImage ? 'rounded-full' : 'rounded-lg'
          }`}
          alt={name}
        />
      </div>
      <div className="mx-4 flex flex-col justify-center text-start">
        <h3 className="m-1 text-sm font-medium text-zinc-50">{name}</h3>
        <span className="mx-1 text-xs font-normal text-zinc-500 line-clamp-2">{description}</span>
      </div>
    </div>
  );
};

export default BadgeItem;
