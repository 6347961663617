import { useQuery } from '@tanstack/react-query';
import { getRecommendedCourses } from '../api';

type useRecommendedCoursesProps = {
  type: string;
  offset: number;
  limit: number;
  staleTime?: number;
  isEnabled?: boolean;
};
const useRecommendedCoursesQuery = ({
  type,
  offset,
  limit,
  staleTime,
  isEnabled = true,
}: useRecommendedCoursesProps) => {
  const query = useQuery(
    ['recommended-courses', type, offset, limit],
    async () => {
      return await getRecommendedCourses(type, offset, limit);
    },
    {
      enabled: isEnabled,
      staleTime,
    }
  );
  return query;
};

export default useRecommendedCoursesQuery;
