import { useStrapiHQAdminCoursesData } from '../../../../hooks/useStrapiHQAdminCourseData';
import CourseStatisticsCard from './CourseStatisticsCard';
import { formatNumber } from '../../../../../../utils';

interface Props {
  data: any;
}
const CourseStatisticsCardMain = ({ data }: Props) => {
  const { singleCourseTableView } = useStrapiHQAdminCoursesData();
  const {
    startedByCardLabel,
    assignedToCardLabel,
    completedByCardLabel,
    assessmentCardLabel,
    ratingCardLabel,
  } = singleCourseTableView.Statistics;
  const statisticData = [
    {
      heading: ratingCardLabel,
      value: data.avgCourseRatings ? parseFloat(data.avgCourseRatings).toFixed(1) : '-',
      percentage: data.diffAvgCourseRatings,
    },
    {
      heading: assignedToCardLabel,
      value: data.totalCourseAssignments ? parseInt(data.totalCourseAssignments) : 0,
      percentage: data.diffTotalCourseAssignments,
    },
    {
      heading: startedByCardLabel,
      value: data.totalCourseStarts ? parseInt(data.totalCourseStarts) : 0,
      percentage: data.diffTotalCourseStarts,
    },
    {
      heading: completedByCardLabel,
      value: data.totalCourseCompletions ? parseInt(data.totalCourseCompletions) : 0,
      percentage: data.diffTotalCourseCompletions,
    },
    {
      heading: assessmentCardLabel,
      value: data.avgAssessmentScore ? formatNumber(data.avgAssessmentScore) + '%' : 'N/A',
      percentage: data.diffAvgAssessmentScore,
    },
  ];

  return (
    <div className="grid grid-cols-[repeat(auto-fit,minmax(var(--card-min-width),1fr))] gap-3 [--card-min-width:120px] sm:[--card-min-width:200px]">
      {statisticData.map((value: any, key: any) => {
        return <CourseStatisticsCard key={key} data={value} />;
      })}
    </div>
  );
};

export default CourseStatisticsCardMain;
