import { useMutation } from '@tanstack/react-query';
import { getCurrentAuthenticatedUser } from '../../auth/utils';
import { VerifyCurrentUserAttributeSubmitParams, verifyCurrentUserAttributeSubmit } from '../utils';

const useVerifyCurrentUserAttributeSubmitMutation = () => {
  const mutation = useMutation({
    mutationFn: async (params: VerifyCurrentUserAttributeSubmitParams) => {
      const response = await verifyCurrentUserAttributeSubmit(params);
      return response;
    },
    onSuccess: async () => {
      // After attribute is verified update the tokens
      try {
        // Will fetch new tokens with updated user attributes
        await getCurrentAuthenticatedUser({ bypassCache: true });
      } catch (error) {
        // The profile has been updated but refreshing the cognito session failed
        console.error('Error updating the cognito user');
      }
    },
  });

  return mutation;
};

export default useVerifyCurrentUserAttributeSubmitMutation;
