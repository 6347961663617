// Requests

export const REQUEST_STATUS = {
  REQUESTED: 'REQUESTED',
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
} as const;

// ----- Enterprise Admin

// Filters
export const ENT_ADMIN_REQUEST_STATUS_VALUES = ['ACTIVE', 'COMPLETED', 'CANCELLED'] as const;
export const ENT_ADMIN_REQUESTS_SORT_BY_VALUES = ['MOST_RECENT', 'EARLIEST'] as const;

export const ENT_ADMIN_DEFAULT_REQUESTS_SORT_BY = 'MOST_RECENT';
// Filters

// ----- Enterprise Admin

// ----- USHG Admin

// Filters
export const USHG_ADMIN_REQUEST_STATUS_VALUES = ENT_ADMIN_REQUEST_STATUS_VALUES;
export const USHG_ADMIN_REQUESTS_SORT_BY_VALUES = ENT_ADMIN_REQUESTS_SORT_BY_VALUES;

export const USHG_ADMIN_DEFAULT_REQUESTS_SORT_BY = 'MOST_RECENT';

// Filters

// ----- USHG Admin

// DEBOUNCE on request made for search input in milliseconds
export const REQUESTS_FILTER_SEARCH_DEBOUNCE_MS = 500;
// DEBOUNCE on request made for search input

// Pagination
export const REQUESTS_PAGE_SIZE_INCREMENT = 10;
export const REQUESTS_MAX_PAGE_SIZE = 50;
export const REQUESTS_MIN_PAGE_SIZE = 10;

export const REQUESTS_DEFAULT_PAGE = 1;
// Pagination

// Query Params
export const REQUESTS_STATUS_QUERY_PARAM = 'request_status';
export const REQUESTS_SORT_QUERY_PARAM = 'sort';
export const REQUESTS_FILTER_QUERY_PARAM = 'search';
// Query Params

// consider the requests stale after 30 seconds
export const REQUESTS_STALE_TIME = 30000;

// one min as they would not probably change
export const USHG_ADMIN_REQUEST_TYPES_FETCH_STALE_TIME = 60000;

export const ENTERPRISE_ADMIN_REQUEST_ACTION = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
} as const;

// Requests
