import {
  ChannelMembershipSummary,
  ChannelMessageSummary,
} from '@aws-sdk/client-chime-sdk-messaging';
import { queryClient } from '../../../config/react-query';

interface SetLastChannelMessageInCacheArgs {
  channelArn: string;
  channelMessages: ChannelMessageSummary[];
  chimeBearer: string;
}

const setLastChannelMessageInCache = (args: SetLastChannelMessageInCacheArgs) => {
  const { channelArn, channelMessages, chimeBearer } = args;

  const data = { ChannelMessages: channelMessages };

  queryClient.setQueryData(
    [
      {
        scope: 'messaging',
        item: 'last-channel-message',
        channelArn,
        chimeBearer,
      },
    ],
    data
  );
};

interface SetChannelMembershipsInCacheArgs {
  channelArn: string;
  channelMemberships: ChannelMembershipSummary[];
  chimeBearer: string;
}

const setChannelMembershipsInCache = (args: SetChannelMembershipsInCacheArgs) => {
  const { channelArn, channelMemberships, chimeBearer } = args;

  queryClient.setQueryData(
    [
      {
        scope: 'messaging',
        item: 'channel-memberships',
        channelArn,
        chimeBearer,
      },
    ],
    channelMemberships
  );
};

export { setLastChannelMessageInCache, setChannelMembershipsInCache };
