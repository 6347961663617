import { Navbar } from '../Navbar';
import { Page } from '../Pages';
import PageLevelErrorBoundaryFallback from './PageLevelErrorBoundaryFallback';
interface ApplicationErrorBoundaryProps {
  error: Error;
}

const ApplicationErrorBoundary = ({ error }: ApplicationErrorBoundaryProps) => {
  return (
    <Page showIllustrator={true}>
      <Navbar></Navbar>
      <PageLevelErrorBoundaryFallback error={error} />
    </Page>
  );
};

export default ApplicationErrorBoundary;
