import Select from '../../../../../../components/Select';
import { useEffect, useState } from 'react';
import { SearchInput } from '../../../common';
import { Filter, selectOptionType } from '../../../../types/Filter.Helper';
import { RootState } from '../../../../../../store';
import { useAppSelector } from '../../../../../../hooks';
import useFilterState from '../../../../hooks/useFilterState';
import { GetDurationSortRange } from '../../../../data/DurationRange';
import { COURSES_SORT, COURSE_SEARCH_DELAY } from '../../../../constants/course';
import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';
import useDebounce from '../../../../../../hooks/use-debounce';

type FilterWidgetsProps = {
  filterApplyHandler: any;
  search: string;
  setSearch: any;
  searchClear: any;
};

const FilterWidgets = ({
  filterApplyHandler,
  search,
  setSearch,
  searchClear,
}: FilterWidgetsProps) => {
  const {
    filters,
    sort,
    search: { searchPlaceHolder },
  } = useStrapiCoursesData().allCourses;

  const {
    ascendingSortLabel,
    descendingSortLabel,
    shortestToLongestSortLabel,
    longestToShortestSortLabel,
    sortPlaceHolder,
  } = sort;
  const { speakersLabel, tagsLabel, categoriesLabel, formatLabel, durationLabel } = filters;
  const sortBy: selectOptionType[] = [
    {
      display: sortPlaceHolder,
      value: Filter.NONE,
    },
    {
      display: ascendingSortLabel,
      value: COURSES_SORT.NAME.ASCENDING,
    },
    {
      display: descendingSortLabel,
      value: COURSES_SORT.NAME.DESCENDING,
    },
    {
      display: shortestToLongestSortLabel,
      value: COURSES_SORT.DURATION.ASCENDING,
    },
    {
      display: longestToShortestSortLabel,
      value: COURSES_SORT.DURATION.DESCENDING,
    },
  ];
  const DURATION_SORT_RANGE = GetDurationSortRange();
  const filterByDuration: selectOptionType[] = [
    {
      display: durationLabel,
      value: Filter.NONE,
    },
    ...DURATION_SORT_RANGE,
  ];
  const applyToFilter = (list: selectOptionType[], find: string, filterType: Filter) => {
    const element = list.find((l) => l.value === find);
    if (element)
      filterApplyHandler({ type: filterType, display: element.display, value: element.value });
  };

  // Tags Control
  const tagFetch = useAppSelector((state: RootState) => state.courses.tags);
  const selectedTagChangeHandler = (e: any) => {
    applyToFilter(tags, e.target.value, Filter.TAGS);
  };

  // Categories Control
  const categoriesFetch = useAppSelector((state: RootState) => state.courses.categoryName);
  const selectedCategoryChangeHandler = (e: any) => {
    applyToFilter(categories, e.target.value, Filter.CATEGORIES);
  };

  // Duration Control
  const selectedDurationChangeHandler = (e: any) => {
    applyToFilter(filterByDuration, e.target.value, Filter.DURATION);
  };

  // Speaker Control
  const speakersFetch = useAppSelector((state: RootState) => state.courses.speakers);
  const selectedSpeakerChangeHandler = (e: any) => {
    applyToFilter(speakers, e.target.value, Filter.SPEAKERS);
  };

  // Format Control
  const formatsFetch = useAppSelector((state: RootState) => state.courses.format);
  const selectedFormatChangeHandler = (e: any) => {
    applyToFilter(formats, e.target.value, Filter.FORMATS);
  };

  // Sort Control
  const [sorts, setSorts] = useState<selectOptionType[]>([]);
  const selectedSortChangeHandler = (e: any) => {
    applyToFilter(sorts, e.target.value, Filter.SORT);
  };

  const debounceSearch = useDebounce(search, COURSE_SEARCH_DELAY);

  // Search Control
  const searchHandler = (e: any | null, forceSearch?: boolean) => {
    if (e?.key === 'Enter' || forceSearch) {
      if (search.trim().length !== 0)
        filterApplyHandler({ type: Filter.SEARCH, display: search, value: search });
      else searchClear();
    }
  };

  useEffect(() => {
    searchHandler(null, true);
  }, [debounceSearch]);

  const { tags, speakers, formats, categories } = useFilterState({
    tagFetch,
    categoriesFetch,
    formatsFetch,
    speakersFetch,
  });

  useEffect(() => {
    setSorts(sortBy);
  }, []);

  return (
    <div className="flex flex-wrap justify-between">
      <div className="my-1 flex flex-wrap gap-1.5">
        {categories && (
          <Select
            label={categoriesLabel}
            className="rounded-[1.67521px]"
            onChange={selectedCategoryChangeHandler}
            options={categories}
          />
        )}
        {tags && (
          <Select
            label={tagsLabel}
            className="rounded-[1.67521px]"
            onChange={selectedTagChangeHandler}
            options={tags}
          />
        )}
        {filterByDuration && (
          <Select
            label={durationLabel}
            className="rounded-[1.67521px]"
            onChange={selectedDurationChangeHandler}
            options={filterByDuration}
          />
        )}
        {speakers && (
          <Select
            label={speakersLabel}
            className="rounded-[1.67521px]"
            onChange={selectedSpeakerChangeHandler}
            options={speakers}
          />
        )}
        {formats && (
          <Select
            label={formatLabel}
            className="rounded-[1.67521px]"
            onChange={selectedFormatChangeHandler}
            options={formats}
          />
        )}
      </div>
      <div className="my-1 flex flex-wrap gap-1.5">
        {sorts && (
          <Select
            label={sortPlaceHolder}
            className="rounded"
            onChange={selectedSortChangeHandler}
            options={sorts}
          />
        )}
        <SearchInput
          search={search}
          setSearch={setSearch}
          searchHandler={searchHandler}
          placeholder={searchPlaceHolder}
        />
      </div>
    </div>
  );
};

export default FilterWidgets;
