import { useQuery } from '@tanstack/react-query';
import { getEarnedBadges } from '../api/badges';
import { STALE_TIME } from '../constants/badges';
import { PartialBadgesFilter } from '../types/badges';

interface Props {
  filters: PartialBadgesFilter;
  isEnabled?: boolean;
}

const useGetEarnedBadgesQuery = ({ filters, isEnabled = true }: Props) => {
  const data = useQuery(
    [
      {
        scope: 'badges',
        item: 'earned-badges',
        filters: filters,
      },
    ],
    async () => {
      return getEarnedBadges(filters);
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME,
      enabled: isEnabled,
    }
  );

  return data;
};

export default useGetEarnedBadgesQuery;
