import type { RouteObject } from 'react-router-dom';
import { Reports } from '../components';
import { LAZY_LOAD_REPORTS } from '../constants/performance';

const routes: RouteObject[] = [
  {
    path: 'reports',
    element: <Reports lazyLoad={LAZY_LOAD_REPORTS} />,
  },
];

export default routes;
