import { getUserTenantId } from './tenant';

/**
 * Get media assets cloudfront CDN domain url.
 */
const getMediaAssetsCDNDomain = () => {
  const mediaAssetsCDNDomain = process.env.REACT_APP_MEDIA_ASSETS_CDN_DOMAIN;
  return mediaAssetsCDNDomain;
};

const getSharedMediaAssetsBaseURL = () => {
  const mediaAssetsCDNDomain = getMediaAssetsCDNDomain();
  const tenantId = getUserTenantId();

  const url = `${mediaAssetsCDNDomain}/tenant/${tenantId}/shared`;
  return url;
};

/**
 * Generate profile picture media asset path with sub.
 */
const generateProfilePictureURLWithSub = ({ sub }: { sub: string }) => {
  const sharedMediaAssetsBaseUrl = getSharedMediaAssetsBaseURL();
  const url = `${sharedMediaAssetsBaseUrl}/thumbnail/profile_pic/${sub}`;
  return url;
};

/**
 * Generate the full cdn url with key.
 */
const generateCDNMediaAssetURLWithKey = ({ key }: { key: string }) => {
  const mediaAssetsCDNDomain = getMediaAssetsCDNDomain();
  const url = `${mediaAssetsCDNDomain}/${key}`;
  return url;
};

export {
  generateProfilePictureURLWithSub,
  getSharedMediaAssetsBaseURL,
  getMediaAssetsCDNDomain,
  generateCDNMediaAssetURLWithKey,
};
