import { LinkNode } from '@lexical/link';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import React, { useMemo } from 'react';

interface Props {
  children: React.ReactElement | React.ReactElement[] | string;
  namespace: string;
}

const RichTextEditorProvider = ({ children, namespace }: Props) => {
  // Editor initial config
  const initialConfig: InitialConfigType = useMemo(() => {
    return {
      theme: {
        text: {
          bold: 'font-semibold',
          italic: 'italic',
          strikethrough: 'line-through',
          underline: 'underline',
          underlineStrikethrough: 'underline-line-through',
        },
        link: 'text-blue-500 hover:underline hover:underline-offset-1',
      },
      namespace: namespace,
      onError: (err) => {
        console.error(err);
      },
      nodes: [LinkNode],
    };
  }, [namespace]);

  return <LexicalComposer initialConfig={initialConfig}>{children}</LexicalComposer>;
};

export default RichTextEditorProvider;
