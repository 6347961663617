import clsx from 'clsx';

interface Props {
  text: string | number;
  isLowercase?: boolean;
}

const CommonTextCell = ({ text, isLowercase = false }: Props) => {
  return (
    <span
      className={clsx(
        'inline-block whitespace-nowrap lowercase',
        !isLowercase && 'first-letter:uppercase'
      )}
    >
      {text ? text : '-'}
    </span>
  );
};

export default CommonTextCell;
