import type { RouteObject } from 'react-router-dom';
import { USHG_HQ_APP_ROLE } from '../../../constants';
import HasAccess from '../../shared/components/HasAccess';
import ListIndividualPage from '../pages/ListIndividualPage';
import SingleIndividualPage from '../pages/SingleIndividualPage';

const IndividualPageRoutes: RouteObject[] = [
  {
    path: 'individual',
    element: (
      <HasAccess roles={[USHG_HQ_APP_ROLE.USHG_ADMIN]} Fallback={null}>
        <ListIndividualPage />
      </HasAccess>
    ),
  },
  {
    path: 'individual/:id/:name',
    element: (
      <HasAccess roles={[USHG_HQ_APP_ROLE.USHG_ADMIN]} Fallback={null}>
        <SingleIndividualPage />
      </HasAccess>
    ),
  },
];

export default IndividualPageRoutes;
