import { useStrapiDataHelper } from '../../hooks/useStrapiData';
import cn from '../../utils/cn';

type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const AuthIllustration = ({ className, ...otherProps }: Props) => {
  const data = useStrapiDataHelper();
  const { LoginImage, authIllustrationSRLabel } = data.login.data.attributes;
  const loginImageUrl = LoginImage.data.attributes.url;

  return (
    <div
      style={{
        backgroundImage: `url(${loginImageUrl})`,
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      aria-label={authIllustrationSRLabel}
      role="img"
      className={cn('m-2 h-full w-full', className)}
      {...otherProps}
    ></div>
  );
};

export default AuthIllustration;
