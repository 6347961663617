const SETTING_API_PATHS = {
  CHANGE_PASSWORD: '/auth/account/change-password',
  CANCEL_SUBSCRIPTION: '/payments/v1/cancel-subscription',
  CHECKOUT_SESSION: '/payments/v1/checkout/update',
  INVOICES: '/payments/v1/admin/invoices',
  CHANGE_PLAN: '/payments/v1/change-plan',
  SUBSCRIPTION_DETAILS: '/payments/v1/admin/subscription',
  GET_USER_NOTIFICATION_PREFERENCES: '/users/notification/preferences',
  UPDATE_USER_NOTIFICATION_PREFERENCES: '/users/notification/preferences',
  REACTIVATE_PLAN: '/payments/v1/resume-subscription',
  CANCEL_TRIAL: '/payments/v1/trial',
  RENEW_PLAN: '/payments/v1/subscription/renew',
  UPDATE_TENANT_MFA_CONFIGURATION: '/users/admin/mfa-config',
  GET_TENANT_MFA_CONFIGURATION: '/users/admin/mfa-config',
  MAINTENANCE_MODE: '/users/hq/maintenance-mode',
  HQ_ANNOUNCEMENT: '/users/hq/v1/announcement',
  HQ_ANNOUNCEMENT_MULTI_LANG: '/users/hq/v1/announcement/multi-lang',
  UPDATE_MFA_PREFERENCE: '/users/mfa-preference',
};

const API_ERROR_CODE = {
  RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
} as const;

export { SETTING_API_PATHS, API_ERROR_CODE };
