import Dialog from '../Dialog';

type Props = {
  icon: React.ReactNode;
  content: React.ReactNode;
  containerClassName?: string;
  overlayClassName?: string;
  triggerClassName?: string;
};
const IconWithModal = ({
  icon,
  content,
  containerClassName,
  overlayClassName,
  triggerClassName,
}: Props) => {
  return (
    <Dialog
      containerClassName={containerClassName}
      overlayClassName={overlayClassName}
      triggerClassName={triggerClassName}
      Content={content}
      onInteractOutside={(e) => {
        e.preventDefault();
      }}
    >
      {icon}
    </Dialog>
  );
};

export default IconWithModal;
