import RegistrationBaseLayout from '../layouts/RegistrationBaseLayout';
import { Navigate } from 'react-router-dom';
import UserRegistrationMain from '../components/register/UserRegistration/UserRegistrationMain';
import { UserRegistration } from '../components/register/UserRegistrationActionResponse';
import { PricingTable } from '../components/register/Pricing';
import UserAccountProvisionProgress from '../components/register/UserAccountProvisionProgress/UserAccountProvisionProgress';

const registerRoutes = {
  path: 'register',
  element: <RegistrationBaseLayout />,
  children: [
    {
      index: true,
      element: <Navigate to="pricing" replace={true} />,
    },
    {
      path: 'pricing',
      element: <PricingTable />,
    },
    {
      path: 'subscription',
      element: <UserRegistrationMain />,
    },
    {
      path: 'success',
      element: <UserRegistration />,
    },
    {
      path: 'progress',
      element: <UserAccountProvisionProgress />,
    },
  ],
};

export default registerRoutes;
