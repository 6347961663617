import { useState } from 'react';
import { Button, Modal } from 'react-daisyui';
import toast from 'react-hot-toast';
import { BasicSpinner } from '../../../../components/Spinners';
import { Toast } from '../../../../components/Toast';
import { queryClient } from '../../../../config/react-query';
import { deleteBadge } from '../../api/badges';

import useStrapiBadgesData from '../../hooks/useStrapiBadgeData';
interface props {
  open: boolean;
  toggleVisibility: any;
  badgeId: number;
}
function DeleteBadgeModal({ open, toggleVisibility, badgeId }: props) {
  const { deleteBadge: strapiDeleteBadge, hqToast } = useStrapiBadgesData();
  const { badgeDeletedSuccess, badgeDeletedFailure } = hqToast;
  const { title, description, deleteButton, cancelButton } = strapiDeleteBadge;
  const [isLoading, setIsLoading] = useState(false);
  const toggleVisible = () => {
    toggleVisibility();
  };
  const deleteHandler = () => {
    setIsLoading(true);
    deleteBadge({ id: badgeId })
      .then(async (data: any) => {
        if (data.message) {
          toast.custom(
            (t) => <Toast variant="success" Title={badgeDeletedSuccess} toastInstance={t} />,
            { id: 'DELETE_BADGE_TOAST_' + Math.floor(Math.random() * 3000).toString() }
          );
        }
        await queryClient.invalidateQueries({
          queryKey: [
            {
              scope: 'badges',
              item: 'get-badges',
            },
          ],
        });
        setIsLoading(false);
        toggleVisible();
      })
      .catch(() => {
        toast.custom(
          (t) => <Toast variant="error" Title={badgeDeletedFailure} toastInstance={t} />,
          { id: 'DELETE_BADGE_TOAST_' + Math.floor(Math.random() * 3000).toString() }
        );
        setIsLoading(false);
        toggleVisible();
      });
  };

  return (
    <div className="[&>*:nth-child(1)]:bg-black">
      <Modal className="w-[512px] rounded bg-card-bg shadow-xl" open={open}>
        <Modal.Header className="mb-5 text-lg font-bold text-white">{title}</Modal.Header>
        <Modal.Body>
          <p className="text-sm font-normal text-zinc-300">{description}</p>
          <Modal.Actions>
            <a
              className="cursor-pointer px-6 py-3 font-[inter] text-sm font-medium text-white"
              onClick={toggleVisible}
            >
              {cancelButton}
            </a>

            <Button
              onClick={deleteHandler}
              disabled={isLoading}
              className="btn inline-block h-[40px] !rounded bg-base-brand px-6 py-2 text-sm font-medium normal-case leading-tight text-white hover:bg-base-brand disabled:bg-base-brand disabled:text-white"
            >
              {deleteButton}{' '}
              {isLoading ? <BasicSpinner className=" ml-2 inline-block text-white" /> : null}
            </Button>
          </Modal.Actions>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DeleteBadgeModal;
