import { UseQueryResult } from '@tanstack/react-query';
import { ErrorDisplay } from '../../../../../../components/DataTables';
import useStrapiMembersData from '../../../../hooks/useStrapiMembersData';

interface Props {
  refetch: UseQueryResult['refetch'];
  allowsRefetch?: boolean;
  className?: string;
  isRefetching: boolean;
}

const AllMembersTableErrorState = (props: Props) => {
  const { refetch, allowsRefetch = true, className, isRefetching } = props;
  const {
    errorMessage: { errorFetchingMembers },
  } = useStrapiMembersData();
  return (
    <ErrorDisplay
      message={errorFetchingMembers}
      refetch={refetch}
      allowsRefetch={allowsRefetch}
      className={className}
      isRefetching={isRefetching}
    ></ErrorDisplay>
  );
};

export default AllMembersTableErrorState;
