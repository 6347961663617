import clsx from 'clsx';
import { ArrowRight } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { useStrapiRequestData } from '../../../hooks/useStrapiRequestData';

interface Props {
  className?: string;
}

const DashboardRequestsHeader = ({ className }: Props) => {
  const { dashBoardTableProps } = useStrapiRequestData();
  const { title, viewAllButton } = dashBoardTableProps;
  return (
    <div className={clsx('flex flex-row items-center justify-between gap-2', className)}>
      <h2 className="text-[14px] font-medium leading-[150%] text-zinc-50">{title}</h2>
      <Link
        className="flex cursor-pointer flex-row items-center gap-1 border-b border-b-transparent text-[10px] font-medium leading-[150%] text-zinc-400 hover:border-b-zinc-400"
        to="/requests"
      >
        <span>{viewAllButton}</span>
        <ArrowRight size={12} color="currentColor" className="text-zinc-400" />
      </Link>
    </div>
  );
};

export default DashboardRequestsHeader;
