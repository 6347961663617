import { useMutation } from '@tanstack/react-query';
import { createModule } from '../api';
import { ModuleCreateMutationType } from '../types';

const useModuleCreateMutation = () => {
  const { data, error, isError, isLoading, mutate } = useMutation({
    mutationFn: (data: ModuleCreateMutationType) => createModule(data),
  });
  return { data, error, isError, isLoading, mutate };
};

export default useModuleCreateMutation;
