import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { queryClient } from '../../../config/react-query';
import { FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY } from '../../shared/constants';
import { reactivatePlan } from '../api';
import { useStrapiSettingsData } from './useStrapiSettingsData';

export const useReactivatePlanMutation = () => {
  const {
    toastMessage: { reactivatePlanSuccess, reactivatePlanFailed },
  } = useStrapiSettingsData();
  return useMutation({
    mutationFn: async () => {
      return await reactivatePlan();
    },
    onSuccess: async () => {
      toast.custom(
        (t) => <Toast variant="success" Title={reactivatePlanSuccess} toastInstance={t} />,
        { id: 'REACTIVATE_PLAN_TOAST_' + Math.floor(Math.random() * 3000).toString() }
      );
      await queryClient.refetchQueries([FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY]);
    },
    onError: () => {
      toast.custom(
        (t) => <Toast variant="error" Title={reactivatePlanFailed} toastInstance={t} />,
        { id: 'REACTIVATE_PLAN_TOAST_' + Math.floor(Math.random() * 3000).toString() }
      );
    },
  });
};
