import { ENTERPRISE_ADMIN_REQUEST_ACTION } from './../constants/requests';
import { CreateUSHGAdminRequestResponse } from './../types/enterprise-admin.types';
import {
  EnterpriseAdminRequest,
  GetUSHGAdminRequestTypesResponse,
  PartialEnterpriseAdminRequestsFilter,
  PartialUSHGAdminRequestsFilter,
  USHGAdminRequest,
} from '../types';
import qs from 'qs';
import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { REQUESTS_API_PATHS } from '../constants';

/**
 * Get all enterprise admin requests
 */
const getEnterpriseAdminRequests = async (
  filters: PartialEnterpriseAdminRequestsFilter
): Promise<{
  data: EnterpriseAdminRequest[];
  totalItems: number;
}> => {
  const queryString = qs.stringify(filters);
  const path = REQUESTS_API_PATHS.ENTERPRISE_ADMIN.GET_ALL_REQUESTS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get all USHG Admin request types.
 */
const getUSHGAdminRequestTypes = async (): Promise<GetUSHGAdminRequestTypesResponse> => {
  const path = REQUESTS_API_PATHS.USHG_ADMIN.GET_ALL_REQUEST_TYPES;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get all USHG Admin Requests
 */
const getUSHGAdminRequests = async (
  filters: PartialUSHGAdminRequestsFilter
): Promise<{
  data: USHGAdminRequest[];
  totalItems: number;
}> => {
  const queryString = qs.stringify(filters);
  const path = REQUESTS_API_PATHS.USHG_ADMIN.GET_ALL_REQUESTS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get all USHG Admin Requests
 */
const resolveUSHGAdminRequests = async (
  id: number
): Promise<{
  success: boolean;
  message: string;
}> => {
  const path = REQUESTS_API_PATHS.USHG_ADMIN.RESOLVE_REQUEST(id);
  const response = await API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?`, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

interface RaiseUSHGAdminRequestArgs {
  title: string;
  description: string;
  typeId: number;
}

/**
 * Create/Raise a new USHG Admin Request
 */
const raiseUSHGAdminRequest = async (
  args: RaiseUSHGAdminRequestArgs
): Promise<CreateUSHGAdminRequestResponse> => {
  const { title, description, typeId } = args;
  const body = {
    title,
    description,
    typeId,
  };
  const path = REQUESTS_API_PATHS.ENTERPRISE_ADMIN.RAISE_REQUEST_TO_USHG_ADMIN;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    body,
    withCredentials: true,
  });
  return response.data;
};

interface ApproveOrRejectEnterpriseAdminRequest {
  action: keyof typeof ENTERPRISE_ADMIN_REQUEST_ACTION;
  requestId: number;
  comment?: string;
}

/**
 * Approve or Reject Enterprise Admin Request.
 */
const approveOrRejectEnterpriseAdminRequest = async (
  args: ApproveOrRejectEnterpriseAdminRequest
) => {
  const { action, requestId, comment } = args;

  const body = {
    action,
    comment,
  };
  const path = REQUESTS_API_PATHS.ENTERPRISE_ADMIN.APPROVE_OR_REJECT_REQUEST(requestId);
  const response = await API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    body,
    withCredentials: true,
  });
  return response.data;
};

export {
  getEnterpriseAdminRequests,
  getUSHGAdminRequests,
  getUSHGAdminRequestTypes,
  raiseUSHGAdminRequest,
  approveOrRejectEnterpriseAdminRequest,
  resolveUSHGAdminRequests,
};
export type { RaiseUSHGAdminRequestArgs, ApproveOrRejectEnterpriseAdminRequest };
