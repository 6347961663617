import { twMerge } from 'tailwind-merge';
import Select, { SelectProps } from '../Select';

const FilterDropDown = ({ className, ...otherProps }: SelectProps) => {
  return (
    <Select
      className={twMerge(
        'rounded-sm border border-zinc-900 bg-zinc-900 py-2 pl-4 pr-10 !text-[14px] font-normal !leading-[20px] text-zinc-100',
        className
      )}
      {...otherProps}
    />
  );
};

export default FilterDropDown;
