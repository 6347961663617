import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

// types

interface SMSMFA {
  description: string;
  timeBetweenResend: number;
  resendCodeBtnLabel: string;
  resendAllowedInLabel: string;
  resendingCodeToast: string;
  resendingMFACodeSuccessToast: string;
  resendingMFACodeErrorToast: string;
}

interface Shared {
  heading: string;
  authCodeLabel: string;
  confirmAuthBtnLabel: string;
  backToLoginLabel: string;
  lostAccessLabel: string;
  anyProblemLabel: string;
  contactUsLabel: string;
  contactUsLink: string;
  codeRequiredErrorMessage: string;
  loginSuccessToastMessage: string;
  sessionExpiredErrorMessage: string;
  somethingWentWrongErrorMessage: string;
}

interface StrapiMFAContent {
  Shared: Shared;
  SMSMFA: SMSMFA;
}

// types

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

const useStrapLoginMFAContent = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const strapiLoginMFAContent = data.login.data.attributes.MFA as StrapiMFAContent;
  return strapiLoginMFAContent;
};

export default useStrapLoginMFAContent;
