import { PERIOD, REPORTS_INTERVAL } from '../constants';
import { useStrapiReportsData } from '../hooks/useStrapiReportData';
import { Duration } from '../types';
import { getFirstDateOfLastThreeMonths } from './dateTimeUtil';

const dashboardReportsDuration = () => {
  // Default reports to last 3 Months
  const { firstDateOfLastThreeMonths } = getFirstDateOfLastThreeMonths();
  const { DurationDropDown, DurationLabels } = useStrapiReportsData().topNav;
  const { lastMonthLabel, last3MonthLabel, last6MonthLabel } = DurationDropDown;
  const { daysLabel, weeksLabel, monthsLabel } = DurationLabels;
  const REPORTS_X_AXIS_LABEL = {
    DAYS: daysLabel,
    WEEKS: weeksLabel,
    MONTHS: monthsLabel,
  } as const;
  const DURATION_RANGE = [
    {
      display: lastMonthLabel,
      value: PERIOD.LAST_MONTH,
      interval: REPORTS_INTERVAL.WEEK,
      xAxis: REPORTS_X_AXIS_LABEL.WEEKS,
    },
    {
      display: last3MonthLabel,
      value: PERIOD.LAST_THREE_MONTHS,
      interval: REPORTS_INTERVAL.FORTNIGHT,
      xAxis: REPORTS_X_AXIS_LABEL.WEEKS,
    },
    {
      display: last6MonthLabel,
      value: PERIOD.LAST_SIX_MONTHS,
      interval: REPORTS_INTERVAL.MONTH,
      xAxis: REPORTS_X_AXIS_LABEL.MONTHS,
    },
  ];
  const dashboardReportsDuration: Duration = {
    fromDate: firstDateOfLastThreeMonths,
    ...DURATION_RANGE[1],
  };

  return dashboardReportsDuration;
};

export { dashboardReportsDuration };
