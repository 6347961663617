import { API } from 'aws-amplify';
import {
  DownloadPresignedUrlFilter,
  MultipartPreSignedUrlRequestPayload,
  GetPresignedUrlFilters,
  PresignedUrlResponse,
} from '../../types';
import { AMPLIFY_USHG_API_ENDPOINT_NAME, S3_API_PATHS } from '../../constants';
import qs from 'qs';
import axios, { AxiosRequestConfig } from 'axios';

/**
 * Get s3 presigned url
 */

interface Props {
  presignedUrlFilters: GetPresignedUrlFilters;
}

const getPresignedUrl = async ({ presignedUrlFilters }: Props): Promise<PresignedUrlResponse> => {
  const queryString = qs.stringify(presignedUrlFilters);
  const path = S3_API_PATHS.PRESIGNED_URL.GET_PRESIGNED_URL;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
  });

  return response.data;
};

const getPreSignedUrlCourse = async (
  presignedUrlFilters: MultipartPreSignedUrlRequestPayload
): Promise<PresignedUrlResponse> => {
  const queryString = qs.stringify(presignedUrlFilters);
  const path = S3_API_PATHS.PRESIGNED_COURSE_URL.GET_PRESIGNED_URL;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
  });
  return response.data;
};

const finalizeMultipartUpload = async (finalize: any) => {
  return await API.post(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    S3_API_PATHS.PRESIGNED_COURSE_URL.FINALIZE_MULTIPART_UPLOAD,
    {
      body: finalize,
      response: true,
    }
  );
};

interface UploadToS3Request {
  uploadUrl: string;
  fileContents: File;
  config?: AxiosRequestConfig;
}

/**
 * Upload file to S3
 */

const uploadToS3 = async ({ uploadUrl, fileContents, config }: UploadToS3Request) => {
  const options = {
    ...config,
    headers: { 'Content-Type': 'application/octet-stream', ...(config?.headers ?? {}) },
  };
  const response = await axios.put(uploadUrl, fileContents, options);

  return response.data;
};

interface DownloadPresignedUrlRequest {
  downloadPresignedUrlFilter: DownloadPresignedUrlFilter;
}

/**
 * Download presigned url
 */

const downloadPresignedUrl = async ({
  downloadPresignedUrlFilter,
}: DownloadPresignedUrlRequest) => {
  const queryString = qs.stringify(downloadPresignedUrlFilter);

  const path = S3_API_PATHS.PRESIGNED_URL.DOWNLOAD_PRESIGNED_URL;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
  });

  return response.data;
};

export {
  getPresignedUrl,
  uploadToS3,
  downloadPresignedUrl,
  getPreSignedUrlCourse,
  finalizeMultipartUpload,
};
