import { twMerge } from 'tailwind-merge';
import { BadgeCard } from '../../../../../components/Cards';
import { EarnedBadgesResponse } from '../../../../badges/types/badges';

interface Props {
  badges: EarnedBadgesResponse[];
  className?: string;
  showBadgeName?: boolean;
}

const EarnedBadgesGrid = (props: Props) => {
  const { badges, className, showBadgeName = false } = props;

  return (
    <div
      className={twMerge(
        'flex h-max w-full flex-row gap-2 gap-y-3 overflow-auto lg:grid lg:auto-rows-auto lg:grid-cols-[repeat(auto-fill,minmax(var(--badge-image-size),1fr))] lg:place-content-between xl:grid-cols-3',
        className
      )}
    >
      {badges.map((badge) => {
        return (
          <BadgeCard
            key={badge.badgeId}
            badgeContainerClassName="w-full"
            badge={badge}
            showBadgeName={showBadgeName}
          ></BadgeCard>
        );
      })}
    </div>
  );
};

export default EarnedBadgesGrid;
