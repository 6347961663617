import { format, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { getLocale } from '../../../../../../../../../utils';
import { useLanguage } from '../../../../../../../../shared/context/languageContext';

interface TimestampCellProps {
  // ISO String
  timestamp: string;
}

const TimestampCell = (props: TimestampCellProps) => {
  const { timestamp } = props;
  const { language } = useLanguage();

  const displayDate = useMemo(() => {
    const parsedISOTimestamp = parseISO(timestamp);
    return format(parsedISOTimestamp, 'EEE dd MMM, yyyy p', { locale: getLocale(language) });
  }, [timestamp]);

  return <span className="whitespace-nowrap">{displayDate}</span>;
};

export default TimestampCell;
