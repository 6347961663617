import cn from '../../../../../utils/cn';

type Props = {
  className?: string;
  containerClassName?: string;
  children: React.ReactNode | React.ReactNode[];
};

const UserLoginMainLayout = (props: Props) => {
  const { children, className, containerClassName } = props;

  return (
    <main id="main-content" className={cn('flex w-full justify-center', className)}>
      <div
        className={cn('flex grow flex-col items-center justify-center gap-5', containerClassName)}
      >
        {children}
      </div>
    </main>
  );
};

export default UserLoginMainLayout;
