import { gql } from '@apollo/client';

export const AllMembersFragment = gql`
  fragment AllMembersFragment on AllmemberEntityResponse {
    data {
      attributes {
        TopNav {
          title
          addMemberButton
          bulkUploadButton
        }
        Table {
          TableStatusOptions {
            allStatuslabel
            activatedStatuslabel
            invitedStatuslabel
            deactivatedStatuslabel
            statusPlaceHolder
          }
          TableRowEditOptions {
            changeTeamButton
            changeRoleButton
            activatedButton
            deactivatedButton
            resendInviteButton
            disableMFAForUserButton
          }
          TableHeader {
            nameTableHeader
            teamTableHeader
            statusTableHeader
            lastLoginTableheader
            emailTableHeader
            jobTitleTableHeader
            roleTableHeader
          }
        }
        trialLimitOverModal {
          message
          title
          cancelButton
          actionButton
        }
        activatedModal {
          message
          title
          cancelButton
          actionButton
        }
        deactivatedModal {
          message
          title
          cancelButton
          actionButton
        }
        subscriptionInfoModal {
          message
          title
          cancelButton
          actionButton
        }
        BulkUploadModal {
          title
          bulkUploadTitle
          cancelButton
          uploadButton
          uploadInstructions {
            csvInstructionText
            downloadSampleButton
            uploadText
            dragAndDropLabel
          }
        }
        ToastMessage {
          bulkUploadSuccess
          bulkUploadFailure
          bulkUploadSizeError
          InitiatingBulkUploadSuccess
          InitiatingBulkUploadFailure
          subscriptionLimitOverMessage
          trialLimitOverMessage
          activateSuccess
          activateFailure
          deactivateSuccess
          deactivateFailure
          resendInviteSuccess
          resendInviteFailure
          userAlreadyActive
          userAlreadyDeactive
          activateSuccessMessage
          activateFailureMessage
          deactivateSuccessMessage
          deactivateFailureMessage
          bulkUploadSuccessMessage
          bulkUploadFailureMessage
          fileUploadCancelled
          fileUploadSuccess
          fileUploadFailed
        }
        LoadingMessages {
          uploadingLabel
          InitiatingUploadLabel
          uploadedLabel
        }
        ErrorMessages {
          errorFetchingMembers
          noMembersAvailableMessage
        }
        DisableMFAUserDialog {
          title
          content
          confirmLabel
          cancelLabel
          mfaDisableInProgressToast
          mfaDisableSuccessToast
          mfaDisableErrorToast
        }
        UserRoleUpdate {
          userRoleRequiredError
          invalidUserRoleError
          roleFieldLabel
          teamDetailRequiredError
          teamInputPlaceHolder
          teamFieldLabel
          roleInputLabel
          teamInputLabel
          roleInputPlaceHolder
          SuccessMessageBody
          SuccessMessageHead
          updateRoleButtonText
          cancelButtonText
          changeRoleModalTitle
          teamsFetchErrorHead
          teamsFetchErrorMessage
        }
        userTeamChange {
          userRoleRequiredError
          invalidUserRoleError
          roleFieldLabel
          teamDetailRequiredError
          teamInputPlaceHolder
          teamFieldLabel
          roleInputLabel
          teamInputLabel
          roleInputPlaceHolder
          SuccessMessageBody
          SuccessMessageHead
          changeTeamButtonText
          cancelButtonText
          changeTeamModalTitle
          currentRole
          currentTeam
          teamsFetchErrorMessage
          teamsFetchErrorHead
        }
      }
    }
  }
`;
