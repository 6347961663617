import clsx from 'clsx';
import { useStrapiOrganizationData } from '../../../../../organisation/hooks/useStrapiOrganizationData';

interface HealthRiskCellProps {
  status: string;
}

interface GenericStatusCellProps {
  containerClassName: string;
  dotClassName: string;
  display: string;
}

const GenericStatusCell = (props: GenericStatusCellProps) => {
  const { containerClassName, dotClassName, display } = props;
  return (
    <div
      className={clsx(
        'flex w-min items-center gap-1 rounded-[100px] py-[2px] pr-2 pl-1.5',
        containerClassName
      )}
    >
      <span className={clsx('h-1.5 w-1.5 rounded-full', dotClassName)}></span>
      <span>{display}</span>
    </div>
  );
};

const HealthRiskCell = (props: HealthRiskCellProps) => {
  const {
    organisationTable: { TableProps },
  } = useStrapiOrganizationData();
  const { inRiskStatusLabel, healthyStatusLabel, noDataLabel } = TableProps;
  const { status } = props;
  switch (status) {
    case 'Unhealthy': {
      return (
        <GenericStatusCell
          containerClassName="bg-amber-900 text-amber-100 whitespace-nowrap"
          dotClassName="bg-emerald-100"
          display={inRiskStatusLabel}
        />
      );
    }
    case 'Healthy': {
      return (
        <GenericStatusCell
          containerClassName="bg-emerald-900  text-amber-100"
          dotClassName="bg-emerald-100"
          display={healthyStatusLabel}
        />
      );
    }
    case 'ACTIVE': {
      return (
        <GenericStatusCell
          containerClassName="bg-emerald-900  text-amber-100"
          dotClassName="bg-emerald-100"
          display={healthyStatusLabel}
        />
      );
    }
    case 'CANCELLED': {
      return (
        <GenericStatusCell
          containerClassName="bg-amber-900 text-amber-100 whitespace-nowrap"
          dotClassName="bg-emerald-100"
          display={inRiskStatusLabel}
        />
      );
    }
    case 'SCHEDULED_CANCEL': {
      return (
        <GenericStatusCell
          containerClassName="bg-amber-900 text-amber-100 whitespace-nowrap"
          dotClassName="bg-emerald-100"
          display={inRiskStatusLabel}
        />
      );
    }
    default: {
      return (
        <GenericStatusCell
          containerClassName="bg-zinc-900 text-zinc-100  whitespace-nowrap"
          dotClassName="bg-zinc-100"
          display={noDataLabel}
        />
      );
    }
  }
};

export default HealthRiskCell;
