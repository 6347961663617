import { API } from 'aws-amplify';
import { AUTH_API_PATHS, LOCAL_STORAGE_AMPLIFY_CONFIG_KEY, LOCAL_STORAGE_REMEMBER_ME_KEY, LOCAL_STORAGE_USHG_HQ_ENTERPRISE_INVITE, LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY } from '../constants';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { LOCAL_STORAGE_CHIME_APP_INSTANCE_ARN_KEY, LOCAL_STORAGE_USER_TENANT_ID } from '../../messaging/constants';

export const initApi = async () => {
  const path = AUTH_API_PATHS.INIT;
  try {
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
  });

  return response;

  } catch (error: any) {
      // Handle error from API call
      if (error.response && error.response.data && error.response.data.errorCode === "USER_NOT_FOUND") {

         //   // Clear custom config in localStorage
        localStorage.removeItem(LOCAL_STORAGE_AMPLIFY_CONFIG_KEY);
        localStorage.removeItem(LOCAL_STORAGE_REMEMBER_ME_KEY);
        localStorage.removeItem(LOCAL_STORAGE_CHIME_APP_INSTANCE_ARN_KEY);
        localStorage.removeItem(LOCAL_STORAGE_USER_TENANT_ID);
        localStorage.removeItem(LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY);
        localStorage.removeItem(LOCAL_STORAGE_USHG_HQ_ENTERPRISE_INVITE);
        window.location.reload();
        throw error;
      }
      throw error;
    }


};
