import { Option } from '../../../components/Select';
import RatingsDropDownDisplayComponent from '../components/common/SingleCoursePage/FeedbackTableComponent/components/RatingsDropDownDisplayComponent';

const USHG_ADMIN_RATINGS_SELECT_OPTIONS: Option[] = [
  {
    display: <RatingsDropDownDisplayComponent rating="1" />,
    value: '1',
  },
  {
    display: <RatingsDropDownDisplayComponent rating="2" />,
    value: '2',
  },
  {
    display: <RatingsDropDownDisplayComponent rating="3" />,
    value: '3',
  },
  {
    display: <RatingsDropDownDisplayComponent rating="4" />,
    value: '4',
  },
  {
    display: <RatingsDropDownDisplayComponent rating="5" />,
    value: '5',
  },
];

export { USHG_ADMIN_RATINGS_SELECT_OPTIONS };
