import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS, QUERY_STALE_TIME_MS } from '../helpers/tanstack-query';
import { useReduxAuthState } from '../../../hooks';
import { getLearningJourneyDetail } from '../api/learning-journey';

interface Props {
  id: string;
  enabled?: boolean;
}

const useLearningJourneyDetail = ({ id, enabled = true }: Props) => {
  const auth = useReduxAuthState();

  if (!auth.isAuthenticated) {
    throw new Error('User should be authenticated');
  }

  const query = useQuery({
    queryKey: QUERY_KEYS.learningJourneyDetail({ username: auth.user.username, id }),
    queryFn: async ({ queryKey }) => {
      const { id } = queryKey[0];

      const learningJourney = await getLearningJourneyDetail({ journeyId: id });
      return learningJourney;
    },
    staleTime: QUERY_STALE_TIME_MS.learningJourneyDetail,
    enabled,
  });

  return query;
};

export default useLearningJourneyDetail;
