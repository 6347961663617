import toast from 'react-hot-toast';
import { useAppDispatch } from '../../../hooks';
import { addEnterpriseTeams } from '../api';
import { SECTIONS } from '../constants/section-name';
import { triggerSectionDataRefresh } from '../slices/team-dispatch.helper';
import { AdminTeamData } from '../types/interface';
import { useStrapiTeamData } from './useStrapiTeamData';
import axios from 'axios';
import { USHGAPIError } from '../../../types';
import { ERROR_CODE } from '../constants';
import { showToast } from '../../shared/helpers/toast';
import { useQueryClient } from '@tanstack/react-query';

export const useAddTeamsAPI = () => {
  const dispatch = useAppDispatch();
  const { adminToastMessages } = useStrapiTeamData();
  const {
    adminActionProcessingToastMessage,
    adminSuccessToastMessage,
    adminFailureToastMessage,
    adminSubTeamAddedSuccessMessage,
  } = adminToastMessages;

  const queryClient = useQueryClient();

  const addTeams = async (teamData: AdminTeamData, refreshSectionName: SECTIONS) => {
    let toastId: string;

    toastId = showToast({
      title: adminActionProcessingToastMessage,
      variant: 'info',
    });

    // added the api for the  add teams

    try {
      await addEnterpriseTeams(teamData).then();

      triggerSectionDataRefresh(refreshSectionName, dispatch);
      toast.remove(toastId);
      toastId = showToast({
        title: teamData.parentId ? adminSubTeamAddedSuccessMessage : adminSuccessToastMessage,
        variant: 'success',
      });

      // Remove the username allowed checks for the requested users
      teamData.members?.forEach((tm) => {
        if (tm && tm.email) {
          queryClient.removeQueries({
            queryKey: ['user_exists', tm.email],
          });
        }
      });

      const teamName = teamData.teamName;

      // Remove the team name allowed check from the query cache
      if (teamName) {
        queryClient.removeQueries({
          queryKey: ['team_exists', teamName],
        });
      }
    } catch (error: any) {
      let errorMessage: string | undefined = undefined;

      if (axios.isAxiosError(error)) {
        const data = error.response?.data;

        if (data && typeof data === 'object') {
          const { errorCode, errorMessage: apiErrorMessage } = data as USHGAPIError;

          switch (errorCode) {
            case ERROR_CODE.USER_ALREADY_EXIST: {
              // TODO: Modify response structure such that we could use the emails in message
              // that we get from cms
              errorMessage = apiErrorMessage;
            }
          }
        }
      }

      toast.remove(toastId);
      toastId = showToast({
        title: adminFailureToastMessage,
        body: errorMessage,
        variant: 'error',
      });

      throw error;
    }
  };
  return { addTeams };
};
