import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { USHGAPIError } from '../../../types';
import { getCourseBasicDetails } from '../api';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';

interface Props {
  id: number;
  language?: string;
  isEnabled?: boolean;
}
/**
 * Wrapper around the react querys useQuery to fetch the course details.
 */
const useCourseBasicDetailsQuery = ({
  id,
  language = DEFAULT_PREFERRED_LANGUAGE,
  isEnabled = true,
}: Props) => {
  const [errorType, setErrorType] = useState<string | null>();
  const { isLoading, data, isError, refetch, isRefetching, isFetching } = useQuery(
    [
      {
        courseId: id,
        language: language,
        scope: 'courses',
        item: 'courses-basic-details',
      },
    ],
    async ({ queryKey }) => {
      const { courseId, language } = queryKey[0];

      return await getCourseBasicDetails({ id: courseId, language });
    },
    {
      enabled: isEnabled && id !== undefined,
      onError: (error) => {
        // If axios error
        if (axios.isAxiosError(error)) {
          const data = error.response?.data;
          if (data) {
            const { errorCode } = data as USHGAPIError;
            setErrorType(errorCode);
          }
        }
      },
    }
  );
  return { isLoading, data, isError, refetch, isRefetching, isFetching, errorType, setErrorType };
};

export default useCourseBasicDetailsQuery;
