interface props {
  messageTitle: string;
  messageDescription: string;
}
const DataNotFound = ({ messageTitle, messageDescription }: props) => {
  return (
    <div className="m-4 flex w-full flex-col items-center justify-center gap-1 rounded-lg bg-card-bg p-10">
      <div className="text-center text-base font-semibold text-zinc-50">{messageTitle}</div>
      <div className="text-center text-sm font-normal text-zinc-500">{messageDescription}</div>
    </div>
  );
};

export default DataNotFound;
