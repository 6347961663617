import { Navbar } from 'react-daisyui';
import EarnedBadges from './EarnedBadges';
import Leaderboard from './LeaderboardTable/Leaderboard';
import { ArrowLeft } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import HasAccess from '../../shared/components/HasAccess';
import { USHG_HQ_APP_ROLE } from '../../../constants';
import '../../../styles/header.css';
import useStrapiBadgesData from '../hooks/useStrapiBadgeData';
import { SideBarLayoutPageContentWrapper } from '../../../components/Wrapper';
import UpNextBadges from './UpNextBadges';
interface Props {
  showBackButton?: boolean;
}
function Badge({ showBackButton = false }: Props) {
  const navigate = useNavigate();
  const {
    singleCourse: { badgesLabel, backButton },
  } = useStrapiBadgesData();
  return (
    <div className="flex flex-col gap-5">
      <Navbar className="flex w-full items-center justify-between border-b border-b-zinc-800 bg-card-bg px-3 py-6 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
        <div className="flex flex-col">
          <div className="header-title">
            {showBackButton && (
              <div className="flex justify-between">
                <span
                  className="cursor-pointer text-xs text-[#A1A1AA]"
                  onClick={() => navigate(-1)}
                >
                  <ArrowLeft size={12} className="inline-block" /> {backButton}
                </span>
              </div>
            )}
            {badgesLabel}
          </div>
        </div>
      </Navbar>
      <SideBarLayoutPageContentWrapper className="flex-col gap-5 lg:flex-row">
        <div className="lg:basis-3/4">
          <div className="flex flex-col gap-5">
            <EarnedBadges />
            <UpNextBadges />
          </div>
        </div>
        <HasAccess
          Fallback={null}
          roles={[
            USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
            USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
            USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER,
            USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
          ]}
        >
          <div className="h-max rounded-lg bg-[#252528] lg:basis-1/4">
            <Leaderboard />
          </div>
        </HasAccess>
      </SideBarLayoutPageContentWrapper>
    </div>
  );
}
export default Badge;
