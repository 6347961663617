import Dialog from '../../../../../components/Dialog';
import { useStrapiTeamData } from '../../../hooks/useStrapiTeamData';
import EditTeamModal from './editTeam';

interface Props {
  setIsModalOpen: (open: boolean) => void;
  teamId: number;
  teamName?: string;
}

const EditTeamMain = ({ teamId, setIsModalOpen, teamName }: Props) => {
  const { editTeam } = useStrapiTeamData();
  return (
    <div className="mx-1 flex flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
        {editTeam.editTeamTitle}
      </Dialog.Title>
      <EditTeamModal teamId={teamId} setIsModalOpen={setIsModalOpen} teamName={teamName} />
    </div>
  );
};

export default EditTeamMain;
