import clsx from 'clsx';
import { ErrorDisplay } from '../../../../components/DataTables';
import { BasicSpinner } from '../../../../components/Spinners';
import { ERROR_TYPES } from '../../../../constants/error-types.constants';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import { useStrapiOrganizationData } from '../../../organisation/hooks/useStrapiOrganizationData';
import useBillingHistoryInvoiceQuery from '../../hooks/useBillingHistoryInvoiceQuery';
import BillingHistoryHeader from './BillingHistoryHeader';
import BillingHistoryTable from './Table/BillingHistoryTable';
import TableEmptyState from './Table/TableEmptyState';

interface Props {
  className?: string;
  tenantType?: string;
  username?: string;
  tenantId?: string;
  isOrganizationView?: boolean;
}

const BillingHistoryMain = ({
  className,
  tenantId = undefined,
  tenantType,
  isOrganizationView,
  username,
}: Props) => {
  const request = useBillingHistoryInvoiceQuery({ tenantId, tenantType, username });
  const displayLoader =
    (request.isLoading && !request.data) || (request.isFetching && request.data?.length === 0);
  const isEmptyState = request.data && request.data?.length == 0;
  const { singleView } = useStrapiOrganizationData();
  const { billingTableHeader } = singleView.TableHeadings;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  return (
    <main
      id="main-content"
      className={clsx(
        'flex flex-col items-start gap-4 rounded-sm bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)] md:p-5 lg:p-6',
        className
      )}
    >
      {isOrganizationView && <div className="items-start  text-white">{billingTableHeader}</div>}
      {request.isLoading && !request.data && (
        <div className="flex w-full justify-center py-4">
          <BasicSpinner className="text-white" />
        </div>
      )}
      {request.data && (
        <div
          className={clsx(
            'flex w-full flex-col gap-4',
            request.isPreviousData && request.isFetching && 'pointer-events-none opacity-70'
          )}
        >
          <BillingHistoryHeader data={request.data} />
          <div className="max-h-[50vh] w-full overflow-y-scroll">
            <BillingHistoryTable data={request.data} />
          </div>
        </div>
      )}
      {isEmptyState && !displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <TableEmptyState isFilterApplied={false} />
        </div>
      )}
      {request.isError && !request.data && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <ErrorDisplay
            refetch={request.refetch}
            isRefetching={request.isRefetching}
            allowsRefetch={true}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            message={strapiErrorMessage['SYSTEM_ERROR']}
          />
        </div>
      )}
    </main>
  );
};

export default BillingHistoryMain;
