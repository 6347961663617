import { useMutation } from '@tanstack/react-query';
import { CourseCreateInMultiLangMutationType } from '../types';
import { createCourseInMultiLang } from '../api';

const useCourseCreateInMultiLangMutation = () => {
  const { data, error, isLoading, isError, mutate } = useMutation({
    mutationFn: (create: CourseCreateInMultiLangMutationType) => createCourseInMultiLang(create),
  });
  return { data, error, isLoading, isError, mutate };
};

export default useCourseCreateInMultiLangMutation;
