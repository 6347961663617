import { RouteObject } from 'react-router-dom';
import { SingleCourseView } from '../components/SingleCourseView';
import SingleModuleComponent from '../components/SingleCourseView/components/SingleModuleComponent/SingleModuleComponent';
import AllCoursesPage from '../pages/courses';

const routes: RouteObject[] = [
  {
    path: '/courses',
    element: <AllCoursesPage />,
  },
  {
    path: '/courses/:id',
    element: <SingleCourseView />,
  },
  {
    path: '/courses/:id/modules',
    element: <SingleModuleComponent />,
  },
  {
    path: 'learning-journeys/:journeyId/courses/:id',
    element: <SingleCourseView />,
  },
  {
    path: 'learning-journeys/:journeyId/courses/:id/modules',
    element: <SingleModuleComponent />,
  },
];

export default routes;
