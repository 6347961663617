import { DotsSixVertical, Translate } from 'phosphor-react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { AssessmentQuestionListArgs, AssessmentQuestionRowData } from '../../../../types';
import Dialog from '../../../../../../components/Dialog';
import { useEffect, useState } from 'react';
import AssessmentQuestionModal from './AssessmentQuestionModal/AssessmentQuestionModal';
import { useStrapiHQAdminCoursesData } from '../../../../hooks/useStrapiHQAdminCourseData';
import { useStrapiSharedData } from '../../../../../shared/hooks/useStrapiSharedData';
import { useGetAssessmentQuestionsByIdQuery } from '../../../../hooks';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../../constants/user';
import { COURSES_ERROR_CODE } from '../../../../constants/courses-management';

type AssessmentQuestionRowItemProps = {
  item: AssessmentQuestionListArgs;
  refetch: () => void;
};
const AssessmentQuestionRowItem = ({ item, refetch }: AssessmentQuestionRowItemProps) => {
  const [isEditAssessmentQuestionModalOpen, setIsEditAssessmentQuestionModalOpen] = useState(false);
  const [isManageAssessmentQuestionModalOpen, setIsManageAssessmentQuestionModalOpen] =
    useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { singleAssessmentView, questionModal } = useStrapiHQAdminCoursesData();
  const { choicesLabel } = singleAssessmentView.Questions;
  const { addQuestionButton, editQuestionTitle, editQuestionButton, manageLanguagesTitle } =
    questionModal;

  const {
    language: { StrapiLanguageOptions },
  } = useStrapiSharedData();

  // Map `label` to `display` for Select component with null check
  const mappedOptions =
    StrapiLanguageOptions?.map((option: { label: string; value: string }) => ({
      display: option.label,
      value: option.value,
    })) || [];

  // Find the next language to English
  const nextLanguage = mappedOptions.find(
    (option: { value: string }) => option.value !== DEFAULT_PREFERRED_LANGUAGE
  );

  const [selectedLanguage, setSelectedLanguage] = useState(nextLanguage ? nextLanguage.value : '');
  const [buttonLabel, setButtonLabel] = useState('');

  const handleLanguageChange = (newLanguage: string) => {
    setSelectedLanguage(newLanguage);
  };

  const questionDetail = useGetAssessmentQuestionsByIdQuery({
    questionId: item.data.questionId,
    language: selectedLanguage,
    enabled: isManageAssessmentQuestionModalOpen,
  });

  useEffect(() => {
    if (questionDetail.data || questionDetail.errorType) {
      if (
        !questionDetail.data &&
        questionDetail.errorType === COURSES_ERROR_CODE.QUESTION_ID_NOT_FOUND
      ) {
        setButtonLabel(addQuestionButton);
      } else {
        setButtonLabel(editQuestionButton);
      }
    }
  }, [questionDetail.data, questionDetail.errorType, addQuestionButton, editQuestionButton]);

  return (
    <div
      className="my-4 rounded-lg border border-zinc-700 bg-zinc-900 p-3"
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <Dialog
        open={isEditAssessmentQuestionModalOpen}
        onOpenChange={(open) => setIsEditAssessmentQuestionModalOpen(open)}
        onInteractOutside={(e) => {
          // Donot close the Modal when we click outside the Dialog Content
          e.preventDefault();
        }}
        Content={
          <AssessmentQuestionModal
            modalTitle={editQuestionTitle}
            setIsAssessmentQuestionModalOpen={setIsEditAssessmentQuestionModalOpen}
            questionsPrefetchData={item as unknown as AssessmentQuestionRowData}
            defaultLanguageData={item}
            formActionButtonLabel={editQuestionButton}
            modalType="update"
            refetch={refetch}
          />
        }
      >
        <div className="flex flex-col items-center gap-2 xs:flex-row xs:justify-between xs:gap-0">
          <div className="flex w-full text-left text-sm font-medium text-zinc-50">
            <span>{item.data.sortOrder}.&nbsp;&nbsp;</span>
            <span>{item.data.question}</span>
          </div>
          <div className="flex w-full flex-row items-center justify-between gap-2 text-xs text-zinc-400 xs:justify-end">
            {item.data.answer?.length} {choicesLabel}
            <DotsSixVertical size={28} {...listeners} className="transition hover:text-zinc-300" />
            <Translate
              className="text-inherit"
              color="currentColor"
              size={14}
              weight="bold"
              onClick={(e) => {
                e.stopPropagation(); // Prevent event from reaching the parent
                setIsEditAssessmentQuestionModalOpen(false);
                setIsManageAssessmentQuestionModalOpen(true);
              }}
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        open={isManageAssessmentQuestionModalOpen}
        onOpenChange={(open) => setIsManageAssessmentQuestionModalOpen(open)}
        onInteractOutside={(e) => {
          // Donot close the Modal when we click outside the Dialog Content
          e.preventDefault();
        }}
        Content={
          <AssessmentQuestionModal
            modalTitle={manageLanguagesTitle}
            setIsAssessmentQuestionModalOpen={setIsManageAssessmentQuestionModalOpen}
            questionsPrefetchData={questionDetail.errorType ? item : questionDetail}
            defaultLanguageData={item}
            formActionButtonLabel={buttonLabel}
            modalType="manage"
            refetch={questionDetail.refetch}
            showLanguageDropdown={true}
            mappedOptions={mappedOptions}
            selectedLanguage={selectedLanguage}
            handleLanguageChange={handleLanguageChange}
            errorType={questionDetail.errorType}
            setErrorType={questionDetail.setErrorType}
          />
        }
      ></Dialog>
    </div>
  );
};

export default AssessmentQuestionRowItem;
