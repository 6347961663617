import { gql } from '@apollo/client';

export const SideNavFragment = gql`
  fragment SideNavFragment on SideNavigatorEntityResponse {
    data {
      attributes {
        teamsLabel
        reportsLabel
        requestLabel
        dashboardLabel
        messagesLabel
        myBadgesLabel
        coursesLabel
        organisationLabel
        calendarLabel
        notificationsLabel
        settingsLabel
        logoutButton
        myTeamLabel
        myReportsLabel
        performanceLabel
        allMembersLabel
        inviteFriendsLabel
        individualLabel
        badgesLabel
        collapseButton
        learningJourneysLabel
        raiseRequestToHQLabel
        logo {
          data {
            attributes {
              url
            }
          }
        }
        sidebarSRLabel
        topPanelSRLabel
        bottomPanelSRLabel
      }
    }
  }
`;
