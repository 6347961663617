import { ToastVariant } from '../../../types';

const TOAST_VARIANT_DURATION_MAP: Record<ToastVariant, number> = {
  error: 5000,
  success: 3000,
  info: 2000,
  warning: 5000,
};

export { TOAST_VARIANT_DURATION_MAP };
