import { Row } from '@tanstack/react-table';
import clsx from 'clsx';
import { TableDataRow } from '../../../../../../../../components/DataTables';
import { USHGAdminFeedback } from '../../../../../../constants/course-feedback';
import React, { useState } from 'react';
import FeedbackModal from './FeedbackModal';

interface Props {
  row: Row<USHGAdminFeedback>;
  className?: string;
}

const FeedbacksTableDataRow = (props: Props) => {
  const { row, className } = props;
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const handleRowClick = () => {
    setFeedbackOpen(true);
  };
  return (
    <>
      <TableDataRow
        row={row}
        className={clsx('w-full cursor-pointer hover:bg-page-bg-dark/20', className)}
        onClick={handleRowClick}
      />
      <FeedbackModal
        open={feedbackOpen}
        setIsDialogOpen={setFeedbackOpen}
        feedback={row.original}
      />
    </>
  );
};

export default FeedbacksTableDataRow;
