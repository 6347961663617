import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { updateAssessmentQuestion } from '../api';
import { updateQuestionArgs } from '../types';
import { useStrapiHQAdminCoursesData } from './useStrapiHQAdminCourseData';

const useUpdateAssessmentQuestionMutation = () => {
  const {
    toastMessages: {
      updateQuestionSuccessTitle,
      updateQuestionSuccessMessage,
      updateQuestionFailureTitle,
      updateQuestionFailureMessage,
    },
  } = useStrapiHQAdminCoursesData();
  const mutation = useMutation({
    mutationFn: (args: updateQuestionArgs) => updateAssessmentQuestion(args),

    onSuccess: () => {
      toast.custom((t) => (
        <Toast
          variant="success"
          Title={updateQuestionSuccessTitle}
          SubTitle={updateQuestionSuccessMessage}
          toastInstance={t}
        />
      ));
    },
    onError: (error) => {
      console.error('Error while updating an assessment question %o', error);
      toast.custom((t) => (
        <Toast
          variant="error"
          Title={updateQuestionFailureTitle}
          SubTitle={updateQuestionFailureMessage}
          toastInstance={t}
        />
      ));
    },
  });

  return mutation;
};

export default useUpdateAssessmentQuestionMutation;
