import UserCard from '../../../../components/Cards/UserCard';
import { MEMBERS } from '../../../../constants/userTypes';
import { Member } from '../../types/interface';

type MemberCardProps = {
  members?: Member[];
};

function MemberCard({ members }: MemberCardProps) {
  const renderList = members?.map((Member) => {
    const MemberDetails = {
      type: MEMBERS,
      details: {
        id: Member.id as string,
        name: Member.name as string,
        profileImage: Member.profileImage as string,
        enrolledCourses: Member.enrolledCourses as number,
        progress: Member.progress ? Member.progress : 0,
        designation: Member.designation as string,
      },
    };
    return <UserCard key={Member.id} user={MemberDetails} classNames={'min-w-[172px]'} />;
  });
  return <>{renderList}</>;
}

export default MemberCard;
