import { RouteObject } from 'react-router-dom';
import CourseManager from '../components/CourseManager';
import CourseAssessmentPage from '../components/AssessmentPage/CourseAssessmentPage';
import CoursesPage from '../components/CoursesPage';
import NewCoursePage from '../components/NewCourse';
import CourseModulePage from '../components/ModulePage/CourseModulePage';
import SingleCourseView from '../../../features/course-management/components/common/SingleCoursePage/SingleCourseView';

const routes: RouteObject[] = [
  {
    path: 'courses',
    element: <CourseManager />,
    children: [
      {
        index: true,
        element: <NewCoursePage />,
      },
      {
        path: ':id',
        element: <SingleCourseView />,
      },
      {
        path: ':id/edit',
        element: <CoursesPage />,
      },
      {
        path: ':id/edit/assessment/:assessmentId',
        element: <CourseAssessmentPage />,
      },
      {
        path: ':id/edit/module/:moduleId',
        element: <CourseModulePage />,
      },
    ],
  },
];

export default routes;
