import { useMutation } from '@tanstack/react-query';
import { selfAssignCourse } from '../api/courses';

const useSelfAssignCourseMutation = () => {
  const { data, error, isLoading, isError, mutate } = useMutation({
    mutationFn: selfAssignCourse,
  });
  return { data, error, isLoading, isError, mutate };
};

export default useSelfAssignCourseMutation;
