import { Filter, filterType } from '../../../../types/Filter.Helper';
import FilteredByWidgets from '../FilteredByWidgets/FilteredByWidgets';
import FilterWidgets from '../FilterWidgets/FilterWidgets';
import { twMerge } from 'tailwind-merge';
import { useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';
import '../../../../../../styles/header.css';

type CourseHeaderProps = {
  appliedFilters: filterType[];
  setAppliedFilters: any;
  className?: string;
  showFilteredByWidgets?: boolean;
};

export interface CourcesHeaderRefObj {
  clearFiltersHandler: () => void;
}

const CoursesHeader = forwardRef(
  (
    { appliedFilters, setAppliedFilters, className, showFilteredByWidgets }: CourseHeaderProps,
    ref: React.ForwardedRef<CourcesHeaderRefObj>
  ) => {
    const filterApplyHandler = (filter: filterType) => {
      const isExist = appliedFilters.find(
        (f) => f.value === filter.value && filter.type === f.type
      );
      if (!isExist && filter.value !== Filter.NONE) {
        if (
          !(
            filter.type === Filter.DURATION ||
            filter.type === Filter.SORT ||
            filter.type === Filter.SEARCH
          )
        ) {
          setAppliedFilters([...appliedFilters, filter]);
        } else {
          let isAdded = false;
          const filters = appliedFilters.map((f) => {
            if (f.type === filter.type || f.type === filter.type) {
              isAdded = true;
              return filter;
            }
            return f;
          });
          if (!isAdded) filters.push(filter);
          setAppliedFilters(filters);
        }
      }
    };

    const [search, setSearch] = useState<string>('');

    const filterRemoveHandler = (filter: filterType) => {
      setAppliedFilters(
        appliedFilters.filter((f) => !(f.value === filter.value && filter.type === f.type))
      );
    };

    const clearSearch = () => {
      setAppliedFilters(appliedFilters.filter((f) => !(f.type === Filter.SEARCH)));
    };

    const clearFiltersHandler = useCallback(() => {
      setSearch('');
      setAppliedFilters([]);
    }, [setSearch, setAppliedFilters]);
    const { allCourses } = useStrapiCoursesData();

    // The component instance will be extended with whatever you return from the callback passed as the second argument
    useImperativeHandle(ref, () => ({
      clearFiltersHandler,
    }));

    return (
      <div
        className={twMerge(
          'flex flex-col gap-3.5 border-b border-b-zinc-800 bg-card-bg px-3 py-6 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9',
          className
        )}
      >
        <h1 className="header-title">{allCourses.title}</h1>
        <FilterWidgets
          searchClear={clearSearch}
          filterApplyHandler={filterApplyHandler}
          search={search}
          setSearch={setSearch}
        />
        {showFilteredByWidgets && (
          <FilteredByWidgets
            filters={appliedFilters}
            filterRemoveHandler={filterRemoveHandler}
            clearFiltersHandler={clearFiltersHandler}
          />
        )}
      </div>
    );
  }
);

CoursesHeader.displayName = 'CoursesHeader';

export default CoursesHeader;
