import {
  ChannelMembershipSummary,
  ChimeSDKMessagingClient,
  ChimeSDKMessagingClientConfig,
} from '@aws-sdk/client-chime-sdk-messaging';
import { getAWSCredentials } from '../../auth/utils';
import { CHIME_AWS_REGION } from './../constants/chime-messaging';

let chimeMessagingClient: ChimeSDKMessagingClient | null = null;

/**
 * Get ChimeSDKMessagingClient.
 */
const getChimeMessagingClient = async (options?: { byPassCache: boolean }) => {
  // Default donot bypass cache
  const byPassCache = options?.byPassCache ?? false;

  // If chime messaging client is not yet initialized user it
  if (chimeMessagingClient == null || byPassCache) {
    const config: ChimeSDKMessagingClientConfig = {
      credentials: async () => {
        const credentials = await getAWSCredentials();
        return credentials;
      },
      region: CHIME_AWS_REGION,
    };

    console.log('Initializing new chime messaging client');

    chimeMessagingClient = new ChimeSDKMessagingClient(config);
  }

  return chimeMessagingClient;
};

/**
 * Reset ChimeSDKMessaging clients.
 */
function resetChimeClient() {
  chimeMessagingClient = null;
}

const sendChimeCommand: ChimeSDKMessagingClient['send'] = async (command) => {
  const chimeMessagingClient = await getChimeMessagingClient();

  try {
    const response = await chimeMessagingClient.send(command);
    return response;
  } catch (error) {
    if ((error as any)?.name === 'ExpiredTokenException') {
      // Will refresh the chime messaging client with new credentials
      await getChimeMessagingClient({ byPassCache: true });

      // Retry the command
      const response = await sendChimeCommand(command);
      return response;
    }

    throw error;
  }
};

interface HasMembershipArgs {
  userArn: string;
  activeChannelMemberships: ChannelMembershipSummary[];
}

const hasMembership = (args: HasMembershipArgs) => {
  const { userArn, activeChannelMemberships } = args;
  return activeChannelMemberships.map((m) => m.Member?.Arn).indexOf(userArn) > -1;
};

export { getChimeMessagingClient, resetChimeClient, hasMembership, sendChimeCommand };
export type { HasMembershipArgs };
