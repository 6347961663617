import React, { useState } from 'react';
import { useMyCoursesQuery, useRecommendedCoursesQuery } from '../../../hooks';
import { USER_COURSE_STATUS } from '../../../types';
import DashboardCourseCard from './DashboardCourseCard';
import { ExploreAllCourses } from '../../common';
import clsx from 'clsx';
import {
  IN_PROGRESS_RECOMMENDED_COURSES_DEFAULT_LIMIT,
  IN_PROGRESS_RECOMMENDED_COURSES_DEFAULT_OFFSET,
  RECOMMENDATION_TYPE,
} from '../../../constants';
import { InView } from 'react-intersection-observer';
import { useStrapiDashboardData } from '../../../../shared/hooks';
import ScaleLoader from '../../../../../components/Loader/ScaleLoader';

interface Props {
  className: string;
  lazyLoad?: boolean;
}
const CourseCardWrapper = ({ className, lazyLoad = true }: Props) => {
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);
  // Get Last In Progress Course Query
  const inProgressCourseFilter = {
    offset: IN_PROGRESS_RECOMMENDED_COURSES_DEFAULT_OFFSET,
    limit: IN_PROGRESS_RECOMMENDED_COURSES_DEFAULT_LIMIT,
    type: USER_COURSE_STATUS.IN_PROGRESS,
  };

  const inProgressCourse = useMyCoursesQuery({
    filters: inProgressCourseFilter,
    item: 'dashboard-in-progress-course',
    isEnabled: isInView,
  });

  // Get Recommended Course Query
  const recommendedCourseFilter = {
    type: RECOMMENDATION_TYPE.PERSONAL,
    offset: IN_PROGRESS_RECOMMENDED_COURSES_DEFAULT_OFFSET,
    limit: IN_PROGRESS_RECOMMENDED_COURSES_DEFAULT_LIMIT,
    isEnabled: isInView,
  };

  const recommendedCourse = useRecommendedCoursesQuery(recommendedCourseFilter);

  // Empty State
  const emptyState =
    (inProgressCourse.data && inProgressCourse.data?.results?.length === 0) ||
    (recommendedCourse.data && recommendedCourse.data?.results?.length === 0);

  // Loading State
  const displayLoader = inProgressCourse.isLoading || recommendedCourse.isLoading;

  const hasCourses =
    inProgressCourse.data &&
    inProgressCourse.data?.results?.length > 0 &&
    recommendedCourse.data &&
    recommendedCourse.data?.results?.length > 0;

  const errorState =
    (inProgressCourse.error && !inProgressCourse.data) ||
    (recommendedCourse.error && !recommendedCourse.data);
  const { continueYourLastCourseLabel, newCourseRecommendedToYouLabel } =
    useStrapiDashboardData().dashboardCards;
  return (
    <InView
      as="div"
      className={clsx('h-full min-h-[175px]', className)}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div className="grid h-full grid-cols-6 gap-x-3 gap-y-2 sm:gap-y-3 lg:gap-x-[13px] lg:gap-y-4 xl:gap-y-5">
        {/* Display Loader */}
        {displayLoader && !hasCourses && (
          <div className="col-span-6 flex min-h-[175px] w-full items-center justify-center rounded-lg bg-card-bg py-4">
            <ScaleLoader />
          </div>
        )}
        {/* Render in-progress and newly recommended courses if we have data */}
        {hasCourses && (
          <>
            <DashboardCourseCard
              myCoursesQuery={inProgressCourse}
              className="col-span-6 md:col-span-3"
              cardHeader={continueYourLastCourseLabel}
            />
            <DashboardCourseCard
              myCoursesQuery={recommendedCourse}
              className="col-span-6 md:col-span-3"
              cardHeader={newCourseRecommendedToYouLabel}
            />
          </>
        )}
        {/* Default to placeholder content if either of in-progress or recommended course is empty or when there's an error  */}
        {((emptyState && !displayLoader) || errorState) && (
          <ExploreAllCourses className="col-span-6" />
        )}
      </div>
    </InView>
  );
};

export default CourseCardWrapper;
