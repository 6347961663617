import { useQuery } from '@tanstack/react-query';
import { PERFORMANCE_REPORT_TYPES, REPORTS_STALE_TIME } from '../../shared/constants';
import { getCustomerLeaderboard } from '../api';
import { CustomerLeaderboardFilters } from '../types';

interface Props {
  filters: Partial<CustomerLeaderboardFilters>;
  isEnabled: boolean;
}

const useUSHGAdminCustomerLeaderboardQuery = ({ filters, isEnabled }: Props) => {
  const query = useQuery(
    [
      {
        filters: filters,
        scope: 'ushg-admin-dashboard',
        item: PERFORMANCE_REPORT_TYPES.CUSTOMER_LEADER_BOARD,
      },
    ],
    async () => {
      return getCustomerLeaderboard(filters);
    },
    {
      staleTime: REPORTS_STALE_TIME,
      enabled: isEnabled,
    }
  );

  return query;
};

export default useUSHGAdminCustomerLeaderboardQuery;
