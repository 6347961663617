import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { ORG_API_PATH } from '../constants';
import { GetCouponsByProductName, InviteOrganisationpayload } from '../types';

const getAllOrganisation = async (filters: any): Promise<{ results: []; totalCount: number }> => {
  const queryString = qs.stringify(filters);
  const path = ORG_API_PATH.GET_ORGS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};
const getAllDiscountCoupons = async (
  filters?: GetCouponsByProductName
): Promise<{ results: { id: string; name: string }[] }> => {
  const path = ORG_API_PATH.GET_COUPONS;
  const queryString = qs.stringify(filters);

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};
const inviteOrganisation = async (payload: InviteOrganisationpayload) => {
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, ORG_API_PATH.ADD_ORG, {
    body: payload,
    response: true,
    withCredentials: true,
  });
  return response;
};
const getOrganisationName = async (
  id: string
): Promise<{ data: { name: string; department: string } }> => {
  const path = ORG_API_PATH.GET_ORG_NAME(id);
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}`, {
    response: true,
    withCredentials: true,
  });
  return response;
};

const getOrganisationStatistics = async (id: string) => {
  const path = ORG_API_PATH.GET_ORG_STATISTICS(id);
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}`, {
    response: true,
    withCredentials: true,
  });
  return response;
};

export {
  getAllOrganisation,
  inviteOrganisation,
  getAllDiscountCoupons,
  getOrganisationName,
  getOrganisationStatistics,
};
