import { useQuery } from '@tanstack/react-query';
import { getEnterpriseLeaderTeams } from '../api/EnterpriseLeaderTeams';
import { PartialEnterpriseLeaderTeamsFilter } from '../types/interface';

interface Props {
  filters: PartialEnterpriseLeaderTeamsFilter;
}

const useEnterpriseLeaderTeamsQuery = ({ filters }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching } =
    useQuery(
      [
        {
          scope: 'teams',
          filters: filters,
        },
      ],
      async () => {
        return getEnterpriseLeaderTeams(filters);
      },
      {
        keepPreviousData: true,
        // TODO: to be decided We are storing it in cache for 30 seconds
        staleTime: 30000,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching };
};

export default useEnterpriseLeaderTeamsQuery;
