const isDefined = <T>(argument: T | undefined): argument is T => {
  return argument !== undefined;
};

const parseJSON = <T>(value: string | null | undefined): T | undefined => {
  try {
    return value === 'undefined' || value === undefined || value === null
      ? undefined
      : JSON.parse(value ?? '');
  } catch {
    console.log('parsing error on', { value });
    return undefined;
  }
};

export { isDefined, parseJSON };
