const ORGANIZATION_API_PATHS = {
  GET_BASE_PATH: (tenantId: string) => {
    return `/users/organization/${tenantId}`;
  },
  GET_ALL_MEMBERS: (tenantId: string) => {
    return `${ORGANIZATION_API_PATHS.GET_BASE_PATH(tenantId)}/members`;
  },
};

const USERS_BASE_PATH = '/users';

const USERS_API_PATHS = {
  ACTIVATE_USER: `${USERS_BASE_PATH}/admin/enable`,
  DEACTIVATE_USER: `${USERS_BASE_PATH}/admin/disable`,
  INIT_BULK_INVITE_USERS: `${USERS_BASE_PATH}/admin/enterprise/invite/bulk`,
  ADMIN_DISABLE_MFA: `${USERS_BASE_PATH}/admin/disable-mfa`,
  GET_TEAMS: `${USERS_BASE_PATH}/enterprise/allTeams`,
  UPDATE_USER_ROLE_TEAM: `${USERS_BASE_PATH}/admin/update-role-team`,
} as const;

export { ORGANIZATION_API_PATHS, USERS_API_PATHS };
