import { QueryClient } from '@tanstack/react-query';
import { useFormContext, ValidationValueMessage } from 'react-hook-form';
import { DynamicFieldData } from '../../types/dynamic-control-types';
import DropdownInput from './DropdownInput';
import Label from './Label';
import TextInput from './TextInput';
import PasswordInput from './PasswordInput';
import { Info } from 'phosphor-react';
import Message from './Message';
import { checkUserExist } from '../../features/auth/helpers/register';
import {
  LOWERCASE_CHARACTER_REGEX_PATTERN,
  LEADING_AND_TRAILING_SPACES_PATTERN,
  SINGLE_DIGIT_REGEX_PATTERN,
  SPECIAL_CHARACTER_REGEX_PATTERN,
  UPPERCASE_CHARACTER_REGEX_PATTERN,
} from '../../features/auth/constants';
import { useAppDispatch, useReduxAuthState } from '../../hooks';
import { setErrorId } from '../../features/auth/slices/registration.slice';
import PhoneNumberInput from './PhoneInput';
import RangeInput from '../../features/auth/components/register/common/RangeInput';
import { useStrapiDataHelper } from '../../hooks/useStrapiData';
import clsx from 'clsx';
import PasswordRequirements from './PasswordRequirements';

const queryClient = new QueryClient();

type DynamicControlProps = {
  className?: string;
  hasError?: boolean;
  disabled?: boolean;
};

export const DynamicControl = ({
  label,
  type,
  slug,
  min,
  max,
  options = [],
  config = {},
  primaryInfo,
  className,
  placeholder,
  hasError,
  disabled = false,
}: DynamicFieldData & DynamicControlProps) => {
  // TODO: See if there is a better approach
  const auth = useReduxAuthState();

  const data: any = useStrapiDataHelper();
  const strapiErrorData = data?.errormessage?.data.attributes;
  const {
    passwordUpper,
    passwordLower,
    passwordSpecialCharacter,
    passwordNumber,
    passwordSpace,
    confirmPassword,
    emailAlreadyExists,
    activeLearnersLimit,
  } = strapiErrorData;
  const { register, watch, getValues } = useFormContext();
  // Password and Confirm Password Validation
  const dispatch = useAppDispatch();
  const password = watch('password');
  const validated = (value: string) => {
    if (value !== password && value !== '') {
      return confirmPassword;
    }
  };

  const companyDetailsActiveLearnersValidate = (value: number) => {
    const values = getValues();
    const organizationSize = parseInt(values['companySize']);
    if (organizationSize) {
      if (value > organizationSize) {
        return `${activeLearnersLimit} ${organizationSize}`;
      }
    }
  };

  if (slug === 'companySize') {
    config = { ...config, deps: ['activeLearners'] };
  }

  if (slug === 'activeLearners') {
    config = { ...config, validate: companyDetailsActiveLearnersValidate };
  }

  const _emailValidate = async (value: string) => {
    try {
      // If the user is authenticated
      // and the user email matches the email in the
      // registration form then we need not
      // validate with the backend
      if (auth.isAuthenticated) {
        const userEmail = auth.user.email;
        if (userEmail.trim() === value.trim()) {
          return;
        }
      }

      const response = await queryClient.fetchQuery({
        queryFn: async () => {
          return await checkUserExist(value);
        },
        queryKey: ['user_exists', value],
        staleTime: 60000,
      });
      if (response.userExistsAlready) {
        const id = 'USER_ALREADY_EXIST';
        dispatch(setErrorId(id));
        return emailAlreadyExists;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const pattern = config.pattern as ValidationValueMessage<RegExp>;
  // TODO ignore until the type issue is fixed
  if (pattern) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config = {
      ...config,
      pattern: pattern.value
        ? {
            value: new RegExp(pattern.value),
            message: pattern.message,
          }
        : undefined,
    };
  }

  function passwordRegexvalidate(value: string) {
    if (!value.match(LOWERCASE_CHARACTER_REGEX_PATTERN)) {
      return passwordLower;
    } else if (!value.match(SPECIAL_CHARACTER_REGEX_PATTERN)) {
      return passwordSpecialCharacter;
    } else if (!value.match(UPPERCASE_CHARACTER_REGEX_PATTERN)) {
      return passwordUpper;
    } else if (!value.match(SINGLE_DIGIT_REGEX_PATTERN)) {
      return passwordNumber;
    } else if (!value.match(LEADING_AND_TRAILING_SPACES_PATTERN)) {
      return passwordSpace;
    }
  }
  if (type === 'password' && slug === 'password') {
    config = { ...config, validate: passwordRegexvalidate, deps: ['confirmPassword'] };
  }
  if (type === 'password' && slug === 'confirmPassword') {
    config = { ...config, validate: validated };
  }
  if (
    (type === 'email' && slug === 'personalEmail') ||
    (type === 'email' && slug === 'corporateEmail')
  ) {
    config = {
      ...config,
      validate: _emailValidate,
    };
  }
  switch (type) {
    case 'text':
    case 'number':
    case 'email':
      return (
        <div className="flex flex-col gap-1">
          <Label htmlFor={slug} label={label} />
          <TextInput
            id={slug}
            type={type}
            className={clsx('', className)}
            hasError={hasError}
            disabled={disabled}
            placeholder={placeholder as string}
            {...register(slug, config)}
          />
          <Message icon={<Info size="12px" />} messages={primaryInfo} />
        </div>
      );
    case 'tel':
      return (
        <div className="flex flex-col gap-1">
          <Label htmlFor={slug} label={label} />
          <PhoneNumberInput
            id={slug}
            type={type}
            className={clsx('', className)}
            hasError={hasError}
            disabled={disabled}
            {...register(slug, config)}
          />
          <Message icon={<Info size="12px" />} messages={primaryInfo} />
        </div>
      );
    case 'password':
      return (
        <div className="flex flex-col gap-1">
          <Label htmlFor={slug} label={label} />
          <PasswordInput
            id={slug}
            type={type}
            className={clsx('rounded-md', className)}
            hasError={hasError}
            disabled={disabled}
            {...register(slug, config)}
          />
          {slug === 'password' && <PasswordRequirements />}
        </div>
      );
    case 'range':
      return (
        <div className="flex flex-col">
          <Label htmlFor={slug} label={label} className="mb-2 text-sm font-semibold" />
          <RangeInput
            className={clsx('w-full', className)}
            id={slug}
            type={type}
            min={min}
            max={max}
            disabled={disabled}
            {...register(slug, config)}
          />
          <Message icon={<Info size="12px" />} messages={primaryInfo} />
        </div>
      );

    case 'select': {
      return (
        <div className="flex flex-col gap-1">
          <Label htmlFor={slug} label={label} />
          <DropdownInput
            className={clsx('', className)}
            type={type}
            options={options}
            hasError={hasError}
            disabled={disabled}
            {...register(slug, config)}
          />
        </div>
      );
    }
    default:
      return <input type="text" />;
  }
};
