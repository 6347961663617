import { useInfiniteQuery } from '@tanstack/react-query';
import { getTags } from '../api';
import { TAGS_DROPDOWN_SIZE, STALE_TIME } from '../constants/courses-management';
import { getTagsQueryFilterType } from '../types';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';

const useGetAllTagsQuery = (filters: getTagsQueryFilterType) => {
  const { search, language = DEFAULT_PREFERRED_LANGUAGE } = filters;
  const query = useInfiniteQuery({
    queryKey: [{ scope: 'get-all-tags', search, language }],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getTags({
        limit: TAGS_DROPDOWN_SIZE,
        page: pageParam,
        search: search,
        language: language,
      });
      const { count, rows } = response;
      const results = rows.map((tag) => ({ label: tag.name, value: tag.name }));
      return { results, total: count };
    },
    getNextPageParam: (lastPage, pages) => {
      if (pages.length * TAGS_DROPDOWN_SIZE < lastPage.total) {
        return pages.length + 1;
      }
      return undefined;
    },
    staleTime: STALE_TIME,
  });

  return query;
};

export default useGetAllTagsQuery;
