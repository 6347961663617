import { useQuery } from '@tanstack/react-query';
import { getSimilarCourses } from '../api/courses';
interface Props {
  courseId: string | undefined;
  enabled?: boolean;
}

const useSimilarCoursesQuery = ({ courseId, enabled = true }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery(
      [
        {
          courseId,
          scope: 'singleCourse',
          item: 'similarCourse',
        },
      ],
      async () => {
        if (courseId) {
          return await getSimilarCourses(courseId);
        } else {
          return null;
        }
      },
      {
        enabled: courseId != undefined && enabled,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch };
};

export default useSimilarCoursesQuery;
