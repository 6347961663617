type SetPasswordHeaderProps = {
  heading: string;
  subHeading: string;
};

const SetPasswordHeader = ({ heading, subHeading }: SetPasswordHeaderProps) => {
  return (
    <div className="flex flex-col items-center gap-1">
      <h2 className="text-2xl font-extrabold leading-[35px] text-white">{heading}</h2>
      <p className="text-center text-sm leading-[20px] text-zinc-200">{subHeading}</p>
    </div>
  );
};

export default SetPasswordHeader;
