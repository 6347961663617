import { CourseModule } from './types/moduleTypes';
import 'react-circular-progressbar/dist/styles.css';
import { COMPLETED, IN_PROGRESS } from './constants/constants';
import { BadgeComponent } from '../../../../../../components/Badge';

import { NoDataDisplay } from '../../../../../../components/DataTables';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { calcTimeString, generateCDNMediaAssetURLWithKey } from '../../../../../shared/helpers';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { setModulesCount } from '../../slice/SingleCourse.slice';
import { RootState } from '../../../../../../store';
import { useEffect } from 'react';
import { MODULE_KEYS } from '../../../../../course-management/constants';
import docUrl from './assets/documentIllustration.svg';
import quizUrl from './assets/quizIllustration.svg';
import nowViewing from './assets/nowViewing.svg';
import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';

type Props = {
  moduleClickHandler: (item: CourseModule, index: number) => void;
  modulesData: CourseModule[];
};

const ModulesComponent = ({ moduleClickHandler, modulesData }: Props) => {
  const appDispatch = useAppDispatch();
  const singleCourseSelector = (state: RootState) => state.singleCourse;
  const { currentModuleIndex } = useAppSelector(singleCourseSelector);
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;

  // Store modulesCount in store
  useEffect(() => {
    if (modulesData) {
      appDispatch(setModulesCount(modulesData.length));
    }
  }, [modulesData]);

  // API Error
  // No data
  if (!modulesData || modulesData.length == 0) {
    return (
      <div className="flex w-full flex-col items-center px-5">
        <NoDataDisplay message={strapiErrorMessage['MODULE_NOT_FOUND']} className="m-4" />
      </div>
    );
  }

  const pictureUrl = (type: string, thumbnailUrl: string) => {
    if (type === MODULE_KEYS.ASSESSMENT) {
      return quizUrl;
    } else if (type === MODULE_KEYS.DOCUMENT) {
      return docUrl;
    } else {
      return generateCDNMediaAssetURLWithKey({ key: thumbnailUrl });
    }
  };
  // strapi courses content
  const { moduleDetails, singleModuleCommon } = useStrapiCoursesData();
  const { completedStatus, inProgressStatus, moduleLabel, minsLabel, hoursLabel } = moduleDetails;

  // Sort Module list based on order
  modulesData.sort((a: CourseModule, b: CourseModule) => a.order - b.order);
  return (
    <div className="flex flex-col">
      {modulesData.map((item: CourseModule, index: number) => {
        return (
          <div key={item.id} className="relative h-full w-full">
            {/* Overlay to make module inaccessible */}
            {!item.isAccessible && item.status !== COMPLETED && (
              <div
                className={`absolute z-[1] flex h-full w-full cursor-not-allowed
              ${index == 0 ? 'rounded-t-lg' : ''} 
              ${index == modulesData.length - 1 ? 'rounded-b-lg' : ''}
               flex-col  items-center justify-center bg-black/50 `}
              ></div>
            )}
            <div
              className={`flex h-max w-full cursor-pointer flex-col border-b  border-black transition-all duration-200 ${
                index == currentModuleIndex ? 'bg-zinc-900' : 'bg-component-bg-dark'
              } p-3 
              ${index == 0 ? 'rounded-t-lg' : ''} 
              ${index == modulesData.length - 1 ? 'rounded-b-lg' : ''}`}
              onClick={() => moduleClickHandler(item, index)}
            >
              {index == currentModuleIndex && (
                <div className="mb-2 flex flex-row items-center">
                  <img src={nowViewing} />
                  <span className="mx-2  text-xs font-bold text-base-bright-green">
                    {singleModuleCommon.nowViewingLabel}
                  </span>
                </div>
              )}
              <div className="my-1 flex sm:hidden">
                {item.status && item.status === IN_PROGRESS && (
                  <BadgeComponent
                    label={inProgressStatus}
                    className="bg-base-creme py-0.5 px-2 text-xs font-extrabold text-zinc-700"
                  />
                )}
                {item.status && item.status === COMPLETED && (
                  <BadgeComponent
                    label={completedStatus}
                    className="bg-base-green py-0.5 px-2 text-xs font-extrabold text-white"
                  />
                )}
              </div>

              <div className="flex flex-row sm:gap-1 ">
                {/* Image */}
                <div className="flex aspect-[3/2] flex-1 shrink-0 flex-col items-center justify-center rounded-lg">
                  <img
                    src={pictureUrl(item.type, item.thumbnail)}
                    alt={item.type}
                    className="aspect-[3/2] w-full rounded-lg object-cover "
                  />
                </div>
                {/* Module Details */}
                <div className="flex flex-[2] flex-row sm:flex-[3] ">
                  <div className="ml-5 flex w-full flex-col">
                    <div className="flex flex-col justify-between sm:flex-row sm:gap-1 ">
                      <p className="flex-nowrap self-start whitespace-nowrap text-zinc-500">
                        {moduleLabel} {item.order}
                      </p>
                      <div className="flex flex-row gap-1 sm:items-center">
                        {item.duration && (
                          <p className="flex-nowrap self-start whitespace-nowrap text-zinc-500">
                            {calcTimeString(parseInt(item.duration), hoursLabel, minsLabel)}
                          </p>
                        )}
                        {item.status && item.status === IN_PROGRESS && (
                          <BadgeComponent
                            label={inProgressStatus}
                            className="hidden bg-base-creme py-0.5 px-2 text-xs font-extrabold text-zinc-700 sm:flex"
                          />
                        )}
                        {item.status && item.status === COMPLETED && (
                          <BadgeComponent
                            label={completedStatus}
                            className="hidden bg-base-green py-0.5 px-2 text-xs font-extrabold text-white sm:flex"
                          />
                        )}
                      </div>
                    </div>
                    <p className="text-white">{item.name}</p>
                    <p className="py-1 text-zinc-500 line-clamp-2">{item.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ModulesComponent;
