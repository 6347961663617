import { API } from 'aws-amplify';
import axios from 'axios';
import qs from 'qs';

import { GET_MY_COURSES, REPORTS_API_PATHS } from '../constants';
import {
  CourseType,
  CustomerLeaderboard,
  CustomerLeaderboardFilters,
  MyCoursesFilter,
  OverallChurnRate,
  OverallChurnRateFilters,
  TopChurnRisk,
  TopChurnRiskFilters,
} from '../types';

import { AMPLIFY_USHG_API_ENDPOINT_NAME, GAMIFICATION_API_BASE_URL } from '../../../constants';
import { GET_RECOMMENDED_COURSES } from '../../courses/constants';

export const getRecommendedCourses = (type: string, offset?: number, limit?: number) => {
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, GET_RECOMMENDED_COURSES(type, limit, offset), {
    withCredentials: true,
  });
};

export const getBadges = () => {
  return axios.get(`${GAMIFICATION_API_BASE_URL}/badges`);
};

/**
 * Get my courses
 */
const getMyCourses = async (
  filters: MyCoursesFilter
): Promise<{
  results: CourseType[];
  total: number;
}> => {
  const queryString = qs.stringify(filters);
  const path = GET_MY_COURSES();
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Top Churn Risk
 * Personas Involved: USHG Admin
 */

const getTopChurnRisk = async (
  filters: Partial<TopChurnRiskFilters>
): Promise<{
  results: TopChurnRisk[];
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.HQ_ADMIN.PERFORMANCE_REPORT.TOP_CHURN_RISK;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Overall Churn Rate
 * Personas Involved: USHG Admin
 */

const getOverallChurnRate = async (
  filters: OverallChurnRateFilters
): Promise<{
  result: OverallChurnRate;
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.HQ_ADMIN.PERFORMANCE_REPORT.OVERALL_CHURN_RATE;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Customer Leaderboard
 * Personas Involved: USHG Admin
 */

const getCustomerLeaderboard = async (
  filters: Partial<CustomerLeaderboardFilters>
): Promise<{
  totalCount: number;
  results: CustomerLeaderboard[];
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.HQ_ADMIN.PERFORMANCE_REPORT.CUSTOMER_LEADER_BOARD;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

export { getMyCourses, getTopChurnRisk, getOverallChurnRate, getCustomerLeaderboard };
