import { gql } from '@apollo/client';

export const SettingFragment = gql`
  fragment SettingFragment on SettingEntityResponse {
    data {
      attributes {
        COMMON {
          ... on ComponentSettingToastMessages {
            subscriptionCancelledSuccess
            subscriptionCancelledFailed
            updatedPaymentSuccess
            updatedPaymentFailed
            profilePictureUploadFailed
            trialPeriodCancelledSuccess
            trialPeriodCancelledFailed
            updatePasswordSuccessTitle
            updatePasswordSuccessMessage
            updatePasswordFailedTitle
            updatePasswordFailedMessage
            reactivatePlanSuccess
            reactivatePlanFailed
            renewPlanSuccess
            renewPlanFailed
            updateNotificationPreferenceSuccess
            updateNotificationPreferenceFailed
            profileFetchError
          }
          ... on ComponentSettingTopNav {
            commonDescription
            enterpriseAdminDescription
            title
          }
          ... on ComponentSettingSettingsTab {
            passwordTab
            billingTab
            profileTab
            notificationTab
            securityTab
            organizationTab
            platformTab
          }
          ... on ComponentSettingPassword {
            title
            description
            currentPasswordLabel
            newPasswordLabel
            confirmPasswordLabel
            passwordInfo
            updatePasswordButton
            updatePasswordRequestButton
            resetButton
          }
          ... on ComponentSettingBilling {
            CanelSubscription {
              description
              title
              cancelButton
              cancelSubscriptionButton
              checkBoxLabel
              ifOtherReasonLabel
              reasonRequired
              checkBoxRequired
              ifOtherReasonPlaceholder
            }
            CancelSubscriptionReasons {
              label
              value
            }
            billingHistory {
              billingDateTableHeader
              statusTableHeader
              amountTableHeader
              paymentDueTableHeader
              usersTableHeader
              invoiceTableHeader
              downloadButton
              exportButton
              title
              searchPlaceholder
              paidStatusLabel
              pendingStatusLabel
              overDueStatusLabel
              emptyMessage
            }
            currentPlan {
              title
              subscriptionInfoText
              changePlanButton
              currentPlanLabel
              manageActiveLicensesButton
              cancelButton
              renewSubscriptionText
              reactivateButtonText
              renewButtonText
              reactivateSubscriptionText
              trialTitle
              trialInfoText
              trialButtonText
              monthlyTenure
              yearlyTenure
            }
            changePlan {
              toastSuccessTitle
              toastSuccessDescription
              toastFailureTitle
              toastFailureDescription
              displayInfoIndividualUserTitle
              displayInfoEnterpriseAdminUserTitle
              warningMessage
              switchToMonthly
              switchToYearly
              monthlyCurrentPlan
              yearlyCurrentPlan
              displayInfoTeamUserTitle
              title
            }
            manageActiveLicence {
              title
              activeLicence
              totalLicence
              addMoreLicence
              warningMessage
              onErrorMessage
              onSuccessMessage
              cancelButtonText
              submitButtonText
              inputFieldError
              inputFieldRangeError
              manageActiveLicensesPlaceholder
            }
            paymentMethod {
              title
              updatePaymentButton
              stripeLogo {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
          ... on ComponentSettingsSecurityMfa {
            heading
            description
            showIsNewFeature
            smsMFAInstructions
            editInProfileBtnLabel
            disableMFABtnLabel
            enableMFABtnLabel
            mfaConfigUpdateSuccessToastMessage
            mfaConfigUpdateErrorToastMessage
            mfaConfigUpdateStartToastMessage
            enableMFASinceIsMandatedAlert
            cannotDisableMFASinceIsMandatedAlert
            EnableMFADialog {
              heading
              description
              smsMFAInstructions
              cancelBtnLabel
              enableMFABtnLabel
            }
            VerifyOrAddPhoneDialog {
              heading
              description
              addPhoneInProfileDescription
              verifyPhoneInProfileDescription
              cancelBtnLabel
              verifyNumberBtnLabel
              addNumberBtnLabel
            }
            DisableMFADialog {
              heading
              description
              body
              cancelBtnLabel
              disableMFABtnLabel
            }
          }
          ... on ComponentOnBoardingProfile {
            title
            description
            form {
              firstNameLabel
              lastNameLabel
              emailLabel
              emailPlaceholder
              phoneNumberLabel
              phoneNumberPlaceHolder
              jobTitleLabel
              departmentLabel
              firstNameRequired
              firstNameMinValue
              firstNameMinError
              firstNameMaxValue
              firstNameMaxError
              firstNameTypeError
              lastNameRequired
              lastNameMinValue
              lastNameMinError
              lastNameMaxValue
              lastNameMaxError
              lastNameTypeError
              emailRequired
              validEmail
              phoneNumberRequired
              validPhoneNumber
              jobTitleRequired
              departmentRequired
              verifyPhoneLabel
              phoneNotAvailableToastMessage
              updatePhoneBeforeVerifyToastMessage
              phoneVerifiedLabel
              languageLabel
            }
            PhoneVerificationDialog {
              title
              description
              codeRequiredErrorMessage
              verificationSuccessToastMessage
              confirmVerificationBtnLabel
              cancelBtnLabel
              somethingWentWrongErrorMessage
            }
            PhoneChangeConfirmDialog {
              title
              description
              confirmBtnLabel
              cancelBtnLabel
            }
            avatarTitle
            avatarUploadInstruction
            uploadAvatarButton
            removeAvatarButton
            saveButton
            invalidPhoneForVerificationToastMessage
            initPhoneVerifySomethingWentWrongMessage
          }
        }
        ENTERPRISE_ADMIN {
          ... on ComponentSettingOrgMfaMandate {
            heading
            description
            enableLabel
            disableLabel
            showIsNewFeature
            newFeatureLabel
            content
            orgMFAUpdateSuccessToast
            orgMFAUpdateFailureToast
            orgMFAConfigUpdateInProgressToast
            getOrgMFAConfigErrorMessage
            EnableMFAMandateDialog {
              heading
              description
              confirmLabel
              cancelLabel
            }
            DisableMFAMandateDialog {
              heading
              description
              confirmLabel
              cancelLabel
            }
          }
        }
        HQ_ADMIN {
          ... on ComponentSettingsPlatformMainteMode {
            title
            description
            showIsNewFeature
            maintenanceModeSwitchAriaLabel
            maintenanceModeFetchError
            EnableMaintenanceModeDialog {
              heading
              description
              primaryActionLabel
              secondaryActionLabel
            }
            DisableMaintenanceModeDialog {
              heading
              description
              primaryActionLabel
              secondaryActionLabel
            }
            mainteModeUpdateInProgressToast
            mainteModeUpdateSuccessToast
            mainteModeUpdateErrorToast
          }
          ... on ComponentSettingsPlatformAnnouncement {
            title
            description
            showIsNewFeature
            addAnnouncementBtnLabel
            updateBtnLabel
            removeBtnLabel
            editorPlaceholder
            announcementCreateSuccessToast
            announcementUpdateSuccessToast
            announcementDeleteSuccessToast
            announcementCreateErrorToast
            announcementDeleteErrorToast
            announcementUpdateErrorToast
            announcementFetchErrorMessage
            announcementAlreadyDeletedErrorMessage
          }
        }
      }
    }
  }
`;
