import { Modal } from 'react-daisyui';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { RootState } from '../../../../../store';
import { toggleInviteFriendModal } from '../../../slices/inviteFriend.slice';
import { Copy, EnvelopeSimple } from 'phosphor-react';
import toast from 'react-hot-toast';
import { Toast } from '../../../../../components/Toast';
import { SubmitHandler, useForm } from 'react-hook-form';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
import { useMemo, useState } from 'react';
import { inviteFriend } from '../../../api/invite';
import { BasicSpinner } from '../../../../../components/Spinners';
import { useStrapiSharedData } from '../../../hooks/useStrapiSharedData';
import clsx from 'clsx';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const modalState = (state: RootState) => state.inviteFriendModalState;

// Form Schema Starts

type GenerateInviteFriendFormSchemaParams = {
  emailRequiredErrorMessage: string;
  invalidEmailErrorMessage: string;
};

const generateInviteFriendFormSchema = (params: GenerateInviteFriendFormSchemaParams) => {
  const { emailRequiredErrorMessage, invalidEmailErrorMessage } = params;

  const schema = yup.object({
    inviteToEmailId: yup
      .string()
      .trim()
      .email(invalidEmailErrorMessage)
      .required(emailRequiredErrorMessage),
  });

  return schema;
};

type InviteFriendFormData = yup.InferType<ReturnType<typeof generateInviteFriendFormSchema>>;

// Form Schema Ends

function InviteFriendModal() {
  const dispatch = useAppDispatch();
  const cancelModal = () => {
    dispatch(toggleInviteFriendModal());
    reset();
  };

  // Strapi

  const {
    inviteFriendModal: {
      sendEmailLabel,
      copyLinkLabel,
      title,
      description,
      cancelButton,
      sendInviteButton,
      image1,
      image2,
      image3,
      inviteSentSuccessfully,
      inviteSentFailure,
      linkCopiedSuccessfully,
      sendEmailPlaceholder,
      invalidEmailErrorMessage,
      emailRequiredErrorMessage,
    },
  } = useStrapiSharedData();
  const friend1 = image1.data.attributes.url;
  const friend2 = image2.data.attributes.url;
  const friend3 = image3.data.attributes.url;

  // Strapi

  const formSchema = useMemo(() => {
    return generateInviteFriendFormSchema({
      emailRequiredErrorMessage,
      invalidEmailErrorMessage,
    });
  }, [emailRequiredErrorMessage, invalidEmailErrorMessage]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InviteFriendFormData>({
    defaultValues: {
      inviteToEmailId: '',
    },
    resolver: yupResolver(formSchema),
  });

  const [isLoading, setIsLoading] = useState(false);

  const formSubmit: SubmitHandler<InviteFriendFormData> = (data) => {
    const payload = {
      toEmailId: data.inviteToEmailId,
    };
    setIsLoading(true);
    inviteFriend(payload)
      .then((data) => {
        if (data) {
          toast.custom(
            (t) => <Toast variant="success" Title={inviteSentSuccessfully} toastInstance={t} />,
            { id: 'INVITE_FRIEND_TOAST_' + Math.floor(Math.random() * 3000).toString() }
          );
          setIsLoading(false);
          cancelModal();
        }
      })
      .catch(() => {
        toast.custom((t) => <Toast variant="error" Title={inviteSentFailure} toastInstance={t} />, {
          id: 'INVITE_FRIEND_TOAST_' + Math.floor(Math.random() * 3000).toString(),
        });
        setIsLoading(false);
        cancelModal();
      });
  };
  const { isModalOpen } = useAppSelector(modalState);
  const link = process.env.REACT_APP_USHG_UI_BASE_URL + 'invite';
  const copyLink = () => {
    navigator.clipboard.writeText(link);
    toast.custom(
      (t) => <Toast variant="success" Title={linkCopiedSuccessfully} toastInstance={t} />,
      {
        id: 'COPY_LINK_TOAST_' + Math.floor(Math.random() * 3000).toString(),
      }
    );
  };

  const avatarGroupImageSources = [friend2, friend1, friend3];

  return isModalOpen ? (
    <div className="[&>*:nth-child(1)]:bg-black" tabIndex={isModalOpen ? 0 : -1}>
      <Modal
        className="w-[512px] rounded bg-zinc-900 p-0 shadow-xl"
        open={isModalOpen}
        aria-modal={isModalOpen ? 'true' : undefined}
        aria-hidden={!isModalOpen}
      >
        <Modal.Body>
          <div className="p-6">
            <div role="presentation" className="flex items-end justify-center -space-x-4">
              {avatarGroupImageSources.map((imageSource) => {
                return (
                  <img
                    key={imageSource}
                    className={clsx(
                      'aspect-square',
                      'first:h-[51px] first:w-[51px]',
                      'last:h-[51px] last:w-[51px]',
                      '[&:nth-child(2)]:z-[1] [&:nth-child(2)]:h-[59px] [&:nth-child(2)]:w-[59px]',
                      'rounded-full border-[1.5px] border-zinc-900 object-cover object-center'
                    )}
                    src={imageSource}
                    alt="Person avatar"
                  />
                );
              })}
            </div>
            <div className="mt-6 text-center">
              <div className="text-lg font-bold text-zinc-50">{title}</div>
              <div className="text-sm font-normal text-zinc-400">{description}</div>
            </div>
            <div className="ml-3.5 mt-6 w-full">
              <label
                className="mb-2 block text-sm font-medium normal-case tracking-wide text-zinc-400"
                htmlFor="grid-manager-email"
              >
                {copyLinkLabel}
              </label>
              <div className="flex flex-row">
                <input
                  className="block h-11 w-5/6 cursor-pointer appearance-none rounded-lg border border-zinc-700 bg-zinc-900 pl-3.5 text-white placeholder:text-sm placeholder:text-zinc-50"
                  id="copyLinkInput"
                  value={link}
                  readOnly
                />
                <Copy
                  onClick={copyLink}
                  size={32}
                  className="ml-4 cursor-pointer self-center text-zinc-700 active:text-zinc-50"
                />
              </div>
            </div>
          </div>
          <div className="mt-3 w-full border border-zinc-700"></div>
          <div className="px-10 pt-6">
            <div className="relative w-full">
              <label
                className="mb-2 block text-sm font-medium normal-case tracking-wide text-zinc-400"
                htmlFor="grid-manager-email"
              >
                {sendEmailLabel}
              </label>
              <EnvelopeSimple
                size={24}
                className="absolute top-[39px] left-[19px] cursor-pointer self-center text-zinc-700"
              />
              <input
                className="block h-11 w-full cursor-pointer appearance-none rounded-lg border border-zinc-700 bg-zinc-900 pl-12 text-white placeholder:text-sm placeholder:text-zinc-500"
                id="inviteEmailInput"
                placeholder={sendEmailPlaceholder}
                {...register('inviteToEmailId')}
              />
              <HookFormErrorMessage
                name="inviteToEmailId"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 px-10 pt-6 pb-10">
            <button
              onClick={cancelModal}
              className="h-11 w-full rounded-lg border border-zinc-700 bg-zinc-900 text-white"
            >
              {cancelButton}
            </button>
            <button
              type="submit"
              disabled={isLoading}
              onClick={handleSubmit(formSubmit)}
              className="h-11 w-full rounded-lg border border-base-brand bg-base-brand text-white disabled:bg-base-brand disabled:text-white"
            >
              {isLoading ? (
                <div className="flex items-center justify-center gap-2">
                  {sendInviteButton}
                  <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
                </div>
              ) : (
                sendInviteButton
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  ) : null;
}

export default InviteFriendModal;
