interface Props {
  amount: number;
}

const AmountCell = ({ amount }: Props) => {
  return (
    <span className="inline-block whitespace-nowrap lowercase first-letter:uppercase">
      {amount ? '$' + amount : '-'}
    </span>
  );
};

export default AmountCell;
