import { useQuery } from '@tanstack/react-query';
import { getHQAdminCourses } from '../api/courses';
import { STALE_TIME } from '../constants/USHGAdmin';
import { PartialUSHGHQAdminCoursesFilter } from '../types/interface';

interface Props {
  filters: PartialUSHGHQAdminCoursesFilter;
}

const useHQAdminCourseQuery = ({ filters }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery(
      [
        {
          scope: 'courses',
          item: 'courses',
          filters: filters,
        },
      ],
      async () => {
        return getHQAdminCourses(filters);
      },
      {
        keepPreviousData: true,
        staleTime: STALE_TIME,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch };
};

export default useHQAdminCourseQuery;
