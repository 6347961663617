import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import RegisterSuccessIllustration from '../RegisterIllustration/RegisterSuccessIllustration';
import {
  LOCAL_STORAGE_USHG_HQ_ENTERPRISE_INVITE,
  LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY,
} from '../../../constants';
import { signUpUser } from '../../../helpers/register';
import { appendCountryCodeToPhoneNumber } from '../../../../../utils';
import { BasicSpinner } from '../../../../../components/Spinners';
import UserRegistrationFailure, { UserRegistrationFailureType } from './UserRegistrationFailure';
import UserRegistrationSuccess from './UserRegistrationSuccess';
import axios from 'axios';
import { USHGAPIError } from '../../../../../types';
import getRegistrationData from '../../../helpers/localStorageRegistrationData';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { useReduxAuthState } from '../../../../../hooks';
import { getCurrentAuthenticatedUser } from '../../../utils';
import { useNavigate } from 'react-router-dom';

type UserRegistrationProps = {
  className?: string;
};

const UserRegistration = ({ className }: UserRegistrationProps) => {
  const [errorType, setErrorType] = useState<UserRegistrationFailureType | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const requestStartedRef = useRef<boolean>(false);
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const auth = useReduxAuthState();

  const sessionId = queryParams.get('session_id');
  const data = useStrapiDataHelper();
  const { RegistrationFailed, registeringYourAccountLabel } = data.registration.data.attributes;
  useEffect(() => {
    const registerAccount = async () => {
      if (sessionId === null || sessionId === undefined) {
        setErrorType('SEARCH_PARAMS_MISSING');
        setIsLoading(false);
        return;
      }

      try {
        let registrationPayload = getRegistrationData();
        if (registrationPayload && sessionId) {
          registrationPayload.sessionId = sessionId;
          requestStartedRef.current = true;
          registrationPayload = {
            ...registrationPayload,
            basicDetails: {
              ...registrationPayload.basicDetails,
              mobile: appendCountryCodeToPhoneNumber(registrationPayload?.basicDetails?.mobile),
            },
          };
          await signUpUser(registrationPayload);

          // We want to do these steps only if the user is authenticated
          if (auth.isAuthenticated) {
            // refresh the cognito user pool to update the authenticated user information
            await getCurrentAuthenticatedUser({ bypassCache: true });

            // based on that send the user to the appropriate page
            navigate('/auth/register/progress');
          }
        } else {
          setErrorType('REGISTRATION_UNKNOWN_ERROR');
        }
      } catch (error) {
        // If axios error
        if (axios.isAxiosError(error)) {
          const data = error.response?.data;
          if (data) {
            const { errorCode } = data as USHGAPIError;
            switch (errorCode) {
              case 'ACCOUNT_ALREADY_EXIST': {
                setErrorType(errorCode);
                break;
              }
              default: {
                setErrorType('REGISTRATION_UNKNOWN_ERROR');
                break;
              }
            }
          } else {
            setErrorType('REGISTRATION_UNKNOWN_ERROR');
          }
        } else {
          setErrorType('REGISTRATION_UNKNOWN_ERROR');
        }
      } finally {
        setIsLoading(false);
        // remove registration data from localStorage
        localStorage.removeItem(LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY);
        localStorage.removeItem(LOCAL_STORAGE_USHG_HQ_ENTERPRISE_INVITE);
      }
    };
    if (!requestStartedRef.current) {
      registerAccount();
    }
  }, []);

  // Loading State
  if (isLoading) {
    return (
      <div className="flex flex-col items-center gap-5 pt-[10vh]">
        <div className="flex max-w-lg flex-col items-center gap-3">
          <BasicSpinner className="h-6 w-6 text-base-brand" />
          <span className="text-lg font-normal leading-[24px] text-zinc-50">
            {registeringYourAccountLabel}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-hidden">
      <RegisterSuccessIllustration />
      <div
        className={clsx(
          'absolute top-1/2 left-1/2 w-full -translate-x-1/2 -translate-y-1/2 p-4',
          className
        )}
      >
        {!isLoading && errorType && (
          <UserRegistrationFailure
            type={errorType}
            badgeText={RegistrationFailed.paymentBadgeTitle}
          />
        )}
        {!isLoading && !errorType && <UserRegistrationSuccess />}
      </div>
    </div>
  );
};

export default UserRegistration;
