export const DROP_FETCH_LIMIT = 10;

export const MAX_USER_ASSIGN = 5;

export const RECOMMENDED_COURSE_LIMIT = 5;

export const COURSE_SEARCH_DELAY = 2000;

export const RECOMMENDATION_TYPE: Record<string, string> = {
  PERSONAL: 'PERSONAL',
  TEAM: 'TEAM',
  ORGANIZATION: 'ORGANIZATION',
};

export const COURSES_SORT = {
  NAME: {
    ASCENDING: 'NAME_ASC',
    DESCENDING: 'NAME_DESC',
  },
  DURATION: {
    ASCENDING: 'DURATION_ASC',
    DESCENDING: 'DURATION_DESC',
  },
};

export const MEDIA_CHECKPOINT_INTERVAL = 60;

export const COURSES_DISPLAY_LIMIT = 3;

export const MORE_COURSES_DISPLAY_LIMIT = 4;
