import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PaginationFooter } from '../../../../../../../components/DataTables';
import { BasicSpinner } from '../../../../../../../components/Spinners';
import useDebounce from '../../../../../../../hooks/use-debounce';

import {
  MODULE_TABLE_DEFAULT_PAGE,
  MODULE_TABLE_FILTER_QUERY_PARAM,
  MODULE_TABLE_FILTER_SEARCH_DEBOUNCE_MS,
  MODULE_TABLE_MAX_PAGE_SIZE,
  MODULE_TABLE_MIN_PAGE_SIZE,
  MODULE_TABLE_PAGE_SIZE_INCREMENT,
  PartialUSHGAdminModuleTableFilter,
} from '../../../../../constants/course-module-table';

import ModuleTableMainHeader from './ModuleTableMainHeader';
import ModuleTableErrorState from './ModuleTableErrorState';
import ModuleTableEmptyState from './ModuleTableEmptyState';
import ModulesTable from './ModulesTable/ModulesTable';
import useUSHGAdminCourseModulesQuery from '../../../../../hooks/useUSHGAdminCourseModulesQuery';
import { useAppSelector } from '../../../../../../../hooks';
import { RootState } from '../../../../../../../store';
interface Props {
  className?: string;
}

const USHGAdminCourseModuleTableMain = ({ className }: Props) => {
  const courseId = parseInt(useParams().id as string);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hqSingleCourseStateSelector = (state: RootState) => state.hqSingleCourse;
  const { courseModuleSortKey, courseModuleSortOrder } = useAppSelector(
    hqSingleCourseStateSelector
  );
  // We would need whether the filter is applied
  // We would check the sort, status and filter to decide whether the filter is
  // Applied or not
  let isFilterApplied = false;

  // Parse the filters from the url query parameters
  const search = searchParams.get(MODULE_TABLE_FILTER_QUERY_PARAM) ?? undefined;

  // let sort: string | null | undefined = searchParams.get(MODULE_TABLE_SORT_QUERY_PARAM);

  // If a filter string is present set isFilterApplied to true
  isFilterApplied = search !== undefined;

  // Current Page
  const [page, setPage] = useState(MODULE_TABLE_DEFAULT_PAGE);
  // Page Size
  const [pageSize, setPageSize] = useState(MODULE_TABLE_MIN_PAGE_SIZE);

  // Debounce the fast changing search filter
  // so we would make the request only when there is some considerable pause
  const debouncedFilter = useDebounce(search, MODULE_TABLE_FILTER_SEARCH_DEBOUNCE_MS);

  useEffect(() => {
    // Whenever the status select, sort or the search query changes
    // Then reset the page to the start of the page
    setPage(MODULE_TABLE_DEFAULT_PAGE);
  }, [debouncedFilter]);

  // Build the feedback filters from the available filters

  const filters: PartialUSHGAdminModuleTableFilter = useMemo(() => {
    return {
      search: debouncedFilter,
      start: (page - 1) * pageSize,
      size: pageSize,
      sortOrder: courseModuleSortOrder,
      sortKey: courseModuleSortKey ?? 'updatedOn',
    };
  }, [courseModuleSortKey, courseModuleSortOrder, debouncedFilter, page, pageSize]);
  if (!courseModuleSortOrder && !courseModuleSortKey) {
    delete filters.sortOrder;
    delete filters.sortKey;
  }
  const module = useUSHGAdminCourseModulesQuery({ filters, courseId });

  // Display loader during initial loading and if we are fetching when there are no items.
  const displayLoader =
    (module.isLoading && !module.data) || (module.isFetching && module.data?.totalCount === 0);
  const isEmptyState = module.data && module.data.totalCount == 0;

  return (
    <div
      className={clsx(
        'flex flex-col items-start gap-4 rounded-sm bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)] md:p-5 lg:p-6',
        className
      )}
    >
      <>
        {/* Display only if there is modules */}
        <ModuleTableMainHeader
          filter={search}
          isFilterVisible={module.data ? module.data.totalCount !== 0 : false}
        />
        {/* Is Loading and We donot have data or Is Fetching and we have data as empty array */}
        {displayLoader && (
          <div className="flex min-h-[250px] w-full items-center justify-center py-4">
            <BasicSpinner className="text-white" />
          </div>
        )}
        {/* We have data */}
        {module.data && module.data.totalCount > 0 && (
          <div
            className={clsx(
              'relative flex w-full flex-col gap-4',
              module.isPreviousData && module.isFetching && 'pointer-events-none opacity-70'
            )}
          >
            {module.isPreviousData && module.isFetching && (
              <div className="flex w-full justify-center">
                <BasicSpinner className="text-center text-white" />
              </div>
            )}

            <div className="relative w-full overflow-y-scroll">
              {module.isError ? (
                <ModuleTableErrorState
                  isRefetching={module.isRefetching}
                  refetch={module.refetch}
                />
              ) : (
                <ModulesTable data={module.data.results} />
              )}
            </div>

            <PaginationFooter
              setPage={setPage}
              activePage={page}
              totalItems={module.data.totalCount}
              pageSize={pageSize}
              setPageSize={setPageSize}
              maxPageSize={MODULE_TABLE_MAX_PAGE_SIZE}
              minPageSize={MODULE_TABLE_MIN_PAGE_SIZE}
              pageSizeIncrement={MODULE_TABLE_PAGE_SIZE_INCREMENT}
            />
          </div>
        )}

        {/* Empty State */}
        {isEmptyState && !displayLoader && (
          <div className="flex min-h-[250px] w-full items-center justify-center">
            <ModuleTableEmptyState isFilterApplied={isFilterApplied} />
          </div>
        )}

        {module.isError && !module.data && (
          <div className="flex min-h-[250px] w-full items-center justify-center">
            <ModuleTableErrorState isRefetching={module.isRefetching} refetch={module.refetch} />
          </div>
        )}
      </>
    </div>
  );
};

export default USHGAdminCourseModuleTableMain;
