import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { MFAMethod } from '../../../constants';
import { getCurrentAuthenticatedUser } from '../../auth/utils';
import { updateMFAPreference } from '../../settings/api';
import { useStrapiSettingsData } from '../../settings/hooks/useStrapiSettingsData';

type MutationParams = { mfaMethod: keyof typeof MFAMethod };

const useSetUserPreferredMFAMutation = () => {
  // strapi
  const { mfa: mfaSettingsCMS } = useStrapiSettingsData();
  // strapi

  const mutation = useMutation({
    mutationFn: async (params: MutationParams) => {
      const { mfaMethod } = params;
      const response = await updateMFAPreference(mfaMethod);
      return response;
    },
    onMutate: () => {
      const toastMessage = mfaSettingsCMS.mfaConfigUpdateStartToastMessage;
      toast.custom((t) => <Toast toastInstance={t} variant="info" Title={toastMessage} />);
    },
    onSuccess: async () => {
      // After attribute is verified update the tokens
      try {
        // Will fetch new tokens after the MFA is updated
        await getCurrentAuthenticatedUser({ bypassCache: true });
      } catch (error) {
        // The profile has been updated but refreshing the cognito session failed
        console.error('Error updating the cognito user');
      }

      const toastMessage = mfaSettingsCMS.mfaConfigUpdateSuccessToastMessage;
      toast.custom((t) => <Toast toastInstance={t} variant="success" Title={toastMessage} />);
    },
    onError: async () => {
      const toastMessage = mfaSettingsCMS.mfaConfigUpdateErrorToastMessage;
      toast.custom((t) => <Toast toastInstance={t} variant="error" Title={toastMessage} />);
    },
  });

  return mutation;
};

export default useSetUserPreferredMFAMutation;
