import clsx from 'clsx';
import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterDropDown } from '../../../../components/DataTables';
import SearchInput from '../../../../components/SearchInput';
import { Option } from '../../../../components/Select';
import { useStrapiSharedData } from '../../../shared/hooks/useStrapiSharedData';
import {
  REQUESTS_FILTER_QUERY_PARAM,
  REQUESTS_SORT_QUERY_PARAM,
  REQUESTS_STATUS_QUERY_PARAM,
} from '../../constants';

interface Props {
  className?: string;
  filter: string | undefined;
  status: string | undefined;
  sort: string;
  sortLabel: string;
  statusLabel: string;
  isFilterApplied: boolean;
  statusSelectOptions: Option[];
  requestsSortOptions: Option[];
}

const RequestsMainHeader = (props: Props) => {
  const {
    className,
    filter,
    status,
    sort,
    isFilterApplied,
    statusSelectOptions,
    requestsSortOptions,
    sortLabel,
    statusLabel,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const handleQChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    searchParams.set(REQUESTS_FILTER_QUERY_PARAM, value);
    navigate('?' + searchParams.toString());
  };

  const handleStatusChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value;
    searchParams.set(REQUESTS_STATUS_QUERY_PARAM, value);
    navigate('?' + searchParams.toString());
  };

  const handleSortByChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value;
    searchParams.set(REQUESTS_SORT_QUERY_PARAM, value);
    navigate('?' + searchParams.toString());
  };

  const handleResetFiltersBtnClick = () => {
    // This will remove all the query params (i.e. how filters are set)
    navigate('.');
  };
  const {
    tableProps: { clearAllFiltersButton, searchPlaceHolder },
  } = useStrapiSharedData();

  return (
    <div
      className={clsx(
        'flex w-full flex-col justify-between gap-3 text-[14px] font-normal leading-[20px] text-zinc-100 md:flex-row md:gap-2',
        className
      )}
    >
      <div className="order-1 flex w-full flex-col gap-3 md:w-min md:flex-row md:gap-2">
        <SearchInput
          placeholder={searchPlaceHolder}
          containerClassName="min-w-[200px]"
          iconClassName="left-2"
          className="h-9 rounded-sm border border-zinc-900 bg-zinc-900 p-2 pl-9 text-[14px] font-normal leading-[20px] text-zinc-100 placeholder:text-zinc-500"
          value={filter ?? ''}
          onChange={handleQChange}
        />
        <FilterDropDown
          value={status ?? undefined}
          options={statusSelectOptions}
          onChange={handleStatusChange}
          selectItemLabel={statusLabel}
          aria-label="Requests status filter options"
        />
      </div>
      {isFilterApplied && (
        <div className="order-3 flex w-full grow items-center justify-center md:order-2 md:justify-start">
          <button
            className="h-min self-center justify-self-start rounded-[32px] bg-zinc-700 py-1.5 px-2.5 font-sans text-[10px] font-medium leading-[105%] text-zinc-200"
            onClick={handleResetFiltersBtnClick}
            // When there are no query params disable it
            // As we are setting the filters using the search params
          >
            {clearAllFiltersButton}
          </button>
        </div>
      )}
      <div className="order-2 flex items-center gap-2 md:order-3">
        <span className="self-center whitespace-nowrap text-[14px] font-normal leading-[20px] text-zinc-100">
          {sortLabel}
        </span>
        <FilterDropDown
          value={sort}
          aria-label="Requests sort options"
          options={requestsSortOptions}
          onChange={handleSortByChange}
          className="grow"
        />
      </div>
    </div>
  );
};

export default memo(RequestsMainHeader);
