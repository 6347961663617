const COURSES_BASE_PATH = '/courses';
const USERS_BASE_PATH = '/users';
const SPEAKER_API_PATH = '/speakers';
const SIMILAR_API_PATH = '/similar';
const MODULES_API_PATH = '/modules';
const MODULE_API_PATH = '/module';
const GET_COURSES_PATH = COURSES_BASE_PATH + '';

const GET_COURSES_DETAILS_PATH = (id: string) => {
  return `${COURSES_BASE_PATH}/${id}`;
};
const GET_COURSES_SPEAKERS_PATH = (id: string) => {
  return `${COURSES_BASE_PATH}/${id}${SPEAKER_API_PATH}`;
};

const GET_COURSE_MODULE_SPEAKERS_PATH = (id: string) => {
  return `${COURSES_BASE_PATH}${MODULE_API_PATH}/${id}${SPEAKER_API_PATH}`;
};

const GET_COURSES_SIMILAR_PATH = (id: string) => {
  return `${COURSES_BASE_PATH}/${id}${SIMILAR_API_PATH}`;
};

const GET_COURSES_MODULES_PATH = (id: string) => {
  return `${COURSES_BASE_PATH}/${id}${MODULES_API_PATH}`;
};

const GET_ASSESSMENT_QUESTIONS = (assessmentId: string) => {
  return `${COURSES_BASE_PATH}/assessment/${assessmentId}/question-details`;
};

const GET_COURSES_TAGS_FILTER_PATH = () => {
  return `${COURSES_BASE_PATH}/course-tags`;
};

const GET_COURSES_CATEGORIES_FILTER_PATH = () => {
  return `${COURSES_BASE_PATH}/course-categories`;
};

const GET_COURSES_SPEAKERS_FILTER_PATH = () => {
  return `${COURSES_BASE_PATH}/course-speakers`;
};
const GET_USER_FEEDBACK = `${USERS_BASE_PATH}/feedback`;

const GET_RECOMMENDED_COURSES = (type: string, limit?: number, offset?: number) => {
  let query = `?type=${type}`;
  if (limit) query += `&limit=${limit}`;
  if (offset !== undefined) query += `&offset=${offset}`;
  return `${COURSES_BASE_PATH}/recommended${query}`;
};

const GET_LEARNING_JOURNEY_RECOMMENDED_COURSES = (
  journeyId: string,
  start: number,
  size: number,
  excludeCourseStatuses?: string[]
) => {
  let query = `start=${start}&size=${size}`;
  if (excludeCourseStatuses) {
    excludeCourseStatuses.forEach((exclude) => (query += `&excludeCourseStatuses=${exclude}`));
  }
  return `${COURSES_BASE_PATH}/v1/learning-journeys/${journeyId}/courses?${query}`;
};

const GET_HQ_ADMIN_COURSES = `${COURSES_BASE_PATH}/hq/courses`;

const GET_TEAMS = `${COURSES_BASE_PATH}/v1/teams`;

const GET_USERS = `${COURSES_BASE_PATH}/v1/users`;

const SELF_ASSIGN = `${COURSES_BASE_PATH}/v1/assign/self`;

const USER_ASSIGN = `${COURSES_BASE_PATH}/v1/assign/user`;

const TEAM_ASSIGN = `${COURSES_BASE_PATH}/v1/assign/team`;

const SUBMIT_ANSWERS = `${COURSES_BASE_PATH}/assessment/submit`;

const MEDIA_CHECK_POINT_UPDATE = `${COURSES_BASE_PATH}/v1/module/media-checkpoint`;

const POTENTIAL_BADGES = (id: number) => `${COURSES_BASE_PATH}/${id}/badges`;

const UPDATE_MODULE_LAST_ACCESSED = (assessmentId: string) => {
  return `${COURSES_BASE_PATH}/module/${assessmentId}/last-accessed`;
};

const COMPLETE_MODULE = (moduleId: number) => {
  return `${COURSES_BASE_PATH}/module/${moduleId}/complete`;
};

export {
  COURSES_BASE_PATH,
  GET_COURSES_PATH,
  GET_COURSES_DETAILS_PATH,
  POTENTIAL_BADGES,
  GET_COURSES_SPEAKERS_PATH,
  GET_COURSES_SIMILAR_PATH,
  GET_COURSES_MODULES_PATH,
  GET_COURSES_SPEAKERS_FILTER_PATH,
  GET_COURSES_CATEGORIES_FILTER_PATH,
  GET_COURSES_TAGS_FILTER_PATH,
  GET_ASSESSMENT_QUESTIONS,
  UPDATE_MODULE_LAST_ACCESSED,
  GET_COURSE_MODULE_SPEAKERS_PATH,
  GET_HQ_ADMIN_COURSES,
  GET_TEAMS,
  GET_USERS,
  SELF_ASSIGN,
  USER_ASSIGN,
  TEAM_ASSIGN,
  SUBMIT_ANSWERS,
  COMPLETE_MODULE,
  GET_USER_FEEDBACK,
  MEDIA_CHECK_POINT_UPDATE,
  GET_RECOMMENDED_COURSES,
  GET_LEARNING_JOURNEY_RECOMMENDED_COURSES,
};
