import { useLanguage } from '../features/shared/context/languageContext';
import { useMemo } from 'react';
import { formatDuration } from 'date-fns';
import { getLocale } from './index';

interface DurationBadgeProps {
  durationInMin?: number;
  hasDuration?: boolean;
}

const DurationBadge = (props: DurationBadgeProps) => {
  const { language } = useLanguage();

  const { hasDuration = false, durationInMin = 0 } = props;

  return useMemo(() => {
    if (hasDuration) {
      const hours = Math.floor(durationInMin / 60);
      const minutes = durationInMin - hours * 60;
      const duration = formatDuration({ minutes, hours }, { locale: getLocale(language) });
      return <span>{duration}</span>;
    }
    return <span> </span>;
  }, [durationInMin, hasDuration, language]);
};

export default DurationBadge;
