import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { TeamManagerCell, CreatedOnCell, NumberCountCell, TeamNameCell } from './TableCell';
import { Team, TeamManager } from '../../types/interface';
import { TableDataRow } from '../../../../components/DataTables';
import { useNavigate } from 'react-router-dom';
import { BsLayoutThreeColumns } from 'react-icons/bs';
import TeamTableHeaderRow from './TeamTableHeader';
import { TeamTableDropDown } from './TeamTableDropdown';
import { useDispatch } from 'react-redux';
import { useStrapiTeamData } from '../../hooks/useStrapiTeamData';
import { setSelectedTeam } from '../../slices/team.slice';

interface Props {
  className?: string;
  data: Team[];
  isOrganizationView: boolean;
}

const TeamsTable = ({ className, data, isOrganizationView }: Props) => {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<Team>();
  const { teamTable } = useStrapiTeamData();
  const { teamManagerHeader, teamNameHeader, teamMembersCountHeader, createdDateHeader } =
    teamTable;
  const columns = [
    columnHelper.accessor('teamName', {
      header: `${teamNameHeader}`,
      cell: ({ row, getValue }) => <TeamNameCell teamName={getValue() as string} row={row} />,
      id: 'TEAM_NAME',
    }),
    columnHelper.accessor('teamManagers', {
      header: `${teamManagerHeader}`,
      cell: ({ getValue }) => <TeamManagerCell managers={getValue() as TeamManager[]} />,
    }),
    columnHelper.accessor('membersCount', {
      header: `${teamMembersCountHeader}`,
      cell: ({ getValue }) => <NumberCountCell number={getValue() as number} />,
      id: 'NO_OF_MEMBERS',
    }),
    columnHelper.accessor('createdOn', {
      header: `${createdDateHeader}`,
      cell: ({ getValue }) => <CreatedOnCell createdOn={getValue() as string} />,
      id: 'CREATED_ON',
    }),
    columnHelper.display({
      header: () => (isOrganizationView ? <></> : <BsLayoutThreeColumns />),
      cell: ({ row }) =>
        isOrganizationView ? (
          <></>
        ) : (
          <TeamTableDropDown
            row={row}
            teamName={row.original?.teamName}
            teamId={Number(row.original?.teamId)}
          />
        ),
      id: 'edit',
    }),
  ];
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableMultiSort: false,
    getSubRows: (row) => row?.subTeams?.map((sub) => ({ ...sub, isSubTeam: true })),
    state: {
      expanded: true,
    },
  });
  const dispatch = useDispatch();
  function rowClick(row: Row<Team>) {
    const id = row.original.teamId;
    const isSubTeam = row.original.isSubTeam;
    let hasSubTeam = false;
    if (row.subRows.length !== 0) {
      hasSubTeam = true;
    }
    dispatch(setSelectedTeam({ isSubTeam, hasSubTeam }));
    navigate(`/team/${id}`);
  }
  return (
    <table className={clsx('w-full', className)}>
      <thead className="w-full">
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TeamTableHeaderRow
                  isSortable={true}
                  allowMultiColSort={true}
                  header={header}
                  key={header.id}
                />
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <TableDataRow
            tdClassName={clsx('px-2 py-3', isOrganizationView && 'pointer-events-none')}
            className="cursor-pointer"
            row={row}
            key={row.original.teamId}
            onClick={() => rowClick(row)}
            isSubRow={row.original.isSubTeam}
          />
        ))}
      </tbody>
    </table>
  );
};

export default TeamsTable;
