import { useEffect, useState } from 'react';
interface Props {
  totalSteps: number;
  stepInterval: number;
  delayMS: number;
  onComplete: () => void;
}
const useTimer = (props: Props) => {
  const { totalSteps, stepInterval, delayMS, onComplete } = props;
  const [stepsTaken, setStepsTaken] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const newSteps = stepsTaken + 1;
      setStepsTaken(newSteps);
      if (newSteps == totalSteps) {
        clearInterval(intervalId);
        setTimeout(() => {
          onComplete();
        }, delayMS);
      }
    }, stepInterval);
    return () => clearInterval(intervalId);
  }, [delayMS, onComplete, stepInterval, stepsTaken, totalSteps]);
  return {
    percentageComplete: Math.floor((stepsTaken / totalSteps) * 100),
  };
};
export default useTimer;
