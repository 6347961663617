import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { EarnedBadgesResponse } from '../../features/badges/types/badges';
import ImageWithAcronymFallback from '../ImageWithAcronymFallback';

type BadgeCardProps = {
  className?: string;
  showBadgeName?: boolean;
  badgeImgClassName?: string;
  badge: EarnedBadgesResponse;
  badgeNameClassName?: string;
  badgeContainerClassName?: string;
  badgeAcronymLogoClassName?: string;
};

const BadgeCard = (props: BadgeCardProps) => {
  const {
    badge,
    className,
    showBadgeName,
    badgeImgClassName,
    badgeNameClassName,
    badgeContainerClassName,
    badgeAcronymLogoClassName,
  } = props;

  return (
    <div className={twMerge('flex w-max flex-col items-center gap-2', badgeContainerClassName)}>
      <div
        className={clsx(
          showBadgeName && 'border-0',
          `flex w-max flex-col items-center justify-center overflow-hidden rounded-full`,
          className
        )}
      >
        <ImageWithAcronymFallback
          alt={badge.badgeName}
          imageKey={badge.imageUrl}
          acronymWord={badge.badgeName ?? 'Badge'}
          acronymLogoClassName={twMerge(
            'h-[var(--badge-image-size,78px)] w-[var(--badge-image-size,78px)]',
            badgeAcronymLogoClassName
          )}
          className={twMerge(
            'aspect-square h-[var(--badge-image-size,78px)] w-[var(--badge-image-size,78px)] rounded-full object-scale-down',
            badgeImgClassName
          )}
        />
      </div>
      {showBadgeName && (
        <p
          className={twMerge(
            'max-w-[var(--badge-image-size,78px)] text-center text-xs font-medium text-zinc-200 line-clamp-2',
            badgeNameClassName
          )}
        >
          {badge.badgeName}
        </p>
      )}
    </div>
  );
};

export default BadgeCard;
