import { useEffect, useRef } from 'react';

interface Props<TValue> {
  value: TValue;
}

function useDelayRef<TValue>(props: Props<TValue>) {
  const { value } = props;

  const ref = useRef(value);

  const delayRef = useRef<TValue | null>(null);

  useEffect(() => {
    delayRef.current = ref.current;
  }, [value]);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return delayRef;
}

export default useDelayRef;
