import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import Dialog from '../../../../../../components/Dialog';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { queryClient } from '../../../../../../config/react-query';
import { useAppSelector } from '../../../../../../hooks';
import { RootState } from '../../../../../../store';
import SubscriptionInfo from '../../../../../teams/components/Modal/subscriptionInfo/subscriptionMain';
import { useStrapiTeamData } from '../../../../../teams/hooks/useStrapiTeamData';
import { ENTERPRISE_ADMIN_REQUEST_ACTION, REQUEST_STATUS } from '../../../../constants';
import useEnterpriseAdminRequestActionMutation from '../../../../hooks/useEnterpriseAdminRequestActionMutation';
import { useStrapiRequestData } from '../../../../hooks/useStrapiRequestData';
import {
  EnterpriseAdminEditTeamRequestData,
  EnterpriseAdminRequest,
  EnterpriseAdminRequestType,
  EnterpriseAdminTeamRequestData,
  EnterpriseAdminUserRequestData,
} from '../../../../types';
import { RequestActionDialogItem } from '../../../common';
import EditTeamRequestDialogContent from './EditTeamRequestDialogContent';
import TeamRequestDialogContent from './TeamRequestDialogContent';
import UserRequestDialogContent from './UserRequestDialogContent';
import RequestStatusCell from '../RequestsTable/TableCell/RequestStatusCell';
import { TRAIL_LIMIT } from '../../../../../../constants/subscription-plans';
import { getLocale } from '../../../../../../utils';
import { useLanguage } from '../../../../../shared/context/languageContext';

const subscriptionInfoData = (state: RootState) => state.subscription;

const getDisplayNameForRequestType = (request: EnterpriseAdminRequest, strapiData: any) => {
  const { requestType } = strapiData;
  const { addSubTeam, addMember, addTeam, editTeam } = requestType;

  switch (request.requestType.requestTypeDetails[0].name) {
    case 'NEW_MEMBER':
      return addMember;
    case 'NEW_TEAM': {
      if ((request.data as EnterpriseAdminTeamRequestData).isSubTeam) {
        return addSubTeam;
      } else {
        return addTeam;
      }
    }
    case 'EDIT_TEAM': {
      return editTeam;
    }
  }
};

const getAcceptButtonMessageForRequestType = (
  type: EnterpriseAdminRequestType,
  strapiData: any
) => {
  const { editTeamRequestModal, addMemberRequestModal, addTeamRequestModal } = strapiData;

  const { acceptButton: teamAcceptButton } = addTeamRequestModal;
  const { acceptButton: editTeamAcceptButton } = editTeamRequestModal;
  const { acceptButton: memberAcceptButton } = addMemberRequestModal;

  switch (type) {
    case 'NEW_MEMBER':
      return memberAcceptButton;
    case 'NEW_TEAM':
      return teamAcceptButton;
    case 'EDIT_TEAM':
      return editTeamAcceptButton;
  }
};

interface RequestDialogContentProps {
  type: EnterpriseAdminRequestType;
  request: EnterpriseAdminRequest;
}

// Map Request Type to Specific Request Content Component
const RequestDialogContent = (args: RequestDialogContentProps) => {
  const { request, type } = args;

  switch (type) {
    case 'NEW_MEMBER':
      return (
        <UserRequestDialogContent
          userRequestData={request.data as EnterpriseAdminUserRequestData}
        />
      );
    case 'NEW_TEAM': {
      return (
        <TeamRequestDialogContent
          teamRequestData={request.data as EnterpriseAdminTeamRequestData}
        />
      );
    }
    case 'EDIT_TEAM': {
      return (
        <EditTeamRequestDialogContent
          editTeamRequestData={request.data as EnterpriseAdminEditTeamRequestData}
        />
      );
    }
  }
};

interface Props {
  request: EnterpriseAdminRequest;
  setIsDialogOpen: (open: boolean) => void;
  showRejectActionDialogContent: (show: boolean) => void;
}

const RequestActionDialogContent = (props: Props) => {
  const { request, setIsDialogOpen, showRejectActionDialogContent } = props;
  // Strapi Data
  const strapiData = useStrapiRequestData();
  const { commonModal } = strapiData;
  const {
    raisedByLabel,
    raisedTimeLabel,
    requestTypeLabel,
    rejectRequestButton,
    requestDetailsTitle,
  } = commonModal;
  const { addteam, addmember } = useStrapiTeamData();
  const { addMemberRequestModal } = useStrapiRequestData();
  const { statusLabel } = addMemberRequestModal;
  // Mutation
  const requestActionMutation = useEnterpriseAdminRequestActionMutation();
  const { language } = useLanguage();
  const formattedRaisedTime = format(parseISO(request.createdOn), 'hh:mm a MMM dd, yyyy', {
    locale: getLocale(language),
  });

  const requestTypeName = request.requestType.requestTypeDetails[0].name;
  const { subscriptionData } = useAppSelector(subscriptionInfoData);
  let totalMembers, activeMembers, newTotalMembers, totalAvailableLicenses;
  let isLicensesAvailable = true;
  if (subscriptionData && requestTypeName !== 'EDIT_TEAM') {
    totalMembers = subscriptionData.totalLicenses;
    activeMembers = subscriptionData.activeLicenses;
    newTotalMembers =
      requestTypeName === 'NEW_MEMBER'
        ? activeMembers + 1
        : activeMembers + (request.data as EnterpriseAdminTeamRequestData).teamMembers.length;
    totalAvailableLicenses =
      subscriptionData?.status === 'trialing'
        ? Math.min(TRAIL_LIMIT, totalMembers as number)
        : totalMembers;
    isLicensesAvailable = newTotalMembers <= totalAvailableLicenses;
  }

  const navigate = useNavigate();
  const handleBilling = () => {
    navigate(`/settings?view=billing`);
  };
  const handleRequestAction = (action: keyof typeof ENTERPRISE_ADMIN_REQUEST_ACTION) => {
    const params = {
      action,
      requestId: parseInt(request.id),
    };
    requestActionMutation.mutate(params, {
      onSuccess: () => {
        // Close the dialog when the action succeds
        setIsDialogOpen(false);

        // For Edit Teams since we are changing the team name
        // Invalidate the teams name query as that is displayed in the table
        if (requestTypeName === 'EDIT_TEAM') {
          const data = request.data as EnterpriseAdminEditTeamRequestData;
          const teamId = data.teamId;

          const teamNameQueryKey = [
            {
              scope: 'teams',
              items: 'teamName',
              teamId,
            },
          ];

          queryClient.invalidateQueries(teamNameQueryKey);
        }
      },
    });
  };

  // derived values

  // Completed === Approved & ACTIVE === Open & CANCELLED === Rejected
  const isApprovedOrRejected =
    request.status === REQUEST_STATUS.COMPLETED || request.status === REQUEST_STATUS.CANCELLED;

  const approveInProgress =
    requestActionMutation.isLoading &&
    requestActionMutation.variables?.action === ENTERPRISE_ADMIN_REQUEST_ACTION.APPROVE;

  const rejectInProgress =
    requestActionMutation.isLoading &&
    requestActionMutation.variables?.action === ENTERPRISE_ADMIN_REQUEST_ACTION.REJECT;

  return (
    <div className="mx-1 flex flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-2 py-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <Dialog.Title className="text-center text-[18px] font-semibold leading-[24px] text-white xs:text-start">
        {requestDetailsTitle}
      </Dialog.Title>
      <div className="flex flex-col justify-between gap-6 xs:flex-row">
        <RequestActionDialogItem
          heading={requestTypeLabel}
          content={getDisplayNameForRequestType(request, strapiData)}
        />
        <RequestActionDialogItem
          heading={statusLabel}
          content={<RequestStatusCell status={request.status} />}
        />
      </div>
      <RequestDialogContent request={request} type={requestTypeName} />
      <div className="w-full border border-zinc-700 " />
      <div className="flex flex-col justify-between gap-6 xs:flex-row">
        <RequestActionDialogItem heading={raisedByLabel} content={request.createdByUser.fullName} />
        <RequestActionDialogItem heading={raisedTimeLabel} content={formattedRaisedTime} />
      </div>
      {!isLicensesAvailable && subscriptionData && request.status === 'ACTIVE' && (
        <SubscriptionInfo
          existingMemberCount={totalAvailableLicenses as number}
          newMemberCount={newTotalMembers as number}
        />
      )}

      {/* Show Action Buttons only if the request is open */}
      {!isApprovedOrRejected && (
        <div className="grid grid-cols-1 grid-rows-2 justify-items-center gap-2 text-sm font-medium text-white xs:grid-cols-2 xs:grid-rows-1 xs:justify-items-start">
          <button
            onClick={() => showRejectActionDialogContent(true)}
            className="w-max min-w-[8rem] rounded border border-zinc-800 bg-zinc-900 px-[30px] py-2.5 disabled:opacity-80"
            disabled={rejectInProgress}
          >
            {rejectInProgress ? (
              <div className="flex w-full items-center justify-center">
                <BasicSpinner className="!m-0 leading-[20px] text-zinc-100" />
              </div>
            ) : (
              rejectRequestButton
            )}
          </button>
          {!isLicensesAvailable ? (
            <button
              onClick={() => {
                handleBilling();
              }}
              className=" w-full whitespace-nowrap rounded bg-base-brand  disabled:opacity-80"
            >
              {requestTypeName === 'NEW_MEMBER'
                ? addmember.addMemberBillingButton
                : addteam.addTeamBillingButton}
            </button>
          ) : (
            <button
              onClick={() => handleRequestAction(ENTERPRISE_ADMIN_REQUEST_ACTION.APPROVE)}
              className="min-w-[8rem] max-w-full rounded bg-base-brand px-[30px] py-2.5 disabled:opacity-80"
              disabled={approveInProgress}
            >
              {approveInProgress ? (
                <div className="flex w-full items-center justify-center">
                  <BasicSpinner className="!m-0 leading-[20px] text-zinc-100" />
                </div>
              ) : (
                getAcceptButtonMessageForRequestType(requestTypeName, strapiData)
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default RequestActionDialogContent;
