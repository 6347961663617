import { useNavigate } from 'react-router-dom';
import NoDataDisplay from '../../../../components/DataTables/NoDataDisplay';
import { useStrapiRequestData } from '../../hooks/useStrapiRequestData';

interface Props {
  isFilterApplied: boolean;
  className?: string;
}

const RequestsEmptyState = ({ isFilterApplied, className }: Props) => {
  const navigate = useNavigate();

  const clearFilters = () => {
    // This will clear all search params
    navigate('.');
  };
  const { tableFilterOptions, errorMessages } = useStrapiRequestData();
  const { clearAllFiltersButton } = tableFilterOptions;

  if (isFilterApplied) {
    return (
      <NoDataDisplay
        className={className}
        message={
          <div className="flex flex-col items-center gap-8">
            <span>{errorMessages.emptyResultsMessage}</span>
            <button
              className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
              onClick={() => clearFilters()}
            >
              {clearAllFiltersButton}
            </button>
          </div>
        }
      />
    );
  }

  return <NoDataDisplay className={className} message={errorMessages.noRequestsAvailableMessage} />;
};

export default RequestsEmptyState;
