import { RouteObject } from 'react-router-dom';
import UserForgotPasswordMain from '../components/login/UserForgotPassowrd';
import UserLoginMain from '../components/login/UserLoginMain';
import UserResetPasswordMain from '../components/login/UserResetPassword';
import UserResetPasswordSuccess from '../components/login/UserResetPassword/UserResetPasswordSuccess';
import USHGAdminLoginMain from '../components/login/USHGAdminLoginMain';
import UserConfirmAccount from '../components/UserConfirmAccount';
import AuthBaseLayout from '../layouts/AuthBaseLayout';
import LoginBaseLayout from '../layouts/LoginBaseLayout';

const loginRoutes: RouteObject = {
  element: <AuthBaseLayout />,
  children: [
    {
      path: 'login',
      element: <LoginBaseLayout />,
      children: [
        {
          index: true,
          element: <UserLoginMain />,
        },
        {
          path: 'admin',
          element: <USHGAdminLoginMain />,
        },
      ],
    },
    {
      path: 'forgot-password',
      element: <LoginBaseLayout />,
      children: [
        {
          index: true,
          element: <UserForgotPasswordMain />,
        },
      ],
    },
    {
      path: 'reset-password',
      element: <LoginBaseLayout />,
      children: [
        {
          index: true,
          element: <UserResetPasswordMain />,
        },
        {
          path: 'success',
          element: <UserResetPasswordSuccess />,
        },
      ],
    },
    {
      element: <LoginBaseLayout />,
      children: [
        {
          path: 'confirm',
          element: <UserConfirmAccount />,
        },
      ],
    },
  ],
};

export default loginRoutes;
