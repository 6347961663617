import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import React, { useContext, useMemo, useState } from 'react';
import {
  ErrorDisplay,
  NoDataDisplay,
  TableHeaderRow,
} from '../../../../../../components/DataTables';
import { PartialEnterpriseLeaderBoardFilter } from '../../../../types';
import ReportsHeader from '../../ReportsHeader';
import { BadgesEarnedCell, CourseTimeCell, TeamCell } from '../TableCell';
import TableDataRow from '../../../../../../components/DataTables/TableDataRow';
import { useEnterpriseTeamsLeaderboardQuery } from '../../../../hooks';
import { ERROR_TYPES } from '../../../../../../constants/error-types.constants';
import { EnterpriseTeams, ReportTooltipDataType } from '../../../../types/reports/reports';
import durationContext from '../../../../context/durationContext';
import { ENTERPRISE_LEADERBOARD_DEFAULT_LIMIT, REPORT_TYPES } from '../../../../constants';
import { InView } from 'react-intersection-observer';
import { useStrapiReportsData } from '../../../../hooks/useStrapiReportData';
import ScaleLoader from '../../../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../../../components/ReportToolTip';

interface TeamsLeaderboardProps {
  className: string;
  tableClassName?: string;
  lazyLoad?: boolean;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}

const getTableColumnDefinition = (
  teamHeader: string,
  totalTimeSpentHeader: string,
  badgesEarnedHeader: string
) => {
  const columnHelper = createColumnHelper<EnterpriseTeams>();
  const columns = [
    columnHelper.accessor('name', {
      header: teamHeader,
      cell: (props) => <TeamCell teamName={props.getValue()} />,
    }),
    columnHelper.accessor('totalTimeSpent', {
      header: totalTimeSpentHeader,
      cell: (props) => {
        return <CourseTimeCell totalTimeSpent={props.getValue()} />;
      },
    }),
    columnHelper.accessor('badgesEarned', {
      header: badgesEarnedHeader,
      cell: (props) => <BadgesEarnedCell badgesEarned={props.getValue()} />,
    }),
  ];
  return columns;
};

const TeamsLeaderboardTable = ({
  className,
  tableClassName,
  lazyLoad = true,
  reportTooltipContent,
  userPersona,
}: TeamsLeaderboardProps) => {
  const { duration } = useContext(durationContext);
  const { title, tableheader1, tableheader2, tableheader3, errorMessage, emptyMessage } =
    useStrapiReportsData().TeamsLeaderboard;
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);

  // Build the filter
  const filters: PartialEnterpriseLeaderBoardFilter = useMemo(() => {
    return {
      fromDate: duration.fromDate,
      limit: ENTERPRISE_LEADERBOARD_DEFAULT_LIMIT,
    };
  }, [duration]);

  const teamsLeaderboard = useEnterpriseTeamsLeaderboardQuery({ filters, isEnabled: isInView });

  // Display loader during initial loading and if we are fetching when there are no items.
  const displayLoader =
    (teamsLeaderboard.isLoading && !teamsLeaderboard.data) ||
    (teamsLeaderboard.isFetching && teamsLeaderboard.data?.results.length === 0);

  const isEmptyState = teamsLeaderboard.data && teamsLeaderboard.data?.results?.length == 0;

  const tableHeader = {
    teamHeader: tableheader1,
    totalTimeSpentHeader: tableheader2,
    badgesEarnedHeader: tableheader3,
  };
  const { teamHeader, totalTimeSpentHeader, badgesEarnedHeader } = tableHeader;

  const columns = getTableColumnDefinition(teamHeader, totalTimeSpentHeader, badgesEarnedHeader);
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: teamsLeaderboard.data?.results || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiSort: true,
  });

  return (
    <InView
      as="div"
      className={className}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div
        className={clsx(
          'flex h-full min-h-[420px] w-full flex-col gap-4 overflow-hidden rounded-lg bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)]',
          tableClassName
        )}
      >
        <div className="flex items-center gap-2 ">
          <ReportsHeader title={title} />
          {reportTooltipContent?.[REPORT_TYPES.TEAMS_LEADERBOARD]?.[userPersona] && (
            <ReportTooltip
              content={reportTooltipContent[REPORT_TYPES.TEAMS_LEADERBOARD][userPersona]}
            />
          )}
        </div>
        {/* Is Loading and We donot have data or Is Fetching and we have data as empty array */}
        {displayLoader && <ScaleLoader />}
        {/* We have data */}
        {teamsLeaderboard.data && teamsLeaderboard.data?.results?.length > 0 && (
          <div className="relative w-full overflow-hidden overflow-x-scroll">
            <table className={clsx('w-full', className)}>
              <thead className="w-full">
                {getHeaderGroups().map((headerGroup) => {
                  return (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableHeaderRow
                          className="!font-normal !text-zinc-400"
                          isSortable={false}
                          header={header}
                          key={header.id}
                        />
                      ))}
                    </tr>
                  );
                })}
              </thead>
              <tbody>
                {getRowModel().rows.map((row) => (
                  <TableDataRow row={row} key={row.id} tdClassName="!text-zinc-50 font-semibold" />
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Empty State */}
        {isEmptyState && !displayLoader && (
          <div className="flex w-full grow items-center justify-center">
            <NoDataDisplay message={emptyMessage} />
          </div>
        )}

        {teamsLeaderboard.isError && !teamsLeaderboard.data && (
          <div className="flex w-full grow items-center justify-center">
            <ErrorDisplay
              isRefetching={teamsLeaderboard.isRefetching}
              refetch={teamsLeaderboard.refetch}
              allowsRefetch={true}
              type={ERROR_TYPES.SOMETHING_WENT_WRONG}
              message={errorMessage}
            />
          </div>
        )}
      </div>
    </InView>
  );
};

export default TeamsLeaderboardTable;
