import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import TableHeaderRow from '../../../../../components/DataTables/TableHeaderRow';
import { useStrapiRequestData } from '../../../hooks/useStrapiRequestData';
import { EnterpriseAdminRequest } from '../../../types';
import RequestsTableDataRow from '../../EnterpriseAdminRequestsPage/components/RequestsTable/RequestsTableDataRow';
import {
  EmailCell,
  RaisedByCell,
  RequestsCell,
  RequestStatusCell,
} from '../../EnterpriseAdminRequestsPage/components/RequestsTable/TableCell';

interface Props {
  className?: string;
  data: EnterpriseAdminRequest[];
}

const getTableColumnDefinition = (
  requestTypeHeader: string,
  raisedByHeader: string,
  emailHeader: string,
  statusHeader: string
) => {
  const columnHelper = createColumnHelper<EnterpriseAdminRequest>();
  const columns = [
    columnHelper.accessor('requestType', {
      header: requestTypeHeader,
      cell: (props) => <RequestsCell row={props.row.original} />,
    }),
    columnHelper.accessor('createdByUser', {
      header: raisedByHeader,
      cell: (props) => {
        // TODO : Check With @Team
        return <RaisedByCell createdByUser={props.getValue()} />;
      },
    }),
    columnHelper.accessor('createdByUser.username', {
      header: emailHeader,
      cell: (props) => <EmailCell email={props.getValue()} />,
    }),
    columnHelper.accessor('status', {
      header: statusHeader,
      cell: (props) => <RequestStatusCell status={props.getValue()} />,
    }),
  ];
  return columns;
};

const DashboardRequestsTable = ({ className, data }: Props) => {
  const { tableHeader } = useStrapiRequestData();
  const { raisedByColumnHeader, requestsColumnHeader, emailColumnHeader, statusColumnHeader } =
    tableHeader;
  const columns = getTableColumnDefinition(
    requestsColumnHeader,
    raisedByColumnHeader,
    emailColumnHeader,
    statusColumnHeader
  );
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiSort: true,
  });

  return (
    <table className={clsx('relative w-full', className)}>
      <thead className="w-full">
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeaderRow className="!bg-zinc-800/10" header={header} key={header.id} />
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <RequestsTableDataRow row={row} key={row.id} />
        ))}
      </tbody>
    </table>
  );
};

export default DashboardRequestsTable;
