import { flexRender, Header } from '@tanstack/react-table';
import clsx from 'clsx';
import DynamicSortIcon from '../../../../../components/DataTables/DynamicSortIcon';
import { INVOICE_PDF_URL, IS_OVER_DUE } from '../../../constants/billings';
import { Bills } from '../../../types/billing';
interface Props {
  header: Header<Bills, unknown>;
  className?: string;
  isSortable?: boolean;
  allowMultiColSort?: boolean;
}
const BillingHistoryTableHeaderRow = (props: Props) => {
  const { header, className, isSortable = false, allowMultiColSort = false } = props;
  const toggleColumnSorting = () => {
    if (header.id === INVOICE_PDF_URL || header.id === IS_OVER_DUE) {
      return;
    }
    isSortable && header.column.toggleSorting(undefined, allowMultiColSort);
  };
  return (
    <th
      className={clsx(
        'bg-zinc-900 px-2 py-3 text-start text-[12px] font-semibold leading-[16px] text-zinc-200',
        isSortable && 'cursor-pointer',
        className
      )}
      key={header.id}
      colSpan={header.colSpan}
      onClick={toggleColumnSorting}
    >
      <div className="flex w-full items-center gap-2">
        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
        {isSortable && header.id !== INVOICE_PDF_URL && header.id !== IS_OVER_DUE && (
          <DynamicSortIcon
            fallback={<div className="h-4 w-4 bg-transparent"></div>}
            sortDirection={header.column.getIsSorted()}
          />
        )}
      </div>
    </th>
  );
};

export default BillingHistoryTableHeaderRow;
