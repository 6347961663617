import { useQuery } from '@tanstack/react-query';
import { getTeams } from '../api';

type props = {
  includeSubTeams: boolean;
};
const useGetAllTeamsQuery = ({ includeSubTeams }: props) => {
  const { data, isLoading } = useQuery({
    queryKey: ['get-all-teams', includeSubTeams],
    queryFn: () => getTeams(includeSubTeams),
    select: (response) =>
      response.data.map((team: any) => ({ label: team.teamName, value: team.teamId })),
  });
  return { data, isLoading };
};

export default useGetAllTeamsQuery;
