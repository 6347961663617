import { twMerge } from 'tailwind-merge';
import { BadgeCard } from '../../../components/Cards';
import { EarnedBadgesResponse } from '../../badges/types/badges';
import { MostEarnedBadgesType } from '../types/interface';

type BadgeCardDisplayProps = {
  badges: MostEarnedBadgesType[];
  className?: string;
  badgeClassName?: string;
  showBadgeName?: boolean;
};

const MostEarnedBadgeCardDisplay = ({
  badges,
  className,
  badgeClassName,
  showBadgeName,
}: BadgeCardDisplayProps) => {
  const mostEarnedBadges: EarnedBadgesResponse[] = badges.map((badge) => {
    return {
      badgeId: badge.id,
      badgeName: badge.name,
      imageUrl: badge.pictureUrl,
    };
  });

  return (
    <div className={twMerge('flex flex-row flex-wrap gap-2 overflow-hidden', className)}>
      {mostEarnedBadges.map((badge) => {
        return (
          <BadgeCard
            key={badge.badgeId}
            badge={badge}
            className={badgeClassName}
            showBadgeName={showBadgeName}
          />
        );
      })}
    </div>
  );
};

export default MostEarnedBadgeCardDisplay;
