import clsx from 'clsx';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterDropDown } from '../../../../../components/DataTables';
import SearchInput from '../../../../../components/SearchInput';
import useStrapiBadgesData from '../../../hooks/useStrapiBadgeData';
import { Option } from '../../../../../components/Select';
import { BADGE_TYPES } from '../../../constants/badges';

interface Props {
  className?: string;
  category: string | undefined | null;
  filter: string;
  isFilterApplied: boolean;
}

const BadgesMainHeader = ({ className, category, filter, isFilterApplied }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const handleQChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    searchParams.set('search', value);
    navigate('?' + searchParams.toString());
  };
  const handleCategoryChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value;
    searchParams.set('category', value);
    navigate('?' + searchParams.toString());
  };
  const handleResetFiltersBtnClick = () => {
    navigate('.');
  };
  const {
    title,
    clearAllFiltersButton,
    allCategoriesLabel,
    focusAreaCategoriesLabel,
    courseCompletionCategoriesLabel,
    learningStreaksCategoriesLabel,
    searchPlaceholder,
  } = useStrapiBadgesData().table.Category;
  const USHG_ADMIN_BADGES_CATEGORY_VALUES: Option[] = [
    {
      display: courseCompletionCategoriesLabel,
      value: BADGE_TYPES.COURSE_ACCOMPLISHMENT,
    },
    {
      display: focusAreaCategoriesLabel,
      value: BADGE_TYPES.FOCUS_AREA,
    },
    {
      display: learningStreaksCategoriesLabel,
      value: BADGE_TYPES.LEARNING_STREAK,
    },
  ];
  return (
    <div
      className={clsx(
        'flex w-full flex-col justify-between gap-3 text-[14px] font-normal leading-[20px] text-zinc-100 md:flex-row md:gap-2',
        className
      )}
    >
      <div className="flex flex-col gap-2 lg:flex-row">
        <SearchInput
          placeholder={searchPlaceholder}
          containerClassName="min-w-[200px]"
          iconClassName="left-2"
          className="h-9 rounded-sm border border-zinc-900 bg-zinc-900 p-2 pl-9 text-[14px] font-normal leading-[20px] text-zinc-100 placeholder:text-zinc-500"
          value={filter ?? ''}
          onChange={handleQChange}
        />

        {isFilterApplied && (
          <div className="order-3 flex w-full grow items-center justify-center md:order-2 md:justify-start">
            <button
              className="h-min self-center justify-self-start rounded-[32px] bg-zinc-700 py-1.5 px-2.5 font-sans text-[10px] font-medium leading-[105%] text-zinc-200"
              onClick={handleResetFiltersBtnClick}
              // When there are no query params disable it
              // As we are setting the filters using the search params
            >
              {clearAllFiltersButton}
            </button>
          </div>
        )}
      </div>
      <div className="order-2 flex items-center gap-2 md:order-3">
        <span className="self-center whitespace-nowrap text-[14px] font-normal leading-[20px] text-zinc-100">
          {title}
        </span>

        <FilterDropDown
          value={category ?? undefined}
          options={USHG_ADMIN_BADGES_CATEGORY_VALUES}
          onChange={handleCategoryChange}
          selectItemLabel={allCategoriesLabel}
          placeholder={allCategoriesLabel}
          aria-label="Badges Category filter"
          className="rounded-sm border border-zinc-900 bg-zinc-900 py-2 pl-4 pr-10 text-[14px] font-normal leading-[20px] text-zinc-100"
        />
      </div>
    </div>
  );
};
export default BadgesMainHeader;
