import { useQuery } from '@tanstack/react-query';
import { getModules } from '../api';

import { useState } from 'react';
import axios from 'axios';
import { USHGAPIError } from '../../../types';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';
type useGetModulesByIdQueryProps = {
  language?: string;
  id: number;
};
const useGetModulesByIdQuery = ({
  id,
  language = DEFAULT_PREFERRED_LANGUAGE,
}: useGetModulesByIdQueryProps) => {
  const [errorType, setErrorType] = useState<string | null>();
  const { data, isError, error, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ['get-modules', id, language],
    queryFn: () => getModules(`${id}`, language),
    onError: (error) => {
      console.error('Error while fetching modules %o', error);
      // If axios error
      if (axios.isAxiosError(error)) {
        const data = error.response?.data;
        if (data) {
          const { errorCode } = data as USHGAPIError;
          setErrorType(errorCode);
        }
      }
    },
    enabled: id !== undefined,
  });
  return { data, isError, error, isLoading, isRefetching, refetch, errorType };
};

export default useGetModulesByIdQuery;
