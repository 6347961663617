import React from 'react';
import ModuleBasicDetails from './ModuleBasicDetails';

interface ModuleMainProps {
  moduleBasicDetailsQuery: Record<string, any>;
}
export const ModuleMain = ({ moduleBasicDetailsQuery }: ModuleMainProps) => {
  return (
    <div className="flex w-full flex-col gap-4">
      <ModuleBasicDetails moduleBasicDetailsQuery={moduleBasicDetailsQuery} />
    </div>
  );
};
