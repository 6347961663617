import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { ENTERPRISE_ONBOARDING_TYPE, ONBOARDING_API_PATHS } from '../constants';
import { CreateCategoriesResponse, Category, GetGoalCategoryResponse } from '../types';

interface CategoriesArgs {
  type: keyof typeof ENTERPRISE_ONBOARDING_TYPE;
  language: string;
}
const getAllCategories = async (params: CategoriesArgs): Promise<Category[]> => {
  const queryString = qs.stringify(params);
  const path = ONBOARDING_API_PATHS.CATEGORIES.GET_ALL_CATEGORIES;

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${queryString}`, {
    response: true,
    withCredentials: true,
  });

  return response.data;
};
const getGoalCategory = async (): Promise<GetGoalCategoryResponse[]> => {
  const path = ONBOARDING_API_PATHS.CATEGORIES.GET_GOAL_CATEGORY;

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const createCategories = async (
  selectedCategories: Array<any[]>
): Promise<CreateCategoriesResponse> => {
  const body = selectedCategories;
  const path = ONBOARDING_API_PATHS.CATEGORIES.CREATE_CATEGORIES;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    body,
    withCredentials: true,
  });

  return response.data;
};

export { getAllCategories, getGoalCategory, createCategories };
