import { TabWidgetType } from '../../../../../types/tab-widget.types';
import CategoryCardDisplay from './CategoryCardDisplay';
import { Category } from '../../../../../types/category-types';
import { CATEGORIES_CONTENT_MAPPER, MAX_CATEGORY_LIMIT } from '../../../../../constants';
import { BasicSpinner } from '../../../../../../../components/Spinners';
import OnboardingErrorState from '../../../OnboardingErrorState';
import { useEffect, useMemo } from 'react';
import AllCategoriesCardDisplay from './AllCategoriesCardDisplay';
import NoDataDisplay from '../../../OnboardingEmptyState';
import { useStrapiOnBoardingData } from '../../../../../hooks/useStrapiOnBoardingData';
import { UseQueryResult } from '@tanstack/react-query';
interface CategoryCardViewProps {
  categoryQuery: UseQueryResult<any>;
  recommendedCategories: number[];
  currentTab: number;
  widgetData: TabWidgetType[];
  selectedCategories: Category[];
  setSelectedCategories: (selectedCategories: Category[]) => void;
  selectedCategoriesInitialise: Record<string, boolean>;
  setSelectedCategoriesIntialise: (selectedCategoriesInitialise: Record<string, boolean>) => void;
  selectedCount: number;
  content: any;
}

const style = {
  fontFeatureSettings: '"salt" off',
};
const CategoryCardView = ({
  content,
  categoryQuery,
  recommendedCategories,
  currentTab,
  widgetData,
  selectedCategories,
  setSelectedCategories,
  selectedCategoriesInitialise,
  setSelectedCategoriesIntialise,
  selectedCount,
}: CategoryCardViewProps) => {
  const {
    leaderCategory,
    emptyMessages: { noCategoriesFound },
  } = useStrapiOnBoardingData();
  const { allCategoriesTitle, recommendedCategoriesTitle } = leaderCategory;

  const recommedCategoriesData = useMemo(() => {
    if (categoryQuery.data && recommendedCategories?.length) {
      return categoryQuery?.data?.filter((category: Category) =>
        recommendedCategories.includes(category.id)
      );
    }
    return [];
  }, [categoryQuery.data, recommendedCategories]);

  useEffect(() => {
    if (categoryQuery.data && !selectedCategoriesInitialise[widgetData[currentTab].type]) {
      const arrayUniqueByKey: Category[] = categoryQuery.data.filter(
        (obj: Category, index: number) =>
          categoryQuery.data.findIndex(
            (category: Category) =>
              category.type === obj.type && category.id === obj.id && category.isSelected
          ) === index
      );

      const categoryIds = new Set(selectedCategories.map((category) => category.id));

      setSelectedCategories([
        ...selectedCategories,
        ...arrayUniqueByKey.filter((obj) => !categoryIds.has(obj.id)),
      ]);

      const categoriesInitialize = {
        ...selectedCategoriesInitialise,
        [widgetData[currentTab].type]: true,
      };

      setSelectedCategoriesIntialise(categoriesInitialize);
    }
  }, [categoryQuery.data]);

  const handleSelectedCategories = (category: Category) => {
    // Check if the category is already selected in the current widget
    const isCategorySelected = selectedCategories.some(
      (selected) => selected.id === category.id && selected.type === widgetData[currentTab].type
    );
    if (isCategorySelected) {
      // If the category is already selected in the current widget, unselect it by filtering it out
      const filteredCategories = selectedCategories.filter(
        (selected) =>
          !(selected.id === category.id && selected.type === widgetData[currentTab].type)
      );
      setSelectedCategories(filteredCategories);
    } else {
      // If the category is not selected in the current widget, handle the addition to selectedCategories
      // If the category's type is different from the current widget's type, update the type to match the current widget's type
      const type =
        category.type !== widgetData[currentTab].type ? widgetData[currentTab].type : category.type;
      // Check if the maximum category limit has not been reached
      if (selectedCount < MAX_CATEGORY_LIMIT) {
        // Add the category to selectedCategories with the updated type
        setSelectedCategories(selectedCategories.concat({ ...category, type: type }));
      }
    }
  };
  const isLoading = categoryQuery.isLoading || categoryQuery.isFetching;

  // strapi data

  return (
    <div className="my-5 flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <h1 className="text-lg font-bold text-zinc-50 lg:text-xl">
          {content[widgetData[currentTab].text as keyof typeof CATEGORIES_CONTENT_MAPPER].header}{' '}
          {selectedCount} /{MAX_CATEGORY_LIMIT}
        </h1>
        <p className="text-sm text-zinc-400">
          {content[widgetData[currentTab].text as keyof typeof CATEGORIES_CONTENT_MAPPER].subHeader}
        </p>
      </div>
      {/* Categories loading */}
      {isLoading && (
        <div className="flex h-[6.25rem] w-full items-center justify-center">
          <BasicSpinner className="!ml-0 h-6 w-6 text-base-brand" />
        </div>
      )}

      {recommedCategoriesData && recommedCategoriesData.length > 0 && !isLoading && (
        <div className="my-5 flex flex-col content-around gap-5">
          <p className="mb-2 text-sm font-medium text-zinc-400">{recommendedCategoriesTitle}</p>
          {/* We have data */}
          <CategoryCardDisplay
            style={style}
            categories={recommedCategoriesData}
            selectedCategories={selectedCategories}
            handleSelectedCategories={handleSelectedCategories}
            widgetType={widgetData[currentTab].type}
          />
        </div>
      )}

      {/* We have data */}
      {categoryQuery.data && categoryQuery.data.length > 0 && (
        <div className="flex flex-col content-around gap-5">
          <p className="mb-2 text-sm font-medium text-zinc-400">{allCategoriesTitle}</p>
          <AllCategoriesCardDisplay
            style={style}
            categories={categoryQuery.data}
            selectedCategories={selectedCategories}
            handleSelectedCategories={handleSelectedCategories}
            widgetType={widgetData[currentTab].type}
          />
        </div>
      )}
      {/* Empty State */}
      {categoryQuery.data && categoryQuery.data.length === 0 && !categoryQuery.isLoading && (
        <NoDataDisplay
          message={
            <div className="flex flex-col items-center gap-8">
              <span>{noCategoriesFound}</span>
            </div>
          }
        />
      )}
      {/* Error State */}
      {categoryQuery.isError && !categoryQuery.data && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <OnboardingErrorState
            isRefetching={categoryQuery.isRefetching}
            refetch={categoryQuery.refetch}
          />
        </div>
      )}
    </div>
  );
};

export default CategoryCardView;
