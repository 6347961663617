import clsx from 'clsx';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorDisplay, NoDataDisplay } from '../../../../components/DataTables';
import { BasicSpinner } from '../../../../components/Spinners';
import { ERROR_TYPES } from '../../../../constants/error-types.constants';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import useGetBadgesLeaderBoardQuery from '../../hooks/useGetBadgesLeaderBoardQuery';
import useStrapiBadgesData from '../../hooks/useStrapiBadgeData';
import { PartialBadgesFilter } from '../../types/badges';
import LeaderboardTable from './LeaderboardTable';

const Leaderboard = () => {
  const { id } = useParams() ?? undefined;
  const filters: PartialBadgesFilter = useMemo(() => {
    return {
      username: id,
    };
  }, [id]);

  const leaderboard = useGetBadgesLeaderBoardQuery({ filters });

  const displayLoader =
    (leaderboard.isLoading && !leaderboard.data) ||
    (leaderboard.isFetching && leaderboard.data?.length === 0);
  const isEmptyState = leaderboard.data && leaderboard.data.length == 0;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const { myBadges, errorMessages } = useStrapiBadgesData();
  const {
    leaderBoard: { title },
  } = myBadges;
  return (
    <>
      <p className="p-3 font-medium text-[#FFFFFF]">{title}</p>

      {displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center py-4">
          <BasicSpinner className="text-white" />
        </div>
      )}
      {/* We have data */}
      {leaderboard.data && leaderboard.data.length > 0 && (
        <div
          className={clsx(
            'relative flex w-full flex-col gap-4',
            leaderboard.isPreviousData && leaderboard.isFetching && 'pointer-events-none opacity-70'
          )}
        >
          {leaderboard.isPreviousData && leaderboard.isFetching && (
            <div className="flex w-full justify-center">
              <BasicSpinner className="text-center text-white" />
            </div>
          )}

          <div className="relative w-full overflow-y-scroll">
            {leaderboard.isError ? (
              <ErrorDisplay
                refetch={leaderboard.refetch}
                isRefetching={leaderboard.isRefetching}
                allowsRefetch={true}
                type={ERROR_TYPES.SOMETHING_WENT_WRONG}
                message={strapiErrorMessage['SYSTEM_ERROR']}
              />
            ) : (
              <LeaderboardTable data={leaderboard.data} />
            )}
          </div>
        </div>
      )}

      {/* Empty State */}
      {isEmptyState && !displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <NoDataDisplay message={errorMessages.noLeaderBoardData} />
        </div>
      )}

      {leaderboard.isError && !leaderboard.data && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <ErrorDisplay
            refetch={leaderboard.refetch}
            isRefetching={leaderboard.isRefetching}
            allowsRefetch={true}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            message={strapiErrorMessage['SYSTEM_ERROR']}
          />
        </div>
      )}
    </>
  );
};

export default Leaderboard;
