import qs from 'qs';
import { TEAMS_API_PATHS } from '../constants';
import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { PartialEnterpriseAdminViewAllTeamMembersFilter, PartialMembers } from '../types/interface';

const getAllTeamMembers = async (
  filters: PartialEnterpriseAdminViewAllTeamMembersFilter
): Promise<{
  data: PartialMembers[];
  rowCount: number;
}> => {
  const queryString = qs.stringify(filters);
  const path = TEAMS_API_PATHS.ENTERPRISE_ADMIN.GET_ALL_MEMBERS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const resendInviteToMember = (username: string) => {
  return API.post(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    `${TEAMS_API_PATHS.ENTERPRISE_ADMIN.RESEND_INVITE}`,
    {
      body: { username },
      response: true,
      withCredentials: true,
    }
  );
};

export { getAllTeamMembers, resendInviteToMember };
