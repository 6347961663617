import { differenceInSeconds } from 'date-fns';
import { useEffect, useState } from 'react';
import { getTimeAgo } from '../utils';
import { useLanguage } from '../features/shared/context/languageContext';

interface Props {
  timestamp?: Date;
  updateLive?: boolean;
  diffInSecondsAndDurationMap?: Record<number, number>;
  style?: 'long' | 'short' | 'narrow';
}

// The default difference in seconds to duration map
const defaultDiffInSecondsAndDurationMap: Record<number, number> = {
  // Less than a minute ago update every second
  60: 1000,
  // Less than a 60 minutes ago update every minute
  3600: 60 * 1000,
};

/**
 * This hook will get you the time ago for the timestamp compared to now
 * If updateLive is passed then the timeAgo would be updated based on the seconds difference
 * would return ''
 */
const useTimeAgo = (props: Props) => {
  const { timestamp, updateLive = true, diffInSecondsAndDurationMap = {}, style = 'short' } = props;
  const { language } = useLanguage();

  const [timeAgo, setTimeAgo] = useState(() => {
    if (!timestamp) {
      return '';
    }

    try {
      const timestampDate = new Date(timestamp);
      const timeAgo = getTimeAgo(timestampDate, style, language);

      return timeAgo;
    } catch (error) {
      console.log('Error converting timestamp to Date', error);
      return '';
    }
  });

  useEffect(() => {
    if (timestamp && updateLive && timeAgo !== '') {
      const secondsDiffAndDurationMap = {
        ...defaultDiffInSecondsAndDurationMap,
        ...diffInSecondsAndDurationMap,
      };

      const timestampDate = new Date(timestamp);

      const timeDifferenceInSeconds = differenceInSeconds(timestampDate, Date.now());

      // Every hour
      const hourInMilliSeconds = 3600 * 1000;
      let INTERVAL_DURATION = hourInMilliSeconds;

      if (timeDifferenceInSeconds < 60) {
        INTERVAL_DURATION = secondsDiffAndDurationMap[60];
      } else if (timeDifferenceInSeconds < 3600) {
        INTERVAL_DURATION = secondsDiffAndDurationMap[3600];
      }

      const intervalId = setInterval(() => {
        const timeAgo = getTimeAgo(timestampDate, style, language);
        setTimeAgo(timeAgo);
      }, INTERVAL_DURATION);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [diffInSecondsAndDurationMap, style, timeAgo, timestamp, updateLive]);

  return timeAgo;
};

export default useTimeAgo;
