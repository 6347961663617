import clsx from 'clsx';
import { ArrowCircleRight } from 'phosphor-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStrapiDashboardData } from '../../../shared/hooks';

interface Props {
  className?: string;
}
const ExploreAllCourses = ({ className }: Props) => {
  const { startYourJourneyLabel, title, description, exploreAllCoursesImg } =
    useStrapiDashboardData().exploreCourses;
  const navigate = useNavigate();
  return (
    <div
      className={clsx(
        'flex h-full min-h-[175px] w-full items-center rounded-lg bg-card-bg p-4 md:p-0',
        className
      )}
    >
      <img
        alt="USHG Explore All Courses Illustrator Stacked Vertically and Horizantally to make a pattern"
        src={exploreAllCoursesImg.data.attributes.url}
        className="pointer-events-none hidden h-full w-1/2 object-cover object-left gradient-mask-r-0 md:flex"
      />

      <div className="flex basis-11/12 flex-col gap-y-2 md:basis-2/5">
        <span className="text-[8px] font-bold uppercase leading-[150%] tracking-wider text-base-brand">
          {startYourJourneyLabel}
        </span>
        <h1 className="text-xl font-bold text-zinc-50">{title}</h1>
        <p className="text-xs font-normal text-zinc-400">{description}</p>
      </div>
      <ArrowCircleRight
        aria-label="Explore All Courses"
        size="24px"
        color="currentColor"
        className="basis-[10%] cursor-pointer text-zinc-500"
        onClick={() => navigate('/courses?view=all')}
      />
    </div>
  );
};

export default ExploreAllCourses;
