import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/slices/auth.slice';
import strapiReducer from './features/auth/slices/strapi.slice';
import registerReducer from './features/auth/slices/registration.slice';
import pricingReducer from './features/auth/slices/pricing.slice';
import teamReducer from './features/teams/slices/team.slice';
import onboarding from './features/onboarding/slices/onboarding.slice';
import coursesReducer from './features/courses/slices/courses.slice';
import subscriptionReducer from './features/shared/slices/subscription.slice';
import inviteFriendModalSlice from './features/shared/slices/inviteFriend.slice';
import messagingReducer from './features/messaging/slices/messaging.slice';
import notificationReducer from './features/notifications/slice/notification.slice';
import singleCourseReducer from './features/courses/components/SingleCourseView/slice/SingleCourse.slice';
import badgesReducer from './features/badges/components/USHGAdminBadgeView/slice/badges.slice';
import organisationReducer from './features/organisation/slice/organisation.slice';
import hqSingleCourseReducer from './features/course-management/components/common/SingleCoursePage/components/slice/hq-single-course.slice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    register: registerReducer,
    pricing: pricingReducer,
    strapi: strapiReducer,
    team: teamReducer,
    onboarding: onboarding,
    courses: coursesReducer,
    subscription: subscriptionReducer,
    messaging: messagingReducer,
    notification: notificationReducer,
    singleCourse: singleCourseReducer,
    inviteFriendModalState: inviteFriendModalSlice,
    organisation: organisationReducer,
    badges: badgesReducer,
    hqSingleCourse: hqSingleCourseReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
