import { format } from 'date-fns';
import { CalendarItem } from '../types';
import { getLocale } from '../../../utils';

type GenerateDateToCalendarItemsMapArgs = {
  items: CalendarItem[];
  initDateItemsMap?: Record<string, CalendarItem[]>;
};

/**
 * Takes a list of calendar items
 * creates a map with date as key and calendar items for that day as value.
 */
const generateDateToCalendarItemsMap = ({
  items,
  initDateItemsMap = {},
}: GenerateDateToCalendarItemsMapArgs) => {
  const dateItemsMap: Record<string, CalendarItem[]> = initDateItemsMap;

  items.forEach((ev) => {
    try {
      const calendarDate = ev.calendarDate;

      const calendarItemsForDate = dateItemsMap[calendarDate] ?? [];
      dateItemsMap[calendarDate] = [...calendarItemsForDate, ev];
    } catch (error) {
      console.log(`Error parsing event ${ev}`);
    }
  });

  return {
    dateItemsMap,
  };
};

type GenerateDateToCalendarItemsForDatesMapArgs = {
  dates: Date[];
  items: CalendarItem[];
  language: string;
};

/**
 * Takes a list of days and calendar items
 * creates a map with date as key and calendar items for that day as value.
 * returns [] for dates with no events
 */
const generateDateToCalendarItemsMapForDates = ({
  dates,
  items,
  language,
}: GenerateDateToCalendarItemsForDatesMapArgs) => {
  const initDateItemsMap: Record<string, CalendarItem[]> = {};

  dates.forEach((d) => {
    const fDate = format(d, 'yyyy-MM-dd', { locale: getLocale(language) });
    initDateItemsMap[fDate] = [];
  });

  const { dateItemsMap } = generateDateToCalendarItemsMap({
    items,
    initDateItemsMap,
  });

  return {
    dates,
    dateItemsMap,
  };
};

export { generateDateToCalendarItemsMapForDates, generateDateToCalendarItemsMap };
