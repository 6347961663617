import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { useStrapiSettingsData } from './useStrapiSettingsData';
import { ChangePasswordParams, changePassword } from '../../auth/helpers';
import { COGNITO_ERROR_TYPE } from '../constants';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';

const useChangePasswordMutation = () => {
  const {
    toastMessage: {
      updatePasswordSuccessTitle,
      updatePasswordSuccessMessage,
      updatePasswordFailedTitle,
      updatePasswordFailedMessage,
    },
  } = useStrapiSettingsData();
  const data: any = useStrapiDataHelper();
  const strapiErrorMessage = data.errorsMap;

  const mutation = useMutation({
    mutationFn: async (params: ChangePasswordParams) => {
      const response = await changePassword(params);
      return response;
    },
    onSuccess: () => {
      toast.custom((t) => (
        <Toast
          variant="success"
          Title={updatePasswordSuccessTitle}
          SubTitle={updatePasswordSuccessMessage}
          toastInstance={t}
        />
      ));
    },
    onError: (error) => {
      console.error('Error changing a new password %o', error);

      const errorToastTitle = updatePasswordFailedTitle;
      let errorToastSubtitle = updatePasswordFailedMessage;

      if (error instanceof Error) {
        const { name, message } = error;

        // TODO: Strapi Integration
        switch (name) {
          case COGNITO_ERROR_TYPE.NotAuthorizedException:
          case COGNITO_ERROR_TYPE.LimitExceededException: {
            errorToastSubtitle = strapiErrorMessage['INCORRECT_DETAILS'];
            console.error(message);
          }
        }
      }

      toast.custom((t) => (
        <Toast
          variant="error"
          Title={errorToastTitle}
          SubTitle={errorToastSubtitle}
          toastInstance={t}
        />
      ));
    },
  });

  return mutation;
};

export default useChangePasswordMutation;
