import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { BasicSpinner } from '../../../../../../../components/Spinners';
import { isNotFoundError } from '../../../../../../../utils/axios';
import cn from '../../../../../../../utils/cn';
import { RichTextEditor } from '../../../../../../shared';
import { RichTextEditorProvider } from '../../../../../../shared/providers';
import { useStrapiSettingsData } from '../../../../../hooks/useStrapiSettingsData';
import NewFeatureLabel from '../NewFeatureLabel';
import AnnouncementActions from './AnnouncementActions';
import {
  ANNOUNCEMENT_ACTION,
  ANNOUNCEMENT_EDITOR_MARKDOWN_TRANSFORMERS,
} from '../../../../../constants/announcement';
import { ErrorDisplay } from '../../../../../../../components/DataTables';
import { useStrapiSharedData } from '../../../../../../shared/hooks/useStrapiSharedData';
import Select from '../../../../../../../components/Select';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../../../constants/user';
import { useGetAnnouncementQuery } from '../../../../../hooks';

type Props = {
  className?: string;
};

const Announcement = ({ className }: Props) => {
  // strapi content
  const { hqAdminPlatformAnnouncement: announcementCMS } = useStrapiSettingsData();

  const {
    language: { StrapiLanguageOptions },
  } = useStrapiSharedData();

  // strapi content
  const mappedOptions = useMemo(
    () =>
      StrapiLanguageOptions?.map((option: { label: string; value: string }) => ({
        display: option.label,
        value: option.value,
      })) || [],
    [StrapiLanguageOptions]
  );

  const [selectedLanguage, setSelectedLanguage] = useState(DEFAULT_PREFERRED_LANGUAGE);
  const announcementIdRef = useRef<number | undefined>(undefined);
  const isDefaultContentAddedRef = useRef(false);

  const handleLanguageChange = (newLanguage: string) => {
    setSelectedLanguage(newLanguage);
  };

  // state

  // create, update or delete of announcement action in progress
  const [actionInProgress, setActionInProgress] = useState<keyof typeof ANNOUNCEMENT_ACTION | null>(
    null
  );

  // state

  // hooks
  const getAnnouncementQuery = useGetAnnouncementQuery({ language: selectedLanguage });

  useEffect(() => {
    if (getAnnouncementQuery.data?.id) {
      announcementIdRef.current = parseInt(getAnnouncementQuery.data.id);

      // Mark content as added if the default language is selected
      if (selectedLanguage === DEFAULT_PREFERRED_LANGUAGE) {
        isDefaultContentAddedRef.current = true;
      }
    }
  }, [getAnnouncementQuery.data]);

  // hooks

  // derived state

  // If we get a 404 that means there is no announcement already and we need to create one
  const shouldCreateNewAnnouncement = isNotFoundError(getAnnouncementQuery.error);

  // if we get markdown content then we have an announcement
  const hasAnnouncementAlready = !!getAnnouncementQuery.data;

  const initialAnnouncementMarkdown = hasAnnouncementAlready
    ? getAnnouncementQuery.data.data?.markdownContent
    : undefined;

  const showRichTextEditor = hasAnnouncementAlready || shouldCreateNewAnnouncement;

  // derived state

  return (
    <RichTextEditorProvider namespace="announcement">
      <div
        className={cn(
          'flex w-full flex-col items-start gap-[18px] rounded-sm bg-card-bg p-4 sm:p-6',
          '[--rte-height:128px]',
          className
        )}
      >
        <div className="flex flex-col items-start gap-0.5">
          <div className="flex items-start gap-2 sm:gap-3">
            <h2 className="text-lg font-semibold text-zinc-50 sm:text-xl sm:leading-8">
              {announcementCMS.title}
            </h2>
            {announcementCMS.showIsNewFeature && (
              <div className="flex h-7 shrink-0 items-center sm:h-8">
                <NewFeatureLabel />
              </div>
            )}
          </div>
          <p className="text-sm font-normal text-zinc-500">{announcementCMS.description}</p>
        </div>
        {/* has data */}
        <div className="flex w-full flex-col gap-2.5">
          {/* is loading */}
          {getAnnouncementQuery.isLoading && !hasAnnouncementAlready && (
            // Temp Fix calc(var(--rte-height)+42px) to match rte height
            <div className="flex h-[calc(var(--rte-height)+42px+46px)] items-center justify-center">
              <BasicSpinner className="!m-0 text-zinc-100" />
            </div>
          )}
          {/* We have a announcement or we need to create a new one */}
          {showRichTextEditor && (
            <>
              {(isDefaultContentAddedRef.current || hasAnnouncementAlready) && (
                <Select
                  aria-label="Language options"
                  options={mappedOptions?.length ? mappedOptions : []}
                  value={selectedLanguage}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    handleLanguageChange?.(e.target.value)
                  }
                  className="ml-auto"
                  disabled={!!actionInProgress} // Disable dropdown during any action
                />
              )}
              <RichTextEditor
                isEditable={actionInProgress === null}
                initialMarkdownString={initialAnnouncementMarkdown}
                transformers={ANNOUNCEMENT_EDITOR_MARKDOWN_TRANSFORMERS}
                placeholder={announcementCMS.editorPlaceholder}
                selectedLanguage={selectedLanguage}
                hasAnnouncementAlready={hasAnnouncementAlready}
              />
              <AnnouncementActions
                hasAnnouncementAlready={hasAnnouncementAlready}
                actionsdisabled={getAnnouncementQuery.isLoading}
                setActionInProgress={setActionInProgress}
                selectedLanguage={selectedLanguage}
                announcementId={announcementIdRef?.current}
                isDefaultContentAddedRef={isDefaultContentAddedRef}
              />
            </>
          )}
          {/* Error */}
          {getAnnouncementQuery.isError && !showRichTextEditor && (
            <ErrorDisplay
              className="h-[calc(var(--rte-height)+42px+46px)] gap-2"
              displayWarningIcon={true}
              message={announcementCMS.announcementFetchErrorMessage}
              refetch={getAnnouncementQuery.refetch}
              allowsRefetch={true}
              isRefetching={getAnnouncementQuery.isRefetching}
            />
          )}
        </div>
      </div>
    </RichTextEditorProvider>
  );
};

export default Announcement;
