import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { BasicSpinner } from '../../../../../../components/Spinners';

export type Action = {
  label: string;
  handler: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  className?: string;
};

interface ActionButtonProps extends Action {
  type: 'primary' | 'secondary';
}

const ActionButton = (props: ActionButtonProps) => {
  const { label, type, handler, className, isLoading } = props;
  const isPrimaryAction = type === 'primary';

  return (
    <button
      key={label}
      className={twMerge(
        clsx(
          'flex items-center justify-center gap-1 rounded-[0.25rem] p-[0.625rem_1.875rem] text-sm font-medium text-white disabled:opacity-70',
          isPrimaryAction && 'bg-base-brand',
          className
        )
      )}
      onClick={handler}
      disabled={isLoading}
    >
      <span>{label}</span>
      {isLoading && <BasicSpinner className="!m-0" />}
    </button>
  );
};

export default ActionButton;
