import clsx from 'clsx';
import { Warning } from 'phosphor-react';
import { useStrapiSharedData } from '../../features/shared/hooks/useStrapiSharedData';
interface ComponentLevelErrorBoundaryFallbackProps {
  error?: Error;
  description: string;
  className?: string;
}

const ComponentLevelErrorBoundaryFallback = ({
  error,
  description,
  className,
}: ComponentLevelErrorBoundaryFallbackProps) => {
  const {
    componentLevelErrorBoundary: { title },
  } = useStrapiSharedData();

  console.error('Error catched by Component Level Error Boundary', error);

  return (
    <div
      className={clsx(
        'flex h-max w-full flex-col items-center gap-1 border-r border-r-zinc-800 p-2 text-rose-200',
        className
      )}
    >
      <Warning size={32} className="text-inherit" color="currentColor" />
      <h3 className="text-center text-base font-normal">{title}</h3>
      <span className="text-center text-sm font-light">{description}</span>
    </div>
  );
};

export default ComponentLevelErrorBoundaryFallback;
