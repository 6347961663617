import clsx from 'clsx';
import { CheckCircle, Warning, XCircle, Info } from 'phosphor-react';
import { AlertVariant } from './Alert';

interface Props {
  variant: AlertVariant;
  className?: string;
}

const AlertIcon = ({ variant, className }: Props) => {
  switch (variant) {
    case 'error': {
      return (
        <XCircle
          size={16}
          weight="fill"
          color="currentColor"
          className={clsx('text-rose-50', className)}
        />
      );
    }
    case 'warning': {
      return (
        <Warning
          size={16}
          weight="fill"
          color="currentColor"
          className={clsx('text-amber-50', className)}
        />
      );
    }
    case 'success': {
      return (
        <CheckCircle
          size={16}
          weight="fill"
          color="currentColor"
          className={clsx('text-emerald-50', className)}
        />
      );
    }
    case 'info': {
      return (
        <Info
          size={16}
          weight="fill"
          color="currentColor"
          className={clsx('text-blue-50', className)}
        />
      );
    }
  }
};

export default AlertIcon;
