import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import Dialog from '../../../../../../components/Dialog';
import {
  CheckboxInput,
  DropdownInput,
  Label,
  TextInput,
} from '../../../../../../components/FormElements';
import ErrorMessage from '../../../../../../components/FormElements/ErrorMessage';
import TextAreaInput from '../../../../../../components/FormElements/TextAreaInput';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { fieldHasError } from '../../../../../../utils/react-hook-form';
import { ASSESSMENT_ACTION, MODULE_KEYS } from '../../../../constants';
import {
  useAddAssessmentInMultiLangMutation,
  useAddAssessmentMutation,
  useUpdateAssessmentMutation,
} from '../../../../hooks';
import { useStrapiHQAdminCoursesData } from '../../../../hooks/useStrapiHQAdminCourseData';
import { AssessmentInMultiLangArgs, ModalType } from '../../../../types';
import { FormActionButton, FormFieldWrapper } from '../../../common/FormControls';
import { formatNumber } from '../../../../../../utils';
import { COURSES_ERROR_CODE } from '../../../../constants/courses-management';
import cn from '../../../../../../utils/cn';

// Yup Schema
interface Schema {
  nameRequired: string;
  nameMinValue: number;
  nameMinError: string;
  nameMaxValue: number;
  nameMaxError: string;
  durationRequired: string;
  durationMinError: string;
  durationMinValue: number;
  descriptionRequired: string;
  descriptionMinValue: number;
  descriptionMinError: string;
  descriptionMaxValue: number;
  descriptionMaxError: string;
  passPercentageRequired: string;
}
const getSchema = (data: Schema) => {
  const {
    nameRequired,
    nameMinValue,
    nameMinError,
    nameMaxValue,
    nameMaxError,
    durationRequired,
    durationMinError,
    durationMinValue,
    descriptionRequired,
    descriptionMinValue,
    descriptionMinError,
    descriptionMaxValue,
    descriptionMaxError,
    passPercentageRequired,
  } = data;
  const assessmentFormSchema = yup.object({
    name: yup
      .string()
      .trim()
      .required(nameRequired)
      .min(nameMinValue, nameMinError)
      .max(nameMaxValue, nameMaxError),
    assessmentDuration: yup
      .number()
      .min(durationMinValue, durationMinError)
      .typeError(durationRequired)
      .required(durationRequired)
      .label('assessmentDuration'),
    passingPercent: yup.number().typeError(passPercentageRequired).required(passPercentageRequired),
    description: yup
      .string()
      .trim()
      .min(descriptionMinValue, descriptionMinError)
      .max(descriptionMaxValue, descriptionMaxError)
      .required(descriptionRequired),
    isRequired: yup.boolean().default(false),
  });
  return assessmentFormSchema;
};

// Yup Schema

interface Props {
  setIsAssessmentModalOpen: (open: boolean) => void; // will be used when have mutation in place
  assessmentBasicDetails?: Record<string, any>;
  formActionButtonLabel: string;
  buttonIcon?: React.ReactNode;
  modalType: ModalType;
  refetch: () => void;
  language: string;
  errorType?: string | null;
  setErrorType?: (error: string | null) => void;
  setActionInProgress: (a: keyof typeof ASSESSMENT_ACTION | null) => void;
}

const AssessmentForm = ({
  setIsAssessmentModalOpen,
  assessmentBasicDetails,
  formActionButtonLabel,
  buttonIcon,
  modalType,
  refetch,
  language,
  errorType,
  setErrorType,
  setActionInProgress,
}: Props) => {
  const courseId = parseInt(useParams().id as string);
  // Create add new Assessment Mutation
  const addAssessmentMutation = useAddAssessmentMutation();
  const addAssessmentInMultiLangMutation = useAddAssessmentInMultiLangMutation();

  // Create update Assessment Mutation
  const updateAssessmentMutation = useUpdateAssessmentMutation();
  const { assessmentModal } = useStrapiHQAdminCoursesData();
  const assessmentFormSchema = getSchema(assessmentModal);
  const {
    nameLabel,
    namePlaceholder,
    durationLabel,
    durationPlaceholder,
    descriptionLabel,
    descriptionPlaceholder,
    passPercentageLabel,
    passPercentagePlaceholder,
    assessmentRequiredCheckboxLabel,
    cancelButton,
  } = assessmentModal;
  type ASSESSMENT_FORM_DATA = yup.InferType<typeof assessmentFormSchema>;

  // Form initialization
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ASSESSMENT_FORM_DATA>({
    resolver: yupResolver(assessmentFormSchema),
  });

  // State to manage field disabling
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);

  // Map the percentage to the Option format
  const PASS_PERCENTAGE_OPTIONS = useMemo(() => {
    let options = [...new Array(9)].fill(0).map((_, index) => {
      const value: number = ++index * 10;
      return {
        label: `${formatNumber(value).toString()}%`,
        value: value || null,
      };
    });

    // Select label
    options = [
      ...options,
      {
        label: passPercentagePlaceholder,
        value: null,
      },
    ];

    return options;
  }, []);

  useEffect(() => {
    const setCreateAssessmentValues = () => {
      if (assessmentBasicDetails?.data) {
        setValue('passingPercent', assessmentBasicDetails.data.passingPercent * 100);
        setValue('isRequired', assessmentBasicDetails.data.isRequired);
        setValue('assessmentDuration', assessmentBasicDetails.data.timeLimit);
      }
    };

    const setEditAssessmentValues = () => {
      if (assessmentBasicDetails?.data) {
        setValue('name', assessmentBasicDetails.data.name || '');
        setValue('description', assessmentBasicDetails.data.description || '');
        setValue('passingPercent', assessmentBasicDetails.data.passingPercent * 100);
        setValue('isRequired', assessmentBasicDetails.data.isRequired ?? false);
        setValue('assessmentDuration', assessmentBasicDetails.data.timeLimit || undefined);
      }
    };

    reset();
    if (assessmentBasicDetails?.data) {
      if (modalType === 'manage' && errorType === COURSES_ERROR_CODE.ASSESSMENT_ID_NOT_FOUND) {
        // Handle Create Mode
        setIsFieldDisabled(true); // Disable fields in create mode
        setCreateAssessmentValues(); // Prepopulate fields in create mode
      } else if (modalType === 'manage' || modalType === 'update') {
        setIsFieldDisabled(modalType === 'manage'); // Disable fields in manage mode, enable in update mode
        setEditAssessmentValues(); // Set all values for manage or update mode
      }
    }
  }, [assessmentBasicDetails?.data, errorType, modalType, setValue, reset]);

  const addAssessmentHandler = (data: ASSESSMENT_FORM_DATA) => {
    const params = {
      courseId: courseId,
      moduleName: data.name,
      moduleType: MODULE_KEYS.ASSESSMENT,
      description: data.description,
      passingPercent: data.passingPercent,
      isRequired: data.isRequired,
      timeLimit: data.assessmentDuration,
    };

    setActionInProgress('add');
    // add new assessment
    addAssessmentMutation.mutate(params, {
      onSuccess: () => {
        refetch(); // refetch assessment basic details
        // Close modal if the mutation is successful
        setIsAssessmentModalOpen(false);
      },
      onSettled: () => {
        setActionInProgress(null);
      },
    });
    return;
  };

  const addAssessmentInMultiLangHandler = (data: ASSESSMENT_FORM_DATA) => {
    const params: AssessmentInMultiLangArgs = {
      courseModuleId: assessmentBasicDetails?.data.id,
      moduleName: data.name,
      moduleType: MODULE_KEYS.ASSESSMENT,
      description: data.description,
      language: language,
    };

    setActionInProgress('add');
    // add new assessment
    addAssessmentInMultiLangMutation.mutate(params, {
      onSuccess: () => {
        refetch(); // refetch assessment basic details
        setErrorType && setErrorType(null);
        // Close modal if the mutation is successful
        setIsAssessmentModalOpen(false);
      },
      onSettled: () => {
        setActionInProgress(null);
      },
    });
    return;
  };

  const updateAssessmentHandler = (data: ASSESSMENT_FORM_DATA) => {
    if (assessmentBasicDetails?.data) {
      const params = {
        moduleId: assessmentBasicDetails.data.id,
        moduleName: data.name,
        moduleType: MODULE_KEYS.ASSESSMENT,
        description: data.description,
        passingPercent: data.passingPercent,
        isRequired: data.isRequired,
        timeLimit: data.assessmentDuration,
        language: language,
      };

      setActionInProgress('update');
      updateAssessmentMutation.mutate(params, {
        onSuccess: () => {
          refetch(); // refetch modules and assessment list
          // Close modal if the mutation is successful
          setIsAssessmentModalOpen(false);
        },
        onSettled: () => {
          setActionInProgress(null);
        },
      });
    }
    return;
  };

  return (
    <form
      className="flex flex-col gap-6"
      onSubmit={(e) => {
        e.preventDefault();
        if (modalType === 'add') {
          handleSubmit(addAssessmentHandler)(e);
        } else if (modalType === 'update') {
          handleSubmit(updateAssessmentHandler)(e);
        } else if (modalType === 'manage') {
          if (errorType === COURSES_ERROR_CODE.ASSESSMENT_ID_NOT_FOUND) {
            handleSubmit(addAssessmentInMultiLangHandler)(e);
          } else {
            handleSubmit(updateAssessmentHandler)(e);
          }
        }
      }}
    >
      {/* Assessment Name */}
      <FormFieldWrapper>
        <Label htmlFor="assessment_name" label={nameLabel}></Label>
        <TextInput
          id="assessment_name"
          placeholder={namePlaceholder}
          type="text"
          className="border border-zinc-700 bg-zinc-900"
          aria-invalid={fieldHasError(errors, 'name') ? 'true' : 'false'}
          hasError={fieldHasError(errors, 'name')}
          {...register('name')}
        />
        <HookFormErrorMessage
          name="name"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </FormFieldWrapper>

      {/* Assessment Estimated Duration */}
      <FormFieldWrapper className={cn(isFieldDisabled && 'opacity-50')}>
        <Label htmlFor="assessment_duration" label={durationLabel}></Label>
        <TextInput
          id="assessment_duration"
          placeholder={durationPlaceholder}
          type="text"
          className="border border-zinc-700 bg-zinc-900"
          aria-invalid={fieldHasError(errors, 'assessmentDuration') ? 'true' : 'false'}
          hasError={fieldHasError(errors, 'assessmentDuration')}
          disabled={isFieldDisabled}
          {...register('assessmentDuration')}
        />
        <HookFormErrorMessage
          name="assessmentDuration"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </FormFieldWrapper>

      {/* Description Input */}
      <FormFieldWrapper>
        <Label htmlFor="assessment_description" label={descriptionLabel}></Label>
        <TextAreaInput
          id="assessment_description"
          placeholder={descriptionPlaceholder}
          className="border border-zinc-700 bg-zinc-900"
          rows={6}
          hasError={fieldHasError(errors, 'description')}
          aria-invalid={fieldHasError(errors, 'description') ? 'true' : 'false'}
          {...register('description')}
        ></TextAreaInput>
        <HookFormErrorMessage
          name="description"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </FormFieldWrapper>

      {/* Pass Percentage Input */}
      <FormFieldWrapper className={cn(isFieldDisabled && 'opacity-50')}>
        <Label htmlFor="pass_percentage" label={passPercentageLabel}></Label>
        <DropdownInput
          type="select"
          id="pass_percentage"
          placeholder={PASS_PERCENTAGE_OPTIONS[0].label}
          className="gap-2 rounded border !border-zinc-700 bg-zinc-900 !p-2 invalid:text-zinc-500"
          options={PASS_PERCENTAGE_OPTIONS}
          aria-invalid={fieldHasError(errors, 'passingPercent') ? 'true' : 'false'}
          hasError={fieldHasError(errors, 'passingPercent')}
          disabled={isFieldDisabled}
          {...register('passingPercent')}
        />
        <HookFormErrorMessage
          name="passingPercent"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </FormFieldWrapper>

      <div className={cn('flex items-center gap-[6px]', isFieldDisabled && 'opacity-50')}>
        <CheckboxInput
          className="grow-0"
          id="required_assessment"
          type="checkbox"
          disabled={isFieldDisabled}
          {...register('isRequired')}
        />
        <Label
          htmlFor="required_assessment"
          label={assessmentRequiredCheckboxLabel}
          className="text-xs font-normal text-zinc-300"
        />
      </div>

      {/* Action Buttons */}
      <div className="flex flex-col items-center justify-end gap-2 xs:flex-row">
        <Dialog.Close
          type="button"
          className="rounded bg-transparent py-2.5 px-[30px] text-center text-sm font-medium text-white"
        >
          {cancelButton}
        </Dialog.Close>
        <FormActionButton
          type="submit"
          className="bg-base-brand disabled:opacity-80"
          disabled={
            // When the mutation is in progress
            addAssessmentMutation.isLoading ||
            updateAssessmentMutation.isLoading ||
            addAssessmentInMultiLangMutation.isLoading
          }
        >
          {addAssessmentMutation.isLoading ||
          updateAssessmentMutation.isLoading ||
          addAssessmentInMultiLangMutation.isLoading ||
          assessmentBasicDetails?.isLoading ? (
            <div className="flex gap-2">
              <span>{formActionButtonLabel}</span>
              <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <span>{formActionButtonLabel}</span>
              {buttonIcon}
            </div>
          )}
        </FormActionButton>
      </div>
    </form>
  );
};

export default AssessmentForm;
