import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useReduxAuthState } from '../../../hooks';
import { UpdateTenantMFAConfigurationParams, updateTenantMFAConfiguration } from '../api';
import { useStrapiSettingsData } from './useStrapiSettingsData';
import { getCurrentAuthenticatedUser } from '../../auth/utils';
import { showToast } from '../../shared/helpers/toast';

const useUpdateTenantMFAConfigMutation = () => {
  const queryClient = useQueryClient();
  const auth = useReduxAuthState();

  // strapi
  const { enterpriseAdminMfaMandate: mfaMandateCMS } = useStrapiSettingsData();
  // strapi

  const mutation = useMutation({
    mutationFn: async (params: UpdateTenantMFAConfigurationParams) => {
      const response = await updateTenantMFAConfiguration(params);
      return response;
    },
    onMutate: () => {
      const toastId = showToast({
        variant: 'info',
        title: mfaMandateCMS.orgMFAConfigUpdateInProgressToast,
      });

      return {
        toastId: toastId,
      };
    },
    onSuccess: async (_, variables, context) => {
      const { mfaConfig } = variables;

      // Update the local query data for the tenant mfa configuration
      queryClient.setQueryData(
        [
          {
            scope: 'settings',
            item: 'tenant-mfa-configuration',
            // we should have the user here since at this point the user is authenticated
            tenantId: auth.user?.['custom:tenant_id'],
          },
        ],
        () => {
          return {
            mfaConfig: mfaConfig,
          };
        }
      );

      // After tenant mfa config is updated refresh the tokens
      try {
        // Will fetch new tokens after the MFA is updated
        await getCurrentAuthenticatedUser({ bypassCache: true });
      } catch (error) {
        // The profile has been updated but refreshing the cognito session failed
        console.error('Error updating the cognito user');
      }

      toast.remove(context?.toastId);
      showToast({
        variant: 'success',
        title: mfaMandateCMS.orgMFAUpdateSuccessToast,
      });
    },
    onError: async (_err, _params, context) => {
      toast.remove(context?.toastId);
      showToast({
        variant: 'error',
        title: mfaMandateCMS.orgMFAUpdateFailureToast,
      });
    },
  });

  return mutation;
};

export default useUpdateTenantMFAConfigMutation;
