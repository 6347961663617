import { chimeMessagingServiceContext } from '../context';
import { useChimeMessagingSession } from '../hooks';

const ChimeMessagingServiceProvider = ({ children }: React.PropsWithChildren) => {
  const { closeSession, subscribeToMessageUpdate, unsubscribeFromMessageUpdate } =
    useChimeMessagingSession();

  return (
    <chimeMessagingServiceContext.Provider
      value={{ closeSession, subscribeToMessageUpdate, unsubscribeFromMessageUpdate }}
    >
      {children}
    </chimeMessagingServiceContext.Provider>
  );
};

export default ChimeMessagingServiceProvider;
