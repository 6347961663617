import clsx from 'clsx';
import { useContext } from 'react';
import sideBarContext from './context/sideBarContext';

interface Props {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

// Action Item to be used for items that are not used for navigation
// Like collapsible
const SideBarActionItem = ({ className, children, onClick }: React.PropsWithChildren<Props>) => {
  const { isSidebarCollapsed } = useContext(sideBarContext);

  return (
    <div
      onClick={onClick}
      className={clsx(
        'flex grow items-center gap-4 rounded-sm p-2',
        isSidebarCollapsed && 'justify-center rounded-full p-1',
        className
      )}
    >
      {children}
    </div>
  );
};

export default SideBarActionItem;
