import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';

import type { BarChartOptions } from '../../../../dashboard/types';
import { defaultGridOptions, defaultTickOptions } from '../../../config';

// Register components with chartjs
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

interface Props {
  className?: string;
  options?: BarChartOptions;
  data: ChartData<'bar', number[], unknown>;
}

const chartOptions: BarChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  datasets: {
    bar: {
      barThickness: 13,
    },
  },
  scales: {
    x: {
      grid: {
        ...defaultGridOptions,
      },
      ticks: {
        ...defaultTickOptions,
      },
    },
    y: {
      grid: {
        ...defaultGridOptions,
      },
      ticks: {
        ...defaultTickOptions,
      },
    },
  },
};

const BarChart = ({ className, options, data }: Props) => {
  return (
    <div className={clsx('', className)}>
      <Bar options={options ?? chartOptions} data={data} />
    </div>
  );
};

export default BarChart;
