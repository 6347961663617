import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import CommonTextCell from './TableCell/CommonTextCell';
import ListSpeakersTableHeaderRow from './ListIndividualTableHeaderRow';
import ListSpeakersTableDataRow from './ListIndividualTableDataRow';
import { ListIndividualsResults } from '../../../types/GetIndividualApiResponse';
import { BasicSpinner } from '../../../../../components/Spinners';
import { useStrapiOrganizationData } from '../../../../organisation/hooks/useStrapiOrganizationData';
import TimeStampCell from './TableCell/TimeStampCell';
import HealthRiskCell from './TableCell/HealthRiskCell';

interface Props {
  className?: string;
  isDataLoading?: boolean;
  data: ListIndividualsResults[];
}
interface TableHeading {
  emailTableHeader: string;
  paymentModeTableHeader: string;
  individualTableHeader: string;
  healthRiskTableHeader: string;
  signUpTableHeader: string;
}

const getSchema = (data: TableHeading) => {
  const {
    emailTableHeader,
    paymentModeTableHeader,
    individualTableHeader,
    healthRiskTableHeader,
    signUpTableHeader,
  } = data;
  const columnHelper = createColumnHelper<ListIndividualsResults>();
  const columns = [
    columnHelper.accessor('name', {
      header: individualTableHeader,
      cell: ({ getValue }) => <CommonTextCell text={getValue() as string} isLowercase={false} />,
    }),
    columnHelper.accessor('email', {
      header: emailTableHeader,
      cell: ({ getValue }) => <CommonTextCell text={getValue() as string} isLowercase={true} />,
    }),
    columnHelper.accessor('createdOn', {
      header: signUpTableHeader,
      cell: ({ getValue }) => <TimeStampCell timestamp={getValue() as string} />,
    }),
    columnHelper.accessor('paymentMode', {
      header: paymentModeTableHeader,
      cell: ({ getValue }) => <CommonTextCell text={getValue() as string} />,
    }),
    columnHelper.accessor('healthStatus', {
      header: healthRiskTableHeader,
      cell: ({ getValue }) => <HealthRiskCell status={getValue() as string} />,
    }),
  ];
  return columns;
};
const ListIndividualTable = ({ className, data, isDataLoading }: Props) => {
  const { individualTable } = useStrapiOrganizationData();
  const columns = getSchema(individualTable);
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiSort: true,
    data,
  });

  return (
    <table className={clsx('w-full', className)}>
      <thead className="w-full">
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <ListSpeakersTableHeaderRow header={header} key={header.id} isSortable={false} />
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {isDataLoading ? (
          <BasicSpinner />
        ) : (
          getRowModel().rows.map((row) => (
            <ListSpeakersTableDataRow
              className="cursor-pointer hover:bg-page-bg-dark/20"
              row={row}
              key={row.id}
            />
          ))
        )}
      </tbody>
    </table>
  );
};

export default ListIndividualTable;
