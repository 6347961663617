import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { USHGAPIError } from '../../../types';
import { getAssessmentQuestions } from '../api';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';

interface Props {
  assessmentId?: number;
  language?: string;
}
/**
 * Wrapper around the react querys useQuery to fetch the assessment questions.
 */
const useAssessmentQuestionsQuery = ({
  assessmentId,
  language = DEFAULT_PREFERRED_LANGUAGE,
}: Props) => {
  const [errorType, setErrorType] = useState<string | null>();
  const { isLoading, data, isError, refetch, isRefetching, isFetching } = useQuery(
    [
      {
        assessmentId: assessmentId,
        scope: 'assessment',
        item: 'assessment-questions',
      },
    ],
    async () => {
      if (assessmentId) {
        return await getAssessmentQuestions({ assessmentId, language });
      }
    },
    {
      enabled: assessmentId !== undefined,
      onError: (error) => {
        // If axios error
        if (axios.isAxiosError(error)) {
          const data = error.response?.data;
          if (data) {
            const { errorCode } = data as USHGAPIError;
            setErrorType(errorCode);
          }
        }
      },
    }
  );
  return { isLoading, data, isError, refetch, isRefetching, isFetching, errorType };
};

export default useAssessmentQuestionsQuery;
