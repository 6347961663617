import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppSelector, useReduxAuthState, useShouldUserRegister } from '../../../../../../hooks';
import UserRegistrationForm from './UserRegistrationForm';
import { Navigate, useLocation } from 'react-router-dom';
import {
  ExtendedRegistration,
  OrganizationInvite,
  Registration,
  BasicDetails,
  CompanyDetails,
  SubscriptionType,
  Tenure,
  Roles,
  EnterpriseRegistration,
} from '../../../../types';
import { LOCAL_STORAGE_USHG_HQ_ENTERPRISE_INVITE, ROLES } from '../../../../constants';
import { useDispatch } from 'react-redux';
import { setPricingInfo, setTenure } from '../../../../slices/pricing.slice';
import UserRegistrationFailure, {
  UserRegistrationFailureType,
} from '../../UserRegistrationActionResponse/UserRegistrationFailure';
import axios from 'axios';
import { getOrganizationInvite } from '../../../../helpers/register';
import { USHGAPIError } from '../../../../../../types';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { readFromLocalStorage } from '../../../../../../utils';
import getRegistrationData from '../../../../helpers/localStorageRegistrationData';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { USHG_HQ_APP_ROLE } from '../../../../../../constants';

const UserRegistrationMain = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const auth = useReduxAuthState();
  const shouldUserRegister = useShouldUserRegister();

  const [errorType, setErrorType] = useState<UserRegistrationFailureType | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const strapiData = useStrapiDataHelper();
  const { loadingEnterpriseAccountMessage } = strapiData.registration.data.attributes;
  const [registrationFormData, setRegistrationFormData] = useState<
    ExtendedRegistration | Partial<Registration>
  >();

  const [inviteOrganizationResponse, setInviteOrganizationResponse] =
    useState<Partial<EnterpriseRegistration>>();

  const requestStartedRef = useRef<boolean>(false);

  // get role from redux state and render registration form based on the role here
  const role = useAppSelector(useCallback((state) => state.pricing.pricing.role, []));

  // Enterprise invite params
  const queryParams = new URLSearchParams(location.search);
  const inviteId = queryParams.get('inviteId');
  const email = queryParams.get('email');

  // session Id from Stripe
  const sessionId = queryParams.get('session_id');

  useEffect(() => {
    if (auth.isAuthenticated && shouldUserRegister) {
      if (auth.user.role === USHG_HQ_APP_ROLE.INDIVIDUAL_USER) {
        // dispatch pricing info
        dispatch(
          setPricingInfo({
            freeTrail: true,
            subscriptionType: 'basic',
            role: ROLES.INDIVIDUAL,
          })
        );

        dispatch(setTenure('month'));

        // build registration data
        const registrationFormData = {
          basicDetails: {
            personalEmail: auth.user.email,
          } as Partial<BasicDetails>,
          subscriptionType: 'basic',
        } as Partial<EnterpriseRegistration>;

        setRegistrationFormData(registrationFormData);
      } else if (auth.user.role === USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN) {
        // dispatch pricing info
        dispatch(
          setPricingInfo({
            freeTrail: true,
            subscriptionType: 'team',
            role: ROLES.TEAM,
          })
        );

        dispatch(setTenure('month'));

        // build registration data
        const registrationFormData = {
          basicDetails: {
            corporateEmail: auth.user.email,
          } as Partial<BasicDetails>,
          subscriptionType: 'basic',
        } as Partial<EnterpriseRegistration>;

        setRegistrationFormData(registrationFormData);
      }
    }
  }, [auth, dispatch, shouldUserRegister]);

  useEffect(() => {
    const getOrganizationInviteRequest = async () => {
      if (inviteId == null || email == null) {
        setErrorType('SEARCH_PARAMS_MISSING');
        setIsLoading(false);
        return;
      }
      try {
        requestStartedRef.current = true;
        setIsLoading(true);
        const params: Parameters<typeof getOrganizationInvite>[0] = {
          inviteId,
          email,
        };
        const invite: OrganizationInvite = await getOrganizationInvite(params);

        if (invite && Object.keys(invite ?? {}).length > 0) {
          // dispatch pricing info
          dispatch(
            setPricingInfo({
              freeTrail: false,
              subscriptionType: invite?.setting?.plan as SubscriptionType,
              role: ROLES.ENTERPRISE,
            })
          );
          dispatch(setTenure(invite?.setting?.paymentCycle as Tenure));

          // build registration data
          const registration = {
            basicDetails: {
              corporateEmail: invite?.adminEmail,
            } as Partial<BasicDetails>,
            companyDetails: {
              activeLearners: invite?.setting?.activeLearners,
              companyName: invite?.setting?.organizationName,
              companySize: invite?.setting?.organizationSize,
              department: invite?.setting?.department,
            } as Partial<CompanyDetails>,
            subscriptionType: invite?.setting?.plan,
            inviteId,
          } as Partial<EnterpriseRegistration>;
          setRegistrationFormData(registration);

          // cache enterprise invite registration response to localStorage
          localStorage.setItem(
            LOCAL_STORAGE_USHG_HQ_ENTERPRISE_INVITE,
            JSON.stringify(registration)
          );

          // read enterprise invite registration data from localStorage and set to local state
          readEnterpriseInviteFromLocalStorage();
        }
      } catch (error) {
        // If axios error
        if (axios.isAxiosError(error)) {
          const data = error.response?.data;
          if (data) {
            const { errorCode } = data as USHGAPIError;
            switch (errorCode) {
              case 'INVITE_NOT_FOUND': {
                setErrorType(errorCode);
                break;
              }
              default: {
                setErrorType('UNKNOWN_ERROR');
                break;
              }
            }
          } else {
            setErrorType('UNKNOWN_ERROR');
          }
        } else {
          setErrorType('UNKNOWN_ERROR');
        }
      }
      setIsLoading(false);
    };

    if (!requestStartedRef.current && inviteId && email) {
      getOrganizationInviteRequest();
    }

    // from stripe checkout session
    // populate form with the cached localStorage data
    if (sessionId) {
      // Get Registration Data from localStorage if exists
      const registrationDataFromLocalStorage = getRegistrationData();
      if (
        registrationDataFromLocalStorage &&
        Object.keys(registrationDataFromLocalStorage ?? {}).length > 0
      ) {
        // dispatch pricing info
        dispatch(
          setPricingInfo({
            freeTrail: registrationDataFromLocalStorage.freeTrail,
            subscriptionType: registrationDataFromLocalStorage.subscriptionType,
            role: registrationDataFromLocalStorage.role as Roles,
          })
        );
        setRegistrationFormData(registrationDataFromLocalStorage);

        // read enterprise invite registration data from localStorage and set to local state
        readEnterpriseInviteFromLocalStorage();
      }
      setIsLoading(false);
    }
  }, [location.search]);

  // helper
  const readEnterpriseInviteFromLocalStorage = () => {
    const enterpriseInviteLocalStorage = readFromLocalStorage(
      LOCAL_STORAGE_USHG_HQ_ENTERPRISE_INVITE
    );
    if (
      enterpriseInviteLocalStorage &&
      Object.keys(enterpriseInviteLocalStorage || {}).length > 0
    ) {
      setInviteOrganizationResponse(enterpriseInviteLocalStorage);
    }
  };
  const isPublicSite = queryParams.get('publicsite');
  const publicSiteRole = queryParams.get('role');
  const publicSitesubscriptionType = queryParams.get('subscriptionType');
  const publicSiteFreetrail = queryParams.get('freeTrail');
  const publicSiteTenure = queryParams.get('tenure');
  if (isPublicSite) {
    const payload = {
      role: publicSiteRole as Roles,
      freeTrail: publicSiteFreetrail === 'true' ? true : false,
      subscriptionType: publicSitesubscriptionType as SubscriptionType,
    };
    dispatch(setPricingInfo(payload));
    dispatch(setTenure(publicSiteTenure as Tenure));
    return <Navigate to="/auth/register/subscription" />;
  }

  if (auth.isAuthenticated && !shouldUserRegister) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  // Loading State
  if (isLoading && location.search && !isPublicSite) {
    return (
      <div className="flex flex-col items-center gap-5 pt-[6.5vw]">
        <div className="flex max-w-lg flex-col items-center gap-3">
          <BasicSpinner className="h-6 w-6 text-base-brand" />
          <span className="text-lg font-normal leading-[24px] text-zinc-50">
            {loadingEnterpriseAccountMessage}
          </span>
        </div>
      </div>
    );
  }

  // Error Message
  if (!isLoading && errorType && location.search) {
    return (
      <div className="absolute top-1/2 left-1/2 w-full -translate-x-1/2 -translate-y-1/2 p-4">
        <UserRegistrationFailure type={errorType} />
      </div>
    );
  }

  // render form if there is a role set or when registrationFormData has values
  const renderForm = role || Object.keys(registrationFormData ?? {}).length > 0;

  if (renderForm) {
    return (
      <UserRegistrationForm
        disablePopulatedFields={
          Object.keys(inviteOrganizationResponse ?? {}).length > 0 || auth.isAuthenticated
        }
        registrationFormData={registrationFormData}
        role={role}
        enterpriseInvite={inviteOrganizationResponse}
      />
    );
  }
  return <Navigate to="/auth/register/pricing" />;
};

export default UserRegistrationMain;
