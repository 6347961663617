/* eslint-disable */
import { useEffect, useCallback, useRef } from 'react';
import useAppSelector from './use-app-selector';

interface VideoDetails {
  courseTitle: string;
  videoTitle: string;
}

const useSessionVideoTracker = (
  videoRef: React.RefObject<HTMLVideoElement>,
  videoDetails: VideoDetails
) => {
  const user = useAppSelector(useCallback((state) => state.auth.user, []));
  const username = user?.name || '';
  const email = user?.email || '';

  const startTimeRef = useRef<number | null>(null); // Ref to track start time
  const watchedTimeRef = useRef<number>(0); // Ref to accumulate watched time
  const isPausedRef = useRef<boolean>(false); // Ref to track if the video is paused

  // Handle Video Play
  const handlePlay = useCallback(() => {
    // Start timing only if the video was not already playing
    if (!startTimeRef.current) {
      startTimeRef.current = Date.now();
      isPausedRef.current = false; // Mark the video as playing
    }
  }, []);

  // Track pause event to accumulate watched time but don't send data yet
  const handlePause = useCallback(() => {
    if (startTimeRef.current && videoRef.current) {
      const currentTime = Date.now();
      const elapsedTime = (currentTime - startTimeRef.current) / 1000; // Convert milliseconds to seconds
      watchedTimeRef.current += elapsedTime; // Accumulate the watched time in ref
      startTimeRef.current = null; // Reset the start time because the video is paused
      isPausedRef.current = true; // Mark the video as paused
    }
  }, [videoRef]);

  // Track time updates to accumulate the playtime when the video ends
  const handleEnded = useCallback(() => {
    if (startTimeRef.current && videoRef.current) {
      const currentTime = Date.now();
      const elapsedTime = (currentTime - startTimeRef.current) / 1000;
      watchedTimeRef.current += elapsedTime; // Accumulate the watched time in ref
      startTimeRef.current = null; // Reset start time because video ended
      isPausedRef.current = true; // Treat the video as paused since it has ended
    }
  }, [videoRef]);

  // Send session data to HubSpot only if the user paused and left the tab
  const sendSessionDataToHubSpot = useCallback(() => {
    // Only send data if the video was paused (or ended) and there's watched time to report
    if (watchedTimeRef.current > 0) {
      const totalWatchedTime = watchedTimeRef.current;
      const sessionData = {
        watchedTime: totalWatchedTime,
        videoDetails,
        username,
        timestamp: new Date().toISOString(),
      };

      if (window._hsq) {
        window._hsq.push([
          'identify',
          {
            email: email || '',
          },
        ]);
        window._hsq.push([
          'trackCustomBehavioralEvent',
          {
            name: 'pe46252984_hqplus_courses_custom_event',
            properties: {
              watch_time_seconds_: sessionData.watchedTime,
              video_title: sessionData.videoDetails.videoTitle,
              course_title: sessionData.videoDetails.courseTitle,
              user_name: sessionData.username,
              time_stamp: sessionData.timestamp,
            },
          },
        ]);
      } else {
      }
    } else {
    }
  }, [videoDetails, username, email]);

  // Handle Before Unload to send session data if the user leaves the tab after pausing
  const handleBeforeUnload = useCallback(() => {
    sendSessionDataToHubSpot();
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      handleBeforeUnload();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);

  // Attach event listeners to the video element for play, pause, and ended
  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('play', handlePlay);
      videoElement.addEventListener('pause', handlePause);
      videoElement.addEventListener('ended', handleEnded);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('play', handlePlay);
        videoElement.removeEventListener('pause', handlePause);
        videoElement.removeEventListener('ended', handleEnded);
      }
    };
  }, [handlePlay, handlePause, handleEnded, videoRef]);

  return { sendSessionDataToHubSpot };
};

export default useSessionVideoTracker;
