import { filterType } from '../types/Filter.Helper';
import { useQuery } from '@tanstack/react-query';
import { getRecommendedCourses } from '../api/courses';
import { useEffect, useState } from 'react';
import { RECOMMENDED_COURSE_LIMIT } from '../constants/course';

type useFetchRecommendedCoursesQueryProps = {
  type: string;
  filters: filterType[];
  enabled: boolean;
};
const useRecommendedCoursesFetch = ({
  type,
  filters,
  enabled,
}: useFetchRecommendedCoursesQueryProps) => {
  const { isLoading, error, data, isError } = useQuery({
    queryKey: ['courses', 'recommended', type, filters],
    queryFn: () => getRecommendedCourses(filters, 0, type, RECOMMENDED_COURSE_LIMIT),
    enabled,
  });
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    if (data) {
      setTotalCount(data.count);
    }
  }, [data]);
  return { isLoading, error, data, totalCount, isError };
};

export default useRecommendedCoursesFetch;
