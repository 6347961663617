import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { TEAMS_API_PATHS } from '../constants';
import { AllTeams, PartialEnterpriseAdminTeamsFilter, Team } from '../types/interface';
/**
 * API for getting all teams for admin
 * @param filters
 * @returns list of teams for the admin
 */
const getAllTeams = async (): Promise<AllTeams[]> => {
  const path = TEAMS_API_PATHS.ENTERPRISE_ADMIN.GET_ALL_TEAM_NAME;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}`, {
    response: true,
  });
  return response.data;
};
const getEnterpriseAdminTeams = async (
  filters: PartialEnterpriseAdminTeamsFilter
): Promise<{ rows: Team[]; count: number }> => {
  const queryString = qs.stringify(filters);
  const path = TEAMS_API_PATHS.ENTERPRISE_ADMIN.GET_ALL_TEAMS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
  });
  return response.data;
};
/**
 * Add Teams by admin
 * @param TeamData{team[{teamName,members,parentId},leader[]]}
 * @returns success or error message
 */
const addEnterpriseTeams = async (args: any) => {
  const { teamName, members, parentId } = args;
  let team;
  if (parentId) {
    team = [{ teamName, members, parentId }];
  } else {
    team = [{ teamName, members }];
  }
  const body = {
    team,
    leader: [],
  };
  const path = TEAMS_API_PATHS.ENTERPRISE_ADMIN.ADD_NEW_TEAM;
  const response: any = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?`, {
    response: true,
    body,
  });
  return response;
};

export interface SendTeamRequestsArgs {
  requestType: string;
  teamName: string;
  members?: {
    email: string;
    jobTitle: string;
    role: string;
  }[];
  parentId?: number;
  // Used for Team Edit
  teamId?: number;
  isEditTeamRequest?: boolean;
}

/**
 * Send request to add Team| add Member | Edit Team
 */
const sendTeamRequests = async (args: SendTeamRequestsArgs) => {
  const { requestType, teamName, members, parentId, teamId, isEditTeamRequest = false } = args;

  const teamMembers = members?.map((m) => ({
    memberEmail: m.email,
    userType: m.role,
    jobTitle: m.jobTitle,
  }));

  let body;

  if (isEditTeamRequest) {
    body = {
      requestType,
      teamId,
      teamName,
    };
  } else {
    body = {
      requestType: requestType,
      team: [
        {
          teamName,
          members: teamMembers,
          parentTeamId: parentId,
        },
      ],
    };
  }

  const path = TEAMS_API_PATHS.ENTERPRISE_ADMIN.ADD_TEAM_REQUEST;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?`, {
    response: true,
    body,
  });

  return response;
};
/**
 *Edit team by admins
 * @param teamId
 * @returns success or error message
 */
const editEnterpriseTeams = async (teamId: number, teamName: string) => {
  const body = {
    teamId: teamId,
    teamName: teamName,
  };
  const path = TEAMS_API_PATHS.ENTERPRISE_ADMIN.ADD_NEW_TEAM;
  const response = await API.patch(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}`, {
    response: true,
    body,
  });
  return response.data;
};

export {
  getEnterpriseAdminTeams,
  addEnterpriseTeams,
  editEnterpriseTeams,
  sendTeamRequests,
  getAllTeams,
};
