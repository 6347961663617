import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

const useStrapiMembersData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const strapiData = data.allmember.data.attributes;
  const loadingMessages = strapiData.LoadingMessages;
  const toastMessage = strapiData.ToastMessage;
  const bulkUploadModal = strapiData.BulkUploadModal;
  const activateModal = strapiData.activatedModal;
  const topNav = strapiData.TopNav;
  const deactivateModal = strapiData.deactivatedModal;
  const membersTable = strapiData.Table;
  const subscriptionInfoModal = strapiData.subscriptionInfoModal;
  const trialLimitOverModal = strapiData.trialLimitOverModals;
  const errorMessage = strapiData.ErrorMessages;
  const disableMFAUserDialog = strapiData.DisableMFAUserDialog;
  const userRoleUpdate = strapiData.UserRoleUpdate;
  const userTeamChange = strapiData.userTeamChange;

  return {
    topNav,
    errorMessage,
    loadingMessages,
    toastMessage,
    bulkUploadModal,
    deactivateModal,
    activateModal,
    subscriptionInfoModal,
    membersTable,
    trialLimitOverModal,
    disableMFAUserDialog,
    userRoleUpdate,
    userTeamChange,
  };
};
export default useStrapiMembersData;
