import { PartialEnterpriseLeaderTeamManagerFilter, TeamMemberResponse } from '../types/interface';
import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';

const getEnterpriseLeaderTeamUsers = async (
  teamId: PartialEnterpriseLeaderTeamManagerFilter
): Promise<TeamMemberResponse> => {
  const path = '/users/teams/';
  const id = teamId.teamId;

  try {
    const response = await API.get(
      AMPLIFY_USHG_API_ENDPOINT_NAME,
      `${path}` + `${id}` + '/members',
      {
        response: true,
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export { getEnterpriseLeaderTeamUsers };
