import { useNavigate } from 'react-router-dom';
import { NoDataDisplay } from '../../../../../components/DataTables';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';

interface Props {
  isFilterApplied: boolean;
  className?: string;
}

const CEmptyState = ({ isFilterApplied, className }: Props) => {
  const navigate = useNavigate();
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const clearFilters = () => {
    navigate('.');
  };
  const { clearAllFiltersButton } = useStrapiSharedData().tableProps;
  if (isFilterApplied) {
    return (
      <NoDataDisplay
        className={className}
        message={
          <div className="flex flex-col items-center gap-8">
            <span>{strapiErrorMessage['NO_FILTERED_DATA']}</span>
            <button
              className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
              onClick={() => clearFilters()}
            >
              {clearAllFiltersButton}
            </button>
          </div>
        }
      />
    );
  }

  return <NoDataDisplay className={className} message={strapiErrorMessage['COURSE_NOT_FOUND']} />;
};

export default CEmptyState;
