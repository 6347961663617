import clsx from 'clsx';
import { Avatar } from 'react-daisyui';
import AcronymLogo from '../../../../../../components/AcronymnLogo';

interface AvatarAndNameCellProps {
  name: string;
  profilePictureURL?: string;
  className?: string;
}

const AvatarAndNameCell = (props: AvatarAndNameCellProps) => {
  const { name, profilePictureURL, className } = props;

  // Use user picture url if present else use the name image fallback
  return (
    <div className={clsx('flex items-center gap-2 whitespace-nowrap', className)}>
      {profilePictureURL && (
        <Avatar
          src={profilePictureURL}
          size={24}
          className="rounded-full border border-zinc-900"
          shape="circle"
          role="img"
          aria-label={name}
        ></Avatar>
      )}
      {!profilePictureURL && (
        <AcronymLogo
          aria-label={name}
          className="h-6 w-6 rounded-full border border-zinc-900 text-xs"
          wordsStr={name}
        />
      )}
      <span>{name}</span>
    </div>
  );
};

export default AvatarAndNameCell;
