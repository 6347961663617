import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorDisplay } from '../../../components/DataTables';
import { BasicSpinner } from '../../../components/Spinners';
import { ERROR_TYPES } from '../../../constants/error-types.constants';
import { LIST_SPEAKERS_DEFAULT_PAGE, LIST_SPEAKERS_MIN_PAGE_SIZE } from '../constants/common';
import useGetSpeakerListQuery from '../hooks/useGetSpeakerListQuery';
import { useSpeakerStrapiData } from '../hooks/useSpeakerStrapiData';
import {
  ASC,
  COURSES,
  DESC,
  FULL_NAME,
  GetSpeakerFilterQuery,
  JOB_TITLE,
  SPEAKER_ID,
} from '../types/GetSpeakerFilterQuery';
import ListSpeakerTable from './Table/components/ListSpeakerTable';
import TableEmptyState from './Table/components/TableEmptyState';
import ListSpeakerTableFooter from './Table/ListSpeakerTableFooter';
import ListSpeakerTableHeader from './Table/ListSpeakerTableHeader';

interface Props {
  className?: string;
}

const ListSpeakerTableMain = ({ className }: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const q = searchParams.get('q') ?? undefined;
  const isFilterApplied = q ? true : false;
  const sortOrder = searchParams.get('order') ?? undefined;
  const sortKey = searchParams.get('key') ?? undefined;
  const [page, setPage] = useState(LIST_SPEAKERS_DEFAULT_PAGE);
  const [size, setPageSize] = useState(LIST_SPEAKERS_MIN_PAGE_SIZE);
  const {
    errorMessages: { errorFetchingSpeakers },
  } = useSpeakerStrapiData();
  const filters = useMemo(() => {
    const query: GetSpeakerFilterQuery = {
      search: q as string,
      start: (page - 1) * size,
      size: size,
      sortKey: sortKey as SPEAKER_ID | FULL_NAME | COURSES | JOB_TITLE,
      sortOrder: sortOrder as ASC | DESC,
    };
    if (!sortKey && !sortOrder) {
      delete query.sortOrder;
      delete query.sortKey;
    }
    return query;
  }, [q, size, sortKey, sortOrder, page]);
  const request = useGetSpeakerListQuery({ filters });
  const displayLoader =
    (request.isLoading && !request.data) || (request.isFetching && request.data?.totalCount === 0);
  const isEmptyState = request.data && request.data.totalCount == 0;

  return (
    <main
      id="main-content"
      className={clsx(
        'flex flex-col items-start gap-4 rounded-sm bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)] md:p-5 lg:p-6',
        className
      )}
    >
      <ListSpeakerTableHeader q={q} />
      {request.isLoading && !request.data && (
        <div className="flex w-full justify-center py-4">
          <BasicSpinner className="text-white" />
        </div>
      )}
      {request.data && (
        <div
          className={clsx(
            'flex w-full flex-col gap-4',
            request.isPreviousData && request.isFetching && 'pointer-events-none opacity-70'
          )}
        >
          <div className="w-full overflow-y-scroll">
            <ListSpeakerTable data={request.data.results} />
          </div>
          {!isEmptyState && (
            <ListSpeakerTableFooter
              setPage={setPage}
              activePage={page}
              totalItems={request.data.totalCount}
              pageSize={size}
              setPageSize={setPageSize}
            />
          )}
        </div>
      )}
      {isEmptyState && !displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <TableEmptyState isFilterApplied={isFilterApplied} />
        </div>
      )}
      {request.isError && !request.data && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <ErrorDisplay
            refetch={request.refetch}
            isRefetching={request.isRefetching}
            allowsRefetch={true}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            message={errorFetchingSpeakers}
          />
        </div>
      )}
    </main>
  );
};

export default ListSpeakerTableMain;
