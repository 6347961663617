import { $isLinkNode } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isAtNodeEnd } from '@lexical/selection';
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  LexicalNode,
  TextFormatType,
} from 'lexical';
import { ClockCounterClockwise, TextItalic, TextStrikethrough } from 'phosphor-react';
import { useCallback, useEffect, useState } from 'react';
import cn from '../../../../../utils/cn';
import { TOOLBAR_ICON_SIZE } from '../../../constants/rich-text';
import { useStrapiSharedData } from '../../../hooks/useStrapiSharedData';
import ToolbarActionButton from './ToolbarActionButton';
import ToolbarLinkActionButton from './ToolbarLinkActionButton';

type Props = {
  className?: string;
  isEditable?: boolean;
  revertChanges?: () => void;
};

const Toolbar = (props: Props) => {
  const { className, isEditable = true, revertChanges } = props;

  // context
  const [editor] = useLexicalComposerContext();
  // context

  // state
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isStrikeThrough, setIsStrikeThrough] = useState(false);
  const [_hasUnderline, setHasUnderline] = useState(false);
  const [_isLink, setIsLink] = useState(false);
  const [_hasSelection, setHasSelection] = useState(false);
  // state

  // strapi content
  const { RichTextEditor: richTextEditorCMS } = useStrapiSharedData();
  // strapi content

  // handlers
  const updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const isSelectionBold = selection.hasFormat('bold');
      setIsBold(isSelectionBold);
      const isSelectionItalic = selection.hasFormat('italic');
      setIsItalic(isSelectionItalic);
      const isStrikeThrough = selection.hasFormat('strikethrough');
      setIsStrikeThrough(isStrikeThrough);
      const hasUnderline = selection.hasFormat('underline');
      setHasUnderline(hasUnderline);

      const anchor = selection.anchor;
      const focus = selection.focus;

      const anchorNode = anchor.getNode();
      const focusNode = focus.getNode();

      const backward = selection.isBackward();

      let selectedNode: LexicalNode;

      if (anchorNode === focusNode) {
        selectedNode = anchorNode;
      } else {
        if (backward) {
          selectedNode = $isAtNodeEnd(focus) ? anchorNode : focusNode;
        } else {
          selectedNode = $isAtNodeEnd(anchor) ? focusNode : anchorNode;
        }
      }

      const parentNode = selectedNode.getParent();

      if ($isLinkNode(parentNode) || $isLinkNode(selectedNode)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      setHasSelection(anchor.offset !== focus.offset);
    }
  }, []);

  const dispatchFormatCommand = (formatType: TextFormatType) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);
  };

  const handleRevertChanges = useCallback(() => {
    revertChanges && revertChanges();
  }, [revertChanges]);

  // handlers

  // effects
  useEffect(() => {
    editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updateToolbar();
      });
    });
  }, [editor, updateToolbar]);

  // effects

  return (
    <div
      className={cn(
        'relative flex gap-2 border border-zinc-700 bg-component-bg-dark text-zinc-50',
        className
      )}
    >
      {/* Text Format Options */}
      <div className="flex gap-1">
        {/* Bold */}
        <ToolbarActionButton
          isActive={isBold}
          onClick={() => dispatchFormatCommand('bold')}
          tooltipContent={richTextEditorCMS.boldLabel}
          aria-label={richTextEditorCMS.boldLabel}
          disabled={!isEditable}
        >
          <span className={cn('text-sm leading-4', isBold && 'text-base-brand')} aria-label="Bold">
            B
          </span>
        </ToolbarActionButton>
        {/* Italic */}
        <ToolbarActionButton
          isActive={isItalic}
          onClick={() => dispatchFormatCommand('italic')}
          tooltipContent={richTextEditorCMS.italicLabel}
          aria-label={richTextEditorCMS.italicLabel}
          disabled={!isEditable}
        >
          <TextItalic
            size={TOOLBAR_ICON_SIZE}
            color="currentColor"
            className={cn('', isItalic && 'text-base-brand')}
            weight="regular"
          />
        </ToolbarActionButton>
        {/* Strike-Through */}
        <ToolbarActionButton
          isActive={isStrikeThrough}
          onClick={() => dispatchFormatCommand('strikethrough')}
          tooltipContent={richTextEditorCMS.strikeLabel}
          aria-label={richTextEditorCMS.strikeLabel}
          disabled={!isEditable}
        >
          <TextStrikethrough
            size={TOOLBAR_ICON_SIZE}
            color="currentColor"
            className={cn('', isStrikeThrough && 'text-base-brand')}
            weight="regular"
          />
        </ToolbarActionButton>
        {/* Text-Underline */}
        {/* <ToolbarActionButton
          isActive={hasUnderline}
          onClick={() => dispatchFormatCommand('underline')}
          tooltipContent={richTextEditorCMS.underlineLabel}
          aria-label={richTextEditorCMS.underlineLabel}
          disabled={!isEditable}
        >
          <TextUnderline
            size={TOOLBAR_ICON_SIZE}
            color="currentColor"
            className={cn('', hasUnderline && 'text-base-brand')}
            weight="regular"
          />
        </ToolbarActionButton> */}
      </div>
      {/* Separator */}
      <div className="h-[28px] w-[1px] bg-zinc-700"></div>
      <ToolbarLinkActionButton isEditable={isEditable} />
      {/* Reset changes */}
      <ToolbarActionButton
        isActive={false}
        onClick={handleRevertChanges}
        tooltipContent={richTextEditorCMS.resetLabel}
        aria-label={richTextEditorCMS.resetLabel}
        disabled={!isEditable}
        className={['justify-self-end', 'ml-auto']}
      >
        <ClockCounterClockwise size={TOOLBAR_ICON_SIZE} color="currentColor" weight="regular" />
      </ToolbarActionButton>
    </div>
  );
};

export default Toolbar;
