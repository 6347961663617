import { useAppSelector } from '../../../hooks';
import type { RootState } from '../../../store';

// types

type CMS = {
  learningJourney: LearningJourney;
};

type LearningJourney = {
  data: {
    attributes: Attributes;
  };
};

type Attributes = {
  ListAllLearningJourneys: ListAllLearningJourneys;
  LearningJourneyCard: LearningJourneyCard;
  LearningJourneyDetail: LearningJourneyDetail;
};

type LearningJourneyCard = {
  thumbnailAlt: string;
  coursesCountLabel: string;
  linkAriaLabel: string;
  inProgressBadgeLabel: string;
  notStartedBadgeLabel: string;
  completedBadgeLabel: string;
};

type ListAllLearningJourneys = {
  pageHeading: string;
  pageDescription: string;
  allLearningJourneysFetched: string;
  loadingMoreLearningJourneys: string;
  errorLoadingLearningJourneys: string;
  noJourneysActionLabel: string;
  noJourneysMessage: string;
};

type LearningJourneyDetail = {
  headerBackLabel: string;
  allCoursesFetched: string;
  loadingMoreCourses: string;
  errorLoadingCourses: string;
  detailsLoadingErrorMessage: string;
  noCoursesForJourneyMessage: string;
  checkoutOtherJourneysMessage: string;
};

// types

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

const useStrapiLearningJourneyData = () => {
  const data = useAppSelector(strapiDataSelector) as CMS;
  const learningJourneyData = data.learningJourney.data.attributes;
  return learningJourneyData;
};

export default useStrapiLearningJourneyData;
