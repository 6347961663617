import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME, S3_API_PATHS } from '../../../constants';
import { MultipartPreSignedUrlRequestPayload, PresignedUrlResponse } from '../../../types';

const getUserBasedImagePreSignedUrl = async (
  presignedUrlFilters: MultipartPreSignedUrlRequestPayload
): Promise<PresignedUrlResponse> => {
  const queryString = qs.stringify(presignedUrlFilters);
  const path = S3_API_PATHS.PRESIGNED_URL.GET_PRESIGNED_URL;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
  });
  return response.data;
};

export { getUserBasedImagePreSignedUrl };
