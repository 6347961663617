const CALENDAR_ITEM_TYPE = {
  COURSE: {
    DUE: 'COURSE.DUE',
  },
} as const;

const MY_CALENDAR_ITEMS_API_STALE_TIME = 30000;
const UPCOMING_EVENTS_DISPLAY_COUNT = 2;

export { CALENDAR_ITEM_TYPE, MY_CALENDAR_ITEMS_API_STALE_TIME, UPCOMING_EVENTS_DISPLAY_COUNT };
