import Dialog from '../../../../../../components/Dialog';
import { ModalType } from '../../../../types';
import CourseForm from './CourseForm';
import Select from '../../../../../../components/Select';
import { ChangeEvent, useState } from 'react';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../../constants/user';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { COURSE_ACTION } from '../../../../constants';

interface Props {
  modalTitle: string;
  setIsCourseModalOpen: (open: boolean) => void;
  coursePreFetchData?: Record<string, any>;
  formActionButtonLabel: string;
  buttonIcon?: React.ReactNode;
  modalType: ModalType;
  refetch?: any;
  showLanguageDropdown?: boolean;
  mappedOptions?: { display: string; value: string }[];
  selectedLanguage?: string;
  handleLanguageChange?: (newLanguage: string) => void;
  errorType?: string | null;
  setErrorType?: (error: string | null) => void;
}

const CourseModal = ({
  modalTitle,
  setIsCourseModalOpen,
  coursePreFetchData,
  buttonIcon,
  formActionButtonLabel,
  modalType,
  refetch,
  mappedOptions,
  selectedLanguage,
  handleLanguageChange,
  errorType,
  setErrorType,
  showLanguageDropdown = false,
}: Props) => {
  // state

  // create, update of course details action in progress
  const [actionInProgress, setActionInProgress] = useState<keyof typeof COURSE_ACTION | null>(null);

  // state

  return (
    <div className="mx-3 flex h-[80vh] flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6 sm:h-fit ">
      <div className="flex items-center">
        <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
          {modalTitle}
        </Dialog.Title>
        {showLanguageDropdown && coursePreFetchData?.data && (
          <Select
            aria-label="Language options"
            options={mappedOptions?.length ? mappedOptions : []}
            value={selectedLanguage}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleLanguageChange?.(e.target.value)}
            disabledOptions={modalType === 'manage' ? [DEFAULT_PREFERRED_LANGUAGE] : []}
            className="ml-auto"
            disabled={!!actionInProgress}
          />
        )}
      </div>
      {coursePreFetchData?.isLoading || coursePreFetchData?.isFetching ? (
        <div className="flex h-full w-full items-center justify-center py-4">
          <BasicSpinner className="h-[30vh] text-white" />
        </div>
      ) : (
        (coursePreFetchData?.data || modalType === 'create') && (
          <CourseForm
            setIsCourseModalOpen={setIsCourseModalOpen}
            coursePreFetchData={coursePreFetchData}
            formActionButtonLabel={formActionButtonLabel}
            buttonIcon={buttonIcon}
            modalType={modalType}
            refetch={refetch}
            language={selectedLanguage ?? DEFAULT_PREFERRED_LANGUAGE}
            errorType={errorType}
            setErrorType={setErrorType}
            mappedOptions={mappedOptions}
            setActionInProgress={setActionInProgress}
          />
        )
      )}
    </div>
  );
};

export default CourseModal;
