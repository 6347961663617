import clsx from 'clsx';
import { Warning } from 'phosphor-react';
import { BasicSpinner } from '../../../../components/Spinners';
import { ERROR_TYPES } from '../../../../constants/error-types.constants';

interface Props {
  refetchArray: { (): void }[];
  isRefetching: boolean;
  allowsRefetch?: boolean;
  className?: string;
  message: React.ReactNode;
  type?: string;
}

const CoursesErrorState = (props: Props) => {
  const { refetchArray, isRefetching, allowsRefetch, className, message, type } = props;

  // Initiate Refetch for the list of queries
  const initiateRefecth = () => {
    refetchArray.forEach((refetch: { (): void }) => refetch());
  };

  return (
    <div
      className={clsx(
        'flex w-full flex-col items-center justify-center gap-6 text-base font-normal text-rose-200',
        className
      )}
    >
      {isRefetching && (
        <div className="flex justify-center">
          <BasicSpinner className="text-white" />
        </div>
      )}
      {type === ERROR_TYPES.SOMETHING_WENT_WRONG && <Warning size={32} />}
      <div>{message}</div>
      {allowsRefetch && (
        <button
          className={clsx(
            'w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4 leading-[20px] text-zinc-50',
            isRefetching && 'opacity-90'
          )}
          onClick={() => initiateRefecth()}
          disabled={isRefetching}
        >
          Retry
        </button>
      )}
    </div>
  );
};

export default CoursesErrorState;
