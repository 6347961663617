import illustrationSrc from '../../../assets/RegistrationIllustration.png';

type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const RegisterSuccessIllustration = (props: Props) => {
  return (
    <img
      src={illustrationSrc}
      alt="USHG Registration Illustration"
      className="absolute h-full w-screen object-cover"
      {...props}
    />
  );
};

export default RegisterSuccessIllustration;
