import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import React, { useContext, useMemo, useState } from 'react';
import {
  ErrorDisplay,
  NoDataDisplay,
  TableHeaderRow,
} from '../../../../../../components/DataTables';
import { Badges, PartialTopEarnedBadgesFilter, ReportTooltipDataType } from '../../../../types';
import ReportsHeader from '../../ReportsHeader';
import { BadgesEarnedCell, BadgeNameCell } from '../TableCell';
import TableDataRow from '../../../../../../components/DataTables/TableDataRow';
import useTopEarnedBadgesQuery from '../../../../hooks/useTopEarnedBadgesQuery';
import { ERROR_TYPES } from '../../../../../../constants/error-types.constants';
import durationContext from '../../../../context/durationContext';
import useStrapiBadgesData from '../../../../../badges/hooks/useStrapiBadgeData';
import { REPORT_TYPES, TOP_EARNED_BADGES_DEFAULT_LIMIT } from '../../../../constants';
import { InView } from 'react-intersection-observer';
import ScaleLoader from '../../../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../../../components/ReportToolTip';

interface TopEarnedBadgesProps {
  className: string;
  tableClassName?: string;
  lazyLoad?: boolean;
  teamId?: string;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}

const getTableColumnDefinition = (badgeNameHeader: string, badgeEarnedCount: string) => {
  const columnHelper = createColumnHelper<Badges>();
  const columns = [
    columnHelper.accessor('name', {
      header: badgeNameHeader,
      cell: (props) => (
        <BadgeNameCell
          badgeName={props.row.original.name}
          pictureUrl={props.row.original.pictureUrl}
        />
      ),
    }),

    columnHelper.accessor('count', {
      header: badgeEarnedCount,
      cell: (props) => <BadgesEarnedCell badgesEarned={props.getValue()} />,
    }),
  ];
  return columns;
};

const TopEarnedBadgesTable = ({
  className,
  tableClassName,
  lazyLoad = true,
  teamId,
  reportTooltipContent,
  userPersona,
}: TopEarnedBadgesProps) => {
  const { duration } = useContext(durationContext);
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);

  // Build the filter
  const filters: PartialTopEarnedBadgesFilter = useMemo(() => {
    return {
      fromDate: duration?.fromDate,
      limit: TOP_EARNED_BADGES_DEFAULT_LIMIT,
      teamId: teamId && teamId !== '1' ? teamId : undefined,
    };
  }, [duration, teamId]);

  const topEarnedBadges = useTopEarnedBadgesQuery({ filters, isEnabled: isInView });

  // Display loader during initial loading and if we are fetching when there are no items.
  const displayLoader =
    (topEarnedBadges.isLoading && !topEarnedBadges.data) ||
    (topEarnedBadges.isFetching && topEarnedBadges.data?.total === 0);

  const isEmptyState = topEarnedBadges.data && topEarnedBadges.data?.total === 0;

  const { dashBoard } = useStrapiBadgesData();
  const { topEarnedBadges: strapiTopEarnedBadges } = dashBoard;
  const tableHeader = {
    badgeNameHeader: strapiTopEarnedBadges.nameTableHeader,
    badgeEarnedCount: strapiTopEarnedBadges.earnedBytableHeader,
  };
  const { badgeNameHeader, badgeEarnedCount } = tableHeader;

  const columns = getTableColumnDefinition(badgeNameHeader, badgeEarnedCount);
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: topEarnedBadges.data?.badges || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiSort: true,
  });

  return (
    <InView
      as="div"
      className={className}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div
        className={clsx(
          'flex h-full min-h-[420px] w-full flex-col gap-4 overflow-hidden rounded-lg bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)]',
          tableClassName
        )}
      >
        <div className="flex items-center gap-2 ">
          <ReportsHeader title={strapiTopEarnedBadges.title} />
          {reportTooltipContent?.[REPORT_TYPES.TOP_EARNED_BADGES]?.[userPersona] && (
            <ReportTooltip
              content={reportTooltipContent[REPORT_TYPES.TOP_EARNED_BADGES][userPersona]}
            />
          )}
        </div>
        {/* Is Loading and We donot have data or Is Fetching and we have data as empty array */}
        {displayLoader && <ScaleLoader />}
        {/* We have data */}
        {topEarnedBadges.data && topEarnedBadges.data?.badges?.length > 0 && (
          <div className="relative w-full overflow-hidden overflow-x-scroll">
            <table className={clsx('w-full', className)}>
              <thead className="w-full">
                {getHeaderGroups().map((headerGroup) => {
                  return (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableHeaderRow
                          className="!font-normal !text-zinc-400"
                          isSortable={false}
                          header={header}
                          key={header.id}
                        />
                      ))}
                    </tr>
                  );
                })}
              </thead>
              <tbody>
                {getRowModel().rows.map((row) => (
                  <TableDataRow tdClassName="!text-zinc-50 font-semibold" row={row} key={row.id} />
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Empty State */}
        {isEmptyState && !displayLoader && (
          <div className="flex w-full grow items-center justify-center">
            <NoDataDisplay message={strapiTopEarnedBadges.emptyMessage} />
          </div>
        )}

        {topEarnedBadges.isError && !topEarnedBadges.data && (
          <div className="flex w-full grow items-center justify-center">
            <ErrorDisplay
              isRefetching={topEarnedBadges.isRefetching}
              refetch={topEarnedBadges.refetch}
              allowsRefetch={true}
              type={ERROR_TYPES.SOMETHING_WENT_WRONG}
              message={strapiTopEarnedBadges.errorMessage}
            />
          </div>
        )}
      </div>
    </InView>
  );
};

export default TopEarnedBadgesTable;
