import React, { useEffect } from 'react';
import { TabWidgetType } from '../../../../../types/tab-widget.types';
import GoalsCardDisplay from './GoalsCardDisplay';
import { GOALS_CONTENT_MAPPER } from '../../../../../constants';
import { BasicSpinner } from '../../../../../../../components/Spinners';
import { Goal } from '../../../../../types';
import NoDataDisplay from '../../../OnboardingEmptyState';
import OnboardingErrorState from '../../../OnboardingErrorState';
import { UseQueryResult } from '@tanstack/react-query';
import { useStrapiOnBoardingData } from '../../../../../hooks/useStrapiOnBoardingData';

interface GoalsCardViewProps {
  goalsQuery: UseQueryResult<any>;
  currentTab: number;
  widgetData: TabWidgetType[];
  selectedGoals: Goal[];
  setSelectedGoals: (selectedGoals: Goal[]) => void;
  selectedGoalsInitialise: Record<string, boolean>;
  setSelectedGoalsIntialise: (selectedGoalsInitialise: Record<string, boolean>) => void;
  content: any;
}

const style = {
  fontFeatureSettings: '"salt" off',
};

const GoalsCardView = ({
  goalsQuery,
  currentTab,
  setSelectedGoals,
  widgetData,
  selectedGoals,
  selectedGoalsInitialise,
  setSelectedGoalsIntialise,
  content,
}: GoalsCardViewProps) => {
  const {
    emptyMessages: { noGoalsFound },
  } = useStrapiOnBoardingData();
  useEffect(() => {
    if (goalsQuery.data && !selectedGoalsInitialise[widgetData[currentTab].type]) {
      const arrayUniqueByKey: Goal[] = goalsQuery.data.filter(
        (obj: Goal, index: number) =>
          goalsQuery.data.findIndex(
            (goal: Goal) => goal.type === obj.type && goal.id === obj.id && goal.isSelected
          ) === index
      );

      const goalIds = new Set(selectedGoals.map((goal) => goal.id));

      setSelectedGoals([
        ...selectedGoals,
        ...arrayUniqueByKey.filter((obj) => !goalIds.has(obj.id)),
      ]);

      const goalsInitialise = {
        ...selectedGoalsInitialise,
        [widgetData[currentTab].type]: true,
      };
      setSelectedGoalsIntialise(goalsInitialise);
    }
  }, [goalsQuery.data]);

  const handleSelectedGoals = (goal: Goal) => {
    const { id } = goal;

    if (selectedGoals.some((selectedGoal) => selectedGoal.id === id)) {
      setSelectedGoals(selectedGoals.filter((selectedGoal) => selectedGoal.id !== id));
    } else {
      setSelectedGoals(selectedGoals.concat(goal));
    }
  };
  return (
    <div className="my-5 flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <h1 className="text-lg font-bold text-zinc-50 lg:text-xl">
          {content[widgetData[currentTab].text as keyof typeof GOALS_CONTENT_MAPPER].header}
        </h1>
        <p className="text-sm text-zinc-400">
          {content[widgetData[currentTab].text as keyof typeof GOALS_CONTENT_MAPPER].subHeader}
        </p>
      </div>
      {/* Goals is loading */}
      {(goalsQuery.isLoading || goalsQuery.isFetching) && !goalsQuery.data && (
        <div className="flex h-[6.25rem] w-full items-center justify-center">
          <BasicSpinner className="!ml-0 h-6 w-6 text-base-brand" />
        </div>
      )}
      {/* We have data */}
      {goalsQuery.data && goalsQuery.data.length > 0 && (
        <GoalsCardDisplay
          style={style}
          goals={goalsQuery.data}
          handleSelectedGoals={handleSelectedGoals}
          widgetType={widgetData[currentTab].type}
          selectedGoals={selectedGoals}
        />
      )}
      {/* Empty State */}
      {goalsQuery.data && goalsQuery.data.length === 0 && !goalsQuery.isLoading && (
        <NoDataDisplay
          message={
            <div className="flex flex-col items-center gap-8">
              <span>{noGoalsFound}</span>
            </div>
          }
        />
      )}
      {/* Error State */}
      {goalsQuery.isError && !goalsQuery.data && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <OnboardingErrorState
            isRefetching={goalsQuery.isRefetching}
            refetch={goalsQuery.refetch}
          />
        </div>
      )}
    </div>
  );
};

export default GoalsCardView;
