import { flexRender, Header, SortDirection } from '@tanstack/react-table';
import clsx from 'clsx';
import DynamicSortIcon from './DynamicSortIcon';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { SORT_DIRECTION } from '../../constants/table';

interface Props {
  header: Header<any, unknown>;
  className?: string;
  isSortable?: boolean;
  allowMultiColSort?: boolean;
  isBackendSort?: boolean;
  sortByKey?: string;
  sortDirectionKey?: string;
}

const TableHeaderRow = (props: Props) => {
  const {
    header,
    className,
    isSortable = false,
    allowMultiColSort = false,
    isBackendSort = false,
    sortByKey = 'sortBy',
    sortDirectionKey = 'sortDirection',
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  // derived
  const sortDirParam = searchParams.get(sortDirectionKey);
  const sortByParam = searchParams.get(sortByKey);

  const sortDirection: SortDirection | false = useMemo(() => {
    // If this is a FE sort
    // Then we can get the sort state from header
    if (!isBackendSort) {
      return header.column.getIsSorted();
    }

    const id = header.id;

    // If the sort_by param doesn't match the current header id
    // Then there is not sort on this column
    if (sortByParam === null || sortByParam !== id) {
      return false;
    }

    // if not sortDir default to 'asc'
    if (!sortDirParam) {
      return SORT_DIRECTION.ASC;
    }

    if (sortDirParam.toLowerCase() === SORT_DIRECTION.ASC) {
      return SORT_DIRECTION.ASC;
    }

    if (sortDirParam.toLowerCase() === SORT_DIRECTION.DESC) {
      return SORT_DIRECTION.DESC;
    }

    return SORT_DIRECTION.ASC;
  }, [header.column, header.id, isBackendSort, sortByParam, sortDirParam]);

  const toggleColumnSorting = () => {
    if (!isSortable) {
      return;
    }

    // update the query params only if this is a backend sort
    if (isBackendSort) {
      const currSortDirection = sortDirection;
      let nextSortDirection: SortDirection | undefined;

      switch (currSortDirection) {
        case SORT_DIRECTION.ASC: {
          nextSortDirection = SORT_DIRECTION.DESC;
          break;
        }
        case SORT_DIRECTION.DESC: {
          nextSortDirection = undefined;
          break;
        }
        default: {
          nextSortDirection = SORT_DIRECTION.ASC;
        }
      }

      if (nextSortDirection) {
        setSearchParams((prev) => ({
          ...prev,
          [sortDirectionKey]: nextSortDirection,
          [sortByKey]: header.column.id,
        }));
      } else {
        searchParams.delete(sortDirectionKey);
        searchParams.delete(sortByKey);
        setSearchParams(searchParams);
      }
    } else {
      header.column.toggleSorting(undefined, allowMultiColSort);
    }
  };

  return (
    <th
      className={clsx(
        'bg-zinc-900 px-2 py-3 text-start text-[12px] font-semibold leading-[16px] text-zinc-200',
        isSortable && 'cursor-pointer',
        className
      )}
      key={header.id}
      colSpan={header.colSpan}
      onClick={toggleColumnSorting}
    >
      <div className="flex w-full items-center gap-2">
        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
        {isSortable && (
          <DynamicSortIcon
            fallback={<div className="h-4 w-4 bg-transparent"></div>}
            sortDirection={sortDirection}
          />
        )}
      </div>
    </th>
  );
};

export default TableHeaderRow;
