import clsx from 'clsx';
// import RecommendedCourses from '../common/RecommendedCourses';
import Calendar from '../Calendar';
// import EarnedBadgesDisplay from '../common/EarnedBadgesDisplay';
import {
  IndividualLeaderboardTable,
  TeamsLeaderboardTable,
  TopEarnedBadgesTable,
} from '../../../shared/components/Reports/Tables';
import {
  AverageWeeklyHoursViz,
  CoursePerformanceViz,
  HoursLoggedInViz,
} from '../../../shared/components/charts';
import { RecommendedCourses, UserGreeting } from '../common';
import { AssignedCourses } from '../Courses';
import { useStrapiReportsData } from '../../../shared/hooks/useStrapiReportData';
import { USHG_HQ_APP_ROLE } from '../../../../constants';

interface Props {
  className?: string;
  lazyLoad?: boolean;
}

const EnterpriseLeaderDashboard = ({ className, lazyLoad = true }: Props) => {
  const {
    OrganizationCoursePerformance,
    OrganizationHoursLogged,
    AverageWeeklyHours,
    ReportTooltipContent,
  } = useStrapiReportsData();
  return (
    <main
      id="main-content"
      className={clsx(
        'relative mb-4 grid grow auto-rows-min grid-cols-8 gap-x-3 gap-y-2 sm:gap-y-3 lg:gap-x-[13px] lg:gap-y-4 xl:gap-y-5',
        className
      )}
    >
      <UserGreeting className="col-span-8 col-start-1 row-start-1 lg:col-span-6" />
      <HoursLoggedInViz
        key1={OrganizationHoursLogged.key1}
        key2={OrganizationHoursLogged.key2}
        xAxisLabel={OrganizationHoursLogged.xAxisLabel}
        yAxisLabel={OrganizationHoursLogged.yAxisLabel}
        emptyMessage={OrganizationHoursLogged.emptyChart}
        errorMessage={OrganizationHoursLogged.errorChart}
        emptyChartIconUrl={OrganizationHoursLogged.emptyChartIcon.data.attributes.url}
        heading={OrganizationHoursLogged.title}
        className="col-span-8 col-start-1 row-start-2 overflow-hidden lg:col-span-6"
        lazyLoad={lazyLoad}
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_LEADER}
      />
      <Calendar className="col-span-8 col-start-1 row-start-3 md:col-span-4 md:row-start-4 lg:col-span-2 lg:col-start-7 lg:row-span-2 lg:row-start-1" />
      <TopEarnedBadgesTable
        lazyLoad={lazyLoad}
        className="col-span-8 col-start-1 row-start-4 overflow-hidden md:col-span-4 md:col-start-5 md:row-start-4 lg:col-span-2 lg:col-start-7"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_LEADER}
      />
      <IndividualLeaderboardTable
        lazyLoad={lazyLoad}
        className="col-span-8 col-start-1 row-start-5 overflow-hidden md:col-span-4 md:row-start-3"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_LEADER}
      />
      <TeamsLeaderboardTable
        lazyLoad={lazyLoad}
        className="col-span-8 col-start-1 row-start-6 overflow-hidden md:col-span-4 md:col-start-5 md:row-start-3"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_LEADER}
      />
      <CoursePerformanceViz
        key1={OrganizationCoursePerformance.key1}
        key2={OrganizationCoursePerformance.key2}
        key3={OrganizationCoursePerformance.key3}
        emptyMessage={OrganizationCoursePerformance.emptyChart}
        errorMessage={OrganizationCoursePerformance.errorMessage}
        emptyChartIconUrl={OrganizationCoursePerformance.emptyChartIcon.data.attributes.url}
        heading={OrganizationCoursePerformance.title}
        lazyLoad={lazyLoad}
        className="col-span-8 col-start-1 row-start-7 overflow-hidden md:col-span-4 md:row-start-5 lg:col-span-3 lg:row-start-4"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_LEADER}
      />
      <AverageWeeklyHoursViz
        heading={AverageWeeklyHours.title}
        errorChartmessage={OrganizationCoursePerformance.emptyChart}
        lazyLoad={lazyLoad}
        className="col-span-8 col-start-1 row-start-[8] overflow-hidden md:col-span-4 md:row-start-5 lg:col-span-3 lg:col-start-4 lg:row-start-4"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_LEADER}
      />
      <AssignedCourses
        lazyLoad={lazyLoad}
        className="col-span-8 row-start-[9] overflow-hidden md:row-start-6 lg:row-start-5"
      />
      <RecommendedCourses
        lazyLoad={lazyLoad}
        className="col-span-8 row-start-[10] overflow-hidden md:row-start-7 lg:row-start-6"
      />
    </main>
  );
};

export default EnterpriseLeaderDashboard;
