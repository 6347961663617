import { GenericStatusCell } from '../../../../../../../components/DataTables';
import { USER_STATUS } from '../../../../../constants';

interface StatusOptions {
  activatedStatuslabel: string;
  invitedStatuslabel: string;
  deactivatedStatuslabel: string;
}
interface Props {
  status: keyof typeof USER_STATUS;
  tableStatusOptions: StatusOptions;
}

const UserStatusCell = (props: Props) => {
  const { status, tableStatusOptions } = props;

  switch (status) {
    case USER_STATUS.ACTIVE: {
      return (
        <GenericStatusCell
          display={tableStatusOptions.activatedStatuslabel}
          containerClassName="bg-emerald-900 text-emerald-100"
          dotClassName="bg-emerald-100"
        />
      );
    }
    case USER_STATUS.DISABLED: {
      return (
        <GenericStatusCell
          display={tableStatusOptions.deactivatedStatuslabel}
          containerClassName="bg-zinc-900 text-zinc-100"
          dotClassName="bg-zinc-100"
        />
      );
    }
    case USER_STATUS.INVITED: {
      return (
        <GenericStatusCell
          display={tableStatusOptions.invitedStatuslabel}
          containerClassName="bg-amber-900 text-amber-100"
          dotClassName="bg-amber-100"
        />
      );
    }
  }
};

export default UserStatusCell;
