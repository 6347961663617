import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import AssessmentQuestionRowItem from './AssessmentQuestionRowItem';
import React from 'react';
import { AssessmentQuestionListArgs } from '../../../../types';
type AssessmentQuestionListProps = {
  assessmentQuestionsList: AssessmentQuestionListArgs[];
  setAssessmentQuestionsList: (assessmentQuestionsList: AssessmentQuestionListArgs[]) => void;
  refetch: () => void;
};
const AssessmentQuestionList = ({
  assessmentQuestionsList,
  setAssessmentQuestionsList,
  refetch,
}: AssessmentQuestionListProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const handleDrop = (event: any) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const activeIndex = assessmentQuestionsList.findIndex(({ id }) => id === active.id);
      const overIndex = assessmentQuestionsList.findIndex(({ id }) => id === over.id);
      setAssessmentQuestionsList(
        arrayMove(assessmentQuestionsList, activeIndex, overIndex).map((item, index) => {
          item.data.sortOrder = index + 1;
          return item;
        })
      );
    }
  };
  return (
    <div className="my-4">
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDrop}>
        <SortableContext items={assessmentQuestionsList} strategy={verticalListSortingStrategy}>
          {assessmentQuestionsList.map((item) => (
            <AssessmentQuestionRowItem key={item.data.questionId} item={item} refetch={refetch} />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default AssessmentQuestionList;
