import { fetchType } from '../../course-management/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getTeams, getUsers } from '../api/courses';
import React, { useEffect, useState } from 'react';
import { DROP_FETCH_LIMIT } from '../constants/course';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { useStrapiHQAdminCoursesData } from '../../course-management/hooks/useStrapiHQAdminCourseData';

type fetchTeamsQueryProps = {
  params: fetchType;
};

export const nextPageParam = (lastPage: any) => {
  return lastPage.offset + lastPage.pageSize < lastPage.totalCount
    ? lastPage.offset + DROP_FETCH_LIMIT
    : undefined;
};
const useFetchDropUserTeamQuery = ({ params }: fetchTeamsQueryProps) => {
  const { key, courseId, status } = params;
  const LIMIT = DROP_FETCH_LIMIT;
  const [totalCount, setTotalCount] = useState();
  const {
    toastMessages: {
      teamsFetchErrorTitle,
      teamsFetchErrorMessage,
      usersFetchErrorTitle,
      usersFetchErrorMessage,
    },
  } = useStrapiHQAdminCoursesData();
  const { isLoading, error, data, fetchNextPage, isError, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: [key, courseId, status],
      queryFn: ({ pageParam = 0 }) => {
        if (key === 'teams') return getTeams(courseId, pageParam, LIMIT, status);
        else return getUsers(courseId, pageParam, LIMIT, status);
      },
      getNextPageParam: nextPageParam,
      onError: (error) => {
        console.error(`Error while fetching ${key} %o`, error);
        if (key === 'teams') {
          toast.custom(
            (t) => (
              <Toast
                variant="error"
                Title={teamsFetchErrorTitle}
                SubTitle={teamsFetchErrorMessage}
                toastInstance={t}
              />
            ),
            {
              id: '1',
            }
          );
        } else if (key === 'users') {
          toast.custom(
            (t) => (
              <Toast
                variant="error"
                Title={usersFetchErrorTitle}
                SubTitle={usersFetchErrorMessage}
                toastInstance={t}
              />
            ),
            {
              id: '1',
            }
          );
        }
      },
    });
  useEffect(() => {
    if (data) {
      setTotalCount(data.pages[0]?.totalCount);
    }
  }, [data]);
  return {
    isLoading,
    error,
    data,
    isError,
    fetchNextPage,
    totalCount,
    isFetchingNextPage,
    hasNextPage,
  };
};

export default useFetchDropUserTeamQuery;
