import { useQuery } from '@tanstack/react-query';
import { ORG_STALE_TIME } from '../../shared/constants';
import { getAllOrganisation } from '../api/organisation';
interface Props {
  filters: any;
}

const useGetAllOrganisationQuery = ({ filters }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery(
      [
        {
          scope: 'organisation',
          filters: filters,
        },
      ],
      async () => {
        return getAllOrganisation(filters);
      },

      {
        keepPreviousData: true,
        staleTime: ORG_STALE_TIME,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch };
};

export default useGetAllOrganisationQuery;
