import { useQuery } from '@tanstack/react-query';
import { PERFORMANCE_REPORT_TYPES, REPORTS_STALE_TIME } from '../constants';
import { getEnterpriseCoursePerformance } from '../api';
import { DashboardReportsFilter } from '../types';
interface Props {
  filters: DashboardReportsFilter;
  isEnabled: boolean;
}
const useEnterpriseCoursePerformanceQuery = ({ filters, isEnabled }: Props) => {
  const query = useQuery(
    [{ filters: filters, scope: 'dashboard', item: PERFORMANCE_REPORT_TYPES.COURSE_PERFORMANCE }],
    async ({ queryKey }) => {
      const { filters } = queryKey[0];
      return await getEnterpriseCoursePerformance(filters);
    },
    { staleTime: REPORTS_STALE_TIME, enabled: isEnabled }
  );
  return query;
};
export default useEnterpriseCoursePerformanceQuery;
