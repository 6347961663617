import { Auth } from 'aws-amplify';

export type VerifyCurrentUserAttributeParams = {
  attributeName: string;
};

const verifyCurrentUserAttribute = async (params: VerifyCurrentUserAttributeParams) => {
  const { attributeName } = params;
  const response = await Auth.verifyCurrentUserAttribute(attributeName);
  return response;
};

export type VerifyCurrentUserAttributeSubmitParams = {
  attributeName: string;
  code: string;
};

const verifyCurrentUserAttributeSubmit = async (params: VerifyCurrentUserAttributeSubmitParams) => {
  const { attributeName, code } = params;
  const response = await Auth.verifyCurrentUserAttributeSubmit(attributeName, code);
  return response;
};

export { verifyCurrentUserAttribute, verifyCurrentUserAttributeSubmit };
