const FEDERATED_IDP = {
  GOOGLE: 'GOOGLE',
  APPLE: 'APPLE',
  OKTA: 'OKTA',
};

const COGNITO_FEDERATED_IDP = {
  USHG_ADMIN: {
    [FEDERATED_IDP.OKTA]: process.env.REACT_APP_USHG_ADMIN_COGNITO_OKTA_IDP_PROVIDER as string,
  },
  SHARED: {
    [FEDERATED_IDP.GOOGLE]: process.env.REACT_APP_SHARED_COGNITO_GOOGLE_PROVIDER_NAME as string,
    [FEDERATED_IDP.APPLE]: process.env.REACT_APP_SHARED_COGNITO_APPLE_PROVIDER_NAME as string,
  },
} as const;

// Roles and Groups are Inter Changeable terms in the context of app
const COGNITO_INDIVIDUAL_USER_GROUP = process.env
  .REACT_APP_USHG_COGNITO_INDIVIDUAL_USER_GROUP as string;
const COGNITO_USHG_ADMIN_GROUP = process.env.REACT_APP_USHG_COGNITO_USHG_ADMIN_GROUP as string;

// Enterprise Users
const COGNITO_ENTERPRISE_ADMIN_GROUP = process.env
  .REACT_APP_USHG_COGNITO_ENTERPRISE_ADMIN_GROUP as string;
const COGNITO_ENTERPRISE_MANAGER_GROUP = process.env
  .REACT_APP_USHG_COGNITO_ENTERPRISE_MANAGER_GROUP as string;
const COGNITO_ENTERPRISE_LEADER_GROUP = process.env
  .REACT_APP_USHG_COGNITO_ENTERPRISE_LEADER_GROUP as string;
const COGNITO_ENTERPRISE_LEARNER_GROUP = process.env
  .REACT_APP_USHG_COGNITO_ENTERPRISE_LEARNER_GROUP as string;

export {
  COGNITO_ENTERPRISE_ADMIN_GROUP,
  COGNITO_ENTERPRISE_LEADER_GROUP,
  COGNITO_ENTERPRISE_LEARNER_GROUP,
  COGNITO_ENTERPRISE_MANAGER_GROUP,
  COGNITO_FEDERATED_IDP,
  COGNITO_INDIVIDUAL_USER_GROUP,
  COGNITO_USHG_ADMIN_GROUP,
  FEDERATED_IDP,
};
