import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import React from 'react';
import { TableDataRow, TableHeaderRow } from '../../../../../../components/DataTables';
import { useStrapiReportsData } from '../../../../../shared/hooks/useStrapiReportData';
import { CustomerLeaderboard } from '../../../../types';
import { AdminEmailCell, HealthRiskCell, MembersCountCell, OrganizationCell } from './TableCell';

interface CustomerLeaderboardTableProps {
  className?: string;
  tableClassName?: string;
  data: CustomerLeaderboard[];
}

const getTableColumnDefinition = (
  organizationHeader: string,
  adminEmailHeader: string,
  membersHeader: string,
  healthRiskHeader: string
) => {
  const columnHelper = createColumnHelper<CustomerLeaderboard>();
  const columns = [
    columnHelper.accessor('name', {
      header: organizationHeader,
      cell: (props) => <OrganizationCell organization={props.getValue()} />,
    }),
    columnHelper.accessor('adminEmail', {
      header: adminEmailHeader,
      cell: (props) => {
        return <AdminEmailCell email={props.getValue()} />;
      },
    }),
    columnHelper.accessor('members', {
      header: membersHeader,
      cell: (props) => <MembersCountCell members={props.getValue()} />,
    }),
    columnHelper.accessor('healthRate', {
      header: healthRiskHeader,
      cell: (props) => <HealthRiskCell status={props.getValue()} />,
    }),
  ];
  return columns;
};

const CustomerLeaderboardTable = ({ className, data }: CustomerLeaderboardTableProps) => {
  const {
    organisationNameTableHeader,
    emailTableHeader,
    membersTableHeader,
    healthRiskTableHeader,
  } = useStrapiReportsData().CustomerLeaderboard;

  const columns = getTableColumnDefinition(
    organisationNameTableHeader,
    emailTableHeader,
    membersTableHeader,
    healthRiskTableHeader
  );

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className={clsx('w-full', className)}>
      <thead className="w-full">
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeaderRow
                  className="!font-semibold !text-zinc-400"
                  isSortable={false}
                  header={header}
                  key={header.id}
                />
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <TableDataRow tdClassName="!text-zinc-50 font-semibold" row={row} key={row.id} />
        ))}
      </tbody>
    </table>
  );
};

export default CustomerLeaderboardTable;
