import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { addAssessmentQuestionInMultiLang } from '../api';
import { AssessmentQuestionInMultiLangArgs } from '../types';
import { useStrapiHQAdminCoursesData } from './useStrapiHQAdminCourseData';

const useAddAssessmentQuestionInMultiLangMutation = () => {
  const {
    toastMessages: {
      createQuestionSuccessTitle,
      createQuestionSuccessMessage,
      createQuestionFailureTitle,
      createQuestionFailureMessage,
    },
  } = useStrapiHQAdminCoursesData();
  const mutation = useMutation({
    mutationFn: (args: AssessmentQuestionInMultiLangArgs) => addAssessmentQuestionInMultiLang(args),

    onSuccess: () => {
      toast.custom((t) => (
        <Toast
          variant="success"
          Title={createQuestionSuccessTitle}
          SubTitle={createQuestionSuccessMessage}
          toastInstance={t}
        />
      ));
    },
    onError: (error) => {
      console.error('Error while adding an assessment question %o', error);
      toast.custom((t) => (
        <Toast
          variant="error"
          Title={createQuestionFailureTitle}
          SubTitle={createQuestionFailureMessage}
          toastInstance={t}
        />
      ));
    },
  });

  return mutation;
};

export default useAddAssessmentQuestionInMultiLangMutation;
