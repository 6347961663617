import React from 'react';
import { BasicSpinner } from '../../../../../components/Spinners';
import { useProfileQuery } from '../../../../shared/hooks';
import { useStrapiSettingsData } from '../../../hooks/useStrapiSettingsData';
import UserProfileForm from './UserProfileForm';

const UserProfileMain = () => {
  const response = useProfileQuery();

  // Display loader during initial loading and if we are fetching when there are no items.
  const displayLoader =
    (response.isLoading && !response.data) || (response.isFetching && response.data.length === 0);
  const { profile } = useStrapiSettingsData();
  const { loadingText } = profile;
  return (
    <div>
      {displayLoader && (
        <div className="flex flex-col items-center gap-5 pt-[6.5vw]">
          <div className="flex max-w-lg flex-col items-center gap-3">
            <BasicSpinner className="h-6 w-6 text-base-brand" />
            <span className="text-lg font-normal leading-[24px] text-zinc-50">{loadingText}</span>
          </div>
        </div>
      )}
      {!displayLoader && <UserProfileForm profile={response?.data} />}
    </div>
  );
};

export default UserProfileMain;
