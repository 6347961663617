import React from 'react';

type Props = React.SVGProps<SVGSVGElement> & { title?: string };

const GoogleIcon = ({ title, ...props }: Props) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7316 11.239C20.7316 10.4945 20.6648 9.77858 20.5407 9.09131H10.6516V13.1529H16.3025C16.0591 14.4654 15.3193 15.5774 14.2073 16.322V18.9565H17.6007C19.5862 17.1286 20.7316 14.4368 20.7316 11.239Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6512 21.5001C13.4862 21.5001 15.8631 20.5599 17.6003 18.9563L14.2069 16.3217C13.2667 16.9517 12.064 17.324 10.6512 17.324C7.91648 17.324 5.6017 15.4769 4.77602 12.9951H1.26807V15.7156C2.99579 19.1472 6.5467 21.5001 10.6512 21.5001Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.77638 12.9951C4.56638 12.3651 4.44707 11.6922 4.44707 11.0001C4.44707 10.3081 4.56638 9.63512 4.77638 9.00512V6.28467H1.26843C0.557293 7.70217 0.151611 9.3058 0.151611 11.0001C0.151611 12.6944 0.557293 14.2981 1.26843 15.7156L4.77638 12.9951Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6512 4.67614C12.1928 4.67614 13.5769 5.20591 14.6651 6.24636L17.6767 3.23477C15.8583 1.54045 13.4815 0.5 10.6512 0.5C6.5467 0.5 2.99579 2.85295 1.26807 6.28454L4.77602 9.005C5.6017 6.52318 7.91648 4.67614 10.6512 4.67614Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleIcon;
