import { format } from 'date-fns';
import { useLanguage } from '../../../../../shared/context/languageContext';
import { getLocale } from '../../../../../../utils';

interface Props {
  updatedOn: string;
}

const UpdatedOn = ({ updatedOn }: Props) => {
  const { language } = useLanguage();
  return (
    <span className="whitespace-nowrap">
      {format(new Date(updatedOn), 'EEE PPpp', { locale: getLocale(language) })}
    </span>
  );
};

export default UpdatedOn;
