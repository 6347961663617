import { ErrorDisplay, NoDataDisplay } from '../../../../../../components/DataTables';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { ERROR_TYPES } from '../../../../../../constants/error-types.constants';
import {
  SpeakerItemType,
  SpeakerProps,
  SpeakerViewModalProps,
} from '../../types/SingleCourseTypes';
import SpeakerItem from './components/SpeakerItem/SpeakerItem';
import Dialog from '../../../../../../components/Dialog';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import SpeakerModal from '../../../../../speakers/components/Modal/SpeakerModal';

const SpeakerComponent = ({ speakersQuery, title }: SpeakerProps) => {
  const { data, isLoading, isError, refetch, isRefetching } = speakersQuery;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  if (isLoading) {
    return (
      <div className="min-h-[6.25rem] rounded-lg bg-component-bg-dark p-4">
        <div className="flex flex-col">
          <div className="text-xl font-bold">{title}</div>
          <div className="my-4 flex w-full flex-col items-center justify-center">
            <BasicSpinner />
          </div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex min-h-[6.25rem] items-center justify-center rounded-lg bg-component-bg-dark p-4">
        <ErrorDisplay
          refetch={refetch}
          allowsRefetch={true}
          isRefetching={isRefetching}
          type={ERROR_TYPES.SOMETHING_WENT_WRONG}
          message={strapiErrorMessage['SPEAKER_ERROR']}
        />
      </div>
    );
  }

  if (!isLoading && !isError && (!data || data.length == 0)) {
    return (
      <div className="rounded-lg bg-component-bg-dark p-4">
        <NoDataDisplay message={`No Speakers found`} className="my-3 w-full self-center p-0" />
      </div>
    );
  }

  return (
    <div className={'rounded-lg bg-component-bg-dark p-4'}>
      <div className="flex flex-col gap-4">
        <h2 className="text-xl font-bold">{title}</h2>
        <div className="flex flex-col gap-3">
          {data.map((speaker: SpeakerItemType) => {
            const speakerModalProps: SpeakerViewModalProps = {
              numberOfCourses: speaker.totalCourses,
              picture: speaker.profilePic,
              speakerDescription: speaker.bio,
              speakerDesignation: speaker.jobTitle,
              speakerName: speaker.name,
            };

            return (
              <Dialog
                key={speaker.id}
                triggerClassName="flex justify-center basis-0 grow-0"
                Content={<SpeakerModal {...speakerModalProps} />}
                containerClassName="max-w-7xl"
              >
                <SpeakerItem
                  id={speaker.id}
                  profilePic={speaker.profilePic}
                  name={speaker.name}
                  totalCourses={speaker.totalCourses}
                  key={speaker.name}
                  className="p-0 hover:bg-zinc-800"
                />
              </Dialog>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SpeakerComponent;
