import clsx from 'clsx';
import { format } from 'date-fns';
import { memo, useMemo } from 'react';
import { useStrapiDashboardData } from '../../../../shared/hooks';
import { generateDateToCalendarItemsMapForDates } from '../../../helpers';
import { CalendarItem } from '../../../types';
import CalendarBodyDayTile from './CalendarDayTile';
import { getLocale } from '../../../../../utils';
import { useLanguage } from '../../../../shared/context/languageContext';

const WeekHeaderItem = ({ weekDay }: { weekDay: string }) => (
  <div
    role="columnheader"
    aria-label={weekDay}
    className="flex items-center justify-center text-center text-[9.75px] font-medium leading-[14px] text-zinc-400"
  >
    {weekDay}
  </div>
);

interface Props {
  // Array of dates [week[]]
  weeks: Date[][];
  // When we are fetching data we can assume []
  // We can show a loader on the calendar or dim the calendar
  calendarItems?: CalendarItem[];
  className?: string;
  viewing: Date;
}

const CalendarBody = (props: Props) => {
  const { weeks, calendarItems = [], className, viewing } = props;
  const {
    calendar: {
      days: { monday, tuesday, wednesday, thursday, friday, saturday, sunday },
    },
  } = useStrapiDashboardData();
  const { language } = useLanguage();
  const { dates, dateItemsMap } = useMemo(() => {
    const dates = weeks.flat();
    // Return {"2022-23-23": [ev1,ev2...]}
    const { dateItemsMap } = generateDateToCalendarItemsMapForDates({
      dates,
      items: calendarItems,
      language,
    });

    return { dates, dateItemsMap };
  }, [weeks, calendarItems]);

  // TODO: Get from strapi
  const WEEKS = [sunday, monday, tuesday, wednesday, thursday, friday, saturday];

  return (
    <div
      role="grid"
      className={clsx('grid grid-cols-7 grid-rows-[repeat(7,minmax(0,1fr))] gap-1', className)}
    >
      <div role="row" style={{ display: 'contents' }}>
        {WEEKS.map((w, index) => (
          <WeekHeaderItem key={w} weekDay={w} aria-colindex={index + 1} />
        ))}
      </div>
      {dates.map((date, dateIndex) => {
        const fDate = format(date, 'yyyy-MM-dd', { locale: getLocale(language) });
        const colIndex = (dateIndex % 7) + 1; // Calculate the column index
        return (
          <div role="row" key={fDate} style={{ display: 'contents' }}>
            <CalendarBodyDayTile
              aria-colindex={colIndex}
              viewing={viewing}
              key={fDate}
              date={date}
              calendarItems={dateItemsMap[fDate]}
            />
          </div>
        );
      })}
    </div>
  );
};

export default memo(CalendarBody);
