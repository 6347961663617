interface Props {
  username: string;
}

const EmailCell = (props: Props) => {
  const { username } = props;

  return <span className="text-xs font-normal text-zinc-100">{username}</span>;
};

export default EmailCell;
