import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { USHGAdminModuleTableResults } from '../../../../../../constants/course-module-table';
import { useStrapiHQAdminCoursesData } from '../../../../../../hooks/useStrapiHQAdminCourseData';
import {
  setCourseModuleTableSortKey,
  setCourseModuleTableSortOrder,
} from '../../../components/slice/hq-single-course.slice';
import ModulesTableDataRow from './ModulesTableDataRow';
import ModuleTableHeaderRow from './ModuleTableHeaderRow';
import ModuleNameCell from './TableCells/ModuleNameCell';
import ModuleTakenByCell from './TableCells/ModuleTakenByCell';
import SpeakerNameCell from './TableCells/SpeakerNameCell';
import TimestampCell from './TableCells/TimestampCell';

interface Props {
  className?: string;
  data: USHGAdminModuleTableResults[];
}
const getTableColumnDefinition = (
  moduleHeader: string,
  moduleTakenByHeader: string,
  updatedOnHeader: string,
  speakerHeader: string
) => {
  const columnHelper = createColumnHelper<USHGAdminModuleTableResults>();

  const columns = [
    columnHelper.accessor('name', {
      header: moduleHeader,
      cell: (props) => <ModuleNameCell name={props.getValue()} />,
      id: 'name',
    }),
    columnHelper.accessor('lifetimeCompletions', {
      header: moduleTakenByHeader,
      cell: (props) => <ModuleTakenByCell moduleTakenBy={props.getValue()} />,
      id: 'moduleTaken',
    }),
    columnHelper.accessor('updatedOn', {
      header: updatedOnHeader,
      cell: (props) => <TimestampCell timestamp={props.getValue()} />,
      id: 'updatedOn',
    }),
    columnHelper.accessor('speakers', {
      header: speakerHeader,
      cell: (props) => <SpeakerNameCell speakers={props.getValue()} />,
      id: 'speakers',
    }),
  ];
  return columns;
};

const ModulesTable = ({ className, data }: Props) => {
  const { speakerTableHeader, moduleTableHeader, moduleTakenByTableHeader, updatedOnTableHeader } =
    useStrapiHQAdminCoursesData().singleCourseTableView.ModuleTable;
  const columns = getTableColumnDefinition(
    moduleTableHeader,
    moduleTakenByTableHeader,
    updatedOnTableHeader,
    speakerTableHeader
  );
  const { getHeaderGroups, getRowModel, getState } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiSort: true,
  });

  const dispatch = useDispatch();

  const { sorting } = getState();
  useEffect(() => {
    if (sorting.length == 0) {
      dispatch(setCourseModuleTableSortKey('updatedOn'));
      dispatch(setCourseModuleTableSortOrder('asc'));
    } else {
      const { id, desc } = sorting[0];
      dispatch(setCourseModuleTableSortKey(id));
      dispatch(setCourseModuleTableSortOrder(desc ? 'desc' : 'asc'));
    }
  }, [sorting, dispatch]);

  return (
    <table className={clsx('w-full', className)}>
      <thead className="w-full">
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <ModuleTableHeaderRow isSortable={true} header={header} key={header.id} />
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <ModulesTableDataRow row={row} key={row.id} />
        ))}
      </tbody>
    </table>
  );
};

export default ModulesTable;
