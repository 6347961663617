import { ArrowLeft } from 'phosphor-react';
import { useMemo } from 'react';
import { Navbar } from 'react-daisyui';
import { useNavigate } from 'react-router-dom';
import { useStrapiTeamData } from '../../hooks/useStrapiTeamData';
import '../../../../styles/header.css';
import ImageWithAcronymFallback from '../../../../components/ImageWithAcronymFallback';

interface Props {
  title?: string;
  jobTitle?: string;
  isSingleTeamView?: boolean;
  isAdminView?: boolean;
  invisibleClass?: string;
  isSinglePersonView?: boolean;
  isLeaderTeamView?: boolean;
  isSubTeamPresentAlready?: boolean;
  pictureUrl?: string;
}

function SinglePersonTopNav({ title, jobTitle, pictureUrl }: React.PropsWithChildren<Props>) {
  const profilePictureURL = useMemo(() => {
    const picUrl = pictureUrl;
    if (!picUrl) {
      return undefined;
    }
    return picUrl;
  }, [pictureUrl]);
  const navigate = useNavigate();
  const { topNav } = useStrapiTeamData();
  return (
    <Navbar className="flex w-full items-center justify-between border-b border-b-zinc-800 bg-card-bg px-3 py-6 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
      <div className="flex-1">
        <div className="flex-1">
          <div className="flex justify-between">
            <span className="cursor-pointer text-xs text-[#A1A1AA]" onClick={() => navigate(-1)}>
              <ArrowLeft size={12} className="inline-block" /> {topNav.topNavBackButton}
            </span>
            {/* Removed Change Team and Update Role Button */}
            {/* <SinglePersonMenuItems /> */}
          </div>
          <div className="my-2 flex gap-4">
            <div className="flex-col self-center pt-1">
              <ImageWithAcronymFallback
                alt="Default avatars"
                imageKey={profilePictureURL}
                acronymWord={title!}
                acronymLogoClassName={'h-14 w-14'}
                className={'h-14 w-14 rounded-full object-cover object-center'}
              />
            </div>
            <div>
              <div className="flex flex-col pt-1 sm:pt-0">
                <div className="header-title">{title}</div>
                <p className="text-sm normal-case text-zinc-200">{jobTitle}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
}

export default SinglePersonTopNav;
