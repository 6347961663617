import React from 'react';
import ActionButton, { type Action } from './ActionButton';
import Dialog from '../../../../../components/Dialog';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

interface Props {
  heading: string;
  Description?: React.ReactNode | React.ReactNode[];
  primaryAction: Action;
  secondaryAction: Action;
  Content?: React.ReactNode | React.ReactNode[];
}

const ActionDialogContent = (props: Props) => {
  const { heading, Description, primaryAction, secondaryAction, Content } = props;

  return (
    <div className="flex flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-6 shadow-[0px_10px_10px_-5px_rgba(0,0,0,0.04),0px_20px_25px_-5px_rgba(0,0,0,0.10)]">
      <div className="flex flex-col gap-2">
        <Dialog.Title className="text-lg font-semibold leading-6 text-white">
          {heading}
        </Dialog.Title>
        {Description && (
          <Dialog.Description className="text-sm font-normal leading-[1.375rem] text-zinc-300">
            {Description}
          </Dialog.Description>
        )}
      </div>
      {Content && (
        <div className="text-sm font-normal leading-[1.375rem] text-zinc-300">{Content}</div>
      )}
      <div className="flex flex-col items-center justify-end gap-2 xxs:flex-row">
        <ActionButton
          {...secondaryAction}
          type="secondary"
          className={twMerge(clsx('order-2 xxs:order-1', secondaryAction.className))}
        ></ActionButton>
        <ActionButton
          {...primaryAction}
          type="primary"
          className={twMerge(clsx('order-1 xxs:order-2', primaryAction.className))}
        ></ActionButton>
      </div>
    </div>
  );
};

export default ActionDialogContent;
