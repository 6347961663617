import { BadgeButton } from '../../../../../../components/Badge';
import { useEffect, useState } from 'react';
import { TabWidgetType } from '../../../../types/Filter.Helper';
import { twMerge } from 'tailwind-merge';
import { useSearchParams } from 'react-router-dom';
import { COURSE_STATES } from '../../../SingleCourseView/constants/constants';

type TabWidgetsProps = {
  selectedWidget: TabWidgetType | undefined;
  setSelectedWidget: any;
  className?: string;
  widgetData: TabWidgetType[];
};

const TabWidgets = ({
  selectedWidget,
  setSelectedWidget,
  className,
  widgetData,
}: TabWidgetsProps) => {
  const [widgets, setWidgets] = useState<TabWidgetType[]>(widgetData);
  const [searchParams, setSearchParams] = useSearchParams();

  const onWidgetClickHandler = (widget: TabWidgetType) => {
    setSearchParams({ view: widget.value });
  };

  useEffect(() => {
    if (!searchParams.get('view')) {
      setSearchParams({ view: COURSE_STATES.PROGRESS }, { state: { auto: true } });
    }
  }, [searchParams.get('view')]);

  useEffect(() => {
    if (widgets && selectedWidget) {
      setWidgets(
        widgets.map((w) => {
          if (w.id === selectedWidget.id) {
            w.isSelected = true;
            return w;
          } else {
            w.isSelected = false;
            return w;
          }
        })
      );
    }
  }, [selectedWidget]);

  useEffect(() => {
    if (searchParams.get('view')) {
      setSelectedWidget(widgets.find((widget: any) => widget.value === searchParams.get('view')));
    }
  }, [searchParams]);

  return (
    <div className="flex flex-wrap justify-between px-3 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
      <div className={twMerge(`flex flex-wrap gap-2 ${className ?? ''}`)}>
        {widgets &&
          widgets.map((w) => {
            return (
              parseInt(w.id) <= 4 && (
                <BadgeButton onClick={onWidgetClickHandler} key={w.id} badge={w} />
              )
            );
          })}
      </div>
      <div className={twMerge(`flex flex-wrap gap-2 ${className ?? ''}`)}>
        {widgets &&
          widgets.map((w) => {
            return (
              parseInt(w.id) > 4 && (
                <BadgeButton onClick={onWidgetClickHandler} key={w.id} badge={w} />
              )
            );
          })}
      </div>
    </div>
  );
};

export default TabWidgets;
