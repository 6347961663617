import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PaginationFooter } from '../../../../../components/DataTables';
import { BasicSpinner } from '../../../../../components/Spinners';
import { useAppSelector, useAppDispatch } from '../../../../../hooks';
import useDebounce from '../../../../../hooks/use-debounce';
import { RootState } from '../../../../../store';

import { SECTIONS } from '../../../constants';

import {
  USHG_ADMIN_DEFAULT_PAGE,
  USHG_ADMIN_MAX_PAGE_SIZE,
  USHG_ADMIN_MIN_PAGE_SIZE,
  USHG_ADMIN_PAGE_SIZE_INCREMENT,
} from '../../../constants/USHGAdmin';
import useHQAdminCourseQuery from '../../../hooks/useHQAdminCoursesQuery';
import { triggerCoursesRefresh } from '../../../slices/courses.slice';

import CoursesEmptyState from './CoursesEmptyPage';
import CoursesErrorState from './CoursesErrorpage';
import CoursesMainHeader from './CoursesMainHeader';
import CoursesTable from './CoursesTable';

interface Props {
  className?: string;
}

const courseStateSelector = (state: RootState) => state.courses;

const CoursesTableMain = ({ className }: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Parse the filters from the url query parameters
  const { sortKey, sortOrder, courseRefreshState } = useAppSelector(courseStateSelector);
  const dispatch = useAppDispatch();
  const status: string | null | undefined = searchParams.get('state') ?? undefined;
  let _isFilterApplied = false;

  const search = searchParams.get('search') ?? undefined;
  const debounceSearch = useDebounce(search);
  // Current Page
  const [page, setPage] = useState(USHG_ADMIN_DEFAULT_PAGE);
  // Page Size
  const [pageSize, setPageSize] = useState(USHG_ADMIN_MIN_PAGE_SIZE);
  // sort
  let newSortKey = 'UPDATED_ON';
  let newSortOrder: string | boolean = 'ASC';
  if (sortKey) {
    // since we dont need sorting for teamManagers
    if (sortOrder === false) {
      // we will be getting the sort Order for columns which contain String values as asc:asc desc:false and false:desc
      newSortOrder = 'DESC';
      newSortKey = sortKey;
    } else if (sortOrder === 'desc') {
      newSortKey === '';
      newSortOrder === '';
    } else {
      newSortOrder = 'ASC';
      newSortKey = sortKey;
    }
    // we will be getting the sort Order for columns which contain number values as asc:false desc:desc and false:asc
  }
  // Build the request filters from the available filters
  const filters = useMemo(() => {
    return {
      status: status,
      search: debounceSearch,
      start: (page - 1) * pageSize,
      size: pageSize,
      sortOrder: newSortOrder,
      sortKey: newSortKey,
    };
  }, [status, page, pageSize, debounceSearch, newSortOrder, newSortKey]);

  if (!filters.status || status === 'all') {
    // remove if the filter is empty
    delete filters.status;
  }
  if (!filters.search) {
    delete filters.search;
  }
  const request = useHQAdminCourseQuery({ filters });

  const displayLoader = useMemo(
    () => (request.isLoading && !request.data) || (request.isFetching && request.data?.count === 0),
    [request.data, request.isFetching, request.isLoading]
  );

  const isEmptyState = useMemo(() => request.data && request.data.count === 0, [request.data]);

  _isFilterApplied = status !== undefined || search !== undefined;

  // Refresh the Table on data changes
  useEffect(() => {
    if (courseRefreshState?.USHG_ADMIN_COURSES_LIST_TABLE) {
      request.refetch().then(() => {
        const actionPayload = {
          [SECTIONS.USHG_ADMIN_COURSES_LIST_TABLE]: false,
        };
        dispatch(triggerCoursesRefresh(actionPayload));
      });
    }
  }, [courseRefreshState, dispatch, request]);

  return (
    <main
      id="main-content"
      className={clsx(
        'flex flex-col items-center gap-4 rounded-sm bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)] md:p-5 lg:p-6',
        className
      )}
    >
      <CoursesMainHeader status={status} search={search ?? ''} isFilterApplied={_isFilterApplied} />
      {/* Is Loading and We donot have data */}
      {request.isLoading && !request.data && (
        <div className="flex w-full justify-center py-4">
          <BasicSpinner className="text-white" />
        </div>
      )}
      {/* We have data */}
      {request.data && request.data?.count > 0 && (
        <div
          className={clsx(
            'flex w-full flex-col gap-4',
            request.isPreviousData && request.isFetching && 'pointer-events-none opacity-50'
          )}
        >
          <div className="w-full overflow-y-scroll">
            <CoursesTable data={request.data?.data} />
          </div>
          <PaginationFooter
            setPage={setPage}
            activePage={page}
            totalItems={request?.data?.count}
            pageSize={pageSize}
            setPageSize={setPageSize}
            maxPageSize={USHG_ADMIN_MAX_PAGE_SIZE}
            minPageSize={USHG_ADMIN_MIN_PAGE_SIZE}
            pageSizeIncrement={USHG_ADMIN_PAGE_SIZE_INCREMENT}
          />
        </div>
      )}
      {/* Empty State */}
      {isEmptyState && !displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <CoursesEmptyState isFilterApplied={_isFilterApplied} />
        </div>
      )}
      {/* Error State */}
      {request.isError && !request.data && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <CoursesErrorState isRefetching={request.isRefetching} refetch={request.refetch} />
        </div>
      )}
    </main>
  );
};

export default CoursesTableMain;
