import Dialog from '../../../../../../../../components/Dialog';
import FeedbackView from './FeedbackView';
type feedbackViewType = {
  feedback: any;
  open: boolean;
  setIsDialogOpen: (open: boolean) => void;
};
const FeedbackModal = ({ feedback, open, setIsDialogOpen }: feedbackViewType) => {
  return (
    <Dialog
      open={open}
      onOpenChange={setIsDialogOpen}
      containerClassName="max-w-2xl"
      Content={<FeedbackView feedback={feedback} />}
    ></Dialog>
  );
};

export default FeedbackModal;
