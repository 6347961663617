import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

export const useStrapiOrganizationData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const strapiData = data.organization.data.attributes;
  const organisationTable = strapiData.OrganisationTable;
  const individualTable = strapiData.IndividualTable;
  const modal = strapiData.InviteOrganizationModal;
  const topNav = strapiData.TopNav;
  const singleView = strapiData.SingleView;
  const toastMessages = strapiData.ToastMessages;
  const errorMessage = strapiData.ErrorMessages;
  return {
    errorMessage,
    organisationTable,
    individualTable,
    modal,
    topNav,
    singleView,
    toastMessages,
  };
};
