import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../helpers/tanstack-query';
import { getLearningJourneyMetaData } from '../api/learning-journey';
import { useReduxAuthState } from '../../../hooks';
type props = {
  journeyId: string;
  courseId: string;
  enabled?: boolean;
};
const useLearningJourneyMetaQuery = ({ journeyId, courseId, enabled = true }: props) => {
  const auth = useReduxAuthState();

  if (!auth.isAuthenticated) {
    throw new Error('User should be authenticated');
  }
  return useQuery({
    queryKey: QUERY_KEYS.learningJourneyMetaData({ journeyId, courseId }),
    queryFn: () => getLearningJourneyMetaData(journeyId, courseId),
    enabled,
  });
};

export default useLearningJourneyMetaQuery;
