import { useMutation } from '@tanstack/react-query';
import { getPresignedUrl } from '../../../api';
import { MODULE_TYPES } from '../../../types';

type MODULE_TYPES_KEYS = keyof typeof MODULE_TYPES;

const useGetUploadPresignedUrlMutation = () => {
  const mutation = useMutation({
    mutationFn: async (args: {
      module: (typeof MODULE_TYPES)[MODULE_TYPES_KEYS];
      id?: string | undefined;
    }) => {
      const { module, id } = args;

      const response = await getPresignedUrl({
        presignedUrlFilters: {
          module,
          id,
        },
      });
      return response;
    },
  });

  return mutation;
};

export default useGetUploadPresignedUrlMutation;
