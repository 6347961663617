import { CognitoProviderType } from '../constants';
import { parseJSON } from '../utils';
import useReduxAuthState from './use-redux-auth-state';

const useIsFederatedUser = () => {
  const auth = useReduxAuthState();

  if (!auth.isAuthenticated) {
    return false;
  }

  const identities = auth.user['identities']
    ? parseJSON<{ providerName: string; providerType: string; primary: boolean }[]>(
        auth.user['identities']
      )
    : [];

  if (!identities) {
    return false;
  }

  const isFederatedUser = identities.some((identity) => {
    // Might have to come back to this logic
    // once we allow federated users as well to set passwords
    if (Object.keys(CognitoProviderType).includes(identity.providerType)) {
      return true;
    }
    return false;
  });

  return isFederatedUser;
};

export default useIsFederatedUser;
