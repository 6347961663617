import { API } from 'aws-amplify';
import { queryClient } from '../../../config/react-query';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import {
  LOCAL_STORAGE_CHIME_APP_INSTANCE_ARN_KEY,
  LOCAL_STORAGE_USER_TENANT_ID,
} from '../../messaging/constants';
import {
  AUTH_API_PATHS,
  LOCAL_STORAGE_AMPLIFY_CONFIG_KEY,
  LOCAL_STORAGE_DASHBOARD_VIDEO_PLAYED,
  LOCAL_STORAGE_REMEMBER_ME_KEY,
  LOCAL_STORAGE_USHG_HQ_ENTERPRISE_INVITE,
  LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY,
} from '../constants';
import { signOutUser } from '../helpers';

export const logoutUser = async () => {
  const path = AUTH_API_PATHS.LOGOUT;


  try {
    // Logout request to clear cookies
    const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
      withCredentials: true,
    });

    // Signout user from cognito
    await signOutUser();

    queryClient.removeQueries();

    // Clear custom config in localStorage
    localStorage.removeItem(LOCAL_STORAGE_AMPLIFY_CONFIG_KEY);
    localStorage.removeItem(LOCAL_STORAGE_REMEMBER_ME_KEY);
    localStorage.removeItem(LOCAL_STORAGE_CHIME_APP_INSTANCE_ARN_KEY);
    localStorage.removeItem(LOCAL_STORAGE_USER_TENANT_ID);
    localStorage.removeItem(LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY);
    localStorage.removeItem(LOCAL_STORAGE_USHG_HQ_ENTERPRISE_INVITE);
    localStorage.removeItem(LOCAL_STORAGE_DASHBOARD_VIDEO_PLAYED);

    return response;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.errorCode === "USER_NOT_FOUND") {
      // Clear custom config in localStorage
      localStorage.removeItem(LOCAL_STORAGE_AMPLIFY_CONFIG_KEY);
      localStorage.removeItem(LOCAL_STORAGE_REMEMBER_ME_KEY);
      localStorage.removeItem(LOCAL_STORAGE_CHIME_APP_INSTANCE_ARN_KEY);
      localStorage.removeItem(LOCAL_STORAGE_USER_TENANT_ID);
      localStorage.removeItem(LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY);
      localStorage.removeItem(LOCAL_STORAGE_USHG_HQ_ENTERPRISE_INVITE);
      localStorage.removeItem(LOCAL_STORAGE_DASHBOARD_VIDEO_PLAYED);
      window.location.reload();
      throw error;
    }
    throw error;
  }
};

