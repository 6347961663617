import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { queryClient } from '../../../config/react-query';
import { useReduxAuthState } from '../../../hooks';
import { getCurrentAuthenticatedUser } from '../../auth/utils';
import { useStrapiOnBoardingData } from '../../onboarding/hooks/useStrapiOnBoardingData';
import { updateUserProfile } from '../api';
import { UpdateUserProfileRequest } from '../types';

const useUpdateProfileMutation = () => {
  const { toastMessages } = useStrapiOnBoardingData();
  const { user } = useReduxAuthState();

  const {
    profileUpdatedSuccessfulTitle,
    profileUpdatedSuccessfulMessage,
    profileUpdateFailureMessage,
    profileUpdateFailureTitle,
  } = toastMessages;
  const mutation = useMutation({
    mutationFn: async (args: UpdateUserProfileRequest) => {
      const response = await updateUserProfile(args);
      return response;
    },
    onSuccess: async (response, variables) => {
      queryClient.setQueryData(
        [
          {
            scope: 'profile',
            item: 'profile',
            username: user?.username,
          },
        ],
        (previous: any) => ({
          ...previous,
          ...response.data,
        })
      );
      toast.custom((t) => (
        <Toast
          variant="success"
          Title={profileUpdatedSuccessfulTitle}
          SubTitle={profileUpdatedSuccessfulMessage}
          toastInstance={t}
        />
      ));

      try {
        // Will fetch new tokens with updated user attributes
        await getCurrentAuthenticatedUser({ bypassCache: true });
        // Handle language change and page reload
        if (variables.preferredLanguage && variables.preferredLanguage !== user?.locale) {
          queryClient.clear();
        }
      } catch (error) {
        // The profile has been updated but refreshing the cognito session failed
        console.error('Error updating the cognito user');
      }
    },
    onError: (error) => {
      console.error('Error updating user profile %o', error);
      toast.custom((t) => (
        <Toast
          variant="error"
          Title={profileUpdateFailureTitle}
          SubTitle={profileUpdateFailureMessage}
          toastInstance={t}
        />
      ));
    },
  });

  return mutation;
};

export default useUpdateProfileMutation;
