import React from 'react';
import cn from '../../../../../utils/cn';
import TooltipWrapper from '../../../../../components/TooltipWrapper/TooltipWrapper';

type HTMLButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface Props extends Omit<HTMLButtonProps, 'className'> {
  isActive: boolean;
  children: React.ReactNode[] | React.ReactNode;
  className?: Parameters<typeof cn>[0];
  tooltipContent: string;
}

const ToolbarActionButton = React.forwardRef(
  (props: Props, forwardedRef: React.ForwardedRef<HTMLButtonElement>) => {
    const { children, className, isActive, disabled, tooltipContent, ...btnProps } = props;

    return (
      <TooltipWrapper content={tooltipContent} direction="top" sideOffset={4} asChild={true}>
        <button
          ref={forwardedRef}
          className={cn(
            'flex h-7 w-7 items-center justify-center rounded-sm',
            isActive && 'bg-base-brand/10',
            !isActive && 'hover:bg-base-creme/10',
            'disabled:hover:bg-component-bg-dark',
            className
          )}
          disabled={disabled}
          {...btnProps}
        >
          {children}
        </button>
      </TooltipWrapper>
    );
  }
);

ToolbarActionButton.displayName = 'ToolbarActionButton';

export default ToolbarActionButton;
