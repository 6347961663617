import { useQuery } from '@tanstack/react-query';
import { getBadgeDetailsById } from '../api/badges';
import { STALE_TIME } from '../constants/badges';
import { PartialBadgeDetailsFilter } from '../types/badges';
interface Props {
  filters: PartialBadgeDetailsFilter;
}
const useGetBadgeDetailsByIdQuery = ({ filters }: Props) => {
  const data = useQuery(
    [
      {
        scope: 'badges',
        item: 'badgeDetails',
        filters: filters,
      },
    ],
    async ({ queryKey }) => {
      const { filters } = queryKey[0];
      return getBadgeDetailsById(filters);
    },
    {
      staleTime: STALE_TIME,
    }
  );

  return data;
};

export default useGetBadgeDetailsByIdQuery;
