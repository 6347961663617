import clsx from 'clsx';
import { useCallback } from 'react';
import brandLogoPatternImg from '../../../../assets/brand_logo_pattern.png';
import { useAppSelector } from '../../../../hooks';
import { useStrapiDashboardData } from '../../../shared/hooks';

interface Props {
  className: string;
}
interface salutation {
  morningSalutation: string;
  eveningSalutation: string;
  afternoonSalutation: string;
  agenda: string;
}

const generateGreetingMessage = (name: string, salutation: salutation) => {
  const { morningSalutation, eveningSalutation, afternoonSalutation } = salutation;
  const currDate = new Date();
  const currHour = currDate.getHours();
  //   TODO: Revisit logic and check if the emoji is working fine
  let greetingPrefix = '';
  if (currHour < 12) {
    greetingPrefix = morningSalutation;
  } else if (currHour < 18 && currHour >= 12) {
    greetingPrefix = afternoonSalutation;
  } else {
    greetingPrefix = eveningSalutation;
  }

  return `${greetingPrefix}, ${name}! 👋`;
};

const UserGreeting = ({ className }: Props) => {
  const user = useAppSelector(useCallback((state) => state.auth.user, []));
  const { quotes: qoutesList, salutation } = useStrapiDashboardData();
  const randomIndex = Math.floor(Math.random() * qoutesList.length);
  // get random item
  const qoutes = qoutesList[randomIndex];
  let name = `${user?.given_name} ${user?.family_name}`;
  //   If name information is not available use Admin as name
  if (name === '') {
    name = 'Admin';
  }

  return (
    <div className={clsx('flex h-full items-center rounded-lg bg-card-bg', className)}>
      <div className="flex grow flex-col gap-2 p-[25px]">
        <h1 className="whitespace-normal text-xl font-bold leading-[20px] text-white xs:whitespace-nowrap">
          {generateGreetingMessage(name, salutation)}
        </h1>
        <p className="text-xs font-medium leading-[18px] text-zinc-300">{salutation.agenda}</p>
      </div>
      {/* Hides quote on small window, TODO: update if different after mobile designs */}
      <div className="relative hidden h-full grow flex-col items-end justify-center gap-[5px] overflow-hidden rounded-r-lg bg-el-dashboard-greeting-quote py-[23px] pr-[25px] xs:flex">
        <img
          alt="USHG Brand Logo Stacked Vertically and Horizantally to make a pattern"
          src={brandLogoPatternImg}
          className="pointer-events-none absolute -bottom-11 -right-3 z-[0] w-4/5 opacity-30"
        />
        <div className="flex flex-col whitespace-pre-line text-right text-sm font-semibold italic text-zinc-100">
          {qoutes.quote}
        </div>
        <span className="text-right text-[10px] font-semibold italic leading-[20px] text-zinc-100">
          {qoutes.quoteAuthor}
        </span>
      </div>
    </div>
  );
};

export default UserGreeting;
