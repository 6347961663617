import React from 'react';
import { Link } from 'react-router-dom';

type IndividualRegistrationMainFooterProps = {
  context: string;
  label: string;
};
const IndividualRegistrationMainFooter = ({
  context,
  label,
}: IndividualRegistrationMainFooterProps) => {
  return (
    <div className="flex flex-row items-center gap-1">
      <span className="text-sm font-normal leading-[25px] text-slate-400">{context}</span>
      <Link to="/auth/login" className="text-sm font-normal leading-[25px] text-white">
        {label}
      </Link>
    </div>
  );
};

export default IndividualRegistrationMainFooter;
