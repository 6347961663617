import { useCallback, useState } from 'react';
import Dialog from '../../../../../../components/Dialog';
import { EnterpriseAdminRequest } from '../../../../types';
import RejectRequestActionDialogContent from './RejectRequestActionDialogContent';
import RequestActionDialogContent from './RequestActionDialogContent';
interface Props {
  request: EnterpriseAdminRequest;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  // To control the open behavior of the dialog
  setIsDialogOpen: (open: boolean) => void;
}

const RequestActionDialog = (props: Props) => {
  const { request, open, onOpenChange, setIsDialogOpen } = props;
  const [showRejectActionDialog, setShowRejectActionDialog] = useState(false);

  // Set whether the content on the dialog would be request details or reject request flow
  const showRejectActionDialogContent = useCallback((show: boolean) => {
    setShowRejectActionDialog(show);
  }, []);

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      containerClassName="max-h-[80vh] items-center overflow-y-scroll"
      Content={
        // Whether to show reject action dialog
        showRejectActionDialog ? (
          // Reject Action Dialog
          <RejectRequestActionDialogContent
            showRejectActionDialogContent={showRejectActionDialogContent}
            requestId={request.id}
            setIsDialogOpen={setIsDialogOpen}
          />
        ) : (
          // Request Details Content
          <RequestActionDialogContent
            showRejectActionDialogContent={showRejectActionDialogContent}
            setIsDialogOpen={setIsDialogOpen}
            request={request}
          />
        )
      }
    ></Dialog>
  );
};

export default RequestActionDialog;
