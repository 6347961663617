import { useMemo } from 'react';
import { generateCDNMediaAssetURLWithKey } from '../../../../shared/helpers';
import { TeamManager } from '../../../types/interface';
import ManagersAvatarAndNameCell from '../../Table/AdminViewTeamMembersTable/TableCell/ManagersAvatarAndNameCell';

interface TeamManagerCellProps {
  managers: TeamManager[];
}

const TeamManagerCell = (props: TeamManagerCellProps) => {
  const { managers } = props;
  const pictureUrl = managers[0]?.pictureUrl;
  const profilePictureURL = useMemo(() => {
    if (!pictureUrl) {
      return undefined;
    }
    const cdnProfilePictureUrl = generateCDNMediaAssetURLWithKey({ key: pictureUrl });
    return cdnProfilePictureUrl;
  }, [pictureUrl]);

  return <ManagersAvatarAndNameCell managers={managers} profilePictureURL={profilePictureURL} />;
};
export default TeamManagerCell;
