export const BILLING_API_PATHS = {
  INVOICES: '/payments/v1/admin/invoices',
  INDIVIDUAL_INVOICES: '/payments/hq/v1/customer/{id}/invoices',
};
export const STALE_TIME = 2000;
export const BILLING_HISTORY_QUERY_KEY = 'get_invoices';
export const BILLING_DATE = 'billingDate';
export const AMOUNT_DUE = 'amountDue';
export const DUE_DATE = 'dueDate';
// TODO: Removed part of USHG-1914 JIRA Ticket
// export const QUANTITY = 'quantity';
export const IS_OVER_DUE = 'isOverDue';
export const INVOICE_PDF_URL = 'invoicePdf';

export const BILLING_STATUS = {
  PENDING: 'Pending',
  OVER_DUE: 'Over Due',
  PAID: 'Paid',
};
