import usePotentialBadgesQuery from '../../../../hooks/usePotentialBadgesQuery';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { ErrorDisplay, NoDataDisplay } from '../../../../../../components/DataTables';
import { ERROR_TYPES } from '../../../../../../constants/error-types.constants';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import useStrapiBadgesData from '../../../../../badges/hooks/useStrapiBadgeData';
import Dialog from '../../../../../../components/Dialog';
import BadgeItem from '../../../../../badges/components/BadgeItem';
import BadgeDetailsContent from '../../../../../badges/components/BadgeDetails/BadgeDetailsContent';

type Props = {
  id: number;
};

const PotentialBadges = ({ id }: Props) => {
  const { isLoading, isError, data, refetch, isRefetching } = usePotentialBadgesQuery({
    id,
  });
  const strapidata: any = useStrapiDataHelper();
  const {
    singleCourse: { badgesLabel },
    hqError: { emptyMessage },
  } = useStrapiBadgesData();

  const strapiErrorMessage = strapidata.errorsMap;
  if (isLoading) {
    return (
      <div className="min-h-[6.25rem] rounded-lg bg-component-bg-dark p-4">
        <div className="flex flex-col">
          <h2 className="text-xl font-bold">{badgesLabel}</h2>
          <div className="my-4 flex w-full flex-col items-center justify-center">
            <BasicSpinner />
          </div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex min-h-[6.25rem] items-center justify-center rounded-lg bg-component-bg-dark p-4">
        <ErrorDisplay
          refetch={refetch}
          allowsRefetch={true}
          isRefetching={isRefetching}
          type={ERROR_TYPES.SOMETHING_WENT_WRONG}
          message={strapiErrorMessage['POTENTIAL_BADGES_ERROR']}
        />
      </div>
    );
  }

  if (!isLoading && !isError && (!data || data.count === 0)) {
    return (
      <div className="flex min-h-[6.25rem] items-center justify-center rounded-lg bg-component-bg-dark p-4">
        <NoDataDisplay message={emptyMessage} />
      </div>
    );
  }

  return (
    <div className="rounded-lg bg-component-bg-dark p-4">
      <div className="flex flex-col gap-4">
        <h2 className="text-xl font-bold">{badgesLabel}</h2>
        {/* We would have data at this point as there is no error and isLoading = false */}
        <div className="flex w-full flex-col gap-3">
          {data?.rows.map((badge) => {
            return (
              <Dialog
                key={badge.badgeId}
                Content={<BadgeDetailsContent badge={badge} renderDefaultLayout={true} />}
                containerClassName="max-w-lg"
              >
                <BadgeItem
                  key={badge.badgeId}
                  id={badge.badgeId}
                  name={badge.badgeName}
                  description={badge.badgeDescription}
                  roundedImage={true}
                  profile={badge.imageUrl}
                  className="my-0 hover:bg-zinc-800"
                />
              </Dialog>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PotentialBadges;
