export enum Filter {
  NONE = '',
  TAGS = 'tags',
  CATEGORIES = 'categories',
  DURATION = 'duration',
  SPEAKERS = 'speakers',
  FORMATS = 'formats',
  SORT = 'sort',
  SEARCH = 'search',
  TEAM = 'team',
  MEMBER = 'member',
}

export type filterType = {
  type: Filter;
  display: string;
  value: string;
  subText?: string;
};

export type TabWidgetType = {
  id: string;
  text: string;
  value: string;
  subText?: string;
  isDue: boolean;
  isSelected: boolean;
};

export type selectOptionType = { display: string; value: string };
