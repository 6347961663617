import { ChatsCircle } from 'phosphor-react';
import SideBar from '../../../../../components/SideBar';
import { MESSAGE_EVENTS_MAX_DISPLAY_LIMIT } from '../../../../../constants';
import { useAppSelector } from '../../../../../hooks';
import useSubscribeToActiveChannelMessages from '../../../../../hooks/use-subscribe-to-active-channel-messages';
import useSubscribeToChannelEvents from '../../../../../hooks/use-subscribe-to-channel-events';
import { useStrapiSideNavData } from '../../../../../hooks/useStrapiSideNavData';
import { CustomSideNavItemProps } from '../../../types';

const MessagesNavItem = ({ children }: React.PropsWithChildren<CustomSideNavItemProps>) => {
  const unreadChannelArns = useAppSelector((state) => state.messaging.channels.unreadChannelArns);
  const { routes } = useStrapiSideNavData();

  // will listen to CHANNEL_DETAILS_EVENT and CREATE_MESSAGE events and updates the
  // tanstack query cache with the latest messages and channel memberships to be reused
  // and also updates the list of unread channels
  useSubscribeToChannelEvents();

  // This will listen to new channel message events and It updates the active messages
  // In the redux cache
  useSubscribeToActiveChannelMessages();

  // We are counting the number of unread channels and showing that as the notifications count
  const eventsCount =
    unreadChannelArns.length > MESSAGE_EVENTS_MAX_DISPLAY_LIMIT
      ? MESSAGE_EVENTS_MAX_DISPLAY_LIMIT
      : unreadChannelArns.length;

  const isAtleastEventsCount = unreadChannelArns.length > MESSAGE_EVENTS_MAX_DISPLAY_LIMIT;

  return (
    <SideBar.SideBarNavItem
      icon={
        <ChatsCircle
          aria-label={routes.messagesLabel}
          size="20px"
          color="currentColor"
          className="text-inherit"
        />
      }
      label={routes.messagesLabel}
      path="messages"
      eventsCount={eventsCount}
      isAtleastEventsCount={isAtleastEventsCount}
    >
      {children}
    </SideBar.SideBarNavItem>
  );
};

export default MessagesNavItem;
