import { Scroll } from 'phosphor-react';
import SideBar from '../../../../../components/SideBar';
import { USHG_HQ_APP_ROLE } from '../../../../../constants';
import { useHasAccess } from '../../../../../hooks';
import { useStrapiSideNavData } from '../../../../../hooks/useStrapiSideNavData';
import { CustomSideNavItemProps } from '../../../types';

const ReportsNavItem = ({
  eventsCount,
  children,
}: React.PropsWithChildren<CustomSideNavItemProps>) => {
  const hasLearnerAccess = useHasAccess([
    USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER,
    USHG_HQ_APP_ROLE.INDIVIDUAL_USER,
  ]);
  const hasManagerAccess = useHasAccess([USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER]);
  const { routes } = useStrapiSideNavData();
  return (
    <SideBar.SideBarNavItem
      icon={
        <Scroll aria-label="Reports" size="20px" color="currentColor" className="text-inherit" />
      }
      label={
        hasLearnerAccess
          ? routes.myReportsLabel
          : hasManagerAccess
          ? routes.performanceLabel
          : routes.reportsLabel
      }
      path="reports"
      eventsCount={eventsCount}
    >
      {children}
    </SideBar.SideBarNavItem>
  );
};

export default ReportsNavItem;
