import clsx from 'clsx';
import Popover from '../../../../../components/Popover';
import { CalendarItem } from '../../../types';
import CalendarItemsList from '../CalendarItemsList';
import CalendarDayTileContent from './CalendarDayTileContent';

interface Props {
  //   item date
  date: Date;
  calendarItems: CalendarItem[];
  className?: string;
  viewing: Date;
}

const CalendarDayTile = ({ date, calendarItems, className, viewing }: Props) => {
  const hasCalendarItems = calendarItems.length > 0;

  // If there are any calendar items on this day then
  // wrap the trigger with Popover
  if (hasCalendarItems) {
    return (
      <Popover
        Content={
          <CalendarItemsList isInPopover={true} calendarDate={date} calendarItems={calendarItems} />
        }
        modal={true}
        displayArrow={true}
        collisionPadding={20}
        contentClassName="max-h-[256px] overflow-y-auto w-[320px] xl:w-[360px]"
        triggerClassName="grid auto-rows-auto place-items-center grid-cols-1 gap-[2px] px-1"
      >
        <CalendarDayTileContent calendarItems={calendarItems} date={date} viewing={viewing} />
      </Popover>
    );
  }

  return (
    <div
      className={clsx(
        'grid auto-rows-auto grid-cols-1 place-items-center gap-[2px] px-1',
        className
      )}
    >
      <CalendarDayTileContent calendarItems={calendarItems} date={date} viewing={viewing} />
    </div>
  );
};

export default CalendarDayTile;
