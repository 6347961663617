import { Info } from 'phosphor-react';
import { useStrapiSharedData } from '../../features/shared/hooks/useStrapiSharedData';
import Message from './Message';

const PasswordRequirements = () => {
  const { PasswordRequirements } = useStrapiSharedData();
  return (
    <Message
      icon={<Info size="12px" className="mt-0.5 min-w-[12px]" aria-hidden="true" />}
      messages={PasswordRequirements}
      className="items-start"
    />
  );
};

export default PasswordRequirements;
