import clsx from 'clsx';
import { Hole, QuestionMark } from '../../../components/Icons';

interface Props {
  message: React.ReactNode;
  className?: string;
  messageContainerClassName?: string;
}

const IndividualEmptyState = (props: Props) => {
  const { messageContainerClassName, message, className } = props;

  return (
    <div
      className={clsx(
        'm-4 flex w-full flex-col items-center justify-center gap-8 rounded-lg bg-card-bg p-10',
        className
      )}
    >
      <div className="relative flex flex-col items-center">
        <Hole className="absolute -bottom-1/2 z-[0] -translate-y-1/2" />
        <QuestionMark className="relative" />
      </div>
      <div
        className={clsx(
          'text-center text-base font-normal text-zinc-50',
          messageContainerClassName
        )}
      >
        {message}
      </div>
    </div>
  );
};

export default IndividualEmptyState;
