import type { Option } from '../../../components/Select';

// #region All Members Table

import { USER_STATUS } from './user';

export const ALL_MEMBERS_TABLE_DEFAULT_PAGE = 1;
export const ALL_MEMBERS_TABLE_DEFAULT_SIZE = 10;

export const ALL_MEMBERS_TABLE_SEARCH_QUERY_PARAM = 'q';
export const ALL_MEMBERS_TABLE_USER_STATUS_QUERY_PARAM = 'user_status';

// User Status Select Options
export const ALL_MEMBERS_TABLE_USER_STATUS_SELECT_OPTIONS: Option[] = [
  {
    display: 'Active',
    value: USER_STATUS.ACTIVE,
  },
  {
    display: 'Disabled',
    value: USER_STATUS.DISABLED,
  },
  {
    display: 'Invited',
    value: USER_STATUS.INVITED,
  },
];

export const ALL_MEMBERS_TABLE_MAX_PAGE_SIZE = 50;
export const ALL_MEMBERS_TABLE_MIN_PAGE_SIZE = 10;
export const ALL_MEMBERS_TABLE_PAGE_SIZE_INCREMENT = 10;

// DEBOUNCE on request made for search input in milliseconds
export const ALL_MEMBERS_TABLE_FILTER_SEARCH_DEBOUNCE_MS = 500;

// Stale time is 120 seconds
export const GET_ALL_MEMBERS_IN_ORG_REQUEST_STALE_TIME = 120000;

export const ALL_MEMBERS_TABLE_SORT_BY_QUERY_PARAM = 'am_sort_by';
export const ALL_MEMBERS_TABLE_SORT_DIR_QUERY_PARAM = 'am_sort_dir';

// #endregion All Members Table
