import { useQuery } from '@tanstack/react-query';
import { USHG_HQ_APP_ROLE } from '../../../constants';
import { getUSHGAdminRequestTypes } from '../api';
import { USHG_ADMIN_REQUEST_TYPES_FETCH_STALE_TIME } from '../constants';

const useUSHGAdminRequestTypesQuery = () => {
  const { isLoading, data, isError, refetch, isRefetching } = useQuery(
    [
      {
        persona: USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
        scope: 'requests',
        item: 'request-to-hq-types',
      },
    ],
    async () => {
      return await getUSHGAdminRequestTypes();
    },
    {
      staleTime: USHG_ADMIN_REQUEST_TYPES_FETCH_STALE_TIME,
    }
  );
  return { isLoading, data, isError, refetch, isRefetching };
};

export default useUSHGAdminRequestTypesQuery;
