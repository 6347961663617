import { useNavigate, useParams } from 'react-router-dom';
import { ErrorDisplay } from '../../../../../components/DataTables';
import { BasicSpinner } from '../../../../../components/Spinners';
import { ERROR_TYPES } from '../../../../../constants/error-types.constants';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import useCourseStatisticsQuery from '../../../hooks/useCourseStatisticsQuery';
import { useStrapiHQAdminCoursesData } from '../../../hooks/useStrapiHQAdminCourseData';
import CourseStatisticsCardMain from './components/CourseStatisticsCardMain';
import PageHeader from './components/PageHeader';
import USHGAdminCourseFeedbackMainComponent from './FeedbackTableComponent/USHGAdminCourseFeedbackMainComponent';
import USHGAdminCourseModuleTableMainComponent from './ModuleTableComponent/USHGAdminCourseModuleTableMainComponent';
import { SideBarLayoutPageContentWrapper } from '../../../../../components/Wrapper';
import { useCourseBasicDetailsQuery } from '../../../hooks';
import CoursesEmptyState from '../CoursesEmptyState';
import useSessionTracker from '../../../../../hooks/use-session-tracker-hook';
const SingleCourseView = () => {
  useSessionTracker();
  const id = parseInt(useParams().id as string);
  const navigate = useNavigate();

  const courseStatistics = useCourseStatisticsQuery({ id });
  const displayLoader = courseStatistics.isLoading && !courseStatistics.data;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const { singleCourseTableView, emptyMessages, topNav } = useStrapiHQAdminCoursesData();
  const { durationTitle } = singleCourseTableView.Statistics;
  const courseBasicDetailsQuery = useCourseBasicDetailsQuery({ id });

  const isnotExist =
    courseBasicDetailsQuery.errorType == 'COURSE_NOT_FOUND' &&
    courseBasicDetailsQuery.isError == true &&
    courseBasicDetailsQuery.isLoading == false;
  if (isnotExist) {
    return (
      <div className="flex w-full">
        <CoursesEmptyState
          message={
            <div className="flex flex-col items-center gap-y-8">
              <span>{emptyMessages.commonEmptyMessage}</span>
              <button
                className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
                onClick={() => navigate('/manage/courses')}
              >
                {topNav.backToCourseButton}
              </button>
            </div>
          }
        />
      </div>
    );
  }
  return (
    <main id="main-content" className="flex w-full flex-col items-center gap-5">
      <PageHeader id={id} courseBasicDetailsQuery={courseBasicDetailsQuery} />
      <SideBarLayoutPageContentWrapper className="flex-col gap-5">
        <span className="text-lg font-medium text-white">{durationTitle}</span>
        {displayLoader && (
          <div className="flex min-h-[120px] w-full items-center justify-center py-4">
            <BasicSpinner className="text-white" />
          </div>
        )}
        {courseStatistics.data && <CourseStatisticsCardMain data={courseStatistics.data} />}
        {courseStatistics.isError && !courseStatistics.data && (
          <div className="flex min-h-[120px] w-full items-center justify-center">
            <ErrorDisplay
              refetch={courseStatistics.refetch}
              isRefetching={courseStatistics.isRefetching}
              allowsRefetch={true}
              type={ERROR_TYPES.SOMETHING_WENT_WRONG}
              message={strapiErrorMessage['SYSTEM_ERROR']}
            />
          </div>
        )}
        <USHGAdminCourseFeedbackMainComponent />
        <USHGAdminCourseModuleTableMainComponent />
      </SideBarLayoutPageContentWrapper>
    </main>
  );
};

export default SingleCourseView;
