import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InviteTeam, Leader } from '../types';
interface onboardingSliceState {
  teams: InviteTeam[];
  leaders: Leader[];
}

const initialState: onboardingSliceState = {
  teams: [],
  leaders: [],
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    addNewTeam: (state, event: PayloadAction<InviteTeam>) => {
      const newTeam = event.payload;
      state.teams = [...state.teams, newTeam];
    },
    removeTeam: (state, event: PayloadAction<number>) => {
      const index = event.payload;
      state.teams.splice(index, 1);
    },
    addMemberToTeam: (
      state,
      event: PayloadAction<{
        index: number;
        member: InviteTeam['members'][number];
      }>
    ) => {
      const { index, member } = event.payload;
      state.teams[index].members = [...state.teams[index].members, member];
    },
    removeMemberFromTeam: (
      state,
      event: PayloadAction<{
        index: number;
        memberEmail: string;
      }>
    ) => {
      const { index } = event.payload;

      state.teams[index].members = state.teams[index].members.filter(
        (member) => member.email != event.payload.memberEmail
      );
    },
    addNewLeader: (state, event: PayloadAction<Leader>) => {
      state.leaders.push(event.payload);
    },
    removeLeader: (state, event: PayloadAction<string>) => {
      state.leaders = state.leaders.filter((leader: Leader) => leader.email !== event.payload);
    },
    clearTeamsAndLeaderData: (state) => {
      state.teams = [];
      state.leaders = [];
    },
  },
});

export const {
  addNewTeam,
  addNewLeader,
  removeMemberFromTeam,
  removeLeader,
  addMemberToTeam,
  removeTeam,
  clearTeamsAndLeaderData,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
