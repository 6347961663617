import { useQuery } from '@tanstack/react-query';
import { getCourseSpeakers } from '../api/courses';

interface Props {
  courseId: string | undefined;
}

const useCourseSpeakerQuery = ({ courseId }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery(
      [
        {
          courseId,
          scope: 'singleCourse',
          item: 'courseSpeakers',
        },
      ],
      async ({ queryKey }) => {
        const { courseId } = queryKey[0];
        return getCourseSpeakers(`${courseId}`);
      },
      {
        enabled: courseId != undefined,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch };
};

export default useCourseSpeakerQuery;
