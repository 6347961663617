const ERROR_CODE = {
  USER_ALREADY_IN_SAME_STATE: 'USER_ALREADY_IN_SAME_STATE',
  TRIAL_LIMIT_EXCEEDED: 'TRIAL_LIMIT_EXCEEDED',
  SUBSCRIPTION_LICENSES_EXCEEDS: 'SUBSCRIPTION_LICENSES_EXCEEDS',
  VALIDATION_FAILED: 'VALIDATION_FAILED',
  BULK_UPLOAD_VALIDATION_FAILED: 'BULK_UPLOAD_VALIDATION_FAILED',
  // used in ui to get the unknown error message from strapi
  BULK_UPLOAD_UNKNOWN_ERROR: 'BULK_UPLOAD_UNKNOWN_ERROR',
  USER_ALREADY_EXIST: 'USER_ALREADY_EXIST',
} as const;

export { ERROR_CODE };
