import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { queryClient } from '../../../config/react-query';
import { USHGAPIError } from '../../../types';
import { FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY } from '../../shared/constants';
import { deactivateEnterpriseUser, DeactivateEnterpriseUserArgs } from '../api';
import { ERROR_CODE } from '../constants';
import useStrapiMembersData from './useStrapiMembersData';

/**
 * Wrapper around useMutation to deactivate enterprise user
 */
interface props {
  teamId?: number;
}
const useDeactivateEnterpriseUserMutation = ({ teamId }: props) => {
  const {
    toastMessage: {
      deactivateSuccessMessage,
      deactivateFailureMessage,
      userAlreadyDeactive,
      deactivateSuccess,
      deactivateFailure,
    },
  } = useStrapiMembersData();
  const mutation = useMutation({
    mutationFn: async (args: DeactivateEnterpriseUserArgs) => {
      const response = await deactivateEnterpriseUser(args);
      return response;
    },
    onSuccess: (_, variables) => {
      const substitutedDeactivateSuccessMessage = deactivateSuccessMessage?.replace(
        '{{username}}',
        variables.username
      );

      toast.custom(
        (t) => {
          return (
            <Toast
              Title={deactivateSuccess}
              SubTitle={substitutedDeactivateSuccessMessage}
              toastInstance={t}
              variant="success"
            />
          );
        },
        {
          duration: 3000,
        }
      );

      // Invalidate the cache for get-members
      queryClient.invalidateQueries({
        queryKey: [
          {
            scope: 'organization',
            item: 'get-members',
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          {
            scope: 'teams',
            items: 'TeamMembers',
            teamId: teamId,
          },
        ],
      });
      queryClient.invalidateQueries([FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY]);
    },
    onError: (error, variables) => {
      let errorMessage = deactivateFailureMessage;

      if (axios.isAxiosError(error)) {
        const { errorCode } = error.response?.data as unknown as USHGAPIError;
        switch (errorCode) {
          case ERROR_CODE.USER_ALREADY_IN_SAME_STATE: {
            errorMessage = userAlreadyDeactive;
          }
        }
      }

      errorMessage = errorMessage?.replace('{{username}}', variables.username);

      toast.custom(
        (t) => {
          return (
            <Toast
              Title={deactivateFailure}
              SubTitle={errorMessage}
              toastInstance={t}
              variant="error"
            />
          );
        },
        {
          duration: 3000,
        }
      );
    },
  });

  return mutation;
};

export default useDeactivateEnterpriseUserMutation;
