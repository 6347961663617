import { ScaleLoader as Loader } from 'react-spinners';

type props = {
  height?: number;
};

const ScaleLoader = ({ height = 35 }: props) => {
  return (
    <div className="flex grow items-center justify-center">
      <Loader color="#a1a1aa" height={height} />
    </div>
  );
};

export default ScaleLoader;
