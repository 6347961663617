const AccountProvisionStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  NOT_REGISTERED: 'NOT_REGISTERED',
  FAILED: 'FAILED',
} as const;

const DEFAULT_PREFERRED_LANGUAGE = 'en';
const LOCAL_STORAGE_USER_PREFERRED_LANGUAGE = 'language';

export {
  AccountProvisionStatus,
  DEFAULT_PREFERRED_LANGUAGE,
  LOCAL_STORAGE_USER_PREFERRED_LANGUAGE,
};
