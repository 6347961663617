import { useInfiniteQuery } from '@tanstack/react-query';
import { FETCH_LIMIT } from '../constants';
import { getAllMembers } from '../api';

type props = {
  teamId?: string;
};

const nextPageParam = (lastPage: any) =>
  lastPage.offset + lastPage.limit < lastPage.total ? lastPage.offset + FETCH_LIMIT : undefined;

const useAllMembersQuery = ({ teamId }: props) => {
  const query = useInfiniteQuery({
    queryKey: [
      {
        scope: 'reports',
        item: 'all-members',
      },
      teamId,
    ],
    queryFn: async ({ pageParam = 0 }) => {
      const filters = {
        offset: pageParam,
        limit: FETCH_LIMIT,
        teamId: teamId && teamId !== '1' ? teamId : undefined,
      };
      return getAllMembers(filters);
    },
    getNextPageParam: nextPageParam,
  });

  return query;
};

export default useAllMembersQuery;
