import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetAnnouncementResponse } from '../../shared/api/announcement';
import { showToast } from '../../shared/helpers/toast';
import { updateAnnouncement, type UpdateAnnouncementParams } from '../api';
import { useStrapiSettingsData } from './useStrapiSettingsData';
import axios from 'axios';
import { USHGAPIError } from '../../../types';
import { API_ERROR_CODE } from '../constants';
import { useReduxAuthState } from '../../../hooks';

const useUpdateAnnouncementMutation = () => {
  // query client
  const queryClient = useQueryClient();

  const auth = useReduxAuthState();
  // query client

  // strapi content
  const { hqAdminPlatformAnnouncement: announcementCMS } = useStrapiSettingsData();
  // strapi content

  const mutation = useMutation({
    mutationFn: async (params: UpdateAnnouncementParams) => {
      const response = await updateAnnouncement(params);
      return response;
    },
    onSuccess: async (response, variables) => {
      // Update the language-specific query
      queryClient.setQueryData(
        [
          {
            scope: 'shared',
            item: 'announcement',
            language: variables.language, // Language-specific update
          },
        ],
        (): GetAnnouncementResponse => {
          return {
            id: response.id,
            data: {
              markdownContent: response.data?.markdownContent,
            },
          };
        }
      );

      // Update the user (banner) query
      if (auth.user && auth.user.locale) {
        if (auth.user.locale === variables.language) {
          queryClient.setQueryData(
            [
              {
                scope: 'shared',
                item: 'announcement',
              },
            ],
            (): GetAnnouncementResponse => {
              return {
                id: response.id,
                data: {
                  markdownContent: response.data?.markdownContent,
                },
              };
            }
          );
        }
      }

      showToast({
        variant: 'success',
        title: announcementCMS.announcementUpdateSuccessToast,
      });
    },
    onError: async (error, _variables) => {
      let errorMessage = announcementCMS.announcementUpdateErrorToast;

      if (axios.isAxiosError(error)) {
        const { errorCode } = (error.response?.data ?? {}) as USHGAPIError;
        switch (errorCode) {
          case API_ERROR_CODE.RESOURCE_NOT_FOUND: {
            errorMessage = announcementCMS.announcementAlreadyDeletedErrorMessage;

            queryClient.resetQueries([
              {
                scope: 'shared',
                item: 'announcement',
              },
            ]);
          }
        }
      }

      showToast({
        variant: 'error',
        title: errorMessage,
      });
    },
  });

  return mutation;
};

export default useUpdateAnnouncementMutation;
