import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { USHGAPIError } from '../../../types';
import { getCourseStatistics } from '../api';

interface Props {
  id: number;
}
/**
 * Wrapper around the react querys useQuery to fetch the course details.
 */
const useCourseStatisticsQuery = ({ id }: Props) => {
  const [errorType, setErrorType] = useState<string | null>();
  const { isLoading, data, isError, refetch, isRefetching, isFetching } = useQuery(
    [
      {
        courseId: id,
        scope: 'course',
        item: 'courses-statistics',
      },
    ],
    async ({ queryKey }) => {
      const { courseId } = queryKey[0];

      return await getCourseStatistics({ id: courseId });
    },
    {
      enabled: id !== undefined,
      onError: (error) => {
        // If axios error
        if (axios.isAxiosError(error)) {
          const data = error.response?.data;
          if (data) {
            const { errorCode } = data as USHGAPIError;
            setErrorType(errorCode);
          }
        }
      },
    }
  );
  return { isLoading, data, isError, refetch, isRefetching, isFetching, errorType };
};

export default useCourseStatisticsQuery;
