import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Link } from 'phosphor-react';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { TextInput } from '../../../../../components/FormElements';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
import { useStrapiSharedData } from '../../../hooks/useStrapiSharedData';

// schema

const generateFormSchema = ({ urlRequired }: { urlRequired: string }) => {
  const schema = yup.object({
    url: yup.string().required(urlRequired),
  });

  return schema;
};

// schema

type Props = {
  isLink: boolean;
  linkAttributes?: {
    url?: string;
  };
  closePopover: () => void;
};

const ToolbarLinkEditPopoverContent = ({ isLink, linkAttributes, closePopover }: Props) => {
  // strapi content
  const {
    RichTextEditor: { LinkEditForm: linkEditFormCMS },
  } = useStrapiSharedData();
  // strapi content

  // Form
  const schema = useMemo(() => {
    return generateFormSchema({
      urlRequired: linkEditFormCMS.urlRequired,
    });
  }, [linkEditFormCMS.urlRequired]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      url: linkAttributes?.url ?? '',
    },
    resolver: yupResolver(schema),
  });
  // Form

  //   context
  const [editor] = useLexicalComposerContext();
  //   context

  // handlers

  const handleFormSubmit: Parameters<typeof handleSubmit>[0] = (data) => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
      url: data.url,
      // opens in new tab
      target: '_blank',
    });
    closePopover();
  };

  const removeLinkFormatting = () => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    closePopover();
  };

  // handlers

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="flex flex-col gap-2 [--vertical-padding:8px]"
    >
      <div className="relative flex w-[256px] flex-col items-start gap-1.5 px-[var(--vertical-padding)]">
        <div className="flex w-full shrink-0 items-center gap-2">
          <Link size={12} color="currentColor" className="shrink-0 text-white" />
          <TextInput
            type="text"
            autoComplete="url"
            {...register('url')}
            className="grow text-xs"
            placeholder="https://www.example.com"
          />
        </div>
        <HookFormErrorMessage
          name="url"
          errors={errors}
          render={({ message }) => {
            return <ErrorMessage message={message} />;
          }}
        />
      </div>
      <div className="text-xs text-white">
        {/* Update link content */}
        <button type="submit" className="px-[var(--vertical-padding)] py-1">
          {linkEditFormCMS.updateBtnLabel}
        </button>
        {/* Remove link formatting */}
        {isLink ? (
          <button
            className="px-[var(--vertical-padding)] py-1"
            type="button"
            onClick={removeLinkFormatting}
          >
            {linkEditFormCMS.removeBtnLabel}
          </button>
        ) : null}
      </div>
    </form>
  );
};

export default ToolbarLinkEditPopoverContent;
