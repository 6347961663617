import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { useContext, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';
import * as yup from 'yup';
import Alert from '../../../../../../../components/Alert';
import { CodeInput } from '../../../../../../../components/FormElements';
import ErrorMessage from '../../../../../../../components/FormElements/ErrorMessage';
import { BasicSpinner } from '../../../../../../../components/Spinners';
import { Toast } from '../../../../../../../components/Toast';
import { CognitoException, MFAMethod } from '../../../../../../../constants';
import { useStrapiLoginMFAContent } from '../../../../../hooks';
import { useStrapiSharedData } from '../../../../../../shared/hooks/useStrapiSharedData';
import { cognitoUserContext } from '../../../../../context';

// Form Schema

type GenerateFormSchemaParams = {
  codeRequiredErrorMessage: string;
};

const generateFormSchema = (params: GenerateFormSchemaParams) => {
  const { codeRequiredErrorMessage } = params;

  const formSchema = yup.object({
    code: yup
      .array(
        yup.object({
          value: yup.string().trim(),
        })
      )
      .test({
        name: 'is-valid-code',
        message: codeRequiredErrorMessage,
        test: (value) => {
          if (!value) {
            return false;
          }

          for (let i = 0; i < value.length; i++) {
            const codeChar = value[i];
            if (!codeChar || !codeChar.value || codeChar.value.length === 0) {
              return false;
            }
          }

          return true;
        },
      })
      .length(6)
      .required(codeRequiredErrorMessage),
  });

  return formSchema;
};

type FormData = yup.InferType<ReturnType<typeof generateFormSchema>>;

// Form Schema

type Props = {
  className?: string;
  onLoginSuccess?: () => void;
};

// 6 fields and all of them initially empty
const codeDefaultValue = Array(6)
  .fill(0)
  .map((_) => ({ value: '' }));

const SMSMFAForm = (props: Props) => {
  const { className, onLoginSuccess } = props;

  // context
  const { cognitoUser } = useContext(cognitoUserContext);
  // context

  // strapi

  const loginMFACMS = useStrapiLoginMFAContent();
  const { codeRequiredErrorMessage } = loginMFACMS.Shared;

  const { Exception: exceptionCMS } = useStrapiSharedData();

  // strapi

  const formSchema = useMemo(() => {
    return generateFormSchema({ codeRequiredErrorMessage });
  }, [codeRequiredErrorMessage]);

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isSubmitted, isValid },
  } = useForm<FormData>({
    defaultValues: {
      code: codeDefaultValue,
    },
    resolver: yupResolver(formSchema),
  });

  const formSubmitHandler: SubmitHandler<FormData> = async (data) => {
    try {
      const code = data.code.map((codeChar) => codeChar.value).join('');
      await Auth.confirmSignIn(cognitoUser, code, MFAMethod.SMS_MFA);

      // If there is a callback for login success call that
      onLoginSuccess && onLoginSuccess();

      toast.custom(
        (t) => (
          <Toast
            toastInstance={t}
            Title={loginMFACMS.Shared.loginSuccessToastMessage}
            variant="success"
          />
        ),
        {
          duration: 3000,
        }
      );
    } catch (error) {
      console.error(error);
      let errorMessage = loginMFACMS.Shared.somethingWentWrongErrorMessage;

      if (error && typeof error === 'object') {
        const { code } = error as { code: string };
        if (code) {
          switch (code) {
            case CognitoException.NotAuthorizedException: {
              errorMessage = loginMFACMS.Shared.sessionExpiredErrorMessage;
              break;
            }
            // The following would be handled under default case
            // CognitoException.LimitExceededException:
            // CognitoException.ExpiredCodeException:
            // CognitoException.TooManyFailedAttemptsException:
            // CognitoException.CodeMismatchException: {
            default: {
              const exceptionErrorMessage = exceptionCMS[code] ?? errorMessage;
              errorMessage = exceptionErrorMessage ?? errorMessage;
            }
          }
        }
      }

      setError('root.serverError', {
        message: errorMessage,
        type: 'SERVER_ERROR',
      });

      toast.custom((t) => <Toast toastInstance={t} Title={errorMessage} variant="error" />, {
        duration: 3000,
      });
    }
  };

  return (
    <form
      className={twMerge(clsx('flex flex-col gap-7', className))}
      onSubmit={handleSubmit(formSubmitHandler)}
    >
      <HookFormErrorMessage
        errors={errors}
        name="root.serverError"
        render={({ message }) => {
          return <Alert message={message} variant="error" />;
        }}
      />
      <div className="flex flex-col items-center justify-center gap-2">
        <div className="flex flex-col gap-3">
          <label htmlFor="verification-code" className="text-xs font-medium text-zinc-400">
            {loginMFACMS.Shared.authCodeLabel}
          </label>
          <CodeInput
            htmlId="verification-code"
            control={control}
            register={register}
            singleInputClassName="p-2 aspect-square xs:p-3 text-zinc-50 bg-zinc-900 hover:bg-zinc-800 text-zinc-50 border border-zinc-700 focus:border-zinc-500 focus:bg-zinc-800 rounded"
            className="gap-2"
          />
        </div>
        <HookFormErrorMessage
          errors={errors}
          name="code"
          render={({ message }) => {
            return <ErrorMessage className="max-w-md text-center" message={message} />;
          }}
        ></HookFormErrorMessage>
      </div>
      <button
        type="submit"
        disabled={isSubmitting || (isSubmitted && !isValid)}
        className="flex w-full items-center justify-center gap-1 rounded bg-base-brand p-4 text-sm font-semibold leading-6 text-white shadow-[0px_0px_6px_0px_rgba(0,0,0,0.08)] disabled:opacity-70"
      >
        {!isSubmitting && <span>{loginMFACMS.Shared.confirmAuthBtnLabel}</span>}
        {isSubmitting && <BasicSpinner className="!m-0 h-6 w-6" />}
      </button>
    </form>
  );
};

export default SMSMFAForm;
