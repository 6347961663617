/* eslint-disable */
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useAppSelector from './use-app-selector';

const useSessionTracker = () => {
  const user = useAppSelector(useCallback((state) => state.auth.user, []));
  const username = user?.name || '';
  const email = user?.email || '';

  const [startTime, setStartTime] = useState<number>(Date.now());
  const [sessionId] = useState<string>(() => {
    const existingSessionId = localStorage.getItem('sessionId');
    if (existingSessionId) return existingSessionId;
    const newSessionId = uuidv4();
    localStorage.setItem('sessionId', newSessionId);
    return newSessionId;
  });

  const [currentPage, setCurrentPage] = useState<string>(window.location.pathname);

  // Helper to get session data from localStorage
  const getSessionData = (): Record<string, { pageViews: number; sessionDuration: number }> => {
    const storedSessionData = localStorage.getItem('sessionData');
    return storedSessionData ? JSON.parse(storedSessionData) : {};
  };

  // Helper to update session data in localStorage
  const updateSessionData = (page: string, pageViews: number, sessionDuration: number) => {
    const sessionData = getSessionData();
    if (sessionData[page]) {
      sessionData[page].pageViews += pageViews;
      sessionData[page].sessionDuration += sessionDuration;
    } else {
      sessionData[page] = { pageViews, sessionDuration };
    }
    localStorage.setItem('sessionData', JSON.stringify(sessionData));
  };

  // Helper for total session duration in localStorage
  const getTotalSessionDuration = (): number =>
    Number(localStorage.getItem('totalSessionDuration') || 0);
  const updateTotalSessionDuration = (additionalDuration: number) => {
    const newTotal = getTotalSessionDuration() + additionalDuration;
    localStorage.setItem('totalSessionDuration', String(newTotal));
  };

  // Accumulate data for the current page
  const accumulateDataForCurrentPage = () => {
    const now = Date.now();
    const sessionDuration = Math.floor((now - startTime) / 1000);
    if (sessionDuration > 0) {
      updateSessionData(currentPage, 1, sessionDuration);
      updateTotalSessionDuration(sessionDuration);
      setStartTime(now);
    }
  };

  const handlePageChange = () => {
    accumulateDataForCurrentPage();
    setCurrentPage(window.location.pathname); // Update current page to new path
  };

  const handleBeforeUnload = () => {
    accumulateDataForCurrentPage(); // Ensure last page is tracked before unload
  };

  useEffect(() => {
    // Track navigation changes and accumulate data on page unload
    window.addEventListener('popstate', handlePageChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      accumulateDataForCurrentPage(); // Ensure final accumulation on unmount
      window.removeEventListener('popstate', handlePageChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [currentPage]);

  // Function to send session data to HubSpot on logout
  const sendAllSessionDataToHubSpot = () => {
    accumulateDataForCurrentPage(); // Ensure final page data is accumulated before sending
    const sessionData = getSessionData();
    const totalSessionDuration = getTotalSessionDuration();

    Object.entries(sessionData).forEach(([page, data]) => {
      const { pageViews, sessionDuration } = data;
      if (sessionDuration > 0 && window._hsq) {
        window._hsq.push(['identify', { email, username }]);
        window._hsq.push([
          'trackCustomBehavioralEvent',
          {
            name: 'pe46252984_hqplus_session_custom_event_v2',
            properties: {
              session_id: sessionId,
              session_duration: sessionDuration,
              page_views: pageViews,
              current_page: page,
              time_stamp: new Date(),
              user_name: username,
              total_session_duration: totalSessionDuration,
            },
          },
        ]);
      }
    });

    localStorage.removeItem('sessionData');
    localStorage.removeItem('totalSessionDuration');
  };

  return { sendAllSessionDataToHubSpot };
};

export default useSessionTracker;
