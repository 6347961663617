import { REPORTS_INTERVAL, REPORTS_X_AXIS_LABEL, PERIOD } from '../../shared/constants';

export const DURATION_RANGE = [
  {
    display: 'Last 30 Days',
    value: PERIOD.LAST_MONTH,
    interval: REPORTS_INTERVAL.WEEK,
    xAxis: REPORTS_X_AXIS_LABEL.DAYS,
  },
  {
    display: 'Last 3 Months',
    value: PERIOD.LAST_THREE_MONTHS,
    interval: REPORTS_INTERVAL.FORTNIGHT,
    xAxis: REPORTS_X_AXIS_LABEL.WEEKS,
  },
  {
    display: 'Last 6 months',
    value: PERIOD.LAST_SIX_MONTHS,
    interval: REPORTS_INTERVAL.MONTH,
    xAxis: REPORTS_X_AXIS_LABEL.MONTHS,
  },
];
