import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface hqSingleCourseSliceState {
  courseModuleSortKey: string;
  courseModuleSortOrder: string | boolean;
  courseFeedbackSortKey: string;
  courseFeedbackSortOrder: string | boolean;
}

const initialState: hqSingleCourseSliceState = {
  courseModuleSortKey: 'updatedOn',
  courseModuleSortOrder: 'asc',
  courseFeedbackSortKey: 'createdOn',
  courseFeedbackSortOrder: 'asc',
};

const hqSingleCourseSlice = createSlice({
  name: 'single-course',
  initialState,
  reducers: {
    setCourseModuleTableSortKey: (state, action: PayloadAction<string>) => {
      state.courseModuleSortKey = action.payload;
    },
    setCourseModuleTableSortOrder: (state, action: PayloadAction<string | boolean>) => {
      state.courseModuleSortOrder = action.payload;
    },
    setCourseFeedbackTableSortKey: (state, action: PayloadAction<string>) => {
      state.courseFeedbackSortKey = action.payload;
    },
    setCourseFeedbackTableSortOrder: (state, action: PayloadAction<string | boolean>) => {
      state.courseFeedbackSortOrder = action.payload;
    },
  },
});

export const {
  setCourseModuleTableSortKey,
  setCourseModuleTableSortOrder,
  setCourseFeedbackTableSortKey,
  setCourseFeedbackTableSortOrder,
} = hqSingleCourseSlice.actions;

export default hqSingleCourseSlice.reducer;
