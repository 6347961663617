import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BasicSpinner } from '../../../../components/Spinners';
import { USHGAPIError } from '../../../../types';
import { confirmUserAccount } from '../../api/login';
import UserConfirmAccountFailure, {
  UserConfirmAccountFailureType,
} from './UserConfirmAccountFailure';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import UserConfirmAccountSuccess from './UserConfirmAccountSuccess';

const UserConfirmAccount = () => {
  const location = useLocation();
  const [errorType, setErrorType] = useState<UserConfirmAccountFailureType | null>();
  const [loading, setLoading] = useState<boolean>(true);

  const requestStartedRef = useRef<boolean>(false);
  const queryParams = new URLSearchParams(location.search);
  const confirmationCode = queryParams.get('confirmationCode');
  const username = queryParams.get('username');

  useEffect(() => {
    const confirmAccount = async () => {
      if (username == null || confirmationCode == null) {
        setErrorType('SEARCH_PARAMS_MISSING');
        setLoading(false);
        return;
      }

      try {
        requestStartedRef.current = true;

        const params: Parameters<typeof confirmUserAccount>[0] = {
          username,
          confirmationCode,
        };
        await confirmUserAccount(params);
      } catch (error) {
        // If axios error
        if (axios.isAxiosError(error)) {
          const data = error.response?.data;
          if (data) {
            const { errorCode } = data as USHGAPIError;
            switch (errorCode) {
              case 'EXPIRED_CODE':
              case 'USER_ALREADY_CONFIRMED':
              case 'CODE_MISMATCH': {
                setErrorType(errorCode);
                break;
              }
              default: {
                setErrorType('UNKNOWN_ERROR');
                break;
              }
            }
          } else {
            setErrorType('UNKNOWN_ERROR');
          }
        } else {
          setErrorType('UNKNOWN_ERROR');
        }
      }

      setLoading(false);
    };

    if (!requestStartedRef.current) {
      confirmAccount();
    }
  }, [location.search]);

  const data: any = useStrapiDataHelper();
  const attributes = data?.login.data.attributes;
  const {
    UserConfirmAccountSuccess: { confirmingYourAccountSpinnerLabel },
  } = attributes;

  // Loading State
  if (loading) {
    return (
      <div className="flex flex-col items-center gap-5 pt-[6.5vw]">
        <div className="flex max-w-lg flex-col items-center gap-3">
          <BasicSpinner className="h-6 w-6 text-base-brand" />
          <span className="text-lg font-normal leading-[24px] text-zinc-50">
            {confirmingYourAccountSpinnerLabel}
          </span>
        </div>
      </div>
    );
  }

  // Error Message
  if (!loading && errorType) {
    return <UserConfirmAccountFailure username={username as string} type={errorType} />;
  }

  // Success Message
  return <UserConfirmAccountSuccess />;
};

export default UserConfirmAccount;
