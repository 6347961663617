import clsx from 'clsx';
import { CaretRight } from 'phosphor-react';
import React, { useContext } from 'react';
import { Badge } from 'react-daisyui';
import { NavLink } from 'react-router-dom';
import { useToggle } from '../../hooks';
import sideBarContext from './context/sideBarContext';
interface Props {
  label: React.ReactNode;
  icon: React.ReactNode;
  path: string;
  subItems?: React.ReactNode[];
  // Could be notifications or messages or any other events
  eventsCount?: number;
  // whether the eventsCount passed as prop is lower limit on the number of events
  // would render +
  isAtleastEventsCount?: boolean;
  // This would show a notification dot instead of the count
  showNotificationDot?: boolean;
}

const SideBarNavItem = ({
  icon,
  label,
  path,
  subItems,
  eventsCount = 0,
  isAtleastEventsCount = false,
  showNotificationDot,
}: React.PropsWithChildren<Props>) => {
  // Trigger to show sub items
  const [showSubItems, toggleShowSubItems] = useToggle(true);
  const { isSidebarCollapsed } = useContext(sideBarContext);

  const hasSubItems = subItems && subItems.filter((subItem) => subItem !== null).length > 0;

  // To display sub items
  // The navbar shouldn't be collapsed
  // it should have sub items and showSubItems should be true
  const displaySubItems = !isSidebarCollapsed && hasSubItems && showSubItems;

  // TODO: Get hover color from @praveenjuge

  return (
    <>
      <NavLink
        to={path}
        className={({ isActive }) => {
          return clsx(
            'relative flex items-center gap-4 p-2',
            isActive
              ? 'bg-[#404049] fill-white text-white'
              : 'fill-zinc-500 text-zinc-500 hover:bg-[rgba(64,64,73,0.5)]',
            [!isSidebarCollapsed && 'rounded-sm'],
            [isSidebarCollapsed && 'w-min justify-center rounded-full p-1']
          );
        }}
      >
        {icon}
        {!isSidebarCollapsed && (
          <>
            <div className="flex grow items-center justify-between gap-4">
              <span className="grow text-sm font-normal text-white">{label}</span>
              {eventsCount !== 0 && !showNotificationDot && (
                <Badge className="flex h-5 gap-1 rounded-[100px] bg-base-brand text-xs font-medium text-white">
                  {eventsCount}
                  {isAtleastEventsCount && <span>+</span>}
                </Badge>
              )}
              {eventsCount !== 0 && showNotificationDot && (
                <div className="h-3 w-3 rounded-full bg-base-brand"></div>
              )}
            </div>

            {/* Add a caret if there are sub nav items */}
            {hasSubItems && (
              <div
                onClick={(event) => {
                  event.preventDefault();
                  toggleShowSubItems();
                }}
                className="flex items-center justify-center pl-9"
              >
                <CaretRight
                  size="16px"
                  color="currentColor"
                  className={clsx('text-zinc-500 transition-transform', [
                    showSubItems && 'rotate-90',
                  ])}
                />
              </div>
            )}
          </>
        )}
        {/* If the sidebar is collapsed and there are events display a dot */}
        {isSidebarCollapsed && eventsCount > 0 && (
          <span className="absolute top-0 right-0 h-1.5 w-1.5 rounded-full bg-base-brand"></span>
        )}
      </NavLink>
      {/* Recursive Structure */}
      {displaySubItems && <div className="ml-5">{subItems}</div>}
      {/* Display the subItems If the sidebar is collapsed */}
      {isSidebarCollapsed && subItems}
    </>
  );
};

export default SideBarNavItem;
export type { Props as SideBarNavItemProps };
