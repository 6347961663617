import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';

interface Props {
  mode: string;
}

const PaymentModeCell = ({ mode }: Props) => {
  const data: any = useStrapiDataHelper();
  const formData = data?.registration?.data.attributes;
  const { monthy, annually } = formData;
  const { label: monthlyLabel, value: month } = monthy;
  const { label: annuallyLabel, value: year } = annually;
  const paymentMode = mode === month ? monthlyLabel : mode === year ? annuallyLabel : null;
  return (
    <div className="flex space-x-1.5">
      <span className="whitespace-nowrap">{paymentMode ?? monthlyLabel}</span>
    </div>
  );
};

export default PaymentModeCell;
