import { useQuery } from '@tanstack/react-query';
import { getAllIndividuals } from '../api/individual';
import { GET_INDIVIDUAL_QUERY_KEY, STALE_TIME } from '../constants/common';
import { GetIndividualFilterQuery } from '../types/GetIndividualFilterQuery';

interface Props {
  filters: GetIndividualFilterQuery;
}

const useGetIndividualListQuery = ({ filters }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching } =
    useQuery(
      [GET_INDIVIDUAL_QUERY_KEY, filters],
      async () => {
        return getAllIndividuals(filters);
      },
      {
        keepPreviousData: true,
        staleTime: STALE_TIME,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching };
};

export default useGetIndividualListQuery;
