import { RouteObject } from 'react-router-dom';
import EnterpriseAdminAllMembersMain from '../components';

const routes: RouteObject[] = [
  {
    path: '/members/all',
    element: <EnterpriseAdminAllMembersMain />,
  },
];

export default routes;
