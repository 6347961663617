import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { useStrapiRequestData } from '../../../../hooks/useStrapiRequestData';
import { EnterpriseAdminUserRequestData } from '../../../../types';
import { RequestActionDialogItem } from '../../../common';
import TeamNameDialogItemContent from './TeamNameDialogItemContent';

interface Props {
  userRequestData: EnterpriseAdminUserRequestData;
}

const UserRequestDialogContent = ({ userRequestData }: Props) => {
  const teamId = userRequestData.teamId;
  const { addMemberRequestModal } = useStrapiRequestData();
  const { teamToAddInLabel, roleLabel, emailToAddLabel } = addMemberRequestModal;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  return (
    <div className="flex flex-col gap-6">
      <RequestActionDialogItem heading={emailToAddLabel} content={userRequestData.memberEmail} />
      <div className="flex flex-col justify-between gap-6 xs:flex-row">
        <RequestActionDialogItem heading={roleLabel} content={userRequestData.userType} />
        <RequestActionDialogItem
          heading={teamToAddInLabel}
          content={
            <TeamNameDialogItemContent
              teamId={teamId != undefined ? parseInt(teamId) : undefined}
              errorMessage={strapiErrorMessage['TEAM_NAME_ERROR']}
            />
          }
        />
      </div>
    </div>
  );
};

export default UserRequestDialogContent;
