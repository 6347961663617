import { UseQueryResult } from '@tanstack/react-query';
import RequestsEmptyState from '../RequestsEmptyState';
import RequestsErrorState from '../RequestsErrorState';
import DashboardRequestsHeader from './DashboardRequestsHeader';
import { twMerge } from 'tailwind-merge';
import ScaleLoader from '../../../../../components/Loader/ScaleLoader';

interface DashboardRequestsProps {
  RequestsTable: React.ReactNode;
  request: UseQueryResult<any>;
  tableWrapperClassName?: string;
  bottomGradientDivClassName?: string;
}

const DashboardRequestsMain = (props: DashboardRequestsProps) => {
  const { RequestsTable, request, tableWrapperClassName, bottomGradientDivClassName } = props;

  const hasRequests = request.data && request.data.data && request.data.data.length > 0;
  const hasNoRequests = request.data && request.data.data && request.data.data.length === 0;

  return (
    <div className="relative flex h-full max-h-[320px] min-h-[320px] w-full flex-col gap-4 overflow-hidden rounded-lg bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)]">
      <>
        <DashboardRequestsHeader />
        {/* Table component */}
        {hasRequests && (
          <div
            className={twMerge(
              'w-full overflow-hidden overflow-x-scroll pb-2',
              tableWrapperClassName
            )}
          >
            {request.data?.data && RequestsTable}
          </div>
        )}
        {/* Opacity Gradient on the component */}
        {hasRequests && (
          <div
            style={{
              background: 'linear-gradient(180deg, rgba(32, 32, 36, 0) 0%, #202024 100%)',
            }}
            className={twMerge('absolute inset-x-0 bottom-0 h-1/2', bottomGradientDivClassName)}
          ></div>
        )}
        {hasNoRequests && <RequestsEmptyState isFilterApplied={false} />}
        {/* Loader */}
        {request.isLoading && (
          <div className="flex grow items-center justify-center">
            <ScaleLoader />
          </div>
        )}
        {/* Error State */}
        {request.error && !request.data && (
          <div className="flex grow items-center justify-center">
            <RequestsErrorState isRefetching={request.isRefetching} refetch={request.refetch} />
          </div>
        )}
      </>
    </div>
  );
};

export default DashboardRequestsMain;
