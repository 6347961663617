import { MagnifyingGlass } from 'phosphor-react';

type SearchInputProps = {
  search: string;
  setSearch: any;
  searchHandler: any;
  placeholder?: string;
};

const SearchInput = ({ search, setSearch, searchHandler, placeholder }: SearchInputProps) => {
  return (
    <div className="relative flex flex-row">
      <MagnifyingGlass
        className="absolute left-3 flex h-full align-middle"
        size={18}
        color={'#3F3F46'}
        weight="bold"
      />
      <input
        className="h-full rounded border-[0.84px] border-zinc-800 bg-zinc-900 pl-10 text-xs text-zinc-200 placeholder:text-zinc-200"
        type="text"
        placeholder={placeholder ?? 'Search Courses'}
        value={search}
        onChange={(e: any) => {
          setSearch(e.target.value);
        }}
        onKeyDown={searchHandler}
      />
    </div>
  );
};

export default SearchInput;
