import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useStrapiDataHelper } from '../../hooks/useStrapiData';
import BrandLogoImage from '../Brand/BrandLogoImage';
import { useReduxAuthState } from '../../hooks';
import { signOutUser } from '../../features/auth/helpers';
import { Language, LanguageTrigger } from '../../features/shared/components/Language';

const Navbar = () => {
  const navigate = useNavigate();
  const data = useStrapiDataHelper();
  const auth = useReduxAuthState();

  // TODO: strapi integration
  const { logInLabel, signUpLabel, logoutLabel, hqHomeLinkSRLabel } = data.nav.data.attributes;

  return (
    <div className="flex flex-col">
      <nav className="flex flex-row justify-between px-5 py-8" aria-label="Main Navigation">
        <div className="flex items-center justify-center gap-3 ">
          <Link
            aria-label={hqHomeLinkSRLabel}
            target="_blank"
            to={process.env.REACT_APP_USHG_PUBLIC_SITE_URL as string}
            className="self-center"
          >
            <div className="flex w-28 flex-col text-[15px] font-semibold leading-[15px] text-white lg:w-32">
              <BrandLogoImage />
            </div>
          </Link>
        </div>
        <div className="flex items-center gap-8">
          {!location.pathname.includes('/auth/login/admin') && (
            <Language side="bottom" alignOffset={-20}>
              <LanguageTrigger />
            </Language>
          )}
          <div className="flex grow flex-row items-center justify-end gap-4 text-sm leading-[17px] text-white xs:justify-center xs:gap-8">
            {auth.isAuthenticated ? (
              // Logout button
              <div className="cursor-pointer px-4 py-3" onClick={() => signOutUser()}>
                {logoutLabel}
              </div>
            ) : (
              // Login button
              location.pathname.includes('/auth/register') && (
                <div
                  className="cursor-pointer px-4 py-3"
                  onClick={() => navigate('/auth/login', { replace: true })}
                >
                  {logInLabel}
                </div>
              )
            )}
            {location.pathname.includes('/auth/login') && (
              <div className="cursor-pointer rounded bg-base-brand px-4 py-3">
                <NavLink to="/auth/register"> {signUpLabel}</NavLink>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
