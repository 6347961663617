import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { generateCDNMediaAssetURLWithKey } from '../../shared/helpers';
import { InProgressBadgeItemType } from '../types/badges';
import colors from 'tailwindcss/colors';
import 'react-circular-progressbar/dist/styles.css';
const InProgressBadgeItem = ({
  percentageCompleted,
  profile,
  name,
  roundedImage,
  description,
}: InProgressBadgeItemType) => {
  return (
    <div className="my-5 flex h-max w-full flex-row items-center">
      <div className="aspect-square h-[85px] w-[85px] rounded-lg ">
        <CircularProgressbarWithChildren
          value={percentageCompleted}
          strokeWidth={5}
          styles={buildStyles({
            pathColor: '#F7634F',
            trailColor: colors.zinc[700],
          })}
        >
          <img
            src={generateCDNMediaAssetURLWithKey({ key: profile })}
            className={`h-[calc(100%-10px)] w-[calc(100%-10px)] object-cover p-1 ${
              roundedImage ? 'rounded-full' : 'rounded-lg'
            }`}
            alt={name}
          />
        </CircularProgressbarWithChildren>
      </div>
      <div className="mx-4 flex flex-col justify-center text-start">
        <div className="m-1 text-sm font-medium text-zinc-50">{name}</div>
        <div className="mx-1 text-xs font-normal text-zinc-500 line-clamp-2">{description}</div>
      </div>
    </div>
  );
};

export default InProgressBadgeItem;
