import { $convertToMarkdownString } from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { BasicSpinner } from '../../../../../../../components/Spinners';
import cn from '../../../../../../../utils/cn';
import {
  ANNOUNCEMENT_ACTION,
  ANNOUNCEMENT_EDITOR_MARKDOWN_TRANSFORMERS,
} from '../../../../../constants/announcement';
import {
  useCreateAnnouncementInMultiLangMutation,
  useCreateAnnouncementMutation,
  useDeleteAnnouncementMutation,
  useUpdateAnnouncementMutation,
} from '../../../../../hooks';
import { useStrapiSettingsData } from '../../../../../hooks/useStrapiSettingsData';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../../../constants/user';

// components
type HtmlButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface ActionButtonProps extends HtmlButtonProps {
  variant: 'primary' | 'secondary';
  children: string;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
}

const ActionButton = (props: ActionButtonProps) => {
  const { variant, children, disabled, isLoading, className, ...btnProps } = props;

  const isBtnDisabled = (disabled ?? false) || (isLoading ?? false);

  return (
    <button
      className={cn(
        'flex items-center gap-1.5 rounded-md py-2 px-4 text-sm font-normal disabled:opacity-70',
        variant === 'primary' && 'bg-base-brand',
        variant === 'secondary' && 'bg-zinc-900',
        className
      )}
      disabled={isBtnDisabled}
      {...btnProps}
    >
      <span>{children}</span>
      {isLoading && <BasicSpinner className="!m-0 h-5 w-5 text-zinc-100" />}
    </button>
  );
};
// components

type Props = {
  hasAnnouncementAlready: boolean;
  actionsdisabled?: boolean;
  setActionInProgress: (a: keyof typeof ANNOUNCEMENT_ACTION | null) => void;
  announcementId?: number;
  selectedLanguage: string;
  isDefaultContentAddedRef: MutableRefObject<boolean>;
};

const AnnouncementActions = (props: Props) => {
  const {
    hasAnnouncementAlready,
    actionsdisabled = false,
    setActionInProgress,
    announcementId,
    selectedLanguage,
    isDefaultContentAddedRef,
  } = props;
  //   context
  const [editor] = useLexicalComposerContext();
  //   context

  // hooks
  const createAnnouncementMutation = useCreateAnnouncementMutation();
  const createAnnouncementInMultiLangMutation = useCreateAnnouncementInMultiLangMutation();
  const updateAnnouncementMutation = useUpdateAnnouncementMutation();
  const deleteAnnouncementMutation = useDeleteAnnouncementMutation();
  // hooks

  //   state
  const [markdownContent, setMarkdownContent] = useState('');
  const [textContent, setTextContent] = useState('');
  //   state

  //   derived state
  const hasTextContent = textContent.length > 0;
  //   derived state

  // strapi content
  const { hqAdminPlatformAnnouncement: announcementCMS } = useStrapiSettingsData();
  // strapi content

  //   effects

  useEffect(() => {
    const cb = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const root = $getRoot();
        const markdown = $convertToMarkdownString(ANNOUNCEMENT_EDITOR_MARKDOWN_TRANSFORMERS);
        setMarkdownContent(markdown);

        const textContent = root.getTextContent();
        setTextContent(textContent);
      });
    });

    return () => cb();
  }, [editor, setMarkdownContent]);

  //   effects

  //   handlers
  const deleteAnnouncement = async () => {
    try {
      setActionInProgress('DELETE');
      await deleteAnnouncementMutation.mutateAsync();
      editor.update(() => {
        const root = $getRoot();
        root.clear();
      });
      // Reset the ref when deleting the Default announcement
      if (selectedLanguage === DEFAULT_PREFERRED_LANGUAGE) {
        isDefaultContentAddedRef.current = false;
      }
    } catch (error) {
      console.error('Error deleting announcement', error);
    } finally {
      setActionInProgress(null);
    }
  };

  const createAnnouncement = async () => {
    try {
      setActionInProgress('CREATE');
      await createAnnouncementMutation.mutateAsync({
        markdownContent: markdownContent,
        language: DEFAULT_PREFERRED_LANGUAGE,
      });
    } catch (error) {
      console.error('Error creating announcement', error);
    } finally {
      setActionInProgress(null);
    }
  };

  const createAnnouncementInMultiLang = async () => {
    try {
      if (!announcementId) {
        console.error('Announcement ID is not found');
        return;
      }
      setActionInProgress('CREATE');
      await createAnnouncementInMultiLangMutation.mutateAsync({
        announcementId: announcementId,
        markdownContent: markdownContent,
        language: selectedLanguage,
      });
    } catch (error) {
      console.error('Error creating announcement in multi-language', error);
    } finally {
      setActionInProgress(null);
    }
  };
  const updateAnnouncement = async () => {
    try {
      setActionInProgress('UPDATE');
      await updateAnnouncementMutation.mutateAsync({
        markdownContent: markdownContent,
        language: selectedLanguage,
      });
    } catch (error) {
      console.error('Error updating announcement', error);
    } finally {
      setActionInProgress(null);
    }
  };

  //   handlers

  //   loaders
  const isCreatingAnnouncement =
    createAnnouncementMutation.isLoading || createAnnouncementInMultiLangMutation.isLoading;
  const isUpdatingAnnouncement = updateAnnouncementMutation.isLoading;
  const isDeletingAnnouncement = deleteAnnouncementMutation.isLoading;

  const isLoading = isCreatingAnnouncement || isUpdatingAnnouncement || isDeletingAnnouncement;
  //   loaders

  const handleAnnouncementAction = async () => {
    try {
      if (selectedLanguage === DEFAULT_PREFERRED_LANGUAGE) {
        // Handle creation for the default language
        createAnnouncement();
      } else {
        // Handle creation for other languages
        createAnnouncementInMultiLang();
      }
    } catch (error) {
      console.error('Error handling announcement action', error);
    }
  };

  //   If we don't already have an announcement
  if (!hasAnnouncementAlready) {
    return (
      <div className="flex items-center text-zinc-100">
        <ActionButton
          onClick={handleAnnouncementAction}
          variant="primary"
          isLoading={isCreatingAnnouncement}
          type="button"
          disabled={isLoading || !hasTextContent || actionsdisabled}
        >
          {announcementCMS.addAnnouncementBtnLabel}
        </ActionButton>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2 text-zinc-100">
      <ActionButton
        onClick={updateAnnouncement}
        variant="primary"
        isLoading={isUpdatingAnnouncement}
        type="button"
        disabled={isLoading || !hasTextContent || actionsdisabled}
      >
        {announcementCMS.updateBtnLabel}
      </ActionButton>
      {selectedLanguage === DEFAULT_PREFERRED_LANGUAGE && (
        <ActionButton
          onClick={deleteAnnouncement}
          variant="secondary"
          isLoading={isDeletingAnnouncement}
          type="button"
          disabled={isLoading || actionsdisabled}
        >
          {announcementCMS.removeBtnLabel}
        </ActionButton>
      )}
    </div>
  );
};

export default AnnouncementActions;
