import clsx from 'clsx';
import { useStrapiSharedData } from '../../features/shared/hooks/useStrapiSharedData';
import Select, { Option } from '../Select';

interface Props {
  pageSize: string;
  handlePageSizeChange: React.ChangeEventHandler<HTMLSelectElement>;
  options: Option[];
  className?: string;
}

const PageSizeSelector = ({ pageSize, handlePageSizeChange, options, className }: Props) => {
  const { tableProps } = useStrapiSharedData();
  const { pageSizeSelectorLabel } = tableProps;
  return (
    <div className={clsx('flex gap-2', className)}>
      <span className="self-center whitespace-nowrap text-xs font-normal text-zinc-100">
        {pageSizeSelectorLabel} :
      </span>
      <Select
        className="!text-xs"
        value={pageSize}
        aria-label="Page size options"
        options={options}
        onChange={handlePageSizeChange}
      />
    </div>
  );
};

export default PageSizeSelector;
