import { generateCDNMediaAssetURLWithKey } from '../features/shared/helpers';

interface Props {
  mediaKey?: string;
}

/**
 * Takes a key and generates the media assets cdn url
 */
const useMediaAssetsCDNURL = ({ mediaKey }: Props) => {
  let cdnMediaURL = undefined;

  if (mediaKey) {
    cdnMediaURL = generateCDNMediaAssetURLWithKey({
      key: mediaKey,
    });
  }

  return cdnMediaURL;
};

export default useMediaAssetsCDNURL;
