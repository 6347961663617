import { useQuery } from '@tanstack/react-query';
import { ACTIVITY_REPORT_TYPES, REPORTS_STALE_TIME } from '../constants';
import { getHoursSpentFrequency } from '../api';
import { DashboardReportsFilter } from '../types';

interface Props {
  filters: Partial<DashboardReportsFilter>;
  isEnabled: boolean;
}
const useHoursSpentFrequencyQuery = ({ filters, isEnabled }: Props) => {
  const query = useQuery(
    [
      {
        filters: filters,
        scope: 'reports',
        item: ACTIVITY_REPORT_TYPES.HOURS_SPENT_FREQUENCY,
      },
    ],
    async ({ queryKey }) => {
      const { filters } = queryKey[0];
      return await getHoursSpentFrequency(filters);
    },
    {
      staleTime: REPORTS_STALE_TIME,
      enabled: isEnabled,
    }
  );
  return query;
};

export default useHoursSpentFrequencyQuery;
