import { UserRectangle } from 'phosphor-react';
import SideBar from '../../../../../components/SideBar';
// import { useStrapiSideNavData } from '../../../../../hooks/useStrapiSideNavData'; //TODO by Saairam
import { CustomSideNavItemProps } from '../../../types';

const SpeakersNavItem = ({
  eventsCount,
  children,
}: React.PropsWithChildren<CustomSideNavItemProps>) => {
  //   const { routes } = useStrapiSideNavData(); //TODO by Saairam
  return (
    <SideBar.SideBarNavItem
      icon={
        <UserRectangle
          aria-label="Speakers"
          size="20px"
          color="currentColor"
          className="text-inherit"
        />
      }
      label="Speakers"
      path="manage/speakers"
      eventsCount={eventsCount}
    >
      {children}
    </SideBar.SideBarNavItem>
  );
};

export default SpeakersNavItem;
