import { useQuery } from '@tanstack/react-query';
import useReduxAuthState from './use-redux-auth-state';
import { getCurrentAuthenticatedUser } from '../features/auth/utils';
import { Auth } from 'aws-amplify';

type Props = {
  refetchIntervalInMS?: number;
  enabled?: boolean;
} & Parameters<typeof Auth.currentAuthenticatedUser>[0];

const useCurrentAuthenticatedUser = ({
  refetchIntervalInMS,
  enabled = true,
  ...queryFnOpts
}: Props) => {
  const auth = useReduxAuthState();

  const query = useQuery({
    queryKey: [
      {
        scope: 'global',
        item: 'profile',
        email: auth.user?.email,
      },
    ],
    queryFn: async () => {
      const response = await getCurrentAuthenticatedUser(queryFnOpts);
      return response;
    },
    enabled: auth.isAuthenticated && enabled,
    refetchInterval: refetchIntervalInMS,
  });

  return query;
};

export default useCurrentAuthenticatedUser;
