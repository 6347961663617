import clsx from 'clsx';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation } from 'react-router-dom';
import { SideBarBaseLayoutErrorBoundary } from '../components/ErrorBoundary';
import { Page } from '../components/Pages';
import { BasicSpinner } from '../components/Spinners';
import { AppSideBar } from '../features/shared';
import AnnouncementBanner from '../features/shared/components/AnnouncementBanner';
import { useGetAnnouncementQuery } from '../features/shared/hooks';

interface Props {
  isSideBarDefaultCollapsed?: boolean;
  className?: string;
  outletContainerClassName?: string;
  showIllustrator?: boolean;
}

// Used for the pages that are shown to the
// user when the user is logged into the platform
const SideBarBaseLayout = ({
  isSideBarDefaultCollapsed,
  className,
  outletContainerClassName,
  showIllustrator = true,
}: Props) => {
  const location = useLocation();

  // const isUSHGAdmin = useHasAccess([USHG_HQ_APP_ROLE.USHG_ADMIN]);

  // Get announcement
  const getAnnouncementQuery = useGetAnnouncementQuery({
    // To fetch announcement for all users expect USHG Admin
    // enabled: !isUSHGAdmin,
  });
  const announcementMarkdown = getAnnouncementQuery.data?.data?.markdownContent;

  // if we get markdown content then we have an announcement
  const hasAnnouncement = !!announcementMarkdown;

  return (
    <Page showIllustrator={showIllustrator}>
      <div className={clsx('flex h-screen w-screen flex-row', className)}>
        <AppSideBar
          className="fixed left-0 z-10 min-w-max max-w-md shrink-0 grow-0 overflow-y-auto lg:relative"
          defaultCollapsed={isSideBarDefaultCollapsed ?? false}
        />
        <div
          className={clsx(
            'relative ml-[61px] grow overflow-auto lg:ml-0',
            outletContainerClassName
          )}
        >
          <ErrorBoundary
            FallbackComponent={SideBarBaseLayoutErrorBoundary}
            resetKeys={[location.pathname]}
          >
            <Suspense
              fallback={
                <div className="flex h-full w-full items-center justify-center">
                  <BasicSpinner className="text-white" />
                </div>
              }
            >
              {hasAnnouncement ? (
                <AnnouncementBanner>{announcementMarkdown}</AnnouncementBanner>
              ) : null}
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </Page>
  );
};

export default SideBarBaseLayout;
