import { useStrapiDashboardData } from '../../../../shared/hooks';

const UpcomingEventsBaseLayout = ({ children }: React.PropsWithChildren) => {
  const {
    calendar: { upcomingEventsLabel },
  } = useStrapiDashboardData();
  return (
    <div className="flex w-full flex-col gap-2.5">
      <span className="text-xs font-medium text-zinc-200">{upcomingEventsLabel}</span>
      {children}
    </div>
  );
};

export default UpcomingEventsBaseLayout;
