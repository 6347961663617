import * as DropDownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { UserMinus, UserPlus } from 'phosphor-react';
import { USER_STATUS } from '../../../../../../constants';

interface Props {
  status: keyof typeof USER_STATUS;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  activateLabel: string;
  deactivateLabel: string;
}

const UserActivateOrDeactivateItem = (props: Props) => {
  const { status, className, onClick, activateLabel, deactivateLabel } = props;
  const isUserDeactivated = status === USER_STATUS.DISABLED;

  const UserIcon = isUserDeactivated ? UserPlus : UserMinus;
  const itemLabel = isUserDeactivated ? activateLabel : deactivateLabel;

  return (
    <>
      <DropDownMenu.Item
        onClick={onClick}
        className={clsx('flex items-center gap-2.5 text-zinc-50', className)}
      >
        <UserIcon className="text-inherit" color="currentColor" size={14} />
        <span className="text-xs font-medium leading-5 text-inherit">{itemLabel}</span>
      </DropDownMenu.Item>
    </>
  );
};

export default UserActivateOrDeactivateItem;
