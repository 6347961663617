import { UserStatus } from '../types/user';

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  INVITED: 'INVITED',
  DISABLED: 'DISABLED',
} as const;

/**
 * Maps user type to display name.
 */
export const USER_TYPE_TO_DISPLAY_NAME_MAP = {
  Manager: 'Manager',
  Admin: 'Admin',
  User: 'Learner',
  Leader: 'Leader',
} as const;

export const USER_STATUS_VALUES = Object.keys(USER_STATUS) as UserStatus[];
