import { USHG_HQ_APP_ROLE } from '../../../../constants';
import {
  CoursePerformanceRatesViz,
  AssessmentsScoreViz,
  CourseCompletedAssignedStatsViz,
  EngagementByCategoriesViz,
} from '../../../shared/components/charts';
import {
  CoursePerformanceStatisticCards,
  DurationDisplayHeader,
} from '../../../shared/components/Reports';
import { useStrapiReportsData } from '../../../shared/hooks/useStrapiReportData';

interface Props {
  className?: string;
}
const IndividualLearnerReports = ({ className }: Props) => {
  const { ReportTooltipContent } = useStrapiReportsData();
  return (
    <main id="main-content" className={className}>
      <div className="grid auto-rows-min grid-cols-9 gap-x-3 gap-y-2 sm:gap-y-3 lg:gap-x-[13px] lg:gap-y-4 xl:gap-y-5">
        <DurationDisplayHeader className="col-span-9 col-start-1 row-start-1 overflow-hidden" />
        <CoursePerformanceStatisticCards
          className="col-span-9 col-start-1 row-start-2 overflow-hidden"
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.INDIVIDUAL_USER}
        />
        <CoursePerformanceRatesViz
          className="col-span-9 col-start-1 row-start-3 overflow-hidden lg:col-span-3"
          heading="Completion Rates"
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.INDIVIDUAL_USER}
        />
        <AssessmentsScoreViz
          className="col-span-9 col-start-1 row-start-4 overflow-hidden lg:col-span-3 lg:row-start-3"
          heading="Scores On Assessments"
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.INDIVIDUAL_USER}
        />
        <CourseCompletedAssignedStatsViz
          className="col-span-9 col-start-1 row-start-5 overflow-hidden lg:col-span-3 lg:row-start-3"
          heading="Number of Courses Completed/ Assigned"
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.INDIVIDUAL_USER}
        />
        <EngagementByCategoriesViz
          className="col-span-9 col-start-1 row-start-6 overflow-hidden lg:row-start-4"
          heading="Engagement By Categories"
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.INDIVIDUAL_USER}
        />
      </div>
    </main>
  );
};

export default IndividualLearnerReports;
