import { RootState } from '../store';
import useAppSelector from './use-app-selector';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

export const useStrapiSideNavData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const {
    teamsLabel,
    inviteFriendsLabel,
    allMembersLabel,
    badgesLabel,
    reportsLabel,
    requestLabel,
    dashboardLabel,
    messagesLabel,
    coursesLabel,
    calendarLabel,
    notificationsLabel,
    settingsLabel,
    logoutButton,
    collapseButton,
    organisationLabel,
    myBadgesLabel,
    myTeamLabel,
    myReportsLabel,
    performanceLabel,
    individualLabel,
    learningJourneysLabel,
    logo: Logo,
    raiseRequestToHQLabel,
    sidebarSRLabel,
    topPanelSRLabel,
    bottomPanelSRLabel,
  } = data.sideNavigator.data.attributes;
  const logo = Logo.data.attributes.url;
  return {
    routes: {
      teamsLabel,
      inviteFriendsLabel,
      individualLabel,
      organisationLabel,
      myTeamLabel,
      myReportsLabel,
      reportsLabel,
      requestLabel,
      dashboardLabel,
      messagesLabel,
      coursesLabel,
      calendarLabel,
      notificationsLabel,
      settingsLabel,
      myBadgesLabel,
      performanceLabel,
      logo,
      allMembersLabel,
      badgesLabel,
      learningJourneysLabel,
    },
    buttons: {
      logoutButton,
      collapseButton,
      inviteFriendsLabel,
      raiseRequestToHQLabel,
    },
    srLabels: {
      sidebarSRLabel,
      topPanelSRLabel,
      bottomPanelSRLabel,
    },
  };
};
