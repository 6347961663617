import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { API } from 'aws-amplify';
import { USERS_API_PATHS } from '../constants';
import qs from 'qs';

export const getTeams = (includeSubTeams = true) => {
  const queryString = qs.stringify({ includeSubTeams });
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${USERS_API_PATHS.GET_TEAMS}?${queryString}`, {
    response: true,
    withCredentials: true,
  });
};
