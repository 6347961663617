import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { USERS_API_PATHS } from '../constants';

interface InitBulkInviteUsersArgs {
  uploadId: string;
  id?: string;
}

const initBulkInviteUsers = async (args: InitBulkInviteUsersArgs) => {
  const { uploadId, id } = args;

  const path = USERS_API_PATHS.INIT_BULK_INVITE_USERS;

  const body = {
    uploadId,
    id,
  };

  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    body,
  });

  return response.data;
};

export { initBulkInviteUsers };
export type { InitBulkInviteUsersArgs };
