const AUTH_API_PATHS = {
  TENANT: {
    GET_TENANT_DETAILS: 'tenant',
    CHECK_COMPANY_EXISTS: 'check-company',
    CHECK_USER_EXISTS: 'check-user',
  },
  LOGIN: {
    CONFIRM_ACCOUNT: 'account/confirm',
    CONFIRM_RESEND: 'account/resend-confirmation-code',
  },
  REVIEW_SUBSCRIPTION: '/payments/v1/preview/plan',
  LOGOUT: '/auth/v1/logout',
  INIT: '/auth/v1/init',
  ORGANIZATION: {
    GET_ORGANIZATION_INVITE: '/auth/v1/org/invite',
  },
} as const;

export { AUTH_API_PATHS };
