import { DescribeChannelMembershipForAppInstanceUserCommandOutput } from '@aws-sdk/client-chime-sdk-messaging';
import { useQuery } from '@tanstack/react-query';
import {
  DescribeChannelMembershipForAppInstanceUserArgs,
  describeChannelMembershipForAppInstanceUser,
} from '../../messaging/helpers';

interface Props {
  chimeBearer: string;
  appInstanceUserArn?: string;
  channelArn: string;
  staleTime?: number;
  cacheTime?: number;
  onSuccess?: (data: DescribeChannelMembershipForAppInstanceUserCommandOutput) => void;
}

const useDescribeChannelMembershipForAppInstanceUser = (props: Props) => {
  const { chimeBearer, appInstanceUserArn, channelArn, staleTime, cacheTime, onSuccess } = props;

  const query = useQuery({
    queryKey: [
      {
        scope: 'messaging',
        item: 'describe-channel-membership-for-app-instance-user',
        channelArn,
        chimeBearer,
        appInstanceUserArn: appInstanceUserArn ?? chimeBearer,
      },
    ],
    queryFn: async ({ queryKey }) => {
      const { appInstanceUserArn, channelArn, chimeBearer } = queryKey[0];

      const params: DescribeChannelMembershipForAppInstanceUserArgs = {
        channelArn,
        appInstanceUserArn,
        chimeBearer,
      };
      const response = await describeChannelMembershipForAppInstanceUser(params);
      return response;
    },
    staleTime,
    cacheTime,
    refetchOnMount: false,
    onSuccess,
  });

  return query;
};

export default useDescribeChannelMembershipForAppInstanceUser;
