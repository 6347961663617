import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { useStrapiRequestData } from '../../../../hooks/useStrapiRequestData';
import { EnterpriseAdminEditTeamRequestData } from '../../../../types';
import { RequestActionDialogItem } from '../../../common';
import TeamNameDialogItemContent from './TeamNameDialogItemContent';

interface Props {
  editTeamRequestData: EnterpriseAdminEditTeamRequestData;
}

const EditTeamRequestDialogContent = ({ editTeamRequestData }: Props) => {
  const teamId = editTeamRequestData.teamId;
  const { editTeamRequestModal } = useStrapiRequestData();
  const { proposedTeamNameLabel, currentTeamNameLabel } = editTeamRequestModal;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  return (
    <div className="flex flex-col justify-between gap-6 xs:flex-row">
      <RequestActionDialogItem
        heading={currentTeamNameLabel}
        content={
          <TeamNameDialogItemContent
            teamId={teamId}
            errorMessage={strapiErrorMessage['TEAM_NAME_ERROR']}
          />
        }
      />
      <RequestActionDialogItem
        heading={proposedTeamNameLabel}
        content={editTeamRequestData.teamName}
      />
    </div>
  );
};

export default EditTeamRequestDialogContent;
