import { Link } from 'react-router-dom';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';

const UserLoginMainFooter = () => {
  const data: any = useStrapiDataHelper();
  const attributes = data?.login.data.attributes;
  const { ForgotPasswordLabel, signup, NeedNewAcc } = attributes;
  return (
    <div className="flex flex-col items-center gap-3">
      {/* TODO : Change to correct link */}
      <Link to="/auth/forgot-password" className="text-sm font-normal leading-[23px] text-white">
        {ForgotPasswordLabel}
      </Link>
      <div className="flex flex-row items-center gap-1">
        <span className="text-sm font-normal leading-[25px] text-slate-400">{NeedNewAcc}</span>
        <Link to="/auth/register" className="text-sm font-normal leading-[25px] text-white">
          {signup}
        </Link>
      </div>
    </div>
  );
};

export default UserLoginMainFooter;
