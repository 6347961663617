type Props = React.SVGProps<SVGSVGElement>;

const ProfilePictureSquare = (props: Props) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="#141415" width="100" height="100" />
      <path
        d="M0,100h100V0H0V100z M52.4,15.6c3.6,0.7,7.3,3.5,8.9,6.9c3.6,7.6-1.1,16.4-9.4,17.7    c-5.2,0.8-10.6-2-12.9-6.7C34.3,24.2,42.2,13.8,52.4,15.6z M56.3,48.2c8.4,2.2,14.8,8.1,17.3,15.9c1.3,4.1,1.4,8.9,0.3,12.8l-0.3,1    h-47l-0.3-1c-3.9-13,5.6-27,19.9-29.2C48.6,47.3,54,47.6,56.3,48.2L56.3,48.2z"
        fill="#3F3F46"
      />
    </svg>
  );
};

export default ProfilePictureSquare;
