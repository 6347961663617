import type { UseQueryResult } from '@tanstack/react-query';
import { ErrorDisplay } from '../../../../../../../components/DataTables';
import { ERROR_TYPES } from '../../../../../../../constants/error-types.constants';
import { useStrapiDataHelper } from '../../../../../../../hooks/useStrapiData';

interface Props {
  refetch: UseQueryResult['refetch'];
  isRefetching: boolean;
}

const ModuleTableErrorState = ({ refetch, isRefetching }: Props) => {
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  return (
    <ErrorDisplay
      refetch={refetch}
      isRefetching={isRefetching}
      allowsRefetch={true}
      type={ERROR_TYPES.SOMETHING_WENT_WRONG}
      message={strapiErrorMessage['SYSTEM_ERROR']}
    />
  );
};

export default ModuleTableErrorState;
