import { gql } from '@apollo/client';

export const LearningJourneyFragment = gql`
  fragment LearningJourneyFragment on LearningJourneyEntityResponse {
    data {
      attributes {
        ListAllLearningJourneys {
          pageHeading
          pageDescription
          allLearningJourneysFetched
          loadingMoreLearningJourneys
          errorLoadingLearningJourneys
          noJourneysActionLabel
          noJourneysMessage
        }
        LearningJourneyCard {
          thumbnailAlt
          coursesCountLabel
          linkAriaLabel
          inProgressBadgeLabel
          notStartedBadgeLabel
          completedBadgeLabel
        }
        LearningJourneyDetail {
          headerBackLabel
          allCoursesFetched
          loadingMoreCourses
          errorLoadingCourses
          detailsLoadingErrorMessage
          noCoursesForJourneyMessage
          checkoutOtherJourneysMessage
        }
      }
    }
  }
`;
