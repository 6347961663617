import { useInfiniteQuery } from '@tanstack/react-query';
import { useReduxAuthState } from '../../../hooks';
import { listAllLearningJourneys } from '../api/learning-journey';
import { DEFAULT_PAGE_SIZE } from '../constants/learning-journey';
import { QUERY_KEYS, QUERY_STALE_TIME_MS } from '../helpers/tanstack-query';

const useListAllLearningJourneys = () => {
  const auth = useReduxAuthState();

  if (!auth.isAuthenticated) {
    throw new Error('User should be authenticated');
  }

  const query = useInfiniteQuery({
    queryKey: QUERY_KEYS.listAllLearningJourneys({
      username: auth.user.username,
    }),
    queryFn: ({ pageParam = 1 }) => {
      return listAllLearningJourneys({
        pageSize: DEFAULT_PAGE_SIZE.JOURNEYS.LIST.ALL,
        pageOffset: (pageParam - 1) * DEFAULT_PAGE_SIZE.JOURNEYS.LIST.ALL,
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      const fetchedJourneysCount = allPages.length * DEFAULT_PAGE_SIZE.JOURNEYS.LIST.ALL;
      const totalJourneysCount = lastPage.total;

      //   This means we have fetched all the learning journeys
      if (fetchedJourneysCount >= totalJourneysCount) {
        return undefined;
      }

      const nextPageIndex = allPages.length + 1;
      return nextPageIndex;
    },
    staleTime: QUERY_STALE_TIME_MS.listAllLearningJourneys,
  });

  return query;
};

export default useListAllLearningJourneys;
