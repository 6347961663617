import { useMemo } from 'react';
import { CognitoTriggerCustomException } from '../../constants';
import Alert from '../Alert';
import { AlertVariant } from '../Alert/Alert';
import { useStrapiSharedData } from '../../features/shared/hooks/useStrapiSharedData';

type Props = {
  message: string;
};

const UserSocialLoginAlert = ({ message }: Props) => {
  const { SSO: cms } = useStrapiSharedData();

  const { alertVariant, alertMessage } = useMemo(() => {
    let alertMessage = 'Generic';
    let alertVariant: AlertVariant = 'error';

    if (message.includes(CognitoTriggerCustomException.ACCOUNT_WITH_EMAIL_EXIST_EXCEPTION)) {
      alertMessage = cms.usernamePasswordBasedAccountExistsMessage;
      alertVariant = 'error';
    } else if (
      message.includes(CognitoTriggerCustomException.ACCOUNT_WITH_EXTERNAL_PROVIDER_EXIST_EXCEPTION)
    ) {
      alertMessage = cms.accountExistsWithExternalProviderMessage;
      alertVariant = 'error';
    } else if (message.includes(CognitoTriggerCustomException.REGISTRATION_IN_PROGRESS)) {
      alertMessage = cms.registrationIsInProgressWithUsernameMessage;
      alertVariant = 'error';
    }

    return {
      alertVariant,
      alertMessage,
    };
  }, [
    cms.accountExistsWithExternalProviderMessage,
    cms.usernamePasswordBasedAccountExistsMessage,
    message,
  ]);

  return <Alert variant={alertVariant} message={alertMessage}></Alert>;
};

export default UserSocialLoginAlert;
