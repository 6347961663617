import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { InView } from 'react-intersection-observer';
import useGetEarnedBadgesQuery from '../../../badges/hooks/useGetEarnedBadgesQuery';
import useStrapiBadgesData from '../../../badges/hooks/useStrapiBadgeData';
import { PartialBadgesFilter } from '../../../badges/types/badges';
import { MY_BADGES_DEFAULT_PAGE_SIZE, MY_BADGES_DEFAULT_PAGE_START } from '../../constants/badges';
import BadgesDashboard from './BadgesDashboard/BadgesDashboard';
import useGetUpNextBadgesQuery from '../../../badges/hooks/useGetUpNextBadgesQuery';

interface Props {
  className: string;
  lazyLoad?: boolean;
}

const MyBadgesDashBoardComponent = ({ className, lazyLoad = true }: Props) => {
  const upNextBadges = useGetUpNextBadgesQuery();
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);
  // Build the badge filters from the available filters
  const filters: PartialBadgesFilter = useMemo(() => {
    return {
      start: MY_BADGES_DEFAULT_PAGE_START,
      size: MY_BADGES_DEFAULT_PAGE_SIZE,
    };
  }, []);

  const earnedBadges = useGetEarnedBadgesQuery({ filters, isEnabled: isInView });
  const { dashBoard, myBadges: myBadgesTitle } = useStrapiBadgesData();
  const { myBadges } = dashBoard;

  const areEarnedBadgesLoading = earnedBadges.isLoading;
  const hasEarnedBadges = earnedBadges.data && earnedBadges.data.count > 0;

  const showEarnedBadges = areEarnedBadgesLoading || hasEarnedBadges;

  return (
    <InView
      as="div"
      className={clsx('flex h-full flex-col gap-3 rounded-lg bg-card-bg p-4 px-5', className)}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      {showEarnedBadges ? (
        <BadgesDashboard
          title={myBadges.badgesLabel}
          navigationButton={myBadges.viewAllButton}
          navigationTo={'/badges'}
          fetchQuery={earnedBadges}
        />
      ) : (
        <BadgesDashboard
          title={myBadgesTitle.upNextBadges.title}
          navigationButton={myBadges.viewAllButton}
          navigationTo={'/badges'}
          fetchQuery={upNextBadges}
        />
      )}
    </InView>
  );
};

export default MyBadgesDashBoardComponent;
