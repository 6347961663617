import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useState } from 'react';
import TooltipWrapper from '../../../../components/TooltipWrapper/TooltipWrapper';
import cn from '../../../../utils/cn';
import HelpWidgetDialog, { type HelpWidgetDialogType } from './HelpWidgetDialog';
import MailtoContactUsWidget from './MailtoContactUsWidget';
import RaiseRequestToHQWidget from './RaiseRequestToHQWidget';
import { useStrapiSharedData } from '../../hooks/useStrapiSharedData';

type HelpWidgetProps = {
  triggerClassName?: string;
  isAllowedToRaiseRequestToHQ?: boolean;
};

// Help Widget
const HelpWidget = (props: HelpWidgetProps) => {
  const { triggerClassName, isAllowedToRaiseRequestToHQ = false } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<HelpWidgetDialogType | null>(null);

  // strapi
  const { helpAndResourcesWidget: cms } = useStrapiSharedData();
  // strapi

  return (
    <DropdownMenu.Root>
      <TooltipWrapper
        content={cms.triggerLabel}
        asChild
        sideOffset={8}
        collisionPadding={8}
        alignment="start"
      >
        <DropdownMenu.Trigger
          className={cn(
            'flex items-center justify-center rounded-full bg-zinc-700 p-2',
            triggerClassName
          )}
          aria-label={cms.triggerLabel}
          tabIndex={0} // Ensure it's focusable if it's not inherently interactive
        >
          <span className="h-6 w-6 shrink-0 text-zinc-50" aria-label={cms.triggerLabel}>
            ?
          </span>
        </DropdownMenu.Trigger>
      </TooltipWrapper>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="isolate z-30 mx-2 flex min-w-[10rem] max-w-[min(20rem,calc(100vw-2*var(--dropdown-content-mx)))] flex-col items-start rounded-lg border border-zinc-700 bg-zinc-800 shadow-[0px_4px_6px_-2px_rgba(16,24,40,0.03),0px_12px_16px_-4px_rgba(16,24,40,0.08)] [--dropdown-content-mx:0.5rem] xs:min-w-[12rem]"
          sideOffset={8}
          align="start"
          alignOffset={-6}
        >
          {/* Content */}
          <DropdownMenu.Group className="w-full py-1.5 px-1">
            {/* If the user is allowed to raise a request to hq admin
            then user HQ Admin request dialog else use mailto contact us */}
            {isAllowedToRaiseRequestToHQ ? (
              <RaiseRequestToHQWidget
                onSelect={() => {
                  setDialogType('raise-request-to-hq');
                  setIsDialogOpen(true);
                }}
              />
            ) : (
              <MailtoContactUsWidget />
            )}
          </DropdownMenu.Group>
          {/* Arrow */}
          <DropdownMenu.Arrow className="fill-zinc-700" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
      {/* Dialog */}
      <HelpWidgetDialog isOpen={isDialogOpen} setIsOpen={setIsDialogOpen} type={dialogType} />
    </DropdownMenu.Root>
  );
};

export default HelpWidget;
