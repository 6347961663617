import { LOCAL_STORAGE_AMPLIFY_CONFIG_KEY } from '../../features/auth/constants';
import type { AmplifyAuthOptions } from '../../types';
import { readFromLocalStorage } from '../../utils';

// If there is a config in `localStorage` load it or return `undefined` if there is no config
const getConfig = (): AmplifyAuthOptions | undefined => {
  if (window) {
    try {
      // Get the base amplify auth config
      // use remember me value to decide on the storage for auth config
      const baseAuthConfig = readFromLocalStorage<AmplifyAuthOptions>(
        LOCAL_STORAGE_AMPLIFY_CONFIG_KEY
      );

      if (baseAuthConfig) {
        const storage = localStorage;
        const authConfig: AmplifyAuthOptions = { ...baseAuthConfig, storage };
        return authConfig;
      }

      return undefined;
    } catch (error) {
      console.log('Error parsing localStorage cached amplify config', error);
      return undefined;
    }
  }
  return undefined;
};
export default getConfig;
