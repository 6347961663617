import { useQuery } from '@tanstack/react-query';
import { getCourseModules } from '../api/courses';

interface Props {
  id: string | undefined;
}

const useGetCourseModulesQuery = ({ id }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery(
      [
        {
          scope: 'courses',
          item: 'modules',
          id,
        },
      ],
      async () => {
        return getCourseModules(`${id}`);
      },
      {
        enabled: id != undefined,
        onError: (error) => {
          console.error('Error while fetching modules %o', error);
        },
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch };
};

export default useGetCourseModulesQuery;
