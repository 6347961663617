import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
import { USHGAPIError } from '../../../../../types';
import { initiateForgotPasswordRequest } from '../../../helpers';
import BasicSpinner from '../../../../../components/Spinners/BasicSpinner';
import Button from '../../../../../components/Buttons/Button';
import Alert from '../../../../../components/Alert/Alert';
import { Label, TextInput } from '../../../../../components/FormElements';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';

// Forgot Password Schema section starts
const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .trim()
    .lowercase()
    .email('validEmail')
    .required('emailRequired')
    .label('Email'),
});

type ForgotPasswordFormData = yup.InferType<typeof forgotPasswordSchema>;

// Forgot Password Schema section ends

type Props = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;

const UserForgotPasswordForm = ({ className, ...otherProps }: Props) => {
  const navigate = useNavigate();
  const data: any = useStrapiDataHelper();
  const { resetPasswordButton, emailLabel, emailPlaceholder, forgotPasswordInitiationFailed } =
    data.forgotpassword.data.attributes;
  const strapiErrorMessage = data.errorsMap;
  const strapiErrorData = data?.errormessage?.data.attributes;
  const { validEmail, emailRequired } = strapiErrorData;
  const errorMap: Record<string, string> = {
    validEmail: validEmail,
    emailRequired: emailRequired,
  };
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid, isSubmitted },
  } = useForm<ForgotPasswordFormData>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(forgotPasswordSchema),
  });

  // Reason Forgot Password Request Failed Reason
  const [failedMessage, setFailedMessage] = useState<string | null>(null);
  // Initiate a forgot password request
  const formSubmitHandler: SubmitHandler<ForgotPasswordFormData> = async (data) => {
    try {
      setFailedMessage(null);
      const { email: username } = data;
      await initiateForgotPasswordRequest(username);
      navigate(`/auth/reset-password?email=${username}`, {
        state: { fromPath: '/auth/forgot-password' },
      });
    } catch (error) {
      console.log(error);

      if (axios.isAxiosError(error)) {
        const data = error.response?.data;

        if (data) {
          // USHG API Error
          const { errorCode, errorMessage } = data as USHGAPIError;

          switch (errorCode) {
            case 'USER_NOT_FOUND': {
              setFailedMessage(strapiErrorMessage['USER_NOT_FOUND']);
              console.error(errorMessage);
              break;
            }
            default: {
              setFailedMessage(forgotPasswordInitiationFailed);
            }
          }
        } else {
          setFailedMessage(forgotPasswordInitiationFailed);
        }
      } else {
        // AuthErrors from amplify would have code and message
        const { code, message } = error as { code: string; message: string };

        switch (code) {
          case 'UserNotFoundException':
          case 'LimitExceededException': {
            setFailedMessage(message);
            break;
          }
          default: {
            setFailedMessage(forgotPasswordInitiationFailed);
          }
        }
      }
    }
    // for react-hook-form isSubmitting boolean value
    return;
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(formSubmitHandler)}
        className={`flex w-full max-w-md flex-col gap-5 ${className ?? ''}`}
        {...otherProps}
      >
        {failedMessage && <Alert variant="error" message={failedMessage}></Alert>}
        <div className="flex flex-col gap-2">
          <Label htmlFor="email_input" label={emailLabel} isRequired={true} />
          <TextInput
            className="rounded-md"
            id="email_input"
            placeholder={emailPlaceholder}
            type="text"
            autoComplete="username"
            {...register('email')}
          />
          {errors.email?.message && <ErrorMessage message={errorMap[errors.email.message]} />}
        </div>
        <Button
          type="submit"
          // Disable when we're submitting or when the form is submitted once
          // and is not valid
          disabled={isSubmitting || (!isValid && isSubmitted)}
          className="my-3.5 flex items-center justify-center bg-base-brand text-sm font-semibold leading-[25px] text-white"
        >
          {isSubmitting ? (
            <BasicSpinner className="h-[25px] w-[25px] text-white" />
          ) : (
            resetPasswordButton
          )}
        </Button>
      </form>
    </>
  );
};

export default UserForgotPasswordForm;
