import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionData } from '../types/subscription-data';

interface SubscriptionState {
  subscriptionData: SubscriptionData | null;
}

const initialState: SubscriptionState = {
  subscriptionData: null,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionData: (state, event: PayloadAction<any>) => {
      state.subscriptionData = event.payload;
    },
  },
});

export const { setSubscriptionData } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
