interface NumberCountCellProps {
  number: number;
}

const NumberCountCell = (props: NumberCountCellProps) => {
  const { number } = props;
  return <span className="whitespace-nowrap">{number}</span>;
};

export default NumberCountCell;
