import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { GET_MANAGER_TEAM_ID_API_PATH } from '../constants';
import { ManagerTeamIdResponse } from '../types/interface';

const getManagerTeamId = async (): Promise<ManagerTeamIdResponse> => {
  const path = GET_MANAGER_TEAM_ID_API_PATH;
  try {
    const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?`, {
      response: true,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export { getManagerTeamId };
