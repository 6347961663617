import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { TEAMS_API_PATHS } from '../constants';
import {
  GetTeamNameAPIResponse,
  AddTeamMemberRequest,
  AddTeamMemberRequestAPIResponse,
  RequestToAddTeamMemberAPIResponse,
  AdminAddTeamMemberArgs,
} from '../types';

interface GetTeamNameArgs {
  teamId: number;
}

/**
 * Get team name by team id
 */
const getTeamName = async (args: GetTeamNameArgs): Promise<GetTeamNameAPIResponse> => {
  const { teamId } = args;
  const params = {
    teamId,
  };
  const queryString = qs.stringify(params);
  const path = TEAMS_API_PATHS.GET_TEAM_NAME;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${queryString}`, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Admin add a member
 */
const adminAddTeamMember = async (
  args: AdminAddTeamMemberArgs
): Promise<AddTeamMemberRequestAPIResponse> => {
  const payload = {
    body: {
      leader: args?.leader?.email ? [args.leader] : [],
      team: !args.isLeader
        ? [
            {
              teamName: args.teamName,
              teamId: args.teamId,
              members: [
                {
                  email: args.email,
                  jobTitle: args.jobTitle,
                  role: args.role,
                },
              ],
            },
          ]
        : [],
    },
    withCredentials: true,
  };
  const path = TEAMS_API_PATHS.ENTERPRISE_ADMIN.ADD_NEW_MEMBER;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, payload);
  return response;
};

/**
 * Add a member request
 */
const requestToAddTeamMember = async (
  args: AddTeamMemberRequest
): Promise<RequestToAddTeamMemberAPIResponse> => {
  const payload = {
    body: {
      requestType: 'NEW_MEMBER',
      member: {
        email: args.email,
        role: args.role,
        jobTitle: args.jobTitle,
        teamId: args.teamId,
      },
    },
    withCredentials: true,
  };
  const path = TEAMS_API_PATHS.ADD_NEW_MEMBER_REQUEST;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, payload);
  return response;
};
export { getTeamName, adminAddTeamMember, requestToAddTeamMember };
