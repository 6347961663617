import { useCallback } from 'react';
import { useUpdateMaintenanceModeMutation } from '../../../../../hooks';
import { ActionDialogContent } from '../../../../common/components';
import { useStrapiSettingsData } from '../../../../../hooks/useStrapiSettingsData';

export type UpdateAction = 'Enable' | 'Disable';

interface Props {
  action: 'Enable' | 'Disable';
  setShowDialog: (show: boolean) => void;
}

const UpdateMaintenanceDialogContent = ({ action, setShowDialog }: Props) => {
  // derived state
  const enableMaintenanceMode = action === 'Enable';
  // derived state

  // mutation
  const updateMaintenanceModeMutation = useUpdateMaintenanceModeMutation();
  // mutation

  // strapi content

  const { hqAdminPlatformMaintenanceMode: maintenanceModeCMS } = useStrapiSettingsData();

  const enableMaintenanceModeDialogCMS = maintenanceModeCMS.EnableMaintenanceModeDialog;
  const disableMaintenanceModeDialogCMS = maintenanceModeCMS.DisableMaintenanceModeDialog;

  const cmsContent = enableMaintenanceMode
    ? enableMaintenanceModeDialogCMS
    : disableMaintenanceModeDialogCMS;

  // strapi content

  // handlers

  const handleUpdateMaintenanceModeDialog = useCallback(async () => {
    try {
      await updateMaintenanceModeMutation.mutateAsync({
        action,
      });
      setShowDialog(false);
    } catch (error) {
      console.error(error);
    }
  }, [action, setShowDialog, updateMaintenanceModeMutation]);

  const cancelAction = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  // handlers

  return (
    <ActionDialogContent
      heading={cmsContent.heading}
      Description={cmsContent.description}
      primaryAction={{
        handler: handleUpdateMaintenanceModeDialog,
        label: cmsContent.primaryActionLabel,
        isLoading: updateMaintenanceModeMutation.isLoading,
      }}
      secondaryAction={{
        handler: cancelAction,
        label: cmsContent.secondaryActionLabel,
      }}
    ></ActionDialogContent>
  );
};

export default UpdateMaintenanceDialogContent;
