export const DEFAULT_IMAGE_URL = `https://avatars.dicebear.com/api/micah/${Math.random()}.svg`;

export const STALE_TIME = 2000;

export const MONTH = 'month';
export const YEAR = 'year';
export const PRICING_TYPE = 'PRICING_TYPE';
export const LICENSES = 'LICENSES';
export const MIN_LICENCE_CAN_BE_ADDED = 0;
export const MAX_LICENCE_CAN_BE_ADDED = 501;

export const DEFAULT_TIME = 167548437;
