import { gql } from '@apollo/client';

export const MessageFragment = gql`
  fragment MessageFragment on MessageEntityResponse {
    data {
      attributes {
        TopNav {
          title
          backButton
        }
        MessagingTools {
          sendButton
          courseButton
          todayLabel
          yesterdayLabel
          youLabel
          textBoxPlaceholder
          atLabel
          reachedEndMessage
        }
        AttachCourseModal {
          title
          searchCourseLabel
          searchCoursePlaceholder
          courseRequired
          courseAttachSuccess
          courseAttachFailure
          cancelButton
          attachCourseButton
          courseSharedInChannelLabel
        }
        ErrorMessage {
          errorMessage
          dataBadgeErrorMessage
          shareCourseCardErrorMessage
          courseCardErrorMessage
          channelErrorTitle
          channelErrorDescription
        }
        loadingMessages {
          loadingMessages
          loadingChannels
          loadingMoreChannels
        }
        EmptyMessage {
          noChatMessage
          noChannelMessage
        }
        InitialisationMessages {
          selectChat
          InitiatingMessages
        }
        Misc {
          andOthersLabel
          andLabel
          deletedLabel
        }
      }
    }
  }
`;
