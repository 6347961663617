import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../../../../../../../../../components/AlertDialog';
import { useAppSelector } from '../../../../../../../../../hooks';
import { RootState } from '../../../../../../../../../store';
import SubscriptionInfo from '../../../../../../../../teams/components/Modal/subscriptionInfo/subscriptionMain';
import useStrapiMembersData from '../../../../../../../hooks/useStrapiMembersData';

// Subscription State Selector
const subscriptionStateSelector = (state: RootState) => state.subscription;

const SubscriptionLimitExceededModal = () => {
  // Subscription Data
  const subscription = useAppSelector(subscriptionStateSelector);
  const totalLicenses = subscription.subscriptionData?.totalLicenses as number;

  const navigate = useNavigate();

  const navigateToBillingPage = useCallback(() => {
    // Maybe we can send some state from here
    // To automatically open the modal that could change the subscription plan
    navigate('/settings?view=billing');
  }, [navigate]);
  const { subscriptionInfoModal } = useStrapiMembersData();
  const { title, cancelButton, actionButton } = subscriptionInfoModal;
  return (
    <AlertDialog
      contentWrapperClassName="gap-4"
      Title={title}
      Content={
        <SubscriptionInfo
          existingMemberCount={totalLicenses}
          // We would need one additional license
          newMemberCount={totalLicenses + 1}
          isOnBoarding={false}
        />
      }
      actionItem={{
        label: actionButton,
        action: navigateToBillingPage,
      }}
      cancelItem={{
        label: cancelButton,
      }}
    ></AlertDialog>
  );
};

export default SubscriptionLimitExceededModal;
