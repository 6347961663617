import { useQuery } from '@tanstack/react-query';
import { getUSHGAdminCourseModules } from '../api';
import { MODULE_TABLE_STALE_TIME } from '../constants/course-module-table';
import { PartialUSHGAdminCourseModulesFilter } from '../types';

interface Props {
  filters: PartialUSHGAdminCourseModulesFilter;
  courseId: number;
}

const useUSHGAdminCourseModulesQuery = ({ filters, courseId }: Props) => {
  const courseModule = useQuery(
    [
      {
        scope: 'single-course',
        item: 'modules',
        filters,
        courseId,
      },
    ],
    async ({ queryKey }) => {
      const { filters, courseId } = queryKey[0];
      return getUSHGAdminCourseModules({ filters, courseId });
    },
    {
      keepPreviousData: true,
      staleTime: MODULE_TABLE_STALE_TIME,
    }
  );

  return courseModule;
};

export default useUSHGAdminCourseModulesQuery;
