import { COURSES_BASE_PATH } from '../../courses/constants';

const PATHS = {
  LEARNING_JOURNEY: {
    LIST: `${COURSES_BASE_PATH}/v1/learning-journeys`,
    DETAIL: ({ id }: { id: string }) => `${COURSES_BASE_PATH}/v1/learning-journeys/${id}`,
    COURSES: ({ id }: { id: string }) => `${COURSES_BASE_PATH}/v1/learning-journeys/${id}/courses`,
    META_DATA: ({ journeyId, courseId }: { journeyId: string; courseId: string }) =>
      `${COURSES_BASE_PATH}/v1/learning-journeys/${journeyId}/courses/${courseId}`,
  },
};

export { PATHS };
