import { createContext } from 'react';
import { CognitoAuthChallenge } from '../../../constants';

type AuthChallenge = keyof typeof CognitoAuthChallenge;

export type LoginAuthChallengeContext = {
  authChallenge: Required<AuthChallenge> | null;
  setAuthChallenge: React.Dispatch<React.SetStateAction<AuthChallenge | null>>;
};

const loginAuthChallengeContext = createContext({} as LoginAuthChallengeContext);

export default loginAuthChallengeContext;
