import clsx from 'clsx';

interface Props {
  title: string;
  className?: string;
}

const TableHeader = ({ title, className }: Props) => {
  return (
    <div className={clsx('flex flex-row items-center justify-between gap-2', className)}>
      <h2 className="text-sm font-bold leading-[150%] text-white">{title}</h2>
    </div>
  );
};

export default TableHeader;
