import type { UseQueryResult } from '@tanstack/react-query';
import { ErrorDisplay } from '../../../../../components/DataTables';
import { ERROR_TYPES } from '../../../../../constants/error-types.constants';
import useStrapiBadgesData from '../../../hooks/useStrapiBadgeData';

interface Props {
  refetch: UseQueryResult['refetch'];
  isRefetching: boolean;
}

const BadgesErrorState = ({ refetch, isRefetching }: Props) => {
  const { errorMessage } = useStrapiBadgesData().hqError;
  return (
    <ErrorDisplay
      refetch={refetch}
      isRefetching={isRefetching}
      allowsRefetch={true}
      message={errorMessage}
      type={ERROR_TYPES.SOMETHING_WENT_WRONG}
    />
  );
};

export default BadgesErrorState;
