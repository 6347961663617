import { COMMON_SIZE } from '../../../constants/size-constants';
import { MEDIA_UPLOAD_FORMAT, MEDIA_TYPE_FORMAT } from '../constants/imageUpload';

export const mediaFormatResolver = (format: string) => {
  if (format === 'image') {
    return MEDIA_UPLOAD_FORMAT.IMAGE;
  } else if (format === 'video') {
    return MEDIA_UPLOAD_FORMAT.VIDEO;
  } else if (format === 'application') {
    return MEDIA_UPLOAD_FORMAT.DOCUMENT;
  } else if (format === 'audio') {
    return MEDIA_UPLOAD_FORMAT.AUDIO;
  } else return [];
};

export const validateMediaFile = (fileArray: any, maxSize: any, format: string, field: any) => {
  const file = fileArray[0];
  const fileName = file.name;
  const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
  if (file) {
    const fileFormat = file.type.split('/');
    const validFormats: string[] = mediaFormatResolver(format);
    if (fileFormat[0] === format) {
      if (validFormats.includes(fileFormat[1])) {
        const fileSize = file.size / COMMON_SIZE.ONE_MB;
        if (fileSize <= maxSize) {
          return { error: false, message: 'file valid', type: fileFormat[1] };
        } else {
          return { error: true, message: `${field} size is more than the expected size` };
        }
      } else {
        return {
          error: true,
          message: `${field} can only be of one of the formats: ${validFormats.join(',')}`,
        };
      }
    } else {
      if (fileExtension != format) {
        return { error: true, message: `${field} need to be an ${MEDIA_TYPE_FORMAT[format]}` };
      }
      if (fileFormat[0] === format) {
        return { error: true, message: `${field} need to be an ${MEDIA_TYPE_FORMAT[format]}` };
      } else {
        return { error: false, message: 'file valid', type: fileExtension };
      }
    }
  } else {
    return { error: true, message: `Cannot find ${field}` };
  }
};

export const validateMediaUrl = (url: string, format: string, field: any) => {
  const fileExtension = url.split('.').pop(); // Get the extension from the URL
  const validFormats: string[] = mediaFormatResolver(format);

  if (fileExtension && validFormats.includes(fileExtension)) {
    return { error: false, message: 'file valid', type: fileExtension };
  } else {
    return {
      error: true,
      message: `${field} can only be of one of the formats: ${validFormats.join(',')}`,
    };
  }
};
