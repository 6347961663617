import { useInfiniteQuery } from '@tanstack/react-query';
import { ListChannelMessagesArgs, listChannelMessages } from '../../messaging/helpers';

interface Props {
  channelArn: string;
  chimeBearer: string;
  staleTime?: number;
  cacheTime?: number;
  refetchOnMount?: boolean;
  maxResults?: number;
}

const useListChannelMessages = (props: Props) => {
  const { channelArn, chimeBearer, staleTime, cacheTime, refetchOnMount, maxResults } = props;

  const query = useInfiniteQuery({
    queryKey: [
      {
        scope: 'messaging',
        item: 'list-channel-messages',
        channelArn,
        chimeBearer,
        maxResults,
      },
    ],
    queryFn: async ({ queryKey, pageParam }) => {
      const { channelArn, chimeBearer, maxResults } = queryKey[0];

      const listChannelMessagesParams: ListChannelMessagesArgs = {
        channelArn,
        chimeBearer,
        nextToken: pageParam,
        maxResults,
      };

      const listChannelMessagesResponse = await listChannelMessages(listChannelMessagesParams);

      const channelMessagesInResponse = listChannelMessagesResponse.ChannelMessages ?? [];

      // Sort the channel messages
      const channelMessages = channelMessagesInResponse.sort((m1, m2) => {
        if (m1.CreatedTimestamp === undefined && m2.CreatedTimestamp === undefined) {
          return 0;
        }

        if (m1.CreatedTimestamp === undefined) {
          return 1;
        }

        if (m2.CreatedTimestamp === undefined) {
          return -1;
        }

        if (m1.CreatedTimestamp < m2.CreatedTimestamp) {
          return 1;
        } else if (m1.CreatedTimestamp > m2.CreatedTimestamp) {
          return -1;
        } else {
          return 0;
        }
      });

      return { channelMessages, nextToken: listChannelMessagesResponse.NextToken };
    },
    select: (data) => {
      const messages = data.pages.map((page) => page.channelMessages).flatMap((x) => x);

      return {
        pages: messages,
        pageParams: data.pageParams,
      };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextToken;
    },
    refetchOnMount,
    staleTime,
    cacheTime,
  });

  return query;
};

export default useListChannelMessages;
