import clsx from 'clsx';

interface GenericStatusCellProps {
  containerClassName?: string;
  dotClassName?: string;
  display: string;
}

const GenericStatusCell = (props: GenericStatusCellProps) => {
  const { containerClassName, dotClassName, display } = props;
  return (
    <div
      className={clsx(
        'flex w-min items-center gap-[9px] rounded-[100px] py-[2px] pr-2 pl-1.5',
        containerClassName
      )}
    >
      <span className={clsx('h-1.5 w-1.5 rounded-full', dotClassName)}></span>
      <span className="text-xs font-normal">{display}</span>
    </div>
  );
};

export default GenericStatusCell;
