import clsx from 'clsx';
import {
  CoursePerformanceRatesViz,
  AssessmentsScoreViz,
  CourseCompletedAssignedStatsViz,
  EngagementByCategoriesViz,
  HoursSpentFrequencyViz,
} from '../../../shared/components/charts';
import {
  CoursePerformanceStatisticCards,
  DurationDisplayHeader,
} from '../../../shared/components/Reports';
import {
  MostPopularCoursesTable,
  TeamsLeaderboardTable,
  TopEarnedBadgesTable,
} from '../../../shared/components/Reports/Tables';
import { useStrapiReportsData } from '../../../shared/hooks/useStrapiReportData';
import TeamLeaderboardTable from '../../../shared/components/Reports/Tables/TeamLeaderboard/TeamLeaderboardTable';
import { USHG_HQ_APP_ROLE } from '../../../../constants';

interface Props {
  className?: string;
  lazyLoad?: boolean;
}
const EnterpriseManagerOverallReports = ({ className, lazyLoad = true }: Props) => {
  const {
    completionRate,
    AssessmentScore,
    HoursSpentFrequency,
    CourseAssignedOrCompleted,
    EngagementByCategory,
    ReportTooltipContent,
  } = useStrapiReportsData();
  return (
    <main id="main-content" className={clsx('w-full', className)}>
      <div className="grid auto-rows-min grid-cols-9 gap-x-3 gap-y-2 sm:gap-y-3 lg:gap-x-[13px] lg:gap-y-4 xl:gap-y-5">
        <DurationDisplayHeader className="col-span-9 col-start-1 row-start-2 overflow-hidden" />
        <CoursePerformanceStatisticCards
          className="col-span-9 col-start-1 row-start-3 overflow-hidden"
          lazyLoad={lazyLoad}
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
        />
        <CoursePerformanceRatesViz
          className="col-span-9 col-start-1 row-start-4 overflow-hidden lg:col-span-3"
          heading={completionRate.title}
          lazyLoad={lazyLoad}
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
        />
        <AssessmentsScoreViz
          className="col-span-9 col-start-1 row-start-5 overflow-hidden lg:col-span-3 lg:row-start-4"
          heading={AssessmentScore.title}
          lazyLoad={lazyLoad}
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
        />
        <CourseCompletedAssignedStatsViz
          className="col-span-9 col-start-1 row-start-6 overflow-hidden lg:col-span-3 lg:row-start-4"
          heading={CourseAssignedOrCompleted.title}
          lazyLoad={lazyLoad}
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
        />
        {/* <CompletionPerformanceRatesOverTimeViz
          className="col-span-9 col-start-1 row-start-7 overflow-hidden lg:col-span-3 lg:row-start-5"
          heading="Completion Rates - Over Time"
        /> */}
        <EngagementByCategoriesViz
          className="col-span-9 col-start-1 row-start-7 overflow-hidden lg:col-span-6 lg:row-start-5"
          heading={EngagementByCategory.title}
          lazyLoad={lazyLoad}
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
          xAxisLabel={EngagementByCategory.xAxisLabel}
        />
        <HoursSpentFrequencyViz
          className="col-span-9 col-start-1 row-start-[8] overflow-hidden lg:col-span-3 lg:row-start-5"
          heading={HoursSpentFrequency.title}
          lazyLoad={lazyLoad}
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
        />

        {/* <LoginFrequencyViz
          className="col-span-9 col-start-1 row-start-[11] overflow-hidden md:row-start-[10] lg:col-span-3 lg:col-start-7 lg:row-start-6"
          heading="Login Frequency"
        /> */}
      </div>
      <div className="grid auto-rows-min grid-cols-8 gap-x-3 gap-y-2 sm:gap-y-3 lg:gap-x-[13px] lg:gap-y-4 xl:gap-y-5">
        <TeamLeaderboardTable
          className="col-span-8 col-start-1 row-start-2 overflow-hidden md:col-span-4"
          tableClassName="border border-zinc-700"
          lazyLoad={lazyLoad}
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
        />
        <TeamsLeaderboardTable
          className="col-span-8 row-start-3 overflow-hidden md:col-span-4 md:row-start-2"
          tableClassName="border border-zinc-700"
          lazyLoad={lazyLoad}
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
        />
        <TopEarnedBadgesTable
          className="col-span-8 col-start-1 row-start-4 overflow-hidden md:col-span-4 md:row-start-3"
          tableClassName="border border-zinc-700"
          lazyLoad={lazyLoad}
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
        />
        <MostPopularCoursesTable
          className="col-span-8 col-start-1 row-start-5 overflow-hidden md:col-span-4 md:row-start-3"
          tableClassName="border border-zinc-700"
          lazyLoad={lazyLoad}
          reportTooltipContent={ReportTooltipContent}
          userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
        />
        {/* <MostPopularInstructorsTable
          className="col-span-8 row-start-5 overflow-hidden md:col-span-4 md:row-start-3"
          tableClassName="border border-zinc-700"
        /> */}
      </div>
    </main>
  );
};

export default EnterpriseManagerOverallReports;
