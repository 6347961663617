import { filterType } from '../types/Filter.Helper';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getCourses } from '../api/courses';
import { useEffect, useState } from 'react';
import { PAGE_LENGTH } from '../constants/data';

type useFetchCoursesQueryProps = {
  tab: string;
  filters: filterType[];
};

const nextPageParam = (lastPage: any) =>
  lastPage.page + lastPage.pageSize < lastPage.totalCount ? lastPage.page + PAGE_LENGTH : undefined;

const useFetchCoursesQuery = ({ tab, filters }: useFetchCoursesQueryProps) => {
  const fetchCourses = ({ pageParam = 0 }) => {
    return getCourses(filters, tab, pageParam);
  };
  const [totalCount, setTotalCount] = useState();
  const { isLoading, error, data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery(['courses', tab, filters], fetchCourses, {
      getNextPageParam: nextPageParam,
    });

  useEffect(() => {
    if (data) {
      setTotalCount(data.pages[0]?.totalCount);
    }
  }, [data]);

  return { isLoading, error, data, fetchNextPage, totalCount, isFetchingNextPage, hasNextPage };
};

export default useFetchCoursesQuery;
