import { Params, useNavigate, useParams } from 'react-router-dom';
import AdminViewTeamMembersMain from '../../components/Table/AdminViewTeamMembersMain';
import TopNav from '../../components/TopNav/TopNav';
import useTeamNameQuery from '../../hooks/useTeamNameQuery';
import { Loader } from '../../../../components/Loader/Loader';
import { LandingNotFound } from '../../../../components/404Pages/LandingNotFound';
import TeamsEmptyState from '../TeamsEmptyState';
import { useStrapiHQAdminCoursesData } from '../../../course-management/hooks/useStrapiHQAdminCourseData';


function AdminTeamView() {
  const { id }: Readonly<Params<string>> = useParams();
  const navigate = useNavigate();
  let queryData;
  if (id) queryData = useTeamNameQuery({ teamId: parseInt(id) });
  const notExistError = !queryData?.data?.teamName 
  && !queryData?.isLoading 
  && queryData?.data?.message == 'Not Found';

  const {  emptyMessages } = useStrapiHQAdminCoursesData();
  if(notExistError){
    return (<div className="flex w-full">
    <TeamsEmptyState
      message={
        <div className="flex flex-col items-center gap-y-8">
          <span>{emptyMessages.commonEmptyMessage}</span>
          <button
            className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
            onClick={() => navigate('/teams')}
          >
            {'Back to Teams'}
          </button>
        </div>
      }
    />
  </div>)
  }

  return (
    <div>
      {id && queryData && queryData.data && (
        <div className="flex w-full flex-col items-center gap-5">
          <TopNav
            title={queryData.data.teamName as string}
            isSingleTeamView={true}
            isAdminView={true}
            isSubTeamPresentAlready={queryData.data.hasSubTeam}
            isSubTeam={queryData.data.isSubTeam}
            showBackButton={true}
            teamId={parseInt(id)}
          />
          <div className="flex w-full justify-center px-3 xs:px-4 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
            <AdminViewTeamMembersMain className="w-full" />
          </div>
        </div>
      )}
      {queryData && queryData.isLoading && <Loader />}
      {queryData && queryData.isError && <LandingNotFound />}
    </div>
  );
}

export default AdminTeamView;
