import { FadeLoader } from 'react-spinners';
import PatternIllustrator from '../Pages/PatternIllustrator';

export const Loader = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center bg-black font-sans text-white">
      <PatternIllustrator />
      <FadeLoader color="#E4E4E7" width="3px" height="10px" />
    </div>
  );
};
