import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TENURE } from '../constants';
import { Licenses, Pricing, Roles, SubscriptionType, Tenure } from '../types';

type PricingSliceState = {
  tenure: Tenure;
  pricing: Pricing;
  licenses?: Licenses;
};

const initialState: PricingSliceState = {
  tenure: TENURE.MONTH,
  pricing: {
    freeTrail: true,
    subscriptionType: '' as SubscriptionType,
    role: '' as Roles,
  },
  licenses: undefined,
};

const PricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    setTenure: (state, event: PayloadAction<Tenure>) => {
      state.tenure = event.payload;
    },
    setPricingInfo: (state, event: PayloadAction<Pricing>) => {
      state.pricing = event.payload;
    },
    setNumberOfLicense: (state, event: PayloadAction<Licenses>) => {
      state.licenses = event.payload;
    },
  },
});

export const { setTenure, setPricingInfo, setNumberOfLicense } = PricingSlice.actions;

export default PricingSlice.reducer;
