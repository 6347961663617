import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { TextInput } from '../../../../../components/FormElements';
import { USHG_HQ_APP_ROLE } from '../../../../../constants';
import HasAccess from '../../../../shared/components/HasAccess';
import { useAddTeamsAPI } from '../../../hooks/useAddTeamApi';
import { AdminTeamData } from '../../../types/interface';
import { Plus, X } from 'phosphor-react';
import { REQUEST_TYPE, Roles } from '../../../constants/role';
import { useSendTeamsRequest } from '../../../hooks/useTeamsRequest';
import { BasicSpinner } from '../../../../../components/Spinners';
import { SECTIONS } from '../../../constants/section-name';
import Dialog from '../../../../../components/Dialog';
import { useStrapiTeamData } from '../../../hooks/useStrapiTeamData';
import ModalErrorAlert from '../ModalAlert/ModalErrorAlert';
import { queryClient } from '../../../../../config/react-query';
import { checkTeamExist } from '../../../../auth/helpers/register';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { GET_ALL_TEAMS_FOR_LEADERS } from '../../../constants/react-query';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
import { fieldHasError } from '../../../../../utils/react-hook-form';
import SubscriptionInfo from '../subscriptionInfo/subscriptionMain';
import { RootState } from '../../../../../store';
import { useAppSelector, useHasAccess } from '../../../../../hooks';
import { useNavigate } from 'react-router-dom';
import { debounce } from '../../../../../utils';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { REGEX } from '../../../constants/regex';
import { FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY } from '../../../../shared/constants';
import { addNewTeam } from '../../../../onboarding/slices/onboarding.slice';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { Toast } from '../../../../../components/Toast';
import { useStrapiOnBoardingData } from '../../../../onboarding/hooks/useStrapiOnBoardingData';
import { checkUserExists } from '../../../../auth/api';
import { TRAIL_LIMIT } from '../../../../../constants/subscription-plans';
import Button from '../../../../../components/Buttons/Button';
import { yupResolver } from '@hookform/resolvers/yup';

interface schema {
  teamNameRequired: string;
  teamNameMinValue: number;
  teamNameMaxValue: number;
  teamNameMinError: string;
  teamNameMaxError: string;
  validEmailError: string;
  managerEmailRequired: string;
  memberEmailRequired: string;
  emailMaxValue: number;
  emailLimitError: string;
  jobTitleRequired: string;
  jobTitleMinValue: number;
  jobTitleMaxValue: number;
  jobTitleMinError: string;
  jobTitleMaxError: string;
}
function schema(data: schema) {
  const {
    teamNameRequired,
    teamNameMinValue,
    teamNameMaxValue,
    teamNameMinError,
    teamNameMaxError,
    validEmailError,
    managerEmailRequired,
    memberEmailRequired,
    emailMaxValue,
    emailLimitError,
    jobTitleRequired,
    jobTitleMinValue,
    jobTitleMaxValue,
    jobTitleMinError,
    jobTitleMaxError,
  } = data;
  // Yup Schema

  return yup.object({
    teamName: yup
      .string()
      .trim()
      .required(teamNameRequired)
      .min(teamNameMinValue, teamNameMinError)
      .max(teamNameMaxValue, teamNameMaxError),
    managerEmail: yup
      .string()
      .trim()
      .email(validEmailError)
      .required(managerEmailRequired)
      .max(emailMaxValue, emailLimitError),
    jobTitle: yup
      .string()
      .trim()
      .required(jobTitleRequired)
      .min(jobTitleMinValue, jobTitleMinError)
      .max(jobTitleMaxValue, jobTitleMaxError),
    members: yup.array(
      yup.object({
        email: yup
          .string()
          .trim()
          .email(validEmailError)
          .required(memberEmailRequired)
          .max(emailMaxValue, emailLimitError),
        jobTitle: yup
          .string()
          .trim()
          .required(jobTitleRequired)
          .min(jobTitleMinValue, jobTitleMinError)
          .max(jobTitleMaxValue, jobTitleMaxError),
        role: yup.string(),
      })
    ),
    teamAlreadyExist: yup.string(),
  });
}

// Yup Schema

interface Props {
  setIsModalOpen: (open: boolean) => void;
  currentIndex?: number;
  setCurrentIndex?: any;
  isLoading?: boolean;
  isOnBoarding: boolean;
}
const subscriptionInfoData = (state: RootState) => state.subscription;

function AddNewTeamModal({ setIsModalOpen, isOnBoarding }: Props): JSX.Element {
  const { subscriptionData } = useAppSelector(subscriptionInfoData);
  const { addteam, commomTeamErrorMessage } = useStrapiTeamData();
  const {
    memberAlreadyExists,
    teamAlreadyExists,
    managerMemberSameError,
    teamNameRequired,
    managerEmailRequired,
    memberEmailRequired,
    jobTitleRequired,
    jobTitleMaxError,
    jobTitleMinError,
    emailLimitError,
    validEmailError,
    teamNameMinError,
    teamNameMaxError,
    teamNameMinValue,
    teamNameMaxValue,
    emailMaxValue,
    jobTitleMinValue,
    jobTitleMaxValue,
    sameMemberError,
  } = commomTeamErrorMessage;

  const addNewTeamFormdata = {
    teamNameRequired,
    teamNameMinValue,
    teamNameMaxValue,
    teamNameMinError,
    teamNameMaxError,
    validEmailError,
    managerEmailRequired,
    memberEmailRequired,
    emailMaxValue,
    emailLimitError,
    jobTitleRequired,
    jobTitleMinValue,
    jobTitleMaxValue,
    jobTitleMinError,
    jobTitleMaxError,
  };

  const addNewTeamFormSchema = schema(addNewTeamFormdata);
  type ADD_NEW_TEAM_FORM_DATA = yup.InferType<typeof addNewTeamFormSchema>;
  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    clearErrors,
  } = useForm<ADD_NEW_TEAM_FORM_DATA>({
    mode: 'onChange',
    defaultValues: {
      teamName: '',
      managerEmail: '',
      jobTitle: '',
      members: [{ email: '', jobTitle: '', role: Roles.LEARNER }],
      teamAlreadyExist: '',
    },
    resolver: yupResolver(addNewTeamFormSchema),
  });
  const { fields, append, remove } = useFieldArray({
    name: 'members',
    control,
  });
  const [errorIndex, setErrorIndex] = useState<number>();
  const [isTeamNameCheckInProgress, setIsTeamNameCheckInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isManagerEmailCheckInProgress, setIsManagerEmailCheckInProgress] = useState(false);
  const [memberEmailIndexes, setMemberEmailIndexes] = useState<any>(new Set());

  const {
    addAnotherMemberButton,
    addTeamBillingButton,
    addTeamButton,
    cancelButton,
    addTeamRequestButton,
    teamNameTitle,
    teamNameLabel,
    teamNamePlaceHolder,
    memberDetailsTitle,
    memberEmailLabel,
    memberEmailPlaceHolder,
    memberJobLabel,
    memberJobTitlePlaceHolder,
    managerDetailsTitle,
    managerEmailLabel,
    managerEmailPlaceHolder,
    managerJobLabel,
    managerJobTitlePlaceHolder,
    addNewMemberButton,
  } = addteam;
  const data: any = useStrapiDataHelper();
  const strapiErrorData = data?.errormessage?.data.attributes;
  const { teamNameAlreadyExist } = strapiErrorData;
  const onboardingStateSelector = (state: RootState) => state.onboarding;
  const { teams, leaders } = useAppSelector(onboardingStateSelector);
  const dispatch = useDispatch();
  const toggleVisible = () => {
    setIsModalOpen(false);
    setValue('teamName', '');
    setValue('managerEmail', '');
    setValue('jobTitle', '');
    setValue('members', [{ email: '', jobTitle: '', role: Roles.LEARNER }]);
  };

  // Emails of all team members or leaders that are added so far
  const memberEmailsAdded = useMemo(() => {
    const membersAdded = teams.flatMap((t) => t.members).map((m) => m.email);
    membersAdded.push(...leaders.map((l) => l.email));
    return membersAdded;
  }, [teams, leaders]);

  // Error handling
  const _emailValidate = async (value: string, index: number) => {
    try {
      setMemberEmailIndexes((indexes: any) => {
        const newIndexes = new Set(indexes.values());
        newIndexes.add(index);
        return newIndexes;
      });
      const response = await queryClient.fetchQuery({
        queryFn: async () => {
          return await checkUserExists(value && value.trim());
        },
        queryKey: ['user_exists', value],
        staleTime: 30000,
      });
      setMemberEmailIndexes((indexes: any) => {
        const newIndexes = new Set(indexes.values());
        newIndexes.delete(index);
        return newIndexes;
      });
      if (response.userExistsAlready) {
        return memberAlreadyExists;
      } else {
        for (let x = 0; x < fields.length; x++) {
          if (x !== index && value === getValues(`members.${x}.email`)) {
            return sameMemberError;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _managerEmailValidate = async (value: string) => {
    try {
      setIsManagerEmailCheckInProgress(true);
      const response = await queryClient.fetchQuery({
        queryFn: async () => {
          return await checkUserExists(value && value.trim());
        },
        queryKey: ['user_exists', value],
        staleTime: 60000,
      });
      setIsManagerEmailCheckInProgress(false);
      if (response.userExistsAlready) {
        return memberAlreadyExists;
      } else {
        for (let x = 0; x < fields.length; x++) {
          if (value === getValues(`members.${x}.email`)) {
            return managerMemberSameError;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedTeamValidate = useMemo(() => {
    const _teamValidate = async (value: string) => {
      try {
        if (value.trim().length !== 0) {
          setIsTeamNameCheckInProgress(true);
          const response = await queryClient.fetchQuery({
            queryFn: async () => {
              return await checkTeamExist(value && value.trim());
            },
            queryKey: ['team_exists', value],
            staleTime: 60000,
          });
          if (response.teamNameExistsAlready) {
            setError('teamAlreadyExist', {
              type: 'manual',
              message: teamAlreadyExists,
            });
          } else {
            clearErrors('teamAlreadyExist');
          }
          setIsTeamNameCheckInProgress(false);
        } else {
          clearErrors('teamAlreadyExist');
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    return debounce(_teamValidate, 1000)[0];
  }, [setError, clearErrors]);

  const { addTeams } = useAddTeamsAPI();
  const { sendTeamsRequest } = useSendTeamsRequest();
  const { toastMessages } = useStrapiOnBoardingData();
  const { addMemberFailureTitle, memberAlreadyExist } = toastMessages;

  const onSubmit = async (data: any) => {
    const { teamName, managerEmail, jobTitle, members } = data;
    const isteamexist = await TeamexistValidationCheck(teamName);
    if (isteamexist) {
      console.log('Team Name already exists');
      return;
    }

    const trimmedMembers = members.map((member: any) => {
      const { email, jobTitle, ...rest } = member;
      return { ...rest, email: email.trim(), jobTitle: jobTitle.trim() };
    });

    const manager = [
      {
        email: managerEmail.trim(),
        jobTitle: jobTitle.trim(),
        role: Roles.MANAGER,
      },
    ];
    const memberList = manager.concat(trimmedMembers);
    const teamData: AdminTeamData = {
      teamName: teamName.trim(),
      members: memberList,
      requestType: REQUEST_TYPE.NEW_TEAM,
    };
    if (isOnBoarding) {
      const memberAlreadyAdded = memberList.some((newMember) =>
        memberEmailsAdded.includes(newMember.email)
      );

      if (!memberAlreadyAdded) {
        dispatch(
          addNewTeam({
            teamName,
            members: memberList,
          })
        );
        toggleVisible();
      } else {
        toast.custom(
          (t) => (
            <Toast
              variant={'error'}
              Title={addMemberFailureTitle}
              SubTitle={memberAlreadyExist}
              toastInstance={t}
            />
          ),
          {
            duration: 3000,
          }
        );
      }
    } else {
      try {
        await addTeams(teamData, SECTIONS.ADMIN_TEAMS_LIST_TABLE);
        toggleVisible();
      } catch (error) {
        console.error('Error adding new team', error);
      }
    }
    await queryClient.invalidateQueries({
      queryKey: [FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY],
    });
  };

  const onRequest = async (data: any) => {
    const { teamName, managerEmail, jobTitle, members } = data;
    const manager = [
      {
        email: managerEmail,
        jobTitle: jobTitle,
        role: Roles.MANAGER,
      },
    ];
    const memberList = manager.concat(members);
    const teamData = {
      teamName: teamName,
      members: memberList,
      requestType: REQUEST_TYPE.NEW_TEAM,
    };

    try {
      await sendTeamsRequest(teamData, SECTIONS.ADMIN_TEAMS_LIST_TABLE);
      await queryClient.refetchQueries({ queryKey: [GET_ALL_TEAMS_FOR_LEADERS] });
      // Close the modal only if the request succeeds
      toggleVisible();
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();
  const handleBilling = async () => {
    await navigate(`/settings?view=billing`);
  };
  // subscription info calculation
  const totalMembers = subscriptionData?.totalLicenses as number;
  const activeMembers = subscriptionData?.activeLicenses as number;
  const newTotalMembers = activeMembers + fields.length + 1;
  const totalAvailableLicenses =
    subscriptionData?.status === 'trialing'
      ? Math.min(TRAIL_LIMIT, totalMembers as number)
      : totalMembers;
  const isLicenseAvailable = newTotalMembers <= totalAvailableLicenses;
  const notAvailable = useHasAccess([USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]) && !isLicenseAvailable;

  const eventHandler = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      await debouncedTeamValidate(event.target.value);
    },
    [debouncedTeamValidate]
  );
  const TeamexistValidationCheck = async (value: any) => {
    try {
      if (value.trim().length !== 0) {
        setIsTeamNameCheckInProgress(true);
        const response = await queryClient.fetchQuery({
          queryFn: async () => {
            return await checkTeamExist(value && value.trim());
          },
          queryKey: ['team_exists', value],
          staleTime: 60000,
        });
        if (response.teamNameExistsAlready) {
          setError('teamAlreadyExist', {
            type: 'manual',
            message: teamAlreadyExists,
          });
          setIsTeamNameCheckInProgress(false);
          return true;
        } else {
          setIsTeamNameCheckInProgress(false);
          clearErrors('teamAlreadyExist');
          return false;
        }
      } else {
        clearErrors('teamAlreadyExist');
        return true;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="justify-between">
      <form
        name="form"
        id="form"
        className="w-full"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="-mx-3 mb-6 flex flex-wrap">
          <label
            className="mb-2  block px-3 text-xs  font-medium normal-case tracking-wide text-zinc-50"
            htmlFor="team_title"
          >
            {teamNameTitle}
          </label>

          <div className="w-full px-3">
            <label
              className="required mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-500"
              htmlFor="team_name"
            >
              {teamNameLabel}
            </label>
            <div className="relative">
              <TextInput
                className="block h-9 w-full appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900 py-3 px-4 leading-tight text-zinc-50 placeholder:text-xs focus:border-gray-500  focus:outline-none"
                id="team_name"
                type="text"
                placeholder={teamNamePlaceHolder}
                aria-invalid={fieldHasError(errors, 'teamName') ? 'true' : 'false'}
                hasError={fieldHasError(errors, 'teamName')}
                {...register('teamName', {
                  validate: (value) => {
                    const trimmedValue = value.trim();

                    if (trimmedValue.length == 0) {
                      return teamNameRequired;
                    }

                    if (trimmedValue.length < teamNameMinValue) {
                      return teamNameMinError;
                    }

                    if (trimmedValue.length > teamNameMaxValue) {
                      return teamNameMaxError;
                    }
                  },
                  onChange: eventHandler,
                })}
              />
              {isTeamNameCheckInProgress ? (
                <div className="absolute top-2 right-2">
                  <BasicSpinner className="leading-[14px] text-zinc-100" />
                </div>
              ) : null}
            </div>
            <HookFormErrorMessage
              name="teamName"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
            {errors?.teamAlreadyExist &&
              errors.teamAlreadyExist.type === 'manual' &&
              errors.teamAlreadyExist?.message === teamNameAlreadyExist && (
                <span className="text-xs font-normal text-red-500">{teamNameAlreadyExist}</span>
              )}
          </div>
        </div>
        <div className="-mx-3 mb-6 flex flex-wrap">
          <div className="w-full px-3 py-5">
            <div className="grow border-t border-zinc-600 pb-7"></div>
            <label
              className="mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-50"
              htmlFor="manager_details"
            >
              {managerDetailsTitle}
            </label>
            <div className="flex w-full gap-2">
              <div className="w-3/5">
                <label
                  className="required mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-500"
                  htmlFor="manager_email"
                >
                  {managerEmailLabel}
                </label>
                <div className="relative">
                  <TextInput
                    className="block h-9 w-full appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900 py-3 px-4 leading-tight text-zinc-50 placeholder:text-xs focus:border-gray-500  focus:outline-none"
                    type={'text'}
                    id="manager_email"
                    placeholder={managerEmailPlaceHolder}
                    aria-invalid={fieldHasError(errors, 'managerEmail') ? 'true' : 'false'}
                    hasError={fieldHasError(errors, 'managerEmail')}
                    {...register('managerEmail', {
                      required: {
                        value: true,
                        message: managerEmailRequired,
                      },
                      pattern: {
                        value: REGEX.CHECK_VALID_MAIL,
                        message: validEmailError,
                      },
                      validate: _managerEmailValidate,
                      maxLength: {
                        value: emailMaxValue,
                        message: emailLimitError,
                      },
                    })}
                  />
                  {isManagerEmailCheckInProgress ? (
                    <div className="absolute top-2 right-2">
                      <BasicSpinner className="leading-[14px] text-zinc-100" />
                    </div>
                  ) : null}
                </div>
                <HookFormErrorMessage
                  name="managerEmail"
                  errors={errors}
                  render={({ message }) => <ErrorMessage message={message} />}
                />
              </div>
              <div className="w-2/5">
                <label
                  className="required mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-500"
                  htmlFor="manager_job_title"
                >
                  {managerJobLabel}
                </label>
                <TextInput
                  className=" block h-9 w-full appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900 py-3 px-4 leading-tight text-zinc-50 placeholder:text-xs focus:border-gray-500  focus:outline-none"
                  type={'text'}
                  id="manager_job_title"
                  placeholder={managerJobTitlePlaceHolder}
                  aria-invalid={fieldHasError(errors, 'jobTitle') ? 'true' : 'false'}
                  hasError={fieldHasError(errors, 'jobTitle')}
                  {...register('jobTitle', {
                    validate: (value) => {
                      const trimmedValue = value.trim();

                      if (trimmedValue.length == 0) {
                        return jobTitleRequired;
                      }

                      if (trimmedValue.length < jobTitleMinValue) {
                        return jobTitleMinError;
                      }

                      if (trimmedValue.length > jobTitleMaxValue) {
                        return jobTitleMaxError;
                      }
                    },
                  })}
                />
                <HookFormErrorMessage
                  name="jobTitle"
                  errors={errors}
                  render={({ message }) => <ErrorMessage message={message} />}
                />
              </div>
            </div>
          </div>

          <div className="w-full px-3 py-5">
            <div className="grow border-t border-zinc-600 pb-7"></div>
            <label
              className="mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-50"
              htmlFor="member_details"
            >
              {memberDetailsTitle}
            </label>
            {/* start here for the useField */}
            {fields.map((field: any, index: number) => {
              return (
                <div key={field.id} className="flex w-full gap-2 py-1">
                  <div className="w-3/5">
                    <label
                      className="required mb-2 block text-xs  font-medium normal-case text-zinc-500"
                      htmlFor="member_email"
                    >
                      {memberEmailLabel}
                    </label>
                    <div className="relative">
                      <TextInput
                        className="block h-9 appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900  py-2 px-4 leading-tight text-zinc-50 placeholder:text-xs focus:border-gray-500  focus:outline-none"
                        type={'text'}
                        id="member_email"
                        placeholder={memberEmailPlaceHolder}
                        aria-invalid={
                          fieldHasError(
                            errors,
                            errors.members?.[index]?.email?.type ? 'members' : ''
                          )
                            ? 'true'
                            : 'false'
                        }
                        hasError={fieldHasError(
                          errors,
                          errors.members?.[index]?.email?.type ? 'members' : ''
                        )}
                        onInput={() => {
                          setErrorIndex(index);
                        }}
                        {...register(`members.${index}.email`, {
                          required: {
                            value: true,
                            message: memberEmailRequired,
                          },
                          pattern: {
                            value: REGEX.CHECK_VALID_MAIL,
                            message: validEmailError,
                          },
                          validate: (value) => {
                            if (value) {
                              return _emailValidate(value, index);
                            }
                          },
                          maxLength: {
                            value: emailMaxValue,
                            message: emailLimitError,
                          },
                        })}
                      />
                      {memberEmailIndexes.has(index) ? (
                        <div className="absolute top-2 right-1">
                          <BasicSpinner className="justify-items-end leading-[10px] text-zinc-100" />
                        </div>
                      ) : null}
                    </div>
                    <HookFormErrorMessage
                      name={`members.${index}.email`}
                      errors={errors}
                      render={({ message }) => <ErrorMessage message={message} />}
                    />
                  </div>
                  <div className="w-2/5">
                    <label
                      className="required mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-500"
                      htmlFor="member_job_title"
                    >
                      {memberJobLabel}
                    </label>
                    <TextInput
                      className="block h-9 appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900 p-2 py-3 px-4 leading-tight text-zinc-50 placeholder:text-xs focus:border-gray-500  focus:outline-none"
                      type={'text'}
                      id="member_job_title"
                      placeholder={memberJobTitlePlaceHolder}
                      aria-invalid={
                        fieldHasError(
                          errors,
                          errors.members?.[index]?.jobTitle?.type ? `members.${index}.jobTitle` : ''
                        )
                          ? 'true'
                          : 'false'
                      }
                      hasError={fieldHasError(
                        errors,
                        errors.members?.[index]?.jobTitle?.type ? 'members' : ''
                      )}
                      {...register(`members.${index}.jobTitle`, {
                        validate: (value) => {
                          const trimmedValue = value.trim();

                          if (trimmedValue.length == 0) {
                            return jobTitleRequired;
                          }

                          if (trimmedValue.length < jobTitleMinValue) {
                            return jobTitleMinError;
                          }

                          if (trimmedValue.length > jobTitleMaxValue) {
                            return jobTitleMaxError;
                          }
                        },
                      })}
                    />
                    <HookFormErrorMessage
                      name={`members.${index}.jobTitle`}
                      errors={errors}
                      render={({ message }) => <ErrorMessage message={message} />}
                    />
                  </div>

                  <button
                    type="button"
                    onClick={() => {
                      {
                        remove(index);
                      }
                    }}
                    className="relative top-[2rem] flex h-9 text-xs text-base-brand"
                  >
                    <X size={16} />
                  </button>
                </div>
              );
            })}
          </div>
          {fields.length < 10 && (
            <div className="flex flex-col">
              <button
                type="button"
                onClick={() => {
                  append({ email: '', jobTitle: '', role: 'User' });
                }}
                className="text-base-brand"
              >
                <div className="flex px-2 text-xs">
                  <Plus size={16} />{' '}
                  {fields.length === 0 ? addNewMemberButton : addAnotherMemberButton}
                </div>
              </button>
              <div className="">
                <div className="mt-2 justify-self-center">
                  <ModalErrorAlert errors={errors} index={errorIndex as number} />
                </div>
              </div>
            </div>
          )}
        </div>
        {notAvailable && subscriptionData && (
          <div className="pb-6">
            <SubscriptionInfo
              existingMemberCount={totalMembers as number}
              newMemberCount={newTotalMembers}
              isOnBoarding={isOnBoarding}
            />
          </div>
        )}
      </form>
      <div className="flex justify-end">
        <Dialog.Close className="px-7 text-zinc-50">{cancelButton}</Dialog.Close>

        {!notAvailable && (
          <>
            <HasAccess
              roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEADER, USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER]}
              Fallback={null}
            >
              <Button
                type="submit"
                onClick={handleSubmit(onRequest)}
                disabled={
                  loading ||
                  isTeamNameCheckInProgress ||
                  isManagerEmailCheckInProgress ||
                  memberEmailIndexes.size != 0 ||
                  isSubmitting
                }
                className="inline-block h-[40px] !rounded bg-base-brand px-6 py-2 text-sm font-medium normal-case leading-tight text-white hover:bg-base-brand disabled:opacity-80"
              >
                {isSubmitting ? (
                  <div className="flex items-center gap-2">
                    {addTeamRequestButton}
                    <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
                  </div>
                ) : (
                  ` ${addTeamRequestButton}`
                )}
              </Button>
            </HasAccess>
            <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]} Fallback={null}>
              <button
                type="submit"
                onClick={handleSubmit(onSubmit)}
                disabled={
                  loading ||
                  isTeamNameCheckInProgress ||
                  isManagerEmailCheckInProgress ||
                  memberEmailIndexes.size != 0 ||
                  isSubmitting
                }
                className="inline-block h-[40px] !rounded bg-base-brand px-6 py-2 text-sm font-medium normal-case leading-tight text-white transition-opacity hover:bg-base-brand disabled:cursor-default disabled:opacity-80"
              >
                {isSubmitting ? (
                  <div className="flex items-center gap-2">
                    {addTeamButton}
                    <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
                  </div>
                ) : (
                  `${addTeamButton}`
                )}
              </button>
            </HasAccess>
          </>
        )}
        {notAvailable && !isOnBoarding && (
          <button
            type="submit"
            onClick={() => handleBilling()}
            className="btn inline-block h-[40px] !rounded bg-base-brand px-6 py-2 text-sm font-medium normal-case leading-tight text-white hover:bg-base-brand"
          >
            {addTeamBillingButton}
          </button>
        )}
      </div>
    </div>
  );
}

export default AddNewTeamModal;
