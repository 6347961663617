import Dialog from '../../../../components/Dialog';
import { useStrapiRequestData } from '../../../requests/hooks/useStrapiRequestData';
import RaiseARequestToHQForm from './RaiseARequestToHQForm';

interface Props {
  setIsModalOpen: (open: boolean) => void;
}

const RaiseARequestToHQModal = ({ setIsModalOpen }: Props) => {
  const { HQRequestModal } = useStrapiRequestData();
  const { title } = HQRequestModal;
  return (
    <div className="mx-1 flex flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
        {title}
      </Dialog.Title>
      <RaiseARequestToHQForm setIsModalOpen={setIsModalOpen} />
    </div>
  );
};

export default RaiseARequestToHQModal;
