import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../../../../../../../../../components/AlertDialog';
import useStrapiMembersData from '../../../../../../../hooks/useStrapiMembersData';

const TrialLimitExceededDialogContent = () => {
  const navigate = useNavigate();
  const { trialLimitOverModal } = useStrapiMembersData();
  const { title, message, cancelButton, actionButton } = trialLimitOverModal;
  const navigateToBillingPage = useCallback(() => {
    // Maybe we can send some state from here
    // To automatically open the modal that could change the enterprise admin
    // subscription to team/enterprise from trial
    navigate('/settings?view=billing');
  }, [navigate]);

  return (
    <AlertDialog
      Title={title}
      Content={message}
      actionItem={{
        label: actionButton,
        action: navigateToBillingPage,
      }}
      cancelItem={{
        label: cancelButton,
      }}
    />
  );
};

export default TrialLimitExceededDialogContent;
