import { Users } from 'phosphor-react';
import { useMemo } from 'react';
import SideBar from '../../../../../components/SideBar';
import { USHG_HQ_APP_ROLE } from '../../../../../constants';
import { useHasAccess } from '../../../../../hooks';
import { useStrapiSideNavData } from '../../../../../hooks/useStrapiSideNavData';
import { CustomSideNavItemProps } from '../../../types';
import AllMembersNavItem from './AllMembersNavItem';

const TeamsNavItem = ({ eventsCount }: React.PropsWithChildren<CustomSideNavItemProps>) => {
  const { routes } = useStrapiSideNavData();

  const isEnterpriseAdmin = useHasAccess([USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]);

  const subItems = useMemo(() => {
    if (isEnterpriseAdmin) {
      return [<AllMembersNavItem key="all_members_nav_item" />];
    } else {
      return [];
    }
  }, [isEnterpriseAdmin]);

  return (
    <SideBar.SideBarNavItem
      icon={<Users aria-label="Teams" size="20px" color="currentColor" className="text-inherit" />}
      label={routes.teamsLabel}
      path="teams"
      eventsCount={eventsCount}
      subItems={subItems}
    ></SideBar.SideBarNavItem>
  );
};

export default TeamsNavItem;
