import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowCounterClockwise } from 'phosphor-react';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Dialog from '../../../../components/Dialog';
import { DropdownInput, Label, TextInput } from '../../../../components/FormElements';
import ErrorMessage from '../../../../components/FormElements/ErrorMessage';
import TextAreaInput from '../../../../components/FormElements/TextAreaInput';
import { BasicSpinner } from '../../../../components/Spinners';
import { fieldHasError } from '../../../../utils/react-hook-form';
import { useRaiseUSHGAdminRequestMutation } from '../../../requests/hooks';
import { useStrapiRequestData } from '../../../requests/hooks/useStrapiRequestData';
import useUSHGAdminRequestTypesQuery from '../../../requests/hooks/useUSHGAdminRequestTypesQuery';
import FormActionButton from './FormActionButton';
import FormFieldWrapper from './FormFieldWrapper';

// Yup Schema
function schema(
  requestCategoryError: string,
  requestDescriptionError: string,
  requestNameError: string,
  nameMinValue: number,
  nameMinError: string,
  nameMaxValue: number,
  nameMaxError: string,
  descriptionMinValue: number,
  descriptionMinError: string,
  descriptionMaxValue: number,
  descriptionMaxError: string
) {
  return yup.object({
    name: yup
      .string()
      .trim()
      .required(requestNameError)
      .min(nameMinValue, nameMinError)
      .max(nameMaxValue, nameMaxError),
    category: yup.string().required(requestCategoryError),
    description: yup
      .string()
      .trim()
      .min(descriptionMinValue, descriptionMinError)
      .max(descriptionMaxValue, descriptionMaxError)
      .required(requestDescriptionError),
  });
}

// Yup Schema

interface Props {
  setIsModalOpen: (open: boolean) => void;
}

const RaiseARequestToHQForm = ({ setIsModalOpen }: Props) => {
  // Create new USHG Admin Request Mutation
  const ushgAdminRequestMutation = useRaiseUSHGAdminRequestMutation();

  // Form initialization

  const { HQRequestModal } = useStrapiRequestData();
  const {
    requestNameLabel,
    requestNamePlaceholder,
    requestDescriptionLabel,
    requestDescriptionPlaceholder,
    requestCategoryLabel,
    requestCategoryPlaceholder,
    cancelButton,
    raiseRequestButton,
    requestCategoryError,
    requestDescriptionError,
    requestNameError,
    nameMinValue,
    nameMinError,
    nameMaxValue,
    nameMaxError,
    descriptionMinValue,
    descriptionMinError,
    descriptionMaxValue,
    descriptionMaxError,
  } = HQRequestModal;
  const ushgAdminRequestFormSchema = schema(
    requestCategoryError,
    requestDescriptionError,
    requestNameError,
    nameMinValue,
    nameMinError,
    nameMaxValue,
    nameMaxError,
    descriptionMinValue,
    descriptionMinError,
    descriptionMaxValue,
    descriptionMaxError
  );
  type USHG_ADMIN_REQUEST_FORM_DATA = yup.InferType<typeof ushgAdminRequestFormSchema>;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<USHG_ADMIN_REQUEST_FORM_DATA>({
    defaultValues: {
      name: '',
      category: '',
      description: '',
    },
    resolver: yupResolver(ushgAdminRequestFormSchema),
  });

  // Fetch all the categories of request that the enterprise admin could raise to USHG Admin
  const requestTypesQuery = useUSHGAdminRequestTypesQuery();

  // Map the categories response to the Option format
  const CATEGORIES_OPTIONS = useMemo(() => {
    if (requestTypesQuery.data && requestTypesQuery.data.length > 0) {
      // map response to the options format
      let options = requestTypesQuery.data.map((d) => {
        return {
          label: d.name,
          value: d.typeId?.toString(),
        };
      });

      // Select label
      options = [
        ...options,
        {
          label: requestCategoryPlaceholder,
          value: '',
        },
      ];

      return options;
    }

    return [];
  }, [requestCategoryPlaceholder, requestTypesQuery.data]);

  const handleFormSubmit = (data: USHG_ADMIN_REQUEST_FORM_DATA) => {
    const params = {
      title: data.name,
      description: data.description,
      typeId: parseInt(data.category),
    };
    // Create new request
    ushgAdminRequestMutation.mutate(params, {
      onSuccess: () => {
        // Close modal if the mutation is successful
        setIsModalOpen(false);
      },
    });
    return;
  };
  const { errorMessages } = useStrapiRequestData();

  return (
    <form
      className="flex flex-col gap-6"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(handleFormSubmit)(e);
      }}
    >
      {/* Request Name */}
      <FormFieldWrapper>
        <Label htmlFor="hq_request_name" label={requestNameLabel}></Label>
        <TextInput
          id="hq_request_name"
          placeholder={requestNamePlaceholder}
          type="text"
          className="border border-zinc-700 bg-zinc-900"
          hasError={fieldHasError(errors, 'name')}
          {...register('name')}
        />
        <HookFormErrorMessage
          name="name"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </FormFieldWrapper>

      {/* Categories Input */}
      <FormFieldWrapper>
        <Label htmlFor="hq_request_category" label={requestCategoryLabel}></Label>
        {/* Categories are loading */}
        {(requestTypesQuery.isLoading || requestTypesQuery.isRefetching) &&
          !requestTypesQuery.data && (
            <div className="flex items-center justify-center gap-2 py-2">
              <BasicSpinner className="text-zinc-100" />
            </div>
          )}
        {/* Categories are loaded */}
        {requestTypesQuery.data && requestTypesQuery.data.length > 0 && (
          <DropdownInput
            type="select"
            id="hq_request_category"
            className="gap-2 rounded border !border-zinc-700 bg-zinc-900 !p-2 invalid:text-zinc-500"
            options={CATEGORIES_OPTIONS}
            hasError={fieldHasError(errors, 'category')}
            {...register('category')}
          />
        )}
        {/* Display when there is no data, there is an error and we are not refetching */}
        {requestTypesQuery.isError &&
          !requestTypesQuery.data &&
          !requestTypesQuery.isRefetching && (
            <div className="flex items-center gap-2 py-2">
              <span className="text-sm text-error">
                {errorMessages.errorLoadingCategoriesMessage}
              </span>
              <ArrowCounterClockwise
                size={12}
                color="currentColor"
                className="cursor-pointer text-zinc-100"
                onClick={() => {
                  // Refetch the categories
                  requestTypesQuery.refetch();
                }}
              />
            </div>
          )}
        <HookFormErrorMessage
          name="category"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </FormFieldWrapper>

      {/* Description Input */}
      <FormFieldWrapper>
        <Label htmlFor="hq_request_description" label={requestDescriptionLabel}></Label>
        <TextAreaInput
          id="hq_request_description"
          placeholder={requestDescriptionPlaceholder}
          className="border border-zinc-700 bg-zinc-900"
          rows={6}
          hasError={fieldHasError(errors, 'description')}
          {...register('description')}
        ></TextAreaInput>
        <HookFormErrorMessage
          name="description"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </FormFieldWrapper>

      {/* Action Buttons */}
      <div className="flex flex-col items-center justify-end gap-2 xs:flex-row">
        <Dialog.Close
          type="button"
          className="rounded bg-transparent py-2.5 px-[30px] text-center text-sm font-medium text-white"
        >
          {cancelButton}
        </Dialog.Close>
        <FormActionButton
          type="submit"
          className="bg-base-brand disabled:opacity-80"
          disabled={
            // When the mutation is in progress
            ushgAdminRequestMutation.isLoading ||
            // When the categories are not loaded disabled the button
            requestTypesQuery.isLoading
          }
        >
          {ushgAdminRequestMutation.isLoading ? (
            <div className="flex gap-2">
              <span>{raiseRequestButton}</span>
              <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
            </div>
          ) : (
            raiseRequestButton
          )}
        </FormActionButton>
      </div>
    </form>
  );
};

export default RaiseARequestToHQForm;
