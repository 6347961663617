import { RouteObject } from 'react-router-dom';
import { lazy } from 'react';

const NotificationsPage = lazy(() => import('../components'));

const routes: RouteObject[] = [
  {
    path: '/notifications',
    element: <NotificationsPage />,
  },
];

export default routes;
