import React, { useEffect, useState } from 'react';
import Dialog from '../../../../../components/Dialog';
import { useStrapiHQAdminCoursesData } from '../../../hooks/useStrapiHQAdminCourseData';
import AssessmentModal from './AssessmentModal';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../constants/user';
import { useAssessmentBasicDetailsQuery } from '../../../hooks';
import { COURSES_ERROR_CODE } from '../../../constants/courses-management';

interface AssessmentBasicDetailsProps {
  assessmentBasicDetailsQuery: Record<string, any>;
}

const AssessmentBasicDetails = ({ assessmentBasicDetailsQuery }: AssessmentBasicDetailsProps) => {
  const [isEditAssessmentModalOpen, setIsEditAssessmentModalOpen] = useState(false);
  const [isManageAssessmentModalOpen, setIsManageAssessmentModalOpen] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('');

  const { singleAssessmentView, assessmentModal } = useStrapiHQAdminCoursesData();
  const {
    editAssessmentTitle,
    editAssessmentButton: editAssessmentModalButton,
    addAssessmentButton,
    manageLanguagesTitle,
  } = assessmentModal;
  const { title, descriptionLabel, passPercentageLabel, editAssessmentButton, nameLabel } =
    singleAssessmentView.BasicDetails;

  const {
    language: { StrapiLanguageOptions },
  } = useStrapiSharedData();

  // Map `label` to `display` for Select component with null check
  const mappedOptions =
    StrapiLanguageOptions?.map((option: { label: string; value: string }) => ({
      display: option.label,
      value: option.value,
    })) || [];

  // Find the next language to English
  const nextLanguage = mappedOptions.find(
    (option: { value: string }) => option.value !== DEFAULT_PREFERRED_LANGUAGE
  );

  const [selectedLanguage, setSelectedLanguage] = useState(nextLanguage ? nextLanguage.value : '');

  const handleLanguageChange = (newLanguage: string) => {
    setSelectedLanguage(newLanguage);
  };

  const assessmentDetails = useAssessmentBasicDetailsQuery({
    assessmentId: assessmentBasicDetailsQuery.data.id,
    language: selectedLanguage,
    isEnabled: isManageAssessmentModalOpen,
  });

  useEffect(() => {
    if (assessmentDetails.data || assessmentDetails.errorType) {
      if (
        !assessmentDetails.data &&
        assessmentDetails.errorType === COURSES_ERROR_CODE.ASSESSMENT_ID_NOT_FOUND
      ) {
        setButtonLabel(addAssessmentButton);
      } else {
        setButtonLabel(editAssessmentModalButton);
      }
    }
  }, [
    assessmentDetails.data,
    assessmentDetails.errorType,
    addAssessmentButton,
    editAssessmentButton,
  ]);

  return (
    <div className="w-full rounded-sm bg-card-bg p-4">
      {/* Header Starts  */}
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col items-start justify-between gap-4 xs:flex-row xs:items-center">
          <p className="font-sans text-base font-medium text-white">{title}</p>
          <div className="flex gap-4">
            <Dialog
              open={isManageAssessmentModalOpen}
              onOpenChange={(open) => setIsManageAssessmentModalOpen(open)}
              triggerClassName="flex justify-start xs:justify-end basis-0 grow-0"
              onInteractOutside={(e) => {
                // Donot close the Modal when we click outside the Dialog Content
                e.preventDefault();
              }}
              Content={
                <AssessmentModal
                  setIsAssessmentModalOpen={setIsManageAssessmentModalOpen}
                  modalTitle={manageLanguagesTitle}
                  assessmentBasicDetails={
                    assessmentDetails.errorType ? assessmentBasicDetailsQuery : assessmentDetails
                  }
                  formActionButtonLabel={buttonLabel}
                  modalType="manage"
                  refetch={assessmentDetails.refetch}
                  showLanguageDropdown={true}
                  mappedOptions={mappedOptions}
                  selectedLanguage={selectedLanguage}
                  handleLanguageChange={handleLanguageChange}
                  errorType={assessmentDetails.errorType}
                  setErrorType={assessmentDetails.setErrorType}
                />
              }
            >
              <span className="whitespace-nowrap rounded bg-zinc-900 py-3 px-4 text-xs font-normal text-zinc-200">
                {manageLanguagesTitle}
              </span>
            </Dialog>
            {assessmentBasicDetailsQuery.data && (
              <Dialog
                open={isEditAssessmentModalOpen}
                onOpenChange={(open) => setIsEditAssessmentModalOpen(open)}
                triggerClassName="flex justify-start xs:justify-end basis-0 grow-0"
                onInteractOutside={(e) => {
                  // Donot close the Modal when we click outside the Dialog Content
                  e.preventDefault();
                }}
                Content={
                  <AssessmentModal
                    setIsAssessmentModalOpen={setIsEditAssessmentModalOpen}
                    modalTitle={editAssessmentTitle}
                    assessmentBasicDetails={assessmentBasicDetailsQuery}
                    formActionButtonLabel={editAssessmentModalButton}
                    modalType="update"
                    refetch={assessmentBasicDetailsQuery.refetch}
                  />
                }
              >
                <span className="whitespace-nowrap rounded bg-zinc-900 py-3 px-4 text-xs font-normal text-zinc-200">
                  {editAssessmentButton}
                </span>
              </Dialog>
            )}
          </div>
        </div>
        {/* Header Ends  */}

        {/* Assessment Details Loaded */}
        {assessmentBasicDetailsQuery.data && (
          <>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-medium text-zinc-400">{nameLabel}</p>
                <p className="text-sm font-medium text-zinc-50">
                  {assessmentBasicDetailsQuery.data?.name}
                </p>
              </div>
              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-medium text-zinc-400">{passPercentageLabel}</p>
                <p className="text-sm font-medium text-zinc-50">
                  {assessmentBasicDetailsQuery.data?.passingPercent * 100}%
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-y-1">
              <p className="text-sm font-medium text-zinc-400">{descriptionLabel}</p>
              <p className="text-sm font-medium text-zinc-50">
                {assessmentBasicDetailsQuery.data?.description}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AssessmentBasicDetails;
