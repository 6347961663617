import { RouteObject } from 'react-router-dom';
import { UserDashboard } from '../components';

const routes: RouteObject[] = [
  {
    path: '/dashboard',
    element: <UserDashboard />,
  },
];

export default routes;
