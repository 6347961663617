const ONBOARDING_API_PATHS = {
  GOALS: {
    GET_ALL_GOALS: '/users/goals/selection',
    CREATE_GOALS: '/users/create-goals',
  },
  CATEGORIES: {
    GET_ALL_CATEGORIES: '/courses/categories/selection',
    CREATE_CATEGORIES: '/courses/create-categories',
    GET_GOAL_CATEGORY: '/courses/goal-category',
  },
  PROFILE: {
    GET_USER_PROFILE: '/users/profile',
    UPDATE_USER_PROFILE: '/users/profile',
  },
  INVITE_TEAM: {
    INVITE_TEAM: '/users/enterprise/invite',
  },
} as const;

export { ONBOARDING_API_PATHS };
