import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NoDataDisplay } from '../../../components/DataTables';
import Dialog from '../../../components/Dialog';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
import { useStrapiSharedData } from '../../shared/hooks/useStrapiSharedData';
import AddTeamMain from './Modal/AddTeamModal/AddTeamMain';
import { NO_TEAMS_AVAILABLE_TITLE } from '../constants/enterpriseAdmin';

interface Props {
  isFilterApplied: boolean;
  className?: string;
}

const TeamsEmptyState = ({ isFilterApplied, className }: Props) => {
  const navigate = useNavigate();
  const { clearAllFiltersButton } = useStrapiSharedData().tableProps;
  const clearFilters = () => {
    // This will clear all search params
    navigate('.');
  };

  const [isAddTeamModalOpen, setIsAddTeamModalOpen] = useState(false);
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  return (
    <>
      {isFilterApplied ? (
        <NoDataDisplay
          className={className}
          message={
            <div className="flex w-full flex-col items-center gap-2">
              <span>{strapiErrorMessage['NO_FILTERED_DATA']}</span>

              <button
                className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
                onClick={() => clearFilters()}
              >
                {clearAllFiltersButton}
              </button>
            </div>
          }
        />
      ) : (
        <div className="flex h-[40rem]">
          <div className="m-auto flex flex-col gap-4 text-center">
            <h3 className="text-white">{strapiErrorMessage[NO_TEAMS_AVAILABLE_TITLE]}</h3>
            <button
              className="m-auto inline-block h-[30px] max-w-[200px] !rounded bg-base-brand px-5 text-xs font-medium leading-tight  text-white hover:bg-base-brand md:max-w-none md:py-1"
              type="button"
              id="button-addon3"
            >
              <Dialog
                open={isAddTeamModalOpen}
                onOpenChange={(open) => setIsAddTeamModalOpen(open)}
                Content={
                  <AddTeamMain
                    setIsModalOpen={setIsAddTeamModalOpen}
                    isLoading={false}
                    isOnBoarding={false}
                  />
                }
                onInteractOutside={(e) => {
                  // Donot close the Modal when we click outside the Dialog Content
                  e.preventDefault();
                }}
                containerClassName="max-w-2xl"
              >
                + Add Team
              </Dialog>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TeamsEmptyState;
