import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SECTIONS } from '../../../constants/section-name';

type SectionDataRefreshPayload = {
  [key in SECTIONS]?: boolean;
};
interface BadgesSliceState {
  sortKey: string;
  sortOrder: string | boolean;
  badgeRefreshState: SectionDataRefreshPayload;
}

const initialState: BadgesSliceState = {
  sortKey: '',
  sortOrder: 'ASC',
  badgeRefreshState: {},
};

const badgesSlice = createSlice({
  name: 'badges',
  initialState,
  reducers: {
    setSortKey: (state, action: PayloadAction<string>) => {
      state.sortKey = action.payload;
    },
    setSortOrder: (state, action: PayloadAction<any>) => {
      state.sortOrder = action.payload;
    },
    triggerBadgesRefresh: (state, action: PayloadAction<SectionDataRefreshPayload>) => {
      state.badgeRefreshState = {
        ...state.badgeRefreshState,
        ...action.payload,
      };
    },
  },
});

export const { setSortKey, triggerBadgesRefresh, setSortOrder } = badgesSlice.actions;

export default badgesSlice.reducer;
