import { CompanyDetails } from './CompanyDetails';
import { BasicDetails } from './BasicDetails';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
import { ROLES } from '../constants';

const Registration = () => {
  const data: any = useStrapiDataHelper();
  let basicDetails, companyDetails, ssoBasicDetails, previewSubscriptionData;
  const formdata = data?.form.data.attributes.FormData;
  for (let i = 0; i <= formdata?.length; i++) {
    if (formdata[i]?.slug === 'basicDetails') {
      basicDetails = (formdata ?? [])[i];
    } else if (formdata[i]?.slug === 'companyDetails') {
      companyDetails = (formdata ?? [])[i];
    } else if (formdata[i]?.slug === 'SSOBasicDetails') {
      ssoBasicDetails = (formdata ?? [])[i];
    } else if (formdata[i]?.__typename === 'ComponentPageFormSubscriptionDetails') {
      previewSubscriptionData = (formdata ?? [])[i];
    }
  }
  const nextStepLabel = basicDetails?.FormFooter[0].FormFooter;
  const completeSubscriptionLabel = basicDetails?.FormFooter[1].FormFooter;

  // All the contents here are pulled from Strapi. Fallback content is also added.
  const form = [
    {
      type: 'form',
      title: (basicDetails ?? [])?.FormHeading || 'Basic Details',
      content: basicDetails.Label || BasicDetails,
      slug: basicDetails?.slug || 'basicDetails',
      role: [ROLES.INDIVIDUAL, ROLES.TEAM, ROLES.ENTERPRISE],
    },
    {
      type: 'form',
      title: (formdata ?? [])[1]?.FormHeading || 'Company Details',
      content: companyDetails.Label || CompanyDetails,
      slug: companyDetails?.slug || 'companyDetails',
      role: [ROLES.TEAM, ROLES.ENTERPRISE],
    },
    {
      type: 'component',
      title: previewSubscriptionData?.formHeading || 'Subscription Details',
      content: previewSubscriptionData,
      slug: 'subscriptionDetails',
      role: [ROLES.INDIVIDUAL, ROLES.TEAM, ROLES.ENTERPRISE],
    },
    {
      type: 'form',
      title: (ssoBasicDetails ?? [])?.FormHeading || 'Basic Details',
      content: ssoBasicDetails.Label || BasicDetails,
      slug: ssoBasicDetails?.slug || 'SSOBasicDetails',
      role: [ROLES.INDIVIDUAL_SSO],
    },
  ];
  return { form, basicDetails, companyDetails, nextStepLabel, completeSubscriptionLabel };
};
export default Registration;
