import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import ChangePassword from './ChangePassword';
import MFA from './MFA/MFA';
import { useHasAccess } from '../../../../../hooks';
import { USHG_HQ_APP_ROLE } from '../../../../../constants';

type Props = {
  className?: string;
};

const SecurityTab = ({ className }: Props) => {
  // Would change for individual users when we implement SSO might need to add more logic here
  const userCanChangePassword = useHasAccess([
    USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
    USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
    USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
    USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER,
    USHG_HQ_APP_ROLE.INDIVIDUAL_USER,
  ]);

  return (
    <section className={twMerge(clsx('flex flex-col items-center gap-6', className))}>
      <MFA className="max-w-2xl" />
      {userCanChangePassword && <ChangePassword className="max-w-2xl" />}
    </section>
  );
};

export default SecurityTab;
