import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

export const useStrapiCoursesData = () => {
  const data: any = useAppSelector(strapiDataSelector);
  const strapiCourseCommon = data.course.data.attributes.COMMON;
  let noCourses;
  let singleModule;
  let assignCourseModal;
  let allCourses;
  let singleCourse;
  for (let i = 0; i <= strapiCourseCommon?.length; i++) {
    if (strapiCourseCommon[i]?.__typename === 'ComponentCoursesNoCourses') {
      noCourses = (strapiCourseCommon ?? [])[i];
    } else if (strapiCourseCommon[i]?.__typename === 'ComponentCoursesSingleModule') {
      singleModule = (strapiCourseCommon ?? [])[i];
    } else if (strapiCourseCommon[i]?.__typename === 'ComponentCoursesAssignCourseModal') {
      assignCourseModal = (strapiCourseCommon ?? [])[i];
    } else if (strapiCourseCommon[i]?.__typename === 'ComponentCoursesAllCourses') {
      allCourses = (strapiCourseCommon ?? [])[i];
    } else if (strapiCourseCommon[i]?.__typename === 'ComponentCoursesSingleCourse') {
      singleCourse = (strapiCourseCommon ?? [])[i];
    }
  }
  const {
    common: singleModuleCommon,
    moduleSpeaker,
    singleAssessment,
    assessmentCompletion,
    singleSpeaker,
    courseCompleted,
  } = singleModule;
  const {
    speaker,
    courseInfo,
    topNav,
    moduleDetails,
    courseDetails,
    singleCourseCompleted,
    singleCourseTabs,
  } = singleCourse;
  return {
    speaker,
    singleCourseCompleted,
    courseInfo,
    topNav,
    moduleDetails,
    courseDetails,
    noCourses,
    allCourses,
    moduleSpeaker,
    singleAssessment,
    assessmentCompletion,
    singleSpeaker,
    courseCompleted,
    singleModuleCommon,
    assignCourseModal,
    singleCourseTabs,
  };
};
