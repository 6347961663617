import { gql } from '@apollo/client';

export const RequestFragment = gql`
  fragment RequestFragment on RequestEntityResponse {
    data {
      attributes {
        COMMON {
          ... on ComponentRequestHqRequestTable {
            HQRequestTable {
              statusColumnHeader
              requestColumnHeader
              raisedOnColumnHeader
              raisedByColumnHeader
              organisationColumnHeader
              categoryColumnHeader
              emailColumnHeader
            }
            HQTableSortOptions {
              sortLabel
              oldestSortOption
              mostRecentSortOption
            }
            HQTableStatusOptions {
              completedFilterOption
              clearAllFiltersButton
              statusPlaceHolder
              openFilterOption
            }
            HQRequestModal {
              raisedByLabel
              resolveButton
              raisedOrgLabel
              requestTitleLabel
              requestCategoryLabel
              title
              requestDescriptionLabel
              statusLabel
            }
          }
          ... on ComponentRequestToastMessages {
            rejectSuccessTitle
            approveSuccessTitle
            approveFailedTitle
            rejectFailureTitle
            requestProcessingTitle
            requestProccessingFailureTitle
            HQRequestSentFailureTitle
            HQRequestSentSuccessTitle
            HQRequestSentSuccessMessage
            HQRequestSentFailureMEssage
            requestNotFoundError
            requestAlreadyHandledError
            HQRequestResolvedFailedTitle
            HQRequestResolvedTitle
          }
          ... on ComponentRequestErrorMessages {
            emptyResultsMessage
            noRequestsAvailableMessage
            invalidTeamInfoMessage
            errorLoadingCategoriesMessage
          }
          ... on ComponentRequestRequestTable {
            HQRequestModal {
              title
              requestNameLabel
              requestNamePlaceholder
              requestNameError
              requestDescriptionLabel
              requestDescriptionPlaceholder
              requestDescriptionError
              requestCategoryLabel
              requestCategoryPlaceholder
              requestCategoryError
              cancelButton
              raiseRequestButton
              nameMinValue
              nameMinError
              nameMaxValue
              nameMaxError
              descriptionMinValue
              descriptionMinError
              descriptionMaxValue
              descriptionMaxError
            }
            tableHeader {
              raisedOnColumnHeader
              raisedByColumnHeader
              requestsColumnHeader
              emailColumnHeader
              statusColumnHeader
            }
            addTeamRequestModal {
              teamNameLabel
              acceptButton
            }
            addRequestSubTeamModal {
              teamNameLabel
              acceptButton
              mainTeamLabel
            }
            addMemberRequestModal {
              acceptButton
              emailLabel
              roleLabel
              teamToAddInLabel
              statusLabel
              emailToAddLabel
            }
            commonModal {
              raisedByLabel
              raisedTimeLabel
              requestTypeLabel
              managerEmailLabel
              memberEmailLabel
              requestDetailsTitle
              acceptButton
              rejectRequestButton
            }
            editTeamRequestModal {
              proposedTeamNameLabel
              currentTeamNameLabel
              acceptButton
            }
            tableSortOptions {
              sortLabel
              oldestSortOption
              mostRecentSortOption
            }
            tableFilterOptions {
              completedFilterOption
              clearAllFiltersButton
              statusPlaceHolder
              openFilterOption
              rejectedFilterOption
            }
            rejectRequestModal {
              title
              rejectReasonLabel
              backButton
              rejectRequestButton
              rejectingRequestLabel
              rejectReasonPlaceholder
              rejectReasonError
              rejectReasonMaxError
              rejectReasonMaxValue
            }
            topNav {
              requestHeader
              addRequestToHQButton
            }
            dashBoardTableProps {
              title
              viewAllButton
            }
            requestType {
              addSubTeam
              addMember
              addTeam
              editTeam
            }
          }
        }
      }
    }
  }
`;
