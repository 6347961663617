import React from 'react';
import { Button } from '../../../../../../components/Buttons';
import WelcomeIllustration from '../../../OnboardingIllustration/WelcomeIllustration';
import { ArrowRight } from 'phosphor-react';
import { useStrapiOnBoardingData } from '../../../../hooks/useStrapiOnBoardingData';
interface Props {
  currentStep: string;
  setCurrentStep: any;
  steps: string[];
}

const Welcome = ({ currentStep, setCurrentStep, steps }: Props) => {
  const stepperNextControl = () => {
    let currIndex = steps.indexOf(currentStep);

    const newStep = ++currIndex;

    if (newStep <= steps.length - 1) {
      setCurrentStep(steps[newStep]);
    }
  };
  const { welcomePage } = useStrapiOnBoardingData();
  const { welcomePageTitle, welcomePageDescription, welcomePageGetStartedButton } = welcomePage;
  return (
    <div className="flex flex-col gap-1">
      <h1 className="text-lg font-semibold text-zinc-50 lg:text-2xl">{welcomePageTitle}</h1>
      <p className="text-sm text-zinc-400">{welcomePageDescription}</p>
      <div className="my-8">
        <WelcomeIllustration></WelcomeIllustration>
      </div>
      <Button
        onClick={() => stepperNextControl()}
        className="flex items-center justify-center gap-x-2 bg-base-brand text-sm font-semibold text-white"
      >
        {welcomePageGetStartedButton} <ArrowRight weight="bold" />
      </Button>
    </div>
  );
};
export default Welcome;
