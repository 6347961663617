import Calendar from '../Calendar';
import clsx from 'clsx';
import { RecommendedCourses, UserGreeting } from '../common';
import { HoursLoggedInViz } from '../../../shared/components/charts';
import { AssignedCourses } from '../Courses';
import CourseCardWrapper from '../Courses/CourseCardWrapper/CourseCardWrapper';
import { useStrapiReportsData } from '../../../shared/hooks/useStrapiReportData';
import MyBadgesDashBoardComponent from '../common/MyBadgesDashBoardComponent';
import { USHG_HQ_APP_ROLE } from '../../../../constants';

interface Props {
  className?: string;
  lazyLoad?: boolean;
}

const IndividualLearnerDashboard = ({ className, lazyLoad = true }: Props) => {
  const { emptyChart, errorChart, title, key1, key2, emptyChartIcon, xAxisLabel, yAxisLabel } =
    useStrapiReportsData().MyViewingActivity;
  const { ReportTooltipContent } = useStrapiReportsData();
  return (
    <main
      id="main-content"
      className={clsx(
        'relative mb-4 grid grow auto-rows-min grid-cols-8 gap-x-3 gap-y-2 sm:gap-y-3 lg:gap-x-[13px] lg:gap-y-4 xl:gap-y-5',
        className
      )}
    >
      <UserGreeting className="col-span-8 col-start-1 row-start-1 lg:col-span-6" />
      <MyBadgesDashBoardComponent
        className="col-span-8 col-start-1 row-start-2 lg:col-span-6 lg:row-span-2"
        lazyLoad={lazyLoad}
      />
      <CourseCardWrapper
        className="col-span-8 col-start-1 row-start-3 overflow-hidden md:row-start-3 lg:col-span-6 lg:row-span-2 lg:row-start-4"
        lazyLoad={lazyLoad}
      />
      <Calendar className="col-span-8 col-start-1 row-start-4 md:row-start-4 lg:col-span-2 lg:col-start-7 lg:row-span-4 lg:row-start-1" />
      <HoursLoggedInViz
        lazyLoad={lazyLoad}
        key1={key1}
        key2={key2}
        xAxisLabel={xAxisLabel}
        yAxisLabel={yAxisLabel}
        emptyMessage={emptyChart}
        errorMessage={errorChart}
        emptyChartIconUrl={emptyChartIcon.data.attributes.url}
        heading={title}
        className="col-span-8 col-start-1 row-start-5 overflow-hidden lg:row-start-6"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.INDIVIDUAL_USER}
      />
      <AssignedCourses className="col-span-8 row-start-7 overflow-hidden" lazyLoad={lazyLoad} />
      <RecommendedCourses
        className="col-span-8 row-start-[8] overflow-hidden lg:row-start-[8]"
        lazyLoad={lazyLoad}
      />
    </main>
  );
};

export default IndividualLearnerDashboard;
