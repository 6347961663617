import React from 'react';
import './Styles/TextInputNumberStyle.css';
import { twMerge } from 'tailwind-merge';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  className?: string;
  type: 'email' | 'text' | 'password' | 'number' | 'range' | 'date';
  hasError?: boolean;
  placeholder?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
};

const TextInput = React.forwardRef(
  (
    { className, hasError, placeholder, onKeyDown, ...otherProps }: Props,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
      e.stopPropagation();
      onKeyDown && onKeyDown(e);
    };

    return (
      <input
        className={twMerge(
          `w-full grow rounded-[4px] border p-2 text-white placeholder:text-sm placeholder:font-normal placeholder:text-zinc-500
          ${className}
          ${
            hasError
              ? '!border-error-border bg-error-bg invalid:border-error-border focus:!border-error-border focus:outline-none focus:invalid:border-error-border'
              : 'border-zinc-700 bg-zinc-900 focus-within:bg-zinc-800 focus:bg-zinc-800'
          }`
        )}
        // default a text but could be overrided to password or other types
        ref={ref}
        {...otherProps}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
      ></input>
    );
  }
);

TextInput.displayName = 'CustomTextInput';

export default TextInput;
export type { Props as TextInputProps };
