import { PropsWithChildren } from 'react';
import cn from '../../../../../utils/cn';

interface FormFieldWrapperProps extends PropsWithChildren {
  className?: string;
}

const FormFieldWrapper = ({ children, className }: FormFieldWrapperProps) => {
  return <div className={cn('flex w-full flex-col gap-1', className)}>{children}</div>;
};

export default FormFieldWrapper;
