const FILE_EXTENSION = {
  CSV: 'csv',
  HTML: 'html',
} as const;

const MIME_TYPE = {
  [FILE_EXTENSION.CSV]: 'text/csv',
  [FILE_EXTENSION.HTML]: 'text/html',
} as const;

// Convert size to their bytes equivalent
// MegaBytes, KiloBytes and Bytes
const SIZE_IN_BYTES = {
  MB: 1024 * 1024,
  KB: 1024,
  B: 1,
};

export { MIME_TYPE, FILE_EXTENSION, SIZE_IN_BYTES };
