import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { createAssessment } from '../api';
import { AssessmentArgs } from '../types';
import { useStrapiHQAdminCoursesData } from './useStrapiHQAdminCourseData';

const useAddAssessmentMutation = () => {
  const {
    toastMessages: {
      createAssessmentSuccessTitle,
      createAssessmentSuccessMessage,
      createAssessmentFailureTitle,
      createAssessmentFailureMessage,
    },
  } = useStrapiHQAdminCoursesData();
  const mutation = useMutation({
    mutationFn: async (args: AssessmentArgs) => {
      const response = await createAssessment(args);
      return response;
    },

    onSuccess: () => {
      toast.custom((t) => (
        <Toast
          variant="success"
          Title={createAssessmentSuccessTitle}
          SubTitle={createAssessmentSuccessMessage}
          toastInstance={t}
        />
      ));
    },
    onError: (error) => {
      console.error('Error while creating an assessment %o', error);
      toast.custom((t) => (
        <Toast
          variant="error"
          Title={createAssessmentFailureTitle}
          SubTitle={createAssessmentFailureMessage}
          toastInstance={t}
        />
      ));
    },
  });

  return mutation;
};

export default useAddAssessmentMutation;
