import { useQuery } from '@tanstack/react-query';
import { MODULE_KEYS } from '../../course-management/constants';
import { getCourseModuleSpeakers } from '../api/courses';

type ModuleDetails = {
  id: number | null;
  name: string | null;
  type: string | null;
  mediaUrl: string | null;
  pictureUrl: string | null;
  status: string | null;
};

const useCourseModuleSpeakersQuery = (module: ModuleDetails) => {
  const STALE_TIME = 60 * 60 * 1000; // 1hr
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery({
      queryKey: [
        { scope: 'course-modules', item: 'speakers', moduleId: module.id, moduleType: module.type },
      ],
      queryFn: ({ queryKey }) => {
        const { moduleId, moduleType } = queryKey[0];
        if (moduleId && moduleType !== MODULE_KEYS.ASSESSMENT)
          return getCourseModuleSpeakers(moduleId.toString());
        else return null;
      },
      enabled: module.id != null && module.type !== MODULE_KEYS.ASSESSMENT,
      staleTime: STALE_TIME,
    });

  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch };
};

export default useCourseModuleSpeakersQuery;
