import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorDisplay } from '../../../components/DataTables';
import { BasicSpinner } from '../../../components/Spinners';
import { ERROR_TYPES } from '../../../constants/error-types.constants';
import { LIST_INDIVIDUALS_DEFAULT_PAGE, LIST_INDIVIDUALS_MIN_PAGE_SIZE } from '../constants/common';
import useGetIndividualListQuery from '../hooks/useGetSpeakerListQuery';
import {
  ASC,
  DESC,
  EMAIL,
  GetIndividualFilterQuery,
  NAME,
  PAYMENT_MODE,
} from '../types/GetIndividualFilterQuery';
import ListIndividualTable from './Table/components/ListIndividualTable';
import TableEmptyState from './Table/components/TableEmptyState';
import ListIndividualTableFooter from './Table/ListIndividualTableFooter';
import ListIndividualTableHeader from './Table/ListIndividualTableHeader';
import { useStrapiSharedData } from '../../shared/hooks/useStrapiSharedData';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
interface Props {
  className?: string;
}

const ListIndividualTableMain = ({ className }: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const q = searchParams.get('q') ?? undefined;
  const status = searchParams.get('status') ?? 'All';
  const isFilterApplied = q ? true : false;
  const sortOrder = searchParams.get('order') ?? undefined;
  const sortKey = searchParams.get('key') ?? undefined;
  const [page, setPage] = useState(LIST_INDIVIDUALS_DEFAULT_PAGE);
  const [size, setPageSize] = useState(LIST_INDIVIDUALS_MIN_PAGE_SIZE);
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const filters = useMemo(() => {
    const query: GetIndividualFilterQuery = {
      search: q as string,
      start: (page - 1) * size,
      size: size,
      sortKey: sortKey as NAME | EMAIL | PAYMENT_MODE,
      sortOrder: sortOrder as ASC | DESC,
    };
    if (!sortKey && !sortOrder) {
      delete query.sortOrder;
      delete query.sortKey;
    }
    return query;
  }, [q, size, sortKey, sortOrder, page]);

  const request = useGetIndividualListQuery({ filters });
  const displayLoader =
    (request.isLoading && !request.data) || (request.isFetching && request.data?.totalCount === 0);
  const isEmptyState = request.data && request.data.totalCount == 0;

  const data: any = useStrapiSharedData();
  const { rowLabel, pageSizeSelectorLabel } = data.tableProps;

  return (
    <main
      id="main-content"
      className={clsx(
        'flex flex-col items-start gap-4 rounded-sm bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)] md:p-5 lg:p-6',
        className
      )}
    >
      <ListIndividualTableHeader q={q} status={status} />
      {request.isLoading && !request.data && (
        <div className="flex w-full justify-center py-4">
          <BasicSpinner className="text-white" />
        </div>
      )}
      {request.data && (
        <div
          className={clsx(
            'flex w-full flex-col gap-4',
            request.isPreviousData && request.isFetching && 'pointer-events-none opacity-70'
          )}
        >
          <div className="w-full overflow-y-scroll">
            <ListIndividualTable isDataLoading={request.isLoading} data={request.data.results} />
          </div>
          {!isEmptyState && (
            <ListIndividualTableFooter
              setPage={setPage}
              activePage={page}
              totalItems={request.data.totalCount}
              pageSize={size}
              setPageSize={setPageSize}
              rowLabel={rowLabel}
              pageSizeSelectorLabel={pageSizeSelectorLabel}
            />
          )}
        </div>
      )}
      {isEmptyState && !displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <TableEmptyState isFilterApplied={isFilterApplied} />
        </div>
      )}
      {request.isError && !request.data && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <ErrorDisplay
            refetch={request.refetch}
            isRefetching={request.isRefetching}
            allowsRefetch={true}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            message={strapiErrorMessage['SYSTEM_ERROR']}
          />
        </div>
      )}
    </main>
  );
};

export default ListIndividualTableMain;
