import React from 'react';

interface PerformanceTeamFilterContextValue {
  teamFilter: string;
  setTeamFilter: React.Dispatch<React.SetStateAction<string>>;
}

const performanceTeamFilterContext = React.createContext<PerformanceTeamFilterContextValue>(
  {} as PerformanceTeamFilterContextValue
);

export default performanceTeamFilterContext;
