import { X, ShieldCheck, Medal } from 'phosphor-react';
import Dialog from '../../../../components/Dialog';
import { SpeakerViewModalProps } from '../../../../features/courses/components/SingleCourseView/types/SingleCourseTypes';
import ImageWithFallback from '../../../../components/ImageWithFallback/ImageWithFallback';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import useReduxAuthState from '../../../../hooks/use-redux-auth-state';
import { USHG_HQ_APP_ROLE } from '../../../../constants';
import { COURSES_BASE_PATH } from '../../../courses/constants';
import qs from 'qs';

const SpeakerModal = ({
  speakerName,
  speakerDesignation,
  speakerDescription,
  numberOfCourses,
  picture,
}: SpeakerViewModalProps) => {
  const authState = useReduxAuthState();
  const role = authState.user?.role;
  // Forming the redirect link based on the role of the user
  const link: string =
    role === USHG_HQ_APP_ROLE.USHG_ADMIN
      ? '/manage' + COURSES_BASE_PATH
      : COURSES_BASE_PATH + '?' + qs.stringify({ view: 'all' });
  const strapiData = useStrapiDataHelper();
  const speakerImageFallback =
    strapiData.shared.data.attributes.SpeakerModalFallbackAvatar.data.attributes.url;
  const { coursesLabel, viewAllCoursesButton } = strapiData.speaker.data.attributes.HQ_ADMIN[4];
  return (
    <div className="relative h-screen overflow-y-auto rounded-lg bg-zinc-800 px-10 lg:h-fit lg:px-12">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between pt-8">
          <div>
            <p className="text-2xl font-semibold text-zinc-100">{speakerName}</p>
          </div>
          <Dialog.Close>
            <X size={32} fill="white" color="white" />
          </Dialog.Close>
        </div>

        <div className="flex flex-row gap-8 pt-3">
          <div className="flex flex-row gap-1">
            <div>
              <Medal size={19} color="#F7634F" />
            </div>
            <div>
              <p className="text-sm font-normal text-zinc-100">
                {numberOfCourses} {coursesLabel}
              </p>
            </div>
          </div>
          <div className="flex flex-row gap-1">
            <div>
              <ShieldCheck size={19} color="#F7634F" />
            </div>
            <div>
              <p className="text-sm font-normal text-zinc-100"> {speakerDesignation}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse gap-0 pb-14 lg:flex-row lg:gap-14">
        <div className="flex flex-col justify-between">
          <p className="max-w-3xl pt-8 text-justify text-sm font-normal leading-7 text-zinc-400 lg:max-w-2xl lg:pt-8">
            {speakerDescription}
          </p>
          <a className="pt-4 font-normal text-yellow-100 lg:pt-0" href={link}>
            {viewAllCoursesButton} -&gt;
          </a>
        </div>
        <div className="flex items-center justify-center pt-8 lg:ml-auto lg:pt-0">
          <ImageWithFallback
            imageKey={picture}
            className="aspect-square max-h-[400px] rounded-lg object-cover lg:max-h-[500px] lg:min-h-[500px]"
            Fallback={
              <img
                src={speakerImageFallback}
                className="aspect-square max-h-[400px] rounded-lg object-contain lg:max-h-[500px] lg:min-h-[500px]"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};
export default SpeakerModal;
