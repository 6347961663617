import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

type HTMLSpanProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;

interface Props extends HTMLSpanProps {
  wordsStr?: string;
  type?: 'rounded' | 'rounded-full';
  className?: string;
  length?: number;
}

/**
 * Builds a logo with the acronym of the words in a string.
 * Splits the words on space and takes the first word
 */
const AcronymLogo = (props: Props) => {
  const { wordsStr, className, length = 1, type = 'rounded-full', ...otherProps } = props;

  const acronym = useMemo(() => {
    if (!wordsStr) {
      return '';
    }

    try {
      const acronym = wordsStr
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '');
      return acronym;
    } catch (error) {
      console.log(`Error splitting ${wordsStr} into an acronym`);
      // As a fallback take the first character in case split fails
      return wordsStr[0];
    }
  }, [wordsStr]);

  return (
    <span
      className={twMerge(
        'flex aspect-square h-12 w-12 items-center justify-center border-zinc-800 bg-base-brand/95 text-lg font-bold text-white',
        type,
        className
      )}
      {...otherProps}
    >
      {acronym.slice(0, length).toLocaleUpperCase()}
    </span>
  );
};

export default AcronymLogo;
