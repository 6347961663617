import React from 'react';
import { Link } from 'react-router-dom';
import { useStrapiSharedData } from '../../features/shared/hooks/useStrapiSharedData';
import { Navbar } from '../Navbar';
import { Page } from '../Pages';

export const LandingNotFound = () => {
  const {
    pageNotFound: { title, subTitle, homepageLabel, goBackLabel, orLabel },
  } = useStrapiSharedData();
  return (
    <Page showIllustrator={true}>
      <Navbar></Navbar>
      <div className="p-4">
        <div className="mx-auto my-8 max-w-md rounded-xl border border-zinc-700 bg-component-bg-dark p-8 shadow-md">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-zinc-50">{title}</h5>
          <p className="font-normal leading-6 text-zinc-400">
            {subTitle}{' '}
            <Link className="underline" to="/">
              {homepageLabel}
            </Link>{' '}
            {orLabel}{' '}
            <Link className="underline" to={-1 as any}>
              {goBackLabel}
            </Link>
          </p>
        </div>
      </div>
    </Page>
  );
};
