import { flexRender, Row } from '@tanstack/react-table';
import { Bills } from '../../../types/billing';

interface Props {
  row: Row<Bills>;
  className?: string;
}

const BillingHistoryTableDataRow = ({ row, className }: Props) => {
  return (
    <>
      <tr className={className}>
        {row.getVisibleCells().map((cell) => {
          return (
            <td key={cell.id} className="p-2 text-[12px] font-normal leading-[16px] text-zinc-100">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default BillingHistoryTableDataRow;
