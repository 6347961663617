import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME, MFAMethod } from '../../../constants';
import {
  CancelTrialResponse,
  ReactivatePlanResponse,
  RenewPlanResponse,
  SubscriptionData,
} from '../../shared/types/subscription-data';

import { SETTING_API_PATHS } from '../constants';
import { CancelSubscription, ChangePlan, ChangeUserPasswordRequest } from '../types';
import { getAccessTokenHelper } from '../../shared/helpers';
import { LOCAL_STORAGE_AMPLIFY_CONFIG_KEY, LOCAL_STORAGE_REMEMBER_ME_KEY, LOCAL_STORAGE_USHG_HQ_ENTERPRISE_INVITE, LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY } from '../../auth/constants';
import { LOCAL_STORAGE_CHIME_APP_INSTANCE_ARN_KEY, LOCAL_STORAGE_USER_TENANT_ID } from '../../messaging/constants';

/**
 * Change User Password
 */
const changeUserPassword = async (args: ChangeUserPasswordRequest) => {
  const { accessToken, previousPassword, proposedPassword } = args;
  const body = {
    accessToken,
    previousPassword,
    proposedPassword,
  };
  const path = SETTING_API_PATHS.CHANGE_PASSWORD;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body,
    withCredentials: true,
  });
  return response;
};

/**
 * Cancel Subscription
 */
const cancelSubscription = async (args: CancelSubscription) => {
  const { reason } = args;
  const body = {
    reason,
  };
  const path = SETTING_API_PATHS.CANCEL_SUBSCRIPTION;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body,
    withCredentials: true,
  });
  return response;
};

/**
 * Get Checkout Session URL
 */
const getCheckoutSessionURL = async () => {
  const path = SETTING_API_PATHS.CHECKOUT_SESSION;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, { withCredentials: true });
  return response;
};

/**
 * Get Subscription Details
 */
const getSubscriptionDetails = async (): Promise<SubscriptionData> => {
  const path = SETTING_API_PATHS.SUBSCRIPTION_DETAILS;

  try{
    const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, { withCredentials: true });
    return response;
  } catch (error: any) {
    // Handle error from API call
    if (error.response && error.response.data && error.response.data.errorCode === "USER_NOT_FOUND") {
       //   // Clear custom config in localStorage
      localStorage.removeItem(LOCAL_STORAGE_AMPLIFY_CONFIG_KEY);
      localStorage.removeItem(LOCAL_STORAGE_REMEMBER_ME_KEY);
      localStorage.removeItem(LOCAL_STORAGE_CHIME_APP_INSTANCE_ARN_KEY);
      localStorage.removeItem(LOCAL_STORAGE_USER_TENANT_ID);
      localStorage.removeItem(LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY);
      localStorage.removeItem(LOCAL_STORAGE_USHG_HQ_ENTERPRISE_INVITE);
      window.location.reload();
      throw error;
    }
    throw error;
  }

};

/**
 * Change Plan
 */
const changePlan = async (args: ChangePlan) => {
  const { pricingType, type, licenses } = args;
  const body = {
    type,
    pricingType,
    licenses,
  };
  const path = SETTING_API_PATHS.CHANGE_PLAN;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body,
  });
  return response;
};

/**
 * Reactivate Plan
 */
const reactivatePlan = async (): Promise<ReactivatePlanResponse> => {
  const path = SETTING_API_PATHS.REACTIVATE_PLAN;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {});
  return response;
};
/**
 * Cancel Trial
 */
const cancelTrial = async (): Promise<CancelTrialResponse> => {
  const path = SETTING_API_PATHS.CANCEL_TRIAL;
  const response = await API.del(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    withCredentials: true,
  });
  return response;
};
/**
 * Renew Plan
 */
const renewPlan = async (): Promise<RenewPlanResponse> => {
  const path = SETTING_API_PATHS.RENEW_PLAN;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {});
  return response;
};

const updateMFAPreference = async (mfaPreference: keyof typeof MFAMethod) => {
  const path = SETTING_API_PATHS.UPDATE_MFA_PREFERENCE;
  const accessToken = await getAccessTokenHelper();
  const response = await API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body: {
      mfaPreference,
      accessToken,
    },
    withCredentials: true,
  });
  return response;
};

export {
  changeUserPassword,
  cancelSubscription,
  getCheckoutSessionURL,
  getSubscriptionDetails,
  changePlan,
  reactivatePlan,
  cancelTrial,
  renewPlan,
  updateMFAPreference,
};
