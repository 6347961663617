import Announcement from './Announcement';
import MaintenanceMode from './MaintenanceMode';

const PlatformSettings = () => {
  return (
    <section className="flex w-full flex-col items-center gap-6">
      <Announcement className="max-w-2xl" />
      <MaintenanceMode className="max-w-2xl" />
    </section>
  );
};

export default PlatformSettings;
