import { Auth, Hub } from 'aws-amplify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import getLocalStorageAmplifyAuthConfig from '../../../config/aws-config/localStorageAmplifyAuthConfig';
import useAppDispatch from '../../../hooks/use-app-dispatch';
import { mapCognitoUserToReduxUser } from '../helpers';
import {
  initializedAuthState,
  setCognitoHostedUIError,
  signedIn,
  signedOut,
} from '../slices/auth.slice';
import { getCurrentAuthenticatedUser } from '../utils';
import { MFAMethod } from '../../../constants';

const useAmplifyReduxBridge = () => {
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUserSignedIn = (data: any) => {
    try {
      const user = mapCognitoUserToReduxUser(data);
      console.log(data);
      console.log(user);
      appDispatch(signedIn(user));
    } catch (error) {
      console.log(error);
      // Sign out the user if there is any error
      const { code } = error as { code: string; message: string };

      // The below error is throw in @resolveRoleFromCognitoUserGroups
      if (code == 'USER_DOES_NOT_BELONG_TO_ANY_ROLE') {
        appDispatch(signedOut());
      }
    }
  };

  const getCurrentAuthenticatedUserAndUpdateState = async () => {
    try {
      const data = await getCurrentAuthenticatedUser();
      // Resolves with CognitoUser when there the current user is authenticated
      handleUserSignedIn(data);
    } catch (error) {
      // Thrown if there is no authenticated user currently
      console.log(error);
      appDispatch(signedOut());
    } finally {
      // We have initialized the auth state to either authenticated or unauthenticated
      appDispatch(initializedAuthState());
      console.log('Initialized authentication state');
    }
  };

  // runs only once on app mount
  useEffect(() => {
    console.log('Initializing authentication state');

    // Get Auth Config from localStorage if exists
    const authConfig = getLocalStorageAmplifyAuthConfig();
    console.log(authConfig);

    // dispatch signOut action if there is no user
    // set state to initialized
    // this would set isAuthenticated to false and user to null
    if (authConfig === null || authConfig === undefined) {
      appDispatch(signedOut());
      appDispatch(initializedAuthState());
      console.log('Initialized authentication state');
      return;
    }

    // configure auth config
    Auth.configure(authConfig);

    getCurrentAuthenticatedUser()
      .then((data) => {
        // Resolves with CognitoUser when there the current user is authenticated
        handleUserSignedIn(data);
      })
      .catch((e) => {
        // Thrown if there is no authenticated user currently
        console.log(e);
        appDispatch(signedOut());
      })
      .finally(() => {
        // We have initialized the auth state to either authenticated or unauthenticated
        appDispatch(initializedAuthState());
        console.log('Initialized authentication state');
      });
  }, []);

  // runs only once on app mount
  useEffect(() => {
    // callback for Hub auth channel events
    const hubAuthCallback: Parameters<typeof Hub.listen>[1] = async ({ payload }) => {
      const { event, data } = payload;

      console.log(payload);

      switch (event) {
        case 'cognitoHostedUI_failure': {
          const error = payload.data as { message?: string };
          appDispatch(
            setCognitoHostedUIError({
              code: error.message,
              message: error.message,
            })
          );
          break;
        }
        case 'cognitoHostedUI':
        case 'signIn': {
          const challengeName = data.challengeName;

          // When the challenge is SMS_MFA
          // the data doesn't include all the attributes
          // so we are getting the current authenticated user
          // and using the object to update redux
          if (challengeName === MFAMethod.SMS_MFA) {
            const cognitoUser = await getCurrentAuthenticatedUser();
            handleUserSignedIn(cognitoUser);
          } else {
            handleUserSignedIn(data);
          }

          break;
        }
        case 'signOut': {
          appDispatch(signedOut());
          break;
        }
        // Will be triggered when we pass custom state to cognito signIn
        // currently used to redirect users to the path
        // they were trying to access before they are authenticated
        case 'customOAuthState': {
          const path = data;
          navigate(path);
          break;
        }
        // When there is a token refresh update the current user in the state
        case 'tokenRefresh': {
          getCurrentAuthenticatedUserAndUpdateState();
        }
      }
    };

    // Listen to Hub auth events and dispatch actions accordingly
    const hubListenerCancelToken = Hub.listen('auth', hubAuthCallback);

    // Clear Hub Listener
    return () => hubListenerCancelToken();
  }, []);
};

export default useAmplifyReduxBridge;
