import axios from 'axios';

/**
 * Whether this is a 404/ Not Found error
 * returns true if yes else false null if unsure
 */
const isNotFoundError = (error: any) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 404) {
      return true;
    }
    return false;
  }

  return null;
};

export { isNotFoundError };
