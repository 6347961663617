import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import React, { useContext, useMemo, useState } from 'react';
import { NoDataDisplay, TableHeaderRow } from '../../../../../../components/DataTables';
import {
  EnterpriseUsers,
  PartialEnterpriseLeaderBoardFilter,
  ReportTooltipDataType,
} from '../../../../types';
import ReportsHeader from '../../ReportsHeader';
import { BadgesEarnedCell, CourseTimeCell, UserCell } from '../TableCell';
import TableDataRow from '../../../../../../components/DataTables/TableDataRow';
import ErrorDisplay from '../../../../../../components/DataTables/ErrorDisplay';
import { useEnterpriseIndividualLeaderboardQuery } from '../../../../hooks';
import durationContext from '../../../../context/durationContext';
import { ENTERPRISE_LEADERBOARD_DEFAULT_LIMIT, REPORT_TYPES } from '../../../../constants';
import { InView } from 'react-intersection-observer';
import { useStrapiReportsData } from '../../../../hooks/useStrapiReportData';
import { ERROR_TYPES } from '../../../../../../constants/error-types.constants';
import ScaleLoader from '../../../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../../../components/ReportToolTip';

interface IndividualLeaderboardProps {
  className: string;
  tableClassName?: string;
  lazyLoad?: boolean;
  teamId?: string;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}

const getTableColumnDefinition = (
  userHeader: string,
  totalTimeSpentHeader: string,
  badgesEarnedHeader: string
) => {
  const columnHelper = createColumnHelper<EnterpriseUsers>();
  const columns = [
    columnHelper.accessor('fullName', {
      header: userHeader,
      cell: (props) => (
        <UserCell
          username={props.row.original.fullName}
          pictureUrl={props.row.original.pictureUrl}
        />
      ),
    }),
    columnHelper.accessor('totalTimeSpent', {
      header: totalTimeSpentHeader,
      cell: (props) => {
        return <CourseTimeCell totalTimeSpent={props.getValue()} />;
      },
    }),
    columnHelper.accessor('badgesEarned', {
      header: badgesEarnedHeader,
      cell: (props) => <BadgesEarnedCell badgesEarned={props.getValue()} />,
    }),
  ];
  return columns;
};

const IndividualLeaderboardTable = ({
  className,
  tableClassName,
  lazyLoad = true,
  teamId,
  reportTooltipContent,
  userPersona,
}: IndividualLeaderboardProps) => {
  const { duration } = useContext(durationContext);
  const { title, tableheader1, tableheader2, tableheader3, emptyMessage, errorMessage } =
    useStrapiReportsData().IndividualLeaderboard;
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);

  // Build the filter
  const filters: PartialEnterpriseLeaderBoardFilter = useMemo(() => {
    return {
      fromDate: duration.fromDate,
      limit: ENTERPRISE_LEADERBOARD_DEFAULT_LIMIT,
      teamId: teamId && teamId !== '1' ? teamId : undefined,
    };
  }, [duration, teamId]);

  const individualLeaderboard: any = useEnterpriseIndividualLeaderboardQuery({
    filters,
    isEnabled: isInView,
  });

  const tableHeader = {
    userHeader: tableheader1,
    totalTimeSpentHeader: tableheader2,
    badgesEarnedHeader: tableheader3,
  };

  // Display loader during initial loading and if we are fetching when there are no items.
  const displayLoader =
    (individualLeaderboard.isLoading && !individualLeaderboard.data) ||
    (individualLeaderboard.isFetching && individualLeaderboard.data?.count === 0);

  const isEmptyState = individualLeaderboard.data && individualLeaderboard.data?.count == 0;

  const { userHeader, totalTimeSpentHeader, badgesEarnedHeader } = tableHeader;

  const columns = getTableColumnDefinition(userHeader, totalTimeSpentHeader, badgesEarnedHeader);

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: individualLeaderboard.data?.users || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiSort: true,
  });
  return (
    <InView
      as="div"
      className={className}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div
        className={clsx(
          'flex h-full min-h-[420px] w-full flex-col gap-4 overflow-hidden rounded-lg bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)]',
          tableClassName
        )}
      >
        <div className="flex items-center gap-2">
          <ReportsHeader title={title} />
          {reportTooltipContent?.[REPORT_TYPES.INDIVIDUAL_LEADERBOARD]?.[userPersona] && (
            <ReportTooltip
              content={reportTooltipContent[REPORT_TYPES.INDIVIDUAL_LEADERBOARD][userPersona]}
            />
          )}
        </div>
        {/* Is Loading and We donot have data or Is Fetching and we have data as empty array */}
        {displayLoader && <ScaleLoader />}
        {/* We have data */}
        {individualLeaderboard.data && individualLeaderboard.data?.count > 0 && (
          <div className="relative w-full overflow-hidden overflow-x-scroll">
            <table className={clsx('w-full', className)}>
              <thead className="w-full">
                {getHeaderGroups().map((headerGroup) => {
                  return (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableHeaderRow
                          className="!font-normal !text-zinc-400"
                          isSortable={false}
                          header={header}
                          key={header.id}
                        />
                      ))}
                    </tr>
                  );
                })}
              </thead>
              <tbody>
                {getRowModel().rows.map((row) => (
                  <TableDataRow tdClassName="!text-zinc-50 font-semibold" row={row} key={row.id} />
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Empty State */}
        {isEmptyState && !displayLoader && (
          <div className="flex w-full grow items-center justify-center">
            <NoDataDisplay message={emptyMessage} />
          </div>
        )}

        {individualLeaderboard.isError && !individualLeaderboard.data && (
          <div className="flex w-full grow items-center justify-center">
            <ErrorDisplay
              isRefetching={individualLeaderboard.isRefetching}
              refetch={individualLeaderboard.refetch}
              allowsRefetch={true}
              type={ERROR_TYPES.SOMETHING_WENT_WRONG}
              message={errorMessage}
            />
          </div>
        )}
      </div>
    </InView>
  );
};

export default IndividualLeaderboardTable;
