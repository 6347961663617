import { useQuery } from '@tanstack/react-query';
import { getEnterpriseLeaderTeamUsers } from '../api/EnterpriseLeaderTeamUsers';
import { PartialEnterpriseLeaderTeamManagerFilter } from '../types/interface';

interface Props {
  managerQueryParam: PartialEnterpriseLeaderTeamManagerFilter;
}

const useEnterpriseLeaderTeamUsersQuery = ({ managerQueryParam }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching } =
    useQuery(
      [
        {
          scope: 'leaderTeamManagers',
          managerQueryParam: managerQueryParam,
        },
      ],
      async () => {
        return getEnterpriseLeaderTeamUsers(managerQueryParam);
      },
      {
        keepPreviousData: true,
        // TODO: to be decided We are storing it in cache for 30 seconds
        staleTime: 30000,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching };
};

export default useEnterpriseLeaderTeamUsersQuery;
