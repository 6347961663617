import { useLocation } from 'react-router-dom';
import { MFAMethod, TenantMFAConfig, USHG_HQ_APP_ROLE } from '../constants';
import useReduxAuthState from './use-redux-auth-state';

/**
 * Whether the user *should* setup mfa due to the enterprise admin mandating it
 */
const useShouldSetupMFA = () => {
  const auth = useReduxAuthState();
  const location = useLocation();

  // We should be authenticated at this point
  // but doesn't make sense if the user is not authentication
  // so return false
  if (!auth.isAuthenticated) {
    return false;
  }

  // No onboarding or MFA for ushg admin
  if (auth.user.role === USHG_HQ_APP_ROLE.USHG_ADMIN) {
    return false;
  }

  // If the user has to go through onboarding
  // then don't redirect to settings page
  if (auth.user['custom:is_onboarded'] !== 'true') {
    return false;
  }

  const tenantMFAConfig = auth.user['custom:tenant_mfa_config'];
  const preferredMFA = auth.user.preferredMFA;

  // If the tenant has mfa config set to required
  // but the preferredMFA method of the user is NOMFA (It should be either SMS_MFA or SOFTWARE_TOKEN_MFA)
  // redirect the user to the settings page to setup their MFA
  if (
    tenantMFAConfig === TenantMFAConfig.REQUIRED &&
    preferredMFA === MFAMethod.NOMFA &&
    // If not already on settings page only then redirect
    location.pathname !== '/settings'
  ) {
    return true;
  }

  return false;
};

export default useShouldSetupMFA;
