import clsx from 'clsx';
import React, { useContext } from 'react';
import durationContext from '../../../shared/context/durationContext';

interface Props {
  className: string;
}
const DurationDisplayHeader = ({ className }: Props) => {
  const { duration } = useContext(durationContext);
  const { display } = duration;
  return (
    <div className={clsx('', className)}>
      <h1 className="text-sm font-semibold text-white md:text-lg">{display}</h1>
    </div>
  );
};

export default DurationDisplayHeader;
