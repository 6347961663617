export const PAGE_SEPERATOR_DOT_VALUE = '...';

// Get Pagination Items

const getRange = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

const clamp = (number: number, lower: number, upper: number) => {
  return Math.min(Math.max(number, lower), upper);
};

const getPaginationItems = (
  currentPage: number,
  pageCount: number,
  pagesShown: number,
  MINIMUM_PAGE_SIZE = 5
) => {
  let delta: number;
  currentPage = clamp(currentPage, 1, pageCount);
  pagesShown = clamp(pagesShown, MINIMUM_PAGE_SIZE, pageCount);
  const centerPagesShown = pagesShown - 5;
  const boundaryPagesShown = pagesShown - 3;

  if (pageCount <= pagesShown) {
    delta = pagesShown;
  } else {
    delta =
      currentPage < boundaryPagesShown || currentPage > pageCount - boundaryPagesShown
        ? boundaryPagesShown
        : centerPagesShown;
  }

  const range = {
    start: Math.round(currentPage - delta / 2),
    end: Math.round(currentPage + delta / 2),
  };

  if (range.start - 1 === 1 || range.end + 1 === pageCount) {
    range.start += 1;
    range.end += 1;
  }
  let pages: (string | number)[] =
    currentPage > delta
      ? getRange(Math.min(range.start, pageCount - delta), Math.min(range.end, pageCount))
      : getRange(1, Math.min(pageCount, delta + 1));

  if (currentPage > pageCount - boundaryPagesShown && pageCount > pagesShown) {
    pages = getRange(pageCount - delta, pageCount);
  }

  const withDots = (value: number, pair: (string | number)[]) =>
    pages.length + 1 !== pageCount ? pair : [value];
  const lastPage = pages[pages.length - 1];

  if (pages[0] !== 1) {
    pages = withDots(1, [1, PAGE_SEPERATOR_DOT_VALUE]).concat(pages);
  }

  if (lastPage && lastPage < pageCount) {
    pages = pages.concat(withDots(pageCount, [PAGE_SEPERATOR_DOT_VALUE, pageCount]));
  }

  return pages;
};

// Get Pagination Items

interface ComputePageSizeOptionsProps {
  totalItems: number;
  pageSizeIncrement: number;
  minPageSize: number;
  maxPageSize: number;
  paginationRowLabel: string;
}

const computePageSizeOptions = (args: ComputePageSizeOptionsProps) => {
  const { totalItems, pageSizeIncrement, minPageSize, maxPageSize, paginationRowLabel } = args;

  const PAGE_SIZES = [minPageSize];
  let remainingItems = totalItems - pageSizeIncrement;

  while (remainingItems > 0) {
    const newPageSize = PAGE_SIZES[PAGE_SIZES.length - 1] + pageSizeIncrement;
    PAGE_SIZES.push(newPageSize);

    // We want to limit the max number of pages
    if (newPageSize >= maxPageSize) {
      break;
    }

    remainingItems = remainingItems - pageSizeIncrement;
  }

  const PAGE_SIZE_OPTIONS = PAGE_SIZES.map((pageSize) => {
    return {
      display: `${pageSize.toString()} ${paginationRowLabel}`,
      value: pageSize.toString(),
    };
  });

  return PAGE_SIZE_OPTIONS;
};

export { getPaginationItems, computePageSizeOptions };
