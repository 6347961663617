import { useNavigate } from 'react-router-dom';
import { NoDataDisplay } from '../../../../../../components/DataTables';
import { useStrapiSharedData } from '../../../../../shared/hooks/useStrapiSharedData';
import useStrapiMembersData from '../../../../hooks/useStrapiMembersData';

interface Props {
  isFilterApplied: boolean;
  className?: string;
}

const AllMembersTableEmptyState = ({ isFilterApplied, className }: Props) => {
  const navigate = useNavigate();

  const clearFilters = () => {
    // This will clear all search params
    navigate('.');
  };
  const {
    tableProps: { clearAllFiltersButton },
  } = useStrapiSharedData();
  const {
    errorMessage: { noMembersAvailableMessage },
  } = useStrapiMembersData();
  if (isFilterApplied) {
    return (
      <NoDataDisplay
        className={className}
        message={
          <div className="flex flex-col items-center gap-8">
            <span>{noMembersAvailableMessage}</span>
            <button
              className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
              onClick={() => clearFilters()}
            >
              {clearAllFiltersButton}
            </button>
          </div>
        }
      />
    );
  }

  return (
    <NoDataDisplay className={className} message={'No members found under this organization'} />
  );
};

export default AllMembersTableEmptyState;
