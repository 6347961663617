import { useQuery } from '@tanstack/react-query';
import { getTopEarnedBadges } from '../api';
import { PartialTopEarnedBadgesFilter } from '../types';
import { PERFORMANCE_REPORT_TYPES, REPORTS_STALE_TIME } from '../constants/reports';

interface Props {
  filters: PartialTopEarnedBadgesFilter;
  isEnabled: boolean;
}
const useTopEarnedBadgesQuery = ({ filters, isEnabled }: Props) => {
  const { isLoading, data, isError, refetch, isRefetching, isFetching } = useQuery(
    [
      {
        filters: filters,
        scope: 'dashboard-reports',
        item: PERFORMANCE_REPORT_TYPES.TOP_EARNED_BADGES,
      },
    ],
    async ({ queryKey }) => {
      const { filters } = queryKey[0];
      return await getTopEarnedBadges(filters);
    },
    {
      staleTime: REPORTS_STALE_TIME,
      enabled: isEnabled,
    }
  );
  return { isLoading, data, isError, refetch, isRefetching, isFetching };
};

export default useTopEarnedBadgesQuery;
