import React from 'react';

interface Props {
  course: string;
}

const CourseNameCell = ({ course }: Props) => {
  return <span className="whitespace-nowrap">{course}</span>;
};

export default CourseNameCell;
