import { CourseModulesTabProps } from './type/tabType';

const CourseModulesTab = ({ currentTab, tabHeaders, changeTab }: CourseModulesTabProps) => {
  return (
    <div className="flex flex-row gap-6">
      {tabHeaders.map((item) => {
        return (
          <div
            key={item}
            className={`cursor-pointer ${
              currentTab === item ? 'border-b border-base-brand text-base-brand' : 'text-zinc-500'
            } p-1 `}
            onClick={() => changeTab(item)}
          >
            {item}
          </div>
        );
      })}
    </div>
  );
};

export default CourseModulesTab;
