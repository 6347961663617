import { createSlice } from '@reduxjs/toolkit';
import { ClosedCaptionsType } from '../../../../course-management/types';

type ModuleDetails = {
  id: number | null;
  name: string | null;
  type: string | null;
  mediaUrl: string | null;
  description: string | null;
  pictureUrl: string | null;
  closedCaptionsUrls: ClosedCaptionsType[];
  status: string | null;
  seekableDuration: number | null;
  startFromDuration: number | null;
};

interface SingleCourseState {
  modulesCount: number;
  currentModuleIndex: number | null;
  currentAssessmentQuestions: any[] | null;
  currentModuleDetails: ModuleDetails;
}

const initialState: SingleCourseState = {
  modulesCount: 0,
  currentModuleIndex: null,
  currentAssessmentQuestions: null,
  currentModuleDetails: {
    id: null,
    name: null,
    type: null,
    description: null,
    mediaUrl: null,
    pictureUrl: null,
    closedCaptionsUrls: [],
    status: null,
    seekableDuration: null,
    startFromDuration: null,
  },
};

const singleCourseSlice = createSlice({
  name: 'singleCourses',
  initialState,
  reducers: {
    setModulesCount: (state, event) => {
      state.modulesCount = event.payload;
    },
    setCurrentModuleIndex: (state, event) => {
      state.currentModuleIndex = event.payload;
    },
    setCurrentAssessmentQuestions: (state, event) => {
      state.currentAssessmentQuestions = event.payload;
    },
    setCurrentModuleDetails: (state, event) => {
      state.currentModuleDetails = event.payload;
    },
    clearModule: (state) => {
      state.modulesCount = 0;
      state.currentModuleIndex = null;
      state.currentAssessmentQuestions = null;
      state.currentModuleDetails = {
        id: null,
        name: null,
        description: null,
        type: null,
        mediaUrl: null,
        pictureUrl: null,
        closedCaptionsUrls: [],
        status: null,
        seekableDuration: null,
        startFromDuration: null,
      };
    },
  },
});

export const {
  setModulesCount,
  setCurrentModuleIndex,
  setCurrentAssessmentQuestions,
  setCurrentModuleDetails,
  clearModule,
} = singleCourseSlice.actions;

export default singleCourseSlice.reducer;
