import clsx from 'clsx';
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useStrapiSharedData } from '../../features/shared/hooks/useStrapiSharedData';
import { computePageSizeOptions } from '../../utils';
import PageSelector from './PageSelector';
import PageSizeSelector from './PageSizeSelector';
import cn from '../../utils/cn';

interface Props {
  activePage: number;
  totalItems: number;
  setPage: (page: number) => void;
  pageSize: number;
  setPageSize?: (pageSize: number) => void;
  pageSizeIncrement?: number;
  minPageSize?: number;
  maxPageSize?: number;
  className?: string;
  disablePageSizeSelector?: boolean;
  disablePageSelector?: boolean;
}

// Footer with the PageSelector and PageSize Selector components organized in flex container
const PaginationFooter = (props: Props) => {
  const { tableProps } = useStrapiSharedData();
  const { rowLabel: paginationRowLabel } = tableProps;
  const {
    activePage,
    totalItems,
    setPage,
    pageSize,
    setPageSize,
    pageSizeIncrement,
    minPageSize,
    maxPageSize,
    className,
    disablePageSelector,
    disablePageSizeSelector,
  } = props;

  const PAGES_SIZE_SELECT_OPTIONS = useMemo(() => {
    if (pageSizeIncrement && minPageSize && maxPageSize) {
      return computePageSizeOptions({
        paginationRowLabel,
        totalItems,
        pageSizeIncrement,
        minPageSize,
        maxPageSize,
      });
    } else {
      return null;
    }
  }, [paginationRowLabel, totalItems, pageSizeIncrement, minPageSize, maxPageSize]);

  const handlePageSizeChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const pageSize = e.target.value;
    setPage(1);
    if (setPageSize) setPageSize(Number(pageSize));
  };

  const totalPages = Math.ceil(totalItems / pageSize);

  // Logic for number of items to be displayed on pagination

  const isLargeScreenOrLarger = useMediaQuery({ query: '(min-width: 1024px)' });
  const isMediumScreenOrLarger = useMediaQuery({ query: '(min-width: 768px)' });
  const isSmallScreenOrLarger = useMediaQuery({ query: '(min-width: 640px)' });
  const isSmallScreenOrSmaller = useMediaQuery({ query: '(max-width: 640px)' });

  let visiblePagesCount = 15;
  if (!isLargeScreenOrLarger && isMediumScreenOrLarger) {
    visiblePagesCount = 10;
  } else if (!isMediumScreenOrLarger && isSmallScreenOrLarger) {
    visiblePagesCount = 7;
  } else if (isSmallScreenOrSmaller) {
    visiblePagesCount = 5;
  }

  // Logic for number of items to be displayed on pagination
  return (
    <div
      className={clsx(
        'flex w-full flex-col items-center justify-between gap-2 lg:flex-row',
        className
      )}
    >
      {!disablePageSelector && (
        <PageSelector
          className={cn('justify-center', !disablePageSizeSelector && 'lg:justify-start')}
          activePage={activePage}
          totalPages={totalPages}
          setPage={setPage}
          visiblePagesCount={visiblePagesCount}
        />
      )}
      {!disablePageSizeSelector && PAGES_SIZE_SELECT_OPTIONS && (
        <PageSizeSelector
          handlePageSizeChange={handlePageSizeChange}
          options={PAGES_SIZE_SELECT_OPTIONS}
          pageSize={pageSize.toString()}
        />
      )}
    </div>
  );
};

export default PaginationFooter;
