import clsx from 'clsx';
import { Crown } from 'phosphor-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MANAGER } from '../../constants/userTypes';
import { useStrapiSharedData } from '../../features/shared/hooks/useStrapiSharedData';
import { ProfilePictureSquare } from '../Icons';
import ImageWithFallback from '../ImageWithFallback';
import { toast } from 'react-hot-toast';
import { Toast } from '../Toast';
import { formatNumber } from '../../utils';

// UserType & UserDetails
type userDetails = {
  name: string;
  profileImage: string;
  enrolledCourses?: number;
  progress?: number;
  designation?: string;
  id?: string;
};
export type UserDetails = {
  type?: string;
  details: userDetails;
};

export type accessType = {
  hasAccess: boolean;
  restrictionToast: { title: string; message: string };
};

type UserCardProps = {
  user: UserDetails;
  hasAccess?: accessType;
  classNames?: string;
  isMemberList?: boolean;
};

function UserCard({ user, classNames, isMemberList, hasAccess }: UserCardProps) {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);
  const { coursesLabel, overAllProgressLabel } = useStrapiSharedData().userCard;
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const onUserCardClick = (user: any) => {
    navigate(`/team/member/${user.details.id}`); // Fix it to meaningful route
  };

  const restrictAccess = (restrict: accessType) => {
    toast.custom((t) => (
      <Toast
        variant="error"
        Title={restrict.restrictionToast.title}
        SubTitle={restrict.restrictionToast.message}
        toastInstance={t}
      />
    ));
  };

  return (
    <div onMouseLeave={handleMouseOut} onMouseOver={handleMouseOver}>
      <div
        className={clsx('relative h-[10.9rem] overflow-hidden rounded-md', classNames)}
        onClick={() => {
          if (hasAccess && !hasAccess.hasAccess) {
            restrictAccess(hasAccess);
          } else {
            onUserCardClick(user);
          }
        }}
      >
        <ImageWithFallback
          loading="lazy"
          alt={user.details.name}
          imageKey={user.details.profileImage}
          className={clsx(
            'h-[10.9rem]  rounded-md object-cover',
            isMemberList ? 'w-full' : 'w-[10.9rem]'
          )}
          Fallback={<ProfilePictureSquare className="aspect-square h-full w-full object-cover" />}
        />

        <div className="absolute bottom-0 left-0 h-full w-full cursor-pointer bg-gradient-to-t from-[#010211] via-[#03072B]/[0.35]">
          <div className="absolute bottom-0 left-0 m-3 w-4/5">
            {!isHovering && (
              <p className="overflow-hidden text-ellipsis text-[16px] font-bold leading-[1.70rem] text-white">
                {user.details.name ? user.details.name : user.details.id}
              </p>
            )}
            {!isHovering && user.type === MANAGER && (
              <p className="my-[0.43rem] overflow-hidden text-ellipsis text-xs font-medium leading-[0.78rem] text-zinc-200">
                <span>
                  <Crown size={16} weight="fill" color={'#F7634F'} className="inline" /> Manager
                </span>
              </p>
            )}
          </div>
        </div>
        {isHovering && (
          <div className="absolute top-0 left-0 h-full w-full cursor-pointer bg-black opacity-80">
            <div className="p-4">
              <h1 className="overflow-hidden text-ellipsis text-base !font-bold text-white">
                {user.details.name ? user.details.name : user.details.id}
              </h1>
              <p className="text-xs text-white">
                {(user.details.enrolledCourses || 0) + ' ' + coursesLabel}
              </p>
              <p className="!mb-0 text-xs text-white">{user.details.designation}</p>
              {
                <div className="absolute inset-x-4 bottom-4 mt-[25%] pr-3">
                  <div className="mb-3 grid grid-cols-6 gap-4">
                    <div className="col-start-1 col-end-5 text-[10px] text-white">
                      {overAllProgressLabel}
                    </div>

                    <div className="col-span-2 col-end-7 text-end text-[10px] text-white">
                      {(user.details.progress ? formatNumber(user.details.progress) : 0) + '%'}
                    </div>
                  </div>
                  <div className="flex w-full flex-none text-sm">
                    <progress
                      className="progress progress-error w-full bg-zinc-700"
                      value={user.details.progress || 0}
                      max="100"
                    ></progress>
                  </div>
                </div>
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserCard;
