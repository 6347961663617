import { useMutation } from '@tanstack/react-query';
import { updateModuleLastAccessed } from '../api/courses';

const useUpdateModuleLastAccessedMutation = () => {
  const { data, error, isLoading, isError, mutate } = useMutation({
    mutationFn: async (assessmentId: string) => {
      const response = await updateModuleLastAccessed(assessmentId);
      return response;
    },
  });
  return { data, error, isLoading, isError, mutate };
};

export default useUpdateModuleLastAccessedMutation;
