import React from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

type HTMLSelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

export type Option = {
  display: React.ReactNode;
  value: string;
};

type Props = {
  className?: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  label?: string;
  options: Option[];
  optionClassName?: string;
  bgClassName?: string;
  value?: string;
  selectItemLabel?: string;
  disabledOptions?: string[]; // New prop for disabled options
};

export type SelectProps = Props & HTMLSelectProps;

export const SELECTLABELVALUE = '__SELECT_LABEL__';

const Select = ({
  className,
  value = SELECTLABELVALUE,
  selectItemLabel = 'Select One',
  onChange,
  label,
  options,
  optionClassName,
  disabledOptions,
  ...otherProps
}: SelectProps) => {
  return (
    <select
      {...otherProps}
      value={value}
      className={twMerge(
        `flex items-center justify-center border-[0.837607px] border-zinc-800 bg-zinc-900 py-1.5 pl-4 pr-8 text-[11.7px] font-normal leading-[17px] text-zinc-200 `,
        className
      )}
      onChange={onChange}
    >
      <option className={clsx(optionClassName)} hidden disabled value={SELECTLABELVALUE}>
        {label ?? selectItemLabel}
      </option>
      {options.map((o) => (
        <option
          className={clsx(optionClassName)}
          value={o.value}
          key={o.value}
          disabled={disabledOptions ? disabledOptions.includes(o.value) : false} // Check if disabledOptions is provided and then disable accordingly
        >
          {o.display}
        </option>
      ))}
    </select>
  );
};

Select.LabelValue = SELECTLABELVALUE;

export default Select;
