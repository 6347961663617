import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'phosphor-react';
import ScaleLoader from '../../../../../components/Loader/ScaleLoader';
import { twMerge } from 'tailwind-merge';
import { BadgeCard } from '../../../../../components/Cards';
import { ErrorDisplay } from '../../../../../components/DataTables';
import { ERROR_TYPES } from '../../../../../constants/error-types.constants';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
type props = {
  className?: string;
  title: string;
  navigationButton: string;
  navigationTo: string;
  fetchQuery: any;
};
const BadgesDashboard = ({
  className,
  title,
  navigationButton,
  navigationTo,
  fetchQuery,
}: props) => {
  const displayLoader = fetchQuery.isLoading && !fetchQuery.data;
  const isEmptyState = fetchQuery.data && fetchQuery.data.count == 0;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  return (
    <>
      {!isEmptyState && !displayLoader && (
        <div className="flex flex-row items-center justify-between gap-1">
          <p className={clsx('text-sm font-semibold leading-[21px] text-zinc-50', className)}>
            {title}
          </p>

          <Link to={navigationTo}>
            <div className="flex flex-row items-center">
              <p className="text-xs text-zinc-400">{navigationButton}</p>
              <ArrowRight size={13} color="#A1A1AA" />
            </div>
          </Link>
        </div>
      )}
      <div className="flex h-max w-full grow items-center">
        {displayLoader && (
          <div className="flex min-h-[120px] w-full items-center justify-center py-4">
            <ScaleLoader />
          </div>
        )}
        {/* We have data */}
        {fetchQuery.data && fetchQuery.data.count > 0 && (
          <div
            className={clsx(
              'relative flex w-full flex-col gap-4',
              fetchQuery.isPreviousData && fetchQuery.isFetching && 'pointer-events-none opacity-70'
            )}
          >
            <div
              className={twMerge(
                'flex flex-nowrap gap-6 overflow-auto [--badge-image-size:78px]',
                className
              )}
            >
              {fetchQuery.data.rows.map((badge: any) => (
                <BadgeCard
                  key={badge.badgeId}
                  badge={badge}
                  showBadgeName={true}
                  badgeNameClassName="text-zinc-200"
                ></BadgeCard>
              ))}
            </div>
          </div>
        )}

        {fetchQuery.isError && !fetchQuery.data && (
          <div className="flex min-h-[120px] w-full items-center justify-center">
            <ErrorDisplay
              refetch={fetchQuery.refetch}
              isRefetching={fetchQuery.isRefetching}
              allowsRefetch={true}
              type={ERROR_TYPES.SOMETHING_WENT_WRONG}
              message={strapiErrorMessage['SYSTEM_ERROR']}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BadgesDashboard;
