// one min as they would not probably change
export const USHG_ADMIN_COURSES_STALE_TIME = 60000;

export const TAGS_DROPDOWN_SIZE = 50;
export const STALE_TIME = 30000;

export const COURSES_ERROR_CODE = {
  COURSE_NOT_FOUND: 'COURSE_NOT_FOUND',
  MODULE_ID_NOT_FOUND: 'MODULE_ID_NOT_FOUND',
  ASSESSMENT_ID_NOT_FOUND: 'ASSESSMENT_ID_NOT_FOUND',
  QUESTION_ID_NOT_FOUND: 'QUESTION_ID_NOT_FOUND',
};
