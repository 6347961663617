import { PartialUsersStatsFilter, UserStatsResponse } from '../types/interface';
import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import qs from 'qs';

const getUserStats = async (username: PartialUsersStatsFilter): Promise<UserStatsResponse> => {
  const path = '/users/user/statistics';
  const queryString = qs.stringify(username);

  try {
    const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
      response: true,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export { getUserStats };
