import { useStrapiSharedData } from '../../../../../shared/hooks/useStrapiSharedData';

const NewFeatureLabel = () => {
  // strapi
  const { newFeatureLabel } = useStrapiSharedData();
  // strapi

  return (
    <span className="rounded-[56px] bg-base-brand py-[1px] px-[5px] text-[0.625rem] font-bold leading-normal text-white">
      {newFeatureLabel}
    </span>
  );
};

export default NewFeatureLabel;
