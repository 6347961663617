import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useReduxAuthState } from '../../../hooks';
import { getMyCalendarItems, GetMyCalendarItemsArgs } from '../api';
import { MY_CALENDAR_ITEMS_API_STALE_TIME } from '../constants';

type Props = {
  rangeStartDate: Date;
  rangeEndDate?: Date;
  offset?: number;
  limit?: number;
};

/**
 * Wrapper around useQuery to fetch the calendar items for the user.
 */
const useMyCalendarItemsQuery = (props: Props) => {
  const { rangeStartDate, rangeEndDate, limit, offset } = props;

  if (rangeEndDate == undefined && limit === undefined) {
    throw new Error(
      'Either rangeEndDate or limit or both should be passed to useMyCalendarItemsQuery'
    );
  }

  const auth = useReduxAuthState();

  /**
   * Convert rangeStartDate and rangeEndDate in Date format to string with yyyy-MM-dd format
   */
  const { rangeStart, rangeEnd } = useMemo(() => {
    let rangeEnd: string | undefined = undefined;
    const rangeStart = rangeStartDate.toISOString().substring(0, 10);

    if (rangeEndDate) {
      rangeEnd = rangeEndDate.toISOString().substring(0, 10);
    }

    return { rangeStart, rangeEnd };
  }, [rangeStartDate, rangeEndDate]);

  const query = useQuery({
    queryKey: [
      {
        scope: 'dashboard',
        item: 'my-calendar-items',
        // We should have an username since the user would be logged in
        limit,
        offset,
        rangeEnd,
        rangeStart,
        username: auth.user?.username,
      },
    ],
    queryFn: async ({ queryKey }) => {
      const { rangeStart, rangeEnd, limit, offset } = queryKey[0];
      const params: GetMyCalendarItemsArgs = {
        limit,
        offset,
        // eslint-disable-next-line camelcase
        rangeEndDate: rangeEnd,
        // eslint-disable-next-line camelcase
        rangeStartDate: rangeStart,
      };

      const calendarItems = await getMyCalendarItems(params);
      return calendarItems;
    },
    keepPreviousData: true,
    enabled: auth.isAuthenticated,
    staleTime: MY_CALENDAR_ITEMS_API_STALE_TIME,
  });

  return query;
};

export default useMyCalendarItemsQuery;
