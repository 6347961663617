import { ArrowLeft } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { BadgeComponent } from '../../../../../../components/Badge/Badge';
import { Button } from '../../../../../../components/Buttons';
import Dialog from '../../../../../../components/Dialog';
import { useAppSelector, useReduxAuthState } from '../../../../../../hooks';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { RootState } from '../../../../../../store';
import IndividualSSOChangePlan from '../IndividualSSOChangePlan';
import { useState } from 'react';
import cn from '../../../../../../utils/cn';

const UserRegistrationMainHeader = () => {
  const pricingStateSelector = (state: RootState) => state.pricing;
  const { pricing, tenure } = useAppSelector(pricingStateSelector);
  const { freeTrail, role } = pricing;

  const auth = useReduxAuthState();

  const [isModifyPlanDialogOpen, setIsModifyDialogPlanOpen] = useState(false);

  // strapi
  const data: any = useStrapiDataHelper();
  const attributes = data?.registration.data.attributes;
  const formData = data?.registration?.data?.attributes;
  const { monthy, annually } = formData; // TODO: fix
  const { tenure: monthlyTenure } = monthy;
  const { tenure: annuallyTenure, value: year } = annually;
  const { signupLabel, subscriptionLabel, planLabel, backLabel, SubFormsHeader } = attributes;
  const priceCards = data?.registration?.data?.attributes.pricingTable?.Pricecard;

  const pricingCardDetails = priceCards.find((pc: any) => pc.value === pricing.subscriptionType);

  // derived
  // donot allow to go back if the user is authenticated
  const allowBack = !auth.isAuthenticated;
  // derived

  return (
    <div
      className={cn(
        'flex w-full flex-col rounded-lg border border-base-bright-green/30 bg-zinc-900 p-4 text-lg font-bold text-white',
        allowBack === false ? 'gap-2 md:gap-1.5 lg:gap-1' : ''
      )}
    >
      <div className="mt-0 ml-auto flex items-center gap-2">
        {freeTrail && (
          <BadgeComponent
            size="lg"
            label={SubFormsHeader.freeTrialLabel}
            className="bg-base-brand text-[9px] font-bold uppercase text-white"
          ></BadgeComponent>
        )}
        {pricingCardDetails ? (
          <Dialog
            open={isModifyPlanDialogOpen}
            triggerClassName="w-fit rounded-sm bg-zinc-700 py-1 px-2 text-xs font-medium text-base-white"
            Content={
              <IndividualSSOChangePlan
                setIsDialogOpen={setIsModifyDialogPlanOpen}
                pricingCard={{ plan: pricingCardDetails, tenure: tenure }}
              />
            }
            containerClassName="max-w-md max-h-[90vh] overflow-y-scroll"
            onOpenChange={(o) => setIsModifyDialogPlanOpen(o)}
          >
            {SubFormsHeader.changeCurrPlanLabel}
          </Dialog>
        ) : null}
      </div>
      <div className="pl-1">
        {allowBack ? (
          <Link to="/auth/register/pricing">
            <Button
              type="submit"
              isBorderless={true}
              className="flex text-xs font-bold uppercase text-base-brand outline-none"
            >
              <ArrowLeft size="16px" className="mr-2" />
              {backLabel}
            </Button>
          </Link>
        ) : null}
        <div className="flex flex-row items-center justify-between">
          <p className="text-lg md:text-xl">
            {signupLabel} {role} {planLabel} — {tenure === year ? annuallyTenure : monthlyTenure}{' '}
            {subscriptionLabel}
          </p>
        </div>
      </div>
    </div>
  );
};
export default UserRegistrationMainHeader;
