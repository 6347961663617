import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import Hole from '../Icons/Hole';
import QuestionMark from '../Icons/QuestionMark';

interface Props {
  message: React.ReactNode;
  className?: string;
  messageContainerClassName?: string;
  IconUrl?: string;
}

const NoDataDisplay = (props: Props) => {
  const { messageContainerClassName, message, className, IconUrl } = props;

  return (
    <div
      className={twMerge(
        'm-4 flex w-full flex-col items-center justify-center gap-8 rounded-lg bg-card-bg p-10',
        className
      )}
    >
      <div className="relative flex flex-col items-center">
      {IconUrl ?  
          <img src={IconUrl} alt="loading..." className='w-32 h-32' /> 
          : 
          <>
              <QuestionMark className="relative" />
              <Hole className="absolute -bottom-1/2 z-[0] -translate-y-1/2" />
          </>
      }
      </div>
      <div
        className={clsx(
          'text-center text-base font-normal text-zinc-50',
          messageContainerClassName
        )}
      >
        {message}
      </div>
    </div>
  );
};

export default NoDataDisplay;
