import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Button } from '../../../../../components/Buttons';
import { Label, PasswordInput } from '../../../../../components/FormElements';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
import { BasicSpinner } from '../../../../../components/Spinners';
import {
  LOWERCASE_CHARACTER_REGEX_PATTERN,
  SINGLE_DIGIT_REGEX_PATTERN,
  SPECIAL_CHARACTER_REGEX_PATTERN,
  UPPERCASE_CHARACTER_REGEX_PATTERN,
  LEADING_AND_TRAILING_SPACES_PATTERN,
} from '../../../../../constants';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { fieldHasError } from '../../../../../utils/react-hook-form';
import { ChangePasswordParams } from '../../../../auth/helpers';
import { useChangePasswordMutation } from '../../../hooks';
import { useStrapiSettingsData } from '../../../hooks/useStrapiSettingsData';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { FormFieldWrapper } from '../../Form';
import PasswordRequirements from '../../../../../components/FormElements/PasswordRequirements';
// Change Password Form Schema section starts

// Yup Schema
const changePasswordSchema = yup.object({
  currentPassword: yup
    .string()
    .trim()
    .required('currentPasswordRequired')
    .label('Current password'),
  password: yup
    .string()
    .min(8, 'passwordMin')
    .required('passwordRequired')
    .matches(new RegExp(LOWERCASE_CHARACTER_REGEX_PATTERN), 'passwordLower')
    .matches(new RegExp(UPPERCASE_CHARACTER_REGEX_PATTERN), 'passwordUpper')
    .matches(new RegExp(SPECIAL_CHARACTER_REGEX_PATTERN), 'passwordSpecialCharacter')
    .matches(new RegExp(SINGLE_DIGIT_REGEX_PATTERN), 'passwordNumber')
    .matches(new RegExp(LEADING_AND_TRAILING_SPACES_PATTERN), 'passwordSpace')
    .label('New password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'confirmPassword')
    .label('Confirm password')
    .required('confirmPasswordRequired')
    .label('Confirm new password'),
});
// Change Password Form Schema section starts

export type CHANGE_PASSWORD_FORM_DATA = yup.InferType<typeof changePasswordSchema>;

type Props = {
  className?: string;
};

const ChangePassword = (props: Props) => {
  const { className } = props;

  // Create new Change Password Mutation
  const changePasswordMutation = useChangePasswordMutation();

  // Form initialization
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<CHANGE_PASSWORD_FORM_DATA>({
    mode: 'onChange',
    resolver: yupResolver(changePasswordSchema),
  });

  // Strapi data currentPasswordRequired
  const data = useStrapiDataHelper();
  const strapiErrorData = data?.errormessage.data.attributes;

  const handleFormSubmit = async (data: CHANGE_PASSWORD_FORM_DATA) => {
    const { currentPassword, password } = data;
    const params: ChangePasswordParams = {
      oldPassword: currentPassword,
      newPassword: password,
    };
    // Change new password
    changePasswordMutation.mutate(params, {
      onSuccess: () => {
        onReset(); // reset password fields on success
      },
    });
  };
  const { password } = useStrapiSettingsData();
  const {
    title,
    description,
    currentPasswordLabel,
    newPasswordLabel,
    confirmPasswordLabel,
    updatePasswordButton,
    updatePasswordRequestButton,
    resetButton,
  } = password;
  // Clear form errors and reset values on Reset
  const onReset = () => {
    clearErrors();
    reset();
  };

  return (
    <div
      className={twMerge(clsx('flex w-full flex-col gap-4 rounded-sm bg-card-bg p-6', className))}
    >
      <div className="flex flex-col gap-1 leading-5">
        <h1 className="text-xl font-semibold text-zinc-50">{title}</h1>
        <p className="text-sm text-zinc-400">{description}</p>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(handleFormSubmit)(e);
        }}
        className="flex flex-col gap-4"
      >
        {/* Current Password Input */}
        <FormFieldWrapper>
          <Label htmlFor="current_password" label={currentPasswordLabel} />
          <PasswordInput
            className="rounded-md"
            id="current_password"
            type="password"
            hasError={fieldHasError(errors, 'currentPassword')}
            {...register('currentPassword')}
          />
          <HookFormErrorMessage
            name="currentPassword"
            errors={errors}
            render={({ message }) => <ErrorMessage message={strapiErrorData[message]} />}
          />
        </FormFieldWrapper>

        {/* New Password Input */}
        <FormFieldWrapper>
          <Label htmlFor="new_password" label={newPasswordLabel} />
          <PasswordInput
            className="rounded-md"
            id="new_password"
            type="password"
            autoComplete="off"
            hasError={fieldHasError(errors, 'password')}
            {...register('password', {
              deps: ['confirmPassword'],
            })}
          />
          <PasswordRequirements />
          <HookFormErrorMessage
            name="password"
            errors={errors}
            render={({ message }) => <ErrorMessage message={strapiErrorData[message]} />}
          />
        </FormFieldWrapper>
        {/* Confirm New Password Input */}
        <FormFieldWrapper>
          <Label htmlFor="confirm_password" label={confirmPasswordLabel} />
          <PasswordInput
            className="rounded-md"
            id="confirm_password"
            type="password"
            autoComplete="off"
            hasError={fieldHasError(errors, 'confirmPassword')}
            {...register('confirmPassword')}
          />
          <HookFormErrorMessage
            name="confirmPassword"
            errors={errors}
            render={({ message }) => <ErrorMessage message={strapiErrorData[message]} />}
          />
        </FormFieldWrapper>
        {/* Form Actions */}
        <div className="flex gap-2">
          <Button
            type="submit"
            disabled={
              // When the mutation is in progress
              changePasswordMutation.isLoading
            }
            className="order-1 flex cursor-pointer items-center justify-center whitespace-nowrap bg-base-brand py-2 px-4 text-sm text-white disabled:cursor-not-allowed disabled:opacity-80"
          >
            {changePasswordMutation.isLoading ? (
              <div className="flex gap-2">
                <span> {updatePasswordRequestButton}</span>
                <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
              </div>
            ) : (
              updatePasswordButton
            )}
          </Button>
          <Button
            type="button"
            disabled={
              // When the mutation is in progress
              changePasswordMutation.isLoading
            }
            onClick={() => onReset()}
            className="order-2 flex cursor-pointer items-center justify-center bg-transparent px-4 py-2 text-sm text-zinc-400 disabled:cursor-not-allowed"
          >
            {resetButton}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
