import clsx from 'clsx';
import { Check } from 'phosphor-react';
import React from 'react';
import { Collapse } from 'react-daisyui';
import { setCurrentIndex } from '../../features/auth/slices/registration.slice';
import { useAppDispatch } from '../../hooks';
import useAppSelector from '../../hooks/use-app-selector';
import { RootState } from '../../store';
type Icons = 'plus' | 'arrow';
type Props = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
  className?: string;
  open?: boolean;
  icon?: Icons;
  title: string;
  collapseKey: number;
  children: React.ReactNode;
  showTick?: boolean;
};
export const CollapseComponent = ({
  className,
  icon = 'arrow',
  title,
  collapseKey,
  showTick,
  children,
}: Props) => {
  const dispatch = useAppDispatch();
  const registrationStateSelector = (state: RootState) => state.register;
  const { currentIndex, registration } = useAppSelector(registrationStateSelector);

  const changeCurrentIndex = (collapseKey: number) => {
    if (registration[currentIndex].errors.length === 0 && registration[collapseKey].touched) {
      dispatch(setCurrentIndex(collapseKey));
    }
  };
  return (
    <>
      {registration.length > 0 && (
        <Collapse
          open={collapseKey === currentIndex}
          icon={icon}
          className={`w-full flex-col md:flex
            ${className ?? ''}`}
        >
          <Collapse.Title
            onClick={() => changeCurrentIndex(collapseKey)}
            className={clsx(
              'my-2 cursor-pointer text-xl font-bold text-white',
              !registration[collapseKey].touched &&
                'pointer-events-none cursor-not-allowed opacity-25'
            )}
          >
            <div className="flex items-center space-x-2">
              {showTick && <Check size={30} color="#03ab38" weight="bold" />}
              <span>{title}</span>
            </div>
          </Collapse.Title>
          {registration[collapseKey].touched && <Collapse.Content>{children}</Collapse.Content>}
        </Collapse>
      )}
    </>
  );
};
