import { X } from 'phosphor-react';
import { useMemo } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import colors from 'tailwindcss/colors';
import { BasicSpinner } from '../../../../components/Spinners';
import { formatBytes, formatNumber } from '../../../../utils';
import useStrapiMembersData from '../../../members/hooks/useStrapiMembersData';

type Props = {
  file: File;
  // When we are doing things like fetching the presigned url
  isInitiating: boolean;
  // When the upload has started
  isUploading: boolean;
  cancelUpload: () => void;
} & (
  | {
      uploadProgress: never;
      showProgress: false;
    }
  | {
      // % of upload complete
      uploadProgress: number;
      // Whether to show how much the upload has progressed
      // or show a rotating loader while submitting
      showProgress: true;
    }
);

/**
 * Used to display the status of the bulk upload when it is in progress.
 */
const FileUploadInProgress = (props: Props) => {
  const { isInitiating, isUploading, uploadProgress, showProgress, file, cancelUpload } = props;

  const fileSizeFormatted = formatBytes({
    bytes: file.size,
  });

  const fileDisplayName = useMemo(() => {
    const fileNameLength = file.name.length;

    if (fileNameLength > 32) {
      return `${file.name.substring(0, 32)}...`;
    }

    return file.name;
  }, [file]);
  const { uploadingLabel, initiatingUploadLabel } = useStrapiMembersData().loadingMessages;
  return (
    <div className="flex w-full grow flex-col items-center justify-center gap-2 p-[12px_26px_16px] text-zinc-50">
      {isInitiating && (
        <>
          <BasicSpinner className="!m-0 text-zinc-50" />
          <span className="text-zinc-50">{initiatingUploadLabel}</span>
        </>
      )}
      {isUploading && showProgress === true && uploadProgress !== undefined && (
        <>
          <div className="flex w-full cursor-pointer justify-end" aria-label="Cancel Upload">
            <X onClick={cancelUpload} color="currentColor" className="text-zinc-50" />
          </div>
          <div className="aspect-square h-16 w-16">
            <CircularProgressbar
              value={uploadProgress}
              minValue={0}
              maxValue={100}
              text={`${formatNumber(uploadProgress)}%`}
              styles={buildStyles({
                pathColor: colors.blue['500'],
                textColor: colors.blue['500'],
                trailColor: colors.blue['50'],
              })}
            ></CircularProgressbar>
          </div>
          <span title={file.name}>
            {uploadingLabel + ' '}
            {fileDisplayName} ({fileSizeFormatted})
          </span>
        </>
      )}
      {isUploading && (showProgress === false || uploadProgress === undefined) && (
        <>
          <BasicSpinner />
          <span>{uploadingLabel + ' '}</span>
        </>
      )}
    </div>
  );
};

export default FileUploadInProgress;
