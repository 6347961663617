import { $isLinkNode } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection } from 'lexical';
import { LinkSimple } from 'phosphor-react';
import { useEffect, useState } from 'react';
import Popover from '../../../../../components/Popover';
import cn from '../../../../../utils/cn';
import { TOOLBAR_ICON_SIZE } from '../../../constants/rich-text';
import ToolbarActionButton from './ToolbarActionButton';
import ToolbarLinkEditPopoverContent from './ToolbarLinkEditPopoverContent';
import { getSelectedNode } from './utils';
import { useStrapiSharedData } from '../../../hooks/useStrapiSharedData';

type Props = {
  isEditable?: boolean;
};

const ToolbarLinkActionButton = (props: Props) => {
  const { isEditable = true } = props;

  // state
  const [hasSelection, setHasSelection] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const [isLink, setIsLink] = useState(false);
  const [linkArributes, setLinkAttributes] = useState<
    | undefined
    | {
        url: string;
      }
  >(undefined);
  // state

  // context
  const [editor] = useLexicalComposerContext();
  // context

  // strapi content
  const { RichTextEditor: richTextEditorCMS } = useStrapiSharedData();
  // strapi content

  // effects
  useEffect(() => {
    const cb = editor.registerUpdateListener(() => {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          setHasSelection(selection.focus.offset !== selection.anchor.offset);

          const selectedNode = getSelectedNode({ selection });
          const parentNode = selectedNode.getParent();

          if ($isLinkNode(parentNode)) {
            setIsLink(true);
            setLinkAttributes({
              url: parentNode.getURL(),
            });
          } else if ($isLinkNode(selectedNode)) {
            setIsLink(true);
            setLinkAttributes({
              url: selectedNode.getURL(),
            });
          } else {
            setIsLink(false);
          }
        }
      });
    });

    return () => cb();
  }, [editor]);
  // effects

  const onClick = () => {
    setShowPopover(true);
  };

  // Disable when there is no selection and it is not a link
  const isDisabled = !(hasSelection || isLink);

  return (
    <Popover
      Content={
        <ToolbarLinkEditPopoverContent
          isLink={isLink}
          linkAttributes={linkArributes}
          closePopover={() => {
            // clear link attributes and set show popover to false
            setLinkAttributes(undefined);
            setShowPopover(false);
          }}
        />
      }
      open={showPopover}
      onOpenChange={(o) => setShowPopover(o)}
      triggerAsChild={true}
      contentClassName="p-2 border border-zinc-700 bg-component-bg-dark"
      arrowClassName="text-zinc-700"
    >
      <ToolbarActionButton
        isActive={isLink}
        onClick={onClick}
        disabled={isDisabled || !isEditable}
        tooltipContent={richTextEditorCMS.linkLabel}
        aria-label={richTextEditorCMS.linkLabel}
      >
        <LinkSimple
          size={TOOLBAR_ICON_SIZE}
          color="currentColor"
          className={cn('', isLink && 'text-base-brand', isDisabled && 'text-gray-500')}
          weight="regular"
        />
      </ToolbarActionButton>
    </Popover>
  );
};

export default ToolbarLinkActionButton;
