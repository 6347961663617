import { useQuery } from '@tanstack/react-query';
import { getAllCategories } from '../api';
import { ENTERPRISE_ONBOARDING_TYPE, REQUESTS_STALE_TIME } from '../constants';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';
interface Props {
  type: keyof typeof ENTERPRISE_ONBOARDING_TYPE;
  language?: string;
}

const useCategoryQuery = ({ type, language = DEFAULT_PREFERRED_LANGUAGE }: Props) => {
  const { data, isError, error, isLoading, refetch, isRefetching, isFetching } = useQuery(
    [
      {
        scope: 'categories',
        item: 'get-categories',
        type,
        language,
      },
    ],
    async () => {
      return getAllCategories({ type, language });
    },
    {
      staleTime: REQUESTS_STALE_TIME,
    }
  );

  return { data, isError, error, isLoading, refetch, isRefetching, isFetching };
};

export default useCategoryQuery;
