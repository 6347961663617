import { ViewHeader } from '../../common';
import { ArrowRight } from 'phosphor-react';
import { BasicSpinner } from '../../../../../components/Spinners';
import { useNavigate, useParams } from 'react-router-dom';
import { useStrapiHQAdminCoursesData } from '../../../hooks/useStrapiHQAdminCourseData';

interface CoursePageHeaderProps {
  courseName: string;
  onclickHandler: any;
  isActive: boolean;
  isButtonLoading: boolean;
}

const PageHeader = ({
  courseName,
  onclickHandler,
  isActive,
  isButtonLoading,
}: CoursePageHeaderProps) => {
  const navigate = useNavigate();
  const { id: courseId } = useParams();

  const makeCourseLiveClickHandler = () => {
    onclickHandler();
  };

  const navigateToSingleCoursePage = () => {
    navigate(`/manage/courses/${courseId}`);
  };
  const {
    topNav: { makeCourseLiveButton },
  } = useStrapiHQAdminCoursesData();
  return (
    <ViewHeader
      buttonText={makeCourseLiveButton}
      heading={courseName}
      buttonIcon={
        isButtonLoading ? (
          <BasicSpinner className="ml-2 mr-1 h-3 w-3 text-white" />
        ) : (
          <ArrowRight size={12} />
        )
      }
      buttonIconBack={true}
      onButtonClickHandler={makeCourseLiveClickHandler}
      isBackVisible={true}
      hideButton={isActive}
      onBackClick={navigateToSingleCoursePage}
    />
  );
};

export default PageHeader;
