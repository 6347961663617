import { Button } from 'react-daisyui';
import { Link, Navigate } from 'react-router-dom';
import ActionResponse from '../../../../../components/ActionResponse';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { useIsFederatedUser, useReduxAuthState } from '../../../../../hooks';
import { USHG_HQ_APP_ROLE } from '../../../../../constants';
import { signOutUser } from '../../../helpers';
import { useEffect } from 'react';

const UserRegistrationSuccess = () => {
  const auth = useReduxAuthState();

  const isFederatedUser = useIsFederatedUser();

  // pull data from strapi helper
  const data = useStrapiDataHelper();
  const attributes = data?.registration.data.attributes;
  const {
    backToLogin,
    RegistrationSuccess: {
      title: registrationSuccessTitle,
      description: registrationSuccessDescription,
      paymentBadgeTitle: registrationSuccessPaymentBadgeTitle,
    },
  } = attributes;

  useEffect(() => {
    signOutUser({
      global: false,
    });
  }, []);

  if (isFederatedUser && auth.user?.role === USHG_HQ_APP_ROLE.INDIVIDUAL_USER) {
    return <Navigate to="/auth/register/progress" />;
  }

  return (
    <div className="flex w-full flex-col items-center">
      <ActionResponse
        className="max-w-md border border-base-bright-green/30 bg-zinc-900 shadow-md"
        title={registrationSuccessTitle}
        subtitle={
          <>
            {registrationSuccessDescription}
            {!auth.isAuthenticated && (
              <Link className="mt-8 flex justify-center" to="/auth/login">
                <Button className="cursor-pointer bg-base-brand py-2 px-14 text-center text-sm font-semibold normal-case text-white hover:bg-base-brand">
                  {backToLogin}
                </Button>
              </Link>
            )}
          </>
        }
        variant="success"
        badgeClassName="bg-emerald-900 text-[10px] text-emerald-100 font-bold justify-center"
        badgeText={registrationSuccessPaymentBadgeTitle}
        successVariantBackground="bg-emerald-900"
      />
    </div>
  );
};

export default UserRegistrationSuccess;
