import { useQuery } from '@tanstack/react-query';
import { REPORTS_STALE_TIME } from '../../shared/constants';
import { getMyCourses } from '../api';
import { MyCoursesFilter } from '../types';

interface Props {
  filters: MyCoursesFilter;
  item: string;
  isEnabled: boolean;
}

const useMyCoursesQuery = ({ filters, item, isEnabled }: Props) => {
  const query = useQuery(
    [
      {
        scope: 'courses',
        item,
        filters,
      },
    ],
    async ({ queryKey }) => {
      const { filters } = queryKey[0];
      return await getMyCourses(filters);
    },
    {
      staleTime: REPORTS_STALE_TIME,
      enabled: isEnabled,
    }
  );
  return query;
};

export default useMyCoursesQuery;
