export const HEALTH_RISK_STATUS = {
  UNHEALTHY: 'Unhealthy',
  HEALTHY: 'Healthy',
} as const;

// Filters
export const USHG_ADMIN_CUSTOMER_LEADERBOARD_DEFAULT_SORT_KEY = 'healthRank';
export const USHG_ADMIN_CUSTOMER_LEADERBOARD_LIMIT = 6;
export const USHG_ADMIN_CUSTOMER_LEADERBOARD_DEFAULT_SORT_ORDER = 'asc';
export const IN_PROGRESS_RECOMMENDED_COURSES_DEFAULT_OFFSET = 0;
export const IN_PROGRESS_RECOMMENDED_COURSES_DEFAULT_LIMIT = 1;

export const RECOMMENDATION_TYPE = {
  PERSONAL: 'PERSONAL',
  TEAM: 'TEAM',
};

export const RECOMMENDED_COURSE_LIMIT_DEFAULT = 5;

export const RECOMMENDED_COURSE_OFFSET_DEFAULT = 0;

export const LAZY_LOAD_DASHBOARD = false;
