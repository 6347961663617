import { Auth, API } from 'aws-amplify';
import { AmplifyAuthOptions, AmplifyAPIOptions } from '../../../types';

// get current cognito authenticated user from amplify
const getCurrentAuthenticatedUser = async (
  opts?: Parameters<typeof Auth.currentAuthenticatedUser>[0]
) => {
  const user = await Auth.currentAuthenticatedUser(opts);
  return user;
};

// get JwtToken from current session
const getAccessToken = async () => {
  const response = await Auth.currentSession();
  return response.getAccessToken();
};

// configure amplify auth
const configureAmplifyAuth = (config: AmplifyAuthOptions) => {
  return Auth.configure(config);
};

// configure amplify API
const configureAmplifyAPI = (config: AmplifyAPIOptions) => {
  return API.configure(config);
};

/**
 * Get AWS Credentials for the current user.
 */
const getAWSCredentials = async () => {
  const creds = await Auth.currentCredentials();
  return creds;
};

export {
  getCurrentAuthenticatedUser,
  configureAmplifyAuth,
  configureAmplifyAPI,
  getAccessToken,
  getAWSCredentials,
};
