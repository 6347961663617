import { GenericStatusCell } from '../../../../../../components/DataTables';
import { USER_STATUS } from '../../../../../members/constants';
import { useStrapiTeamData } from '../../../../hooks/useStrapiTeamData';

interface Props {
  status: keyof typeof USER_STATUS;
}

const StatusCell = (props: Props) => {
  const { status } = props;
  const {
    singleTeamTable: { singleTeamSortActive, singleTeamSortInvited, singleTeamSortDeactivated },
  } = useStrapiTeamData();
  switch (status) {
    case USER_STATUS.ACTIVE: {
      return (
        <GenericStatusCell
          display={singleTeamSortActive}
          containerClassName="bg-emerald-900 text-emerald-100"
          dotClassName="bg-emerald-100"
        />
      );
    }
    case USER_STATUS.DISABLED: {
      return (
        <GenericStatusCell
          display={singleTeamSortDeactivated}
          containerClassName="bg-zinc-900 text-zinc-100"
          dotClassName="bg-zinc-100"
        />
      );
    }
    case USER_STATUS.INVITED: {
      return (
        <GenericStatusCell
          display={singleTeamSortInvited}
          containerClassName="bg-amber-900 text-amber-100"
          dotClassName="bg-amber-100"
        />
      );
    }
  }
};

export default StatusCell;
