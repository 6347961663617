const CognitoException = {
  CodeMismatchException: 'CodeMismatchException',
  ExpiredCodeException: 'ExpiredCodeException',
  LimitExceededException: 'LimitExceededException',
  InvalidParameterException: 'InvalidParameterException',
  TooManyFailedAttemptsException: 'TooManyFailedAttemptsException',
  NotAuthorizedException: 'NotAuthorizedException',
} as const;

const CognitoTriggerCustomException = {
  INDIVIDUAL_ACCOUNT_WITH_EMAIL_EXIST_EXCEPTION: 'INDIVIDUAL_ACCOUNT_WITH_EMAIL_EXIST_EXCEPTION',
  ENTERPRISE_ACCOUNT_WITH_EMAIL_EXIST_EXCEPTION: 'ENTERPRISE_ACCOUNT_WITH_EMAIL_EXIST_EXCEPTION',
  USHG_ACCOUNT_WITH_EMAIL_EXIST_EXCEPTION: 'USHG_ACCOUNT_WITH_EMAIL_EXIST_EXCEPTION',
  ACCOUNT_WITH_EMAIL_EXIST_EXCEPTION: 'ACCOUNT_WITH_EMAIL_EXIST_EXCEPTION',
  ACCOUNT_WITH_EXTERNAL_PROVIDER_EXIST_EXCEPTION: 'ACCOUNT_WITH_EXTERNAL_PROVIDER_EXIST_EXCEPTION',
  REGISTRATION_IN_PROGRESS: 'REGISTRATION_IN_PROGRESS',
} as const;

const TenantMFAConfig = {
  OPTIONAL: 'OPTIONAL',
  REQUIRED: 'REQUIRED',
} as const;

const MFAMethod = {
  SMS_MFA: 'SMS_MFA',
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
  NOMFA: 'NOMFA',
} as const;

const CognitoUserAttributes = {
  PHONE_NUMBER: 'phone_number',
  ACCOUNT_PROVISION_STATUS: 'custom:user_provision_state',
} as const;

const CognitoAuthChallenge = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  SMS_MFA: 'SMS_MFA',
} as const;

const CognitoCustomClaim = {
  TENANT_MFA_CONFIG: 'custom:tenant_mfa_config',
} as const;

const CognitoProviderType = {
  SAML: 'SAML',
  Facebook: 'Facebook',
  Google: 'Google',
  LoginWithAmazon: 'LoginWithAmazon',
  SignInWithApple: 'SignInWithApple',
  OIDC: 'OIDC',
};

export {
  CognitoException,
  MFAMethod,
  CognitoUserAttributes,
  CognitoAuthChallenge,
  TenantMFAConfig,
  CognitoCustomClaim,
  CognitoTriggerCustomException,
  CognitoProviderType,
};
