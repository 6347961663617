import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { USHGAPIError } from '../../../types';
import { getModuleBasicDetails } from '../api';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';

interface Props {
  moduleId?: number;
  language?: string;
  isEnabled?: boolean;
}
/**
 * Wrapper around the react querys useQuery to fetch the assessment details.
 */
const useModuleBasicDetailsQuery = ({
  moduleId,
  language = DEFAULT_PREFERRED_LANGUAGE,
  isEnabled = true,
}: Props) => {
  const [errorType, setErrorType] = useState<string | null>();
  const { isLoading, data, isError, refetch, isRefetching, isFetching } = useQuery(
    [
      {
        moduleId: moduleId,
        language: language,
        scope: 'module',
        item: 'module-basic-details',
      },
    ],
    async ({ queryKey }) => {
      const { moduleId, language } = queryKey[0];
      if (moduleId) {
        return await getModuleBasicDetails({ moduleId, language });
      }
    },
    {
      enabled: isEnabled && moduleId !== undefined,
      onError: (error) => {
        // If axios error
        if (axios.isAxiosError(error)) {
          const data = error.response?.data;
          if (data) {
            const { errorCode } = data as USHGAPIError;
            setErrorType(errorCode);
          }
        }
      },
    }
  );
  return { isLoading, data, isError, refetch, isRefetching, isFetching, errorType, setErrorType };
};

export default useModuleBasicDetailsQuery;
