import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { useStrapiLoginMFAContent } from '../../../../../hooks';

type Props = {
  className?: string;
};

const SMSMFAHeader = (props: Props) => {
  const { className } = props;

  // strapi
  const loginMFACMS = useStrapiLoginMFAContent();
  // strapi

  return (
    <header className={twMerge(clsx('flex flex-col items-center gap-1 text-center', className))}>
      <h1 className="text-2xl font-extrabold leading-[2.1875rem] text-white">
        {loginMFACMS.Shared.heading}
      </h1>
      <p className="text-sm font-normal text-zinc-200">{loginMFACMS.SMSMFA.description}</p>
    </header>
  );
};

export default SMSMFAHeader;
