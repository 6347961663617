import clsx from 'clsx';
import React from 'react';
type CustomProps = {
  primaryLabel?: string;
  secondaryLabel?: string;
  onSwitch: (event: React.FormEvent<HTMLInputElement>) => void;
  className?: string;
  checked?: boolean;
};
export const SwitchInput = ({
  primaryLabel,
  secondaryLabel,
  onSwitch,
  checked,
  className,
}: CustomProps) => {
  return (
    <span className={clsx('flex items-center justify-center space-x-4', className)}>
      <span>{primaryLabel}</span>
      <label htmlFor="default-toggle" className="relative inline-flex cursor-pointer items-center">
        <input
          type="checkbox"
          value=""
          id="default-toggle"
          className="peer sr-only"
          onChange={onSwitch}
          checked={checked}
        />
        <div className="peer h-6 w-11 rounded-full bg-base-creme after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-black after:transition-all after:content-[''] peer-checked:bg-base-creme peer-checked:after:translate-x-full peer-checked:after:border-black peer-focus:outline-none"></div>
      </label>
      <span>{secondaryLabel}</span>
    </span>
  );
};
