import { LexicalNode, RangeSelection } from 'lexical';
import { $isAtNodeEnd } from '@lexical/selection';

const getSelectedNode = ({ selection }: { selection: RangeSelection }) => {
  const { focus, anchor } = selection;
  const focusNode = focus.getNode();
  const anchorNode = anchor.getNode();

  const backward = selection.isBackward();

  let selectedNode: LexicalNode;

  if (anchorNode === focusNode) {
    selectedNode = anchorNode;
  } else {
    if (backward) {
      selectedNode = $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
      selectedNode = $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
  }

  return selectedNode;
};

export { getSelectedNode };
