import clsx from 'clsx';
import { ArrowUp } from 'phosphor-react';
import { formatNumber } from '../../../../../utils';

interface GenericStatusCellProps {
  containerClassName: string;
  display: string;
  direction: string;
}

interface ArrowClassNameConfig {
  desc: string;
  inc: string;
  DEFAULT: string;
}

const ARROW_CLASS_NAME_CONFIG: ArrowClassNameConfig = {
  desc: 'transform rotate-180',
  inc: '',
  DEFAULT: 'hidden',
};

const GenericStatusCell = (props: GenericStatusCellProps) => {
  const { containerClassName, display, direction } = props;
  const arrowDirectionClassName: string =
    ARROW_CLASS_NAME_CONFIG[direction as keyof ArrowClassNameConfig] ||
    ARROW_CLASS_NAME_CONFIG.DEFAULT;

  return (
    <div
      className={clsx(
        'flex w-full items-center gap-[9px] rounded-[100px] py-[2px] pr-2 pl-1.5',
        containerClassName
      )}
    >
      <ArrowUp
        size={12}
        weight="bold"
        style={{ marginRight: -8 }}
        className={clsx(arrowDirectionClassName)}
      />

      <span className="space-x-0 text-sm font-normal">{display}</span>
    </div>
  );
};
interface ChurnRateProps {
  status?: string;
  data?: string | number;
}
export const ChurnRate = ({ status, data = 0 }: ChurnRateProps) => {
  switch (status) {
    case 'inc':
      return (
        <GenericStatusCell
          containerClassName="bg-emerald-900 text-amber-100"
          display={formatNumber(data) + '%'}
          direction={'inc'}
        />
      );
    case 'desc':
      return (
        <GenericStatusCell
          containerClassName="bg-amber-900 text-emerald-100"
          display={formatNumber(data) + '%'}
          direction={'desc'}
        />
      );
    default:
      return (
        <GenericStatusCell
          containerClassName="bg-zinc-900 text-zinc-100"
          display="No Data"
          direction={''}
        />
      );
  }
};
