import clsx from 'clsx';
import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchInput from '../../../../../../../components/SearchInput';
import { ComponentHeading } from '../../../../../../dashboard/components/common';
import { useStrapiSharedData } from '../../../../../../shared/hooks/useStrapiSharedData';
import { MODULE_TABLE_FILTER_QUERY_PARAM } from '../../../../../constants/course-module-table';
import { useStrapiHQAdminCoursesData } from '../../../../../hooks/useStrapiHQAdminCourseData';

interface Props {
  className?: string;
  filter: string | undefined;
  isFilterVisible: boolean;
}

const ModuleTableMainHeader = ({ className, filter, isFilterVisible }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const handleQChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    searchParams.set(MODULE_TABLE_FILTER_QUERY_PARAM, value);
    navigate('?' + searchParams.toString());
  };
  const { title } = useStrapiHQAdminCoursesData().singleCourseTableView.ModuleTable;
  const {
    tableProps: { searchPlaceHolder },
  } = useStrapiSharedData();
  return (
    <div className={clsx('w-full  text-zinc-100 ', className)}>
      <ComponentHeading heading={title} className="text-lg font-medium" />
      {isFilterVisible && (
        <div className="mt-2 flex flex-col justify-between gap-3 text-[14px] font-normal leading-[20px] md:flex-row md:gap-2">
          <div className="order-1 flex w-full flex-col gap-3 md:w-min md:flex-row md:gap-2">
            <SearchInput
              placeholder={searchPlaceHolder}
              containerClassName="min-w-[200px]"
              iconClassName="left-2"
              className="h-9 rounded-sm border border-zinc-900 bg-zinc-900 p-2 pl-9 text-[14px] font-normal leading-[20px] text-zinc-100 placeholder:text-zinc-500"
              value={filter ?? ''}
              onChange={handleQChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(ModuleTableMainHeader);
