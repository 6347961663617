import { useMemo } from 'react';
import { generateCDNMediaAssetURLWithKey } from '../../../../../../shared/helpers';
import { USHGAdminRequest } from '../../../../../types';
import { AvatarAndNameCell } from '../../../../common';

interface Props {
  request: USHGAdminRequest;
}

const RaisedByCell = ({ request }: Props) => {
  const name = request.createdByName;

  const profilePictureURL = useMemo(() => {
    const pictureUrl = request.createdByPictureUrl;
    if (!pictureUrl) {
      return undefined;
    }

    const cdnProfilePictureUrl = generateCDNMediaAssetURLWithKey({ key: pictureUrl });
    return cdnProfilePictureUrl;
  }, [request.createdByPictureUrl]);

  return <AvatarAndNameCell name={name} profilePictureURL={profilePictureURL} />;
};

export default RaisedByCell;
