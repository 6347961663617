import patternSrc from '../../assets/brand_logo_pattern.png';

type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const PatternIllustrator = (props: Props) => {
  return (
    <img
      src={patternSrc}
      alt=""
      className="invisible absolute -right-8 -bottom-8 h-[calc(100vh-500px)] md:visible"
      {...props}
    />
  );
};

export default PatternIllustrator;
