import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Dialog from '../../../../../components/Dialog';
import { Label, TextInput } from '../../../../../components/FormElements';
import { BasicSpinner } from '../../../../../components/Spinners';
import { USHG_HQ_APP_ROLE } from '../../../../../constants';
import { useAppSelector, useReduxAuthState } from '../../../../../hooks';
import { RootState } from '../../../../../store';
import { fieldHasError } from '../../../../../utils/react-hook-form';
import HasAccess from '../../../../shared/components/HasAccess';
import { REQUEST_TYPE } from '../../../constants/role';
import { SECTIONS } from '../../../constants/section-name';
import { useEditTeamsAPI } from '../../../hooks/useEditTeamAPI';
import { useStrapiTeamData } from '../../../hooks/useStrapiTeamData';
import { useSendTeamsRequest } from '../../../hooks/useTeamsRequest';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { queryClient } from '../../../../../config/react-query';
import { checkTeamExist } from '../../../../auth/helpers/register';
import { debounce } from '../../../../../utils';
import ModalErrorAlert from '../ModalAlert/ModalErrorAlert';
import { TeamNameStatusCheck } from '../../../types/interface';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';

// Yup Schema

function getSchema(teamSchemaData: {
  teamNameRequired: string;
  teamNameMinError: string;
  teamNameMaxError: string;
  teamNameMinValue: number;
  teamNameMaxValue: number;
}) {
  const {
    teamNameRequired,
    teamNameMinError,
    teamNameMaxError,
    teamNameMinValue,
    teamNameMaxValue,
  } = teamSchemaData;
  const editTeamFormSchema = yup.object({
    teamName: yup
      .string()
      .trim()
      .required(teamNameRequired)
      .min(teamNameMinValue, teamNameMinError)
      .max(teamNameMaxValue, teamNameMaxError),
    teamAlreadyExist: yup.string(),
  });
  return editTeamFormSchema;
}
const teamStateSelector = (state: RootState) => state.team;
// Yup Schema
interface Props {
  teamId: number;
  setIsModalOpen: (open: boolean) => void;
  teamName?: string;
}

function EditTeamModal({ teamId, setIsModalOpen, teamName }: Props): JSX.Element {
  // strapi content
  const { editTeam, commomTeamErrorMessage } = useStrapiTeamData();
  const {
    updateTeamButton,
    updateTeamRequestButton,
    editTeamCancelButton,
    editTeamNameLabel,
    editTeamNamePlaceHolder,
  } = editTeam;
  const {
    teamAlreadyExists,
    teamNameRequired,
    teamNameMinError,
    teamNameMaxError,
    teamNameMinValue,
    teamNameMaxValue,
  } = commomTeamErrorMessage;
  const teamSchemaData = {
    teamNameRequired,
    teamNameMinError,
    teamNameMaxError,
    teamNameMinValue,
    teamNameMaxValue,
  };
  const editTeamFormSchema = getSchema(teamSchemaData);
  type EDIT_TEAM_FORM_DATA = yup.InferType<typeof editTeamFormSchema>;
  const {
    register,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isValid, isSubmitting, isSubmitted, errors },
  } = useForm<EDIT_TEAM_FORM_DATA>({
    resolver: yupResolver(editTeamFormSchema),
    defaultValues: {
      teamName: '',
      teamAlreadyExist: '',
    },
  });
  const { selectedTeam } = useAppSelector(teamStateSelector);
  const auth = useReduxAuthState();

  const parentId = teamId ?? selectedTeam.teamId;
  const toggleVisible = () => {
    setIsModalOpen(false);
    setValue('teamName', '');
  };
  const { editTeams } = useEditTeamsAPI();
  const { sendTeamsRequest } = useSendTeamsRequest();
  const [loading, setLoading] = useState(false);
  // Admin Team Edit
  const onSubmit = async (data: EDIT_TEAM_FORM_DATA) => {
    const { teamName } = data;

    const isteamexist = await TeamexistValidationCheck(teamName);
    if(isteamexist){
      console.log("Team Name already exists");
      return;
    }

    try {
      await editTeams(teamName, parentId as number, SECTIONS.ADMIN_TEAMS_LIST_TABLE);
      toggleVisible();
    } catch (error) {
      console.error('Error editing team name', error);
    }
  };

  // Edit Team Request
  const onRequest = async (data: EDIT_TEAM_FORM_DATA) => {
    const { teamName } = data;
    const isteamexist = await TeamexistValidationCheck(teamName);
    if(isteamexist){
      console.log("Team Name already exists");
      return;
    }
    const teamData = {
      teamName: teamName,
      requestType: REQUEST_TYPE.EDIT_TEAM,
      teamId: parentId,
      isEditTeamRequest: true,
    };
    try {
      await sendTeamsRequest(teamData, SECTIONS.ADMIN_TEAMS_LIST_TABLE);
      toggleVisible();
    } catch (error) {
      console.log(error);
    }
  };

  const formSubmitHandler = async (data: EDIT_TEAM_FORM_DATA) => {
    if (!auth.isAuthenticated) {
      return;
    }

    // Enterprise Admin would directly modify the team
    if ([USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN as string].includes(auth.user.role)) {
      await onSubmit(data);
    }

    // Enterprise Leader and Enterprise Manager would raise a request
    if (
      [
        USHG_HQ_APP_ROLE.ENTERPRISE_LEADER as string,
        USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER as string,
      ].includes(auth.user.role)
    ) {
      await onRequest(data);
    }

    return;
  };

  const [teamNameCheckStatus, setTeamNameCheckStatus] = useState<TeamNameStatusCheck>('IDLE');
  const TeamexistValidationCheck = async (value: any) => {
      try {
        if (value.trim().length !== 0) {
          setTeamNameCheckStatus('INPROGRESS');
          const response = await queryClient.fetchQuery({
            queryFn: async () => {
              return await checkTeamExist(value && value.trim(), teamId);
            },
            queryKey: ['team_exists', value, teamId],
            staleTime: 60000,
          });
          if (response.teamNameExistsAlready) {
            setError('teamAlreadyExist', {
              type: 'manual',
              message: teamAlreadyExists,
            });
            setTeamNameCheckStatus('COMPLETED');
            return true;
          } else {
            clearErrors('teamAlreadyExist');
            setTeamNameCheckStatus('COMPLETED');
            return false;
          }
         
        } else {
          setTeamNameCheckStatus('COMPLETED');
          clearErrors('teamAlreadyExist');
        }
      } catch (error) {
        console.log(error);
      }finally {
        setLoading(false);
    }
    
  }
  const debouncedTeamValidate = useMemo(() => {
    const _teamValidate = async (value: string) => {
      try {
        if (value.trim().length !== 0) {
          setTeamNameCheckStatus('INPROGRESS');
          const response = await queryClient.fetchQuery({
            queryFn: async () => {
              return await checkTeamExist(value && value.trim(), teamId);
            },
            queryKey: ['team_exists', value, teamId],
            staleTime: 60000,
          });
          if (response.teamNameExistsAlready) {
            setError('teamAlreadyExist', {
              type: 'manual',
              message: teamAlreadyExists,
            });
          } else {
            clearErrors('teamAlreadyExist');
          }
          setTeamNameCheckStatus('COMPLETED');
        } else {
          clearErrors('teamAlreadyExist');
        }
      } catch (error) {
        console.log(error);
      }finally {
        setLoading(false);
    }
    };

    return debounce(_teamValidate, 1000)[0];
  }, [setError, clearErrors]);

  const eventHandler = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      await debouncedTeamValidate(event.target.value);
    },
    [debouncedTeamValidate]
  );

  return (
    <form
      onSubmit={handleSubmit(formSubmitHandler)}
      name="form"
      className="flex w-full flex-col gap-6"
    >
      <div className="flex w-full flex-col gap-2">
        <Label label={editTeamNameLabel} htmlFor="edit_team_name"></Label>
        <TextInput
          className="text-zinc-50"
          id="edit_team_name"
          type="text"
          placeholder={editTeamNamePlaceHolder}
          defaultValue={teamName}
          aria-invalid={fieldHasError(errors, 'teamName') ? 'true' : 'false'}
          hasError={fieldHasError(errors, 'teamName')}
          {...register('teamName', {
            onChange: eventHandler,
          })}
        />
        <HookFormErrorMessage
          name="teamName"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
        {teamNameCheckStatus === 'INPROGRESS' ? (
          <div className="absolute top-6 right-4">
            <BasicSpinner className="leading-[14px] text-zinc-100" />
          </div>
        ) : null}
      </div>
      <div className="">
        <div className="mt-2 justify-self-center">
          <ModalErrorAlert errors={errors} index={0} />
        </div>
      </div>
      <div className="flex justify-end">
        <Dialog.Close className="px-7 text-zinc-50">{editTeamCancelButton}</Dialog.Close>
        <button
          type="submit"
          disabled={
            isSubmitting ||
            (!isValid && isSubmitted) ||
            teamNameCheckStatus === 'IDLE' ||
            teamNameCheckStatus === 'INPROGRESS' ||
            loading ||
            (errors?.teamAlreadyExist && errors.teamAlreadyExist.type === 'manual')
          }
          className="inline-block justify-end !rounded bg-base-brand px-7 py-2.5 text-sm font-medium normal-case leading-tight text-white hover:bg-base-brand disabled:opacity-70"
        >
          {isSubmitting ? (
            <div className="flex items-center gap-2">
              <>
                <HasAccess
                  roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEADER, USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER]}
                  Fallback={null}
                >
                  {updateTeamRequestButton}
                </HasAccess>
                <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]} Fallback={null}>
                  {updateTeamButton}
                </HasAccess>
              </>
              <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
            </div>
          ) : (
            <>
              <HasAccess
                roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEADER, USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER]}
                Fallback={null}
              >
                {updateTeamRequestButton}
              </HasAccess>
              <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]} Fallback={null}>
                {updateTeamButton}
              </HasAccess>
            </>
          )}
        </button>
      </div>
    </form>
  );
}

export default EditTeamModal;
