import { Star, Clock, Notebook } from 'phosphor-react';
import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';
import { formatDueDate } from '../../utils/utils';
import DurationBadge from '../../../../../../utils/DurationBadge';
export interface ReviewComponentProps {
  rating: string;
  reviewsCount: number;
  duration: string;
  modules: string;
  dueDate?: string;
}
const ReviewComponent = ({
  rating,
  reviewsCount,
  duration,
  modules,
  dueDate,
}: ReviewComponentProps) => {
  const { courseInfo } = useStrapiCoursesData();
  const { dueLabel, reviewLabel, moduleLabel, noRatingsYetLabel } = courseInfo;
  return (
    <div className="flex flex-col flex-wrap gap-4 sm:flex-row ">
      {/* Rating Section*/}
      <div className="flex flex-row items-center gap-2">
        <Star color="#FF9758" weight="fill" fill="#FF9758" size={'24px'} />
        <p className="text-sm">
          {rating === '0' ? noRatingsYetLabel : rating || '0.0'} ({reviewsCount || '0'}{' '}
          {reviewLabel})
        </p>
      </div>
      {/* Duration Section */}
      {duration && (
        <div className="flex flex-row items-center gap-2">
          <Clock color="#71717A" size={'24px'} />
          <p className="text-sm">
            <DurationBadge hasDuration={true} durationInMin={parseInt(duration)} />
          </p>
        </div>
      )}
      {/* Modules Section */}
      <div className="flex flex-row items-center gap-2">
        <Notebook color="#71717A" size={'24px'} />
        <p className="text-sm">
          {modules || '0'} {moduleLabel}
        </p>
      </div>
      {/* Due Date Section */}
      {dueDate && (
        <div className="flex flex-row items-center gap-2">
          <Clock color="#FF9758" size={'24px'} />
          <p className="text-sm text-base-tangerine">
            {dueLabel} {formatDueDate(dueDate)}
          </p>
        </div>
      )}
    </div>
  );
};

export default ReviewComponent;
