import { EnterpriseLeaderTeam, PartialEnterpriseLeaderTeamsFilter } from '../types/interface';
import qs from 'qs';
import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { LEADER_TEAMS_API_PATHS } from '../constants/api';

const getEnterpriseLeaderTeams = async (
  filters: PartialEnterpriseLeaderTeamsFilter
): Promise<{
  rows: EnterpriseLeaderTeam[];
  count: number;
}> => {
  const queryString = qs.stringify(filters);
  const path = LEADER_TEAMS_API_PATHS.ENTERPRISE_LEADER.GET_ALL_LEADER_TEAMS;
  try {
    const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
      response: true,
      withCredentials: true,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export { getEnterpriseLeaderTeams };
