/**
 * Helper function to get accessToken from the current session
 */

import { getAccessToken } from '../../auth/utils';

const getAccessTokenHelper = async () => {
  const result = await getAccessToken();
  return result.getJwtToken();
};

export { getAccessTokenHelper };
