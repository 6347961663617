import { useMutation } from '@tanstack/react-query';
import { PublishCourseType } from '../types';
import { publishCourse } from '../api';
import toast from 'react-hot-toast';
import Toast from '../../../components/Toast/Toast';
import axios from 'axios';
import { ERROR_TYPES } from '../../../constants/error-types.constants';
import { USHGAPIError } from '../../../types';
import { useStrapiHQAdminCoursesData } from './useStrapiHQAdminCourseData';

const usePublishCourseMutation = () => {
  const {
    toastMessages: { publishCourseError, courseCreationFailedDueToNoQuestions },
  } = useStrapiHQAdminCoursesData();
  const { data, error, isError, isLoading, mutate } = useMutation({
    mutationFn: (data: PublishCourseType) => publishCourse(data),
    onError: (error) => {
      console.error('Error in publishing course', error);
      let toastSubTitle = ERROR_TYPES.SOMETHING_WENT_WRONG;
      if (axios.isAxiosError(error)) {
        const data = error.response?.data;
        if (data) {
          const { errorCode } = data as USHGAPIError;
          if (errorCode === 'QUESTION_NOT_FOUND') {
            toastSubTitle = courseCreationFailedDueToNoQuestions;
          }
        }
      }
      toast.custom((t) => (
        <Toast
          variant="error"
          Title={publishCourseError}
          SubTitle={toastSubTitle}
          toastInstance={t}
        />
      ));
    },
  });
  return { data, error, isError, isLoading, mutate };
};

export default usePublishCourseMutation;
