import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Button } from '../../../../../components/Buttons';
import { Label, PasswordInput, TextInput } from '../../../../../components/FormElements';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
import { BasicSpinner } from '../../../../../components/Spinners';
import { USHGAPIError } from '../../../../../types';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { signInUser } from '../../../helpers';
import { LoginExceptionCode } from '../../../types';
import LoginExceptionAlert from './LoginExceptionAlert/LoginExceptionAlert';
import { API_RESPONSE_CODES } from '../../../constants/errorCode';
import {
  clearCognitoHostedUIError,
  setCognitoUser as setCognitoUserAction,
} from '../../../slices/auth.slice';
import { useAppDispatch } from '../../../../../hooks';
import { CognitoAuthChallenge } from '../../../../../constants';
import { CognitoUser } from '@aws-amplify/auth';
import {
  cognitoUserContext,
  loginAuthChallengeContext,
  userLoginCredentialsContext,
} from '../../../context';

// Login Form Schema section starts

const loginSchema = yup.object({
  email: yup
    .string()
    .trim()
    .lowercase()
    .email('validEmail')
    .required('emailRequired')
    .label('Email'),
  password: yup
    .string()
    .trim()
    .min(8, 'passwordMin')
    .required('passwordRequired')
    .label('Password'),
  rememberMe: yup.boolean().default(true).required(),
});
type LoginFormData = yup.InferType<typeof loginSchema>;

// Login Form Schema section ends

interface Props
  extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  onLoginSuccess?: () => void;
}

const UserLoginForm = ({ className, onLoginSuccess, ...otherProps }: Props) => {
  const dispatch = useAppDispatch();
  const data: any = useStrapiDataHelper();
  const strapiLoginData = data?.login.data?.attributes;
  const { loginButton, emailLabel, passwordLabel } = strapiLoginData || {};
  const loginErrordata: any = data?.errormessage?.data?.attributes;

  // context
  const { setLoginCredentials } = useContext(userLoginCredentialsContext);
  const { setCognitoUser } = useContext(cognitoUserContext);
  const { setAuthChallenge } = useContext(loginAuthChallengeContext);
  // context

  const {
    register,
    getValues,
    handleSubmit,
    formState: { isSubmitting, errors, isValid, isSubmitted },
  } = useForm<LoginFormData>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
      rememberMe: true,
    },
  });

  const emailValue = getValues('email');
  // React Router hooks
  const navigate = useNavigate();
  // Login Exception State
  const [loginExceptionCode, setLoginExceptionCode] = useState<LoginExceptionCode | null>(null);
  // Handle user login form submit
  const formSubmitHandler: SubmitHandler<LoginFormData> = async (data) => {
    try {
      // clear if login exception was set before
      setLoginExceptionCode(null);
      dispatch(clearCognitoHostedUIError());

      const { email: username, password, rememberMe } = data;
      const signInUserArgs = {
        username,
        password,
        rememberMe,
      };
      const response: CognitoUser = await signInUser(signInUserArgs);

      const challengeName = response.challengeName;

      // If the challenge is NEW_PASSWORD_REQUIRED, redirect user to set password page
      if (response.challengeName === API_RESPONSE_CODES.NEW_PASSWORD_REQUIRED) {
        dispatch(setCognitoUserAction(response));
        navigate('/invite/set-password');
        return;
      }

      // Handle SMS_MFA for now
      // If there are additional challenges that we can handle
      // we can add a fallthrough case statement
      switch (challengeName) {
        case CognitoAuthChallenge.SMS_MFA: {
          setCognitoUser(response);
          setAuthChallenge(challengeName);
          setLoginCredentials({
            username: data.email,
            password: data.password,
          });
          return;
        }
      }

      // If there is a callback passed call that
      onLoginSuccess && onLoginSuccess();
    } catch (error) {
      // Default is unhandled error we could modify it based on error
      // We could display the generic error if it not known error
      let loginExceptionCode: LoginExceptionCode = 'UnHandledLoginError';

      // If this is axios error
      // one situation is when we make call to backend to
      // get the tenant cognito details It might return an error response
      if (axios.isAxiosError(error)) {
        const data = error.response?.data;
        if (data) {
          // USHG API Error
          const { errorCode } = data as USHGAPIError;

          switch (errorCode) {
            case 'USER_NOT_FOUND': {
              // We would display the unauthorized exception
              // for user not found situations
              loginExceptionCode = 'NotAuthorizedException';
              break;
            }
          }
        }
      } else {
        // AuthErrors from amplify would have code and message
        const { code } = error as { code: string; message: string };
        if (code) {
          switch (code) {
            case 'UserNotConfirmedException':
            case 'NotAuthorizedException': {
              loginExceptionCode = code;
              break;
            }
          }
        }
      }

      setLoginExceptionCode(loginExceptionCode);
    }
    // for react-hook-form isSubmitting boolean value
    return;
  };

  return (
    <form
      onSubmit={handleSubmit(formSubmitHandler)}
      className={clsx(
        'flex w-full max-w-md flex-col gap-5',
        className,
        loginExceptionCode && 'pt-0'
      )}
      {...otherProps}
    >
      {loginExceptionCode && (
        <LoginExceptionAlert code={loginExceptionCode} username={emailValue} />
      )}
      <div className="flex flex-col gap-2">
        <Label htmlFor="email_input" label={emailLabel} isRequired={true} />
        <TextInput
          className="rounded-md"
          id="email_input"
          type="text"
          autoComplete="username"
          hasError={errors.email?.message ? true : false}
          {...register('email')}
        />
        {errors.email?.message && <ErrorMessage message={loginErrordata[errors.email.message]} />}
      </div>
      <div className="flex flex-col gap-2">
        <Label htmlFor="password_input" label={passwordLabel} isRequired={true} />
        <PasswordInput
          className="rounded-md"
          id="password_input"
          type="password"
          autoComplete="current-password"
          hasError={errors.password?.message ? true : false}
          {...register('password')}
        />
        {errors.password?.message && (
          <ErrorMessage message={loginErrordata[errors.password.message]} />
        )}
      </div>
      <Button
        type="submit"
        // Disable when we're submitting or when the form is submitted once
        // and is not valid
        disabled={isSubmitting || (!isValid && isSubmitted)}
        aria-hidden={isSubmitting || (!isValid && isSubmitted)} // Hide from screen readers when disabled
        tabIndex={isSubmitting || (!isValid && isSubmitted) ? -1 : 0} // Remove from tab order when disabled
        className={clsx(
          'my-3.5 flex cursor-pointer items-center justify-center bg-base-brand text-sm font-semibold leading-[25px] text-white',
          [(isSubmitting || (!isValid && isSubmitted)) && 'opacity-80']
        )}
      >
        {isSubmitting ? <BasicSpinner className="h-[25px] w-[25px]" /> : loginButton}
      </Button>
    </form>
  );
};

export default UserLoginForm;
