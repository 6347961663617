import { EnterpriseLeaderTeam } from '../../types/interface';
import SubTeamCard from './SubTeamCard';

type SubTeamCardWrapperProps = {
  subteams?: EnterpriseLeaderTeam[];
};
function SubTeamCardWrapper({ subteams }: SubTeamCardWrapperProps) {
  // For each subteams render below card
  if (subteams) {
    return (
      <div className="grid w-full grid-cols-1 gap-3 lg:grid-cols-2 lg:gap-5">
        {subteams.map((team) => (
          <SubTeamCard key={team.teamId} team={team} />
        ))}
      </div>
    );
  }
  return <></>;
}

export default SubTeamCardWrapper;
