import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import ModuleAssessmentRowItem from './ModuleAssessmentRowItem';
import React from 'react';
import { ModuleAssessmentRowItemType } from '../../../../types/courses';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
type ModuleAssessmentListProps = {
  modulesAssessments: ModuleAssessmentRowItemType[];
  setModulesAssessments: any;
  isSaving: boolean;
  refetch?: any;
};
const ModuleAssessmentList = ({
  modulesAssessments,
  setModulesAssessments,
  isSaving,
}: ModuleAssessmentListProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const handleDrop = (event: any) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const activeIndex = modulesAssessments.findIndex(({ id }) => id === active.id);
      const overIndex = modulesAssessments.findIndex(({ id }) => id === over.id);
      setModulesAssessments(
        arrayMove(modulesAssessments, activeIndex, overIndex).map((item, index) => {
          item.data.sort = index + 1;
          return item;
        })
      );
    }
  };

  return (
    <div className="my-4">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDrop}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext
          items={modulesAssessments}
          disabled={isSaving}
          strategy={verticalListSortingStrategy}
        >
          {modulesAssessments.map((item) => (
            <ModuleAssessmentRowItem key={item.data.id} item={item} />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default ModuleAssessmentList;
