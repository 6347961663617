import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

const useStrapiBadgesData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const strapiBadgesCommon = data.badge.data.attributes.COMMON;
  const strapiBadgesHQAdmin = data.badge.data.attributes.HQ_ADMIN;
  let singleCourse;

  let myBadges;
  let dashBoard;
  let singleBadge;
  let errorMessages;
  for (let i = 0; i <= strapiBadgesCommon?.length; i++) {
    if (strapiBadgesCommon[i]?.__typename === 'ComponentBadgesSingleCourse') {
      singleCourse = (strapiBadgesCommon ?? [])[i];
    } else if (strapiBadgesCommon[i]?.__typename === 'ComponentBadgesMyBadges') {
      myBadges = (strapiBadgesCommon ?? [])[i];
    } else if (strapiBadgesCommon[i]?.__typename === 'ComponentBadgesSingleBadges') {
      singleBadge = (strapiBadgesCommon ?? [])[i];
    } else if (strapiBadgesCommon[i]?.__typename === 'ComponentBadgesDashBoard') {
      dashBoard = (strapiBadgesCommon ?? [])[i];
    } else if (strapiBadgesCommon[i]?.__typename === 'ComponentBadgesErrorMessages') {
      errorMessages = (strapiBadgesCommon ?? [])[i];
    }
  }

  let table;
  let modal;
  let deleteBadge;
  let hqTopNav;
  let hqError;
  let hqToast;
  for (let i = 0; i <= strapiBadgesHQAdmin?.length; i++) {
    if (strapiBadgesHQAdmin[i]?.__typename === 'ComponentBadgesBadgesTable') {
      table = (strapiBadgesHQAdmin ?? [])[i];
    } else if (strapiBadgesHQAdmin[i]?.__typename === 'ComponentBadgesModal') {
      modal = (strapiBadgesHQAdmin ?? [])[i];
    } else if (strapiBadgesHQAdmin[i]?.__typename === 'ComponentBadgesDeleteBadge') {
      deleteBadge = (strapiBadgesHQAdmin ?? [])[i];
    } else if (strapiBadgesHQAdmin[i]?.__typename === 'ComponentBadgesHqTopNav') {
      hqTopNav = (strapiBadgesHQAdmin ?? [])[i];
    } else if (strapiBadgesHQAdmin[i]?.__typename === 'ComponentBadgesErrorMessage') {
      hqError = (strapiBadgesHQAdmin ?? [])[i];
    } else if (strapiBadgesHQAdmin[i]?.__typename === 'ComponentBadgesToastMessage') {
      hqToast = (strapiBadgesHQAdmin ?? [])[i];
    }
  }

  return {
    hqError,
    hqToast,
    hqTopNav,
    deleteBadge,
    table,
    modal,
    dashBoard,
    singleBadge,
    singleCourse,
    myBadges,
    errorMessages,
  };
};

export default useStrapiBadgesData;
