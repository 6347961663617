import useGetAllTeamsQuery from '../../../hooks/useGetAllTeamsQuery';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import performanceTeamFilterContext from '../../../../shared/context/performanceTeamFilterContext';
import { useStrapiReportsData } from '../../../../shared/hooks/useStrapiReportData';
import { customStylesTwo } from '../../../../../styles/modalCustomStyle';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';

const TeamFilter = () => {
  // strapi
  const { TeamSelectionDropDown } = useStrapiReportsData().topNav;
  const { dropDownLabel, teamFilterDropdownPlaceholder, globalScopeLabel } = TeamSelectionDropDown;

  const {
    dropdownPlaceholderMessages: { loadingMessage, noOptionsMessage },
  } = useStrapiSharedData();
  // strapi

  // team performance
  const { data, isLoading } = useGetAllTeamsQuery({ includeSubTeams: false });
  const [teams, setTeams] = useState<{ label: string; value: string }[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<{ label: string; value: string }>();
  const { teamFilter, setTeamFilter } = useContext(performanceTeamFilterContext);

  const PERFORMANCE_DEFAULT_TEAM = useMemo(
    () => ({ label: globalScopeLabel, value: '1' }),
    [globalScopeLabel]
  );

  useEffect(() => {
    if (data) {
      setTeams([PERFORMANCE_DEFAULT_TEAM, ...data]);
    }
  }, [data, PERFORMANCE_DEFAULT_TEAM]);

  useEffect(() => {
    if (teamFilter) {
      setSelectedTeam(teams.find((data: any) => data.value === teamFilter));
    } else {
      setSelectedTeam(PERFORMANCE_DEFAULT_TEAM);
    }
  }, [teams, PERFORMANCE_DEFAULT_TEAM, teamFilter]);

  const handleTeamFilterChange = (data: any) => {
    setSelectedTeam(data);
    setTeamFilter(data.value);
  };

  return (
    <>
      <label htmlFor="team_id" className="visually-hidden">
        {dropDownLabel}
      </label>
      <Select
        inputId="team_id"
        loadingMessage={() => loadingMessage}
        noOptionsMessage={() => noOptionsMessage}
        options={teams}
        placeholder={teamFilterDropdownPlaceholder}
        isLoading={isLoading}
        styles={customStylesTwo}
        isSearchable={true}
        onChange={handleTeamFilterChange}
        value={selectedTeam}
      />
    </>
  );
};

export default TeamFilter;
