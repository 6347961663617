import { useMutation } from '@tanstack/react-query';
import { updateCourseDetails } from '../api';
import { CourseUpdateMutationType } from '../types';

const useCourseUpdateMutation = () => {
  const { data, error, isLoading, isError, mutate } = useMutation({
    mutationFn: (data: { id: number; update: CourseUpdateMutationType }) =>
      updateCourseDetails(data.id, data.update),
  });
  return { data, error, isLoading, isError, mutate };
};

export default useCourseUpdateMutation;
