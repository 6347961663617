import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { UserPlus } from 'phosphor-react';
import { toggleInviteFriendModal } from '../../../features/shared/slices/inviteFriend.slice';
import { useAppDispatch } from '../../../hooks';
import { useStrapiSideNavData } from '../../../hooks/useStrapiSideNavData';

function SideBarProfileInviteMember() {
  const dispatch = useAppDispatch();
  const toggleModal = () => {
    dispatch(toggleInviteFriendModal());
  };
  const { inviteFriendsLabel } = useStrapiSideNavData().buttons;
  return (
    <>
      <DropdownMenu.Item
        className="pointer-events-auto cursor-pointer rounded-md py-[9px] px-[10px] hover:focus:bg-zinc-700"
        textValue="invite"
      >
        <div className="flex h-full w-full flex-row items-center justify-between ">
          <div className="flex items-center gap-2 text-zinc-50" onClick={toggleModal}>
            <UserPlus size={16} className="text-inherit" color="currentColor" />
            <span className="text-sm font-medium text-inherit">{inviteFriendsLabel}</span>
          </div>
        </div>
      </DropdownMenu.Item>
    </>
  );
}

export default SideBarProfileInviteMember;
