import { useMutation } from '@tanstack/react-query';
import { updateOrderRequest } from '../api/coursesManagement';
import { UpdateOrderType } from '../types';

const useUpdateModuleOrderMutation = () => {
  const { data, error, isLoading, isError, mutate } = useMutation({
    mutationFn: (update: UpdateOrderType) => updateOrderRequest(update),
  });

  return { data, error, isLoading, isError, mutate };
};

export default useUpdateModuleOrderMutation;
