import { format } from 'date-fns';
import { getLocale } from '../../../../../../utils';
import { useLanguage } from '../../../../../shared/context/languageContext';

interface Props {
  addedOn: string;
}

const AddedOn = ({ addedOn }: Props) => {
  const { language } = useLanguage();
  return (
    <span className="whitespace-nowrap">
      {format(new Date(addedOn), 'EEE PPpp', { locale: getLocale(language) })}
    </span>
  );
};

export default AddedOn;
