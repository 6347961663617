import { createBrowserRouter, Navigate } from 'react-router-dom';
import App from '../App';
import { LandingNotFound } from '../components/404Pages/LandingNotFound';
import AuthRoutes from '../features/auth/routes';
import { CoursesRoutes } from '../features/courses';
import { DashboardRoutes } from '../features/dashboard';
import ChatRoutes from '../features/messaging/routes';
import { NotificationPageRoutes } from '../features/notifications';
import OnboardingRoutes from '../features/onboarding/routes';
import { MembersRoutes } from '../features/members';
import ReportPageRoutes from '../features/reports/routes';
import { RequestsPageRoutes } from '../features/requests';
import { SettingsPageRoutes } from '../features/settings';
import { TeamsRoutes } from '../features/teams';
import { PrivateRoute, SideBarBaseLayout } from '../layouts';
import MgmtRoutes from './AdminRoutes';
import { BadgesRoutes } from '../features/badges';
import { routes as LearningJourneysRoutes } from '../features/learning-journeys/routes';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        element: <Navigate to="/dashboard" />,
        index: true,
      },
      {
        element: <PrivateRoute />,
        children: [
          {
            element: <SideBarBaseLayout />,
            children: [
              ...DashboardRoutes,
              ...TeamsRoutes,
              ...CoursesRoutes,
              ...RequestsPageRoutes,
              ...SettingsPageRoutes,
              ...MgmtRoutes,
              ...ReportPageRoutes,
              ...NotificationPageRoutes,
              ...BadgesRoutes,
              ...MembersRoutes,
              ...LearningJourneysRoutes,
              // {
              //   path: 'calendar',
              //   element: <DummyOutlet />,
              // },
            ],
          },
          {
            element: <SideBarBaseLayout showIllustrator={false} />,
            children: [...ChatRoutes],
          },
        ],
      },
      ...AuthRoutes,
      ...OnboardingRoutes,
      {
        path: '*',
        element: <LandingNotFound />,
      },
      {
        path: '/invite',
        element: <Navigate to="/auth/register/pricing" replace />,
      },
    ],
  },
]);

export default router;
