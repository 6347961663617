import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '../../../hooks';
import { getTeamName } from '../api';
import { GET_TEAM_NAME_QUERY_STALE_TIME } from '../constants/react-query';
import { setSelectedTeam } from '../slices/team.slice';

interface Props {
  teamId?: number;
}

/**
 * Wrapper around the react querys useQuery to fetch the team name.
 */
const useTeamNameQuery = ({ teamId }: Props) => {
  const dispatch = useAppDispatch();
  const { data, isError, error, isLoading, refetch, isRefetching } = useQuery(
    [
      {
        scope: 'teams',
        items: 'teamName',
        teamId,
      },
    ],
    async ({ queryKey }) => {
      const { teamId: id } = queryKey[0];
      if (teamId) {
        const response = await getTeamName({ teamId: id ?? 1 });
        const { isSubTeam, hasSubTeam } = response;
        dispatch(
          setSelectedTeam({ teamId: id, teamName: response.teamName, isSubTeam, hasSubTeam })
        );
        return response;
      }
      return null;
    },
    {
      // Donot fetch the team name if the teamId is undefined
      enabled: teamId != undefined,
      staleTime: GET_TEAM_NAME_QUERY_STALE_TIME,
    }
  );
  return { data, isError, error, isLoading, refetch, isRefetching };
};

export default useTeamNameQuery;
