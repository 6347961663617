import React from 'react';
import { Navigate } from 'react-router-dom';
import ActionResponse from '../../../../../components/ActionResponse';
import { BasicSpinner } from '../../../../../components/Spinners';
import { AccountProvisionStatus } from '../../../../../constants/user';
import { useReduxAuthState } from '../../../../../hooks';
import useAccountProvisionStatus from '../../../../../hooks/use-account-provision-status';
import useCurrentAuthenticatedUser from '../../../../../hooks/use-current-authenticated-user';
import { FadeLoader } from 'react-spinners';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';

type BaseLayoutProps = {
  isLoading?: boolean;
  variant?: Parameters<typeof ActionResponse>[0]['variant'];
  title?: string;
  description?: React.ReactNode[] | React.ReactNode;
};

const BaseLayout = (props: BaseLayoutProps) => {
  const { isLoading = false } = props;

  return (
    <div className=" flex w-full grow justify-center">
      {isLoading === false ? (
        <ActionResponse
          className="relative top-[10vh] max-w-md border border-base-bright-green/30 bg-zinc-900 shadow-md"
          title={props.title}
          subtitle={props.description}
          variant={props.variant ?? 'success'}
          successVariantBackground={'bg-blue-900'}
        />
      ) : (
        <FadeLoader color="currentColor" className="text-zinc-50" width="3px" />
      )}
    </div>
  );
};

const UserAccountProvisionProgress = () => {
  const auth = useReduxAuthState();
  const userAccountProvisionState = useAccountProvisionStatus();

  const queryResponse = useCurrentAuthenticatedUser({
    bypassCache: true,
    refetchIntervalInMS: 5000,
  });

  // strapi
  const data: any = useStrapiDataHelper();
  const { AccountProvisionStatus: cms } = data.registration.data.attributes;
  // strapi

  if (queryResponse.isLoading) {
    return <BaseLayout isLoading={true} />;
  }

  if (!auth.isAuthenticated) {
    return <Navigate to="/auth/login" replace={true} />;
  }

  if (auth.isAuthenticated && !userAccountProvisionState) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  // If the user is yet to register
  // navigate to registration
  if (userAccountProvisionState === AccountProvisionStatus.NOT_REGISTERED) {
    return <Navigate to="/auth/register/subscription" replace={true} />;
  }

  // If the user has completed registration and account provision
  // navigate to dashboard
  if (userAccountProvisionState === AccountProvisionStatus.COMPLETED) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  // If the account provisioning has failed
  if (userAccountProvisionState === AccountProvisionStatus.FAILED) {
    // TODO: Add a mailto link here
    return (
      <BaseLayout
        title={cms.provisionFailedTitle}
        description={cms.provisionFailedDescription}
        variant="failure"
      />
    );
  }

  // In progress
  return (
    <BaseLayout
      title={cms.provisionInProgressTitle}
      description={
        <div className="flex flex-col items-center gap-3">
          <span>{cms.provisionInProgressDescription}</span>
          <BasicSpinner className="!m-0 text-base-brand" />
        </div>
      }
      variant="info"
    />
  );
};

export default UserAccountProvisionProgress;
