import axios from 'axios';
import { useCallback } from 'react';
import AlertDialog from '../../../../../../../../../components/AlertDialog';
import { useAppSelector } from '../../../../../../../../../hooks';
import { RootState } from '../../../../../../../../../store';
import { USHGAPIError } from '../../../../../../../../../types';
import { ERROR_CODE } from '../../../../../../../constants';
import { useActivateEnterpriseUserMutation } from '../../../../../../../hooks';
import useStrapiMembersData from '../../../../../../../hooks/useStrapiMembersData';
import type { DialogType } from '../EnterpriseDropDownCell';
import SubscriptionLimitExceededModal from './SubscriptionLimitExceededModal';

interface Props {
  username: string;
  closeDialog: () => void;
  setActiveDialogType: (dialog: DialogType) => void;
  teamId?: number;
}

// Subscription State Selector
const subscriptionStateSelector = (state: RootState) => state.subscription;

// Dialog Content to activate an user
const ActivateUserDialogContent = (props: Props) => {
  const { username, closeDialog, setActiveDialogType, teamId } = props;
  const { activateModal } = useStrapiMembersData();
  const { title, message, cancelButton, actionButton } = activateModal;
  // Mutation to activate user
  const activateUserMutation = useActivateEnterpriseUserMutation({ teamId });
  // Subscription Data
  const subscription = useAppSelector(subscriptionStateSelector);

  const activateEnterpriseUser = useCallback(
    async ({ username }: { username: string }) => {
      activateUserMutation.mutate(
        {
          username,
        },
        {
          onSuccess: () => {
            // Close the Dialog only on success
            closeDialog();
          },
          onError: (error) => {
            if (axios.isAxiosError(error)) {
              const { errorCode } = error.response?.data as unknown as USHGAPIError;

              // Show appropriate Dialogs based on the error
              switch (errorCode) {
                case ERROR_CODE.TRIAL_LIMIT_EXCEEDED: {
                  setActiveDialogType('TRIAL_LIMIT_EXCEEDED');
                  break;
                }
                case ERROR_CODE.SUBSCRIPTION_LICENSES_EXCEEDS: {
                  setActiveDialogType('SUBSCRIPTION_LICENSES_EXCEEDS');
                  break;
                }
              }
            }
          },
        }
      );
    },
    [activateUserMutation, closeDialog, setActiveDialogType]
  );

  const availableLicenses = subscription.subscriptionData?.availableLicenses as number;

  // If there are no licenses available then show subscription limit exceeded modal
  // since we need 1 for activating new user
  if (availableLicenses < 1) {
    return <SubscriptionLimitExceededModal />;
  }

  const substitutedMessage = message?.replace('{{username}}', username);

  return (
    <AlertDialog
      Title={title}
      Content={<>{substitutedMessage}</>}
      actionItem={{
        label: actionButton,
        action: () => {
          activateEnterpriseUser({ username });
        },
      }}
      cancelItem={{
        label: cancelButton,
      }}
      isActionDiabled={activateUserMutation.isLoading}
      isLoading={activateUserMutation.isLoading}
    />
  );
};

export default ActivateUserDialogContent;
