import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface strapiState {
  strapiData: any;
  loading: boolean;
  error: any;
}

const initialState: strapiState = {
  strapiData: null,
  loading: true,
  error: '',
};

export const strapiSlice = createSlice({
  name: 'strapi',
  initialState,
  reducers: {
    setStrapiData: (state, action: PayloadAction<any>) => {
      state.strapiData = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setErrorState: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
  },
});
export const { setStrapiData, setLoading, setErrorState } = strapiSlice.actions;

export default strapiSlice.reducer;
