import { useFormContext } from 'react-hook-form';
import { useNotificationPreferencesStrapiData } from '../../../hooks';
import {
  CategoryNotificationPreferences,
  NotificationPreferenceCategoryDefinition,
} from '../../../types';
import NotificationPreferenceNotificationTypeField from './NotificationPreferenceNotificationTypeField';

interface Props {
  category: NotificationPreferenceCategoryDefinition;
}

/**
 * Notification Category field set.
 * Will render all the notification preference types and the channel checkbox
 */
const NotificationPreferenceCategoryFieldset = (props: Props) => {
  const { category } = props;
  const { setValue } = useFormContext();

  const { allOffLabel, allOnLabel } = useNotificationPreferencesStrapiData();

  /**
   * Set all the notification preferences to either enabled or disabled.
   */
  const setAllNotificationsForThisCategory = ({ isEnabled }: { isEnabled: boolean }) => {
    const categoryValue: CategoryNotificationPreferences = {};

    category.preferences.forEach((preference) => {
      const preferenceValue = {
        isWebEnabled: isEnabled,
        isEmailEnabled: isEnabled,
      };
      categoryValue[preference.notificationTypeId] = preferenceValue;
    });

    setValue(`${category.categoryId}`, categoryValue);
  };

  /**
   * Enabled all the notifications for this category.
   */
  const enableAllNotificationsForThisCategory = () => {
    setAllNotificationsForThisCategory({ isEnabled: true });
  };

  /**
   * Disable all the notifications for this category.
   */
  const disableAllNotificationsForThisCategory = () => {
    setAllNotificationsForThisCategory({ isEnabled: false });
  };

  return (
    <fieldset className="flex flex-col gap-5">
      <legend className="hidden">{category.categoryHeading}</legend>
      <div className="flex flex-col">
        <h2 className="text-base font-semibold text-zinc-200">{category.categoryHeading}</h2>
        <span className="text-sm font-normal text-zinc-400">{category.categoryDescription}</span>
      </div>
      <div className="flex flex-col gap-3 rounded-sm border border-zinc-600 p-4 sm:gap-2">
        <div className="flex flex-row items-center justify-start gap-2 sm:justify-end">
          <button
            onClick={enableAllNotificationsForThisCategory}
            className="bg-transparent text-xs font-normal text-base-brand"
            type="button"
          >
            {allOnLabel}
          </button>
          <span className="text-xs font-normal text-zinc-600">/</span>
          <button
            onClick={disableAllNotificationsForThisCategory}
            className="bg-transparent text-xs font-normal text-base-brand"
            type="button"
          >
            {allOffLabel}
          </button>
        </div>
        {category.preferences.map((preference) => (
          <NotificationPreferenceNotificationTypeField
            key={preference.notificationTypeId}
            categoryId={category.categoryId}
            preference={preference}
          />
        ))}
      </div>
    </fieldset>
  );
};

export default NotificationPreferenceCategoryFieldset;
