import clsx from 'clsx';
import { BasicSpinner } from '../../../../components/Spinners';

interface Props {
  refetch: () => void;
  isRefetching: boolean;
  allowsRefetch?: boolean;
  className?: string;
  message: React.ReactNode;
}

const ErrorDisplay = (props: Props) => {
  const { refetch, isRefetching, allowsRefetch, className, message } = props;

  return (
    <div
      className={clsx(
        'flex w-full flex-col items-center justify-center gap-6 text-base font-normal text-rose-200',
        className
      )}
    >
      {isRefetching && (
        <div className="flex justify-center">
          <BasicSpinner className="text-white" />
        </div>
      )}
      <div>{message}</div>
      {allowsRefetch && (
        <button
          className={clsx(
            'w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4 leading-[20px] text-zinc-50',
            isRefetching && 'opacity-90'
          )}
          onClick={() => refetch()}
          disabled={isRefetching}
        >
          Retry
        </button>
      )}
    </div>
  );
};

export default ErrorDisplay;
