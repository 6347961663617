import {
  EnterpriseBulkInviteUser,
  EnterpriseBulkInviteUserError,
} from '../../../../../../shared/components';
import { useStrapiOnBoardingData } from '../../../../../hooks/useStrapiOnBoardingData';

interface Props {
  setUploadId: (uploadId: string | null) => void;
  isError: boolean;
  error: Error;
}

const BulkUpload = (props: Props) => {
  const { setUploadId, isError, error } = props;

  const { inviteMember } = useStrapiOnBoardingData();
  const { bulkUploadTitle } = inviteMember;

  return (
    <div className="flex w-full flex-col gap-2">
      <p className="text-left text-sm text-gray-300">{bulkUploadTitle}</p>
      <EnterpriseBulkInviteUser setUploadId={setUploadId} />
      {isError && <EnterpriseBulkInviteUserError error={error} isOnboarding={true} />}
    </div>
  );
};

export default BulkUpload;
