import { CourseType } from '../../features/dashboard/types/CourseType';
import { BadgeComponent } from '../Badge';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { generateCDNMediaAssetURLWithKey } from '../../features/shared/helpers';
import { useEffect, useState } from 'react';
import { useStrapiCoursesData } from '../../features/courses/hooks/useStrapiCourseData';
import AcronymLogo from '../AcronymnLogo';
import { formatNumber } from '../../utils';

type CourseCardProps = {
  course: CourseType;
  isThumbnailKeyToCDNAssets?: boolean;
  useThumbnailAndGradientAsBg?: boolean;
  source?: 'learning-journey' | 'default';
  learningJourneyId?: string;
};

const CourseCard = ({
  course,
  isThumbnailKeyToCDNAssets = true,
  useThumbnailAndGradientAsBg = false,
  source = 'default',
  learningJourneyId,
}: CourseCardProps) => {
  const navigate = useNavigate();
  const cardClick = () => {
    if (source === 'learning-journey' && learningJourneyId) {
      navigate(`/learning-journeys/${learningJourneyId}/courses/${course.id}`);
    } else {
      navigate(`/courses/${course.id}`);
    }
  };

  const [duration, setDuration] = useState<number | string>(0);
  const { allCourses } = useStrapiCoursesData();
  const { byLabel, hoursLabel, minsLabel } = allCourses.coursesCard;
  const { assignedLabel, overDueLabel } = allCourses.status;
  const [alternateProfile, setAlternateProfile] = useState<boolean>(false);

  useEffect(() => {
    if (course.duration < 60) {
      setDuration(`${course.duration} ${minsLabel}`);
    } else {
      setDuration(`${parseFloat(`${course.duration / 60}`).toFixed(1)} ${hoursLabel}`);
    }
  }, [course]);

  const speakerProfileFallback = () => {
    setAlternateProfile(true);
  };

  const thumbnailSrc = isThumbnailKeyToCDNAssets
    ? generateCDNMediaAssetURLWithKey({ key: course.thumbnail })
    : course.thumbnail;

  return (
    <div className="group h-fit w-full hover:cursor-pointer" onClick={cardClick} key={course.id}>
      <div className="relative h-[16.98rem] overflow-hidden rounded-md border-0 transition group-hover:scale-105">
        {useThumbnailAndGradientAsBg ? (
          <div
            className="absolute inset-0 aspect-square h-[16.98rem] w-full rounded-md transition group-hover:scale-105"
            style={{
              background: `linear-gradient(180deg, rgba(3, 7, 43, 0.12) 0%, #010211 100%),no-repeat center url('${thumbnailSrc}')`,
              backgroundSize: 'cover',
            }}
          ></div>
        ) : (
          <img
            className="aspect-square h-[16.98rem] w-full rounded-md object-cover transition group-hover:scale-105"
            src={thumbnailSrc}
            alt={`${course.name} ${byLabel} ${course.speakers.name}`}
          />
        )}
        {course.isAssigned && course.isOverdue && (
          <div className="absolute top-0 h-full w-full">
            <BadgeComponent
              className="absolute top-4 right-4 border-0 bg-base-brand py-2.5 font-extrabold text-white transition group-hover:hidden"
              label={overDueLabel}
              size="sm"
            />
          </div>
        )}
        {course.isAssigned && !course.isOverdue && (
          <div className="absolute top-0 h-full w-full">
            <BadgeComponent
              className="absolute top-4 right-4 border-0 bg-base-green py-2.5 font-extrabold text-white transition group-hover:hidden"
              label={assignedLabel}
              size="sm"
            />
          </div>
        )}
        {useThumbnailAndGradientAsBg ? null : (
          <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[#010211] via-[#03072B]/[0.35] transition group-hover:scale-105 group-hover:bg-[#010314C7]/[0.58]"></div>
        )}
        <div className="absolute top-0 h-full w-full">
          <div className="absolute bottom-0 left-0 m-3 flex h-fit w-11/12 flex-row items-start justify-between gap-1 transition group-hover:top-2">
            <div className="flex w-10/12 flex-col transition group-hover:w-4/5">
              <p className="my-[0.43rem] overflow-hidden text-ellipsis text-[0.65rem] font-medium leading-[0.78rem] text-zinc-200 transition group-hover:hidden">
                {byLabel} {course.speakers.name}&nbsp;&#183;&nbsp;{duration}
              </p>
              <h2 className="overflow-hidden text-ellipsis text-[1.40rem] font-bold leading-[1.70rem] text-white transition group-hover:text-[1.50rem] group-hover:leading-[1.85rem]">
                {course.name}
              </h2>
            </div>
            {course.progress && (
              <div className="hidden aspect-square h-14 w-2/12 grow transition group-hover:block group-hover:w-1/5">
                <CircularProgressbar
                  value={course.progress}
                  text={`${formatNumber(course.progress)}%`}
                  strokeWidth={14}
                  styles={buildStyles({
                    textColor: '#E4E4E7',
                    pathColor: '#F7634F',
                    trailColor: '#27272A',
                  })}
                />
              </div>
            )}
          </div>
          <div className="absolute right-2 bottom-0 m-3 hidden w-fit transition group-hover:block">
            <div className="flex flex-row items-center justify-end gap-2">
              <div className="flex flex-col gap-1">
                <p className="flex justify-end overflow-hidden text-ellipsis text-[0.68rem] font-normal leading-[0.78rem] text-zinc-200">
                  {duration}
                </p>
                <p className="flex justify-end overflow-hidden text-ellipsis text-[0.68rem] font-normal leading-[0.78rem] text-zinc-200">
                  {course.category}
                </p>
              </div>
              {!alternateProfile ? (
                <img
                  className="h-10 w-10 rounded border-2 border-zinc-50 object-cover"
                  src={generateCDNMediaAssetURLWithKey({ key: course.speakers.profile })}
                  alt=""
                  onError={speakerProfileFallback}
                />
              ) : (
                <AcronymLogo
                  className="h-10 w-10 text-sm"
                  wordsStr={course.speakers.name}
                  aria-label={`${course.speakers.id}`}
                  type="rounded"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
