import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { useStrapiReportsData } from '../../../../../shared/hooks/useStrapiReportData';

import { TopChurnRisk } from '../../../../types';
import OrgName from './TopChurnRiskTableCell/OrgNameCell';
import RiskValueCell from './TopChurnRiskTableCell/RiskValueCell';

interface Props {
  data: TopChurnRisk[];
  className?: string;
}

const TopChurnRisksTable = ({ data, className }: Props) => {
  const { tableheader1, tableheader2 } = useStrapiReportsData().TopChurnRisk;
  const columnHelper = createColumnHelper<TopChurnRisk>();
  const columns = [
    columnHelper.accessor('name', {
      header: tableheader1,
      cell: ({ getValue }) => <OrgName orgName={getValue() as string} />,
    }),
    columnHelper.accessor('churnRisk', {
      header: tableheader2,
      cell: ({ getValue }) => <RiskValueCell risk={getValue() as string} />,
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className={clsx('w-full', className)}>
      <thead className="w-full">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="bg-zinc-900 px-2 py-3 text-start text-[12px] font-semibold leading-[16px] text-zinc-400"
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id} className="even:bg-zinc-900">
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className={`px-2 py-3 text-[12px] font-normal leading-[16px] text-zinc-100 `}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TopChurnRisksTable;
