import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

export const useStrapiHQAdminCoursesData = () => {
  const data: any = useAppSelector(strapiDataSelector);
  const strapiCourseHQAdmin = data.course.data.attributes.HQ_ADMIN;
  let topNav;
  let moduletTypes;
  let courseTable;
  let singleCourseTableView;
  let singleCourseView;
  let courseModal;
  let moduleModal;
  let assessmentModal;
  let singleModuleView;
  let singleAssessmentView;
  let questionModal;
  let loadingMessages;
  let emptyMessages;
  let toastMessages;
  for (let i = 0; i <= strapiCourseHQAdmin?.length; i++) {
    if (strapiCourseHQAdmin[i]?.__typename === 'ComponentCoursesHqTopNav') {
      topNav = (strapiCourseHQAdmin ?? [])[i];
    } else if (strapiCourseHQAdmin[i]?.__typename === 'ComponentCoursesModuleType') {
      moduletTypes = (strapiCourseHQAdmin ?? [])[i];
    } else if (strapiCourseHQAdmin[i]?.__typename === 'ComponentCoursesCourseTable') {
      courseTable = (strapiCourseHQAdmin ?? [])[i];
    } else if (strapiCourseHQAdmin[i]?.__typename === 'ComponentCoursesSingleCourseTableView') {
      singleCourseTableView = (strapiCourseHQAdmin ?? [])[i];
    } else if (strapiCourseHQAdmin[i]?.__typename === 'ComponentCoursesAddOrEditCourse') {
      courseModal = (strapiCourseHQAdmin ?? [])[i];
    } else if (strapiCourseHQAdmin[i]?.__typename === 'ComponentCoursesSingleCourseView') {
      singleCourseView = (strapiCourseHQAdmin ?? [])[i];
    } else if (strapiCourseHQAdmin[i]?.__typename === 'ComponentCoursesAddOrEditModule') {
      moduleModal = (strapiCourseHQAdmin ?? [])[i];
    } else if (strapiCourseHQAdmin[i]?.__typename === 'ComponentCoursesSingleModuleView') {
      singleModuleView = (strapiCourseHQAdmin ?? [])[i];
    } else if (strapiCourseHQAdmin[i]?.__typename === 'ComponentCoursesSingleAssessmentView') {
      singleAssessmentView = (strapiCourseHQAdmin ?? [])[i];
    } else if (strapiCourseHQAdmin[i]?.__typename === 'ComponentCoursesAddOrEditAssessment') {
      assessmentModal = (strapiCourseHQAdmin ?? [])[i];
    } else if (strapiCourseHQAdmin[i]?.__typename === 'ComponentCoursesAddOrEditQuestion') {
      questionModal = (strapiCourseHQAdmin ?? [])[i];
    } else if (strapiCourseHQAdmin[i]?.__typename === 'ComponentCoursesLoadingMessages') {
      loadingMessages = (strapiCourseHQAdmin ?? [])[i];
    } else if (strapiCourseHQAdmin[i]?.__typename === 'ComponentCoursesEmptyMessages') {
      emptyMessages = (strapiCourseHQAdmin ?? [])[i];
    } else if (strapiCourseHQAdmin[i]?.__typename === 'ComponentCoursesToastMessages') {
      toastMessages = (strapiCourseHQAdmin ?? [])[i];
    }
  }

  return {
    topNav,
    moduletTypes,
    courseTable,
    singleCourseTableView,
    singleCourseView,
    courseModal,
    moduleModal,
    singleModuleView,
    singleAssessmentView,
    loadingMessages,
    questionModal,
    assessmentModal,
    emptyMessages,
    toastMessages,
  };
};
