import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;
export const useStrapiRequestData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const strapiRequestCommon = data.request.data.attributes.COMMON;
  let requestTable;
  let HQRequestTable;
  let toastMessages;
  let errorMessages;
  for (let i = 0; i <= strapiRequestCommon?.length; i++) {
    if (strapiRequestCommon[i]?.__typename === 'ComponentRequestRequestTable') {
      requestTable = (strapiRequestCommon ?? [])[i];
    } else if (strapiRequestCommon[i]?.__typename === 'ComponentRequestHqRequestTable') {
      HQRequestTable = (strapiRequestCommon ?? [])[i];
    } else if (strapiRequestCommon[i]?.__typename === 'ComponentRequestToastMessages') {
      toastMessages = (strapiRequestCommon ?? [])[i];
    } else if (strapiRequestCommon[i]?.__typename === 'ComponentRequestErrorMessages') {
      errorMessages = (strapiRequestCommon ?? [])[i];
    }
  }

  const {
    HQRequestModal,
    tableHeader,
    addTeamRequestModal,
    addRequestSubTeamModal,
    commonModal,
    editTeamRequestModal,
    tableSortOptions,
    tableFilterOptions,
    rejectRequestModal,
    topNav,
    dashBoardTableProps,
    requestType,
    addMemberRequestModal,
  } = requestTable;

  const {
    HQRequestTable: HQTable,
    HQRequestModal: HQModal,
    HQTableSortOptions,
    HQTableStatusOptions,
  } = HQRequestTable;
  return {
    HQTableSortOptions,
    HQTableStatusOptions,
    HQTable,
    HQModal,
    toastMessages,
    errorMessages,
    HQRequestModal,
    tableHeader,
    addTeamRequestModal,
    addRequestSubTeamModal,
    commonModal,
    editTeamRequestModal,
    tableSortOptions,
    tableFilterOptions,
    rejectRequestModal,
    dashBoardTableProps,
    addMemberRequestModal,
    topNav,
    requestType,
  };
};
