import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { getManagerTeamId } from '../api/getManagerTeamId';
import { setSelectedTeam } from '../slices/team.slice';

type Props = {
  username: string | undefined;
};
const useGetManagerTeamIdQuery = ({ username }: Props) => {
  const dispatch = useDispatch();
  const { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching } =
    useQuery(
      [
        {
          scope: 'teams',
          items: 'my-teamId',
          username: username,
        },
      ],
      async () => {
        const teamDetails = await getManagerTeamId();
        dispatch(setSelectedTeam(teamDetails));
        return teamDetails;
      },
      {
        enabled: username != undefined,
        // TODO: to be decided We are storing it in cache for 30 seconds
        staleTime: 30000,
        refetchOnMount: false,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching };
};

export default useGetManagerTeamIdQuery;
