import clsx from 'clsx';
import * as DropDownMenu from '@radix-ui/react-dropdown-menu';
import React from 'react';
import { PaperPlaneTilt, Spinner } from 'phosphor-react';

type ResendInviteProps = {
  className?: string;
  isLoading: boolean;
  title: string;
  onResendInvite: (e: any) => void;
};
const ResendInvite = ({ className, onResendInvite, isLoading, title }: ResendInviteProps) => {
  return (
    <DropDownMenu.Item
      onClick={onResendInvite}
      className={clsx('flex items-center gap-2.5 text-zinc-50', className)}
    >
      {!isLoading && <PaperPlaneTilt className="text-inherit" color="currentColor" size={14} />}
      {isLoading && <Spinner className="text-inherit" color="currentColor" size={14} />}
      <span className="text-xs font-medium leading-5 text-inherit">{title}</span>
    </DropDownMenu.Item>
  );
};

export default ResendInvite;
