import React, { useEffect, useState } from 'react';
import Dialog from '../../../../../components/Dialog';
import { VideoController } from '../../../../../components/MediaControls';
import { generateCDNMediaAssetURLWithKey } from '../../../../shared/helpers';
import { useStrapiHQAdminCoursesData } from '../../../hooks/useStrapiHQAdminCourseData';
import CourseModal from '../../NewCourse/components/CourseModal';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../constants/user';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';
import { useCourseBasicDetailsQuery } from '../../../hooks';
import { COURSES_ERROR_CODE } from '../../../constants/courses-management';

interface CourseBasicDetailsProps {
  courseBasicDetailsQuery: Record<string, any>;
}

const CourseBasicDetails = ({ courseBasicDetailsQuery }: CourseBasicDetailsProps) => {
  const [isEditCourseModalOpen, setIsEditCourseModalOpen] = useState(false);
  const [isManageCourseModalOpen, setIsManageCourseModalOpen] = useState(false);
  const { courseModal, singleCourseView } = useStrapiHQAdminCoursesData();
  const { editCourseTitle, editCourseButton, manageLanguagesTitle, createCourseButton } =
    courseModal;

  const [buttonLabel, setButtonLabel] = useState('');

  const {
    title,
    speakerLabel,
    tagsLabel,
    nameLabel,
    editCourseButton: editBasciDetailsButton,
    descriptionLabel,
    categoryLabel,
    coverImageLabel,
    learnerGistLabel,
    trailerLabel,
  } = singleCourseView.BasicDetails;

  const {
    language: { StrapiLanguageOptions },
  } = useStrapiSharedData();

  // Map `label` to `display` for Select component with null check
  const mappedOptions =
    StrapiLanguageOptions?.map((option: { label: string; value: string }) => ({
      display: option.label,
      value: option.value,
    })) || [];

  // Find the next language to English
  const nextLanguage = mappedOptions.find(
    (option: { value: string }) => option.value !== DEFAULT_PREFERRED_LANGUAGE
  );

  const [selectedLanguage, setSelectedLanguage] = useState(nextLanguage ? nextLanguage.value : '');

  const handleLanguageChange = (newLanguage: string) => {
    setSelectedLanguage(newLanguage);
  };

  const courseDetails = useCourseBasicDetailsQuery({
    id: courseBasicDetailsQuery.data.id,
    language: selectedLanguage,
    isEnabled: isManageCourseModalOpen,
  });

  useEffect(() => {
    if (courseDetails.data || courseDetails.errorType) {
      if (!courseDetails.data && courseDetails.errorType === COURSES_ERROR_CODE.COURSE_NOT_FOUND) {
        setButtonLabel(createCourseButton);
      } else {
        setButtonLabel(editCourseButton);
      }
    }
  }, [courseDetails.data, courseDetails.errorType, createCourseButton, editCourseButton]);

  return (
    <div className="w-full rounded-sm bg-card-bg p-4">
      <div className="flex flex-col gap-y-4">
        {/* Header Starts  */}
        <div className="flex items-center justify-between ">
          <p className="font-sans text-base font-medium text-white">{title}</p>
          <div className="flex gap-4">
            <Dialog
              containerClassName="!max-w-4xl"
              open={isManageCourseModalOpen}
              onOpenChange={(open) => setIsManageCourseModalOpen(open)}
              triggerClassName="flex justify-end basis-0 grow-0"
              onInteractOutside={(e) => {
                // Donot close the Modal when we click outside the Dialog Content
                e.preventDefault();
              }}
              Content={
                <CourseModal
                  modalTitle={manageLanguagesTitle}
                  setIsCourseModalOpen={setIsManageCourseModalOpen}
                  coursePreFetchData={
                    courseDetails.errorType ? courseBasicDetailsQuery : courseDetails
                  }
                  refetch={courseDetails.refetch}
                  formActionButtonLabel={buttonLabel}
                  modalType="manage"
                  showLanguageDropdown={true}
                  mappedOptions={mappedOptions}
                  selectedLanguage={selectedLanguage}
                  handleLanguageChange={handleLanguageChange}
                  errorType={courseDetails.errorType}
                  setErrorType={courseDetails.setErrorType}
                />
              }
            >
              <span className="whitespace-nowrap rounded bg-zinc-900 py-3 px-4 text-xs font-normal text-zinc-200">
                {manageLanguagesTitle}
              </span>
            </Dialog>
            {courseBasicDetailsQuery.data && (
              <Dialog
                containerClassName="!max-w-4xl"
                open={isEditCourseModalOpen}
                onOpenChange={(open) => setIsEditCourseModalOpen(open)}
                triggerClassName="flex justify-end basis-0 grow-0"
                onInteractOutside={(e) => {
                  // Donot close the Modal when we click outside the Dialog Content
                  e.preventDefault();
                }}
                Content={
                  <CourseModal
                    modalTitle={editCourseTitle}
                    setIsCourseModalOpen={setIsEditCourseModalOpen}
                    coursePreFetchData={courseBasicDetailsQuery}
                    formActionButtonLabel={editCourseButton}
                    modalType="update"
                    refetch={courseBasicDetailsQuery.refetch}
                  />
                }
              >
                <span className="whitespace-nowrap rounded bg-zinc-900 py-3 px-4 text-xs font-normal text-zinc-200">
                  {editBasciDetailsButton}
                </span>
              </Dialog>
            )}
          </div>
        </div>
        {/* Header Ends  */}

        {/* Course Basic Details loaded */}
        {courseBasicDetailsQuery.data && (
          <>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {/* Course Name */}
              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-medium text-zinc-400">{nameLabel}</p>
                <p className="text-sm font-medium text-zinc-50">
                  {courseBasicDetailsQuery.data?.name}
                </p>
              </div>
              {/* Course Name */}

              {/* Course Category */}
              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-medium text-zinc-400">{categoryLabel}</p>
                <p className="text-sm font-medium text-zinc-50">
                  {courseBasicDetailsQuery.data?.category?.name}
                </p>
              </div>
              {/* Course Category */}

              {/* Course Tags */}
              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-medium text-zinc-400">{tagsLabel}</p>
                {courseBasicDetailsQuery.data?.tagsList?.length > 0 ? (
                  <div className="flex flex-wrap gap-2">
                    {courseBasicDetailsQuery.data?.tagsList?.map(
                      (tag: Record<string, any>, index: number) => (
                        <span key={index} className="text-sm font-medium text-zinc-50">
                          #{tag.name}
                          {courseBasicDetailsQuery.data?.tagsList &&
                            index < courseBasicDetailsQuery.data?.tagsList?.length - 1 &&
                            ', '}
                        </span>
                      )
                    )}
                  </div>
                ) : (
                  <p className="text-sm font-medium text-zinc-50">-</p>
                )}
              </div>
              {/* Course Tags */}

              {/* HeadLine Speaker */}
              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-medium text-zinc-400">{speakerLabel}</p>
                <p className="text-sm font-medium text-zinc-50">
                  {courseBasicDetailsQuery.data?.headlineSpeaker?.name}
                </p>
              </div>
              {/* HeadLine Speaker */}
            </div>

            {/* Description */}
            <div className="flex flex-col gap-y-1">
              <p className="text-sm font-medium text-zinc-400">{descriptionLabel}</p>
              {courseBasicDetailsQuery.data.description &&
                courseBasicDetailsQuery.data.description.split('\n').map((item: string) => {
                  return (
                    <p className="text-sm font-medium text-zinc-50" key={item}>
                      {item}
                    </p>
                  );
                })}
            </div>
            {/* Description */}

            {/* Things to Learn */}
            <div className="flex flex-col gap-y-1">
              <p className="text-sm font-medium text-zinc-400">{learnerGistLabel}</p>
              {courseBasicDetailsQuery.data?.thingsToLearn?.length > 0 ? (
                <ul className="ml-8 list-disc text-sm font-medium text-zinc-50">
                  {courseBasicDetailsQuery.data?.thingsToLearn?.map(
                    (list: string, index: number) => (
                      <li key={index}>{list}</li>
                    )
                  )}
                </ul>
              ) : (
                <p className="text-sm font-medium text-zinc-50">-</p>
              )}
            </div>
            {/* Things to Learn */}

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {/* Course Cover Image */}
              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-medium text-zinc-400">{coverImageLabel}</p>
                {courseBasicDetailsQuery.data?.thumbnailUrl ? (
                  <img
                    className="h-full max-h-[15.625rem] w-80 object-cover"
                    src={generateCDNMediaAssetURLWithKey({
                      key: courseBasicDetailsQuery.data?.thumbnailUrl,
                    })}
                    alt="Course Cover Image"
                  />
                ) : (
                  <p className="text-sm font-medium text-zinc-50">-</p>
                )}
              </div>
              {/* Course Cover Image */}

              {/* Course Trailer Video */}
              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-medium text-zinc-400">{trailerLabel}</p>
                {courseBasicDetailsQuery.data?.previewUrl ? (
                  <div className="flex">
                    <VideoController
                      className="aspect-video !h-full max-h-[15.625rem] w-max"
                      video={generateCDNMediaAssetURLWithKey({
                        key: courseBasicDetailsQuery.data?.previewUrl,
                      })}
                    ></VideoController>
                  </div>
                ) : (
                  <p className="text-sm font-medium text-zinc-50">-</p>
                )}
              </div>
              {/* Course Trailer Video */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CourseBasicDetails;
