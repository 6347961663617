import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { USERS_API_PATHS } from '../constants';
import qs from 'qs';

interface ActivateEnterpriseUserArgs {
  username: string;
}

/**
 * Used to activate an enterprise user
 */
const activateEnterpriseUser = async (args: ActivateEnterpriseUserArgs) => {
  const { username } = args;

  const path = USERS_API_PATHS.ACTIVATE_USER;
  const queryString = qs.stringify({ username });

  const response = await API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${queryString}`, {
    response: true,
  });
  return response.data;
};

interface DeactivateEnterpriseUserArgs {
  username: string;
}

/**
 * Used to deactivate an enterprise user.
 */
const deactivateEnterpriseUser = async (args: DeactivateEnterpriseUserArgs) => {
  const { username } = args;

  const path = USERS_API_PATHS.DEACTIVATE_USER;
  const queryString = qs.stringify({ username });

  const response = await API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${queryString}`, {
    response: true,
  });
  return response.data;
};

export type UserUpdateRolePayloadType = {
  username: string;
  role: string;
  teamName?: string;
  teamId?: number;
};

const updateUserRoleOrTeam = async (payload: UserUpdateRolePayloadType) => {
  return API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, USERS_API_PATHS.UPDATE_USER_ROLE_TEAM, {
    body: payload,
    response: true,
    withCredentials: true,
  });
};

export { activateEnterpriseUser, deactivateEnterpriseUser, updateUserRoleOrTeam };
export type { ActivateEnterpriseUserArgs, DeactivateEnterpriseUserArgs };
