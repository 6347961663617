import toast from 'react-hot-toast';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
import { showToast } from '../../shared/helpers/toast';
import { resendAccountConfirmationCode } from '../api/login';
import { ERROR_CODE } from '../constants/errorCode';

const useResendAccountConfirmationCode = () => {
  const data: any = useStrapiDataHelper();

  const toastMessageStrapi = data?.toastMessage.data.attributes;
  const {
    confirmationCodeResendToastMessage,
    confirmationCodeResendSuccessToastMessage,
    userAlreadyActivatedToastMessage,
    userNotFoundToastMessage,
    limitExceededToastMessage,
  } = toastMessageStrapi;

  const resendConfirmationCodeHandler = async (username: string) => {
    const toastId = showToast({
      variant: 'info',
      title: confirmationCodeResendToastMessage,
    });

    try {
      const response: any = await resendAccountConfirmationCode(username);
      if (response.isSuccess === true) {
        toast.remove(toastId);
        showToast({
          variant: 'success',
          title: confirmationCodeResendSuccessToastMessage,
        });
      }
    } catch (error: any) {
      const errorMessage = error.response.data.message;
      toast.remove(toastId);
      if (errorMessage == 'Attempt limit exceeded, please try after some time.') {
        showToast({
          title: limitExceededToastMessage,
          variant: 'error',
        });
      } else if (error.response.data.errorCode === ERROR_CODE.USER_ALREADY_ACTIVE) {
        showToast({
          title: userAlreadyActivatedToastMessage,
          variant: 'error',
        });
      } else {
        showToast({
          title: userNotFoundToastMessage,
          variant: 'error',
        });
      }
    }
  };
  return { resendConfirmationCodeHandler };
};
export { useResendAccountConfirmationCode };
