import { createContext } from 'react';
import { type CognitoUser } from '@aws-amplify/auth';

export type CognitoUserContext = {
  cognitoUser: CognitoUser | null;
  setCognitoUser: React.Dispatch<React.SetStateAction<CognitoUser | null>>;
};

const cognitoUserContext = createContext({} as CognitoUserContext);

export default cognitoUserContext;
