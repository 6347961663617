import { flexRender, Header } from '@tanstack/react-table';
import clsx from 'clsx';
import { DynamicSortIcon } from '../../../../../../../../components/DataTables';

interface Props {
  header: Header<any, unknown>;
  className?: string;
  isSortable?: boolean;
  allowMultiColSort?: boolean;
}

const FeedbacksTableHeaderRow = (props: Props) => {
  const { header, className, isSortable = false, allowMultiColSort = false } = props;

  const toggleColumnSorting = () => {
    if (header.id === 'comments') {
      return;
    }

    isSortable && header.column.toggleSorting(undefined, allowMultiColSort);
  };

  return (
    <th
      className={clsx(
        'bg-zinc-900 px-2 py-3 text-start text-[12px] font-semibold leading-[16px] text-zinc-200',
        isSortable && 'cursor-pointer',
        className
      )}
      key={header.id}
      colSpan={header.colSpan}
      onClick={toggleColumnSorting}
    >
      <div className="flex w-full items-center gap-2">
        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
        {isSortable && (
          <DynamicSortIcon
            fallback={<div className="h-4 w-4 bg-transparent"></div>}
            sortDirection={header.column.getIsSorted()}
          />
        )}
      </div>
    </th>
  );
};

export default FeedbacksTableHeaderRow;
