import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';
import Banner from '../../../components/Banner';
import { BannerVariant } from '../../../components/Banner/Banner';

interface Props {
  variant?: BannerVariant;
  //   markdown content
  children: string;
}

/**
 * Map Banner Variant to override options
 */
const variantElementPropsMap: Record<
  BannerVariant,
  Record<keyof MarkdownToJSX.Overrides, MarkdownToJSX.Overrides[keyof MarkdownToJSX.Overrides]>
> = {
  info: {
    a: {
      props: {
        className: 'text-blue-700 hover:underline hover:underline-offset-1',
        target: '_blank',
      },
    },
    strong: {
      props: {
        className: 'font-semibold',
      },
    },
    del: {
      props: {
        className: 'line-through',
      },
    },
    em: {
      props: {
        className: 'italic',
      },
    },
  },
};

const AnnouncementBanner = (props: Props) => {
  const { variant = 'info', children } = props;

  return (
    <Banner variant={variant}>
      <Markdown
        options={{
          overrides: variantElementPropsMap[variant],
          disableParsingRawHTML: true,
        }}
      >
        {children}
      </Markdown>
    </Banner>
  );
};

export default AnnouncementBanner;
