import { GetAllMembersInOrganizationResponseDataItem } from '../types';

const getTeamNames = (row: GetAllMembersInOrganizationResponseDataItem) => {
  // A manager would have his team details
  // in the teamManager property
  if (row.userType === 'Manager') {
    return row.teamManager.map((x) => x.teamNameHierarchy);
  } else {
    return [row.teamNameHierarchy];
  }
};

const getTeamNameFormatString = (teamNames: string[]) => {
  const teamsCount = teamNames.length;

  if (teamsCount > 1) {
    return `${teamNames[0]} +${teamsCount - 1}`;
  } else if (teamsCount === 1) {
    return teamNames[0];
  } else {
    return '-';
  }
};

const generateTeamHierarchyName = (user: GetAllMembersInOrganizationResponseDataItem) => {
  const teamNames = getTeamNames(user).filter((x) => x !== null && x !== undefined) as string[];
  const teamNameFormatString = getTeamNameFormatString(teamNames);
  return teamNameFormatString;
};

export { getTeamNames, getTeamNameFormatString, generateTeamHierarchyName };
