import { MultipartPreSignedUrlRequestPayload, uploadModuleType } from '../../types';
import { COMMON_SIZE } from '../../constants/size-constants';
import { UPLOAD_REQUEST_TYPE } from '../../features/shared/constants/imageUpload';

export type UploaderInit = {
  fileExtension: string;
  fileType: string;
  file: any;
  preSignedUrlFetch: any;
  finalizeMultipartUpload: any;
  module: uploadModuleType;
  courseId?: string;
  fileName?: string;
};

export class Uploader {
  chunkSize: any;
  threadsQuantity: any;
  aborted: any;
  uploadedSize: any;
  progressCache: any;
  activeConnections: any;
  parts: any;
  uploadedParts: any;
  fileId: any;
  fileKey: any;
  onInitiateHandler: any;
  onProgressHandler: any;
  onErrorHandler: any;
  onCompleteHandler: any;
  firstChunk: boolean;

  // upload type
  file: any;
  fileType: string;
  fileName: string | undefined;
  fileExtension: string; // video or image
  preSignedUrlFetch: any;
  finalizeMultipartUpload: any;
  module: uploadModuleType;
  courseId: string | undefined;
  constructor(init: UploaderInit, options: any) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options.chunkSize || COMMON_SIZE.TWENTY_MB;
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = null;
    this.fileKey = null;
    this.firstChunk = true;

    this.file = init.file;
    this.fileExtension = init.fileExtension;
    this.preSignedUrlFetch = init.preSignedUrlFetch;
    this.finalizeMultipartUpload = init.finalizeMultipartUpload;
    this.module = init.module;
    this.fileType = init.fileType;
    this.fileName = init.fileName;
    this.courseId = init.courseId;

    this.onProgressHandler = () => true;
    this.onErrorHandler = () => true;
    this.onCompleteHandler = () => true;
    this.onInitiateHandler = () => true;
  }

  // starting the multipart upload request
  start() {
    this.initialize();
  }

  async initialize() {
    try {
      const preSignedUrlFilters: MultipartPreSignedUrlRequestPayload = {
        fileExtension: this.fileExtension,
        parts: Math.ceil(this.file.size / this.chunkSize),
        requestType: UPLOAD_REQUEST_TYPE.MULTI_PART,
        module: this.module,
        courseId: this.courseId,
        fileType: this.fileType,
        fileName: this.fileName,
      };
      this.onInitiateHandler(true);
      const preSignedUrls: any = await this.preSignedUrlFetch(preSignedUrlFilters);
      this.fileId = preSignedUrls.fileId;
      this.fileKey = preSignedUrls.fileKey;
      this.parts = preSignedUrls.parts;
      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    // To thread not more than specific limit
    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    //  Check if all the parts are uploaded
    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);

          this.complete(error);
        });
    }
  }

  // terminating the multipart upload request on success or failure
  async complete(error?: any) {
    if (error && !this.aborted) {
      this.onErrorHandler(error);
      return;
    }

    if (error) {
      this.onErrorHandler(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
    } catch (error) {
      this.onErrorHandler(error);
    }
  }

  // finalizing the multipart upload request on success by calling
  // the finalization API
  async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      const videoFinalizationMultiPartInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        fileName: this.fileName,
        parts: this.uploadedParts,
      };
      try {
        this.onCompleteHandler(await this.finalizeMultipartUpload(videoFinalizationMultiPartInput));
      } catch (e) {
        console.error(e);
      }
    }
  }

  sendChunk(chunk: any, part: any, sendChunkStarted: any) {
    return new Promise<void>((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // calculating the current progress of the multipart upload request
  handleProgress(part: any, event: any) {
    if (this.file) {
      if (event.type === 'progress' || event.type === 'error' || event.type === 'abort') {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      if (this.firstChunk) {
        this.firstChunk = false;
        this.onInitiateHandler(false);
      }

      this.onProgressHandler({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  }

  // uploading a part through its pre-signed URL
  upload(file: any, part: any, sendChunkStarted: any) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        // - 1 because PartNumber is an index starting from 1 and not 0
        const xhr = (this.activeConnections[part.PartNumber - 1] = new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(this, part.PartNumber - 1);

        xhr.upload.addEventListener('progress', progressListener);

        xhr.addEventListener('error', progressListener);
        xhr.addEventListener('abort', progressListener);
        xhr.addEventListener('loadend', progressListener);

        xhr.open('PUT', part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader('ETag');

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                // removing the " enclosing carachters from
                // the raw ETag
                ETag: ETag.replaceAll('"', ''),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.PartNumber - 1];
            }
          }
        };

        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'));
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.send(file);
      }
    });
  }

  onInitiate(onInitiate: any) {
    this.onInitiateHandler = onInitiate;
    return this;
  }

  onProgress(onProgress: any) {
    this.onProgressHandler = onProgress;
    return this;
  }

  onError(onError: any) {
    this.onErrorHandler = onError;
    return this;
  }

  onComplete(completed: any) {
    this.onCompleteHandler = completed;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });
    this.aborted = true;
  }
}
