import { UseQueryResult } from '@tanstack/react-query';
import clsx from 'clsx';
import { ArrowCircleRight } from 'phosphor-react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useNavigate } from 'react-router';
import { ErrorDisplay } from '../../../../../components/DataTables';
import { BasicSpinner } from '../../../../../components/Spinners';
import { ERROR_TYPES } from '../../../../../constants/error-types.constants';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { useStrapiCoursesData } from '../../../../courses/hooks/useStrapiCourseData';
import { generateCDNMediaAssetURLWithKey } from '../../../../shared/helpers';
import { useStrapiDashboardData } from '../../../../shared/hooks';
import { ComponentHeading, ExploreAllCourses } from '../../common';
import { formatNumber } from '../../../../../utils';
interface Props {
  className?: string;
  myCoursesQuery: UseQueryResult<any>;
  cardHeader: string;
}

const DashboardCourseCard = ({ className, myCoursesQuery, cardHeader }: Props) => {
  const navigate = useNavigate();
  // Error State
  const errorState = myCoursesQuery.isError && !myCoursesQuery.data;
  const { hoursLabel } = useStrapiDashboardData().dashboardCards;
  const displayLoader =
    (myCoursesQuery.isLoading && !myCoursesQuery.data) ||
    (myCoursesQuery.isFetching && myCoursesQuery.data?.results?.length === 0);

  const course = myCoursesQuery?.data?.results?.[0];

  // pull data from strapi helper
  const strapidata = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;

  const parseCourseDuration = (duration: number) => {
    if (duration < 60) {
      return `${duration} Minutes`;
    } else {
      return `${parseFloat(`${course.duration / 60}`).toFixed(1)} ${hoursLabel}`;
    }
  };
  const {
    allCourses: {
      coursesCard: { byLabel },
    },
  } = useStrapiCoursesData();
  return (
    <div className={clsx('min-h-[175px]  rounded-lg bg-card-bg shadow-md', className)}>
      {(errorState || displayLoader) && (
        <div className="flex flex-col content-around gap-5 p-5">
          <ComponentHeading heading={cardHeader} />
          {/* Course Details loading */}
          {displayLoader && (
            <div className="flex h-full w-full items-center justify-center py-4">
              <BasicSpinner className="text-white" />
            </div>
          )}
          {/* Error State */}
          {errorState && (
            <div className="flex h-full items-center justify-center">
              <ErrorDisplay
                refetch={myCoursesQuery.refetch}
                isRefetching={myCoursesQuery.isRefetching}
                allowsRefetch={true}
                type={ERROR_TYPES.SOMETHING_WENT_WRONG}
                message={strapiErrorMessage['SYSTEM_ERROR']}
              />
            </div>
          )}
          {/* Empty State */}
          <ExploreAllCourses className="col-span-8 col-start-1 row-start-2 overflow-hidden md:row-start-3 lg:col-span-6" />
        </div>
      )}
      {/* Course Details loaded */}
      {myCoursesQuery.data && myCoursesQuery.data?.results.length > 0 && (
        <div
          className={clsx(
            'flex h-full w-full items-center rounded-lg bg-card-bg shadow-md',
            className
          )}
        >
          <img
            alt={cardHeader}
            src={generateCDNMediaAssetURLWithKey({
              key: course?.thumbnail,
            })}
            className="pointer-events-none hidden aspect-square h-full w-2/5 rounded-lg object-cover gradient-mask-r-50 lg:flex"
          />
          <div className="flex h-full basis-9/12 flex-col justify-between gap-y-4 p-4 lg:basis-3/5">
            <div className="flex flex-col gap-y-2">
              <span className="text-[8px] font-bold uppercase leading-[150%] tracking-wider text-base-brand">
                {cardHeader}
              </span>
              <h1 className="text-xl font-bold text-zinc-50">
                {myCoursesQuery.data?.results?.[0]?.name}
              </h1>
            </div>
            <p className="text-xs font-normal text-zinc-400">
              {byLabel} {course?.speakers?.name}&nbsp;&#183;&nbsp;
              {course && parseCourseDuration(course.duration)}
            </p>
          </div>
          <div className="flex h-full items-start justify-end pr-4 pt-4">
            <div className="flex h-full flex-col items-center justify-between pb-4">
              {course?.isInProgress && (
                <div className={clsx('aspect-square h-8 w-8 md:h-12 md:w-12')}>
                  <CircularProgressbar
                    value={course?.progress}
                    text={`${formatNumber(course.progress)}%`}
                    strokeWidth={14}
                    styles={buildStyles({
                      textColor: '#E4E4E7',
                      pathColor: '#F7634F',
                      trailColor: '#18181B',
                    })}
                  />
                </div>
              )}
              <ArrowCircleRight
                aria-label="Explore All Courses"
                size="24px"
                color="currentColor"
                className="mt-auto cursor-pointer text-zinc-500"
                onClick={() => navigate(`/courses/${course?.id}`)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardCourseCard;
