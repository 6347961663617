import { useMemo } from 'react';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { useStrapiRequestData } from '../../../../hooks/useStrapiRequestData';
import { EnterpriseAdminTeamRequestData } from '../../../../types';
import { RequestActionDialogItem } from '../../../common';
import TeamNameDialogItemContent from './TeamNameDialogItemContent';

interface Props {
  teamRequestData: EnterpriseAdminTeamRequestData;
}

const TeamMemberEmailList = ({
  members,
}: {
  members: EnterpriseAdminTeamRequestData['teamMembers'];
}) => {
  return (
    <ul className="flex flex-col gap-1">
      {members.map((m) => {
        return (
          <li className="text-ellipsis text-center xs:text-left" key={m.memberEmail}>
            {m.memberEmail}
          </li>
        );
      })}
    </ul>
  );
};
const TeamRequestDialogContent = ({ teamRequestData }: Props) => {
  // Get Parent Team Here If it is sub team
  const isSubTeam = teamRequestData.isSubTeam;
  const managers = useMemo(() => {
    return teamRequestData.teamMembers.filter((tm) => tm.userType === 'Manager');
  }, [teamRequestData]);

  const learners = useMemo(() => {
    return teamRequestData.teamMembers.filter((tl) => tl.userType === 'User');
  }, [teamRequestData]);

  const hasManagers = managers && managers.length > 0;
  const hasLearners = learners && learners.length > 0;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const { addTeamRequestModal, addRequestSubTeamModal, commonModal } = useStrapiRequestData();
  const { teamNameLabel } = addTeamRequestModal;
  const { teamNameLabel: subTeamNameLabel, mainTeamLabel } = addRequestSubTeamModal;
  const { managerEmailLabel, memberEmailLabel } = commonModal;
  const parentTeamId = teamRequestData.parentTeamId;

  return (
    <div className="flex flex-col justify-between gap-6">
      <div className="flex flex-col justify-between gap-6 xs:flex-row">
        <RequestActionDialogItem
          className="grow basis-0 text-center"
          heading={isSubTeam ? subTeamNameLabel : teamNameLabel}
          content={teamRequestData.teamName}
        />
        {isSubTeam && parentTeamId && (
          <RequestActionDialogItem
            heading={mainTeamLabel}
            content={
              <TeamNameDialogItemContent
                teamId={parentTeamId != undefined ? parseInt(parentTeamId) : undefined}
                errorMessage={strapiErrorMessage['TEAM_NAME_ERROR']}
              />
            }
          />
        )}
      </div>
      {/* If we have managers or learners display this */}
      {(hasManagers || hasLearners) && (
        <div className="flex w-full flex-col gap-6">
          {hasManagers && (
            <RequestActionDialogItem
              className="grow basis-0"
              heading={managerEmailLabel}
              content={<TeamMemberEmailList members={managers} />}
            />
          )}
          {hasLearners && (
            <RequestActionDialogItem
              className="grow basis-0"
              heading={memberEmailLabel}
              content={<TeamMemberEmailList members={learners} />}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TeamRequestDialogContent;
