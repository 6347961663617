import { Tag } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../../../../../../components/Buttons';
import { ErrorDisplay } from '../../../../../../components/DataTables';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { useAppDispatch, useAppSelector, useReduxAuthState } from '../../../../../../hooks';
import { RootState } from '../../../../../../store';
import { MONTH } from '../../../../../settings/constants/settings';
import { BASIC_DETAILS, ROLES } from '../../../../constants';
import Registration from '../../../../data/Registration';
import usePreviewSubscriptionMutation from '../../../../hooks/usePreviewSubscriptionMutation';
import { setChangePlanFlag } from '../../../../slices/registration.slice';
import { ERROR_TYPES } from '../../../../../../constants/error-types.constants';
import { format } from 'date-fns';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { getLocale } from '../../../../../../utils';
import { useLanguage } from '../../../../../shared/context/languageContext';

interface Props {
  role: string;
  setIsSubmitting: (value: boolean) => void;
  enterpriseInviteId?: string;
}

const pricingStateSelector = (state: RootState) => state.pricing;
const registrationStateSelector = (state: RootState) => state.register;

export const UserSubscriptionDetail = ({ role, setIsSubmitting, enterpriseInviteId }: Props) => {
  const dispatch = useAppDispatch();
  const { form } = Registration();

  const { pricing, licenses, tenure } = useAppSelector(pricingStateSelector);
  const { registration } = useAppSelector(registrationStateSelector);
  const { freeTrail, subscriptionType } = pricing;
  const basicDetails = registration.filter((data: { slug: string }) => data.slug === BASIC_DETAILS);
  const previewSubscription = usePreviewSubscriptionMutation();
  const strapiData = form[2].content;
  useEffect(() => {
    setIsSubmitting(previewSubscription.isLoading);
  }, [previewSubscription.isLoading, setIsSubmitting]);
  const subscriptionInfoTextList = [...strapiData.subscriptionDeatilsList];
  const [membersQtyText, setMembersQtyText] = useState({
    text: '',
    hasValue: false,
  });
  const [freeTrialMemberlimit, setFreeTrialMemberlimit] = useState({
    text: '',
    hasValue: false,
  });
  const [discountText, setDiscountText] = useState({
    text: '',
    hasValue: false,
  });
  const [firstPayment, setFirstPayment] = useState({
    text: '',
    hasValue: false,
  });
  const [trailDisclaimer, setTrailDisclaimer] = useState({
    text: '',
    hasValue: false,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const inviteId = queryParams.get('inviteId') || enterpriseInviteId;
  const { language } = useLanguage();

  const auth = useReduxAuthState();

  // Hide the change plan if this is invite
  // or if the user is authenticated
  const hideChangePlan = (inviteId !== undefined && inviteId !== null) || auth.isAuthenticated;

  useEffect(() => {
    const payload = {
      email:
        pricing.role === ROLES.TEAM || pricing.role === ROLES.ENTERPRISE
          ? basicDetails[0].data.corporateEmail
          : pricing.role === ROLES.INDIVIDUAL
          ? basicDetails[0].data.personalEmail
          : '',
      inviteId: inviteId ? inviteId : undefined,
      pricingType: tenure,
      name: basicDetails[0].data.firstName + ' ' + basicDetails[0].data.lastName,
      subscriptionType,
      licenses: subscriptionType === 'basic' ? 1 : (licenses as number),
      freeTrail,
    };
    previewSubscription.mutate(payload, {
      onSuccess(data) {
        subscriptionInfoTextList.map((element: { slug: string; subscriptionDetail: string }) => {
          if (element.slug === 'membersQty' && data && subscriptionType === 'team') {
            setMembersQtyText({
              text: element.subscriptionDetail.replace(
                `{{quantity}}`,
                `<span class="text-base-brand">${data.licenses}</span>`
              ),
              hasValue: true,
            });
          }
          if (
            element.slug === 'freeTrialMemberlimit' &&
            freeTrail &&
            data &&
            subscriptionType === 'team'
          ) {
            setFreeTrialMemberlimit({
              text: element.subscriptionDetail.replace(
                `{{membersQuantity}}`,
                `<span class="text-base-brand">${data.trialUser}</span>`
              ),
              hasValue: true,
            });
          }
          if (element.slug === 'trialDisclaimer') {
            setTrailDisclaimer({
              text: element.subscriptionDetail,
              hasValue: !!element.subscriptionDetail,
            });
          }
          if (element.slug === 'discount' && data && (data.discountPercentage as number) > 0) {
            setDiscountText({
              text: element.subscriptionDetail.replace(
                `{{discount}}`,
                `<span class="text-base-brand">${data.discountPercentage}% discount</span>`
              ),
              hasValue: true,
            });
          }
          if (element.slug === 'firstMonthlyPayment' && data) {
            setFirstPayment({
              text: element.subscriptionDetail
                .replace(
                  `{{tenure}}`,
                  `${tenure === MONTH ? monthlyTenure.toLowerCase() : yearlyTenure.toLowerCase()}`
                )
                .replace(
                  `{{originalPrice}}`,
                  `<span class="${
                    (data.discountPercentage as number) > 0 ? 'line-through' : 'hidden'
                  }">${'$' + data?.actualAmount}</span>`
                )
                .replace(`{{discountedPrice}}`, `${'$' + data?.invoiceAmount}`)
                .replace(
                  `{{date}}`,
                  `<span class="text-base-brand">${format(
                    new Date(data?.subscriptionStartDate as number),
                    'MMM dd yyyy',
                    { locale: getLocale(language) }
                  )}</span>`
                ),
              hasValue: true,
            });
          }
        });
      },
    });
  }, [licenses, freeTrail, tenure]);
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const attributes = strapidata?.registration.data.attributes;
  const {
    monthy: { label: monthlyTenure },
    annually: { label: yearlyTenure },
  } = attributes;
  return (
    <div className="w-full rounded-lg bg-zinc-800 p-6 font-semibold shadow-md">
      {previewSubscription.isLoading ? (
        <div className="flex justify-center">
          {' '}
          <BasicSpinner className="h-[25px] w-[25px] text-white" />
        </div>
      ) : previewSubscription.status === 'error' ? (
        <ErrorDisplay
          message={strapiErrorMessage['SYSTEM_ERROR']}
          type={ERROR_TYPES.SOMETHING_WENT_WRONG}
          refetch={() => {
            true;
          }}
        />
      ) : (
        <>
          <p className="text-lg text-zinc-100">
            {freeTrail ? strapiData.title_trial : strapiData.title}
          </p>
          <ul className="ml-5 mt-1 list-disc text-sm font-normal text-zinc-100">
            {membersQtyText.hasValue ? (
              <li dangerouslySetInnerHTML={{ __html: membersQtyText.text }}></li>
            ) : null}
            {freeTrialMemberlimit.hasValue ? (
              <li dangerouslySetInnerHTML={{ __html: freeTrialMemberlimit.text }}></li>
            ) : null}
            {discountText.hasValue ? (
              <li dangerouslySetInnerHTML={{ __html: discountText.text }}></li>
            ) : null}
            {firstPayment.hasValue ? (
              <li dangerouslySetInnerHTML={{ __html: firstPayment.text }}></li>
            ) : null}
            {trailDisclaimer.hasValue && freeTrail ? (
              <li dangerouslySetInnerHTML={{ __html: trailDisclaimer.text }}></li>
            ) : null}
          </ul>
          {freeTrail ? (
            <div className="mt-3 text-base font-semibold text-zinc-100">
              <span className="text-4xl font-extrabold ">{'$' + strapiData.freeTrialAmount}</span>{' '}
              {strapiData.toPayNow}
            </div>
          ) : null}
          {!freeTrail ? (
            <div className="mt-3 text-base text-zinc-100">
              <span
                className="mr-2 text-2xl font-extrabold line-through"
                hidden={(previewSubscription.data?.discountPercentage as number) < 1}
              >
                ${previewSubscription.data?.actualAmount as number}
              </span>{' '}
              <span className="text-4xl font-extrabold ">
                {'$' + previewSubscription.data?.invoiceAmount}
              </span>{' '}
              {strapiData.toPayNow}
            </div>
          ) : null}
          {/* If discount */}
          {(previewSubscription.data?.discountPercentage as number) > 0 ? (
            <div className="mt-8 flex gap-2 text-sm font-normal">
              <Tag size={24} color="#F7634F" weight="fill" />
              <span className="text-zinc-300">
                {previewSubscription.data?.discountPercentage + strapiData.discountAppliedText}
              </span>
            </div>
          ) : null}
          {!hideChangePlan && (
            <Link to="/auth/register/pricing">
              <Button
                onClick={() => dispatch(setChangePlanFlag(role))}
                className="my-3.5 flex cursor-pointer items-center justify-center bg-zinc-700 text-sm font-normal text-white"
              >
                {strapiData.changePlan}
              </Button>
            </Link>
          )}
        </>
      )}
    </div>
  );
};
