export const BASE_SIZE = {
  BYTE: 1024,
  MB: 1024 * 1024,
  GB: 1024 * 1024 * 1024,
};

export const COMMON_SIZE = {
  ONE_MB: BASE_SIZE.MB,
  FIVE_MB: BASE_SIZE.MB * 5,
  TEN_MB: BASE_SIZE.MB * 10,
  TWENTY_MB: BASE_SIZE.MB * 20,
  HUNDRED_MB: BASE_SIZE.MB * 100,
  ONE_GB: BASE_SIZE.GB,
};

export const MEDIA_SIZE_LIMIT = {
  THUMBNAIL: COMMON_SIZE.TEN_MB,
  TRAILER: COMMON_SIZE.ONE_GB,
  MEDIA: COMMON_SIZE.ONE_GB,
};
