import { OnboardingTabs } from '../types';

export const API_RESPONSE_CODES = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
};

export type InitiateAuthExceptionCode =
  | 'NotAuthorizedException'
  | 'UserNotConfirmedException'
  | 'UnHandledLoginError';

// consider the requests stale after 30 seconds
export const QUERY_STATE_TIME = 30000;

export const ENTERPRISE_ONBOARDING_TYPE = {
  PERSONAL: 'PERSONAL',
  TEAM: 'TEAM',
  ALL: 'ALL',
} as const;

export const GOALS = {
  PERSONAL_GOALS: 'Personal Goals',
  TEAM_GOALS: 'Team Goals',
} as const;

export const CATEGORIES = {
  PERSONAL_CATEGORIES: 'Personal Categories',
  TEAM_CATEGORIES: 'Team Categories',
} as const;

// Represents the steps in the onboarding
export const STEPS = {
  WELCOME: 'Welcome',
  GOALS: 'Goals',
  CATEGORIES: 'Categories',
  PROFILE: 'Profile',
  INVITE_MEMBERS: 'Invite Members',
  ALL_DONE: 'All Done!',
};

export const MAX_CATEGORY_LIMIT = 7;

export const GOALS_CONTENT_MAPPER = {
  [GOALS.PERSONAL_GOALS]: {
    header: 'What are your personal goals?',
    subHeader: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  [GOALS.TEAM_GOALS]: {
    header: "What are your team's goals?",
    subHeader: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
};

export const CATEGORIES_CONTENT_MAPPER = {
  [CATEGORIES.PERSONAL_CATEGORIES]: {
    header: 'Select your categories you like for personal recommendations',
    subHeader:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices viverra quisque amet est, eros amet. Tortor facilisi tristique ultrices non ut sollicitudin at eget lectus. Lorem accumsan non euismod sit velit nunc, sed.',
  },
  [CATEGORIES.TEAM_CATEGORIES]: {
    header: 'Select categories you like for your team',
    subHeader:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices viverra quisque amet est, eros amet. Tortor facilisi tristique ultrices non ut sollicitudin at eget lectus. Lorem accumsan non euismod sit velit nunc, sed. ',
  },
};

export const getStepMapping = (tabs: OnboardingTabs) => ({
  [STEPS.WELCOME]: tabs.welcomeTab,
  [STEPS.GOALS]: tabs.goalsTab,
  [STEPS.CATEGORIES]: tabs.categoriesTab,
  [STEPS.PROFILE]: tabs.profileTab,
  [STEPS.INVITE_MEMBERS]: tabs.inviteMemberTab,
  [STEPS.ALL_DONE]: tabs.successTab,
});

export const REQUESTS_STALE_TIME = 30000;
