import React from 'react';
import { twMerge } from 'tailwind-merge';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  className?: string;
  type: 'tel';
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  hasError?: boolean;
  // TODO: Update the logic once we implement any country selector
  enableFormatting?: boolean;
};

const PhoneNumberInput = React.forwardRef(
  (
    { className, onChange, hasError, enableFormatting = true, ...otherProps }: Props,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      // only format if the formatting is enabled
      if (enableFormatting) {
        const formattedPhoneNumber = formatPhoneNumber(event.target.value);
        event.target.value = formattedPhoneNumber;
      }

      onChange && onChange(event);
    };

    return (
      <input
        className={twMerge(
          `w-full grow rounded-[4px] border p-2 text-white placeholder:text-sm placeholder:font-normal placeholder:text-zinc-500
          ${className}
          ${
            hasError
              ? '!border-error-border bg-error-bg invalid:border-error-border invalid:text-white focus:!border-error-border focus:outline-none focus:invalid:border-error-border'
              : 'border-zinc-700 bg-zinc-900 focus-within:bg-zinc-800 focus:bg-zinc-800'
          }`
        )}
        ref={ref}
        {...otherProps}
        onChange={handleInput}
      />
    );
  }
);

PhoneNumberInput.displayName = 'PhoneNumberInput';

export default PhoneNumberInput;
