import { format, formatDuration, subMonths, isValid } from 'date-fns';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';

const getFirstDateOfPreviousMonth = () => {
  const currentDate = new Date();
  const previousMonth = subMonths(currentDate, 1);
  const firstDateOfPreviousMonth = format(previousMonth, 'yyyy-MM-dd');
  return {
    firstDateOfPreviousMonth,
  };
};

const getFirstDateOfLastThreeMonths = () => {
  const currentDate = new Date();
  // Subtract 3 from current date to get the last 3rd Month
  const lastThreeMonths = subMonths(currentDate, 3);
  const firstDateOfLastThreeMonths = format(lastThreeMonths, 'yyyy-MM-dd');
  return {
    firstDateOfLastThreeMonths,
  };
};

const getFirstDateOfLastSixMonths = () => {
  const currentDate = new Date();
  // Subtract 6 from current date to get the last 6th Month
  const lastSixMonths = subMonths(currentDate, 6);
  const firstDateOfLastSixMonths = format(lastSixMonths, 'yyyy-MM-dd');
  return {
    firstDateOfLastSixMonths,
  };
};

/**
 * Method to construct duration format
 * @param durationInMin - duration
 * @param hoursLabel - Hours Label from Strapi - hrs
 * @param minsLabel - Mins Label from Strapi - mins
 * @returns Formatted Duration
 */
const calcTimeString = (
  durationInMin: number,
  hoursLabel = 'h',
  minsLabel = 'm',
  secondsLabel = 's'
) => {
  if (!durationInMin) {
    return '-';
  }
  const hours = Math.floor(durationInMin / 60);
  const minutes = Math.floor(durationInMin - hours * 60);
  const seconds = Math.floor((durationInMin - minutes - hours * 60) * 60);
  const formatDistanceLocale = {
    xMinutes: `{{count}} ${minsLabel}`,
    xHours: `{{count}} ${hoursLabel}`,
    xSeconds: `{{count}} ${secondsLabel}`,
  };
  const shortEnLocale = {
    formatDistance: (token: keyof typeof formatDistanceLocale, count: any) => {
      return formatDistanceLocale[token].replace('{{count}}', count);
    },
  };
  return (
    formatDuration(
      { hours, minutes, seconds },
      { format: ['hours', 'minutes', 'seconds'], locale: shortEnLocale }
    ) || '-'
  );
};

/**
 * Function is to format time in hours(fractional) to hrs and mins format
 * @param hours - duration in hours(fractional)
 * @returns Formatted Duration
 */
export const formatTime = (hours: number | string) => {
  const durationInMinutes = Number(hours) * 60;
  const result = calcTimeString(durationInMinutes);
  if (result === '-') {
    return '0 m';
  } else {
    return result;
  }
};

/**
 *
 * @param d1 - date1
 * @param d2 - date2
 * @param locale - locale
 * @returns formatted date range based on the locale and options provided
 */
const formatDateRange = (d1: string, d2: string, locale = DEFAULT_PREFERRED_LANGUAGE) => {
  if (!(d1 && d2)) {
    return '';
  }

  /* Checking if the date is valid or not. */
  if (!(isValid(new Date(d1)) && isValid(new Date(d2)))) {
    return '';
  }

  return new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
  }).formatRange(new Date(d1), new Date(d2));
};

export {
  calcTimeString,
  getFirstDateOfPreviousMonth,
  getFirstDateOfLastThreeMonths,
  getFirstDateOfLastSixMonths,
  formatDateRange,
};
