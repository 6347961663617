import { DotsSixVertical } from 'phosphor-react';
import { ModuleAssessmentRowItemType } from '../../../../types/courses';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Dialog from '../../../../../../components/Dialog';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { MODULE_TYPE } from '../../../../constants';
import { useStrapiHQAdminCoursesData } from '../../../../hooks/useStrapiHQAdminCourseData';

interface ModuleAssessmentRowItemProps {
  item: ModuleAssessmentRowItemType;
  refetch?: any;
}
const ModuleAssessmentRowItem = ({ item }: ModuleAssessmentRowItemProps) => {
  const [isModuleModalOpen, setIsModuleModalOpen] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const { singleCourseView } = useStrapiHQAdminCoursesData();

  const { inactiveAssessmentStatusLabel } = singleCourseView.ModuleDetails;
  return (
    <div
      className="my-4 rounded-lg border border-zinc-700 bg-zinc-900 p-3"
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <Dialog
        open={isModuleModalOpen}
        onOpenChange={(open) => setIsModuleModalOpen(open)}
        onInteractOutside={(e) => {
          // Donot close the Modal when we click outside the Dialog Content
          e.preventDefault();
        }}
        Content={
          item.data.type === MODULE_TYPE.ASSESSMENT ? (
            <Navigate to={`assessment/${item.data.id}`} />
          ) : (
            <Navigate to={`module/${item.data.id}`} />
          )
        }
      >
        <div className="flex flex-col items-center gap-2 xs:flex-row xs:justify-between xs:gap-0">
          <div className="flex items-center gap-2 text-left text-sm font-medium text-zinc-50">
            <span>{item.data.sort}.&nbsp;&nbsp;</span>
            <span>{item.data.title}</span>
            {item.data.isActive === false && (
              <span className="rounded-3xl bg-zinc-700 px-2 text-[10px] font-bold text-zinc-200">
                {inactiveAssessmentStatusLabel}
              </span>
            )}
          </div>
          <div className="flex flex-row items-center justify-between gap-2 text-xs text-zinc-400 xs:justify-end">
            {item.data.type}
            <DotsSixVertical size={28} {...listeners} className="transition hover:text-zinc-300" />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ModuleAssessmentRowItem;
