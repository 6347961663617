import { API_BASE_URL, AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../constants';

const config = {
  endpoints: [
    {
      name: AMPLIFY_USHG_API_ENDPOINT_NAME,
      endpoint: API_BASE_URL,
    },
  ],
};
export default config;
