import { IdentificationCard } from 'phosphor-react';
import SideBar from '../../../../../components/SideBar';
import { useStrapiSideNavData } from '../../../../../hooks/useStrapiSideNavData';
import { CustomSideNavItemProps } from '../../../types';

const IndividualNavItem = ({
  eventsCount,
  children,
}: React.PropsWithChildren<CustomSideNavItemProps>) => {
  const { routes } = useStrapiSideNavData();
  return (
    <SideBar.SideBarNavItem
      icon={
        <IdentificationCard
          aria-label="individual"
          size="20px"
          color="currentColor"
          className="text-inherit"
        />
      }
      label={routes.individualLabel}
      path="manage/individual"
      eventsCount={eventsCount}
    >
      {children}
    </SideBar.SideBarNavItem>
  );
};

export default IndividualNavItem;
