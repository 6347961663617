interface Props {
  OrganisationName: string;
}

const OrganisationNameCell = ({ OrganisationName }: Props) => {
  return (
    <div className="flex space-x-1.5">
      <span className="whitespace-nowrap">{OrganisationName}</span>
    </div>
  );
};

export default OrganisationNameCell;
