import { CaretDown, Translate } from 'phosphor-react';
import React from 'react';
import { useStrapiSharedData } from '../../hooks/useStrapiSharedData';

const LanguageTrigger = () => {
  // strapi data
  const {
    language: { languageLabel },
  } = useStrapiSharedData();
  return (
    <div className="flex items-center gap-2.5 text-zinc-50">
      <Translate className="text-inherit" color="currentColor" size={14} weight="bold" />
      <span className="hidden text-xs font-medium leading-5 text-inherit sm:inline">
        {languageLabel}
      </span>
      <CaretDown className="text-inherit" color="currentColor" size={14} weight="bold" />
    </div>
  );
};

export default LanguageTrigger;
