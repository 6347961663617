import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { PaginationFooter } from '../../../../../components/DataTables';
import { BasicSpinner } from '../../../../../components/Spinners';
import { useStrapiOrganizationData } from '../../../../organisation/hooks/useStrapiOrganizationData';
import {
  ALL_MEMBERS_TABLE_MAX_PAGE_SIZE,
  ALL_MEMBERS_TABLE_MIN_PAGE_SIZE,
  ALL_MEMBERS_TABLE_PAGE_SIZE_INCREMENT,
} from '../../../constants';
import { useAllMembersTableFilters, useGetAllMembersInOrganizationQuery } from '../../../hooks';
import {
  AllMembersTableEmptyState,
  AllMembersTableErrorState,
  AllMembersTableHeader,
} from './components';
import AllMembersTable from './components/AllMembersTable';

interface Props {
  className?: string;
  tenantId: string;
  tableHeadClassName?: string;
  isOrganization?: boolean;
  getAllMembersQuery?: any;
}

const AllMembersTableMain = (props: Props) => {
  const { className, tenantId, isOrganization = false } = props;
  const {
    page,
    setPage,
    pageSize,
    setPageSize,
    requestFilters,
    isFilterApplied,
    searchQuery,
    userStatus,
  } = useAllMembersTableFilters();

  const getAllMembersQuery = useGetAllMembersInOrganizationQuery({
    tenantId,
    filters: requestFilters,
  });

  // Display loader during initial loading and if we are fetching when there are no items.
  const displayLoader =
    (getAllMembersQuery?.isLoading && !getAllMembersQuery.data) ||
    // If we applied a filter and the we got no data
    ((getAllMembersQuery.isFetching || getAllMembersQuery.isRefetching) &&
      getAllMembersQuery.data?.totalCount === 0);

  const isEmptyState = getAllMembersQuery.data && getAllMembersQuery.data.totalCount == 0;

  // Has no members when no filter is applied and we don't have any data
  const hasNoMembers =
    getAllMembersQuery.data &&
    getAllMembersQuery.data.totalCount == 0 &&
    !getAllMembersQuery.isPreviousData &&
    isFilterApplied === false;
  const { singleView } = useStrapiOrganizationData();
  const { membersTableHeader } = singleView.TableHeadings;
  return (
    <div
      className={twMerge(
        'flex flex-col items-start gap-4 rounded-sm bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)] md:p-5 lg:p-6',
        className
      )}
    >
      {isOrganization && <div className=" text-white">{membersTableHeader}</div>}
      <>
        {/* Display only if the user has requests */}
        {!hasNoMembers && (
          <AllMembersTableHeader
            searchQuery={searchQuery}
            userStatus={userStatus}
            isFilterApplied={isFilterApplied}
          />
        )}
        {/* Is Loading and We donot have data or Is Fetching and we have data as empty array */}
        {displayLoader && (
          <div className="flex min-h-[250px] w-full items-center justify-center py-4">
            <BasicSpinner className="text-white" />
          </div>
        )}
        {/* We have data */}
        {getAllMembersQuery.data && getAllMembersQuery.data.totalCount > 0 && (
          <div
            className={clsx(
              'relative flex w-full flex-col gap-4',
              getAllMembersQuery.isPreviousData &&
                getAllMembersQuery.isFetching &&
                'pointer-events-none opacity-70'
            )}
          >
            {getAllMembersQuery.isPreviousData && getAllMembersQuery.isFetching && (
              <div className="flex w-full justify-center">
                <BasicSpinner className="text-center text-white" />
              </div>
            )}

            <div className="relative w-full overflow-y-scroll">
              {getAllMembersQuery.isError ? (
                <AllMembersTableErrorState
                  isRefetching={getAllMembersQuery.isRefetching}
                  refetch={getAllMembersQuery.refetch}
                />
              ) : (
                <AllMembersTable
                  isOrganization={isOrganization}
                  data={getAllMembersQuery.data.data}
                />
              )}
            </div>

            <PaginationFooter
              setPage={setPage}
              activePage={page}
              totalItems={getAllMembersQuery.data.totalCount}
              pageSize={pageSize}
              setPageSize={setPageSize}
              maxPageSize={ALL_MEMBERS_TABLE_MAX_PAGE_SIZE}
              minPageSize={ALL_MEMBERS_TABLE_MIN_PAGE_SIZE}
              pageSizeIncrement={ALL_MEMBERS_TABLE_PAGE_SIZE_INCREMENT}
            />
          </div>
        )}

        {/* Empty State */}
        {isEmptyState && !displayLoader && (
          <div className="flex min-h-[250px] w-full items-center justify-center">
            <AllMembersTableEmptyState isFilterApplied={isFilterApplied} />
          </div>
        )}

        {getAllMembersQuery.isError && !getAllMembersQuery.data && (
          <div className="flex min-h-[250px] w-full items-center justify-center">
            <AllMembersTableErrorState
              isRefetching={getAllMembersQuery.isRefetching}
              refetch={getAllMembersQuery.refetch}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default AllMembersTableMain;
