import { useCallback } from 'react';
import AlertDialog from '../../../../../../../../../components/AlertDialog';
import { useDeactivateEnterpriseUserMutation } from '../../../../../../../hooks';
import useStrapiMembersData from '../../../../../../../hooks/useStrapiMembersData';

interface Props {
  username: string;
  closeDialog: () => void;
  teamId?: number;
}

const DeactivateUserDialogContent = ({ username, closeDialog, teamId }: Props) => {
  const deactivateUserMutation = useDeactivateEnterpriseUserMutation({ teamId });
  const { deactivateModal } = useStrapiMembersData();
  const { title, message, cancelButton, actionButton } = deactivateModal;
  const deActivateEnterpriseUser = useCallback(
    async ({ username }: { username: string }) => {
      try {
        await deactivateUserMutation.mutateAsync({
          username,
        });

        closeDialog();
      } catch (error) {
        console.error(`Error activating user with email ${username}`, error);
      }
    },
    [closeDialog, deactivateUserMutation]
  );

  const substitutedMessage = message?.replace('{{username}}', username);

  return (
    <AlertDialog
      Title={title}
      Content={<>{substitutedMessage}</>}
      actionItem={{
        label: actionButton,
        action: () => {
          deActivateEnterpriseUser({ username });
        },
      }}
      cancelItem={{
        label: cancelButton,
      }}
      isLoading={deactivateUserMutation.isLoading}
      isActionDiabled={deactivateUserMutation.isLoading}
    />
  );
};

export default DeactivateUserDialogContent;
