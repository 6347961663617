import { getDateInMMDDYYY } from '../../../../utils/getDateInMMDDYYY';

interface TimestampCellProps {
  timestamp: number;
}

const TimestampCell = (props: TimestampCellProps) => {
  const { timestamp } = props;
  const displayDate = getDateInMMDDYYY(timestamp * 1000);
  return <span className="whitespace-nowrap">{displayDate}</span>;
};

export default TimestampCell;
