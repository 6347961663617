import { ArrowDown, ArrowUp } from 'phosphor-react';
import { formatNumber } from '../../../../../../utils';

interface Props {
  data: any;
}
const CourseStatisticsCard = ({ data }: Props) => {
  return (
    <div className="flex h-[110px] flex-col gap-3 rounded-lg bg-[#252528] p-4">
      <span className="text-xs font-medium text-zinc-300 lg:text-sm">{data.heading}</span>
      <div className="flex items-center justify-between gap-2">
        <span className="text-right text-4xl font-bold tracking-tight text-zinc-50">
          {data.value}
        </span>

        {parseFloat(data.percentage) > 0 ? (
          <div className="flex items-center rounded-lg bg-[#064E3B] p-[2px_10px_2px_8px]">
            <ArrowUp size={12} className={'text-emerald-50'} weight={'bold'} />
            <span className="text-sm font-medium text-emerald-50">
              {data.percentage ? formatNumber(data.percentage) : 0}%
            </span>
          </div>
        ) : parseFloat(data.percentage) < 0 ? (
          <div className="flex items-center rounded-lg bg-red-900 p-[2px_10px_2px_8px]">
            <ArrowDown size={12} className={'text-red-50'} weight={'bold'} />
            <span className="text-sm font-medium text-emerald-50">
              {Math.abs((data.percentage ? formatNumber(data.percentage) : 0) as number)}%
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CourseStatisticsCard;
