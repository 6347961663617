import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { COURSES_API_PATHS } from '../constants';
import {
  CourseCreateMutationType,
  UpdateOrderType,
  CoursesBasicDetailsResponse,
  CourseUpdateMutationType,
  AssessmentBasicDetails,
  AssessmentQuestionListArgs,
  ModuleCreateMutationType,
  ModuleUpdateMutationType,
  AssessmentArgs,
  AssessmentQuestionArgs,
  UpdateAssessmentQuestionOrderArgs,
  PublishCourseType,
  updateQuestionArgs,
  ModuleBasicDetails,
  HQCourseStatisticsResponse,
  PartialUSHGAdminCourseModulesFilter,
  getTagsQueryFilterType,
  TagsResponseType,
  CourseCreateInMultiLangMutationType,
  AssessmentInMultiLangArgs,
  AssessmentQuestionInMultiLangArgs,
  AssessmentQuestionsByIdResponse,
} from '../types';

const getCategories = (language: string) => {
  const queryString = qs.stringify({ language });
  return API.get(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    `${COURSES_API_PATHS.GET_CATEGORIES}?` + queryString,
    {
      withCredentials: true,
    }
  );
};

const getSpeakers = () => {
  return API.get(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    `${COURSES_API_PATHS.GET_SPEAKERS}?status=active`,
    {
      withCredentials: true,
    }
  );
};

const getTags = (filters: getTagsQueryFilterType): Promise<TagsResponseType> => {
  return API.get(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    `${COURSES_API_PATHS.GET_TAGS}?${qs.stringify(filters)}`,
    {
      withCredentials: true,
    }
  );
};

const createCourse = (data: CourseCreateMutationType) => {
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, COURSES_API_PATHS.HQ.CREATE_COURSE, {
    body: data,
    response: true,
    withCredentials: true,
  });
};

const createCourseInMultiLang = (data: CourseCreateInMultiLangMutationType) => {
  return API.post(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    COURSES_API_PATHS.HQ.CREATE_COURSE_IN_MULTI_LANG,
    {
      body: data,
      response: true,
      withCredentials: true,
    }
  );
};

const getModules = (id: string, language: string) => {
  const queryString = qs.stringify({ language });
  return API.get(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    `${COURSES_API_PATHS.HQ.GET_MODULES(String(id))}?` + queryString,
    {
      withCredentials: true,
    }
  );
};

const updateOrderRequest = (payload: UpdateOrderType) =>
  API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, COURSES_API_PATHS.HQ.UPDATE_MODULE_ORDER, {
    body: payload,
    withCredentials: true,
  });

interface getCourseBasicDetailsArgs {
  id: number;
  language: string;
}

interface getCourseStatisticsArgs {
  id: number;
}

interface getUSHGAdminCourseModulesArgs {
  courseId: number;
  filters: PartialUSHGAdminCourseModulesFilter;
}

interface getUSHGAdminCourseFeedbacksArgs {
  courseId: number;
  filters: PartialUSHGAdminCourseModulesFilter;
}

const getCourseBasicDetails = async ({
  id,
  language,
}: getCourseBasicDetailsArgs): Promise<CoursesBasicDetailsResponse> => {
  const path = COURSES_API_PATHS.HQ.GET_COURSE_BASIC_DETAILS(id);
  const queryString = qs.stringify({ language });
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const getUSHGAdminCourseModules = async ({
  filters,
  courseId,
}: getUSHGAdminCourseModulesArgs): Promise<{ results: any[]; totalCount: number }> => {
  const queryString = qs.stringify(filters);
  const path = COURSES_API_PATHS.HQ.GET_USHG_ADMIN_COURSE_MODULES(courseId);
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const getUSHGAdminCourseFeedbacks = async ({
  filters,
  courseId,
}: getUSHGAdminCourseFeedbacksArgs): Promise<{ results: any[]; totalCount: number }> => {
  const queryString = qs.stringify(filters);
  const path = COURSES_API_PATHS.HQ.GET_USHG_ADMIN_COURSE_FEEDBACKS(courseId);
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const getCourseStatistics = async ({
  id,
}: getCourseStatisticsArgs): Promise<HQCourseStatisticsResponse> => {
  const path = COURSES_API_PATHS.GET_COURSE_STATISTICS(id);
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

interface getAssessmentBasicDetailsArgs {
  assessmentId: number;
  language: string;
}
interface getModuleBasicDetailsArgs {
  moduleId: number;
  language: string;
}

const getAssessmentBasicDetails = async ({
  assessmentId,
  language,
}: getAssessmentBasicDetailsArgs): Promise<AssessmentBasicDetails> => {
  const path = COURSES_API_PATHS.HQ.GET_ASSESSMENT_BASIC_DETAILS(assessmentId);
  const queryString = qs.stringify({ language });
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });

  return response.data;
};

const getModuleBasicDetails = async ({
  moduleId,
  language,
}: getModuleBasicDetailsArgs): Promise<ModuleBasicDetails> => {
  const path = COURSES_API_PATHS.HQ.GET_MODULE_BASIC_DETAILS(moduleId);
  const queryString = qs.stringify({ language });
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });

  return response.data;
};

const getAssessmentQuestions = async ({
  assessmentId,
}: getAssessmentBasicDetailsArgs): Promise<AssessmentQuestionListArgs[]> => {
  const path = COURSES_API_PATHS.HQ.GET_ASSESSMENT_QUESTIONS(assessmentId);
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const updateCourseDetails = (id: number, update: CourseUpdateMutationType) => {
  return API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, COURSES_API_PATHS.HQ.UPDATE_COURSE_DETAILS(id), {
    body: update,
    response: true,
    withCredentials: true,
  });
};

interface getAssessmentQuestionByIdArgs {
  questionId: number;
  language: string;
}

const getAssessmentQuestionsById = ({
  questionId,
  language,
}: getAssessmentQuestionByIdArgs): Promise<AssessmentQuestionsByIdResponse> => {
  const path = COURSES_API_PATHS.HQ.GET_ASSESSMENT_QUESTIONS_BY_ID(questionId);
  const queryString = qs.stringify({ language });
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    withCredentials: true,
  });
};

const createModule = (create: ModuleCreateMutationType) => {
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, COURSES_API_PATHS.HQ.CREATE_MODULE, {
    body: create,
    response: true,
    withCredentials: true,
  });
};

const createModuleInMultiLang = (create: ModuleCreateMutationType) => {
  return API.post(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    COURSES_API_PATHS.HQ.CREATE_MODULE_IN_MULTI_LANG,
    {
      body: create,
      response: true,
      withCredentials: true,
    }
  );
};

const updateModule = (update: ModuleUpdateMutationType) => {
  return API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, COURSES_API_PATHS.HQ.UPDATE_MODULE, {
    body: update,
    response: true,
    withCredentials: true,
  });
};

/**
 * Create a new Assessment
 */
const createAssessment = (args: AssessmentArgs) => {
  const path = COURSES_API_PATHS.HQ.MODULE;
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body: args,
    response: true,
    withCredentials: true,
  });
};

const createAssessmentInMultiLang = (args: AssessmentInMultiLangArgs) => {
  const path = COURSES_API_PATHS.HQ.CREATE_MODULE_IN_MULTI_LANG;
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body: args,
    response: true,
    withCredentials: true,
  });
};

/**
 * Update Assessment
 */
const updateAssessment = (args: AssessmentArgs) => {
  const path = COURSES_API_PATHS.HQ.MODULE;
  return API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body: args,
    response: true,
    withCredentials: true,
  });
};

/**
 * Add Assessment Question
 */
const addAssessmentQuestion = (args: AssessmentQuestionArgs) => {
  const path = COURSES_API_PATHS.HQ.ASSESSMENT_QUESTION;
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body: args,
    response: true,
    withCredentials: true,
  });
};

const addAssessmentQuestionInMultiLang = (args: AssessmentQuestionInMultiLangArgs) => {
  const path = COURSES_API_PATHS.HQ.ASSESSMENT_QUESTION_IN_MULTI_LANG;
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body: args,
    response: true,
    withCredentials: true,
  });
};

/**
 * Update Assessment Question
 */
const updateAssessmentQuestion = (args: updateQuestionArgs) => {
  const path = COURSES_API_PATHS.HQ.ASSESSMENT_QUESTION;
  return API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body: args,
    response: true,
    withCredentials: true,
  });
};

/**
 * Update Assessment Question order
 */
const updateAssessmentQuestionOrder = (args: UpdateAssessmentQuestionOrderArgs) =>
  API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, COURSES_API_PATHS.HQ.UPDATE_ASSESSMENT_QUESTION_ORDER, {
    body: args,
    response: true,
    withCredentials: true,
  });

const publishCourse = (args: PublishCourseType) =>
  API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, COURSES_API_PATHS.HQ.PUBLISH_COURSE(args.id), {
    body: { isActive: args.isActive },
    response: true,
  });

export {
  getCategories,
  getSpeakers,
  getTags,
  createCourse,
  createCourseInMultiLang,
  getModules,
  updateOrderRequest,
  getCourseBasicDetails,
  getAssessmentBasicDetails,
  getAssessmentQuestions,
  getAssessmentQuestionsById,
  updateCourseDetails,
  createModule,
  createModuleInMultiLang,
  updateModule,
  createAssessment,
  createAssessmentInMultiLang,
  updateAssessment,
  addAssessmentQuestion,
  addAssessmentQuestionInMultiLang,
  updateAssessmentQuestion,
  updateAssessmentQuestionOrder,
  publishCourse,
  getModuleBasicDetails,
  getCourseStatistics,
  getUSHGAdminCourseModules,
  getUSHGAdminCourseFeedbacks,
};
