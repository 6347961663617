import { PaperPlaneTilt } from 'phosphor-react';
import { HelpWidgetItem } from './HelpWidgetItem';
import { HelpWidgetItemContent } from './HelpWidgetItemContent';
import { useStrapiSharedData } from '../../hooks/useStrapiSharedData';

const MailtoContactUsWidget = () => {
  const { helpAndResourcesWidget: cms } = useStrapiSharedData();
  const mailtoURI = `mailto:${cms.contactHQSupportEmail}`;

  return (
    <HelpWidgetItem>
      <a href={mailtoURI}>
        <HelpWidgetItemContent Icon={PaperPlaneTilt} label={cms.contactHQSupportLabel} />
      </a>
    </HelpWidgetItem>
  );
};

export default MailtoContactUsWidget;
