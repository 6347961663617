import { USHG_HQ_APP_ROLE } from '../../../constants';
import { NOTIFICATION_PREFERENCE_CATEGORY, NOTIFICATION_PREFERENCE_TYPE } from '../constants';
import { NotificationPreferenceCategoryDefinition } from '../types';

const getUSHGAdminNotificationPreferencesConfig = (args: {
  labels: {
    heading: string;
    description: string;
    newSignup: string;
    paymentOverdue: string;
    newSupportRequest: string;
    newCourseFeedback: string;
    subscriptionCancelled: string;
  };
}) => {
  const { labels } = args;

  const config: NotificationPreferenceCategoryDefinition = {
    categoryId: NOTIFICATION_PREFERENCE_CATEGORY.SIGNUPS_PAYMENTS_REQUESTS_AND_COURSES,
    categoryHeading: labels.heading,
    categoryDescription: labels.description,
    preferences: [
      {
        notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.NEW_HQ_SIGNUP,
        notificationTypeName: labels.newSignup,
      },
      {
        notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.PAYMENT_OVERDUE,
        notificationTypeName: labels.paymentOverdue,
      },
      {
        notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.NEW_COURSE_FEEDBACK_ADDED,
        notificationTypeName: labels.newCourseFeedback,
      },
      {
        notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.NEW_SUPPORT_REQUEST_RAISED,
        notificationTypeName: labels.newSupportRequest,
      },
      {
        notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.SUBSCRIPTION_CANCELLED,
        notificationTypeName: labels.subscriptionCancelled,
      },
    ],
  };

  return config;
};

const getUserManagementNotificationPreferencesConfig = (args: {
  role: keyof typeof USHG_HQ_APP_ROLE;
  labels: {
    heading: string;
    description: string;
    entNewTeamCreated: string;
    entNewMemberOnboarded: string;
    newEnterpriseTeamRequest: string;
    newEnterpriseUserRequest: string;
    enterpriseEditTeamRequest: string;
    entUserTeamChangeManager: string;
    entUserTeamChangeAdmin: string;
  };
}) => {
  const { role, labels } = args;

  const config: NotificationPreferenceCategoryDefinition = {
    categoryId: NOTIFICATION_PREFERENCE_CATEGORY.USER_MANAGEMENT,
    categoryHeading: labels.heading,
    categoryDescription: labels.description,
    preferences: [],
  };

  if (role === USHG_HQ_APP_ROLE.ENTERPRISE_LEADER || role === USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN) {
    config.preferences = [
      ...config.preferences,
      {
        notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.NEW_ENTERPRISE_USER_ONBOARDED,
        notificationTypeName: labels.entNewMemberOnboarded,
      },
      {
        notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.NEW_ENTERPRISE_TEAM_CREATED,
        notificationTypeName: labels.entNewTeamCreated,
      },
      {
        notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.ENTERPRISE_USER_TEAM_CHANGE,
        notificationTypeName: labels.entUserTeamChangeAdmin,
      },
    ];
  }

  if (role === USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER) {
    config.preferences = [
      ...config.preferences,
      {
        notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.ENTERPRISE_USER_TEAM_CHANGE,
        notificationTypeName: labels.entUserTeamChangeManager,
      },
    ];
  }

  // Requests related notification preferences should be available only to enterprise admins
  if (role === USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN) {
    config.preferences = [
      ...config.preferences,
      {
        notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.NEW_ENTERPRISE_TEAM_REQUEST,
        notificationTypeName: labels.newEnterpriseTeamRequest,
      },
      {
        notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.NEW_ENTERPRISE_USER_REQUEST,
        notificationTypeName: labels.newEnterpriseUserRequest,
      },
      {
        notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.ENTERPRISE_EDIT_TEAM_REQUEST,
        notificationTypeName: labels.enterpriseEditTeamRequest,
      },
    ];
  }

  return config;
};

const getCoursesAndBadgesNotificationPreferencesConfig = (args: {
  role: keyof typeof USHG_HQ_APP_ROLE;
  labels: {
    heading: string;
    description: string;
    newBadgeEarned: string;
    newCourseAssigned: string;
    assignedCourseOverdue: string;
  };
}) => {
  const { role, labels } = args;

  let config: NotificationPreferenceCategoryDefinition = {
    categoryId: NOTIFICATION_PREFERENCE_CATEGORY.COURSES_AND_BADGES,
    categoryHeading: labels.heading,
    categoryDescription: labels.description,
    preferences: [
      {
        notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.NEW_BADGE_EARNED,
        notificationTypeName: labels.newBadgeEarned,
      },
    ],
  };

  //   Individual User would only have new badge earned notification
  if (role !== USHG_HQ_APP_ROLE.INDIVIDUAL_USER) {
    config = {
      ...config,
      preferences: [
        ...config.preferences,
        {
          notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.COURSE_ASSIGNED,
          notificationTypeName: labels.newCourseAssigned,
        },
        {
          notificationTypeId: NOTIFICATION_PREFERENCE_TYPE.COURSE_DUE,
          notificationTypeName: labels.assignedCourseOverdue,
        },
      ],
    };
  }

  return config;
};

export {
  getCoursesAndBadgesNotificationPreferencesConfig,
  getUserManagementNotificationPreferencesConfig,
  getUSHGAdminNotificationPreferencesConfig,
};
