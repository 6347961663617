import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GetAnnouncementResponse } from '../../shared/api/announcement';
import { showToast } from '../../shared/helpers/toast';
import { CreateAnnouncementParams, createAnnouncement } from '../api';
import { useStrapiSettingsData } from './useStrapiSettingsData';
import { useReduxAuthState } from '../../../hooks';

const useCreateAnnouncementMutation = () => {
  // query client
  const queryClient = useQueryClient();

  const auth = useReduxAuthState();
  // query client

  // strapi content
  const { hqAdminPlatformAnnouncement: announcementCMS } = useStrapiSettingsData();
  // strapi content

  const mutation = useMutation({
    mutationFn: async (params: CreateAnnouncementParams) => {
      const response = await createAnnouncement(params);
      return response;
    },
    onSuccess: async (response, variables) => {
      // Update the language-specific query
      queryClient.setQueryData(
        [
          {
            scope: 'shared',
            item: 'announcement',
            language: variables.language, // Language-specific update
          },
        ],
        (): GetAnnouncementResponse => {
          return {
            id: response.id,
            data: {
              markdownContent: response.data?.markdownContent,
            },
          };
        }
      );

      // Update the user (banner) query
      if (auth.user && auth.user.locale) {
        if (auth.user.locale === variables.language) {
          queryClient.setQueryData(
            [
              {
                scope: 'shared',
                item: 'announcement',
              },
            ],
            (): GetAnnouncementResponse => {
              return {
                id: response.id,
                data: {
                  markdownContent: response.data?.markdownContent,
                },
              };
            }
          );
        }
      }

      showToast({
        variant: 'success',
        title: announcementCMS.announcementCreateSuccessToast,
      });
    },
    onError: async (_err, _variables) => {
      showToast({
        variant: 'error',
        title: announcementCMS.announcementCreateErrorToast,
      });
    },
  });

  return mutation;
};

export default useCreateAnnouncementMutation;
