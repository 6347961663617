import { ChartData } from 'chart.js';
import clsx from 'clsx';
import { useContext, useMemo, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { ErrorDisplay, NoDataDisplay } from '../../../../components/DataTables';
import { defaultGridOptions, defaultTickOptions, defaultTitleOptions } from '../../config';
import durationContext from '../../context/durationContext';
import { useEnterpriseAverageWeeklyHoursQuery } from '../../hooks';
import { useStrapiReportsData } from '../../hooks/useStrapiReportData';
import {
  AverageWeeklyHours,
  BarChartOptions,
  DashboardReportsFilter,
  ReportTooltipDataType,
} from '../../types';
import { BarChart } from './baseCharts';
import ChartHeading from './ChartHeading';
import ScaleLoader from '../../../../components/Loader/ScaleLoader';
import { REPORT_TYPES } from '../../constants';
import ReportTooltip from '../../../../components/ReportToolTip';

interface Props {
  className?: string;
  heading?: string;
  lazyLoad?: boolean;
  errorChartmessage?: string;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}

const AverageWeeklyHoursViz = ({
  className,
  heading,
  lazyLoad = true,
  errorChartmessage,
  reportTooltipContent,
  userPersona,
}: Props) => {
  const { duration } = useContext(durationContext);
  const { xAxisLabel, yAxisLabel, emptyChart, errorChart, emptyChartIcon } =
    useStrapiReportsData().AverageWeeklyHours;
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);

  // Build filter
  const filters: DashboardReportsFilter = useMemo(() => {
    return {
      fromDate: duration.fromDate,
      interval: duration.interval,
    };
  }, [duration]);

  const averageWeeklyHoursActivity = useEnterpriseAverageWeeklyHoursQuery({
    filters,
    isEnabled: isInView,
  });

  let hasNoAverageWeeklyHours = true;
  const results = averageWeeklyHoursActivity.data ? averageWeeklyHoursActivity.data.results : [];
  // revist after prod release on 28 Feb 2024
  for (const result of results) {
    if (result.count !== 0) {
      hasNoAverageWeeklyHours = false; // If any count is not zero, set to false
      break;
    }
  }
  const hasAverageWeeklyHours =
    averageWeeklyHoursActivity.data &&
    averageWeeklyHoursActivity.data.results &&
    averageWeeklyHoursActivity.data.results.length > 0 &&
    hasNoAverageWeeklyHours === false;

  const isLoadingAverageWeeklyHours = averageWeeklyHoursActivity.isLoading;

  const data = useMemo(() => {
    let labels: string[] = [];
    let averageWeeklyHours: number[] = [];

    if (hasAverageWeeklyHours) {
      // Chartjs labels
      labels = averageWeeklyHoursActivity.data?.results.map(
        (result: AverageWeeklyHours) => result.pool
      );
      averageWeeklyHours = averageWeeklyHoursActivity.data?.results.map(
        (result: AverageWeeklyHours) => +result.count
      );
    }

    const data: ChartData<'bar', number[], unknown> = {
      labels,
      datasets: [
        {
          data: averageWeeklyHours || [],
          borderColor: ['#F7634F', '#FF9758', '#F3EAC7', '#C4D500', '#37990E'],
          backgroundColor: ['#F7634F', '#FF9758', '#F3EAC7', '#C4D500', '#37990E'],
        },
      ],
    };
    return data;
  }, [hasAverageWeeklyHours, averageWeeklyHoursActivity.data]);

  const chartOptions: BarChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
      },
    },
    datasets: {
      bar: {
        barThickness: 13,
      },
    },
    scales: {
      x: {
        title: {
          ...defaultTitleOptions,
          text: xAxisLabel,
        },
        grid: {
          ...defaultGridOptions,
        },
        ticks: {
          ...defaultTickOptions,
          padding: 8,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          ...defaultTitleOptions,
          text: yAxisLabel,
        },
        grid: {
          ...defaultGridOptions,
        },

        ticks: {
          ...defaultTickOptions,
          precision: 0,
          padding: 12,
        },
      },
    },
  };

  return (
    <InView
      as="div"
      className={clsx(
        'flex h-full min-h-[420px] flex-col gap-5 rounded-lg bg-card-bg pt-4 pb-7',
        className
      )}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div className="flex items-center gap-2 px-4">
        <ChartHeading heading={heading as string} />
        {reportTooltipContent?.[REPORT_TYPES.AVERAGE_WEEKLY_HOURS]?.[userPersona] && (
          <ReportTooltip
            content={reportTooltipContent[REPORT_TYPES.AVERAGE_WEEKLY_HOURS][userPersona]}
          />
        )}
      </div>

      {isLoadingAverageWeeklyHours && !hasAverageWeeklyHours && (
        <div className="flex grow items-center justify-center">
          <ScaleLoader />
        </div>
      )}
      {/* Error State */}
      <>
        {averageWeeklyHoursActivity.error && !averageWeeklyHoursActivity.data && (
          <div className="flex grow items-center justify-center">
            <ErrorDisplay
              message={errorChart}
              refetch={averageWeeklyHoursActivity.refetch}
              allowsRefetch={true}
              isRefetching={averageWeeklyHoursActivity.isRefetching}
            />
          </div>
        )}
      </>
      {hasAverageWeeklyHours && (
        <BarChart
          options={chartOptions}
          className="m-auto max-h-80 min-h-[20rem] w-full px-4"
          data={data}
        />
      )}
      {hasNoAverageWeeklyHours && !isLoadingAverageWeeklyHours && (
        <NoDataDisplay
          message={errorChartmessage ? (errorChartmessage as string) : (emptyChart as string)}
          IconUrl={emptyChartIcon.data.attributes.url}
        />
      )}
    </InView>
  );
};

export default AverageWeeklyHoursViz;
