import { useQuery } from '@tanstack/react-query';
import { PERFORMANCE_REPORT_TYPES, REPORTS_STALE_TIME } from '../constants';
import { getCoursePerformanceRates } from '../api';
import { DashboardReportsFilter } from '../types/reports/reports';

interface Props {
  filters: Partial<DashboardReportsFilter>;
  isEnabled: boolean;
}
const useCoursePerformanceRatesQuery = ({ filters, isEnabled }: Props) => {
  const query = useQuery(
    [
      {
        filters: filters,
        scope: 'reports',
        item: PERFORMANCE_REPORT_TYPES.COURSE_PERFORMANCE_RATES,
      },
    ],
    async ({ queryKey }) => {
      const { filters } = queryKey[0];
      return await getCoursePerformanceRates(filters);
    },
    {
      staleTime: REPORTS_STALE_TIME,
      enabled: isEnabled,
    }
  );
  return query;
};

export default useCoursePerformanceRatesQuery;
