import { useQuery } from '@tanstack/react-query';
import { getAllMembersInOrganization } from '../api';
import { GET_ALL_MEMBERS_IN_ORG_REQUEST_STALE_TIME } from '../constants';
import { GetAllMembersInOrganizationFilters } from '../types/members';

interface Props {
  filters: GetAllMembersInOrganizationFilters;
  tenantId: string;
}

/**
 * Wrapper around userQuery to get all the members inside an organization
 */
const useGetAllMembersInOrganizationQuery = (props: Props) => {
  const { filters, tenantId } = props;

  const query = useQuery({
    queryKey: [
      {
        scope: 'organization',
        item: 'get-members',
        tenantId,
        filters,
      },
    ],
    queryFn: async ({ queryKey }) => {
      const { filters, tenantId } = queryKey[0];
      const response = await getAllMembersInOrganization({ filters, tenantId });
      return response;
    },
    keepPreviousData: true,
    staleTime: GET_ALL_MEMBERS_IN_ORG_REQUEST_STALE_TIME,
    refetchOnMount: false,
  });

  return query;
};

export default useGetAllMembersInOrganizationQuery;
