import { twMerge } from 'tailwind-merge';
import { CourseCard } from '../../../../../../components/Cards';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { COURSES_DISPLAY_LIMIT } from '../../../../constants/course';
import { ReactElement } from 'react';

interface Props {
  headerClassName?: string;
  label: ReactElement | string;
  coursesData: Array<any>;
  isLoading: boolean;
  isError: boolean;
  coursesDisplayLimit?: number;
  showSort?: boolean;
  source?: 'learning-journey' | 'default';
  learnJourneyId?: string;
}

const CourseWrapperComponent = ({
  coursesData,
  label,
  headerClassName,
  isLoading,
  isError,
  coursesDisplayLimit = COURSES_DISPLAY_LIMIT as number,
  showSort = false,
  source = 'default',
  learnJourneyId,
}: Props) => {
  // Error State
  if (isError) {
    return <></>;
  }

  // Loading State
  if (isLoading && !coursesData) {
    return (
      <div className="flex w-full flex-col items-center justify-center">
        <BasicSpinner />
      </div>
    );
  }

  if (coursesData.length === 0) return <></>;

  return (
    <div className="flex flex-col gap-4">
      <header className={twMerge('text-xl font-bold', headerClassName)}>{label}</header>
      <div className="flex flex-nowrap gap-x-5 overflow-x-auto pb-4">
        {coursesData?.map((item: any, index: number) => {
          if (index < coursesDisplayLimit)
            return (
              <div key={item.id} className="group relative min-w-[15rem]">
                <CourseCard course={item} source={source} learningJourneyId={learnJourneyId} />
                {showSort && item.sort && (
                  <div className="absolute top-3 left-4 rounded-md border-[0.851px] bg-base-creme py-2 px-3 text-lg font-extrabold leading-[1.125rem] text-base-soft-black group-hover:hidden">
                    {item.sort}
                  </div>
                )}
              </div>
            );
        })}
      </div>
    </div>
  );
};

export default CourseWrapperComponent;
