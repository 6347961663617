import { SIZE_IN_BYTES } from '../constants';

const formatBytes = (args: { bytes: number; decimals?: number }) => {
  const { bytes, decimals = 2 } = args;
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

type ValidateFileSizeArgs = {
  sizeInBytes: number;
  maxSize: Partial<Record<keyof typeof SIZE_IN_BYTES, number | undefined>>;
};

const validateFileSize = (args: ValidateFileSizeArgs) => {
  const { sizeInBytes, maxSize } = args;

  let maxSizeAllowedInBytes = 0;

  (Object.keys(maxSize) as (keyof typeof SIZE_IN_BYTES)[]).forEach((unit) => {
    // The default value will be 0 for the unit
    const maxSizeInBytesForUnit = SIZE_IN_BYTES[unit] * (maxSize[unit] ?? 0);
    maxSizeAllowedInBytes += maxSizeInBytesForUnit;
  });

  return sizeInBytes <= maxSizeAllowedInBytes;
};

export { formatBytes, validateFileSize };
