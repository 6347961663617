export const MODULE_TYPE = {
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  DOCUMENT: 'DOCUMENT',
  ASSESSMENT: 'ASSESSMENT',
} as const;

export const MODULE_KEYS = {
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  DOCUMENT: 'DOCUMENT',
  ASSESSMENT: 'QUIZ',
} as const;

export const MODULE_TYPE_MESSAGE: any = {
  DEFAULT: { label: 'Select a module type', size: '' },
  IMAGE: { label: 'PNG, JPG or JPEG', size: '10mb' },
  VIDEO: { label: 'MOV, MP4 or MPEG', size: '1gb' },
  AUDIO: { label: 'MP3', size: '250mb' },
  DOCUMENT: { label: 'PDF', size: '250mb' },
};

export const MEDIA_TYPE_PREFIX: any = {
  AUDIO: 'audio',
  DOCUMENT: 'application',
  VIDEO: 'video',
  IMAGE: 'image',
  VTT: 'vtt',
};

export const MEDIA_UPLOAD_REQUEST_TYPE = {
  MODULE: 'module',
  COURSE: 'course',
  ASSESSMENT: 'assessment',
};

export const MEDIA_SIZE_LIMIT = {
  MODULE_THUMBNAIL: 0.2,
  MODULE_CONTENT: 1024,
  MODULE_VTT: 2,
};
