import { useMutation } from '@tanstack/react-query';
import { createSpeaker, createSpeakerInMultiLang, updateSpeaker } from '../api/speaker';
import {
  CreateSpeakerInMultiLangPayload,
  CreateSpeakerPayload,
  UpdateSpeakerPayload,
} from '../types/GetSpeakerApiResponse';

export const useCreateMutation = () => {
  return useMutation({
    mutationFn: async (payload: CreateSpeakerPayload) => {
      return await createSpeaker(payload);
    },
  });
};
export const useCreateSpeakerInMultiLangMutation = () => {
  return useMutation({
    mutationFn: async (payload: CreateSpeakerInMultiLangPayload) => {
      const response = await createSpeakerInMultiLang(payload);
      return response;
    },
  });
};
export const useUpdateMutation = () => {
  return useMutation({
    mutationFn: async (payload: UpdateSpeakerPayload) => {
      const response = await updateSpeaker(payload);
      return response;
    },
  });
};
