import { ChannelMessage } from '@aws-sdk/client-chime-sdk-messaging';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useReduxAuthState } from '../../../hooks';
import {
  CHIME_MESSAGE_EVENT_TYPE_HEADER,
  CHIME_MESSAGE_PERSISTENCE,
  CHIME_MESSAGING_EVENT_TYPE,
} from '../../messaging/constants';
import { MessageUpdateCallback, useChimeMessagingService } from '../../messaging/hooks';
import { receivedNewNotification } from '../slice';
import { addNewNotificationToTheListInCache } from '../helpers';

// This hook is intended to be used in the Notifications sidebar item
// So that this will be called when the app mounts
// Will subscribe to notification messages and will update the notifications count
// and notifications in the query cache
const useSubscribeToNotifications = () => {
  const chimeMessagingService = useChimeMessagingService();
  const auth = useReduxAuthState();
  const appDispatch = useAppDispatch();

  const messagesProcessor: MessageUpdateCallback = useCallback(
    (message) => {
      if (!auth.isAuthenticated) {
        return;
      }

      const notificationChannelArn = auth.user['custom:notification_channel'];
      const appInstanceUser = auth.user['custom:appinstance_user_arn'];

      const { headers, payload } = message;
      const eventType = headers[CHIME_MESSAGE_EVENT_TYPE_HEADER];
      const record = JSON.parse(payload);

      switch (eventType) {
        // We would only deal with the create channel message events
        // since we would be sending non-persistent messages from backend
        // there will not be anything like delete
        case CHIME_MESSAGING_EVENT_TYPE.CREATE_CHANNEL_MESSAGE: {
          const channelMessage = record as ChannelMessage;

          // The notification messages that we get would be non persistent
          if (channelMessage.Persistence !== CHIME_MESSAGE_PERSISTENCE.PERSISTENT) {
            return;
          }

          // If this is a message to the notification channel only then process it
          // set has new notifications boolean and add the message to state
          if (channelMessage.ChannelArn === notificationChannelArn) {
            appDispatch(receivedNewNotification());
            addNewNotificationToTheListInCache({
              chimeBearer: appInstanceUser,
              message: channelMessage,
              notificationChannelArn,
            });
          }
        }
      }
    },
    [auth.isAuthenticated, auth.user, appDispatch]
  );

  useEffect(() => {
    if (!auth.isAuthenticated) {
      return;
    }

    if (chimeMessagingService) {
      const { subscribeToMessageUpdate, unsubscribeFromMessageUpdate } = chimeMessagingService;

      subscribeToMessageUpdate(messagesProcessor);

      return () => {
        unsubscribeFromMessageUpdate(messagesProcessor);
      };
    }
  }, [auth.isAuthenticated, chimeMessagingService, messagesProcessor]);
};

export default useSubscribeToNotifications;
