import { useQuery } from '@tanstack/react-query';
import { downloadPresignedUrl } from '../../../api';

type Props = {
  key: string | null;
};

const useDownloadPreSignedQuery = ({ key }: Props) => {
  const STALE_TIME = 5 * 60 * 1000; // 5mins
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery({
      queryKey: [{ scope: 'course-modules', item: 'pre-signed', key }],
      queryFn: ({ queryKey }) => {
        const { key } = queryKey[0];
        if (key != null) return downloadPresignedUrl({ downloadPresignedUrlFilter: { key } });
        else return null;
      },
      enabled: key != null,
      staleTime: STALE_TIME,
    });

  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch };
};

export default useDownloadPreSignedQuery;
