import { format } from 'date-fns';
import { getLocale } from '../../../../../utils';
import { useLanguage } from '../../../../shared/context/languageContext';

interface CreatedOnCellProps {
  createdOn: string;
}

const CreatedOnCell: any = (props: CreatedOnCellProps) => {
  const { createdOn } = props;
  const { language } = useLanguage();

  return createdOn ? (
    <span className="whitespace-nowrap">
      {createdOn
        ? format(new Date(createdOn), 'MMMM dd yyyy', { locale: getLocale(language) })
        : '-'}
    </span>
  ) : (
    '-'
  );
};

export default CreatedOnCell;
