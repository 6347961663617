import { ViewHeader } from '../../common';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
  name: string;
}

const PageHeader = ({ name }: Props) => {
  const navigate = useNavigate();
  const { id: courseId } = useParams();

  const saveAssessmentClickHandler = () => {
    navigate(-1);
  };

  const navigateToCourseEditPage = () => {
    navigate(`/manage/courses/${courseId}/edit`);
  };

  return (
    <div className="flex flex-col gap-1">
      <ViewHeader
        heading={name}
        hideButton={true}
        onButtonClickHandler={saveAssessmentClickHandler}
        onBackClick={navigateToCourseEditPage}
        isBackVisible={true}
        backNavigationText={'Back to course'}
        buttonIconBack={true}
      />
    </div>
  );
};

export default PageHeader;
