import React, { useState } from 'react';
import { TextInputProps } from '../../../../../components/FormElements/TextInput';
import { companySizeRangeValues } from '../../../constants';
import '../../../../../components/FormElements/Styles/RangeInputStyle.css';
import { useFormContext } from 'react-hook-form';

type Props = TextInputProps;

const RangeInput = React.forwardRef(
  ({ min, max, onChange, ...otherProps }: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    // Get company size from the form context - else default to (2-50 size)
    // This is to retain the already defined company size during registration process
    const { getValues } = useFormContext();
    const companySize = getValues('companySize');
    const [value, setValue] = useState<number>(companySize ?? 1);

    const getBackgroundSize = () => {
      return {
        backgroundSize: `${colors[value]}%`,
        width: '200px',
      };
    };

    const [output, setOutput] = useState(0);

    const colors = [0, 19, 37, 60, 76, 100];

    const onSetValue = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(Number((event.target as HTMLInputElement).value));
      setOutput(companySizeRangeValues[Number((event.target as HTMLInputElement).value)]);
      onChange && onChange(event);
    };

    return (
      <div className="flex flex-col items-center py-3.5 sm:flex sm:flex-row sm:py-5">
        <input
          min={min}
          max={max}
          value={value}
          {...otherProps}
          onChange={onSetValue}
          step="1"
          style={getBackgroundSize()}
          ref={ref}
        />
        <output className="items-end p-5 text-left text-xs text-zinc-500 sm:items-center sm:py-2 sm:px-5 sm:text-center	">
          {output === 10000
            ? '5000+'
            : companySizeRangeValues[value - 1] >= 2
            ? companySizeRangeValues[value - 1] + ' to ' + companySizeRangeValues[value]
            : companySizeRangeValues[value]}
        </output>
      </div>
    );
  }
);

RangeInput.displayName = 'RangeInput';

export default RangeInput;
