import clsx from 'clsx';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import SearchInput from '../../../../components/SearchInput';
import { useStrapiSharedData } from '../../../shared/hooks/useStrapiSharedData';

interface Props {
  className?: string;
  filter: string | undefined;
  isFilterApplied: boolean;
}

const TeamsMainHeader = ({ className, filter, isFilterApplied }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const handleQChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    searchParams.set('teamTableSearch', value);
    navigate('?' + searchParams.toString());
  };

  const handleResetFiltersBtnClick = () => {
    navigate('.');
  };
  const {
    tableProps: { clearAllFiltersButton, searchPlaceHolder },
  } = useStrapiSharedData();
  return (
    <div
      className={clsx(
        'flex w-full flex-col justify-between gap-2 text-[14px] font-normal leading-[20px] text-zinc-100 sm:flex-row',
        className
      )}
    >
      <div className="flex flex-col gap-2 lg:flex-row">
        <SearchInput
          placeholder={searchPlaceHolder}
          containerClassName="min-w-[200px]"
          iconClassName="left-2"
          className="h-9 rounded-sm border border-zinc-900 bg-zinc-900 p-2 pl-9 text-[14px] font-normal leading-[20px] text-zinc-100 placeholder:text-zinc-500"
          value={filter ?? ''}
          onChange={handleQChange}
        />

        {isFilterApplied && (
          <div className="order-3 flex w-full grow items-center justify-center md:order-2 md:justify-start">
            <button
              className="h-min self-center justify-self-start rounded-[32px] bg-zinc-700 py-1.5 px-2.5 font-sans text-[10px] font-medium leading-[105%] text-zinc-200"
              onClick={handleResetFiltersBtnClick}
              // When there are no query params disable it
              // As we are setting the filters using the search params
            >
              {clearAllFiltersButton}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default TeamsMainHeader;
