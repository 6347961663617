import type { ChannelMessage } from '@aws-sdk/client-chime-sdk-messaging';
import { queryClient } from '../../../config/react-query';

interface AddNewNotificationToTheListInCache {
  message: ChannelMessage;
  notificationChannelArn: string;
  chimeBearer: string;
}

/**
 * Adds the new notification in the notifications list in cache
 */
const addNewNotificationToTheListInCache = (args: AddNewNotificationToTheListInCache) => {
  const { message, notificationChannelArn, chimeBearer } = args;

  queryClient.setQueryData(
    [
      {
        scope: 'messaging',
        item: 'list-channel-messages',
        channelArn: notificationChannelArn,
        chimeBearer,
        maxResults: 10,
      },
    ],
    (data: any) => {
      try {
        if (!data || data.pages.length === 0) {
          return data;
        }

        const firstPage = data.pages[0];
        const newFirstPage = {
          ...firstPage,
          channelMessages: [message, ...firstPage.channelMessages],
        };

        return {
          pages: [newFirstPage, ...data.pages.slice(1)],
          pageParams: data.pageParams,
        };
      } catch (error) {
        console.log('Error updating react query cache, %o', error);
        return data;
      }
    }
  );
};

export { addNewNotificationToTheListInCache };
