import { BasicSpinner } from '../../../../../../components/Spinners';
import { useAppSelector } from '../../../../../../hooks';
import { RootState } from '../../../../../../store';
import useAssessmentQuestionsQuery from '../../../../hooks/useAssessmentQuestionsQuery';
import QuizFormComponent from './QuizForm';
import QuizPicture from '../../assets/quiz.svg';
import { ErrorDisplay } from '../../../../../../components/DataTables';
import { ERROR_TYPES } from '../../../../../../constants/error-types.constants';
import { SubmitAssessmentArgsType } from '../../types/SingleCourseTypes';
import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';

type Props = {
  submitAssessment: (payload: SubmitAssessmentArgsType) => void;
  isSubmitting: boolean;
};

const AssessmentComponent = ({ submitAssessment, isSubmitting }: Props) => {
  const singleCourseSelector = (state: RootState) => state.singleCourse;
  const { currentModuleDetails } = useAppSelector(singleCourseSelector);
  const questionsQuery = useAssessmentQuestionsQuery({ assessmentId: currentModuleDetails.id });
  const showLoader =
    questionsQuery.isLoading || questionsQuery.isFetching || questionsQuery.isRefetching;
  const isError = questionsQuery.isError;
  const { singleAssessment } = useStrapiCoursesData();
  const getNumberOfQues = () => {
    let result = '';
    if (!showLoader && questionsQuery.data) {
      const questionCount = questionsQuery.data.length;
      if (questionCount == 1) result = `${questionCount} ${singleAssessment.questionsLabel}`;
      else result = `${questionCount} ${singleAssessment.questionsLabel}`;
    }
    return result;
  };
  return (
    <div className="flex flex-col rounded-lg border border-zinc-700 bg-zinc-900 ">
      {/* Header */}
      <div className="flex flex-row items-center rounded-t-lg border-b border-zinc-700 bg-component-bg-dark p-2 text-sm">
        <img src={QuizPicture} alt="" className="mx-2 h-[24px] w-[24px] object-cover" />
        <div className="m-2 flex flex-col gap-1 sm:flex-row sm:gap-2">
          <div className="font-semibold text-zinc-200">{currentModuleDetails.name}</div>
          <div className="text-zinc-500"> {getNumberOfQues()}</div>
        </div>
      </div>
      {/* Question */}
      {showLoader && (
        <div className="flex h-[12.5rem] w-full items-center justify-center">
          <BasicSpinner />
        </div>
      )}
      {isError && (
        <ErrorDisplay
          refetch={questionsQuery.refetch}
          allowsRefetch={true}
          isRefetching={questionsQuery.isRefetching}
          type={ERROR_TYPES.SOMETHING_WENT_WRONG}
          message={'Failed loading questions'}
          className="my-5"
        />
      )}
      {!isError && !showLoader && questionsQuery.data && (
        <QuizFormComponent
          submitAssessment={submitAssessment}
          questions={questionsQuery.data}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
};

export default AssessmentComponent;
