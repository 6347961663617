import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { ORG_API_PATH } from '../../organisation/constants';
import { Bills } from '../../shared/types/billing';
import { PartialEnterpriseAdminTeamsFilter, Team } from '../types/interface';

const getHQAdminTeams = async (
  filters: PartialEnterpriseAdminTeamsFilter,
  id: string
): Promise<{
  rows: Team[];
  count: number;
}> => {
  const queryString = qs.stringify(filters);
  const path = ORG_API_PATH.GET_ORG_TEAMS(id);
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};
const getHQAdminInvoices = async (id: string): Promise<Bills[]> => {
  const queryString = qs.stringify({ tenantType: 'ENTERPRISE' });
  const path = ORG_API_PATH.GET_ORG_INVOICES(id);
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    withCredentials: true,
  });
  return response;
};
export { getHQAdminTeams, getHQAdminInvoices };
