import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';

import { useNavigate } from 'react-router-dom';
import CourseTableHeaderRow from './CoursesTableHeader';
import CourseName from './TableCells/CourseName';
import SpeakerName from './TableCells/SpeakerName';
import ModuleCount from './TableCells/ModuleCount';
import UpdatedOn from './TableCells/UpdatedOn';

import CategoryCell from './TableCells/Category';
import { StatusCell } from './TableCells/Status';
import { TableDataRow } from '../../../../../components/DataTables';
import { Courses } from '../../../types/interface';
import Ratings from './TableCells/Ratings';
import { generateCDNMediaAssetURLWithKey } from '../../../../shared/helpers';
import { useStrapiHQAdminCoursesData } from '../../../../course-management/hooks/useStrapiHQAdminCourseData';

interface Props {
  className?: string;
  data: Courses[];
}
interface TableHeadings {
  statusTableHeader: string;
  nameTableHeader: string;
  speakerTableHeader: string;
  modulesTableHeader: string;
  ratingsTableHeader: string;
  categoryTableHeader: string;
  updatedOnTableHeader: string;
}
const getSchema = (data: TableHeadings) => {
  const columnHelper = createColumnHelper<Courses>();
  const {
    statusTableHeader,
    nameTableHeader,
    speakerTableHeader,
    modulesTableHeader,
    ratingsTableHeader,
    categoryTableHeader,
    updatedOnTableHeader,
  } = data;
  const columns = [
    columnHelper.accessor('name', {
      header: nameTableHeader,
      cell: ({ getValue }) => <CourseName courseName={getValue() as string} />,
      id: 'NAME',
    }),

    columnHelper.accessor('fullName', {
      header: speakerTableHeader,
      cell: ({ row, getValue }) => (
        <SpeakerName
          speakerName={getValue() as string}
          speakerPic={generateCDNMediaAssetURLWithKey({ key: row.original.speakerThumbnail })}
        />
      ),
      id: 'SPEAKER',
    }),
    columnHelper.accessor('moduleCount', {
      header: modulesTableHeader,
      cell: ({ getValue }) => <ModuleCount count={getValue() as number} />,
      id: 'NO_OF_MOUDULES',
    }),
    columnHelper.accessor('rating', {
      header: ratingsTableHeader,
      cell: ({ getValue }) => <Ratings rating={getValue() as number} />,
      id: 'RATINGS',
    }),

    columnHelper.accessor('updatedOn', {
      header: updatedOnTableHeader,
      cell: (props) => <UpdatedOn updatedOn={props.getValue() as string} />,
      id: 'CREATED_ON',
    }),
    columnHelper.accessor('category', {
      header: categoryTableHeader,
      cell: ({ getValue }) => <CategoryCell categoryName={getValue() as string} />,
      id: 'CATEGORY',
    }),
    columnHelper.accessor('isActive', {
      header: statusTableHeader,
      cell: ({ getValue }) => <StatusCell status={getValue() as boolean} />,
      id: 'STATUS',
    }),
  ];
  return columns;
};
const CoursesTable = ({ className, data }: Props) => {
  const navigate = useNavigate();
  const { courseTable } = useStrapiHQAdminCoursesData();
  const columns = getSchema(courseTable);
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableMultiSort: false,
    state: {
      expanded: true,
    },
  });

  function rowClick(id: number) {
    navigate(`/manage/courses/${id}`);
  }
  return (
    <table className={clsx('w-full', className)}>
      <thead className="w-full">
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <CourseTableHeaderRow
                  isSortable={true}
                  allowMultiColSort={true}
                  header={header}
                  key={header.id}
                />
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <TableDataRow
            tdClassName={clsx('px-2 py-3')}
            className="cursor-pointer"
            row={row}
            key={row.original.id}
            onClick={() => rowClick(row.original.id as number)}
          />
        ))}
      </tbody>
    </table>
  );
};

export default CoursesTable;
