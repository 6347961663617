import { ChartData } from 'chart.js';
import clsx from 'clsx';
import { useContext, useMemo, useState } from 'react';
import { defaultGridOptions, defaultTickOptions } from '../../config';
import { useEnterpriseCoursePerformanceQuery } from '../../hooks';
import {
  BarChartOptions,
  CoursePerformance,
  DashboardReportsFilter,
  ReportTooltipDataType,
} from '../../types';
import ChartHeading from './ChartHeading';
import { BarChart } from './baseCharts';
import { ErrorDisplay, NoDataDisplay } from '../../../../components/DataTables';
import durationContext from '../../context/durationContext';
import { InView } from 'react-intersection-observer';
import { chartJsLabel } from '../../helpers';
import ScaleLoader from '../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../components/ReportToolTip';
import { REPORT_TYPES } from '../../constants';
import { useLanguage } from '../../context/languageContext';

interface Props {
  className?: string;
  heading?: string;
  key1?: string;
  key2?: string;
  key3?: string;
  errorMessage?: string;
  emptyChartIconUrl?: string;
  emptyMessage?: string;
  lazyLoad?: boolean;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}
interface LabelProps {
  pointClass: string;
  label: string;
}

const Label = ({ label, pointClass }: LabelProps) => {
  return (
    <div className="flex items-center gap-1">
      <span className={`h-2.5 w-2.5 rounded-full bg-base-brand ${pointClass ?? ''}`}></span>
      <span className="text-[10px] font-normal leading-[12px] text-white">{label}</span>
    </div>
  );
};

const CoursePerformanceViz = ({
  className,
  heading,
  key1,
  key2,
  key3,
  emptyMessage,
  emptyChartIconUrl,
  errorMessage,
  lazyLoad = true,
  reportTooltipContent,
  userPersona,
}: Props) => {
  const { duration } = useContext(durationContext);
  const { language } = useLanguage();

  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);

  // Build filter
  const filters: DashboardReportsFilter = useMemo(() => {
    return {
      fromDate: duration.fromDate,
      interval: duration.interval,
    };
  }, [duration]);

  const coursePerformance = useEnterpriseCoursePerformanceQuery({ filters, isEnabled: isInView });
  let hasNoCoursePerformance = true;

  const results = coursePerformance.data ? coursePerformance.data.results : [];

  // revist after prod release on 28 Feb 2024
  results?.forEach((record) => {
    const totalCompletedString = record.totalCompleted.toString();
    const totalAssigned = record.totalAssigned.toString();
    if (record.totalInProgress !== 0 || totalCompletedString !== '0' || totalAssigned !== '0') {
      hasNoCoursePerformance = false;
    }
  });

  const hasCoursePerformance =
    coursePerformance.data &&
    coursePerformance.data.results &&
    coursePerformance.data.results.length > 0 &&
    !hasNoCoursePerformance;

  const isLoadingCoursePerformance = coursePerformance.isLoading;

  const data = useMemo(() => {
    let labels: string[] = [];

    // Total Completed Hours
    let completedHours: number[] = [];

    // Total InProgress Hours
    let inProgressHours: number[] = [];

    //  Total Assigned Hours

    let assignedHours: number[] = [];

    if (hasCoursePerformance) {
      // Chartjs labels
      labels = coursePerformance.data?.results.map((result: CoursePerformance) =>
        chartJsLabel(duration.interval, result.pool, language)
      );
      // Total Completed Hours
      completedHours = coursePerformance.data?.results.map(
        (result: CoursePerformance) => result.totalCompleted
      );
      // Total InProgress Hours
      inProgressHours = coursePerformance.data?.results.map(
        (result: CoursePerformance) => result.totalInProgress
      );
      //  Total Assigned Hours
      assignedHours = coursePerformance.data?.results.map(
        (result: CoursePerformance) => result.totalAssigned
      );
    }

    const data: ChartData<'bar', number[], unknown> = {
      labels,
      datasets: [
        {
          label: key1,
          data: completedHours || [],
          borderColor: '#F3EAC7',
          backgroundColor: '#F7634F',
        },
        {
          label: key2,
          data: inProgressHours || [],
          borderColor: '#F3EAC7',
          backgroundColor: '#F3EAC7',
        },
        {
          label: key3,
          data: assignedHours || [],
          borderColor: '#F3EAC7',
          backgroundColor: '#C4D500',
        },
      ],
    };

    return data;
  }, [hasCoursePerformance, key1, key2, key3, coursePerformance.data?.results]);

  const options: BarChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
      },
    },
    responsive: true,
    datasets: {
      bar: {
        barThickness: 13,
      },
    },
    scales: {
      x: {
        grid: {
          ...defaultGridOptions,
        },
        ticks: {
          ...defaultTickOptions,
          padding: 8,
        },
        stacked: true,
      },
      y: {
        beginAtZero: true,
        grid: {
          ...defaultGridOptions,
        },
        ticks: {
          ...defaultTickOptions,
          precision: 0,
          padding: 12,
        },
        stacked: true,
      },
    },
  };

  return (
    <InView
      as="div"
      className={clsx(
        'flex h-full min-h-[420px] flex-col gap-5 rounded-lg bg-card-bg px-5 pt-4 pb-7',
        className
      )}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div className="flex flex-col gap-1">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <div className="flex">
              <ChartHeading heading={heading as string} />
            </div>
            {reportTooltipContent?.[REPORT_TYPES.COURSE_PERFORMANCE]?.[userPersona] && (
              <ReportTooltip
                content={reportTooltipContent[REPORT_TYPES.COURSE_PERFORMANCE][userPersona]}
              />
            )}
          </div>
          <div className="flex items-center gap-4">
            <Label pointClass="bg-base-brand" label={key1 as string} />
            <Label pointClass="bg-base-creme" label={key2 as string} />
            <Label pointClass="bg-base-bright-green" label={key3 as string} />
          </div>
        </div>
        {isLoadingCoursePerformance && !hasCoursePerformance && (
          <div className="flex grow items-center justify-center">
            <ScaleLoader />
          </div>
        )}
        {/* Error State */}
        <>
          {coursePerformance.error && !coursePerformance.data && (
            <div className="flex grow items-center justify-center">
              <ErrorDisplay
                message={errorMessage as string}
                refetch={coursePerformance.refetch}
                allowsRefetch={true}
                isRefetching={coursePerformance.isRefetching}
              />
            </div>
          )}
        </>

        {hasCoursePerformance && (
          <BarChart
            options={options}
            className="m-auto max-h-80 min-h-[20rem] w-full"
            data={data}
          />
        )}
        {hasNoCoursePerformance && !isLoadingCoursePerformance && (
          <NoDataDisplay message={emptyMessage as string} IconUrl={emptyChartIconUrl} />
        )}
      </div>
    </InView>
  );
};

export default CoursePerformanceViz;
