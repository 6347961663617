import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { INVITE_FRIEND_HQ } from '../constants';

/**
 * Invite Friend
 */
interface Prop {
  toEmailId: string;
}
export const inviteFriend = async ({ toEmailId }: Prop) => {
  const path = INVITE_FRIEND_HQ;
  const payload = {
    toEmailId,
  };
  const queryString = path + '?' + qs.stringify(payload);
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, queryString, {
    response: true,
    withCredentials: true,
  });

  return response.data;
};
