import { ErrorDisplay } from '../../../../../components/DataTables';
import { BasicSpinner } from '../../../../../components/Spinners';
import {
  useNotificationPreferencesStrapiData,
  useUserNotificationPreferenceQuery,
} from '../../../hooks';
import NotificationPreferenceForm from './NotificationPreferenceForm';

const NotificationPreferenceSettings = () => {
  const userNotificationPreferencesQuery = useUserNotificationPreferenceQuery({
    staleTime: Infinity,
  });
  const { heading, description } = useNotificationPreferencesStrapiData();

  return (
    <div className="m-auto flex w-full flex-col gap-5 rounded-sm bg-card-bg p-6 transition-[height] md:max-w-2xl">
      <div className="flex flex-col">
        <h1 className="text-xl font-semibold leading-8 text-zinc-300">{heading}</h1>
        <p className="text-sm font-normal text-zinc-400">{description}</p>
      </div>
      {/* Loading State */}
      {userNotificationPreferencesQuery.isLoading && !userNotificationPreferencesQuery.data && (
        <div className="min-h-16 flex w-full items-center justify-center">
          <BasicSpinner className="!m-0 text-zinc-50" />
        </div>
      )}
      {/* Has Data */}
      {userNotificationPreferencesQuery.data && (
        <NotificationPreferenceForm
          notificationPreferences={userNotificationPreferencesQuery.data.notificationPreferences}
        />
      )}
      {/* Error State */}
      <>
        {userNotificationPreferencesQuery.error && !userNotificationPreferencesQuery.data && (
          <ErrorDisplay
            message="Error fetching notification preferences for user"
            refetch={userNotificationPreferencesQuery.refetch}
            allowsRefetch={true}
            isRefetching={userNotificationPreferencesQuery.isRefetching}
          />
        )}
      </>
    </div>
  );
};

export default NotificationPreferenceSettings;
