import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Alert from '../../../../components/Alert';
import { Button } from '../../../../components/Buttons';
import { Label, PasswordInput } from '../../../../components/FormElements';
import ErrorMessage from '../../../../components/FormElements/ErrorMessage';
import { BasicSpinner } from '../../../../components/Spinners';
import {
  LEADING_AND_TRAILING_SPACES_PATTERN,
  LOWERCASE_CHARACTER_REGEX_PATTERN,
  SINGLE_DIGIT_REGEX_PATTERN,
  SPECIAL_CHARACTER_REGEX_PATTERN,
  UPPERCASE_CHARACTER_REGEX_PATTERN,
} from '../../../../constants/regex-patterns';
import { useAppSelector } from '../../../../hooks';
import { RootState } from '../../../../store';
import { completeNewPassword } from '../../../auth/helpers';
import { useStrapiOnBoardingData } from '../../hooks/useStrapiOnBoardingData';
import PasswordRequirements from '../../../../components/FormElements/PasswordRequirements';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';

// Set Password Form Schema section starts

const setPasswordSchema = yup.object({
  password: yup
    .string()
    .min(8, 'passwordMin')
    .required('passwordRequired')
    .matches(new RegExp(LOWERCASE_CHARACTER_REGEX_PATTERN), 'passwordLower')
    .matches(new RegExp(UPPERCASE_CHARACTER_REGEX_PATTERN), 'passwordUpper')
    .matches(new RegExp(SPECIAL_CHARACTER_REGEX_PATTERN), 'passwordSpecialCharacter')
    .matches(new RegExp(SINGLE_DIGIT_REGEX_PATTERN), 'passwordNumber')
    .matches(new RegExp(LEADING_AND_TRAILING_SPACES_PATTERN), 'passwordSpace')
    .label('Password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'confirmPassword')
    .label('Confirm Password')
    .required('confirmPasswordRequired'),
});

export type SetPasswordFormData = yup.InferType<typeof setPasswordSchema>;

// Set Password Form Schema section ends
type Props = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;

const SetPasswordForm = ({ className, ...otherProps }: Props) => {
  const authStateSelector = (state: RootState) => state.auth;
  const { cognitoUser } = useAppSelector(authStateSelector);
  const strapiDataHelper: any = useStrapiDataHelper();
  const strapiErrorMessage = strapiDataHelper.errorsMap;

  // If the cognito user does not exist, redirect to login
  if (!cognitoUser) {
    return <Navigate to="/auth/login" />;
  }
  // React router hook
  const navigate = useNavigate();

  const data: any = useAppSelector((state: RootState) => state.strapi.strapiData);
  const strapiData = data?.login.data?.attributes;
  const strapiErrorData = data?.errormessage.data.attributes;
  const { passwordInfo } = strapiData;

  // Reason Forgot Password Request Failed Reason
  const [failedMessage, setFailedMessage] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isSubmitted, isValid },
  } = useForm<SetPasswordFormData>({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(setPasswordSchema),
  });
  const formSubmitHandler: SubmitHandler<SetPasswordFormData> = async (data) => {
    try {
      const { password: newPassword } = data;
      const params: Parameters<typeof completeNewPassword>[0] = {
        user: cognitoUser,
        newPassword,
      };
      await completeNewPassword(params);
      navigate(`/invite/set-password/success`, {
        state: {
          fromPath: '/invite/set-password',
        },
      });
    } catch (error) {
      // TODO: Improve Error Handling
      console.log(error);
      // try to parse code and message from error
      // AuthErrors from amplify would have code and message
      const { code } = error as { code: string; message: string };

      if (code) {
        switch (code) {
          case 'InvalidPasswordException': {
            setFailedMessage(strapiErrorMessage['INVALID_PASSWORD_EXCEPTION']);
            // #markedforlookup - this cannot be handled now since the message is from amplify
            break;
          }
          default: {
            setFailedMessage(strapiErrorMessage['PASSWORD_SETUP_FAILED']);
          }
        }
      } else {
        setFailedMessage(strapiErrorMessage['PASSWORD_SETUP_FAILED']);
      }
    }
    // for react-hook-form isSubmitting boolean value
    return;
  };
  const { setPassword } = useStrapiOnBoardingData();
  const { setPasswordButton, newPasswordLabel, confirmPasswordLabel: confirmLabel } = setPassword;

  return (
    <form
      onSubmit={handleSubmit(formSubmitHandler)}
      className={`flex w-full max-w-md flex-col gap-6 ${className ?? ''}`}
      {...otherProps}
    >
      {failedMessage && <Alert variant="error" message={failedMessage}></Alert>}
      <div className="flex flex-col gap-2">
        <Label htmlFor="password_input" label={newPasswordLabel} />
        <PasswordInput
          className="rounded-md"
          id="password_input"
          type="password"
          autoComplete="off"
          hasError={errors.password?.message ? true : false}
          {...register('password', {
            deps: ['confirmPassword'],
          })}
        />
        <PasswordRequirements />
        {errors.password?.message ? (
          <ErrorMessage message={strapiErrorData[errors.password.message]} />
        ) : (
          <p className="text-xs font-thin">{passwordInfo}</p>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <Label htmlFor="confirm_password" label={confirmLabel} />
        <PasswordInput
          className="rounded-md"
          id="confirm_password"
          type="password"
          autoComplete="off"
          hasError={errors.confirmPassword?.message ? true : false}
          {...register('confirmPassword')}
        />
        {errors.confirmPassword?.message ? (
          <ErrorMessage message={strapiErrorData[errors.confirmPassword.message]} />
        ) : (
          <p className="text-xs font-thin">{passwordInfo}</p>
        )}
      </div>
      <Button
        type="submit"
        // Disable when we're submitting or when the form is submitted once
        // and is not valid
        disabled={isSubmitting || (!isValid && isSubmitted)}
        className="my-3.5 flex items-center justify-center bg-base-brand text-sm font-semibold leading-[25px] text-white"
      >
        {isSubmitting ? (
          <BasicSpinner className="h-[25px] w-[25px] text-white" />
        ) : (
          setPasswordButton
        )}
      </Button>
    </form>
  );
};

export default SetPasswordForm;
