import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DynamicControl } from '../../../../../../components/FormElements/DynamicControl';
import { useAppDispatch, useAppSelector, useShouldUserRegister } from '../../../../../../hooks';
import { RootState } from '../../../../../../store';
import { DynamicFieldData } from '../../../../../../types/dynamic-control-types';
import { debounce } from '../../../../../../utils';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { checkCompanyExists } from '../../../../api';
import { setErrorId } from '../../../../slices/registration.slice';
import RegistrationErrorAlert from '../../RegistrationAlert/RegistrationErrorAlert';
import { setNumberOfLicense } from '../../../../slices/pricing.slice';
import { Registration } from '../../../../types';

interface FormProps {
  fields: DynamicFieldData[];
  formKey: number;
  className?: string;
  validateForm: (
    index: number,
    data: object,
    isSubmitting: boolean,
    isValid: boolean,
    errors: object
  ) => void;
  nextStepClicked?: boolean | null;
  nextStepToggle?: boolean;
  defaultValue?: Partial<Registration> | undefined;
  setErrors: (value: object, formKey: number) => void;
  setIsSubmitting: (value: boolean) => void;
  disablePopulatedFields?: boolean;
  enterpriseInvite: Partial<Registration> | undefined;
}

const Form = ({
  fields,
  formKey,
  nextStepClicked,
  nextStepToggle,
  validateForm,
  className,
  setErrors,
  defaultValue,
  enterpriseInvite,
  disablePopulatedFields = false,
  setIsSubmitting,
}: FormProps) => {
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });
  const registrationStateSelector = (state: RootState) => state.register;
  const { currentIndex } = useAppSelector(registrationStateSelector);
  const { handleSubmit, getValues, formState, setError } = formMethods;
  const { isSubmitting, isValid, errors } = formState;

  const shouldUserRegister = useShouldUserRegister();

  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting]);

  useEffect(() => {
    if (nextStepClicked) {
      if (formKey === currentIndex) {
        handleSubmit(onSubmit, onError)();
      }
    }
  }, [nextStepClicked, nextStepToggle]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      const [setErr] = debounce(setErrors, 1000);
      setErr(errors, formKey);
    }
  }, [isValid, errors]);
  const data: any = useStrapiDataHelper();
  const strapiErrorData = data?.errormessage?.data.attributes;
  const { companyAlreadyExists } = strapiErrorData;
  const dispatch = useAppDispatch();
  const value: any = getValues();

  const onSubmit = async () => {
    try {
      if (value.companyName && value.department) {
        const response = await checkCompanyExists(value.companyName, value.department);
        const errorCode = 'COMPANY_ALREADY_EXIST';
        dispatch(setNumberOfLicense(value.activeLearners));
        dispatch(setErrorId(errorCode));
        if (response.companyExistsAlready) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore */

          setError('department', {
            type: 'custom',
            message: companyAlreadyExists,
          });
        } else {
          validateForm(formKey, getValues(), isSubmitting, isValid, errors);
        }
      } else {
        validateForm(formKey, getValues(), isSubmitting, isValid, errors);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onError = () => {
    validateForm(formKey, getValues(), isSubmitting, isValid, errors);
  };
  return (
    <div>
      <div>
        <form key={formKey} className={className}>
          <FormProvider {...formMethods}>
            {fields?.map((d: DynamicFieldData, i) => {
              if (shouldUserRegister) {
                if (d.slug === 'password' || d.slug === 'confirmPassword') {
                  return null;
                }
              }

              return (
                <div key={i}>
                  <DynamicControl
                    hasError={errors[d.slug as keyof typeof errors] ? true : false}
                    disabled={
                      defaultValue &&
                      Object.keys(defaultValue).length > 0 &&
                      disablePopulatedFields &&
                      ((enterpriseInvite &&
                        enterpriseInvite[d.slug as keyof typeof enterpriseInvite]) ||
                        (shouldUserRegister &&
                          defaultValue[d.slug as keyof typeof enterpriseInvite]))
                        ? disablePopulatedFields
                        : false
                    }
                    className={
                      disablePopulatedFields
                        ? 'disabled:cursor-not-allowed disabled:border disabled:border-zinc-700 disabled:bg-zinc-700 disabled:text-zinc-400'
                        : ''
                    }
                    {...d}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={d.slug}
                    render={({ message }) => {
                      return message
                        ? Object.entries(message)?.map(([type, message]) => (
                            <span key={type} className="text-xs font-normal text-red-500">
                              {message}
                            </span>
                          ))
                        : null;
                    }}
                  />
                </div>
              );
            })}
          </FormProvider>
        </form>
      </div>
      <div className="mt-2">{<RegistrationErrorAlert errors={errors} />}</div>
    </div>
  );
};
Form.displayName = 'Form';
export default Form;
