const LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY = 'ushg-hq-registartion';
const LOCAL_STORAGE_USHG_HQ_ENTERPRISE_INVITE = 'ushg-hq-enterprise-invite';
const LOCAL_STORAGE_DASHBOARD_VIDEO_PLAYED= 'ushg-hq-dashboard-video-played';

export const companySizeRangeValues = [2, 50, 500, 1000, 5000, 10000];
export { LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY, LOCAL_STORAGE_USHG_HQ_ENTERPRISE_INVITE,LOCAL_STORAGE_DASHBOARD_VIDEO_PLAYED };
export const TEAM = 'Team';
export const INDIVIDUAL = 'Individual';
export const BASIC_DETAILS = 'basicDetails';

export const TENURE = {
  MONTH: 'month',
  YEAR: 'year',
} as const;

export const ROLES = {
  INDIVIDUAL_SSO: 'IndividualSSO',
  INDIVIDUAL: 'Individual',
  TEAM: 'Team',
  ENTERPRISE: 'Enterprise',
} as const;
