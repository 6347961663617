import { useStrapiSharedData } from '../../shared/hooks/useStrapiSharedData';

export const DURATION_UNIT = {
  MINUTE: 1,
  HOUR: 60,
};

export const POPULAR_DURATION = {
  FIVE_MINUTES: DURATION_UNIT.MINUTE * 5,
  FIFTEEN_MINUTES: DURATION_UNIT.MINUTE * 15,
  THIRTY_MINUTES: DURATION_UNIT.MINUTE * 30,
  ONE_HOUR: DURATION_UNIT.HOUR,
  TWO_HOUR: DURATION_UNIT.HOUR * 2,
  FOUR_HOUR: DURATION_UNIT.HOUR * 4,
  SIX_HOUR: DURATION_UNIT.HOUR * 6,
  EIGHT_HOUR: DURATION_UNIT.HOUR * 8,
};

export function GetDurationSortRange() {
  const {
    durationLabels: { toLabel, minutesLabel, hoursLabel },
  } = useStrapiSharedData();
  return [
    {
      display: '1 ' + `${toLabel}` + ' 5 ' + `${minutesLabel}`,
      value: `_::${POPULAR_DURATION.FIVE_MINUTES}`,
    },
    {
      display: '5 ' + `${toLabel}` + ' 15 ' + `${minutesLabel}`,
      value: `${POPULAR_DURATION.FIVE_MINUTES}::${POPULAR_DURATION.FIFTEEN_MINUTES}`,
    },
    {
      display: '15 ' + `${toLabel}` + ' 30 ' + `${minutesLabel}`,
      value: `${POPULAR_DURATION.FIFTEEN_MINUTES}::${POPULAR_DURATION.THIRTY_MINUTES}`,
    },
    {
      display: '30 ' + `${toLabel}` + ' 60 ' + `${minutesLabel}`,
      value: `${POPULAR_DURATION.THIRTY_MINUTES}::${POPULAR_DURATION.ONE_HOUR}`,
    },
    {
      display: '1 ' + `${toLabel}` + ' 2 ' + `${hoursLabel}`,
      value: `${POPULAR_DURATION.ONE_HOUR}::${POPULAR_DURATION.TWO_HOUR}`,
    },
    {
      display: '2+ ' + `${hoursLabel}`,
      value: `${POPULAR_DURATION.TWO_HOUR}::_`,
    },
  ];
}
