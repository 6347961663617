import { BasicSpinner } from '../../../../../components/Spinners';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';

interface props {
  memberCount: number;
  cost: number;
  isLoading?: boolean;
}

export const SubscriptionCard = ({ memberCount, cost, isLoading }: props) => {
  const { subscriptionInfo } = useStrapiSharedData();
  return (
    <div className=" flex h-[6rem] w-[15rem] flex-col items-center justify-center rounded-lg  bg-zinc-800  md:w-[10rem]">
      {!isLoading ? (
        <div className="flex flex-col items-center ">
          <div>${cost}</div>
          <div className=" flex  items-center space-x-1 ">
            <span>{memberCount} </span>
            <span>{subscriptionInfo.membersLabel}</span>
          </div>
        </div>
      ) : (
        <div className="flex h-[4.5rem] w-[9.75rem] items-center justify-center ">
          <BasicSpinner className="text-white " />
        </div>
      )}
    </div>
  );
};
