import { parseJSON } from './misc';

const readFromLocalStorage = <T>(key: string, initialValue = undefined) => {
  if (typeof window === 'undefined') {
    return initialValue;
  }

  try {
    const item = window.localStorage.getItem(key);
    return item ? (parseJSON(item) as T) : initialValue;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error);
    return initialValue;
  }
};

export { readFromLocalStorage };
