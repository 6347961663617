import React, { useState } from 'react';
import durationContext from '../context/durationContext';
import { defaultDuration } from '../helpers/reportsDuration';
import { Duration } from '../types';
import performanceTeamFilterContext from '../context/performanceTeamFilterContext';

const ReportsFilterProvider = ({ children }: React.PropsWithChildren) => {
  // Default reports to last 30 days
  const defaultDurationState = defaultDuration();
  const [duration, setDuration] = useState<Duration>(defaultDurationState);

  const [teamFilter, setTeamFilter] = useState<string>('1');

  return (
    <durationContext.Provider value={{ duration, setDuration }}>
      <performanceTeamFilterContext.Provider
        value={{
          teamFilter,
          setTeamFilter,
        }}
      >
        {children}
      </performanceTeamFilterContext.Provider>
    </durationContext.Provider>
  );
};

export default ReportsFilterProvider;
