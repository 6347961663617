import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

interface Props {
  roles: string[];
  Fallback: React.ReactNode;
}

const authStateSelector = (state: RootState) => state.auth;

/**
 * Render children if user has role else render fallback.
 * @summary Should be used to render components based on the user role.
 * @summary Fallback would be rendered if the user doesn't have specific role.
 * @summary If not authenticated would be redirected to the login page.
 */
const HasAccess = ({ roles, children, Fallback }: React.PropsWithChildren<Props>) => {
  const { user, isAuthenticated } = useAppSelector(authStateSelector);

  // If not authenticated navigate to login
  if (!isAuthenticated || !user) {
    return Navigate({ to: '/auth/login', replace: true });
  }

  // Atleast one of the roles in user, matches the roles allowed
  // If authenticated there would be role
  const hasAccess = roles.includes(user.role as string);

  if (hasAccess) {
    return <>{children}</>;
  }

  return <>{Fallback}</>;
};

export default HasAccess;
