import React from 'react';
import PatternIllustrator from './PatternIllustrator';
import BlobIllustrator from './BlobIllustrator';
import cn from '../../utils/cn';
type CustomProps = {
  className?: string;
  showIllustrator?: boolean;
  children: React.ReactNode | JSX.Element[];
  childrenWrapperClassName?: string;
};
export const Page = ({
  className,
  children,
  showIllustrator = false,
  childrenWrapperClassName,
}: CustomProps) => {
  return (
    <div
      className={`relative flex min-h-screen w-screen flex-col overflow-hidden bg-page-bg-dark ${
        className ?? ''
      }`}
    >
      <div className={cn('z-20 flex flex-col', childrenWrapperClassName)}>{children}</div>
      {showIllustrator && (
        <>
          <BlobIllustrator />
          <PatternIllustrator />
        </>
      )}
    </div>
  );
};
