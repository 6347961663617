import { createSlice } from '@reduxjs/toolkit';

interface InviteModalState {
  isModalOpen: boolean;
}

const initialState: InviteModalState = {
  isModalOpen: false,
};

const inviteFriendModalSlice = createSlice({
  name: 'InviteFriendModalState',
  initialState,
  reducers: {
    toggleInviteFriendModal: (state) => {
      state.isModalOpen = !state.isModalOpen;
    },
  },
});

export const { toggleInviteFriendModal } = inviteFriendModalSlice.actions;

export default inviteFriendModalSlice.reducer;
