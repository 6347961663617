import { useQuery } from '@tanstack/react-query';
import { ORG_STALE_TIME } from '../../shared/constants';
import { getOrganisationName } from '../api/organisation';
interface props {
  tenantId: string;
}
const useGetOrganisationNameQuery = ({ tenantId }: props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery(
      [
        {
          scope: 'organisation',
          item: 'BasicDetails',
          tenantId,
        },
      ],
      async () => {
        return await getOrganisationName(tenantId);
      },
      {
        keepPreviousData: true,
        staleTime: ORG_STALE_TIME,
      }
    );
  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch };
};

export default useGetOrganisationNameQuery;
