import { useState } from 'react';
import { USHG_HQ_APP_ROLE } from '../../../../../../../constants';
import HasAccess from '../../../../../../shared/components/HasAccess';
import { Category, TabWidgetType } from '../../../../../types';
import { StepperControl } from '../../../../Stepper';
import { Tabs } from '../../Tabs';
import CategoryCardView from './CategoryCardView';
import { useCreateCategoryMutation } from '../../../../../hooks';
import { ENTERPRISE_ONBOARDING_TYPE } from '../../../../../constants';
import { useHasAccess } from '../../../../../../../hooks';
import { useStrapiOnBoardingData } from '../../../../../hooks/useStrapiOnBoardingData';
import toast from 'react-hot-toast';
import { Toast } from '../../../../../../../components/Toast';

interface CategoryWrapperProps {
  currentStep: string;
  setCurrentStep: any;
  steps: string[];
  categoryQuery: any;
  recommendedCategories: number[];
  selectedCategories: Category[];
  setSelectedCategories: (selectedCategories: Category[]) => void;
  // prevSelectedCategories: GetCategoriesAPIResponse[] | null;
}

const CategoryWrapper = ({
  currentStep,
  setCurrentStep,
  steps,
  categoryQuery,
  recommendedCategories,
  selectedCategories,
  setSelectedCategories,
}: // prevSelectedCategories,
CategoryWrapperProps) => {
  // strapi content

  const { leaderCategory, mangerCategory, toastMessages } = useStrapiOnBoardingData();
  const {
    leaderTeamCategoryTitle,
    leaderTeamCategoryDescription,
    leaderPersonalCategoryTitle,
    teamCategoryToggleButton,
    leaderPersonalCategoryDescription,
    personalCategoryToggleButton,
  } = leaderCategory;
  const {
    managerTeamCategoryTitle,
    managerTeamCategoryDescription,
    managerPersonalCategoryTitle,
    managerTeamCategoryToggleButton,
    managerPersonalCategoryDescription,
    managerPersonalCategoryToggleButton,
  } = mangerCategory;

  const managerAccess = useHasAccess([USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER]);
  const CATEGORIES = {
    PERSONAL_CATEGORIES: managerAccess
      ? managerPersonalCategoryToggleButton
      : personalCategoryToggleButton,
    TEAM_CATEGORIES: managerAccess ? managerTeamCategoryToggleButton : teamCategoryToggleButton,
  } as const;

  const CategoryWidgetData: TabWidgetType[] = [
    {
      id: 1,
      type: ENTERPRISE_ONBOARDING_TYPE.PERSONAL,
      text: CATEGORIES.PERSONAL_CATEGORIES,
      completed: false,
    },
    {
      id: 2,
      type: ENTERPRISE_ONBOARDING_TYPE.TEAM,
      text: CATEGORIES.TEAM_CATEGORIES,
      completed: false,
    },
  ];
  const CATEGORIES_MANAGER_CONTENT_MAPPER = {
    [CATEGORIES.PERSONAL_CATEGORIES]: {
      header: managerPersonalCategoryTitle,
      subHeader: managerPersonalCategoryDescription,
    },
    [CATEGORIES.TEAM_CATEGORIES]: {
      header: managerTeamCategoryTitle,
      subHeader: managerTeamCategoryDescription,
    },
  };
  const CATEGORIES_LEADER_CONTENT_MAPPER = {
    [CATEGORIES.PERSONAL_CATEGORIES]: {
      header: leaderPersonalCategoryTitle,
      subHeader: leaderPersonalCategoryDescription,
    },
    [CATEGORIES.TEAM_CATEGORIES]: {
      header: leaderTeamCategoryTitle,
      subHeader: leaderTeamCategoryDescription,
    },
  };
  const CATEGORIES_CONTENT_MAPPER = managerAccess
    ? CATEGORIES_MANAGER_CONTENT_MAPPER
    : CATEGORIES_LEADER_CONTENT_MAPPER;

  // strapi content

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [widgetData, setWidgetData] = useState<TabWidgetType[]>(CategoryWidgetData);

  // To prevent getting the data from tanstack response every time we switch between tabs
  const [selectedCategoriesInitialise, setSelectedCategoriesIntialise] = useState<
    Record<string, boolean>
  >(() => {
    const initialiseObj: Record<string, boolean> = {};
    Object.keys(ENTERPRISE_ONBOARDING_TYPE).forEach((key) => {
      initialiseObj[key] = false;
    });
    return initialiseObj;
  });

  // Access check using custom hook
  const hasAccess = useHasAccess([
    USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
    USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
    USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
  ]);

  // Create - Create Category Mutation
  const createCategoriesMutation = useCreateCategoryMutation();

  const selectedCount =
    selectedCategories?.filter(
      (selectedCategory: any) => selectedCategory?.type === widgetData[currentTab].type
    ).length ?? 0;

  // TODO: Revisit
  /**
   * Checks if the selected categories have changed.
   *
   * @returns {boolean} Returns `true` if the selected categories have changed, `false` otherwise.
   */
  // const hasSelectedCategoiresChanged = () => {
  //   return JSON.stringify(selectedCategories) !== JSON.stringify(prevSelectedCategories);
  // };

  // Show error toast message for minimum categories requirement
  const showMinCategoryToastErrorMessage = () => {
    toast.custom(
      (t) => <Toast variant="error" Title={toastMessages.categoriesMinError} toastInstance={t} />,
      {
        id: '1',
        duration: 3000,
      }
    );
  };

  // Filter and get selected categories for the current tab
  const selectedCategoriesForTab = () => {
    return selectedCategories.filter((category) => category.type === widgetData[currentTab].type);
  };

  const handleWidgetToggle = (widgetIndex: number) => {
    if (widgetIndex === 1) {
      // If moving from the first tab to the second tab, check if there are selected categories for the current tab

      if (selectedCategoriesForTab().length === 0) {
        // Show the error message if no categories are selected for the current tab
        showMinCategoryToastErrorMessage();
        return;
      }
    }

    // Update the widget completion flag and the current tab.
    widgetData[currentTab].completed = true;
    setWidgetData(widgetData);
    setCurrentTab(widgetIndex);
  };

  // Handle previous step in the stepper
  const stepperPrevControl = () => {
    let currIndex = steps.indexOf(currentStep);
    const newStep = --currIndex;
    if (newStep !== -1) {
      setCurrentStep(steps[newStep]);
    }
  };

  // Handle next step in the stepper
  const stepperNextControl = () => {
    if (!hasAccess) {
      handleCategorySubmission();
      return;
    }

    if (selectedCount === 0) {
      showMinCategoryToastErrorMessage();
      return;
    }

    if (currentTab < widgetData.length - 1) {
      const nextTab = currentTab + 1;
      handleWidgetToggle(nextTab);
    } else {
      handleCategorySubmission();
    }
  };

  // Handle category submission
  const handleCategorySubmission = () => {
    // TODO: Revisit
    //  Checks if the selected categories have changed. If not, proceeds to the next step.
    //  If the selected categories have not changed, the current step is incremented and set
    //  as the new current step. allowing progression to the next step.

    // if (!hasSelectedCategoiresChanged()) {
    //   let currIndex = steps.indexOf(currentStep);

    //   const newStep = ++currIndex;

    //   if (newStep <= steps.length - 1) {
    //     setCurrentStep(steps[newStep]);
    //   }
    //   return;
    // }

    // Creates categories if there are selected categories
    if (selectedCategoriesForTab().length > 0) {
      createCategoriesMutation.mutate(selectedCategories, {
        onSuccess: () => {
          setSelectedCategoriesIntialise(() => {
            return {};
          });
        },
        onSettled: () => {
          let currIndex = steps.indexOf(currentStep);

          const newStep = ++currIndex;

          if (newStep <= steps.length - 1) {
            setCurrentStep(steps[newStep]);
          }
        },
      });
      return;
    } else {
      showMinCategoryToastErrorMessage();
      return;
    }
  };

  return (
    <div className="flex flex-col gap-2">
      {/* Enterprise Admin, Leader, Manager Steps  */}
      <HasAccess
        roles={[
          USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
          USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
          USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
        ]}
        Fallback={null}
      >
        <Tabs
          key={widgetData[currentTab].id}
          widgetData={widgetData}
          selectedWidget={currentTab}
          handleWidgetToggle={handleWidgetToggle}
        />
        <CategoryCardView
          content={CATEGORIES_CONTENT_MAPPER}
          categoryQuery={categoryQuery}
          recommendedCategories={recommendedCategories}
          currentTab={currentTab}
          widgetData={widgetData}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          selectedCategoriesInitialise={selectedCategoriesInitialise}
          setSelectedCategoriesIntialise={setSelectedCategoriesIntialise}
          selectedCount={selectedCount}
        />
      </HasAccess>
      {/* Enterprise Learner, Individual User */}
      <HasAccess
        roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER, USHG_HQ_APP_ROLE.INDIVIDUAL_USER]}
        Fallback={null}
      >
        <CategoryCardView
          content={CATEGORIES_CONTENT_MAPPER}
          categoryQuery={categoryQuery}
          recommendedCategories={recommendedCategories}
          currentTab={currentTab}
          widgetData={widgetData}
          setSelectedCategories={setSelectedCategories}
          selectedCategories={selectedCategories}
          selectedCategoriesInitialise={selectedCategoriesInitialise}
          setSelectedCategoriesIntialise={setSelectedCategoriesIntialise}
          selectedCount={selectedCount}
        />
      </HasAccess>
      {/* navigation button */}
      <StepperControl
        aria-label="Category Step Controls"
        stepperPrevControl={stepperPrevControl}
        stepperNextControl={stepperNextControl}
        currentStep={currentStep}
        isStepLoading={categoryQuery.isLoading || categoryQuery.isRefetching}
        isNextStepLoading={createCategoriesMutation.isLoading}
      />
    </div>
  );
};

export default CategoryWrapper;
