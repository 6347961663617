import Dialog from '../../../../../components/Dialog';
import useStrapiMembersData from '../../../hooks/useStrapiMembersData';
import UserChangeTeamModal from './UserChangeTeamModal';
type props = {
  setIsModalOpen: any;
  username: string;
  fullName: string;
  currentRole: string;
  teamName: string;
};
const UserChangeTeam = ({ setIsModalOpen, username, currentRole, fullName, teamName }: props) => {
  const { userTeamChange } = useStrapiMembersData();
  return (
    <div className="mx-3 flex flex-col gap-7 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
        {userTeamChange.changeTeamModalTitle} -{' '}
        {fullName && fullName.length !== 0 ? fullName : username}
      </Dialog.Title>
      <UserChangeTeamModal
        currentRole={currentRole}
        setIsModalOpen={setIsModalOpen}
        username={username}
        strapiData={userTeamChange}
        teamName={teamName}
      />
    </div>
  );
};

export default UserChangeTeam;
