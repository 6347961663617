import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { BadgeButton } from '../../../components/Badge';
import { TabWidgetType } from '../types';

interface TabWidgetsProps {
  selectedWidget: TabWidgetType | undefined;
  setSelectedWidget: (selectedWidget: TabWidgetType) => void;
  className?: string;
  widgetData: TabWidgetType[];
}
const TabWidgets = ({
  selectedWidget,
  setSelectedWidget,
  className,
  widgetData,
}: TabWidgetsProps) => {
  const [widgets, setWidgets] = useState<TabWidgetType[]>(widgetData);

  useEffect(() => {
    const updatedWidgets = widgetData.map((w) => ({
      ...w,
      isSelected: !!selectedWidget && w.id === selectedWidget.id,
    }));

    setWidgets(updatedWidgets);
  }, [widgetData, selectedWidget]);

  const onWidgetClickHandler = (widget: TabWidgetType) => {
    setSelectedWidget(widget);
  };

  return (
    <div className={twMerge(`flex gap-2 py-3 ${className ?? ''}`)}>
      {widgets &&
        widgets.map((w) => {
          return <BadgeButton onClick={onWidgetClickHandler} key={w.id} badge={w} />;
        })}
    </div>
  );
};

export default TabWidgets;
