import React from 'react';
import { twMerge } from 'tailwind-merge';
import { NoDataDisplay } from '../../../../components/DataTables';
import { useStrapiHQAdminCoursesData } from '../../../course-management/hooks/useStrapiHQAdminCourseData';

type Props = {
  src: string | null;
  name: string | null;
  className?: string;
};
const PDFViewer = ({ src, name, className }: Props) => {
  const {
    emptyMessages: { emptyDocument },
  } = useStrapiHQAdminCoursesData();
  if (!src) return <NoDataDisplay message={emptyDocument} />;
  return (
    <iframe
      src={src}
      title={name ? name : ''}
      className={twMerge('block h-full w-full border-none bg-black', className)}
    />
  );
};

export default PDFViewer;
