import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { AdminDisableMFAForUserParams, adminDisableMFAForUser } from '../api';
import useStrapiMembersData from './useStrapiMembersData';
import { STRAPI_PLACEHOLDER_CODE } from '../../../constants/strapi';
import { showToast } from '../../shared/helpers/toast';

const useAdminDisableMFAForUserMutation = () => {
  // strapi
  const { disableMFAUserDialog: disableMFAUserCMS } = useStrapiMembersData();
  // strapi

  const mutation = useMutation({
    mutationFn: async (params: AdminDisableMFAForUserParams) => {
      const response = await adminDisableMFAForUser(params);
      return response;
    },
    onMutate: (variables) => {
      const toastMessage = disableMFAUserCMS.mfaDisableInProgressToast.replace(
        STRAPI_PLACEHOLDER_CODE.EMAIL,
        variables.username
      );

      const toastId = showToast({
        title: toastMessage,
        variant: 'info',
      });

      return {
        toastId,
      };
    },
    onSuccess: async (_, variables, context) => {
      const toastMessage = disableMFAUserCMS.mfaDisableSuccessToast.replace(
        STRAPI_PLACEHOLDER_CODE.EMAIL,
        variables.username
      );

      toast.remove(context?.toastId);
      showToast({
        variant: 'success',
        title: toastMessage,
      });
    },
    onError: async (_, variables, context) => {
      const toastMessage = disableMFAUserCMS.mfaDisableErrorToast.replace(
        STRAPI_PLACEHOLDER_CODE.EMAIL,
        variables.username
      );

      toast.remove(context?.toastId);
      showToast({
        variant: 'error',
        title: toastMessage,
      });
    },
  });

  return mutation;
};

export default useAdminDisableMFAForUserMutation;
