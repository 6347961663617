import { useDispatch } from 'react-redux';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '../../../../../../components/Buttons';
import { Divider } from '../../../../../../components/Divider';
import UserSocialLogin from '../../../../../../components/UserSocialLogin';
import { SUBSCRIPTION_TYPE } from '../../../../../../constants/subscription-plans';
import { useAppSelector } from '../../../../../../hooks';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { RootState } from '../../../../../../store';
import { useStrapiSharedData } from '../../../../../shared/hooks/useStrapiSharedData';
import { ROLES, TENURE } from '../../../../constants';
import { setPricingInfo, setTenure } from '../../../../slices/pricing.slice';
import { SubscriptionType, Tenure } from '../../../../types';
import IndividualRegistrationMainFooter from './IndividualRegistrationMainFooter';
import IndividualRegistrationMainHeader from './IndividualRegistrationMainHeader';
const pricingInfoSelector = (state: RootState) => state.pricing.pricing;

const IndividualRegistrationMain = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const subscriptionType = searchParams.get('subscriptionType');
  const freetrail = searchParams.get('freeTrail');
  const tenure = searchParams.get('tenure');

  const {
    SSO: { appleLogin, googleLogin, orLabel },
  } = useStrapiSharedData();
  const data: any = useStrapiDataHelper();
  const attributes = data?.individualsignup.data?.attributes;
  const { signUpTitle, signUpDesc, signUpButton, signInContext, signInLabel } = attributes;

  const planInfo = useAppSelector(pricingInfoSelector);

  // if the redirection is from public site we are setting the query params data to redux
  if (subscriptionType === SUBSCRIPTION_TYPE.BASIC) {
    // Checking if tenure received is valid, if not using 'month' as tenure
    const subscriptionTenure =
      tenure && (Object.values(TENURE) as string[]).includes(tenure) ? tenure : TENURE.MONTH;

    const payload = {
      role: ROLES.INDIVIDUAL,
      freeTrail: freetrail === 'true' ? true : false,
      subscriptionType: subscriptionType as SubscriptionType,
    };
    dispatch(setPricingInfo(payload));
    dispatch(setTenure(subscriptionTenure as Tenure));
    return <Navigate to="/auth/register/individual" />;
  }

  if (planInfo.subscriptionType !== 'basic') {
    return <Navigate to="/auth/register" />;
  }

  // handlers
  const navigateToEmailBasedRegistrationForm = () => {
    navigate('/auth/register/subscription');
  };

  return (
    <main id="main-content" className="flex w-full flex-col items-center justify-evenly">
      <div className="flex w-full max-w-sm flex-col items-center justify-center gap-5">
        <div className="flex w-full flex-col gap-4">
          <IndividualRegistrationMainHeader heading={signUpTitle} subHeading={signUpDesc} />
          <UserSocialLogin
            appleLogin={appleLogin}
            googleLogin={googleLogin}
            displayOnlyAvailableForIndLearnersMessage={false}
          />
        </div>
        <Divider text={orLabel} textClassName="font-normal" className="text-xs text-zinc-400" />
        <Button
          className="my-3.5 flex w-full max-w-md cursor-pointer items-center justify-center bg-base-brand text-sm font-semibold leading-[25px] text-white"
          onClick={navigateToEmailBasedRegistrationForm}
        >
          {signUpButton}
        </Button>
        <IndividualRegistrationMainFooter context={signInContext} label={signInLabel} />
      </div>
      <div></div>
    </main>
  );
};

export default IndividualRegistrationMain;
