import { Info, Plus } from 'phosphor-react';
import { RootState } from '../../../../../../../store';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks';
import { removeLeader } from '../../../../../slices/onboarding.slice';
import { INVITEE_ROLE, Leader } from '../../../../../types';
import { useStrapiOnBoardingData } from '../../../../../hooks/useStrapiOnBoardingData';
import Dialog from '../../../../../../../components/Dialog';
import { useState } from 'react';
import AddNewMemberMain from '../../../../../../teams/components/Modal/AddNewMemberModal/AddNewMemberMain';
import { RoleOptions } from '../../../../../../teams/types/interface';
import TooltipWrapper from '../../../../../../../components/TooltipWrapper/TooltipWrapper';
import clsx from 'clsx';

const onboardingStateSelector = (state: RootState) => state.onboarding;

const Leaders = () => {
  const dispatch = useAppDispatch();
  const { leaders } = useAppSelector(onboardingStateSelector);

  const {
    inviteMember,
    leaderTooltip: { tooltipContent, tooltipSubContent, tooltipSRLabel },
  } = useStrapiOnBoardingData();
  const { leaderInviteTitle, addLeaderButton, removeButton } = inviteMember;

  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex items-center gap-2">
        <h1 className="text-lg font-bold text-zinc-50 lg:text-xl">{leaderInviteTitle}</h1>

        <TooltipWrapper
          content={tooltipContent}
          subContent={tooltipSubContent}
          ariaLabel={tooltipSRLabel}
          direction={'top'}
          alignment={'start'}
        >
          <Info size={20} color={'white'} />
        </TooltipWrapper>
      </div>
      <div className="rounded-lg bg-zinc-900 p-4">
        <div className={clsx('flex flex-col justify-between gap-0.5', leaders.length > 0 && 'p-2')}>
          {leaders.map((data: Leader, index: number) => {
            return (
              <div key={index} className="flex items-start justify-between gap-1">
                <span className="break-all text-sm font-normal text-zinc-300">{data.email}</span>
                <span
                  className="cursor-pointer text-sm font-semibold text-base-brand"
                  onClick={() => dispatch(removeLeader(data.email))}
                >
                  {removeButton}
                </span>
              </div>
            );
          })}
        </div>

        <Dialog
          containerClassName="max-h-[80vh] overflow-y-auto"
          triggerClassName="p-2 text-base-brand"
          open={isAddMemberModalOpen}
          onOpenChange={(open) => setIsAddMemberModalOpen(open)}
          Content={
            <AddNewMemberMain
              INVITEE={INVITEE_ROLE.LEADER}
              setIsModalOpen={setIsAddMemberModalOpen}
              dropDownOptions={RoleOptions.LEADER}
              isLoading={false}
              isOnBoarding={true}
              isAllMembers={false}
            />
          }
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
        >
          <div className="flex items-center gap-2">
            <Plus size={16} color="#F7634F" weight="bold" />
            <p className="text-sm font-medium">{addLeaderButton}</p>
          </div>
        </Dialog>
      </div>
    </div>
  );
};
export default Leaders;
