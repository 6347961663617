import { USHG_HQ_APP_ROLE } from '../../../constants';
import useSessionTracker from '../../../hooks/use-session-tracker-hook';
import HasAccess from '../../shared/components/HasAccess';
import { ReportsFilterProvider } from '../../shared/providers';
import EnterpriseAdminReports from './EnterpriseAdminReports';
import EnterpriseLeaderReports from './EnterpriseLeaderReports';
import EnterpriseLearnerReports from './EnterpriseLearnerReports';
import EnterpriseManagerReports from './EnterpriseManagerReports';
import IndividualLearnerReports from './IndividualLearnerReports';
import { USHGAdminReports } from './USHGAdminReports';
type props = {
  lazyLoad?: boolean;
};
const Reports = ({ lazyLoad = true }: props) => {
  useSessionTracker();
  return (
    <ReportsFilterProvider>
      {/* Enterprise Leader Reports  */}
      <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEADER]} Fallback={null}>
        <EnterpriseLeaderReports lazyLoad={lazyLoad} />
      </HasAccess>
      {/* Enterprise Manager Reports  */}
      <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER]} Fallback={null}>
        <EnterpriseManagerReports lazyLoad={lazyLoad} />
      </HasAccess>
      {/* Enterprise Admin Reports  */}
      <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]} Fallback={null}>
        <EnterpriseAdminReports lazyLoad={lazyLoad} />
      </HasAccess>
      {/* USHG Admin Reports  */}
      <HasAccess roles={[USHG_HQ_APP_ROLE.USHG_ADMIN]} Fallback={null}>
        <USHGAdminReports lazyLoad={lazyLoad} />
      </HasAccess>
      {/* Enterprise Learner */}
      <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER]} Fallback={null}>
        <EnterpriseLearnerReports lazyLoad={lazyLoad} />
      </HasAccess>
      {/* Individual Learner */}
      <HasAccess roles={[USHG_HQ_APP_ROLE.INDIVIDUAL_USER]} Fallback={null}>
        <IndividualLearnerReports lazyLoad={lazyLoad} />
      </HasAccess>
    </ReportsFilterProvider>
  );
};
export default Reports;
