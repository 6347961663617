import { ArrowLeft } from 'phosphor-react';
import { Button } from '../../../../components/Buttons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CourseStatusCell from '../CourseStatusCell';
import { useStrapiHQAdminCoursesData } from '../../hooks/useStrapiHQAdminCourseData';

type HeaderProps = {
  heading: string;
  buttonText?: string;
  buttonIcon?: React.ReactNode;
  buttonIconFront?: boolean;
  buttonIconBack?: boolean;
  onButtonClickHandler?: any;
  isBackVisible?: boolean;
  hideButton?: boolean;
  backNavigationText?: string;
  onBackClick?: () => void;
};

const ViewHeader = ({
  heading,
  buttonText,
  onButtonClickHandler,
  isBackVisible,
  buttonIcon,
  buttonIconFront,
  buttonIconBack,
  hideButton,
  backNavigationText,
  onBackClick,
}: HeaderProps) => {
  const navigate = useNavigate();
  const defaultBackClick = () => {
    navigate(-1);
  };
  const {
    topNav: { backButton, draftStatusLabel },
  } = useStrapiHQAdminCoursesData();

  return (
    <div className="flex w-full flex-col items-center justify-between gap-3 border-b border-b-zinc-800 bg-card-bg px-3 py-6 xs:flex-row xs:items-center xs:gap-0 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
      <div className="flex flex-col gap-1 xs:gap-0">
        {isBackVisible && (
          <div
            className="flex cursor-pointer flex-row items-center"
            onClick={onBackClick ? onBackClick : defaultBackClick}
          >
            <ArrowLeft size={12} className="text-zinc-400" />
            <p className="font-sans text-xs font-normal text-zinc-400">
              &nbsp;{backNavigationText ? backNavigationText : backButton}
            </p>
          </div>
        )}
        <div className="flex gap-x-4">
          <p className="font-sans text-base font-semibold text-white xs:font-medium lg:text-xl">
            {heading}
          </p>
          {!hideButton && (
            <CourseStatusCell
              containerClassName="bg-zinc-900 text-zinc-50"
              display={draftStatusLabel}
            />
          )}
        </div>
      </div>
      <div>
        {!hideButton && (
          <Button
            className="flex w-full flex-row items-center justify-center gap-2 bg-base-brand py-2 text-xs font-medium text-white xs:w-fit"
            onClick={onButtonClickHandler}
          >
            {!buttonIconFront && !buttonIconBack && buttonIcon}
            {buttonIconFront && buttonIcon}
            {buttonText}
            {buttonIconBack && buttonIcon}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ViewHeader;
