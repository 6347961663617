import IndividualRegistrationMain from '../components/register/UserRegistration/IndividualRegistrationMain';
import IndividualRegistrationBaseLayout from '../layouts/IndividualRegistrationBaseLayout';

const individualRegisterRoutes = {
  path: 'register',
  element: <IndividualRegistrationBaseLayout />,
  children: [
    {
      path: 'individual',
      element: <IndividualRegistrationMain />,
    },
  ],
};

export default individualRegisterRoutes;
