import React from 'react';

interface SideBarContextValue {
  isSidebarCollapsed: boolean;
  setIsSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const sideBarContext = React.createContext<SideBarContextValue>({} as SideBarContextValue);

export default sideBarContext;
