import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

const useStrapiDashboardData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const strapiDashboardCommon = data.dashboard.data.attributes.COMMON;
  let salutation;
  let quotesList;
  let continueWhereYouLeft;
  let recommendedCourses;
  let exploreCourses;
  let dashboardCards;
  let calendar;
  let sizzleVideo;
  for (let i = 0; i <= strapiDashboardCommon?.length; i++) {
    if (strapiDashboardCommon[i]?.__typename === 'ComponentDashboardSalutation') {
      salutation = (strapiDashboardCommon ?? [])[i];
    } else if (strapiDashboardCommon[i]?.__typename === 'ComponentDashboardQuoteList') {
      quotesList = (strapiDashboardCommon ?? [])[i];
    } else if (strapiDashboardCommon[i]?.__typename === 'ComponentDashboardContinueCourses') {
      continueWhereYouLeft = (strapiDashboardCommon ?? [])[i];
    } else if (strapiDashboardCommon[i]?.__typename === 'ComponentDashboardRecommendedCourses') {
      recommendedCourses = (strapiDashboardCommon ?? [])[i];
    } else if (strapiDashboardCommon[i]?.__typename === 'ComponentDashboardExploreCourses') {
      exploreCourses = (strapiDashboardCommon ?? [])[i];
    } else if (strapiDashboardCommon[i]?.__typename === 'ComponentDashboardDashboardCards') {
      dashboardCards = (strapiDashboardCommon ?? [])[i];
    } else if (strapiDashboardCommon[i]?.__typename === 'ComponentDashboardCalendar') {
      calendar = (strapiDashboardCommon ?? [])[i];
    } else if(strapiDashboardCommon[i]?.__typename ==='ComponentDashboardSizzleVideo'){
      sizzleVideo =(strapiDashboardCommon ?? [])[i];
    }
  }

  const { morningSalutation, eveningSalutation, afternoonSalutation, agenda } = salutation;
  // leader charts
  const { quotes } = quotesList;
  return {
    continueWhereYouLeft,
    dashboardCards,
    recommendedCourses,
    exploreCourses,
    quotes,
    calendar,
    salutation: {
      morningSalutation,
      eveningSalutation,
      afternoonSalutation,
      agenda,
    },
    sizzleVideo,
  };
};

export default useStrapiDashboardData;
