import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { useStrapiHQAdminCoursesData } from '../../course-management/hooks/useStrapiHQAdminCourseData';
import { submitFeedback } from '../api/courses';
import { submitFeedbackPayloadType } from '../components/SingleCourseView/types/SingleCourseTypes';

const useFeedbackSubmitMutation = () => {
  const {
    toastMessages: { feedbackSentSuccess, feedbackSentFailed },
  } = useStrapiHQAdminCoursesData();
  const { data, error, isLoading, isError, mutate } = useMutation({
    mutationFn: async (payload: submitFeedbackPayloadType) => {
      const response = await submitFeedback(payload);
      return response;
    },
    onSuccess: () => {
      toast.custom((t) => (
        <Toast variant={'success'} Title={feedbackSentSuccess} toastInstance={t} />
      ));
    },
    onError: () => {
      toast.custom((t) => <Toast variant={'error'} Title={feedbackSentFailed} toastInstance={t} />);
    },
  });
  return { data, error, isLoading, isError, mutate };
};

export default useFeedbackSubmitMutation;
