import clsx from 'clsx';
import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { ErrorDisplay, NoDataDisplay } from '../../../../../../components/DataTables';
import { ERROR_TYPES } from '../../../../../../constants/error-types.constants';
import { REPORTS_DASHBOARD_LIMIT, REPORT_TYPES } from '../../../../../shared/constants';
import { useStrapiReportsData } from '../../../../../shared/hooks/useStrapiReportData';
import { useUSHGAdminTopChurnRiskQuery } from '../../../../hooks';
import { TopChurnRiskFilters } from '../../../../types';
import TableHeader from '../TableHeader';
import TopChurnRisksTable from './TopChurnRisksTable';
import ScaleLoader from '../../../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../../../components/ReportToolTip';
import { ReportTooltipDataType } from '../../../../../shared/types';
interface TopChurnRateTableProps {
  className: string;
  lazyLoad?: boolean;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}
const TopChurnRisksTableMain = ({
  className,
  lazyLoad = true,
  reportTooltipContent,
  userPersona,
}: TopChurnRateTableProps) => {
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);
  // Build the filter
  const filters: Partial<TopChurnRiskFilters> = {
    limit: REPORTS_DASHBOARD_LIMIT,
  };

  const topChurnRisks = useUSHGAdminTopChurnRiskQuery({ filters, isEnabled: isInView });

  const displayLoader =
    (topChurnRisks.isLoading && !topChurnRisks.data) ||
    (topChurnRisks.isFetching && topChurnRisks.data?.results.length === 0);

  const hasNoTopChurnRisks = topChurnRisks.data && topChurnRisks.data.results.length === 0;

  const hasTopChurnRisks =
    topChurnRisks.data && topChurnRisks.data.results && topChurnRisks.data.results.length > 0;

  const { title, emptyMessage, errorMessage } = useStrapiReportsData().TopChurnRisk;
  return (
    <InView
      as="div"
      className={clsx(
        'relative flex h-full max-h-[320px] min-h-[320px] w-full flex-col overflow-hidden rounded-lg bg-card-bg shadow-[0px_16px_24px_rgba(0,0,0,0.15)]',
        className
      )}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div className="flex gap-2 pr-5">
        <TableHeader title={title} className="py-4 pl-4" />
        {reportTooltipContent?.[REPORT_TYPES.TOP_CHURN_RISK]?.[userPersona] && (
          <ReportTooltip content={reportTooltipContent[REPORT_TYPES.TOP_CHURN_RISK][userPersona]} />
        )}
      </div>
      {/* Loader */}
      {displayLoader && (
        <div className="flex grow items-center justify-center py-4">
          <ScaleLoader />
        </div>
      )}
      {/* We have data */}
      {hasTopChurnRisks && (
        <div className="w-full overflow-hidden pb-2">
          <TopChurnRisksTable data={topChurnRisks.data?.results} />
        </div>
      )}

      {/* Empty State */}
      {hasNoTopChurnRisks && !displayLoader && (
        <div className="flex grow items-center justify-center">
          <NoDataDisplay message={emptyMessage} />
        </div>
      )}

      {topChurnRisks.isError && !topChurnRisks.data && (
        <div className="flex grow items-center justify-center">
          <ErrorDisplay
            refetch={topChurnRisks.refetch}
            isRefetching={topChurnRisks.isRefetching}
            allowsRefetch={true}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            message={errorMessage}
          />
        </div>
      )}
    </InView>
  );
};

export default TopChurnRisksTableMain;
