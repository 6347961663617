import { useQuery } from '@tanstack/react-query';
import { getEnterpriseAdminTeams } from '../api';
import { getHQAdminTeams } from '../api/HQAdminTeams';
import { PartialEnterpriseAdminTeamsFilter } from '../types/interface';

interface Props {
  filters: PartialEnterpriseAdminTeamsFilter;
  tenantId?: string;
  isOrganizationView?: boolean;
}

const useEnterpriseAdminTeamsQuery = ({ filters, tenantId, isOrganizationView }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery(
      [
        {
          scope: ['teams', 'organisations'],
          filters: filters,
          tenantId,
        },
      ],
      isOrganizationView
        ? async () => {
            return getHQAdminTeams(filters, tenantId as string);
          }
        : async () => {
            return getEnterpriseAdminTeams(filters);
          },
      {
        keepPreviousData: true,
        staleTime: 2000,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch };
};

export default useEnterpriseAdminTeamsQuery;
