import { useMemo } from 'react';
import { USHG_HQ_APP_ROLE } from '../../../constants';
import { useReduxAuthState } from '../../../hooks';
import {
  getCoursesAndBadgesNotificationPreferencesConfig,
  getUserManagementNotificationPreferencesConfig,
  getUSHGAdminNotificationPreferencesConfig,
} from '../helpers';
import {
  NotificationPreferencesCoursesAndBadgesItemsFragment,
  NotificationPreferencesOrganizationAndCoursesItemsFragment,
  NotificationPreferencesTeamsAndMembersItemsFragment,
} from '../types';
import useNotificationPreferencesStrapiData from './useNotificationPreferencesStrapiData';

const useNotificationPreferenceConfig = () => {
  const auth = useReduxAuthState();
  const { preferences } = useNotificationPreferencesStrapiData();

  /**
   * Generates the preference config
   */
  const preferenceConfig = useMemo(() => {
    // The user should be authenticated at this point
    if (!auth.isAuthenticated) {
      return [];
    }

    const role = auth.user.role;

    let config = [];

    // Strapi Labels

    const coursesAndBadgesLabels = preferences[
      'coursesAndBadges'
    ] as NotificationPreferencesCoursesAndBadgesItemsFragment;

    const userManagementLabels = preferences[
      'teamsAndMembers'
    ] as NotificationPreferencesTeamsAndMembersItemsFragment;

    const ushgAdminOrganizationAndCoursesConfig = preferences[
      'orgAndCourses'
    ] as NotificationPreferencesOrganizationAndCoursesItemsFragment;

    // Strapi Labels

    const coursesAndBadgesConfig = getCoursesAndBadgesNotificationPreferencesConfig({
      role,
      labels: {
        ...coursesAndBadgesLabels,
      },
    });

    switch (role) {
      case USHG_HQ_APP_ROLE.INDIVIDUAL_USER:
      case USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER: {
        config.push(coursesAndBadgesConfig);
        break;
      }
      case USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN:
      case USHG_HQ_APP_ROLE.ENTERPRISE_LEADER:
      case USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER: {
        const userManagementConfig = getUserManagementNotificationPreferencesConfig({
          role,
          labels: {
            ...userManagementLabels,
          },
        });

        config = [coursesAndBadgesConfig, userManagementConfig];
        break;
      }
      case USHG_HQ_APP_ROLE.USHG_ADMIN: {
        const ushgAdminConfig = getUSHGAdminNotificationPreferencesConfig({
          labels: {
            ...ushgAdminOrganizationAndCoursesConfig,
          },
        });
        config = [ushgAdminConfig];
        break;
      }
      default: {
        return [];
      }
    }

    return config;
  }, [auth, preferences]);

  return preferenceConfig;
};

export default useNotificationPreferenceConfig;
