import React from 'react';
import { BadgeComponent } from '../../../../../components/Badge';
import { Button, ButtonIcon } from '../../../../../components/Buttons';
import clsx from 'clsx';
import { Roles, SubscriptionType, Tenure } from '../../../types';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { setPricingInfo } from '../../../slices/pricing.slice';
import { useAppDispatch } from '../../../../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckCircle } from '../../../../../components/Icons';
import { SUBSCRIPTION_TYPE } from '../../../../../constants/subscription-plans';

interface PricingCardProps {
  pricing: Array<Record<string, any>>;
  tenure: Tenure;
  popularPlanIndex?: number;
  onPlanActionButtonClick?: () => void;
}

const PricingCard = ({
  pricing,
  tenure,
  popularPlanIndex = 1,
  onPlanActionButtonClick,
}: PricingCardProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const data: any = useStrapiDataHelper();
  const formData = data?.registration?.data.attributes;
  const { contactUsEmail } = useStrapiSharedData();
  const { contactUsEmailSubject } = formData;

  const contactUsClick = () => {
    window.location.href = `mailto:${contactUsEmail}?subject=${contactUsEmailSubject}`;
  };

  const inRegistrationFormPage = location.pathname === '/auth/register/subscription';

  const onButtonClick = (plan: Record<string, any>, value: string) => {
    onPlanActionButtonClick && onPlanActionButtonClick();

    const { role, value: subscriptionType } = plan;
    const payload = {
      role: role as Roles,
      freeTrail: value === 'free_trial' ? true : false,
      subscriptionType: subscriptionType as SubscriptionType,
    };
    dispatch(setPricingInfo(payload));

    // If the user is trying to subscribe to
    // the basic plan and we are not in registration page
    if (subscriptionType === SUBSCRIPTION_TYPE.BASIC && !inRegistrationFormPage) {
      navigate('/auth/register/individual');
      return;
    }

    const data = {
      plan,
      tenure,
    };

    navigate('/auth/register/subscription', {
      state: data,
    });
  };

  return (
    <React.Fragment>
      {pricing?.map((plan, index) => {
        const Svg = plan.AvatarForpricing.data?.attributes.url;
        const Badge = plan?.badgelabel;

        return (
          <div
            key={index}
            className={clsx(
              `flex max-w-sm flex-1 flex-col gap-4 rounded-3xl ${
                popularPlanIndex == index ? 'border border-[#C4D5004D]/30' : ''
              }  bg-zinc-800 p-8 shadow`,
              plan.className ?? ''
            )}
          >
            {Badge && (
              <BadgeComponent
                size="md"
                label={plan?.badgelabel}
                className="absolute top-4 right-4 mt-0 ml-auto rounded-lg border-0 bg-base-creme p-3 text-xs font-bold text-zinc-900"
                badgeSRLabel={`${plan?.badgelabel} plan`}
              />
            )}
            <div className="flex flex-col gap-4">
              <div className="flex items-center">
                {Svg && <img className="h-20 w-20" alt="" src={Svg}></img>}
                <div className="ml-5">
                  <p className="text-xl font-bold text-white">{plan.name}</p>
                </div>
              </div>
              <p className="text-zinc-200">{plan.description}</p>

              <ul className="text-left font-normal text-zinc-100">
                {plan?.features.map((feature: any, index: any) => {
                  return (
                    <li key={index} className="my-3 flex items-start gap-x-3 text-base">
                      <div className="flex h-6 shrink-0 items-center justify-center">
                        <CheckCircle />
                      </div>
                      <p>{feature.Listitem}</p>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="flex flex-col gap-4">
              <div className="my-4 flex items-baseline justify-start lg:inset-x-0">
                {plan?.role !== null ? (
                  Object.entries(plan.pricing).map(
                    ([key, val]: any) =>
                      val.value === tenure && (
                        <div key={key}>
                          <span className="mr-1 text-[2.5rem] font-bold text-white xl:text-5xl">
                            ${val?.price}
                          </span>
                          <span className="text-zinc-200">{val?.tenure}</span>
                        </div>
                      )
                  )
                ) : (
                  <p className="text-[2.5rem] font-bold text-white xl:text-5xl">
                    {plan?.pricingAltText}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-4">
                {plan.buttons.map((button: any, index: number) => {
                  const Icon = ButtonIcon;
                  return (
                    <React.Fragment key={index}>
                      <Button
                        className={clsx(
                          'inline-flex cursor-pointer items-center justify-center gap-2 rounded-lg text-center font-bold',
                          button.className,
                          'm-0'
                        )}
                        onClick={() =>
                          button?.value === 'contact_us'
                            ? contactUsClick()
                            : onButtonClick(plan, button?.value)
                        }
                      >
                        {button.label} <Icon />
                      </Button>
                      {button?.value === 'contact_us' && (
                        <Button
                          key={index}
                          className={clsx(
                            'invisible inline-flex cursor-pointer items-center justify-center gap-2 rounded-lg text-center font-bold',
                            button.className,
                            'm-0'
                          )}
                        >
                          {button.label} <Icon />
                        </Button>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default PricingCard;
