import { gql } from '@apollo/client';

const NotificationPreferencesCoursesAndBadgesItemsFragment = gql`
  fragment NotificationPreferencesCoursesAndBadgesItemsFragment on ComponentNotifCategoryCoursesAndBadges {
    heading
    description
    newBadgeEarned
    newCourseAssigned
    assignedCourseOverdue
  }
`;

const NotificationPreferencesTeamsAndMembersItemsFragment = gql`
  fragment NotificationPreferencesTeamsAndMembersItemsFragment on ComponentNotifCategoryTeamsAndMembers {
    heading
    description
    entNewTeamCreated
    entNewMemberOnboarded
    newEnterpriseTeamRequest
    newEnterpriseUserRequest
    enterpriseEditTeamRequest
    entUserTeamChangeManager
    entUserTeamChangeAdmin
  }
`;

const NotificationPreferencesOrganizationAndCoursesItemsFragment = gql`
  fragment NotificationPreferencesOrganizationAndCoursesItemsFragment on ComponentNotifCategoryOrgAndCourses {
    heading
    description
    newSignup
    paymentOverdue
    newSupportRequest
    newCourseFeedback
    subscriptionCancelled
  }
`;

const NotificationPreferencesFragment = gql`
  ${NotificationPreferencesCoursesAndBadgesItemsFragment}
  ${NotificationPreferencesTeamsAndMembersItemsFragment}
  ${NotificationPreferencesOrganizationAndCoursesItemsFragment}
  fragment NotificationPreferencesFragment on NotifPreferenceEntityResponse {
    data {
      attributes {
        heading
        description
        allOnLabel
        allOffLabel
        webLabel
        emailLabel
        saveButton
        prefs {
          coursesAndBadges {
            ...NotificationPreferencesCoursesAndBadgesItemsFragment
          }
          orgAndCourses {
            ...NotificationPreferencesOrganizationAndCoursesItemsFragment
          }
          teamsAndMembers {
            ...NotificationPreferencesTeamsAndMembersItemsFragment
          }
        }
      }
    }
  }
`;

export { NotificationPreferencesFragment };
