import { formatNumber } from '../../../../../../../utils';

interface Props {
  risk: string;
}

const RiskValueCell = ({ risk }: Props) => {
  return (
    <div className="flex space-x-1.5">
      <span className="whitespace-nowrap">{formatNumber(risk)}</span>
    </div>
  );
};

export default RiskValueCell;
