import { useMemo, useState } from 'react';
import { generateCDNMediaAssetURLWithKey } from '../../features/shared/helpers';

type ImageElementProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

interface Props extends Omit<ImageElementProps, 'src' | 'srcSet' | 'className'> {
  imageKey?: string;
  className?: string;
  Fallback: React.ReactNode | null;
}

/**
 * Renders an image and fallbacks to Fallback if there is an error
 * image is completely unstyled would expect the component user to pass the styles to the image
 */
const ImageWithFallback = (props: Props) => {
  const { imageKey, className, Fallback, ...imgProps } = props;
  const [showFallback, setShowFallback] = useState(false);

  /**
   * Takes the key and generates the media assets cdn url.
   */
  const cdnImageURL = useMemo(() => {
    if (!imageKey) {
      return null;
    }

    const cdnImageURL = generateCDNMediaAssetURLWithKey({ key: imageKey });
    return cdnImageURL;
  }, [imageKey]);

  return (
    <>
      {cdnImageURL && !showFallback && (
        <img
          {...imgProps}
          className={className}
          onError={() => {
            // On error show acronym logo
            setShowFallback(true);
          }}
          src={cdnImageURL}
        ></img>
      )}
      {(!cdnImageURL || showFallback) && Fallback}
    </>
  );
};

export default ImageWithFallback;
