import { useQuery } from '@tanstack/react-query';
import { PartialEnterpriseLeaderBoardFilter } from '../../shared';
import { PERFORMANCE_REPORT_TYPES, REPORTS_STALE_TIME } from '../../shared/constants';
import { getEnterpriseTeamLeaderboard } from '../api';

interface Props {
  filters: PartialEnterpriseLeaderBoardFilter;
  isEnabled: boolean;
}
const useEnterpriseTeamLeaderboardQuery = ({ filters, isEnabled }: Props) => {
  const { isLoading, data, isError, refetch, isRefetching, isFetching } = useQuery(
    [
      {
        filters: filters,
        scope: 'dashboard',
        item: PERFORMANCE_REPORT_TYPES.TEAM_LEADERBOARD,
      },
    ],
    async ({ queryKey }) => {
      const { filters } = queryKey[0];
      return await getEnterpriseTeamLeaderboard(filters);
    },
    {
      staleTime: REPORTS_STALE_TIME,
      enabled: isEnabled,
    }
  );
  return { isLoading, data, isError, refetch, isRefetching, isFetching };
};

export default useEnterpriseTeamLeaderboardQuery;
