import Dialog from '../../../../../components/Dialog';
import { USHGAdminRequest } from '../../../types';
import RequestActionDialogContent from './RequestActionDialogContent';

interface Props {
  request: USHGAdminRequest;
  open: boolean;
  setIsDialogOpen: (open: boolean) => void;
}

const RequestActionDialog = (props: Props) => {
  const { request, open, setIsDialogOpen } = props;

  return (
    <Dialog
      open={open}
      onOpenChange={setIsDialogOpen}
      containerClassName="max-w-2xl"
      Content={<RequestActionDialogContent setIsDialogOpen={setIsDialogOpen} request={request} />}
    ></Dialog>
  );
};

export default RequestActionDialog;
