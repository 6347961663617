import { House } from 'phosphor-react';
import SideBar from '../../../../../components/SideBar';
import { useStrapiSideNavData } from '../../../../../hooks/useStrapiSideNavData';
import { CustomSideNavItemProps } from '../../../types';

const DashboardNavItem = ({ eventsCount }: CustomSideNavItemProps) => {
  const { routes } = useStrapiSideNavData();
  return (
    <SideBar.SideBarNavItem
      icon={
        <House aria-label="Dashboard" size="20px" color="currentColor" className="text-inherit" />
      }
      label={routes.dashboardLabel}
      path="dashboard"
      eventsCount={eventsCount}
    ></SideBar.SideBarNavItem>
  );
};

export default DashboardNavItem;
