import { gql } from '@apollo/client';

export const ForgotPasswordFragment = gql`
  fragment ForgotPasswordFragment on ForgotpasswordEntityResponse {
    data {
      attributes {
        title
        description
        emailLabel
        emailPlaceholder
        resetPasswordButton
        backToLoginButton
        forgotPasswordInitiationFailed
      }
    }
  }
`;
