import clsx from 'clsx';
import AlertIcon from './AlertIcon';

export type AlertVariant = 'error' | 'warning' | 'success' | 'info';

interface Props {
  variant: AlertVariant;
  className?: string;
  iconClassName?: string;
  message: React.ReactNode;
  action?: {
    content: string;
    onAction: () => void;
  };
}

const getColorsForVariant = (variant: AlertVariant) => {
  switch (variant) {
    case 'error': {
      return {
        bg: 'bg-rose-900',
        text: 'text-rose-50',
      };
    }
    case 'warning': {
      return {
        bg: 'bg-amber-900',
        text: 'text-amber-50',
      };
    }
    case 'success': {
      return {
        bg: 'bg-emerald-900',
        text: 'text-emerald-50',
      };
    }
    case 'info': {
      return {
        bg: 'bg-blue-900',
        text: 'text-blue-50',
      };
    }
  }
};

const Alert = ({ variant, message, className, action, iconClassName }: Props) => {
  const colors = getColorsForVariant(variant);

  return (
    <div className={clsx('flex rounded-[8px] p-4', colors.bg, className)}>
      <div className="flex justify-center px-1">
        <AlertIcon variant={variant} className={iconClassName} />
      </div>
      <div
        className={clsx(
          'flex grow flex-col items-start gap-4 px-1 text-xs font-medium',
          colors.text
        )}
      >
        <div>{message}</div>
        {action && (
          <button type="button" onClick={action.onAction} className="underline">
            {action.content}
          </button>
        )}
      </div>
    </div>
  );
};

export default Alert;
