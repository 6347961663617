import React from 'react';
import { SwitchInput } from '../../../../../components/FormElements/SwitchInput';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { setTenure } from '../../../slices/pricing.slice';

const TenureToggle = () => {
  const dispatch = useAppDispatch();
  const data = useStrapiDataHelper();
  const formData = data?.registration?.data.attributes;
  const { monthy, annually } = formData;
  const { label: monthlyLabel, value: month } = monthy;
  const { label: annuallyLabel, value: year } = annually;
  const tenure = useAppSelector((state) => state.pricing.tenure);

  const onSwitchTenure = (event: React.FormEvent<HTMLInputElement>) => {
    const checked = (event.target as HTMLInputElement).checked;
    dispatch(setTenure(checked ? year : month));
  };

  return (
    <SwitchInput
      className="text-lg font-medium text-zinc-300"
      primaryLabel={monthlyLabel}
      secondaryLabel={annuallyLabel}
      onSwitch={onSwitchTenure}
      checked={tenure === year ? true : false}
    />
  );
};

export default TenureToggle;
