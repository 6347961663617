import { useQuery } from '@tanstack/react-query';
import { getSubscriptionDetails } from '../../settings/api';
import { STALE_TIME } from '../../settings/constants/settings';
import { FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY } from '../constants/profile';

const useFetchSubscription = ({ enabled }: { enabled: boolean }) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching } =
    useQuery(
      [FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY],
      async () => {
        return await getSubscriptionDetails();
      },
      {
        keepPreviousData: true,
        staleTime: STALE_TIME,
        enabled: enabled,
        refetchOnMount: false,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching };
};

export default useFetchSubscription;
