import { useQuery } from '@tanstack/react-query';
import { useReduxAuthState } from '../../../hooks';
import { getUserNotificationPreferences } from '../api';

interface Props {
  staleTime?: number;
  cacheTime?: number;
}

/**
 * Wrapper around useQuery to fetch user notification preferences
 */
const useUserNotificationPreferenceQuery = (props: Props) => {
  const { staleTime, cacheTime } = props;
  const auth = useReduxAuthState();

  const query = useQuery({
    queryKey: [
      {
        scope: 'settings',
        item: 'notification-preferences',
        // The user would be authenticated at this point
        // so this should not be null/undefined
        username: auth.user?.username,
      },
    ],
    queryFn: async () => {
      const response = await getUserNotificationPreferences();
      return response;
    },
    staleTime,
    cacheTime,
    refetchOnMount: false,
    enabled: auth.isAuthenticated,
  });

  return query;
};

export default useUserNotificationPreferenceQuery;
