import { useMutation } from '@tanstack/react-query';
import { updateAssessmentQuestionOrder } from '../api/coursesManagement';
import { UpdateAssessmentQuestionOrderArgs } from '../types';

const useUpdateQuestionOrderMutation = () => {
  const mutation = useMutation({
    mutationFn: (update: UpdateAssessmentQuestionOrderArgs) =>
      updateAssessmentQuestionOrder(update),
  });

  return mutation;
};

export default useUpdateQuestionOrderMutation;
