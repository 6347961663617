import { useCallback, useState } from 'react';
import useInterval from './use-interval';

interface Props {
  countdownInSeconds: number;
}

export type CountDownState = 'in-progress' | 'complete';

const useCountDown = (props: Props) => {
  const { countdownInSeconds } = props;

  const [state, setState] = useState<CountDownState>('in-progress');
  const [timeRemainingInSeconds, setTimeRemainingInSeconds] = useState(countdownInSeconds);

  useInterval(
    () => {
      const newTimeRemainingInSeconds = timeRemainingInSeconds - 1;

      if (newTimeRemainingInSeconds <= 0) {
        setTimeRemainingInSeconds(0);
        setState('complete');
      } else {
        setTimeRemainingInSeconds(newTimeRemainingInSeconds);
      }
    },
    state === 'in-progress' ? 1000 : null
  );

  /**
   * Reset the countdown
   */
  const reset = useCallback(() => {
    setTimeRemainingInSeconds(countdownInSeconds);
    setState('in-progress');
  }, [countdownInSeconds]);

  return {
    timeRemainingInSeconds,
    reset,
    state,
  };
};

export default useCountDown;
