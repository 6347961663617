// Shared file to maintain regex patterns
// Common regex pattern used all over the application
export const NAME_REGEX_PATTERN = '^[a-zA-Z]+(\\s[a-zA-Z]+)?$';
export const PHONE_NUMBER_REGEX_PATTERN = '^\\([2-9][\\d]{2}\\) [\\d]{3}-[\\d]{4}$';
export const EMAIL_REGEX_PATTERN = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$';
export const SINGLE_DIGIT_REGEX_PATTERN = '[0-9]{1}';
export const LOWERCASE_CHARACTER_REGEX_PATTERN = '[a-z]';
export const UPPERCASE_CHARACTER_REGEX_PATTERN = '[A-Z]';
export const SPECIAL_CHARACTER_REGEX_PATTERN = '[^a-zA-Z0-9\\s]';
export const LEADING_AND_TRAILING_SPACES_PATTERN = /^\S(.*\S)?$/;
export const FIRSTNAME_REGEX_PATTERN = "^[a-zA-Z][A-Za-z0-9.'\\-_ ]{0,}[A-Za-z0-9.'\\-_]$";
export const LASTNAME_REGEX_PATTERN = "^[a-zA-Z][A-Za-z0-9.'\\-_ ]*$";
