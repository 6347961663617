import React, { useEffect, useState } from 'react';

import StatisticsCard from './StatisticsCard';
import ComponentHeading from '../../../dashboard/components/common/ComponentHeading';
import { toTitleCaseKeys } from '../../../../utils/titleCaseConvertor';

import { ErrorDisplay, NoDataDisplay } from '../../../../components/DataTables';
import { BasicSpinner } from '../../../../components/Spinners';
import { ERROR_TYPES } from '../../../../constants/error-types.constants';
import { useStrapiTeamData } from '../../hooks/useStrapiTeamData';
import { calcTimeString } from '../../../shared/helpers';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import { formatNumber } from '../../../../utils';

type StatisticsCardProps = {
  statsCardTitle?: string;
  className?: string;
  courseHistory?: any;
};

const SinglePersonCourseHistory = ({
  statsCardTitle,
  className,
  courseHistory,
}: StatisticsCardProps) => {
  const [details, setDetails] = useState({});
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const { singlePerson } = useStrapiTeamData();
  const {
    totalLearningHoursLabel,
    completedCoursesLabel,
    assignedCoursesHistoryLabel,
    inProgressCoursesLabel,
    mostEngagedCategoryLabel,
    averageAssessmentScoreLabel,
  } = singlePerson;

  const calaculatedTime = calcTimeString(courseHistory?.data.timeSpent);
  useEffect(() => {
    if (courseHistory.data) {
      const courseHistoryObject = {
        [assignedCoursesHistoryLabel]: courseHistory?.data.assignedCourses || 0,
        [inProgressCoursesLabel]: courseHistory?.data.inProgressCourses || 0,
        [completedCoursesLabel]: courseHistory?.data.completedCourses || 0,
        [totalLearningHoursLabel]: calaculatedTime || 0,
        [averageAssessmentScoreLabel]: courseHistory.data.averageScore
          ? formatNumber(courseHistory.data.averageScore) + '%'
          : 0 + '%',
        [mostEngagedCategoryLabel]: courseHistory?.data.mostEngagedCategory || '-',
      };
      setDetails(toTitleCaseKeys(courseHistoryObject));
    }
  }, [courseHistory.data]);
  return (
    <div className={className}>
      {statsCardTitle && (
        <ComponentHeading className="mb-2" heading={statsCardTitle}></ComponentHeading>
      )}
      <div className="flex flex-wrap gap-4">
        {courseHistory.error ? (
          <div className="flex h-full w-full items-center justify-center text-zinc-200">
            <ErrorDisplay
              message={strapiErrorMessage['DATA_ERROR']}
              type={ERROR_TYPES.SOMETHING_WENT_WRONG}
              refetch={courseHistory.refetch}
              isRefetching={courseHistory.isRefetching}
            />
          </div>
        ) : !courseHistory.isLoading && courseHistory.data ? (
          <StatisticsCard profile={details} />
        ) : !courseHistory.data && !courseHistory.isLoading ? (
          <NoDataDisplay message="No data available." />
        ) : (
          <div className="flex h-full w-full items-center justify-center">
            {courseHistory.isLoading && <BasicSpinner className="text-white" />}
          </div>
        )}
      </div>
    </div>
  );
};

export default SinglePersonCourseHistory;
