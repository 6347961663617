import { useState } from 'react';
import { Navbar } from 'react-daisyui';
import Dialog from '../../../components/Dialog';
import { useSpeakerStrapiData } from '../hooks/useSpeakerStrapiData';
import CreateAndEditNewSpeaker from './Modal/CreateAndEditNewSpeaker';
import '../../../styles/header.css';

function TopNavBar() {
  const { topNavData, modalStrapiData } = useSpeakerStrapiData();
  const { addNewSpeakerButton, createTitle } = modalStrapiData;
  const [isAddNewSpeakerModelOpen, setAddNewSpeakerModelOpen] = useState(false);
  return (
    <Navbar className="flex w-full items-center justify-between border-b border-b-zinc-800 bg-card-bg px-3 py-6 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
      <div className="flex w-full flex-row justify-between">
        <div className="header-title">{topNavData.title}</div>
        <div className="relative mb-4 mt-[25px] flex max-w-[188px] flex-col xs:m-0 xs:flex xs:max-w-none xs:gap-0 xs:gap-y-4 sm:gap-y-4 md:flex md:flex-row md:space-x-2">
          <Dialog
            triggerClassName="inline-block h-[30px] max-w-[200px] !rounded bg-base-brand p-[5px] text-xs font-medium leading-tight  text-white hover:bg-base-brand md:max-w-none md:px-5 md:py-1"
            open={isAddNewSpeakerModelOpen}
            onOpenChange={(open) => setAddNewSpeakerModelOpen(open)}
            Content={
              <CreateAndEditNewSpeaker
                type="ADD"
                formActionButtonLabel={addNewSpeakerButton}
                modalTitle={createTitle}
              />
            }
            onInteractOutside={(e) => {
              e.preventDefault();
            }}
          >
            {topNavData.addNewSpeakerButton}
          </Dialog>
        </div>
      </div>
    </Navbar>
  );
}

export default TopNavBar;
