import { useMutation } from '@tanstack/react-query';
import { ModuleUpdateMutationType } from '../types';
import { updateModule } from '../api';

const useModuleUpdateMutation = () => {
  const { data, error, isError, isLoading, mutate } = useMutation({
    mutationFn: (data: ModuleUpdateMutationType) => updateModule(data),
  });
  return { data, error, isError, isLoading, mutate };
};

export default useModuleUpdateMutation;
