import { intlFormatDistance } from 'date-fns';
import { useMemo } from 'react';
import { useLanguage } from '../../../../../../shared/context/languageContext';

interface Props {
  lastLogin?: string;
}

const LastLoginCell = (props: Props) => {
  const { lastLogin } = props;
  const { language } = useLanguage();

  const formattedLastLogin = useMemo(() => {
    if (!lastLogin) {
      return '-';
    }

    const lastLoginDate = new Date(lastLogin);
    const baseDate = new Date();

    return intlFormatDistance(lastLoginDate, baseDate, { locale: language });
  }, [lastLogin]);

  return <span className="text-xs font-normal text-zinc-100">{formattedLastLogin}</span>;
};

export default LastLoginCell;
