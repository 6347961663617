import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import useStrapiBadgesData from '../../hooks/useStrapiBadgeData';
import { Leaderboard } from '../../types/badges';
import LeaderboardBadgesCountCell from './LeaderboardTableCell/LeaderboardBadgesCountCell';
import LeaderboardNameCell from './LeaderboardTableCell/LeaderBoardNameCell';

interface Props {
  data: Leaderboard[];
}

const LeaderboardTable = ({ data }: Props) => {
  const { myBadges } = useStrapiBadgesData();
  const { leaderBoard } = myBadges;
  const { nameTableHeader, badgesTableHeader } = leaderBoard;
  console.log(myBadges);
  const columnHelper = createColumnHelper<Leaderboard>();
  const columns = [
    columnHelper.accessor('name', {
      header: nameTableHeader,
      cell: ({ getValue }) => <LeaderboardNameCell name={getValue() as string} />,
    }),
    columnHelper.accessor('badgesCount', {
      header: badgesTableHeader,
      cell: ({ getValue }) => <LeaderboardBadgesCountCell badgesCount={getValue() as number} />,
    }),
  ];
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <table className="w-full">
        <thead className="w-full">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="bg-zinc-900 px-2 py-3 text-start text-[12px] font-semibold leading-[16px] text-zinc-400"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="even:bg-zinc-900">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={`px-2 py-3 text-[12px] font-normal leading-[16px] text-zinc-100 `}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default LeaderboardTable;
