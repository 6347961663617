import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

const QuestionMark = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="70"
      viewBox="0 0 45 70"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1641 46.2501C13.1641 49.7022 15.962 52.5001 19.4141 52.5001C22.8661 52.5001 25.6641 49.7022 25.6641 46.2501V43.473C27.3245 43.1397 29.1786 42.648 31.0536 41.9167C39.6703 38.5688 44.4141 31.9688 44.4141 23.3334C44.4141 12.8792 38.1641 0.416748 21.4974 0.416748C10.2057 0.416748 0.664062 9.00425 0.664062 19.1667C0.664062 22.6188 3.46198 25.4167 6.91406 25.4167C10.3661 25.4167 13.1641 22.6188 13.1641 19.1667C13.1641 17.0834 16.412 12.9167 21.4974 12.9167C27.7474 12.9167 31.9141 17.0834 31.9141 21.2501C31.9141 29.5834 20.6703 31.6542 19.4141 31.6667C15.962 31.6667 13.1641 34.4647 13.1641 37.9167V46.2501ZM25.6641 62.9167C25.6641 66.3685 22.8658 69.1667 19.4141 69.1667C15.9623 69.1667 13.1641 66.3685 13.1641 62.9167C13.1641 59.465 15.9623 56.6667 19.4141 56.6667C22.8658 56.6667 25.6641 59.465 25.6641 62.9167Z"
        fill="#52525B"
      />
    </svg>
  );
};

export default QuestionMark;
