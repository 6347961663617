import { gql } from '@apollo/client';
import { LoginMFAFragment } from './LoginMFAFragment';

const LoginFragment = gql`
  ${LoginMFAFragment}
  fragment LoginFragment on LoginEntityResponse {
    data {
      attributes {
        LoginTitle
        LoginDesc
        signup
        NeedNewAcc
        ForgotPasswordLabel
        RememberMe
        loginwithSSO
        backToConsumerLogin
        adminTitle
        adminDescription
        loginButton
        emailLabel
        passwordLabel
        LoginImage {
          data {
            attributes {
              url
            }
          }
        }
        MFA {
          ...LoginMFAFragment
        }
        Seo {
          pageTitle
          pageDescription
          ogTitle
          ogDescription
          ogSiteName
          canonicalUrl
          twitterTitle
          twitterDescription
          twitterUsername
          socialMediaImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          ogType
          twitterCardType
          preventIndexing
        }
        authIllustrationSRLabel
        oktaSvgTitle
        UserConfirmAccountSuccess {
          title
          subTitle
          onClickLabel
          confirmingYourAccountSpinnerLabel
        }
        UserConfirmAccountFailure {
          subTitle
          onClickLabel
        }
      }
    }
  }
`;
export { LoginFragment };
