import axios from 'axios';
import { useMemo } from 'react';
import ErrorMessage from '../../../../components/FormElements/ErrorMessage';
import { USHGAPIError } from '../../../../types';
import { ERROR_CODE } from '../../../members/constants/errors';
import { getInitBulkInviteErrorMessage } from '../../helpers';
import { useBulkUploadErrorMap } from '../../hooks/useBulkUploadErrorMap';

// TODO: We need to get this as a map from strapi
// instead of separate labels

interface Props {
  error: Error;
  isOnboarding?: boolean;
}

const EnterpriseBulkInviteUserError = (props: Props) => {
  const { error, isOnboarding = false } = props;
  const { errorMap: BULK_UPLOAD_ERROR_MESSAGE_CODE_TO_MESSAGE_MAP } = useBulkUploadErrorMap();

  const message = useMemo(() => {
    let message: string | null = null;

    if (axios.isAxiosError(error)) {
      const { errorCode, errorMessage } = (error.response?.data as USHGAPIError) ?? {};

      message = getInitBulkInviteErrorMessage({
        errorCode,
        errorMessage,
        errorCodeToMessageMap: BULK_UPLOAD_ERROR_MESSAGE_CODE_TO_MESSAGE_MAP,
        isOnboarding,
      });
    }

    message =
      message ??
      BULK_UPLOAD_ERROR_MESSAGE_CODE_TO_MESSAGE_MAP[ERROR_CODE.BULK_UPLOAD_UNKNOWN_ERROR];

    return message;
  }, [BULK_UPLOAD_ERROR_MESSAGE_CODE_TO_MESSAGE_MAP, error, isOnboarding]);

  return <ErrorMessage className="break-all text-center text-sm" message={message} />;
};

export default EnterpriseBulkInviteUserError;
