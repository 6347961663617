import { useQuery } from '@tanstack/react-query';
import { getSpeakers } from '../api';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import React from 'react';
import { useStrapiHQAdminCoursesData } from './useStrapiHQAdminCourseData';

const useGetSpeakersQuery = () => {
  const {
    toastMessages: { speakerFetchErrorTitle, speakerFetchErrorMessage },
  } = useStrapiHQAdminCoursesData();
  const { data, isLoading } = useQuery({
    queryKey: ['get-all-speakers'],
    queryFn: getSpeakers,
    select: (data: any) => ({
      count: data.count,
      speakers: data.data.map((speaker: any) => ({
        label: `${speaker.firstName} ${speaker.lastName}`,
        value: speaker.id,
      })),
    }),
    onError: (error) => {
      console.error('Error while fetching speakers %o', error);
      toast.custom((t) => (
        <Toast
          variant="error"
          Title={speakerFetchErrorTitle}
          SubTitle={speakerFetchErrorMessage}
          toastInstance={t}
        />
      ));
    },
  });
  return { data, isLoading };
};

export default useGetSpeakersQuery;
