import { useStrapiOnBoardingData } from '../../hooks/useStrapiOnBoardingData';
import SetPasswordForm from './SetPasswordForm';
import SetPasswordHeader from './SetPasswordHeader';

const SetPasswordMain = () => {
  const { setPassword } = useStrapiOnBoardingData();
  const { title, description } = setPassword;

  return (
    <main id="main-content" className="flex grow flex-col items-center justify-center gap-5">
      <SetPasswordHeader heading={title} subHeading={description} />
      <SetPasswordForm className="pt-5" />
    </main>
  );
};

export default SetPasswordMain;
