import type { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import {
  sharedCognitoAmplifyAuthConfig,
  ushgAdminAmplifyAuthConfig,
} from '../../../config/aws-config';
import { AmplifyAuthOptions } from '../../../types';
import {
  LOCAL_STORAGE_CHIME_APP_INSTANCE_ARN_KEY,
  LOCAL_STORAGE_USER_TENANT_ID,
} from '../../messaging/constants';
import { getTenantDetailsForUser } from '../api';
import {
  COGNITO_FEDERATED_IDP,
  LOCAL_STORAGE_AMPLIFY_CONFIG_KEY,
  LOCAL_STORAGE_REMEMBER_ME_KEY,
} from '../constants';
import { configureAmplifyAuth, getCurrentAuthenticatedUser } from '../utils';

/**
 * Fetches Tenant details for user, creates and configures amplify config and stores in localStorage.
 * @param {string} username - user email
 * @param {boolean} rememberMe - whether to persist user login across browser sessions
 * @return {true} Returns true if successful
 */
const getTenantDetailsAndConfigureAmplifyAuth = async (username: string, rememberMe = false) => {
  try {
    // get the tenant details for the current username
    const tenantDetails = await getTenantDetailsForUser(username);
    const {
      userPoolId,
      userPoolWebClientId,
      region,
      identityPoolId,
      chatAppInstanceArn,
      tenantId,
    } = tenantDetails;

    console.log('Started Configuring Amplify Auth');

    // If rememberMe, store to localStorage else store to sessionStorage
    const amplifyStorage = rememberMe === true ? window.localStorage : window.sessionStorage;
    const amplifyAuthConfig = {
      userPoolId,
      userPoolWebClientId,
      region,
      identityPoolId,
      storage: amplifyStorage,
    };
    configureAmplifyAuth(amplifyAuthConfig);

    // Remove LocalStorage / Session Storage instance from the config
    const localStorageAmplifyConfig = {
      ...amplifyAuthConfig,
      storage: undefined,
    };

    // cache config to localStorage
    localStorage.setItem(
      LOCAL_STORAGE_AMPLIFY_CONFIG_KEY,
      JSON.stringify(localStorageAmplifyConfig)
    );
    localStorage.setItem(
      LOCAL_STORAGE_REMEMBER_ME_KEY,
      rememberMe ? JSON.stringify(rememberMe) : 'false'
    );

    // TODO: currently we are storing the chime AppInstanceArn in localStorage
    // TODO: maybe we could add it as a custom attribute in cognito
    localStorage.setItem(LOCAL_STORAGE_CHIME_APP_INSTANCE_ARN_KEY, chatAppInstanceArn);
    localStorage.setItem(LOCAL_STORAGE_USER_TENANT_ID, tenantId);

    console.log('Configured Amplify Auth');

    return true;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

interface SignInUserParams {
  username: string;
  password: string;
  rememberMe?: boolean;
}

interface CompleteNewPasswordParams {
  user: CognitoUser | null;
  newPassword: string;
}

/**
 * Function to sign in enterprise users or individual users i.e. customers
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {SignInUserParams} SignInUserParams - sign in parameters
 * @return {CognitoUser} CognitoUser object
 */
const signInUser = async (params: SignInUserParams): Promise<CognitoUser> => {
  try {
    const { username, password, rememberMe } = params;
    await getTenantDetailsAndConfigureAmplifyAuth(username, rememberMe);
    const response = await Auth.signIn(username, password);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

type ConfigureAndStoreAmplifyAuthParams = {
  amplifyAuthConfig: AmplifyAuthOptions;
};

/**
 * To configure and store amplify auth
 */
const configureAndStoreAmplifyAuth = (params: ConfigureAndStoreAmplifyAuthParams) => {
  const { amplifyAuthConfig } = params;

  console.log('Started Configuring Amplify Auth');

  configureAmplifyAuth(amplifyAuthConfig);

  // Save config to localStorage
  localStorage.setItem(LOCAL_STORAGE_AMPLIFY_CONFIG_KEY, JSON.stringify(amplifyAuthConfig));
  localStorage.setItem(LOCAL_STORAGE_REMEMBER_ME_KEY, JSON.stringify(true));

  console.log('Configured amplify auth');
};

/**
 * Function to login ushg admins with ushg okta provider, Redirects user to USHG okta sign in page
 * @param {string} state - optional state to pass to federatedSignIn
 * @return {ICredentials} Credentials object
 */
const signInUserWithUSHGOktaProvider = async (state?: string) => {
  try {
    const amplifyAuthConfig = ushgAdminAmplifyAuthConfig as AmplifyAuthOptions;
    configureAndStoreAmplifyAuth({ amplifyAuthConfig });

    const federatedSignInOpts = {
      customProvider: COGNITO_FEDERATED_IDP.USHG_ADMIN.OKTA,
      customState: state,
    };
    const response = await Auth.federatedSignIn(federatedSignInOpts);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

type SignInWithSharedCognitoFederatedIdpOptions = {
  state?: string;
};

/**
 * Sign in with shared cognito user pool federated identity provider
 */
const signInWithSharedCognitoFederatedIdp = async (
  provider: keyof typeof COGNITO_FEDERATED_IDP.SHARED,
  options?: SignInWithSharedCognitoFederatedIdpOptions
) => {
  const { state } = options ?? {};

  try {
    const amplifyAuthConfig = sharedCognitoAmplifyAuthConfig as AmplifyAuthOptions;
    configureAndStoreAmplifyAuth({ amplifyAuthConfig });

    const federatedSignInOpts = {
      customProvider: COGNITO_FEDERATED_IDP.SHARED[provider],
      customState: state,
    };
    const response = await Auth.federatedSignIn(federatedSignInOpts);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Initiates forgot password flow
 * @param {string} username - cognito username
 */
const initiateForgotPasswordRequest = async (username: string) => {
  await getTenantDetailsAndConfigureAmplifyAuth(username, true);
  const response = await Auth.forgotPassword(username);
  return response;
};

interface CompleteForgotPasswordRequestParams {
  username: string;
  code: string;
  password: string;
}

/**
 * Completed forgot password flow using the code from user
 * @summary reset user password with the code sent from forgot password flow
 * @param {CompleteForgotPasswordRequestParams} params
 * @return - A promise that resolves if success
 */
const completeForgotPasswordRequest = async (params: CompleteForgotPasswordRequestParams) => {
  const { username, code, password } = params;
  getTenantDetailsAndConfigureAmplifyAuth(username, true);
  const response = await Auth.forgotPasswordSubmit(username, code, password);
  return response;
};

/**
 * Signout user from cognito
 * @param {Parameters<typeof Auth.signOut>[0]} opts - signout options
 * @return resolves if success
 */
const signOutUser = async (opts?: Parameters<typeof Auth.signOut>[0]) => {
  const response = await Auth.signOut(opts);
  return response;
};

/**
 * Function to complete new password and required attributes during the first sign-in attempt into the application.
 * @param {CompleteNewPasswordParams} params
 * @return {CognitoUser} CognitoUser object
 */
const completeNewPassword = async (params: CompleteNewPasswordParams): Promise<CognitoUser> => {
  try {
    const { user, newPassword } = params;
    const response = await Auth.completeNewPassword(user, newPassword);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export type ChangePasswordParams = {
  oldPassword: string;
  newPassword: string;
};

const changePassword = async (params: ChangePasswordParams) => {
  try {
    const { oldPassword, newPassword } = params;
    const user = await getCurrentAuthenticatedUser();
    const response = await Auth.changePassword(user, oldPassword, newPassword);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export {
  signInUser,
  signInUserWithUSHGOktaProvider,
  initiateForgotPasswordRequest,
  completeForgotPasswordRequest,
  signOutUser,
  completeNewPassword,
  changePassword,
  signInWithSharedCognitoFederatedIdp,
};
