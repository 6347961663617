import { CalendarItemType } from '../../../types';
import clsx from 'clsx';
import { CALENDAR_ITEM_TYPE } from '../../../constants';

interface DotProps {
  className?: string;
}

const Dot = ({ className }: DotProps) => (
  <div className={clsx('aspect-square h-1.5 w-1.5 rounded-full', className)}></div>
);

interface Props {
  calendarItemType: CalendarItemType;
  className?: string;
}

const CalendarItemDot = ({ calendarItemType, className }: Props) => {
  switch (calendarItemType.type) {
    case CALENDAR_ITEM_TYPE.COURSE.DUE: {
      return <Dot className={clsx('bg-[#E11D48]', className)} />;
    }
    // This shouldn't happen
    default: {
      return <Dot className={clsx('bg-base-creme', className)} />;
    }
  }
};

export default CalendarItemDot;
