import React, { useCallback, useRef } from 'react';
import { UserCard } from '../../../../../components/Cards';
import { UserDetails } from '../../../../../components/Cards/UserCard';
import { BasicSpinner } from '../../../../../components/Spinners';
import { useStrapiReportsData } from '../../../../shared/hooks/useStrapiReportData';
import { useAllMembersQuery } from '../../../hooks';
import MembersListLoadingError from './MembersListLoadingError';
type props = {
  teamId?: string;
};
const InfiniteMembersList = ({ teamId }: props) => {
  const {
    fetchNextPage,
    isInitialLoading,
    isLoading,
    isFetchingNextPage,
    data,
    hasNextPage,
    error,
    refetch,
    isRefetching,
  } = useAllMembersQuery({ teamId });
  const {
    genericMessages: { memberLoadingMessage, noMemberFound },
  } = useStrapiReportsData();
  const cardObserver: any = useRef();

  let members: UserDetails[] = [];

  // If We have data
  if (data && data.pages) {
    members = data.pages
      .flatMap((x) => x.results)
      .map((member) => {
        const details = {
          id: member.username,
          name: member.name,
          profileImage: member.profile,
          enrolledCourses: member.courseAssigned,
          progress: member.overallProgress || 0,
          designation: member.jobTitle,
        };
        return {
          details,
        };
      });
  }

  const cardObserverCallBack = useCallback(
    (card: any) => {
      if (isLoading) return;
      if (cardObserver.current) cardObserver.current.disconnect();
      cardObserver.current = new IntersectionObserver((cardElement) => {
        if (cardElement[0].isIntersecting) {
          fetchNextPage();
        }
      });
      if (card) cardObserver.current.observe(card);
    },
    [isFetchingNextPage, hasNextPage, fetchNextPage]
  );

  return (
    <div className="flex h-full w-full flex-col">
      <>
        <div className="w-full px-4 pt-4 xs:px-0 sm:px-0">
          {!isInitialLoading && members.length > 0 && (
            <div className="grid grid-cols-[repeat(auto-fill,minmax(10.9rem,1fr))] gap-6">
              {members &&
                members.length > 0 &&
                members.map((member, index) => (
                  <div key={index} ref={cardObserverCallBack}>
                    <UserCard
                      user={member}
                      classNames="min-w-[172px] bg-zinc-700"
                      isMemberList={true}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>

        {(isLoading || isFetchingNextPage) && (
          <div className="flex h-full w-full items-center justify-center gap-2 py-4 text-sm text-white">
            <span>{memberLoadingMessage}</span>
            <BasicSpinner className="aspect-square h-5 w-5 text-inherit" />
          </div>
        )}
        {!isInitialLoading && members.length === 0 && !error && (
          <div className="flex h-full w-full items-center justify-center text-zinc-50">
            <span>{noMemberFound}</span>
          </div>
        )}
        {!isInitialLoading && members.length === 0 && error && (
          <MembersListLoadingError refetch={refetch} isRefetching={isRefetching} />
        )}
      </>
    </div>
  );
};

export default InfiniteMembersList;
