interface Props {
  orgName: string;
}

const OrgName = ({ orgName }: Props) => {
  return (
    <div className="flex space-x-1.5">
      <span className="whitespace-nowrap">{orgName}</span>
    </div>
  );
};

export default OrgName;
