import cn from '../../../../utils/cn';
import useStrapiLearningJourneyData from '../../hooks/use-strapi-learning-journey-data';

type Props = {
  className?: string;
};

const LearningJourneysHeading = (props: Props) => {
  const { className } = props;

  const { ListAllLearningJourneys: cms } = useStrapiLearningJourneyData();

  return (
    <header
      className={cn(
        'sticky top-0 z-[2] flex flex-col items-start border-b border-b-zinc-800 bg-card-bg px-3 py-6 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9',
        className
      )}
    >
      <h1 className="header-title">{cms.pageHeading}</h1>
      <p className="text-xs font-normal leading-4 text-zinc-400 xs:text-sm xs:leading-5">
        {cms.pageDescription}
      </p>
    </header>
  );
};

export default LearningJourneysHeading;
