import { USHG_HQ_APP_ROLE } from '../../../constants';
import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';
import AdminTeamView from './adminPages/adminTeamView';
import LeaderTeamPage from './nonAdminPages/LeaderTeamPage';
import useSessionTracker from '../../../hooks/use-session-tracker-hook';

function TeamPage() {
  const authStateSelector = (state: RootState) => state.auth;
  const { user } = useAppSelector(authStateSelector);
  useSessionTracker();
  const role = user?.role;
  if (role === USHG_HQ_APP_ROLE.ENTERPRISE_LEADER || role === USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER) {
    return <LeaderTeamPage />;
  } else if (role === USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN) {
    return <AdminTeamView />;
  } else {
    return <></>;
  }
}
export default TeamPage;
