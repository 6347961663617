import clsx from 'clsx';
import { ArrowLeft } from 'phosphor-react';
import { Menu, Navbar } from 'react-daisyui';
import { useNavigate } from 'react-router-dom';
import { useStrapiTeamData } from '../../hooks/useStrapiTeamData';
import SingleTeamMenuItems from './MenuItems/SingleTeam';
import TeamsMenuItems from './MenuItems/Teams';
import '../../../../styles/header.css';
interface Props {
  title?: string;
  jobTitle?: string;
  isSingleTeamView?: boolean;
  isAdminView?: boolean;
  invisibleClass?: string;
  isSinglePersonView?: boolean;
  isLeaderTeamView?: boolean;
  isSubTeamPresentAlready?: boolean;
  isSubTeam?: boolean;
  showBackButton?: boolean;
  teamId: number;
}

function TopNav({
  title,
  jobTitle,
  isSingleTeamView,
  invisibleClass,
  isAdminView,
  isSinglePersonView,
  isLeaderTeamView,
  isSubTeamPresentAlready,
  isSubTeam,
  showBackButton = true,
  teamId,
}: React.PropsWithChildren<Props>) {
  const navigate = useNavigate();
  const { topNav } = useStrapiTeamData();
  return (
    <Navbar className="flex w-full flex-col items-start gap-4 border-b border-b-zinc-800 bg-card-bg px-3 py-6 sm:flex-row sm:items-center sm:justify-between sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
      <div className="flex-1">
        <div className="flex-col">
          {(isSingleTeamView || isSinglePersonView) && showBackButton ? (
            <span
              className="cursor-pointer text-xs text-[#A1A1AA]"
              onClick={() => navigate(`/teams`)}
            >
              <ArrowLeft size={12} className="inline-block" /> {topNav.topNavBackButton}
            </span>
          ) : undefined}
          <div className={clsx('flex space-x-4', isSinglePersonView && 'my-2')}>
            {isSinglePersonView && (
              <img
                className="h-10 w-10 rounded-full"
                src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                alt="Default avatar"
              />
            )}
            <div className="flex flex-col">
              <div className="header-title">{title}</div>
              {isSinglePersonView && (
                <p className=" text-xs normal-case text-zinc-200">{jobTitle}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(invisibleClass)}>
        <Menu horizontal className="p-0">
          {isSingleTeamView ? (
            <SingleTeamMenuItems
              teamName={title}
              isAdminView={isAdminView}
              isSubTeamPresentAlready={isSubTeamPresentAlready}
              isSubTeam={isSubTeam}
              teamId={teamId}
            />
          ) : (
            <TeamsMenuItems isLeaderTeamView={isLeaderTeamView} />
          )}
        </Menu>
      </div>
    </Navbar>
  );
}

export default TopNav;
