import { DynamicFieldData } from '../../../types/dynamic-control-types';
export const BasicDetails: DynamicFieldData[] = [
  {
    label: 'First Name',
    slug: 'firstName',
    type: 'text',
    config: {
      required: {
        value: true,
        message: 'First Name is required',
      },
      minLength: {
        value: 2,
        message: 'First Name should be minimum of 2 characters',
      },
      maxLength: {
        value: 50,
        message: 'First Name should be maximum of 50 characters',
      },
      pattern: {
        value: /[a-zA-Z][a-zA-Z ]*/,
        message: 'First Name should contain only alphabets and spaces',
      },
    },
  },
  {
    label: 'Last Name',
    slug: 'lastName',
    type: 'text',
    config: {
      required: {
        value: true,
        message: 'Last Name is required',
      },
      minLength: {
        value: 1,
        message: 'Last Name should be minimum of 1 character',
      },
      maxLength: {
        value: 50,
        message: 'Last Name should be maximum of 50 characters',
      },
      pattern: {
        value: /[a-zA-Z][a-zA-Z ]*/,
        message: 'Last Name should contain only alphabets and spaces',
      },
    },
  },
  {
    label: 'Email',
    slug: 'corporateEmail',
    type: 'email',
    role: 'Team',
    config: {
      required: {
        value: true,
        message: 'Email is required',
      },
      pattern: {
        value: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: 'Enter a valid Email',
      },
    },
    primaryInfo: 'Use your corporate email',
    secondaryInfo: '',
  },
  {
    label: 'Email',
    slug: 'personalEmail',
    type: 'email',
    role: 'Individual',
    config: {
      required: {
        value: true,
        message: 'Email is required',
      },
      pattern: {
        value: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: 'Enter a valid Email',
      },
    },
    primaryInfo: 'Use your personal email',
    secondaryInfo: '',
  },
  {
    label: 'Corporate Email (Optional)',
    slug: 'corporateEmail',
    type: 'email',
    role: 'Individual',
    config: {
      pattern: {
        value: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: 'Enter a valid Email',
      },
    },
    primaryInfo: '',
    secondaryInfo: '',
  },
  {
    label: 'Mobile Number (Optional)',
    slug: 'mobile',
    type: 'tel',
  },
  {
    label: 'Job Title',
    slug: 'jobTitle',
    type: 'select',
    config: {
      required: {
        value: true,
        message: 'Job Title is required',
      },
    },
    options: [
      { value: '', label: 'Select One' },
      { value: 'cto', label: 'CTO' },
      { value: 'manager', label: 'Manager' },
      { value: 'leader', label: 'Leader' },
    ],
    role: 'Team',
  },
  {
    label: 'Department',
    slug: 'department',
    type: 'select',
    config: {
      required: {
        value: true,
        message: 'Department is required',
      },
    },
    options: [
      { value: '', label: 'Select One' },
      { value: 'sales', label: 'Sales' },
      { value: 'finance', label: 'Finance' },
      { value: 'admin', label: 'Admin' },
    ],
    role: 'Team',
  },
  {
    label: 'Enter Password',
    slug: 'password',
    type: 'password',
    config: {
      required: {
        value: true,
        message: 'Password is required',
      },
      pattern: {
        value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        message: "Password doesn't meet password policy",
      },
      deps: ['register_confirm_password'],
    },
    primaryInfo: 'Atleast 8 characters and one number',
    secondaryInfo: '',
  },
  {
    label: 'Confirm Password',
    slug: 'confirmPassword',
    type: 'password',
    config: {
      pattern: {
        value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        message: "Password doesn't meet password policy",
      },
    },
  },
];
