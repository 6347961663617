import { useEffect } from 'react';
import { useAppSelector } from '../../../../../hooks';
import { RootState } from '../../../../../store';
import { format } from 'date-fns';
import usePreviewSubscriptionMutation from '../../../../auth/hooks/usePreviewSubscriptionMutation';
import { SubscriptionCardWrapper } from './subscriptionInfoCardWrapper';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';
import { TRAIL_LIMIT } from '../../../../../constants/subscription-plans';
import { getLocale } from '../../../../../utils';
import { useLanguage } from '../../../../shared/context/languageContext';

interface props {
  existingMemberCount: number;
  newMemberCount: number;
  isOnBoarding?: boolean;
}

const subscriptionInfoData = (state: RootState) => state.subscription;
const userData = (state: RootState) => state.auth;

// There are two scenarios where we display this
// 1. In Trial + trying to add greater than 10 members
// 2. Exceed the subscription limit in an active subscription
const SubscriptionInfo = ({ existingMemberCount, newMemberCount, isOnBoarding }: props) => {
  const { subscriptionData } = useAppSelector(subscriptionInfoData);
  const { subscriptionInfo: strapiSubscriptionInfo } = useStrapiSharedData();
  const { user } = useAppSelector(userData);
  const inFreeTrial = subscriptionData?.status === 'trialing';
  const totalLicenses = subscriptionData?.totalLicenses as number;
  const previewPlan = usePreviewSubscriptionMutation();
  const exceedsSubscriptionLimit = newMemberCount > totalLicenses;
  const username = user ? user?.name + user?.family_name : 'admin';
  useEffect(() => {
    if (subscriptionData) {
      previewPlan.mutate({
        email: subscriptionData.customerEmail,
        pricingType: subscriptionData.planCycle,
        name: username,
        subscriptionType: subscriptionData.subscriptionType.toLowerCase(),
        licenses: newMemberCount,
        freeTrail: inFreeTrial,
      });
    }
  }, [newMemberCount, subscriptionData, username]);

  let finalDueDate;
  let memberText = strapiSubscriptionInfo.subscriptionInfoOnMembers;
  let costText = strapiSubscriptionInfo.subscriptionInfoOnCost;
  const { language } = useLanguage();
  if (subscriptionData) {
    if (!isOnBoarding) {
      const dueDate = new Date((subscriptionData?.currentPeriodEnd as number) * 1000);
      finalDueDate = format(dueDate, 'MMMM  dd, yyyy', { locale: getLocale(language) });
    }
    memberText = memberText.replace(`{{existingMemberCount}}`, existingMemberCount);
    memberText = memberText.replace(`{{newMemberCount}}`, newMemberCount);

    costText = costText.replace(
      `{{amount}}`,
      (previewPlan.data?.actualAmount as number)
        ? (previewPlan.data?.actualAmount as number)
        : '...'
    );
    costText = costText.replace(`{{dueDate}}`, finalDueDate);
  }
  return (
    <div className="rounded border-[1px] border-gray-700 bg-zinc-900 text-white">
      {/* Active + Licenses Exceeded or Free Trial + > Licenses Subscribed For  */}
      {!inFreeTrial || (inFreeTrial && exceedsSubscriptionLimit && totalLicenses < TRAIL_LIMIT) ? (
        <div className="w-full p-4">
          <div className="pb-3 text-lg">{strapiSubscriptionInfo.title}</div>
          <div
            className="pb-5 text-sm text-zinc-300"
            dangerouslySetInnerHTML={{ __html: memberText }}
          ></div>
          {!isOnBoarding && (
            <>
              <div
                className="pb-5 text-sm text-zinc-300"
                dangerouslySetInnerHTML={{ __html: costText }}
              ></div>
              <SubscriptionCardWrapper
                newAmount={previewPlan.data?.actualAmount as number}
                existingmemberCount={existingMemberCount}
                newMemberCount={newMemberCount}
                isLoading={previewPlan.isLoading}
              />
            </>
          )}
          {isOnBoarding && (
            <div className="pb-5 text-sm text-zinc-300">
              {strapiSubscriptionInfo.subscriptionInfoForOnBoarding}
            </div>
          )}
        </div>
      ) : (
        // Trial Limit Exceeded
        <div className="space-y-2 p-5">
          <div>{strapiSubscriptionInfo.trialLimitOverTitle}</div>
          <div className=" text-sm text-zinc-300">
            {strapiSubscriptionInfo.subscriptionInfoForTrialPeriod}
          </div>
        </div>
      )}
    </div>
  );
};
export default SubscriptionInfo;
