import clsx from 'clsx';
import { ErrorBoundary } from 'react-error-boundary';
import { useLilius } from 'use-lilius';
import { ErrorDisplay } from '../../../../components/DataTables';
import { useStrapiDashboardData } from '../../../shared/hooks';
import { useMyCalendarItemsQuery } from '../../hooks';
import CalendarBody from './CalendarBody';
import CalendarBodyErrorBoundaryFallback from './CalendarBodyErrorBoundaryFallback';
import CalendarHeader from './CalendarHeader';
import UpcomingEvents from './UpcomingEvents';
import UpcomingEventsErrorBoundaryFallback from './UpcomingEventsErrorBoundaryFallback';

interface Props {
  className?: string;
}

const Calendar = ({ className }: Props) => {
  const { calendar, viewNextMonth, viewPreviousMonth, viewing } = useLilius();
  const {
    calendar: { errorLoadingCalendar },
  } = useStrapiDashboardData();
  const weeks = calendar[0];
  const fromDate = weeks[0][0];
  const toDate = weeks[weeks.length - 1][6];

  const calendarItemsQuery = useMyCalendarItemsQuery({
    rangeStartDate: fromDate,
    rangeEndDate: toDate,
  });

  // During the initial load and
  // When we have previous data and we are fetching the data for the new month
  const isLoading =
    calendarItemsQuery.isLoading ||
    (calendarItemsQuery.isPreviousData && calendarItemsQuery.isFetching);

  const calendarItems = calendarItemsQuery.data?.calendarItems;

  return (
    <div
      className={clsx(
        'relative flex h-full min-h-0 w-full flex-col justify-between gap-4 rounded-lg bg-card-bg p-2 py-4 xl:p-4',
        className
      )}
    >
      {/* ErrorBoundary for the Calendar Body */}
      <ErrorBoundary FallbackComponent={CalendarBodyErrorBoundaryFallback}>
        <div className="relative flex min-h-[256px] grow flex-col items-stretch gap-2">
          {/* There is no error and (we have data or is loading) */}
          {!calendarItemsQuery.isError && (calendarItems || isLoading) && (
            <>
              <CalendarHeader
                viewing={viewing}
                viewNextMonth={viewNextMonth}
                viewPreviousMonth={viewPreviousMonth}
                isLoading={isLoading}
              />
              <CalendarBody
                viewing={viewing}
                className="grow"
                weeks={weeks}
                calendarItems={calendarItems}
              />
            </>
          )}
          {/* there is an error */}
          {calendarItemsQuery.isError && (
            <div className="flex h-full w-full items-center justify-center">
              <ErrorDisplay
                message={errorLoadingCalendar}
                refetch={calendarItemsQuery.refetch}
                allowsRefetch={true}
                isRefetching={calendarItemsQuery.isRefetching}
              />
            </div>
          )}
        </div>
      </ErrorBoundary>
      {/* ErrorBoundary for the Upcoming Events */}
      <ErrorBoundary FallbackComponent={UpcomingEventsErrorBoundaryFallback}>
        <div className="min-h-[128px]">
          <UpcomingEvents />
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default Calendar;
