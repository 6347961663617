import React from 'react';
import CourseBasicDetails from './CourseBasicDetails';
import ModuleAssessment from './ModuleAssessment/ModuleAssessment';
interface CourseMainProps {
  courseBasicDetailsQuery: Record<string, any>;
  courseModulesQuery: Record<string, any>;
  courseId: number;
}
const CoursesMain = ({
  courseBasicDetailsQuery,
  courseModulesQuery,
  courseId,
}: CourseMainProps) => {
  return (
    <div className="flex w-full flex-col gap-4">
      {/* Course Basic Details */}
      <CourseBasicDetails courseBasicDetailsQuery={courseBasicDetailsQuery} />
      {/* Course Modules and Assessments */}
      <ModuleAssessment courseModulesQuery={courseModulesQuery} id={courseId} />
    </div>
  );
};

export default CoursesMain;
