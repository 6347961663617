import { ArrowLeft } from 'phosphor-react';
import { useState } from 'react';
import { Navbar } from 'react-daisyui';
import { useNavigate } from 'react-router-dom';
import Dialog from '../../../components/Dialog';
import { useStrapiOrganizationData } from '../hooks/useStrapiOrganizationData';
import AddOrganisationModalMain from './Modal/AddOrganisationModalMain';
import '../../../styles/header.css';
interface props {
  title: string;
  department?: string;
  isMain?: boolean;
}

function TopNav({ title, department, isMain }: props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { topNav, singleView } = useStrapiOrganizationData();
  return (
    <Navbar className="flex w-full items-center justify-between border-b border-b-zinc-800 bg-card-bg px-3 py-6 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
      <div className="flex flex-col">
        <div className="font-[inter] text-lg font-semibold normal-case text-white md:text-[22px] lg:text-[22px]">
          {!isMain && (
            <div className="flex justify-between">
              <span
                className="flex cursor-pointer items-center gap-0.5 text-xs text-zinc-400"
                onClick={() => navigate(-1)}
              >
                <ArrowLeft size={12} color="currentColor" className="text-inherit" />
                <span className="text-xs font-normal leading-[150%]">
                  {singleView.TopNav.backButton}
                </span>
              </span>
            </div>
          )}
          <div className="header-title">{title}</div>
          {department && (
            <div className="text-xs text-white">
              <span className="text-base-brand">{singleView.TopNav.departmentLabel}: </span>
              {department}
            </div>
          )}
        </div>
      </div>
      {isMain && (
        <div className="relative flex max-w-[188px] flex-col items-center justify-center xs:m-0 xs:flex xs:max-w-none xs:gap-0 xs:gap-y-4 sm:gap-y-4 md:flex md:flex-row md:space-x-2">
          <Dialog
            triggerClassName="w-full inline-block gap-1 whitespace-nowrap !rounded bg-base-brand px-4 py-1 text-xs font-normal leading-tight text-white hover:bg-base-brand md:h-[30px]"
            open={isModalOpen}
            onOpenChange={(open) => setIsModalOpen(open)}
            Content={<AddOrganisationModalMain setIsModalOpen={setIsModalOpen} />}
            onInteractOutside={(e) => {
              e.preventDefault();
            }}
          >
            {topNav.inviteOrganizationButton}
          </Dialog>
        </div>
      )}
    </Navbar>
  );
}

export default TopNav;
