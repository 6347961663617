import React from 'react';
import {
  SinglePersonCourseHistoryType,
  SinglePersonProfileType,
} from '../../types/SinglePersonProfileType';

type StatisticsCard = {
  profile: SinglePersonProfileType | SinglePersonCourseHistoryType;
};

const StatisticsCard = ({ profile }: React.PropsWithChildren<StatisticsCard>) => {
  return (
    <>
      {Object.entries(profile).map(([key, value]) => (
        <div
          key={key}
          className="flex w-fit rounded-md border-card-border bg-zinc-800 py-2 pl-4 pr-6 shadow-lg"
        >
          <div>
            <h2 className="text-base font-bold leading-8 text-white">{value}</h2>
            <p className="text-xs text-zinc-400">{key}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default StatisticsCard;
