import { flexRender, Row } from '@tanstack/react-table';
import clsx from 'clsx';

interface Props {
  row: Row<any>;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLTableRowElement>;
  tdClassName?: string;
  isSubRow?: boolean;
}

const TableDataRow = (props: Props) => {
  const { row, className, onClick, tdClassName, isSubRow } = props;
  return (
    <tr className={clsx(className, isSubRow ? ' bg-zinc-900' : '')} onClick={onClick}>
      {row.getVisibleCells().map((cell) => {
        return (
          <td
            key={cell.id}
            className={clsx(
              'px-2 py-3 text-[12px] font-normal leading-[16px] text-zinc-100',
              tdClassName
            )}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  );
};

export default TableDataRow;
