import { SortDirection } from '@tanstack/react-table';
import { SortAscending, SortDescending } from 'phosphor-react';

interface Props {
  sortDirection: SortDirection | false;
  fallback?: React.ReactNode;
}

const DynamicSortIcon = ({ sortDirection }: Props) => {
  switch (sortDirection) {
    case false: {
      return <div />;
    }
    case 'asc': {
      return <SortAscending size={16} color="currentColor" className="text-zinc-100" />;
    }
    case 'desc': {
      return <SortDescending size={16} color="currentColor" className="text-zinc-100" />;
    }
  }
};

export default DynamicSortIcon;
