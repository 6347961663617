import { gql } from '@apollo/client';

const OnboardingFragment = gql`
  fragment OnboardingFragment on OnBoardingEntityResponse {
    data {
      attributes {
        ENTERPRISE_MANAGER {
          ... on ComponentOnBoardingGoals {
            teamGoalToggleButton
            personalGoalToggleButton
            teamGoals {
              title
              description
            }
            personalGoals {
              title
              description
            }
          }
          ... on ComponentOnBoardingCategories {
            teamCategory {
              title
              description
            }
            teamCategoryToggleButton
            courseCountLabel
            personalCategoryToggleButton
            allCategoriesTitle
            recommendedCategoriesTitle
            personalCategory {
              title
              description
            }
          }
        }
        COMMON {
          ... on ComponentOnBoardingSetPassword {
            title
            setPasswordButton
            newPasswordLabel
            description
            confirmPasswordLabel
            continueButton
            setpasswordSuccessTitle
            setpasswordSuccessMessage
          }
          ... on ComponentOnBoardingWelcomePage {
            description
            title
            welcomeImage {
              data {
                attributes {
                  url
                }
              }
            }
            getStartedButton
          }
          ... on ComponentOnBoardingOnBoardingTabs {
            welcomeTab
            goalsTab
            categoriesTab
            profileTab
            inviteMemberTab
            successTab
          }
          ... on ComponentOnBoardingOnBoardingButtons {
            nextButton
            backButton
            goToDashboardButton
            skipButton
          }
          ... on ComponentOnBoardingProfile {
            title
            description
            form {
              firstNameLabel
              lastNameLabel
              emailLabel
              emailPlaceholder
              phoneNumberLabel
              phoneNumberPlaceHolder
              firstNameRequired
              firstNameMinValue
              firstNameMinError
              firstNameMaxValue
              firstNameMaxError
              firstNameTypeError
              lastNameRequired
              lastNameMinValue
              lastNameMinError
              lastNameMaxValue
              lastNameMaxError
              lastNameTypeError
              emailRequired
              validEmail
              phoneNumberRequired
              isPhoneNumberRequired
              validPhoneNumber
              jobTitleRequired
              departmentRequired
              languageLabel
            }
            avatarUploadInstruction
            uploadAvatarButton
            removeAvatarButton
          }
          ... on ComponentOnBoardingSuccessPage {
            successEmoji {
              data {
                attributes {
                  url
                }
              }
            }
            pageLoading
            description
            title
          }
          ... on ComponentOnBoardingToastMessages {
            goalsMinError
            categoriesMinError
            avatarUploadFailure
            addMemberFailureTitle
            missingTeamDetailsTitle
            inviteTeamFailureMessage
            goalSelectionFailureTitle
            profileUpdateFailureTitle
            profileUpdateFailureMessage
            goalSelectionFailureMessage
            categorySelectionFailureTitle
            categorySelectionFailureMessage
            addMemberSuccessTitle
            addMemberSuccessMessage
            leaderAlreadyExist
            memberAlreadyExist
            profileUpdatedSuccessfulTitle
            profileUpdatedSuccessfulMessage
            teamAddedSuccessfullyTitle
            teamAddedSuccessfullyMessage
            teamAddedFailureTitle
            teamAddedFailureMessage
          }
          ... on ComponentOnBoardingEmptyMessages {
            noGoalsFound
            noCategoriesFound
          }
          ... on ComponentSharedTooltip {
            tooltipContent
            tooltipSubContent
            tooltipSRLabel
          }
        }
        ENTERPRISE_LEADER_ADMIN {
          ... on ComponentOnBoardingGoals {
            teamGoalToggleButton
            personalGoalToggleButton
            teamGoals {
              title
              description
            }
            personalGoals {
              title
              description
            }
          }
          ... on ComponentOnBoardingInviteMember {
            teamInviteTitle
            leaderInviteTitle
            addTeamButton
            addTeamMemberButton
            addLeaderButton
            removeButton
            orLabel
            addTeamTitle
            addTeamPlaceholder
            bulkUpload {
              title
              uploadFileLabel
              csvInstruction
              dragAndDropLabel
              downloadSampleButton
            }
          }
          ... on ComponentOnBoardingCategories {
            teamCategory {
              title
              description
            }
            teamCategoryToggleButton
            courseCountLabel
            personalCategoryToggleButton
            allCategoriesTitle
            recommendedCategoriesTitle
            personalCategory {
              title
              description
            }
          }
          ... on ComponentSharedTooltip {
            tooltipContent
            tooltipSubContent
            tooltipSRLabel
          }
        }
      }
    }
  }
`;
export { OnboardingFragment };
