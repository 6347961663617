import { twMerge } from 'tailwind-merge';

type ComponentHeadingProps = {
  heading: string;
  className?: string;
};

const ComponentHeading = ({ heading, className }: ComponentHeadingProps) => {
  return (
    <h2 className={twMerge('text-sm font-bold leading-[21px] text-zinc-50', className)}>
      {heading}
    </h2>
  );
};

export default ComponentHeading;
