import { ArrowRight } from 'phosphor-react';
import { useAppSelector } from '../../../../../hooks';
import { RootState } from '../../../../../store';
import { SubscriptionCard } from './subscriptionCard';

interface props {
  existingmemberCount: number;
  newMemberCount: number;
  newAmount: number;
  isLoading: boolean;
}
const subscriptionInfoData = (state: RootState) => state.subscription;
export const SubscriptionCardWrapper = ({
  existingmemberCount,
  newMemberCount,
  newAmount,
  isLoading,
}: props) => {
  const { subscriptionData } = useAppSelector(subscriptionInfoData);
  return (
    <div className="flex w-full flex-col items-center justify-between p-4 md:flex-row">
      <SubscriptionCard
        memberCount={existingmemberCount}
        cost={subscriptionData?.upcomingAmountDue as number}
      />
      <div className="rotate-90 p-3 md:rotate-0">
        <ArrowRight size={32} weight="thin" color="#52525B" />
      </div>
      <SubscriptionCard memberCount={newMemberCount} cost={newAmount} isLoading={isLoading} />
    </div>
  );
};
