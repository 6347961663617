import { Row } from '@tanstack/react-table';
import clsx from 'clsx';
import { TableDataRow } from '../../../../../../../../components/DataTables';
import { USHGAdminModuleTableResults } from '../../../../../../constants/course-module-table';

interface Props {
  row: Row<USHGAdminModuleTableResults>;
  className?: string;
}

const ModulesTableDataRow = (props: Props) => {
  const { row, className } = props;

  // Dialog that shows more details and options to enable and reject

  return (
    <>
      <TableDataRow
        row={row}
        className={clsx('w-full cursor-pointer hover:bg-page-bg-dark/20', className)}
      />
    </>
  );
};

export default ModulesTableDataRow;
