import clsx from 'clsx';
import { FileCsv } from 'phosphor-react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useStrapiSharedData } from '../../hooks/useStrapiSharedData';

interface Props {
  dropzoneOptions: DropzoneOptions;
}

/**
 * Dropzone to upload files.
 */
const FileUploadDropzone = (props: Props) => {
  const { dropzoneOptions } = props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...dropzoneOptions,
  });
  const {
    bulkUpload: {
      uploadLabel,
      orLabel,
      dragAndDropLabel,
      dropHereLabel,
      fileSizeValidationMessage,
      downloadSampleButton,
    },
  } = useStrapiSharedData();

  return (
    <div
      {...getRootProps()}
      className={clsx(
        'flex w-full grow cursor-pointer flex-col items-center gap-2 p-[22px_26px_26px]',
        isDragActive && 'bg-base-brand/5'
      )}
    >
      <FileCsv size={36} color="currentColor" className="text-zinc-700" />
      <div className="flex flex-col gap-1">
        <div className="flex flex-wrap justify-center gap-1 text-sm font-medium">
          {!isDragActive && (
            <>
              <span className="text-base-brand">{uploadLabel}</span>
              <span className="text-zinc-300">
                {orLabel} {dragAndDropLabel}
              </span>
            </>
          )}
          {isDragActive && <span className="text-base-brand">{dropHereLabel}</span>}
        </div>
        <div
          className="flex flex-wrap justify-center gap-1 text-xs font-normal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <span className="text-zinc-300">{fileSizeValidationMessage}</span>
          <a
            className="text-base-brand no-underline"
            href="/assets/sample_enterprise_bulk_invite.csv"
            download="hq_sample_enterprise_bulk_invite.csv"
          >
            {downloadSampleButton}
          </a>
        </div>
      </div>
      <input {...getInputProps()}></input>
    </div>
  );
};

export default FileUploadDropzone;
