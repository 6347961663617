import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showToast } from '../../shared/helpers/toast';
import { deleteAnnouncement } from '../api';
import { useStrapiSettingsData } from './useStrapiSettingsData';

const useDeleteAnnouncementMutation = () => {
  // query client
  const queryClient = useQueryClient();
  // query client

  // strapi content
  const { hqAdminPlatformAnnouncement: announcementCMS } = useStrapiSettingsData();
  // strapi content

  const mutation = useMutation({
    mutationFn: async () => {
      const response = await deleteAnnouncement();
      return response;
    },
    onSuccess: async (_response, _variables) => {
      // TODO: Revisit --> Make it better
      queryClient.resetQueries([
        {
          scope: 'shared',
          item: 'announcement',
        },
      ]);

      showToast({
        variant: 'success',
        title: announcementCMS.announcementDeleteSuccessToast,
      });
    },
    onError: async (_err, _variables) => {
      showToast({
        variant: 'error',
        title: announcementCMS.announcementDeleteErrorToast,
      });
    },
  });

  return mutation;
};

export default useDeleteAnnouncementMutation;
