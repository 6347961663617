import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { PaperPlaneTilt } from 'phosphor-react';
import { useStrapiSideNavData } from '../../../hooks/useStrapiSideNavData';

type SideBarProfileContactHQProps = {
  onSelect: ((event: Event) => void) | undefined;
};

function SideBarProfileContactHQ({ onSelect }: SideBarProfileContactHQProps) {
  const { buttons } = useStrapiSideNavData();

  return (
    <DropdownMenu.Item
      className="cursor-pointer rounded-md py-[9px] px-[10px] hover:focus:bg-zinc-700"
      onSelect={onSelect}
    >
      <div className="flex h-full w-full flex-row items-center justify-between ">
        <div className="flex items-center gap-2 text-zinc-50">
          <PaperPlaneTilt size={16} className="text-inherit" color="currentColor" />
          <span className="text-sm font-medium text-inherit">{buttons.raiseRequestToHQLabel}</span>
        </div>
      </div>
    </DropdownMenu.Item>
  );
}

export default SideBarProfileContactHQ;
