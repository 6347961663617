import { useQuery } from '@tanstack/react-query';
import { USHG_HQ_APP_ROLE } from '../../../constants';
import { getEnterpriseAdminRequests } from '../api';
import { REQUESTS_STALE_TIME } from '../constants';
import { PartialEnterpriseAdminRequestsFilter } from '../types';

interface Props {
  filters: PartialEnterpriseAdminRequestsFilter;
  isEnabled?: boolean;
}

const useEnterpriseAdminRequestsQuery = ({ filters, isEnabled = true }: Props) => {
  const request = useQuery(
    [
      {
        persona: USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
        scope: 'requests',
        item: 'requests',
        filters: filters,
      },
    ],
    async () => {
      return getEnterpriseAdminRequests(filters);
    },
    {
      keepPreviousData: true,
      staleTime: REQUESTS_STALE_TIME,
      enabled: isEnabled,
    }
  );

  return request;
};

export default useEnterpriseAdminRequestsQuery;
