const BrandLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="34"
      viewBox="0 0 32 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Brand Logo</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.11587 14.2457C4.95736 21.9148 1.56363 28.1978 1.57424 28.208C1.60403 28.2365 11.684 33.6981 11.7068 33.6981C11.7417 33.6981 14.0652 32.1067 14.6503 31.6821C16.4401 30.3832 17.9263 28.978 19.213 27.3683C20.0195 26.3592 20.896 25.1154 21.6197 23.9529C21.8098 23.6474 30.4112 7.83021 30.4258 7.75911C30.4279 7.74892 29.1585 7.05098 27.605 6.20814C19.2346 1.66695 16.7133 0.30188 16.696 0.30188C16.6854 0.30188 13.2744 6.57658 9.11587 14.2457Z"
        fill="#D16451"
      />
    </svg>
  );
};

export default BrandLogo;
