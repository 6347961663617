import { useContext, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { RootState } from '../../../../../store';
import { setSubscriptionData } from '../../../slices/subscription.slice';
import { differenceInDays } from 'date-fns';
import clock from '../../../../../assets/SubscriptionDue.png';
import { Warning } from 'phosphor-react';
import sideBarContext from '../../../../../components/SideBar/context/sideBarContext';
import { useReactivatePlanMutation } from '../../../../settings/hooks/useReactivatePlanMutation';
import { BasicSpinner } from '../../../../../components/Spinners';
import useFetchSubscription from '../../../hooks/useFetchSubscription';
import { twMerge } from 'tailwind-merge';
import { useStrapiSharedData } from '../../../hooks/useStrapiSharedData';
const subscriptionState = (state: RootState) => state.subscription.subscriptionData;

function SubscriptionReminder() {
  const { setIsSidebarCollapsed, isSidebarCollapsed } = useContext(sideBarContext);
  const subscription = useAppSelector(subscriptionState);
  const isSubscriptionCanceled = subscription?.cancelAtPeriodEnd;
  const dispatch = useAppDispatch();
  const subscriptionDetails = useFetchSubscription({ enabled: true });
  useEffect(() => {
    dispatch(setSubscriptionData(subscriptionDetails.data));
  }, [dispatch, subscriptionDetails]);

  return (
    <>
      {!isSidebarCollapsed && isSubscriptionCanceled && subscription.status !== 'canceled' ? (
        <SubscriptionReminderExpandedUI className="self-center" />
      ) : isSidebarCollapsed && isSubscriptionCanceled && subscription.status !== 'canceled' ? (
        <Warning
          size={25}
          className="cursor-pointer text-amber-500"
          onClick={() => {
            setIsSidebarCollapsed(!isSidebarCollapsed);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default SubscriptionReminder;

interface SubscriptionReminderExpandedUIProps {
  className?: string;
}

const SubscriptionReminderExpandedUI = ({ className }: SubscriptionReminderExpandedUIProps) => {
  const subscription = useAppSelector(subscriptionState);
  const { daysLeftLabel, restartInfoText, reminderInfoText, clickHereLabel } =
    useStrapiSharedData().subscriptionReminder;
  const noDaysLeft = differenceInDays(
    (subscription?.currentPeriodEnd as number) * 1000,
    new Date().getTime()
  );
  const resumeSubscription = useReactivatePlanMutation();

  if (noDaysLeft > 30) {
    return null;
  }
  const finalReminderInfoText = reminderInfoText.replace(`{{noDaysLeft}}`, noDaysLeft);
  return (
    <div
      className={twMerge(
        'relative max-w-[240px] rounded border-2 border-[#474642] p-5 text-center',
        className
      )}
    >
      <img className="absolute left-[50%] mt-[-40px] translate-x-[-50%]" src={clock}></img>
      <h3 className="mb-1 text-sm font-bold text-white">
        {noDaysLeft} {daysLeftLabel}
      </h3>
      {resumeSubscription.isLoading ? (
        <span className="mt-3 flex justify-center">
          {' '}
          <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
        </span>
      ) : (
        <div className="text-xs text-[#9e9e9f]">
          <p dangerouslySetInnerHTML={{ __html: finalReminderInfoText }}></p>
          <a className="cursor-pointer underline" onClick={() => resumeSubscription.mutate()}>
            {clickHereLabel}
          </a>{' '}
          {restartInfoText}
        </div>
      )}
    </div>
  );
};
