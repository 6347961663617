import { gql } from '@apollo/client';

export const ResetPasswordFragment = gql`
  fragment ResetPasswordFragment on ResetpasswordEntityResponse {
    data {
      attributes {
        title
        description
        verificationInfoText1
        verificationInfoText2
        verificationCodeLabel
        newPasswordLabel
        confirmPasswordLabel
        updatePasswordButton
        passwordInfoText
        emailLabel
        emailPlaceholder
        resetPasswordFailed
        resetSuccessPageTitle
        resetSuccessPageDescription
        resetSuccessPageLoginLabel
      }
    }
  }
`;
