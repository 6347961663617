import { RouteObject } from 'react-router-dom';
import React from 'react';

// Lazy Load Messaging Component
const Messaging = React.lazy(() => import('../components/Messaging'));

const routes: RouteObject[] = [
  {
    path: '/messages',
    element: <Messaging />,
  },
];

export default routes;
