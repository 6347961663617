import { Option } from '../../../components/Select';

// Pagination
export const USHG_ADMIN_PAGE_SIZE_INCREMENT = 10;
export const USHG_ADMIN_MAX_PAGE_SIZE = 50;
export const USHG_ADMIN_MIN_PAGE_SIZE = 10;
export const USHG_ADMIN_DEFAULT_PAGE = 1;

// Sort
export const USHG_ADMIN_SORT_DEFAULT = '';
export const USHG_ADMIN_SORTKEY_MAP: any = {
  name: 'COURSE_NAME',
  moduleCount: 'MODULE_COUNT',
  createdOn: 'UPDATED_ON',
  speaker: 'SPEAKER',
};
export const USHG_ADMIN_STATUS_QUERY_PARAM = 'state';
export const USHG_ADMIN_COURSES_STATUS_VALUES: Option[] = [
  {
    display: 'All',
    value: 'all',
  },
  {
    display: 'Live',
    value: 'live',
  },
  {
    display: 'Draft',
    value: 'draft',
  },
];

export const STALE_TIME = 1000;
