import React from 'react';
import { useStrapiReportsData } from '../../../shared/hooks/useStrapiReportData';

import PageHeader from '../common/PageHeader';
import IndividualLearnerOverallReports from './IndividualLearnerOverallReports';

type props = {
  lazyLoad?: boolean;
};

const IndividualLearnerReports = ({ lazyLoad = true }: props) => {
  const { myPerformanceLabel } = useStrapiReportsData().topNav;
  return (
    <main id="main-content" className="flex h-full w-full flex-col">
      <PageHeader reportsHeader={myPerformanceLabel} className="pb-6" />
      <div className="flex h-full w-full justify-center px-3 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
        <IndividualLearnerOverallReports lazyLoad={lazyLoad} />
      </div>
    </main>
  );
};

export default IndividualLearnerReports;
