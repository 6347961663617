import clsx from 'clsx';
import { DownloadSimple } from 'phosphor-react';
import React, { memo } from 'react';
import csvDownload from 'json-to-csv-export';
import { Bills } from '../../types/billing';
import { useStrapiSettingsData } from '../../../settings/hooks/useStrapiSettingsData';
import { getDateInMMDDYYY } from '../../utils/getDateInMMDDYYY';
import { getBillingStatus } from '../../utils/getBillingStatus';

interface Props {
  className?: string;
  data?: Bills[];
}

const BillingHistoryHeader = ({ className, data }: Props) => {
  const exportToCsv = () => {
    if (!data) return;
    const headers = ['Billing Date', 'Amount', 'Payment Due', 'Users', 'Status'];
    const ExportData = data.map((elt) => [
      getDateInMMDDYYY((elt.billingDate as number) * 1000),
      elt.amountDue || '-',
      getDateInMMDDYYY((elt.dueDate as number) * 1000),
      elt.quantity,
      getBillingStatus(elt.isPaid, elt.isOverDue),
    ]);

    const dataToConvert = {
      data: ExportData,
      filename: 'hq_billing_history',
      delimiter: ',',
      headers,
    };
    csvDownload(dataToConvert);
  };
  const { billingHistory } = useStrapiSettingsData();
  return (
    <div
      className={clsx(
        'flex w-full flex-col justify-end text-[14px] font-normal text-zinc-100 sm:flex-row',
        className
      )}
    >
      <button
        onClick={exportToCsv}
        className="rounded-sm border border-zinc-900 bg-zinc-900 py-1 px-4 "
      >
        <DownloadSimple className="inline-block" size={16} weight="fill" />{' '}
        {billingHistory.exportButton}
      </button>
    </div>
  );
};

export default memo(BillingHistoryHeader);
