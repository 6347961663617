import { useMemo } from 'react';
import { useStrapiSharedData } from './useStrapiSharedData';

export const useBulkUploadErrorMap = () => {
  const {
    bulkUpload: { ErrorMessages },
  } = useStrapiSharedData();

  const errorMap = useMemo(() => {
    const list: Record<string, string> = {};
    ErrorMessages.forEach((errors: any) => {
      const { errorCode, errorMessage } = errors as {
        errorCode: string;
        errorMessage: string;
      };
      list[errorCode] = errorMessage;
    });
    return list;
  }, [ErrorMessages]);
  return { errorMap };
};
