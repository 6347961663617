export const AVATAR_SIZE = 200;

export const MAX_FILE_SIZE_BYTES = 10485760; // 10Mb - for thumbnails

export const MAX_FILE_SIZE = 10;

export const FILE_UNITS = {
  MB: 'MB',
} as const;

export const FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY = 'FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY';

export const FETCH_TEAM_NAME_QUERY_KEY = (teamId: number | undefined) => {
  return {
    scope: 'teams',
    items: 'teamName',
    teamId: teamId ?? 1,
  };
};
