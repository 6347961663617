import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PaginationFooter } from '../../../../../components/DataTables';
import { BasicSpinner } from '../../../../../components/Spinners';
import { useAppSelector } from '../../../../../hooks';
import useDebounce from '../../../../../hooks/use-debounce';
import { RootState } from '../../../../../store';

import {
  USHG_ADMIN_DEFAULT_PAGE,
  USHG_ADMIN_MAX_PAGE_SIZE,
  USHG_ADMIN_MIN_PAGE_SIZE,
  USHG_ADMIN_PAGE_SIZE_INCREMENT,
} from '../../../../courses/constants/USHGAdmin';

import BadgesMainHeader from './BadgesMainHeader';
import BadgesEmptyState from './BadgesEmptyState';
import BadgesErrorState from './BadgesErrorState';
import BadgesTable from './BadgesTable';
import useHQAdminBadgesQuery from '../../../hooks/useHQAdminBadgesQuery';

interface Props {
  className?: string;
}

const badgeStateSelector = (state: RootState) => state.badges;

const BadgesTableMain = ({ className }: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Parse the filters from the url query parameters
  const { sortKey, sortOrder } = useAppSelector(badgeStateSelector);
  const category: string | null | undefined = searchParams.get('category') ?? undefined;
  let _isFilterApplied = false;

  const search = searchParams.get('search') ?? undefined;
  const debounceSearch = useDebounce(search);
  // Current Page
  const [page, setPage] = useState(USHG_ADMIN_DEFAULT_PAGE);
  // Page Size
  const [pageSize, setPageSize] = useState(USHG_ADMIN_MIN_PAGE_SIZE);
  // sort
  let newSortKey = 'CREATED_ON';
  let newSortOrder: string | boolean = 'ASC';
  if (sortKey) {
    if (sortOrder === false) {
      // we will be getting the sort Order for columns which contain String values as asc:asc desc:false and false:desc
      newSortOrder = 'DESC';
      newSortKey = sortKey;
    } else if (sortOrder === 'desc') {
      newSortKey === '';
      newSortOrder === '';
    } else {
      newSortOrder = 'ASC';
      newSortKey = sortKey;
    }
    // we will be getting the sort Order for columns which contain number values as asc:false desc:desc and false:asc
  }
  // Build the badge filters from the available filters
  const filters = useMemo(() => {
    return {
      category: category,
      search: debounceSearch,
      start: (page - 1) * pageSize,
      size: pageSize,
      sortOrder: newSortOrder,
      sortKey: newSortKey,
    };
  }, [category, page, pageSize, debounceSearch, newSortOrder, newSortKey]);

  if (!filters.category || category === 'all') {
    // remove if the filter is empty
    delete filters.category;
  }
  if (!filters.search) {
    delete filters.search;
  }
  const badge = useHQAdminBadgesQuery({ filters });

  const displayLoader = useMemo(
    () => (badge.isLoading && !badge.data) || (badge.isFetching && badge.data?.count === 0),
    [badge.data, badge.isFetching, badge.isLoading]
  );

  const isEmptyState = useMemo(() => badge.data && badge.data.count === 0, [badge.data]);

  _isFilterApplied = category !== undefined || search !== undefined;

  return (
    <main
      id="main-content"
      className={clsx(
        'flex flex-col items-center gap-4 rounded-sm bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)] md:p-5 lg:p-6',
        className
      )}
    >
      <BadgesMainHeader
        category={category}
        filter={search ?? ''}
        isFilterApplied={_isFilterApplied}
      />
      {/* Is Loading and We donot have data */}
      {badge.isLoading && !badge.data && (
        <div className="flex w-full justify-center py-4">
          <BasicSpinner className="text-white" />
        </div>
      )}
      {/* We have data */}
      {badge.data && badge.data?.count > 0 && (
        <div
          className={clsx(
            'flex w-full flex-col gap-4',
            badge.isPreviousData && badge.isFetching && 'pointer-events-none opacity-50'
          )}
        >
          <div className="w-full overflow-y-scroll">
            <BadgesTable data={badge.data?.rows} />
          </div>
          <PaginationFooter
            setPage={setPage}
            activePage={page}
            totalItems={badge?.data?.count}
            pageSize={pageSize}
            setPageSize={setPageSize}
            maxPageSize={USHG_ADMIN_MAX_PAGE_SIZE}
            minPageSize={USHG_ADMIN_MIN_PAGE_SIZE}
            pageSizeIncrement={USHG_ADMIN_PAGE_SIZE_INCREMENT}
          />
        </div>
      )}
      {/* Empty State */}
      {isEmptyState && !displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <BadgesEmptyState isFilterApplied={_isFilterApplied} />
        </div>
      )}
      {/* Error State */}
      {badge.isError && !badge.data && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <BadgesErrorState isRefetching={badge.isRefetching} refetch={badge.refetch} />
        </div>
      )}
    </main>
  );
};

export default BadgesTableMain;
