import { useQuery } from '@tanstack/react-query';
import { PERFORMANCE_REPORT_TYPES, REPORTS_STALE_TIME } from '../../shared/constants';
import { getTopChurnRisk } from '../api';
import { TopChurnRiskFilters } from '../types';

interface Props {
  filters: Partial<TopChurnRiskFilters>;
  isEnabled: boolean;
}
const useUSHGAdminTopChurnRisk = ({ filters, isEnabled }: Props) => {
  const query = useQuery(
    [
      {
        filters: filters,
        scope: 'ushg-admin-dashboard',
        item: PERFORMANCE_REPORT_TYPES.TOP_CHURN_RISK,
      },
    ],
    async ({ queryKey }) => {
      const { filters } = queryKey[0];
      return await getTopChurnRisk(filters);
    },
    {
      staleTime: REPORTS_STALE_TIME,
      enabled: isEnabled,
    }
  );
  return query;
};

export default useUSHGAdminTopChurnRisk;
