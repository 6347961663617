import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RegisterSliceState {
  currentIndex: number;
  registration: any; // TODO: Assign right type
  errorCode: string;
  changePlanEvent: {
    role: string;
  };
}
const initialState: RegisterSliceState = {
  currentIndex: 0,
  registration: [],
  errorCode: '',
  changePlanEvent: {
    role: '',
  },
};

const RegisterSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentIndex: (state, event: PayloadAction<number>) => {
      state.currentIndex = event.payload;
    },
    setValue: (state, event: PayloadAction<object>) => {
      state.registration = event.payload;
    },
    setChangePlanFlag: (state, event: PayloadAction<string>) => {
      state.changePlanEvent.role = event.payload;
    },
    setErrorId: (state, event: PayloadAction<string>) => {
      state.errorCode = event.payload;
    },
  },
});

export const { setCurrentIndex, setValue, setChangePlanFlag, setErrorId } = RegisterSlice.actions;

export default RegisterSlice.reducer;
