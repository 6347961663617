export const SUBSCRIPTION_TYPE = {
  BASIC: 'basic',
  TEAM: 'team',
  ENTERPRISE: 'enterprise',
} as const;

export const SUBSCRIPTION_PLAN: Record<string, any> = {
  INDIVIDUAL: {
    LABEL: 'Individual',
    month: 15,
    year: 144,
  },
  TEAM: {
    LABEL: 'Team',
    month: 18,
    year: 180,
  },
  ENTERPRISE: {
    LABEL: 'Enterprise',
    month: 18,
    year: 180,
  },
};

export const DISCOUNT = {
  FIVE_PERCENT: {
    PERCENT: 5 / 100,
    LOWER_LIMIT: 200,
    UPPER_LIMIT: 299,
  },
  TEN_PERCENT: {
    PERCENT: 10 / 100,
    LOWER_LIMIT: 300,
    UPPER_LIMIT: 399,
  },
  FIFTEEN_PERCENT: {
    PERCENT: 15 / 100,
    LOWER_LIMIT: 400,
    UPPER_LIMIT: 499,
  },
  TWENTY_PERCENT: {
    PERCENT: 20 / 100,
    LOWER_LIMIT: 500,
  },
};

export const MEMBER_SLIDER_MAX = 1000;
export const TRAIL_LIMIT = 10;
export const SLIDER_STEPPER = {
  LARGE_SCREEN: 5,
  MEDIUM_SCREEN: 20,
  SMALL_SCREEN: 50,
};

export const VIEW_PORT_SM = 640;
