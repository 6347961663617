import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { TEAMS_API_PATHS } from '../../teams/constants';
import { AUTH_API_PATHS } from '../constants';
import {
  CheckCompanyExistsResponse,
  GetTenantResponse,
  CheckUserExistsResponse,
} from '../types/api';
import { authAPI } from './axios';
import qs from 'qs';

interface GetTenantDetailsReturnType {
  userPoolId: string;
  userPoolWebClientId: string;
  region: string;
  tenantId: string;
  identityPoolId: string;
  chatAppInstanceArn: string;
}

/**
 * Fetch tenant details for the current user, could be a indi
 * @summary Fetches the tenant details for the current user which contains cognito details, an individual user is considered to be under shared tenant
 * @param {string} username - username of the user i.e. email or sub
 * @return {TenantDetails} tenant details
 */
const getTenantDetailsForUser = async (username: string): Promise<GetTenantDetailsReturnType> => {
  const path = AUTH_API_PATHS.TENANT.GET_TENANT_DETAILS;

  const getTenantResponse = await authAPI.get<GetTenantResponse>(path, {
    params: {
      username,
    },
  });

  const tenantData = getTenantResponse.data;
  return {
    tenantId: tenantData.tenantId,
    userPoolId: tenantData.userPool,
    userPoolWebClientId: tenantData.webClientId,
    region: process.env.REACT_APP_COGNITO_REGION as string,
    identityPoolId: tenantData.identityPool,
    chatAppInstanceArn: tenantData.chatAppInstanceArn,
  };
};

const checkCompanyExists = async (tenantName: string, department: string) => {
  const path = AUTH_API_PATHS.TENANT.CHECK_COMPANY_EXISTS;

  const getCompanyExistsResponse = await authAPI.get<CheckCompanyExistsResponse>(path, {
    params: {
      tenantName,
      department,
    },
  });

  const checkCompanyExistData = getCompanyExistsResponse.data;
  return {
    companyExistsAlready: checkCompanyExistData.companyExistsAlready,
    message: checkCompanyExistData.message,
  };
};

const checkUserExists = async (email: string) => {
  const path = AUTH_API_PATHS.TENANT.CHECK_USER_EXISTS;

  const getUserExistsResponse = await authAPI.get<CheckUserExistsResponse>(path, {
    params: {
      email,
    },
  });

  const checkUserExistData = getUserExistsResponse.data;
  return {
    userExistsAlready: checkUserExistData.userExistsAlready,
    message: checkUserExistData.message,
  };
};
const checkTeamExists = async (teamName: string, currentTeamId?: number) => {
  const path = TEAMS_API_PATHS.CHECK_TEAM_EXISTS;
  const query = qs.stringify({ teamName, currentTeamId });
  const getTeamExistsResponse = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${query}`, {
    response: true,
    withCredentials: true,
  });

  const checkTeamExistData = getTeamExistsResponse.data;
  return {
    teamNameExistsAlready: checkTeamExistData.teamNameExistsAlready,
    message: checkTeamExistData.message,
  };
};

export { getTenantDetailsForUser, checkUserExists, checkCompanyExists, checkTeamExists };
