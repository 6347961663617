type AnyObject = { [key: string]: any };
const toTitleCaseKeys = (obj: AnyObject, deep = false) =>
  Object.keys(obj).reduce((acc, key) => {
    const result = key.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    acc[finalResult] = deep && typeof obj[key] === 'object' ? toTitleCaseKeys(obj[key]) : obj[key];
    return acc;
  }, {} as AnyObject);

export { toTitleCaseKeys };
