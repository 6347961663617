import { ChartData } from 'chart.js';
import clsx from 'clsx';
import { useContext, useMemo, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { ErrorDisplay } from '../../../../components/DataTables';
import { defaultGridOptions, defaultTickOptions, defaultTitleOptions } from '../../config';
import { REPORTS_INTERVAL, REPORT_TYPES } from '../../constants';
import durationContext from '../../context/durationContext';
import { useHoursSpentFrequencyQuery } from '../../hooks';
import { useStrapiReportsData } from '../../hooks/useStrapiReportData';
import {
  BarChartOptions,
  DashboardReportsFilter,
  HoursSpentFrequency,
  ReportTooltipDataType,
} from '../../types';
import NoDataFallback from '../NoDataFallback';
import { BarChart } from './baseCharts';
import ChartHeading from './ChartHeading';
import ScaleLoader from '../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../components/ReportToolTip';

interface Props {
  className?: string;
  heading?: string;
  lazyLoad?: boolean;
  teamId?: string;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}

const HoursSpentFrequencyViz = ({
  className,
  heading,
  lazyLoad = true,
  teamId,
  reportTooltipContent,
  userPersona,
}: Props) => {
  const { duration } = useContext(durationContext);
  const { xAxisLabel, yAxisLabel, emptyChart, errorChart } =
    useStrapiReportsData().HoursSpentFrequency;
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);

  // Build the filter
  const filters: Partial<DashboardReportsFilter> = useMemo(() => {
    return {
      fromDate: duration.fromDate,
      interval: duration.interval as keyof typeof REPORTS_INTERVAL,
      teamId: teamId && teamId !== '1' ? teamId : undefined,
    };
  }, [duration, teamId]);

  const hoursSpentFrequencyActvity = useHoursSpentFrequencyQuery({ filters, isEnabled: isInView });

  const hasHoursSpentFrequency =
    hoursSpentFrequencyActvity.data &&
    hoursSpentFrequencyActvity.data.results &&
    hoursSpentFrequencyActvity.data.results.length > 0;

  const hasNoHoursSpentFrequency =
    hoursSpentFrequencyActvity.data &&
    hoursSpentFrequencyActvity.data.results &&
    hoursSpentFrequencyActvity.data.results.length === 0;

  const isLoadingHoursSpentFrequency = hoursSpentFrequencyActvity.isLoading;

  const data = useMemo(() => {
    let labels: string[] = [];
    let hoursSpentFrequency: number[] = [];

    if (hasHoursSpentFrequency) {
      // Chartjs labels
      labels = hoursSpentFrequencyActvity.data?.results.map(
        (result: HoursSpentFrequency) => result.pool
      );

      hoursSpentFrequency = hoursSpentFrequencyActvity.data?.results.map(
        (result: HoursSpentFrequency) => +result.count
      );
    }

    const data: ChartData<'bar', number[], unknown> = {
      labels,
      datasets: [
        {
          data: hoursSpentFrequency || [],
          borderColor: ['#F7634F', '#FF9758', '#F3EAC7', '#C4D500', '#37990E'],
          backgroundColor: ['#F7634F', '#FF9758', '#F3EAC7', '#C4D500', '#37990E'],
        },
      ],
    };
    return data;
  }, [hasHoursSpentFrequency, hoursSpentFrequencyActvity.data]);

  // Chartjs options
  const chartOptions: BarChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
      },
    },
    datasets: {
      bar: {
        barThickness: 13,
      },
    },
    scales: {
      x: {
        title: {
          ...defaultTitleOptions,
          text: xAxisLabel,
        },
        grid: {
          ...defaultGridOptions,
          borderDash: [],
        },
        ticks: {
          ...defaultTickOptions,
          padding: 8,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          ...defaultTitleOptions,
          text: yAxisLabel,
        },
        grid: {
          ...defaultGridOptions,
          borderDash: [],
        },
        ticks: {
          ...defaultTickOptions,
          precision: 0,
          padding: 12,
        },
      },
    },
  };

  return (
    <InView
      as="div"
      className={clsx(
        'flex h-full min-h-[420px] flex-col gap-5 rounded-lg border border-zinc-700 bg-card-bg pt-4 pb-7',
        className
      )}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div className="flex flex-col justify-between gap-2 pl-4">
        <div className="flex items-center gap-2">
          <ChartHeading heading={heading as string} />
          {reportTooltipContent?.[REPORT_TYPES.HOURS_SPENT_FREQUENCY]?.[userPersona] && (
            <ReportTooltip
              content={reportTooltipContent[REPORT_TYPES.HOURS_SPENT_FREQUENCY][userPersona]}
            />
          )}
        </div>
      </div>
      <hr className="h-px border-0 bg-gray-700" />
      {isLoadingHoursSpentFrequency && !hasHoursSpentFrequency && (
        <div className="flex grow items-center justify-center">
          <ScaleLoader />
        </div>
      )}
      {/* Error State */}
      <>
        {hoursSpentFrequencyActvity.error && !hoursSpentFrequencyActvity.data && (
          <div className="flex grow items-center justify-center">
            <ErrorDisplay
              message={errorChart}
              refetch={hoursSpentFrequencyActvity.refetch}
              allowsRefetch={true}
              isRefetching={hoursSpentFrequencyActvity.isRefetching}
            />
          </div>
        )}
      </>
      {hasHoursSpentFrequency && (
        <BarChart
          options={chartOptions}
          className="m-auto max-h-80 min-h-[20rem] w-full px-4 pt-4"
          data={data}
        />
      )}
      {hasNoHoursSpentFrequency && !isLoadingHoursSpentFrequency && (
        <NoDataFallback message={emptyChart} />
      )}
    </InView>
  );
};

export default HoursSpentFrequencyViz;
