import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import sideBarContext from './context/sideBarContext';
import SideBarActionItem from './SideBarActionItem';
import SideBarCollapse from './SideBarCollapse';
import SideBarLogoBox from './SideBarLogoBox';
import SideBarNavItem from './SideBarNavItem';
import SideBarPanel from './SideBarPanel';
import SideBarProfile from './SideBarProfile';
import { useMediaQuery } from 'react-responsive';
import { useStrapiSideNavData } from '../../hooks/useStrapiSideNavData';

interface Props {
  className?: string;
  top: React.ReactNode[] | React.ReactNode;
  bottom: React.ReactNode[] | React.ReactNode;
  defaultCollapsed?: boolean;
}

const SideBar = ({ className, top, bottom, defaultCollapsed }: Props) => {
  const { srLabels } = useStrapiSideNavData();
  const handleIsWiderThanLargeScreenChange = useCallback((matches: boolean) => {
    if (matches) {
      setIsSidebarCollapsed(true);
    } else {
      setIsSidebarCollapsed(false);
    }
  }, []);

  // Smaller than large screen
  const isSmallerThanLargeScreen = useMediaQuery(
    { maxWidth: 1023 },
    undefined,
    handleIsWiderThanLargeScreenChange
  );

  // If the screen is smaller than large,
  // collapse by default
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    isSmallerThanLargeScreen ?? defaultCollapsed ?? false
  );

  return (
    <sideBarContext.Provider value={{ isSidebarCollapsed, setIsSidebarCollapsed }}>
      <aside
        aria-label={srLabels.sidebarSRLabel}
        className={clsx(
          'flex h-full flex-col items-stretch justify-between gap-2 border-r border-zinc-700 bg-card-bg px-3 py-2.5',
          !isSidebarCollapsed && 'w-1/6',
          // TODO: discuss with @praveen temporary
          isSmallerThanLargeScreen && !isSidebarCollapsed && 'border-zinc-700',
          className
        )}
      >
        {/* Top Panel */}
        <SideBarPanel
          aria-label={srLabels.topPanelSRLabel}
          className={clsx('order-1 items-stretch', isSidebarCollapsed && 'gap-6')}
        >
          <SideBarLogoBox />
          <div
            className={clsx(
              'flex flex-col items-stretch gap-2',
              isSidebarCollapsed && '!items-center gap-4'
            )}
          >
            {top}
          </div>
        </SideBarPanel>

        {/* Bottom Panel */}
        <SideBarPanel
          aria-label={srLabels.bottomPanelSRLabel}
          className={clsx('order-2 items-stretch', isSidebarCollapsed && '!items-center gap-4')}
        >
          {bottom}
        </SideBarPanel>
      </aside>
    </sideBarContext.Provider>
  );
};

SideBar.SideBarActionItem = SideBarActionItem;
SideBar.SideBarNavItem = SideBarNavItem;
SideBar.SideBarProfile = SideBarProfile;
SideBar.SideBarCollapse = SideBarCollapse;
SideBar.sideBarContext = sideBarContext;

export default SideBar;
