import { Info, X } from 'phosphor-react';
import { useMemo, useState } from 'react';
import cn from '../../utils/cn';

export type BannerVariant = 'info';

type Props = {
  variant?: BannerVariant;
  children: React.ReactNode[] | React.ReactNode;
};

const Banner = (props: Props) => {
  const { variant = 'info', children } = props;

  const [showBanner, setShowBanner] = useState(true);

  const Icon = useMemo(() => {
    switch (variant) {
      case 'info': {
        return Info;
      }
    }
  }, [variant]);

  if (!showBanner) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex w-full flex-row items-center justify-between gap-2 py-2 px-2 font-sans text-sm font-normal text-base-soft-black xs:py-4 xs:px-4 md:py-5 md:px-6',
        variant === 'info' && 'bg-base-bright-green'
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <Icon size={20} className={cn('shrink-0', variant === 'info' && 'text-base-soft-black')} />
        <div>{children}</div>
      </div>
      <button
        className={cn('shrink-0 px-1 py-0.5')}
        onClick={() => {
          setShowBanner(false);
        }}
      >
        <X size={20} className={cn(variant === 'info' && 'text-base-soft-black')} />
      </button>
    </div>
  );
};

export default Banner;
