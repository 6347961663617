import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { USHGAdminFeedback } from '../../../../../../constants/course-feedback';
import { useStrapiHQAdminCoursesData } from '../../../../../../hooks/useStrapiHQAdminCourseData';
import {
  setCourseFeedbackTableSortKey,
  setCourseFeedbackTableSortOrder,
} from '../../../components/slice/hq-single-course.slice';
import RatingsDropDownDisplayComponent from '../RatingsDropDownDisplayComponent';
import FeedbacksTableDataRow from './FeedbacksTableDataRow';
import FeedbacksTableHeaderRow from './FeedbacksTableHeaderRow';
import FeedbackTitleCell from './TableCells/FeedbackTitleCell';
import OrganizationNameCell from './TableCells/OrganizationNameCell';
import SubmittedNameCell from './TableCells/SubmittedNameCell';
import TimestampCell from './TableCells/TimestampCell';

interface Props {
  className?: string;
  data: USHGAdminFeedback[];
}
const getTableColumnDefinition = (
  feedbackHeader: string,
  ratingHeader: string,
  submittedNameHeader: string,
  organisationHeader: string,
  submittedOnHeader: string
) => {
  const columnHelper = createColumnHelper<USHGAdminFeedback>();

  const columns = [
    columnHelper.accessor('comments', {
      header: feedbackHeader,
      cell: (props) => <FeedbackTitleCell title={props.getValue()} />,
      id: 'comments',
    }),
    columnHelper.accessor('rating', {
      header: ratingHeader,
      cell: (props) => <RatingsDropDownDisplayComponent rating={props.getValue()} />,
      id: 'rating',
    }),
    columnHelper.accessor('createdBy', {
      header: submittedNameHeader,
      cell: (props) => <SubmittedNameCell feedback={props.row.original} />,
      id: 'createdBy',
    }),
    columnHelper.accessor('organizationName', {
      header: organisationHeader,
      cell: (props) => <OrganizationNameCell name={props.getValue()} />,
      id: 'organizationName',
    }),
    columnHelper.accessor('createdOn', {
      header: submittedOnHeader,
      cell: (props) => <TimestampCell timestamp={props.getValue()} />,
      id: 'createdOn',
    }),
  ];
  return columns;
};

const FeedbacksTable = ({ className, data }: Props) => {
  const {
    submittedOnTableHeader,
    feedbackTableHeader,
    organizationTableHeader,
    nameTableHeader,
    ratingTableHeader,
  } = useStrapiHQAdminCoursesData().singleCourseTableView.FeedbackTable;

  const columns = getTableColumnDefinition(
    feedbackTableHeader,
    ratingTableHeader,
    nameTableHeader,
    organizationTableHeader,
    submittedOnTableHeader
  );
  const { getHeaderGroups, getRowModel, getState } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiSort: true,
  });

  const dispatch = useDispatch();

  const { sorting } = getState();

  useEffect(() => {
    if (sorting.length === 0) {
      dispatch(setCourseFeedbackTableSortKey('createdOn'));
      dispatch(setCourseFeedbackTableSortOrder('asc'));
    } else {
      const { id, desc } = sorting[0];
      dispatch(setCourseFeedbackTableSortKey(id));
      dispatch(setCourseFeedbackTableSortOrder(desc ? 'desc' : 'asc'));
    }
  }, [sorting, dispatch]);

  return (
    <table className={clsx('w-full', className)}>
      <thead className="w-full">
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <FeedbacksTableHeaderRow isSortable={true} header={header} key={header.id} />
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <FeedbacksTableDataRow row={row} key={row.id} />
        ))}
      </tbody>
    </table>
  );
};

export default FeedbacksTable;
