import { FadeLoader } from 'react-spinners';
import { Button } from '../../../../components/Buttons';
import { CourseCard } from '../../../../components/Cards';
import { CourseType } from '../../../dashboard/types/CourseType';
import { useCallback, useRef } from 'react';
import { filterType } from '../../types/Filter.Helper';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import { useStrapiCoursesData } from '../../hooks/useStrapiCourseData';
import { SideBarLayoutPageContentWrapper } from '../../../../components/Wrapper';

type CoursesViewProps = {
  className?: string;
  currentTab?: any;
  currentTabState?: string;
  appliedFilters?: filterType[];
  tab?: any;
  navigateToAllCourses?: any;
  triggerClearAllFilters?: any;
};

const CoursesView = ({
  className,
  tab,
  currentTabState,
  navigateToAllCourses,
  triggerClearAllFilters,
}: CoursesViewProps) => {
  const cardObserver: any = useRef();
  const cardObserverCallBack = useCallback(
    (card: any) => {
      if (tab && tab.isLoading) return;
      if (cardObserver.current) cardObserver.current.disconnect();
      cardObserver.current = new IntersectionObserver((cardElement) => {
        if (cardElement[0].isIntersecting) {
          tab.fetchNextPage();
        }
      });
      if (card) cardObserver.current.observe(card);
    },
    [tab.isFetchingNextPage, tab.hasNextPage, tab.fetchNextPage]
  );
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const { noCourses, allCourses } = useStrapiCoursesData();
  const { clearAllFiltersButton } = allCourses.filters;
  const { navigator } = allCourses;
  return (
    <>
      <SideBarLayoutPageContentWrapper
        className={`h-full flex-col content-around gap-5 py-6 ${className}`}
      >
        {!tab.isLoading && tab.data && tab.data.pages.length !== 0 && (
          <div className="grid grid-cols-[repeat(auto-fill,_minmax(15rem,1fr))] gap-5">
            {tab.data.pages.map((page: any) =>
              page.results.map((c: CourseType, index: number) => {
                if (index + 1 === page.results.length)
                  return (
                    <div key={c.id} ref={cardObserverCallBack}>
                      <CourseCard key={c.id} course={c} />
                    </div>
                  );
                return (
                  <div key={c.id}>
                    <CourseCard key={c.id} course={c} />
                  </div>
                );
              })
            )}
          </div>
        )}
      </SideBarLayoutPageContentWrapper>
      {!tab.isLoading && tab.totalCount === 0 && (
        <div className="h-1/2">
          <div className="flex h-full items-center justify-center text-zinc-200">
            <div className="flex flex-col">
              <h2 className="m-auto text-base font-semibold">
                {noCourses.noCoursesMessage} {currentTabState}
              </h2>
              <p className="m-auto text-sm font-normal text-zinc-500">
                {noCourses.noCoursesDescription}
              </p>
              <Button
                className="m-auto my-4 w-fit bg-base-brand py-2 text-sm font-semibold"
                onClick={
                  !(currentTabState == navigator.allCoursesTab)
                    ? navigateToAllCourses
                    : triggerClearAllFilters
                }
              >
                {!(currentTabState == navigator.allCoursesTab)
                  ? noCourses.viewAllCoursesButton
                  : clearAllFiltersButton}
              </Button>
            </div>
          </div>
        </div>
      )}
      {tab.isLoading && (
        <div className="flex h-full items-center justify-center">
          {<FadeLoader color="#E4E4E7" width="3px" />}
        </div>
      )}
      {tab.isFetchingNextPage && (
        <div className="flex justify-center text-zinc-200">
          <p>{noCourses.loadingCourses}</p>
        </div>
      )}
      {tab.error && (
        <div className="flex h-full items-center justify-center text-zinc-200">
          <p>{strapiErrorMessage['SYSTEM_ERROR']}</p>
        </div>
      )}
    </>
  );
};

export default CoursesView;
