import { useQuery } from '@tanstack/react-query';
import { getMaintenanceMode } from '../api';
import { SETTING_API_PATHS } from '../constants';
import { QUERY_STALE_TIME } from '../constants/query';

const useGetMaintenanceModeQuery = () => {
  const query = useQuery({
    queryKey: [
      {
        scope: 'settings',
        item: 'maintenance-mode',
      },
    ],
    queryFn: async () => {
      const response = await getMaintenanceMode();
      return response;
    },
    staleTime: QUERY_STALE_TIME[SETTING_API_PATHS.GET_TENANT_MFA_CONFIGURATION],
    refetchOnMount: false,
  });

  return query;
};

export default useGetMaintenanceModeQuery;
