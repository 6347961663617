import { Check } from 'phosphor-react';
import { useStrapiCoursesData } from '../../../../../../hooks/useStrapiCourseData';
import { submitFeedbackFormType } from '../../../../types/SingleCourseTypes';
import RatingComponent from '../RatingComponent/RatingComponent';

interface Props {
  submitFormHandler: (data: submitFeedbackFormType) => void;
  isSubmitting: boolean;
}

const CourseCompletedModal = ({ submitFormHandler, isSubmitting }: Props) => {
  const { courseCompleted } = useStrapiCoursesData();
  return (
    <div className="mx-6 flex h-max max-h-[80vh] flex-col overflow-scroll rounded-lg border border-zinc-900 bg-card-bg p-6 text-white shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)]">
      <div
        className={`flex  flex-col items-center rounded-lg  border  border-[#34D3991A] bg-[#064E3B1A] p-8 `}
      >
        <div
          className={`mb-5 flex aspect-square h-max w-max flex-col items-center justify-center rounded-full bg-emerald-600 p-2 `}
        >
          <Check size={24} color="#ffffff" />
        </div>
        <div className="mb-2 text-lg font-bold">{courseCompleted.title}</div>
        <div className={`text-sm  text-emerald-200 `}>{courseCompleted.description}</div>
      </div>
      <div className="my-6 h-[1px] w-full bg-zinc-600" />
      <RatingComponent submitFormHandler={submitFormHandler} isSubmitting={isSubmitting} />
    </div>
  );
};

export default CourseCompletedModal;
