import useUserTypeToDisplay from '../../../../../../../hooks/useUserTypeToDisplay';
import { USER_TYPE_TO_DISPLAY_NAME_MAP } from '../../../../../constants';

interface Props {
  userType: keyof typeof USER_TYPE_TO_DISPLAY_NAME_MAP;
}

const UserRoleCell = ({ userType }: Props) => {
  const { USER_ROLE_DISPLAY_NAME_MAP } = useUserTypeToDisplay();
  const userRole = USER_ROLE_DISPLAY_NAME_MAP[userType];
  return <span className="text-xs font-normal text-zinc-100">{userRole}</span>;
};

export default UserRoleCell;
