import { useQuery } from '@tanstack/react-query';
import { ACTIVITY_REPORT_TYPES, REPORTS_STALE_TIME } from '../constants';
import { getEnterpriseAverageWeeklyHours } from '../api';
import { DashboardReportsFilter } from '../types';

interface Props {
  filters: DashboardReportsFilter;
  isEnabled: boolean;
}
const useEnterpriseAverageWeeklyHoursQuery = ({ filters, isEnabled }: Props) => {
  const query = useQuery(
    [
      {
        filters: filters,
        scope: 'dashboard',
        item: ACTIVITY_REPORT_TYPES.AVERAGE_WEEKLY_HOURS,
      },
    ],
    async ({ queryKey }) => {
      const { filters } = queryKey[0];
      return await getEnterpriseAverageWeeklyHours(filters);
    },
    {
      staleTime: REPORTS_STALE_TIME,
      enabled: isEnabled,
    }
  );
  return query;
};

export default useEnterpriseAverageWeeklyHoursQuery;
