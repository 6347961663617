import { useState } from 'react';
import Dialog from '../../../../../components/Dialog';
import { useStrapiTeamData } from '../../../hooks/useStrapiTeamData';
import { RoleOptions } from '../../../types/interface';

import AddNewMemberMain from '../../Modal/AddNewMemberModal/AddNewMemberMain';
import AddSubTeamMain from '../../Modal/AddSubTeamModal/AddSubTeamMain';
import EditTeamMain from '../../Modal/EditTeamModal/EditTeamMain';
import './styles.css';

interface Props {
  isAdminView?: boolean;
  isSubTeamPresentAlready?: boolean;
  teamName?: string;
  isSubTeam?: boolean;
  teamId: number;
}

function SingleTeamMenuItems({ teamName, isSubTeam, teamId }: Props) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddSubTeamModalOpen, setIsAddSubTeamModalOpen] = useState(false);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const { topNav } = useStrapiTeamData();
  const { topNavAddMemberButton, topNavAddSubTeamButton, topNavEditTeamButton } = topNav;
  return (
    <div className="flex flex-row justify-end">
      <div className="relative flex max-w-[188px] flex-col gap-y-4 xs:m-0  xs:max-w-none xs:flex-row xs:space-x-2">
        <Dialog
          triggerClassName=" w-full whitespace-nowrap !rounded bg-zinc-900 px-5 py-2 text-xs  w-full font-normal leading-tight text-white hover:bg-base-brand "
          containerClassName="max-h-[80vh] overflow-y-auto"
          open={isEditModalOpen}
          onOpenChange={(open) => setIsEditModalOpen(open)}
          Content={
            <EditTeamMain teamId={teamId} setIsModalOpen={setIsEditModalOpen} teamName={teamName} />
          }
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
        >
          {topNavEditTeamButton}
        </Dialog>

        {!isSubTeam && (
          <Dialog
            triggerClassName="gap-1 inline-block w-full whitespace-nowrap !rounded bg-zinc-900 px-5 py-2 text-xs  w-full font-normal leading-tight text-white hover:bg-base-brand md:mx-1"
            open={isAddSubTeamModalOpen}
            onOpenChange={(open) => setIsAddSubTeamModalOpen(open)}
            Content={<AddSubTeamMain teamId={teamId} setIsModalOpen={setIsAddSubTeamModalOpen} />}
            onInteractOutside={(e) => {
              e.preventDefault();
            }}
            containerClassName="max-h-[80vh] overflow-y-auto"
          >
            + {topNavAddSubTeamButton}
          </Dialog>
        )}

        <Dialog
          triggerClassName="mr-1 w-full inline-block gap-1 whitespace-nowrap !rounded bg-base-brand px-5 py-2 text-xs font-normal leading-tight text-white hover:bg-base-brand "
          open={isAddMemberModalOpen}
          onOpenChange={(open) => setIsAddMemberModalOpen(open)}
          containerClassName="max-h-[80vh] items-center overflow-y-auto"
          Content={
            <AddNewMemberMain
              teamId={teamId}
              setIsModalOpen={setIsAddMemberModalOpen}
              isLoading={false}
              dropDownOptions={RoleOptions.MEMBER_MANAGER}
              isOnBoarding={false}
              isAllMembers={false}
              teamName={teamName}
            />
          }
          onInteractOutside={(e) => {
            e.preventDefault();
          }}
        >
          + {topNavAddMemberButton}
        </Dialog>
      </div>
    </div>
  );
}

export default SingleTeamMenuItems;
