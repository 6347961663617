import { BILLING_STATUS } from '../constants/billings';

export const getBillingStatus = (isPaid: boolean, isOverdue: boolean) => {
  if (!isOverdue && !isPaid) {
    return BILLING_STATUS.PENDING;
  } else if (isPaid && !isOverdue) {
    return BILLING_STATUS.PAID;
  } else if (isOverdue && !isPaid) {
    return BILLING_STATUS.OVER_DUE;
  } else {
    return '-';
  }
};
