import ErrorMessage from '../../../../components/FormElements/ErrorMessage';
import { ENT_BULK_UPLOAD_FILE_SIZE_LIMIT_IN_MB } from '../../constants';
import { useBulkUploadErrorMap } from '../../hooks/useBulkUploadErrorMap';
import { FILE_UPLOAD_ERROR } from './EnterpriseBulkInviteUser';

interface Props {
  error: FILE_UPLOAD_ERROR;
}

const FileUploadError = (props: Props) => {
  const { error } = props;
  const { errorMap: BULK_UPLOAD_ERROR_MESSAGE_CODE_TO_MESSAGE_MAP } = useBulkUploadErrorMap();

  switch (error) {
    case 'EXCEEDS_SIZE_LIMIT': {
      const errorMessageTemplate = BULK_UPLOAD_ERROR_MESSAGE_CODE_TO_MESSAGE_MAP[error];

      const formattedMessage = errorMessageTemplate?.replace(
        '{{limit}}',
        ENT_BULK_UPLOAD_FILE_SIZE_LIMIT_IN_MB.toString()
      );

      return <ErrorMessage className="text-sm" message={formattedMessage} />;
    }
  }
};

export default FileUploadError;
