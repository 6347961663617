import { AppleIcon, GoogleIcon } from '../Icons';
import { Button } from '../Buttons';
import cn from '../../utils/cn';
import { signInWithSharedCognitoFederatedIdp } from '../../features/auth/helpers';
import { COGNITO_FEDERATED_IDP, FEDERATED_IDP } from '../../features/auth/constants';
import { useReduxAuthState } from '../../hooks';
import UserSocialLoginAlert from './UserSocialLoginAlert';
import { useStrapiSharedData } from '../../features/shared/hooks/useStrapiSharedData';

interface UserSocialLoginProps {
  appleLogin: string;
  googleLogin: string;
  displayOnlyAvailableForIndLearnersMessage?: boolean;
}

const UserSocialLogin = ({
  appleLogin,
  googleLogin,
  displayOnlyAvailableForIndLearnersMessage = true,
}: UserSocialLoginProps) => {
  // state
  const auth = useReduxAuthState();
  // state

  // strapi
  const {
    SSO: { availableOnlyForIndependentLearnersMessage },
  } = useStrapiSharedData();
  // strapi

  const socialLogins = [
    {
      icon: <AppleIcon width={22} height={22} className="fill-white" title={appleLogin} />,
      buttonText: appleLogin,
      className: 'bg-black text-white',
      provider: FEDERATED_IDP.APPLE,
    },
    {
      icon: <GoogleIcon width={22} height={22} title={googleLogin} />,
      buttonText: googleLogin,
      className: 'bg-white text-zinc-600',
      provider: FEDERATED_IDP.GOOGLE,
    },
  ];

  // derived
  const cognitoHostedUIErrorCode = auth.cognitoHostedUIError?.code;
  // derived

  // handlers

  const signInWithFederatedProvider = async (
    provider: keyof typeof COGNITO_FEDERATED_IDP.SHARED
  ) => {
    try {
      await signInWithSharedCognitoFederatedIdp(provider);
    } catch (error) {
      console.error(`Error signing in with provider ${provider}`);
    }
  };

  // handlers

  return (
    <div className="flex w-full max-w-md flex-col gap-3 py-5">
      {cognitoHostedUIErrorCode && (
        <UserSocialLoginAlert message={cognitoHostedUIErrorCode}></UserSocialLoginAlert>
      )}
      {socialLogins.map((login) => (
        <Button
          key={login.buttonText}
          className={cn('rounded-md', login.className)}
          leadingIcon={login.icon}
          contentWrapperClassName="gap-x-3 text-sm"
          onClick={() => signInWithFederatedProvider(login.provider)}
        >
          <span className="font-medium"> {login.buttonText}</span>
        </Button>
      ))}
      {displayOnlyAvailableForIndLearnersMessage && availableOnlyForIndependentLearnersMessage ? (
        <span className="text-center text-xs font-normal text-[#fbb1a7]">
          {availableOnlyForIndependentLearnersMessage}
        </span>
      ) : null}
    </div>
  );
};

export default UserSocialLogin;
