import { Link, useLocation } from 'react-router-dom';
import OktaText from '../../../../../components/Icons/OktaText';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { signInUserWithUSHGOktaProvider } from '../../../helpers';
import UserLoginHeader from '../common/UserLoginHeader';
import { useEffect } from 'react';
import { useLanguage } from '../../../../shared/context/languageContext';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../constants/user';

const USHGAdminLoginMain = () => {
  const location = useLocation();
  const data: any = useStrapiDataHelper();
  const handleLoginButtonClick = () => {
    // We could use the information from state
    // to redirect the user to the page the user was originally intending to but was redirected because
    // entity was unauthenticated
    signInUserWithUSHGOktaProvider(location.state?.fromPath ?? '/dashboard');
  };

  // Set the preferred lang as "en" for HQ Admin
  const { setLanguage } = useLanguage();

  useEffect(() => {
    setLanguage(DEFAULT_PREFERRED_LANGUAGE);
  }, []);

  return (
    <main id="main-content" className="flex items-center justify-center">
      <div className="flex w-full max-w-md flex-col items-center justify-center gap-7">
        <UserLoginHeader
          heading={data?.login.data.attributes?.adminTitle}
          subHeading={data?.login.data.attributes?.adminDescription}
        />
        <button
          onClick={handleLoginButtonClick}
          className="relative flex items-center justify-start self-stretch bg-base-brand py-[14px] px-[23px] xs:justify-center"
        >
          <span className="text-sm font-semibold leading-[25px] tracking-[0.005em] text-white">
            {data?.login.data.attributes?.loginwithSSO}
          </span>
          <OktaText
            className="absolute right-0 h-3 w-auto pr-[23px]"
            fill="white"
            title={data?.login.data.attributes?.oktaSvgTitle}
          />
        </button>
        <Link to=".." className="text-center text-sm font-normal leading-[23px] text-white">
          {data?.login.data.attributes.backToConsumerLogin}
        </Link>
      </div>
    </main>
  );
};

export default USHGAdminLoginMain;
