import {
  AVATAR_SIZE,
  FILE_UNITS,
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_BYTES,
} from '../../features/shared/constants';

type FileState = {
  fileSize: number | null;
  fileType: string | null;
  fileContents: File | null;
};

let response: FileState = {
  fileSize: null,
  fileType: null,
  fileContents: null,
};

/**
 * Helper to convert bytes to Mb
 */

const bytesToMb = (bytes: number) => {
  const mb = bytes / 1000000;

  return mb;
};

/**
 * Helper to handle file upload
 */

const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const fileObj = event.target.files && event.target.files[0];

  if (!fileObj) {
    return;
  }

  // Build response object
  response = {
    fileSize: fileObj.size,
    fileType: fileObj.type,
    fileContents: fileObj,
  };

  // File type validation

  const [type] = fileObj.type.split('/');
  if (!type || type !== 'image') {
    throw { message: 'You can only upload image files.' };
  }

  // Image dimension validation

  if (type === 'image') {
    const img = new Image();
    img.src = window.URL.createObjectURL(fileObj);
    const promise = new Promise<FileState>((resolve, reject) => {
      img.onload = () => {
        if (img.width < AVATAR_SIZE && img.height < AVATAR_SIZE) {
          // Reject promise on error
          reject({
            message: `Sorry, this image doesn't look like the size we wanted. It's
      ${img.width} x ${img.height} but we require at least ${AVATAR_SIZE} x ${AVATAR_SIZE} size image.`,
          });
        } else {
          resolve(response);
        }
      };
    });
    return promise;
  }

  // Image size validation

  if (fileObj.size > MAX_FILE_SIZE_BYTES) {
    throw {
      message: `File is too large, file size is ${bytesToMb(fileObj.size).toFixed(2)} ${
        FILE_UNITS.MB
      }, maximum allowed size - ${MAX_FILE_SIZE} ${FILE_UNITS.MB}.`,
    };
  }

  return response;
};

export { handleFileChange };
