import { useStrapiDataHelper } from '../../../../../../../hooks/useStrapiData';
import { useStrapiRequestData } from '../../../../../hooks/useStrapiRequestData';
import {
  EnterpriseAdminEditTeamRequestData,
  EnterpriseAdminRequest,
  EnterpriseAdminTeamRequestData,
  EnterpriseAdminUserRequestData,
} from '../../../../../types';
import TeamNameDialogItemContent from '../../RequestActionDialog/TeamNameDialogItemContent';

interface RequestsCellProps {
  row: EnterpriseAdminRequest;
}

const RequestsCell = (props: RequestsCellProps) => {
  const { row } = props;
  const requestTypeName = row.requestType.requestTypeDetails[0].name;
  const { requestType } = useStrapiRequestData();
  const { addMember, addTeam, editTeam } = requestType;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  switch (requestTypeName) {
    case 'NEW_MEMBER': {
      const data = row.data as EnterpriseAdminUserRequestData;
      return (
        <span className="flex gap-1 whitespace-nowrap">
          <span className="font-bold">{addMember}</span>
          <span>:</span>
          <span>{data.memberEmail}</span>
        </span>
      );
    }
    case 'NEW_TEAM': {
      const data = row.data as EnterpriseAdminTeamRequestData;
      return (
        <span className="flex gap-1 whitespace-nowrap">
          <span className="font-bold">{addTeam}</span>
          <span>:</span>
          <span>{data.teamName}</span>
        </span>
      );
    }
    case 'EDIT_TEAM': {
      const data = row.data as EnterpriseAdminEditTeamRequestData;

      return (
        <span className="flex items-center gap-1 whitespace-nowrap">
          <span className="font-bold">{editTeam}</span>
          <span>:</span>
          <TeamNameDialogItemContent
            teamId={data.teamId}
            errorMessage={strapiErrorMessage['TEAM_NAME_ERROR']}
          />
        </span>
      );
    }
  }
};

export default RequestsCell;
