import ImageWithAcronymFallback from '../../../../../../../components/ImageWithAcronymFallback';
import { USER_STATUS } from '../../../../../constants';
import { GetAllMembersInOrganizationResponseDataItem } from '../../../../../types';

interface Props {
  row: GetAllMembersInOrganizationResponseDataItem;
}

const AvatarAndNameCell = (props: Props) => {
  const { row } = props;
  const { fullName, pictureUrl, username, status } = row;

  const hasFullName = fullName && fullName !== '';

  const acronymWord = hasFullName ? fullName : username;
  const name = !hasFullName
    ? status === USER_STATUS.INVITED
      ? 'Invited Member'
      : username
    : fullName;

  return (
    <div className="flex items-center gap-2">
      <ImageWithAcronymFallback
        acronymLogoClassName="w-6 h-6 text-xs font-normal border border-zinc-900"
        className="h-6 w-6 rounded-full border border-zinc-900 object-cover object-center"
        acronymWord={acronymWord}
        imageKey={pictureUrl}
      />
      <span className="text-xs font-normal text-zinc-100">{name}</span>
    </div>
  );
};

export default AvatarAndNameCell;
