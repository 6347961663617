import React, { useState, useRef, useEffect, MouseEvent, TouchEvent } from 'react';
import { Info } from 'phosphor-react';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';

// Define a type for the handler function
type Handler = (event: MouseEvent | TouchEvent) => void;

// Custom Hook to handle clicks outside the referenced element
function useOutsideClick(ref: React.RefObject<HTMLElement>, handler: Handler) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener as unknown as EventListener);
    document.addEventListener('touchstart', listener as unknown as EventListener);

    return () => {
      document.removeEventListener('mousedown', listener as unknown as EventListener);
      document.removeEventListener('touchstart', listener as unknown as EventListener);
    };
  }, [ref, handler]);
}

const ReportTooltip: React.FC<{ content: string }> = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null); // Specify HTMLDivElement as the type for the ref

  // Use the custom hook
  useOutsideClick(ref, () => setIsOpen(false));

  return (
    <div className="flex">
      <TooltipWrapper
        content={content}
        ariaLabel="Click/Hover for additional information about the report"
        direction="bottom"
        alignment="end"
        contentClassName="z-50 w-full overflow-auto max-w-[265px] max-h-56 border border-zinc-600 bg-zinc-700 text-zinc-200 p-3.5 font-inter text-xs font-medium text-left rounded-lg shadow-lg"
        sideOffset={5}
        duration={300}
        open={isOpen}
      >
        <div className="cursor-pointer" onClick={() => setIsOpen(!isOpen)} ref={ref}>
          <Info
            aria-label="Click/Hover for Report Description"
            size={18}
            color="#A1A1AA"
            weight="bold"
          />
        </div>
      </TooltipWrapper>
    </div>
  );
};

export default ReportTooltip;
