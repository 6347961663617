import { useNavigate } from 'react-router-dom';
import ActionResponse from '../../../../../components/ActionResponse';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';

export type UserRegistrationFailureType =
  | 'ACCOUNT_ALREADY_EXIST'
  | 'SEARCH_PARAMS_MISSING'
  | 'REGISTRATION_UNKNOWN_ERROR'
  | 'INVITE_NOT_FOUND'
  | 'UNKNOWN_ERROR';

interface Props {
  type: UserRegistrationFailureType;
  badgeText?: string;
}

const UserRegistrationMessageSubTitle = ({ type }: Props) => {
  const navigate = useNavigate();
  // pull data from strapi helper
  const data = useStrapiDataHelper();
  const strapiErrorMessage = data.errorsMap;
  const { accountAlreadyExists, clickHereLabel } = data.errormessage.data.attributes;
  const accountAlreadyExistsMessage = accountAlreadyExists.split('{{click here}}');
  const navigateToLogin = () => {
    navigate('/auth/login');
  };
  const { RegistrationFailed } = data.registration.data.attributes;
  const { description: registrationUnknownErrorDescription } = RegistrationFailed;
  switch (type) {
    case 'SEARCH_PARAMS_MISSING':
    case 'INVITE_NOT_FOUND':
    case 'UNKNOWN_ERROR': {
      return <>{strapiErrorMessage[type]}</>;
    }
    case 'ACCOUNT_ALREADY_EXIST':
      return (
        <>
          {accountAlreadyExistsMessage[0]}
          <button onClick={navigateToLogin} className="underline">
            {clickHereLabel}
          </button>{' '}
          {accountAlreadyExistsMessage[1]}
        </>
      );
    case 'REGISTRATION_UNKNOWN_ERROR':
      return registrationUnknownErrorDescription;
  }
};

const getUserRegistrationMessageTitle = (type: UserRegistrationFailureType) => {
  const data = useStrapiDataHelper();
  const strapiErrorMessage = data.errorsMap;
  const { RegistrationFailed } = data.registration.data.attributes;
  const { title: registrationUnknownErrorTitle } = RegistrationFailed;
  switch (type) {
    case 'REGISTRATION_UNKNOWN_ERROR': {
      return registrationUnknownErrorTitle;
    }

    case 'SEARCH_PARAMS_MISSING': {
      return strapiErrorMessage['SEARCH_PARAMS_MISSING_TITLE'];
    }
    case 'UNKNOWN_ERROR': {
      return strapiErrorMessage['ORGANIZATION_PROFILE_FAILED'];
    }
  }
};

const UserRegistrationFailure = ({ type, badgeText }: Props) => {
  const title = getUserRegistrationMessageTitle(type);

  return (
    <div className="flex w-full flex-col items-center">
      <ActionResponse
        className="max-w-md border border-base-bright-green/30 bg-zinc-900 shadow-md"
        title={title}
        subtitle={<UserRegistrationMessageSubTitle type={type}></UserRegistrationMessageSubTitle>}
        variant="failure"
        badgeClassName="bg-red-500 text-[10px] text-emerald-100 font-bold justify-center"
        badgeText={badgeText}
        failureVariantBackground="bg-red-500"
      />
    </div>
  );
};

export default UserRegistrationFailure;
