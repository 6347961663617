import { useState } from 'react';
import toast from 'react-hot-toast';
import { Toast } from '../../../../../../../components/Toast';
import { USHG_HQ_APP_ROLE } from '../../../../../../../constants';
import useHasAccess from '../../../../../../../hooks/use-has-access';
import HasAccess from '../../../../../../shared/components/HasAccess';
import { ENTERPRISE_ONBOARDING_TYPE } from '../../../../../constants';
import { useCreateGoalsMutation } from '../../../../../hooks';
import { useStrapiOnBoardingData } from '../../../../../hooks/useStrapiOnBoardingData';
import { Goal, TabWidgetType } from '../../../../../types';
import { StepperControl } from '../../../../Stepper';
import { Tabs } from '../../Tabs';
import GoalsCardView from './GoalsCardView';
import { UseQueryResult } from '@tanstack/react-query';

interface GoalWrapperProps {
  currentStep: string;
  setCurrentStep: (currentStep: string) => void;
  steps: string[];
  selectedGoals: Goal[];
  setSelectedGoals: (selectedGoals: Goal[]) => void;
  // prevSelectedGoals: number[] | null;
  goalsQuery: UseQueryResult<any>;
}

const GoalWrapper = ({
  currentStep,
  setCurrentStep,
  steps,
  selectedGoals,
  setSelectedGoals,
  // prevSelectedGoals,
  goalsQuery,
}: GoalWrapperProps) => {
  // strapi content

  const { leaderGoals, managerGoals, toastMessages } = useStrapiOnBoardingData();
  const {
    leaderPersonalGoalTitle,
    leaderPersonalGoalDescription,
    leaderTeamGoalTitle,
    teamGoalToggleButton,
    leaderTeamGoalDescription,
    personalGoalToggleButton,
  } = leaderGoals;
  const {
    managerTeamGoalToggleButton,
    managerPersonalGoalToggleButton,
    managerTeamGoalTitle,
    managerTeamGoalDescription,
    managerPersonalGoalTitle,
    managerPersonalGoalDescription,
  } = managerGoals;

  const managerAccess = useHasAccess([USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER]);
  const GOALS = {
    PERSONAL_GOALS: managerAccess ? managerPersonalGoalToggleButton : personalGoalToggleButton,
    TEAM_GOALS: managerAccess ? managerTeamGoalToggleButton : teamGoalToggleButton,
  };
  const GoalWidgetData: TabWidgetType[] = [
    {
      id: 1,
      type: ENTERPRISE_ONBOARDING_TYPE.PERSONAL,
      text: GOALS.PERSONAL_GOALS,
      completed: false,
    },
    {
      id: 2,
      type: ENTERPRISE_ONBOARDING_TYPE.TEAM,
      text: GOALS.TEAM_GOALS,
      completed: false,
    },
  ];

  const GOALS_LEADER_CONTENT_MAPPER = {
    [GOALS.PERSONAL_GOALS]: {
      header: leaderPersonalGoalTitle,
      subHeader: leaderPersonalGoalDescription,
    },
    [GOALS.TEAM_GOALS]: {
      header: leaderTeamGoalTitle,
      subHeader: leaderTeamGoalDescription,
    },
  };
  const GOALS_MANAGER_CONTENT_MAPPER = {
    [GOALS.PERSONAL_GOALS]: {
      header: managerPersonalGoalTitle,
      subHeader: managerPersonalGoalDescription,
    },
    [GOALS.TEAM_GOALS]: {
      header: managerTeamGoalTitle,
      subHeader: managerTeamGoalDescription,
    },
  };

  let GOALS_CONTENT_MAPPER;
  if (managerAccess) {
    GOALS_CONTENT_MAPPER = GOALS_MANAGER_CONTENT_MAPPER;
  } else {
    GOALS_CONTENT_MAPPER = GOALS_LEADER_CONTENT_MAPPER;
  }

  // strapi content

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [widgetData, setWidgetData] = useState<TabWidgetType[]>(GoalWidgetData);

  // Access check using custom hook
  const hasAccess = useHasAccess([
    USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
    USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
    USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
  ]);

  // To prevent getting the data from tanstack response every time we switch between tabs
  const [selectedGoalsInitialise, setSelectedGoalsIntialise] = useState<Record<string, boolean>>(
    () => {
      const initialiseObj: Record<string, boolean> = {};
      Object.keys(ENTERPRISE_ONBOARDING_TYPE).forEach((key) => {
        initialiseObj[key] = false;
      });
      return initialiseObj;
    }
  );

  // Create - Create Goal Mutation
  const createGoalsMutation = useCreateGoalsMutation();

  // TODO: Revisit
  /**
   * Checks if the selected goals have changed.
   *
   * @returns {boolean} Returns `true` if the selected goals have changed, `false` otherwise.
   */
  // const hasSelectedGoalsChanged = () => {
  //   return JSON.stringify(selectedGoals) !== JSON.stringify(prevSelectedGoals);
  // };

  // Show error toast message for minimum goals requirement
  const showMinGoalToastErrorMessage = () => {
    toast.custom(
      (t) => <Toast variant="error" Title={toastMessages.goalsMinError} toastInstance={t} />,
      {
        id: '1',
        duration: 3000,
      }
    );
  };

  // Filter and get selected goals for the current tab
  const selectedGoalsForTab = () => {
    return selectedGoals.filter((goal) => goal.type === widgetData[currentTab].type);
  };

  const handleWidgetToggle = (widgetIndex: number) => {
    if (widgetIndex === 1) {
      // If moving from the first tab to the second tab, check if there are selected goals for the current tab

      if (selectedGoalsForTab().length === 0) {
        // Show the error message if no goals are selected for the current tab
        showMinGoalToastErrorMessage();
        return;
      }
    }

    // Update the widget completion flag and the current tab.
    widgetData[currentTab].completed = true;
    setWidgetData(widgetData);
    setCurrentTab(widgetIndex);
  };

  // Handle goal submission
  const handleGoalSubmission = () => {
    // TODO: Revisit
    //  Checks if the selected goals have changed. If not, proceeds to the next step.
    //  If the selected goals have not changed, the current step is incremented and set
    //  as the new current step. allowing progression to the next step.

    // if (!hasSelectedGoalsChanged()) {
    //   let currIndex = steps.indexOf(currentStep);

    //   const newStep = ++currIndex;

    //   if (newStep <= steps.length - 1) {
    //     setCurrentStep(steps[newStep]);
    //   }
    //   return;
    // }
    // Create new request
    // Check if the selected goals for the specific tab type (Personal or Team)
    if (selectedGoalsForTab().length > 0) {
      createGoalsMutation.mutate(selectedGoals, {
        onSuccess: () => {
          setSelectedGoalsIntialise(() => {
            return {};
          });
        },
        onSettled: () => {
          let currIndex = steps.indexOf(currentStep);

          const newStep = ++currIndex;

          if (newStep <= steps.length - 1) {
            setCurrentStep(steps[newStep]);
          }
        },
      });
    } else {
      // Show the error message if no goals are selected for the current tab
      showMinGoalToastErrorMessage();
      return;
    }
  };

  // Handle previous step in the stepper
  const stepperPrevControl = () => {
    let currIndex = steps.indexOf(currentStep);
    const newStep = --currIndex;
    if (newStep !== -1) {
      setCurrentStep(steps[newStep]);
    }
  };

  // Handle next step in the stepper
  const stepperNextControl = () => {
    if (!hasAccess) {
      handleGoalSubmission();
      return;
    }

    if (selectedGoalsForTab().length === 0) {
      showMinGoalToastErrorMessage();
      return;
    }

    if (currentTab < widgetData.length - 1) {
      const nextTab = currentTab + 1;
      handleWidgetToggle(nextTab);
    } else {
      handleGoalSubmission();
    }
  };

  return (
    <div className="flex flex-col gap-2">
      {/* Enterprise Admin, Leader, Manager Steps  */}
      <HasAccess
        roles={[
          USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
          USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
          USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
        ]}
        Fallback={null}
      >
        <Tabs
          key={GoalWidgetData[currentTab].id}
          widgetData={widgetData}
          selectedWidget={currentTab}
          handleWidgetToggle={handleWidgetToggle}
        />
        <GoalsCardView
          content={GOALS_CONTENT_MAPPER}
          goalsQuery={goalsQuery}
          currentTab={currentTab}
          widgetData={widgetData}
          setSelectedGoals={setSelectedGoals}
          selectedGoals={selectedGoals}
          selectedGoalsInitialise={selectedGoalsInitialise}
          setSelectedGoalsIntialise={setSelectedGoalsIntialise}
        />
      </HasAccess>

      {/* Enterprise Learner, Individual User */}
      <HasAccess
        roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER, USHG_HQ_APP_ROLE.INDIVIDUAL_USER]}
        Fallback={null}
      >
        <GoalsCardView
          content={GOALS_CONTENT_MAPPER}
          goalsQuery={goalsQuery}
          currentTab={currentTab}
          widgetData={widgetData}
          setSelectedGoals={setSelectedGoals}
          selectedGoals={selectedGoals}
          selectedGoalsInitialise={selectedGoalsInitialise}
          setSelectedGoalsIntialise={setSelectedGoalsIntialise}
        />
      </HasAccess>
      {/* navigation button */}
      <StepperControl
        isStepLoading={goalsQuery.isLoading}
        isNextStepLoading={createGoalsMutation.isLoading}
        aria-label="Goal Step controls"
        stepperPrevControl={stepperPrevControl}
        stepperNextControl={stepperNextControl}
        currentStep={currentStep}
      />
    </div>
  );
};

export default GoalWrapper;
