import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { USHGAPIError } from '../../../types';
import { getAssessmentBasicDetails } from '../api';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';

interface Props {
  assessmentId?: number;
  language?: string;
  isEnabled?: boolean;
}
/**
 * Wrapper around the react querys useQuery to fetch the assessment details.
 */
const useAssessmentBasicDetailsQuery = ({
  assessmentId,
  language = DEFAULT_PREFERRED_LANGUAGE,
  isEnabled = true,
}: Props) => {
  const [errorType, setErrorType] = useState<string | null>();
  const { isLoading, data, isError, refetch, isRefetching, isFetching } = useQuery(
    [
      {
        assessmentId: assessmentId,
        language: language,
        scope: 'assessment',
        item: 'assessment-basic-details',
      },
    ],
    async ({ queryKey }) => {
      const { assessmentId, language } = queryKey[0];
      if (assessmentId) {
        return await getAssessmentBasicDetails({ assessmentId, language });
      }
    },
    {
      enabled: isEnabled && assessmentId !== undefined,
      onError: (error) => {
        // If axios error
        if (axios.isAxiosError(error)) {
          const data = error.response?.data;
          if (data) {
            const { errorCode } = data as USHGAPIError;
            setErrorType(errorCode);
          }
        }
      },
    }
  );
  return { isLoading, data, isError, refetch, isRefetching, isFetching, errorType, setErrorType };
};

export default useAssessmentBasicDetailsQuery;
