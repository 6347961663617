import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { queryClient } from '../../../config/react-query';
import { USHG_HQ_APP_ROLE } from '../../../constants';
import { resolveUSHGAdminRequests } from '../api';
import { useStrapiRequestData } from './useStrapiRequestData';
const useUSHGAdminRequestResolveMutation = () => {
  // strapi data
  const {
    toastMessages: { HQRequestResolvedFailedTitle, HQRequestResolvedTitle },
  } = useStrapiRequestData();
  const mutation = useMutation({
    mutationFn: async (id: number) => {
      const response = await resolveUSHGAdminRequests(id);
      return response;
    },
    onSuccess: (response) => {
      // Invalidate the enterprise admin requests query
      queryClient.invalidateQueries([
        {
          persona: USHG_HQ_APP_ROLE.USHG_ADMIN,
          scope: 'requests',
          item: 'requests',
        },
      ]);

      toast.custom((t) => (
        <Toast
          variant="success"
          Title={HQRequestResolvedTitle}
          SubTitle={response.message}
          toastInstance={t}
        />
      ));
    },
    onError: (_error) => {
      toast.custom((t) => (
        <Toast variant="error" Title={HQRequestResolvedFailedTitle} toastInstance={t} />
      ));
    },
  });

  return mutation;
};

export default useUSHGAdminRequestResolveMutation;
