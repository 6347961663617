import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import Alert from '../../../../../components/Alert';
import { USHG_HQ_APP_ROLE } from '../../../../../constants';
import { useAppSelector, useHasAccess } from '../../../../../hooks';
import { RootState } from '../../../../../store';
import HasAccess from '../../../../shared/components/HasAccess';
import { adminAddTeamMember, requestToAddTeamMember } from '../../../api';
import { REGEX } from '../../../constants/regex';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AddTeamMemberRequest, AdminAddTeamMemberArgs } from '../../../types';
import { DropdownInput, Label, TextInput } from '../../../../../components/FormElements';
import toast from 'react-hot-toast';
import { Toast } from '../../../../../components/Toast';
import { triggerSectionDataRefresh } from '../../../slices/team-dispatch.helper';
import { SECTIONS } from '../../../constants/section-name';
import { BasicSpinner } from '../../../../../components/Spinners';
import Dialog from '../../../../../components/Dialog';
import { useStrapiTeamData } from '../../../hooks/useStrapiTeamData';
import { fieldHasError } from '../../../../../utils/react-hook-form';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
import SubscriptionInfo from '../subscriptionInfo/subscriptionMain';
import { Button } from 'react-daisyui';
import { useNavigate } from 'react-router-dom';
import { useStrapiOnBoardingData } from '../../../../onboarding/hooks/useStrapiOnBoardingData';
import { INVITEE_ROLE, Member } from '../../../../onboarding/types';
import { addMemberToTeam, addNewLeader } from '../../../../onboarding/slices/onboarding.slice';
import { ROLES } from '../../../types/interface';
import useEnterpriseAdminAllTeamsQuery from '../../../hooks/useAllTeamsApi';
import { queryClient } from '../../../../../config/react-query';
import { FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY } from '../../../../shared/constants';
import { checkUserExists } from '../../../../auth/api';
import { TRAIL_LIMIT } from '../../../../../constants/subscription-plans';
import { ERROR_CODE } from '../../../constants';
import { USHGAPIError } from '../../../../../types';
import axios from 'axios';
import IconWithModal from '../../../../../components/IconWithModal';
import RoleDescription from '../../../../shared/components/RoleDescription';
import { Question } from 'phosphor-react';
// Yup Schema
function getSchema(
  jobTitleMinValue: number,
  jobTitleMaxValue: number,
  emailLimit: number,
  isAllMembers: boolean
) {
  const addNewMemberFormSchema = yup.object({
    teamName: yup
      .string()
      .trim()
      .when(['isAllMembers'], {
        is: true,
        then: (schema) =>
          schema.when(['role'], {
            is: 'Leader',
            then: yup.string().notRequired(),
            otherwise: yup.string().required('teamNameRequired'),
          }),
      }),
    isAllMembers: yup.boolean().default(isAllMembers),
    name: yup
      .string()
      .trim()
      .when('isAllMembers', {
        is: true,
        then: (schema) => schema.required('memberNameRequired'),
      }),
    email: yup
      .string()
      .trim()
      .email('validEmailError')
      .required('memberEmailRequired')
      .max(emailLimit, 'emailLimitError'),
    role: yup.string().required('memberRoleRequired'),
    jobTitle: yup
      .string()
      .trim()
      .required('jobTitleRequired')
      .min(jobTitleMinValue, 'jobTitleMinError')
      .max(jobTitleMaxValue, 'jobTitleMaxError'),
  });
  return addNewMemberFormSchema;
}
// Yup Schema

interface Props {
  teamId?: number;
  teamName?: string;
  setIsModalOpen: (open: boolean) => void;
  ROLE_OPTIONS: string;
  INVITEE?: string;
  currentIndex?: number;
  setCurrentIndex?: any;
  isLoading?: boolean;
  isOnBoarding: boolean;
  isAllMembers: boolean;
}

const subscriptionInfoData = (state: RootState) => state.subscription;
const teamStateSelector = (state: RootState) => state.team;
const onboardingStateSelector = (state: RootState) => state.onboarding;

function AddNewTeamMemberModal({
  teamId,
  teamName,
  setIsModalOpen,
  ROLE_OPTIONS,
  INVITEE,
  isLoading: isLoadingOnboarding,
  currentIndex,
  isOnBoarding,
  isAllMembers,
}: Props): JSX.Element {
  const { addmember, commomTeamErrorMessage, adminToastMessages, leaderManagerToastMessages } =
    useStrapiTeamData();
  const teamsErrorMessages: any = commomTeamErrorMessage;
  const addNewMemberFormSchema = getSchema(
    teamsErrorMessages.jobTitleMinValue,
    teamsErrorMessages.jobTitleMaxValue,
    teamsErrorMessages.emailMaxValue,
    isAllMembers
  );
  const {
    memberAdditionSuccessToastMessage,
    memberAdditionProcessingToastMessage,
    memberAdditionFailureToastMessage,
  } = adminToastMessages;
  const { leaderManagerSuccessToastMessage, leaderManagerFailureToastMessage } =
    leaderManagerToastMessages;
  // for we can use these values for all members page
  // isAllMembers = true;
  // ROLE_OPTIONS = 'ALL_MEMBERS';

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<ADD_NEW_MEMBER_FORM_DATA>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      role: '',
      jobTitle: '',
      teamName: '',
    },
    resolver: yupResolver(addNewMemberFormSchema),
  });

  type ADD_NEW_MEMBER_FORM_DATA = yup.InferType<typeof addNewMemberFormSchema>;
  const { subscriptionData } = useAppSelector(subscriptionInfoData);
  const [isLoading, setLoading] = useState(false);
  const [isEmailCheckinProgress, setEmailCheckinProgress] = useState(false);
  const [memberExists, setMemberExists] = useState(false);
  const dispatch = useDispatch();
  const authStateSelector = (state: RootState) => state.auth;
  const { user } = useAppSelector(authStateSelector);
  const { selectedTeam } = useAppSelector(teamStateSelector);
  const role = user?.role;
  const isLeader = watch('role') === ROLES.LEADER;
  const toggleVisible = () => {
    reset();
    setMemberExists(false);
    setIsModalOpen(false);
  };

  const { teams, leaders } = useAppSelector(onboardingStateSelector);

  // Emails of all team members or leaders that are added so far
  const memberEmailsAdded = useMemo(() => {
    const membersAdded = teams.flatMap((t) => t.members).map((m) => m.email);
    membersAdded.push(...leaders.map((l) => l.email));
    return membersAdded;
  }, [teams, leaders]);

  const handleMemberEmailInput = async (value: string) => {
    const isEmail = String(value).toLowerCase().match(REGEX.CHECK_VALID_MAIL);
    if (isEmail) {
      setEmailCheckinProgress(true);
      const memberCheck = await checkUserExists(value && value.trim());
      setMemberExists(memberCheck.userExistsAlready);
      setEmailCheckinProgress(false);
    }
  };
  const totalMembers = subscriptionData?.totalLicenses;
  const activeMembers = subscriptionData?.activeLicenses as number;
  const newTotalMembers = activeMembers + (isOnBoarding ? memberEmailsAdded.length + 1 : 1);
  const totalAvailableLicenses =
    subscriptionData?.status === 'trialing'
      ? Math.min(TRAIL_LIMIT, totalMembers as number)
      : (totalMembers as number);
  const isLicenseAvailable = newTotalMembers <= totalAvailableLicenses;
  const notAvailable = useHasAccess([USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]) && !isLicenseAvailable;
  const {
    addMemberButton,
    addMemberRequestButton,
    addMemberCancelButton,
    addMemberEmailLabel,
    addMemberPlaceHolder,
    addMemberJobTitleLabel,
    addMemberJobTitlePlaceHolder,
    addMemberSelectRole,
    addMemberSelectRolePlaceHolder,
    addMemberNameLabel,
    addMemberNamePlaceHolder,
    addMemberTeamNameLabel,
    addMemberTeamNamePlaceHolder,
    memberRole,
    addMemberBillingButton,
    managerRole,
    leaderRole,
  } = addmember;
  const selectedteamName = watch('teamName');
  const navigate = useNavigate();
  const handleBilling = async () => {
    await navigate(`/settings?view=billing`);
  };
  const onSubmit: SubmitHandler<ADD_NEW_MEMBER_FORM_DATA> = (data) => {
    if (memberExists) return;
    setLoading(true);
    if (
      role === USHG_HQ_APP_ROLE.ENTERPRISE_LEADER ||
      role === USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER
    ) {
      const payload: AddTeamMemberRequest = {
        role: data.role,
        email: data.email,
        jobTitle: data.jobTitle,
        teamId: (teamId as number) ?? (selectedTeam.teamId as number),
      };
      toast.custom(
        (t) => (
          <Toast variant={'info'} Title={memberAdditionProcessingToastMessage} toastInstance={t} />
        ),
        {
          id: '1',
          duration: 3000,
        }
      );
      requestToAddTeamMember(payload)
        .then((data) => {
          if (data) {
            toast.custom(
              (t) => (
                <Toast
                  variant={'success'}
                  Title={leaderManagerSuccessToastMessage}
                  toastInstance={t}
                />
              ),
              { id: data.data.requestId as string, duration: 3000 }
            );
            setLoading(false);
            toggleVisible();
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.custom(
            (t) => (
              <Toast variant={'error'} Title={leaderManagerFailureToastMessage} toastInstance={t} />
            ),
            {
              id: error as string,
              duration: 3000,
            }
          );
        });
    } else {
      const payload: AdminAddTeamMemberArgs = isLeader
        ? {
            leader: {
              role: data.role,
              email: data.email,
              jobTitle: data.jobTitle,
            },
            isLeader: true,
          }
        : {
            role: data.role,
            email: data.email,
            jobTitle: data.jobTitle,
            isLeader: false,
            // use the teamName if passed in props else use team name from redux state first watch the teamName selected in the dropdown in all Members page
            teamName: isAllMembers
              ? selectedteamName
              : teamName ?? (selectedTeam.teamName as string),
            teamId: teamId,
          };

      adminAddTeamMember(payload)
        .then(async (data) => {
          if (data) {
            toast.custom(
              (t) => (
                <Toast
                  variant={'success'}
                  Title={memberAdditionSuccessToastMessage}
                  toastInstance={t}
                />
              ),
              { id: data.message, duration: 3000 }
            );
            setLoading(false);
            triggerSectionDataRefresh(SECTIONS.ADMIN_TEAM_MEMBER_LIST_TABLE, dispatch);
            toggleVisible();

            // Invalidate Subscription Details
            await queryClient.invalidateQueries({
              queryKey: [FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY],
            });

            // Invalidate All Members Query
            await queryClient.invalidateQueries({
              queryKey: [{ scope: 'organization', item: 'get-members' }],
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          let errorMessage: string;

          if (axios.isAxiosError(error)) {
            const data = error.response?.data;

            if (data && typeof data === 'object') {
              const { errorCode, errorMessage: apiErrorMessage } = data as USHGAPIError;

              switch (errorCode) {
                case ERROR_CODE.USER_ALREADY_EXIST: {
                  // TODO: Modify response structure such that we could use the emails in message
                  // that we get from cms
                  errorMessage = apiErrorMessage;
                }
              }
            }
          }

          toast.custom(
            (t) => (
              <Toast
                variant={'error'}
                Title={memberAdditionFailureToastMessage}
                SubTitle={errorMessage}
                toastInstance={t}
              />
            ),
            { id: error as string, duration: 3000 }
          );
        });
    }
  };

  const { toastMessages } = useStrapiOnBoardingData();
  const { addMemberFailureTitle, leaderAlreadyExist, memberAlreadyExist } = toastMessages;
  const formSubmitHandler: SubmitHandler<ADD_NEW_MEMBER_FORM_DATA | any> = async (data: Member) => {
    if (INVITEE === INVITEE_ROLE.MEMBER) {
      const exists = memberEmailsAdded.some((memberEmail) => memberEmail === data.email);

      if (!exists) {
        dispatch(
          addMemberToTeam({
            index: currentIndex as number,
            member: data,
          })
        );
        toggleVisible();
      } else {
        toast.custom(
          (t) => (
            <Toast
              variant={'error'}
              Title={addMemberFailureTitle}
              SubTitle={memberAlreadyExist}
              toastInstance={t}
            />
          ),
          {
            duration: 3000,
          }
        );
      }
    }
    if (INVITEE === INVITEE_ROLE.LEADER) {
      const leaderExists = memberEmailsAdded.some((memberEmail) => memberEmail === data.email);

      if (!leaderExists) {
        dispatch(addNewLeader(data));
        toggleVisible();
      } else {
        toast.custom(
          (t) => (
            <Toast
              variant={'error'}
              Title={addMemberFailureTitle}
              SubTitle={leaderAlreadyExist}
              toastInstance={t}
            />
          ),
          { duration: 3000 }
        );
      }
    }
  };
  const team = useEnterpriseAdminAllTeamsQuery(isAllMembers);
  const teamList = team.data;
  const finalTeamList =
    teamList?.map((value) => {
      return { label: value.teamName, value: value.teamName };
    }) ?? [];

  const DROPDOWN_MAP: { [key: string]: string[] } = {
    LEADER: [ROLES.LEADER],
    MEMBER_MANAGER: [ROLES.LEANER, ROLES.MANAGER],
    ALL_MEMBERS: [ROLES.LEANER, ROLES.MANAGER, ROLES.LEADER],
  };
  const LABEL_MAP: { [key: string]: string } = {
    User: memberRole,
    Manager: managerRole,
    Leader: leaderRole,
  };
  const dropDownOptions = DROPDOWN_MAP[ROLE_OPTIONS].map((role: string) => {
    return {
      label: LABEL_MAP[role],
      value: role,
    };
  });
  const rolesInDropDownOptions: string[] = dropDownOptions.map((role) => role.value);
  const selectedRole = watch('role');

  const rolePlaceHolder = [
    {
      label: addMemberSelectRolePlaceHolder,
      value: '',
    },
  ];
  const teamPlaceHolder = [
    {
      label: addMemberTeamNamePlaceHolder,
      value: '',
    },
  ];
  const teamDropDown = teamPlaceHolder.concat(...finalTeamList);
  const dropDown = rolePlaceHolder.concat(dropDownOptions);
  const formSubmit = isOnBoarding ? formSubmitHandler : onSubmit;
  return (
    <div>
      <form className=" w-full">
        <div className="-mx-3 mb-6 flex flex-wrap">
          {isAllMembers && (
            <div className="mb-1 w-full px-3 py-2">
              <Label
                htmlFor="new_member_email"
                label={addMemberNameLabel}
                className="required"
              ></Label>
              <div className="relative">
                <TextInput
                  className="block h-9 w-full appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900 px-4 py-3 leading-tight text-gray-50 placeholder:text-xs"
                  id="new_member_name"
                  type="text"
                  placeholder={addMemberNamePlaceHolder}
                  aria-invalid={fieldHasError(errors, 'name') ? 'true' : 'false'}
                  hasError={fieldHasError(errors, 'name')}
                  {...register('name')}
                />
              </div>
              <HookFormErrorMessage
                name="name"
                errors={errors}
                render={({ message }) => <ErrorMessage message={teamsErrorMessages[message]} />}
              />
            </div>
          )}
          <div className="w-full px-3">
            <Label
              label={addMemberEmailLabel}
              htmlFor="new_member_email"
              className="required"
            ></Label>
            <div className="relative">
              <TextInput
                className="block h-9 w-full appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900 px-4 py-3 leading-tight text-gray-50 placeholder:text-xs"
                id="new_member_email"
                type="text"
                placeholder={addMemberPlaceHolder}
                aria-invalid={fieldHasError(errors, 'email') ? 'true' : 'false'}
                hasError={fieldHasError(errors, 'email')}
                {...register('email', {
                  onChange: (e) => handleMemberEmailInput(e.target.value as string),
                })}
              />
              {isEmailCheckinProgress ? (
                <div className="absolute right-2 top-2">
                  <BasicSpinner className="leading-[14px] text-zinc-100" />
                </div>
              ) : null}
            </div>
            <HookFormErrorMessage
              name="email"
              errors={errors}
              render={({ message }) => <ErrorMessage message={teamsErrorMessages[message]} />}
            />
          </div>
          <div className="mt-4 w-full px-3">
            <Label
              label={addMemberJobTitleLabel}
              htmlFor="new_member_job_title"
              className="required"
            ></Label>
            <TextInput
              className="block h-9 w-full appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900 px-4 py-3 leading-tight text-gray-50 placeholder:text-xs"
              id="new_member_job_title"
              type="text"
              placeholder={addMemberJobTitlePlaceHolder}
              aria-invalid={fieldHasError(errors, 'jobTitle') ? 'true' : 'false'}
              hasError={fieldHasError(errors, 'jobTitle')}
              {...register('jobTitle')}
            />
            <HookFormErrorMessage
              name="jobTitle"
              errors={errors}
              render={({ message }) => <ErrorMessage message={teamsErrorMessages[message]} />}
            />
          </div>
          <div className="mt-5 flex w-full space-x-3 px-3">
            <div className="flex w-full flex-col gap-0.5">
              <div className="flex flex-row items-center gap-1">
                <Label htmlFor="new_member_job_role" label={addMemberSelectRole}></Label>
                <IconWithModal
                  icon={<Question size="14px" className="flex items-start text-zinc-400" />}
                  content={<RoleDescription roles={rolesInDropDownOptions} />}
                  triggerClassName="max-w-max px-0.5"
                  containerClassName="max-h-[80vh] overflow-y-auto z-[100]"
                  overlayClassName={'z-[80] bg-black/40'}
                />
                <div className="required !text-xs"></div>
              </div>
              <DropdownInput
                id="new_member_job_role"
                type={'select'}
                className="grow-0"
                options={dropDown}
                defaultValue={dropDown[0].label}
                aria-invalid={fieldHasError(errors, 'role') ? 'true' : 'false'}
                hasError={fieldHasError(errors, 'role')}
                {...register('role')}
              />
              <HookFormErrorMessage
                name="role"
                errors={errors}
                render={({ message }) => <ErrorMessage message={teamsErrorMessages[message]} />}
              />
            </div>
            {isAllMembers && selectedRole !== 'Leader' && (
              <div className="flex w-full flex-col gap-0.5">
                <Label
                  htmlFor="new_member_team"
                  className="required"
                  label={addMemberTeamNameLabel}
                ></Label>
                <DropdownInput
                  id="new_member_team"
                  className="grow-0"
                  type={'select'}
                  options={teamDropDown}
                  aria-invalid={fieldHasError(errors, 'teamName') ? 'true' : 'false'}
                  hasError={fieldHasError(errors, 'teamName')}
                  {...register('teamName', {
                    deps: ['role'],
                  })}
                />
                <HookFormErrorMessage
                  name="teamName"
                  errors={errors}
                  render={({ message }) => <ErrorMessage message={teamsErrorMessages[message]} />}
                />
              </div>
            )}
          </div>
        </div>
        {memberExists && (
          <Alert
            variant="warning"
            message={commomTeamErrorMessage.memberAlreadyExistsMessage}
            className="mb-6"
          />
        )}
        {notAvailable && subscriptionData && (
          <div className="pb-6">
            <SubscriptionInfo
              existingMemberCount={totalMembers as number}
              newMemberCount={newTotalMembers}
              isOnBoarding={isOnBoarding}
            />
          </div>
        )}

        <div className="flex flex-col items-center justify-end gap-2 text-sm font-medium xs:flex-row">
          <Dialog.Close className="p-[10px_30px] text-zinc-50">
            {addMemberCancelButton}
          </Dialog.Close>
          {!notAvailable && (
            <button
              type="submit"
              onClick={handleSubmit(formSubmit)}
              disabled={isLoading || isEmailCheckinProgress || memberExists || isLoadingOnboarding}
              className="flex gap-2 rounded bg-base-brand p-[10px_30px] normal-case text-white disabled:opacity-80"
            >
              <HasAccess
                roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEADER, USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER]}
                Fallback={null}
              >
                {addMemberRequestButton}
              </HasAccess>
              <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]} Fallback={null}>
                {addMemberButton}
              </HasAccess>
              {isLoading ? <BasicSpinner className="!m-0 text-zinc-100" /> : null}
            </button>
          )}

          {notAvailable && !isOnBoarding && (
            <Button
              type="button"
              onClick={() => handleBilling()}
              className="btn inline-block h-[40px] !rounded bg-base-brand px-6 py-2 text-sm font-medium normal-case leading-tight text-white hover:bg-base-brand"
            >
              {addMemberBillingButton}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
}

export default AddNewTeamMemberModal;
