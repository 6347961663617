import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { queryClient } from '../../../config/react-query';
import { USHGAPIError } from '../../../types';
import { FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY } from '../../shared/constants';
import { activateEnterpriseUser, ActivateEnterpriseUserArgs } from '../api';
import { ERROR_CODE } from '../constants';
import useStrapiMembersData from './useStrapiMembersData';

/**
 * Wrapper around useMutation to activate enterprise user
 */
interface props {
  teamId?: number;
}
const useActivateEnterpriseUserMutation = ({ teamId }: props) => {
  const {
    toastMessage: {
      activateSuccess,
      activateFailure,
      userAlreadyActive,
      activateSuccessMessage,
      activateFailureMessage,
      subscriptionLimitOverMessage,
      trialLimitOverMessage,
    },
  } = useStrapiMembersData();
  const mutation = useMutation({
    mutationFn: async (args: ActivateEnterpriseUserArgs) => {
      const response = await activateEnterpriseUser(args);
      return response;
    },
    onSuccess: (_, variables) => {
      const substitutedActivateSuccessMessage = activateSuccessMessage?.replace(
        '{{username}}',
        variables.username
      );

      toast.custom(
        (t) => {
          return (
            <Toast
              Title={activateSuccess}
              SubTitle={substitutedActivateSuccessMessage}
              toastInstance={t}
              variant="success"
            />
          );
        },
        {
          duration: 3000,
        }
      );

      // Invalidate the cache for get-members
      queryClient.invalidateQueries({
        queryKey: [
          {
            scope: 'organization',
            item: 'get-members',
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          {
            scope: 'teams',
            items: 'TeamMembers',
            teamId: teamId,
          },
        ],
      });
      queryClient.invalidateQueries([FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY]);
    },
    onError: (error, variables) => {
      let errorMessage = activateFailureMessage;

      if (axios.isAxiosError(error)) {
        const { errorCode } = error.response?.data as unknown as USHGAPIError;

        switch (errorCode) {
          case ERROR_CODE.USER_ALREADY_IN_SAME_STATE: {
            errorMessage = userAlreadyActive;
            break;
          }
          case ERROR_CODE.TRIAL_LIMIT_EXCEEDED: {
            errorMessage = trialLimitOverMessage;
            break;
          }
          case ERROR_CODE.SUBSCRIPTION_LICENSES_EXCEEDS: {
            errorMessage = subscriptionLimitOverMessage;
            break;
          }
        }
      }

      errorMessage = errorMessage?.replace('{{username}}', variables.username);

      toast.custom(
        (t) => {
          return (
            <Toast
              Title={activateFailure}
              SubTitle={errorMessage}
              toastInstance={t}
              variant="error"
            />
          );
        },
        {
          duration: 3000,
        }
      );
    },
  });

  return mutation;
};

export default useActivateEnterpriseUserMutation;
