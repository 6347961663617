import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import SideBarProfileMenu from './SideBarProfileMenu';
import SideBarProfileTrigger from './SideBarProfileTrigger';
import SideBarProfileDialog, { type SideBarProfileDialogType } from './SideBarProfileDialog';
import { useState } from 'react';

const SideBarProfile = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<SideBarProfileDialogType | null>(null);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <SideBarProfileTrigger />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="isolate z-20 mx-2 flex w-44 flex-col items-start rounded-md drop-shadow-[0px_8px_20px_rgba(0,0,0,0.1)] xl:w-52 2xl:w-60">
          <SideBarProfileMenu setDialogType={setDialogType} setIsDialogOpen={setIsDialogOpen} />
          <DropdownMenu.Arrow className="fill-zinc-700" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
      {/* Dialog */}
      <SideBarProfileDialog isOpen={isDialogOpen} setIsOpen={setIsDialogOpen} type={dialogType} />
    </DropdownMenu.Root>
  );
};

export default SideBarProfile;
