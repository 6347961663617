import { Row } from '@tanstack/react-table';
import { Team } from '../../../types/interface';
import { ArrowElbowDownRight } from 'phosphor-react';

interface Props {
  teamName: string;
  row: Row<Team>;
}

const TeamNameCell = ({ teamName, row }: Props) => {
  const isSubRow = row.depth >= 1;
  return (
    <div className="flex space-x-1.5">
      {isSubRow && <ArrowElbowDownRight size={14} className="text-zinc-500" weight="bold" />}
      <span className="whitespace-nowrap">{teamName}</span>
    </div>
  );
};

export default TeamNameCell;
