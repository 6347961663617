import clsx from 'clsx';
import { format, isSameMonth, isToday } from 'date-fns';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { CalendarItem as CalendarItemType } from '../../../types';
import { CalendarItemDot } from '../common';
import { getLocale } from '../../../../../utils';
import { useLanguage } from '../../../../shared/context/languageContext';

interface Props {
  calendarItems: CalendarItemType[];
  date: Date;
  viewing: Date;
}

const CalendarDayTileContent = (props: Props) => {
  const { calendarItems, date, viewing } = props;

  const hasEvents = calendarItems.length > 0;

  const { isTodaysTile, isThisMonthsTile } = useMemo(() => {
    const isTodaysTile = isToday(date);
    const isThisMonthsTile = isSameMonth(date, viewing);
    return { isTodaysTile, isThisMonthsTile };
  }, [date, viewing]);
  const { language } = useLanguage();

  return (
    <>
      <span
        className={twMerge(
          clsx(
            'flex h-full w-full items-center justify-center rounded-[7.1px] px-[7.25px] py-[5.25px] text-[11.3px] font-normal leading-[18px]',
            isTodaysTile && 'bg-base-brand text-white',
            isThisMonthsTile ? 'text-zinc-200' : 'text-zinc-400',
            !isTodaysTile && hasEvents && 'hover:bg-base-brand/10',
            hasEvents && 'cursor-pointer'
          )
        )}
      >
        {format(date, 'd', { locale: getLocale(language) })}
      </span>
      <div className="flex h-1.5 flex-row justify-center gap-1">
        {calendarItems.slice(0, 2).map((calendarItem) => (
          <CalendarItemDot key={calendarItem.id} calendarItemType={calendarItem.calendarItemType} />
        ))}
      </div>
    </>
  );
};

export default CalendarDayTileContent;
