import { useQuery } from '@tanstack/react-query';
import { getCourseDetails } from '../api/courses';
import { SINGLE_COURSE_STALE_TIME } from '../components/SingleCourseView/constants/constants';
import { useEffect, useState } from 'react';

interface Props {
  id: string | undefined;
}

const useCourseDetailsQuery = ({ id }: Props) => {
  const [errorCode, setErrorCode] = useState(null);

  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery(
      ['courses', 'single-course', id],
      async () => {
        return getCourseDetails(`${id}`);
      },
      {
        enabled: id !== undefined,
        onError: (error) => {
          console.error('Error while fetching courses %o', error);
          // Use type assertion to tell TypeScript that 'error' may have a 'response' property
          const errorResponseData = (error as any)?.response?.data;

          const parsedErrorCode =
            errorResponseData?.errorMessage?.message &&
            JSON.parse(errorResponseData.errorMessage.message)?.errorCode;
            
          // Set the errorCode state
          setErrorCode(parsedErrorCode);
        },
        staleTime: SINGLE_COURSE_STALE_TIME,
      }
    );

  // Use useEffect to ensure the errorCode is updated after the asynchronous operation
  useEffect(() => {
    // You can perform additional actions when errorCode changes if needed
    console.log('Effect: errorCode changed to', errorCode);
  }, [errorCode]);

  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, errorCode, refetch };
};

export default useCourseDetailsQuery;
