import clsx from 'clsx';
import Dialog from '../../../components/Dialog';
import BadgeDetails from './BadgeDetails/BadgeDetails';
import BadgeItem from './BadgeItem';
import InProgressBadgeItem from './InProgressBadgeItem';

interface Props {
  data: any[];
  isDashboardView?: boolean;
  showIfHasProgress?: boolean;
}

const BadgeComponent = ({ data, isDashboardView = false, showIfHasProgress = false }: Props) => {
  return (
    <>
      <div
        className={clsx(
          'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3',
          isDashboardView && 'opacity-60'
        )}
      >
        {data.map((value) => {
          return (
            <Dialog
              key={value.badgeId}
              Content={<BadgeDetails badgeData={value} />}
              containerClassName="max-w-lg"
            >
              {!(showIfHasProgress && value.percentageCompleted !== null) ? (
                <BadgeItem
                  key={value.badgeId}
                  id={value.badgeId}
                  profile={value.imageUrl}
                  name={value.badgeName}
                  description={value.badgeDescription}
                  roundedImage={true}
                  classNameImage={value.percentageCompleted === null ? 'opacity-40' : ''}
                />
              ) : (
                <InProgressBadgeItem
                  key={value.badgeId}
                  id={value.badgeId}
                  profile={value.imageUrl}
                  name={value.badgeName}
                  description={value.badgeDescription}
                  roundedImage={true}
                  percentageCompleted={value.percentageCompleted}
                />
              )}
            </Dialog>
          );
        })}
      </div>
    </>
  );
};
export default BadgeComponent;
