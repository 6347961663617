import { Users } from 'phosphor-react';
import SideBar from '../../../../../components/SideBar';
import { useStrapiSideNavData } from '../../../../../hooks/useStrapiSideNavData';
import { CustomSideNavItemProps } from '../../../types';

const MyTeamsNavItem = ({ eventsCount }: React.PropsWithChildren<CustomSideNavItemProps>) => {
  const { routes } = useStrapiSideNavData();
  return (
    <SideBar.SideBarNavItem
      icon={
        <Users aria-label="My Team" size="20px" color="currentColor" className="text-inherit" />
      }
      label={routes.myTeamLabel}
      path={`/my-team`}
      eventsCount={eventsCount}
    ></SideBar.SideBarNavItem>
  );
};

export default MyTeamsNavItem;
