const userTypes = {
  MANAGER: 'manager',
  MEMBERS: 'members',
};
export const { MANAGER, MEMBERS } = userTypes;

export const USER_ROLE_DISPLAY_NAME_MAP: { [key: string]: string } = {
  User: 'Learner',
  Manager: 'Manager',
  Leader: 'Leader',
  Admin: 'Admin',
  INDIVIDUAL: 'Individual',
  USHG: 'Ushg Admin',
};

export const COGNITO_USER_ROLES = {
  LEADER: 'ENTERPRISE_LEADER',
  MANAGER: 'ENTERPRISE_MANAGER',
};
