import { useState } from 'react';
import Dialog from '../../../../../../../components/Dialog';
import { BasicSpinner } from '../../../../../../../components/Spinners';
import cn from '../../../../../../../utils/cn';
import { useGetMaintenanceModeQuery } from '../../../../../hooks';
import NewFeatureLabel from '../NewFeatureLabel';
import * as Switch from '@radix-ui/react-switch';
import UpdateMaintenanceDialogContent, {
  type UpdateAction,
} from './UpdateMaintenanceDialogContent';
import { ArrowClockwise } from 'phosphor-react';
import { useStrapiSettingsData } from '../../../../../hooks/useStrapiSettingsData';

type Props = {
  className?: string;
};

const MaintenanceMode = (props: Props) => {
  const { className } = props;

  // state
  const [showDialog, setShowDialog] = useState(false);
  const [updateAction, setUpdateAction] = useState<UpdateAction | null>(null);
  // state

  // queries
  const maintenanceModeQuery = useGetMaintenanceModeQuery();
  // queries

  // strapi content
  const { hqAdminPlatformMaintenanceMode: maintenanceModeCMS } = useStrapiSettingsData();
  // strapi content

  // handlers
  const refetchMaintenanceMode = async () => {
    await maintenanceModeQuery.refetch();
  };
  // handlers

  return (
    <>
      <div
        className={cn('flex w-full items-start gap-2 rounded-sm bg-card-bg p-4 sm:p-6', className)}
      >
        <div className="flex flex-col items-start gap-0.5">
          <div className="flex items-start gap-2 sm:gap-3">
            <h2 className="text-lg font-semibold text-zinc-50 sm:text-xl sm:leading-8">
              {maintenanceModeCMS.title}
            </h2>
            {maintenanceModeCMS.showIsNewFeature && (
              <div className="flex h-7 shrink-0 items-center sm:h-8">
                <NewFeatureLabel />
              </div>
            )}
          </div>
          <p className="text-sm font-normal text-zinc-500">{maintenanceModeCMS.description}</p>
          {/* Error */}
          {maintenanceModeQuery.error ? (
            <p className="text-sm font-normal text-red-400">
              {maintenanceModeCMS.maintenanceModeFetchError}
            </p>
          ) : null}
        </div>
        <>
          {/* Loading */}
          {(maintenanceModeQuery.isLoading || maintenanceModeQuery.isRefetching) && (
            <div className="flex h-7 w-9 items-center justify-center sm:h-8">
              <BasicSpinner className="!m-0 text-white" />
            </div>
          )}
          {/* Data */}
          {maintenanceModeQuery.data && (
            <div className="flex h-7 items-center sm:h-8">
              <Switch.Root
                aria-label={maintenanceModeCMS.maintenanceModeSwitchAriaLabel}
                checked={maintenanceModeQuery.data.Enabled}
                onCheckedChange={async (checked) => {
                  const action = checked ? 'Enable' : 'Disable';
                  setUpdateAction(action);
                  setShowDialog(true);
                }}
                className={cn(
                  'relative flex h-5 w-9 items-center rounded-full px-[2.5px] shadow-page-bg-dark duration-300 hover:shadow-[0px_0px_0px_2px]',
                  'data-[state="checked"]:bg-base-brand data-[state="checked"]:shadow-base-brand',
                  'data-[state="unchecked"]:bg-page-bg-dark'
                )}
              >
                <Switch.Thumb
                  className={cn(
                    'block h-4 w-4 rounded-full',
                    'transition-transform duration-500 data-[state="checked"]:translate-x-[15px] data-[state="checked"]:bg-zinc-50',
                    'data-[state="unchecked"]:bg-card-bg'
                  )}
                />
              </Switch.Root>
            </div>
          )}
          {/* Error */}
          {maintenanceModeQuery.error && (
            <button
              className="flex h-7 w-9 items-center justify-center sm:h-8"
              onClick={refetchMaintenanceMode}
            >
              <ArrowClockwise className="text-red-400" color="currentColor" size={18} />
            </button>
          )}
        </>
      </div>
      {/* Dialog */}
      <Dialog
        open={showDialog}
        onOpenChange={(open) => setShowDialog(open)}
        Content={
          <UpdateMaintenanceDialogContent
            action={updateAction as UpdateAction}
            setShowDialog={setShowDialog}
          />
        }
        containerClassName="max-h-[80vh] overflow-y-auto px-2"
        onInteractOutside={(event) => {
          event.preventDefault();
        }}
      ></Dialog>
    </>
  );
};

export default MaintenanceMode;
