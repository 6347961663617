import { ChartData } from 'chart.js';
import clsx from 'clsx';
import { useContext, useMemo, useState } from 'react';
import {
  defaultFontOptions,
  defaultGridOptions,
  defaultLabelOptions,
  defaultTickOptions,
  defaultTitleOptions,
} from '../../config';
import { BarChartOptions, DashboardReportsFilter } from '../../types';
import ChartHeading from './ChartHeading';
import { BarChart } from './baseCharts';
import durationContext from '../../context/durationContext';
import { useCourseCompletedAssignedStatsQuery } from '../../hooks';
import { CourseCompletedAssignedStats, ReportTooltipDataType } from '../../types/reports/reports';
import { ErrorDisplay } from '../../../../components/DataTables';
import NoDataFallback from '../NoDataFallback';
import { REPORTS_INTERVAL, REPORT_TYPES } from '../../constants';
import { InView } from 'react-intersection-observer';
import { useStrapiReportsData } from '../../hooks/useStrapiReportData';
import { useParams } from 'react-router-dom';
import { chartJsLabel } from '../../helpers';
import ScaleLoader from '../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../components/ReportToolTip';
import { useLanguage } from '../../context/languageContext';

interface Props {
  className?: string;
  heading?: string;
  lazyLoad?: boolean;
  teamId?: string;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}

const CourseCompletedAssignedStatsViz = ({
  className,
  heading,
  lazyLoad = true,
  teamId,
  reportTooltipContent,
  userPersona,
}: Props) => {
  const { id } = useParams();
  const { language } = useLanguage();
  const { duration } = useContext(durationContext);
  const { key1, key2, emptyChart, yAxisLabel, errorChart } =
    useStrapiReportsData().CourseAssignedOrCompleted;
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);

  // Build the filter
  let filters: Partial<DashboardReportsFilter> = useMemo(() => {
    return {
      fromDate: duration.fromDate,
      interval: duration.interval as keyof typeof REPORTS_INTERVAL,
      teamId: teamId && teamId !== '1' ? teamId : undefined,
    };
  }, [duration, teamId]);

  // include username in filter if exists in route path
  if (id) {
    filters = { ...filters, username: id };
  }

  const courseCompletedAssignedStats = useCourseCompletedAssignedStatsQuery({
    filters,
    isEnabled: isInView,
  });

  const hasCourseCompletedAssignedStats =
    courseCompletedAssignedStats.data &&
    courseCompletedAssignedStats.data.results &&
    courseCompletedAssignedStats.data.results.length > 0;

  const hasNoCourseCompletedAssignedStats =
    courseCompletedAssignedStats.data &&
    courseCompletedAssignedStats.data.results &&
    courseCompletedAssignedStats.data.results.length === 0;

  const isLoadingCourseCompletedAssignedStats = courseCompletedAssignedStats.isLoading;

  const data = useMemo(() => {
    let labels: string[] = [];

    // Total Assigned Courses
    let assignedCourses: number[] = [];

    // Total Assigned Courses Completed
    let assignedCoursesCompleted: number[] = [];

    if (hasCourseCompletedAssignedStats) {
      // Chartjs labels
      // Chartjs labels
      labels = courseCompletedAssignedStats.data?.results.map(
        (result: CourseCompletedAssignedStats) =>
          chartJsLabel(duration.interval, result.pool, language)
      );

      // Total Assigned Courses
      assignedCourses = courseCompletedAssignedStats.data?.results.map(
        (result: CourseCompletedAssignedStats) => +result.totalAssignedCourses
      );

      // Total Assigned Courses Completed
      assignedCoursesCompleted = courseCompletedAssignedStats.data?.results.map(
        (result: CourseCompletedAssignedStats) => +result.totalAssignedCoursesCompleted
      );
    }
    const data: ChartData<'bar', number[], unknown> = {
      labels,
      datasets: [
        {
          label: key1,
          data: assignedCoursesCompleted || [],
          backgroundColor: '#37990E',
        },
        {
          label: key2,
          data: assignedCourses || [],
          backgroundColor: '#F7634F',
        },
      ],
    };

    return data;
  }, [hasCourseCompletedAssignedStats, courseCompletedAssignedStats.data, duration]);

  // Chartjs options

  const chartOptions: BarChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    datasets: {
      bar: {
        barThickness: 13,
      },
    },
    scales: {
      x: {
        title: {
          ...defaultTitleOptions,
          text: duration.xAxis,
        },
        grid: {
          ...defaultGridOptions,
          borderDash: [],
        },
        ticks: {
          ...defaultTickOptions,
          autoSkip: true,
          padding: 8,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          ...defaultTitleOptions,
          text: yAxisLabel,
        },
        grid: {
          ...defaultGridOptions,
          borderDash: [],
        },
        ticks: {
          ...defaultTickOptions,
          precision: 0,
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          ...defaultLabelOptions,
          font: {
            ...defaultFontOptions,
          },
        },
      },
      tooltip: {
        intersect: false,
      },
    },
  };

  return (
    <InView
      as="div"
      className={clsx(
        'flex h-full min-h-[420px] flex-col gap-5 rounded-lg border border-zinc-700 bg-card-bg pt-4 pb-7',
        className
      )}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div className="flex items-center gap-2 px-4">
        <ChartHeading heading={heading as string} />
        {reportTooltipContent?.[REPORT_TYPES.COURSE_COMPLETED_ASSIGNED_STATISTICS]?.[
          userPersona
        ] && (
          <ReportTooltip
            content={
              reportTooltipContent[REPORT_TYPES.COURSE_COMPLETED_ASSIGNED_STATISTICS][userPersona]
            }
          />
        )}
      </div>
      <hr className="h-px border-0 bg-gray-700" />
      {isLoadingCourseCompletedAssignedStats && !hasCourseCompletedAssignedStats && (
        <div className="flex grow items-center justify-center">
          <ScaleLoader />
        </div>
      )}
      {/* Error State */}
      <>
        {courseCompletedAssignedStats.error && !courseCompletedAssignedStats.data && (
          <div className="flex grow items-center justify-center">
            <ErrorDisplay
              message={errorChart}
              refetch={courseCompletedAssignedStats.refetch}
              allowsRefetch={true}
              isRefetching={courseCompletedAssignedStats.isRefetching}
            />
          </div>
        )}
      </>
      {hasCourseCompletedAssignedStats && (
        <BarChart
          options={chartOptions}
          className="m-auto max-h-80 min-h-[20rem] w-full px-8 pt-8"
          data={data}
        />
      )}
      {hasNoCourseCompletedAssignedStats && !isLoadingCourseCompletedAssignedStats && (
        <NoDataFallback message={emptyChart} />
      )}
    </InView>
  );
};

export default CourseCompletedAssignedStatsViz;
