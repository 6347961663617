import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENT_ADMIN_SORT_DEFAULT } from '../constants/enterpriseAdmin';
import { SECTIONS } from '../constants/section-name';
import { Team } from '../types/interface';

type SectionDataRefreshPayload = {
  [key in SECTIONS]?: boolean;
};

interface TeamSliceState {
  allTeams: Team[];
  selectedTeam: Team;
  isAddNewTeamModalOpen: boolean;
  isAddNewTeamMemberModalOpen: boolean;
  isEditTeamModalOpen: boolean;
  isAddSubTeamModalOpen: boolean;
  isAddTeamModalOpen: boolean;
  isAddLeaderModalOpen: boolean;
  sortKey: string;
  sortOrder: string | boolean;
  dataRefreshState: SectionDataRefreshPayload;
}

const initialState: TeamSliceState = {
  allTeams: [],
  selectedTeam: {},
  isAddNewTeamModalOpen: false,
  isAddNewTeamMemberModalOpen: false,
  isEditTeamModalOpen: false,
  isAddSubTeamModalOpen: false,
  isAddTeamModalOpen: false,
  isAddLeaderModalOpen: false,
  dataRefreshState: {},
  sortKey: '',
  sortOrder: ENT_ADMIN_SORT_DEFAULT,
};

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    toggleAddNewTeamModal: (state) => {
      state.isAddNewTeamModalOpen = !state.isAddNewTeamModalOpen;
    },
    toggleAddNewTeamMemberModal: (state) => {
      state.isAddNewTeamMemberModalOpen = !state.isAddNewTeamMemberModalOpen;
    },
    toggleEditTeamModal: (state) => {
      state.isEditTeamModalOpen = !state.isEditTeamModalOpen;
    },
    toggleAddSubTeamModal: (state) => {
      state.isAddSubTeamModalOpen = !state.isAddSubTeamModalOpen;
    },
    toggleOnboardingAddTeamModal: (state) => {
      state.isAddTeamModalOpen = !state.isAddTeamModalOpen;
    },
    toggleOnboardingAddLeaderModal: (state) => {
      state.isAddLeaderModalOpen = !state.isAddLeaderModalOpen;
    },
    setSelectedTeam: (state, event) => {
      state.selectedTeam = event.payload;
    },
    loadTeams: () => {
      return initialState;
    },
    toggleSortKey: (state, action: PayloadAction<any>) => {
      state.sortKey = action.payload;
    },
    toggleSortOrder: (state, action: PayloadAction<any>) => {
      state.sortOrder = action.payload;
    },
    resetSort: (state) => {
      state.sortKey = initialState.sortKey;
      state.sortOrder = initialState.sortOrder;
    },
    triggerDataRefresh: (state, action: PayloadAction<SectionDataRefreshPayload>) => {
      state.dataRefreshState = {
        ...state.dataRefreshState,
        ...action.payload,
      };
    },
  },
});

export const {
  toggleAddNewTeamModal,
  toggleAddNewTeamMemberModal,
  toggleEditTeamModal,
  toggleAddSubTeamModal,
  toggleOnboardingAddTeamModal,
  toggleOnboardingAddLeaderModal,
  setSelectedTeam,
  loadTeams,
  toggleSortKey,
  toggleSortOrder,
  resetSort,
  triggerDataRefresh,
} = teamSlice.actions;

export default teamSlice.reducer;
