import { FieldErrorsImpl } from 'react-hook-form';
import Alert from '../../../../../components/Alert';
import { useAppSelector } from '../../../../../hooks';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';

interface Props {
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

const RegistrationErrorAlert = ({ errors }: Props) => {
  const data: any = useStrapiDataHelper();
  // const strapiAPIError = data?.apiErrors.data;
  const strapiErrorMessages = data?.errormessage?.data?.attributes;
  const errorcode = useAppSelector((state) => state.register.errorCode);
  const { emailAlreadyExists, companyAlreadyExists } = strapiErrorMessages;
  const { contactUsEmailSubject } = data.registration.data.attributes;
  const { contactUsEmail } = useStrapiSharedData();
  const contactUsClick = () => {
    window.location.href = `mailto:${contactUsEmail}?subject=${contactUsEmailSubject}`;
  };
  const errorMessage = data.errorsMap[errorcode];
  const errorActionContent = data.errorActionContentMap[errorcode];
  if (
    errors?.personalEmail?.message === emailAlreadyExists ||
    errors?.corporateEmail?.message === emailAlreadyExists
  ) {
    return (
      <Alert
        variant="error"
        message={errorMessage}
        action={{
          content: errorActionContent,
          onAction: contactUsClick,
        }}
      ></Alert>
    );
  }
  if (errors?.department?.message === companyAlreadyExists) {
    return (
      <Alert
        variant="error"
        message={errorMessage}
        action={{
          content: errorActionContent,
          onAction: contactUsClick,
        }}
      ></Alert>
    );
  }
  return null;
};

export default RegistrationErrorAlert;
