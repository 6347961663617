import { ArrowClockwise } from 'phosphor-react';
import ConditionalLink from '../../../../../../components/ConditionalLink';
import { BasicSpinner } from '../../../../../../components/Spinners';
import useCourseDetailsQuery from '../../../../../courses/hooks/useCourseDetailsQuery';
import { useStrapiDashboardData } from '../../../../../shared/hooks';
import { CalendarItem, CalendarItemCourseDueContent } from '../../../../types';
import BaseCalendarItem from '../BaseCalendarItem';

interface Props {
  calendarItem: CalendarItem;
}

const CourseDueCalendarItem = (props: Props) => {
  const { calendarItem } = props;
  const {
    calendar: { deadlineLabel, errorLoadingDetails },
  } = useStrapiDashboardData();
  const calendarItemContent = calendarItem.content as CalendarItemCourseDueContent;

  const courseBasicDetailsQuery = useCourseDetailsQuery({
    id: `${calendarItemContent.courseId}`,
  });

  // Loading
  if (courseBasicDetailsQuery.isLoading || courseBasicDetailsQuery.isRefetching) {
    return (
      <div className="flex items-center gap-1">
        <BaseCalendarItem calendarItemType={calendarItem.calendarItemType}>
          <span>{deadlineLabel}: </span>
        </BaseCalendarItem>
        <BasicSpinner className="!m-0 aspect-square h-4 w-4 text-zinc-200" />
      </div>
    );
  }

  // Error
  if (courseBasicDetailsQuery.isError) {
    return (
      <div className="flex items-center gap-1">
        <BaseCalendarItem calendarItemType={calendarItem.calendarItemType}>
          <span>{deadlineLabel}:</span>
        </BaseCalendarItem>
        <span className="text-sm text-rose-200">{errorLoadingDetails}</span>
        <ArrowClockwise
          className="cursor-pointer text-zinc-200"
          color="currentColor"
          onClick={(e) => {
            e.preventDefault();
            courseBasicDetailsQuery.refetch();
          }}
        />
      </div>
    );
  }

  const coursePath = `/courses/${calendarItemContent.courseId}`;

  return (
    <BaseCalendarItem calendarItemType={calendarItem.calendarItemType}>
      <ConditionalLink
        renderLink={!!calendarItemContent.courseId}
        to={coursePath}
        className="line-clamp-2"
        target="_blank"
      >
        {`${deadlineLabel}: ${courseBasicDetailsQuery.data?.name}`}
      </ConditionalLink>
    </BaseCalendarItem>
  );
};

export default CourseDueCalendarItem;
