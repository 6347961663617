import clsx from 'clsx';
import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterDropDown } from '../../../../../../components/DataTables';
import SearchInput from '../../../../../../components/SearchInput';
import type { Option } from '../../../../../../components/Select';
import { useStrapiSharedData } from '../../../../../shared/hooks/useStrapiSharedData';
import {
  ALL_MEMBERS_TABLE_SEARCH_QUERY_PARAM,
  ALL_MEMBERS_TABLE_USER_STATUS_QUERY_PARAM,
  USER_STATUS,
} from '../../../../constants';
import useStrapiMembersData from '../../../../hooks/useStrapiMembersData';
interface Props {
  className?: string;
  searchQuery: string | null;
  userStatus: keyof typeof USER_STATUS | null;
  isFilterApplied: boolean;
}

const AllMembersTableHeader = (props: Props) => {
  const { className, isFilterApplied, searchQuery, userStatus } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const {
    tableProps: { clearAllFiltersButton, searchPlaceHolder },
  } = useStrapiSharedData();
  const handleQChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    searchParams.set(ALL_MEMBERS_TABLE_SEARCH_QUERY_PARAM, value);
    navigate('?' + searchParams.toString());
  };

  const handleUserStatusChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value;
    searchParams.set(ALL_MEMBERS_TABLE_USER_STATUS_QUERY_PARAM, value);
    navigate('?' + searchParams.toString());
  };

  const handleResetFiltersBtnClick = () => {
    // This will remove all the query params (i.e. how filters are set)
    navigate('.');
  };
  const { activatedStatuslabel, invitedStatuslabel, deactivatedStatuslabel, statusPlaceHolder } =
    useStrapiMembersData().membersTable.TableStatusOptions;
  const ALL_MEMBERS_TABLE_USER_STATUS_SELECT_OPTIONS: Option[] = [
    {
      display: activatedStatuslabel,
      value: USER_STATUS.ACTIVE,
    },
    {
      display: deactivatedStatuslabel,
      value: USER_STATUS.DISABLED,
    },
    {
      display: invitedStatuslabel,
      value: USER_STATUS.INVITED,
    },
  ];
  return (
    <div
      className={clsx(
        'flex w-full flex-col justify-between gap-3 text-[14px] font-normal leading-[20px] text-zinc-100 md:flex-row md:gap-2',
        className
      )}
    >
      <div className="order-1 flex w-full flex-col gap-3 md:w-min md:flex-row md:gap-2">
        <SearchInput
          placeholder={searchPlaceHolder}
          containerClassName="min-w-[200px]"
          iconClassName="left-2"
          className="h-9 rounded-sm border border-zinc-900 bg-zinc-900 p-2 pl-9 text-[14px] font-normal leading-[20px] text-zinc-100 placeholder:text-zinc-500"
          value={searchQuery ?? ''}
          onChange={handleQChange}
        />
        <FilterDropDown
          value={userStatus ?? undefined}
          options={ALL_MEMBERS_TABLE_USER_STATUS_SELECT_OPTIONS}
          onChange={handleUserStatusChange}
          selectItemLabel={statusPlaceHolder}
          aria-label="Requests status filter options"
        />
      </div>
      {isFilterApplied && (
        <div className="order-3 flex w-full grow items-center justify-center md:order-2 md:justify-start">
          <button
            className="h-min self-center justify-self-start rounded-[32px] bg-zinc-700 py-1.5 px-2.5 font-sans text-[10px] font-medium leading-[105%] text-zinc-200"
            onClick={handleResetFiltersBtnClick}
          >
            {clearAllFiltersButton}
          </button>
        </div>
      )}
    </div>
  );
};

export default memo(AllMembersTableHeader);
