export const BADGES_DEFAULT_PAGE_SIZE = {
  EARNED_BADGES: 6,
  UP_NEXT_BADGES: 6,
};

export const BADGES_DEFAULT_PAGE_NUMBER = 1;

export const STALE_TIME = 3000;

export type ModalType = 'update' | 'create' | 'manage';

export const GET_BADGE_QUERY_KEY = 'GET_BADGE_QUERY_KEY';

export interface DeleteBadgeResponse {
  message: string;
}

export type GetBadgeTypesResponseItem = {
  typeId: number;
  name: string;
  badgeTypeKey: string;
};

export type GetBadgeTypesResponse = GetBadgeTypesResponseItem[];

export interface GetBadgeCriteriasArgs {
  language?: string;
}

export interface DeleteBadgePayload {
  id: number;
}

export interface UpdateBadgePayload {
  name: string;
  description: string;
  typeId?: number;
  categoryId?: number;
  imageUrl: string;
  courseCount?: number;
  badgeId: number;
  count?: number;
  unit?: string;
  language: string;
}

export interface CreateBadgePayload {
  name: string;
  description: string;
  typeId?: number;
  categoryId?: number;
  imageUrl: string;
  courseCount?: number;
  count?: number;
  unit?: string;
}

export interface CreateBadgeInMultiLangPayload {
  badgeId: number;
  name: string;
  description: string;
  language: string;
}

export interface GetUSHGHQBadgeDetailsArgs {
  id: number;
  language: string;
}

export interface CourseCategory {
  id: number;
  name: string;
}

export interface Criteria {
  id: number;
  name: string;
  badgeTypeKey: string;
}
export interface USHGHQBadgeDetailsResponse {
  isLoading?: boolean;
  id: number;
  picture: string;
  courseCount: number;
  category: CourseCategory;
  name: string;
  description: string;
  criteriaType: Criteria;
  unit?: number;
  count?: number;
}

export enum BADGE_TYPES {
  COURSE_ACCOMPLISHMENT = 'COURSE_ACCOMPLISHMENT',
  FOCUS_AREA = 'FOCUS_AREA',
  LEARNING_STREAK = 'LEARNING_STREAKS',
}

export const LEARNING_STREAK_UNITS = {
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
  YEARS: 'years',
};

export const UNIT_OPTIONS = [
  LEARNING_STREAK_UNITS.DAYS,
  LEARNING_STREAK_UNITS.WEEKS,
  LEARNING_STREAK_UNITS.MONTHS,
  LEARNING_STREAK_UNITS.YEARS,
];

export const BADGE_ACTION = {
  create: 'create',
  update: 'update',
  manage: 'manage',
} as const;
