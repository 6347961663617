import * as yup from 'yup';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { Label } from '../../../../../components/FormElements';
import Select from 'react-select';
import React, { useState } from 'react';
import useGetTeamsQuery from '../../../hooks/useGetTeamsQuery';
import Dialog from '../../../../../components/Dialog';
import { Button } from '../../../../../components/Buttons';
import useUpdateRoleOrTeamMutation from '../../../hooks/useUpdateRoleOrTeamMutation';
import { BasicSpinner } from '../../../../../components/Spinners';
import Alert from '../../../../../components/Alert';
import axios from 'axios';
import { UserUpdateRolePayloadType } from '../../../api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fieldHasError } from '../../../../../utils/react-hook-form';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
import IconWithModal from '../../../../../components/IconWithModal';
import RoleDescription from '../../../../shared/components/RoleDescription';
import { ArrowClockwise, Question } from 'phosphor-react';
import { queryClient } from '../../../../../config/react-query';
import { showToast } from '../../../../shared/helpers/toast';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { customStylesOne } from '../../../../../styles/modalCustomStyle';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';
import { ENTERPRISE_ROLE_KEYS, ENTERPRISE_USER_ROLES } from '../../../../../constants';

type props = {
  currentRole: string;
  setIsModalOpen: any;
  username: string;
  strapiData: any;
  teamName: string;
};

type schemaProps = {
  userRoleRequiredError: string;
  invalidUserRoleError: string;
  roleFieldLabel: string;
  teamDetailRequiredError: string;
  teamFieldLabel: string;
};

const getSchema = ({
  userRoleRequiredError,
  invalidUserRoleError,
  roleFieldLabel,
  teamDetailRequiredError,
  teamFieldLabel,
}: schemaProps) => {
  return yup.object({
    role: yup
      .string()
      .required(userRoleRequiredError)
      .equals([ENTERPRISE_USER_ROLES.MANAGER, ENTERPRISE_USER_ROLES.LEARNER], invalidUserRoleError)
      .label(roleFieldLabel),
    team: yup.object().nullable().required(teamDetailRequiredError).label(teamFieldLabel),
  });
};

const UserChangeTeamModal = ({
  currentRole,
  setIsModalOpen,
  username,
  strapiData,
  teamName,
}: props) => {
  const {
    roleInputPlaceHolder,
    roleInputLabel,
    teamFieldLabel,
    teamDetailRequiredError,
    roleFieldLabel,
    invalidUserRoleError,
    userRoleRequiredError,
    cancelButtonText,
    changeTeamButtonText,
    SuccessMessageHead,
    SuccessMessageBody,
    teamInputLabel,
    teamInputPlaceHolder,
    currentTeam,
    currentRole: currentRoleHead,
    teamsFetchErrorMessage,
  } = strapiData;
  const strapiError: any = useStrapiDataHelper();
  const strapiErrorMessage = strapiError.errorsMap;

  const userRoleUpdateSchema = getSchema({
    userRoleRequiredError,
    invalidUserRoleError,
    roleFieldLabel,
    teamDetailRequiredError,
    teamFieldLabel,
  });
  const { isLoading, isError, error, mutate } = useUpdateRoleOrTeamMutation();
  const {
    roleDescription,
    dropdownPlaceholderMessages: { loadingMessage, noOptionsMessage },
  } = useStrapiSharedData();
  const [alertError, setAlertError] = useState<string>();
  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(userRoleUpdateSchema),
    defaultValues: { role: currentRole, team: null },
  });
  const onSuccess = () => {
    showToast({ variant: 'success', title: SuccessMessageHead, body: SuccessMessageBody });
    setIsModalOpen(false);
    queryClient.invalidateQueries({
      queryKey: [
        {
          scope: 'organization',
          item: 'get-members',
        },
      ],
    });
    queryClient.invalidateQueries({
      queryKey: [
        {
          scope: 'teams',
          items: 'TeamMembers',
        },
      ],
    });
  };

  const onSubmit: any = (data: yup.InferType<typeof userRoleUpdateSchema>) => {
    const payload = {
      username,
      role: data.role,
      teamId: data.team.value,
      teamName: data.team.name,
    } as UserUpdateRolePayloadType;
    mutate(payload, {
      onSuccess,
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          const data: any = error.response?.data;
          console.error('Error in UserChangeTeamModal: ', data);
          setAlertError(strapiErrorMessage['USER_ROLE_UPDATE_FAILED']);
          // #markedforlookup
        }
      },
    });
  };

  const roleDropDown = roleDescription.roleDescriptions
    .filter(
      (dropdown: { description: string; label: string; slug: string }) =>
        dropdown.slug === ENTERPRISE_USER_ROLES.MANAGER ||
        dropdown.slug === ENTERPRISE_USER_ROLES.LEARNER
    )
    .map((dropdown: { description: string; label: string; slug: string }) => {
      return {
        label: dropdown.label,
        value: dropdown.slug,
      };
    });

  const rolesInDropDownOptions: string[] = roleDescription.roleDescriptions
    .filter(
      (dropdown: { description: string; label: string; slug: string }) =>
        dropdown.slug === ENTERPRISE_USER_ROLES.MANAGER ||
        dropdown.slug === ENTERPRISE_USER_ROLES.LEARNER
    )
    .map((dropdown: { description: string; label: string; slug: string }) => dropdown.slug);

  const {
    data,
    isLoading: isLoadingTeams,
    refetch,
    isError: isErrorTeams,
    isRefetching: isReFetchingTeams,
  } = useGetTeamsQuery({
    includeSubTeams: true,
  });

  return (
    <>
      {isError && error && <Alert variant="error" message={alertError} />}
      <div className="flex w-full flex-col gap-2 sm:flex-row">
        <div className="grow">
          <p className="text-xs font-medium leading-4 text-zinc-400">{currentRoleHead}</p>
          <p className="text-xs font-medium leading-4 text-zinc-50">{currentRole}</p>
        </div>
        <div className="grow">
          <p className="text-xs font-medium leading-4 text-zinc-400">{currentTeam}</p>
          <p className="text-xs font-medium leading-4 text-zinc-50">{teamName}</p>
        </div>
      </div>
      <form
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e);
        }}
      >
        {/* Select a role */}
        <div>
          <div className="flex flex-row items-center gap-1">
            <Label htmlFor={`change-team_${roleFieldLabel}`} label={roleInputLabel} />
            <IconWithModal
              icon={<Question size="14px" className="text-zinc-400" />}
              content={<RoleDescription roles={rolesInDropDownOptions} />}
              triggerClassName="max-w-max px-0.5"
              containerClassName="max-h-[80vh] overflow-y-auto z-[100]"
              overlayClassName={'z-[80] bg-black/40'}
            />
          </div>
          <Select
            id={roleInputLabel}
            loadingMessage={() => loadingMessage}
            noOptionsMessage={() => noOptionsMessage}
            options={roleDropDown}
            placeholder={roleInputPlaceHolder}
            styles={customStylesOne}
            defaultValue={ENTERPRISE_ROLE_KEYS[currentRole]}
            classNames={{
              control: () => 'mt-0.5',
            }}
            aria-invalid={fieldHasError(errors, roleFieldLabel) ? 'true' : 'false'}
            {...register(roleFieldLabel)}
            onChange={(role: any) => {
              setValue('role', role.value);
              trigger(roleFieldLabel);
            }}
          />
          <HookFormErrorMessage
            name="role"
            errors={errors}
            render={({ message }) => <ErrorMessage message={message} />}
          />
        </div>
        {/* Select a team */}
        <div className="mt-2">
          <div className="flex flex-col justify-between xs:flex-row">
            <Label htmlFor={`change-team_${teamFieldLabel}`} label={teamInputLabel} />
            {isErrorTeams && (
              <div className="flex flex-row items-center gap-1">
                <p className="text-xs font-normal text-red-500">{teamsFetchErrorMessage}</p>
                <ArrowClockwise
                  onClick={() => refetch()}
                  size={14}
                  weight="light"
                  color="#a1a1aa"
                  className="cursor-pointer hover:opacity-50"
                />
              </div>
            )}
          </div>
          <Select
            id="team"
            loadingMessage={() => loadingMessage}
            noOptionsMessage={() => noOptionsMessage}
            options={data}
            isLoading={isLoadingTeams || isReFetchingTeams}
            placeholder={teamInputPlaceHolder}
            styles={customStylesOne}
            classNames={{
              control: () => 'mt-0.5',
            }}
            aria-invalid={fieldHasError(errors, teamFieldLabel) ? 'true' : 'false'}
            {...register(teamFieldLabel)}
            onChange={(team: any) => {
              setValue('team', team);
              trigger(teamFieldLabel);
            }}
          />
          <HookFormErrorMessage
            name="team"
            errors={errors}
            render={({ message }) => <ErrorMessage message={message} />}
          />
        </div>
        <div className="mt-5 flex flex-col items-center justify-end xs:flex-row">
          <Dialog.Close
            type="button"
            className="rounded bg-transparent px-[30px] py-2.5 text-center text-sm font-medium text-white"
          >
            {cancelButtonText}
          </Dialog.Close>
          <Button
            className="flex items-center gap-1 rounded bg-base-brand px-[30px] py-2.5 text-center text-sm font-medium text-white disabled:opacity-80"
            type="submit"
            disabled={isLoading || isError || isErrorTeams}
          >
            <span>{changeTeamButtonText}</span>
            {isLoading && <BasicSpinner className="!m-0 h-4 w-4 text-zinc-100" />}
          </Button>
        </div>
      </form>
    </>
  );
};

export default UserChangeTeamModal;
