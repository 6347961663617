import { GetAllMembersInOrganizationResponseDataItem } from '../../../../../types';
import { getTeamNameFormatString, getTeamNames } from '../../../../../utils/team';

interface Props {
  row: GetAllMembersInOrganizationResponseDataItem;
}

const TeamNameCell = (props: Props) => {
  const { row } = props;

  const teamNames = getTeamNames(row).filter((x) => x !== null && x !== undefined) as string[];
  const teamNameFormatString = getTeamNameFormatString(teamNames);

  return (
    <span className="whitespace-nowrap text-xs font-normal text-zinc-100">
      {teamNameFormatString}
    </span>
  );
};

export default TeamNameCell;
