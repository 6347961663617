import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

export const useStrapiOnBoardingData = () => {
  const data: any = useAppSelector(strapiDataSelector);
  const strapiOnBoardingCommon = data.onBoarding.data.attributes.COMMON;
  let welcomePage;
  let tabs;
  let profile;
  let buttons;
  let successPage;
  let toastmessages;
  let setPassword;
  let teamTooltip;
  let emptyMessages;

  for (let i = 0; i <= strapiOnBoardingCommon?.length; i++) {
    if (strapiOnBoardingCommon[i]?.__typename === 'ComponentOnBoardingWelcomePage') {
      welcomePage = (strapiOnBoardingCommon ?? [])[i];
    } else if (strapiOnBoardingCommon[i]?.__typename === 'ComponentOnBoardingOnBoardingTabs') {
      tabs = (strapiOnBoardingCommon ?? [])[i];
    } else if (strapiOnBoardingCommon[i]?.__typename === 'ComponentOnBoardingProfile') {
      profile = (strapiOnBoardingCommon ?? [])[i];
    } else if (strapiOnBoardingCommon[i]?.__typename === 'ComponentOnBoardingOnBoardingButtons') {
      buttons = (strapiOnBoardingCommon ?? [])[i];
    } else if (strapiOnBoardingCommon[i]?.__typename === 'ComponentOnBoardingSuccessPage') {
      successPage = (strapiOnBoardingCommon ?? [])[i];
    } else if (strapiOnBoardingCommon[i]?.__typename === 'ComponentOnBoardingToastMessages') {
      toastmessages = (strapiOnBoardingCommon ?? [])[i];
    } else if (strapiOnBoardingCommon[i]?.__typename === 'ComponentOnBoardingSetPassword') {
      setPassword = (strapiOnBoardingCommon ?? [])[i];
    } else if (strapiOnBoardingCommon[i]?.__typename === 'ComponentSharedTooltip') {
      teamTooltip = (strapiOnBoardingCommon ?? [])[i];
    } else if (strapiOnBoardingCommon[i]?.__typename === 'ComponentOnBoardingEmptyMessages') {
      emptyMessages = (strapiOnBoardingCommon ?? [])[i];
    }
  }
  const strapiOnBoardingLeader = data.onBoarding.data.attributes.ENTERPRISE_LEADER_ADMIN;
  let leaderGoals;
  let leaderCatergory;
  let inviteMember;
  let addMemeber;
  let leaderTooltip;

  for (let i = 0; i <= strapiOnBoardingLeader?.length; i++) {
    if (strapiOnBoardingLeader[i]?.__typename === 'ComponentOnBoardingGoals') {
      leaderGoals = (strapiOnBoardingLeader ?? [])[i];
    } else if (strapiOnBoardingLeader[i]?.__typename === 'ComponentOnBoardingCategories') {
      leaderCatergory = (strapiOnBoardingLeader ?? [])[i];
    } else if (strapiOnBoardingLeader[i]?.__typename === 'ComponentOnBoardingInviteMember') {
      inviteMember = (strapiOnBoardingLeader ?? [])[i];
    } else if (strapiOnBoardingLeader[i]?.__typename === 'ComponentTeamAddMember') {
      addMemeber = (strapiOnBoardingLeader ?? [])[i];
    } else if (strapiOnBoardingLeader[i]?.__typename === 'ComponentSharedTooltip') {
      leaderTooltip = (strapiOnBoardingLeader ?? [])[i];
    }
  }
  const strapiOnBoardingManger = data.onBoarding.data.attributes.ENTERPRISE_MANAGER;
  let managerGoals;
  let managerCatergory;

  for (let i = 0; i <= strapiOnBoardingManger?.length; i++) {
    if (strapiOnBoardingManger[i]?.__typename === 'ComponentOnBoardingGoals') {
      managerGoals = (strapiOnBoardingManger ?? [])[i];
    } else if (strapiOnBoardingManger[i]?.__typename === 'ComponentOnBoardingCategories') {
      managerCatergory = (strapiOnBoardingManger ?? [])[i];
    }
  }

  // welcome page
  const {
    title: welcomePageTitle,
    description: welcomePageDescription,
    welcomeImage,
    getStartedButton: welcomePageGetStartedButton,
  } = welcomePage;
  const welcomeImg = welcomeImage?.data?.attributes.url;
  // tabs
  const { welcomeTab, goalsTab, categoriesTab, profileTab, inviteMemberTab, successTab } = tabs;
  // leader Goals content
  const {
    teamGoalToggleButton,
    personalGoalToggleButton,
    teamGoals: leaderTeamGoals,
    personalGoals: leaderPersonalGoals,
  } = leaderGoals;
  const { title: leaderTeamGoalTitle, description: leaderTeamGoalDescription } = leaderTeamGoals;
  const { title: leaderPersonalGoalTitle, description: leaderPersonalGoalDescription } =
    leaderPersonalGoals;
  // leader categories content
  const {
    teamCategoryToggleButton,
    personalCategoryToggleButton,
    allCategoriesTitle,
    recommendedCategoriesTitle,
    teamCategory,
    personalCategory,
    courseCountLabel,
  } = leaderCatergory;

  const { title: leaderTeamCategoryTitle, description: leaderTeamCategoryDescription } =
    teamCategory;
  const { title: leaderPersonalCategoryTitle, description: leaderPersonalCategoryDescription } =
    personalCategory;

  // manager goals content
  const {
    teamGoalToggleButton: managerTeamGoalToggleButton,
    personalGoalToggleButton: managerPersonalGoalToggleButton,
    teamGoals: managerTeamGoals,
    personalGoals: managerPersonalGoals,
  } = managerGoals;
  const { title: managerTeamGoalTitle, description: managerTeamGoalDescription } = managerTeamGoals;
  const { title: managerPersonalGoalTitle, description: managerPersonalGoalDescription } =
    managerPersonalGoals;

  // manager category
  const {
    teamCategoryToggleButton: managerTeamCategoryToggleButton,
    personalCategoryToggleButton: managerPersonalCategoryToggleButton,
    allCategoriesTitle: managerAllCategoriesTitle,
    recommendedCategoriesTitle: managerRecommendedCategoriesTitle,
    teamCategory: managerTeamCategory,
    personalCategory: managerPersonalCategory,
    courseCountLabel: managerCourseCountLabel,
  } = managerCatergory;
  const { title: managerTeamCategoryTitle, description: managerTeamCategoryDescription } =
    managerTeamCategory;
  const { title: managerPersonalCategoryTitle, description: managerPersonalCategoryDescription } =
    managerPersonalCategory;

  // profile

  const {
    title: profileTitle,
    description: profileDescription,
    form,
    avatarUploadInstruction,
    uploadAvatarButton,
    removeAvatarButton,
  } = profile;

  // buttons
  const { nextButton, backButton, goToDashboardButton, skipButton } = buttons;

  // Success page strapi
  const {
    title: successPageTitle,
    description: successPageDescription,
    pageLoading: successPageLoading,
  } = successPage;
  // Add member modal
  const {
    title: modalTitle,
    addMemberButton,
    addMemberRequestButton,
    goToBillingButton,
    cancelButton,
    Roles,
    fieldName1: emailLabel,
    placeHolder1: emailPlaceHolder,
    fieldName2: jobTitleLabel,
    placeHolder2: jobTitlePlaceholder,
    fieldName3: memberRoleLabel,
    placeHolder3: memberRolePlaceholder,
  } = addMemeber;

  // invite member
  const {
    teamInviteTitle,
    leaderInviteTitle,
    addTeamButton,
    addTeamMemberButton,
    addLeaderButton,
    removeButton,
    orLabel,
    bulkUpload,
    addTeamTitle,
    addTeamPlaceholder,
  } = inviteMember;
  const {
    title: bulkUploadTitle,
    uploadFileLabel,
    csvInstruction,
    dragAndDropLabel,
    downloadSampleButton,
  } = bulkUpload;

  // toast messages
  const {
    avatarUploadFailure,
    addMemberFailureTitle,
    missingTeamDetailsTitle,
    inviteTeamFailureMessage,
    goalSelectionFailureTitle,
    profileUpdateFailureTitle,
    profileUpdateFailureMessage,
    goalSelectionFailureMessage,
    categorySelectionFailureTitle,
    categorySelectionFailureMessage,
    addMemberSuccessTitle,
    addMemberSuccessMessage,
    leaderAlreadyExist,
    memberAlreadyExist,
    profileUpdatedSuccessfulTitle,
    profileUpdatedSuccessfulMessage,
    teamAddedSuccessfullyTitle,
    teamAddedSuccessfullyMessage,
    teamAddedFailureTitle,
    teamAddedFailureMessage,
    goalsMinError,
    categoriesMinError,
  } = toastmessages;

  // set Password

  return {
    managerGoals: {
      managerTeamGoalToggleButton,
      managerPersonalGoalToggleButton,
      managerTeamGoalTitle,
      managerTeamGoalDescription,
      managerPersonalGoalTitle,
      managerPersonalGoalDescription,
    },
    mangerCategory: {
      managerTeamCategoryTitle,
      managerTeamCategoryDescription,
      managerPersonalCategoryTitle,
      managerTeamCategoryToggleButton,
      managerPersonalCategoryDescription,
      managerPersonalCategoryToggleButton,
      managerAllCategoriesTitle,
      managerRecommendedCategoriesTitle,
      managerCourseCountLabel,
    },
    setPassword,
    inviteMember: {
      addTeamTitle,
      addTeamPlaceholder,
      bulkUploadTitle,
      uploadFileLabel,
      csvInstruction,
      dragAndDropLabel,
      downloadSampleButton,
      teamInviteTitle,
      leaderInviteTitle,
      addTeamButton,
      addTeamMemberButton,
      addLeaderButton,
      removeButton,
      orLabel,
    },
    teamTooltip,
    toastMessages: {
      avatarUploadFailure,
      addMemberFailureTitle,
      missingTeamDetailsTitle,
      inviteTeamFailureMessage,
      goalSelectionFailureTitle,
      profileUpdateFailureTitle,
      profileUpdateFailureMessage,
      goalSelectionFailureMessage,
      categorySelectionFailureTitle,
      categorySelectionFailureMessage,
      addMemberSuccessTitle,
      addMemberSuccessMessage,
      leaderAlreadyExist,
      memberAlreadyExist,
      profileUpdatedSuccessfulTitle,
      profileUpdatedSuccessfulMessage,
      teamAddedSuccessfullyTitle,
      teamAddedSuccessfullyMessage,
      teamAddedFailureTitle,
      teamAddedFailureMessage,
      goalsMinError,
      categoriesMinError,
    },
    addMember: {
      modalTitle,
      addMemberButton,
      addMemberRequestButton,
      goToBillingButton,
      cancelButton,
      emailLabel,
      emailPlaceHolder,
      jobTitleLabel,
      jobTitlePlaceholder,
      memberRoleLabel,
      memberRolePlaceholder,
      Roles,
    },

    successPage: {
      successPageTitle,
      successPageDescription,
      successPageLoading,
    },
    buttons: {
      nextButton,
      backButton,
      goToDashboardButton,
      skipButton,
    },
    profile: {
      profileTitle,
      profileDescription,
      avatarUploadInstruction,
      uploadAvatarButton,
      removeAvatarButton,
      form,
    },
    leaderCategory: {
      leaderTeamCategoryTitle,
      leaderTeamCategoryDescription,
      leaderPersonalCategoryTitle,
      teamCategoryToggleButton,
      leaderPersonalCategoryDescription,
      personalCategoryToggleButton,
      allCategoriesTitle,
      recommendedCategoriesTitle,
      courseCountLabel,
    },
    leaderGoals: {
      leaderPersonalGoalTitle,
      leaderPersonalGoalDescription,
      leaderTeamGoalTitle,
      teamGoalToggleButton,
      leaderTeamGoalDescription,
      personalGoalToggleButton,
    },
    leaderTooltip,
    tabs: {
      welcomeTab,
      goalsTab,
      categoriesTab,
      profileTab,
      inviteMemberTab,
      successTab,
    },
    welcomePage: {
      welcomePageTitle,
      welcomePageDescription,
      welcomeImg,
      welcomePageGetStartedButton,
    },
    emptyMessages,
  };
};
