import { UserLogoInfo } from '../../types/interface';
import LeaderTeamsUserCircleView from './LeaderTeamsUserCircleView';

interface Props {
  displayUsers?: UserLogoInfo[];
}
function LeaderTeamsUserCircleGroup({ displayUsers }: React.PropsWithChildren<Props>) {
  let renderList;

  if (displayUsers) {
    renderList = displayUsers.map((user) => {
      return (
        <div key={user.username}>
          {/* //if index is more than */}
          <LeaderTeamsUserCircleView user={user} />
        </div>
      );
    });
    return <div className="flex justify-start -space-x-2">{renderList}</div>;
  }
  return <></>;
}

export default LeaderTeamsUserCircleGroup;
