import { Channel, ChannelSummary, ChannelMessage } from '@aws-sdk/client-chime-sdk-messaging';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MessagingSliceState {
  channels: {
    activeChannel: ChannelSummary | null;
    // List of channel arns
    unreadChannelArns: string[];
  };
  messages: {
    list: ChannelMessage[];
    nextMessageToken: string | null;
    isLoading: boolean;
    isInitialLoading: boolean;
  };
}

const initialState: MessagingSliceState = {
  channels: {
    activeChannel: null,
    unreadChannelArns: [],
  },
  messages: {
    list: [],
    nextMessageToken: null,
    isLoading: true,
    isInitialLoading: true,
  },
};

const messagingSlice = createSlice({
  name: 'messaging',
  initialState,
  reducers: {
    setActiveChannel: (state, event: PayloadAction<Channel | null>) => {
      state.channels.activeChannel = event.payload;
    },
    setUnreadChannelArns: (state, event: PayloadAction<string[]>) => {
      state.channels.unreadChannelArns = event.payload;
    },
    setNextMessageToken: (state, event: PayloadAction<string | null>) => {
      state.messages.nextMessageToken = event.payload;
    },
    loadingPreviousActiveChannelMessages: (state) => {
      state.messages.isLoading = true;
    },
    loadedActiveChannelMessages: (state, event: PayloadAction<ChannelMessage[]>) => {
      state.messages.list = event.payload;
      state.messages.isLoading = false;
      state.messages.isInitialLoading = false;
    },
    resetMessages: (state) => {
      state.messages.list = [];
      state.messages.isInitialLoading = true;
      state.messages.isLoading = true;
      state.messages.nextMessageToken = null;
    },
    resetMessagingState: (state) => {
      state.messages.list = [];
      state.messages.isInitialLoading = true;
      state.messages.isLoading = true;
      state.messages.nextMessageToken = null;
      state.channels.activeChannel = null;
      state.channels.unreadChannelArns = [];
    },
  },
});

export const {
  setActiveChannel,
  setUnreadChannelArns,
  setNextMessageToken,
  loadedActiveChannelMessages,
  loadingPreviousActiveChannelMessages,
  resetMessages,
  resetMessagingState,
} = messagingSlice.actions;

export default messagingSlice.reducer;
