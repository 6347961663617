import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
// import { Info } from 'phosphor-react';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Alert from '../../../../../components/Alert';
import { Button } from '../../../../../components/Buttons';
import { Label, PasswordInput, TextInput } from '../../../../../components/FormElements';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
// import Message from '../../../../../components/FormElements/Message';
import { BasicSpinner } from '../../../../../components/Spinners';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import {
  LEADING_AND_TRAILING_SPACES_PATTERN,
  LOWERCASE_CHARACTER_REGEX_PATTERN,
  SINGLE_DIGIT_REGEX_PATTERN,
  SPECIAL_CHARACTER_REGEX_PATTERN,
  UPPERCASE_CHARACTER_REGEX_PATTERN,
} from '../../../constants';
import { completeForgotPasswordRequest } from '../../../helpers';
import CodeInput from '../../../../../components/FormElements/CodeInput';
import UserResetPasswordEmailInstructions from './UserResetPasswordEmailInstructions';
import PasswordRequirements from '../../../../../components/FormElements/PasswordRequirements';
// Reset Password Form Schema section starts

const resetPasswordSchema = yup.object({
  email: yup
    .string()
    .trim()
    .lowercase()
    .email('validEmail')
    .required('emailRequired')
    .label('Email'),
  code: yup
    .array(
      yup
        .object({
          value: yup.string().required('codeRequired'),
        })
        .required('codeRequired')
    )
    .length(6, 'codeLength')
    .required('codeRequired'),
  password: yup
    .string()
    .min(8, 'passwordMin')
    .required('passwordRequired')
    .matches(new RegExp(LOWERCASE_CHARACTER_REGEX_PATTERN), 'passwordLower')
    .matches(new RegExp(UPPERCASE_CHARACTER_REGEX_PATTERN), 'passwordUpper')
    .matches(new RegExp(SPECIAL_CHARACTER_REGEX_PATTERN), 'passwordSpecialCharacter')
    .matches(new RegExp(SINGLE_DIGIT_REGEX_PATTERN), 'passwordNumber')
    .matches(new RegExp(LEADING_AND_TRAILING_SPACES_PATTERN), 'passwordSpace')
    .label('Password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'confirmPassword')
    .label('Confirm Password')
    .required('confirmPasswordRequired'),
});

export type ResetPasswordFormData = yup.InferType<typeof resetPasswordSchema>;

// Reset Password Form Schema section ends
type Props = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;

const UserResetPasswordForm = ({ className, ...otherProps }: Props) => {
  const data: any = useStrapiDataHelper();
  const strapiData = data?.resetpassword.data?.attributes;
  const strapiErrorData = data?.errormessage.data.attributes;
  const strapiErrorMessage = data.errorsMap;
  const {
    emailLabel,
    emailPlaceholder,
    verificationCodeLabel,
    newPasswordLabel,
    confirmPasswordLabel,
    updatePasswordButton,
    passwordRequirements,
  } = strapiData;
  console.log(passwordRequirements);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Reason Forgot Password Request Failed Reason
  const [failedMessage, setFailedMessage] = useState<string | null>(null);
  const codeDefaultValue = useMemo(() => {
    if (queryParams) {
      const codeString = queryParams.get('code');
      let codeCharArr: string[];
      if (codeString) {
        codeCharArr = codeString.split('');
      } else {
        codeCharArr = [];
      }

      const result = Array(6)
        .fill(0)
        .map((_, index) => {
          const char = codeCharArr[index];
          if (char?.match(SINGLE_DIGIT_REGEX_PATTERN)) {
            return {
              value: char,
            };
          } else {
            return {
              value: '',
            };
          }
        });

      return result;
    }
  }, [location]);

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isSubmitted, isValid },
  } = useForm<ResetPasswordFormData>({
    defaultValues: {
      email: queryParams.get('email') ?? '',
      code: codeDefaultValue,
      password: '',
    },
    resolver: yupResolver(resetPasswordSchema),
  });

  const formSubmitHandler: SubmitHandler<ResetPasswordFormData> = async (data) => {
    try {
      const { email: username, code, password } = data;
      await completeForgotPasswordRequest({
        username,
        code: code.map((c) => c.value).join(''),
        password,
      });
      navigate(`/auth/reset-password/success`, {
        state: {
          fromPath: '/auth/reset-password',
        },
        replace: true,
      });
    } catch (error) {
      // TODO: Improve Error Handling
      console.log(error);
      // try to parse code and message from error
      // AuthErrors from amplify would have code and message
      const { code } = error as { code: string; message: string };

      if (code) {
        switch (code) {
          case 'CodeMismatchException': {
            setFailedMessage(strapiErrorMessage['CODE_MISMATCH_EXCEPTION']);
            // #markedforlookup - this cannot be handled now since the message is from amplify
            break;
          }
          case 'InvalidPasswordException': {
            setFailedMessage(strapiErrorMessage['INVALID_PASSWORD_EXCEPTION']);
            break;
          }
          case 'ExpiredCodeException': {
            setFailedMessage(strapiErrorMessage['EXPIRED_CODE_EXCEPTION']);
            break;
          }
          case 'TooManyFailedAttemptsException': {
            setFailedMessage(strapiErrorMessage['TOO_MANY_FAILED_ATTEMPTS_EXCEPTION']);
            break;
          }
          case 'LimitExceededException': {
            setFailedMessage(strapiErrorMessage['LIMIT_EXCEEDED_EXCEPTION']);
            break;
          }
          default: {
            setFailedMessage(strapiErrorMessage['PASSWORD_RESET_FAILED']);
          }
        }
      } else {
        setFailedMessage(strapiErrorMessage['PASSWORD_RESET_FAILED']);
      }
    }
    // for react-hook-form isSubmitting boolean value
    return;
  };

  // The Alert informs the user that an email with a code has been
  // sent to the user email box
  // should be displayed only if redirected from forgot-password page
  // and form is not submitted at least once
  const displayWelcomeAlert = !isSubmitted && location.state?.fromPath === '/auth/forgot-password';
  return (
    <form
      onSubmit={handleSubmit(formSubmitHandler)}
      className={`flex w-full max-w-md flex-col gap-6 ${className ?? ''}`}
      {...otherProps}
    >
      {displayWelcomeAlert && <UserResetPasswordEmailInstructions />}
      {failedMessage && <Alert variant="error" message={failedMessage}></Alert>}
      {/* if queryParams has username, hide this form field */}
      <div className={clsx('flex flex-col gap-2', [queryParams.has('email') && 'hidden'])}>
        <Label htmlFor="email_input" label={emailLabel} />
        <TextInput
          className="rounded-md"
          id="email_input"
          placeholder={emailPlaceholder}
          type="text"
          {...register('email')}
        />
        {errors.email?.message && <ErrorMessage message={strapiErrorData[errors.email.message]} />}
      </div>
      <div className="flex flex-col gap-2">
        <Label htmlFor="verification_code" label={verificationCodeLabel} />
        <CodeInput control={control} htmlId="verification_code" register={register} />
        {errors.code?.message && <ErrorMessage message={strapiErrorData[errors.code.message]} />}
      </div>
      <div className="flex flex-col gap-2">
        <Label htmlFor="password_input" label={newPasswordLabel} />
        <PasswordInput
          className="rounded-md"
          id="password_input"
          type="password"
          autoComplete="off"
          {...register('password', {
            deps: ['confirmPassword'],
          })}
        />
        <PasswordRequirements />
        {errors.password?.message && (
          <ErrorMessage message={strapiErrorData[errors.password.message]} />
        )}
      </div>
      <div className="flex flex-col gap-2">
        <Label htmlFor="confirm_password" label={confirmPasswordLabel} />
        <PasswordInput
          className="rounded-md"
          id="confirm_password"
          type="password"
          autoComplete="off"
          {...register('confirmPassword')}
        />
        {errors.confirmPassword?.message && (
          <ErrorMessage message={strapiErrorData[errors.confirmPassword.message]} />
        )}
      </div>
      <Button
        type="submit"
        // Disable when we're submitting or when the form is submitted once
        // and is not valid
        disabled={isSubmitting || (!isValid && isSubmitted)}
        className="my-3.5 flex items-center justify-center bg-base-brand text-sm font-semibold leading-[25px] text-white"
      >
        {isSubmitting ? (
          <BasicSpinner className="h-[25px] w-[25px] text-white" />
        ) : (
          updatePasswordButton
        )}
      </Button>
    </form>
  );
};

export default UserResetPasswordForm;
