import type { RouteObject } from 'react-router-dom';
import { OrganisationMain } from '../components/OrganisationMain';
import { SingleOrganisationView } from '../components/SingleOrganisationView';
const routes: RouteObject[] = [
  {
    path: 'organisations',
    element: <OrganisationMain />,
  },
  {
    path: 'organisation/:id',
    element: <SingleOrganisationView />,
  },
];

export default routes;
