import { RouteObject } from 'react-router-dom';
import { CourseManageRoutes } from '../features/course-management';
import SpeakersPageRoutes from '../features/speakers/routes';
import OrganisationRoute from '../features/organisation/routes';
import IndividualPageRoutes from '../features/individuals/routes';

const MgmtRoutes: RouteObject[] = [
  {
    path: 'manage',
    children: [
      ...CourseManageRoutes,
      ...SpeakersPageRoutes,
      ...OrganisationRoute,
      ...IndividualPageRoutes,
    ],
  },
];

export default MgmtRoutes;
