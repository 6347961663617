import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import React, { useContext, useMemo, useState } from 'react';
import { ErrorDisplay, TableHeaderRow } from '../../../../../../components/DataTables';
import {
  MostPopularCourses,
  MostPopularCoursesFilter,
  ReportTooltipDataType,
} from '../../../../types';
import ReportsHeader from '../../ReportsHeader';
import { CourseAvgRatingsCell, CourseCompletionsCell } from '../TableCell';
import TableDataRow from '../../../../../../components/DataTables/TableDataRow';
import CourseNameCell from '../TableCell/CourseNameCell';
import durationContext from '../../../../context/durationContext';
import { useMostPopularCoursesQuery } from '../../../../hooks';
import NoDataFallback from '../../../NoDataFallback';
import { MOST_POPULAR_COURSES_DEFAULT_LIMIT, REPORT_TYPES } from '../../../../constants';
import { InView } from 'react-intersection-observer';
import { useStrapiReportsData } from '../../../../hooks/useStrapiReportData';
import ScaleLoader from '../../../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../../../components/ReportToolTip';

interface MostPopularCoursesProps {
  className: string;
  tableClassName?: string;
  lazyLoad?: boolean;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}

const getTableColumnDefinition = (
  courseHeader: string,
  completionsHeader: string,
  avgRatingsHeader: string
) => {
  const columnHelper = createColumnHelper<MostPopularCourses>();
  const columns = [
    columnHelper.accessor('name', {
      header: courseHeader,
      cell: (props) => <CourseNameCell course={props.getValue()} />,
    }),
    columnHelper.accessor('completions', {
      header: completionsHeader,
      cell: (props) => {
        return <CourseCompletionsCell completions={props.getValue()} />;
      },
    }),
    columnHelper.accessor('averageRating', {
      header: avgRatingsHeader,
      cell: (props) => <CourseAvgRatingsCell avgRatings={props.getValue()} />,
    }),
  ];
  return columns;
};

const MostPopularCoursesTable = ({
  className,
  tableClassName,
  lazyLoad = true,
  reportTooltipContent,
  userPersona,
}: MostPopularCoursesProps) => {
  const { duration } = useContext(durationContext);
  const { title, tableheader1, tableheader2, tableheader3, emptyMessage, errorMessage } =
    useStrapiReportsData().MostPopularCourses;
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);

  // Build the filter
  const filters: Partial<MostPopularCoursesFilter> = useMemo(() => {
    return {
      fromDate: duration.fromDate,
      limit: MOST_POPULAR_COURSES_DEFAULT_LIMIT,
    };
  }, [duration]);

  const mostPopularCourses = useMostPopularCoursesQuery({ filters, isEnabled: isInView });

  const displayLoader =
    (mostPopularCourses.isLoading && !mostPopularCourses.data) ||
    (mostPopularCourses.isFetching && mostPopularCourses.data?.results?.length === 0);

  const hasMostPopularCourses =
    mostPopularCourses.data &&
    mostPopularCourses.data.results &&
    mostPopularCourses.data.results.length > 0;

  const hasNoMostPopularCourses =
    mostPopularCourses.data &&
    mostPopularCourses.data.results &&
    mostPopularCourses.data.results.length === 0;

  const tableHeader = {
    courseHeader: tableheader1,
    completionsHeader: tableheader2,
    avgRatingsHeader: tableheader3,
  };
  const { courseHeader, completionsHeader, avgRatingsHeader } = tableHeader;

  const columns = getTableColumnDefinition(courseHeader, completionsHeader, avgRatingsHeader);

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data: mostPopularCourses.data?.results || [],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <InView
      as="div"
      className={className}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div
        className={clsx(
          'relative flex h-full min-h-[320px]  w-full flex-col gap-4 overflow-hidden rounded-lg bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)]',
          tableClassName
        )}
      >
        <div className="flex items-center gap-2 ">
          <ReportsHeader title={title} />
          {reportTooltipContent?.[REPORT_TYPES.MOST_POPULAR_COURSES]?.[userPersona] && (
            <ReportTooltip
              content={reportTooltipContent[REPORT_TYPES.MOST_POPULAR_COURSES][userPersona]}
            />
          )}
        </div>
        {displayLoader && (
          <div className="flex w-full grow items-center justify-center py-4">
            <ScaleLoader />
          </div>
        )}
        {/* We have data */}
        {hasMostPopularCourses && (
          <div className="w-full overflow-hidden overflow-x-scroll pb-2">
            <table className={clsx('w-full', className)}>
              <thead className="w-full">
                {getHeaderGroups().map((headerGroup) => {
                  return (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableHeaderRow
                          className="!font-semibold !text-zinc-400"
                          isSortable={false}
                          header={header}
                          key={header.id}
                        />
                      ))}
                    </tr>
                  );
                })}
              </thead>
              <tbody>
                {getRowModel().rows.map((row) => (
                  <TableDataRow tdClassName="!text-zinc-50 font-semibold" row={row} key={row.id} />
                ))}
              </tbody>
            </table>
          </div>
        )}
        {/* Empty State */}
        {hasNoMostPopularCourses && !displayLoader && <NoDataFallback message={emptyMessage} />}

        {/* Error State */}
        <>
          {mostPopularCourses.error && !mostPopularCourses.data && (
            <div className="flex grow items-center justify-center">
              <ErrorDisplay
                message={errorMessage}
                refetch={mostPopularCourses.refetch}
                allowsRefetch={true}
                isRefetching={mostPopularCourses.isRefetching}
              />
            </div>
          )}
        </>
      </div>
    </InView>
  );
};

export default MostPopularCoursesTable;
