import clsx from 'clsx';
import React from 'react';
import { BasicSpinner } from '../../../components/Spinners';

interface Props {
  refetch: () => void;
  isRefetching?: boolean;
  allowsRefetch?: boolean;
  className?: string;
  message: React.ReactNode;
}
const ErrorFallback = (props: Props) => {
  const { refetch, isRefetching, allowsRefetch, className, message } = props;
  return (
    <div role="alert" className={clsx('flex gap-x-1 bg-component-bg-dark', className)}>
      <span className="text-red-400">{message}</span>
      {isRefetching && (
        <div className="flex justify-center">
          <BasicSpinner className="text-zinc-50" />
        </div>
      )}
      {allowsRefetch && (
        <a className="text-red-400 underline" onClick={() => refetch()}>
          Retry
        </a>
      )}
    </div>
  );
};
export default ErrorFallback;
