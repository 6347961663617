import { RouteObject } from 'react-router-dom';
import LearningJourneysListPage from './components/LearningJourneysListPage/LearningJourneysListPage';
import LearningJourneyDetailPage from './components/LearningJourneyDetailPage/LearningJourneyDetailPage';

const routes: RouteObject[] = [
  {
    path: 'learning-journeys',
    element: <LearningJourneysListPage />,
  },
  {
    path: 'learning-journeys/:id',
    element: <LearningJourneyDetailPage />,
  },
];

export { routes };
