import { useMutation } from '@tanstack/react-query';
import { completeModule } from '../api/courses';

const useCompletedModuleMutation = () => {
  const { data, error, isLoading, isError, mutate } = useMutation({
    mutationFn: async (moduleId: number) => {
      const response = await completeModule(moduleId);
      return response;
    },
  });
  return { data, error, isLoading, isError, mutate };
};

export default useCompletedModuleMutation;
