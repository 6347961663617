export const Roles = {
  LEARNER: 'User',
  MANAGER: 'Manager',
  LEADER: 'LEADER',
  ADMIN: 'ADMIN',
};

export const REQUEST_TYPE = {
  NEW_TEAM: 'NEW_TEAM',
  EDIT_TEAM: 'EDIT_TEAM',
};
