import HasAccess from '../../shared/components/HasAccess';
import { USHG_HQ_APP_ROLE } from '../../../constants';
import { LandingNotFound } from '../../../components/404Pages/LandingNotFound';
import { Outlet } from 'react-router-dom';
import useSessionTracker from '../../../hooks/use-session-tracker-hook';

const CourseManager = () => {
  useSessionTracker();
  return (
    <HasAccess roles={[USHG_HQ_APP_ROLE.USHG_ADMIN]} Fallback={<LandingNotFound />}>
      <Outlet />
    </HasAccess>
  );
};

export default CourseManager;
