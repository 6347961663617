import { useQuery } from '@tanstack/react-query';
import { PERFORMANCE_REPORT_TYPES, REPORTS_STALE_TIME } from '../../shared/constants';
import { getMostPopularCourses } from '../api';
import { MostPopularCoursesFilter } from '../types/reports/reports';

interface Props {
  filters: Partial<MostPopularCoursesFilter>;
  isEnabled: boolean;
}
const useMostPopularCoursesQuery = ({ filters, isEnabled }: Props) => {
  const query = useQuery(
    [
      {
        filters: filters,
        scope: 'dashboard-reports',
        item: PERFORMANCE_REPORT_TYPES.MOST_POPULAR_COURSES,
      },
    ],
    async ({ queryKey }) => {
      const { filters } = queryKey[0];
      return await getMostPopularCourses(filters);
    },
    {
      staleTime: REPORTS_STALE_TIME,
      enabled: isEnabled,
    }
  );
  return query;
};

export default useMostPopularCoursesQuery;
