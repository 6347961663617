import { useQuery } from '@tanstack/react-query';
import { getUserFeedback } from '../api/courses';

interface Props {
  itemId: string | undefined;
  type: string;
}

const useGetUserFeedbackQuery = ({ itemId, type }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery({
      queryKey: [
        {
          scope: 'user',
          item: 'feedback',
          itemId,
          type,
        },
      ],
      queryFn: ({ queryKey }) => {
        const { itemId, type } = queryKey[0];
        if (itemId) {
          return getUserFeedback(itemId, type);
        } else {
          return null;
        }
      },
      enabled: itemId != undefined,
    });

  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch };
};

export default useGetUserFeedbackQuery;
