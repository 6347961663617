import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { SPEAKER_API_PATHS } from '../constants/common';
import {
  CreateSpeakerInMultiLangPayload,
  CreateSpeakerPayload,
  CreateSpeakerResponse,
  DeleteSpeakerPayload,
  DeleteSpeakerResponse,
  GetSpeakerApiResponse,
  GetSpeakerByIdResponse,
  UpdateSpeakerPayload,
  UpdateSpeakerResponse,
} from '../types/GetSpeakerApiResponse';
import { GetSpeakerFilterQuery } from '../types/GetSpeakerFilterQuery';

export const getAllSpeakers = async (
  filters: GetSpeakerFilterQuery
): Promise<GetSpeakerApiResponse> => {
  const queryString = qs.stringify(filters);
  const path = SPEAKER_API_PATHS.GET_SPEAKERS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

export const deleteSpeaker = async (
  payload: DeleteSpeakerPayload
): Promise<DeleteSpeakerResponse> => {
  const queryString = qs.stringify({ speakerId: payload.id });
  const path = SPEAKER_API_PATHS.DELETE_SPEAKERS;
  const response = await API.del(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response;
};

export const createSpeaker = async (args: CreateSpeakerPayload): Promise<CreateSpeakerResponse> => {
  const { bio, firstName, lastName, jobTitle, picture } = args;
  const body = {
    bio,
    firstName,
    lastName,
    jobTitle,
    picture,
  };
  const path = SPEAKER_API_PATHS.CREATE_SPEAKER;
  return await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body,
    response: true,
    withCredentials: true,
  });
};

export const createSpeakerInMultiLang = async (
  payload: CreateSpeakerInMultiLangPayload
): Promise<CreateSpeakerResponse> => {
  const path = SPEAKER_API_PATHS.CREATE_SPEAKER_IN_MULTI_LANG;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body: payload,
    response: true,
    withCredentials: true,
  });
  return response.data;
};

export const updateSpeaker = async (
  payload: UpdateSpeakerPayload
): Promise<UpdateSpeakerResponse> => {
  const path = SPEAKER_API_PATHS.UPDATE_SPEAKER;
  const response = await API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body: payload,
    response: true,
    withCredentials: true,
  });
  return response.data;
};

export const getSpeakerById = (id: number, language: string): Promise<GetSpeakerByIdResponse> => {
  const path = SPEAKER_API_PATHS.GET_SPEAKER_BY_ID;
  const queryString = qs.stringify({ speakerId: id, language });
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    withCredentials: true,
  });
};
