import { intlFormatDistance } from 'date-fns';
import { DEFAULT_PREFERRED_LANGUAGE } from '../constants/user';

const getTimeAgo = (
  timestamp?: Date,
  style: 'short' | 'long' | 'narrow' = 'short',
  language = DEFAULT_PREFERRED_LANGUAGE
) => {
  if (!timestamp) {
    return '';
  }

  try {
    const agoTime = intlFormatDistance(timestamp, Date.now(), {
      style,
      locale: language,
    });
    return agoTime;
  } catch (error) {
    console.error(`Error returning timeago for timestamp ${timestamp}`, error);
    return '';
  }
};

const formatSecondsToMMSS = (timeInSeconds: number) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds - minutes * 60;
  const mmssTimeString = `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
  return mmssTimeString;
};

export { getTimeAgo, formatSecondsToMMSS };
