import clsx from 'clsx';
import React from 'react';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  className?: string;
  type: 'checkbox';
};

const CheckboxInput = React.forwardRef(
  ({ className, ...otherProps }: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    return (
      <input
        className={clsx(
          `grow-0 cursor-pointer rounded-[4px] border border-zinc-700 bg-zinc-900 text-base-brand shadow-[0px_0px_6px_rgba(0,0,0,0.08)] checked:bg-base-brand`,
          className
        )}
        // default a text but could be overrided to password or other types
        ref={ref}
        {...otherProps}
      ></input>
    );
  }
);

CheckboxInput.displayName = 'CheckboxInput';

export default CheckboxInput;
