import { ONBOARDING_API_PATHS, ENTERPRISE_ONBOARDING_TYPE } from '../constants';
import qs from 'qs';
import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { CreateGoalsResponse, Goal } from '../types';

/**
 * Get Goals based on Goal Type
 */
interface GetGoalsArgs {
  type: keyof typeof ENTERPRISE_ONBOARDING_TYPE;
  language: string;
}

const getAllGoals = async (params: GetGoalsArgs): Promise<Goal[]> => {
  const queryString = qs.stringify(params);
  const path = ONBOARDING_API_PATHS.GOALS.GET_ALL_GOALS;

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${queryString}`, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const createGoals = async (selectedGoals: Goal[]): Promise<CreateGoalsResponse> => {
  const body = selectedGoals;
  const path = ONBOARDING_API_PATHS.GOALS.CREATE_GOALS;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    body,
    withCredentials: true,
  });

  return response.data;
};

export { getAllGoals, createGoals };
