import React from 'react';

type Props = React.SVGProps<SVGSVGElement>;

const Hole = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="121"
      height="37"
      viewBox="0 0 121 37"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_i_5089_85206)">
        <ellipse cx="60.5" cy="18.75" rx="60" ry="18" fill="#18181B" />
      </g>
      <defs>
        <filter
          id="filter0_i_5089_85206"
          x="0.5"
          y="0.75"
          width="120"
          height="36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5089_85206" />
        </filter>
      </defs>
    </svg>
  );
};

export default Hole;
