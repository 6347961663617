import { Option } from '../../../components/Select';
export const ORG_PAGE_SIZE_INCREMENT = 10;
export const ORG_MAX_PAGE_SIZE = 50;
export const ORG_MIN_PAGE_SIZE = 10;
export const ORG_DEFAULT_PAGE = 1;

export const ORGANISATION_STATUS_VALUES: Option[] = [
  {
    display: 'All',
    value: 'all',
  },
  {
    display: 'In Risk',
    value: 'Unhealthy',
  },
  {
    display: 'Healthy',
    value: 'Healthy',
  },
];
export const ORG_API_PATH = {
  GET_ORGS: '/users/hq/v1/organizations',
  ADD_ORG: '/users/hq/v1/invite/organization',
  GET_COUPONS: '/payments/hq/v1/coupons',
  GET_ORG_TEAMS: (id: string) => `/users/hq/v1/organization/${id}/teams`,
  GET_ORG_INVOICES: (id: string) => `/payments/hq/v1/customer/${id}/invoices`,
  GET_ORG_NAME: (id: string) => `/users/hq/v1/organization/${id}`,
  GET_ORG_STATISTICS: (id: string) => `/reports/hq/v1/tenant/statistic/${id}`,
};
