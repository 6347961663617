import React from 'react';
import { twMerge } from 'tailwind-merge';

type Option = {
  label: string;
  value: string | number | string[] | null;
};

type SelectFieldProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  className?: string;
  type: 'select';
  options: Option[];
  placeholder?: string;
  defaultValue?: string;
  hasError?: boolean;
};

const DropdownInput = React.forwardRef(
  (props: SelectFieldProps, ref: React.ForwardedRef<HTMLSelectElement>) => {
    const { options, className, placeholder, hasError, defaultValue = '', ...otherProps } = props;
    return (
      <>
        <select
          placeholder={placeholder}
          className={twMerge(
            `w-full grow rounded-[4px] border p-2 text-sm text-white
            ${className}
            ${
              hasError
                ? '!border-error-border bg-error-bg invalid:border-error-border invalid:text-white focus:!border-error-border focus:outline-none focus:invalid:border-error-border'
                : 'border-zinc-700 bg-zinc-900 focus-within:bg-zinc-800 focus:bg-zinc-800'
            }`
          )}
          defaultValue={defaultValue}
          {...otherProps}
          ref={ref}
        >
          {options?.map(({ label, value }) => (
            <option
              disabled={value === '' || value === null}
              key={label?.toString()}
              value={value ?? (null || '')}
              className="bg-zinc-800"
            >
              {label}
            </option>
          ))}
        </select>
      </>
    );
  }
);
DropdownInput.displayName = 'DropdownInput';
export default DropdownInput;
