import { useInfiniteQuery } from '@tanstack/react-query';
import { useReduxAuthState } from '../../../hooks';
import { listAllLearningJourneyCourses } from '../api/learning-journey';
import { DEFAULT_PAGE_SIZE } from '../constants/learning-journey';
import { QUERY_KEYS, QUERY_STALE_TIME_MS } from '../helpers/tanstack-query';

type Props = {
  id: string;
};

const useListLearningJourneyCourses = ({ id }: Props) => {
  const auth = useReduxAuthState();

  if (!auth.isAuthenticated) {
    throw new Error('User should be authenticated');
  }

  const query = useInfiniteQuery({
    queryKey: QUERY_KEYS.listLearningJourneyCourses({
      username: auth.user.username,
      id: id,
    }),
    queryFn: ({ pageParam = 1, queryKey }) => {
      const { id } = queryKey[0];

      return listAllLearningJourneyCourses({
        pageSize: DEFAULT_PAGE_SIZE.JOURNEYS.LIST.COURSES,
        pageOffset: (pageParam - 1) * DEFAULT_PAGE_SIZE.JOURNEYS.LIST.COURSES,
        journeyId: id,
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      const fetchedCoursesCount = allPages.length * DEFAULT_PAGE_SIZE.JOURNEYS.LIST.COURSES;
      const totalCoursesCount = lastPage.totalCount;

      //   This means we have fetched all the learning journeys
      if (fetchedCoursesCount >= totalCoursesCount) {
        return undefined;
      }

      const nextPageIndex = allPages.length + 1;
      return nextPageIndex;
    },
    staleTime: QUERY_STALE_TIME_MS.listLearningJourneyCourses,
  });

  return query;
};

export default useListLearningJourneyCourses;
