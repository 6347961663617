import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { INDIVIDUAL_API_PATHS } from '../constants/common';
import { GetIndividualApiResponse, GetUserStatsResponse } from '../types/GetIndividualApiResponse';
import { GetIndividualFilterQuery } from '../types/GetIndividualFilterQuery';

export const getAllIndividuals = async (
  filters: GetIndividualFilterQuery
): Promise<GetIndividualApiResponse> => {
  const queryString = qs.stringify(filters);
  const path = INDIVIDUAL_API_PATHS.GET_INDIVIDUALS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

export const getUserStats = async (username: string): Promise<GetUserStatsResponse> => {
  let path = INDIVIDUAL_API_PATHS.GET_USER_STATS;
  path = path.replace('{username}', username);
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};
