import clsx from 'clsx';
import { useStrapiOrganizationData } from '../../../../../../organisation/hooks/useStrapiOrganizationData';
import { HEALTH_RISK_STATUS } from '../../../../../constants';

interface Props {
  status: string;
}

interface GenericStatusCellProps {
  containerClassName: string;
  dotClassName: string;
  display: string;
}

// Generic Status Cell

const GenericStatusCell = (props: GenericStatusCellProps) => {
  const { containerClassName, dotClassName, display } = props;
  return (
    <div
      className={clsx(
        'flex w-[75px] items-center gap-[9px] rounded-[100px] py-[2px] pr-2 pl-1.5',
        containerClassName
      )}
    >
      <span className={clsx('h-1.5 w-1.5 rounded-full', dotClassName)}></span>
      <span className="text-xs font-normal">{display}</span>
    </div>
  );
};

const HealthRiskCell = ({ status }: Props) => {
  const { TableProps } = useStrapiOrganizationData().organisationTable;
  const { inRiskStatusLabel, healthyStatusLabel, noDataLabel } = TableProps;
  switch (status) {
    case HEALTH_RISK_STATUS.UNHEALTHY:
      return (
        <GenericStatusCell
          containerClassName="bg-amber-900 text-amber-100 whitespace-nowrap"
          dotClassName="bg-amber-100"
          display={inRiskStatusLabel}
        />
      );
    case HEALTH_RISK_STATUS.HEALTHY:
      return (
        <GenericStatusCell
          containerClassName="bg-emerald-900 text-emerald-100"
          dotClassName="bg-emerald-100"
          display={healthyStatusLabel}
        />
      );

    default:
      return (
        <GenericStatusCell
          containerClassName="bg-zinc-900 text-zinc-100  whitespace-nowrap"
          dotClassName="bg-zinc-100"
          display={noDataLabel}
        />
      );
  }
};

export default HealthRiskCell;
