import React, { useContext, useState } from 'react';
import FilterWidgets from '../FilterWidgets';
import durationContext from '../../../shared/context/durationContext';
import { setReportsDuration } from '../../helpers/filterWidget';
import { PERIOD, REPORTS_INTERVAL } from '../../../shared/constants';
import { Duration } from '../../../shared/types/reports';
import { useStrapiReportsData } from '../../../shared/hooks/useStrapiReportData';
import clsx from 'clsx';
import '../../../../styles/header.css';
import TeamFilter from './Filter/TeamFilter';
import HasAccess from '../../../shared/components/HasAccess';
import { USHG_HQ_APP_ROLE } from '../../../../constants';

interface PageHeaderProps {
  reportsHeader: string;
  queryParam?: string;
  individualPerformanceTabLabel?: string;
  className?: string;
}

const PageHeader = ({
  reportsHeader,
  queryParam,
  individualPerformanceTabLabel,
  className,
}: PageHeaderProps) => {
  const { DurationDropDown, DurationLabels } = useStrapiReportsData().topNav;
  const { lastMonthLabel, last3MonthLabel, last6MonthLabel, dropDownLabel } = DurationDropDown;
  const { daysLabel, weeksLabel, monthsLabel } = DurationLabels;

  const REPORTS_X_AXIS_LABEL = {
    DAYS: daysLabel,
    WEEKS: weeksLabel,
    MONTHS: monthsLabel,
  } as const;

  const DURATION_RANGE = [
    {
      display: lastMonthLabel,
      value: PERIOD.LAST_MONTH,
      interval: REPORTS_INTERVAL.WEEK,
      xAxis: REPORTS_X_AXIS_LABEL.WEEKS,
    },
    {
      display: last3MonthLabel,
      value: PERIOD.LAST_THREE_MONTHS,
      interval: REPORTS_INTERVAL.FORTNIGHT,
      xAxis: REPORTS_X_AXIS_LABEL.WEEKS,
    },
    {
      display: last6MonthLabel,
      value: PERIOD.LAST_SIX_MONTHS,
      interval: REPORTS_INTERVAL.MONTH,
      xAxis: REPORTS_X_AXIS_LABEL.MONTHS,
    },
  ];
  const { setDuration } = useContext(durationContext);
  const [dropdownValue, setDropdownValue] = useState<string>(DURATION_RANGE[0].value);

  const handleFilterChange = (event: any) => {
    setDropdownValue(event.target.value);
    const fromDate = setReportsDuration(event.target.value);
    const selectedDropDown = DURATION_RANGE.find(
      (duration) => duration.value === event.target.value
    );
    const duration = { fromDate, ...selectedDropDown };
    setDuration(duration as Duration);
  };

  return (
    <header
      className={clsx(
        'flex w-full flex-wrap items-center justify-between bg-card-bg px-3 pt-6 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9',
        className
      )}
    >
      <span className="header-title">{reportsHeader}</span>
      {/* hides duration dropdown for Individual Report */}
      <div className="flex w-full basis-5 flex-wrap gap-1 xs:flex-nowrap">
        <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]} Fallback={null}>
          <div className="w-full min-w-[10rem]">
            <TeamFilter />
          </div>
        </HasAccess>
        {queryParam &&
          individualPerformanceTabLabel &&
          !queryParam?.includes(individualPerformanceTabLabel) && (
            <FilterWidgets
              value={dropdownValue}
              options={DURATION_RANGE}
              onChange={handleFilterChange}
              label={dropDownLabel}
            />
          )}
      </div>
    </header>
  );
};

export default PageHeader;
