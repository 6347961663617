import toast from 'react-hot-toast';
import { useAppDispatch } from '../../../hooks';
import { sendTeamRequests, SendTeamRequestsArgs } from '../api';
import { SECTIONS } from '../constants/section-name';
import { triggerSectionDataRefresh } from '../slices/team-dispatch.helper';
import { useStrapiTeamData } from './useStrapiTeamData';
import { showToast } from '../../shared/helpers/toast';

export const useSendTeamsRequest = () => {
  const dispatch = useAppDispatch();
  const { leaderManagerToastMessages } = useStrapiTeamData();
  const {
    leaderManagerSuccessToastMessage,
    leaderManagerFailureToastMessage,
    leaderManagerActionProcessingToastMessage,
  } = leaderManagerToastMessages;
  const sendTeamsRequest = async (teamData: SendTeamRequestsArgs, refreshSectionName: SECTIONS) => {
    // used to replace toasts in place
    let toastId;

    try {
      toastId = showToast({
        variant: 'info',
        title: leaderManagerActionProcessingToastMessage,
      });

      await sendTeamRequests(teamData);

      // Trigger Refresh
      triggerSectionDataRefresh(refreshSectionName, dispatch);

      // Show success toast on success
      toast.remove(toastId);
      showToast({
        variant: 'success',
        title: leaderManagerSuccessToastMessage,
      });
    } catch (error: any) {
      toast.remove(toastId);
      showToast({
        variant: 'error',
        title: leaderManagerFailureToastMessage,
      });
    }
  };
  return { sendTeamsRequest };
};
