import { ErrorDisplay } from '../DataTables';
import { Page } from '../Pages';

interface Props {
  refetch: () => void;
  isRefetching?: boolean;
  allowsRefetch?: boolean;
  className?: string;
  message: React.ReactNode;
  type?: string;
}

const ErrorPage = (props: Props) => {
  const { refetch, isRefetching, allowsRefetch, className, message, type } = props;
  return (
    <Page showIllustrator={true}>
      <div className="flex h-screen w-full flex-col px-12 py-8">
        <div className="w-full rounded-lg border border-zinc-700 bg-component-bg-dark py-20 px-8">
          <ErrorDisplay
            refetch={refetch}
            isRefetching={isRefetching}
            allowsRefetch={allowsRefetch}
            className={className}
            message={message}
            type={type}
          />
        </div>
      </div>
    </Page>
  );
};

export default ErrorPage;
