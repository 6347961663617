import { TabGroup } from '../../../../components/Tabs';
import { useStrapiSettingsData } from '../../hooks/useStrapiSettingsData';
import { UserProfileMain } from '../Form';
import { PageHeader } from '../common';
import { NotificationPreferenceSettingsMain } from '../shared';
import PlatformSettings from './components/PlatformSettings';
import { TAB_GROUPS } from '../../../../constants/tabs-tag-constants';

const USHGAdminSettingsPage = () => {
  const { tabs } = useStrapiSettingsData();
  const { profileTab, notificationTab, platformTab } = tabs;

  const TABS = [
    { title: profileTab, tag: TAB_GROUPS.PROFILE, children: <UserProfileMain /> },
    {
      title: notificationTab,
      tag: TAB_GROUPS.NOTIFICATIONS,
      children: <NotificationPreferenceSettingsMain />,
    },
    { title: platformTab, tag: TAB_GROUPS.PLATFORM, children: <PlatformSettings /> },
  ];

  const { title, commonDescription } = useStrapiSettingsData().topNav;

  return (
    <main id="main-content" className="flex flex-col">
      <PageHeader settingsHeader={title} settingsSubHeader={commonDescription} />
      <TabGroup tabs={TABS} tabClassName="px-3 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9" />
    </main>
  );
};

export default USHGAdminSettingsPage;
