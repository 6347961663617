import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const isAuthenticatedSelector = (state: RootState) => state.auth.isAuthenticated;

const AuthBaseLayout = () => {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);

  return isAuthenticated ? <Navigate to="/dashboard" replace={true} /> : <Outlet />;
};

export default AuthBaseLayout;
