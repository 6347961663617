import React from 'react';
import { Badge } from 'react-daisyui';
import { ComponentColor, ComponentSize, IComponentBaseProps } from 'react-daisyui/dist/types';

type BadgeProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'color'> &
  IComponentBaseProps & {
    variant?: 'outline';
    size?: ComponentSize;
    color?: ComponentColor;
    responsive?: boolean;
  };
type CustomProps = {
  label: string;
  className?: string;
  buttonIcon?: React.ReactNode;
  buttonIconFront?: boolean;
  buttonIconBack?: boolean;
  badgeSRLabel?: string;
};
type Props = BadgeProps & CustomProps;
export const BadgeComponent = (props: Props) => {
  const {
    label,
    className,
    buttonIcon,
    buttonIconFront,
    buttonIconBack,
    badgeSRLabel,
    ...otherProps
  } = props;
  return (
    <Badge aria-label={badgeSRLabel} className={className} {...otherProps}>
      {buttonIconFront && buttonIcon}
      <span className="mx-1">{label}</span>
      {buttonIconBack && buttonIcon}
    </Badge>
  );
};
