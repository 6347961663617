import { USHG_HQ_APP_ROLE } from '../../../constants';
import HasAccess from '../../shared/components/HasAccess';
import EnterpriseAdminDashboard from './EnterpriseAdminDashboard';
import EnterpriseLeaderDashboard from './EnterpriseLeaderDashboard';
import USHGAdminDashboard from './USHGAdminDashboard';
import EnterpriseManagerDashboard from './EnterpriseManagerDashboard';
import EnterpriseLearnerDashboard from './EnterpriseLearnerDashboard';
import IndividualLearnerDashboard from './IndividualLearnerDashboard';
import { SideBarLayoutPageContentWrapper } from '../../../components/Wrapper';
import { LAZY_LOAD_DASHBOARD } from '../constants';
import SizzleVideoCompotent from './common/SizzleVideoCompotent';
import DashboardReportsFilterProvider from '../../shared/providers/DashboardReportsFilterProvider';
import useSessionTracker from '../../../hooks/use-session-tracker-hook';

const Dashboard = () => {
  useSessionTracker();
  return (
    <DashboardReportsFilterProvider>
      <SizzleVideoCompotent></SizzleVideoCompotent>
      <SideBarLayoutPageContentWrapper className="pt-3 md:pt-5">
        {/* Enterprise Leader Dashboard  */}
        <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEADER]} Fallback={null}>
          <EnterpriseLeaderDashboard lazyLoad={LAZY_LOAD_DASHBOARD} />
        </HasAccess>
        {/* Enterprise Leader Dashboard  */}
        <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER]} Fallback={null}>
          <EnterpriseManagerDashboard lazyLoad={LAZY_LOAD_DASHBOARD} />
        </HasAccess>
        {/* Enterprise Admin Dashboard  */}
        <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]} Fallback={null}>
          <EnterpriseAdminDashboard lazyLoad={LAZY_LOAD_DASHBOARD} />
        </HasAccess>
        {/* USHG Admin Dashboard  */}
        <HasAccess roles={[USHG_HQ_APP_ROLE.USHG_ADMIN]} Fallback={null}>
          <USHGAdminDashboard lazyLoad={LAZY_LOAD_DASHBOARD} />
        </HasAccess>
        {/* Enterprise Learner Dashboard  */}
        <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER]} Fallback={null}>
          <EnterpriseLearnerDashboard lazyLoad={LAZY_LOAD_DASHBOARD} />
        </HasAccess>
        {/* Individual Learner Dashboard  */}
        <HasAccess roles={[USHG_HQ_APP_ROLE.INDIVIDUAL_USER]} Fallback={null}>
          <IndividualLearnerDashboard lazyLoad={LAZY_LOAD_DASHBOARD} />
        </HasAccess>
      </SideBarLayoutPageContentWrapper>
    </DashboardReportsFilterProvider>
  );
};

export default Dashboard;
