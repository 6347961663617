import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';
import { Toast } from '../../../../../../../../components/Toast';
import { STRAPI_PLACEHOLDER_CODE } from '../../../../../../../../constants/strapi';
import useCountDown from '../../../../../../../../hooks/use-count-down';
import { formatSecondsToMMSS } from '../../../../../../../../utils';
import {
  cognitoUserContext,
  loginAuthChallengeContext,
  userLoginCredentialsContext,
} from '../../../../../../context';
import { useStrapiLoginMFAContent } from '../../../../../../hooks';

type ResendMFACodeState = 'idle' | 'in-progress';

/**
 * To resend SMS MFA Code.
 */
const ResendMFACode = () => {
  //   state
  const [resendMFACodeState, setResendMFACodeState] = useState<ResendMFACodeState>('idle');
  //   state

  // context
  const { loginCredentials } = useContext(userLoginCredentialsContext);
  const { setCognitoUser } = useContext(cognitoUserContext);
  const { setAuthChallenge } = useContext(loginAuthChallengeContext);
  // context

  // strapi

  const { SMSMFA: smsMFACMS } = useStrapiLoginMFAContent();

  //   strapi

  // hooks

  const {
    timeRemainingInSeconds,
    state: countdownState,
    reset,
  } = useCountDown({
    countdownInSeconds: smsMFACMS.timeBetweenResend,
  });

  // hooks

  // derived

  const resendInTimeLabelSubstituted = smsMFACMS.resendAllowedInLabel.replace(
    STRAPI_PLACEHOLDER_CODE.TIME,
    formatSecondsToMMSS(timeRemainingInSeconds)
  );

  // derived

  // handlers

  /**
   * Login again which would send the MFA token again.
   */
  const handleResendCode = async () => {
    // We should have login credentials at this point
    if (!loginCredentials) {
      return;
    }

    try {
      // Init toast
      toast.custom(
        (t) => {
          return <Toast toastInstance={t} Title={smsMFACMS.resendingCodeToast} variant="info" />;
        },
        {
          duration: 3000,
        }
      );
      setResendMFACodeState('in-progress');

      // sign in again to send the sms mfa code
      const cognitoUser = await Auth.signIn({
        username: loginCredentials.username,
        password: loginCredentials.password,
      });

      const challengeName = cognitoUser.challengeName;

      // challenge should be SMS_MFA for now. If there is a challenge
      setAuthChallenge(challengeName);
      setCognitoUser(cognitoUser);

      reset();
      // Success toast
      toast.custom(
        (t) => {
          return (
            <Toast
              toastInstance={t}
              Title={smsMFACMS.resendingMFACodeSuccessToast}
              variant="success"
            />
          );
        },
        {
          duration: 3000,
        }
      );
    } catch (error) {
      console.error('Error re-sending sms mfa code');
      // Failure toast
      toast.custom(
        (t) => {
          return (
            <Toast toastInstance={t} Title={smsMFACMS.resendingMFACodeErrorToast} variant="error" />
          );
        },
        {
          duration: 3000,
        }
      );
    } finally {
      setResendMFACodeState('idle');
    }
  };

  // handlers

  return (
    <div className="flex gap-1 text-sm">
      <button
        onClick={handleResendCode}
        // when the count down is in progress and we are resending the mfa code
        disabled={countdownState === 'in-progress' || resendMFACodeState === 'in-progress'}
        className="disabled:text-zinc-400"
      >
        {smsMFACMS.resendCodeBtnLabel}
      </button>
      <span
        className={twMerge(
          clsx('tabular-nums', { 'text-zinc-400': countdownState === 'complete' })
        )}
      >
        {resendInTimeLabelSubstituted}
      </span>
    </div>
  );
};

export default ResendMFACode;
