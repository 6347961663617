import clsx from 'clsx';
import ImageWithAcronymFallback from '../../../../../../components/ImageWithAcronymFallback';

interface AvatarAndNameCellProps {
  name?: string;
  row?: any;
  className?: string;
}

const GenericNameCell = (props: AvatarAndNameCellProps) => {
  const { className, row } = props;
  const name = row.original.firstName + ' ' + row.original.lastName;
  return (
    <div className={clsx('flex items-center gap-2', className)}>
      <ImageWithAcronymFallback
        acronymWord={name}
        className="h-6 w-6 rounded-full object-cover object-center"
        acronymLogoClassName="h-6 w-6 rounded-full text-xs"
        imageKey={row.original.picture}
        alt={`${name}`}
        aria-label={name}
      />
      <span className="line-clamp-1">{name}</span>
    </div>
  );
};

export default GenericNameCell;
