import {
  AverageWeeklyHoursViz,
  CoursePerformanceViz,
  HoursLoggedInViz,
} from '../../../shared/components/charts';
import {
  TeamLeaderboardTable,
  TopEarnedBadgesTable,
} from '../../../shared/components/Reports/Tables';
import { useStrapiReportsData } from '../../../shared/hooks/useStrapiReportData';
import Calendar from '../Calendar';
import { RecommendedCourses, UserGreeting } from '../common';
import MyBadgesDashBoardComponent from '../common/MyBadgesDashBoardComponent';
import { AssignedCourses } from '../Courses';
import CourseCardWrapper from '../Courses/CourseCardWrapper/CourseCardWrapper';
import { dashboardOptions } from '../../types/dashboard';
import { USHG_HQ_APP_ROLE } from '../../../../constants';

const EnterpriseManagerDashboard = ({ lazyLoad = true }: dashboardOptions) => {
  const { TeamCoursePerformance, TeamHoursLogged, AverageWeeklyHours, ReportTooltipContent } =
    useStrapiReportsData();

  return (
    <main
      id="main-content"
      className="relative mb-4 grid grow auto-rows-min grid-cols-8 gap-x-3 gap-y-2 sm:gap-y-3 lg:gap-x-[13px] lg:gap-y-4 xl:gap-y-5"
    >
      <UserGreeting className="col-span-8 col-start-1 row-start-1 lg:col-span-6" />
      <MyBadgesDashBoardComponent
        className="col-span-8 col-start-1 row-start-2 lg:col-span-6 lg:row-span-2"
        lazyLoad={lazyLoad}
      />
      <CourseCardWrapper
        lazyLoad={lazyLoad}
        className="col-span-8 col-start-1 row-start-3 overflow-hidden md:row-start-3 lg:col-span-6 lg:row-span-2 lg:row-start-4"
      />
      <Calendar className="col-span-8 col-start-1 row-start-4 md:row-start-4 lg:col-span-2 lg:col-start-7 lg:row-span-4 lg:row-start-1" />

      <HoursLoggedInViz
        lazyLoad={lazyLoad}
        key1={TeamHoursLogged.key1}
        key2={TeamHoursLogged.key2}
        xAxisLabel={TeamHoursLogged.xAxisLabel}
        yAxisLabel={TeamHoursLogged.yAxisLabel}
        emptyMessage={TeamHoursLogged.emptyChart}
        errorMessage={TeamHoursLogged.errorChart}
        emptyChartIconUrl={TeamHoursLogged.emptyChartIcon.data.attributes.url}
        heading={TeamHoursLogged.title}
        className="col-span-8 col-start-1 row-start-5 overflow-hidden md:row-start-5 lg:col-span-6 lg:row-start-6"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
      />

      <TopEarnedBadgesTable
        lazyLoad={lazyLoad}
        className="col-span-8 col-start-1 row-start-6 overflow-hidden md:col-span-4 md:row-start-6 lg:col-span-2 lg:col-start-7 lg:row-span-4 lg:row-start-7"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
      />
      <TeamLeaderboardTable
        lazyLoad={lazyLoad}
        className="col-span-8 row-start-7 overflow-hidden md:col-span-4 md:row-start-6 lg:col-span-2 lg:col-start-7 lg:row-span-2 lg:row-start-5"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
      />
      <CoursePerformanceViz
        lazyLoad={lazyLoad}
        key1={TeamCoursePerformance.key1}
        key2={TeamCoursePerformance.key2}
        key3={TeamCoursePerformance.key3}
        emptyMessage={TeamCoursePerformance.emptyChart}
        errorMessage={TeamCoursePerformance.errorMessage}
        emptyChartIconUrl={TeamCoursePerformance.emptyChartIcon.data.attributes.url}
        heading={TeamCoursePerformance.title}
        className="col-span-8 col-start-1 row-start-[8] overflow-hidden md:col-span-4 md:row-start-7 lg:col-span-3 lg:row-span-4"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
      />
      <AverageWeeklyHoursViz
        lazyLoad={lazyLoad}
        errorChartmessage={TeamCoursePerformance.emptyChart}
        heading={AverageWeeklyHours.title}
        className="col-span-8 col-start-1 row-start-[9] overflow-hidden md:col-span-4 md:row-start-7 lg:col-span-3 lg:col-start-4 lg:row-span-4"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER}
      />
      <AssignedCourses
        lazyLoad={lazyLoad}
        className="col-span-8 row-start-[10] overflow-hidden md:row-start-[8] lg:row-start-[11]"
      />
      <RecommendedCourses
        lazyLoad={lazyLoad}
        className="col-span-8 row-start-[11] overflow-hidden md:row-start-[9] lg:row-start-[12]"
      />
    </main>
  );
};

export default EnterpriseManagerDashboard;
