import { useQuery } from '@tanstack/react-query';
import { getCourseHistory } from '../api/getCourseHistory';
import { PartialUsersStatsFilter } from '../types/interface';

interface Props {
  username: PartialUsersStatsFilter;
}

const useCourseHistoryQuery = ({ username }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching } =
    useQuery(
      [
        {
          scope: 'useCourseHistoryQuery',
          username: username,
        },
      ],
      async () => {
        return getCourseHistory(username);
      },
      {
        keepPreviousData: true,
        // TODO: to be decided We are storing it in cache for 30 seconds
        staleTime: 30000,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching };
};

export default useCourseHistoryQuery;
