import { format } from 'date-fns';
import { CaretLeft, CaretRight } from 'phosphor-react';
import { BasicSpinner } from '../../../../components/Spinners';
import { getLocale } from '../../../../utils';
import { useLanguage } from '../../../shared/context/languageContext';

interface Props {
  // currently viewing date
  viewing: Date;
  viewNextMonth: () => void;
  viewPreviousMonth: () => void;
  isLoading?: boolean;
}

const CalendarHeader = (props: Props) => {
  const { viewing, viewNextMonth, viewPreviousMonth, isLoading } = props;
  const { language } = useLanguage();
  return (
    <div className="flex items-center justify-between">
      <CaretLeft
        color="currentColor"
        className="fill-zinc-400 text-zinc-400 hover:cursor-pointer"
        onClick={viewPreviousMonth}
        size="18px"
      />
      <div className="flex items-center gap-2">
        <span className="pointer-events-none text-center text-xs font-semibold leading-[18px] text-zinc-200">
          {format(viewing, 'MMMM yyyy', { locale: getLocale(language) })}
        </span>
        {isLoading && <BasicSpinner className="!m-0 aspect-square h-4 w-4 text-zinc-200" />}
      </div>
      <CaretRight
        color="currentColor"
        className="fill-zinc-400 text-zinc-400 hover:cursor-pointer"
        onClick={viewNextMonth}
        size="18px"
      />
    </div>
  );
};

export default CalendarHeader;
