import { Confetti } from 'phosphor-react';

interface Props {
  message?: string;
  showConfetti?: boolean;
}

const defaultMessage = 'You are all caught up!';

const ReachedEndMessage = ({ message, showConfetti = true }: Props) => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-2 py-8">
      {showConfetti ? <Confetti color="currentColor" className="text-zinc-400" /> : false}
      <span className="text-xs font-normal text-white">{message ?? defaultMessage}</span>
    </div>
  );
};

export default ReachedEndMessage;
