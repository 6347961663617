import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SECTIONS } from '../constants';
type SectionDataRefreshPayload = {
  [key in SECTIONS]?: boolean;
};
interface CoursesSliceState {
  categoryName: string[];
  format: string[];
  speakers: string[];
  tags: string[];
  sortKey: string;
  sortOrder: string | boolean;
  courseRefreshState: SectionDataRefreshPayload;
}

const initialState: CoursesSliceState = {
  categoryName: [],
  format: [],
  speakers: [],
  tags: [],
  sortKey: '',
  sortOrder: 'ASC',
  courseRefreshState: {},
};

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setCategoryName: (state, event) => {
      state.categoryName = event.payload;
    },
    setFormat: (state, event) => {
      state.format = event.payload;
    },
    setSpeakers: (state, event) => {
      state.speakers = event.payload;
    },
    setTags: (state, event) => {
      state.tags = event.payload;
    },
    setSortKey: (state, action: PayloadAction<string>) => {
      state.sortKey = action.payload;
    },
    setSortOrder: (state, action: PayloadAction<any>) => {
      state.sortOrder = action.payload;
    },
    triggerCoursesRefresh: (state, action: PayloadAction<SectionDataRefreshPayload>) => {
      state.courseRefreshState = {
        ...state.courseRefreshState,
        ...action.payload,
      };
    },
  },
});

export const {
  setCategoryName,
  setFormat,
  setSpeakers,
  setTags,
  setSortKey,
  triggerCoursesRefresh,
  setSortOrder,
} = coursesSlice.actions;

export default coursesSlice.reducer;
