import { gql } from '@apollo/client';

const BadgeFragment = gql`
  fragment BadgeFragment on BadgeEntityResponse {
    data {
      attributes {
        HQ_ADMIN {
          ... on ComponentBadgesBadgesTable {
            Tableheader {
              badgeTableheader
              badgeDescriptionTableheader
              addedOnTableheader
              attainedByTableheader
              categoryTableheader
            }
            TableDropDownOptions {
              editOptionLabel
              deleteOptionLabel
              i18nOptionLabel
            }
            Category {
              title
              clearAllFiltersButton
              allCategoriesLabel
              focusAreaCategoriesLabel
              learningStreaksCategoriesLabel
              courseCompletionCategoriesLabel
              searchPlaceholder
            }
          }
          ... on ComponentBadgesToastMessage {
            badgeCreatedSuccess
            badgeCreatedFailure
            badgeUpdatedSuccess
            badgeUpdatedFailure
            badgeDeletedSuccess
            badgeDeletedFailure
            badgeCriteriaFailed
          }
          ... on ComponentBadgesErrorMessage {
            errorMessage
            emptyMessage
            clearAllFiltersButton
          }
          ... on ComponentBadgesModal {
            nameMinValue
            nameMinError
            nameMaxValue
            nameMaxError
            nameRequired
            descriptionRequired
            descriptionMinValue
            descriptionMinError
            descriptionMaxValue
            descriptionMaxError
            targetCourseRequired
            howToEarnRequired
            categoryRequired
            badgeImageRequired
            durationRequired
            targetMinValue
            targetMinError

            nameLabel
            namePlaceholder
            descriptionLabel
            descriptionPlaceholder
            targetCourseLabel
            targetCoursePlaceholder
            targetDaysLabel
            targetDaysPlaceholder
            durationLabel
            durationPlaceholder
            categoryLabel
            categoryPlaceholder
            howToEarnLabel
            howToEarnPlaceholder
            badgeImageType
            badgeImageLabel
            badgeImageMaxValue
            addBadgeTitle
            addBadgeButton
            editBadgeTitle
            editBadgeButton
            manageLanguagesTitle
            cancelButton
          }
          ... on ComponentBadgesDeleteBadge {
            title
            description
            deleteButton
            cancelButton
          }
          ... on ComponentBadgesHqTopNav {
            title
            addBadgeButton
          }
        }
        COMMON {
          ... on ComponentBadgesDashBoard {
            topEarnedBadges {
              title
              nameTableHeader
              earnedBytableHeader
              emptyMessage
              errorMessage
            }
            myBadges {
              badgesLabel
              viewAllButton
            }
          }
          ... on ComponentBadgesErrorMessages {
            noBadgesFound
            noEarnedbadges
            noToEarnBadges
            noInprogressBadges
            noLeaderBoardData
            noMostEarnedBadges
            noUpNextBadges
          }
          ... on ComponentBadgesSingleBadges {
            title
            HowToEarnTheBadgeInfo {
              title
              badgesInfoForSpecficCategory
              badgesInfoForCourseCompletion
              badgesInfoForLearningStreaks
            }
          }
          ... on ComponentBadgesSingleCourse {
            badgesLabel
            backButton
          }
          ... on ComponentBadgesMyBadges {
            earnedBadges {
              title
            }
            upNextBadges {
              title
            }
            leaderBoard {
              title
              nameTableHeader
              badgesTableHeader
            }
          }
        }
      }
    }
  }
`;
export { BadgeFragment };
