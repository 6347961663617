import { Button } from '../../../../../../components/Buttons';
import React, { useEffect, useState } from 'react';
import Dialog from '../../../../../../components/Dialog';
import AssessmentModal from '../../../AssessmentPage/components/AssessmentModal';
import { ArrowRight } from 'phosphor-react';
import { ModuleAssessmentRowItemType } from '../../../../types/courses';
import ModuleAssessmentList from './ModuleAssessmentList';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../../../../components/Toast';
import ModuleAssessmentModal from './ModuleAssessmentModal';
import { MODULE_KEYS, MODULE_TYPE } from '../../../../constants';
import { useUpdateModuleOrderMutation } from '../../../../hooks';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { useStrapiHQAdminCoursesData } from '../../../../hooks/useStrapiHQAdminCourseData';

interface ModuleAssessmentProps {
  courseModulesQuery: Record<string, any>;
  id: number;
}

const ModuleAssessment = ({ courseModulesQuery, id }: ModuleAssessmentProps) => {
  const [isModuleModalOpen, setIsModuleModalOpen] = useState<boolean>(false);
  const [isAssessmentModalOpen, setIsAssessmentModalOpen] = useState(false);
  const [showSave, setShowSave] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);
  const {
    toastMessages: {
      updateModuleOrderSuccessTitle,
      updateModuleOrderSuccessMessage,
      updateModuleOrderFailureTitle,
      updateModuleOrderFailureMessage,
    },
  } = useStrapiHQAdminCoursesData();
  const [initialModuleListState, setInitialModuleListState] = useState<
    ModuleAssessmentRowItemType[]
  >([]);
  const [modulesAssessments, setModulesAssessments] = useState<ModuleAssessmentRowItemType[]>([]);

  const resetOrder = () => {
    setModulesAssessments(
      initialModuleListState.map((item, index) => {
        item.data.sort = index + 1;
        return item;
      })
    );
  };

  const updateOrder = useUpdateModuleOrderMutation();

  const saveReOrder = () => {
    const reOrder: { id: string; sort: string }[] = modulesAssessments.map((item) => ({
      id: `${item.data.id}`,
      sort: `${item.data.sort}`,
    }));
    if (id)
      updateOrder.mutate(
        {
          courseId: `${id}`,
          modules: reOrder,
        },
        {
          onError: (error) => {
            console.error('Error while updating module order %o', error);
            resetOrder();
            toast.custom((t) => (
              <Toast
                variant="error"
                Title={updateModuleOrderFailureTitle}
                SubTitle={updateModuleOrderFailureMessage}
                toastInstance={t}
              />
            ));
          },
          onSuccess: () => {
            toast.custom((t) => {
              return (
                <Toast
                  variant="success"
                  Title={updateModuleOrderSuccessTitle}
                  SubTitle={updateModuleOrderSuccessMessage}
                  toastInstance={t}
                />
              );
            });
          },
          onSettled: () => {
            setShowSave(false);
            setReset(false);
            courseModulesQuery.refetch();
          },
        }
      );
  };

  useEffect(() => {
    if (courseModulesQuery.data) {
      const data = courseModulesQuery.data.map((module: any, index: number) => ({
        id: index + 1,
        data: {
          sort: module.sort,
          id: module.id,
          title: module.name,
          description: module.description,
          speakers: module.speakers,
          type: module.type === MODULE_KEYS.ASSESSMENT ? MODULE_TYPE.ASSESSMENT : module.type,
          thumbnailUrl: module.thumbnailUrl,
          previewUrl: module.previewUrl,
          isActive: module.isActive,
        },
      }));
      setInitialModuleListState(data);
      setModulesAssessments(data);
    }
  }, [courseModulesQuery.data]);

  useEffect(() => {
    if (reset) {
      resetOrder();
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    let isVisible = false;
    if (modulesAssessments.length === initialModuleListState.length)
      for (let i = 0; i < initialModuleListState.length; ++i) {
        if (initialModuleListState[i].id !== modulesAssessments[i].id) {
          isVisible = true;
          break;
        }
      }
    setShowSave(isVisible);
  }, [modulesAssessments]);
  const { singleCourseView, moduleModal, assessmentModal } = useStrapiHQAdminCoursesData();
  const { addAssessmentButton, addModuleButton, title, saveButton, resetButton } =
    singleCourseView.ModuleDetails;
  const { addButton, addModuleTitle } = moduleModal;
  const { addAssessmentTitle, addAssessmentButton: addAssessmentModalButton } = assessmentModal;
  return (
    <div className={`flex max-h-full flex-col rounded-sm bg-card-bg px-6 py-4`}>
      {/* Header Starts  */}
      <div className="flex flex-wrap items-center justify-between gap-3">
        <p className="font-sans text-base font-medium text-white">{title}</p>
        <div className="flex grow flex-col gap-3 xs:flex-row xs:justify-between md:grow-0">
          {!showSave && (
            <Dialog
              open={isAssessmentModalOpen}
              onOpenChange={(open) => setIsAssessmentModalOpen(open)}
              triggerClassName="flex grow xs:w-fit items-center gap-2 bg-zinc-900 px-4 py-3 text-xs font-medium text-white rounded justify-center"
              Content={
                <AssessmentModal
                  setIsAssessmentModalOpen={setIsAssessmentModalOpen}
                  modalTitle={addAssessmentTitle}
                  formActionButtonLabel={addAssessmentModalButton}
                  buttonIcon={<ArrowRight weight="bold" />}
                  modalType="add"
                  refetch={courseModulesQuery.refetch}
                />
              }
              onInteractOutside={(e) => {
                // Donot close the Modal when we click outside the Dialog Content
                e.preventDefault();
              }}
            >
              {addAssessmentButton}
            </Dialog>
          )}
          {!showSave && (
            <Dialog
              open={isModuleModalOpen}
              onOpenChange={(open) => setIsModuleModalOpen(open)}
              triggerClassName="flex grow xs:w-fit items-center gap-2 bg-base-brand px-4 py-3 text-xs font-medium text-white rounded justify-center"
              Content={
                <ModuleAssessmentModal
                  modalTitle={addModuleTitle}
                  setIsModuleModalOpen={setIsModuleModalOpen}
                  formActionButtonLabel={addButton}
                  modalType="create"
                  refetch={courseModulesQuery.refetch}
                />
              }
              onInteractOutside={(e) => {
                // Donot close the Modal when we click outside the Dialog Content
                e.preventDefault();
              }}
            >
              {addModuleButton}
            </Dialog>
          )}
          {showSave && (
            <Button
              className="flex grow items-center justify-center gap-2 rounded bg-zinc-900 px-12 py-3 text-xs font-medium text-white disabled:opacity-50 xs:w-fit"
              disabled={updateOrder.isLoading}
              onClick={() => setReset(true)}
            >
              {resetButton}
            </Button>
          )}
          {showSave && (
            <Button
              className="flex grow items-center justify-center gap-2 rounded bg-base-brand px-12 py-3 text-xs font-medium text-white disabled:opacity-50 xs:w-fit"
              onClick={saveReOrder}
              disabled={updateOrder.isLoading}
            >
              {saveButton}
              {updateOrder.isLoading && (
                <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
              )}
            </Button>
          )}
        </div>
      </div>
      {/* Header Ends */}

      {/* Modules and Assessment loaded */}
      {courseModulesQuery.data && (
        <ModuleAssessmentList
          modulesAssessments={modulesAssessments}
          setModulesAssessments={setModulesAssessments}
          isSaving={updateOrder.isLoading}
        />
      )}
      {courseModulesQuery.data.length === 0 && (
        <p className="m-auto my-10 text-sm font-normal text-zinc-100">
          No module or assessment added yet. Create one from the button above!
        </p>
      )}
    </div>
  );
};

export default ModuleAssessment;
