import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import TenureToogle from './TenureToggle';
import PricingCard from './PricingCard';
import { clearCognitoHostedUIError } from '../../../slices/auth.slice';

const PricingTable = () => {
  const tenure = useAppSelector((state) => state.pricing.tenure);
  const popularPlanIndex = 1;
  const data = useStrapiDataHelper();
  const formData = data?.registration?.data.attributes;
  const { pageHeader, pageDescription } = formData;
  const pricingCard = data?.registration?.data?.attributes.pricingTable?.Pricecard;
  const pricing: Array<Record<string, any>> = pricingCard;
  const dispatch = useAppDispatch();

  // If there are any oAuth errors they would be cleared
  const onPlanActionButtonClick = () => {
    dispatch(clearCognitoHostedUIError());
  };

  return (
    <main id="main-content">
      <div className="flex flex-col gap-y-8">
        <div className="m-4 text-center">
          <h1 className="my-4 text-4xl font-extrabold tracking-tight text-white">{pageHeader}</h1>
          <p className="text-lg text-zinc-400 sm:px-16 xl:px-48">{pageDescription}</p>
        </div>
        <div className="mb-8">
          <TenureToogle />
        </div>
        <div className="mx-4 mb-12 grid grid-rows-1 content-center justify-center justify-items-center gap-8 self-center lg:auto-rows-[1fr] lg:grid-cols-3 xl:mx-0 xl:max-w-[90%]">
          <PricingCard
            onPlanActionButtonClick={onPlanActionButtonClick}
            pricing={pricing}
            popularPlanIndex={popularPlanIndex}
            tenure={tenure}
          />
        </div>
      </div>
    </main>
  );
};

export default PricingTable;
