import React from 'react';
import cn from '../../../utils/cn';

interface Props {
  message: string | React.ReactNode;
  className?: Parameters<typeof cn>[0];
}

const NoDataFallback = ({ message, className = 'text-sm' }: Props) => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <span className={cn('text-center font-normal text-zinc-50', className)}>{message}</span>
    </div>
  );
};

export default NoDataFallback;
