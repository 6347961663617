import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { raiseUSHGAdminRequest, RaiseUSHGAdminRequestArgs } from '../api';
import { useStrapiRequestData } from './useStrapiRequestData';

const useRaiseUSHGAdminRequestMutation = () => {
  const { toastMessages } = useStrapiRequestData();
  const {
    HQRequestSentFailureTitle,
    HQRequestSentSuccessTitle,
    HQRequestSentSuccessMessage,
    HQRequestSentFailureMEssage,
  } = toastMessages;
  const mutation = useMutation({
    mutationFn: async (args: RaiseUSHGAdminRequestArgs) => {
      const response = await raiseUSHGAdminRequest(args);
      return response;
    },
    onSuccess: () => {
      toast.custom((t) => (
        <Toast
          variant="success"
          Title={HQRequestSentSuccessTitle}
          SubTitle={HQRequestSentSuccessMessage}
          toastInstance={t}
        />
      ));
    },
    onError: (error) => {
      console.error('Error rasing a request to USHG Admin %o', error);
      toast.custom((t) => (
        <Toast
          variant="error"
          Title={HQRequestSentFailureTitle}
          SubTitle={HQRequestSentFailureMEssage}
          toastInstance={t}
        />
      ));
    },
  });

  return mutation;
};

export default useRaiseUSHGAdminRequestMutation;
