import { gql } from '@apollo/client';

const TeamsFragment = gql`
  fragment TeamFragment on TeamEntityResponse {
    data {
      attributes {
        COMMON {
          ... on ComponentTeamLoadingMessages {
            loadingMoreTeams
          }
          ... on ComponentTeamErrormessages {
            memberAlreadyExists
            memberAlreadyExistsMessage
            teamAlreadyExists
            managerMemberSameError
            teamNameRequired
            managerEmailRequired
            memberEmailRequired
            jobTitleRequired
            memberRoleRequired
            jobTitleMaxError
            jobTitleMinError
            emailLimitError
            validEmailError
            teamNameMinError
            teamNameMaxError
            teamNameMinValue
            teamNameMaxValue
            emailMaxValue
            jobTitleMinValue
            jobTitleMaxValue
            sameMemberErrorMessage
            sameMemberError
            teamNameAlreadyExistMessage
            managerMemberSameErrorMessage
            memberNameRequired
            memberNameMinValue
            memberNameMinError
            memberNameMaxError
            memberNameMaxValue
          }
          ... on ComponentTeamSinglePerson {
            memberDetails {
              teamName
              loginRate
              averageWeeklyLearningHours
              badgesEarned
              lastLogin
              manager
              perWeekLabel
              hoursLabel
              weeksLabel
            }
            courseHistory {
              totalLearningHours
              assignedCourses
              completedCourses
              inProgressCourses
              mostEngagedCategory
              averageAssessmentScore
              title
            }
            singlePersonProps {
              noBadgesFoundLabel
              noCoursesAssignedLabel
              viewAllButton
              noCoursesAssignedMessage
            }
            singlePersonHeader {
              badges
              profile
              performance
            }
          }
          ... on ComponentTeamSinglePersonProfile {
            title
          }
          ... on ComponentTeamAddTeamModal {
            teamName {
              title
              inputFieldName1
              placeHolderForField1
            }
            managerDetails {
              title
              inputFieldName1
              placeHolderForField1
              inputFieldName2
              placeHolderForField2
            }
            memberDetails {
              title
              inputFieldName1
              placeHolderForField1
              inputFieldName2
              placeHolderForField2
            }
            addTeamButton
            addTeamRequestButton
            addAnotherMemberButton
            addNewMemberButton
            goToBillingButton
            removeMemberButton
            cancelButton
            title
          }
          ... on ComponentTeamAddSubTeam {
            teamName {
              title
              inputFieldName1
              placeHolderForField1
            }
            managerDetails {
              title
              inputFieldName1
              placeHolderForField1
              inputFieldName2
              placeHolderForField2
            }
            memberDetails {
              title
              inputFieldName1
              placeHolderForField1
              inputFieldName2
              placeHolderForField2
            }
            addTeamButton
            addTeamRequestButton
            addNewMemberButton
            addAnotherMemberButton
            goToBillingButton
            removeMemberButton
            cancelButton
            title
          }
          ... on ComponentTeamAddMember {
            title
            addMemberButton
            addMemberRequestButton
            goToBillingButton
            cancelButton
            memberRoleLabel
            memberRolePlaceHolder
            memberNameLabel
            memberNamePlaceHolder
            memberEmailLabel
            memberEmailPlaceHolder
            memberTeamLabel
            memberTeamPlaceHolder
            jobTitleLabel
            jobTitlePlaceHolder
            Roles {
              member
              manager
              leader
            }
            ResendInvite {
              successToastTitle
              successToastSubTitle
              errorToastTitle
              errorToastSubTitle
            }
          }
          ... on ComponentTeamEditTeam {
            title
            updateTeamButton
            updateTeamRequestButton
            cancelButton
            fieldName
            placeHolder
          }
          ... on ComponentTeamTopNav {
            searchPlaceHolder
            addTeamButton
            addMemberButton
            addSubTeamButton
            editTeamButton
            backButton
            changeTeamButton
            updateRoleButton
            teamTitle
          }
          ... on ComponentTeamTableProps {
            pageSizeSelectorLabel
            rowLabel
            searchPlaceHolder
          }
        }
        ENTERPRISE_ADMIN {
          ... on ComponentTeamAdminTeams {
            listAllTeamsTableProps {
              searchPlaceHolder
              pageSizeSelectorText
              rowLabel
            }
            listAllTeamsTableHeader {
              teamName
              teamManager
              noOfMembers
              createdOn
            }
            lisAllTeamsEditRowOptions {
              option1
              option2
              option3
            }
          }
          ... on ComponentTeamListMembers {
            tableSortOptions {
              title
              option1
              option2
              option3
            }
            tableRowEditOptions {
              option1
              option2
              option3
              option4
              option5
              option6
            }
            tableHeader {
              status
              jobTitle
              lastLogin
              name
              email
              role
            }
          }
          ... on ComponentTeamTeamsToastMessage {
            successToastMessage
            failureToastMessage
            actionProcessingToastMessage
            updateFailureToastMessage
            updateSuccessfulToastMessage
            updateProcessingToastMessage
            memberAdditionSuccessToastMessage
            memberAdditionFailureToastMessage
            memberAdditionProcessingToastMessage
            subTeamAddedSuccessMessage
          }
        }
        ENTERPRISE_LEADER {
          ... on ComponentTeamTeamCard {
            manager
            assignedCourses
            subTeams
            courseCompletion
            requestSentToAdminLabel
          }
          ... on ComponentTeamSingleTeam {
            SingleTeamHeader {
              subTeamLabel
              managerLabel
              memberLabel
            }
            SingleTeamStatistics {
              avgSessionLabel
              coursesAssignedLabel
              coursesCompletionLabel
              courseCompletedLabel
              totalBadgesEarnedLabel
              memberCountLabel
              mostPopularBadgesLabel
            }
            EmptyMessages {
              noTeamMembersMessage
            }
          }
          ... on ComponentTeamTeamsToastMessage {
            successToastMessage
            failureToastMessage
            actionProcessingToastMessage
          }
        }
      }
    }
  }
`;
export { TeamsFragment };
