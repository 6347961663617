import { useMemo } from 'react';
import { generateCDNMediaAssetURLWithKey } from '../../../../helpers';
import AvatarAndNameCell from './AvatarAndNameCell';

interface NameCellProps {
  username: string;
  pictureUrl: string;
}

const UserCell = ({ username, pictureUrl }: NameCellProps) => {
  const profilePictureURL = useMemo(() => {
    if (!pictureUrl) {
      return undefined;
    }

    const cdnProfilePictureUrl = generateCDNMediaAssetURLWithKey({ key: pictureUrl });
    return cdnProfilePictureUrl;
  }, [pictureUrl]);

  return <AvatarAndNameCell name={username} profilePictureURL={profilePictureURL} />;
};

export default UserCell;
