import { BadgesTableMain } from './BadgeTable';
import PageHeader from './PageHeader';
import { SideBarLayoutPageContentWrapper } from '../../../../components/Wrapper';
import useSessionTracker from '../../../../hooks/use-session-tracker-hook';

const USHGAdminBadgePage = () => {
  useSessionTracker();

  return (
    <main id="main-content" className="flex w-full flex-col items-center gap-5">
      <PageHeader />
      <SideBarLayoutPageContentWrapper>
        <BadgesTableMain className="w-full" />
      </SideBarLayoutPageContentWrapper>
    </main>
  );
};

export default USHGAdminBadgePage;
