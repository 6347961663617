import { FormProvider, useForm } from 'react-hook-form';
import { BasicSpinner } from '../../../../../components/Spinners';
import {
  useNotificationPreferenceConfig,
  useNotificationPreferencesStrapiData,
  useUpdateNotificationPreferencesMutation,
} from '../../../hooks';
import { UserNotificationPreferences } from '../../../types';
import NotificationPreferenceCategoryFieldset from './NotificationPreferenceCategoryFieldset';

// {categoryId:{preferenceId:{isWebEnabled:boolean, isEmailEnabled:boolean}}}
type NotificationPreferencesFormData = UserNotificationPreferences;

interface Props {
  notificationPreferences?: UserNotificationPreferences;
}

/**
 * Renders the notification preference form.
 */
const NotificationPreferenceForm = (props: Props) => {
  const { notificationPreferences } = props;

  const updateNotificationPreferences = useUpdateNotificationPreferencesMutation();
  const { saveButton } = useNotificationPreferencesStrapiData();
  const formMethods = useForm({
    defaultValues: notificationPreferences,
  });

  /**
   * The notification categories to display to the user.
   */
  const notificationPreferencesConfig = useNotificationPreferenceConfig();

  const handlePreferencesFormSubmit = async (data: NotificationPreferencesFormData) => {
    try {
      const params = {
        notificationPreferences: data,
      };
      await updateNotificationPreferences.mutateAsync(params);
    } catch (error) {
      console.error('Error updating user notification preferences', error);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(handlePreferencesFormSubmit)}
        className="flex flex-col gap-5"
      >
        {notificationPreferencesConfig.map((notificationCategory) => {
          return (
            <NotificationPreferenceCategoryFieldset
              key={notificationCategory.categoryId}
              category={notificationCategory}
            ></NotificationPreferenceCategoryFieldset>
          );
        })}
        <button
          type="submit"
          className="flex w-max items-center gap-1.5 rounded-md bg-base-brand py-2 px-4 text-sm font-normal text-white disabled:bg-base-brand/90 disabled:opacity-90"
          disabled={updateNotificationPreferences.isLoading}
        >
          <span>{saveButton}</span>
          {updateNotificationPreferences.isLoading && (
            <BasicSpinner className="!m-0 text-zinc-50" />
          )}
        </button>
      </form>
    </FormProvider>
  );
};

export default NotificationPreferenceForm;
