import { FallbackProps } from 'react-error-boundary';
import { ComponentLevelErrorBoundaryFallback } from '../../../../components/ErrorBoundary';
import { useStrapiSharedData } from '../../../shared/hooks/useStrapiSharedData';

const UpcomingEventsErrorBoundaryFallback = ({ error }: FallbackProps) => {
  const {
    componentLevelErrorBoundary: { Calendar },
  } = useStrapiSharedData();

  return (
    <ComponentLevelErrorBoundaryFallback error={error} description={Calendar.upcomingEvents} />
  );
};

export default UpcomingEventsErrorBoundaryFallback;
