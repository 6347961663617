import clsx from 'clsx';
import { Check, Info, X } from 'phosphor-react';
import { twMerge } from 'tailwind-merge';
import { BadgeComponent } from '../Badge';
import cn from '../../utils/cn';
import { useMemo } from 'react';

type ActionResponseVariant = 'success' | 'failure' | 'info';

interface Props {
  variant: ActionResponseVariant;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  className?: string;
  titleClassName?: string;
  subTitleClassName?: string;
  badgeClassName?: string;
  badgeText?: string;
  successVariantBackground?: string;
  failureVariantBackground?: string;
  infoVariantBackground?: string;
}

const ActionResponse = ({
  variant,
  className,
  title,
  subtitle,
  titleClassName,
  subTitleClassName,
  badgeClassName,
  badgeText,
  successVariantBackground,
  failureVariantBackground,
  infoVariantBackground,
}: Props) => {
  const icon = useMemo(() => {
    switch (variant) {
      case 'success': {
        return (
          <Check
            size={24}
            color="currentColor"
            weight="fill"
            className="fill-emerald-50 text-emerald-50"
          />
        );
      }
      case 'failure': {
        return (
          <X size={24} color="currentColor" weight="fill" className="fill-rose-50 text-rose-50" />
        );
      }
      case 'info': {
        return (
          <Info
            size={24}
            color="currentColor"
            weight="fill"
            className="fill-blue-50 text-blue-50"
          />
        );
      }
    }
  }, [variant]);

  return (
    <div
      className={twMerge(
        'flex h-min max-w-lg flex-col items-center gap-4 rounded-lg border border-zinc-700 bg-card-bg p-8',
        className
      )}
    >
      {/* Badge  */}
      {badgeText && (
        <BadgeComponent
          className={clsx(
            'ml-auto -mt-4 -mr-4 border-0 py-2.5 font-extrabold text-white group-hover:hidden',
            badgeClassName
          )}
          label={badgeText}
          size="sm"
        />
      )}
      <div
        className={cn(
          'flex items-center rounded-full p-2',
          variant === 'success' &&
            (successVariantBackground ? successVariantBackground : 'bg-emerald-600'),
          variant === 'failure' &&
            (failureVariantBackground ? failureVariantBackground : 'bg-rose-600'),
          variant === 'info' && (infoVariantBackground ? infoVariantBackground : 'bg-blue-600')
        )}
      >
        {icon}
      </div>
      <div className="flex flex-col gap-2">
        <span
          className={clsx(
            'text-center text-lg font-semibold leading-[24px] text-zinc-50',
            titleClassName
          )}
        >
          {title}
        </span>
        <span
          className={clsx(
            'text-center text-sm font-normal leading-[20px] text-zinc-400',
            subTitleClassName
          )}
        >
          {subtitle}
        </span>
      </div>
    </div>
  );
};

export default ActionResponse;
