import { useMutation } from '@tanstack/react-query';
import { logoutUser } from '../api';
import useSessionTracker from '../../../hooks/use-session-tracker-hook';

const useLogoutMutation = () => {
  const { sendAllSessionDataToHubSpot } = useSessionTracker();
  const mutation = useMutation({
    mutationFn: async () => {
      const response = await logoutUser();
      return response;
    },
  });
  sendAllSessionDataToHubSpot();
  return mutation;
};

export default useLogoutMutation;
