import clsx from 'clsx';
import { MagnifyingGlass } from 'phosphor-react';
import { TextInput } from '../FormElements';
import { TextInputProps } from '../FormElements/TextInput';

type TextInputPropsWithoutRef = Omit<TextInputProps, 'ref' | 'type'>;

interface Props extends TextInputPropsWithoutRef {
  containerClassName?: string;
  iconClassName?: string;
  iconSize?: string;
}

const SearchInput = (props: Props) => {
  const { containerClassName, iconClassName, className, iconSize = 20, ...textInputProps } = props;

  return (
    <div className={clsx('relative flex items-center', containerClassName)}>
      <MagnifyingGlass
        size={iconSize}
        color="currentColor"
        className={clsx('absolute text-zinc-500', iconClassName)}
      />
      <TextInput className={className} {...textInputProps} type="text" />
    </div>
  );
};

export default SearchInput;
