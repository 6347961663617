import { ChartData } from 'chart.js';
import clsx from 'clsx';
import { useContext, useMemo, useState } from 'react';
import { ErrorDisplay, NoDataDisplay } from '../../../../components/DataTables';
import { defaultGridOptions, defaultTickOptions, defaultTitleOptions } from '../../config';
import { useHoursLoggedQuery } from '../../hooks';
import {
  DashboardReportsFilter,
  HoursLogged,
  LineChartOptions,
  ReportTooltipDataType,
} from '../../types';
import { LineChart } from './baseCharts';
import ChartHeading from './ChartHeading';
import durationContext from '../../context/durationContext';
import { InView } from 'react-intersection-observer';
import { chartJsLabel, formatTime } from '../../helpers';
import ScaleLoader from '../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../components/ReportToolTip';
import { REPORT_TYPES } from '../../constants';
import { useLanguage } from '../../context/languageContext';

interface HoursLoggedInVizProps {
  className?: string;
  heading?: string;
  key1?: string;
  key2?: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  errorMessage?: string;
  emptyChartIconUrl?: string;
  emptyMessage?: string;
  lazyLoad?: boolean;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}
interface LabelProps {
  pointClass: string;
  label: string;
}

const Label = ({ label, pointClass }: LabelProps) => {
  return (
    <div className="flex items-center gap-1">
      <span className={`h-2.5 w-2.5 rounded-full bg-base-brand ${pointClass ?? ''}`}></span>
      <span className="text-[10px] font-normal leading-[12px] text-white">{label}</span>
    </div>
  );
};

const HoursLoggedInViz = ({
  className,
  heading,
  key1,
  key2,
  xAxisLabel,
  yAxisLabel,
  emptyMessage,
  errorMessage,
  lazyLoad = true,
  emptyChartIconUrl,
  reportTooltipContent,
  userPersona,
}: HoursLoggedInVizProps) => {
  const { duration } = useContext(durationContext);
  const { language } = useLanguage();

  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);

  // Build filter
  const filters: DashboardReportsFilter = useMemo(() => {
    return {
      fromDate: duration.fromDate,
      interval: duration.interval,
    };
  }, [duration]);

  const hoursLoggedActivity = useHoursLoggedQuery({ filters, isEnabled: isInView });

  const hasHoursLoggedActivity =
    hoursLoggedActivity.data &&
    hoursLoggedActivity.data.results &&
    hoursLoggedActivity.data.results.length > 0;

  const hasNoHoursLoggedActivity =
    hoursLoggedActivity.data &&
    hoursLoggedActivity.data.results &&
    hoursLoggedActivity.data.results.length === 0;

  const isLoadingHoursLoggedActivity = hoursLoggedActivity.isLoading;

  const data = useMemo(() => {
    let labels: string[] = [];
    let assessmentViewingActivity: number[] = [];
    let courseViewingActivity: number[] = [];

    if (hasHoursLoggedActivity) {
      // Chartjs labels
      labels = hoursLoggedActivity.data?.results.map((result: HoursLogged) =>
        chartJsLabel(duration.interval, result.pool, language)
      );

      // Total Courses Time
      courseViewingActivity = hoursLoggedActivity.data?.results.map(
        (result: HoursLogged) => +result.totalCourseTime
      );

      // Total Assessment Time
      assessmentViewingActivity = hoursLoggedActivity.data?.results.map(
        (result: HoursLogged) => +result.totalQuizTime
      );
    }

    const data: ChartData<'line', number[], string> = {
      labels,
      datasets: [
        {
          label: key1,
          data: courseViewingActivity ?? [],
          borderColor: '#F7634F',
          backgroundColor: '#F7634F',
        },
        {
          label: key2,
          data: assessmentViewingActivity ?? [],
          borderColor: '#F3EAC7',
          backgroundColor: '#F3EAC7',
        },
      ],
    };
    return data;
  }, [hasHoursLoggedActivity, key1, key2, hoursLoggedActivity.data?.results]);

  // Chartjs options

  const chartOptions: LineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.4,
      },
    },
    scales: {
      x: {
        title: {
          ...defaultTitleOptions,
          text: xAxisLabel,
        },
        grid: { ...defaultGridOptions },
        ticks: {
          padding: 8,
          align: 'start',
          ...defaultTickOptions,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          ...defaultTitleOptions,
          text: yAxisLabel,
        },
        ticks: {
          ...defaultTickOptions,
          precision: 0,
          padding: 12,
        },
        grid: { ...defaultGridOptions },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += formatTime(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <InView
      as="div"
      className={clsx(
        'flex h-full min-h-[420px] flex-col gap-5 rounded-lg bg-card-bg px-5 pt-4 pb-7',
        className
      )}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div className="flex flex-col justify-between gap-2 lg:flex-row">
        <div className="flex items-center gap-2 ">
          <div className="flex">
            <ChartHeading heading={heading as string} />
          </div>
          {reportTooltipContent?.[REPORT_TYPES.HOURS_LOGGED]?.[userPersona] && (
            <div className="flex">
              <ReportTooltip
                content={reportTooltipContent[REPORT_TYPES.HOURS_LOGGED][userPersona]}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col justify-between gap-4 xs:flex-row">
          <div className="flex gap-4">
            <Label pointClass="bg-base-brand" label={key1 as string} />
            <Label pointClass="bg-base-creme" label={key2 as string} />
          </div>
        </div>
      </div>
      {isLoadingHoursLoggedActivity && !hasHoursLoggedActivity && <ScaleLoader />}
      {/* Error State */}
      <>
        {hoursLoggedActivity.error && !hoursLoggedActivity.data && (
          <div className="flex grow items-center justify-center">
            <ErrorDisplay
              message={errorMessage}
              refetch={hoursLoggedActivity.refetch}
              allowsRefetch={true}
              isRefetching={hoursLoggedActivity.isRefetching}
            />
          </div>
        )}
      </>
      {hasHoursLoggedActivity && (
        <LineChart
          className="m-auto max-h-80 min-h-[20rem] w-full"
          options={chartOptions}
          data={data}
        />
      )}
      {hasNoHoursLoggedActivity && !isLoadingHoursLoggedActivity && (
        <NoDataDisplay message={emptyMessage as string} IconUrl={emptyChartIconUrl} />
      )}
    </InView>
  );
};

export default HoursLoggedInViz;
