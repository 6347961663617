import { AUTH_API_PATHS } from '../constants';
import { authAPI } from './axios';

interface ConfirmUserAccountParameters {
  username: string;
  confirmationCode: string;
}

const confirmUserAccount = async ({ username, confirmationCode }: ConfirmUserAccountParameters) => {
  const path = AUTH_API_PATHS.LOGIN.CONFIRM_ACCOUNT;
  const body = {
    username,
    confirmationCode,
  };
  const response = await authAPI.post(path, body);
  const data = response.data;
  return data;
};
const resendAccountConfirmationCode = async (username: string) => {
  const path = AUTH_API_PATHS.LOGIN.CONFIRM_RESEND;
  const response = await authAPI.get(path, { params: { username } });
  const data = response.data;
  return data;
};
export { confirmUserAccount, resendAccountConfirmationCode };
