import { Check, X } from 'phosphor-react';
import { useMemo } from 'react';
import useStrapiMembersData from '../../../members/hooks/useStrapiMembersData';

interface Props {
  file: File;
  resetUpload: () => void;
}

/**
 * Used to display bulk upload complete statys.
 */
const FileUploadComplete = (props: Props) => {
  const { file, resetUpload } = props;

  const csvObjectUrl = useMemo(() => {
    return URL.createObjectURL(file);
  }, [file]);

  const fileDisplayName = useMemo(() => {
    const fileNameLength = file.name.length;

    if (fileNameLength > 32) {
      return `${file.name.substring(0, 32)}...`;
    }

    return file.name;
  }, [file]);
  const { loadingMessages } = useStrapiMembersData();
  return (
    <div className="flex w-full grow flex-col items-center justify-center gap-2 p-[12px_26px_16px]">
      <div className="flex w-full cursor-pointer justify-end" aria-label="Reset Upload">
        <X onClick={resetUpload} color="currentColor" className="text-zinc-50" />
      </div>
      <div className="flex flex-col items-center gap-2">
        <div className="w-max rounded-full bg-emerald-600 p-1">
          <Check
            size={24}
            color="currentColor"
            weight="fill"
            className="fill-emerald-50 text-emerald-50"
          />
        </div>
        <div>
          <div className="flex flex-wrap items-center justify-center gap-1 text-zinc-50">
            <span>{loadingMessages.uploadedLabel}</span>
            <a
              title={file.name}
              className="text-blue-500 underline"
              href={csvObjectUrl}
              download={file.name}
            >
              {fileDisplayName}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploadComplete;
