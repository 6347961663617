import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { TableDataRow, TableHeaderRow } from '../../../../../../components/DataTables';
import { isDefined } from '../../../../../../utils';
import useStrapiMembersData from '../../../../hooks/useStrapiMembersData';
import { GetAllMembersInOrganizationResponseDataItem } from '../../../../types';
import {
  AvatarAndNameCell,
  EmailCell,
  JobTitleCell,
  LastLoginCell,
  TeamNameCell,
  UserStatusCell,
} from './cells';
import EnterpriseDropDownCell from './cells/EnterpriseDropDownCell';
import UserRoleCell from './cells/UserRoleCell';
import { BsLayoutThreeColumns } from 'react-icons/bs';
import { generateTeamHierarchyName } from '../../../../utils/team';
import {
  ALL_MEMBERS_TABLE_SORT_BY_QUERY_PARAM,
  ALL_MEMBERS_TABLE_SORT_DIR_QUERY_PARAM,
} from '../../../../constants';

interface Props {
  data: GetAllMembersInOrganizationResponseDataItem[];
  className?: string;
  // Whether this is USHG Admin View
  isOrganization?: boolean;
}
interface TableHeadings {
  nameTableHeader: string;
  emailTableHeader: string;
  jobTitleTableHeader: string;
  lastLoginTableheader: string;
  teamTableHeader: string;
  statusTableHeader: string;
  roleTableHeader: string;
}
interface EditOptions {
  deactivatedButton: string;
  activatedButton: string;
  resendInviteButton: string;
  disableMFAForUserButton: string;
  changeTeamButton: string;
  changeRoleButton: string;
}

interface StatusOptions {
  activatedStatuslabel: string;
  invitedStatuslabel: string;
  deactivatedStatuslabel: string;
}

function getColumns(
  isOrganization: boolean,
  data: TableHeadings,
  editOptions: EditOptions,
  statusOptions: StatusOptions
) {
  const {
    nameTableHeader,
    emailTableHeader,
    jobTitleTableHeader,
    lastLoginTableheader,
    teamTableHeader,
    statusTableHeader,
    roleTableHeader,
  } = data;
  const {
    activatedButton,
    deactivatedButton,
    resendInviteButton,
    disableMFAForUserButton,
    changeTeamButton,
    changeRoleButton,
  } = editOptions;
  const columnHelper = createColumnHelper<GetAllMembersInOrganizationResponseDataItem>();

  const optionsDisplayColumnDef = columnHelper.display({
    id: 'options',
    cell: ({ row }) => {
      const { username, status, userType, fullName, mfaPreference, isSecondaryAdmin, teamId } =
        row.original;
      const teamName = generateTeamHierarchyName(row.original);

      return (
        <EnterpriseDropDownCell
          username={username}
          status={status}
          activateLabel={activatedButton}
          deactivateLabel={deactivatedButton}
          resendInviteLabel={resendInviteButton}
          disableMFALabel={disableMFAForUserButton}
          userRole={userType}
          fullName={fullName}
          teamName={teamName}
          teamId={teamId}
          changeTeamLabel={changeTeamButton}
          changeRoleLabel={changeRoleButton}
          mfaPreference={mfaPreference}
          isSecondaryAdmin={isSecondaryAdmin}
        />
      );
    },
    header: () => {
      return (
        <BsLayoutThreeColumns
          className="h-3 w-3 text-zinc-100"
          strokeWidth={1.5}
          color="currentColor"
          size={12}
        />
      );
    },
    enableSorting: false,
    enableMultiSort: false,
  });

  const columns = [
    columnHelper.accessor('fullName', {
      header: nameTableHeader,
      cell: (props) => {
        return <AvatarAndNameCell row={props.row.original} />;
      },
    }),
    columnHelper.accessor('username', {
      header: emailTableHeader,
      cell: (props) => {
        return <EmailCell username={props.row.original.username} />;
      },
    }),
    columnHelper.accessor('userType', {
      header: roleTableHeader,
      cell: (props) => {
        return <UserRoleCell userType={props.row.original.userType} />;
      },
    }),
    columnHelper.accessor('lastLogin', {
      header: lastLoginTableheader,
      cell: (props) => {
        return <LastLoginCell lastLogin={props.row.original.lastLogin} />;
      },
    }),
    columnHelper.accessor('jobTitle', {
      header: jobTitleTableHeader,
      cell: (props) => {
        return <JobTitleCell jobTitle={props.row.original.jobTitle} />;
      },
    }),
    // userTeamName_managedTeamName1_managedTeamName2
    columnHelper.accessor(
      (row) =>
        `${row.teamNameHierarchy}_${row.teamManager
          .map((tm) => tm.teamNameHierarchy)
          .sort()
          .join('_')}`,
      {
        header: teamTableHeader,
        cell: (props) => {
          return <TeamNameCell row={props.row.original} />;
        },
        id: 'teamNameHierarchy',
      }
    ),
    columnHelper.accessor('status', {
      header: statusTableHeader,
      cell: (props) => {
        return (
          <UserStatusCell status={props.row.original.status} tableStatusOptions={statusOptions} />
        );
      },
    }),
    // Add Options Display If this not in the ushg admin view
    ...[isOrganization ? undefined : optionsDisplayColumnDef].filter(isDefined),
  ];

  return columns;
}

const AllMembersTable = (props: Props) => {
  const { data, className, isOrganization = false } = props;

  const navigate = useNavigate();

  const { TableHeader, TableRowEditOptions, TableStatusOptions } =
    useStrapiMembersData().membersTable;
  const columns = getColumns(isOrganization, TableHeader, TableRowEditOptions, TableStatusOptions);

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  const navigateToSingleUserView = useCallback(
    (args: { username: string }) => {
      const { username } = args;
      navigate(`/team/member/${username}`);
    },
    [navigate]
  );

  return (
    <table className={clsx('w-full', className)}>
      <thead className="w-full">
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeaderRow
                  isSortable={header.column.getCanSort()}
                  header={header}
                  key={header.id}
                  isBackendSort={true}
                  sortByKey={ALL_MEMBERS_TABLE_SORT_BY_QUERY_PARAM}
                  sortDirectionKey={ALL_MEMBERS_TABLE_SORT_DIR_QUERY_PARAM}
                />
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <TableDataRow
            key={row.id}
            onClick={() => {
              // If this is ushg admin view donot navigate
              if (isOrganization) {
                return;
              }

              navigateToSingleUserView(row.original);
            }}
            row={row}
            className={twMerge(
              clsx(
                'w-full hover:bg-page-bg-dark/20',
                isOrganization ? 'cursor-default' : 'cursor-pointer',
                className
              )
            )}
          />
        ))}
      </tbody>
    </table>
  );
};

export default AllMembersTable;
