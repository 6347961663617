interface AdminEmailCellProps {
  adminEmail: string;
}

const AdminEmailCell = (props: AdminEmailCellProps) => {
  const { adminEmail } = props;
  return (
    <div className="flex space-x-1.5">
      <span className="whitespace-nowrap">{adminEmail}</span>
    </div>
  );
};
export default AdminEmailCell;
