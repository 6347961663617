import { X } from 'phosphor-react';
import Dialog from '../../../../../../components/Dialog';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { useStrapiSharedData } from '../../../../../shared/hooks/useStrapiSharedData';
import PricingCard from '../../Pricing/PricingCard';
import TenureToggle from '../../Pricing/TenureToggle';

interface IndividualSSOChangePlanProps {
  pricingCard: Record<string, any>;
  setIsDialogOpen: (open: boolean) => void;
}

const IndividualSSOChangePlan = ({
  pricingCard,
  setIsDialogOpen,
}: IndividualSSOChangePlanProps) => {
  const data: any = useStrapiDataHelper();
  const attributes = data?.registration.data.attributes;
  const { SubFormsHeader } = attributes;

  const sharedCMS = useStrapiSharedData();

  return (
    <div className="mx-1 flex flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <div className="flex items-center justify-between">
        <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
          {SubFormsHeader.changeCurrPlanDialogTitle}
        </Dialog.Title>
        <Dialog.Close aria-label={sharedCMS.dialog.closeSRLabel}>
          <X color="currentColor" className="text-zinc-200 hover:text-zinc-100" size={18} />
        </Dialog.Close>
      </div>
      <TenureToggle />
      <PricingCard
        pricing={[pricingCard?.plan]}
        tenure={pricingCard?.tenure}
        onPlanActionButtonClick={() => setIsDialogOpen(false)}
      />
    </div>
  );
};

export default IndividualSSOChangePlan;
