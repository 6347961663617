import { ShieldStar } from 'phosphor-react';
import SideBar from '../../../../../components/SideBar';
import { useStrapiSideNavData } from '../../../../../hooks/useStrapiSideNavData';
import { CustomSideNavItemProps } from '../../../types';

const BadgesNavItem = ({
  eventsCount,
  children,
}: React.PropsWithChildren<CustomSideNavItemProps>) => {
  const { routes } = useStrapiSideNavData();
  return (
    <SideBar.SideBarNavItem
      icon={
        <ShieldStar aria-label="Badges" size="20px" color="currentColor" className="text-inherit" />
      }
      label={routes.badgesLabel}
      path="/manage/badges"
      eventsCount={eventsCount}
    >
      {children}
    </SideBar.SideBarNavItem>
  );
};

export default BadgesNavItem;
