// Helpers for formatting US numbers, appending & removing country code +1
// Will be removed after replacing with multi-select library

const formatPhoneNumber = (value: string) => {
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

const appendCountryCodeToPhoneNumber = (value: string) => {
  if (!value) return value;
  return `+1${value.replace(/\D/g, '')}`;
};

const removeCountryCodeFromPhoneNumber = (value: string) => {
  if (!value) return value;
  // clean the input for any non-digit values.
  let phoneNumber = value.replace(/[^\d]/g, '');
  // remove country code from phone number
  if (phoneNumber.slice(0, 1) === '1') {
    phoneNumber = phoneNumber.substring(1);
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
};
export { formatPhoneNumber, appendCountryCodeToPhoneNumber, removeCountryCodeFromPhoneNumber };
