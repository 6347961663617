import { useQuery } from '@tanstack/react-query';
import { getAllTeams } from '../api';

const useEnterpriseAdminAllTeamsQuery = (isAllMembers: boolean) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery(
      [
        {
          scope: 'teams',
        },
      ],
      async () => {
        return isAllMembers ? await getAllTeams() : null;
      },
      {
        keepPreviousData: true,
        staleTime: 2000,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch };
};

export default useEnterpriseAdminAllTeamsQuery;
