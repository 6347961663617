import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { BADGES_API_PATHS } from '../constants/api';
import {
  CreateBadgeInMultiLangPayload,
  CreateBadgePayload,
  DeleteBadgePayload,
  DeleteBadgeResponse,
  GetBadgeCriteriasArgs,
  GetBadgeTypesResponse,
  GetUSHGHQBadgeDetailsArgs,
  UpdateBadgePayload,
  USHGHQBadgeDetailsResponse,
} from '../constants/badges';
import {
  Badges,
  createBadgeResponse,
  PartialUSHGHQAdminBadgesFilter,
  updateBadgeResponse,
} from '../types/badgeManagement';
import {
  BadgesLeaderboardResponse,
  EarnedBadgesResponse,
  GetBadgeDetailsByIdResponse,
  InProgressBadgesResponse,
  PartialBadgeDetailsFilter,
  PartialBadgesFilter,
  ToEarnBadgesResponse,
} from '../types/badges';

const getEarnedBadges = async (
  filters: PartialBadgesFilter
): Promise<{
  rows: EarnedBadgesResponse[];
  count: number;
}> => {
  const queryString = qs.stringify(filters);
  const path = BADGES_API_PATHS.EARNED_BADGES_API_PATHS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const getToEarnBadges = async (
  filters: PartialBadgesFilter
): Promise<{
  rows: ToEarnBadgesResponse[];
  count: number;
}> => {
  const queryString = qs.stringify(filters);
  const path = BADGES_API_PATHS.TO_EARN_BADGES_API_PATHS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const getUpNextBadges = async (filter?: {
  limit: number;
  offset: number;
  language?: string;
  username?: string;
}) => {
  let queryString = '';
  if (filter) queryString = qs.stringify(filter);
  const path = BADGES_API_PATHS.UP_NEXT_BADGES_API_PATHS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${queryString}`, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const getInProgressBadges = async (
  filters: PartialBadgesFilter
): Promise<{
  rows: InProgressBadgesResponse[];
  count: number;
}> => {
  const queryString = qs.stringify(filters);
  const path = BADGES_API_PATHS.IN_PROGRESS_BADGES_API_PATHS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const getBadgesLeaderboard = async (
  filters: PartialBadgesFilter
): Promise<BadgesLeaderboardResponse[]> => {
  const queryString = qs.stringify(filters);
  const path = BADGES_API_PATHS.BADGES_LEADERBOARD;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const getBadgeDetailsById = async (
  filters: PartialBadgeDetailsFilter
): Promise<GetBadgeDetailsByIdResponse> => {
  const path = BADGES_API_PATHS.BADGES_DETAILS_BY_ID;
  const queryString = qs.stringify(filters);
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const getHQAdminBadges = async (
  filters: PartialUSHGHQAdminBadgesFilter
): Promise<{
  count: number;
  rows: Badges[];
}> => {
  const queryString = qs.stringify(filters);
  const path = BADGES_API_PATHS.GET_USHGADMIN_BADGES;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const deleteBadge = async (payload: DeleteBadgePayload): Promise<DeleteBadgeResponse> => {
  const id = payload.id;
  const path = BADGES_API_PATHS.DELETE_BADGE_BY_ID(id);
  const response = await API.del(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?`, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const getBadgeCriterias = async (args: GetBadgeCriteriasArgs): Promise<GetBadgeTypesResponse> => {
  const { language } = args;
  const path = BADGES_API_PATHS.GET_USHGADMIN_BADGE_TYPES;
  const queryString = qs.stringify({ language });
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const createBadge = async (args: CreateBadgePayload): Promise<createBadgeResponse> => {
  const { name, description, typeId, categoryId, imageUrl, courseCount, count, unit } = args;
  const body = { name, description, typeId, categoryId, imageUrl, courseCount, count, unit };
  const path = BADGES_API_PATHS.CREATE_BADGE;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body,
  });
  return response;
};

const createBadgeInMultiLang = async (
  args: CreateBadgeInMultiLangPayload
): Promise<createBadgeResponse> => {
  const { badgeId, name, description, language } = args;
  const body = { badgeId, name, description, language };
  const path = BADGES_API_PATHS.CREATE_BADGE_IN_MULTI_LANG;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body,
  });
  return response;
};

const updateBadge = async (args: UpdateBadgePayload): Promise<updateBadgeResponse> => {
  const {
    name,
    description,
    typeId,
    categoryId,
    imageUrl,
    courseCount,
    badgeId,
    count,
    unit,
    language,
  } = args;
  const body = {
    name,
    description,
    typeId,
    categoryId,
    imageUrl,
    courseCount,
    count,
    unit,
    language,
  };
  const id = badgeId;
  const path = BADGES_API_PATHS.UPDATE_BADGE_BY_ID(id);
  const response = await API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body,
  });
  return response;
};

const getUSHGHQBadgeDetails = async ({
  id,
  language,
}: GetUSHGHQBadgeDetailsArgs): Promise<USHGHQBadgeDetailsResponse> => {
  const path = BADGES_API_PATHS.GET_BADGE_RECORD_BY_ID(id);
  const queryString = qs.stringify({ language });
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

export {
  getEarnedBadges,
  getInProgressBadges,
  getToEarnBadges,
  getBadgesLeaderboard,
  getBadgeDetailsById,
  getHQAdminBadges,
  deleteBadge,
  getBadgeCriterias,
  createBadge,
  createBadgeInMultiLang,
  updateBadge,
  getUSHGHQBadgeDetails,
  getUpNextBadges,
};
