import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { PROFILE_API_PATHS } from '../constants';
import { ConfirmUserOnboardingStatus, UpdateUserProfileRequest } from '../types';

/**
 * Get User Profile
 */
const getUserProfile = async () => {
  const path = PROFILE_API_PATHS.USER_PROFILE;

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    withCredentials: true,
  });

  return response.data;
};

/**
 * Update User Profile
 */

const updateUserProfile = async (args: UpdateUserProfileRequest) => {
  const path = PROFILE_API_PATHS.USER_PROFILE;

  const response = await API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    body: args,
    withCredentials: true,
  });

  return response.data;
};

/**
 * Update User Onboarding Status
 */

const updateUserOnboardingStatus = async (args: ConfirmUserOnboardingStatus) => {
  const path = PROFILE_API_PATHS.USER_CONFIRM_ONBOARDING;

  const response = await API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    body: args,
    withCredentials: true,
  });
  return response.data;
};

export { getUserProfile, updateUserProfile, updateUserOnboardingStatus };
