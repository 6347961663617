import { PropsWithChildren } from 'react';
import useStrapiBadgesData from '../../hooks/useStrapiBadgeData';
import * as Dialog from '@radix-ui/react-dialog';
import { X } from 'phosphor-react';

const BadgeDetailsLayout = ({ children }: PropsWithChildren) => {
  // strapi
  const { singleBadge } = useStrapiBadgesData();
  // strapi

  return (
    <div className="mx-1 flex flex-col rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <div className="flex w-full items-center justify-between">
        <Dialog.Title className="text-lg font-semibold normal-case leading-6 tracking-wide text-white">
          {singleBadge.title}
        </Dialog.Title>
        <Dialog.Close>
          <X
            size={24}
            fill="white"
            color="currentColor"
            className="text-zinc-300 transition-colors hover:text-zinc-100"
          />
        </Dialog.Close>
      </div>
      {children}
    </div>
  );
};

export default BadgeDetailsLayout;
