import { flexRender, Header, SortDirection } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DynamicSortIcon } from '../../../common';
import { resetSort, toggleSortKey, toggleSortOrder } from '../../../slices/team.slice';
import { PartialMembers } from '../../../types/interface';
interface Props {
  header: Header<PartialMembers, unknown>;
}

const AdminViewTeamMembersTableHeaderRow = ({ header }: Props) => {
  const dispatch = useDispatch();
  const [sortDirection, setSortDirection] = useState('asc');
  const toggleColumnSorting = () => {
    // header.column.toggleSorting(undefined, true);
    if (header.id === 'edit') {
      return null;
    }
    if (sortDirection === 'asc') {
      // header.column.toggleSorting(undefined);
      setSortDirection('desc');
    } else {
      setSortDirection('asc');
    }
    dispatch(toggleSortKey(header.id));
    dispatch(toggleSortOrder(sortDirection));
  };

  useEffect(() => {
    return () => {
      dispatch(resetSort());
    };
  }, []);
  return (
    <th
      className="cursor-pointer bg-zinc-900 px-2 py-3 text-start text-[12px] font-semibold leading-[16px] text-zinc-200"
      key={header.id}
      colSpan={header.colSpan}
      onClick={toggleColumnSorting}
    >
      <div className="flex w-full items-center gap-2">
        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
        {header.id !== 'edit' && (
          <DynamicSortIcon
            fallback={<div className="h-4 w-4 bg-transparent"></div>}
            sortDirection={sortDirection as SortDirection}
          />
        )}
      </div>
    </th>
  );
};

export default AdminViewTeamMembersTableHeaderRow;
