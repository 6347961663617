import { ErrorBoundary } from 'react-error-boundary';
import cn from '../../../../../utils/cn';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';
import LearningJourneyCoursesList from './LearningJourneyCoursesList';

interface Props {
  className?: string;
}

const LearningJourneyDetailMain = (props: Props) => {
  const { className } = props;

  return (
    <main id="main-content" className={cn(className)}>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <LearningJourneyCoursesList />
      </ErrorBoundary>
    </main>
  );
};

export default LearningJourneyDetailMain;
