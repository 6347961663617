import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import TimeStampCell from './TableCell/TimeStampCell';
import StatusCell from './TableCell/StatusCell';
import AmountCell from './TableCell/AmountCell';
// TODO: Removed part of USHG-1914 JIRA Ticket
// import UsersCountCell from './TableCell/UsersCountCell';
import InvoiceCell from './TableCell/InvoiceCell';
import BillingHistoryTableHeaderRow from './BillingHistoryTableHeaderRow';
import BillingHistoryTableDataRow from './BillingHistoryTableDataRow';
import { Bills } from '../../../types/billing';
import {
  AMOUNT_DUE,
  BILLING_DATE,
  DUE_DATE,
  INVOICE_PDF_URL,
  IS_OVER_DUE,
} from '../../../constants/billings';
import { useStrapiSettingsData } from '../../../../settings/hooks/useStrapiSettingsData';

interface Props {
  className?: string;
  data: Bills[];
}
const getTableColumnDefinition = (
  billingDateTableColumnHeader: string,
  amountTableColumnHeader: string,
  paymentDueTableColumnHeader: string,
  // TODO: Removed part of USHG-1914 JIRA Ticket
  // usersTableColumnHeader: string,
  invoiceTableColumnHeader: string,
  statusTableColumnHeader: string
) => {
  const columnHelper = createColumnHelper<Bills>();

  const columns = [
    columnHelper.accessor(BILLING_DATE, {
      header: billingDateTableColumnHeader,
      cell: (props) => <TimeStampCell timestamp={props.getValue() as number} />,
    }),
    columnHelper.accessor(AMOUNT_DUE, {
      header: amountTableColumnHeader,
      cell: (props) => <AmountCell amount={props.getValue()} />,
    }),
    columnHelper.accessor(DUE_DATE, {
      header: paymentDueTableColumnHeader,
      cell: (props) => <TimeStampCell timestamp={props.getValue() as number} />,
    }),
    // TODO: Removed part of USHG-1914 JIRA Ticket
    /* columnHelper.accessor(QUANTITY, {
      header: usersTableColumnHeader,
      cell: (props) => <UsersCountCell quantity={props.getValue()} />,
    }), */
    columnHelper.accessor(IS_OVER_DUE, {
      header: statusTableColumnHeader,
      cell: (props) => (
        <StatusCell isOverDue={props.getValue()} isPaid={props.row.original.isPaid} />
      ),
    }),
    columnHelper.accessor(INVOICE_PDF_URL, {
      cell: (props) => <InvoiceCell invoice={props.getValue() as string} />,
      id: INVOICE_PDF_URL,
      header: invoiceTableColumnHeader,
    }),
  ];
  return columns;
};

const BillingHistoryTable = ({ className, data }: Props) => {
  const { billingHistory } = useStrapiSettingsData();
  const {
    billingDateTableHeader,
    statusTableHeader,
    amountTableHeader,
    paymentDueTableHeader,
    // TODO: Removed part of USHG-1914 JIRA Ticket
    // usersTableHeader,
    invoiceTableHeader,
  } = billingHistory;
  const columns = getTableColumnDefinition(
    billingDateTableHeader,
    amountTableHeader,
    paymentDueTableHeader,
    // TODO: Removed part of USHG-1914 JIRA Ticket
    // usersTableHeader,
    invoiceTableHeader,
    statusTableHeader
  );
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiSort: true,
    data,
  });

  return (
    <table className={clsx('w-full', className)}>
      <thead className="sticky top-0 w-full">
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <BillingHistoryTableHeaderRow
                  isSortable={true}
                  allowMultiColSort={false}
                  header={header}
                  key={header.id}
                />
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <BillingHistoryTableDataRow
            className="cursor-pointer hover:bg-page-bg-dark/20"
            row={row}
            key={row.id}
          />
        ))}
      </tbody>
    </table>
  );
};

export default BillingHistoryTable;
