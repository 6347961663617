import * as Tooltip from '@radix-ui/react-tooltip';
import cn from '../../utils/cn';

type Props = {
  content: string;
  subContent?: string;
  ariaLabel?: string;
  direction?: 'top' | 'right' | 'bottom' | 'left';
  alignment?: 'start' | 'center' | 'end';
  duration?: number;
  showArrow?: boolean;
  sideOffset?: number;
  contentClassName?: Parameters<typeof cn>[0];
  asChild?: boolean;
  enabled?: boolean;
  collisionPadding?: number;
  open?: boolean;
};

const TooltipWrapper = (props: React.PropsWithChildren<Props>) => {
  const {
    content,
    direction,
    ariaLabel,
    alignment,
    duration,
    subContent,
    children,
    showArrow,
    sideOffset,
    contentClassName,
    asChild,
    enabled = true,
    collisionPadding,
    open,
  } = props;

  // If not enabled render the children without the tooltip
  if (!enabled) {
    return <>{children}</>;
  }

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={duration ?? 800} open={open ?? undefined}>
        <Tooltip.Trigger asChild={asChild}>{children}</Tooltip.Trigger>
        <Tooltip.Content
          side={direction ?? 'top'}
          align={alignment ?? 'center'}
          aria-label={ariaLabel}
          className={cn(
            'flex w-max flex-col whitespace-normal rounded-sm bg-zinc-700 py-1 px-2 text-xs text-white',
            contentClassName
          )}
          sideOffset={sideOffset}
          collisionPadding={collisionPadding}
        >
          {showArrow && <Tooltip.Arrow fill="white"></Tooltip.Arrow>}
          <div className="w-full">
            <div>{content}</div>
            {subContent && <div>{subContent}</div>}
          </div>
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default TooltipWrapper;
