import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { queryClient } from '../../../config/react-query';
import { USHG_HQ_APP_ROLE } from '../../../constants';
import { USHGAPIError } from '../../../types';
import { FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY } from '../../shared/constants';
import {
  ApproveOrRejectEnterpriseAdminRequest,
  approveOrRejectEnterpriseAdminRequest,
} from '../api';
import { ENTERPRISE_ADMIN_REQUEST_ACTION } from '../constants';
import { useStrapiRequestData } from './useStrapiRequestData';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';

const getSuccessActionMessage = (
  action: keyof typeof ENTERPRISE_ADMIN_REQUEST_ACTION,
  // Toast Messages are messages from strapi
  toastMessages: Record<string, string>
) => {
  const { rejectSuccessTitle, approveSuccessTitle, requestProcessingTitle } = toastMessages;
  switch (action) {
    case 'APPROVE': {
      return approveSuccessTitle;
    }
    case 'REJECT': {
      return rejectSuccessTitle;
    }
    default: {
      return requestProcessingTitle;
    }
  }
};

const getErrorActionMessage = (
  action: keyof typeof ENTERPRISE_ADMIN_REQUEST_ACTION,
  // Toast Messages are messages from strapi
  toastMessages: Record<string, string>
) => {
  const { approveFailedTitle, rejectFailureTitle, requestProccessingFailureTitle } = toastMessages;
  switch (action) {
    case 'APPROVE': {
      return approveFailedTitle;
    }
    case 'REJECT': {
      return rejectFailureTitle;
    }
    default: {
      return requestProccessingFailureTitle;
    }
  }
};

const useEnterpriseAdminRequestActionMutation = () => {
  // strapi data
  const { toastMessages } = useStrapiRequestData();
  const { requestNotFoundError, requestAlreadyHandledError } = toastMessages;

  // strapi api errors map
  const { errorsMap: strapiErrorMessageMap } = useStrapiDataHelper();

  const mutation = useMutation({
    mutationFn: async (args: ApproveOrRejectEnterpriseAdminRequest) => {
      const response = await approveOrRejectEnterpriseAdminRequest(args);
      return response;
    },
    onSuccess: (_, variables) => {
      const { action } = variables;
      const title = getSuccessActionMessage(action, toastMessages);
      // Invalidate the enterprise subscription query if the action is aprrove
      if (action === 'APPROVE') {
        queryClient.invalidateQueries([FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY]);
      }
      // Invalidate the enterprise admin requests query
      queryClient.invalidateQueries([
        {
          persona: USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
          scope: 'requests',
          item: 'requests',
        },
      ]);

      toast.custom((t) => <Toast variant="success" Title={title} toastInstance={t} />);
    },
    onError: (error, variables) => {
      const { action } = variables;

      const title = getErrorActionMessage(action, toastMessages);
      let subtitle: string | undefined = undefined;

      if (axios.isAxiosError(error)) {
        const data = error.response?.data;

        if (data) {
          // USHG API Error
          const { errorCode, errorMessage } = data as USHGAPIError;
          switch (errorCode) {
            case 'REQUEST_NOT_FOUND': {
              subtitle = requestNotFoundError;
              break;
            }
            case 'REQUEST_ALREADY_HANDLED': {
              subtitle = requestAlreadyHandledError;
              break;
            }
            case 'USER_ALREADY_EXIST': {
              subtitle = errorMessage;
              break;
            }
            case 'TEAM_ALREADY_EXIST': {
              subtitle = errorMessage;
              break;
            }
            case 'TRIAL_LIMIT_EXCEEDED': {
              subtitle = strapiErrorMessageMap?.['TRIAL_LIMIT_EXCEEDED'];
              break;
            }
            case 'SUBSCRIPTION_LICENSES_EXCEEDS': {
              subtitle = strapiErrorMessageMap?.['SUBSCRIPTION_LICENSES_EXCEEDS'];
              break;
            }
          }
        }
      }

      toast.custom((t) => (
        <Toast variant="error" SubTitle={subtitle} Title={title} toastInstance={t} />
      ));
    },
  });

  return mutation;
};

export default useEnterpriseAdminRequestActionMutation;
