import { useCallback, useEffect, useMemo, useState } from 'react';

type useMediaUploadManagerProps = {
  uploader: any;
};

type uploadStates = {
  isProgress: boolean;
  progress: number;
  isError: boolean;
  error: string;
  isUploaded: boolean;
  initiating: boolean;
  aborted: boolean;
  data: any;
};

const useClosedCaptionsUploadManager = ({ uploader }: useMediaUploadManagerProps) => {
  const initialUploadState = useMemo(
    () => ({
      isProgress: false,
      progress: 0,
      isError: false,
      error: '',
      isUploaded: false,
      initiating: false,
      aborted: false,
      data: undefined,
    }),
    [] // Empty dependency array ensures this object is only created once
  );

  const [uploadState, setUploadState] = useState<uploadStates>(initialUploadState);

  const resetUploadState = useCallback(() => {
    setUploadState(initialUploadState);
  }, [initialUploadState]);

  useEffect(() => {
    if (uploader) {
      let percentage: any = undefined;
      uploader
        .onProgress(({ percentage: newPercentage }: any) => {
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            setUploadState({
              ...uploadState,
              isProgress: true,
              progress: percentage,
            });
          }
        })
        .onError((error: any) => {
          console.log(error);
          setUploadState({
            ...uploadState,
            isProgress: false,
            isError: true,
            error: error,
          });
        })
        .onComplete((response: any) => {
          setUploadState({
            ...uploadState,
            isProgress: false,
            isUploaded: true,
            data: response.data,
          });
        })
        .onInitiate((initiate: boolean) => {
          setUploadState({
            ...uploadState,
            initiating: initiate,
          });
        });

      setUploadState({
        ...uploadState,
        isProgress: true,
      });

      uploader.start();
    }
  }, [uploader]);

  return { uploadState, resetUploadState };
};

export default useClosedCaptionsUploadManager;
