import { useNavigate } from 'react-router-dom';
import DataNotFound from '../../../../components/DataTables/DataNotFound';
import NoDataDisplay from '../../../../components/DataTables/NoDataDisplay';
import { useStrapiSharedData } from '../../../shared/hooks/useStrapiSharedData';

interface Props {
  isFilterApplied: boolean;
  className?: string;
}

const OrganisationEmptyState = ({ isFilterApplied, className }: Props) => {
  const navigate = useNavigate();
  const { clearAllFiltersButton } = useStrapiSharedData().tableProps;
  const clearFilters = () => {
    navigate('.');
  };
  if (isFilterApplied) {
    return (
      <NoDataDisplay
        className={className}
        message={
          <div className="flex flex-col items-center gap-8">
            <span>{'No Search results'}</span>
            <button
              className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
              onClick={() => clearFilters()}
            >
              {clearAllFiltersButton}
            </button>
          </div>
        }
      />
    );
  }

  return <DataNotFound messageTitle={'No Organisations is created'} messageDescription={''} />;
};

export default OrganisationEmptyState;
