import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { SETTING_API_PATHS, TENANT_MFA_CONFIG } from '../constants';
import { GetTenantMFAConfigResponse, UpdateTenantMFAConfigResponse } from '../types';

/**
 * Get tenant mfa configuration
 */
const getTenantMFAConfiguration = async (): Promise<GetTenantMFAConfigResponse> => {
  const path = SETTING_API_PATHS.GET_TENANT_MFA_CONFIGURATION;

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
    response: true,
  });

  return response.data;
};

export type UpdateTenantMFAConfigurationParams = {
  mfaConfig: keyof typeof TENANT_MFA_CONFIG;
};

/**
 * Update tenant mfa configuration
 */
const updateTenantMFAConfiguration = async (
  params: UpdateTenantMFAConfigurationParams
): Promise<UpdateTenantMFAConfigResponse> => {
  const { mfaConfig } = params;
  const path = SETTING_API_PATHS.UPDATE_TENANT_MFA_CONFIGURATION;

  const requestBody = {
    mfaConfig,
  };

  const response = await API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body: requestBody,
    withCredentials: true,
    response: true,
  });

  return response.data;
};

export { getTenantMFAConfiguration, updateTenantMFAConfiguration };
