import clsx from 'clsx';
import { Goal } from '../../../../../types';
import { generateCDNMediaAssetURLWithKey } from '../../../../../../shared/helpers';
import { ENTERPRISE_ONBOARDING_TYPE } from '../../../../../constants';

type GoalsCardDisplayProps = {
  style: React.CSSProperties;
  goals: Goal[];
  handleSelectedGoals: (goal: Goal) => void;
  selectedGoals: Goal[];
  widgetType: keyof typeof ENTERPRISE_ONBOARDING_TYPE;
};

const GoalsCardDisplay = ({
  style,
  goals,
  handleSelectedGoals,
  selectedGoals,
  widgetType,
}: GoalsCardDisplayProps) => {
  // Set Goals on card click
  const onCardClick = (goal: Goal) => {
    handleSelectedGoals(goal);
  };

  const isGoalSelected = (goal: Goal, selectedGoals: Goal[]): boolean => {
    return selectedGoals.some((selectedGoal) => selectedGoal.id === goal.id);
  };

  return (
    <div className="grid grid-cols-[repeat(auto-fill,_minmax(8rem,_1fr))] justify-between gap-4 lg:grid-cols-[repeat(auto-fill,_minmax(10rem,_1fr))]">
      {goals
        .filter((goal) => goal.type === widgetType)
        .map((goal, index) => (
          <div
            key={index}
            className={clsx('h-fit w-full cursor-pointer')}
            onClick={() => onCardClick(goal)}
          >
            <div
              className={clsx(
                'relative h-[192px] w-full overflow-hidden rounded-[4px] border-0 md:h-[241px]',
                isGoalSelected(goal, selectedGoals) && 'border-4 border-emerald-500'
              )}
            >
              <img
                className="h-[192px] w-full object-cover md:h-[241px]"
                src={generateCDNMediaAssetURLWithKey({ key: goal.image })}
                alt={goal.name}
                loading="lazy"
              />
              <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[#010211] via-[#03072B]/[0.35] transition group-hover:scale-105 group-hover:bg-[#010314C7]/[0.58]"></div>
              <div className="absolute top-0 h-full w-full">
                <div
                  className="absolute right-[11.95%] bottom-[10.87%] left-[12.17%] top-[70.88%] flex flex-row items-end justify-between"
                  style={style}
                >
                  <p className="text-center text-[14.4838px] font-bold leading-[22px] tracking-tight text-white md:text-[17.9391px]">
                    {goal.name}
                  </p>
                </div>
              </div>
              {isGoalSelected(goal, selectedGoals) && (
                <div className="absolute top-2 right-2 flex h-6 w-6 items-center justify-center rounded-full bg-emerald-500 py-3 font-bold text-white">
                  <span className="text-xl font-normal text-white">&#10003;</span>
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default GoalsCardDisplay;
