import { useMemo } from 'react';
import Dialog from '../../../../components/Dialog';
import RaiseARequestToHQModal from '../RaiseARequestToHQModal';

interface Props {
  isOpen: boolean;
  setIsOpen: (o: boolean) => void;
  type: HelpWidgetDialogType | null;
}

export type HelpWidgetDialogType = 'raise-request-to-hq';

const HelpWidgetDialog = ({ isOpen, setIsOpen, type }: Props) => {
  const DialogContent = useMemo(() => {
    switch (type) {
      case 'raise-request-to-hq': {
        return <RaiseARequestToHQModal setIsModalOpen={setIsOpen} />;
      }
    }
  }, [type, setIsOpen]);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={setIsOpen}
      triggerClassName="flex justify-end basis-0 grow-0"
      Content={DialogContent}
      onInteractOutside={(e) => {
        // Donot close the Modal when we click outside the Dialog Content
        e.preventDefault();
      }}
    ></Dialog>
  );
};

export default HelpWidgetDialog;
