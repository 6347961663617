import { readFromLocalStorage } from '../../../utils';
import { LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY } from '../constants';
import { ExtendedRegistration } from '../types';

// If there is registration data in `localStorage` load it or return `undefined` if there is no data
const getRegistrationData = (): ExtendedRegistration | undefined => {
  if (window) {
    try {
      const regsitrationData: ExtendedRegistration | undefined = readFromLocalStorage(
        LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY
      );

      if (regsitrationData) {
        return regsitrationData;
      }
      return undefined;
    } catch (error) {
      console.log('Error parsing localStorage cached registration data', error);
      return undefined;
    }
  }
  return undefined;
};
export default getRegistrationData;
