import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { Label, TextAreaInput, TextInput } from '../../../../../components/FormElements';
import { fieldHasError } from '../../../../../utils/react-hook-form';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
import { useGetCategoriesQuery } from '../../../../course-management/hooks';

import useGetBadgeCriteriasQuery from '../../../hooks/useGetBadgeCriteriasQuery';
import { FormActionButton } from '../../../../course-management/components/common/FormControls';
import MediaUploadDropZone from '../../../../../components/DropZone/MediaUploadDropZone';
import {
  useCreateBadgeInMultiLangMutation,
  useCreateBadgeMutation,
  useUpdateBadgeMutation,
} from '../../../hooks/useCreateAndUpdateMutation';
import toast from 'react-hot-toast';
import { Toast } from '../../../../../components/Toast';
import { queryClient } from '../../../../../config/react-query';
import { BasicSpinner } from '../../../../../components/Spinners';
import {
  BADGE_ACTION,
  BADGE_TYPES,
  CreateBadgeInMultiLangPayload,
  CreateBadgePayload,
  ModalType,
  UNIT_OPTIONS,
  UpdateBadgePayload,
  USHGHQBadgeDetailsResponse,
} from '../../../constants/badges';
import Dialog from '../../../../../components/Dialog';
import useUploadImageQuery from '../../../../shared/hooks/useUploadImageQuery';
import { UPLOAD_REQUEST_TYPE } from '../../../../shared/constants/imageUpload';
import { validateMediaFile } from '../../../../shared/helpers/media.helper';
import useStrapiBadgesData from '../../../hooks/useStrapiBadgeData';
import { customStylesOne } from '../../../../../styles/modalCustomStyle';
import { ERROR_CODE } from '../../../constants/errors';
import { generateCDNMediaAssetURLWithKey } from '../../../../shared/helpers';
import cn from '../../../../../utils/cn';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../constants/user';

interface BadgeCreateModalProps {
  setIsBadgeModalOpen: (open: boolean) => void;
  badgePreFetchData?: USHGHQBadgeDetailsResponse;
  formActionButtonLabel?: string;
  buttonIcon?: React.ReactNode;
  modalType: ModalType;
  refetch?: any;
  language: string;
  mappedOptions?: { display: string; value: string }[];
  errorType?: string | null;
  setErrorType?: (error: string | null) => void;
  setActionInProgress?: (a: keyof typeof BADGE_ACTION | null) => void;
}

interface Schema {
  nameMinValue: number;
  nameMinError: string;
  nameMaxValue: number;
  nameMaxError: string;
  nameRequired: string;
  descriptionRequired: string;
  descriptionMinValue: number;
  descriptionMinError: string;
  descriptionMaxValue: number;
  descriptionMaxError: string;
  targetCourseRequired: string;
  howToEarnRequired: string;
  categoryRequired: string;
  badgeImageRequired: string;
  durationRequired: string;
  targetMinValue: number;
  targetMinError: string;
}

function getSchema(data: Schema) {
  const {
    nameMinValue,
    nameMinError,
    nameMaxValue,
    nameMaxError,
    nameRequired,
    descriptionRequired,
    descriptionMinValue,
    descriptionMinError,
    descriptionMaxValue,
    descriptionMaxError,
    targetCourseRequired,
    howToEarnRequired,
    categoryRequired,
    badgeImageRequired,
    durationRequired,
    targetMinValue,
    targetMinError,
  } = data;
  const badgeFormSchema = yup.object({
    name: yup
      .string()
      .trim()
      .required(nameRequired)
      .min(nameMinValue, nameMinError)
      .max(nameMaxValue, nameMaxError)
      .label('name'),
    criteria: yup.string().required(howToEarnRequired).label('criteria'),
    criteriaSlug: yup.string().label('criteriaSlug'),
    description: yup
      .string()
      .trim()
      .required(descriptionRequired)
      .min(descriptionMinValue, descriptionMinError)
      .max(descriptionMaxValue, descriptionMaxError)
      .label('description'),
    category: yup
      .string()
      .when(['criteriaSlug'], (value, schema) => {
        if (value === 'FOCUS_AREA') {
          return schema.required(categoryRequired);
        }
      })
      .label('category'),
    unit: yup
      .string()
      .when(['criteriaSlug'], (value, schema) => {
        if (value === BADGE_TYPES.LEARNING_STREAK) {
          return schema.required(durationRequired);
        }
      })
      .label('unit'),
    count: yup
      .number()
      .min(targetMinValue, targetMinError)
      .when(['criteriaSlug'], (value, schema) => {
        if (
          value === BADGE_TYPES.FOCUS_AREA ||
          value === BADGE_TYPES.COURSE_ACCOMPLISHMENT ||
          value === BADGE_TYPES.LEARNING_STREAK
        ) {
          return schema.required(targetCourseRequired);
        }
      })
      .typeError(targetCourseRequired)
      .label('count'),
    badgeImage: yup.string().required(badgeImageRequired).label('badgeImage'),
  });

  return badgeFormSchema;
}

const BadgeForm = ({
  badgePreFetchData,
  formActionButtonLabel,
  buttonIcon,
  modalType,
  setIsBadgeModalOpen,
  language,
  mappedOptions,
  errorType,
  setErrorType,
  setActionInProgress,
}: BadgeCreateModalProps) => {
  //  Form Declaration
  const { modal, hqToast } = useStrapiBadgesData();
  const {
    dropdownPlaceholderMessages: { loadingMessage, noOptionsMessage },
  } = useStrapiSharedData();

  const {
    nameLabel,
    namePlaceholder,
    descriptionLabel,
    descriptionPlaceholder,
    targetCourseLabel,
    targetCoursePlaceholder,
    categoryLabel,
    categoryPlaceholder,
    howToEarnLabel,
    howToEarnPlaceholder,
    badgeImageType,
    badgeImageLabel,
    badgeImageMaxValue,
    cancelButton,
    targetDaysLabel,
    targetDaysPlaceholder,
    durationLabel,
    durationPlaceholder,
  } = modal;
  const { badgeCreatedSuccess, badgeCreatedFailure, badgeUpdatedSuccess, badgeUpdatedFailure } =
    hqToast;
  const badgeFormSchema = getSchema(modal);
  type BADGE_MODAL_FORM_DATA = yup.InferType<typeof badgeFormSchema>;
  const {
    register,
    setValue,
    trigger,
    formState: { errors },
    watch,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    control,
  } = useForm<BADGE_MODAL_FORM_DATA>({
    mode: 'onChange',
    resolver: yupResolver(badgeFormSchema),
  });

  // state

  // State to manage field disabling
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false); // Track refetching state

  // state

  //  Mutation Hooks
  const createBadgeMutation = useCreateBadgeMutation();
  const createBadgeInMultiLangMutation = useCreateBadgeInMultiLangMutation();
  const updateBadgeMutation = useUpdateBadgeMutation();

  //  Drop down fetch hooks
  const categoriesFetch = useGetCategoriesQuery({ language: language });
  const criteriaFetch = useGetBadgeCriteriasQuery({ language: language });

  useEffect(() => {
    reset();

    const setBadgeValues = () => {
      if (!badgePreFetchData) return;

      // Set common fields
      setValue('category', badgePreFetchData?.category?.id?.toString());
      setValue('unit', badgePreFetchData?.unit?.toString());
      setValue('count', badgePreFetchData?.courseCount || badgePreFetchData?.count || undefined);
      setValue('badgeImage', badgePreFetchData?.picture || '');
      setValue(
        'criteria',
        badgePreFetchData?.criteriaType ? `${badgePreFetchData.criteriaType.id}` : ''
      );
      setValue('criteriaSlug', badgePreFetchData?.criteriaType.badgeTypeKey);

      // Set additional fields if in edit mode
      if (modalType !== 'manage' || errorType !== ERROR_CODE.BADGE_ID_NOT_FOUND) {
        setValue('name', badgePreFetchData?.name || '');
        setValue('description', badgePreFetchData?.description || '');
      }
    };

    if (badgePreFetchData) {
      if (modalType === 'manage' && errorType === ERROR_CODE.BADGE_ID_NOT_FOUND) {
        setIsFieldDisabled(true); // Disable fields in create mode
      } else if (modalType === 'manage' || modalType === 'update') {
        setIsFieldDisabled(modalType === 'manage'); // Disable fields in manage mode, enable in edit mode
      }
      setBadgeValues(); // Prepopulate fields
    }
  }, [badgePreFetchData, errorType, modalType, reset, setValue]);

  // badge upload cancel
  const onBadgeUploadCancel = () => {
    if (badgeUploader) {
      badgeAbort.abort();
    }
  };

  const badgeCreateHandler = async (data: BADGE_MODAL_FORM_DATA) => {
    const createBadge: CreateBadgePayload = {
      description: data.description,
      name: data.name,
      typeId: data?.criteria ? parseInt(data?.criteria) : undefined,
      categoryId: data?.category ? parseInt(data?.category) : undefined,
      imageUrl: data.badgeImage,
    };
    if (criteria === BADGE_TYPES.COURSE_ACCOMPLISHMENT || criteria === BADGE_TYPES.FOCUS_AREA) {
      createBadge.courseCount = data.count;
    } else if (criteria === BADGE_TYPES.LEARNING_STREAK) {
      createBadge.count = data.count;
      createBadge.unit = data.unit;
    }
    setIsRefetching(true);
    setActionInProgress && setActionInProgress('create');
    createBadgeMutation.mutate(createBadge, {
      onSuccess: async () => {
        toast.custom(
          (t) => <Toast variant="success" Title={badgeCreatedSuccess} toastInstance={t} />,
          { id: 'ADD_NEW_BADGE_TOAST_' + Math.floor(Math.random() * 3000).toString() }
        );
        await queryClient.invalidateQueries({
          queryKey: [
            {
              scope: 'badges',
              item: 'get-badges',
            },
          ],
        });
        setIsRefetching(false);
        setIsBadgeModalOpen(false);
      },

      onError: () => {
        toast.custom(
          (t) => <Toast variant="error" Title={badgeCreatedFailure} toastInstance={t} />,
          { id: 'ADD_NEW_BADGE_TOAST_' + Math.floor(Math.random() * 3000).toString() }
        );
        setIsRefetching(false);
        setIsBadgeModalOpen(false);
      },
      onSettled: () => {
        setActionInProgress && setActionInProgress(null);
      },
    });
  };

  const badgeCreateInMultiLangHandler = async (data: BADGE_MODAL_FORM_DATA) => {
    if (!badgePreFetchData?.id) {
      return;
    }

    const createBadge: CreateBadgeInMultiLangPayload = {
      badgeId: badgePreFetchData.id,
      name: data.name,
      description: data.description,
      language: language,
    };
    setIsRefetching(true);
    setActionInProgress && setActionInProgress('create');
    createBadgeInMultiLangMutation.mutate(createBadge, {
      onSuccess: async () => {
        toast.custom(
          (t) => <Toast variant="success" Title={badgeCreatedSuccess} toastInstance={t} />,
          {
            id: 'ADD_NEW_BADGE_MULTI_LANG_TOAST_' + Math.floor(Math.random() * 3000).toString(),
          }
        );
        setIsRefetching(false);
        setIsBadgeModalOpen(false);
        setErrorType && setErrorType(null);
      },
      onError: () => {
        toast.custom(
          (t) => <Toast variant="error" Title={badgeCreatedFailure} toastInstance={t} />,
          {
            id: 'ADD_NEW_BADGE_MULTI_LANG_TOAST_' + Math.floor(Math.random() * 3000).toString(),
          }
        );
        setIsRefetching(false);
        setIsBadgeModalOpen(false);
      },
      onSettled: () => {
        setActionInProgress && setActionInProgress(null);
      },
    });
  };

  const badgeUpdateHandler = async (data: BADGE_MODAL_FORM_DATA) => {
    if (badgePreFetchData) {
      const updateBadge: UpdateBadgePayload = {
        badgeId: badgePreFetchData.id,
        description: data.description,
        name: data.name,
        typeId: data?.criteria ? parseInt(data?.criteria) : undefined,
        categoryId: data?.category ? parseInt(data?.category) : undefined,
        imageUrl: data.badgeImage,
        language: language,
      };

      if (criteria === BADGE_TYPES.COURSE_ACCOMPLISHMENT || criteria === BADGE_TYPES.FOCUS_AREA) {
        updateBadge.courseCount = data.count;
      } else if (criteria === BADGE_TYPES.LEARNING_STREAK) {
        updateBadge.count = data.count;
        updateBadge.unit = data.unit;
      }

      setIsRefetching(true);
      setActionInProgress && setActionInProgress('update');
      updateBadgeMutation.mutate(updateBadge, {
        onSuccess: async () => {
          toast.custom(
            (t) => <Toast variant="success" Title={badgeUpdatedSuccess} toastInstance={t} />,
            { id: 'UPDATE_NEW_BADGE_TOAST_' + Math.floor(Math.random() * 3000).toString() }
          );

          // Invalidate only for the current  language
          if (language && language !== DEFAULT_PREFERRED_LANGUAGE) {
            queryClient.invalidateQueries({
              queryKey: [
                {
                  scope: 'badges',
                  item: 'ushghq-admin-badge-details',
                  language,
                },
              ],
            });
          } else {
            // Invalidate for all other languages when updating DEFAULT LANGUAGE
            const allLanguages = mappedOptions?.map((option) => option.value);
            allLanguages?.forEach(async (lang) => {
              await queryClient.invalidateQueries({
                queryKey: [
                  {
                    scope: 'badges',
                    item: 'get-badges',
                  },
                ],
              });
              queryClient.invalidateQueries({
                queryKey: [
                  {
                    scope: 'badges',
                    item: 'ushghq-admin-badge-details',
                    language: lang,
                  },
                ],
              });
            });
          }
          setIsRefetching(false);
          setIsBadgeModalOpen(false);
        },
        onError: () => {
          toast.custom(
            (t) => <Toast variant="error" Title={badgeUpdatedFailure} toastInstance={t} />,
            { id: 'ADD_NEW_BADGE_TOAST_' + Math.floor(Math.random() * 3000).toString() }
          );
          setIsRefetching(false);
          setIsBadgeModalOpen(false);
        },
        onSettled: () => {
          setActionInProgress && setActionInProgress(null);
        },
      });
    }
  };

  const criteria = watch('criteriaSlug');
  const [badgeUploader, setBadgeUploader] = useState<any>(undefined);

  const unitDropdownOptions = UNIT_OPTIONS.map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  const {
    isProgress: badgeProgress,
    progress: badgeProgressPercent,
    initiating: badgeInitiating,
    isUploaded: badgeUploaded,
    data: badgeData,
    abort: badgeAbort,
  } = useUploadImageQuery({ upload: badgeUploader });

  // State to manage badge image upload
  const [isBadgeImageUploaded, setIsBadgeImageUploaded] = useState(badgeUploaded);

  const onDropBadge = useCallback(
    (acceptedFiles: any) => {
      const { error, message, type } = validateMediaFile(acceptedFiles, 10, 'image', 'Badge Image');
      if (error) {
        setError('badgeImage', { message });
      } else {
        const uploader = {
          fileType: type,
          requestType: UPLOAD_REQUEST_TYPE.SINGLE_OBJECT,
          file: acceptedFiles[0],
          module: 'badge',
          fileExtension: acceptedFiles[0].type,
        };
        setBadgeUploader(uploader);
      }
    },
    [setError]
  );

  useEffect(() => {
    if (badgeData) {
      setValue('badgeImage', badgeData);
      trigger('badgeImage');
    }
  }, [badgeData, setValue, trigger]);

  useEffect(() => {
    setIsBadgeImageUploaded(badgeUploaded);
  }, [badgeUploaded]);

  return (
    <form
      className="flex flex-col gap-4 overflow-y-scroll md:gap-6"
      onSubmit={(e) => {
        e.preventDefault();
        if (modalType === 'create') {
          handleSubmit(badgeCreateHandler)(e);
        } else if (modalType === 'update') {
          handleSubmit(badgeUpdateHandler)(e);
        } else if (modalType === 'manage') {
          if (errorType === ERROR_CODE.BADGE_ID_NOT_FOUND) {
            handleSubmit(badgeCreateInMultiLangHandler)(e);
          } else {
            handleSubmit(badgeUpdateHandler)(e);
          }
        }
      }}
    >
      <div className="flex w-full flex-col gap-4 md:gap-6">
        {/* Name */}
        <div className="flex w-full flex-col gap-1">
          <Label htmlFor="badge_name" label={nameLabel} />
          <TextInput
            placeholder={namePlaceholder}
            type="text"
            id="badge_name"
            className="mt-0.5 h-9 rounded-md"
            aria-invalid={fieldHasError(errors, 'name') ? 'true' : 'false'}
            hasError={fieldHasError(errors, 'name')}
            {...register('name')}
          />
          <HookFormErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <ErrorMessage message={message} />}
          />
        </div>
        {/* Name */}

        {/* Criteria */}
        <div className="flex w-full flex-col gap-4 md:flex-row md:items-start md:gap-1">
          <div
            className={cn(
              'flex grow basis-0 flex-col gap-1',
              (isFieldDisabled || modalType === 'update') && 'opacity-50'
            )}
          >
            <Label htmlFor="badge_criteria" label={howToEarnLabel} />
            <Controller
              name="criteria"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    isDisabled={isFieldDisabled || modalType === 'update'}
                    id="badge_criteria"
                    loadingMessage={() => loadingMessage}
                    noOptionsMessage={() => noOptionsMessage}
                    options={criteriaFetch.data}
                    placeholder={howToEarnPlaceholder}
                    isLoading={criteriaFetch.isLoading}
                    styles={{
                      ...customStylesOne,
                      control: (provided) => ({
                        ...provided,
                        background: '#18181B',
                        border: '1px solid #3F3F46 !important',
                        borderRadius: '6px !important',
                        boxShadow: 'none !important',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: '12px !important',
                        whiteSpace: 'nowrap', // Prevents text wrapping
                        overflow: 'hidden',
                        textOverflow: 'ellipsis', // Adds ellipsis (...) if text is too long
                      }),
                    }}
                    classNames={{
                      control: () => {
                        return 'h-10';
                      },
                    }}
                    value={
                      criteriaFetch.data?.find(
                        (criteria: { typeId: number }) => criteria.typeId.toString() === value
                      ) || undefined
                    }
                    aria-invalid={fieldHasError(errors, 'criteria') ? 'true' : 'false'}
                    onChange={(data: any) => {
                      clearErrors(['criteria', 'category', 'count']);
                      setValue('count', undefined);
                      onChange(data.typeId);
                      setValue('criteriaSlug', data.value);
                    }}
                  />
                );
              }}
            />
            <HookFormErrorMessage
              name="criteria"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </div>
          {/* Criteria */}

          {criteria === BADGE_TYPES.LEARNING_STREAK && (
            <>
              <div
                className={cn(
                  'flex grow basis-0 flex-col gap-1',
                  (isFieldDisabled || modalType === 'update') && 'opacity-50'
                )}
              >
                <Label htmlFor="count" label={targetDaysLabel} />
                <TextInput
                  disabled={isFieldDisabled || modalType === 'update'}
                  placeholder={targetDaysPlaceholder}
                  type="number"
                  id="count"
                  className="h-10 rounded-md"
                  aria-invalid={fieldHasError(errors, 'count') ? 'true' : 'false'}
                  hasError={fieldHasError(errors, 'count')}
                  {...register('count', {
                    valueAsNumber: true,
                  })}
                />
                <HookFormErrorMessage
                  name="count"
                  errors={errors}
                  render={({ message }) => <ErrorMessage message={message} />}
                />
              </div>
              <div
                className={cn(
                  'flex grow-[1.5] basis-0 flex-col gap-1',
                  (isFieldDisabled || modalType === 'update') && 'opacity-50'
                )}
              >
                <Label htmlFor="learning-streak-unit" label={durationLabel} />
                <Controller
                  name="unit"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Select
                      isDisabled={isFieldDisabled || modalType === 'update'}
                      id="learning-streak-unit"
                      loadingMessage={() => loadingMessage}
                      noOptionsMessage={() => noOptionsMessage}
                      options={unitDropdownOptions}
                      placeholder={durationPlaceholder}
                      styles={{
                        ...customStylesOne,
                        control: (provided) => ({
                          ...provided,
                          background: '#18181B',
                          border: '1px solid #3F3F46 !important',
                          borderRadius: '6px !important',
                          boxShadow: 'none !important',
                        }),
                      }}
                      classNames={{
                        control: () => {
                          return 'h-10';
                        },
                      }}
                      value={
                        unitDropdownOptions &&
                        unitDropdownOptions.find(
                          (unit: { value: string; label: string }) => unit.value === value
                        )
                      }
                      aria-invalid={fieldHasError(errors, 'unit') ? 'true' : 'false'}
                      onChange={(data: any) => {
                        onChange(data.value);
                      }}
                    />
                  )}
                />
                <HookFormErrorMessage
                  name="unit"
                  errors={errors}
                  render={({ message }) => <ErrorMessage message={message} />}
                />
              </div>
            </>
          )}

          {/* Category */}
          {criteria === BADGE_TYPES.FOCUS_AREA && (
            <div
              className={cn(
                'flex grow-[1.5] basis-0 flex-col gap-1',
                (isFieldDisabled || modalType === 'update') && 'opacity-50'
              )}
            >
              <Label htmlFor="course_category" label={categoryLabel} />
              <Controller
                name="category"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Select
                    isDisabled={isFieldDisabled || modalType === 'update'}
                    id="course_category"
                    loadingMessage={() => loadingMessage}
                    noOptionsMessage={() => noOptionsMessage}
                    options={categoriesFetch.data}
                    placeholder={categoryPlaceholder}
                    isLoading={categoriesFetch.isLoading}
                    styles={{
                      ...customStylesOne,
                      control: (provided) => ({
                        ...provided,
                        background: '#18181B',
                        border: '1px solid #3F3F46 !important',
                        borderRadius: '6px !important',
                        boxShadow: 'none !important',
                      }),
                    }}
                    classNames={{
                      control: () => {
                        return 'h-10';
                      },
                    }}
                    value={
                      categoriesFetch.data &&
                      categoriesFetch.data.find(
                        (category: { value: string; label: string }) => category.value === value
                      )
                    }
                    aria-invalid={fieldHasError(errors, 'category') ? 'true' : 'false'}
                    onChange={(data: any) => {
                      onChange(data.value);
                    }}
                  />
                )}
              />
              <HookFormErrorMessage
                name="category"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </div>
          )}
          {/* Category */}
          {/* Course Count */}
          {(criteria === BADGE_TYPES.COURSE_ACCOMPLISHMENT ||
            criteria === BADGE_TYPES.FOCUS_AREA) && (
            <div
              className={cn(
                'flex grow basis-0 flex-col gap-1',
                (isFieldDisabled || modalType === 'update') && 'opacity-50'
              )}
            >
              <Label htmlFor="count" label={targetCourseLabel} />
              <TextInput
                disabled={isFieldDisabled || modalType === 'update'}
                placeholder={targetCoursePlaceholder}
                type="number"
                id="count"
                className="h-10 rounded-md"
                aria-invalid={fieldHasError(errors, 'count') ? 'true' : 'false'}
                hasError={fieldHasError(errors, 'count')}
                {...register('count', {
                  valueAsNumber: true,
                })}
              />
              <HookFormErrorMessage
                name="count"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </div>
          )}
          {/* Course Count */}
        </div>
        {/* Description */}
        <div className="w-full">
          <Label htmlFor="badge_description" label={descriptionLabel} />
          <TextAreaInput
            id="badge_description"
            className="mt-0.5 h-28 w-full rounded-md"
            placeholder={descriptionPlaceholder}
            aria-invalid={fieldHasError(errors, 'description') ? 'true' : 'false'}
            hasError={fieldHasError(errors, 'description')}
            {...register('description')}
          />
          <HookFormErrorMessage
            name="description"
            errors={errors}
            render={({ message }) => <ErrorMessage message={message} />}
          />
        </div>
        {/* Description */}
        {/* Badge Image */}
        <div className={cn('w-full', isFieldDisabled && 'opacity-50')}>
          <label
            className="mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-400"
            htmlFor="badge_image"
          >
            {badgeImageLabel}
          </label>
          <Controller
            name="badgeImage"
            control={control}
            defaultValue=""
            render={({ field: { value } }) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <MediaUploadDropZone
                  fileType={badgeImageType}
                  maxSize={badgeImageMaxValue}
                  loader="progress"
                  onDropCallback={onDropBadge}
                  isUploaded={value && value.length > 0 ? true : isBadgeImageUploaded}
                  initiatingUpload={badgeInitiating}
                  isProgress={badgeProgress}
                  progress={badgeProgressPercent}
                  resetMedia={() => {
                    setValue('badgeImage', '');
                    trigger('badgeImage');
                    setIsBadgeImageUploaded(false);
                  }}
                  isPreviewImage={true}
                  previewImage={
                    value
                      ? generateCDNMediaAssetURLWithKey({
                          key: value,
                        })
                      : undefined
                  }
                  cancel={onBadgeUploadCancel}
                  disabled={isFieldDisabled}
                />
              </div>
            )}
          />
          <HookFormErrorMessage
            name="badgeImage"
            errors={errors}
            render={({ message }) => <ErrorMessage message={message} />}
          />
        </div>
        {/* Badge Image */}
      </div>

      {/* Action Buttons */}
      <div className="flex flex-col items-center justify-end gap-2 xs:flex-row">
        <Dialog.Close className="rounded bg-transparent py-2.5 px-[30px] text-center text-sm font-medium text-white">
          {cancelButton}
        </Dialog.Close>
        <FormActionButton
          type="submit"
          className="bg-base-brand disabled:opacity-80"
          disabled={
            createBadgeMutation.isLoading ||
            createBadgeInMultiLangMutation.isLoading ||
            updateBadgeMutation.isLoading ||
            criteriaFetch.isLoading ||
            categoriesFetch.isLoading ||
            Object.keys(errors).length !== 0 ||
            isRefetching
          }
        >
          {createBadgeMutation.isLoading ||
          updateBadgeMutation.isLoading ||
          createBadgeInMultiLangMutation.isLoading ||
          badgePreFetchData?.isLoading ||
          isRefetching ? (
            <div className="flex gap-2">
              <span>{formActionButtonLabel}</span>
              <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <span>{formActionButtonLabel}</span>
              {buttonIcon}
            </div>
          )}
        </FormActionButton>
      </div>
    </form>
  );
};

export default BadgeForm;
