import React, { useState, useEffect, useRef } from 'react';
import { StepType } from '../../types';
interface StepperProps {
  steps: Array<string>;
  currentStep: string;
}

const Stepper = ({ steps, currentStep }: StepperProps) => {
  const [newStep, setNewStep] = useState<StepType[]>([]);

  const stepsRef = useRef({});

  const updateStep = (stepNumber: number, steps: any) => {
    const newSteps = [...steps];
    let count = 0;
    while (count < newSteps.length) {
      // current step
      if (count === stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: false,
          completed: false,
        };
        count++;
      }

      // step completed
      else if (count < stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      }
      // step pending
      else {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }

    return newSteps;
  };

  useEffect(() => {
    const stepsState = steps.map((step) =>
      Object.assign(
        {},
        {
          description: step,
          completed: false,
          highlighted: false,
          selected: false,
        }
      )
    );

    stepsRef.current = stepsState;
    const current = updateStep(steps.indexOf(currentStep), stepsRef.current);
    setNewStep(current);
  }, [steps, currentStep]);

  const stepsDisplay = newStep.map((step, index) => {
    return (
      <div
        key={index}
        className={index !== newStep.length - 1 ? 'flex w-full items-center' : 'flex items-center'}
      >
        <div className="relative flex flex-col items-center text-zinc-50">
          <div
            className={`flex h-6 w-6 items-center justify-center rounded-full bg-zinc-700  py-3 transition duration-1000 ease-in-out  ${
              step.completed && '!bg-emerald-500 font-bold text-white'
            } ${step.highlighted && '!bg-base-brand'}
            `}
          >
            {step.completed ? (
              <span className="text-xl font-normal text-white">&#10003;</span>
            ) : (
              index + 1
            )}
          </div>
          <div
            className={`absolute top-0 mt-8 text-center text-[10px] font-medium leading-5  md:whitespace-nowrap md:text-sm lg:whitespace-nowrap ${
              step.highlighted ? 'text-base-brand' : 'text-zinc-400'
            } ${step.completed ? 'text-emerald-500' : 'text-zinc-400'}`}
          >
            {step.description}
          </div>
        </div>
        <div
          className={`flex-auto border-t-2 transition duration-500 ease-in-out  ${
            step.completed ? 'border-emerald-500' : 'border-dashed border-zinc-700 '
          }  `}
        ></div>
      </div>
    );
  });

  return <div className="flex items-center justify-between p-4">{stepsDisplay}</div>;
};
export default Stepper;
