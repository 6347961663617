import {
  ConsoleLogger,
  DefaultMessagingSession,
  Message,
  MessagingSessionConfiguration,
  MessagingSessionObserver,
  PrefetchOn,
  PrefetchSortBy,
} from 'amazon-chime-sdk-js';
import { useCallback, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { useReduxAuthState } from '../../../hooks';
import { getChimeMessagingClient } from '../helpers';

export type MessageUpdateCallback = (message: Message) => void;

const useChimeMessagingSession = () => {
  const auth = useReduxAuthState();

  const sessionIdRef = useRef(uuid());
  const messagingSessionRef = useRef<DefaultMessagingSession | null>(null);

  const messageUpdateCallbackRef = useRef<MessageUpdateCallback[]>([]);

  /**
   * Close the session.
   */
  const closeSession = useCallback(() => {
    try {
      messagingSessionRef.current && messagingSessionRef.current.stop();
    } catch (error) {
      console.log('Error closing session %o', error);
    }
  }, [messagingSessionRef]);

  /**
   * Notifies all the subscribers that a message event has been received.
   */
  const notifyAllMessageUpdateCallbacks = useCallback((message: Message) => {
    messageUpdateCallbackRef.current.forEach((cb) => {
      cb(message);
    });
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated) {
      /**
       * Establishes connection with messaging session endpoint.
       */
      const initializeChimeMessagingSession = async () => {
        const chimeMessagingClient = await getChimeMessagingClient();
        const logger = new ConsoleLogger('hq-chime-messaging');
        const appInstanceUserArn = auth.user['custom:appinstance_user_arn'];
        const sessionId = sessionIdRef.current;

        const sessionConfig = new MessagingSessionConfiguration(
          appInstanceUserArn,
          sessionId,
          undefined,
          chimeMessagingClient
        );

        // Fetch the channel details on prefetch
        sessionConfig.prefetchOn = PrefetchOn.Connect;
        sessionConfig.prefetchSortBy = PrefetchSortBy.Unread;

        const messagingSessionObserver: MessagingSessionObserver = {
          messagingSessionDidStartConnecting: (reconnecting: boolean) => {
            console.log(`${reconnecting ? 'Reconnecting' : 'Connecting'} to messaging session`);
          },
          messagingSessionDidStart: () => {
            console.log('Messaging session started');
          },
          messagingSessionDidReceiveMessage: (message: Message) => {
            notifyAllMessageUpdateCallbacks(message);
          },
          messagingSessionDidStop: (event: CloseEvent) => {
            console.log('Messaging session did stop', event);
          },
        };

        const messagingSession = new DefaultMessagingSession(sessionConfig, logger);

        // Add observer to different messaging session events
        messagingSession.addObserver(messagingSessionObserver);
        messagingSessionRef.current = messagingSession;

        // Start the session
        messagingSession.start();

        return () => {
          // Remove observer and close session
          messagingSession.removeObserver(messagingSessionObserver);
          closeSession();
        };
      };

      initializeChimeMessagingSession();
    }
  }, [auth.isAuthenticated, auth.user, closeSession, notifyAllMessageUpdateCallbacks]);

  const subscribeToMessageUpdate = useCallback(
    (cb: MessageUpdateCallback) => {
      const currentMessageUpdateCallbacks = messageUpdateCallbackRef.current;
      messageUpdateCallbackRef.current = [...currentMessageUpdateCallbacks, cb];
    },
    [messageUpdateCallbackRef]
  );

  const unsubscribeFromMessageUpdate = useCallback(
    (cb: MessageUpdateCallback) => {
      const currentMessageUpdateCallbacks = messageUpdateCallbackRef.current;
      const updatedMessageUpdateCallbacks = currentMessageUpdateCallbacks.filter(
        (ccb) => ccb !== cb
      );
      messageUpdateCallbackRef.current = updatedMessageUpdateCallbacks;
    },
    [messageUpdateCallbackRef]
  );

  return {
    session: messagingSessionRef.current,
    closeSession,
    subscribeToMessageUpdate,
    unsubscribeFromMessageUpdate,
  };
};

export default useChimeMessagingSession;
