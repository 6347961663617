import { useEffect, useState } from 'react';
import { TabWidgetType } from '../types/Filter.Helper';
import { COURSE_STATES } from '../components/SingleCourseView/constants/constants';
import { useStrapiCoursesData } from './useStrapiCourseData';
import { RootState } from '../../../store';
import { useAppSelector } from '../../../hooks';
import { USHG_HQ_APP_ROLE } from '../../../constants';

type statusCountProps = {
  progressCount: number | undefined;
  overdueCount: number | undefined;
  assignedCount: number | undefined;
  completedCount: number | undefined;
  allCount: number | undefined;
};

const authStateSelector = (state: RootState) => state.auth;

const useStatusCount = ({
  progressCount,
  overdueCount,
  assignedCount,
  completedCount,
  allCount,
}: statusCountProps) => {
  const { allCourses } = useStrapiCoursesData();
  const { navigator, status } = allCourses;
  const { user } = useAppSelector(authStateSelector);
  const widgetData: TabWidgetType[] = [
    {
      id: '1',
      text: status.inProgressLabel,
      value: COURSE_STATES.PROGRESS,
      subText: '0',
      isDue: false,
      isSelected: false,
    },
    {
      id: '4',
      text: status.completedLabel,
      value: COURSE_STATES.COMPLETED,
      subText: '0',
      isDue: false,
      isSelected: false,
    },
    {
      id: '5',
      text: navigator.allCoursesTab,
      value: COURSE_STATES.ALL,
      subText: '0',
      isDue: false,
      isSelected: false,
    },
    {
      id: '6',
      text: navigator.recommendedCoursesTab,
      value: COURSE_STATES.RECOMMENDED,
      isDue: false,
      isSelected: false,
    },
  ];
  if (user && user.role != USHG_HQ_APP_ROLE.INDIVIDUAL_USER) {
    widgetData.splice(1, 0, {
      id: '2',
      text: status.overDueLabel,
      value: COURSE_STATES.OVERDUE,
      subText: '0',
      isDue: true,
      isSelected: false,
    });
    widgetData.splice(2, 0, {
      id: '3',
      text: status.assignedLabel,
      value: COURSE_STATES.ASSIGNED,
      subText: '0',
      isDue: false,
      isSelected: false,
    });
  }
  const [widgets, setWidgets] = useState<TabWidgetType[]>(widgetData);
  useEffect(() => {
    if (progressCount !== undefined) {
      setWidgets(() =>
        widgets.map((widget) => {
          if (widget.value === COURSE_STATES.PROGRESS) {
            widget.subText = `${progressCount}`;
            return widget;
          }
          return widget;
        })
      );
    }
  }, [progressCount]);

  useEffect(() => {
    if (assignedCount !== undefined) {
      setWidgets(() =>
        widgets.map((widget) => {
          if (widget.value === COURSE_STATES.ASSIGNED) {
            widget.subText = `${assignedCount}`;
            return widget;
          }
          return widget;
        })
      );
    }
  }, [assignedCount]);

  useEffect(() => {
    if (completedCount !== undefined) {
      setWidgets(() =>
        widgets.map((widget) => {
          if (widget.value === COURSE_STATES.COMPLETED) {
            widget.subText = `${completedCount}`;
            return widget;
          }
          return widget;
        })
      );
    }
  }, [completedCount]);

  useEffect(() => {
    if (overdueCount !== undefined) {
      setWidgets(() =>
        widgets.map((widget) => {
          if (widget.value === COURSE_STATES.OVERDUE) {
            widget.subText = `${overdueCount}`;
            return widget;
          }
          return widget;
        })
      );
    }
  }, [overdueCount]);

  useEffect(() => {
    if (allCount !== undefined) {
      setWidgets(() =>
        widgets.map((widget) => {
          if (widget.value === COURSE_STATES.ALL) {
            widget.subText = `${allCount}`;
            return widget;
          }
          return widget;
        })
      );
    }
  }, [allCount]);

  return widgets;
};

export default useStatusCount;
