import HasAccess from '../../../../../shared/components/HasAccess';
import { USHG_HQ_APP_ROLE } from '../../../../../../constants';
import { twMerge } from 'tailwind-merge';
import { filterType } from '../../../../types/Filter.Helper';
import RecommendedCourseView from './RecommendedCourseView';
import useRecommendedCoursesFetch from '../../../../hooks/useRecommendedCoursesFetch';
import { RECOMMENDATION_TYPE } from '../../../../constants/course';
import { RootState } from '../../../../../../store';
import { useAppSelector } from '../../../../../../hooks';
import { FadeLoader } from 'react-spinners';
import { Button } from '../../../../../../components/Buttons';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';
type RecommendedCoursesProps = {
  className?: string;
  appliedFilters: filterType[];
  navigateToAllCourses: () => void;
};

const authStateSelector = (state: RootState) => state.auth;
const RecommendedCourses = ({
  className,
  appliedFilters,
  navigateToAllCourses,
}: RecommendedCoursesProps) => {
  const { user } = useAppSelector(authStateSelector);

  const hasOrganizationRecommendation =
    user &&
    (user.role === USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN ||
      user.role === USHG_HQ_APP_ROLE.ENTERPRISE_LEADER ||
      user.role === USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER ||
      user.role === USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER);

  const organizationRecommendationFetch = useRecommendedCoursesFetch({
    type: RECOMMENDATION_TYPE.ORGANIZATION,
    filters: appliedFilters,
    enabled: !!hasOrganizationRecommendation,
  });

  const hasTeamRecommendation =
    user &&
    (user.role === USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER ||
      user.role === USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER);

  // Fetch Team Recommendation
  const teamRecommendationFetch = useRecommendedCoursesFetch({
    type: RECOMMENDATION_TYPE.TEAM,
    filters: appliedFilters,
    enabled: !!hasTeamRecommendation,
  });
  // Fetch Personal Recommendation
  const personalRecommendationFetch = useRecommendedCoursesFetch({
    type: RECOMMENDATION_TYPE.PERSONAL,
    filters: appliedFilters,
    enabled: true,
  });

  const loading =
    (hasTeamRecommendation && teamRecommendationFetch.isLoading) ||
    (hasOrganizationRecommendation && organizationRecommendationFetch.isLoading) ||
    personalRecommendationFetch.isLoading;

  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;

  const isError =
    (organizationRecommendationFetch && organizationRecommendationFetch.error) ||
    (teamRecommendationFetch && teamRecommendationFetch.error) ||
    personalRecommendationFetch.error;
  const { allCourses, noCourses } = useStrapiCoursesData();
  const { orgRecommendedCoursesTitle, yourRecommendedCoursesTitle, teamRecommendedCoursesTitle } =
    allCourses.recommendedCourses;
  return (
    <>
      <div className={twMerge(className, 'flex flex-col gap-8')}>
        {!loading && !isError && (
          <>
            {/* Organization Recommendation View */}
            <HasAccess
              roles={[
                USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
                USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
                USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
                USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER,
              ]}
              Fallback={null}
            >
              {organizationRecommendationFetch.totalCount !== 0 && (
                <RecommendedCourseView
                  courseFetch={organizationRecommendationFetch.data}
                  heading={orgRecommendedCoursesTitle}
                />
              )}
            </HasAccess>
            {/* Team Recommendation View */}
            <HasAccess
              roles={[USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER, USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER]}
              Fallback={null}
            >
              {teamRecommendationFetch.totalCount !== 0 && (
                <RecommendedCourseView
                  courseFetch={teamRecommendationFetch.data}
                  heading={teamRecommendedCoursesTitle}
                />
              )}
            </HasAccess>
            {/* Personal Recommendation View */}
            {personalRecommendationFetch.totalCount !== 0 && (
              <RecommendedCourseView
                courseFetch={personalRecommendationFetch.data}
                heading={yourRecommendedCoursesTitle}
              />
            )}
          </>
        )}
        {loading && (
          <div className="flex h-full items-center justify-center">
            {<FadeLoader color="#E4E4E7" width="3px" />}
          </div>
        )}
        {!loading &&
          organizationRecommendationFetch.totalCount === 0 &&
          teamRecommendationFetch.totalCount === 0 &&
          personalRecommendationFetch.totalCount === 0 && (
            <div className="h-1/2">
              <div className="flex h-full items-center justify-center text-zinc-200">
                <div className="flex flex-col">
                  <h2 className="m-auto text-base font-semibold">
                    {noCourses.noRecommendedCourse}
                  </h2>
                  <p className="m-auto text-sm font-normal text-zinc-500">
                    {noCourses.noCoursesDescription}
                  </p>
                  <Button
                    className="m-auto my-4 w-fit bg-base-brand py-2 text-sm font-semibold"
                    onClick={navigateToAllCourses}
                  >
                    {noCourses.viewAllCoursesButton}
                  </Button>
                </div>
              </div>
            </div>
          )}
      </div>
      {isError && (
        <div className="flex h-full items-center justify-center text-zinc-200">
          <p>{strapiErrorMessage['SYSTEM_ERROR']}</p>
        </div>
      )}
    </>
  );
};

export default RecommendedCourses;
