import { API } from 'aws-amplify';

import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';

import { ACTIVITY_REPORT_TYPES, PERFORMANCE_REPORT_TYPES, REPORTS_API_PATHS } from '../constants';
import {
  PartialEnterpriseLeaderBoardFilter,
  PartialTopEarnedBadgesFilter,
  Badges,
  EnterpriseTeams,
  EnterpriseUsers,
  DashboardReportsFilter,
  HoursLogged,
  AverageWeeklyHours,
  CourseStatistics,
  HoursSpentFrequency,
  CourseCompletedAssignedStats,
  CoursePerformance,
  MostPopularCoursesFilter,
  MostPopularCourses,
  ScoresOnAssessments,
  CoursePerformanceRates,
  EngagementByCategories,
  EngagementByCategoriesFilter,
} from '../types';

export type DataRange = 'week' | 'month' | 'year';

interface Props {
  range?: DataRange;
  type: ACTIVITY_REPORT_TYPES | PERFORMANCE_REPORT_TYPES;
}

/**
 * Get Course Performance - Over Time
 */
const getCoursePerformanceOverTime = () => {
  return {
    labels: ['0-6', '6-12', '12-18', '15-20', '18-24', '24-30'],
  };
};
// eslint-disable-next-line unused-imports/no-unused-vars
const getCoursePerformanceOverTimeReports = ({ type }: Props) => {
  return getCoursePerformanceOverTime();
};

/**
 * Get Login Frequency
 */

const getLoginFrequency = () => {
  const DAYS = ['0-5', '5-10', '10-15', '15-20', '20-25', '25-31'];
  return {
    labels: DAYS,
    data: Array(DAYS.length)
      .fill(0)
      .map(() => {
        return Math.floor(10 + Math.random() * 60);
      }),
  };
};
// eslint-disable-next-line unused-imports/no-unused-vars
const getLoginFrequencyReports = ({ type }: Props) => {
  return getLoginFrequency();
};

/**
 * Get Enterprise Individual Leaderboard
 */
const getEnterpriseIndividualLeaderboard = async (
  filters: PartialEnterpriseLeaderBoardFilter
): Promise<{
  users: EnterpriseUsers[];
  count: number;
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.ENTERPRISE.GET_ENTERPRISE_INDIVIDUAL_LEADERBOARD;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Enterprise Team Leaderboard
 */
const getEnterpriseTeamLeaderboard = async (
  filters: PartialEnterpriseLeaderBoardFilter
): Promise<{
  users: EnterpriseUsers[];
  count: number;
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.ENTERPRISE.GET_ENTERPRISE_TEAM_LEADERBOARD;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Enterprise Teams Leaderboard
 */
const getEnterpriseTeamsLeaderboard = async (
  filters: PartialEnterpriseLeaderBoardFilter
): Promise<{
  results: EnterpriseTeams[];
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.ENTERPRISE.GET_ENTERPRISE_TEAMS_LEADERBOARD;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Top Earned Badges
 */
const getTopEarnedBadges = async (
  filters: PartialTopEarnedBadgesFilter
): Promise<{
  badges: Badges[];
  total: number;
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.ENTERPRISE.GET_TOP_EARNED_BADGES;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Hours Logged - Organization Hours Logged , Team Hours Logged , My Viewing Activity
 */

const getHoursLogged = async (
  filters: DashboardReportsFilter
): Promise<{
  results: HoursLogged[];
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.ACTIVITY_REPORT.GET_HOURS_LOGGED;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Average Weekly Hours
 * Personas Involved: Ent. Admin, Leader and Manager
 */

const getEnterpriseAverageWeeklyHours = async (
  filters: DashboardReportsFilter
): Promise<{
  results: AverageWeeklyHours[];
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.ENTERPRISE.GET_ENTERPRISE_AVERAGE_WEEKLY_HOURS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Course Performance Statistics
 * Personas Involved: All
 */

const getCoursePerformanceStatistics = async (
  filters: Partial<DashboardReportsFilter>
): Promise<{
  result: CourseStatistics;
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.PERFORMANCE_REPORT.COURSE_PERFORMANCE_STATISTICS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Hours Spent Frequency
 * Personas Involved: All
 */

const getHoursSpentFrequency = async (
  filters: Partial<DashboardReportsFilter>
): Promise<{
  results: HoursSpentFrequency[];
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.ACTIVITY_REPORT.HOURS_SPENT_FREQUENCY;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Number of Courses Completed/Assigned
 * Personas Involved: All
 */

const getCourseCompletedAssignedStatistics = async (
  filters: Partial<DashboardReportsFilter>
): Promise<{
  results: CourseCompletedAssignedStats[];
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.PERFORMANCE_REPORT.COURSE_COMPLETED_ASSIGNED_STATISTICS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Course Performance* Personas Involved: Ent. Admin, Leader, Manager and Learner
 */
const getEnterpriseCoursePerformance = async (
  filters: DashboardReportsFilter
): Promise<{ results: CoursePerformance[] }> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.ENTERPRISE.GET_ENTERPRISE_COURSE_PERFORMANCE;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Most Popular Courses
 *  Personas Involved: All
 */
const getMostPopularCourses = async (
  filters: Partial<MostPopularCoursesFilter>
): Promise<{
  results: MostPopularCourses[];
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.PERFORMANCE_REPORT.MOST_POPULAR_COURSES;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Scores on Assessments
 *  Personas Involved: All
 */
const getScoresOnAssessments = async (
  filters: Partial<DashboardReportsFilter>
): Promise<{
  results: ScoresOnAssessments[];
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.PERFORMANCE_REPORT.SCORES_ON_ASSESSMENTS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Course Peformance Rates
 *  Personas Involved: All
 */
const getCoursePerformanceRates = async (
  filters: Partial<DashboardReportsFilter>
): Promise<{
  result: CoursePerformanceRates;
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.PERFORMANCE_REPORT.COURSE_PERFORMANCE_RATES;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

/**
 * Get Engagement By Categories
 *  Personas Involved: All
 */
const getEngagementByCategories = async (
  filters: Partial<EngagementByCategoriesFilter>
): Promise<{
  results: EngagementByCategories[];
}> => {
  const queryString = qs.stringify(filters);
  const path = REPORTS_API_PATHS.PERFORMANCE_REPORT.ENGAGEMENT_BY_CATEGORIES;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

export {
  getEnterpriseCoursePerformance,
  getEnterpriseIndividualLeaderboard,
  getEnterpriseTeamLeaderboard,
  getEnterpriseTeamsLeaderboard,
  getTopEarnedBadges,
  getCoursePerformanceOverTimeReports,
  getCoursePerformanceRates,
  getHoursSpentFrequency,
  getLoginFrequencyReports,
  getHoursLogged,
  getEnterpriseAverageWeeklyHours,
  getCoursePerformanceStatistics,
  getCourseCompletedAssignedStatistics,
  getMostPopularCourses,
  getScoresOnAssessments,
  getEngagementByCategories,
};
