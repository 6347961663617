import {
  ChannelMembershipSummary,
  ChannelMessage,
  ChannelMessageSummary,
  ChannelSummary,
} from '@aws-sdk/client-chime-sdk-messaging';
import { isAfter } from 'date-fns';
import { useCallback, useEffect } from 'react';
import {
  CHIME_MESSAGE_EVENT_TYPE_HEADER,
  CHIME_MESSAGING_EVENT_TYPE,
} from '../features/messaging/constants';
import { MessageUpdateCallback, useChimeMessagingService } from '../features/messaging/hooks';
import { setUnreadChannelArns } from '../features/messaging/slices';
import { receivedNewNotification } from '../features/notifications/slice';
import useAppDispatch from './use-app-dispatch';
import useAppSelector from './use-app-selector';
import useReduxAuthState from './use-redux-auth-state';
import {
  setChannelMembershipsInCache,
  setLastChannelMessageInCache,
} from '../features/messaging/helpers';

/**
 * Subscribe to channel details events and create message events and updates the react query cache
 * And also updates the list of unread channels if there is a new message to a channel
 */
const useSubscribeToChannelEvents = () => {
  const chimeMessagingService = useChimeMessagingService();
  const auth = useReduxAuthState();
  const appDispatch = useAppDispatch();

  const unreadChannels = useAppSelector((state) => state.messaging.channels.unreadChannelArns);

  // Update the react query cache with the latest channel messages
  // We could reuse this message instead of querying/ making an API call
  const messageProcessor: MessageUpdateCallback = useCallback(
    (message) => {
      if (!auth.isAuthenticated) {
        return;
      }

      const chimeBearer = auth.user['custom:appinstance_user_arn'];
      const notificationChannelArn = auth.user['custom:notification_channel'];

      try {
        const eventType = message.headers[CHIME_MESSAGE_EVENT_TYPE_HEADER];
        const record = JSON.parse(message.payload);

        switch (eventType) {
          case CHIME_MESSAGING_EVENT_TYPE.CHANNEL_DETAILS: {
            const channelDetailsRecord = record as {
              Channel: ChannelSummary;
              ChannelMessages: ChannelMessageSummary[];
              ChannelMemberships: ChannelMembershipSummary[];
              ReadMarkerTimestamp: Date;
            };

            const channelArn = channelDetailsRecord.Channel.ChannelArn;
            const channelMessages = channelDetailsRecord.ChannelMessages;
            const channelMemberships = channelDetailsRecord.ChannelMemberships;

            // CACHE UPDATE FOR CHANNEL MEMBERSHIPS and CHANNEL LATEST MESSAGES

            // If no channel arn return
            if (!channelArn) {
              return;
            }

            const setChannelMembershipsInCacheParams = {
              channelArn,
              channelMemberships,
              chimeBearer,
            };

            setChannelMembershipsInCache(setChannelMembershipsInCacheParams);

            // There are no channel messages

            if (!channelDetailsRecord.ChannelMessages) {
              return;
            }

            const setLastChannelMessageInCacheParams = {
              channelArn,
              channelMessages,
              chimeBearer,
            };

            setLastChannelMessageInCache(setLastChannelMessageInCacheParams);

            // CACHE UPDATE FOR CHANNEL MEMBERSHIPS and CHANNEL LATEST MESSAGES

            // UPDATE UNREAD CHANNELS ARN STATE

            // Whether the channel is unread
            const isAlreadyUnread = unreadChannels.indexOf(channelArn) !== -1;

            // If the channel is already unread return
            if (isAlreadyUnread) {
              return;
            }

            // We have no read marker on this channel but there are messages that means this is unread channel.
            if (
              channelDetailsRecord.ReadMarkerTimestamp === undefined &&
              channelDetailsRecord.ChannelMessages.length > 0
            ) {
              if (!channelArn) {
                return;
              }

              // If the channel arn is same as notification channel then send out a
              // new notification event
              if (channelArn === notificationChannelArn) {
                appDispatch(receivedNewNotification());
              } else {
                const newUnreadChannelArns = [...unreadChannels, channelArn];
                appDispatch(setUnreadChannelArns(newUnreadChannelArns));
              }

              return;
            }

            const readMarkerTimestamp = new Date(channelDetailsRecord.ReadMarkerTimestamp);

            const channelMessagesWithCreatedTimeStamp = channelDetailsRecord.ChannelMessages.filter(
              (x) => x.CreatedTimestamp !== undefined
            );

            if (channelMessagesWithCreatedTimeStamp.length === 0) {
              return;
            }

            const latestMessage = channelMessagesWithCreatedTimeStamp[0];

            if (!latestMessage) {
              return;
            }

            const latestMessageCreateTimestamp = new Date(latestMessage.CreatedTimestamp as Date);

            // If the latest message create timestamp is after the channel read marker timestamp
            if (isAfter(latestMessageCreateTimestamp, readMarkerTimestamp)) {
              // If the channel arn is same as notification channel then send out a
              // new notification event
              if (channelArn === notificationChannelArn) {
                appDispatch(receivedNewNotification());
              } else {
                const newUnreadChannelArns = [...unreadChannels, channelArn];
                appDispatch(setUnreadChannelArns(newUnreadChannelArns));
              }
            }

            // UPDATE UNREAD CHANNELS ARN STATE

            break;
          }
          case CHIME_MESSAGING_EVENT_TYPE.CREATE_CHANNEL_MESSAGE: {
            const channelMessage = record as ChannelMessage;
            const channelArn = channelMessage.ChannelArn;

            if (!channelArn) {
              return;
            }

            // If the channel arn is same as notification channel then return
            // Since this would be handled in useSubscribeToNotification
            if (channelArn === notificationChannelArn) {
              return;
            }

            // If it is not already in the unread channels list
            // add it to the unread channels list
            const isAlreadyAnUnreadChannel = unreadChannels.indexOf(channelArn) != -1;

            // If the current user is the one who sent the message
            // then we need not show the notification
            if (!isAlreadyAnUnreadChannel && channelMessage.Sender?.Arn !== chimeBearer) {
              const unread = [...unreadChannels, channelArn];
              appDispatch(setUnreadChannelArns(unread));
            }

            const channelMessages = [channelMessage];

            const params = {
              channelArn,
              channelMessages,
              chimeBearer,
            };

            // Update the last channel message cache
            setLastChannelMessageInCache(params);
            break;
          }
        }
      } catch (error) {
        console.log(`Error handling event ${message}`);
      }
    },
    [appDispatch, auth.isAuthenticated, auth.user, unreadChannels]
  );

  useEffect(() => {
    if (chimeMessagingService) {
      const { subscribeToMessageUpdate, unsubscribeFromMessageUpdate } = chimeMessagingService;
      subscribeToMessageUpdate(messageProcessor);

      return () => {
        unsubscribeFromMessageUpdate(messageProcessor);
      };
    }
  }, [chimeMessagingService, messageProcessor]);
};

export default useSubscribeToChannelEvents;
