import { useState } from 'react';
import { Modal } from 'react-daisyui';
import toast from 'react-hot-toast';
import { BasicSpinner } from '../../../../../components/Spinners';
import { Toast } from '../../../../../components/Toast';
import { queryClient } from '../../../../../config/react-query';
import { deleteSpeaker } from '../../../api/speaker';
import { GET_SPEAKER_QUERY_KEY } from '../../../constants/common';
import { useSpeakerStrapiData } from '../../../hooks/useSpeakerStrapiData';
interface props {
  open: boolean;
  toggleVisibility: any;
  speakerId: number;
}

function DeleteSpeakerModal({ open, toggleVisibility, speakerId }: props) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    deleteSpeakerStrapiData,
    toastMessage: { deleteSpeakerSuccess, deleteSpeakerFailed },
  } = useSpeakerStrapiData();
  const toggleVisible = () => {
    toggleVisibility();
  };
  const deleteHandler = () => {
    setIsLoading(true);
    deleteSpeaker({ id: speakerId })
      .then(async (data) => {
        if (data.message) {
          toast.custom(
            (t) => <Toast variant="success" Title={deleteSpeakerSuccess} toastInstance={t} />,
            { id: 'DELETE_SPEAKER_TOAST_' + Math.floor(Math.random() * 3000).toString() }
          );
        }
        await queryClient.invalidateQueries([GET_SPEAKER_QUERY_KEY]);
        setIsLoading(false);
        toggleVisible();
      })
      .catch(() => {
        toast.custom(
          (t) => <Toast variant="error" Title={deleteSpeakerFailed} toastInstance={t} />,
          { id: 'DELETE_SPEAKER_TOAST_' + Math.floor(Math.random() * 3000).toString() }
        );
        setIsLoading(false);
        toggleVisible();
      });
  };

  return (
    <div className="[&>*:nth-child(1)]:bg-black">
      <Modal className="w-[512px] rounded bg-card-bg shadow-xl" open={open}>
        <Modal.Header className="mb-5 text-lg font-bold text-white">
          {deleteSpeakerStrapiData.title}
        </Modal.Header>
        <Modal.Body>
          <p className="text-sm font-normal text-zinc-300">
            {deleteSpeakerStrapiData.deleteMessage}
          </p>
          <Modal.Actions>
            <a
              className="cursor-pointer px-6 py-3 font-[inter] text-sm font-medium text-white"
              onClick={toggleVisible}
            >
              {deleteSpeakerStrapiData.cancelButton}
            </a>

            <button
              onClick={deleteHandler}
              disabled={isLoading}
              className="flex h-[40px] items-center gap-1 !rounded bg-base-brand px-6 py-2 text-sm font-medium normal-case leading-tight text-white hover:bg-base-brand disabled:bg-base-brand disabled:text-white"
            >
              <span>{deleteSpeakerStrapiData.confirmButton}</span>
              {isLoading ? <BasicSpinner className=" ml-2 inline-block text-white" /> : null}
            </button>
          </Modal.Actions>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DeleteSpeakerModal;
