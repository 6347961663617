import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PaginationFooter } from '../../../../components/DataTables';
import { BasicSpinner } from '../../../../components/Spinners';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { RootState } from '../../../../store';
import {
  ENT_ADMIN_DEFAULT_PAGE,
  ENT_ADMIN_MIN_PAGE_SIZE,
  ENT_ADMIN_MAX_PAGE_SIZE,
  ENT_ADMIN_PAGE_SIZE_INCREMENT,
} from '../../constants/enterpriseAdmin';
import { SECTIONS } from '../../constants/section-name';

import useEnterpriseAdminTeamsQuery from '../../hooks/useEnterpriseAdminTeamsQuery';
import { triggerDataRefresh } from '../../slices/team.slice';
import TeamsEmptyState from './TeamsEmptyPage';
import TeamsMainHeader from './TeamsMainHeader';
import TeamTable from './TeamTable';
import useDebounce from '../../../../hooks/use-debounce';
import TeamsErrorState from '../TeamsErrorState';
import { useStrapiOrganizationData } from '../../../organisation/hooks/useStrapiOrganizationData';

interface Props {
  className?: string;
  isOrganizationView: boolean;
}

const teamStateSelector = (state: RootState) => state.team;

const TeamsTableMain = ({ className, isOrganizationView }: Props) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const searchParams = new URLSearchParams(location.search);

  // Parse the filters from the url query parameters
  const search = searchParams.get('teamTableSearch') ?? undefined;
  const debouncedFilter = useDebounce(search); // Will debounce filter value for 500 milli seconds

  const { sortKey, sortOrder, dataRefreshState } = useAppSelector(teamStateSelector);
  let _isFilterApplied = false;

  const [page, setPage] = useState(ENT_ADMIN_DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(ENT_ADMIN_MIN_PAGE_SIZE);

  // sort

  let newSortKey = 'CREATED_ON';
  let newSortOrder: string | boolean = 'ASC';
  if (sortKey) {
    if (sortKey === 'NO_OF_MEMBERS') {
      if (sortOrder === false) {
        // we will be getting the sort Order for columns which contain String values as asc:asc desc:false and false:desc
        newSortOrder = 'ASC';
        newSortKey = sortKey;
      } else if (sortOrder === 'ASC') {
        newSortKey === '';
        newSortOrder === '';
      } else {
        newSortOrder = 'DESC';
        newSortKey = sortKey;
      }
    } else {
      if (sortOrder === false) {
        // we will be getting the sort Order for columns which contain String values as asc:asc desc:false and false:desc
        newSortOrder = 'DESC';
        newSortKey = sortKey;
      } else if (sortOrder === 'DESC') {
        newSortKey === '';
        newSortOrder === '';
      } else {
        newSortOrder = 'ASC';
        newSortKey = sortKey;
      }
    }
    // we will be getting the sort Order for columns which contain number values as asc:false desc:desc and false:asc
  }
  // Build the teamAPIData filters from the available filters
  const filters = useMemo(() => {
    return {
      search: debouncedFilter,
      start: (page - 1) * pageSize,
      size: pageSize,
      sortOrder: newSortOrder,
      sortKey: newSortKey,
    };
  }, [debouncedFilter, newSortKey, newSortOrder, page, pageSize]);
  const { id: tenantId } = useParams();
  const teamAPIData = useEnterpriseAdminTeamsQuery({ filters, isOrganizationView, tenantId });

  const displayLoader = useMemo(
    () =>
      (teamAPIData.isLoading && !teamAPIData.data) ||
      (teamAPIData.isFetching && teamAPIData.data?.count === 0),
    [teamAPIData.data, teamAPIData.isFetching, teamAPIData.isLoading]
  );

  const isEmptyState = useMemo(
    () => teamAPIData.data && teamAPIData.data.count === 0,
    [teamAPIData.data]
  );
  _isFilterApplied = search !== undefined;
  // TODO: Handle Error State After Designs are added

  // Refresh the Table on data changes
  useEffect(() => {
    if (dataRefreshState?.ADMIN_TEAMS_LIST_TABLE) {
      teamAPIData.refetch().then(() => {
        const actionPayload = {
          [SECTIONS.ADMIN_TEAMS_LIST_TABLE]: false,
        };
        dispatch(triggerDataRefresh(actionPayload));
      });
    }
  }, [dataRefreshState]);
  const { singleView } = useStrapiOrganizationData();
  const { teamTableHeader } = singleView.TableHeadings;
  return (
    <>
      <main
        id="main-content"
        className={clsx(
          'flex flex-col items-start gap-4 rounded-sm bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)] md:p-5 lg:p-6',
          className
        )}
      >
        {isOrganizationView && <div className=" text-white">{teamTableHeader}</div>}
        <TeamsMainHeader filter={search ?? ''} isFilterApplied={_isFilterApplied} />
        {/* Is Loading and We donot have data */}
        {teamAPIData.isLoading && !teamAPIData.data && (
          <div className="flex w-full justify-center py-4">
            <BasicSpinner className="text-white" />
          </div>
        )}
        {/* We have data */}
        {teamAPIData.data && teamAPIData.data?.count > 0 && (
          <div
            className={clsx(
              'flex w-full flex-col gap-4',
              teamAPIData.isPreviousData &&
                teamAPIData.isFetching &&
                'pointer-events-none opacity-50'
            )}
          >
            <div className="w-full overflow-y-scroll">
              <TeamTable data={teamAPIData.data?.rows} isOrganizationView={isOrganizationView} />
            </div>
            <PaginationFooter
              setPage={setPage}
              activePage={page}
              totalItems={teamAPIData?.data?.count}
              pageSize={pageSize}
              setPageSize={setPageSize}
              maxPageSize={ENT_ADMIN_MAX_PAGE_SIZE}
              minPageSize={ENT_ADMIN_MIN_PAGE_SIZE}
              pageSizeIncrement={ENT_ADMIN_PAGE_SIZE_INCREMENT}
            />
          </div>
        )}
        {/* Empty State */}
        {isEmptyState && !displayLoader && (
          <div className="flex min-h-[250px] w-full items-center justify-center">
            <TeamsEmptyState isFilterApplied={_isFilterApplied} />
          </div>
        )}
        {/* Error State */}
        {teamAPIData.isError && !teamAPIData.data && (
          <div className="flex min-h-[250px] w-full items-center justify-center">
            <TeamsErrorState
              isRefetching={teamAPIData.isRefetching}
              refetch={teamAPIData.refetch}
            />
          </div>
        )}
      </main>
    </>
  );
};

export default TeamsTableMain;
