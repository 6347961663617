import { twMerge } from 'tailwind-merge';
import Dialog from '../Dialog';
import { BasicSpinner } from '../Spinners';

interface Props {
  Title: React.ReactNode;
  Content: React.ReactNode;
  actionItem: {
    label: string;
    action: () => void;
  };
  cancelItem: {
    label: string;
    action?: () => void;
  };
  isLoading?: boolean;
  isActionDiabled?: boolean;
  contentWrapperClassName?: string;
}

/**
 * Used to alert the user when the user has to take some action
 */
const AlertDialog = (props: Props) => {
  const {
    Title,
    Content,
    actionItem,
    cancelItem,
    isLoading = false,
    isActionDiabled = false,
    contentWrapperClassName,
  } = props;

  return (
    <div className="flex flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-6 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)]">
      <div className={twMerge('flex flex-col gap-2', contentWrapperClassName)}>
        <Dialog.Title className="text-lg font-semibold leading-6 text-white">{Title}</Dialog.Title>
        <div className="text-sm font-normal leading-[22px] text-zinc-300">{Content}</div>
      </div>
      <div className="flex w-full flex-col items-center gap-2 sm:flex-row sm:justify-end">
        <Dialog.Close
          onClick={() => {
            cancelItem.action && cancelItem.action();
          }}
          className="w-max rounded py-2.5 px-[30px] text-sm font-medium text-white"
        >
          {cancelItem.label}
        </Dialog.Close>
        <button
          onClick={() => {
            actionItem.action && actionItem.action();
          }}
          className="flex w-max items-center gap-2 rounded bg-base-brand py-2.5 px-[30px] text-sm font-medium text-white disabled:opacity-80"
          disabled={isActionDiabled}
        >
          <span>{actionItem.label}</span>{' '}
          {isLoading && <BasicSpinner className="!m-0 text-white" />}
        </button>
      </div>
    </div>
  );
};

export default AlertDialog;
