const config = {
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_SHARED_COGNITO_USERPOOL_ID,
  identityPoolId: process.env.REACT_APP_SHARED_COGNITO_IDENTITY_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_SHARED_COGNITO_CLIENT_ID,
  mandatorySignIn: true,
  oauth: {
    domain: process.env.REACT_APP_SHARED_COGNITO_DOMAIN,
    // scopes seperated by +
    scope: (process.env.REACT_APP_SHARED_COGNITO_CLIENT_SCOPE as string).split('+'),
    redirectSignIn: process.env.REACT_APP_SHARED_COGNITO_REDIRECT_SIGN_IN_URI,
    redirectSignOut: process.env.REACT_APP_SHARED_COGNITO_REDIRECT_SIGN_OUT_URI,
    responseType: process.env.REACT_APP_SHARED_COGNITO_RESPONSE_TYPE || 'code',
    clientId: process.env.REACT_APP_SHARED_COGNITO_CLIENT_ID,
  },
};

export default config;
