import colors from 'tailwindcss/colors';
import twDefaultTheme from 'tailwindcss/defaultTheme';

const defaultFontOptions = {
  // TODO: Update when font is updated
  family: ['Inter var', 'Inter', ...twDefaultTheme.fontFamily.sans].join(','),
  weight: '400',
  size: 10,
  lineHeight: '12px',
};

const defaultGridOptions = {
  color: colors.zinc['700'],
  drawTicks: false,
  borderColor: colors.zinc['700'],
  borderDash: [3, 3],
  drawBorder: false,
};

const defaultTitleOptions = {
  display: true,
  color: colors.zinc['200'],
};

const defaultLabelOptions = {
  boxWidth: 19,
  padding: 20,
  color: colors.zinc['200'],
};

const defaultTickOptions = {
  color: colors.zinc['300'],
  font: {
    ...defaultFontOptions,
  },
};

export {
  defaultGridOptions,
  defaultTickOptions,
  defaultFontOptions,
  defaultTitleOptions,
  defaultLabelOptions,
};
