export const LIST_SPEAKERS_DEFAULT_PAGE = 1;
export const LIST_SPEAKERS_DEFAULT_START = 0;
export const LIST_SPEAKERS_MIN_PAGE_SIZE = 10;
export const LIST_SPEAKERS_MAX_PAGE_SIZE = 50;
export const LIST_SPEAKERS_PAGE_SIZE_INCREMENT = 10;
export const GET_SPEAKER_QUERY_KEY = 'GET_SPEAKER_QUERY_KEY';
export const STALE_TIME = 2000;
export const SPEAKER_API_PATHS = {
  GET_SPEAKERS: '/courses/hq/v2/speakers',
  DELETE_SPEAKERS: '/courses/hq/v2/speaker',
  CREATE_SPEAKER: '/courses/hq/v2/speaker',
  CREATE_SPEAKER_IN_MULTI_LANG: '/courses/hq/v2/speaker/multi-lang',
  UPDATE_SPEAKER: '/courses/hq/v2/speaker',
  GET_SPEAKER_BY_ID: '/courses/hq/v2/speaker',
};

export const MODULE_TYPE = {
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  DOCUMENT: 'DOCUMENT',
  ASSESSMENT: 'ASSESSMENT',
} as const;

export const MODULE_KEYS = {
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  DOCUMENT: 'DOCUMENT',
  ASSESSMENT: 'QUIZ',
} as const;

export const MODULE_TYPE_MESSAGE = {
  DEFAULT: { label: 'Select a module type', size: '' },
  IMAGE: { label: 'PNG, JPG or JPEG', size: '10mb' },
  VIDEO: { label: 'MOV, MP4 or MPEG', size: '1gb' },
  AUDIO: { label: 'MP3', size: '250mb' },
  DOCUMENT: { label: 'PDF', size: '250mb' },
};

export const MEDIA_TYPE_PREFIX = {
  AUDIO: 'audio',
  DOCUMENT: 'application',
  VIDEO: 'video',
  IMAGE: 'image',
};

export const MEDIA_UPLOAD_REQUEST_TYPE = {
  MODULE: 'module',
  COURSE: 'course',
  ASSESSMENT: 'assessment',
};

export const QUERY_KEYS = {
  getSpeakerById: ({ speakerId, language }: { speakerId: number; language: string }) => {
    return [
      {
        scope: 'speakers',
        item: 'detail',
        speakerId,
        language,
      },
    ];
  },
};

export const QUERY_STALE_TIME_MS = {
  getSpeakerById: 30000,
};

export const SPEAKER_ACTION = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  MANAGE: 'MANAGE',
} as const;
