import { Link } from 'react-router-dom';
import { Placeholder } from 'phosphor-react';

type Props = {
  message: string;
  action: {
    message: string;
    path: string;
  };
};

const EmptyState = (props: Props) => {
  const { message, action } = props;

  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 py-14 px-4 text-zinc-100">
      <Placeholder size={40} color="currentColor" className="text-zinc-500" />
      <span className="max-w-[512px] text-center">{message}</span>
      {/* Action Link */}
      {action.message && action.path ? (
        <Link
          to={action.path}
          className="rounded-[0.375rem] bg-base-brand py-1.5 px-2 text-center text-sm xs:px-4 xs:text-base"
        >
          {action.message}
        </Link>
      ) : null}
    </div>
  );
};

export default EmptyState;
