import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import clsx from 'clsx';
import { Line } from 'react-chartjs-2';
import { LineChartOptions } from '../../../../dashboard/types';
import { defaultGridOptions, defaultTickOptions } from '../../../config';

// Register the components that we are going to use
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Props {
  className?: string;
  options?: LineChartOptions;
  data: ChartData<'line', number[], unknown>;
}

// Chartjs options

const chartOptions: LineChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    line: {
      tension: 0.4,
    },
  },
  scales: {
    y: {
      ticks: {
        stepSize: 20,
        ...defaultTickOptions,
      },
      grid: { ...defaultGridOptions },
    },
    x: {
      grid: { ...defaultGridOptions },
      ticks: {
        ...defaultTickOptions,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

// Chartjs options

const LineChart = ({ className, options, data }: Props) => {
  return (
    <div className={clsx('', className)}>
      <Line options={options ?? chartOptions} data={data} />
    </div>
  );
};

export default LineChart;
