import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { ORGANIZATION_API_PATHS } from '../constants';
import { GetAllMembersInOrganizationFilters, GetAllMembersInOrganizationResponse } from '../types';

interface GetAllMembersInOrganizationArgs {
  filters: GetAllMembersInOrganizationFilters;
  tenantId: string;
}

/**
 * Fetch the list of all the members in the organization.
 */
const getAllMembersInOrganization = async (
  args: GetAllMembersInOrganizationArgs
): Promise<GetAllMembersInOrganizationResponse> => {
  const { filters, tenantId } = args;

  const path = ORGANIZATION_API_PATHS.GET_ALL_MEMBERS(tenantId);
  const queryString = qs.stringify(filters, { skipNulls: true });

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${queryString}`, {
    response: true,
    withCredentials: true,
  });

  return response.data;
};

export { getAllMembersInOrganization };
export type { GetAllMembersInOrganizationArgs };
