import { RouteObject } from 'react-router-dom';
import SetPasswordBaseLayout from '../components/SetPassword/SetPasswordBaseLayout';
import SetPasswordSuccess from '../components/SetPassword/SetPasswordSuccess';
import SetPasswordMain from '../components/SetPassword/SetPasswordMain';
import OnboardingBaseLayout from '../components';
import { PrivateRoute } from '../../../layouts';

const routes: RouteObject[] = [
  {
    path: 'invite/set-password',
    element: <SetPasswordBaseLayout />,
    children: [
      {
        index: true,
        element: <SetPasswordMain />,
      },
      {
        path: 'success',
        element: <SetPasswordSuccess />,
      },
    ],
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        path: 'onboarding',
        element: <OnboardingBaseLayout />,
      },
    ],
  },
];
export default routes;
