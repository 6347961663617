import Dialog from '../../../../components/Dialog';
import { X } from 'phosphor-react';
import { useStrapiSharedData } from '../../hooks/useStrapiSharedData';

type Props = {
  roles: string[];
};

type role = {
  label: string;
  slug: string;
  description: string;
};
const RoleDescription = ({ roles }: Props) => {
  const { roleDescription } = useStrapiSharedData();
  const { roleDescriptions } = roleDescription;
  const requiredRoleDescriptions = roleDescriptions.filter((role: role) =>
    roles.includes(role.slug)
  );
  return (
    <div className="mx-1 flex flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <div className="flex flex-row justify-between">
        <Dialog.Title className="text-lg font-semibold text-zinc-100">
          {roleDescription.title}
        </Dialog.Title>
        <Dialog.Close>
          <X
            size={24}
            fill="white"
            color="currentColor"
            className="text-zinc-300 transition-colors hover:text-zinc-100"
          />
        </Dialog.Close>
      </div>

      <div className="flex flex-col gap-6 text-white">
        {requiredRoleDescriptions.map((role: role) => (
          <div className="text-sm" key={role.label}>
            <div className="font-semibold leading-6">{role.label}</div>
            <div className="font-normal leading-5 text-zinc-300">{role.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoleDescription;
