import { REPORTS_INTERVAL } from '../constants';
import { formatDateRange } from './dateTimeUtil';

// ChartJs label formatter
const chartJsLabel = (interval: string, pool: string, locale?: string) => {
  switch (interval) {
    case REPORTS_INTERVAL.FORTNIGHT:
      return pool ? formatDateRange(pool.split(':')[0], pool.split(':')[1], locale) : '';

    case REPORTS_INTERVAL.WEEK:
      return pool ? formatDateRange(pool.split(':')[0], pool.split(':')[1], locale) : '';

    case REPORTS_INTERVAL.MONTH:
      return pool;

    default:
      return pool;
  }
};

export { chartJsLabel };
