import { useAppSelector } from '../hooks';
import { RootState } from '../store';

/**
 * Check if the role has an access or not
 * @summary Should be used to return result based on the user role.
 */
const useHasAccess = (roles: string[]) => {
  const authStateSelector = (state: RootState) => state.auth;
  const { user, isAuthenticated } = useAppSelector(authStateSelector);

  // If not authenticated
  if (!isAuthenticated || !user) {
    return false;
  }

  // Atleast one of the roles in user, matches the roles allowed
  // If authenticated there would be role
  const hasAccess = roles.includes(user.role as string);

  if (hasAccess) {
    return true;
  }

  return false;
};

export default useHasAccess;
