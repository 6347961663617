import * as RadixPopover from '@radix-ui/react-popover';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import cn from '../../utils/cn';

interface Props {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  // true by default
  modal?: boolean;
  // true by default
  displayArrow?: boolean;
  arrowClassName?: string;
  Content: React.ReactNode;
  contentClassName?: string;
  triggerClassName?: string;
  // distance in pixels from anchor
  sideOffset?: number;
  collisionPadding?: number;
  triggerAsChild?: boolean;
}

const Popover = (props: React.PropsWithChildren<Props>) => {
  const {
    open,
    onOpenChange,
    modal = true,
    children,
    displayArrow = true,
    Content,
    contentClassName,
    triggerClassName,
    sideOffset,
    collisionPadding,
    triggerAsChild,
    arrowClassName,
  } = props;

  return (
    <RadixPopover.Root modal={modal} open={open} onOpenChange={onOpenChange}>
      <RadixPopover.Trigger className={twMerge('', triggerClassName)} asChild={triggerAsChild}>
        {children}
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          sideOffset={sideOffset}
          className={twMerge(
            'z-20 rounded bg-zinc-900 px-6 py-4 shadow-[0px_20px_25px_-5px_rgba(0,_0,_0,_0.1),_0px_10px_10px_-5px_rgba(0,_0,_0,_0.04)]',
            contentClassName
          )}
          collisionPadding={collisionPadding}
        >
          {Content}
          {displayArrow && (
            <RadixPopover.Arrow
              fill="currentColor"
              className={cn('text-zinc-900', arrowClassName)}
            ></RadixPopover.Arrow>
          )}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};

Popover.Close = RadixPopover.Close;

export default Popover;
