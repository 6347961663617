import clsx from 'clsx';
import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { USHG_ADMIN_DEFAULT_REQUESTS_SORT_BY } from '../../constants';
import { useUSHGAdminRequestsQuery } from '../../hooks';
import { PartialUSHGAdminRequestsFilter } from '../../types';
import DashboardRequestsMain from '../common/RequestsDashboard/DashboardRequestsMain';
import DashboardRequestsTable from './components/DashboardRequestsTable';

interface Props {
  className?: string;
  lazyLoad?: boolean;
}

const USHGAdminDashboardRequests = ({ className, lazyLoad = true }: Props) => {
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);
  const filters: PartialUSHGAdminRequestsFilter = {
    start: 0,
    size: 10,
    sort: USHG_ADMIN_DEFAULT_REQUESTS_SORT_BY,
  };

  const request = useUSHGAdminRequestsQuery({ filters, isEnabled: isInView });

  return (
    <InView
      as="div"
      className={clsx('', className)}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <DashboardRequestsMain
        request={request}
        tableWrapperClassName="overflow-y-scroll"
        bottomGradientDivClassName="pointer-events-none"
        RequestsTable={request.data && <DashboardRequestsTable data={request.data.data} />}
      />
    </InView>
  );
};

export default USHGAdminDashboardRequests;
