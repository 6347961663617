import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import Dialog from '../../../../components/Dialog';
import {
  CheckboxInput,
  DropdownInput,
  Label,
  TextInput,
} from '../../../../components/FormElements';
import ErrorMessage from '../../../../components/FormElements/ErrorMessage';
import { BasicSpinner } from '../../../../components/Spinners';
import { Toast } from '../../../../components/Toast';
import { queryClient } from '../../../../config/react-query';
import { SUBSCRIPTION_PRODUCTS, USHG_HQ_APP_ROLE } from '../../../../constants';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import { debounce } from '../../../../utils';
import { fieldHasError } from '../../../../utils/react-hook-form';
import { checkCompanyExists, checkUserExists } from '../../../auth/api';
import { REGEX } from '../../../teams/constants/regex';
import { inviteOrganisation } from '../../api/organisation';
import useGetAllCouponsQuery from '../../hooks/useGetAllCouponsQuery';
import { useStrapiOrganizationData } from '../../hooks/useStrapiOrganizationData';
import { InviteOrganisationpayload } from '../../types';
import Registration from '../../../auth/data/Registration';

// Yup Schema
interface errorMessages {
  emailRequired: string;
  emailMinError: string;
  emailMinValue: number;
  emailMaxError: string;
  emailMaxValue: number;
  validEmailError: string;
  departmentRequired: string;
  departmentMinError: string;
  departmentMinValue: number;
  departmentMaxError: string;
  departmentMaxValue: number;
  organisationNameRequired: string;
  organisationNameMinError: string;
  organisationNameMinValue: number;
  organisationNameMaxValue: number;
  organisationNameMaxError: string;
  activeLearnersRequired: string;
  activeLearnersMinError: string;
  activeLearnersMinValue: number;
  activeLearnersMaxError: string;
}

function getSchema(data: errorMessages) {
  const {
    emailRequired,
    emailMinError,
    emailMinValue,
    emailMaxError,
    emailMaxValue,
    validEmailError,
    departmentRequired,
    departmentMinError,
    departmentMinValue,
    departmentMaxError,
    departmentMaxValue,
    organisationNameRequired,
    organisationNameMinError,
    organisationNameMinValue,
    organisationNameMaxValue,
    organisationNameMaxError,
    activeLearnersRequired,
    activeLearnersMinError,
    activeLearnersMinValue,
    activeLearnersMaxError,
  } = data;
  const addOrganisationSchema = yup.object({
    organisationName: yup
      .string()
      .trim()
      .required(organisationNameRequired)
      .min(organisationNameMinValue, organisationNameMinError)
      .max(organisationNameMaxValue, organisationNameMaxError),
    adminEmail: yup
      .string()
      .trim()
      .required(emailRequired)
      .email(validEmailError)
      .min(emailMinValue, emailMinError)
      .max(emailMaxValue, emailMaxError),
    department: yup
      .string()
      .trim()
      .required(departmentRequired)
      .min(departmentMinValue, departmentMinError)
      .max(departmentMaxValue, departmentMaxError),
    activeLearners: yup
      .number()
      .required(activeLearnersRequired)
      .min(activeLearnersMinValue, activeLearnersMinError)
      .typeError(activeLearnersRequired)
      .when(['organisationSize'], (size, schema) => {
        return schema.max(size, activeLearnersMaxError + ' ' + `${size}`);
      }),
    organisationSize: yup.number(),
    plan: yup.boolean(),
    discountCoupon: yup.string(),
    organisationAlreadyExists: yup.string(),
    adminEmailAlreadyExists: yup.string(),
  });
  return addOrganisationSchema;
}

// Yup Schema
interface Props {
  setIsModalOpen: (open: boolean) => void;
}

function AddOrganisationModalForm({ setIsModalOpen }: Props): JSX.Element {
  // strapi content
  const { errorMessage } = useStrapiOrganizationData();
  const addOrganisationSchema = getSchema(errorMessage);
  // Strapi Content - Company Details
  const { companyDetails } = Registration();
  const organizationSizeDropDownOptions = companyDetails.Label.find(
    (item: any) => item.slug == 'companySize'
  ).options;
  type ORG_FORM_DATA = yup.InferType<typeof addOrganisationSchema>;
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isValid, isSubmitting, isSubmitted, errors },
  } = useForm<ORG_FORM_DATA>({
    resolver: yupResolver(addOrganisationSchema),
  });
  const [isEmailCheckinProgress, setEmailCheckinProgress] = useState(false);
  const [isCompanyCheckinProgress, setCompanyCheckinProgress] = useState(false);
  const toggleVisible = () => {
    setIsModalOpen(false);
  };
  const { modal, toastMessages } = useStrapiOrganizationData();
  const {
    InviteButton,
    cancelButton,
    emailLabel,
    departmentLabel,
    organisationNameLabel,
    activeLearnersLabel,
    organisationSizeLabel,
    discountPercentageLabel,
    emailPlaceholder,
    departmentPlaceholder,
    organisationNamePlaceholder,
    activeLearnersPlaceholder,
    discountPercentagePlaceholder,
    isAnuallyCheckBoxLabel,
  } = modal;
  // Admin Team Edit
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorData = strapidata?.errormessage?.data.attributes;
  const { companyAlreadyExists, emailAlreadyExists } = strapiErrorData;
  const debouncedUserCheck = useMemo(() => {
    const _userAlreadyExistValidation = async (data: string) => {
      const isEmail = String(data).toLowerCase().match(REGEX.CHECK_VALID_MAIL);
      if (isEmail) {
        setEmailCheckinProgress(true);
        try {
          const response = await checkUserExists(data);
          setEmailCheckinProgress(false);
          if (response.userExistsAlready) {
            setError('adminEmailAlreadyExists', {
              type: 'custom',
              message: emailAlreadyExists,
            });
          } else {
            clearErrors('adminEmailAlreadyExists');
          }
        } catch (error: any) {
          toast.custom(
            (t) => (
              <Toast
                variant={'error'}
                Title={error.message ?? error.errorMessage}
                toastInstance={t}
              />
            ),
            {
              id: '1',
              duration: 3000,
            }
          );
        }
      }
    };
    return debounce(_userAlreadyExistValidation, 1000)[0];
  }, []);

  const emailValidationEventHandler = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      await debouncedUserCheck(event.target.value);
    },
    [debouncedUserCheck]
  );
  const onSubmit = async (data: ORG_FORM_DATA) => {
    const {
      organisationName,
      plan,
      activeLearners,
      department,
      adminEmail,
      discountCoupon,
      organisationSize,
    } = data;
    let newPlan;
    if (plan) {
      newPlan = 'year';
    } else {
      newPlan = 'month';
    }

    const payload: InviteOrganisationpayload = {
      adminEmail: adminEmail,
      setting: {
        organizationName: organisationName,
        paymentCycle: newPlan,
        activeLearners: activeLearners as number,
        organizationSize: organisationSize as number,
        department: department,
        discountCoupon: discountCoupon as string,
      },
    };

    try {
      setCompanyCheckinProgress(true);
      const response = await checkCompanyExists(organisationName, department);
      setCompanyCheckinProgress(false);
      if (response.companyExistsAlready) {
        setError('organisationAlreadyExists', {
          type: 'custom',
          message: companyAlreadyExists,
        });
      } else {
        clearErrors('department');
        await inviteOrganisation(payload);
        toast.custom(
          (t) => (
            <Toast variant={'success'} Title={toastMessages.inviteSuccess} toastInstance={t} />
          ),
          {
            id: '1',
            duration: 3000,
          }
        );
        queryClient.invalidateQueries([
          {
            persona: USHG_HQ_APP_ROLE.USHG_ADMIN,
            scope: 'organisation',
          },
        ]);
        toggleVisible();
      }
    } catch (error: any) {
      toast.custom(
        (t) => (
          <Toast
            variant={'error'}
            Title={toastMessages.inviteFailed}
            SubTitle={error.errorMessage ?? error.message}
            toastInstance={t}
          />
        ),
        {
          id: '1',
          duration: 3000,
        }
      );
      toggleVisible();
    }
  };
  const couponList = useGetAllCouponsQuery({ product: SUBSCRIPTION_PRODUCTS.ENTERPRISE });
  const finalTeamList =
    couponList.data?.results?.map((value: { id: string; name: string }) => {
      return { label: value.name, value: value.id };
    }) ?? [];
  const dropdownList = [
    {
      label: discountPercentagePlaceholder,
      value: null,
    },
    ...finalTeamList,
  ];

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      name="form"
      className="flex w-full flex-col gap-6 overflow-y-auto"
    >
      {/* Admin Email */}
      <div className="flex w-full flex-col gap-2">
        <Label label={emailLabel} htmlFor="organisation_admin_email"></Label>
        <div className="relative flex">
          <TextInput
            className="text-zinc-50"
            id="organisation_admin_email"
            type="text"
            placeholder={emailPlaceholder}
            aria-invalid={fieldHasError(errors, 'adminEmail') ? 'true' : 'false'}
            hasError={fieldHasError(errors, 'adminEmail')}
            {...register('adminEmail', {
              onChange: (event) => {
                emailValidationEventHandler(event);
              },
            })}
          />
          {isEmailCheckinProgress ? (
            <div className=" absolute right-6 top-1/2 -translate-y-1/2">
              <BasicSpinner className="leading-[14px] text-zinc-100" />
            </div>
          ) : null}
        </div>
        <HookFormErrorMessage
          name="adminEmail"
          errors={errors}
          render={({ message }) =>
            message !== emailAlreadyExists ? <ErrorMessage message={message} /> : ''
          }
        />
        {errors?.adminEmailAlreadyExists && errors?.adminEmailAlreadyExists.type === 'custom' && (
          <span className="text-xs text-red-500">{emailAlreadyExists}</span>
        )}
      </div>
      {/* Admin Email */}
      {/* organisation Name */}
      <div className="flex w-full flex-col gap-2">
        <Label label={organisationNameLabel} htmlFor="organisation_name"></Label>
        <TextInput
          className="text-zinc-50"
          id="organisation_name"
          type="text"
          placeholder={organisationNamePlaceholder}
          aria-invalid={fieldHasError(errors, 'organisationName') ? 'true' : 'false'}
          hasError={fieldHasError(errors, 'organisationName')}
          {...register('organisationName')}
        />
        <HookFormErrorMessage
          name="organisationName"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </div>
      {/* organisation Name */}
      {/* department*/}
      <div className="flex w-full flex-col gap-2">
        <Label label={departmentLabel} htmlFor="organisation_department"></Label>
        <TextInput
          className="text-zinc-50"
          id="organisation_department"
          type="text"
          placeholder={departmentPlaceholder}
          aria-invalid={fieldHasError(errors, 'department') ? 'true' : 'false'}
          hasError={fieldHasError(errors, 'department')}
          {...register('department')}
        />
        {isCompanyCheckinProgress ? (
          <div className=" absolute top-[6.25rem] right-6 md:top-[6.75rem]">
            <BasicSpinner className="leading-[14px] text-zinc-100" />
          </div>
        ) : null}
        <HookFormErrorMessage
          name="department"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
        {errors?.organisationAlreadyExists &&
          errors?.organisationAlreadyExists.type === 'custom' && (
            <span className="text-xs text-red-500">{companyAlreadyExists}</span>
          )}
      </div>
      {/* deparment */}
      {/* Active Learners */}
      <div className="flex w-full flex-col gap-2">
        <Label label={activeLearnersLabel} htmlFor="organisation_active_learners"></Label>
        <TextInput
          className="text-zinc-50"
          id="organisation_active_learners"
          type="number"
          placeholder={activeLearnersPlaceholder}
          aria-invalid={fieldHasError(errors, 'activeLearners') ? 'true' : 'false'}
          hasError={fieldHasError(errors, 'activeLearners')}
          {...register('activeLearners')}
        />
        <HookFormErrorMessage
          name="activeLearners"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </div>
      {/* Active Learners */}
      {/* Organisation Size */}
      <div className="flex w-full flex-col gap-2">
        <Label label={organisationSizeLabel} htmlFor="organisation_size"></Label>
        <DropdownInput
          type={'select'}
          options={organizationSizeDropDownOptions}
          id="organisation_size"
          placeholder={organizationSizeDropDownOptions[0].label}
          aria-invalid={fieldHasError(errors, 'organisationSize') ? 'true' : 'false'}
          hasError={fieldHasError(errors, 'organisationSize')}
          {...register('organisationSize', {
            deps: ['activeLearners'],
          })}
        />
        <HookFormErrorMessage
          name="organisationSize"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </div>
      {/* Organisation Size */}
      {/* Discount Coupon */}
      <div className="flex w-full flex-col gap-2">
        <Label label={discountPercentageLabel} htmlFor="organisation_discount"></Label>
        <DropdownInput
          type={'select'}
          options={dropdownList}
          id="organisation_discount"
          placeholder={dropdownList[0].label}
          aria-invalid={fieldHasError(errors, 'discountCoupon') ? 'true' : 'false'}
          hasError={fieldHasError(errors, 'discountCoupon')}
          {...register('discountCoupon')}
        />
        <HookFormErrorMessage
          name="discountCoupon"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </div>
      <div className="flex w-full flex-col gap-2">
        <div className="space-x-2">
          <CheckboxInput
            type={'checkbox'}
            id="organisation_plan"
            aria-invalid={fieldHasError(errors, 'plan') ? 'true' : 'false'}
            {...register('plan')}
          />
          <Label
            htmlFor="organisation_plan"
            label={isAnuallyCheckBoxLabel}
            className="font-normal leading-[25px] text-zinc-300"
          />
        </div>
        <HookFormErrorMessage
          name="plan"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </div>
      <div className="flex justify-end">
        <Dialog.Close className="px-7 text-zinc-50">{cancelButton}</Dialog.Close>
        <button
          type="submit"
          disabled={isSubmitting || isEmailCheckinProgress || (!isValid && isSubmitted)}
          className="inline-block justify-end !rounded bg-base-brand px-7 py-2.5 text-sm font-medium normal-case leading-tight text-white hover:bg-base-brand disabled:opacity-90"
        >
          {isSubmitting ? (
            <div className="flex gap-2">
              {InviteButton}
              <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
            </div>
          ) : (
            <>Invite Organisation</>
          )}
        </button>
      </div>
    </form>
  );
}

export default AddOrganisationModalForm;
