import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateMaintenanceModeParams, updateMaintenanceMode } from '../api';
import toast from 'react-hot-toast';
import { useStrapiSettingsData } from './useStrapiSettingsData';
import { showToast } from '../../shared/helpers/toast';

const useUpdateMaintenanceModeMutation = () => {
  // query client
  const queryClient = useQueryClient();
  // query client

  // strapi content
  const { hqAdminPlatformMaintenanceMode: maintenanceModeCMS } = useStrapiSettingsData();
  // strapi content

  const mutation = useMutation({
    mutationFn: async (params: UpdateMaintenanceModeParams) => {
      const response = await updateMaintenanceMode(params);
      return response;
    },
    onMutate: () => {
      const toastId = showToast({
        variant: 'info',
        title: maintenanceModeCMS.mainteModeUpdateInProgressToast,
      });

      return {
        toastId: toastId,
      };
    },
    onSuccess: async (_, variables, context) => {
      const { action } = variables;

      // Update the local query data for the tenant mfa configuration
      queryClient.setQueryData(
        [
          {
            scope: 'settings',
            item: 'maintenance-mode',
          },
        ],
        () => {
          return {
            Enabled: action === 'Enable' ? true : false,
          };
        }
      );

      toast.remove(context?.toastId);
      showToast({
        variant: 'success',
        title: maintenanceModeCMS.mainteModeUpdateSuccessToast,
      });
    },
    onError: async (_err, _params, context) => {
      toast.remove(context?.toastId);
      showToast({
        variant: 'error',
        title: maintenanceModeCMS.mainteModeUpdateErrorToast,
      });
    },
  });

  return mutation;
};

export default useUpdateMaintenanceModeMutation;
