import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ErrorDisplay } from '../../../../components/DataTables';
import { BasicSpinner } from '../../../../components/Spinners';
import { ERROR_TYPES } from '../../../../constants/error-types.constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import { RootState } from '../../../../store';
import { SECTIONS } from '../../constants/section-name';
import {
  ENT_ADMIN_MIN_PAGE_SIZE,
  ENT_ADMIN_VIEW_MEMBERS_DEFAULT_PAGE,
  ENT_ADMIN_VIEW_MEMBERS_DEFAULT_START,
  ENT_ADMIN_VIEW_MEMBERS_STATUS_QUERY_PARAM,
  ENT_ADMIN_VIEW_MEMBERS_STATUS_VALUES,
} from '../../constants/tableConstants';
import useAdminViewTeamMembersQuery from '../../hooks/useAdminViewTeamMembersQuery';
import { triggerDataRefresh } from '../../slices/team.slice';
import {
  EnterpriseAdminViewTeamMembersFilter,
  PartialEnterpriseAdminViewAllTeamMembersFilter,
} from '../../types/interface';
import AdminViewTeamMembersFooter from './AdminViewTeamMembersFooter';
import AdminViewTeamMembersHeader from './AdminViewTeamMembersHeader';
import AdminViewTeamMembersTable from './AdminViewTeamMembersTable';
import TableEmptyState from './AdminViewTeamMembersTable/TableEmptyState';

interface Props {
  className?: string;
}

const AdminViewTeamMembersMain = ({ className }: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const teamStateSelector = (state: RootState) => state.team;
  const { sortKey, sortOrder, dataRefreshState } = useAppSelector(teamStateSelector);
  const q = searchParams.get('q') ?? undefined;
  let status: string | null | undefined = searchParams.get(
    ENT_ADMIN_VIEW_MEMBERS_STATUS_QUERY_PARAM
  );
  const teamId = parseInt(useParams().id as string);
  const isFilterApplied = q || status ? true : false;

  if (
    status == null ||
    !ENT_ADMIN_VIEW_MEMBERS_STATUS_VALUES.includes(
      status as EnterpriseAdminViewTeamMembersFilter['status']
    )
  ) {
    status = undefined;
  }

  const [page, setPage] = useState(ENT_ADMIN_VIEW_MEMBERS_DEFAULT_PAGE);
  const [start, setStart] = useState(ENT_ADMIN_VIEW_MEMBERS_DEFAULT_START);
  const [size, setPageSize] = useState(ENT_ADMIN_MIN_PAGE_SIZE);
  useEffect(() => {
    setStart(page * 10 - 10);
  }, [page]);
  let newSortKey = '';
  let newSortOrder: string | boolean = '';
  if (sortOrder === 'desc') {
    // we will be getting the sort Order for columns which contain String values as asc:asc desc:false and false:desc
    newSortOrder = 'DESC';
    newSortKey = sortKey;
  } else if (sortOrder === 'asc') {
    newSortOrder = 'ASC';
    newSortKey = sortKey;
  }
  const searchFilter: PartialEnterpriseAdminViewAllTeamMembersFilter = {
    searchKey: q,
    start: start,
    size: size,
    teamId,
    status: status as PartialEnterpriseAdminViewAllTeamMembersFilter['status'],
  };

  if (newSortKey && newSortOrder) {
    searchFilter.sortOrder = newSortOrder;
    searchFilter.sortKey = newSortKey;
  }
  const filters: PartialEnterpriseAdminViewAllTeamMembersFilter = useMemo(() => {
    return searchFilter;
  }, [status, q, start, size, teamId, status, newSortKey, newSortOrder]);

  const request = useAdminViewTeamMembersQuery({ filters });
  const displayLoader =
    (request.isLoading && !request.data) ||
    ((request.isFetching || request.isRefetching) && request.data?.rowCount === 0);
  const isEmptyState = request.data && request.data.rowCount == 0;

  const dispatch = useAppDispatch();
  // Refresh the Table on data changes
  useEffect(() => {
    if (dataRefreshState?.ADMIN_TEAM_MEMBER_LIST_TABLE) {
      request.refetch().then(() => {
        const actionPayload = {
          [SECTIONS.ADMIN_TEAM_MEMBER_LIST_TABLE]: false,
        };
        dispatch(triggerDataRefresh(actionPayload));
      });
    }
  }, [dataRefreshState]);
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  return (
    <main
      id="main-content"
      className={clsx(
        'flex flex-col items-start gap-4 rounded-sm bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)] md:p-5 lg:p-6',
        className
      )}
    >
      <AdminViewTeamMembersHeader q={q} status={status} />
      {request.isLoading && !request.data && (
        <div className="flex w-full justify-center py-4">
          <BasicSpinner className="text-white" />
        </div>
      )}
      {request.data && (
        <div
          className={clsx(
            'flex w-full flex-col gap-4',
            request.isPreviousData && request.isFetching && 'pointer-events-none opacity-70'
          )}
        >
          <div className="w-full items-center overflow-y-scroll">
            <AdminViewTeamMembersTable data={request.data.data} />
          </div>
          {!isEmptyState && (
            <AdminViewTeamMembersFooter
              setPage={setPage}
              activePage={page}
              totalItems={request.data.rowCount}
              pageSize={size}
              setPageSize={setPageSize}
            />
          )}
        </div>
      )}
      {isEmptyState && !displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <TableEmptyState isFilterApplied={isFilterApplied} />
        </div>
      )}
      {request.isError && !request.data && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <ErrorDisplay
            refetch={request.refetch}
            isRefetching={request.isRefetching}
            allowsRefetch={true}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            message={strapiErrorMessage['SYSTEM_ERROR']}
          />
        </div>
      )}
    </main>
  );
};

export default AdminViewTeamMembersMain;
