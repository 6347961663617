import { useMutation } from '@tanstack/react-query';
import { CourseCreateMutationType } from '../types';
import { createCourse } from '../api';

const useCourseCreateMutation = () => {
  const { data, error, isLoading, isError, mutate } = useMutation({
    mutationFn: (create: CourseCreateMutationType) => createCourse(create),
  });
  return { data, error, isLoading, isError, mutate };
};

export default useCourseCreateMutation;
