import { gql } from '@apollo/client';

export const CourseFragment = gql`
  fragment CourseFragment on CourseEntityResponse {
    data {
      attributes {
        HQ_ADMIN {
          ... on ComponentCoursesHqTopNav {
            courseTitle
            saveAssessmentButton
            liveStatusLabel
            draftStatusLabel
            addCourseButton
            editCourseButton
            backToCourseButton
            backButton
            manageCategoriesButton
            makeCourseLiveButton
          }
          ... on ComponentCoursesToastMessages {
            createCourseSuccessTitle
            createCourseFailureTitle
            updateCourseSuccessTitle
            updateCourseFailureTitle
            createModuleSuccessTitle
            createModuleFailureTitle
            updateModuleSuccessTitle
            updateModuleFailureTitle
            courseMadeLive
            publishCourseError
            updateModuleOrderSuccessTitle
            updateModuleOrderSuccessMessage
            updateModuleOrderFailureTitle
            updateModuleOrderFailureMessage
            courseCreationFailedDueToNoMedia
            courseCreationFailedDueToNoModule
            courseCreationFailedDueToInvalidId
            updateQuestionOrderSuccessTitle
            updateQuestionOrderSuccessMessage
            updateQuestionOrderFailureTitle
            updateQuestionOrderFailureMessage
            createCourseSuccessMessage
            createCourseFailureMessage
            updateCourseSuccessMessage
            updateCourseFailureMessage
            createModuleSuccessMessage
            createModuleFailureMessage
            updateModuleFailureMessage
            updateModuleSuccessMessage
            createAssessmentSuccessTitle
            createAssessmentSuccessMessage
            createAssessmentFailureTitle
            createAssessmentFailureMessage
            updateAssessmentSuccessTitle
            updateAssessmentSuccessMessage
            updateAssessmentFailureTitle
            updateAssessmentFailureMessage
            createQuestionSuccessTitle
            createQuestionSuccessMessage
            createQuestionFailureTitle
            createQuestionFailureMessage
            updateQuestionSuccessTitle
            updateQuestionSuccessMessage
            updateQuestionFailureTitle
            updateQuestionFailureMessage
            categoriesFetchErrorTitle
            categoriesFetchErrorMessage
            speakerFetchErrorTitle
            speakerFetchErrorMessage
            tagsFetchErrorTitle
            tagsFetchErrorMessage
            courseCreationFailedDueToNoQuestions
            submitAnswersForQuizFailedTitle
            submitAnswersForQuizFailedMessage
            courseAssignedSuccessTitle
            courseAssignedFailureTitle
            courseSelfAssignedSuccessMessage
            courseUserAssignedSuccessMessage
            courseTeamAssignedSuccessMessage
            courseSelfAssignedFailedMessage
            courseUserAssignedFailedMessage
            courseTeamAssignedFailedMessage
            feedbackSentSuccess
            feedbackSentFailed
            teamsFetchErrorTitle
            teamsFetchErrorMessage
            usersFetchErrorTitle
            usersFetchErrorMessage
            createCourseMultLangSuccessMessage
          }
          ... on ComponentCoursesModuleType {
            assessmentTypeLabel
            videoTypeLabel
            audioTypeLabel
            documentTypeLabel
          }
          ... on ComponentCoursesCourseTable {
            statusTableHeader
            nameTableHeader
            speakerTableHeader
            modulesTableHeader
            ratingsTableHeader
            categoryTableHeader
            updatedOnTableHeader

            CourseTableStatus {
              allStatusLabel
              liveStatusLabel
              draftStatusLabel
              statusPlaceholder
            }
          }
          ... on ComponentCoursesSingleCourseTableView {
            Statistics {
              startedByCardLabel
              durationTitle
              assignedToCardLabel
              completedByCardLabel
              assessmentCardLabel
              ratingCardLabel
            }
            FeedbackTable {
              submittedOnTableHeader
              feedbackTableHeader
              organizationTableHeader
              nameTableHeader
              ratingTableHeader
              ratingFilterLabel
              title
            }
            ModuleTable {
              speakerTableHeader
              moduleTableHeader
              moduleTakenByTableHeader
              updatedOnTableHeader
              title
            }
            SingleFeedbackModal {
              starsLabel
              feedbackLabel
              starsLabel
              submittedByLabel
              organizationLabel
              closeButton
            }
          }
          ... on ComponentCoursesAddOrEditCourse {
            closeButton
            addCourseTitle
            editCourseTitle
            addCourseButton
            editCourseButton
            nameLabel
            namePlaceholder
            nameRequired
            nameMinValue
            nameMinError
            nameMaxValue
            nameMaxError
            descriptionLabel
            descriptionPlaceholder
            descriptionRequired
            descriptionMinValue
            descriptionMinError
            descriptionMaxValue
            descriptionMaxError
            speakerLabel
            speakerRequired
            speakerPlaceholder
            tagsLabel
            tagsPlaceholder
            tagsMaxValue
            tagsMaxError
            tagsMinValue
            tagsMinError
            catergoryLabel
            categoryRequired
            categoryPlaceholder
            coverImageLabel
            coverImageType
            coverImageMaxValue
            coverImageRequired
            trailerType
            trailerLabel
            trailerMaxValue
            trailerRequired
            leanerGistLabel
            learnerGistMaxValue
            learnerGistMaxError
            learnerGistPlaceholder
            isFeaturedCourseLabel
            createCourseButton
            manageLanguagesTitle
          }
          ... on ComponentCoursesSingleCourseView {
            BasicDetails {
              title
              speakerLabel
              tagsLabel
              nameLabel
              editCourseButton
              descriptionLabel
              categoryLabel
              coverImageLabel
              learnerGistLabel
              trailerLabel
            }
            ModuleDetails {
              addAssessmentButton
              addModuleButton
              title
              inactiveAssessmentStatusLabel
              resetButton
              saveButton
            }
          }
          ... on ComponentCoursesAddOrEditModule {
            nameLabel
            namePlaceholder
            nameRequired
            nameMinValue
            nameMinError
            nameMaxValue
            nameMaxError
            descriptionLabel
            descriptionPlaceholder
            descriptionRequired
            descriptionMinValue
            descriptionMinError
            descriptionMaxValue
            descriptionMaxError
            moduleTypeLabel
            moduleTypeRequired
            moduleTypePlaceholder
            speakerLabel
            speakerRequired
            speakerPlaceholder
            speakerMinValue
            speakerMinError
            speakerMaxValue
            speakerMaxError
            durationLabel
            durationRequired
            durationPlaceholder
            durationMinValue
            durationMinError
            thumbnailType
            thumbnailLabel
            thumbnailMaxValue
            thumbnailRequired
            mediaType
            mediaLabel
            mediaMaxValue
            mediaRequired
            addButton
            addModuleTitle
            editButton
            editModuleTitle
            cancelButton
            closedCaptionsLabel
            closedCaptionsMaxValue
            closedCaptionsType
            closedCaptionsRequired
            closedCaptionsUploadLimitError
            subtitleFilenameLabel
            subtitleLanguageLabel
            subtitleRemoveLabel
            subtitleRemoveButtonLabel
            LanguageOptions {
              display
              value
            }
            manageLanguagesTitle
          }
          ... on ComponentCoursesSingleModuleView {
            speakerLabel
            descriptionLabel
            mediaLabel
            nameLabel
            thumbnailLabel
            moduleTypeLabel
            editModuleButton
            title
          }
          ... on ComponentCoursesAddOrEditAssessment {
            nameLabel
            namePlaceholder
            nameRequired
            nameMinValue
            nameMinError
            nameMaxValue
            nameMaxError
            descriptionLabel
            descriptionPlaceholder
            descriptionRequired
            descriptionMinValue
            descriptionMinError
            descriptionMaxValue
            descriptionMaxError
            passPercentageLabel
            passPercentageRequired
            passPercentagePlaceholder
            durationLabel
            durationRequired
            durationMinError
            durationMinValue
            durationPlaceholder
            assessmentRequiredCheckboxLabel
            editAssessmentTitle
            editAssessmentButton
            addAssessmentTitle
            addAssessmentButton
            cancelButton
            manageLanguagesTitle
          }
          ... on ComponentCoursesSingleAssessmentView {
            BasicDetails {
              title
              descriptionLabel
              passPercentageLabel
              editAssessmentButton
              nameLabel
            }
            Questions {
              addQuestion
              choicesLabel
              title
              resetButton
              saveButton
            }
          }
          ... on ComponentCoursesAddOrEditQuestion {
            addQuestionTitle
            editQuestionTitle
            addQuestionButton
            editQuestionButton
            questionLabel
            questionRequired
            questionPlaceholder
            questionMinValue
            questionMinError
            questionMaxValue
            questionMaxError
            optionsLabel
            optionsPlaceholder
            optionValueRequired
            correctOptionLabel
            correctOptionRequired
            correctOptionPlaceholder
            addMoreOptionsButton
            removeButton
            cancelButton
            manageLanguagesTitle
          }
          ... on ComponentCoursesLoadingMessages {
            courseLoading
            assessmentLoading
            moduleLoading
          }
          ... on ComponentCoursesEmptyMessages {
            emptyCourse
            emptyModule
            emptyAssessment
            commonEmptyMessage
            emptyDocument
          }
        }
        COMMON {
          ... on ComponentCoursesNoCourses {
            noCoursesMessage
            noCoursesDescription
            viewAllCoursesButton
            noRecommendedCourses
            loadingCourses
          }
          ... on ComponentCoursesSingleModule {
            common {
              backButton
              nowPlayingLabel
              nowViewingLabel
              nextButton
              modulesLabel
            }
            moduleSpeaker {
              coursesLabel
              title
            }
            singleAssessment {
              questionsLabel
              submitButton
              noAnswersErrorMessage
              noQuestionsErrorMessage
            }
            assessmentCompletion {
              assessmentSuccessTitle
              assessmentSuccessDescription
              assessmentFailureTitle
              assessmentFailureDescription
              yourScoreLabel
              correctLabel
              incorrectLabel
              closeButton
              nextButton
            }

            singleSpeaker {
              coursesLabel
              viewAllCoursesButton
            }
            courseCompleted {
              title
              description
              ratingLabel
              starsLabel
              feedbackLabel
              feedbackPlaceHolder
              closeButton
              submitButton
              ratingPlaceholder
              feedbackRequired
              feedbackMinError
              feedbackMinValue
              feedbackMaxValue
              feedbackMaxError
              ratingRequired
            }
          }
          ... on ComponentCoursesAssignCourseModal {
            teamLabel
            teamPlaceHolder
            memberLabel
            memberPlaceHolder
            dueDateLabel
            dueDatePlaceHolder
            fixDueDateMessage
            cancelButton
            assignCourseButton
            title
            orLabel
          }
          ... on ComponentCoursesAllCourses {
            title
            filters {
              categoriesLabel
              speakersLabel
              durationLabel
              formatLabel
              tagsLabel
              filterByLabel
              clearAllFiltersButton
              noFiltersAppliedText
            }
            sort {
              ascendingSortLabel
              descendingSortLabel
              shortestToLongestSortLabel
              longestToShortestSortLabel
              sortPlaceHolder
            }
            status {
              statusPlaceHolder
              assignedLabel
              inProgressLabel
              completedLabel
              overDueLabel
            }
            search {
              searchPlaceHolder
            }
            navigator {
              allCoursesTab
              recommendedCoursesTab
              searchResultsTitle
            }
            coursesCard {
              byLabel
              hoursLabel
              minsLabel
            }
            recommendedCourses {
              orgRecommendedCoursesTitle
              teamRecommendedCoursesTitle
              yourRecommendedCoursesTitle
            }
          }
          ... on ComponentCoursesSingleCourse {
            speaker {
              title
              courseLabel
            }
            courseInfo {
              minsLabel
              hoursLabel
              dueLabel
              reviewLabel
              moduleLabel
              noRatingsLabel
              noRatingsYetLabel
            }
            singleCourseCompleted {
              restartCourseButton
              courseCompletedText
              findMoreCourseButton
              recommendedCourseLabel
              otherCourseInCategoryLabel
              learningJourneyCourseCompleted
              nextUpInLearningJourney
              completedLearningJourney
              exploreMoreLearningJourney
            }
            topNav {
              startCourseButton
              assignCourseButton
              backButton
              rateCourseButton
              continueCourseButton
              courseCompletedLabel
              backToLearningJourney
              restartCourseButton
            }
            moduleDetails {
              hoursLabel
              minsLabel
              completedStatus
              inProgressStatus
              moduleLabel
              assignmentDueStatus
            }
            courseDetails {
              descriptionLabel
              categoryLabel
              tagsLabel
              courseOutComeLabel
              similarCoursesLabel
              noTagsLabel
            }
            singleCourseTabs {
              courseDetails
              modules
            }
          }
        }
      }
    }
  }
`;
