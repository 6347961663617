import clsx from 'clsx';
import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchInput from '../../../../components/SearchInput';
import Select from '../../../../components/Select';
import { ENT_ADMIN_VIEW_MEMBERS_STATUS_QUERY_PARAM } from '../../constants/tableConstants';
import { Option } from '../../../../components/Select/index';
import { useStrapiTeamData } from '../../hooks/useStrapiTeamData';
import { useStrapiSharedData } from '../../../shared/hooks/useStrapiSharedData';
interface Props {
  className?: string;
  q: string | undefined;
  status: string | undefined;
}

const AdminViewTeamMembersHeader = ({ className, q, status }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const handleQChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    searchParams.set('q', value);
    navigate('?' + searchParams.toString());
  };

  const handleStatusChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value;
    searchParams.set(ENT_ADMIN_VIEW_MEMBERS_STATUS_QUERY_PARAM, value);
    navigate('?' + searchParams.toString());
  };

  const handleResetFiltersBtnClick = () => {
    navigate('.');
  };
  const { singleTeamTable } = useStrapiTeamData();
  const {
    singleTeamSortActive,
    singleTeamSortInvited,
    singleTeamSortDeactivated,
    singleTeamSortPlaceHolder,
  } = singleTeamTable;
  const ENT_ADMIN_STATUS_VIEW_MEMBERS_SELECT_OPTIONS: Option[] = [
    {
      display: singleTeamSortActive,
      value: 'ACTIVE',
    },
    {
      display: singleTeamSortInvited,
      value: 'INVITED',
    },
    {
      display: singleTeamSortDeactivated,
      value: 'DEACTIVATED',
    },
  ];
  ENT_ADMIN_STATUS_VIEW_MEMBERS_SELECT_OPTIONS.length = 2;
  const {
    tableProps: { clearAllFiltersButton, searchPlaceHolder },
  } = useStrapiSharedData();
  return (
    <div
      className={clsx(
        'flex w-full flex-col justify-between gap-2 text-[14px] font-normal leading-[20px] text-zinc-100 sm:flex-row',
        className
      )}
    >
      <div className="flex flex-col gap-2 lg:flex-row">
        <SearchInput
          placeholder={searchPlaceHolder}
          containerClassName="min-w-[200px]"
          iconClassName="left-2"
          className="h-9 rounded-sm border border-zinc-900 bg-zinc-900 p-2 pl-9 text-[14px] font-normal leading-[20px] text-zinc-100 placeholder:text-zinc-500"
          value={q ?? ''}
          onChange={handleQChange}
        />
        <Select
          value={status ?? undefined}
          options={ENT_ADMIN_STATUS_VIEW_MEMBERS_SELECT_OPTIONS}
          onChange={handleStatusChange}
          selectItemLabel={singleTeamSortPlaceHolder}
          aria-label="Requests status filter"
          className="rounded-sm border border-zinc-900 bg-zinc-900 py-2 pl-4 pr-10 text-[14px] font-normal leading-[20px] text-zinc-100"
        />
      </div>
      <div className="flex flex-col gap-2 lg:flex-row">
        <button
          onClick={handleResetFiltersBtnClick}
          className="rounded-sm border border-zinc-900 bg-zinc-900 py-1 pl-4 pr-10"
        >
          {clearAllFiltersButton}
        </button>
      </div>
    </div>
  );
};

export default memo(AdminViewTeamMembersHeader);
