import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Dialog from '../../../../../components/Dialog';
import SearchInput from '../../../../../components/SearchInput';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';
// import { ENT_ADMIN_FILTER_QUERY_PARAM } from '../../../../requests/constants';
import { ENT_LEADER_TEAMS_FILTER_QUERY_PARAM } from '../../../constants/enterpriseLeader';
import { useStrapiTeamData } from '../../../hooks/useStrapiTeamData';
import AddTeamMain from '../../Modal/AddTeamModal/AddTeamMain';
import './styles.css';
interface RoleProps {
  isLeaderTeamView?: boolean;
}
const TeamsMenuItems = ({ isLeaderTeamView }: RoleProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  // We would need whether the filter is applied
  // We would check the sort, status and filter to decide whether the filter is
  // Applied or not

  // Parse the filters from the url query parameters
  const search = searchParams.get(ENT_LEADER_TEAMS_FILTER_QUERY_PARAM) ?? undefined;

  const handleQChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    searchParams.set(ENT_LEADER_TEAMS_FILTER_QUERY_PARAM, value);
    navigate('?' + searchParams.toString());
  };
  const { topNav } = useStrapiTeamData();
  const [isAddTeamModalOpen, setIsAddTeamModalOpen] = useState(false);
  const {
    tableProps: { searchPlaceHolder },
  } = useStrapiSharedData();
  return (
    <div>
      {isLeaderTeamView ? (
        <div className="order-1 flex flex-col gap-4 xs:flex-row">
          <SearchInput
            placeholder={searchPlaceHolder}
            containerClassName="min-w-[200px]"
            iconSize="15"
            iconClassName="left-2"
            className="rounded-sm border border-zinc-900 bg-zinc-900 p-1 pl-9 text-[14px] font-normal leading-[20px] text-zinc-100 placeholder:text-zinc-500"
            value={search ?? ''}
            onChange={handleQChange}
          />

          <Dialog
            triggerClassName="mr-1 w-full inline-block gap-1 whitespace-nowrap !rounded bg-base-brand px-5 py-2 text-xs font-normal leading-tight text-white hover:bg-base-brand"
            open={isAddTeamModalOpen}
            onOpenChange={(open) => setIsAddTeamModalOpen(open)}
            Content={
              <AddTeamMain
                setIsModalOpen={setIsAddTeamModalOpen}
                isLoading={false}
                isOnBoarding={false}
              />
            }
            onInteractOutside={(e) => {
              // Donot close the Modal when we click outside the Dialog Content
              e.preventDefault();
            }}
            containerClassName="max-h-[80vh] overflow-y-scroll"
          >
            + {topNav.topNavAddTeamButton}
          </Dialog>
        </div>
      ) : (
        <Dialog
          containerClassName="max-h-[80vh] items-center overflow-y-scroll"
          triggerClassName="mr-1 w-full inline-block gap-1 whitespace-nowrap !rounded bg-base-brand px-5 py-1 text-xs font-normal leading-tight text-white hover:bg-base-brand md:h-[30px] "
          open={isAddTeamModalOpen}
          onOpenChange={(open) => setIsAddTeamModalOpen(open)}
          Content={
            <AddTeamMain
              setIsModalOpen={setIsAddTeamModalOpen}
              isLoading={false}
              isOnBoarding={false}
            />
          }
          onInteractOutside={(e) => {
            // Donot close the Modal when we click outside the Dialog Content
            e.preventDefault();
          }}
        >
          + {topNav.topNavAddTeamButton}
        </Dialog>
      )}
    </div>
  );
};

export default TeamsMenuItems;
