import clsx from 'clsx';

interface Props {
  heading: string;
  content: React.ReactNode;
  className?: string;
}

const RequestActionDialogItem = ({ heading, content, className }: Props) => {
  return (
    <div
      className={clsx(
        'flex grow basis-0 flex-col items-center gap-1 text-[14px] leading-[20px] xs:items-start',
        className
      )}
    >
      <span className="font-medium text-zinc-400">{heading}</span>
      <span className="text-center font-medium text-zinc-50 xs:text-left">{content}</span>
    </div>
  );
};

export default RequestActionDialogItem;
