import '../../../../styles/header.css';
interface PageHeaderProps {
  settingsHeader: string;
  settingsSubHeader: string;
}
const PageHeader = ({ settingsHeader, settingsSubHeader }: PageHeaderProps) => {
  return (
    <header className=" flex w-full items-center justify-between border-b-zinc-800 bg-card-bg px-3 pt-6 pb-3 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
      <div className="flex flex-col gap-y-2">
        <h1 className="header-title">{settingsHeader}</h1>
        <span className="text-xs leading-[20px] text-zinc-500 md:text-sm">{settingsSubHeader}</span>
      </div>
    </header>
  );
};

export default PageHeader;
