import { flexRender, Row } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { ListIndividualsResults } from '../../../types/GetIndividualApiResponse';

interface Props {
  row: Row<ListIndividualsResults>;
  className?: string;
}

const ListIndividualTableDataRow = ({ row, className }: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <tr className={className}>
        {row.getVisibleCells().map((cell) => {
          return (
            <td
              onClick={() =>
                navigate('/manage/individual/' + row.original.email + '/' + row.original.name)
              }
              key={cell.id}
              className="p-2 text-[12px] font-normal leading-[16px] text-zinc-100"
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default ListIndividualTableDataRow;
