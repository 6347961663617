import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import Alert from '../../../../../components/Alert';
import Dialog from '../../../../../components/Dialog';
import { CodeInput } from '../../../../../components/FormElements';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
import { BasicSpinner } from '../../../../../components/Spinners';
import { Toast } from '../../../../../components/Toast';
import { CognitoUserAttributes } from '../../../../../constants';
import { STRAPI_PLACEHOLDER_CODE } from '../../../../../constants/strapi';
import { useStrapiSettingsData } from '../../../hooks/useStrapiSettingsData';
import useVerifyCurrentUserAttributeSubmitMutation from '../../../hooks/useVerifyCurrentUserAttributeSubmitMutation';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';

type Props = {
  phoneNumber: string;
  onVerificationSuccess?: () => void;
};

// Form Schema

type GenerateFormSchemaParams = {
  codeRequiredErrorMessage: string;
};

const generateFormSchema = (params: GenerateFormSchemaParams) => {
  const { codeRequiredErrorMessage } = params;

  const formSchema = yup.object({
    code: yup
      .array(
        yup.object({
          value: yup.string().trim(),
        })
      )
      .test({
        name: 'is-valid-code',
        message: codeRequiredErrorMessage,
        test: (value) => {
          if (!value) {
            return false;
          }

          for (let i = 0; i < value.length; i++) {
            const codeChar = value[i];
            if (!codeChar || !codeChar.value || codeChar.value.length === 0) {
              return false;
            }
          }

          return true;
        },
      })
      .length(6)
      .required(codeRequiredErrorMessage),
  });

  return formSchema;
};

type FormData = yup.InferType<ReturnType<typeof generateFormSchema>>;

// Form Schema

// Helpers

// 6 fields and all of them initially empty
const codeDefaultValue = Array(6)
  .fill(0)
  .map((_) => ({ value: '' }));

// Helpers

const VerifyPhoneNumberDialogContent = (props: Props) => {
  const { phoneNumber, onVerificationSuccess } = props;

  const verifyCurrentUserAttributeSubmitMutation = useVerifyCurrentUserAttributeSubmitMutation();

  // strapi
  const { Exception: exceptionCMS } = useStrapiSharedData();

  const { profile: strapiProfile } = useStrapiSettingsData();
  const { PhoneVerificationDialog: phoneVerDialogCMS } = strapiProfile as {
    PhoneVerificationDialog: any;
  };

  const dialogDescriptionParts = useMemo(() => {
    const parts = phoneVerDialogCMS.description.split(STRAPI_PLACEHOLDER_CODE.PHONE_NUMBER);
    return parts;
  }, [phoneVerDialogCMS.description]);

  const { codeRequiredErrorMessage, verificationSuccessToastMessage } = phoneVerDialogCMS;

  // strapi

  const formSchema = useMemo(() => {
    return generateFormSchema({ codeRequiredErrorMessage });
  }, [codeRequiredErrorMessage]);

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isSubmitted, isValid },
  } = useForm<FormData>({
    defaultValues: {
      code: codeDefaultValue,
    },
    resolver: yupResolver(formSchema),
  });

  // Form Submission Handler

  const handleFormSubmit: SubmitHandler<FormData> = async (data) => {
    // data.code is an array of {value: 'x'}
    const code = data.code.map((codeChar) => codeChar.value).join('');

    try {
      await verifyCurrentUserAttributeSubmitMutation.mutateAsync({
        attributeName: CognitoUserAttributes.PHONE_NUMBER,
        code: code,
      });
      onVerificationSuccess && onVerificationSuccess();
      toast.custom(
        (t) => (
          <Toast toastInstance={t} variant="success" Title={verificationSuccessToastMessage} />
        ),
        {
          duration: 3000,
        }
      );
    } catch (error) {
      console.error(error);
      let errorMessage = phoneVerDialogCMS.somethingWentWrongErrorMessage;

      if (error && typeof error === 'object') {
        const { code } = error as { code: string; message: string };

        // CognitoException.CodeMismatchException
        // CognitoException.ExpiredCodeException
        // CognitoException.LimitExceededException
        // right now these are handled
        if (code) {
          errorMessage = exceptionCMS[code] ?? errorMessage;
        }
      }

      setError('root.serverError', {
        message: errorMessage,
        type: 'SERVER_ERROR',
      });

      toast.custom((t) => <Toast toastInstance={t} Title={errorMessage} variant="error" />, {
        duration: 4000,
      });
    }
  };

  return (
    <form
      className="flex flex-col gap-6 rounded-lg border border-zinc-900 bg-component-bg-dark px-3 py-6 shadow-[0px_10px_10px_-5px_rgba(0,0,0,0.04),0px_20px_25px_-5px_rgba(0,0,0,0.10)] xxs:px-4 xs:px-5 sm:p-6"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div className="flex flex-col items-stretch justify-center gap-2">
        <Dialog.Title className="w-full text-center text-lg font-semibold text-white">
          {phoneVerDialogCMS.title}
        </Dialog.Title>
        <div className="flex items-center justify-center">
          <Dialog.Description className="max-w-md text-center text-sm">
            <span className="font-normal text-zinc-300">{dialogDescriptionParts[0]}</span>
            <span className="font-semibold text-zinc-50">{phoneNumber}</span>
            <span className="font-normal text-zinc-300">{dialogDescriptionParts[1]}</span>
          </Dialog.Description>
        </div>
      </div>
      {/* Server Error */}
      <HookFormErrorMessage
        errors={errors}
        name="root.serverError"
        render={({ message }) => {
          return <Alert message={message} variant="error" className="max-w-sm self-center" />;
        }}
      />
      <div className="flex flex-col items-center justify-center gap-2">
        <CodeInput
          control={control}
          register={register}
          singleInputClassName="w-9 xxs:w-14 xs:w-16 sm:w-[72px] h-12 p-2 xs:p-3 text-zinc-50 bg-zinc-900 hover:bg-zinc-800 text-zinc-50 border border-zinc-700 focus:border-zinc-500 focus:bg-zinc-800 rounded"
          className="gap-2"
        />
        <HookFormErrorMessage
          errors={errors}
          name="code"
          render={({ message }) => {
            return <ErrorMessage className="max-w-md text-center" message={message} />;
          }}
        ></HookFormErrorMessage>
      </div>
      <div
        style={{
          '--btn-padding-x': '30px',
          '--btn-padding-y': '10px',
        }}
        className="flex items-center justify-end text-sm font-medium text-white"
      >
        <Dialog.Close asChild>
          <button className="py-[var(--btn-padding-y)] px-[var(--btn-padding-x)]">
            {phoneVerDialogCMS.cancelBtnLabel}
          </button>
        </Dialog.Close>
        <button
          disabled={isSubmitting || (isSubmitted && !isValid)}
          className="flex items-center justify-center gap-1 rounded bg-base-brand py-[var(--btn-padding-y)] px-[var(--btn-padding-x)] disabled:opacity-70"
        >
          <span>{phoneVerDialogCMS.confirmVerificationBtnLabel}</span>
          {isSubmitting && <BasicSpinner className="!m-0" />}
        </button>
      </div>
    </form>
  );
};

export default VerifyPhoneNumberDialogContent;
