import { useQuery } from '@tanstack/react-query';
import { getHQAdminInvoices } from '../../teams/api/HQAdminTeams';
import { getAllInvoices, getIndividualInvoices } from '../api/billings';
import { BILLING_HISTORY_QUERY_KEY, STALE_TIME } from '../constants/billings';

interface Props {
  username?: string;
  tenantId?: string;
  tenantType?: string;
}
const useBillingHistoryInvoiceQuery = ({ username, tenantId }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching } =
    useQuery(
      [BILLING_HISTORY_QUERY_KEY],
      async () => {
        return username
          ? getIndividualInvoices(username as string)
          : tenantId
          ? getHQAdminInvoices(tenantId)
          : getAllInvoices();
      },
      {
        keepPreviousData: true,
        staleTime: STALE_TIME,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching };
};

export default useBillingHistoryInvoiceQuery;
