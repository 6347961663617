import toast from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';
import { editEnterpriseTeams } from '../api';
import { SECTIONS } from '../constants/section-name';
import { triggerSectionDataRefresh } from '../slices/team-dispatch.helper';
import { setSelectedTeam } from '../slices/team.slice';
import { useStrapiTeamData } from './useStrapiTeamData';
import { showToast } from '../../shared/helpers/toast';
import { useQueryClient } from '@tanstack/react-query';

export const useEditTeamsAPI = () => {
  const dispatch = useAppDispatch();
  const teamStateSelector = (state: RootState) => state.team;
  const { selectedTeam } = useAppSelector(teamStateSelector);

  const queryClient = useQueryClient();

  const { adminToastMessages } = useStrapiTeamData();
  const {
    adminUpdateFailureToastMessage,
    adminUpdateSuccessfulToastMessage,
    adminUpdateProcessingToastMessage,
  } = adminToastMessages;
  const editTeams = async (teamName: string, teamId: number, refreshSectionName: SECTIONS) => {
    const toastId = showToast({
      title: adminUpdateProcessingToastMessage,
      variant: 'info',
    });

    try {
      const response: any = await editEnterpriseTeams(teamId, teamName);
      if (response.success === true) {
        const { isSubTeam, hasSubTeam } = selectedTeam;
        dispatch(setSelectedTeam({ teamId, teamName, isSubTeam, hasSubTeam }));
        triggerSectionDataRefresh(refreshSectionName, dispatch);

        toast.remove(toastId);
        showToast({
          title: adminUpdateSuccessfulToastMessage,
          variant: 'success',
        });

        // Invalidate the team name query
        queryClient.invalidateQueries({
          queryKey: [
            {
              scope: 'teams',
              items: 'teamName',
              teamId,
            },
          ],
        });

        // Remove the team name allowed check from cache
        if (teamName) {
          queryClient.removeQueries({
            queryKey: ['team_exists', teamName],
          });
        }
      }
    } catch (error: any) {
      toast.remove(toastId);
      showToast({
        title: adminUpdateFailureToastMessage,
        body: error.errorMessage ?? error.message,
        variant: 'error',
      });
      throw error;
    }
  };
  return { editTeams };
};
