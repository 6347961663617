import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { SignOut } from 'phosphor-react';
import { useState } from 'react';
import { useStrapiSideNavData } from '../../../hooks/useStrapiSideNavData';
import { BasicSpinner } from '../../Spinners';
import { useLogoutMutation } from '../../../features/auth';
import { toast } from 'react-hot-toast';
import { Toast } from '../../Toast';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
import { useNavigate } from 'react-router-dom';

const SideBarProfileLogoutItem = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const navigate = useNavigate();

  const logoutMutation = useLogoutMutation();
  const data: any = useStrapiDataHelper();
  const { signOutError } = data.errormessage.data.attributes;
  const handleLogOut: DropdownMenu.DropdownMenuItemProps['onSelect'] = async (event) => {
    // Prevent the dropdown from auto closing
    event.preventDefault();
    try {
      setIsLoggingOut(true);
      await logoutMutation.mutateAsync();
      navigate('/dashboard');
    } catch (error) {
      toast.custom((t) => <Toast toastInstance={t} Title={signOutError} variant="error" />, {
        duration: 3000,
      });
      setIsLoggingOut(false);
    }
  };
  const { buttons } = useStrapiSideNavData();
  return (
    <DropdownMenu.Item
      className={clsx(
        'rounded-md py-[9px] px-[10px] hover:focus:bg-zinc-700',
        isLoggingOut ? 'pointer-events-none cursor-default' : 'pointer-events-auto cursor-pointer'
      )}
      onSelect={handleLogOut}
      disabled={isLoggingOut}
      textValue="logout"
    >
      <div className="flex h-full w-full flex-row items-center justify-between ">
        <div className="flex items-center gap-2 text-zinc-50">
          <SignOut size={16} className="text-inherit" color="currentColor" />
          <span className="text-sm font-medium text-inherit">{buttons.logoutButton}</span>
        </div>
        {isLoggingOut && <BasicSpinner className="!m-0 h-[16px] w-[16px] text-zinc-50" />}
      </div>
    </DropdownMenu.Item>
  );
};

export default SideBarProfileLogoutItem;
