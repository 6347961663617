import { enUS, fr, es } from 'date-fns/locale';

const localeMapping: { [key: string]: Locale } = {
  en: enUS,
  fr: fr,
  es: es,
  // Add other locales as needed
};

const DEFAULT_PREFERRED_LANGUAGE_DATE_FNS = enUS;

export function getLocale(language: string): Locale {
  return localeMapping[language] ?? DEFAULT_PREFERRED_LANGUAGE_DATE_FNS;
}
