import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { USERS_API_PATHS } from '../constants';
import { FlattenedAnnouncement } from '../types/announcement';

// types
export type GetAnnouncementResponse = FlattenedAnnouncement;
// types

/**
 * Get Announcement
 */
const getAnnouncement = async (): Promise<GetAnnouncementResponse> => {
  const path = USERS_API_PATHS.GET_ANNOUNCEMENT;

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    withCredentials: true,
  });

  return response.data;
};

export { getAnnouncement };
