import { REQUEST_STATUS } from '../../../../../constants';
import { useStrapiRequestData } from '../../../../../hooks/useStrapiRequestData';
import { EnterpriseAdminRequestStatus } from '../../../../../types';
import GenericStatusCell from '../../../../common/TableCells/GenericStatusCell';

interface StatusCellProps {
  status: EnterpriseAdminRequestStatus;
}

const StatusCell = (props: StatusCellProps) => {
  const { tableFilterOptions } = useStrapiRequestData();
  const { openFilterOption, completedFilterOption, rejectedFilterOption } = tableFilterOptions;
  const { status } = props;
  switch (status) {
    case REQUEST_STATUS.ACTIVE: {
      return (
        <GenericStatusCell
          containerClassName="bg-amber-900 text-amber-100"
          dotClassName="bg-amber-100"
          display={openFilterOption}
        />
      );
    }
    case REQUEST_STATUS.COMPLETED: {
      return (
        <GenericStatusCell
          containerClassName="bg-emerald-900 text-emerald-100"
          dotClassName="bg-emerald-100"
          display={completedFilterOption}
        />
      );
    }
    case REQUEST_STATUS.CANCELLED: {
      return (
        <GenericStatusCell
          containerClassName="bg-slate-900 text-white"
          dotClassName="bg-white"
          display={rejectedFilterOption}
        />
      );
    }
  }
};

export default StatusCell;
