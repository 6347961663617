import { useQuery } from '@tanstack/react-query';
import { getSpeakerById } from '../api/speaker';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';
import { useState } from 'react';
import axios from 'axios';
import { USHGAPIError } from '../../../types';
import { QUERY_KEYS, QUERY_STALE_TIME_MS } from '../constants';

interface Props {
  speakerId: number;
  language?: string;
  isEnabled?: boolean;
}

const useGetSpeakerByIdQuery = ({
  speakerId,
  language = DEFAULT_PREFERRED_LANGUAGE,
  isEnabled = true,
}: Props) => {
  const [errorType, setErrorType] = useState<string | null>();
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery({
      queryKey: QUERY_KEYS.getSpeakerById({ speakerId, language }),
      queryFn: async ({ queryKey }) => {
        const { speakerId } = queryKey[0];
        return getSpeakerById(speakerId, language);
      },
      enabled: isEnabled && speakerId != undefined,
      staleTime: QUERY_STALE_TIME_MS.getSpeakerById,
      onError: (error) => {
        // If axios error
        if (axios.isAxiosError(error)) {
          const data = error.response?.data;
          if (data) {
            const { errorCode } = data as USHGAPIError;
            setErrorType(errorCode);
          }
        }
      },
    });

  return {
    isLoading,
    data,
    isError,
    error,
    isPreviousData,
    isFetching,
    isRefetching,
    refetch,
    errorType,
    setErrorType,
  };
};

export default useGetSpeakerByIdQuery;
