import { gql } from '@apollo/client';

export const SharedFragment = gql`
  fragment SharedFragment on SharedEntityResponse {
    data {
      attributes {
        contactUsEmail
        PageNotFound {
          title
          subTitle
          homepageLabel
          goBackLabel
          orLabel
        }
        BulkInviteModal {
          uploadLabel
          orLabel
          dragAndDropLabel
          dropHereLabel
          fileSizeValidationMessage
          downloadSampleButton
          ErrorMessages(pagination: { limit: 100 }) {
            errorCode
            errorMessage
          }
        }
        UserType {
          adminType
          learnerType
          leaderType
          individualLearnerType
          managerType
          hqAdminType
        }
        TableProps {
          pageSizeSelectorLabel
          rowLabel
          searchPlaceHolder
          clearAllFiltersButton
          retryButton
        }
        PricingPlanAvatar {
          individualPlanAvatar {
            data {
              attributes {
                url
              }
            }
          }
          enterprisePlanAvatar {
            data {
              attributes {
                url
              }
            }
          }
          teamPlanAvatar {
            data {
              attributes {
                url
              }
            }
          }
        }
        inviteFriendsModal {
          sendEmailLabel
          sendEmailPlaceholder
          copyLinkLabel
          title
          description
          sendInviteButton
          cancelButton
          inviteSentSuccessfully
          inviteSentFailure
          linkCopiedSuccessfully
          invalidEmailErrorMessage
          emailRequiredErrorMessage
          image1 {
            data {
              attributes {
                url
              }
            }
          }
          image2 {
            data {
              attributes {
                url
              }
            }
          }
          image3 {
            data {
              attributes {
                url
              }
            }
          }
        }
        MediaUploadModal {
          dragAndDropText
          clickToUploadText
          maxLabel
          orLabel
          dropHereText
          uploadedText
          uploadingText
          cancelButton
          InitiatingUploadText
        }
        UserCard {
          coursesLabel
          overAllProgressLabel
        }
        SubscriptionInfo {
          title
          subscriptionInfoOnCost
          subscriptionInfoOnMembers
          subscriptionInfoForOnBoarding
          subscriptionInfoForTrialPeriod
          membersLabel
          trialLimitOverTitle
        }
        SubscriptionReminder {
          daysLeftLabel
          restartInfoText
          reminderInfoText
          clickHereLabel
        }
        SpeakerModalFallbackAvatar {
          data {
            attributes {
              url
            }
          }
        }
        ApplicationLevelErrorBoundary {
          title
          tryAgainLabel
          goBackLabel
        }
        ComponentLevelErrorBoundary {
          title
          Calendar {
            monthView
            upcomingEvents
          }
          Messages {
            channelList
            messagesList
            chatBubble
            messageDateSeparator
          }
          Notification {
            item
          }
        }
        SSO {
          appleLogin
          googleLogin
          orLabel
          usernamePasswordBasedAccountExistsMessage
          accountExistsWithExternalProviderMessage
          availableOnlyForIndependentLearnersMessage
          registrationIsInProgressWithUsernameMessage
        }
        featureFlags {
          enableUSPhoneNumberFormatting
          showLanguageOptions
        }
        newFeatureLabel
        Exception {
          CodeMismatchException
          ExpiredCodeException
          LimitExceededException
          TooManyFailedAttemptsException
          Custom_SomethingWentWrong
        }
        PasswordRequirements
        RichTextEditor {
          placeholder
          boldLabel
          italicLabel
          strikeLabel
          underlineLabel
          linkLabel
          resetLabel
          LinkEditForm {
            urlRequired
            updateBtnLabel
            removeBtnLabel
          }
        }
        RoleDescription {
          title
          roleDescriptions(pagination: { limit: 10 }) {
            description
            label
            slug
          }
        }
        Dialog {
          closeSRLabel
        }
        HelpAndResourcesWidget {
          triggerLabel
          contactHQSupportLabel
          contactHQSupportEmail
          raiseRequestToHQLabel
        }
        userDefaultProfilePicture {
          data {
            attributes {
              url
            }
          }
        }
        skipToMainContentLinkLabel
        SRPasswordToggle {
          showPasswordSRLabel
          hidePasswordSRLabel
        }
        Language {
          StrapiLanguageOptions {
            label
            value
          }
          languageLabel
          changeLanguageLabel
        }
        DurationLabels {
          toLabel
          minutesLabel
          hoursLabel
        }
        DropdownPlaceholderMessages {
          loadingMessage
          noOptionsMessage
        }
      }
    }
  }
`;
