import React from 'react';
import SideBarProfileInviteMember from './SideBarProfileInviteMember';
import SideBarProfileLogoutItem from './SideBarProfileLogoutItem';
import { SideBarProfileDialogType } from './SideBarProfileDialog';
import SideBarProfileContactHQ from './SideBarProfileContactHQ';
import HasAccess from '../../../features/shared/components/HasAccess';
import { USHG_HQ_APP_ROLE } from '../../../constants';

const SideBarProfileMenuItemWrapper = ({ children }: React.PropsWithChildren) => {
  return <div className="flex w-full flex-col items-stretch py-[2px] px-[6px]">{children}</div>;
};

type Props = {
  setDialogType: (val: SideBarProfileDialogType) => void;
  setIsDialogOpen: (o: boolean) => void;
};

const SideBarProfileMenu = (props: Props) => {
  const { setDialogType, setIsDialogOpen } = props;

  const onContactHQSelect = () => {
    setDialogType('raise-request-to-hq');
    setIsDialogOpen(true);
  };

  return (
    <div className="flex w-full flex-col items-start rounded-lg border border-zinc-700 shadow-[0px_12px_16px_-4px_rgba(16,24,40,0.08),_0px_4px_6px_-2px_rgba(16,24,40,0.03)]">
      <div className="flex w-full flex-col items-start rounded-lg bg-zinc-800">
        <div className="flex w-full flex-col items-stretch py-1 px-0">
          <SideBarProfileMenuItemWrapper>
            {/* only accessible to enterprise and independent users */}
            <HasAccess
              roles={[
                USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
                USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
                USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
                USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER,
                USHG_HQ_APP_ROLE.INDIVIDUAL_USER,
              ]}
              Fallback={null}
            >
              <SideBarProfileContactHQ onSelect={onContactHQSelect} />
            </HasAccess>
            <SideBarProfileInviteMember />
            <SideBarProfileLogoutItem />
          </SideBarProfileMenuItemWrapper>
        </div>
      </div>
    </div>
  );
};

export default SideBarProfileMenu;
