import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from '../../../../../../components/Buttons';
import Dialog from '../../../../../../components/Dialog';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { Toast } from '../../../../../../components/Toast';
import { useUpdateQuestionOrderMutation } from '../../../../hooks';
import { useStrapiHQAdminCoursesData } from '../../../../hooks/useStrapiHQAdminCourseData';
import { AssessmentQuestionListArgs } from '../../../../types';
import AssessmentQuestionList from './AssessmentQuestionList';
import AssessmentQuestionModal from './AssessmentQuestionModal/AssessmentQuestionModal';

interface AssessmentQuestionProps {
  asssessmentQuestionsQuery: Record<string, any>;
  assessmentId: number;
}

const AssessmentQuestion = ({
  asssessmentQuestionsQuery,
  assessmentId,
}: AssessmentQuestionProps) => {
  const [initialQuestionListState, setInitialQuestionListState] = useState<
    AssessmentQuestionListArgs[]
  >([]);

  const [assessmentQuestionsList, setAssessmentQuestionsList] = useState<
    AssessmentQuestionListArgs[]
  >([]);

  const [isAssessmentQuestionModalOpen, setIsAssessmentQuestionModalOpen] = useState(false);
  const {
    toastMessages: {
      updateQuestionOrderSuccessTitle,
      updateQuestionOrderSuccessMessage,
      updateQuestionOrderFailureTitle,
      updateQuestionOrderFailureMessage,
    },
  } = useStrapiHQAdminCoursesData();
  const [showSave, setShowSave] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);

  const resetQuestionOrder = () => {
    setAssessmentQuestionsList(
      initialQuestionListState.map((item, index) => {
        item.data.sortOrder = index + 1;
        return item;
      })
    );
  };

  // update question order mutation

  const updateQuestionOrder = useUpdateQuestionOrderMutation();

  const saveReOrder = () => {
    const reOrder: { id: string; sort: string }[] = assessmentQuestionsList.map((item) => ({
      id: `${item.data.questionId}`,
      sort: `${item.data.sortOrder}`,
    }));
    if (assessmentId)
      updateQuestionOrder.mutate(
        {
          courseModuleId: `${assessmentId}`,
          questions: reOrder,
        },
        {
          onError: (error) => {
            console.error('Error while updating assessment questions %o', error);
            resetQuestionOrder();
            toast.custom((t) => (
              <Toast
                variant="error"
                Title={updateQuestionOrderFailureTitle}
                SubTitle={updateQuestionOrderFailureMessage}
                toastInstance={t}
              />
            ));
          },
          onSuccess: () => {
            toast.custom((t) => {
              return (
                <Toast
                  variant="success"
                  Title={updateQuestionOrderSuccessTitle}
                  SubTitle={updateQuestionOrderSuccessMessage}
                  toastInstance={t}
                />
              );
            });
          },
          onSettled: () => {
            setShowSave(false);
            setReset(false);
            asssessmentQuestionsQuery.refetch();
          },
        }
      );
  };

  useEffect(() => {
    if (asssessmentQuestionsQuery.data) {
      const assessmentQuestionsList = asssessmentQuestionsQuery.data.map(
        (question: Record<string, any>, index: number) => ({
          id: index + 1,
          data: {
            questionId: question.questionId,
            sortOrder: question.sortOrder,
            question: question.question,
            answer: question.answer?.map((value: Record<string, any>) => {
              return {
                value: String(value.answerValue),
              };
            }),
            correctOption:
              question.answer?.findIndex(
                (value: Record<string, any>) => value.answerScore === '1'
              ) + 1,
            options: question.answer?.map((value: Record<string, any>) => {
              return {
                id: Number(value.answerId),
                value: String(value.answerValue),
                score: value.answerId,
              };
            }),
          },
        })
      );
      setInitialQuestionListState(assessmentQuestionsList);
      setAssessmentQuestionsList(assessmentQuestionsList);
    }
  }, [asssessmentQuestionsQuery.data]);

  useEffect(() => {
    if (reset) {
      resetQuestionOrder();
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    let isVisible = false;
    if (assessmentQuestionsList.length === initialQuestionListState.length)
      for (let i = 0; i < initialQuestionListState.length; ++i) {
        if (initialQuestionListState[i].id !== assessmentQuestionsList[i].id) {
          isVisible = true;
          break;
        }
      }
    setShowSave(isVisible);
  }, [assessmentQuestionsList]);
  const { singleAssessmentView, questionModal } = useStrapiHQAdminCoursesData();
  const { addQuestionTitle, addQuestionButton } = questionModal;
  const { addQuestion, title, resetButton, saveButton } = singleAssessmentView.Questions;

  return (
    <div className={`flex max-h-full flex-col rounded-sm bg-card-bg px-6 py-4`}>
      {/* Header Starts  */}
      <div className="flex flex-col justify-between gap-3 xs:flex-row">
        <p className="flex items-center justify-start font-sans text-base font-medium text-white">
          {title}
        </p>
        <div className="flex grow flex-col gap-3 xs:grow-0 xs:flex-row xs:justify-between">
          {!showSave && (
            <Dialog
              open={isAssessmentQuestionModalOpen}
              onOpenChange={(open) => setIsAssessmentQuestionModalOpen(open)}
              triggerClassName="flex grow xs:w-fit items-center gap-2 bg-base-brand px-4 py-3 text-xs font-medium text-white rounded justify-center"
              onInteractOutside={(e) => {
                // Donot close the Modal when we click outside the Dialog Content
                e.preventDefault();
              }}
              Content={
                <AssessmentQuestionModal
                  modalTitle={addQuestionTitle}
                  setIsAssessmentQuestionModalOpen={setIsAssessmentQuestionModalOpen}
                  formActionButtonLabel={addQuestionButton}
                  assessmentId={assessmentId}
                  modalType="add"
                  refetch={asssessmentQuestionsQuery.refetch}
                />
              }
            >
              {addQuestion}
            </Dialog>
          )}
          {showSave && (
            <Button
              className="flex grow items-center justify-center gap-2 rounded bg-zinc-900 px-12 py-3 text-xs font-medium text-white disabled:opacity-50 xs:w-fit"
              disabled={updateQuestionOrder.isLoading}
              onClick={() => setReset(true)}
            >
              {resetButton}
            </Button>
          )}
          {showSave && (
            <Button
              className="flex grow items-center justify-center gap-2 rounded bg-base-brand px-12 py-3 text-xs font-medium text-white disabled:opacity-50 xs:w-fit"
              onClick={saveReOrder}
              disabled={updateQuestionOrder.isLoading}
            >
              {saveButton}

              {updateQuestionOrder.isLoading && (
                <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
              )}
            </Button>
          )}
        </div>
      </div>

      {/* Assessment Questions Loaded */}
      {asssessmentQuestionsQuery.data && (
        <AssessmentQuestionList
          assessmentQuestionsList={assessmentQuestionsList}
          setAssessmentQuestionsList={setAssessmentQuestionsList}
          refetch={asssessmentQuestionsQuery.refetch}
        />
      )}

      {asssessmentQuestionsQuery.data.length === 0 && (
        <p className="m-auto my-10 text-sm font-normal text-zinc-100">
          No assessment question added yet. Create one from the button above!
        </p>
      )}
    </div>
  );
};

export default AssessmentQuestion;
