import clsx from 'clsx';
import { Warning } from 'phosphor-react';
import { twMerge } from 'tailwind-merge';
import { ERROR_TYPES } from '../../constants/error-types.constants';
import { useStrapiSharedData } from '../../features/shared/hooks/useStrapiSharedData';
import { BasicSpinner } from '../Spinners';

interface Props {
  refetch: () => void;
  isRefetching?: boolean;
  allowsRefetch?: boolean;
  className?: string;
  message: React.ReactNode;
  type?: string;
  displayWarningIcon?: boolean;
}

const ErrorDisplay = (props: Props) => {
  const {
    refetch,
    isRefetching,
    allowsRefetch,
    className,
    message,
    type,
    displayWarningIcon = false,
  } = props;
  const {
    tableProps: { retryButton },
  } = useStrapiSharedData();

  return (
    <div
      className={twMerge(
        'flex w-full flex-col items-center justify-center gap-6 px-1 text-base font-normal text-rose-200',
        className
      )}
    >
      {isRefetching && (
        <div className="flex justify-center">
          <BasicSpinner className="!m-0 text-zinc-50" />
        </div>
      )}
      {(type === ERROR_TYPES.SOMETHING_WENT_WRONG || displayWarningIcon) && <Warning size={32} />}
      <div className="text-center">{message}</div>
      {allowsRefetch && (
        <button
          className={clsx(
            'w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4 leading-[20px] text-zinc-50',
            isRefetching && 'opacity-90'
          )}
          onClick={() => refetch()}
          disabled={isRefetching}
        >
          {retryButton}
        </button>
      )}
    </div>
  );
};

export default ErrorDisplay;
