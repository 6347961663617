import { useNavigate } from 'react-router-dom';
import DataNotFound from '../../../../components/DataTables/DataNotFound';
import NoDataDisplay from '../../../../components/DataTables/NoDataDisplay';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import { useStrapiSharedData } from '../../../shared/hooks/useStrapiSharedData';
import {
  NO_TEAMS_AVAILABLE_DESCRIPTION,
  NO_TEAMS_AVAILABLE_TITLE,
} from '../../constants/enterpriseAdmin';

interface Props {
  isFilterApplied: boolean;
  className?: string;
}

const TeamsEmptyState = ({ isFilterApplied, className }: Props) => {
  const navigate = useNavigate();
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const clearFilters = () => {
    navigate('.');
  };
  const { clearAllFiltersButton } = useStrapiSharedData().tableProps;
  if (isFilterApplied) {
    return (
      <NoDataDisplay
        className={className}
        message={
          <div className="flex flex-col items-center gap-8">
            <span>{strapiErrorMessage['TEAMS_NOT_FOUND']}</span>
            <button
              className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
              onClick={() => clearFilters()}
            >
              {clearAllFiltersButton}
            </button>
          </div>
        }
      />
    );
  }

  return (
    <DataNotFound
      messageTitle={strapiErrorMessage[NO_TEAMS_AVAILABLE_TITLE]}
      messageDescription={strapiErrorMessage[NO_TEAMS_AVAILABLE_DESCRIPTION]}
    />
  );
};

export default TeamsEmptyState;
