import React from 'react';
import { formatNumber } from '../../../../../../utils';

interface Props {
  avgRatings: number;
}

const CourseAvgRatingsCell = ({ avgRatings }: Props) => {
  return <span className="whitespace-nowrap">{formatNumber(avgRatings, 1) || '-'}</span>;
};

export default CourseAvgRatingsCell;
