interface Props {
  jobTitle: string;
}

const JobTitleCell = (props: Props) => {
  const { jobTitle } = props;

  return <span className="text-xs font-normal text-zinc-100">{jobTitle}</span>;
};

export default JobTitleCell;
