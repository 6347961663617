import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { generateCDNMediaAssetURLWithKey } from '../../../shared/helpers';
import useStrapiBadgesData from '../../hooks/useStrapiBadgeData';
import { BADGE_TYPES } from '../../constants/badges';
import { Badge } from '../../types/badges';
import BadgeDetailsLayout from './BadgeDetailsLayout';
import React from 'react';
import Pluralize from 'pluralize';

type Props = {
  className?: string;
  badge: Badge;
  // we could remove this once every where we are prefetching the additional details for badge
  renderDefaultLayout?: boolean;
};

const BadgeDetailsContent = (props: Props) => {
  const { className, badge, renderDefaultLayout = false } = props;

  // Strapi

  const { singleBadge } = useStrapiBadgesData();
  const { HowToEarnTheBadgeInfo } = singleBadge;
  let {
    badgesInfoForSpecficCategory: badgesInfoForSpecificCategory,
    badgesInfoForCourseCompletion,
    badgesInfoForLearningStreaks,
  } = HowToEarnTheBadgeInfo;

  if (badge.badgeType === BADGE_TYPES.COURSE_ACCOMPLISHMENT) {
    badgesInfoForCourseCompletion = badgesInfoForCourseCompletion.replace(
      `{{courseCount}}`,
      badge.minimumCourse
    );
  }

  if (badge.badgeType === BADGE_TYPES.FOCUS_AREA) {
    badgesInfoForSpecificCategory = badgesInfoForSpecificCategory.replace(
      `{{courseCount}}`,
      badge.minimumCourse
    );
    badgesInfoForSpecificCategory = badgesInfoForSpecificCategory.replace(
      `{{category}}`,
      badge.categoryName
    );
  }

  if (badge.badgeType === BADGE_TYPES.LEARNING_STREAK) {
    badgesInfoForLearningStreaks = badgesInfoForLearningStreaks.replace(
      `{{minCount}}`,
      badge.minCount
    );
    badgesInfoForLearningStreaks = badgesInfoForLearningStreaks.replace(
      `{{unit}}`,
      Pluralize(badge.unit, badge.minCount)
    );
  }

  // Strapi

  // whether we want to surround the content with a wrapper
  const Wrapper = renderDefaultLayout ? BadgeDetailsLayout : React.Fragment;

  return (
    <Wrapper>
      <div className={twMerge(clsx('relative flex w-full flex-col gap-4', className))}>
        <div className="relative w-full overflow-y-scroll">
          <div>
            <div className="my-5 flex w-full flex-col items-center gap-5">
              <div className="w-max rounded-lg ">
                <img
                  src={generateCDNMediaAssetURLWithKey({ key: badge.imageUrl })}
                  className={`max-h-[108px] min-h-[108px] min-w-[108px] max-w-[108px] rounded-full  object-cover`}
                  alt={badge.badgeName}
                />
              </div>
              <div className="flex w-full flex-col justify-center gap-3 rounded-lg bg-zinc-900 p-6 text-start">
                <div className="text-base font-medium text-zinc-50">{badge.badgeName}</div>
                <div className="text-sm font-normal leading-5 text-zinc-400">
                  {badge.badgeDescription}
                </div>
              </div>
            </div>
            <div className="mt-6 text-sm font-medium text-zinc-200">
              {HowToEarnTheBadgeInfo.title}
            </div>
            {badge.badgeType === BADGE_TYPES.COURSE_ACCOMPLISHMENT && (
              <div
                className="mt-2 text-sm font-normal text-zinc-400"
                dangerouslySetInnerHTML={{ __html: badgesInfoForCourseCompletion }}
              ></div>
            )}
            {badge.badgeType === BADGE_TYPES.FOCUS_AREA && (
              <div
                className="mt-2 text-sm font-normal text-zinc-400"
                dangerouslySetInnerHTML={{ __html: badgesInfoForSpecificCategory }}
              ></div>
            )}
            {badge.badgeType === BADGE_TYPES.LEARNING_STREAK && (
              <div
                className="mt-2 text-sm font-normal text-zinc-400"
                dangerouslySetInnerHTML={{ __html: badgesInfoForLearningStreaks }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default BadgeDetailsContent;
