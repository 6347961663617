import React from 'react';
import { BasicSpinner } from '../Spinners';
import { twMerge } from 'tailwind-merge';
import cn from '../../utils/cn';

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type CustomProps = {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  isBorderless?: boolean;
  // to use custom loading element
  loadingElement?: React.ReactNode;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  contentWrapperClassName?: string;
};

type Props = ButtonProps & CustomProps;

const Button = ({
  className,
  children,
  disabled,
  loading = false,
  isBorderless = false,
  leadingIcon,
  trailingIcon,
  contentWrapperClassName,
  ...otherProps
}: React.PropsWithChildren<Props>) => {
  return (
    <button
      type="button"
      className={twMerge(
        `cursor-pointer shadow-[0px_0px_6px_rgba(0,0,0,0.08)]
        ${isBorderless ? 'bg-transparent p-0 pb-4 outline-none' : 'rounded px-4 py-3'}
        ${disabled ? 'cursor-not-allowed' : ''}
        ${className}`
      )}
      disabled={disabled}
      {...otherProps}
    >
      <div className={cn('flex items-center justify-center gap-x-4', contentWrapperClassName)}>
        {leadingIcon && <span>{leadingIcon}</span>}
        {loading ? (
          <BasicSpinner className="h-full text-white" />
        ) : (
          <>
            {children}
            {trailingIcon && <span>{trailingIcon}</span>}
          </>
        )}
      </div>
    </button>
  );
};
export default Button;
