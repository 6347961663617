export function excessItemsShortner(items: string[], threshold: number) {
  if (items.length > threshold) {
    let tempItems = [...items];
    tempItems = tempItems.slice(0, threshold);
    const excessCount = items.length - threshold;
    tempItems.push('+' + excessCount);
    return tempItems.toString();
  }
  return items.toString();
}
