import clsx from 'clsx';
import { Avatar } from 'react-daisyui';
import AcronymLogo from '../../../../../../components/AcronymnLogo';
import { excessItemsShortner } from '../../../../../../utils/excessItemsShortner';

interface AvatarAndNameCellProps {
  managers?: any;
  profilePictureURL?: string;
  className?: string;
}

const ManagersAvatarAndNameCell = (props: AvatarAndNameCellProps) => {
  const { managers, profilePictureURL, className } = props;

  // Use user picture url if present else use the name image fallback
  return (
    <div className={clsx('flex items-center gap-2 whitespace-nowrap', className)}>
      {profilePictureURL && (
        <Avatar
          src={profilePictureURL}
          size={24}
          className="rounded-full border border-zinc-900"
          shape="circle"
          role="img"
        ></Avatar>
      )}
      {!profilePictureURL && (
        <AcronymLogo
          className="h-6 w-6 rounded-full border border-zinc-900 text-xs"
          wordsStr={
            managers[0]?.fullName
              ? managers[0]?.fullName
              : managers[0]?.username
              ? managers[0]?.username.toUpperCase()
              : '-'
          }
        />
      )}
      <span>
        {excessItemsShortner(
          managers.map((x: any) => (x.fullName ? x.fullName : x.username)),
          1
        )}
      </span>
    </div>
  );
};

export default ManagersAvatarAndNameCell;
