import { useMemo, useState } from 'react';
import { BADGES_DEFAULT_PAGE_NUMBER, BADGES_DEFAULT_PAGE_SIZE } from '../constants/badges';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
import useStrapiBadgesData from '../hooks/useStrapiBadgeData';
import { BasicSpinner } from '../../../components/Spinners';
import clsx from 'clsx';
import BadgesErrorState from './BadgesErrorState';
import BadgeComponent from './BadgeComponent';
import { ErrorDisplay, NoDataDisplay, PaginationFooter } from '../../../components/DataTables';
import { ERROR_TYPES } from '../../../constants/error-types.constants';
import useGetUpNextBadgesQuery from '../hooks/useGetUpNextBadgesQuery';
import { useParams } from 'react-router-dom';

const UpNextBadges = () => {
  const { id } = useParams() ?? undefined;
  // Current Page
  const [page, setPage] = useState(BADGES_DEFAULT_PAGE_NUMBER);

  // Build the badge filters from the available filters
  const filters = useMemo(() => {
    return {
      offset: (page - 1) * BADGES_DEFAULT_PAGE_SIZE.UP_NEXT_BADGES,
      limit: BADGES_DEFAULT_PAGE_SIZE.UP_NEXT_BADGES,
      username: id,
    };
  }, [page, id]);

  const upNextBadges = useGetUpNextBadgesQuery(filters);

  const displayLoader =
    (upNextBadges.isLoading && !upNextBadges.data) ||
    (upNextBadges.isFetching && upNextBadges.data?.count === 0);
  const isEmptyState = upNextBadges.data && upNextBadges.data.count == 0;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const { errorMessages, myBadges } = useStrapiBadgesData();
  const { upNextBadges: upNextBadgesTitle } = myBadges;

  return (
    <div className="rounded-lg bg-[#252528] p-2">
      <p className="p-2 font-medium text-[#FFFFFF]">{upNextBadgesTitle.title}</p>
      {displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center py-4">
          <BasicSpinner className="text-white" />
        </div>
      )}
      {/* We have data */}
      {!upNextBadges.isLoading && upNextBadges.data && upNextBadges.data.count > 0 && (
        <div
          className={clsx(
            'relative flex w-full flex-col gap-4',
            upNextBadges.isPreviousData &&
              upNextBadges.isFetching &&
              'pointer-events-none opacity-70'
          )}
        >
          {upNextBadges.isPreviousData && upNextBadges.isFetching && (
            <div className="flex w-full justify-center">
              <BasicSpinner className="text-center text-white" />
            </div>
          )}

          <div className="relative w-full overflow-y-scroll">
            {upNextBadges.isError ? (
              <BadgesErrorState
                isRefetching={upNextBadges.isRefetching}
                refetch={upNextBadges.refetch}
              />
            ) : (
              <BadgeComponent data={upNextBadges.data.rows} showIfHasProgress={true} />
            )}
          </div>
          <PaginationFooter
            className="items-center justify-center"
            disablePageSizeSelector={true}
            activePage={page}
            totalItems={upNextBadges.data.count}
            setPage={setPage}
            pageSize={BADGES_DEFAULT_PAGE_SIZE.UP_NEXT_BADGES}
          />
        </div>
      )}

      {/* Empty State */}
      {isEmptyState && !displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <NoDataDisplay message={errorMessages.noUpNextBadges} />
        </div>
      )}

      {upNextBadges.isError && !upNextBadges.data && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <ErrorDisplay
            refetch={upNextBadges.refetch}
            isRefetching={upNextBadges.isRefetching}
            allowsRefetch={true}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            message={strapiErrorMessage['SYSTEM_ERROR']}
          />
        </div>
      )}
    </div>
  );
};

export default UpNextBadges;
