import topBlobSrc from '../../assets/Upperblob.png';
import bottomBlobSrc from '../../assets/Lowerblob.png';
type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const BlobIllustrator = (props: Props) => {
  return (
    <>
      <img
        src={topBlobSrc}
        alt=""
        className="absolute top-0 right-0 h-[calc(100vh-500px)]"
        {...props}
      />
      <img
        src={bottomBlobSrc}
        alt=""
        className="absolute bottom-0 left-0 h-[calc(100vh-500px)]"
        {...props}
      />
    </>
  );
};

export default BlobIllustrator;
