import { FallbackProps } from 'react-error-boundary';
import { ComponentLevelErrorBoundaryFallback } from '../../../../components/ErrorBoundary';
import { useStrapiSharedData } from '../../../shared/hooks/useStrapiSharedData';

const CalendarBodyErrorBoundaryFallback = ({ error }: FallbackProps) => {
  const {
    componentLevelErrorBoundary: { Calendar },
  } = useStrapiSharedData();

  return <ComponentLevelErrorBoundaryFallback error={error} description={Calendar.monthView} />;
};

export default CalendarBodyErrorBoundaryFallback;
