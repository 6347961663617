import React, { useEffect, useState } from 'react';
import Onboarding from './Onboarding';
import { OnboardingIllustration } from '../OnboardingIllustration';
import { STEPS } from '../../constants';
import SuccessIllustration from '../OnboardingIllustration/SuccessIllustration';
import { useReduxAuthState } from '../../../../hooks';
import { useNavigate } from 'react-router-dom';
import HelpWidget from '../../../shared/components/HelpWidget/HelpWidget';
import { Language, LanguageTrigger } from '../../../shared/components/Language';

const OnboardingBaseLayout = () => {
  const auth = useReduxAuthState();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState<string>(STEPS.WELCOME);

  useEffect(() => {
    if (auth.isAuthenticated && auth.user['custom:is_onboarded'] === 'true') {
      navigate('/dashboard');
    }
  }, []);

  return (
    <div className="min-h-screen w-full bg-page-bg-dark">
      {/* Language Toggle */}
      {/* Allows to switch the language */}
      <Language side="bottom" alignOffset={-20} triggerClassName="fixed top-5 right-10 z-50">
        <LanguageTrigger />
      </Language>
      {STEPS.ALL_DONE == currentStep ? (
        <SuccessIllustration>
          <div className="flex min-h-screen w-full items-center justify-center p-5">
            <Onboarding currentStep={currentStep} setCurrentStep={setCurrentStep} />
          </div>
        </SuccessIllustration>
      ) : (
        <OnboardingIllustration>
          <div className="flex min-h-screen w-full items-center justify-center p-5">
            <Onboarding currentStep={currentStep} setCurrentStep={setCurrentStep} />
          </div>
        </OnboardingIllustration>
      )}
      {/* Help Widget */}
      {/* Allow raise a request to hq admin since the user is authenticated */}
      <HelpWidget
        triggerClassName="fixed left-5 bottom-7 z-50"
        isAllowedToRaiseRequestToHQ={true}
      ></HelpWidget>
    </div>
  );
};
export default OnboardingBaseLayout;
