import { ArrowLeft } from 'phosphor-react';
import { Navbar } from 'react-daisyui';
import { useNavigate } from 'react-router-dom';
import { useStrapiOrganizationData } from '../../organisation/hooks/useStrapiOrganizationData';
import { capitaliseFirst } from '../utils/capitaliseFirst';
import '../../../styles/header.css';
interface Props {
  isSingleIndividual?: boolean;
  userFullName?: string;
}
function TopNavBar({ isSingleIndividual = false, userFullName }: Props) {
  const navigate = useNavigate();
  const { topNav, singleView } = useStrapiOrganizationData();
  return (
    <Navbar className="flex w-full items-center justify-between border-b border-b-zinc-800 bg-card-bg px-3 py-6 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
      <div className="flex w-full flex-row justify-between">
        <div className="header-title">
          <div className="flex-col">
            {isSingleIndividual && (
              <div
                className="cursor-pointer text-xs text-[#A1A1AA]"
                onClick={() => navigate(`/manage/individual/`)}
              >
                <ArrowLeft size={12} className="inline-block" /> {singleView.TopNav.backButton}
              </div>
            )}
            {userFullName ? capitaliseFirst(userFullName) : topNav.individualTitle}
          </div>
        </div>
      </div>
    </Navbar>
  );
}

export default TopNavBar;
