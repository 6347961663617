import React, { useEffect, useState } from 'react';
import { getTimeAgo } from '../../../../utils';
import { excessItemsShortner } from '../../../../utils/excessItemsShortner';
import { toTitleCaseKeys } from '../../../../utils/titleCaseConvertor';
import { useStrapiTeamData } from '../../hooks/useStrapiTeamData';
import { UserStatsResponse } from '../../types/interface';
import StatisticsCard from './StatisticsCard';
import { useLanguage } from '../../../shared/context/languageContext';

type StatisticsCardProps = {
  className?: string;
  stats?: UserStatsResponse;
};
const SinglePersonProfileStatistics = ({ className, stats }: StatisticsCardProps) => {
  const { language } = useLanguage();
  const [details, setDetails] = useState({});
  const { singlePerson } = useStrapiTeamData();
  const {
    teamLabel,
    managerlabel,
    loginRateLabel,
    badgesEarnedLabel,
    perWeekLabel,
    lastLoginLabel,
  } = singlePerson;
  useEffect(() => {
    const managersData =
      stats?.managers.length != 0
        ? stats?.managers.map((managers) => {
            if (managers.name) {
              return managers.name;
            } else {
              return managers.username;
            }
          })
        : 0;

    const timeDiff = stats?.lastLogin
      ? getTimeAgo(new Date(stats?.lastLogin as string), 'long', language)
      : '-';
    const userStatsValue = {
      [teamLabel]: stats?.teamName,
      [managerlabel]: managersData != 0 && managersData ? excessItemsShortner(managersData, 1) : 0,
      [loginRateLabel]: (stats?.loginsPastWeek ?? 0) + '/' + perWeekLabel,
      [lastLoginLabel]: timeDiff || '-',
      [badgesEarnedLabel]: stats?.badgesEarned || 0,
    };
    setDetails(toTitleCaseKeys(userStatsValue));
  }, []);

  return (
    <div className={className}>
      <div className="flex flex-wrap gap-4">
        <StatisticsCard profile={details} />
      </div>
    </div>
  );
};

export default SinglePersonProfileStatistics;
