import React from 'react';
import { Outlet } from 'react-router-dom';
import AuthIllustration from '../../../../components/AuthIllustration';
import { Navbar } from '../../../../components/Navbar';
import HelpWidget from '../../../shared/components/HelpWidget/HelpWidget';

const SetPasswordBaseLayout = () => {
  return (
    <div className="flex min-h-screen w-screen flex-col bg-page-bg-dark text-white">
      <Navbar></Navbar>
      <div className="grid h-full w-full grow grid-cols-1 grid-rows-1 justify-between p-5 xl:grid-cols-[2.5fr,2fr] xl:p-0">
        <Outlet />
        <section className="hidden h-full grow justify-end xl:flex">
          <AuthIllustration></AuthIllustration>
        </section>
      </div>
      <HelpWidget triggerClassName="fixed left-5 bottom-7 z-50"></HelpWidget>
    </div>
  );
};

export default SetPasswordBaseLayout;
