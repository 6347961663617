import { gql } from '@apollo/client';

export const DashboardFragment = gql`
  fragment DashboardFragment on DashboardEntityResponse {
    data {
      attributes {
        COMMON {
          ... on ComponentDashboardQuoteList {
            quotes(pagination: { limit: 100 }) {
              quote
              quoteAuthor
            }
          }
          ... on ComponentDashboardCalendar {
            upcomingEventsLabel
            deadlineLabel
            errorLoadingDetails
            errorLoadingCalendar
            errorLoadingUpcomingEvents
            noUpcomingEventsMessage
            days {
              monday
              tuesday
              wednesday
              thursday
              friday
              saturday
              sunday
            }
          }
          ... on ComponentDashboardDashboardCards {
            continueYourLastCourseLabel
            newCourseRecommendedToYouLabel
            hoursLabel
          }
          ... on ComponentDashboardContinueCourses {
            title
            emptyMessage
            errorMessage
          }
          ... on ComponentDashboardExploreCourses {
            startYourJourneyLabel
            title
            description
            exploreAllCoursesImg {
              data {
                attributes {
                  url
                }
              }
            }
          }
          ... on ComponentDashboardRecommendedCourses {
            title
            emptyMessage
            errorMessage
          }
          ... on ComponentDashboardSalutation {
            morningSalutation
            eveningSalutation
            afternoonSalutation
            agenda
          }
          ... on ComponentDashboardSizzleVideo {
            dashboardSizzleVideo {
              data {
                attributes {
                  url
                  size
                  alternativeText
                  previewUrl
                }
              }
            }
            dashboardSizzlePoster {
              data {
                attributes {
                  url
                }
              }
            }
            hideVideoBottom
            playVideoSRLabel
            pauseVideoSRLabel
            muteVideoSRLabel
            unmuteVideoSRLabel
          }
        }
      }
    }
  }
`;
