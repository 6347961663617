import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Divider from '../../../../../../../components/Divider/Divider';
import { useAppSelector } from '../../../../../../../hooks';
import { RootState } from '../../../../../../../store';
import { useInitBulkInviteUserMutation } from '../../../../../../members/hooks';
import { getSubscriptionDetails } from '../../../../../../settings/api';
import { setSubscriptionData } from '../../../../../../shared/slices/subscription.slice';
import { useInviteMembersMutation } from '../../../../../hooks';
import { useStrapiOnBoardingData } from '../../../../../hooks/useStrapiOnBoardingData';
import { clearTeamsAndLeaderData } from '../../../../../slices/onboarding.slice';
import { RequestType } from '../../../../../types';
import { StepperControl } from '../../../../Stepper';
import BulkUpload from './BulkUpload';
import Leaders from './Leaders';
import Teams from './Teams';

interface InviteMembersProps {
  currentStep: string;
  setCurrentStep: any;
  steps: string[];
}

// state selectors
const onboardingStateSelector = (state: RootState) => state.onboarding;

const InviteMembers = ({ currentStep, setCurrentStep, steps }: InviteMembersProps) => {
  const dispatch = useDispatch();
  const { teams, leaders } = useAppSelector(onboardingStateSelector);

  const { inviteMember } = useStrapiOnBoardingData();
  const { orLabel } = inviteMember;

  // Create Invite Members Mutation
  const inviteMembersMutation = useInviteMembersMutation();

  // Bulk Upload File ID
  const [bulkUploadId, setBulkUploadId] = useState<string | null>(null);
  const bulkInviteUserMutation = useInitBulkInviteUserMutation({ isOnboarding: true });

  // Bulk Upload Helpers

  const initBulkInvite = async () => {
    if (!bulkUploadId) {
      return;
    }

    await bulkInviteUserMutation.mutateAsync({
      uploadId: bulkUploadId,
    });
  };

  // Bulk Upload Helpers

  const stepperPrevControl = () => {
    let currIndex = steps.indexOf(currentStep);
    const newStep = --currIndex;
    if (newStep !== -1) {
      setCurrentStep(steps[newStep]);
    }
  };

  const subscriptionDetails = async () => {
    const subscriptionDetails = await getSubscriptionDetails();
    if (subscriptionDetails) {
      dispatch(setSubscriptionData(subscriptionDetails));
    }
  };

  useEffect(() => {
    subscriptionDetails();
  }, []);

  const skipControl = () => {
    let currIndex = steps.indexOf(currentStep);

    const newStep = ++currIndex;

    if (newStep <= steps.length - 1) {
      setCurrentStep(steps[newStep]);
    }
  };

  // Invite Members

  const inviteMembers = async () => {
    const params = {
      team: teams,
      leader: leaders,
      requestType: RequestType.NEW_TEAM,
    };

    await inviteMembersMutation.mutateAsync(params);
  };

  const hasUploadedBulkInviteFile = bulkUploadId !== null;
  const hasAddedTeamsOrLeaderInformation = teams.length > 0 || leaders.length > 0;

  const stepperNextControl = async () => {
    try {
      if (hasAddedTeamsOrLeaderInformation) {
        await inviteMembers();
        dispatch(clearTeamsAndLeaderData());
      }

      if (hasUploadedBulkInviteFile) {
        await initBulkInvite();
        // clear bulk upload id if the upload is successful
        setBulkUploadId(null);
      }

      let currIndex = steps.indexOf(currentStep);

      const newStep = ++currIndex;

      if (newStep <= steps.length - 1) {
        setCurrentStep(steps[newStep]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // If there is a new team information/leader information
  // or there is a bulk upload id
  // We can show the next action button
  const showNextActionButton = hasAddedTeamsOrLeaderInformation || hasUploadedBulkInviteFile;

  return (
    <main
      id="main-content"
      className="flex grow flex-col items-center justify-center gap-5 p-4 px-0 sm:px-2 lg:px-4"
    >
      <Teams />
      <Leaders />
      <Divider text={orLabel} className="py-5" textClassName="text-zinc-100 text-[10px]" />
      <BulkUpload
        setUploadId={setBulkUploadId}
        isError={bulkInviteUserMutation.isError}
        error={bulkInviteUserMutation.error as Error}
      />
      {/* navigation button */}
      <StepperControl
        isStepLoading={false}
        aria-label="Invite Members Step controls"
        skipControl={skipControl}
        stepperPrevControl={stepperPrevControl}
        stepperNextControl={stepperNextControl}
        currentStep={currentStep}
        isNextStepLoading={inviteMembersMutation.isLoading || bulkInviteUserMutation.isLoading}
        doNotShowNext={!showNextActionButton}
      />
    </main>
  );
};
export default InviteMembers;
