import AlertDialog from '../../../../../../../../../components/AlertDialog';
import { STRAPI_PLACEHOLDER_CODE } from '../../../../../../../../../constants/strapi';
import { useAdminDisableMFAForUserMutation } from '../../../../../../../hooks';
import useStrapiMembersData from '../../../../../../../hooks/useStrapiMembersData';

interface Props {
  username: string;
  closeDialog: () => void;
}

const DisableMFAForUserDialogContent = (props: Props) => {
  const { username, closeDialog } = props;
  const adminDisableMFAForUserMutation = useAdminDisableMFAForUserMutation();

  // strapi

  const { disableMFAUserDialog: disableMFAUserCMS } = useStrapiMembersData();
  const content = disableMFAUserCMS.content.replace(STRAPI_PLACEHOLDER_CODE.EMAIL, username);

  // strapi

  const handleDisableMFAForUser = async (params: { username: string }) => {
    try {
      const { username } = params;
      await adminDisableMFAForUserMutation.mutateAsync({
        username,
      });
      closeDialog();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AlertDialog
      Title={disableMFAUserCMS.title}
      Content={content}
      actionItem={{
        label: disableMFAUserCMS.confirmLabel,
        action: async () => {
          await handleDisableMFAForUser({ username });
        },
      }}
      cancelItem={{
        label: disableMFAUserCMS.cancelLabel,
      }}
      isLoading={adminDisableMFAForUserMutation.isLoading}
      isActionDiabled={adminDisableMFAForUserMutation.isLoading}
    ></AlertDialog>
  );
};

export default DisableMFAForUserDialogContent;
