import { MostEarnedBadgesType, PartialMostEarnedBadgesFilter } from '../types/interface';
import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import qs from 'qs';
import { GET_TOP_EARNED_BADGES_PATH } from '../constants';

const getMostEarnedBadges = async (
  badgesQueryParam: PartialMostEarnedBadgesFilter
): Promise<{ badges: MostEarnedBadgesType[]; total: number }> => {
  const path = GET_TOP_EARNED_BADGES_PATH;
  const queryString = qs.stringify(badgesQueryParam);
  try {
    const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
      response: true,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export { getMostEarnedBadges };
