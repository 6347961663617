import React, { useEffect, useState } from 'react';
import Dialog from '../../../../../components/Dialog';
import { VideoController } from '../../../../../components/MediaControls';
import { BasicSpinner } from '../../../../../components/Spinners';
import { PDFViewer } from '../../../../courses/components/FileViewers';

import useDownloadPreSignedQuery from '../../../../courses/hooks/useDownloadPreSignedQuery';
import { generateCDNMediaAssetURLWithKey } from '../../../../shared/helpers';
import { useStrapiHQAdminCoursesData } from '../../../hooks/useStrapiHQAdminCourseData';
import ModuleAssessmentModal from '../../CoursesPage/components/ModuleAssessment/ModuleAssessmentModal';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../constants/user';
import { useModuleBasicDetailsQuery } from '../../../hooks';
import { COURSES_ERROR_CODE } from '../../../constants/courses-management';

interface ModuleBasicDetailsProps {
  moduleBasicDetailsQuery: Record<string, any>;
}

const ModuleBasicDetails = ({ moduleBasicDetailsQuery }: ModuleBasicDetailsProps) => {
  const [isEditModuleModalOpen, setIsEditModuleModalOpen] = useState(false);
  const [isManageModuleModalOpen, setIsManageModuleModalOpen] = useState(false);
  const [currentMedia, setCurrentMedia] = useState<string | null>(null);

  const [buttonLabel, setButtonLabel] = useState('');

  const { moduleModal, singleModuleView } = useStrapiHQAdminCoursesData();
  const { addButton, editButton, editModuleTitle, manageLanguagesTitle } = moduleModal;
  const {
    speakerLabel,
    descriptionLabel,
    mediaLabel,
    nameLabel,
    thumbnailLabel,
    moduleTypeLabel,
    editModuleButton,
    title,
  } = singleModuleView;

  const {
    language: { StrapiLanguageOptions },
  } = useStrapiSharedData();

  // Get pre signed media url whenever state changes
  const downloadPreSignedQuery = useDownloadPreSignedQuery({
    key: moduleBasicDetailsQuery.data.previewUrl,
  });

  useEffect(() => {
    if (downloadPreSignedQuery.data && downloadPreSignedQuery.data) {
      setCurrentMedia(downloadPreSignedQuery.data.url);
    }
  }, [downloadPreSignedQuery.data]);

  // Map `label` to `display` for Select component with null check
  const mappedOptions =
    StrapiLanguageOptions?.map((option: { label: string; value: string }) => ({
      display: option.label,
      value: option.value,
    })) || [];

  // Find the next language to English
  const nextLanguage = mappedOptions.find(
    (option: { value: string }) => option.value !== DEFAULT_PREFERRED_LANGUAGE
  );

  const [selectedLanguage, setSelectedLanguage] = useState(nextLanguage ? nextLanguage.value : '');

  const handleLanguageChange = (newLanguage: string) => {
    setSelectedLanguage(newLanguage);
  };

  const moduleDetails = useModuleBasicDetailsQuery({
    moduleId: moduleBasicDetailsQuery.data.id,
    language: selectedLanguage,
    isEnabled: isManageModuleModalOpen,
  });

  useEffect(() => {
    if (moduleDetails.data || moduleDetails.errorType) {
      if (
        !moduleDetails.data &&
        moduleDetails.errorType === COURSES_ERROR_CODE.MODULE_ID_NOT_FOUND
      ) {
        setButtonLabel(addButton);
      } else {
        setButtonLabel(editModuleButton);
      }
    }
  }, [moduleDetails.data, moduleDetails.errorType, addButton, editModuleButton]);

  return (
    <div className="w-full rounded-sm bg-card-bg p-4">
      {/* Header Starts  */}
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col items-start justify-between gap-4 xs:flex-row xs:items-center">
          <p className="font-sans text-base font-medium text-white">{title}</p>
          <div className="flex gap-4">
            <Dialog
              open={isManageModuleModalOpen}
              onOpenChange={(open) => setIsManageModuleModalOpen(open)}
              triggerClassName="flex justify-start xs:justify-end basis-0 grow-0"
              onInteractOutside={(e) => {
                // Donot close the Modal when we click outside the Dialog Content
                e.preventDefault();
              }}
              Content={
                <ModuleAssessmentModal
                  modalType="manage"
                  setIsModuleModalOpen={setIsManageModuleModalOpen}
                  modalTitle={manageLanguagesTitle}
                  modulesAssessmentPrefetchData={
                    moduleDetails.errorType ? moduleBasicDetailsQuery : moduleDetails
                  }
                  formActionButtonLabel={buttonLabel}
                  refetch={moduleDetails.refetch}
                  showLanguageDropdown={true}
                  mappedOptions={mappedOptions}
                  selectedLanguage={selectedLanguage}
                  handleLanguageChange={handleLanguageChange}
                  errorType={moduleDetails.errorType}
                  setErrorType={moduleDetails.setErrorType}
                />
              }
            >
              <span className="whitespace-nowrap rounded bg-zinc-900 py-3 px-4 text-xs font-normal text-zinc-200">
                {manageLanguagesTitle}
              </span>
            </Dialog>
            {moduleBasicDetailsQuery.data && (
              <Dialog
                open={isEditModuleModalOpen}
                onOpenChange={(open) => setIsEditModuleModalOpen(open)}
                triggerClassName="flex justify-start xs:justify-end basis-0 grow-0"
                onInteractOutside={(e) => {
                  // Donot close the Modal when we click outside the Dialog Content
                  e.preventDefault();
                }}
                Content={
                  <ModuleAssessmentModal
                    modalType="update"
                    setIsModuleModalOpen={setIsEditModuleModalOpen}
                    modalTitle={editModuleTitle}
                    modulesAssessmentPrefetchData={moduleBasicDetailsQuery}
                    formActionButtonLabel={editButton}
                    refetch={moduleBasicDetailsQuery.refetch}
                  />
                }
              >
                <span className="whitespace-nowrap rounded bg-zinc-900 py-3 px-4 text-xs font-normal text-zinc-200">
                  {editModuleButton}
                </span>
              </Dialog>
            )}
          </div>
        </div>
        {/* Header Ends  */}

        {/* Assessment Details Loaded */}
        {moduleBasicDetailsQuery.data && (
          <>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-medium text-zinc-400">{nameLabel}</p>
                <p className="text-sm font-medium text-zinc-50">
                  {moduleBasicDetailsQuery?.data?.name}
                </p>
              </div>
              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-medium text-zinc-400">{moduleTypeLabel}</p>
                <p className="text-sm font-medium text-zinc-50">
                  {moduleBasicDetailsQuery?.data?.type}
                </p>
              </div>

              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-medium text-zinc-400">{speakerLabel}</p>
                {moduleBasicDetailsQuery.data?.speakers?.length > 0 ? (
                  <div className="flex flex-wrap gap-2">
                    {moduleBasicDetailsQuery.data?.speakers?.map(
                      (speaker: Record<string, any>, index: number) => (
                        <span key={index} className="text-sm font-medium text-zinc-50">
                          {speaker.name}
                          {moduleBasicDetailsQuery.data?.speakers &&
                            index < moduleBasicDetailsQuery.data?.speakers?.length - 1 &&
                            ', '}
                        </span>
                      )
                    )}
                  </div>
                ) : (
                  <p className="text-sm font-medium text-zinc-50">-</p>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-y-1">
              <p className="text-sm font-medium text-zinc-400">{descriptionLabel}</p>
              <p className="text-sm font-medium text-zinc-50">
                {moduleBasicDetailsQuery.data?.description}
              </p>
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {/* Thumbnail Image */}
              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-medium text-zinc-400">{thumbnailLabel}</p>
                {moduleBasicDetailsQuery.data?.thumbnailUrl ? (
                  <img
                    className="h-[15.625rem] w-80 object-cover"
                    src={generateCDNMediaAssetURLWithKey({
                      key: moduleBasicDetailsQuery.data?.thumbnailUrl,
                    })}
                    alt="Module Thumbnail Image"
                  />
                ) : (
                  <p className="text-sm font-medium text-zinc-50">-</p>
                )}
              </div>
              {/* Media */}
              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-medium text-zinc-400">{mediaLabel}</p>
                {moduleBasicDetailsQuery.data?.previewUrl ? (
                  moduleBasicDetailsQuery.data.type === 'VIDEO' ? (
                    <div className="flex h-[15.625rem]">
                      {downloadPreSignedQuery.isLoading || !currentMedia ? (
                        <div className="flex h-full w-full flex-col items-center justify-center object-contain text-zinc-50">
                          <BasicSpinner />
                        </div>
                      ) : (
                        <VideoController
                          className="aspect-video !h-full max-h-[15.625rem] w-max"
                          thumbnail={generateCDNMediaAssetURLWithKey({
                            key: moduleBasicDetailsQuery.data.thumbnailUrl,
                          })}
                          closedcaptions={moduleBasicDetailsQuery.data.closedCaptionsUrls}
                          video={currentMedia}
                        />
                      )}
                    </div>
                  ) : moduleBasicDetailsQuery.data.type === 'AUDIO' ? (
                    <div className="flex h-[15.625rem]">
                      {downloadPreSignedQuery.isLoading || !currentMedia ? (
                        <div className="flex h-full w-full flex-col items-center justify-center object-contain text-zinc-50">
                          <BasicSpinner />
                        </div>
                      ) : (
                        <VideoController
                          className="aspect-video !h-full max-h-[15.625rem] w-max"
                          thumbnail={generateCDNMediaAssetURLWithKey({
                            key: moduleBasicDetailsQuery.data.thumbnailUrl,
                          })}
                          closedcaptions={moduleBasicDetailsQuery.data.closedCaptionsUrls}
                          video={currentMedia}
                        />
                      )}
                    </div>
                  ) : moduleBasicDetailsQuery.data.type === 'DOCUMENT' ? (
                    <div className="h-full w-full">
                      {downloadPreSignedQuery.isLoading || !currentMedia ? (
                        <div className="flex h-full w-full flex-col items-center justify-center object-contain text-zinc-50">
                          <BasicSpinner />
                        </div>
                      ) : (
                        <PDFViewer name={moduleBasicDetailsQuery.data.name} src={currentMedia} />
                      )}
                    </div>
                  ) : (
                    '-'
                  )
                ) : (
                  <p className="text-sm font-medium text-zinc-50">-</p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModuleBasicDetails;
