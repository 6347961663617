import { useCallback, useMemo } from 'react';
import { MFAMethod } from '../../../../../../constants';
import { STRAPI_PLACEHOLDER_CODE } from '../../../../../../constants/strapi';
import { useReduxAuthState } from '../../../../../../hooks';
import { removeCountryCodeFromPhoneNumber } from '../../../../../../utils';
import { useSetUserPreferredMFAMutation } from '../../../../../shared/hooks';
import { useStrapiSharedData } from '../../../../../shared/hooks/useStrapiSharedData';
import { useStrapiSettingsData } from '../../../../hooks/useStrapiSettingsData';
import ActionDialogContent from '../../components/ActionDialogContent';

type Props = {
  setShowDialog: (show: boolean) => void;
};

const EnableMFADialogContent = (props: Props) => {
  const { setShowDialog } = props;

  //   redux state
  const auth = useReduxAuthState();
  //   redux state

  //   Mutations
  const setUserPreferredMFAMutation = useSetUserPreferredMFAMutation();
  //   Mutations

  // strapi content

  const { featureFlags } = useStrapiSharedData();
  const { enableUSPhoneNumberFormatting = true } = featureFlags ?? {};

  const {
    mfa: { EnableMFADialog: enableMFADialogCMS },
  } = useStrapiSettingsData();

  const smsMFAInstructionsParts = useMemo(() => {
    const parts = enableMFADialogCMS.smsMFAInstructions.split(STRAPI_PLACEHOLDER_CODE.PHONE_NUMBER);
    return parts;
  }, [enableMFADialogCMS.smsMFAInstructions]);

  // strapi content

  //   derived state
  const phoneNumber =
    auth.user?.phone_number && enableUSPhoneNumberFormatting
      ? removeCountryCodeFromPhoneNumber(auth.user?.phone_number)
      : auth.user?.phone_number;
  //   derived state

  //   actions

  const cancelAction = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const enableSMSMFA = useCallback(async () => {
    try {
      await setUserPreferredMFAMutation.mutateAsync({
        mfaMethod: MFAMethod.SMS_MFA,
      });

      setShowDialog(false);
    } catch (error) {
      console.error('Error configuring MFA', error);
    }
  }, [setShowDialog, setUserPreferredMFAMutation]);

  //   actions

  return (
    <ActionDialogContent
      heading={enableMFADialogCMS.heading}
      Description={enableMFADialogCMS.description}
      Content={
        <>
          <span>{smsMFAInstructionsParts[0]}</span>
          <span className="font-semibold">{phoneNumber}</span>
          <span>{smsMFAInstructionsParts[1]}</span>
        </>
      }
      primaryAction={{
        handler: enableSMSMFA,
        label: enableMFADialogCMS.enableMFABtnLabel,
        isLoading: setUserPreferredMFAMutation.isLoading,
      }}
      secondaryAction={{
        label: enableMFADialogCMS.cancelBtnLabel,
        handler: cancelAction,
      }}
    ></ActionDialogContent>
  );
};

export default EnableMFADialogContent;
