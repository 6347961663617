import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { SETTING_API_PATHS } from '../constants';
import { GetUserNotificationPreferencesResponse } from '../types';
import {
  UpdateUserNotificationPreferencesResponse,
  UserNotificationPreferences,
} from './../types/api';

/**
 * Fetch user notification preferences.
 */
const getUserNotificationPreferences =
  async (): Promise<GetUserNotificationPreferencesResponse> => {
    const path = SETTING_API_PATHS.GET_USER_NOTIFICATION_PREFERENCES;

    const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
      withCredentials: true,
      response: true,
    });

    return response.data;
  };

interface UpdateUserNotificationPreferencesArgs {
  notificationPreferences: UserNotificationPreferences;
}

/**
 * Update user notification preferences.
 */
const updateUserNotificationPreferences = async (
  args: UpdateUserNotificationPreferencesArgs
): Promise<UpdateUserNotificationPreferencesResponse> => {
  const path = SETTING_API_PATHS.UPDATE_USER_NOTIFICATION_PREFERENCES;

  const response = await API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body: args,
    withCredentials: true,
    response: true,
  });

  return response.data;
};

export { getUserNotificationPreferences, updateUserNotificationPreferences };
export type { UpdateUserNotificationPreferencesArgs };
