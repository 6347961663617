import { useMutation } from '@tanstack/react-query';
import { Info } from 'phosphor-react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Button } from '../../../../../components/Buttons';
import Dialog from '../../../../../components/Dialog';
import { BasicSpinner } from '../../../../../components/Spinners';
import { Toast } from '../../../../../components/Toast';
import { queryClient } from '../../../../../config/react-query';
import { useAppSelector } from '../../../../../hooks';
import { RootState } from '../../../../../store';
import { FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY } from '../../../../shared/constants';
import { BILLING_HISTORY_QUERY_KEY } from '../../../../shared/constants/billings';
import { changePlan } from '../../../api';

import {
  LICENSES,
  MAX_LICENCE_CAN_BE_ADDED,
  MIN_LICENCE_CAN_BE_ADDED,
} from '../../../constants/settings';
import { useStrapiSettingsData } from '../../../hooks/useStrapiSettingsData';
import { ChangePlan } from '../../../types';
const subscriptionState = (state: RootState) => state.subscription.subscriptionData;

type Inputs = {
  extraLicenses: string;
};
interface Props {
  setIsModalOpen: (open: boolean) => void;
}
const ManageActiveLicence = ({ setIsModalOpen }: Props) => {
  const subscription = useAppSelector(subscriptionState);
  const { manageActiveLicence } = useStrapiSettingsData();
  const toggleModal = (status: boolean) => {
    setIsModalOpen(status);
    reset();
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      extraLicenses: '',
    },
  });
  const refetchSubscriptionDetails = async () => {
    await queryClient.refetchQueries([FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY]);
  };
  const refetchInvoices = async () => {
    await queryClient.refetchQueries({ queryKey: [BILLING_HISTORY_QUERY_KEY] });
  };
  const { mutate, isLoading } = useMutation({
    mutationFn: async (args: ChangePlan) => {
      const response = await changePlan(args);
      return response;
    },
    onSuccess: async () => {
      toast.custom(
        (t) => (
          <Toast variant="success" Title={manageActiveLicence.onSuccessMessage} toastInstance={t} />
        ),
        { id: 'CHANGE_PLAN_TOAST_' + Math.floor(Math.random() * 3000).toString() }
      );
      await Promise.all([refetchInvoices(), refetchSubscriptionDetails()]);
      toggleModal(false);
    },
    onError: () => {
      toast.custom(
        (t) => (
          <Toast variant="error" Title={manageActiveLicence.onErrorMessage} toastInstance={t} />
        ),
        { id: 'CHANGE_PLAN_TOAST_' + Math.floor(Math.random() * 3000).toString() }
      );
      toggleModal(false);
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const payload: ChangePlan = {
      licenses: (subscription?.totalLicenses as number) + Number(data.extraLicenses),
      type: LICENSES,
    };
    mutate(payload);
  };

  return (
    <div className="mx-1 flex flex-col gap-2 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <Dialog.Title className="mb-6 text-lg font-semibold leading-[24px] text-white">
        {manageActiveLicence.title}
      </Dialog.Title>
      <div className="mx-3">
        <div className="grid grid-cols-2 gap-4">
          <div className="rounded-lg bg-zinc-900 p-4">
            <p className="text-xs font-medium text-zinc-300">
              {' '}
              {manageActiveLicence.activeLicence}
            </p>
            <p className="text-[28px] font-bold text-zinc-50">{subscription?.activeLicenses}</p>
          </div>
          <div className="rounded-lg bg-zinc-900 p-4">
            <p className="text-xs font-medium text-zinc-300">{manageActiveLicence.totalLicence}</p>
            <p className="text-[28px] font-bold text-zinc-50">{subscription?.totalLicenses}</p>
          </div>
        </div>
        <form className="mt-5 w-full">
          <div className="-mx-3 mb-6 flex flex-wrap">
            <div className="w-full px-3">
              <label
                className="mb-2 block text-xs normal-case tracking-wide text-zinc-400"
                htmlFor="grid-manager-email"
              >
                {manageActiveLicence.addMoreLicence}
              </label>
              <input
                className="block h-9 w-full appearance-none rounded border border-zinc-900 bg-zinc-900 pl-2 pt-[5px] text-white placeholder:text-xs"
                id="grid-manager-email"
                type="number"
                min="1"
                max="50"
                placeholder={manageActiveLicence.manageActiveLicensesPlaceholder}
                {...register('extraLicenses', {
                  required: true,
                  validate: {
                    validRange: (value) =>
                      Math.round(Number(value)) > MIN_LICENCE_CAN_BE_ADDED &&
                      Math.round(Number(value)) < MAX_LICENCE_CAN_BE_ADDED,
                  },
                })}
              />
              {errors.extraLicenses && errors.extraLicenses.type === 'required' && (
                <span className="text-xs text-red-600">{manageActiveLicence.inputFieldError}</span>
              )}
              {errors.extraLicenses && errors.extraLicenses.type === 'validRange' && (
                <span className="text-xs text-red-600">
                  {manageActiveLicence.inputFieldRangeError}
                </span>
              )}
            </div>
          </div>
        </form>
        <div className="flex rounded border border-zinc-700 p-4">
          <div className="flex-none">
            <Info className="text-zinc-100" />
          </div>
          <div className="w-full flex-auto px-4 text-sm font-normal text-zinc-300">
            {manageActiveLicence.warningMessage}
          </div>
        </div>
        <div className="mt-5 flex justify-end">
          <Dialog.Close className="px-7 text-zinc-50">
            {manageActiveLicence.cancelButtonText}
          </Dialog.Close>
          <Button
            type="submit"
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
            className="btn inline-block h-[40px] !rounded bg-base-brand px-6 py-2 text-sm font-medium normal-case leading-tight text-white hover:bg-base-brand disabled:bg-base-brand disabled:text-white"
          >
            {isLoading ? (
              <div className="flex gap-2">
                {manageActiveLicence.submitButtonText}
                <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
              </div>
            ) : (
              manageActiveLicence.submitButtonText
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ManageActiveLicence;
