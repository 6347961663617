import BillingHistoryMain from '../../../../shared/components/BillingHistory/BillingHistoryTableMain';
import { useStrapiSettingsData } from '../../../hooks/useStrapiSettingsData';

function BillingHistory() {
  const { billingHistory } = useStrapiSettingsData();
  return (
    <div className="mt-3">
      <h2 className="mb-2 text-xl font-bold text-white">{billingHistory.title}</h2>
      <BillingHistoryMain />
    </div>
  );
}

export default BillingHistory;
