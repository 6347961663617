import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useStrapiSharedData } from '../../features/shared/hooks/useStrapiSharedData';

interface PageLevelErrorBoundaryFallbackProps {
  error: Error;
  className?: string;
}

const PageLevelErrorBoundaryFallback = ({
  error,
  className,
}: PageLevelErrorBoundaryFallbackProps) => {
  const {
    applicationLevelErrorBoundary: { title, tryAgainLabel, goBackLabel },
  } = useStrapiSharedData();

  console.error('Error catched by Page Level Error Boundary', error);

  return (
    <div className={twMerge('flex w-full grow justify-center p-4', className)}>
      <div className="flex max-w-xl flex-col gap-2 rounded-xl border border-zinc-700 bg-component-bg-dark p-[30px] shadow-md">
        <h3 className="text-2xl font-bold tracking-tight text-zinc-50">{title}</h3>
        <div className="flex w-full flex-col gap-2 text-base font-normal text-zinc-400">
          <span>{tryAgainLabel}</span>
          <Link className="cursor-pointer underline underline-offset-2" to={-1 as any}>
            {goBackLabel}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PageLevelErrorBoundaryFallback;
