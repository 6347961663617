import { X } from 'phosphor-react';
import { Filter, filterType } from '../../features/courses/types/Filter.Helper';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';

type BadgeRemovableProps = {
  filter: filterType;
  filterRemoveHandler: any;
  tooltipOnHover?: boolean;
  tooltipContentClassName?: string;
};

const BadgeRemovable = ({
  filter,
  filterRemoveHandler,
  tooltipContentClassName,
  tooltipOnHover = false,
}: BadgeRemovableProps) => {
  return (
    <TooltipWrapper
      content={filter.value}
      enabled={tooltipOnHover}
      contentClassName={tooltipContentClassName}
    >
      <div className="badge flex flex-row items-center gap-2 bg-zinc-700 p-3">
        <p className="text-xs text-zinc-200">
          {filter.type === Filter.TAGS ? `#${filter.display}` : filter.display}
          {filter.subText && ` (${filter.subText})`}
        </p>
        <X
          size={12}
          color="#E4E4E7"
          onClick={() => filterRemoveHandler(filter)}
          weight="bold"
          className="cursor-pointer"
        />
      </div>
    </TooltipWrapper>
  );
};

export default BadgeRemovable;
