import { ArrowLeft } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { ErrorDisplay } from '../../../../components/DataTables';
import { BasicSpinner } from '../../../../components/Spinners';
import cn from '../../../../utils/cn';
import useStrapiLearningJourneyData from '../../hooks/use-strapi-learning-journey-data';

type Props = {
  className?: string;
  query?: any;
};

const LearningJourneyDetailHeading = (props: Props) => {
  const { className, query } = props;

  // cms
  const { LearningJourneyDetail: cms } = useStrapiLearningJourneyData();

  return (
    <header
      className={cn(
        'sticky top-0 z-[1] flex flex-col items-start gap-2 border-b border-b-zinc-800 bg-card-bg px-3 py-6 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9',
        className
      )}
    >
      <Link to="/learning-journeys" className="flex items-center gap-1.5 text-zinc-400">
        <ArrowLeft color="currentColor" className="text-sm font-normal leading-5" />
        <span className="text-sm">{cms.headerBackLabel}</span>
      </Link>
      {/* loader */}
      {query.isLoading ? (
        <div className="flex min-h-[64px] items-center py-2 text-zinc-100">
          <BasicSpinner className="!m-0" color="currentColor" />
        </div>
      ) : null}
      {/* has data */}
      {query.data ? (
        <div className="flex flex-col justify-start gap-1">
          <h1 className="header-title font-semibold">{query.data.name}</h1>
          <p className="text-xs font-normal leading-4 text-zinc-400 xs:text-sm xs:leading-5">
            {query.data.description}
          </p>
        </div>
      ) : null}
      {/* error */}
      {query.isError && !query.data ? (
        <ErrorDisplay
          refetch={query.refetch}
          message={cms.detailsLoadingErrorMessage}
          allowsRefetch={true}
          isRefetching={query.isRefetching}
          className="items-start gap-3 py-2 pt-4"
        ></ErrorDisplay>
      ) : null}
    </header>
  );
};

export default LearningJourneyDetailHeading;
