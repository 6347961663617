// Filters
// DEBOUNCE on request made for search input in milliseconds
export const ENT_LEADER_TEAMS_FILTER_SEARCH_DEBOUNCE_MS = 500;
// DEBOUNCE on request made for search input
// Pagination
export const ENT_LEADER_TEAMS_PAGE_SIZE_INCREMENT = 10;
// TODO: Get from backend
export const ENT_LEADER_TEAMS_MAX_PAGE_SIZE = 50;
export const ENT_LEADER_TEAMS_MIN_PAGE_SIZE = 10;

export const ENT_LEADER_TEAMS_DEFAULT_PAGE = 1;
export const ENT_LEADER_TEAMS_FILTER_QUERY_PARAM = 'filter';

// Pagination
