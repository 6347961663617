import { useQuery } from '@tanstack/react-query';
import { getBadgesLeaderboard } from '../api/badges';
import { STALE_TIME } from '../constants/badges';
import { PartialBadgesFilter } from '../types/badges';

interface Props {
  filters: PartialBadgesFilter;
}

const useGetBadgesLeaderBoardQuery = ({ filters }: Props) => {
  const data = useQuery(
    [
      {
        scope: 'badges',
        item: 'leaderboard',
        filters: filters,
      },
    ],
    async () => {
      return getBadgesLeaderboard(filters);
    },
    {
      keepPreviousData: true,
      staleTime: STALE_TIME,
    }
  );

  return data;
};

export default useGetBadgesLeaderBoardQuery;
