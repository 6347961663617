import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { SECTIONS } from '../constants/section-name';
import { triggerDataRefresh } from './team.slice';

/**
 * Method to trigger Data Refresh on Section/Table
 * @param sectionName - SECTIONS
 */
export const triggerSectionDataRefresh = (sectionName: SECTIONS, dispatch: Dispatch<AnyAction>) => {
  const actionPayload = {
    [sectionName]: true,
  };
  dispatch(triggerDataRefresh(actionPayload));
};
