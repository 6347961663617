import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { BILLING_API_PATHS } from '../constants/billings';
import { Bills } from '../types/billing';

/**
 * Get All Past Invoices
 */
export const getAllInvoices = async (): Promise<Bills[]> => {
  const path = BILLING_API_PATHS.INVOICES;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, { withCredentials: true });
  return response;
};
export const getIndividualInvoices = async (username: string): Promise<Bills[]> => {
  const queryString = qs.stringify({ tenantType: 'INDIVIDUAL' });
  let path = BILLING_API_PATHS.INDIVIDUAL_INVOICES;
  path = path.replace('{id}', username);
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};
