import { useMutation } from '@tanstack/react-query';
import { createModuleInMultiLang } from '../api';
import { ModuleCreateInMultiLangMutationType } from '../types';

const useModuleCreateInMultiLangMutation = () => {
  const { data, error, isError, isLoading, mutate } = useMutation({
    mutationFn: (data: ModuleCreateInMultiLangMutationType) => createModuleInMultiLang(data),
  });
  return { data, error, isError, isLoading, mutate };
};

export default useModuleCreateInMultiLangMutation;
