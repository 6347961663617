import { Filter, selectOptionType } from '../types/Filter.Helper';
import { useEffect, useState } from 'react';
import { useStrapiCoursesData } from './useStrapiCourseData';

type useFilterStateProps = {
  tagFetch: string[];
  categoriesFetch: string[];
  speakersFetch: string[];
  formatsFetch: string[];
};
const useFilterState = ({
  tagFetch,
  categoriesFetch,
  formatsFetch,
  speakersFetch,
}: useFilterStateProps) => {
  const { filters } = useStrapiCoursesData().allCourses;
  const { speakersLabel, categoriesLabel, formatLabel } = filters;
  const [tags, setTags] = useState<selectOptionType[]>([{ display: 'Tags', value: Filter.NONE }]);
  const [categories, setCategories] = useState<selectOptionType[]>([
    { display: categoriesLabel, value: Filter.NONE },
  ]);
  const [formats, setFormats] = useState<selectOptionType[]>([
    { display: formatLabel, value: Filter.NONE },
  ]);
  const [speakers, setSpeakers] = useState<selectOptionType[]>([
    { display: speakersLabel, value: Filter.NONE },
  ]);

  useEffect(() => {
    if (formatsFetch) {
      setFormats([
        formats[0],
        ...(formatsFetch.length !== 0
          ? formatsFetch.map((format) => ({
              display: format,
              value: format,
            }))
          : []),
      ]);
    }
  }, [formatsFetch]);

  useEffect(() => {
    if (speakersFetch) {
      setSpeakers([
        speakers[0],
        ...(speakersFetch.length !== 0
          ? speakersFetch.map((speaker) => ({
              display: speaker,
              value: speaker,
            }))
          : []),
      ]);
    }
  }, [speakersFetch]);

  useEffect(() => {
    if (categoriesFetch) {
      setCategories([
        categories[0],
        ...(categoriesFetch.length !== 0
          ? categoriesFetch.map((category) => ({
              display: category,
              value: category,
            }))
          : []),
      ]);
    }
  }, [categoriesFetch]);

  useEffect(() => {
    if (tagFetch) {
      setTags([
        tags[0],
        ...(tagFetch.length !== 0
          ? tagFetch.map((tag) => ({
              display: tag,
              value: tag,
            }))
          : []),
      ]);
    }
  }, [tagFetch]);

  return { tags, speakers, categories, formats };
};

export default useFilterState;
