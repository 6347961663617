import {
  ENT_ADMIN_REQUEST_STATUS_VALUES,
  ENT_ADMIN_VIEW_MEMBERS_STATUS_VALUES,
} from '../constants/tableConstants';

export interface Members {
  username: string;
  userType: string;
  jobTitle: string;
  teamId: number;
  preferredLang: string;
  lastLogin: unknown;
  pictureUrl: null;
  fullName: string;
  lastName: string;
  phone: unknown;
  firstName: string;
  department: string;
  timeZone: string;
  status: string;
  updatedBy: string;
  createdBy: string;
  updatedOn: Date;
  createdOn: Date;
  mfaPreference: string;
}
export const RoleOptions = {
  ALL_MEMBERS: 'ALL_MEMBERS',
  MEMBER_MANAGER: 'MEMBER_MANAGER',
  LEADER: 'LEADER',
};
export const ROLES = {
  LEANER: 'User',
  LEADER: 'Leader',
  MANAGER: 'Manager',
};
export type PartialMembers = Partial<Members>;
export interface Team {
  teamId?: number;
  isActive?: boolean;
  teamName?: string;
  teamManagers?: TeamManager[];
  courses?: number;
  subTeams?: Team[];
  progress?: number;
  enrolledMembers?: User[];
  membersCount?: number;
  createdOn?: string;
  email?: string;
  lastLogin?: string;
  isSubTeam?: boolean;
  hasSubTeam?: boolean;
}

export interface TeamManager {
  fullName: string;
  username: string;
  pictureUrl: string;
}
export interface User {
  id?: number;
  name?: string;
  profileImage?: string;
  excessUser?: boolean;
  count?: number;
  enrolledCourses?: number;
  progress?: number;
  designation?: string;
}
export interface Subteams {
  id: number;
  name: string;
  managers: string[];
  courses: number;
  progress: number;
  members: User[];
}

export interface Manager {
  id: string;
  name: string;
  profileImage: string;
  designation: string;
}

export interface Member {
  id?: string;
  name?: string;
  profileImage?: string;
  enrolledCourses?: number;
  progress?: number;
  designation?: string;
  role?: string;
  excessUser?: boolean;
  count?: number;
  username?: string;
}
export interface ManagerObject {
  count: number;
  rows: Manager[];
}
export interface MemberObject {
  count: number;
  rows: Member[];
}
export type TeamMemberResponse = {
  managers: ManagerObject;
  members: MemberObject;
};

export interface UserStatsManagerInfo {
  username: string;
  name: string;
}
export type UserStatsResponse = {
  fullName: string;
  jobTitle: string;
  teamName: string;
  lastLogin: string;
  badgesEarned: number;
  pictureUrl: string;
  managers: UserStatsManagerInfo[];
  loginsPastWeek: string;
};

export type ManagerTeamIdResponse = {
  teamId: string;
};

export type CourseHistoryResponse = {
  assignedCourses: string;
  inProgressCourses: string;
  completedCourses: string;
  timeSpent: string;
  averageScore: string;
  mostEngagedCategory: string;
};

export interface TeamStatsResponse {
  overallProgress: string;
  averageSession: string;
  coursesAssigned: string;
  coursesCompleted: string;
  mostEarnedBadges: string;
  teamName: string;
}

export interface MostEarnedBadgesType {
  id: number;
  name: string;
  pictureUrl: string;
  count: number;
}

export type TeamData = {
  teamName: string;
  members?: {
    email: string;
    jobTitle: string;
    role: string;
  }[];
  parentId?: number;
  requestType?: string;
};
export type AdminTeamData = {
  teamName: string;
  members?: {
    email: string;
    jobTitle: string;
    role: string;
  }[];
  parentId?: number;
  requestType?: string;
};

export interface EnterpriseAdminTeamsFilter {
  page?: string;
  pageSize?: string;
  status: (typeof ENT_ADMIN_REQUEST_STATUS_VALUES)[number];
  q?: string;
  start?: number;
  size?: number;
  sort?: any;
}
export interface EnterpriseLeaderTeamManagerFilter {
  teamId: string;
}
export type PartialEnterpriseLeaderTeamManagerFilter = Partial<EnterpriseLeaderTeamManagerFilter>;

export interface UsersStatsFilter {
  username: string;
}
export type PartialUsersStatsFilter = Partial<UsersStatsFilter>;

export interface CourseHistoryFilter {
  username: string;
}
export type PartialCourseHistoryFilter = Partial<CourseHistoryFilter>;

export interface EnterpriseAdminViewTeamMembersFilter {
  start: number;
  status: (typeof ENT_ADMIN_VIEW_MEMBERS_STATUS_VALUES)[number];
  searchKey: string;
  size: number;
}
export type PartialEnterpriseAdminTeamsFilter = Partial<EnterpriseAdminTeamsFilter>;
export type PartialEnterpriseAdminViewAllTeamMembersFilter =
  Partial<EnterpriseAdminViewTeamMembersFilter> & {
    teamId: number;
    sortOrder?: string | boolean;
    sortKey?: string;
  };

// Request Type Assumption

export type EnterpriseAdminTeamsType = 'ADD_NEW_MEMBER' | 'ADD_NEW_TEAM';
export type EnterpriseAdminTeamsStatus = 'open' | 'completed' | 'rejected';
export type AllTeams = {
  teamName: string;
  teamId: string;
};
export interface EnterpriseAdminTeamsCreateBy {
  name: string;
  email: string;
}

export type EnterpriseAdminTeams =
  | (
      | {
          type: 'ADD_NEW_MEMBER';
          emailToAdd: string;
          teamToAddIn: string;
        }
      | {
          type: 'ADD_NEW_TEAM';
          teamToAdd: string;
          teamManager: string;
          isSubTeam: boolean;
          parentTeam?: string;
        }
    ) & {
      timestamp: string; // UTC Time Format
      count: number;
      teamname: string;
      createdBy: EnterpriseAdminTeamsCreateBy;
      status: EnterpriseAdminTeamsStatus;
    };

export type EnterpriseSubteam = {
  teamName: string;
  teamManager: string;
  count: number;
  timestamp: string;
};

// Request Type Assumption

export type EnterpriseLeaderTeam = {
  teamId: number;
  teamName: string;
  teamManagers: ManagerDetail[];
  createdOn: Date;
  userLogo: UserLogoInfo[];
  subTeams: subTeamsInfo[];
  subTeamsCount: number;
  usersCount: number;
  coursesCount: number;
  overallProgress: number;
  isActive: boolean;
  status: string;
};

export type UserLogoInfo = {
  name?: string;
  pictureUrl?: string;
  excessUser?: boolean;
  count?: number;
  username?: string;
};

export type subTeamsInfo = {
  teamName: string;
};

export type ManagerDetail = {
  fullName: string;
  username: string;
};

export interface EnterpriseLeaderTeamsFilter {
  start: number;
  // Free Text Search
  search: string;
  size: number;
  parentTeamId: string;
}

export type PartialEnterpriseLeaderTeamsFilter = Partial<EnterpriseLeaderTeamsFilter>;

export interface MostEarnedBadgesFilter {
  start: number;
  size: number;
  teamId: string;
}

export type PartialMostEarnedBadgesFilter = Partial<MostEarnedBadgesFilter>;

export type TeamNameStatusCheck = 'IDLE' | 'INPROGRESS' | 'COMPLETED';
