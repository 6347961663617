type IndividualRegistrationMainHeaderProps = {
  heading: string;
  subHeading: string;
};

const IndividualRegistrationMainHeader = ({
  heading,
  subHeading,
}: IndividualRegistrationMainHeaderProps) => {
  return (
    <div className="flex flex-col items-center gap-1">
      <h2 className="text-2xl font-extrabold leading-[35px] text-white">{heading}</h2>
      <p className="text-center text-sm leading-[25px]">{subHeading}</p>
    </div>
  );
};

export default IndividualRegistrationMainHeader;
