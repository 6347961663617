import { ArrowLeft } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../../../../hooks';
import { RootState } from '../../../../../../../../store';
import { useStrapiCoursesData } from '../../../../../../hooks/useStrapiCourseData';
import { twMerge } from 'tailwind-merge';

interface SingleModuleHeaderProps {
  title: string | null;
  courseName: string;
  courseCategory: string;
  courseDescription: string | null;
}
const SingleModuleHeader = ({
  title,
  courseName,
  courseCategory,
  courseDescription,
}: SingleModuleHeaderProps) => {
  const navigate = useNavigate();
  const goToCourse = () => {
    navigate('..', { relative: 'path' });
  };
  const singleCourseSelector = (state: RootState) => state.singleCourse;
  const { currentModuleIndex } = useAppSelector(singleCourseSelector);
  const { singleModuleCommon } = useStrapiCoursesData();
  return (
    <div
      className={twMerge(
        ' flex-col justify-start border-b border-zinc-800 bg-zinc-900 py-5 text-white  md:px-6 lg:px-[5%]  xl:px-[7.5%]'
      )}
    >
      <div className="my-4 mx-1 flex flex-col justify-between md:my-8 md:flex-row">
        {/* Left Section */}
        <div className="flex flex-col">
          <div
            className=" mb-2 flex w-max cursor-pointer flex-row items-center text-sm text-zinc-300 "
            onClick={goToCourse}
          >
            <ArrowLeft size="16px" className="mr-[3px] " color="white" />
            <p className="border-b border-b-transparent hover:border-b-zinc-300">
              {singleModuleCommon.backButton}
            </p>
          </div>
          {currentModuleIndex != null && title && (
            <div className="text-2xl font-semibold text-white">
              {singleModuleCommon.modulesLabel} {currentModuleIndex + 1}: {title}
            </div>
          )}
          <div className="text-sm text-zinc-300">{courseDescription}</div>
        </div>
        {/* Right Section */}
        <div className="mx-1 mt-1 flex flex-none flex-col ">
          <div className="text-sm font-medium text-white"> {courseName}</div>
          <div className="text-sm text-zinc-300">{courseCategory}</div>
        </div>
      </div>
    </div>
  );
};

export default SingleModuleHeader;
