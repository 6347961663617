import clsx from 'clsx';
import Select, { SelectProps } from '../../../components/Select';

const FilterWidgets = ({ label, className, ...otherProps }: SelectProps) => {
  return (
    <>
      <label htmlFor="reports_duration" className="visually-hidden">
        {label}
      </label>
      <Select
        id="reports_duration"
        className={clsx(
          'w-full min-w-[10rem] rounded-sm border border-zinc-900 bg-zinc-900 py-2 pl-4 pr-10 !text-[14px] font-normal !leading-[20px] text-zinc-100',
          className
        )}
        {...otherProps}
      />
    </>
  );
};

export default FilterWidgets;
