import Dialog from '../../../../components/Dialog';
import { useStrapiOrganizationData } from '../../hooks/useStrapiOrganizationData';
import AddOrganisationModalForm from './AddOrganisationModalForm';

interface Props {
  setIsModalOpen: (open: boolean) => void;
}

const AddOrganisationModalMain = ({ setIsModalOpen }: Props) => {
  const { modal } = useStrapiOrganizationData();
  return (
    <div className="mx-1 flex max-h-[81vh] flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
        {modal.title}
      </Dialog.Title>
      <AddOrganisationModalForm setIsModalOpen={setIsModalOpen} />
    </div>
  );
};

export default AddOrganisationModalMain;
