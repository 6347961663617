import clsx from 'clsx';
import { PropsWithChildren, useCallback, useContext } from 'react';

import { Avatar } from 'react-daisyui';
import { twMerge } from 'tailwind-merge';
import { ushgHQAppRoleMetadata, USHG_HQ_APP_ROLE } from '../../../constants';
import useInitQuery from '../../../features/auth/hooks/use-init-query';
import { generateCDNMediaAssetURLWithKey } from '../../../features/shared/helpers';
import { useAppSelector } from '../../../hooks';
import AcronymLogo from '../../AcronymnLogo';
import LoadingAvatar from '../../Icons/Avatar';
import sideBarContext from '../context/sideBarContext';

const getDisplayNameForRole = (role?: string, email?: string) => {
  // Display the email for individual user and other as specified
  // in the USHG HQ App Metadata
  if (role === USHG_HQ_APP_ROLE.INDIVIDUAL_USER) {
    return email;
  }
  return ushgHQAppRoleMetadata[role ?? '']?.displayName;
};

const SideBarProfileText = (props: PropsWithChildren<{ className?: string }>) => {
  const { children, className } = props;

  return (
    <span
      className={twMerge(
        'inline-block w-full grow overflow-hidden text-ellipsis whitespace-nowrap text-start',
        className
      )}
    >
      {children}
    </span>
  );
};

// User Profile to be displayed on the sidebar
const SideBarProfileTrigger = () => {
  const user = useAppSelector(useCallback((state) => state.auth.user, []));
  const { isSidebarCollapsed } = useContext(sideBarContext);
  // The Sidebar is only rendered when the User is authenticated so there would
  // be role
  const initData = useInitQuery();
  const userName = `${user?.given_name} ${user?.family_name}`;

  const profilePictureCDNURL =
    user?.picture && user.picture !== ''
      ? generateCDNMediaAssetURLWithKey({ key: user.picture })
      : undefined;

  return (
    <div
      className={clsx('flex gap-2 p-2 hover:bg-[rgba(64,64,73,0.5)]', [
        isSidebarCollapsed && 'rounded-full !p-0',
        !isSidebarCollapsed && 'rounded-sm',
      ])}
    >
      {initData.isLoading && (
        <LoadingAvatar className="h-9 w-9 rounded-full border-2 border-white text-[16px]" />
      )}
      {!initData.isLoading && profilePictureCDNURL && (
        <Avatar
          src={profilePictureCDNURL}
          size={32}
          className="rounded-full border-2 border-white"
          shape="circle"
          role="img"
          aria-label={userName}
        ></Avatar>
      )}
      {!initData.isLoading && !profilePictureCDNURL && (
        <h2>
          <AcronymLogo className="h-9 w-9 text-sm" wordsStr={userName} aria-label={userName} />
        </h2>
      )}
      {!isSidebarCollapsed && (
        <div className="flex w-40 grow flex-col text-xs font-normal text-white xxs:w-48 lg:w-[min(calc(100vw/6_-_84px),192px)]">
          <SideBarProfileText>{userName}</SideBarProfileText>
          <SideBarProfileText className="text-zinc-400">
            {getDisplayNameForRole(user?.role, user?.email)}
          </SideBarProfileText>
        </div>
      )}
    </div>
  );
};

export default SideBarProfileTrigger;
