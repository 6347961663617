import { TRANSFORMERS } from '@lexical/markdown';
import { UNDERLINE_TRANSFORMER } from '../../shared/helpers/lexical-markdown';

const ANNOUNCEMENT_ACTION = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
} as const;

const ANNOUNCEMENT_EDITOR_MARKDOWN_TRANSFORMERS = [UNDERLINE_TRANSFORMER, ...TRANSFORMERS];

export { ANNOUNCEMENT_ACTION, ANNOUNCEMENT_EDITOR_MARKDOWN_TRANSFORMERS };
