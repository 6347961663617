import { Navigate } from 'react-router-dom';
import { USHG_HQ_APP_ROLE } from '../../../constants';
import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';
import HasAccess from '../../shared/components/HasAccess';
import Teams from '../components/Teams/Teams';
import TeamsTableMain from '../components/TeamTable/TeamsTableMain';
import TopNav from '../components/TopNav/TopNav';
import { SideBarLayoutPageContentWrapper } from '../../../components/Wrapper';
import { useStrapiTeamData } from '../hooks/useStrapiTeamData';
import useSessionTracker from '../../../hooks/use-session-tracker-hook';

const teamStateSelector = (state: RootState) => state.team;
function AllTeamsPage() {
  useSessionTracker();
  const { selectedTeam } = useAppSelector(teamStateSelector);
  const {
    topNav: { topNavTeamTitle },
  } = useStrapiTeamData();
  return (
    <>
      <HasAccess
        roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEADER, USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]}
        Fallback={<Navigate to={'/dashboard'} />}
      >
        <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEADER]} Fallback={null}>
          <div className="w-full">
            <TopNav
              title={topNavTeamTitle}
              isLeaderTeamView={true}
              teamId={selectedTeam.teamId as number}
            />
            <div>
              <Teams
                classNameProps={
                  'grid grid-flow-row-dense grid-cols-1 gap-5 px-3 py-6 sm:px-5 md:grid-cols-2 md:px-6 lg:px-7 xl:px-8 2xl:px-9'
                }
              />
            </div>
          </div>
        </HasAccess>
        <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]} Fallback={null}>
          <div className="flex flex-col gap-5">
            <TopNav title={topNavTeamTitle} teamId={selectedTeam.teamId as number} />
            <SideBarLayoutPageContentWrapper>
              <TeamsTableMain isOrganizationView={false} className="w-full" />
            </SideBarLayoutPageContentWrapper>
          </div>
        </HasAccess>
      </HasAccess>
    </>
  );
}

export default AllTeamsPage;
