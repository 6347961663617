import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import { useReduxAuthState } from '../../../hooks';
import {
  DEFAULT_PREFERRED_LANGUAGE,
  LOCAL_STORAGE_USER_PREFERRED_LANGUAGE,
} from '../../../constants/user';

interface LanguageContextValue {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

const LanguageContext = createContext<LanguageContextValue>({} as LanguageContextValue);

export const LanguageProvider = ({ children }: PropsWithChildren) => {
  const [language, setLanguage] = useState(
    localStorage.getItem(LOCAL_STORAGE_USER_PREFERRED_LANGUAGE) ?? DEFAULT_PREFERRED_LANGUAGE
  ); // Default to English for Strapi
  const auth = useReduxAuthState();

  useEffect(() => {
    if (auth.user && auth.user.locale) {
      setLanguage(auth.user.locale);
      localStorage.setItem(LOCAL_STORAGE_USER_PREFERRED_LANGUAGE, auth.user.locale);
    }
  }, [auth.user]);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_USER_PREFERRED_LANGUAGE, language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

export default LanguageContext;
