import { BasicSpinner } from '../Spinners';

interface Props {
  message?: string;
}

const defaultMessage = 'Loading more';

const LoadingMoreMessage = ({ message }: Props) => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-2 py-8">
      <BasicSpinner className="text-zinc-400" />
      <span className="text-center text-xs font-normal text-white">
        {message ?? defaultMessage}
      </span>
    </div>
  );
};

export default LoadingMoreMessage;
