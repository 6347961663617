import Alert from '../../../../../components/Alert';
import { useStrapiTeamData } from '../../../hooks/useStrapiTeamData';

interface Props {
  errors: any;
  index: number;
}

const ModalErrorAlert = ({ errors, index }: Props) => {
  const { commomTeamErrorMessage } = useStrapiTeamData();
  const {
    memberAlreadyExists,
    teamAlreadyExists,
    managerMemberSameError,
    managerMemberSameErrorMessage,
    sameMemberError,
    sameMemberErrorMessage,
    teamNameAlreadyExistMessage,
    memberAlreadyExistsMessage,
  } = commomTeamErrorMessage;
  if (errors?.members?.[index]?.email?.message === sameMemberError) {
    return <Alert variant="warning" message={sameMemberErrorMessage}></Alert>;
  } else if (
    errors?.managerEmail?.message === memberAlreadyExists ||
    errors?.members?.[index]?.email?.message === memberAlreadyExists
  ) {
    return <Alert variant="warning" message={memberAlreadyExistsMessage}></Alert>;
  } else if (errors?.managerEmail?.message === managerMemberSameError) {
    return <Alert variant="warning" message={managerMemberSameErrorMessage}></Alert>;
  } else if (errors?.teamAlreadyExist?.message === teamAlreadyExists) {
    return <Alert variant="warning" message={teamNameAlreadyExistMessage}></Alert>;
  } else {
    return null;
  }
};

export default ModalErrorAlert;
