const USERS_BASE_PATH = '/users';
const REQUESTS_API_PATHS = {
  ENTERPRISE_ADMIN: {
    GET_ALL_REQUESTS: `${USERS_BASE_PATH}/enterprise-admin/requests`,
    // POST
    RAISE_REQUEST_TO_USHG_ADMIN: `${USERS_BASE_PATH}/hq-admin/requests`,
    // PUT
    APPROVE_OR_REJECT_REQUEST: (id: number) => {
      return `${USERS_BASE_PATH}/enterprise-admin/requests/${id}`;
    },
  },
  USHG_ADMIN: {
    GET_ALL_REQUESTS: `${USERS_BASE_PATH}/hq-admin/requests`,
    GET_ALL_REQUEST_TYPES: `${USERS_BASE_PATH}/hq-admin/requests/types`,
    RESOLVE_REQUEST: (id: number) => `${USERS_BASE_PATH}/hq-admin/request-resolve/${id}`,
  },
};

export { REQUESTS_API_PATHS };
