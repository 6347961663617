import BillingHistory from './components/BillingHistory';
import CurrentPlan from './components/CurrentPlan';
import UpdatePaymentMethod from './components/UpdatePaymentMethod';
import { useAppSelector } from '../../../../hooks';
import { RootState } from '../../../../store';
import { parseJSON } from '../../../../utils';

const authStateSelector = (state: RootState) => state.auth;
function EnterpriseAdminBillingPage() {
  const { user } = useAppSelector(authStateSelector);

  const enableUpdate = (user: any) => {
    const value = parseJSON<undefined | { isSecondaryAdmin: boolean }>(
      user['custom:role_settings']
    );
    if (value) return value.isSecondaryAdmin;
    return false;
  };

  return (
    <div className="m-auto flex w-full flex-col gap-8 md:max-w-2xl">
      <BillingHistory />
      <CurrentPlan enableUpdate={!enableUpdate(user)} />
      {!enableUpdate(user) && <UpdatePaymentMethod />}
    </div>
  );
}

export default EnterpriseAdminBillingPage;
