import { SubmitAssessmentResponse } from '../../types/SingleCourseTypes';
import AssessmentResult from './AssessmentResult';

interface Props {
  setModalOpen: (open: boolean) => void;
  assessmentResult: SubmitAssessmentResponse;
  nextModuleHandler: () => void;
}

const AssessmentResultModal = ({ setModalOpen, assessmentResult, nextModuleHandler }: Props) => {
  return (
    <div className="mx-6 flex h-max max-h-[80vh] flex-col gap-6 overflow-scroll rounded-lg border border-zinc-900 bg-card-bg shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)]">
      <AssessmentResult
        setModalOpen={setModalOpen}
        assessmentResult={assessmentResult}
        nextModuleHandler={nextModuleHandler}
      />
    </div>
  );
};

export default AssessmentResultModal;
