import UserResetPasswordForm from './UserResetPasswordForm';
import UserLoginHeader from '../common/UserLoginHeader';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';

const UserResetPasswordMain = () => {
  const data: any = useStrapiDataHelper();
  const { title, description } = data.resetpassword.data.attributes;

  return (
    <main id="main-content" className="flex grow flex-col items-center gap-5 pt-[10vw]">
      <UserLoginHeader heading={title} subHeading={description} />
      <UserResetPasswordForm className="pt-5" />
    </main>
  );
};

export default UserResetPasswordMain;
