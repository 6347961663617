import clsx from 'clsx';
import { ArrowDown, ArrowUp } from 'phosphor-react';
import { BasicSpinner } from '../../../components/Spinners';
import { formatNumber } from '../../../utils';

interface Props {
  data: { title: string; value: string; isInc?: boolean; percentage?: number; isTime?: boolean };
  className: string;
  isloading: boolean;
}
const StatisticsCard = ({ data, className, isloading }: Props) => {
  const { title, value, isInc, percentage } = data;
  return (
    <div className={clsx('flex  w-full flex-col gap-3 rounded-lg bg-zinc-800 p-4', className)}>
      <span className="text-xs font-medium text-zinc-300 lg:text-sm">{title}</span>
      {!isloading ? (
        <div className="flex items-center justify-between gap-2">
          <span className="text-right text-4xl font-bold text-zinc-50">{value}</span>
          {isInc && percentage && (
            <div className="flex items-center rounded-lg bg-[#064E3B] p-[2px_10px_2px_8px]">
              <ArrowUp size={12} className={'text-emerald-50'} weight={'bold'} />
              <span className="text-sm font-medium text-emerald-50">
                {percentage ? formatNumber(percentage) : 0}%
              </span>
            </div>
          )}
          {percentage && (
            <div className="flex items-center rounded-lg bg-red-900 p-[2px_10px_2px_8px]">
              <ArrowDown size={12} className={'text-red-50'} weight={'bold'} />
              <span className="text-sm font-medium text-emerald-50">
                {percentage ? formatNumber(percentage) : 0}%
              </span>
            </div>
          )}
        </div>
      ) : (
        <BasicSpinner className="h-full text-white" />
      )}
    </div>
  );
};

export default StatisticsCard;
