import { LOCAL_STORAGE_USER_TENANT_ID } from './../../messaging/constants/chime-messaging';

/**
 * Read tenant id from localStorage.
 */
const getUserTenantId = () => {
  const chimeAppInstanceArn = localStorage.getItem(LOCAL_STORAGE_USER_TENANT_ID) as string;
  return chimeAppInstanceArn;
};

export { getUserTenantId };
