import { useMemo } from 'react';
import { TabGroup } from '../../../../components/Tabs';
import { USHG_HQ_APP_ROLE } from '../../../../constants';
import { useIsFederatedUser, useReduxAuthState } from '../../../../hooks';
import { useStrapiSettingsData } from '../../hooks/useStrapiSettingsData';
import { PageHeader, SecurityTab } from '../common';
import EnterpriseAdminBillingPage from '../EnterpriseAdminSettingsPage/EnterpriseAdminBillingPage';
import { UserProfileMain } from '../Form';
import { NotificationPreferenceSettingsMain } from '../shared';
import { TAB_GROUPS } from '../../../../constants/tabs-tag-constants';

const UsersSettingsPage = () => {
  const { user } = useReduxAuthState();
  const isFederatedUser = useIsFederatedUser();

  const { tabs } = useStrapiSettingsData();
  const { securityTab, billingTab, profileTab, notificationTab } = tabs;

  const TABS = useMemo(() => {
    const tabs = [{ title: profileTab, tag: TAB_GROUPS.PROFILE, children: <UserProfileMain /> }];

    if (!isFederatedUser) {
      tabs.push({ title: securityTab, tag: TAB_GROUPS.SECURITY, children: <SecurityTab /> });
    }

    if (user?.role === USHG_HQ_APP_ROLE.INDIVIDUAL_USER) {
      tabs.push({
        title: billingTab,
        tag: TAB_GROUPS.BILLING,
        children: <EnterpriseAdminBillingPage />,
      });
    }

    tabs.push({
      title: notificationTab,
      tag: TAB_GROUPS.NOTIFICATIONS,
      children: <NotificationPreferenceSettingsMain />,
    });

    return tabs;
  }, [billingTab, isFederatedUser, notificationTab, profileTab, securityTab, user?.role]);

  const { title, commonDescription } = useStrapiSettingsData().topNav;

  return (
    <main id="main-content" className="flex flex-col">
      <PageHeader settingsHeader={title} settingsSubHeader={commonDescription} />
      <TabGroup
        tabs={Array.from(TABS.values())}
        tabClassName="px-3 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9"
      />
    </main>
  );
};

export default UsersSettingsPage;
