import { excessItemsShortner } from '../../../../utils/excessItemsShortner';
import { useStrapiTeamData } from '../../hooks/useStrapiTeamData';
import { EnterpriseLeaderTeam } from '../../types/interface';
import LeaderTeamsUserCircleGroup from '../UserCircle/LeaderTeamsUserCircleGroup';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '../../../../utils';

interface Props {
  team: EnterpriseLeaderTeam;
}
function SubTeamCard({ team }: React.PropsWithChildren<Props>) {
  const { teamCard } = useStrapiTeamData();
  const { managerLabel, assignedCoursesLabel, courseCompletionLabel } = teamCard;
  const navigate = useNavigate();

  let displayUsers;
  if (team.userLogo != undefined) {
    const users = team.userLogo;
    displayUsers = [...users];
    if (users.length > 4) {
      displayUsers = users.slice(0, 4);
      const excessUserCount = team.usersCount - 4;
      const dummyUser = {
        excessUser: true,
        count: excessUserCount,
      };
      displayUsers.push(dummyUser);
    } else if (users.length < 4 && users.length != team.usersCount && team.userLogo != undefined) {
      const users = team.userLogo;
      displayUsers = [...users];
      const excessUserCount = team.usersCount - users.length;
      const dummyUser = {
        excessUser: true,
        count: excessUserCount,
      };
      displayUsers.push(dummyUser);
    }
  }

  const navigateToTeamPage = (id: number) => {
    navigate(`/team/${id}`);
  };

  if (team.isActive) {
    return (
      <article
        className="h-full w-full cursor-pointer rounded-[8px] bg-card-bg p-6 shadow"
        onClick={() => navigateToTeamPage(team.teamId)}
      >
        <div className="flex items-center">
          <h5 className="text-xl font-bold text-white line-clamp-1">{team.teamName}</h5>
          <div className="ml-auto">
            <LeaderTeamsUserCircleGroup displayUsers={displayUsers} />
          </div>
        </div>
        <div className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2">
          <div className="col-span-1 md:col-span-2">
            <div className="mt-4 flex w-full flex-none text-sm">
              <dt className="w-6/12 flex-none text-sm font-medium !text-zinc-400 md:w-1/4">
                {managerLabel}
              </dt>
              <dd className="truncate text-left text-sm text-white">
                {team.teamManagers.length != 0
                  ? excessItemsShortner(
                      team.teamManagers.map((manager) => {
                        if (manager.fullName) {
                          return manager.fullName;
                        } else {
                          return manager.username;
                        }
                      }),
                      1
                    )
                  : '-'}
              </dd>
            </div>
          </div>
          <div className="col-span-1 md:col-span-2">
            <div className="mt-4 flex w-full flex-none text-sm">
              <dt className="w-6/12 flex-none font-medium !text-zinc-400 md:w-1/4">
                {assignedCoursesLabel}
              </dt>
              <dd className=" text-white">{team.coursesCount ? team.coursesCount : 0}</dd>
            </div>
          </div>

          <div className="mt-4 flex w-full text-sm">
            <dt className="flex w-6/12 flex-none font-medium !text-zinc-400 md:w-3/6">Sub teams</dt>
            <dd className="text-sm text-white">
              {team.subTeams.length != 0
                ? excessItemsShortner(
                    team.subTeams.map((subTeam) => {
                      return subTeam.teamName;
                    }),
                    1
                  )
                : '-'}
            </dd>
          </div>

          <div className="order-first ml-0 mt-3 md:-order-none md:ml-[20px] md:mt-0">
            <div className="mb-3 grid grid-cols-6 gap-4">
              <div className="col-start-1 col-end-5 text-xs text-zinc-400">
                {courseCompletionLabel}
              </div>
              <div className="col-span-1 col-end-7 flex justify-end text-xs text-zinc-400">
                {team.overallProgress ? formatNumber(team.overallProgress) : '0' + '%'}
              </div>
            </div>
            <div className="flex w-full flex-none text-sm">
              <progress
                className="progress progress-error w-full bg-zinc-700"
                value={team.overallProgress ? team.overallProgress : '0'}
                max="100"
              ></progress>
            </div>
          </div>
        </div>
      </article>
    );
  } else {
    return <></>;
  }
}

export default SubTeamCard;
