import { PERIOD } from '../../shared/constants';
import {
  getFirstDateOfPreviousMonth,
  getFirstDateOfLastThreeMonths,
  getFirstDateOfLastSixMonths,
} from '../../shared/helpers/dateTimeUtil';

const setReportsDuration = (duration: string) => {
  switch (duration) {
    case PERIOD.LAST_MONTH: {
      const { firstDateOfPreviousMonth } = getFirstDateOfPreviousMonth();
      return firstDateOfPreviousMonth;
    }

    case PERIOD.LAST_THREE_MONTHS: {
      const { firstDateOfLastThreeMonths } = getFirstDateOfLastThreeMonths();
      return firstDateOfLastThreeMonths;
    }
    case PERIOD.LAST_SIX_MONTHS: {
      const { firstDateOfLastSixMonths } = getFirstDateOfLastSixMonths();
      return firstDateOfLastSixMonths;
    }
    default: {
      const { firstDateOfPreviousMonth } = getFirstDateOfPreviousMonth();
      return firstDateOfPreviousMonth;
    }
  }
};

export { setReportsDuration };
