import { gql } from '@apollo/client';

export const ReportsFragment = gql`
  fragment ReportsFragment on ReportEntityResponse {
    data {
      attributes {
        GenericMessages {
          memberLoadingMessage
          noMemberFound
        }
        ReportsTables {
          IndividualLeaderboard {
            title
            tableheader1
            tableheader2
            tableheader3
            emptyMessage
            errorMessage
          }
          TeamLeaderboard {
            title
            tableheader1
            tableheader2
            tableheader3
            emptyMessage
            errorMessage
          }
          TeamsLeaderboard {
            title
            tableheader1
            tableheader2
            tableheader3
            emptyMessage
            errorMessage
          }
          CustomerLeaderboard {
            title
            organisationNameTableHeader
            emailTableHeader
            membersTableHeader
            healthRiskTableHeader
            emptyMessage
            errorMessage
          }
          TopChurnRisk {
            title
            tableheader1
            tableheader2
            tableheader3
            emptyMessage
            errorMessage
          }
          OverAllChurnRate {
            title
            emptyMessage
            errorMessage
          }
          MostPopularCourses {
            title
            tableheader1
            tableheader2
            tableheader3
            emptyMessage
            errorMessage
          }
          MostPopularInstructor {
            title
            tableheader1
            tableheader2
            tableheader3
            emptyMessage
            errorMessage
          }
        }
        ReportsCharts {
          completionRate {
            xAxisLabel
            yAxisLabel
            key1
            key2
            key3
            title
            emptyChart
            emptyChartIcon {
              data {
                attributes {
                  url
                }
              }
            }
            errorChart
          }
          AssessmentScore {
            xAxisLabel
            yAxisLabel
            key1
            key2
            key3
            title
            emptyChart
            emptyChartIcon {
              data {
                attributes {
                  url
                }
              }
            }
            errorChart
          }
          HoursSpentFrequency {
            xAxisLabel
            yAxisLabel
            key1
            key2
            key3
            title
            emptyChart
            emptyChartIcon {
              data {
                attributes {
                  url
                }
              }
            }
            errorChart
          }
          CompletionRateOverTime {
            xAxisLabel
            yAxisLabel
            key1
            key2
            key3
            title
            emptyChart
            emptyChartIcon {
              data {
                attributes {
                  url
                }
              }
            }
            errorChart
          }
          CourseAssignedOrCompleted {
            xAxisLabel
            yAxisLabel
            key1
            key2
            key3
            title
            emptyChart
            emptyChartIcon {
              data {
                attributes {
                  url
                }
              }
            }
            errorChart
          }
          EngagementByCategory {
            xAxisLabel
            yAxisLabel
            key1
            key2
            key3
            title
            emptyChart
            emptyChartIcon {
              data {
                attributes {
                  url
                }
              }
            }
            errorChart
          }
          LoginFrequency {
            xAxisLabel
            yAxisLabel
            key1
            key2
            key3
            title
            emptyChart
            emptyChartIcon {
              data {
                attributes {
                  url
                }
              }
            }
            errorChart
          }
          OrganizationHoursLogged {
            xAxisLabel
            yAxisLabel
            key1
            key2
            key3
            title
            emptyChart
            emptyChartIcon {
              data {
                attributes {
                  url
                }
              }
            }
            errorChart
          }
          OrganizationCoursePerformance {
            xAxisLabel
            yAxisLabel
            key1
            key2
            key3
            title
            emptyChart
            emptyChartIcon {
              data {
                attributes {
                  url
                }
              }
            }
            errorChart
          }
          TeamHoursLogged {
            xAxisLabel
            yAxisLabel
            key1
            key2
            key3
            title
            emptyChart
            emptyChartIcon {
              data {
                attributes {
                  url
                }
              }
            }
            errorChart
          }
          TeamCoursePerformance {
            xAxisLabel
            yAxisLabel
            key1
            key2
            key3
            title
            emptyChart
            emptyChartIcon {
              data {
                attributes {
                  url
                }
              }
            }
            errorChart
          }
          MyViewingActivity {
            xAxisLabel
            yAxisLabel
            key1
            key2
            key3
            title
            emptyChart
            emptyChartIcon {
              data {
                attributes {
                  url
                }
              }
            }
            errorChart
          }
          MyCoursePerformance {
            xAxisLabel
            yAxisLabel
            key1
            key2
            key3
            title
            emptyChart
            emptyChartIcon {
              data {
                attributes {
                  url
                }
              }
            }
            errorChart
          }
          AverageWeeklyHours {
            xAxisLabel
            yAxisLabel
            key1
            key2
            key3
            title
            emptyChart
            emptyChartIcon {
              data {
                attributes {
                  url
                }
              }
            }
            errorChart
          }
        }
        Statistics {
          courseCompletedCardLabel
          assessmentsCompletedCardLabel
          averageAssessmentScoreCardLabel
        }
        TopNav {
          Tabs {
            overallPerformanceTabLabel
            individualPerformanceTabLabel
            teamPerformanceTabLabel
          }
          title
          myPerformanceLabel
          DurationDropDown {
            lastMonthLabel
            last3MonthLabel
            last6MonthLabel
            dropDownLabel
          }
          DurationLabels {
            daysLabel
            weeksLabel
            monthsLabel
          }
          TeamSelectionDropDown {
            dropDownLabel
            teamFilterDropdownPlaceholder
            globalScopeLabel
          }
        }
      }
    }
  }
`;
