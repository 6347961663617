import clsx from 'clsx';
import ComponentHeading from '../ComponentHeading';
import { ArrowRight } from 'phosphor-react';
import { Link, useParams } from 'react-router-dom';
import { useStrapiTeamData } from '../../../../teams/hooks/useStrapiTeamData';
import { PartialBadgesFilter } from '../../../../badges/types/badges';
import { useMemo } from 'react';
import useGetEarnedBadgesQuery from '../../../../badges/hooks/useGetEarnedBadgesQuery';
import { BasicSpinner } from '../../../../../components/Spinners';
import { ERROR_TYPES } from '../../../../../constants/error-types.constants';
import { ErrorDisplay } from '../../../../../components/DataTables';
import {
  EARNED_BADGES_DEFAULT_PAGE_SIZE,
  EARNED_BADGES_DEFAULT_PAGE_START,
} from '../../../constants/badges';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import EarnedBadgesGrid from './EarnedBadgesGrid';

type BadgesDisplayProps = {
  className?: string;
  showBadgeName?: boolean;
};

const EarnedBadgesDisplay = ({ className, showBadgeName }: BadgesDisplayProps) => {
  const { id } = useParams() ?? undefined;

  // Build the badge filters from the available filters
  const filters: PartialBadgesFilter = useMemo(() => {
    return {
      start: EARNED_BADGES_DEFAULT_PAGE_START,
      size: EARNED_BADGES_DEFAULT_PAGE_SIZE,
      username: id,
    };
  }, [id]);

  const earnedBadges = useGetEarnedBadgesQuery({ filters });

  // TODO: Handle Error Responses
  // const { data, isLoading, error } = useQuery(['badges'], getBadges);
  const { singlePerson } = useStrapiTeamData();
  const { viewAllButton, badgesEarnedLabel, noBadgesFoundLabel } = singlePerson;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const displayLoader =
    (earnedBadges.isLoading && !earnedBadges.data) ||
    (earnedBadges.isFetching && earnedBadges.data?.count === 0);
  const isEmptyState = earnedBadges.data && earnedBadges.data.count == 0;

  return (
    <div className={clsx('rounded-lg border-[0.01px] border-[#C4D500] bg-card-bg', className)}>
      <div className="flex flex-col gap-3 p-3">
        <div className="flex flex-row items-center justify-between gap-1 ">
          <ComponentHeading className="grow" heading={badgesEarnedLabel} />
          <Link to={`/badges/${id}`}>
            <div className="flex flex-row items-center gap-0.5">
              <p className="text-xs text-zinc-400">{viewAllButton}</p>
              <ArrowRight size={13} color="#A1A1AA" />
            </div>
          </Link>
        </div>
        <div className="flex h-max w-full items-center ">
          {displayLoader && (
            <div className="flex min-h-[100px] w-full items-center justify-center py-4">
              <BasicSpinner className="text-white" />
            </div>
          )}
          {/* We have data */}
          {earnedBadges.data && earnedBadges.data.count > 0 && (
            <div
              className={clsx(
                'relative flex w-full flex-col gap-4 ',
                earnedBadges.isPreviousData &&
                  earnedBadges.isFetching &&
                  'pointer-events-none opacity-70'
              )}
            >
              <div className="relative w-full overflow-y-scroll lg:max-h-52">
                <EarnedBadgesGrid
                  className="[--badge-image-size:64px]"
                  badges={earnedBadges.data.rows}
                  showBadgeName={showBadgeName}
                />
              </div>
            </div>
          )}

          {/* Empty State */}
          {isEmptyState && !displayLoader && (
            <div className="flex min-h-[100px] w-full flex-col items-center justify-center text-center text-base font-normal text-zinc-50">
              <span> {noBadgesFoundLabel}</span>
            </div>
          )}

          {earnedBadges.isError && !earnedBadges.data && (
            <div className="flex min-h-[100px] w-full items-center justify-center">
              <ErrorDisplay
                refetch={earnedBadges.refetch}
                isRefetching={earnedBadges.isRefetching}
                allowsRefetch={true}
                type={ERROR_TYPES.SOMETHING_WENT_WRONG}
                message={strapiErrorMessage['SYSTEM_ERROR']}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EarnedBadgesDisplay;
