import { useMutation } from '@tanstack/react-query';
import { userAssignCourse } from '../api/courses';

const useAssignCourseMemberMutation = () => {
  const { data, error, isLoading, isError, mutate, isSuccess } = useMutation({
    mutationFn: userAssignCourse,
  });
  return { data, error, isLoading, isError, mutate, isSuccess };
};

export default useAssignCourseMemberMutation;
