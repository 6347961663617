import { format, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { getLocale } from '../../../../../../utils';
import { useLanguage } from '../../../../../shared/context/languageContext';

interface TimestampCellProps {
  // ISO String
  timestamp: string;
}

const TimestampCell = (props: TimestampCellProps) => {
  const { timestamp } = props;

  const displayDate = useMemo(() => {
    if (!timestamp) {
      return '-';
    }

    const parsedISOTimestamp = parseISO(timestamp);
    const { language } = useLanguage();
    return format(parsedISOTimestamp, 'EEE dd MMM, yyyy p', { locale: getLocale(language) });
  }, [timestamp]);

  return <span className="whitespace-nowrap">{displayDate}</span>;
};

export default TimestampCell;
