import { createContext } from 'react';

export type LoginCredentials = {
  username: string;
  password: string;
};

export type UserLoginCredentialsContextValue = {
  loginCredentials: LoginCredentials | null;
  setLoginCredentials: React.Dispatch<React.SetStateAction<LoginCredentials | null>>;
};

const userLoginCredentialsContext = createContext<UserLoginCredentialsContextValue>(
  {} as UserLoginCredentialsContextValue
);

export default userLoginCredentialsContext;
