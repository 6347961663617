import { ArrowRight } from 'phosphor-react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../../../components/Buttons';
import { Emoji } from '../../../../../../components/Emoji';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { getCurrentAuthenticatedUser } from '../../../../../auth/utils';
import { getAccessTokenHelper } from '../../../../../shared/helpers';
import { useStrapiOnBoardingData } from '../../../../hooks/useStrapiOnBoardingData';
import useUpdateOnboardingStatusMutation from '../../../../hooks/useUpdateOnboardingStatusMutation';
import ErrorDisplay from '../../ErrorDisplay';

/**
 * Wraps around the loading and success state of Success Component.
 */
const SuccessContainer = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex flex-col items-center justify-center p-4 text-center">{children}</div>
  );
};

const Success = () => {
  const navigate = useNavigate();

  // To track whether the user onboarding status is set to true
  // and other onboarding activities are completed (like creating a notification channel)
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const updateUserOnboardingStatusMutation = useUpdateOnboardingStatusMutation();

  /**
   * Refresh cognito local user session
   */
  const refreshCognitoLocalUserSession = async () => {
    try {
      // Will fetch new tokens with updated user attributes
      await getCurrentAuthenticatedUser({ bypassCache: true });
    } catch (error) {
      console.error('Error updating local cognito user session', error);
      setIsError(true);
    }
  };

  /**
   * Mark onboarding as complete and refresh the local cognito user session
   */
  const completeOnboarding = async () => {
    try {
      setIsLoading(true);

      const accessToken = await getAccessTokenHelper();
      await updateUserOnboardingStatusMutation.mutateAsync({ accessToken });

      await refreshCognitoLocalUserSession();
    } catch (error) {
      console.error('Error updating onboarding status', error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    completeOnboarding();
  }, []);

  const navigateToDashboard = async () => {
    navigate('/dashboard');
  };

  const { successPage, buttons } = useStrapiOnBoardingData();
  const { successPageTitle, successPageDescription, successPageLoading } = successPage;
  const { goToDashboardButton } = buttons;

  if (isLoading) {
    return (
      <SuccessContainer>
        <BasicSpinner className="!m-0 text-white" />
        <div className="mt-8 flex flex-col gap-2 ">
          <h1 className="text-lg font-semibold text-zinc-50 lg:text-xl">{successPageLoading}</h1>
        </div>
      </SuccessContainer>
    );
  }

  if (isError) {
    return (
      <SuccessContainer>
        <ErrorDisplay
          message="Error gettings things ready for you"
          isRefetching={isLoading}
          refetch={async () => {
            setIsError(false);
            // If there is an error in onboarding call,
            // call onboarding success api else refresh the local cognito session
            if (updateUserOnboardingStatusMutation.isError) {
              await completeOnboarding();
            } else {
              await refreshCognitoLocalUserSession();
            }
          }}
          allowsRefetch={true}
        />
      </SuccessContainer>
    );
  }

  return (
    <SuccessContainer>
      <Emoji symbol="🎉" label="success" className="text-5xl" />
      <div className="mt-8 flex flex-col gap-2 ">
        <h1 className="text-lg font-semibold text-zinc-50 lg:text-xl">{successPageTitle}</h1>
        <p className="text-sm leading-5 text-zinc-400">{successPageDescription}</p>
      </div>
      <Button
        onClick={navigateToDashboard}
        className="mt-8 flex w-full items-center justify-center gap-2 bg-base-brand text-sm font-semibold text-white lg:w-fit"
      >
        {goToDashboardButton} <ArrowRight weight="bold" />
      </Button>
    </SuccessContainer>
  );
};
export default Success;
