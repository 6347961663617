import { useQuery } from '@tanstack/react-query';
import { getGoalCategory } from '../api';
import { REQUESTS_STALE_TIME } from '../constants';

const useGoalCategoryQuery = () => {
  const { data, isError, error, isLoading, refetch, isRefetching } = useQuery(
    [
      {
        scope: 'goalCategory',
        item: 'goal-category',
      },
    ],
    async () => {
      return getGoalCategory();
    },
    {
      staleTime: REQUESTS_STALE_TIME,
    }
  );

  return { data, isError, error, isLoading, refetch, isRefetching };
};

export default useGoalCategoryQuery;
