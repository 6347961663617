import { ChannelMessage } from '@aws-sdk/client-chime-sdk-messaging';
import { useCallback, useEffect } from 'react';
import {
  CHIME_MESSAGE_PERSISTENCE,
  CHIME_MESSAGING_EVENT_TYPE,
} from '../features/messaging/constants';
import { MessageUpdateCallback, useChimeMessagingService } from '../features/messaging/hooks';
import { loadedActiveChannelMessages } from '../features/messaging/slices';
import useAppDispatch from './use-app-dispatch';
import useAppSelector from './use-app-selector';
import useReduxAuthState from './use-redux-auth-state';

const useSubscribeToActiveChannelMessages = () => {
  const auth = useReduxAuthState();
  const appDispatch = useAppDispatch();

  const chimeMessagingService = useChimeMessagingService();

  const currentMessagesList = useAppSelector((state) => state.messaging.messages.list);
  const activeChannel = useAppSelector((state) => state.messaging.channels.activeChannel);

  const processChannelMessage = useCallback(
    (message: ChannelMessage) => {
      // Whether the received message is a duplicate
      let isDuplicate = false;

      let newMessagesList = currentMessagesList.map((m) => {
        if (m?.MessageId === message.MessageId) {
          isDuplicate = true;
          return message;
        }
        return m;
      });

      // We would only want to add persistent messages to the list and not duplicates
      if (isDuplicate === false && message.Persistence === CHIME_MESSAGE_PERSISTENCE.PERSISTENT) {
        newMessagesList = [...newMessagesList, message];
      }

      appDispatch(loadedActiveChannelMessages([...newMessagesList]));
    },
    [appDispatch, currentMessagesList]
  );

  // Process channel message events
  const channelMessagesProcessor: MessageUpdateCallback = useCallback(
    (message) => {
      try {
        const messageType = message.type;
        const record = JSON.parse(message.payload);

        switch (messageType) {
          case CHIME_MESSAGING_EVENT_TYPE.CREATE_CHANNEL_MESSAGE:
          case CHIME_MESSAGING_EVENT_TYPE.REDACT_CHANNEL_MESSAGE:
          case CHIME_MESSAGING_EVENT_TYPE.UPDATE_CHANNEL_MESSAGE:
          case CHIME_MESSAGING_EVENT_TYPE.DELETE_CHANNEL_MESSAGE:
          case CHIME_MESSAGING_EVENT_TYPE.DENIED_CREATE_CHANNEL_MESSAGE:
          case CHIME_MESSAGING_EVENT_TYPE.FAILED_CREATE_CHANNEL_MESSAGE:
          case CHIME_MESSAGING_EVENT_TYPE.PENDING_CREATE_CHANNEL_MESSAGE:
          case CHIME_MESSAGING_EVENT_TYPE.DENIED_UPDATE_CHANNEL_MESSAGE:
          case CHIME_MESSAGING_EVENT_TYPE.FAILED_UPDATE_CHANNEL_MESSAGE:
          case CHIME_MESSAGING_EVENT_TYPE.PENDING_UPDATE_CHANNEL_MESSAGE: {
            const channelMessage = record as ChannelMessage;

            // If the new message is for the current channel
            if (channelMessage.ChannelArn === activeChannel?.ChannelArn) {
              processChannelMessage(channelMessage);
            }
          }
        }
      } catch (error) {
        console.error('Error processing message', message);
      }
    },
    [activeChannel, processChannelMessage]
  );

  useEffect(() => {
    if (chimeMessagingService && auth.isAuthenticated && activeChannel !== null) {
      const { subscribeToMessageUpdate, unsubscribeFromMessageUpdate } = chimeMessagingService;
      subscribeToMessageUpdate(channelMessagesProcessor);

      return () => unsubscribeFromMessageUpdate(channelMessagesProcessor);
    }
  }, [activeChannel, auth.isAuthenticated, channelMessagesProcessor, chimeMessagingService]);
};

export default useSubscribeToActiveChannelMessages;
