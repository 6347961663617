import { Icon } from 'phosphor-react';
import cn from '../../../../utils/cn';

type HelpWidgetItemContent = {
  Icon: Icon;
  label: string;
  className?: string;
};

const HelpWidgetItemContent = (props: HelpWidgetItemContent) => {
  const { Icon, label, className } = props;
  return (
    <div
      className={cn(
        'flex flex-row items-center gap-2 text-sm font-medium leading-5 text-zinc-50',
        className
      )}
    >
      <Icon color="currentColor" size={16} className="shrink-0"></Icon>
      <span>{label}</span>
    </div>
  );
};

export { HelpWidgetItemContent };
