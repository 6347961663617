import { useMemo, useState } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import CategoryCell from './TableCells/Category';
import { TableDataRow } from '../../../../../components/DataTables';
import { generateCDNMediaAssetURLWithKey } from '../../../../shared/helpers';
import BadgeTableHeaderRow from './BadgeTableHeaderRow';
import { Badges } from '../../../types/badgeManagement';
import BadgeName from './TableCells/BadgeName';
import BadgeDescription from './TableCells/BadgeDescription';
import AttainedByCount from './TableCells/AttainedByCount';
import AddedOn from './TableCells/AddedOn';
import { BsLayoutThreeColumns } from 'react-icons/bs';
import BadgeTableDropDown from './BadgeTableDropDown';
import useStrapiBadgesData from '../../../hooks/useStrapiBadgeData';
import Dialog from '../../../../../components/Dialog';
import BadgeDetails from '../../BadgeDetails/BadgeDetails';
import { BadgeBase } from '../../../types/badges';
interface Props {
  className?: string;
  data: Badges[];
}
interface TableHeading {
  badgeTableheader: string;
  badgeDescriptionTableheader: string;
  addedOnTableheader: string;
  attainedByTableheader: string;
  categoryTableheader: string;
}
const getHeaders = (data: TableHeading) => {
  const columnHelper = createColumnHelper<Badges>();
  const {
    badgeTableheader,
    badgeDescriptionTableheader,
    addedOnTableheader,
    attainedByTableheader,
    categoryTableheader,
  } = data;
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: badgeTableheader,
        cell: ({ row, getValue }) => (
          <BadgeName
            badgeName={getValue() as string}
            badgePic={generateCDNMediaAssetURLWithKey({ key: row.original.imageUrl })}
          />
        ),
        id: 'BADGE_NAME',
      }),
      columnHelper.accessor('description', {
        header: badgeDescriptionTableheader,
        cell: ({ getValue }) => <BadgeDescription description={getValue() as string} />,
        id: 'BADGE_DESCRIPTION',
      }),
      columnHelper.accessor('attainedBy', {
        header: attainedByTableheader,
        cell: ({ getValue }) => <AttainedByCount count={getValue() as number} />,
        id: 'ATTAINED_BY',
      }),
      columnHelper.accessor('category', {
        header: categoryTableheader,
        cell: ({ getValue }) => <CategoryCell categoryName={getValue() as string} />,
        id: 'CATEGORY',
      }),
      columnHelper.accessor('addedOn', {
        header: addedOnTableheader,
        cell: (props) => <AddedOn addedOn={props.getValue() as string} />,
        id: 'CREATED_ON',
      }),
      columnHelper.display({
        header: () => <BsLayoutThreeColumns />,
        cell: ({ row }) => (
          <div onClick={(event) => event.stopPropagation()}>
            <BadgeTableDropDown badgeId={Number(row.original?.id)} />
          </div>
        ),
        id: 'edit',
      }),
    ],
    [
      badgeTableheader,
      badgeDescriptionTableheader,
      attainedByTableheader,
      categoryTableheader,
      addedOnTableheader,
    ]
  );
  return columns;
};
const BadgesTable = ({ className, data }: Props) => {
  const [modalState, setModalState] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>();
  const { Tableheader } = useStrapiBadgesData().table;
  const columns = getHeaders(Tableheader);
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableMultiSort: false,
    state: {
      expanded: true,
    },
  });

  const showBadgeDetailsModal = (badge: any) => {
    const { id, name, description, imageUrl } = badge;
    const badgeModalProps: BadgeBase = {
      badgeId: id,
      badgeName: name,
      badgeDescription: description,
      imageUrl: imageUrl,
    };
    setModalData(badgeModalProps);
    setModalState(true);
  };

  return (
    <table className={clsx('w-full', className)}>
      <thead className="w-full">
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <BadgeTableHeaderRow
                  isSortable={true}
                  allowMultiColSort={true}
                  header={header}
                  key={header.id}
                />
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <TableDataRow
            tdClassName={clsx('max-w-[400px] overflow-hidden text-ellipsis px-2 py-3')}
            className="cursor-pointer"
            row={row}
            key={row.original.id}
            onClick={() => showBadgeDetailsModal(row.original)}
          />
        ))}
        <Dialog
          open={modalState}
          onOpenChange={() => setModalState(false)}
          Content={<BadgeDetails badgeData={modalData} />}
        ></Dialog>
      </tbody>
    </table>
  );
};

export default BadgesTable;
