import { useQuery } from '@tanstack/react-query';
import { getAnnouncement } from '../api';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';
import { SETTING_API_PATHS } from '../constants';
import { QUERY_STALE_TIME } from '../constants/query';

type Props = {
  enabled?: boolean;
  language?: string;
};

const useGetAnnouncementQuery = (props?: Props) => {
  const { enabled = true, language = DEFAULT_PREFERRED_LANGUAGE } = props ?? {};

  const query = useQuery({
    queryKey: [
      {
        scope: 'shared',
        item: 'announcement',
        language,
      },
    ],
    queryFn: async () => {
      const response = await getAnnouncement({ language });
      return response;
    },
    staleTime: QUERY_STALE_TIME[SETTING_API_PATHS.HQ_ANNOUNCEMENT],
    refetchOnMount: false,
    enabled,
  });

  return query;
};

export default useGetAnnouncementQuery;
