import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

export const useStrapiTeamData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  let addTeam;
  let addSubTeam;
  let addMember;
  let editTeam;
  let topNav;

  let singlePerson;
  let singlePersonProfile;
  let commomTeamErrorMessage;
  let subscriptionInfo;
  let loadingMessages;

  const strapiTeamCommon = data.team.data.attributes.COMMON;
  for (let i = 0; i <= strapiTeamCommon?.length; i++) {
    if (strapiTeamCommon[i]?.__typename === 'ComponentTeamAddTeamModal') {
      addTeam = (strapiTeamCommon ?? [])[i];
    } else if (strapiTeamCommon[i]?.__typename === 'ComponentTeamAddSubTeam') {
      addSubTeam = (strapiTeamCommon ?? [])[i];
    } else if (strapiTeamCommon[i]?.__typename === 'ComponentTeamAddMember') {
      addMember = (strapiTeamCommon ?? [])[i];
    } else if (strapiTeamCommon[i]?.__typename === 'ComponentTeamEditTeam') {
      editTeam = (strapiTeamCommon ?? [])[i];
    } else if (strapiTeamCommon[i]?.__typename === 'ComponentTeamTopNav') {
      topNav = (strapiTeamCommon ?? [])[i];
    } else if (strapiTeamCommon[i]?.__typename === 'ComponentTeamSinglePerson') {
      singlePerson = (strapiTeamCommon ?? [])[i];
    } else if (strapiTeamCommon[i]?.__typename === 'ComponentTeamSinglePersonProfile') {
      singlePersonProfile = (strapiTeamCommon ?? [])[i];
    } else if (strapiTeamCommon[i]?.__typename === 'ComponentTeamErrormessages') {
      commomTeamErrorMessage = (strapiTeamCommon ?? [])[i];
    } else if (strapiTeamCommon[i]?.__typename === 'ComponentTeamLoadingMessages') {
      loadingMessages = (strapiTeamCommon ?? [])[i];
    }
  }
  const strapiTeamEnterpriseAdmin = data.team.data.attributes.ENTERPRISE_ADMIN;
  let teamTable;
  let singleTeamTable;
  let adminToastMessages;
  for (let i = 0; i <= strapiTeamEnterpriseAdmin?.length; i++) {
    if (strapiTeamEnterpriseAdmin[i]?.__typename === 'ComponentTeamAdminTeams') {
      teamTable = (strapiTeamEnterpriseAdmin ?? [])[i];
    } else if (strapiTeamEnterpriseAdmin[i]?.__typename === 'ComponentTeamListMembers') {
      singleTeamTable = (strapiTeamEnterpriseAdmin ?? [])[i];
    } else if (strapiTeamEnterpriseAdmin[i]?.__typename === 'ComponentTeamTeamsToastMessage') {
      adminToastMessages = (strapiTeamEnterpriseAdmin ?? [])[i];
    }
  }
  const strapiTeamEnterpriseLeader = data.team.data.attributes.ENTERPRISE_LEADER;
  let teamCard;
  let singleTeam;
  let leaderManagerToastMessages;

  for (let i = 0; i <= strapiTeamEnterpriseLeader?.length; i++) {
    if (strapiTeamEnterpriseLeader[i]?.__typename === 'ComponentTeamTeamCard') {
      teamCard = (strapiTeamEnterpriseLeader ?? [])[i];
    } else if (strapiTeamEnterpriseLeader[i]?.__typename === 'ComponentTeamSingleTeam') {
      singleTeam = (strapiTeamEnterpriseLeader ?? [])[i];
    } else if (strapiTeamEnterpriseLeader[i]?.__typename === 'ComponentTeamTeamsToastMessage') {
      leaderManagerToastMessages = (strapiTeamEnterpriseLeader ?? [])[i];
    }
  }
  // add team contents
  const {
    title,
    teamName: TeamName,
    managerDetails,
    memberDetails,
    addAnotherMemberButton,
    removeMemberButton,
    goToBillingButton: addTeamBillingButton,
    addTeamButton,
    cancelButton,
    addTeamRequestButton,
    addNewMemberButton,
  } = addTeam;
  const {
    title: teamNameTitle,
    inputFieldName1: teamNameLabel,
    placeHolderForField1: teamNamePlaceHolder,
  } = TeamName;
  const {
    title: managerDetailsTitle,
    inputFieldName1: managerEmailLabel,
    placeHolderForField1: managerEmailPlaceHolder,
    inputFieldName2: managerJobLabel,
    placeHolderForField2: managerJobTitlePlaceHolder,
  } = managerDetails;
  const {
    title: memberDetailsTitle,
    inputFieldName1: memberEmailLabel,
    placeHolderForField1: memberEmailPlaceHolder,
    inputFieldName2: memberJobLabel,
    placeHolderForField2: memberJobTitlePlaceHolder,
  } = memberDetails;

  // add Sub team Modal contents
  const {
    title: subTeamTitle,
    teamName: subTeamName,
    managerDetails: subTeamManagerDetails,
    memberDetails: subTeamMemberDetails,
    addAnotherMemberButton: subTeamAddAnotherMemberButton,
    addNewMemberButton: subTeamAddNewMemberButton,
    removeMemberButton: subTeamRemoveMemberButton,
    addTeamButton: subTeamAddTeamButton,
    cancelButton: subTeamCancelButton,
    addTeamRequestButton: subTeamAddTeamRequestButton,
    goToBillingButton: subTeamBillingButton,
  } = addSubTeam;
  const {
    title: subTeamNameTitle,
    inputFieldName1: subTeamNameLabel,
    placeHolderForField1: subTeamNamePlaceHolder,
  } = subTeamName;
  const {
    title: subTeamManagerDetailsTitle,
    inputFieldName1: subTeamManagerEmailLabel,
    placeHolderForField1: subTeamManagerEmailPlaceHolder,
    inputFieldName2: subTeamManagerJobLabel,
    placeHolderForField2: subTeamManagerJobTitlePlaceHolder,
  } = subTeamManagerDetails;
  const {
    title: subTeamMemberDetailsTitle,
    inputFieldName1: subTeamMemberEmailLabel,
    placeHolderForField1: subTeamMemberEmailPlaceHolder,
    inputFieldName2: subTeamMemberJobLabel,
    placeHolderForField2: subTeamMemberJobTitlePlaceHolder,
  } = subTeamMemberDetails;

  // add member contents
  const {
    title: addTeamMemberTitle,
    addMemberButton,
    addMemberRequestButton,
    goToBillingButton: addMemberBillingButton,
    cancelButton: addMemberCancelButton,
    memberNameLabel: addMemberNameLabel,
    memberNamePlaceHolder: addMemberNamePlaceHolder,
    memberTeamLabel: addMemberTeamNameLabel,
    memberTeamPlaceHolder: addMemberTeamNamePlaceHolder,
    memberEmailLabel: addMemberEmailLabel,
    memberEmailPlaceHolder: addMemberPlaceHolder,
    jobTitleLabel: addMemberJobTitleLabel,
    jobTitlePlaceHolder: addMemberJobTitlePlaceHolder,
    memberRoleLabel: addMemberSelectRole,
    memberRolePlaceHolder: addMemberSelectRolePlaceHolder,
    Roles,
    ResendInvite,
  } = addMember;
  const { member: memberRole, manager: managerRole, leader: leaderRole } = Roles;
  const { successToastTitle, successToastSubTitle, errorToastTitle, errorToastSubTitle } =
    ResendInvite;

  // edit Team contents
  const {
    title: editTeamTitle,
    updateTeamButton,
    updateTeamRequestButton,
    cancelButton: editTeamCancelButton,
    fieldName: editTeamNameLabel,
    placeHolder: editTeamNamePlaceHolder,
  } = editTeam;
  // Top Nav contents
  const {
    searchPlaceHolder: topNavSearchPlaceHolder,
    addTeamButton: topNavAddTeamButton,
    addMemberButton: topNavAddMemberButton,
    addSubTeamButton: topNavAddSubTeamButton,
    editTeamButton: topNavEditTeamButton,
    backButton: topNavBackButton,
    changeTeamButton: topNavChangeTeamButton,
    updateRoleButton: topNavUpdateRoleButton,
    teamTitle: topNavTeamTitle,
  } = topNav;
  // Admin Team table contents
  const { listAllTeamsTableHeader, lisAllTeamsEditRowOptions } = teamTable;
  const {
    teamName: teamNameHeader,
    teamManager: teamManagerHeader,
    noOfMembers: teamMembersCountHeader,
    createdOn: createdDateHeader,
  } = listAllTeamsTableHeader;
  const {
    option1: teamTableDropDownOption1,
    option2: teamTableDropDownOption2,
    option3: teamTableDropDownOption3,
  } = lisAllTeamsEditRowOptions;
  // table props

  // Leader All Team cards contents
  const {
    manager: managerLabel,
    assignedCourses: assignedCoursesLabel,
    subTeams: subTeamsLabel,
    courseCompletion: courseCompletionLabel,
    requestSentToAdminLabel,
  } = teamCard;

  // Leader Manager single Team
  const { SingleTeamHeader, SingleTeamStatistics, EmptyMessages } = singleTeam;
  const {
    subTeamLabel: singleTeamSubTeamLabel,
    managerLabel: singleTeamManagerLabel,
    memberLabel: singleTeamMemberLabel,
  } = SingleTeamHeader;
  const {
    avgSessionLabel: singleTeamAvgSessionLabel,
    coursesAssignedLabel: singleTeamCoursesAssignedLabel,
    coursesCompletionLabel: singleTeamCoursesCompletionLabel,
    courseCompletedLabel: singleTeamCourseCompletedLabel,
    totalBadgesEarnedLabel: singleTeamTotalBadgesEarnedLabel,
    memberCountLabel: singleTeamMemberCountLabel,
    mostPopularBadgesLabel: singleTeamMostPopularBadgesLabel,
  } = SingleTeamStatistics;

  const { noTeamMembersMessage } = EmptyMessages;

  // single Team Member
  const { tableHeader, tableSortOptions, tableRowEditOptions } = singleTeamTable;
  const {
    status: singleTeamTableStatusHeader,
    jobTitle: singleTeamTableJobTitleHeader,
    lastLogin: singleTeamTableLastLoginHeader,
    name: singleTeamTableNameHeader,
    email: singleTeamTableEmailHeader,
    role: singleTeamTableRoleHeader,
  } = tableHeader;
  const {
    title: singleTeamSortPlaceHolder,
    option1: singleTeamSortActive,
    option2: singleTeamSortInvited,
    option3: singleTeamSortDeactivated,
  } = tableSortOptions;
  const {
    option1: activateMemberOption,
    option2: resendInviteOption,
    option3: deactivateMemberOption,
    option4: disableMFAForUserOption,
    option5: changeTeamButton,
    option6: changeRoleButton,
  } = tableRowEditOptions;

  // single person view
  const {
    memberDetails: personalDetails,
    courseHistory,
    singlePersonProps,
    singlePersonHeader,
  } = singlePerson;
  const { title: courseAssignedTitle } = singlePersonProfile;
  const { noBadgesFoundLabel, noCoursesAssignedLabel, viewAllButton, noCoursesAssignedMessage } =
    singlePersonProps;
  const {
    teamName: teamLabel,
    loginRate: loginRateLabel,
    averageWeeklyLearningHours: averageWeeklyLearningHoursLabel,
    badgesEarned: badgesEarnedLabel,
    lastLogin: lastLoginLabel,
    manager: managerlabel,
    perWeekLabel,
    hoursLabel,
    weeksLabel,
  } = personalDetails;
  const {
    totalLearningHours: totalLearningHoursLabel,
    assignedCourses: assignedCoursesHistoryLabel,
    completedCourses: completedCoursesLabel,
    inProgressCourses: inProgressCoursesLabel,
    mostEngagedCategory: mostEngagedCategoryLabel,
    averageAssessmentScore: averageAssessmentScoreLabel,
    title: courseHistoryTitle,
  } = courseHistory;

  // admin toast messages
  const {
    successToastMessage: adminSuccessToastMessage,
    failureToastMessage: adminFailureToastMessage,
    actionProcessingToastMessage: adminActionProcessingToastMessage,
    updateFailureToastMessage: adminUpdateFailureToastMessage,
    updateSuccessfulToastMessage: adminUpdateSuccessfulToastMessage,
    updateProcessingToastMessage: adminUpdateProcessingToastMessage,
    memberAdditionSuccessToastMessage,
    memberAdditionFailureToastMessage,
    memberAdditionProcessingToastMessage,
    subTeamAddedSuccessMessage: adminSubTeamAddedSuccessMessage,
  } = adminToastMessages;

  // leader and manager toast messages
  const {
    successToastMessage: leaderManagerSuccessToastMessage,
    failureToastMessage: leaderManagerFailureToastMessage,
    actionProcessingToastMessage: leaderManagerActionProcessingToastMessage,
  } = leaderManagerToastMessages;

  // error messages
  const {
    memberAlreadyExists,
    teamAlreadyExists,
    managerMemberSameError,
    teamNameRequired,
    managerEmailRequired,
    memberEmailRequired,
    jobTitleRequired,
    memberRoleRequired,
    jobTitleMaxError,
    jobTitleMinError,
    emailLimitError,
    validEmailError,
    teamNameMinError,
    teamNameMaxError,
    teamNameMinValue,
    teamNameMaxValue,
    emailMaxValue,
    jobTitleMinValue,
    jobTitleMaxValue,
    sameMemberErrorMessage,
    sameMemberError,
    teamNameAlreadyExistMessage,
    managerMemberSameErrorMessage,
    memberAlreadyExistsMessage,
    memberNameRequired,
    memberNameMinValue,
    memberNameMinError,
    memberNameMaxError,
    memberNameMaxValue,
  } = commomTeamErrorMessage;
  return {
    singlePersonHeader,
    subscriptionInfo,
    commomTeamErrorMessage: {
      memberNameRequired,
      memberNameMinValue,
      memberNameMinError,
      memberNameMaxError,
      memberNameMaxValue,
      memberAlreadyExists,
      memberAlreadyExistsMessage,
      teamAlreadyExists,
      managerMemberSameError,
      teamNameRequired,
      managerEmailRequired,
      memberEmailRequired,
      jobTitleRequired,
      memberRoleRequired,
      jobTitleMaxError,
      jobTitleMinError,
      emailLimitError,
      validEmailError,
      teamNameMinError,
      teamNameMaxError,
      teamNameMinValue,
      teamNameMaxValue,
      emailMaxValue,
      jobTitleMinValue,
      jobTitleMaxValue,
      sameMemberErrorMessage,
      sameMemberError,
      teamNameAlreadyExistMessage,
      managerMemberSameErrorMessage,
    },
    adminToastMessages: {
      adminSuccessToastMessage,
      adminFailureToastMessage,
      adminSubTeamAddedSuccessMessage,
      adminActionProcessingToastMessage,
      adminUpdateFailureToastMessage,
      adminUpdateSuccessfulToastMessage,
      adminUpdateProcessingToastMessage,
      memberAdditionSuccessToastMessage,
      memberAdditionFailureToastMessage,
      memberAdditionProcessingToastMessage,
    },
    leaderManagerToastMessages: {
      leaderManagerSuccessToastMessage,
      leaderManagerFailureToastMessage,
      leaderManagerActionProcessingToastMessage,
    },
    singlePerson: {
      hoursLabel,
      weeksLabel,
      perWeekLabel,
      courseHistoryTitle,
      noBadgesFoundLabel,
      viewAllButton,
      noCoursesAssignedLabel,
      noCoursesAssignedMessage,
      courseAssignedTitle,
      teamLabel,
      loginRateLabel,
      managerlabel,
      averageWeeklyLearningHoursLabel,
      badgesEarnedLabel,
      lastLoginLabel,
      totalLearningHoursLabel,
      completedCoursesLabel,
      assignedCoursesHistoryLabel,
      inProgressCoursesLabel,
      mostEngagedCategoryLabel,
      averageAssessmentScoreLabel,
    },

    singleTeamTable: {
      singleTeamTableStatusHeader,
      singleTeamTableJobTitleHeader,
      singleTeamTableLastLoginHeader,
      singleTeamTableNameHeader,
      singleTeamTableEmailHeader,
      singleTeamTableRoleHeader,
      singleTeamSortPlaceHolder,
      singleTeamSortActive,
      singleTeamSortInvited,
      singleTeamSortDeactivated,
      activateMemberOption,
      resendInviteOption,
      deactivateMemberOption,
      disableMFAForUserOption,
      changeTeamButton,
      changeRoleButton,
    },
    singleTeam: {
      singleTeamSubTeamLabel,
      singleTeamManagerLabel,
      singleTeamMemberLabel,
      singleTeamAvgSessionLabel,
      singleTeamCoursesAssignedLabel,
      singleTeamCoursesCompletionLabel,
      singleTeamCourseCompletedLabel,
      singleTeamTotalBadgesEarnedLabel,
      singleTeamMemberCountLabel,
      singleTeamMostPopularBadgesLabel,
      noTeamMembersMessage,
    },

    teamCard: {
      assignedCoursesLabel,
      subTeamsLabel,
      courseCompletionLabel,
      managerLabel,
      requestSentToAdminLabel,
    },
    teamTable: {
      teamNameHeader,
      teamManagerHeader,
      teamMembersCountHeader,
      createdDateHeader,
      teamTableDropDownOption1,
      teamTableDropDownOption2,
      teamTableDropDownOption3,
    },
    topNav: {
      topNavSearchPlaceHolder,
      topNavAddTeamButton,
      topNavAddMemberButton,
      topNavAddSubTeamButton,
      topNavEditTeamButton,
      topNavBackButton,
      topNavUpdateRoleButton,
      topNavChangeTeamButton,
      topNavTeamTitle,
    },
    editTeam: {
      editTeamTitle,
      updateTeamButton,
      updateTeamRequestButton,
      editTeamCancelButton,
      editTeamNameLabel,
      editTeamNamePlaceHolder,
    },
    addteam: {
      title,
      addAnotherMemberButton,
      addTeamBillingButton,
      removeMemberButton,
      addTeamButton,
      cancelButton,
      addTeamRequestButton,
      teamNameTitle,
      teamNameLabel,
      teamNamePlaceHolder,
      memberDetailsTitle,
      memberEmailLabel,
      memberEmailPlaceHolder,
      memberJobLabel,
      memberJobTitlePlaceHolder,
      managerDetailsTitle,
      managerEmailLabel,
      managerEmailPlaceHolder,
      managerJobLabel,
      managerJobTitlePlaceHolder,
      addNewMemberButton,
    },
    addsubteam: {
      subTeamTitle,
      subTeamAddAnotherMemberButton,
      subTeamAddNewMemberButton,
      subTeamBillingButton,
      subTeamRemoveMemberButton,
      subTeamAddTeamButton,
      subTeamCancelButton,
      subTeamAddTeamRequestButton,
      subTeamNameTitle,
      subTeamNameLabel,
      subTeamNamePlaceHolder,
      subTeamManagerDetailsTitle,
      subTeamManagerEmailLabel,
      subTeamManagerEmailPlaceHolder,
      subTeamManagerJobLabel,
      subTeamManagerJobTitlePlaceHolder,
      subTeamMemberDetailsTitle,
      subTeamMemberEmailLabel,
      subTeamMemberEmailPlaceHolder,
      subTeamMemberJobLabel,
      subTeamMemberJobTitlePlaceHolder,
    },
    addmember: {
      addTeamMemberTitle,
      addMemberButton,
      addMemberBillingButton,
      addMemberRequestButton,
      addMemberCancelButton,
      addMemberEmailLabel,
      addMemberPlaceHolder,
      addMemberJobTitleLabel,
      addMemberJobTitlePlaceHolder,
      addMemberSelectRole,
      addMemberSelectRolePlaceHolder,
      addMemberNameLabel,
      addMemberNamePlaceHolder,
      addMemberTeamNameLabel,
      addMemberTeamNamePlaceHolder,
      memberRole,
      managerRole,
      leaderRole,
      successToastTitle,
      successToastSubTitle,
      errorToastTitle,
      errorToastSubTitle,
    },
    loadingMessages,
  };
};
