import clsx from 'clsx';
import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchInput from '../../../../components/SearchInput';
import { useStrapiSharedData } from '../../../shared/hooks/useStrapiSharedData';
interface Props {
  className?: string;
  q: string | undefined;
  status: string | undefined | null;
}

const ListIndividualTableHeader = ({ className, q }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const handleQChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    searchParams.set('q', value);
    navigate('?' + searchParams.toString());
  };

  const handleResetFiltersBtnClick = () => {
    navigate('.');
  };
  const {
    tableProps: { clearAllFiltersButton, searchPlaceHolder },
  } = useStrapiSharedData();
  return (
    <div
      className={clsx(
        'flex w-full flex-col justify-between gap-2 text-[14px] font-normal leading-[20px] text-zinc-100 sm:flex-row',
        className
      )}
    >
      <div className="flex flex-col gap-2 lg:flex-row">
        <SearchInput
          placeholder={searchPlaceHolder}
          containerClassName="min-w-[200px]"
          iconClassName="left-2"
          className="h-9 rounded-sm border border-zinc-900 bg-zinc-900 p-2 pl-9 text-[14px] font-normal leading-[20px] text-zinc-100 placeholder:text-zinc-500"
          value={q ?? ''}
          onChange={handleQChange}
        />
        {q && q.length !== 0 && (
          <div className="flex flex-col gap-2 lg:flex-row">
            <button
              onClick={handleResetFiltersBtnClick}
              className="rounded-sm border border-zinc-900 bg-zinc-900 py-1 px-4 text-center"
            >
              {clearAllFiltersButton}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ListIndividualTableHeader);
