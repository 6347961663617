import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { USHGAPIError } from '../../../types';
import { getInitBulkInviteErrorMessage } from '../../shared/helpers';
import { useBulkUploadErrorMap } from '../../shared/hooks/useBulkUploadErrorMap';
import { initBulkInviteUsers, InitBulkInviteUsersArgs } from '../api';
import { ERROR_CODE } from '../constants';
import useStrapiMembersData from './useStrapiMembersData';

interface Props {
  isOnboarding?: boolean;
}

const useInitBulkInviteUserMutation = (props: Props) => {
  const { isOnboarding = false } = props;

  const {
    toastMessage: { bulkUploadSuccess, bulkUploadFailure, bulkUploadSuccessMessage },
  } = useStrapiMembersData();
  const { errorMap: BULK_UPLOAD_ERROR_MESSAGE_CODE_TO_MESSAGE_MAP } = useBulkUploadErrorMap();

  const mutation = useMutation({
    mutationFn: async (args: InitBulkInviteUsersArgs) => {
      const response = await initBulkInviteUsers(args);
      return response;
    },
    onSuccess: () => {
      toast.custom((t) => {
        return (
          <Toast
            toastInstance={t}
            Title={bulkUploadSuccess}
            SubTitle={bulkUploadSuccessMessage}
            variant="success"
          />
        );
      });
    },
    onError: (error) => {
      // We will show a generic message in the toast and the actual message
      // will be below the bulk upload component

      let errorSubTitle: string | null = null;

      if (axios.isAxiosError(error)) {
        const { errorCode, errorMessage } = (error.response?.data as USHGAPIError) ?? {};

        errorSubTitle = getInitBulkInviteErrorMessage({
          errorCode,
          errorMessage,
          errorCodeToMessageMap: BULK_UPLOAD_ERROR_MESSAGE_CODE_TO_MESSAGE_MAP,
          isOnboarding,
        });
      }

      errorSubTitle =
        errorSubTitle ??
        BULK_UPLOAD_ERROR_MESSAGE_CODE_TO_MESSAGE_MAP[ERROR_CODE.BULK_UPLOAD_UNKNOWN_ERROR];

      toast.custom((t) => {
        return (
          <Toast
            toastInstance={t}
            Title={bulkUploadFailure}
            SubTitle={errorSubTitle}
            variant="error"
          />
        );
      });
    },
  });

  return mutation;
};

export default useInitBulkInviteUserMutation;
