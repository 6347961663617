import { useQuery } from '@tanstack/react-query';
import { learningJourneyCourses } from '../api/courses';
interface Props {
  learningJourneyId: string | undefined;
  start?: number;
  size?: number;
  excludeCourseStatuses?: string[];
  enabled?: boolean;
}

const useLearningJourneyCourses = ({
  learningJourneyId,
  start = 0,
  size = 4,
  excludeCourseStatuses,
  enabled = true,
}: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, isRefetching, refetch } =
    useQuery(
      [
        {
          learningJourneyId,
          scope: 'learning-journeys',
          item: 'learning-journey-recommended-courses',
          excludeCourseStatuses,
        },
      ],
      async () => {
        if (learningJourneyId) {
          return await learningJourneyCourses(
            learningJourneyId,
            start,
            size,
            excludeCourseStatuses
          );
        } else {
          return null;
        }
      },
      {
        enabled: learningJourneyId != undefined && enabled,
      }
    );

  return {
    isLoading,
    data: data?.results,
    isError,
    error,
    isPreviousData,
    isFetching,
    isRefetching,
    refetch,
  };
};

export default useLearningJourneyCourses;
