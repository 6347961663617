import clsx from 'clsx';
import { useContext, useMemo, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { ErrorDisplay } from '../../../../../components/DataTables';
import { ERROR_TYPES } from '../../../../../constants/error-types.constants';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { formatNumber } from '../../../../../utils';
import NoDataFallback from '../../../../shared/components/NoDataFallback';
import durationContext from '../../../../shared/context/durationContext';
import { useStrapiReportsData } from '../../../../shared/hooks/useStrapiReportData';
import { useUSHGAdminOverallChurnRateQuery } from '../../../hooks';
import { OverallChurnRateFilters } from '../../../types';
import { ChurnRate } from './StatusCard';
import ScaleLoader from '../../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../../components/ReportToolTip';
import { REPORT_TYPES } from '../../../../shared/constants';
import { ReportTooltipDataType } from '../../../../shared/types';

interface OverAllChurnRateProps {
  className: string;
  lazyLoad?: boolean;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}

export const OverAllChurnRate = ({
  className,
  lazyLoad = true,
  reportTooltipContent,
  userPersona,
}: OverAllChurnRateProps) => {
  const { duration } = useContext(durationContext);
  const { title } = useStrapiReportsData().OverAllChurnRate;
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);

  // Build the filter
  const filters: OverallChurnRateFilters = useMemo(() => {
    return {
      fromDate: duration.fromDate,
    };
  }, [duration]);

  const overallChurnRate = useUSHGAdminOverallChurnRateQuery({ filters, isEnabled: isInView });

  const displayLoader =
    (overallChurnRate.isLoading && !overallChurnRate.data) ||
    (overallChurnRate.isFetching &&
      overallChurnRate.data &&
      overallChurnRate.data.result &&
      Object.keys(overallChurnRate.data.result).length === 0);

  const hasOverallChurnRate =
    overallChurnRate.data &&
    overallChurnRate.data.result &&
    Object.keys(overallChurnRate.data.result).length > 0;

  const hasNoOverallChurnRate =
    overallChurnRate.data &&
    overallChurnRate.data.result &&
    Object.keys(overallChurnRate.data.result).length === 0;

  const strapidata = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  let increaseDirection, increasePercentage;
  const percentage =
    formatNumber(overallChurnRate.data?.result.percentage) > 0
      ? formatNumber(overallChurnRate.data?.result.percentage)
      : -formatNumber(overallChurnRate.data?.result.percentage);
  if (overallChurnRate.data?.result.increase !== 'Infinity') {
    increaseDirection = formatNumber(overallChurnRate.data?.result.increase) >= 0 ? 'inc' : 'desc';
    increasePercentage =
      formatNumber(overallChurnRate.data?.result.increase) > 0
        ? formatNumber(overallChurnRate.data?.result.increase)
        : -formatNumber(overallChurnRate.data?.result.increase);
  }
  return (
    <InView
      as="div"
      className={clsx('flex rounded-lg bg-zinc-800 p-2', className)}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div className="flex w-full flex-col justify-evenly px-4 text-white">
        <div className="flex gap-2">
          <span className="text-sm font-semibold leading-[150%] text-white">{title}</span>
          {reportTooltipContent?.[REPORT_TYPES.OVERALL_CHURN_RATE]?.[userPersona] && (
            <ReportTooltip
              content={reportTooltipContent[REPORT_TYPES.OVERALL_CHURN_RATE][userPersona]}
            />
          )}
        </div>
        {/* Loader */}
        {displayLoader && (
          <div className="flex grow items-center justify-center py-4">
            <ScaleLoader />
          </div>
        )}
        {/* We have data */}
        {hasOverallChurnRate && (
          <div className="flex items-center">
            <span className="text-[36px] font-bold">
              {percentage ? formatNumber(percentage) : 0}%
            </span>
            <div className="ml-auto ">
              <ChurnRate status={increaseDirection} data={increasePercentage} />
            </div>
          </div>
        )}
        {/* Empty State */}
        {hasNoOverallChurnRate && !displayLoader && (
          <div className="flex grow items-center justify-center">
            <NoDataFallback message="Overall Churn Rate not found." />
          </div>
        )}

        {overallChurnRate.isError && !overallChurnRate.data && (
          <div className="flex grow items-center justify-center">
            <ErrorDisplay
              refetch={overallChurnRate.refetch}
              isRefetching={overallChurnRate.isRefetching}
              allowsRefetch={true}
              type={ERROR_TYPES.SOMETHING_WENT_WRONG}
              message={strapiErrorMessage['SYSTEM_ERROR']}
            />
          </div>
        )}
      </div>
    </InView>
  );
};
