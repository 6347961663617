import clsx from 'clsx';
import Message from './Message';

type HTMLLabelProps = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

type CustomProps = {
  isRequired?: boolean;
  label: string;
  labelDescription?: string;
};

type Props = CustomProps & HTMLLabelProps;

const Label = ({ label, labelDescription, className, isRequired, ...otherProps }: Props) => {
  return (
    <>
      <label
        className={clsx('text-xs font-medium leading-4 text-zinc-400', className)}
        {...otherProps}
      >
        {label}
        {isRequired && <span className="sr-only"> (required)</span>}
      </label>
      {labelDescription && <Message messages={labelDescription} />}
    </>
  );
};

export default Label;
