import { Plus } from 'phosphor-react';
import React, { useState } from 'react';
import Dialog from '../../../../components/Dialog';
import useStrapiBadgesData from '../../hooks/useStrapiBadgeData';
import BadgeModal from './BadgeModal';
import '../../../../styles/header.css';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../constants/user';

const PageHeader = () => {
  const [isBadgeModalOpen, setIsBadgeModalOpen] = useState(false);
  const { modal, hqTopNav } = useStrapiBadgesData();
  const { addBadgeTitle, addBadgeButton } = modal;

  return (
    <header className="flex w-full flex-col justify-between gap-5  border-b border-b-zinc-800 bg-card-bg px-3 py-6 xs:flex-row xs:items-center xs:gap-0 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
      <span className="header-title">{hqTopNav.title}</span>
      <Dialog
        containerClassName="!max-w-2xl overflow-auto max-h-[80vh]"
        open={isBadgeModalOpen}
        onOpenChange={(open) => setIsBadgeModalOpen(open)}
        triggerClassName="flex justify-end basis-0 grow-0"
        Content={
          <BadgeModal
            modalTitle={addBadgeTitle}
            setIsBadgeModalOpen={setIsBadgeModalOpen}
            formActionButtonLabel={addBadgeButton}
            modalType={'create'}
            language={DEFAULT_PREFERRED_LANGUAGE}
          />
        }
        onInteractOutside={(e) => {
          // Donot close the Modal when we click outside the Dialog Content
          e.preventDefault();
        }}
      >
        <span className="flex w-full flex-row items-center justify-center gap-[6.7px] whitespace-nowrap rounded border-[0.837607px] border-base-brand bg-base-brand py-[6.7px] px-[13px] text-[11.7px] font-normal leading-[17px] text-white xs:w-fit">
          <Plus size={12} color="currentColor" className="text-inherit" />
          {hqTopNav.addBadgeButton}
        </span>
      </Dialog>
    </header>
  );
};

export default PageHeader;
