import Dialog from '../../../../../components/Dialog';
import { useStrapiTeamData } from '../../../hooks/useStrapiTeamData';
import AddNewTeamMemberModal from './addNewTeamMember';

interface Props {
  setIsModalOpen: (open: boolean) => void;
  teamId?: number;
  teamName?: string;
  currentIndex?: number;
  setCurrentIndex?: any;
  dropDownOptions: string;
  isLoading: boolean;
  INVITEE?: string;
  isOnBoarding: boolean;
  isAllMembers: boolean;
}

const AddNewMemberMain = ({
  teamId,
  teamName,
  setIsModalOpen,
  currentIndex,
  setCurrentIndex,
  dropDownOptions,
  isLoading,
  INVITEE,
  isOnBoarding,
  isAllMembers,
}: Props) => {
  const { addmember } = useStrapiTeamData();
  return (
    <div className="mx-1 flex flex-col gap-3 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
        {addmember.addTeamMemberTitle}
      </Dialog.Title>
      <AddNewTeamMemberModal
        teamName={teamName}
        teamId={teamId}
        setIsModalOpen={setIsModalOpen}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        INVITEE={INVITEE}
        ROLE_OPTIONS={dropDownOptions}
        isLoading={isLoading}
        isOnBoarding={isOnBoarding}
        isAllMembers={isAllMembers}
      />
    </div>
  );
};

export default AddNewMemberMain;
