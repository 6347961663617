import { useMutation } from '@tanstack/react-query';
import { mediaCheckPointUpdate } from '../api/courses';

const useMediaCheckPointMutation = () => {
  return useMutation({
    mutationFn: (payload: { moduleId: number; courseId: number; checkPointDuration: number }) =>
      mediaCheckPointUpdate(payload),
  });
};

export default useMediaCheckPointMutation;
