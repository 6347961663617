import { Option } from '../../../components/Select';

// Pagination
export const USHG_ADMIN_PAGE_SIZE_INCREMENT = 10;
export const USHG_ADMIN_MAX_PAGE_SIZE = 50;
export const USHG_ADMIN_MIN_PAGE_SIZE = 10;
export const USHG_ADMIN_DEFAULT_PAGE = 1;

// Sort
export const USHG_ADMIN_SORT_DEFAULT = '';

export const USHG_ADMIN_CATEGORY_QUERY_PARAM = 'category';
export const USHG_ADMIN_BADGES_CATEGORY_VALUES: Option[] = [
  {
    display: 'Accomplishment',
    value: 'COURSE_ACCOMPLISHMENT',
  },
  {
    display: 'Focus Area',
    value: 'FOCUS_AREA',
  },
];

export const STALE_TIME = 1000;
