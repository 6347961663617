// Pagination
export const ENT_ADMIN_PAGE_SIZE_INCREMENT = 10;
export const ENT_ADMIN_MAX_PAGE_SIZE = 50;
export const ENT_ADMIN_MIN_PAGE_SIZE = 10;
export const ENT_ADMIN_DEFAULT_PAGE = 1;

// Sort
export const ENT_ADMIN_SORT_DEFAULT = '';
export const ENT_ADMIN_SORTKEY_MAP: any = {
  teamName: 'TEAM_NAME',
  membersCount: 'MEMBERCOUNT',
  createdOn: 'CREATED_ON',
};

export const NO_TEAMS_AVAILABLE_TITLE = 'NO_TEAMS_AVAILABLE_TITLE';
export const NO_TEAMS_AVAILABLE_DESCRIPTION = 'NO_TEAMS_AVAILABLE_DESCRIPTION';
