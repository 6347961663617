type UserLoginHeaderProps = {
  heading: string;
  subHeading: string;
};

const UserLoginHeader = ({ heading, subHeading }: UserLoginHeaderProps) => {
  return (
    <div className="flex flex-col items-center gap-1">
      <h1 className="text-2xl font-extrabold leading-[35px] text-white">{heading}</h1>
      <p className="text-center text-sm leading-[25px]">{subHeading}</p>
    </div>
  );
};

export default UserLoginHeader;
