// Represents the tabs using in the TabsGroup.tsx
export const TAB_GROUPS = {
  TEAM_PERFORMANCE: 'team performance',
  INDIVIDUAL_PERFORMANCE: 'Individual Performance',
  OVERALL_PERFORMANCE: 'Overall Performance',
  PROFILE: 'profile',
  SECURITY: 'security',
  BILLING: 'billing',
  NOTIFICATIONS: 'notifications',
  ORGANIZATION: 'organization',
  PLATFORM: 'platform',
};
