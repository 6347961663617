import { REQUEST_STATUS } from '../../../../../constants';
import { useStrapiRequestData } from '../../../../../hooks/useStrapiRequestData';
import { USHGAdminRequestStatus } from '../../../../../types';
import { GenericStatusCell } from '../../../../common';

interface Props {
  status: USHGAdminRequestStatus;
}

const RequestStatusCell = ({ status }: Props) => {
  const { HQTableStatusOptions } = useStrapiRequestData();
  const { openFilterOption, completedFilterOption } = HQTableStatusOptions;
  switch (status) {
    case REQUEST_STATUS.ACTIVE:
      return (
        <GenericStatusCell
          containerClassName="bg-amber-900 text-amber-100"
          dotClassName="bg-amber-100"
          display={openFilterOption}
        />
      );
    case REQUEST_STATUS.COMPLETED:
      return (
        <GenericStatusCell
          containerClassName="bg-emerald-900 text-emerald-100"
          dotClassName="bg-emerald-100"
          display={completedFilterOption}
        />
      );
  }
};

export default RequestStatusCell;
