import AcronymLogo from '../AcronymnLogo';
import ImageWithFallback from '../ImageWithFallback';

type ImageElementProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

interface Props extends Omit<ImageElementProps, 'src' | 'srcSet' | 'className'> {
  imageKey?: string;
  className?: string;
  acronymLogoClassName?: string;
  acronymWord: string;
}

/**
 * Renders an image and fallbacks to acronym logo if there is an error
 * image is completely unstyled would expect the component user to pass the styles to the image
 * acronymLogoClassName can be used to override acronym logo styles
 */
const ImageWithAcronymFallback = (props: Props) => {
  const { imageKey, className, acronymLogoClassName, acronymWord, ...imgProps } = props;

  return (
    <ImageWithFallback
      {...imgProps}
      imageKey={imageKey}
      className={className}
      Fallback={
        <AcronymLogo
          className={acronymLogoClassName}
          wordsStr={acronymWord}
          aria-label={acronymWord}
        />
      }
    />
  );
};

export default ImageWithAcronymFallback;
