import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../types';
import type { CognitoUser } from '@aws-amplify/auth';

type CognitoHostedUIError = {
  code?: string;
  message?: string;
};

type AuthSliceState = (
  | { isAuthenticated: false; user: null; cognitoHostedUIError: CognitoHostedUIError | null }
  | { isAuthenticated: true; user: User; cognitoHostedUIError: never }
) & {
  // To track the authentication initializing state
  isInitializing: boolean;
  cognitoUser: CognitoUser | null;
};

const initialState = {
  isAuthenticated: false,
  user: null,
  isInitializing: true,
  cognitoUser: null,
  cognitoHostedUIError: null,
} as AuthSliceState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signedIn: (state, event: PayloadAction<User>) => {
      state.isAuthenticated = true;
      state.user = event.payload;
      state.cognitoHostedUIError = null;
    },
    signedOut: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    },
    initializedAuthState: (state) => {
      state.isInitializing = false;
    },
    setCognitoUser: (state, event: PayloadAction<CognitoUser>) => {
      state.cognitoUser = event.payload;
    },
    setCognitoHostedUIError: (state, event: PayloadAction<CognitoHostedUIError>) => {
      state.isAuthenticated = false;
      state.user = null;
      state.cognitoHostedUIError = event.payload;
    },
    clearCognitoHostedUIError: (state) => {
      state.cognitoHostedUIError = null;
    },
  },
});

export const {
  signedIn,
  signedOut,
  initializedAuthState,
  setCognitoUser,
  setCognitoHostedUIError,
  clearCognitoHostedUIError,
} = authSlice.actions;

export default authSlice.reducer;
