import {
  COGNITO_ENTERPRISE_ADMIN_GROUP,
  COGNITO_ENTERPRISE_LEADER_GROUP,
  COGNITO_ENTERPRISE_LEARNER_GROUP,
  COGNITO_ENTERPRISE_MANAGER_GROUP,
  COGNITO_INDIVIDUAL_USER_GROUP,
  COGNITO_USHG_ADMIN_GROUP,
} from '../features/auth/constants';
import { USHGHQAppRole } from '../types';

// Represents the roles in the ushg app
const USHG_HQ_APP_ROLE = {
  INDIVIDUAL_USER: 'INDIVIDUAL_USER',
  USHG_ADMIN: 'USHG_ADMIN',
  ENTERPRISE_LEADER: 'ENTERPRISE_LEADER',
  ENTERPRISE_ADMIN: 'ENTERPRISE_ADMIN',
  ENTERPRISE_MANAGER: 'ENTERPRISE_MANAGER',
  ENTERPRISE_LEARNER: 'ENTERPRISE_LEARNER',
} as const;

// Metadata about the roles
const ushgHQAppRoleMetadata: Record<string, USHGHQAppRole> = {
  [USHG_HQ_APP_ROLE.USHG_ADMIN]: {
    cognitoUserGroup: COGNITO_USHG_ADMIN_GROUP,
    displayName: 'HQ Admin',
    precedence: 0,
  },
  [USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]: {
    cognitoUserGroup: COGNITO_ENTERPRISE_ADMIN_GROUP,
    displayName: 'Admin',
    precedence: 1,
  },
  [USHG_HQ_APP_ROLE.ENTERPRISE_LEADER]: {
    cognitoUserGroup: COGNITO_ENTERPRISE_LEADER_GROUP,
    displayName: 'Leader',
    precedence: 2,
  },
  [USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER]: {
    cognitoUserGroup: COGNITO_ENTERPRISE_MANAGER_GROUP,
    displayName: 'Manager',
    precedence: 3,
  },
  [USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER]: {
    cognitoUserGroup: COGNITO_ENTERPRISE_LEARNER_GROUP,
    displayName: 'Learner',
    precedence: 4,
  },
  [USHG_HQ_APP_ROLE.INDIVIDUAL_USER]: {
    cognitoUserGroup: COGNITO_INDIVIDUAL_USER_GROUP,
    displayName: 'Individual User',
    precedence: 5,
  },
};

const ENTERPRISE_USER_ROLES = {
  ADMIN: 'Admin',
  LEADER: 'Leader',
  MANAGER: 'Manager',
  LEARNER: 'User',
} as const;

const ENTERPRISE_ROLE_KEYS: Record<string, { label: string; value: string }> = {
  Admin: {
    label: ushgHQAppRoleMetadata[USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN].displayName,
    value: ENTERPRISE_USER_ROLES.ADMIN,
  },
  Leader: {
    label: ushgHQAppRoleMetadata[USHG_HQ_APP_ROLE.ENTERPRISE_LEADER].displayName,
    value: ENTERPRISE_USER_ROLES.LEADER,
  },
  Manager: {
    label: ushgHQAppRoleMetadata[USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER].displayName,
    value: ENTERPRISE_USER_ROLES.MANAGER,
  },
  User: {
    label: ushgHQAppRoleMetadata[USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER].displayName,
    value: ENTERPRISE_USER_ROLES.LEARNER,
  },
};

export { USHG_HQ_APP_ROLE, ushgHQAppRoleMetadata, ENTERPRISE_USER_ROLES, ENTERPRISE_ROLE_KEYS };
