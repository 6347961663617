import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Alert from '../../../../../components/Alert';
import { Button } from '../../../../../components/Buttons';
import Dialog from '../../../../../components/Dialog';
import { BasicSpinner } from '../../../../../components/Spinners';
import { Toast } from '../../../../../components/Toast';
import { queryClient } from '../../../../../config/react-query';
import { USHG_HQ_APP_ROLE } from '../../../../../constants';
import { useAppSelector } from '../../../../../hooks';
import { RootState } from '../../../../../store';
import { FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY } from '../../../../shared/constants';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';
import { changePlan } from '../../../api';
import { MONTH, PRICING_TYPE, YEAR } from '../../../constants/settings';
import { useStrapiSettingsData } from '../../../hooks/useStrapiSettingsData';
import { ChangePlan } from '../../../types';
const subscriptionState = (state: RootState) => state.subscription.subscriptionData;
const authState = (state: RootState) => state.auth.user;
interface Props {
  setIsModalOpen: (open: boolean) => void;
}
const ChangePlanModal = ({ setIsModalOpen }: Props) => {
  const toggleModal = (status: boolean) => {
    setIsModalOpen(status);
  };
  const { changePlanStrapiData } = useStrapiSettingsData();
  const { mutate, isLoading } = useMutation({
    mutationFn: async (args: ChangePlan) => {
      const response = await changePlan(args);
      return response;
    },
    onSuccess: async () => {
      toast.custom(
        (t) => (
          <Toast
            variant="success"
            Title={changePlanStrapiData.toastSuccessTitle}
            SubTitle={changePlanStrapiData.toastSuccessDescription}
            toastInstance={t}
          />
        ),
        { id: 'CHANGE_PLAN_TOAST_' + Math.floor(Math.random() * 3000).toString() }
      );
      await queryClient.refetchQueries([FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY]);
      toggleModal(false);
    },
    onError: () => {
      toast.custom(
        (t) => (
          <Toast
            variant="error"
            Title={changePlanStrapiData.toastFailureTitle}
            SubTitle={changePlanStrapiData.toastFailureDescription}
            toastInstance={t}
          />
        ),
        { id: 'CHANGE_PLAN_TOAST_' + Math.floor(Math.random() * 3000).toString() }
      );
      toggleModal(false);
    },
  });

  const changePlanHandler = () => {
    const payload: ChangePlan = {
      pricingType: subscription?.planCycle === MONTH ? YEAR : MONTH,
      type: PRICING_TYPE,
    };
    mutate(payload);
  };
  const subscription = useAppSelector(subscriptionState);
  const user = useAppSelector(authState);
  const { enterprisePlanAvatar, individualPlanAvatar, teamPlanAvatar } =
    useStrapiSharedData().pricingPlanAvatar;
  console.log(
    enterprisePlanAvatar.data.attributes.url,
    individualPlanAvatar.data.attributes.url,
    teamPlanAvatar.data.attributes.url
  );
  const displayInformation = (role: string) => {
    switch (role) {
      case USHG_HQ_APP_ROLE.INDIVIDUAL_USER:
        return {
          title: changePlanStrapiData.displayInfoIndividualUserTitle,
          description: changePlanStrapiData.displayInfoIndividualUserDescription,
          image: individualPlanAvatar.data.attributes.url,
        };
      case USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN:
        return {
          title:
            subscription?.subscriptionType === 'enterprise'
              ? changePlanStrapiData.displayInfoEnterpriseAdminUserTitle
              : changePlanStrapiData.displayInfoTeamUserTitle,
          image:
            subscription?.subscriptionType === 'team'
              ? enterprisePlanAvatar.data.attributes.url
              : teamPlanAvatar.data.attributes.url,
        };
    }
  };
  const warningMessage = changePlanStrapiData.warningMessage;
  return (
    <div className="mx-1 flex flex-col gap-2 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
        {changePlanStrapiData.title}
      </Dialog.Title>
      <Alert variant="warning" message={warningMessage} />
      <div className="mx-40">
        <div className="flex justify-center">
          <div className="grid grid-rows-2">
            <div className="flex flex-row items-center justify-center gap-2">
              <img
                src={displayInformation(user?.role as string)?.image}
                className="h-[66px] w-[66px] rounded-2xl border-zinc-700 bg-zinc-700 bg-center bg-no-repeat"
              />
              <div className="text-white">
                <h3 className="text-lg font-bold">
                  {displayInformation(user?.role as string)?.title}
                </h3>
              </div>
            </div>
            <div className="text-center">
              <Button
                onClick={changePlanHandler}
                type="submit"
                disabled={isLoading || !subscription?.planCycle}
                className="btn inline-block h-[40px] whitespace-nowrap !rounded bg-base-brand px-6 py-2 text-sm font-medium normal-case leading-tight text-white hover:bg-base-brand disabled:bg-base-brand disabled:text-white"
              >
                {isLoading ? (
                  <div className="flex gap-2 ">
                    {subscription?.planCycle === MONTH
                      ? changePlanStrapiData.switchToYearly
                      : changePlanStrapiData.switchToMonthly}{' '}
                    <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
                  </div>
                ) : subscription?.planCycle === MONTH ? (
                  changePlanStrapiData.switchToYearly
                ) : (
                  changePlanStrapiData.switchToMonthly
                )}
              </Button>
              <Button
                type="submit"
                className="btn mt-2 inline-block h-[40px] !rounded bg-zinc-900 px-6 py-2 text-sm font-medium normal-case leading-tight text-zinc-600"
              >
                {subscription?.planCycle === MONTH
                  ? changePlanStrapiData.monthlyCurrentPlan
                  : changePlanStrapiData.yearlyCurrentPlan}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePlanModal;
