import { useStrapiOnBoardingData } from '../../hooks/useStrapiOnBoardingData';

type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const WelcomeIllustration = (props: Props) => {
  const { welcomePage } = useStrapiOnBoardingData();
  const { welcomeImg } = welcomePage;
  return (
    <img
      src={welcomeImg}
      aria-label="USHG Onboarding Welcome Illustration"
      role="img"
      alt="Welcome to ushg"
      className=" h-[269px] rounded-md bg-cover bg-no-repeat"
      {...props}
    />
  );
};

export default WelcomeIllustration;
