import { Link, Navigate, useLocation } from 'react-router-dom';
import ActionResponse from '../../../../components/ActionResponse';
import { useStrapiOnBoardingData } from '../../hooks/useStrapiOnBoardingData';

const SetPasswordSuccess = () => {
  const location = useLocation();
  const isRedirectedFromSetPasswordPage = location.state?.fromPath === '/invite/set-password';

  if (!isRedirectedFromSetPasswordPage) {
    return <Navigate to=".." />;
  }
  const { setPassword } = useStrapiOnBoardingData();
  const { continueButton, setpasswordSuccessMessage } = setPassword;
  return (
    <div className="flex w-full flex-col items-center pt-[10vw]">
      <ActionResponse
        title={setpasswordSuccessMessage}
        variant="success"
        subtitle={
          <>
            <Link to="/onboarding" className="underline">
              {continueButton}
            </Link>
          </>
        }
      />
    </div>
  );
};

export default SetPasswordSuccess;
