const LEADER_TEAMS_API_PATHS = {
  ENTERPRISE_LEADER: {
    GET_ALL_LEADER_TEAMS: '/users/leader-teams',
    GET_ALL_LEADER_TEAM_MANAGERS: '/users/managers',
  },
};
const TEAMS_API_PATHS = {
  GET_TEAM_NAME: '/users/team-name',
  CHECK_MEMBER_EXISTS: '/auth/check-user',
  CHECK_TEAM_EXISTS: '/users/check-team',
  ADD_NEW_MEMBER_REQUEST: '/users/request',
  ENTERPRISE_ADMIN: {
    GET_ALL_MEMBERS: '/users/admin/team-members',
    GET_ALL_TEAMS: '/users/admin-teams',
    GET_ALL_TEAM_NAME: '/users/enterprise/allTeams',
    ADD_NEW_TEAM: '/users/enterprise/teams',
    ADD_NEW_MEMBER: '/users/enterprise/addteamMember',
    EDIT_TEAM: '/users/enterprise/team',
    ADD_TEAM_REQUEST: '/users/request',
    RESEND_INVITE: '/users/admin/resend-user-invite',
  },
} as const;

const GET_MANAGER_TEAM_ID_API_PATH = '/users/enterprise-manager/my-team';
const GET_TOP_EARNED_BADGES_PATH = '/reports/v1/top-earned-badges';
export {
  TEAMS_API_PATHS,
  LEADER_TEAMS_API_PATHS,
  GET_MANAGER_TEAM_ID_API_PATH,
  GET_TOP_EARNED_BADGES_PATH,
};
