import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import TenantMFAConfiguration from './TenantMFAConfiguration';

interface Props {
  className?: string;
}

const OrganizationTab = (props: Props) => {
  const { className } = props;

  return (
    <section className={twMerge(clsx('flex w-full flex-col items-center gap-6', className))}>
      <TenantMFAConfiguration className="max-w-2xl" />
    </section>
  );
};

export default OrganizationTab;
