import React from 'react';
interface EmojiProps extends React.HTMLAttributes<HTMLSpanElement> {
  label?: string;
  symbol: string;
  className?: string;
}
const Emoji = ({ label, symbol, className, ...otherProps }: EmojiProps) => (
  <span
    className={className}
    aria-label={label ? label : undefined}
    aria-hidden={label ? undefined : true}
    role="img"
    {...otherProps}
  >
    {symbol}
  </span>
);
export default Emoji;
