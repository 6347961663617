interface Props {
  name: string;
}

const LeaderboardNameCell = ({ name }: Props) => {
  return (
    <div className="flex space-x-1.5">
      <span className="whitespace-nowrap">{name}</span>
    </div>
  );
};

export default LeaderboardNameCell;
