import { ChartData } from 'chart.js';
import clsx from 'clsx';
import { useContext, useMemo, useState } from 'react';
import { defaultGridOptions, defaultTickOptions, defaultTitleOptions } from '../../config';
import { REPORTS_DASHBOARD_LIMIT, REPORT_TYPES } from '../../constants';
import { BarChartOptions } from '../../types';
import ChartHeading from './ChartHeading';
import { BarChart } from './baseCharts';
import durationContext from '../../context/durationContext';
import {
  EngagementByCategories,
  EngagementByCategoriesFilter,
  ReportTooltipDataType,
} from '../../types/reports/reports';
import { useEngagementByCategoriesQuery } from '../../hooks';
import { ErrorDisplay } from '../../../../components/DataTables';
import NoDataFallback from '../NoDataFallback';
import { InView } from 'react-intersection-observer';
import { useStrapiReportsData } from '../../hooks/useStrapiReportData';
import { useParams } from 'react-router-dom';
import ScaleLoader from '../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../components/ReportToolTip';
import { formatTime } from '../../helpers';

interface Props {
  className?: string;
  heading?: string;
  lazyLoad?: boolean;
  teamId?: string;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
  xAxisLabel?: string;
}

const EngagementByCategoriesViz = ({
  className,
  heading,
  lazyLoad = true,
  teamId,
  reportTooltipContent,
  userPersona,
  xAxisLabel,
}: Props) => {
  const { id } = useParams();
  const { duration } = useContext(durationContext);

  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);
  const {
    EngagementByCategory: { emptyChart, errorChart },
  } = useStrapiReportsData();

  // Build the filter
  let filters: Partial<EngagementByCategoriesFilter> = useMemo(() => {
    return {
      fromDate: duration.fromDate,
      limit: REPORTS_DASHBOARD_LIMIT,
      teamId: teamId && teamId !== '1' ? teamId : undefined,
    };
  }, [duration, teamId]);

  // include username in filter if exists in route path
  if (id) {
    filters = { ...filters, username: id };
  }

  const engagementByCategories = useEngagementByCategoriesQuery({ filters, isEnabled: isInView });

  const hasEngagementByCategories =
    engagementByCategories.data &&
    engagementByCategories.data.results &&
    engagementByCategories.data.results.length > 0;

  const hasNoEngagementByCategories =
    engagementByCategories.data &&
    engagementByCategories.data.results &&
    engagementByCategories.data.results.length === 0;

  const isLoadingEngagementByCategories = engagementByCategories.isLoading;
  const data = useMemo(() => {
    let labels: string[] = [];
    let hoursEngaged: number[] = [];

    if (hasEngagementByCategories) {
      // Chartjs labels
      labels = engagementByCategories.data?.results.map(
        (result: EngagementByCategories) => result.name
      );

      hoursEngaged = engagementByCategories.data?.results.map(
        (result: EngagementByCategories) => +result.hoursEngaged
      );
    }

    const data: ChartData<'bar', number[], unknown> = {
      labels,
      datasets: [
        {
          data: hoursEngaged,
          borderColor: '#F3EAC7',
          backgroundColor: '#F3EAC7',
        },
      ],
    };

    return data;
  }, [hasEngagementByCategories, engagementByCategories.data]);

  // Chartjs options
  const chartOptions: BarChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        callbacks: {
          label: (context) => {
            const value = context.formattedValue;
            const label = context.label;
            const formattedTime = formatTime(value);
            return `${label}: ${formattedTime}`;
          },
        },
      },
    },
    indexAxis: 'y' as const,
    datasets: {
      bar: {
        barThickness: 13,
      },
    },
    scales: {
      x: {
        title: {
          ...defaultTitleOptions,
          text: xAxisLabel,
        },
        grid: {
          ...defaultGridOptions,
          borderDash: [],
        },
        ticks: {
          ...defaultTickOptions,
          precision: 0,
        },
      },
      y: {
        grid: {
          ...defaultGridOptions,
          borderDash: [],
        },
        ticks: {
          ...defaultTickOptions,
          padding: 12,
        },
      },
    },
  };

  return (
    <InView
      as="div"
      className={clsx(
        'flex h-full min-h-[420px] flex-col gap-5 rounded-lg border border-zinc-700 bg-card-bg pt-4 pb-7',
        className
      )}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div className="flex items-center gap-2 px-4">
        <ChartHeading heading={heading as string} />
        {reportTooltipContent?.[REPORT_TYPES.ENGAGEMENT_BY_CATEGORIES]?.[userPersona] && (
          <ReportTooltip
            content={reportTooltipContent[REPORT_TYPES.ENGAGEMENT_BY_CATEGORIES][userPersona]}
          />
        )}
      </div>
      <hr className="h-px border-0 bg-gray-700" />
      {isLoadingEngagementByCategories && !hasEngagementByCategories && (
        <div className="flex grow items-center justify-center">
          <ScaleLoader />
        </div>
      )}
      {/* Error State */}
      <>
        {engagementByCategories.error && !engagementByCategories.data && (
          <div className="flex grow items-center justify-center">
            <ErrorDisplay
              message={errorChart}
              refetch={engagementByCategories.refetch}
              allowsRefetch={true}
              isRefetching={engagementByCategories.isRefetching}
            />
          </div>
        )}
      </>
      {hasEngagementByCategories && (
        <BarChart
          options={chartOptions}
          className="m-auto max-h-80 min-h-[20rem] w-full py-2 px-4"
          data={data}
        />
      )}
      {hasNoEngagementByCategories && !isLoadingEngagementByCategories && (
        <NoDataFallback message={emptyChart} />
      )}
    </InView>
  );
};

export default EngagementByCategoriesViz;
