import React, { useMemo } from 'react';
import { calcTimeString } from '../../../../helpers';

interface Props {
  totalTimeSpent: number;
}

const CourseTimeCell = ({ totalTimeSpent }: Props) => {
  const courseTime = useMemo(() => {
    return calcTimeString(totalTimeSpent);
  }, [totalTimeSpent]);
  return <span className="whitespace-nowrap">{courseTime}</span>;
};

export default CourseTimeCell;
