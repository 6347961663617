import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Dialog from '../../../../../../components/Dialog';
import { MFAMethod, TenantMFAConfig } from '../../../../../../constants';
import { STRAPI_PLACEHOLDER_CODE } from '../../../../../../constants/strapi';
import { useReduxAuthState } from '../../../../../../hooks';
import { useStrapiSettingsData } from '../../../../hooks/useStrapiSettingsData';
import DisableMFADialogContent from './DisableMFADialogContent';
import EnableMFADialogContent from './EnableMFADialogContent';
import VerifyOrAddPhoneDialogContent from './VerifyOrAddPhoneDialogContent';
import { useStrapiSharedData } from '../../../../../shared/hooks/useStrapiSharedData';
import { removeCountryCodeFromPhoneNumber } from '../../../../../../utils';
import ActionButton from '../../components/ActionButton';
import Alert from '../../../../../../components/Alert';

// types

type Props = {
  className?: string;
};

type ActiveDialogType =
  | 'enable-mfa-confirmation'
  | 'add-or-verify-phone-notice'
  | 'disable-mfa-confirmation';

// types

const MFA = ({ className }: Props) => {
  // Redux state
  const auth = useReduxAuthState();
  // Redux state

  // hooks
  const navigate = useNavigate();
  // hooks

  // strapi

  const { mfa: mfaSettingsCMS } = useStrapiSettingsData();
  const sharedCMS = useStrapiSharedData();
  const {
    featureFlags: { enableUSPhoneNumberFormatting = true },
  } = sharedCMS;

  const formattedPhoneNumber =
    auth.user?.phone_number && enableUSPhoneNumberFormatting
      ? removeCountryCodeFromPhoneNumber(auth.user.phone_number)
      : auth.user?.phone_number;

  const smsMFAInstructionsWithPhoneNumber = mfaSettingsCMS.smsMFAInstructions?.replace(
    STRAPI_PLACEHOLDER_CODE.PHONE_NUMBER,
    formattedPhoneNumber ?? ''
  );

  // strapi

  // state
  const [showDialog, setShowDialog] = useState(false);
  const [activeDialogType, setActiveDialogType] = useState<ActiveDialogType | null>(null);
  // state

  // derived state
  const userPreferredMFA = auth.user?.preferredMFA;

  const phoneNumber = auth.user?.phone_number;
  const userHasPhoneNumber =
    phoneNumber !== null && phoneNumber !== undefined && phoneNumber !== '';
  const userHasVerifiedPhoneNumber = auth.user?.phone_number_verified === true;

  // show the add or verify phone number dialog if the user has not phone number
  // or if the number is not yet verified
  const showAddOrVerifyPhoneNumberDialog = !userHasPhoneNumber || !userHasVerifiedPhoneNumber;

  const userHasMfaEnabled: boolean = userPreferredMFA !== MFAMethod.NOMFA;

  const DialogContent = useMemo(() => {
    if (!showDialog) {
      return null;
    }

    switch (activeDialogType) {
      case 'enable-mfa-confirmation': {
        return <EnableMFADialogContent setShowDialog={setShowDialog} />;
      }
      case 'add-or-verify-phone-notice': {
        return <VerifyOrAddPhoneDialogContent setShowDialog={setShowDialog} />;
      }
      case 'disable-mfa-confirmation': {
        return <DisableMFADialogContent setShowDialog={setShowDialog} />;
      }
      default: {
        return null;
      }
    }
  }, [showDialog, activeDialogType]);

  const tenantMFAConfig = auth.user?.['custom:tenant_mfa_config'];
  const isMFAMandatedForTenant = tenantMFAConfig === TenantMFAConfig.REQUIRED;

  const showEnableMFASinceIsRequiredAlert = !userHasMfaEnabled && isMFAMandatedForTenant;
  const showCannotDisableMFASinceIsRequiredAlert = userHasMfaEnabled && isMFAMandatedForTenant;

  // derived state

  // handlers

  const handleEnableMFA = useCallback(() => {
    if (showAddOrVerifyPhoneNumberDialog) {
      setActiveDialogType('add-or-verify-phone-notice');
    } else {
      setActiveDialogType('enable-mfa-confirmation');
    }
    setShowDialog(true);
  }, [showAddOrVerifyPhoneNumberDialog]);

  const handleDisableMFA = useCallback(() => {
    setActiveDialogType('disable-mfa-confirmation');
    setShowDialog(true);
  }, []);

  const navigateToProfile = useCallback(() => {
    navigate('/settings?view=profile');
  }, [navigate]);

  // handlers

  return (
    <section className={twMerge(clsx('flex flex-col gap-4 rounded-sm bg-card-bg p-6', className))}>
      {/* MFA is mandated for the organization alert */}
      {showEnableMFASinceIsRequiredAlert && (
        <Alert variant={'error'} message={mfaSettingsCMS.enableMFASinceIsMandatedAlert} />
      )}
      {/* Cannot disable MFA since it is required by the organization */}
      {showCannotDisableMFASinceIsRequiredAlert && (
        <Alert variant="warning" message={mfaSettingsCMS.cannotDisableMFASinceIsMandatedAlert} />
      )}
      {/* Heading and Sub Heading */}
      <div className="flex flex-col">
        <div className="flex w-full items-center gap-2">
          <h3 className="text-xl font-semibold leading-8 text-zinc-50">{mfaSettingsCMS.heading}</h3>
          {mfaSettingsCMS.showIsNewFeature && (
            <div className="flex h-min items-center justify-center rounded-[3.5rem] bg-base-brand p-[0.0625rem_0.3125rem] text-[0.625rem] font-bold leading-normal text-white">
              {sharedCMS.newFeatureLabel}
            </div>
          )}
        </div>
        <span className="text-sm font-normal text-zinc-500">{mfaSettingsCMS.description}</span>
      </div>
      {/* Has Data */}
      {/* Actions */}
      <div>
        {userHasMfaEnabled ? (
          // Options to update or disable MFA
          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-center rounded bg-zinc-900 p-2 text-sm font-medium text-zinc-400">
              {smsMFAInstructionsWithPhoneNumber}
            </div>
            <div className="flex flex-col items-center gap-2 xxs:flex-row">
              <ActionButton
                type="primary"
                label={mfaSettingsCMS.editInProfileBtnLabel}
                handler={navigateToProfile}
                className="whitespace-nowrap px-4 py-2 font-normal"
              ></ActionButton>
              {/* Donot display disable if the tenant has MFA mandated for its users */}
              {!isMFAMandatedForTenant && (
                <ActionButton
                  type="secondary"
                  label={mfaSettingsCMS.disableMFABtnLabel}
                  handler={handleDisableMFA}
                  className="px-4 py-2 font-normal text-zinc-400"
                ></ActionButton>
              )}
            </div>
          </div>
        ) : (
          // Enable MFA
          <ActionButton
            type="primary"
            className="whitespace-nowrap px-4 py-2 font-normal"
            label={mfaSettingsCMS.enableMFABtnLabel}
            handler={handleEnableMFA}
          ></ActionButton>
        )}
      </div>
      {/* Dialog */}
      <Dialog
        open={showDialog}
        onOpenChange={(open) => setShowDialog(open)}
        Content={DialogContent}
        containerClassName="max-h-[80vh] overflow-y-auto px-2"
      ></Dialog>
    </section>
  );
};

export default MFA;
