const SUBSCRIPTION = {
  STATUS: {
    ACTIVE: 'active',
    CANCELED: 'canceled',
    PAST_DUE: 'past_due',
    UNPAID: 'unpaid',
  },
} as const;

const SUBSCRIPTION_PRODUCTS = {
  BASIC: 'Basic',
  TEAM: 'Team',
  ENTERPRISE: 'Enterprise',
} as const;

export { SUBSCRIPTION, SUBSCRIPTION_PRODUCTS };
