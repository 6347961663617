import { ArrowSquareOut } from 'phosphor-react';
import { Button } from '../../../../../components/Buttons';
import { useState } from 'react';
import { getCheckoutSessionURL } from '../../../api';
import { BasicSpinner } from '../../../../../components/Spinners';
import toast from 'react-hot-toast';
import { Toast } from '../../../../../components/Toast';
import { useStrapiSettingsData } from '../../../hooks/useStrapiSettingsData';

function UpdatePaymentMethod() {
  const {
    paymentMethod,
    toastMessage: { updatedPaymentSuccess, updatedPaymentFailed },
  } = useStrapiSettingsData();
  const queryParams = Object.fromEntries(new URLSearchParams(location.search));
  const { view, status, action } = queryParams;
  if (view === 'billing' && action === 'payment-update') {
    if (status === 'success') {
      toast.custom(
        (t) => <Toast variant={'success'} Title={updatedPaymentSuccess} toastInstance={t} />,
        { id: 'UPDATE_PAYMENT_METHOD_TOAST_' + Math.floor(Math.random() * 3000).toString() }
      );
    }
    if (status !== 'success') {
      toast.custom(
        (t) => <Toast variant={'error'} Title={updatedPaymentFailed} toastInstance={t} />,
        { id: 'UPDATE_PAYMENT_METHOD_TOAST_' + Math.floor(Math.random() * 3000).toString() }
      );
    }
  }

  const [isLoading, setLoading] = useState(false);
  const updatePayment = () => {
    setLoading(true);
    getCheckoutSessionURL()
      .then((data) => {
        window.location.href = data.sessionUrl as string;
      })
      .catch((error) => {
        console.error('Error updating payment method', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="mt-3 flex flex-col gap-4 self-stretch rounded-sm bg-card-bg p-6">
      <div className="flex flex-col gap-2 leading-5">
        <h2 className="text-xl font-semibold text-zinc-50">{paymentMethod.title}</h2>
        <div className="mt-4 flex w-full flex-wrap justify-between gap-3 md:flex-nowrap md:gap-0">
          <div className="flex gap-4">
            <img className="mt-2 h-7" src={paymentMethod.stripeLogo.data.attributes.url}></img>
          </div>
          <Button
            type="submit"
            disabled={isLoading}
            onClick={updatePayment}
            className="flex w-fit cursor-pointer items-center justify-center gap-2 bg-zinc-700 text-center text-sm font-medium leading-[20px] text-zinc-100 transition-opacity disabled:cursor-default disabled:opacity-80"
          >
            <span>{paymentMethod.updatePaymentButton}</span>
            {isLoading ? (
              <BasicSpinner className="!m-0 leading-4 text-inherit" />
            ) : (
              <ArrowSquareOut
                color="currentColor"
                className="text-zinc-200"
                size={20}
                weight="fill"
              />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UpdatePaymentMethod;
