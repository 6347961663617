import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React from 'react';
import cn from '../../../../utils/cn';
import { Check } from 'phosphor-react';
import { LanguageItem } from './LanguageItem';
import { useLanguage } from '../../context/languageContext';
import { useStrapiSharedData } from '../../hooks/useStrapiSharedData';

type LanguageProps = {
  triggerClassName?: string;
  side?: 'top' | 'right' | 'bottom' | 'left' | undefined;
  alignOffset?: number | undefined;
};

const Language = (props: React.PropsWithChildren<LanguageProps>) => {
  // strapi data
  const {
    language: { StrapiLanguageOptions },
    featureFlags: { showLanguageOptions },
  } = useStrapiSharedData();

  const { children, triggerClassName, side, alignOffset } = props;

  const { language, setLanguage } = useLanguage();

  return (
    showLanguageOptions && (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger className={cn(triggerClassName)}>{children}</DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="isolate z-30 mx-2 flex min-w-[10rem] max-w-[min(20rem,calc(100vw-2*var(--dropdown-content-mx)))] flex-col items-start rounded-lg border border-zinc-700 bg-zinc-800 shadow-[0px_4px_6px_-2px_rgba(16,24,40,0.03),0px_12px_16px_-4px_rgba(16,24,40,0.08)] [--dropdown-content-mx:0.5rem] xs:min-w-[12rem]"
            side={side}
            sideOffset={8}
            align="end"
            alignOffset={alignOffset}
          >
            {/* Content */}
            <DropdownMenu.Group className="w-full py-1.5 px-1">
              {StrapiLanguageOptions.map((languageOptions: { label: string; value: string }) => (
                <LanguageItem
                  key={languageOptions.value}
                  onSelect={() => setLanguage(languageOptions.value)}
                >
                  <div className="flex cursor-pointer flex-row items-center gap-2 text-sm font-medium leading-5 text-zinc-50">
                    <span>{languageOptions.label}</span>
                    {language === languageOptions.value && (
                      <Check size={14} className="ml-auto" color="currentColor" weight="bold" />
                    )}
                  </div>
                </LanguageItem>
              ))}
            </DropdownMenu.Group>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    )
  );
};

export default Language;
