import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PaginationFooter } from '../../../../../../../components/DataTables';
import { BasicSpinner } from '../../../../../../../components/Spinners';
import useDebounce from '../../../../../../../hooks/use-debounce';
import {
  FEEDBACK_DEFAULT_PAGE,
  FEEDBACK_FILTER_QUERY_PARAM,
  FEEDBACK_FILTER_SEARCH_DEBOUNCE_MS,
  FEEDBACK_MAX_PAGE_SIZE,
  FEEDBACK_MIN_PAGE_SIZE,
  FEEDBACK_PAGE_SIZE_INCREMENT,
  FEEDBACK_RATING_QUERY_PARAM,
  PartialUSHGAdminFeedbackFilter,
  USHGAdminFeedbackFilter,
  USHG_ADMIN_FEEDBACK_RATING_BY_VALUES,
} from '../../../../../constants/course-feedback';
import { USHG_ADMIN_RATINGS_SELECT_OPTIONS } from '../../../../../constants/courseFeedbackSelect';

import FeedbacksEmptyState from './FeedbacksEmptyState';
import FeedbacksErrorState from './FeedbacksErrorState';
import FeedbacksMainHeader from './FeedbacksMainHeader';
import FeedbacksTable from './FeedbacksTable/FeedbacksTable';
import useUSHGAdminCourseFeedbackQuery from '../../../../../hooks/useUSHGAdminCourseFeedbackQuery';
import { useAppSelector } from '../../../../../../../hooks';
import { RootState } from '../../../../../../../store';

interface Props {
  className?: string;
}

const USHGAdminCourseFeedbackMain = ({ className }: Props) => {
  const courseId = parseInt(useParams().id as string);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const hqSingleCourseStateSelector = (state: RootState) => state.hqSingleCourse;
  const { courseFeedbackSortKey, courseFeedbackSortOrder } = useAppSelector(
    hqSingleCourseStateSelector
  );
  // We would need whether the filter is applied
  // We would check the sort, status and filter to decide whether the filter is
  // Applied or not
  let isFilterApplied = false;

  // Parse the filters from the url query parameters
  const search = searchParams.get(FEEDBACK_FILTER_QUERY_PARAM) ?? undefined;
  let ratings = searchParams.get(FEEDBACK_RATING_QUERY_PARAM) ?? undefined;
  // If a filter string is present set isFilterApplied to true
  isFilterApplied = search !== undefined;

  if (
    ratings == null ||
    !USHG_ADMIN_FEEDBACK_RATING_BY_VALUES.includes(ratings as USHGAdminFeedbackFilter['ratings'])
  ) {
    ratings = undefined;
  } else {
    // If status string is present and is valid set isFilterApplied to true
    isFilterApplied = true;
  }

  // Current Page
  const [page, setPage] = useState(FEEDBACK_DEFAULT_PAGE);
  // Page Size
  const [pageSize, setPageSize] = useState(FEEDBACK_MIN_PAGE_SIZE);

  // Debounce the fast changing search filter
  // so we would make the request only when there is some considerable pause
  const debouncedFilter = useDebounce(search, FEEDBACK_FILTER_SEARCH_DEBOUNCE_MS);

  useEffect(() => {
    // Whenever the status select, sort or the search query changes
    // Then reset the page to the start of the page
    setPage(FEEDBACK_DEFAULT_PAGE);
  }, [ratings, debouncedFilter]);

  // Build the feedback filters from the available filters
  const filters: PartialUSHGAdminFeedbackFilter = useMemo(() => {
    return {
      rating: ratings,
      search: debouncedFilter,
      sortOrder: courseFeedbackSortOrder,
      sortKey: courseFeedbackSortKey,
      start: (page - 1) * pageSize,
      size: pageSize,
    };
  }, [debouncedFilter, ratings, courseFeedbackSortOrder, courseFeedbackSortKey, page, pageSize]);

  const feedback = useUSHGAdminCourseFeedbackQuery({ filters, courseId });

  // Display loader during initial loading and if we are fetching when there are no items.
  const displayLoader =
    (feedback.isLoading && !feedback.data) ||
    (feedback.isFetching && feedback.data?.totalCount === 0);
  const isEmptyState = feedback.data && feedback.data.totalCount == 0;

  return (
    <div
      className={clsx(
        'flex flex-col items-start gap-4 rounded-sm bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)] md:p-5 lg:p-6',
        className
      )}
    >
      <>
        <FeedbacksMainHeader
          filter={search}
          ratings={ratings}
          isFilterApplied={isFilterApplied}
          ratingsSelectOptions={USHG_ADMIN_RATINGS_SELECT_OPTIONS}
          filterIsVisible={feedback.data ? feedback.data.totalCount !== 0 : false}
        />

        {/* Is Loading and We donot have data or Is Fetching and we have data as empty array */}
        {displayLoader && (
          <div className="flex min-h-[250px] w-full items-center justify-center py-4">
            <BasicSpinner className="text-white" />
          </div>
        )}
        {/* We have data */}
        {feedback.data && feedback.data.totalCount > 0 && (
          <div
            className={clsx(
              'relative flex w-full flex-col gap-4',
              feedback.isPreviousData && feedback.isFetching && 'pointer-events-none opacity-70'
            )}
          >
            {feedback.isPreviousData && feedback.isFetching && (
              <div className="flex w-full justify-center">
                <BasicSpinner className="text-center text-white" />
              </div>
            )}

            <div className="relative w-full overflow-y-scroll">
              {feedback.isError ? (
                <FeedbacksErrorState
                  isRefetching={feedback.isRefetching}
                  refetch={feedback.refetch}
                />
              ) : (
                <FeedbacksTable data={feedback.data.results} />
              )}
            </div>

            <PaginationFooter
              setPage={setPage}
              activePage={page}
              totalItems={feedback.data.totalCount}
              pageSize={pageSize}
              setPageSize={setPageSize}
              maxPageSize={FEEDBACK_MAX_PAGE_SIZE}
              minPageSize={FEEDBACK_MIN_PAGE_SIZE}
              pageSizeIncrement={FEEDBACK_PAGE_SIZE_INCREMENT}
            />
          </div>
        )}

        {/* Empty State */}
        {isEmptyState && !displayLoader && (
          <div className="flex min-h-[250px] w-full items-center justify-center">
            <FeedbacksEmptyState isFilterApplied={isFilterApplied} />
          </div>
        )}

        {feedback.isError && !feedback.data && (
          <div className="flex min-h-[250px] w-full items-center justify-center">
            <FeedbacksErrorState isRefetching={feedback.isRefetching} refetch={feedback.refetch} />
          </div>
        )}
      </>
    </div>
  );
};

export default USHGAdminCourseFeedbackMain;
