import type { RouteObject } from 'react-router-dom';
import { USHG_HQ_APP_ROLE } from '../../../constants';
import HasAccess from '../../shared/components/HasAccess';
import ListSpeakersPage from '../pages/ListSpeakersPage';

const SpeakersPageRoutes: RouteObject[] = [
  {
    path: 'speakers',
    element: (
      <HasAccess roles={[USHG_HQ_APP_ROLE.USHG_ADMIN]} Fallback={null}>
        <ListSpeakersPage />
      </HasAccess>
    ),
  },
];

export default SpeakersPageRoutes;
