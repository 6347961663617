import illustrationSrc from '../../assets/SuccessIllustrator.png';

type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const SuccessIllustration = (props: Props) => {
  return (
    <div
      style={{
        backgroundImage: `url(${illustrationSrc})`,
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      aria-label="USHG Onboarding Success Illustration"
      role="img"
      className="m-0 h-full w-full"
      {...props}
    />
  );
};

export default SuccessIllustration;
