import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { TextInput } from '../../../../../components/FormElements';
import { USHG_HQ_APP_ROLE } from '../../../../../constants';
import HasAccess from '../../../../shared/components/HasAccess';
import { useAddTeamsAPI } from '../../../hooks/useAddTeamApi';
import { AdminTeamData } from '../../../types/interface';
import { Plus, X } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import { REQUEST_TYPE, Roles } from '../../../constants/role';
import { useSendTeamsRequest } from '../../../hooks/useTeamsRequest';
import { BasicSpinner } from '../../../../../components/Spinners';
import { SECTIONS } from '../../../constants/section-name';
import Dialog from '../../../../../components/Dialog';
import { useStrapiTeamData } from '../../../hooks/useStrapiTeamData';
import { queryClient } from '../../../../../config/react-query';
import { checkTeamExist } from '../../../../auth/helpers/register';
import ModalErrorAlert from '../ModalAlert/ModalErrorAlert';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { fieldHasError } from '../../../../../utils/react-hook-form';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
import { RootState } from '../../../../../store';
import { useAppSelector, useHasAccess } from '../../../../../hooks';
import SubscriptionInfo from '../subscriptionInfo/subscriptionMain';
import { debounce } from '../../../../../utils';
import { REGEX } from '../../../constants/regex';
import {
  FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY,
  FETCH_TEAM_NAME_QUERY_KEY,
} from '../../../../shared/constants';
import { checkUserExists } from '../../../../auth/api';
import { TRAIL_LIMIT } from '../../../../../constants/subscription-plans';
import Button from '../../../../../components/Buttons/Button';
import { yupResolver } from '@hookform/resolvers/yup';

interface Props {
  teamId: number;
  setIsModalOpen: (open: boolean) => void;
}
// Yup Schema
function schema(
  teamNameRequired: string,
  managerEmailRequired: string,
  memberEmailRequired: string,
  jobTitleRequired: string,
  jobTitleMaxError: string,
  jobTitleMinError: string,
  emailLimitError: string,
  validEmailError: string,
  teamNameMinError: string,
  teamNameMaxError: string,
  teamNameMinValue: number,
  teamNameMaxValue: number,
  emailMaxValue: number,
  jobTitleMinValue: number,
  jobTitleMaxValue: number
) {
  return yup.object({
    teamName: yup
      .string()
      .trim()
      .required(teamNameRequired)
      .min(teamNameMinValue, teamNameMinError)
      .max(teamNameMaxValue, teamNameMaxError),
    managerEmail: yup
      .string()
      .email(validEmailError)
      .trim()
      .required(managerEmailRequired)
      .max(emailMaxValue, emailLimitError),
    jobTitle: yup
      .string()
      .trim()
      .required(jobTitleRequired)
      .min(jobTitleMinValue, jobTitleMinError)
      .max(jobTitleMaxValue, jobTitleMaxError),
    members: yup.array(
      yup.object({
        email: yup
          .string()
          .email(validEmailError)
          .trim()
          .required(memberEmailRequired)
          .max(emailMaxValue, emailLimitError),
        jobTitle: yup
          .string()
          .trim()
          .required(jobTitleRequired)
          .min(jobTitleMinValue, jobTitleMinError)
          .max(jobTitleMaxValue, jobTitleMaxError),
        role: yup.string().oneOf([Roles.LEARNER]),
      })
    ),
    teamAlreadyExist: yup.string(),
  });
}
const subscriptionInfoData = (state: RootState) => state.subscription;
function AddSubTeamModal({ teamId, setIsModalOpen }: Props): JSX.Element {
  const { subscriptionData } = useAppSelector(subscriptionInfoData);
  const { addsubteam, commomTeamErrorMessage } = useStrapiTeamData();
  const {
    memberAlreadyExists,
    teamAlreadyExists,
    managerMemberSameError,
    teamNameRequired,
    managerEmailRequired,
    memberEmailRequired,
    jobTitleRequired,
    jobTitleMaxError,
    jobTitleMinError,
    emailLimitError,
    validEmailError,
    teamNameMinError,
    teamNameMaxError,
    teamNameMinValue,
    teamNameMaxValue,
    emailMaxValue,
    jobTitleMinValue,
    jobTitleMaxValue,
    sameMemberError,
    teamNameAlreadyExistMessage,
  } = commomTeamErrorMessage;
  // Yup Schema
  const addNewSubTeamFormSchema = schema(
    teamNameRequired,
    managerEmailRequired,
    memberEmailRequired,
    jobTitleRequired,
    jobTitleMaxError,
    jobTitleMinError,
    emailLimitError,
    validEmailError,
    teamNameMinError,
    teamNameMaxError,
    teamNameMinValue,
    teamNameMaxValue,
    emailMaxValue,
    jobTitleMinValue,
    jobTitleMaxValue
  );
  type ADD_NEW_SUB_TEAM_FORM_DATA = yup.InferType<typeof addNewSubTeamFormSchema>;
  const {
    register,
    setValue,
    control,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
  } = useForm<ADD_NEW_SUB_TEAM_FORM_DATA>({
    mode: 'onChange',

    defaultValues: {
      teamName: '',
      managerEmail: '',
      jobTitle: '',
      members: [{ email: '', jobTitle: '', role: 'User' }],
      teamAlreadyExist: '',
    },
    resolver: yupResolver(addNewSubTeamFormSchema),
  });
  const { fields, append, remove } = useFieldArray({
    name: 'members',
    control,
  });
  const {
    subTeamAddAnotherMemberButton,
    subTeamAddNewMemberButton,
    subTeamBillingButton,
    subTeamAddTeamButton,
    subTeamCancelButton,
    subTeamAddTeamRequestButton,
    subTeamNameTitle,
    subTeamNameLabel,
    subTeamNamePlaceHolder,
    subTeamManagerDetailsTitle,
    subTeamManagerEmailLabel,
    subTeamManagerEmailPlaceHolder,
    subTeamManagerJobLabel,
    subTeamManagerJobTitlePlaceHolder,
    subTeamMemberDetailsTitle,
    subTeamMemberEmailLabel,
    subTeamMemberEmailPlaceHolder,
    subTeamMemberJobLabel,
    subTeamMemberJobTitlePlaceHolder,
  } = addsubteam;
  const [errorIndex, setErrorIndex] = useState<number>();
  const [isTeamNameCheckInProgress, setIsTeamNameCheckInProgress] = useState(false);
  const [isManagerEmailCheckInProgress, setIsManagerEmailCheckInProgress] = useState(false);
  const [memberEmailIndexes, setMemberEmailIndexes] = useState(new Set());
  const toggleVisible = () => {
    setIsModalOpen(false);
    setValue('teamName', '');
    setValue('managerEmail', '');
    setValue('jobTitle', '');
    setValue('members', [{ email: '', jobTitle: '', role: Roles.LEARNER }]);
  };

  // Error handling
  const _emailValidate = async (value: string, index: number) => {
    try {
      setMemberEmailIndexes((indexes) => {
        const newIndexes = new Set(indexes.values());
        newIndexes.add(index);
        return newIndexes;
      });
      const response = await queryClient.fetchQuery({
        queryFn: async () => {
          return await checkUserExists(value);
        },
        queryKey: ['user_exists', value],
        staleTime: 30000,
      });
      setMemberEmailIndexes((indexes) => {
        const newIndexes = new Set(indexes.values());
        newIndexes.delete(index);
        return newIndexes;
      });
      if (response.userExistsAlready) {
        return memberAlreadyExists;
      } else {
        for (let x = 0; x < fields.length; x++) {
          if (x !== index && value === getValues(`members.${x}.email`)) {
            return sameMemberError;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _managerEmailValidate = async (value: string) => {
    try {
      setIsManagerEmailCheckInProgress(true);
      const response = await queryClient.fetchQuery({
        queryFn: async () => {
          return await checkUserExists(value);
        },
        queryKey: ['user_exists', value],
        staleTime: 60000,
      });
      setIsManagerEmailCheckInProgress(false);
      if (response.userExistsAlready) {
        return memberAlreadyExists;
      } else {
        for (let x = 0; x < fields.length; x++) {
          if (value === getValues(`members.${x}.email`)) {
            return managerMemberSameError;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const debouncedTeamValidate = useMemo(() => {
    const _teamValidate = async (value: string) => {
      try {
        if (value.trim().length !== 0) {
          setIsTeamNameCheckInProgress(true);
          const response = await queryClient.fetchQuery({
            queryFn: async () => {
              return await checkTeamExist(value && value.trim());
            },
            queryKey: ['team_exists', value],
            staleTime: 60000,
          });
          if (response.teamNameExistsAlready) {
            setError('teamAlreadyExist', {
              type: 'manual',
              message: teamNameAlreadyExistMessage,
            });
          } else {
            clearErrors('teamAlreadyExist');
          }
          setIsTeamNameCheckInProgress(false);
        } else {
          clearErrors('teamAlreadyExist');
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    return debounce(_teamValidate, 1000)[0];
  }, [setError, clearErrors]);

  const TeamexistValidationCheck = async (value: any) => {
    try {
      if (value.trim().length !== 0) {
        setIsTeamNameCheckInProgress(true);
        const response = await queryClient.fetchQuery({
          queryFn: async () => {
            return await checkTeamExist(value && value.trim());
          },
          queryKey: ['team_exists', value],
          staleTime: 60000,
        });
        if (response.teamNameExistsAlready) {
          setError('teamAlreadyExist', {
            type: 'manual',
            message: teamNameAlreadyExistMessage,
          });
          setIsTeamNameCheckInProgress(false);
          return true;
        } else {
          setIsTeamNameCheckInProgress(false);
          clearErrors('teamAlreadyExist');
          return false;
        }
      } else {
        clearErrors('teamAlreadyExist');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const { addTeams } = useAddTeamsAPI();
  const { sendTeamsRequest } = useSendTeamsRequest();
  const [loading, setLoading] = useState(false);

  // add teams by admin api
  const onSubmit = async (data: any) => {
    const { teamName, managerEmail, jobTitle, members } = data;
    const isteamexist = await TeamexistValidationCheck(teamName);
    if (isteamexist) {
      console.log('Team Name already exists');
      return;
    }
    const manager = [
      {
        email: managerEmail,
        jobTitle: jobTitle,
        role: 'Manager',
      },
    ];
    const memberList = manager.concat(members);
    const teamData: AdminTeamData = {
      teamName: teamName,
      members: memberList,
      parentId: teamId,
    };
    try {
      await addTeams(teamData, SECTIONS.ADMIN_TEAMS_LIST_TABLE);
      await queryClient.invalidateQueries({
        queryKey: [FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY],
      });
      await queryClient.invalidateQueries({ queryKey: [FETCH_TEAM_NAME_QUERY_KEY(teamId)] });
      await toggleVisible();
    } catch (error) {
      console.error('Error adding team', error);
    }
  };

  // request api by leader and manager
  const onRequest = async (data: any) => {
    const { teamName, managerEmail, jobTitle, members } = data;
    const manager = [
      {
        email: managerEmail,
        jobTitle: jobTitle,
        role: Roles.MANAGER,
      },
    ];
    const memberList = manager.concat(members);
    const teamData = {
      requestType: REQUEST_TYPE.NEW_TEAM,
      teamName: teamName,
      members: memberList,
      parentId: teamId,
    };

    try {
      await sendTeamsRequest(teamData, SECTIONS.ADMIN_TEAMS_LIST_TABLE);
      toggleVisible();
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();
  const handleBilling = async () => {
    await navigate(`/settings?view=billing`);
  };
  // subscription info calculation
  const totalMembers = subscriptionData?.totalLicenses as number;
  const activeMembers = subscriptionData?.activeLicenses as number;
  const newTotalMembers = activeMembers + fields.length + 1;
  const totalAvailableLicenses =
    subscriptionData?.status === 'trialing'
      ? Math.min(TRAIL_LIMIT, totalMembers as number)
      : totalMembers;
  const isLicenseAvailable = newTotalMembers <= totalAvailableLicenses;
  const notAvailable = useHasAccess([USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]) && !isLicenseAvailable;

  const eventHandler = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      await debouncedTeamValidate(event.target.value);
    },
    [debouncedTeamValidate]
  );
  return (
    <div>
      <form name="form" id="form" className="w-full">
        <div className="-mx-3 mb-6 flex flex-wrap ">
          <label
            className="mb-2  block px-3 text-xs  font-medium normal-case tracking-wide text-zinc-50"
            htmlFor="sub_team_details"
          >
            {subTeamNameTitle}
          </label>

          <div className="w-full px-3">
            <label
              className="required mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-500"
              htmlFor="sub_team_name"
            >
              {subTeamNameLabel}
            </label>
            <div className="relative">
              <TextInput
                className="block h-9 w-full appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900 py-3 px-4 leading-tight text-zinc-50 placeholder:text-xs focus:border-gray-500  focus:outline-none"
                id="sub_team_name"
                type="text"
                placeholder={subTeamNamePlaceHolder}
                aria-invalid={fieldHasError(errors, 'teamName') ? 'true' : 'false'}
                hasError={fieldHasError(errors, 'teamName')}
                {...register('teamName', {
                  validate: (value) => {
                    const trimmedValue = value.trim();

                    if (trimmedValue.length == 0) {
                      return teamNameRequired;
                    }

                    if (trimmedValue.length < teamNameMinValue) {
                      return teamNameMinError;
                    }

                    if (trimmedValue.length > teamNameMaxValue) {
                      return teamNameMaxError;
                    }
                  },
                  onChange: eventHandler,
                })}
              />
              {isTeamNameCheckInProgress ? (
                <div className="absolute top-2 right-2">
                  <BasicSpinner className="leading-[14px] text-zinc-100" />
                </div>
              ) : null}
            </div>
            <HookFormErrorMessage
              name="teamName"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
            {errors?.teamAlreadyExist &&
              errors.teamAlreadyExist.type === 'manual' &&
              errors.teamAlreadyExist?.message === teamNameAlreadyExistMessage && (
                <span className="text-xs text-red-600">{teamAlreadyExists}</span>
              )}
          </div>
        </div>
        <div className="-mx-3 mb-6 flex flex-wrap">
          <div className="w-full justify-center px-3 py-5">
            <div className="grow border-t border-zinc-600 pb-4"></div>
            <label
              className="mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-50"
              htmlFor="sub_team_manager_details"
            >
              {subTeamManagerDetailsTitle}
            </label>
            <div className="flex w-full gap-2">
              <div className="w-3/5">
                <label
                  className="required mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-500"
                  htmlFor="sub_team_manager_email"
                >
                  {subTeamManagerEmailLabel}
                </label>
                <div className="relative">
                  <TextInput
                    className="block h-9 w-full appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900 py-3 px-4 pt-2 leading-tight text-zinc-50 placeholder:w-full placeholder:text-xs  focus:border-gray-500 focus:outline-none"
                    type={'text'}
                    id="manager_email"
                    placeholder={subTeamManagerEmailPlaceHolder}
                    aria-invalid={fieldHasError(errors, 'managerEmail') ? 'true' : 'false'}
                    hasError={fieldHasError(errors, 'managerEmail')}
                    {...register('managerEmail', {
                      required: {
                        value: true,
                        message: managerEmailRequired,
                      },
                      pattern: {
                        value: REGEX.CHECK_VALID_MAIL,
                        message: validEmailError,
                      },
                      validate: _managerEmailValidate,
                      maxLength: {
                        value: emailMaxValue,
                        message: emailLimitError,
                      },
                    })}
                  />
                  {isManagerEmailCheckInProgress ? (
                    <div className="absolute top-2 right-2">
                      <BasicSpinner className="leading-[14px] text-zinc-100" />
                    </div>
                  ) : null}
                </div>
                <HookFormErrorMessage
                  name="managerEmail"
                  errors={errors}
                  render={({ message }) => <ErrorMessage message={message} />}
                />
              </div>
              <div className="w-2/5">
                <label
                  className="required mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-500"
                  htmlFor="manager_job_title"
                >
                  {subTeamManagerJobLabel}
                </label>
                <div className="relative">
                  <TextInput
                    className="block h-9 w-full appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900 py-3 px-4 leading-tight text-zinc-50 placeholder:text-xs focus:border-gray-500  focus:outline-none"
                    type={'text'}
                    id="manager_job_title"
                    placeholder={subTeamManagerJobTitlePlaceHolder}
                    aria-invalid={fieldHasError(errors, 'jobTitle') ? 'true' : 'false'}
                    hasError={fieldHasError(errors, 'jobTitle')}
                    {...register('jobTitle', {
                      validate: (value) => {
                        const trimmedValue = value.trim();

                        if (trimmedValue.length == 0) {
                          return jobTitleRequired;
                        }

                        if (trimmedValue.length < jobTitleMinValue) {
                          return jobTitleMinError;
                        }

                        if (trimmedValue.length > jobTitleMaxValue) {
                          return jobTitleMaxError;
                        }
                      },
                    })}
                  />
                  <HookFormErrorMessage
                    name="jobTitle"
                    errors={errors}
                    render={({ message }) => <ErrorMessage message={message} />}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-3 py-5">
            <div className="grow border-t border-zinc-600 pb-4"></div>
            <label
              className="mb-2 block pt-1 text-xs  font-medium normal-case tracking-wide text-zinc-50"
              htmlFor="sub_team_member_details"
            >
              {subTeamMemberDetailsTitle}
            </label>
            {/* start here for the useField */}
            {fields.map((field: any, index: number) => {
              return (
                <div key={field.id} className="flex w-full gap-2  py-3">
                  <div className="w-3/5">
                    <label
                      className="required mb-2 block text-xs  font-medium normal-case text-zinc-500"
                      htmlFor="member_email"
                    >
                      {subTeamMemberEmailLabel}
                    </label>
                    <div className="relative">
                      <TextInput
                        className="block h-9 appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900  py-3 px-4 leading-tight text-zinc-50 placeholder:text-xs focus:border-gray-500  focus:outline-none"
                        type={'text'}
                        id="member_email"
                        onInput={() => {
                          setErrorIndex(index);
                        }}
                        placeholder={subTeamMemberEmailPlaceHolder}
                        aria-invalid={
                          fieldHasError(
                            errors,
                            errors.members?.[index]?.email?.type ? 'members' : ''
                          )
                            ? 'true'
                            : 'false'
                        }
                        hasError={fieldHasError(
                          errors,
                          errors.members?.[index]?.email?.type ? 'members' : ''
                        )}
                        {...register(`members.${index}.email`, {
                          required: {
                            value: true,
                            message: memberEmailRequired,
                          },
                          validate: (value) => _emailValidate(value, index),
                          pattern: {
                            value: REGEX.CHECK_VALID_MAIL,
                            message: validEmailError,
                          },
                          maxLength: {
                            value: emailMaxValue,
                            message: emailLimitError,
                          },
                        })}
                      />
                      {memberEmailIndexes.has(index) ? (
                        <div className="absolute top-2 right-1">
                          <BasicSpinner className="justify-items-end leading-[10px] text-zinc-100" />
                        </div>
                      ) : null}
                    </div>
                    <HookFormErrorMessage
                      name={`members.${index}.email`}
                      errors={errors}
                      render={({ message }) => <ErrorMessage message={message} />}
                    />
                  </div>
                  <div className="w-2/5">
                    <label
                      className="required mb-2 block text-xs  font-medium normal-case tracking-wide text-zinc-500"
                      htmlFor="member_job_title"
                    >
                      {subTeamMemberJobLabel}
                    </label>
                    <TextInput
                      className="block h-9 appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900 p-2 py-3 px-4 leading-tight text-zinc-50 placeholder:text-xs focus:border-gray-500  focus:outline-none"
                      type={'text'}
                      id="member_job_title"
                      onInput={() => {
                        setErrorIndex(index);
                      }}
                      placeholder={subTeamMemberJobTitlePlaceHolder}
                      aria-invalid={
                        fieldHasError(
                          errors,
                          errors.members?.[index]?.jobTitle?.type ? `members.${index}.jobTitle` : ''
                        )
                          ? 'true'
                          : 'false'
                      }
                      hasError={fieldHasError(
                        errors,
                        errors.members?.[index]?.jobTitle?.type ? 'members' : ''
                      )}
                      {...register(`members.${index}.jobTitle`, {
                        validate: (value) => {
                          const trimmedValue = value.trim();

                          if (trimmedValue.length == 0) {
                            return jobTitleRequired;
                          }

                          if (trimmedValue.length < jobTitleMinValue) {
                            return jobTitleMinError;
                          }

                          if (trimmedValue.length > jobTitleMaxValue) {
                            return jobTitleMaxError;
                          }
                        },
                      })}
                    />
                    <HookFormErrorMessage
                      name={`members.${index}.jobTitle`}
                      errors={errors}
                      render={({ message }) => <ErrorMessage message={message} />}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      {
                        remove(index);
                      }
                    }}
                    className="relative top-[2rem] flex h-9 text-xs text-base-brand"
                  >
                    <X size={16} />
                  </button>
                </div>
              );
            })}
          </div>
          {fields.length < 10 && (
            <div className="flex flex-col">
              <button
                type="button"
                onClick={() => {
                  append({ email: '', jobTitle: '', role: 'User' });
                }}
                className=" text-base-brand"
              >
                <div className="flex px-2  text-xs">
                  <Plus size={16} />
                  {fields.length === 0 ? subTeamAddNewMemberButton : subTeamAddAnotherMemberButton}
                </div>
              </button>
              <div className="mt-2">
                <ModalErrorAlert errors={errors} index={errorIndex as number} />
              </div>
            </div>
          )}
        </div>
        {notAvailable && subscriptionData && (
          <div className="pb-6">
            <SubscriptionInfo
              existingMemberCount={totalMembers as number}
              newMemberCount={newTotalMembers}
            />
          </div>
        )}
      </form>

      <div className="flex justify-end">
        <Dialog.Close className="px-7 text-zinc-50">{subTeamCancelButton}</Dialog.Close>
        {!notAvailable && (
          <>
            <HasAccess
              roles={[USHG_HQ_APP_ROLE.ENTERPRISE_LEADER, USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER]}
              Fallback={null}
            >
              <Button
                type="submit"
                onClick={handleSubmit(onRequest)}
                disabled={
                  isTeamNameCheckInProgress ||
                  isManagerEmailCheckInProgress ||
                  memberEmailIndexes.size != 0 ||
                  isSubmitting ||
                  loading
                }
                className="inline-block h-[40px] !rounded bg-base-brand px-6 py-2 text-sm font-medium normal-case leading-tight text-white hover:bg-base-brand disabled:opacity-80"
              >
                {isSubmitting ? (
                  <div className="flex items-center gap-2">
                    {subTeamAddTeamRequestButton}
                    <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
                  </div>
                ) : (
                  ` ${subTeamAddTeamRequestButton}`
                )}
              </Button>
            </HasAccess>
            <HasAccess roles={[USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN]} Fallback={null}>
              <Button
                type="submit"
                onClick={handleSubmit(onSubmit)}
                disabled={
                  isTeamNameCheckInProgress ||
                  isManagerEmailCheckInProgress ||
                  memberEmailIndexes.size != 0 ||
                  isSubmitting ||
                  loading
                }
                className="inline-block h-[40px] !rounded bg-base-brand px-6 py-2 text-sm font-medium normal-case leading-tight text-white hover:bg-base-brand disabled:opacity-80"
              >
                {isSubmitting ? (
                  <div className="flex items-center gap-2">
                    {subTeamAddTeamButton}
                    <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
                  </div>
                ) : (
                  `${subTeamAddTeamButton}`
                )}
              </Button>
            </HasAccess>
          </>
        )}
        {notAvailable && (
          <Button
            type="submit"
            onClick={() => handleBilling()}
            className="btn inline-block h-[40px] !rounded bg-base-brand px-6 py-2 text-sm font-medium normal-case leading-tight text-white hover:bg-base-brand"
          >
            {subTeamBillingButton}
          </Button>
        )}
      </div>
    </div>
  );
}

export default AddSubTeamModal;
