import ImageWithAcronymFallback from '../../../../components/ImageWithAcronymFallback';
import { Member } from '../../types/interface';

interface Props {
  user: Member;
}
function LeaderTeamsDetailedViewCircleView({ user }: React.PropsWithChildren<Props>) {
  if (user?.excessUser) {
    return (
      <div className="flex h-6 w-6 items-center justify-center rounded-full border border-zinc-700 bg-zinc-700 text-xs font-black text-white">
        {'+' + user?.count}
      </div>
    );
  } else {
    const acronymWord = user.name && user.name !== '' ? user.name : (user.username as string);

    return (
      <ImageWithAcronymFallback
        loading="lazy"
        imageKey={user.profileImage}
        acronymWord={acronymWord}
        className="aspect-square h-6 w-6 rounded-full border border-zinc-700 bg-zinc-100 object-cover object-center"
        acronymLogoClassName="h-6 w-6 aspect-square rounded-full border border-zinc-700 text-xs"
      />
    );
  }
}

export default LeaderTeamsDetailedViewCircleView;
