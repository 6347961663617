import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OrganizationSliceState {
  sortKey: string;
  sortOrder: string | boolean;
  dataRefreshState: boolean;
}

const initialState: OrganizationSliceState = {
  dataRefreshState: false,
  sortKey: 'name',
  sortOrder: 'asc',
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    toggleSortKey: (state, action: PayloadAction<any>) => {
      state.sortKey = action.payload;
    },
    toggleSortOrder: (state, action: PayloadAction<any>) => {
      state.sortOrder = action.payload;
    },
    resetSort: (state) => {
      state.sortKey = initialState.sortKey;
      state.sortOrder = initialState.sortOrder;
    },
    triggerDataRefresh: (state, action: PayloadAction<boolean>) => {
      state.dataRefreshState = action.payload;
    },
  },
});

export const { toggleSortKey, toggleSortOrder, resetSort, triggerDataRefresh } =
  organizationSlice.actions;

export default organizationSlice.reducer;
