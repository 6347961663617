import { Member } from '../../types/interface';
import LeaderTeamsDetailedViewCircleView from './LeaderTeamsDetailedViewCircleView';

interface Props {
  displayUsers?: Member[];
}
function LeaderTeamsDetailedViewCircleGroup({ displayUsers }: React.PropsWithChildren<Props>) {
  let renderList;

  if (displayUsers) {
    renderList = displayUsers?.map((user) => {
      return (
        <div key={`${user.id}-${user.username}`} className="grow basis-0">
          {/* //if index is more than */}
          <LeaderTeamsDetailedViewCircleView user={user} />
        </div>
      );
    });
    return <div className="flex w-max shrink-0 justify-start -space-x-2">{renderList}</div>;
  }
  return <></>;
}

export default LeaderTeamsDetailedViewCircleGroup;
