import { ArrowCounterClockwise } from 'phosphor-react';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { useTeamNameQuery } from '../../../../../teams/hooks';
import { useStrapiRequestData } from '../../../../hooks/useStrapiRequestData';

interface Props {
  teamId?: number;
  errorMessage?: string;
}

const TeamNameDialogItemContent = ({ teamId, errorMessage }: Props) => {
  // The query would be enabled only when the parentTeamId is not undefined
  const teamNameQuery = useTeamNameQuery({
    teamId,
  });
  const { errorMessages } = useStrapiRequestData();

  return (
    <>
      {/* Team Name is loading */}
      {(teamNameQuery.isLoading || teamNameQuery.isRefetching) && !teamNameQuery.data && (
        <BasicSpinner className="!m-0 h-[20px] text-zinc-100" />
      )}
      {/* Team Name is loaded */}
      {teamNameQuery.data && teamNameQuery.data.teamName && <>{teamNameQuery.data.teamName}</>}
      {teamNameQuery.isError && !teamNameQuery.data && !teamNameQuery.isRefetching && (
        <div className="flex items-center gap-2 py-2">
          <span className="text-error">{errorMessage}</span>
          <ArrowCounterClockwise
            size={12}
            color="currentColor"
            className="cursor-pointer text-zinc-100"
            onClick={() => {
              // Refetch the team name
              teamNameQuery.refetch();
            }}
          />
        </div>
      )}
      {teamId === undefined && (
        <span className="text-sm text-error">{errorMessages.invalidTeamInfoMessage}</span>
      )}
    </>
  );
};

export default TeamNameDialogItemContent;
