import React, { ReactElement, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';

type TabProps = {
  tabs: Array<any>;
  className?: string;
  setQueryParam?: (value: string) => void;
  tabClassName?: string;
};

const TabGroup = ({ tabs, className, setQueryParam, tabClassName }: TabProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTabIndex, setActiveTabIndex] = useState(onLoadGetParams());
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabsRef = useRef<any>([]);
  function onLoadGetParams() {
    const params = searchParams.get('view'); // view is set to english
    const index = tabs.findIndex((x) => (x.tag as string).toLowerCase() === params);
    const response = index > 0 ? index : 0;
    return response;
  }
  const setCurrentIndex = (
    tab: { title: string; tag: string; children: ReactElement },
    index: number
  ) => {
    setActiveTabIndex(index);
    setSearchParams({ view: (tab.tag as string).toLowerCase() });
  };
  useEffect(() => {
    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
      // set current tab
      if (setQueryParam) {
        setQueryParam(currentTab.innerText);
      }
    };

    setTabPosition();
    window.addEventListener('resize', setTabPosition);

    return () => window.removeEventListener('resize', setTabPosition);
  }, [activeTabIndex]);

  // When the query param changes update the active tab index
  // TODO: Check if anything breaks because of this
  useEffect(() => {
    const view = searchParams.get('view');
    const tabIndex = tabs.findIndex((tv) => tv.tag?.toLowerCase() === view?.toLowerCase());

    // If there is a tabView with that title
    // set that index or set it to zero
    if (tabIndex !== -1) {
      setActiveTabIndex(tabIndex);
    } else {
      setActiveTabIndex(0);
    }
  }, [searchParams, tabs]);

  return (
    <>
      <div
        className={clsx(
          'relative overflow-x-auto border-b border-b-zinc-800 bg-card-bg',
          tabClassName
        )}
      >
        <div className="flex space-x-4">
          {tabs.map((tab, idx) => {
            // Determine if the tab is the active tab
            const isActive = idx === activeTabIndex;
            return (
              <button
                key={idx}
                ref={(el) => (tabsRef.current[idx] = el)}
                className={clsx(
                  'py-3 text-sm',
                  idx === activeTabIndex ? 'text-base-brand' : 'text-zinc-300'
                )}
                onClick={() => setCurrentIndex(tab, idx)}
                tabIndex={isActive ? 0 : -1} // Only allow the active tab to be focusable
              >
                {tab.title}
              </button>
            );
          })}
        </div>
        <span
          className="absolute bottom-0 block h-1 bg-base-brand transition-all duration-300"
          style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
        />
      </div>
      <div className={clsx('p-4', className)}>
        <>{tabs[activeTabIndex].children}</>
      </div>
    </>
  );
};

export default TabGroup;
