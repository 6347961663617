import Dialog from '../../../../../components/Dialog';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../constants/user';
import { ModalType } from '../../../constants/badges';

import BadgeForm from './BadgeForm';

interface Props {
  modalTitle: string;
  setIsBadgeModalOpen: (open: boolean) => void;
  formActionButtonLabel: string;
  buttonIcon?: React.ReactNode;
  modalType: ModalType;
  refetch?: any;
  language?: string;
}

const BadgeModal = ({
  modalTitle,
  setIsBadgeModalOpen,
  buttonIcon,
  formActionButtonLabel,
  refetch,
  modalType,
  language = DEFAULT_PREFERRED_LANGUAGE,
}: Props) => {
  return (
    <div className="flex flex-col gap-4 rounded-lg border border-zinc-900 bg-card-bg p-6 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] sm:h-fit md:gap-6 ">
      <Dialog.Title className="text-lg font-semibold leading-6 text-white">
        {modalTitle}
      </Dialog.Title>
      <BadgeForm
        setIsBadgeModalOpen={setIsBadgeModalOpen}
        formActionButtonLabel={formActionButtonLabel}
        buttonIcon={buttonIcon}
        refetch={refetch}
        modalType={modalType}
        language={language}
      />
    </div>
  );
};

export default BadgeModal;
