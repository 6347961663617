import { useQuery } from '@tanstack/react-query';
import { initApi } from '../api/init';

/**
 * Wrapper around the react querys useQuery to fetch the assessment details.
 */
const useInitQuery = () => {
  const { isLoading, data, isError, refetch, isRefetching, isFetching, isSuccess } = useQuery({
    queryKey: [
      {
        scope: 'app',
        item: 'init',
      },
    ],
    queryFn: async () => {
      return await initApi();
    },
  });

  return { isLoading, data, isError, refetch, isRefetching, isFetching, isSuccess };
};

export default useInitQuery;
