import { AccountProvisionStatus } from '../constants/user';
import useAccountProvisionStatus from './use-account-provision-status';

const usePendingAccountProvision = () => {
  const accountProvisionStatus = useAccountProvisionStatus();

  return (
    accountProvisionStatus !== undefined &&
    accountProvisionStatus !== AccountProvisionStatus.COMPLETED
  );
};

export default usePendingAccountProvision;
