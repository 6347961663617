import { useDropzone } from 'react-dropzone';
import { CircleWavyCheck, CloudArrowUp, XCircle } from 'phosphor-react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { BasicSpinner } from '../Spinners';
import { useStrapiSharedData } from '../../features/shared/hooks/useStrapiSharedData';
import { formatNumber } from '../../utils';
import { VideoController } from '../MediaControls';
import { MODULE_TYPE } from '../../features/course-management/constants';
import { PDFViewer } from '../../features/courses/components/FileViewers';
import cn from '../../utils/cn';

type MediaUploadDnDProps = {
  fileType: string;
  maxSize: string;
  onDropCallback: any;
  isProgress: boolean;
  isUploaded: boolean;
  progress?: number;
  cancel?: any;
  initiatingUpload?: boolean;
  aborted?: boolean;
  loader: 'spinner' | 'progress';
  resetMedia?: () => void;
  isPreviewImage?: boolean;
  previewImage?: string;
  isPreviewMedia?: boolean;
  previewMedia?: string | null;
  mediaType?: (typeof MODULE_TYPE)[keyof typeof MODULE_TYPE];
  disabled?: boolean;
};
const MediaUploadDropZone = ({
  fileType,
  maxSize,
  onDropCallback,
  isProgress,
  isUploaded,
  progress,
  cancel,
  initiatingUpload,
  aborted,
  loader,
  previewImage,
  previewMedia,
  resetMedia,
  mediaType,
  isPreviewImage = false,
  isPreviewMedia = false,
  disabled = false,
}: MediaUploadDnDProps) => {
  const useDropCallBack = (data: any) => {
    onDropCallback(data);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: useDropCallBack,
  });

  const {
    dragAndDropText,
    clickToUploadText,
    maxLabel,
    orLabel,
    dropHereText,
    uploadedText,
    uploadingText,
    cancelButton,
    InitiatingUploadText,
  } = useStrapiSharedData().mediaUpload;

  return (
    <div
      className={cn(
        'relative h-[200px] rounded border border-zinc-700 bg-zinc-900 p-3',
        disabled && 'pointer-events-none opacity-70'
      )}
    >
      {!isUploaded && !isProgress && !initiatingUpload && (
        <div className="flex h-full flex-row items-center py-3" {...getRootProps()}>
          <input {...getInputProps()} />
          {!isDragActive ? (
            <div className="m-auto flex flex-col gap-1 text-white">
              <div className="m-auto aspect-square w-fit rounded-full border-4 border-zinc-800 bg-zinc-700 p-1.5">
                <CloudArrowUp className="text-zinc-300" size={15} />
              </div>
              <p className="mx-auto my-0 cursor-pointer text-xs">
                <span className="text-base-brand hover:underline">{clickToUploadText}</span>
                &nbsp;{orLabel}
                {dragAndDropText}
              </p>
              <p className="mx-auto my-0 text-[0.72rem]">
                {fileType} {maxSize !== '' && `${maxLabel}. ${maxSize}`}
              </p>
            </div>
          ) : (
            <div className="m-auto flex flex-col gap-1 text-white">
              <div className="m-auto aspect-square w-fit animate-bounce rounded-full border-4 border-zinc-800 bg-zinc-700 p-1.5">
                <CloudArrowUp className="text-zinc-300" size={15} />
              </div>
              <p className="mx-auto my-0 animate-pulse text-xs text-zinc-400">{dropHereText}</p>
            </div>
          )}
        </div>
      )}
      {initiatingUpload && (
        <div className="flex h-full w-full flex-col justify-center gap-1">
          <div className="flex w-full justify-center">
            <BasicSpinner className="ml-3 text-white" />
          </div>
          <p className="mx-auto animate-pulse text-xs text-white">{InitiatingUploadText}</p>
        </div>
      )}
      {loader === 'progress' && isProgress && (
        <div className="flex h-full w-full flex-col">
          <div className="m-auto aspect-square h-12">
            <CircularProgressbar
              value={progress ? progress : 0}
              text={`${formatNumber(progress)}%`}
              strokeWidth={14}
              styles={buildStyles({
                textColor: '#E4E4E7',
                pathColor: '#F7634F',
                trailColor: '#27272A',
              })}
            />
          </div>
          <div>
            <p className="text-center text-xs">
              <span
                className="animate-pulse cursor-pointer text-base-brand hover:underline"
                onClick={cancel}
              >
                {cancelButton}
              </span>
            </p>
          </div>
        </div>
      )}
      {loader === 'spinner' && isProgress && (
        <div className="flex h-full w-full flex-col justify-center gap-1">
          <div className="flex w-full justify-center">
            <BasicSpinner className="ml-3 text-white" />
          </div>
          <p className="mx-auto animate-pulse text-xs text-white">{uploadingText}</p>
        </div>
      )}
      {isPreviewImage && previewImage && (
        <>
          <XCircle
            className="absolute right-2 top-2 cursor-pointer text-sm text-white"
            onClick={() => {
              resetMedia && resetMedia();
            }}
          ></XCircle>
          <div className="flex h-full items-center justify-center">
            <img className="h-full max-w-[200px] rounded" src={previewImage} />
          </div>
        </>
      )}
      {isPreviewMedia && previewMedia && (
        <>
          <XCircle
            className="absolute right-2 top-2 z-10 cursor-pointer text-sm text-white"
            onClick={() => {
              resetMedia && resetMedia();
            }}
          ></XCircle>
          {mediaType && (
            <div className="flex h-full items-center justify-center">
              {mediaType === MODULE_TYPE.VIDEO || mediaType === MODULE_TYPE.AUDIO ? (
                <VideoController
                  className="aspect-video h-full max-w-[200px]"
                  thumbnail={previewImage}
                  video={previewMedia}
                />
              ) : mediaType === MODULE_TYPE.DOCUMENT ? (
                <PDFViewer src={previewMedia} name={null} />
              ) : null}
            </div>
          )}
          {!mediaType && (
            <VideoController
              className="aspect-video h-full max-w-[200px]"
              thumbnail={previewImage}
              video={previewMedia}
            />
          )}
        </>
      )}
      {isUploaded && !isProgress && !initiatingUpload && !isPreviewImage && !isPreviewMedia && (
        <div className="flex h-full w-full flex-col justify-center gap-1" {...getRootProps()}>
          <input disabled={aborted} {...getInputProps()} />
          <CircleWavyCheck className="mx-auto" color="#F7634F" size={30} weight="fill" />
          <p className="mx-auto text-xs text-white  hover:underline">{uploadedText}</p>
        </div>
      )}
    </div>
  );
};

export default MediaUploadDropZone;
