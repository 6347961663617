import type { RouteObject } from 'react-router-dom';
import SettingsPage from '../components/SettingsPage';

const routes: RouteObject[] = [
  {
    path: 'settings',
    element: <SettingsPage />,
  },
];

export default routes;
