import { UseQueryResult } from '@tanstack/react-query';
import { twMerge } from 'tailwind-merge';
import { ErrorDisplay } from '../../../../../components/DataTables';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';

interface Props {
  refetch: UseQueryResult['refetch'];
  isRefetching: boolean;
  className?: string;
  errorDisplayClassName?: string;
}

const MembersListLoadingError = (props: Props) => {
  const { refetch, isRefetching, className, errorDisplayClassName } = props;
  const {
    applicationLevelErrorBoundary: { tryAgainLabel },
  } = useStrapiSharedData();
  return (
    <div className={twMerge('flex h-full items-center', className)}>
      <ErrorDisplay
        refetch={refetch}
        isRefetching={isRefetching}
        message={tryAgainLabel}
        className={twMerge('h-max', errorDisplayClassName)}
        displayWarningIcon={true}
        allowsRefetch={true}
      ></ErrorDisplay>
    </div>
  );
};

export default MembersListLoadingError;
