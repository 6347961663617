import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Row } from '@tanstack/react-table';
import clsx from 'clsx';
import { DotsThreeVertical, PencilSimple, Plus } from 'phosphor-react';
import React, { useMemo, useState } from 'react';
import Dialog from '../../../../components/Dialog';
import { useStrapiTeamData } from '../../hooks/useStrapiTeamData';
import { RoleOptions, Team } from '../../types/interface';
import AddNewMemberMain from '../Modal/AddNewMemberModal/AddNewMemberMain';
import AddSubTeamMain from '../Modal/AddSubTeamModal/AddSubTeamMain';
import EditTeamMain from '../Modal/EditTeamModal/EditTeamMain';

// Team Table DropDown Item

type TeamTableDropDownItemProps = DropdownMenu.DropdownMenuItemProps &
  React.RefAttributes<HTMLDivElement>;

const TeamTableDropDownItem = ({
  children,
  className,
  ...otherProps
}: React.PropsWithChildren<TeamTableDropDownItemProps>) => {
  return (
    <DropdownMenu.Item
      className={clsx(
        'flex h-8 cursor-pointer items-center gap-x-2 rounded-md pl-2 pr-4 text-xs font-medium leading-5 text-zinc-50 outline-none hover:rounded-sm hover:focus:bg-zinc-700',
        className
      )}
      {...otherProps}
    >
      {children}
    </DropdownMenu.Item>
  );
};

// Team Table DropDown Item

interface Props {
  teamId: number;
  teamName?: string;
  username?: string;
  row: Row<Team>;
}

type ACTIVE_DIALOG_TYPE = 'EDIT_TEAM' | 'ADD_SUB_TEAM' | 'ADD_NEW_MEMBER';

export const TeamTableDropDown = ({ teamId, row, teamName }: Props) => {
  const { topNav } = useStrapiTeamData();
  const { topNavAddMemberButton, topNavAddSubTeamButton, topNavEditTeamButton } = topNav;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [activeDialogType, setActiveDialogType] = useState<ACTIVE_DIALOG_TYPE | null>(null);

  const ActiveDialogContent = useMemo(() => {
    switch (activeDialogType) {
      case 'ADD_NEW_MEMBER': {
        return (
          <AddNewMemberMain
            teamId={teamId}
            teamName={teamName}
            setIsModalOpen={setIsDialogOpen}
            isLoading={false}
            dropDownOptions={RoleOptions.MEMBER_MANAGER}
            isOnBoarding={false}
            isAllMembers={false}
          />
        );
      }
      case 'ADD_SUB_TEAM': {
        return <AddSubTeamMain teamId={teamId} setIsModalOpen={setIsDialogOpen} />;
      }
      case 'EDIT_TEAM': {
        return (
          <EditTeamMain teamId={teamId} setIsModalOpen={setIsDialogOpen} teamName={teamName} />
        );
      }
      default: {
        return null;
      }
    }
  }, [activeDialogType, teamId, teamName]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <DotsThreeVertical size={20} />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            side={'bottom'}
            align={'end'}
            hideWhenDetached={true}
            className="z-20 min-w-[150px] rounded-lg border border-zinc-700 bg-zinc-800 p-1 shadow-[0px_25px_50px_-12px_rgba(0,0,0,0.25)]"
          >
            <TeamTableDropDownItem
              onClick={() => {
                setActiveDialogType('EDIT_TEAM');
                setIsDialogOpen(true);
              }}
            >
              <PencilSimple size={12} />
              <p className="leading-5 ">{topNavEditTeamButton}</p>
            </TeamTableDropDownItem>

            {!row.original.isSubTeam && (
              <TeamTableDropDownItem
                onClick={() => {
                  setActiveDialogType('ADD_SUB_TEAM');
                  setIsDialogOpen(true);
                }}
              >
                <Plus size={12} />
                <p className="leading-5 ">{topNavAddSubTeamButton}</p>
              </TeamTableDropDownItem>
            )}

            <TeamTableDropDownItem
              onClick={() => {
                setActiveDialogType('ADD_NEW_MEMBER');
                setIsDialogOpen(true);
              }}
            >
              <Plus size={12} />
              <p className="leading-5 ">{topNavAddMemberButton}</p>
            </TeamTableDropDownItem>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      {/* Dialog */}
      <Dialog
        containerClassName="max-h-[80vh] overflow-y-auto"
        open={isDialogOpen}
        onOpenChange={(open) => setIsDialogOpen(open)}
        Content={ActiveDialogContent}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      ></Dialog>
    </div>
  );
};
