import { CognitoCustomClaim, TenantMFAConfig, ushgHQAppRoleMetadata } from '../../../constants';
import { signOutUser } from './login';

// Get the app role from the groups the user is enrolled in
const resolveRoleFromCognitoUserGroups = (groups: string[]) => {
  const satisfiedRoleNames = Object.getOwnPropertyNames(ushgHQAppRoleMetadata)
    // Filter all the roles where the group is a group the user is part of
    .filter((key) => {
      const ushgHQRole = ushgHQAppRoleMetadata[key];

      if (ushgHQRole === undefined || ushgHQRole == null) {
        return false;
      }

      const satisfies = groups.includes(ushgHQRole.cognitoUserGroup);
      return satisfies;
    })
    // Sort all the satisfied groups with the precedence and take the first
    // The one with lowest precedence (the smaller the precedence the greater the role)
    // 0 highest and decreases as value increases
    .sort((k1, k2) => ushgHQAppRoleMetadata[k1].precedence - ushgHQAppRoleMetadata[k2].precedence);

  // If the user is not in any of the groups that are pre-defined for roles throw
  // an error
  if (!satisfiedRoleNames || satisfiedRoleNames.length == 0) {
    throw {
      code: 'USER_DOES_NOT_BELONG_TO_ANY_ROLE',
      message: "User doesn't belong to any of the pre-defined roles",
    };
  }

  const roleName = satisfiedRoleNames[0];

  return roleName;
};

// Map CognitoUser from aws-amplify to the user structure we are
// storing in Redux
// TODO: Amplify Cognito User type doesn't match actual response
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapCognitoUserToReduxUser = (data: any) => {
  try {
    const { username, attributes, signInUserSession, preferredMFA } = data;

    const groups = signInUserSession?.accessToken?.payload['cognito:groups'] ?? [];
    const role = resolveRoleFromCognitoUserGroups(groups);

    // If there is a tenant_mfa_config use that else default to OPTIONAL
    const tenantMFAConfig =
      signInUserSession?.idToken?.payload?.[CognitoCustomClaim.TENANT_MFA_CONFIG] ??
      TenantMFAConfig.OPTIONAL;

    const user = {
      username,
      ...attributes,
      groups,
      role,
      preferredMFA: preferredMFA,
      [CognitoCustomClaim.TENANT_MFA_CONFIG]: tenantMFAConfig,
    };

    return user;
  } catch (error) {
    // Sign out the user if there is any error
    const { code } = error as { code: string; message: string };

    // The below error is throw in @resolveRoleFromCognitoUserGroups
    if (code == 'USER_DOES_NOT_BELONG_TO_ANY_ROLE') {
      signOutUser();
    }

    throw error;
  }
};

export { mapCognitoUserToReduxUser };
