import { ChartData } from 'chart.js';
import clsx from 'clsx';
import { DoughnutChart } from './baseCharts';
import ChartHeading from './ChartHeading';
import { useCoursePerformanceRatesQuery } from '../../hooks';
import { DashboardReportsFilter, ReportTooltipDataType } from '../../types';
import { ErrorDisplay } from '../../../../components/DataTables';
import NoDataFallback from '../NoDataFallback';
import { useMemo, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { formatNumber } from '../../../../utils';
import { useStrapiReportsData } from '../../hooks/useStrapiReportData';
import { useParams } from 'react-router-dom';
import { USHG_HQ_APP_ROLE } from '../../../../constants';
import { useAppSelector } from '../../../../hooks';
import { RootState } from '../../../../store';
import ScaleLoader from '../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../components/ReportToolTip';
import { REPORT_TYPES } from '../../constants';

interface Props {
  className?: string;
  heading?: string;
  lazyLoad?: boolean;
  teamId?: string;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}
const authStateSelector = (state: RootState) => state.auth;
const CoursePerformanceRatesViz = ({
  className,
  heading,
  lazyLoad = true,
  teamId,
  reportTooltipContent,
  userPersona,
}: Props) => {
  const { user } = useAppSelector(authStateSelector);
  const isIndividualUser = user && user.role === USHG_HQ_APP_ROLE.INDIVIDUAL_USER;

  const { key1, key2, key3, emptyChart, errorChart } = useStrapiReportsData().completionRate;
  const { id } = useParams();
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);

  // include username in filter if exists in route path
  // Build the filter
  const filters: Partial<DashboardReportsFilter> = useMemo(() => {
    return {
      username: id,
      teamId: teamId && teamId !== '1' ? teamId : undefined,
    };
  }, [id, teamId]);

  const coursePerformanceRates = useCoursePerformanceRatesQuery({ filters, isEnabled: isInView });

  const hasCoursePerformanceRates =
    coursePerformanceRates.data &&
    coursePerformanceRates.data.result &&
    Object.keys(coursePerformanceRates.data.result).length > 0;

  const hasNoCoursePerformanceRates =
    coursePerformanceRates.data &&
    coursePerformanceRates.data.result &&
    Object.keys(coursePerformanceRates.data.result).length == 0;

  const isLoadingCoursePerformanceRates = coursePerformanceRates.isLoading;

  const data = useMemo(() => {
    const labels: string[] = [key1, key2];
    const backgroundColorArray: string[] = ['#37990E', '#F7634F'];
    if (!isIndividualUser) {
      labels.push(key3);
      backgroundColorArray.push('#F3EAC7');
    }
    let performanceRates: number[] = [];

    if (hasCoursePerformanceRates) {
      const { result } = coursePerformanceRates.data;

      performanceRates = [result.coursesCompleted, result.coursesIncomplete];
      if (!isIndividualUser) performanceRates.push(result.coursesOverdue);
    }
    const data: ChartData<'doughnut', number[], unknown> = {
      labels: performanceRates.some(Boolean) ? labels : [],
      datasets: [
        {
          data: performanceRates,
          backgroundColor: backgroundColorArray,
          borderColor: backgroundColorArray,
          borderWidth: 1,
        },
      ],
    };
    return { data, performanceRates };
  }, [hasCoursePerformanceRates, coursePerformanceRates.data]);

  return (
    <InView
      as="div"
      className={clsx(
        'flex h-full min-h-[420px] flex-col gap-5 rounded-lg border border-zinc-700 bg-card-bg pt-4 pb-7',
        className
      )}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div className="flex flex-col gap-2 pl-4">
        <div className="flex items-center gap-2">
          <ChartHeading heading={heading as string} />
          {reportTooltipContent?.[REPORT_TYPES.COURSE_PERFORMANCE_RATES]?.[userPersona] && (
            <ReportTooltip
              content={reportTooltipContent[REPORT_TYPES.COURSE_PERFORMANCE_RATES][userPersona]}
            />
          )}
        </div>
        {hasCoursePerformanceRates && (
          <p className="text-4xl font-semibold text-zinc-200">
            {formatNumber(coursePerformanceRates.data?.result?.percentage)}%
          </p>
        )}
      </div>
      <hr className="h-px border-0 bg-gray-700" />
      {isLoadingCoursePerformanceRates && !hasCoursePerformanceRates && (
        <div className="flex grow items-center justify-center">
          <ScaleLoader />
        </div>
      )}
      {/* Error State */}
      <>
        {coursePerformanceRates.error && !coursePerformanceRates.data && (
          <div className="flex grow items-center justify-center">
            <ErrorDisplay
              message={errorChart}
              refetch={coursePerformanceRates.refetch}
              allowsRefetch={true}
              isRefetching={coursePerformanceRates.isRefetching}
            />
          </div>
        )}
      </>
      {hasCoursePerformanceRates && data.performanceRates.some(Boolean) && (
        <DoughnutChart className="m-auto max-h-60 min-h-[15rem] w-full" data={data.data} />
      )}
      {!isLoadingCoursePerformanceRates &&
        (!data.performanceRates.some(Boolean) || hasNoCoursePerformanceRates) &&
        !coursePerformanceRates.error && <NoDataFallback message={emptyChart} />}
    </InView>
  );
};

export default CoursePerformanceRatesViz;
