import UserForgotPasswordForm from './UserForgotPasswordForm';
import UserForgotPasswordMainFooter from './UserForgotPasswordMainFooter';
import UserLoginHeader from '../common/UserLoginHeader';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';

const UserForgotPasswordMain = () => {
  const data: any = useStrapiDataHelper();
  const attributes = data?.forgotpassword.data?.attributes;
  const { title, description } = attributes;
  return (
    <main
      id="main-content"
      className="flex max-w-md grow flex-col items-center gap-5 justify-self-center pt-[10vw]"
    >
      <UserLoginHeader heading={title} subHeading={description} />
      <UserForgotPasswordForm className="pt-5" />
      <UserForgotPasswordMainFooter />
    </main>
  );
};

export default UserForgotPasswordMain;
