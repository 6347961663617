import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { updateAssessment } from '../api';
import { AssessmentArgs } from '../types';
import { useStrapiHQAdminCoursesData } from './useStrapiHQAdminCourseData';

const useUpdateAssessmentMutation = () => {
  const {
    toastMessages: {
      updateAssessmentSuccessTitle,
      updateAssessmentSuccessMessage,
      updateAssessmentFailureTitle,
      updateAssessmentFailureMessage,
    },
  } = useStrapiHQAdminCoursesData();
  const mutation = useMutation({
    mutationFn: async (args: AssessmentArgs) => {
      const response = await updateAssessment(args);
      return response;
    },

    onSuccess: () => {
      toast.custom((t) => (
        <Toast
          variant="success"
          Title={updateAssessmentSuccessTitle}
          SubTitle={updateAssessmentSuccessMessage}
          toastInstance={t}
        />
      ));
    },
    onError: (error) => {
      console.error('Error while updating an assessment %o', error);
      toast.custom((t) => (
        <Toast
          variant="error"
          Title={updateAssessmentFailureTitle}
          SubTitle={updateAssessmentFailureMessage}
          toastInstance={t}
        />
      ));
    },
  });

  return mutation;
};

export default useUpdateAssessmentMutation;
