import clsx from 'clsx';
import React from 'react';
import { TabWidgetType } from '../../../../types/tab-widget.types';

type TabWidgetsProps = {
  selectedWidget: number;
  widgetData: TabWidgetType[];
  handleWidgetToggle: (widgetIndex: number) => void;
};

export const Tabs = ({ widgetData, selectedWidget, handleWidgetToggle }: TabWidgetsProps) => {
  const onWidgetClickHandler = (widgetIndex: number) => {
    handleWidgetToggle(widgetIndex);
  };

  return (
    <div className="flex w-full items-center justify-center text-zinc-50">
      {widgetData &&
        widgetData.map((widget, index) => {
          return (
            <div
              key={widget.id}
              onClick={() => onWidgetClickHandler(index)}
              className="flex items-center"
            >
              <div
                className={`flex cursor-pointer items-center justify-center gap-2 rounded-full font-medium`}
              >
                <div
                  className={clsx(
                    'flex w-fit items-center justify-center rounded-full py-2 pl-1 pr-4 font-bold text-gray-700 transition duration-1000 ease-in-out',
                    index === selectedWidget && 'bg-orange-100',
                    widget.completed && index !== selectedWidget && 'bg-emerald-100 '
                  )}
                >
                  <div className="flex w-fit items-center space-x-4">
                    <span
                      className={clsx(
                        'flex h-6 w-6 items-center justify-center rounded-full bg-zinc-700 font-bold text-zinc-50',
                        index === selectedWidget && '!bg-base-brand !text-white',
                        widget.completed &&
                          index !== selectedWidget &&
                          '!bg-emerald-500 !text-white'
                      )}
                    >
                      {widget.completed && index !== selectedWidget ? (
                        <span className="text-xl font-normal text-white">&#10003;</span>
                      ) : (
                        <span className="flex h-6 w-6 items-center justify-center rounded-full bg-base-brand text-white">
                          {index + 1}
                        </span>
                      )}
                    </span>

                    <p className="text-xs font-medium leading-4 text-gray-700">{widget.text}</p>
                  </div>
                </div>
              </div>
              <div
                className={
                  index !== widgetData.length - 1
                    ? `w-0 border-t-2 px-2 transition duration-500 ease-in-out lg:w-8  ${
                        widget.completed && index !== selectedWidget
                          ? 'border-emerald-500'
                          : 'border-dashed border-zinc-700 '
                      }  `
                    : ''
                }
              ></div>
            </div>
          );
        })}
    </div>
  );
};
