import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { useReduxAuthState } from '../../../hooks';
import { useStrapiSettingsData } from '../../settings/hooks/useStrapiSettingsData';
import { getUserProfile } from '../api';

const useProfileQuery = () => {
  const { user, isAuthenticated } = useReduxAuthState();

  const {
    toastMessage: { profileFetchError },
  } = useStrapiSettingsData();
  const profile = useQuery(
    [
      {
        scope: 'profile',
        item: 'profile',
        username: user?.username,
      },
    ],
    async () => {
      return getUserProfile();
    },
    {
      keepPreviousData: true,
      staleTime: 300000,
      // Having the stale time as 5 minutes here, as the profile details won't be changing frequently.
      // But we invalidate and refetch profile details when there is any update by the user
      onError: () => {
        toast.custom((t) => (
          <Toast variant={'error'} Title={''} SubTitle={profileFetchError} toastInstance={t} />
        ));
      },
      enabled: isAuthenticated,
    }
  );

  return profile;
};

export default useProfileQuery;
