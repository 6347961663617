export const USHG_ADMIN_MODULE_TABLE_SORT_BY_VALUES = ['MOST_RECENT', 'EARLIEST'] as const;

export const USHG_ADMIN_MODULE_TABLE_RATING_BY_VALUES = ['1', '2', '3', '4', '5'] as const;

export const USHG_ADMIN_DEFAULT_MODULE_TABLE_SORT_BY = 'MOST_RECENT';

// Filters

export interface USHGAdminModuleTableFilter {
  start: number;
  serach: string;
  size: number;
  sortOrder: string | boolean;
  sortKey: string;
}

export type PartialUSHGAdminModuleTableFilter = Partial<USHGAdminModuleTableFilter>;

// DEBOUNCE on Module table made for search input in milliseconds
export const MODULE_TABLE_FILTER_SEARCH_DEBOUNCE_MS = 500;
// DEBOUNCE on Module table made for search input

// Pagination
export const MODULE_TABLE_PAGE_SIZE_INCREMENT = 10;
export const MODULE_TABLE_MAX_PAGE_SIZE = 50;
export const MODULE_TABLE_MIN_PAGE_SIZE = 10;

export const MODULE_TABLE_DEFAULT_PAGE = 1;
// Pagination

// Query Params
export const MODULE_TABLE_RATING_QUERY_PARAM = 'rating';
export const MODULE_TABLE_SORT_QUERY_PARAM = 'sort';
export const MODULE_TABLE_FILTER_QUERY_PARAM = 'moduleFilter';
// Query Params

// consider the MODULE_TABLE stale after 30 seconds
export const MODULE_TABLE_STALE_TIME = 30000;

// one min as they would not probably change
export const USHG_ADMIN_MODULE_TABLE_TYPES_FETCH_STALE_TIME = 60000;

// MODULE_TABLE

export interface USHGAdminCourseModuleSpeakers {
  id: number;
  name: string;
  picture: string;
}
export interface USHGAdminModuleTableResults {
  courseId: string;
  language: string;
  lifetimeCompletions: number;
  moduleId: string;
  name: string;
  speakers: string[];
  updatedOn: string;
}

export interface USHGAdminModuleTable {
  moduleName: string;
  moduleTakenBy: number;
  speakerName: string;
  speakerPictureUrl: string | null;
  updatedOn: string;
}
