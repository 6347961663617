import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { useStrapiLoginMFAContent } from '../../../../../../hooks';
import ResendMFACode from './ResendMFACode';
import { useContext } from 'react';
import { userLoginCredentialsContext } from '../../../../../../context';

type Props = {
  className?: string;
  goBackToLogin: () => void;
};

const SMSMFABottom = (props: Props) => {
  const { className, goBackToLogin } = props;

  // context
  const { loginCredentials } = useContext(userLoginCredentialsContext);
  // context

  // strapi
  const loginMFACMS = useStrapiLoginMFAContent();
  // strapi

  // derived
  // show resend only if we have login credentials
  const allowCodeResend = !!loginCredentials;
  // derived

  return (
    <div
      className={twMerge(
        clsx(
          'flex flex-col items-center gap-4 text-center text-sm font-normal leading-6',
          className
        )
      )}
    >
      {allowCodeResend && <ResendMFACode />}
      <button onClick={goBackToLogin} className="text-white">
        {loginMFACMS.Shared.backToLoginLabel}
      </button>
      <span className="text-zinc-400 ">{loginMFACMS.Shared.lostAccessLabel}</span>
    </div>
  );
};

export default SMSMFABottom;
