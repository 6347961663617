import clsx from 'clsx';

type ChartHeadingProps = {
  heading: string;
  className?: string;
};

const ChartHeading = ({ heading, className }: ChartHeadingProps) => {
  return (
    <h2 className={clsx('text-sm font-semibold leading-[21px] text-zinc-50', className)}>
      {heading}
    </h2>
  );
};

export default ChartHeading;
