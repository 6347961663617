interface Props {
  text: string;
  isLowercase?: boolean;
}

const CommonTextCell = ({ text, isLowercase = false }: Props) => {
  return (
    <span
      className={
        'inline-block whitespace-nowrap lowercase' + isLowercase && 'first-letter:uppercase'
      }
    >
      {text}
    </span>
  );
};

export default CommonTextCell;
