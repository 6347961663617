import { Star } from 'phosphor-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Button } from '../../../../../../../../components/Buttons';
import Dialog from '../../../../../../../../components/Dialog';
import { TextAreaInput } from '../../../../../../../../components/FormElements';
import { BasicSpinner } from '../../../../../../../../components/Spinners';
import useGetUserFeedbackQuery from '../../../../../../hooks/useGetUserFeedbackQuery';
import { useStrapiCoursesData } from '../../../../../../hooks/useStrapiCourseData';
import { submitFeedbackFormType } from '../../../../types/SingleCourseTypes';
import { FEEDBACK_TYPE } from '../../constants/userRatingConstants';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Ratings Form Schema section starts

type GetRatingsSchemaParams = {
  feedbackLabel: string;
  ratingLabel: string;
  ratingRequired: string;
  // feedbackRequired: string;
  // feedbackMinError: string;
  // feedbackMinValue: number;
  feedbackMaxValue: number;
  feedbackMaxError: string;
};

/**
 * Generate schema for ratings form based on attributes from strapi
 */
const getRatingsSchema = (params: GetRatingsSchemaParams) => {
  const {
    feedbackLabel,
    ratingLabel,
    ratingRequired,
    // feedbackRequired,
    feedbackMaxError,
    feedbackMaxValue,
    // feedbackMinError,
    // feedbackMinValue,
  } = params;

  const ratingsSchema = yup.object({
    feedback: yup
      .string()
      .trim()
      .max(feedbackMaxValue, feedbackMaxError)
      .label(feedbackLabel),
      // .min(feedbackMinValue, feedbackMinError)
      // .required(feedbackRequired)
      
    rating: yup
      .number()
      .transform((value) => {
        if (isNaN(value)) {
          return undefined;
        }
        return value;
      })
      .min(1, ratingRequired)
      .max(5)
      .required(ratingRequired)
      .label(ratingLabel),
  });

  return ratingsSchema;
};

type RatingsFormData = yup.InferType<ReturnType<typeof getRatingsSchema>>;

// Ratings Form Schema section ends

type Props = {
  submitFormHandler: (data: submitFeedbackFormType) => void;
  isSubmitting: boolean;
};

const RatingComponent = ({ submitFormHandler, isSubmitting }: Props) => {
  const { id } = useParams();
  const userFeedbackQuery = useGetUserFeedbackQuery({ itemId: id, type: FEEDBACK_TYPE.COURSE });
  const noOfStars = 5;
  const [rating, setRating] = useState<number>(0);

  // Strapi

  const { courseCompleted } = useStrapiCoursesData();
  const {
    ratingLabel,
    starsLabel,
    // TODO: We don't need a placeholder for ratings (??)
    // ratingPlaceholder,
    feedbackLabel,
    feedbackPlaceHolder,
    // feedbackRequired,
    // feedbackMinError,
    // feedbackMinValue,
    feedbackMaxValue,
    feedbackMaxError,
    closeButton,
    submitButton,
    ratingRequired,
  } = courseCompleted;

  // Strapi

  const ratingsSchema = useMemo(() => {
    const schema = getRatingsSchema({
      feedbackLabel,
      feedbackMaxError,
      feedbackMaxValue,
      // feedbackMinError,
      // feedbackMinValue,
      // feedbackRequired,
      ratingLabel,
      ratingRequired,
    });
    return schema;
  }, [
    feedbackLabel,
    feedbackMaxError,
    feedbackMaxValue,
    // feedbackMinError,
    // feedbackMinValue,
    // feedbackRequired,
    ratingLabel,
    ratingRequired,
  ]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<RatingsFormData>({
    defaultValues: {
      rating: 0,
      feedback: '',
    },
    resolver: yupResolver(ratingsSchema),
  });

  useEffect(() => {
    if (userFeedbackQuery.data) {
      const { rating, feedback } = userFeedbackQuery.data;
      setRating(parseInt(rating));
      setValue('feedback', feedback);
      setValue('rating', rating);
    }
  }, [userFeedbackQuery.data, setValue]);

  const isLoader =
    userFeedbackQuery.isLoading || userFeedbackQuery.isFetching || userFeedbackQuery.isRefetching;

  if (isLoader) {
    return (
      <div className="flex w-full flex-col items-center p-6 text-white">
        <BasicSpinner />
      </div>
    );
  }

  const ratingHandler = (index: number) => {
    setRating(index + 1);
    setValue('rating', index + 1, {
      shouldValidate: true,
    });
  };

  return (
    <form onSubmit={handleSubmit(submitFormHandler)}>
      <div className="flex flex-col gap-5">
        <span className="text-lg font-semibold text-white">{ratingLabel}</span>
        <div className="flex flex-row items-center gap-2">
          {[...Array(noOfStars)].map((element, index) => {
            const isFilled = index < rating;
            return (
              <div key={element} onClick={() => ratingHandler(index)}>
                <Star
                  color={isFilled ? '#FBBF24' : undefined}
                  weight={isFilled ? 'fill' : undefined}
                />
              </div>
            );
          })}
          <span>
            {rating} {starsLabel}
          </span>
        </div>
        <input type="hidden" {...register('rating')} />
        {errors.rating && errors.rating.message && (
          <span className="text-xs text-red-600">*{errors.rating.message}</span>
        )}
      </div>
      <div className="my-6 h-[1px] w-full bg-zinc-600" />
      <div className="flex flex-col gap-4">
        <span className="text-lg font-semibold text-white">{feedbackLabel}</span>
        <TextAreaInput placeholder={feedbackPlaceHolder} rows={4} {...register('feedback')} />
        {errors.feedback && errors.feedback.message && (
          <span className="text-xs text-red-600">*{errors.feedback.message}</span>
        )}
      </div>
      <div className="mt-4 flex flex-row-reverse text-sm font-medium text-white ">
        <Button
          type="submit"
          className="flex flex-row items-center bg-base-brand px-7 py-2.5"
          disabled={isSubmitting}
          contentWrapperClassName="gap-x-2 items-center"
        >
          <span>{submitButton}</span>
          {isSubmitting && <BasicSpinner className="!m-0 h-4 w-4" />}
        </Button>

        <Dialog.Close asChild className="mx-2">
          <Button> {closeButton} </Button>
        </Dialog.Close>
      </div>
    </form>
  );
};

export default RatingComponent;
