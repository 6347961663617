import { ChangeEvent, useState } from 'react';
import Dialog from '../../../../../../../components/Dialog';
import Select from '../../../../../../../components/Select';
import { ModalType } from '../../../../../types';
import ModuleAssessmentForm from './ModuleAssessmentForm';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../../../constants/user';
import { BasicSpinner } from '../../../../../../../components/Spinners';
import { MODULE_ASSESSMENT_ACTION } from '../../../../../constants';

interface Props {
  modalTitle?: string;
  setIsModuleModalOpen: (open: boolean) => void;
  modulesAssessmentPrefetchData?: Record<string, any>; // Todo: assign right type
  formActionButtonLabel: string;
  refetch?: any;
  modalType: ModalType;
  showLanguageDropdown?: boolean;
  mappedOptions?: { display: string; value: string }[];
  selectedLanguage?: string;
  handleLanguageChange?: (newLanguage: string) => void;
  errorType?: string | null;
  setErrorType?: (error: string | null) => void;
}

const ModuleAssessmentModal = ({
  modalTitle,
  setIsModuleModalOpen,
  modulesAssessmentPrefetchData,
  formActionButtonLabel,
  refetch,
  modalType,
  mappedOptions,
  selectedLanguage,
  handleLanguageChange,
  errorType,
  setErrorType,
  showLanguageDropdown = false,
}: Props) => {
  // state

  // create, update of module assessment details action in progress
  const [actionInProgress, setActionInProgress] = useState<
    keyof typeof MODULE_ASSESSMENT_ACTION | null
  >(null);

  // state
  return (
    <div className="mx-1 flex h-[75vh] flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <div className="flex items-center">
        <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
          {modalTitle}
        </Dialog.Title>
        {showLanguageDropdown && modulesAssessmentPrefetchData?.data && (
          <Select
            aria-label="Language options"
            options={mappedOptions?.length ? mappedOptions : []}
            value={selectedLanguage}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleLanguageChange?.(e.target.value)}
            disabledOptions={modalType === 'manage' ? [DEFAULT_PREFERRED_LANGUAGE] : []}
            className="ml-auto"
            disabled={!!actionInProgress}
          />
        )}
      </div>
      {modulesAssessmentPrefetchData?.isLoading || modulesAssessmentPrefetchData?.isFetching ? (
        <div className="flex h-full w-full items-center justify-center py-4">
          <BasicSpinner className="h-[30vh] text-white" />
        </div>
      ) : (
        (modulesAssessmentPrefetchData?.data || modalType === 'create') && (
          <ModuleAssessmentForm
            setIsModuleModalOpen={setIsModuleModalOpen}
            modulesAssessmentPrefetchData={modulesAssessmentPrefetchData}
            formActionButtonLabel={formActionButtonLabel}
            modalType={modalType}
            refetch={refetch}
            language={selectedLanguage ?? DEFAULT_PREFERRED_LANGUAGE}
            errorType={errorType}
            setErrorType={setErrorType}
            setActionInProgress={setActionInProgress}
          />
        )
      )}
    </div>
  );
};

export default ModuleAssessmentModal;
