import Alert from '../../../../../components/Alert';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';

const UserResetPasswordEmailInstructions = () => {
  const data: any = useStrapiDataHelper();
  const attributes = data?.resetpassword.data.attributes;
  const { verificationInfoText1, verificationInfoText2 } = attributes;
  return (
    <Alert
      variant="success"
      message={
        <>
          <div className="flex flex-col gap-2">
            <span>{verificationInfoText1}</span>
            <span className="text-emerald-200">{verificationInfoText2}</span>
          </div>
        </>
      }
    />
  );
};

export default UserResetPasswordEmailInstructions;
