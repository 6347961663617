import { UserList } from 'phosphor-react';
import SideBar from '../../../../../components/SideBar';
import { useStrapiSideNavData } from '../../../../../hooks/useStrapiSideNavData';
import { CustomSideNavItemProps } from '../../../types';

const AllMembersNavItem = ({ children }: React.PropsWithChildren<CustomSideNavItemProps>) => {
  const { routes } = useStrapiSideNavData();
  return (
    <SideBar.SideBarNavItem
      icon={
        <UserList
          aria-label="All Members List"
          size="20px"
          color="currentColor"
          className="text-inherit"
        />
      }
      label={routes.allMembersLabel}
      path="/members/all"
    >
      {children}
    </SideBar.SideBarNavItem>
  );
};

export default AllMembersNavItem;
