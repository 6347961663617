import { useNavigate, useParams } from 'react-router-dom';
import { ViewHeader } from '../common';

interface ModulePageHeaderProps {
  moduleName: string;
}

const PageHeader = ({ moduleName }: ModulePageHeaderProps) => {
  const { id: courseId } = useParams();
  const navigate = useNavigate();

  const navigateToCourseEditPage = () => {
    navigate(`/manage/courses/${courseId}/edit`);
  };

  return (
    <ViewHeader
      onBackClick={navigateToCourseEditPage}
      heading={moduleName}
      hideButton={true}
      isBackVisible={true}
    />
  );
};

export default PageHeader;
