import EnterpriseAdminDashboardRequests from '../../../requests/components/EnterpriseAdminDashboardRequests/EnterpriseAdminDashboardRequests';
import {
  IndividualLeaderboardTable,
  TeamsLeaderboardTable,
  TopEarnedBadgesTable,
} from '../../../shared/components/Reports/Tables';
import Calendar from '../Calendar';
import {
  CoursePerformanceViz,
  AverageWeeklyHoursViz,
  HoursLoggedInViz,
} from '../../../shared/components/charts';
import { RecommendedCourses, UserGreeting } from '../common';
import { AssignedCourses } from '../Courses';
import { useStrapiReportsData } from '../../../shared/hooks/useStrapiReportData';
import { dashboardOptions } from '../../types/dashboard';
import { USHG_HQ_APP_ROLE } from '../../../../constants';

const EnterpriseAdminDashboard = ({ lazyLoad = true }: dashboardOptions) => {
  // Pull data from strapi (Add for admin in strapi)
  const {
    OrganizationCoursePerformance,
    OrganizationHoursLogged,
    AverageWeeklyHours,
    ReportTooltipContent,
  } = useStrapiReportsData();

  return (
    <main
      id="main-content"
      className="relative mb-4 grid grow auto-rows-min grid-cols-8 gap-x-3 gap-y-2 sm:gap-y-3 lg:gap-x-[13px] lg:gap-y-4 xl:gap-y-5"
    >
      <UserGreeting className="col-span-8 col-start-1 row-start-1 lg:col-span-6" />
      {/* Organization Hours Logged - Line Chart */}
      <HoursLoggedInViz
        key1={OrganizationHoursLogged.key1}
        key2={OrganizationHoursLogged.key2}
        xAxisLabel={OrganizationHoursLogged.xAxisLabel}
        yAxisLabel={OrganizationHoursLogged.yAxisLabel}
        emptyMessage={OrganizationHoursLogged.emptyChart}
        errorMessage={OrganizationHoursLogged.errorChart}
        heading={OrganizationHoursLogged.title}
        emptyChartIconUrl={OrganizationHoursLogged.emptyChartIcon.data.attributes.url}
        className="col-span-8 col-start-1 row-start-2 overflow-hidden lg:col-span-6"
        lazyLoad={lazyLoad}
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN}
      />
      {/* Calendar */}
      <Calendar className="col-span-8 col-start-1 row-start-3 md:col-span-4 md:row-start-4 lg:col-span-2 lg:col-start-7 lg:row-span-2 lg:row-start-1" />
      {/* Top Earned Badges */}
      <TopEarnedBadgesTable
        className="col-span-8 col-start-1 row-start-4 overflow-hidden md:col-span-4 md:col-start-5 md:row-start-4 lg:col-span-2 lg:col-start-7"
        lazyLoad={lazyLoad}
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN}
      />
      {/* Individual Leaderboard */}
      <IndividualLeaderboardTable
        className="col-span-8 col-start-1 row-start-5 overflow-hidden md:col-span-4 md:row-start-3"
        lazyLoad={lazyLoad}
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN}
      />
      {/* Teams Leaderboard */}
      <TeamsLeaderboardTable
        className="col-span-8 col-start-1 row-start-6 overflow-hidden md:col-span-4 md:col-start-5 md:row-start-3"
        lazyLoad={lazyLoad}
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN}
      />

      {/* Org. Course Performance */}
      <CoursePerformanceViz
        key1={OrganizationCoursePerformance.key1}
        key2={OrganizationCoursePerformance.key2}
        key3={OrganizationCoursePerformance.key3}
        emptyMessage={OrganizationCoursePerformance.emptyChart}
        emptyChartIconUrl={OrganizationCoursePerformance.emptyChartIcon.data.attributes.url}
        errorMessage={OrganizationCoursePerformance.errorMessage}
        heading={OrganizationCoursePerformance.title}
        className="col-span-8 col-start-1 row-start-7 overflow-hidden md:col-span-4 md:row-start-5 lg:col-span-3 lg:row-start-4"
        lazyLoad={lazyLoad}
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN}
      />
      {/* Avg Weekly Hours */}
      <AverageWeeklyHoursViz
        heading={AverageWeeklyHours.title}
        errorChartmessage={OrganizationCoursePerformance.emptyChart}
        className="col-span-8 col-start-1 row-start-[8] overflow-hidden md:col-span-4 md:row-start-5 lg:col-span-3 lg:col-start-4 lg:row-start-4"
        lazyLoad={lazyLoad}
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN}
      />
      {/* Enterprise Admin Requests */}
      <EnterpriseAdminDashboardRequests
        className="col-span-8 col-start-1 row-start-[9] md:row-start-6 lg:row-start-5"
        lazyLoad={lazyLoad}
      />
      {/* In progress courses */}
      <AssignedCourses
        className="col-span-8 row-start-[10] overflow-hidden md:row-start-7 lg:row-start-6"
        lazyLoad={lazyLoad}
      />
      <RecommendedCourses
        lazyLoad={lazyLoad}
        className="col-span-8 row-start-[11] overflow-hidden md:row-start-[8] lg:row-start-7"
      />
    </main>
  );
};

export default EnterpriseAdminDashboard;
