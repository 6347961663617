import { ArcElement, Chart as ChartJS, ChartData, Legend, Tooltip } from 'chart.js';
import clsx from 'clsx';
import { Doughnut } from 'react-chartjs-2';
import { DoughnutOptions } from '../../../../dashboard/types';
import { defaultFontOptions } from '../../../config';
import colors from 'tailwindcss/colors';

// Register components used with chartjs
ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  className?: string;
  options?: DoughnutOptions;
  data: ChartData<'doughnut', number[], unknown>;
}

const chartOptions: DoughnutOptions = {
  responsive: true,
  maintainAspectRatio: false,
  datasets: {
    doughnut: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore this is in the chartjs config but it is throwing a type error
      cutout: '50%',
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'right',
      labels: {
        boxWidth: 19,
        color: colors.zinc['200'],
        font: {
          ...defaultFontOptions,
        },
      },
    },
  },
  layout: {
    padding: {
      right: 20,
    },
  },
};

const DoughnutChart = ({ className, options, data }: Props) => {
  return (
    <div className={clsx('', className)}>
      <Doughnut options={options ?? chartOptions} data={data} />
    </div>
  );
};

export default DoughnutChart;
