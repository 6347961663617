import ComponentHeading from '../ComponentHeading';
import { CoursesCardDisplay } from '../../../../../components/Wrapper';
import clsx from 'clsx';
import { ErrorDisplay } from '../../../../../components/DataTables';
import { ERROR_TYPES } from '../../../../../constants/error-types.constants';
import useRecommendedCoursesQuery from '../../../hooks/useRecommendedCoursesQuery';
import { InView } from 'react-intersection-observer';
import {
  RECOMMENDED_COURSE_LIMIT_DEFAULT,
  RECOMMENDED_COURSE_OFFSET_DEFAULT,
} from '../../../constants';
import { REPORTS_STALE_TIME } from '../../../../shared/constants';
import NoDataFallback from '../../../../shared/components/NoDataFallback';
import { useStrapiDashboardData } from '../../../../shared/hooks';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';
import { Link } from 'react-router-dom';
import ScaleLoader from '../../../../../components/Loader/ScaleLoader';
import useRecommendedCoursesFetch from '../../../../courses/hooks/useRecommendedCoursesFetch';
import { USHG_HQ_APP_ROLE } from '../../../../../constants';
import { useAppSelector } from '../../../../../hooks';
import { RootState } from '../../../../../store';
import { RECOMMENDATION_TYPE } from '../../../../courses/constants/course';
import { useState } from 'react';

interface Props {
  className?: string;
  type?: (typeof RECOMMENDATION_TYPE)[keyof typeof RECOMMENDATION_TYPE];
  offset?: number;
  limit?: number;
  lazyLoad?: boolean;
}
const authStateSelector = (state: RootState) => state.auth;
const RecommendedCourses = ({
  className,
  type = RECOMMENDATION_TYPE.PERSONAL,
  offset = RECOMMENDED_COURSE_OFFSET_DEFAULT,
  limit = RECOMMENDED_COURSE_LIMIT_DEFAULT,
  lazyLoad = true,
}: Props) => {
  const { user } = useAppSelector(authStateSelector);

  const hasOrganizationRecommendation =
    user &&
    (user.role === USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN ||
      user.role === USHG_HQ_APP_ROLE.ENTERPRISE_LEADER ||
      user.role === USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER ||
      user.role === USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER);

  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);
  const { title, emptyMessage, errorMessage } = useStrapiDashboardData().recommendedCourses;

  const recommendedCoursesQuery = useRecommendedCoursesQuery({
    type,
    limit,
    offset,
    isEnabled: isInView,
    staleTime: REPORTS_STALE_TIME,
  });

  const { data, isLoading, error, refetch, isRefetching } = recommendedCoursesQuery;

  const organizationRecommendationFetch = useRecommendedCoursesFetch({
    type: RECOMMENDATION_TYPE.ORGANIZATION,
    filters: [],
    enabled: !!hasOrganizationRecommendation,
  });

  const RecommendationData =
    data?.results && data.count != 0
      ? data
      : organizationRecommendationFetch && organizationRecommendationFetch.totalCount !== 0
      ? organizationRecommendationFetch.data
      : null;
  const finalEmptyMessage = emptyMessage.split('{{clickhere}}');
  const strapidata = useStrapiDataHelper();
  const { clickHereLabel } = strapidata.errormessage.data.attributes;
  return (
    <>
      {RecommendationData ? (
        <InView
          as="div"
          className={clsx(
            'flex h-full min-h-[320px] w-full flex-col rounded-lg bg-card-bg p-4',
            className
          )}
          onChange={(inView) => {
            // default inView local state would be false,
            // set it to true when it enters into the viewport and it stays the same
            if (inView) {
              setIsInView(inView);
            }
          }}
          // this will disabled the intersection observer once we are in view
          // since we only need it until once the card comes into view
          skip={isInView}
        >
          <div className="flex grow flex-col gap-4">
            <ComponentHeading heading={title} />
            {error ? (
              <div className="flex grow items-center justify-center">
                <ErrorDisplay
                  refetch={refetch}
                  isRefetching={isRefetching}
                  allowsRefetch={true}
                  message={errorMessage}
                  type={ERROR_TYPES.SOMETHING_WENT_WRONG}
                />
              </div>
            ) : !isLoading && RecommendationData ? (
              RecommendationData.count !== 0 ? (
                <CoursesCardDisplay coursesList={RecommendationData.results} />
              ) : (
                <NoDataFallback
                  className="text-base"
                  message={
                    <>
                      {finalEmptyMessage[0]}
                      <Link to="/courses?view=all" className="underline underline-offset-1">
                        {clickHereLabel}
                      </Link>
                      {finalEmptyMessage[1]}
                    </>
                  }
                />
              )
            ) : (
              <div className="flex grow items-center justify-center">
                {isLoading && <ScaleLoader />}
              </div>
            )}
          </div>
        </InView>
      ) : null}
    </>
  );
};

export default RecommendedCourses;
