import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../hooks';
import AssignedCoursesView from '../../../courses/components/CoursesView/AssignedCoursesView';
import AssignedCoursesHeader from '../../../courses/components/CoursesView/components/CoursesHeader/AssignedCoursesHeader';
import widgetData from '../../../courses/data/WidgetData';
import useFetchCoursesByIdQuery from '../../../courses/hooks/useFetchCoursesByIdQuery';
import {
  setCategoryName,
  setFormat,
  setSpeakers,
  setTags,
} from '../../../courses/slices/courses.slice';
import { filterType, Filter } from '../../../courses/types/Filter.Helper';
import { UsersStatsFilter } from '../../types/interface';

type SinglePersonAssignedCourseProps = {
  className?: string;
  username?: Partial<UsersStatsFilter>;
};

const SinglePersonAssignedCourse = ({ className, username }: SinglePersonAssignedCourseProps) => {
  const [appliedFilters, setAppliedFilters] = useState<filterType[]>([]);
  const dispatch = useAppDispatch();
  const assignedFetch = useFetchCoursesByIdQuery({
    tab: widgetData[2].value,
    filters: appliedFilters,
    id: username,
  });
  useEffect(() => {
    if (assignedFetch.data?.pages[0]?.facets) {
      dispatch(setCategoryName(assignedFetch.data?.pages[0]?.facets.category_name));
      dispatch(setFormat(assignedFetch.data?.pages[0]?.facets.format));
      dispatch(setSpeakers(assignedFetch.data?.pages[0]?.facets.speakers));
      dispatch(setTags(assignedFetch.data?.pages[0]?.facets.tags));
    }
  }, [assignedFetch]);

  return (
    <>
      <div className={clsx('flex h-full flex-col gap-2 rounded-lg bg-zinc-800 p-5', className)}>
        <AssignedCoursesHeader
          appliedFilters={appliedFilters}
          className={'bg-zinc-800'}
          setAppliedFilters={setAppliedFilters}
          showFilteredByWidgets={
            appliedFilters.length > 1 ||
            (appliedFilters[0] && appliedFilters[0].type !== Filter.SEARCH)
          }
        />
        <AssignedCoursesView className="px-0" tab={assignedFetch} currentTabState={'assigned'} />
      </div>
    </>
  );
};

export default SinglePersonAssignedCourse;
