import { API } from 'aws-amplify';
import { checkTeamExists, checkUserExists } from '../api/tenant';
import {
  AMPLIFY_USHG_API_ENDPOINT_NAME,
  CHECKOUT_SESSION_API_PATH,
  REGISTER_API_PATH,
} from '../../../constants';
import { CheckoutSession, getOrganizationInviteRequest, Registration } from '../types';
import { AUTH_API_PATHS } from '../constants';
import qs from 'qs';

/**
 * Function to sign up enterprise users or individual users i.e. customers
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {SignUpUserParams} SignUpUserParams - sign in parameters
 * @return {response} response object
 */
const signUpUser = async (params: Registration) => {
  try {
    const {
      basicDetails,
      companyDetails,
      freeTrail,
      subscriptionType,
      sessionId,
      isFederatedLoginUser,
    } = params;
    const apiName = AMPLIFY_USHG_API_ENDPOINT_NAME;
    const path = REGISTER_API_PATH;
    const payload = {
      body: {
        basicDetails,
        companyDetails: {
          ...companyDetails,
        },
        sessionId,
        freeTrail,
        subscriptionType,
        isFederatedLoginUser,
      },
    };
    const response = await API.post(apiName, path, payload);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const checkoutSession = async (params: CheckoutSession) => {
  try {
    const apiName = AMPLIFY_USHG_API_ENDPOINT_NAME;
    const path = CHECKOUT_SESSION_API_PATH;
    const payload = {
      body: params,
    };
    const response = await API.post(apiName, path, payload);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const checkUserExist = async (email: string) => {
  try {
    const checkUserExistsResponse = await checkUserExists(email);
    return checkUserExistsResponse;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const checkTeamExist = async (teamName: string, currentTeamId?: number) => {
  try {
    return await checkTeamExists(teamName, currentTeamId);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

/**
 * Get Organization Invite
 */

const getOrganizationInvite = async (args: getOrganizationInviteRequest) => {
  const path = AUTH_API_PATHS.ORGANIZATION.GET_ORGANIZATION_INVITE;
  const queryString = qs.stringify(args);
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${queryString}`, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};
export { signUpUser, checkoutSession, checkUserExist, checkTeamExist, getOrganizationInvite };
