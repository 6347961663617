import { useQuery } from '@tanstack/react-query';
import { getAllSpeakers } from '../api/speaker';
import { GET_SPEAKER_QUERY_KEY, STALE_TIME } from '../constants/common';
import { GetSpeakerFilterQuery } from '../types/GetSpeakerFilterQuery';

interface Props {
  filters: GetSpeakerFilterQuery;
}

const useGetSpeakerListQuery = ({ filters }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching } =
    useQuery(
      [GET_SPEAKER_QUERY_KEY, filters],
      async () => {
        return getAllSpeakers(filters);
      },
      {
        keepPreviousData: true,
        staleTime: STALE_TIME,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching };
};

export default useGetSpeakerListQuery;
