import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { inviteEnterpise } from '../api';
import { InviteEnterprise } from '../types';
import { useStrapiOnBoardingData } from './useStrapiOnBoardingData';

const useInviteMembersMutation = () => {
  const { toastMessages } = useStrapiOnBoardingData();
  const {
    teamAddedSuccessfullyTitle,
    teamAddedSuccessfullyMessage,
    teamAddedFailureTitle,
    teamAddedFailureMessage,
  } = toastMessages;
  const mutation = useMutation({
    mutationFn: async (args: InviteEnterprise) => {
      const response = await inviteEnterpise(args);
      return response;
    },
    onSuccess: () => {
      toast.custom((t) => (
        <Toast
          variant="success"
          Title={teamAddedSuccessfullyTitle}
          SubTitle={teamAddedSuccessfullyMessage}
          toastInstance={t}
        />
      ));
    },
    onError: (error) => {
      console.error('Error while inviting member %o', error);
      toast.custom((t) => (
        <Toast
          variant="error"
          Title={teamAddedFailureTitle}
          SubTitle={teamAddedFailureMessage}
          toastInstance={t}
        />
      ));
    },
  });

  return mutation;
};

export default useInviteMembersMutation;
