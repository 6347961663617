import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
import { NotificationPreferencesFragment } from '../types';

const useNotificationPreferencesStrapiData = () => {
  const { notifPreference } = useStrapiDataHelper() as {
    notifPreference: NotificationPreferencesFragment;
  };

  const { heading, description, allOffLabel, allOnLabel, prefs, webLabel, emailLabel, saveButton } =
    notifPreference.data.attributes;

  return {
    webLabel,
    emailLabel,
    heading,
    saveButton,
    allOnLabel,
    description,
    allOffLabel,
    preferences: prefs,
  };
};

export default useNotificationPreferencesStrapiData;
