interface Props {
  badgesCount: number;
}

const LeaderboardBadgesCountCell = ({ badgesCount }: Props) => {
  return (
    <div className="flex space-x-1.5">
      <span className="whitespace-nowrap">{badgesCount}</span>
    </div>
  );
};

export default LeaderboardBadgesCountCell;
