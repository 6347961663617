import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { SETTING_API_PATHS } from '../constants';
import type { GetMaintenanceMode } from '../types';

/**
 * Get maintenance mode
 */
const getMaintenanceMode = async (): Promise<GetMaintenanceMode> => {
  const path = SETTING_API_PATHS.MAINTENANCE_MODE;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
  });
  return response.data;
};

export type UpdateMaintenanceModeParams = {
  action: 'Enable' | 'Disable';
};

/**
 * Update maintenance mode
 */
const updateMaintenanceMode = async (params: UpdateMaintenanceModeParams) => {
  const { action } = params;

  const path = SETTING_API_PATHS.MAINTENANCE_MODE;
  const requestBody = {
    maintenanceMode: action,
  };

  const response = await API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body: requestBody,
    withCredentials: true,
    response: true,
  });

  return response.data;
};

export { getMaintenanceMode, updateMaintenanceMode };
