import { parseISO, format } from 'date-fns';
import { useMemo } from 'react';
import { getLocale } from '../../../../../../utils';
import { useLanguage } from '../../../../../shared/context/languageContext';

interface Props {
  timestamp: string;
}

const TimeStampCell = ({ timestamp }: Props) => {
  const displayDate = useMemo(() => {
    const parsedISOTimestamp = parseISO(timestamp);
    const { language } = useLanguage();
    return format(parsedISOTimestamp, 'EEE dd MMM, yyyy p', { locale: getLocale(language) });
  }, [timestamp]);
  return <span>{timestamp ? displayDate : '-'}</span>;
};

export default TimeStampCell;
