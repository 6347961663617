const PROFILE_API_PATHS = {
  USER_PROFILE: '/users/profile',
  USER_CONFIRM_ONBOARDING: '/users/onboard/success',
};

const REPORTS_BASE_PATH = '/reports';
const USERS_API_BASE_PATH = '/users';

const REPORTS_API_PATHS = {
  ENTERPRISE: {
    GET_ENTERPRISE_INDIVIDUAL_LEADERBOARD: `${REPORTS_BASE_PATH}/v1/individual-leaderboard`,
    GET_ENTERPRISE_TEAM_LEADERBOARD: `${REPORTS_BASE_PATH}/v1/team-leaderboard`,
    GET_ENTERPRISE_TEAMS_LEADERBOARD: `${REPORTS_BASE_PATH}/v1/teams-leaderboard`,
    GET_TOP_EARNED_BADGES: `${REPORTS_BASE_PATH}/v1/top-earned-badges`,
    GET_ENTERPRISE_AVERAGE_WEEKLY_HOURS: `${REPORTS_BASE_PATH}/v1/average-weekly-hours`,
    GET_ENTERPRISE_COURSE_PERFORMANCE: `${REPORTS_BASE_PATH}/v1/course-performance`,
  },
  ACTIVITY_REPORT: {
    GET_HOURS_LOGGED: `${REPORTS_BASE_PATH}/v1/hours-logged`,
    HOURS_SPENT_FREQUENCY: `${REPORTS_BASE_PATH}/v1/hours-spent-frequency`,
  },
  PERFORMANCE_REPORT: {
    COURSE_PERFORMANCE_STATISTICS: `${REPORTS_BASE_PATH}/v1/course-performance-statistic`,
    COURSE_COMPLETED_ASSIGNED_STATISTICS: `${REPORTS_BASE_PATH}/v1/courses-assigned-completed`,
    COURSE_PERFORMANCE_RATES: `${REPORTS_BASE_PATH}/v1/course-completion-rate`,
    SCORES_ON_ASSESSMENTS: `${REPORTS_BASE_PATH}/v1/assessment-on-scores`,
    MOST_POPULAR_COURSES: `${REPORTS_BASE_PATH}/v1/most-popular-courses`,
    ENGAGEMENT_BY_CATEGORIES: `${REPORTS_BASE_PATH}/v1/category-engagement`,
  },
};

const USERS_API_PATHS = {
  GET_ANNOUNCEMENT: `${USERS_API_BASE_PATH}/v1/announcement`,
};

const ORG_STALE_TIME = 2000;
const STRAPI_STALE_TIME = 3000;
const INVITE_FRIEND_HQ = '/users/invite-friendToHQ';

export {
  PROFILE_API_PATHS,
  REPORTS_API_PATHS,
  INVITE_FRIEND_HQ,
  ORG_STALE_TIME,
  STRAPI_STALE_TIME,
  USERS_API_PATHS,
};
