import { useQuery } from '@tanstack/react-query';
import { getTeams } from '../api/team';
import { showToast } from '../../shared/helpers/toast';
import useStrapiMembersData from './useStrapiMembersData';

type props = {
  includeSubTeams: boolean;
  enabled?: boolean;
};
const useGetTeamsQuery = ({ includeSubTeams, enabled }: props) => {
  const { userTeamChange } = useStrapiMembersData();
  const { data, isLoading, refetch, isError, isRefetching } = useQuery({
    queryKey: ['get-all-teams', includeSubTeams],
    queryFn: () => getTeams(includeSubTeams),
    select: (response) =>
      response.data.map((team: any) => ({ label: team.teamName, value: team.teamId })),
    enabled,
    onError: () =>
      showToast({
        variant: 'error',
        title: userTeamChange.teamsFetchErrorHead,
        body: userTeamChange.teamsFetchErrorMessage,
      }),
  });
  return { data, isLoading, refetch, isError, isRefetching };
};

export default useGetTeamsQuery;
