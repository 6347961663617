import { useQuery } from '@tanstack/react-query';
import { getUpNextBadges } from '../api/badges';
import { STALE_TIME } from '../constants/badges';

type props = {
  offset: number;
  limit: number;
  language?: string;
  username?: string;
};

const useGetUpNextBadgesQuery = (filters?: props) => {
  return useQuery({
    queryKey: [
      {
        scope: 'badges',
        item: 'up-next-badges',
        filters: filters,
      },
    ],
    queryFn: () => {
      return getUpNextBadges(filters);
    },
    keepPreviousData: true,
    staleTime: STALE_TIME,
  });
};

export default useGetUpNextBadgesQuery;
