import { ArrowRight, Check, X } from 'phosphor-react';
import { Button } from '../../../../../../components/Buttons';
import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';
import { SubmitAssessmentResponse } from '../../types/SingleCourseTypes';
import { formatNumber } from '../../../../../../utils';

type Props = {
  setModalOpen: (open: boolean) => void;
  assessmentResult: SubmitAssessmentResponse;
  nextModuleHandler: () => void;
};

const AssessmentResult = ({ setModalOpen, assessmentResult, nextModuleHandler }: Props) => {
  const { passed, score, answerDetails } = assessmentResult;
  // strapi content
  const { assessmentCompletion } = useStrapiCoursesData();
  const {
    assessmentSuccessTitle,
    assessmentSuccessDescription,
    assessmentFailureTitle,
    assessmentFailureDescription,
    yourScoreLabel,
    correctLabel,
    incorrectLabel,
    closeButton,
    nextButton,
  } = assessmentCompletion;
  return (
    <div className="flex flex-col p-6 text-white">
      <div
        className={`flex  flex-col items-center rounded-lg  border  p-8 ${
          passed ? 'border-[#34D3991A] bg-[#064E3B1A]' : ' border-red-600 bg-red-200/10'
        }`}
      >
        <div
          className={`mb-5 flex aspect-square h-max w-max flex-col items-center justify-center rounded-full ${
            passed ? 'bg-emerald-600' : 'bg-red-600'
          } p-2 `}
        >
          {passed && <Check size={24} color="#ffffff" />}
          {!passed && <X size={24} color="#ffffff" />}
        </div>
        <div className="mb-2 text-lg font-bold">
          {' '}
          {passed ? assessmentSuccessTitle : assessmentFailureTitle}
        </div>
        <div className={`text-sm ${passed ? 'text-emerald-200' : 'text-red-200'} `}>
          {passed ? assessmentSuccessDescription : assessmentFailureDescription}
        </div>
      </div>
      <div className="my-6 flex flex-col rounded-lg border border-zinc-700 px-4 py-5">
        <span className="text-sm font-medium">{yourScoreLabel}</span>
        <span className="mt-2 text-4xl font-bold"> {formatNumber(score)}%</span>
      </div>
      {answerDetails.map((item, index) => {
        return (
          <div
            key={`answerResult${index}`}
            className="mb-2 flex flex-row items-center rounded-lg border border-zinc-700 bg-zinc-900 py-3 px-4"
          >
            <div className="w-max text-sm font-normal">{index + 1}.</div>
            <div className="mx-2 w-[100%] text-sm font-medium">{item.question}</div>
            <div
              className={`w-max text-xs font-medium ${
                item.isCorrect ? 'text-emerald-500' : 'text-red-500'
              }`}
            >
              {item.isCorrect ? correctLabel : incorrectLabel}
            </div>
          </div>
        );
      })}

      <div className="mt-6 flex flex-row-reverse text-sm font-medium text-white ">
        {passed && (
          <Button
            className="flex flex-row items-center bg-base-brand px-7 py-2"
            onClick={nextModuleHandler}
            contentWrapperClassName="gap-x-2 items-center"
          >
            <span>{nextButton}</span>
            <ArrowRight size={16} className="shrink-0" />
          </Button>
        )}
        <Button className="mx-2" onClick={() => setModalOpen(false)}>
          {closeButton}
        </Button>
      </div>
    </div>
  );
};

export default AssessmentResult;
