import { CheckboxInput, Label, TextInput } from '../../../../../../components/FormElements';
import React, { useEffect, useState } from 'react';
import BadgeRemovable from '../../../../../../components/Badge/BadgeRemovable';
import { Filter, filterType } from '../../../../types/Filter.Helper';
import Dialog from '../../../../../../components/Dialog';
import useFetchDropUserTeamQuery, {
  nextPageParam,
} from '../../../../hooks/useFetchDropUserTeamQuery';
import AsyncSelect from 'react-select/async';
import { Button } from '../../../../../../components/Buttons';
import { MAX_USER_ASSIGN } from '../../../../constants/course';
import { BasicSpinner } from '../../../../../../components/Spinners';
import useAssignCourseMemberMutation from '../../../../hooks/useAssignCourseMemberMutation';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../../../../components/Toast';
import useAssignCourseTeamMutation from '../../../../hooks/useAssignCourseTeamMutation';
import { useQueryClient } from '@tanstack/react-query';
import { getCourseModulesQueryKey } from '../SingleModuleComponent/constants/queryKeyConstants';
import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';
import { useStrapiHQAdminCoursesData } from '../../../../../course-management/hooks/useStrapiHQAdminCourseData';
import { components } from 'react-select';
import { customStylesOne } from '../../../../../../styles/modalCustomStyle';
import { useStrapiSharedData } from '../../../../../shared/hooks/useStrapiSharedData';

type AssignCourseFormProps = {
  courseId: number;
  refetchDetails?: () => void;
  modalState: any;
};

const CustomOption = (props: any) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <p className="text-xs font-normal leading-5 text-zinc-200">
        {data.label.length === 0 ? '-' : data.label}
      </p>
      <p className="text-[11px] font-normal leading-5 text-zinc-500">{data.value}</p>
    </components.Option>
  );
};

const AssignCourseForm = ({ courseId, modalState, refetchDetails }: AssignCourseFormProps) => {
  // state to maintain list of teams and users fetched
  const [teams, setTeams] = useState<{ label: string; value: string }[]>();
  const [users, setUsers] = useState<{ label: string; value: string }[]>();
  const [error, setError] = useState<string | false>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  // strapi content
  const { assignCourseModal } = useStrapiCoursesData();
  const {
    teamLabel,
    teamPlaceHolder,
    memberLabel,
    memberPlaceHolder,
    dueDateLabel,
    dueDatePlaceHolder,
    fixDueDateMessage,
    cancelButton,
    assignCourseButton,
    orLabel,
  } = assignCourseModal;
  // state to maintain list of selected teams and users
  const [selectedTeam, setSelectedTeam] = useState<{ label: string; value: string } | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<filterType[]>([]);

  // date helper states
  const [dueDate, setDueDate] = useState();
  const [minimumDate, setMinimumDate] = useState<string>();

  const [isRelativeDue, setIsRelativeDue] = useState<boolean>(true);

  // function to set the due date
  const onDateSelected = (e: any) => {
    setDueDate(e.target.value);
  };

  // Fetch team records for the dropdown
  const {
    isLoading: teamLoading,
    fetchNextPage: teamFetchNext,
    isFetchingNextPage: teamIsFetchNext,
    hasNextPage: teamHasNext,
  } = useFetchDropUserTeamQuery({
    params: {
      key: 'teams',
      courseId: courseId,
      status: 'NOT_ASSIGNED',
    },
  });

  // Fetch user records for the dropdown
  const {
    isLoading: userLoading,
    fetchNextPage: userFetchNext,
    isFetchingNextPage: userIsFetchNext,
    hasNextPage: userHasNext,
  } = useFetchDropUserTeamQuery({
    params: {
      key: 'users',
      courseId: courseId,
      status: 'NOT_ASSIGNED',
    },
  });

  // Search from records
  const searchWithStartsWith = (inputValue: string, records: { label: string; value: string }[]) =>
    records.filter((i: any) => {
      return (
        i.label.toLowerCase().startsWith(inputValue.toLowerCase()) ||
        i.value.toLowerCase().startsWith(inputValue.toLowerCase())
      );
    });

  // Set Initial Values for the Dropdown
  useEffect(() => {
    getUserRecords().then((data) => setUsers(data.mapped));
    getTeamRecords().then((data) => setTeams(data.mapped));
  }, []);

  // Fetch Next Page and Parse the User Data
  const getUserRecords = async () => {
    const userRecords: any = await userFetchNext();
    let mapped: { label: string; value: string }[] = [];
    const lastPage =
      userRecords.data.pages.length !== 0
        ? userRecords.data.pages[userRecords.data.pages.length - 1]
        : undefined;
    userRecords.data.pages.forEach((page: any) => {
      const mappedData = page.results.map((data: any) => ({
        label: data.fullName,
        value: data.username,
      }));
      mapped = [...mapped, ...mappedData];
    });
    setUsers(mapped);
    return { mapped, lastPage };
  };

  // Fetch Next Page and Parse the Team Data
  const getTeamRecords = async () => {
    const teamRecords: any = await teamFetchNext();
    let mapped: { label: string; value: string }[] = [];
    const lastPage =
      teamRecords.data.pages.length !== 0
        ? teamRecords.data.pages[teamRecords.data.pages.length - 1]
        : undefined;
    teamRecords.data.pages.forEach((page: any) => {
      const mappedData = page.results.map((data: any) => ({
        label: data.name,
        value: data.id,
      }));
      mapped = [...mapped, ...mappedData];
    });
    setTeams(mapped);
    return { mapped, lastPage };
  };
  const {
    toastMessages: {
      courseAssignedSuccessTitle,
      courseAssignedFailureTitle,
      courseUserAssignedSuccessMessage,
      courseTeamAssignedSuccessMessage,
      courseUserAssignedFailedMessage,
      courseTeamAssignedFailedMessage,
    },
  } = useStrapiHQAdminCoursesData();
  const {
    dropdownPlaceholderMessages: { loadingMessage, noOptionsMessage },
  } = useStrapiSharedData();
  // Handler to set minimum date
  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const day = currentDate
      .getDate()
      .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    setMinimumDate(`${year}-${month}-${day}`);
  }, []);

  const removeUser = (user: filterType) => {
    setError(false);
    setSelectedUsers((prev) => prev.filter((u) => u.value !== user.value));
  };

  // Dropdown Value Finder Team
  const findTeam = async (inputValue: string) => {
    let record: any = [];
    if (teams) {
      record = searchWithStartsWith(inputValue, teams);
      if (record.length === 0 && teamHasNext && !teamIsFetchNext) {
        const { mapped, lastPage } = await getTeamRecords();
        record = searchWithStartsWith(inputValue, mapped);
        if (record.length === 0 && nextPageParam(lastPage)) {
          record = await findTeam(inputValue);
        }
      }
    }
    return record;
  };

  // Dropdown Value Finder User
  const findUser = async (inputValue: string) => {
    let record: any = [];
    if (users) {
      record = searchWithStartsWith(inputValue, users);
      if (record.length === 0 && userHasNext && !userIsFetchNext) {
        const { mapped, lastPage } = await getUserRecords();
        record = searchWithStartsWith(inputValue, mapped);
        if (record.length === 0 && nextPageParam(lastPage)) {
          record = await findUser(inputValue);
        }
      }
    }
    return record;
  };

  const addUserHandler = (data: any) => {
    const find = selectedUsers.find((v) => v.value === data.value);
    if (!find) {
      setSelectedUsers((prev) => {
        const check = [
          ...prev,
          {
            display: data.label.length === 0 ? data.value : data.label,
            value: data.value,
            type: Filter.MEMBER,
          },
        ];
        if (check.length > MAX_USER_ASSIGN) {
          setError(`You can assign course to maximum of ${MAX_USER_ASSIGN} users at a time`);
          return prev;
        }
        return check;
      });
    }
  };

  // Load Options
  const userLoadOptions = (inputValue: string) => findUser(inputValue);
  const teamLoadOptions = (inputValue: string) => findTeam(inputValue);

  const {
    isLoading: userAssignLoading,
    mutate: userAssignMutate,
    isSuccess: userAssignSuccess,
  } = useAssignCourseMemberMutation();

  const {
    isLoading: teamAssignLoading,
    mutate: teamAssignMutate,
    isSuccess: teamAssignSuccess,
  } = useAssignCourseTeamMutation();

  const queryClient = useQueryClient();
  useEffect(() => {
    if (
      isSubmitted &&
      (selectedUsers.length !== 0 ? userAssignSuccess : true) &&
      (selectedTeam ? teamAssignSuccess : true)
    ) {
      queryClient.invalidateQueries({
        queryKey: getCourseModulesQueryKey(`${courseId}`),
      });
      queryClient.removeQueries({
        queryKey: ['courses'],
      });
      queryClient.removeQueries({
        queryKey: ['teams', courseId, 'NOT_ASSIGNED'],
      });
      queryClient.removeQueries({
        queryKey: ['users', courseId, 'NOT_ASSIGNED'],
      });
      if (refetchDetails) refetchDetails();
      modalState(false);
    }
  }, [userAssignSuccess, teamAssignSuccess]);

  const onAssignHandler = () => {
    const users = selectedUsers.map((user) => user.value);
    setIsSubmitted(true);
    if (users.length !== 0) {
      userAssignMutate(
        {
          courseId,
          username: users,
          dueDate: dueDate ? `${new Date(dueDate).getTime()}` : undefined,
        },
        {
          onSuccess: () => {
            toast.custom((t) => {
              return (
                <Toast
                  variant="success"
                  Title={courseAssignedSuccessTitle}
                  SubTitle={courseUserAssignedSuccessMessage}
                  toastInstance={t}
                />
              );
            });
          },
          onError: (error) => {
            console.error('Error while creating course %o', error);
            setIsSubmitted(false);
            toast.custom((t) => (
              <Toast
                variant="error"
                Title={courseAssignedFailureTitle}
                SubTitle={courseUserAssignedFailedMessage}
                toastInstance={t}
              />
            ));
          },
        }
      );
    }
    if (selectedTeam) {
      teamAssignMutate(
        {
          courseId,
          teamId: selectedTeam.value,
          dueDate: dueDate ? `${new Date(dueDate).getTime()}` : undefined,
          isRelative: isRelativeDue,
        },
        {
          onSuccess: () => {
            toast.custom((t) => {
              return (
                <Toast
                  variant="success"
                  Title={courseAssignedSuccessTitle}
                  SubTitle={courseTeamAssignedSuccessMessage}
                  toastInstance={t}
                />
              );
            });
          },
          onError: (error) => {
            console.error('Error while creating course %o', error);
            setIsSubmitted(false);
            toast.custom((t) => (
              <Toast
                variant="error"
                Title={courseAssignedFailureTitle}
                SubTitle={courseTeamAssignedFailedMessage}
                toastInstance={t}
              />
            ));
          },
        }
      );
    }
  };

  return (
    <>
      <div className="flex flex-col gap-2 rounded-lg bg-zinc-900 p-4">
        <div className="flex flex-col gap-2">
          <Label htmlFor="selected_teams" label={teamLabel} />
          <AsyncSelect
            id="selected_teams"
            loadingMessage={() => loadingMessage}
            noOptionsMessage={() => noOptionsMessage}
            loadOptions={teamLoadOptions}
            defaultOptions={teams}
            isLoading={teamLoading || teamIsFetchNext}
            options={teams}
            styles={customStylesOne}
            placeholder={teamPlaceHolder}
            onChange={(data: any) => setSelectedTeam(data)}
            maxMenuHeight={215}
            backspaceRemovesValue={true}
            isClearable={true}
          />
        </div>
        <div className="flex w-full flex-row items-center justify-center justify-items-center">
          <div className="basis-5/12 border-t border-zinc-800" />
          <span className="basis-1/12 text-center text-sm font-medium text-zinc-400">
            {orLabel}
          </span>
          <div className="basis-5/12 border-t border-zinc-800" />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="selected_members" label={memberLabel} />
          <AsyncSelect
            id="selected_users"
            loadingMessage={() => loadingMessage}
            noOptionsMessage={() => noOptionsMessage}
            loadOptions={userLoadOptions}
            components={{ Option: CustomOption }}
            defaultOptions={users}
            isLoading={userLoading || userIsFetchNext}
            options={users}
            styles={customStylesOne}
            placeholder={memberPlaceHolder}
            onChange={addUserHandler}
            value={null}
            maxMenuHeight={150}
          />
        </div>
        <div className="flex flex-wrap gap-1">
          {selectedUsers &&
            selectedUsers.map((user) => (
              <BadgeRemovable
                key={user.value}
                filterRemoveHandler={removeUser}
                filter={user}
                tooltipOnHover={true}
                tooltipContentClassName="bg-[#141415] border border-zinc-800 rounded-lg mb-1.5 py-1 px-2 text-zinc-200"
              />
            ))}
        </div>
      </div>
      <div>
        <Label htmlFor="end_date" label={dueDateLabel} />
        <TextInput
          type="date"
          min={minimumDate}
          placeholder={dueDatePlaceHolder}
          onInput={onDateSelected}
          className="text-sm"
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      </div>
      <div className="flex gap-1">
        <CheckboxInput
          className="grow-0 disabled:cursor-not-allowed"
          id="relative_due"
          type="checkbox"
          checked={!isRelativeDue}
          onChange={() => setIsRelativeDue(!isRelativeDue)}
          disabled={!dueDate}
        />
        <Label
          htmlFor="relative_due"
          label={fixDueDateMessage}
          className="text-xs font-normal text-zinc-300"
        />
      </div>
      <div className="flex flex-col items-center justify-end xs:flex-row">
        {error && <span className={'grow text-xs font-normal text-red-500'}>{error}</span>}
        <Dialog.Close
          type="button"
          className="rounded bg-transparent py-2.5 px-[30px] text-center text-sm font-medium text-white"
        >
          {cancelButton}
        </Dialog.Close>
        <Button
          disabled={
            (selectedUsers.length === 0 && selectedTeam === null) ||
            userAssignLoading ||
            teamAssignLoading
          }
          className="flex items-center gap-1 rounded bg-base-brand py-2.5 px-[30px] text-center text-sm font-medium text-white disabled:opacity-80"
          onClick={onAssignHandler}
        >
          <span>{assignCourseButton}</span>
          {(userAssignLoading || teamAssignLoading) && (
            <BasicSpinner className="!m-0 h-4 w-4 text-zinc-100" />
          )}
        </Button>
      </div>
    </>
  );
};

export default AssignCourseForm;
