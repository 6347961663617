import React from 'react';
import AssessmentBasicDetails from './AssessmentBasicDetails';
import { AssessmentQuestion } from './AssessmentQuestion';

interface AssessmentMainProps {
  assessmentBasicDetailsQuery: Record<string, any>;
  asssessmentQuestionsQuery: Record<string, any>;
  assessmentId: number;
}
export const AssessmentMain = ({
  assessmentBasicDetailsQuery,
  asssessmentQuestionsQuery,
  assessmentId,
}: AssessmentMainProps) => {
  return (
    <div className="flex w-full flex-col gap-4">
      <AssessmentBasicDetails assessmentBasicDetailsQuery={assessmentBasicDetailsQuery} />
      <AssessmentQuestion
        asssessmentQuestionsQuery={asssessmentQuestionsQuery}
        assessmentId={assessmentId}
      />
    </div>
  );
};
