export const ERROR_CODE = {
  USER_ALREADY_ACTIVE: 'USER_ALREADY_CONFIRMED',
  INVALID_TOKEN: 'INVALID_TOKEN',
};

export const ERROR_MESSAGE = {
  LIMIT_EXCEEDED: 'Attempt limit exceeded, please try after some time.',
};
export const API_RESPONSE_CODES = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
};
