const REPORTS_BASE_PATH = '/reports';
const USERS_BASE_PATH = '/users';
const CALENDAR_BASE_PATH = '/calendar';

const REPORTS_API_PATHS = {
  HQ_ADMIN: {
    PERFORMANCE_REPORT: {
      CUSTOMER_LEADER_BOARD: `${USERS_BASE_PATH}/hq/v1/organizations`,
      TOP_CHURN_RISK: `${REPORTS_BASE_PATH}/hq/v1/top-churn-risk`,
      OVERALL_CHURN_RATE: `${REPORTS_BASE_PATH}/hq/v1/overall-churn-rate`,
    },
  },
};

const COURSES_BASE_PATH = '/courses';

const GET_MY_COURSES = () => {
  return `${COURSES_BASE_PATH}/my-courses`;
};

const CALENDAR_API_PATHS = {
  MY_CALENDAR_ITEMS: `${CALENDAR_BASE_PATH}/items`,
};

export { REPORTS_API_PATHS, GET_MY_COURSES, CALENDAR_API_PATHS };
