import { useState } from 'react';
import Dialog from '../../../../../components/Dialog';
import { BasicSpinner } from '../../../../../components/Spinners';
import { REQUEST_STATUS } from '../../../constants';
import { useStrapiRequestData } from '../../../hooks/useStrapiRequestData';
import useUSHGAdminRequestResolveMutation from '../../../hooks/useUSHGAdminRequestResolveMutation';
import { USHGAdminRequest } from '../../../types';
import { RequestActionDialogItem } from '../../common';
import { RequestStatusCell } from '../components/RequestsTable/TableCells';

interface Props {
  request: USHGAdminRequest;
  setIsDialogOpen: (open: boolean) => void;
}

const RequestActionDialogContent = ({ request, setIsDialogOpen }: Props) => {
  // Completed means resolved

  const isResolved = request.status === REQUEST_STATUS.COMPLETED;
  const [resolveLoader, setResolveLoader] = useState<boolean>(false);
  const requestResolveMutation = useUSHGAdminRequestResolveMutation();

  const onResolveButtonClick = async () => {
    setResolveLoader(true);
    requestResolveMutation.mutate(parseInt(request.id), {
      onSuccess: () => {
        setResolveLoader(false);
        setIsDialogOpen(false);
      },
    });
  };
  const { HQModal } = useStrapiRequestData();
  const {
    raisedByLabel,
    resolveButton,
    raisedOrgLabel,
    requestTitleLabel,
    requestCategoryLabel,
    title,
    requestDescriptionLabel,
    statusLabel,
  } = HQModal;

  return (
    <div className="mx-1 flex flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-2 py-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <Dialog.Title className="text-center text-[18px] font-semibold leading-[24px] text-white xs:text-start">
        {title}
      </Dialog.Title>
      <div className="grid grid-rows-2 gap-6 sm:grid-cols-[3fr,2fr] sm:grid-rows-1 sm:gap-2">
        <RequestActionDialogItem heading={requestCategoryLabel} content={request.category} />
        <RequestActionDialogItem
          heading={statusLabel}
          content={<RequestStatusCell status={request.status} />}
        />
      </div>
      <RequestActionDialogItem heading={requestTitleLabel} content={request.data.title} />
      <RequestActionDialogItem
        heading={requestDescriptionLabel}
        content={request.data.description}
      />
      <div className="w-full border border-zinc-700" />
      <div className="flex flex-col justify-between gap-6 sm:grid sm:grid-cols-[3fr,2fr] sm:grid-rows-1">
        <RequestActionDialogItem heading={raisedByLabel} content={request.createdBy} />
        <RequestActionDialogItem heading={raisedOrgLabel} content={request.tenantName} />
      </div>
      {/* Show the action button only if the request is not yet resolved */}
      {!isResolved && (
        <div className="flex w-full justify-center gap-2 text-sm font-medium text-white xs:justify-end">
          <button
            className="flex w-max flex-row rounded bg-base-brand py-2.5 px-[30px]"
            onClick={onResolveButtonClick}
            disabled={resolveLoader}
          >
            {resolveButton}
            {resolveLoader && <BasicSpinner className="ml-2" />}
          </button>
        </div>
      )}
    </div>
  );
};

export default RequestActionDialogContent;
