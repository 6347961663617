import { isAfter } from 'date-fns';
import { Bell } from 'phosphor-react';
import SideBar from '../../../../../components/SideBar';
import { useAppDispatch, useAppSelector, useReduxAuthState } from '../../../../../hooks';
import { useStrapiSideNavData } from '../../../../../hooks/useStrapiSideNavData';
import {
  useDescribeChannelMembershipForAppInstanceUser,
  useSubscribeToNotifications,
} from '../../../../notifications/hooks';
import {
  receivedNewNotification,
  setChannelLastMarkedAsReadTimestamp,
} from '../../../../notifications/slice';
import type { CustomSideNavItemProps } from '../../../types';

const NotificationsNavItem = ({ children }: React.PropsWithChildren<CustomSideNavItemProps>) => {
  const { routes } = useStrapiSideNavData();

  const appDispatch = useAppDispatch();
  const auth = useReduxAuthState();

  if (!auth.isAuthenticated) {
    throw new Error('User is not authenticated');
  }

  const chimeBearer = auth.user['custom:appinstance_user_arn'];
  const notificationChannelArn = auth.user['custom:notification_channel'];

  const hasNewNotifications = useAppSelector((state) => state.notification.hasNewNotifications);

  useSubscribeToNotifications();

  useDescribeChannelMembershipForAppInstanceUser({
    channelArn: notificationChannelArn,
    appInstanceUserArn: chimeBearer,
    chimeBearer,
    // We would want this query to run only once during the app mount
    // We would update the ReadMarkerTimestamp via redux actions
    staleTime: Infinity,
    onSuccess: (data) => {
      try {
        const channelLastReadTimestamp =
          data.ChannelMembership?.AppInstanceUserMembershipSummary?.ReadMarkerTimestamp;
        const channelLastMessageTimestamp =
          data.ChannelMembership?.ChannelSummary?.LastMessageTimestamp;

        if (!channelLastReadTimestamp || !channelLastMessageTimestamp) {
          return;
        }

        const channelLastReadTimestampDate = new Date(channelLastReadTimestamp);
        const channelLastMessageTimestampDate = new Date(channelLastMessageTimestamp);

        // If the last message in the channel is after the last channel read timestamp
        // set has new notifications state in redux
        if (isAfter(channelLastMessageTimestampDate, channelLastReadTimestampDate)) {
          appDispatch(receivedNewNotification());
        }

        const channelLastReadTimestampDatestring = channelLastMessageTimestampDate.toUTCString();
        appDispatch(setChannelLastMarkedAsReadTimestamp(channelLastReadTimestampDatestring));
      } catch (error) {
        console.log('Error updating the notifications state', error);
      }
    },
  });

  return (
    <SideBar.SideBarNavItem
      icon={
        <Bell
          aria-label="Notifications"
          size="20px"
          color="currentColor"
          className="text-inherit"
        />
      }
      label={routes.notificationsLabel}
      path="notifications"
      eventsCount={hasNewNotifications ? 1 : 0}
      showNotificationDot={true}
    >
      {children}
    </SideBar.SideBarNavItem>
  );
};

export default NotificationsNavItem;
