import clsx from 'clsx';
import { useStrapiHQAdminCoursesData } from '../../../../../course-management/hooks/useStrapiHQAdminCourseData';

interface StatusCellProps {
  status: boolean;
}
interface GenericStatusCellProps {
  containerClassName: string;
  dotClassName: string;
  display: string;
}
const GenericStatusCell = (props: GenericStatusCellProps) => {
  const { containerClassName, dotClassName, display } = props;
  return (
    <div
      className={clsx(
        'flex w-min items-center gap-[9px] rounded-[100px] py-[2px] pr-2 pl-1.5',
        containerClassName
      )}
    >
      <span className={clsx('h-1.5 w-1.5 rounded-full', dotClassName)}></span>
      <span className="text-xs font-normal">{display}</span>
    </div>
  );
};
export const StatusCell = (props: StatusCellProps) => {
  const {
    courseTable: {
      CourseTableStatus: { liveStatusLabel, draftStatusLabel },
    },
  } = useStrapiHQAdminCoursesData();
  const { status } = props;
  switch (status) {
    case true: {
      return (
        <GenericStatusCell
          containerClassName="bg-emerald-900 text-emerald-100 space-x-1"
          dotClassName="bg-emerald-100"
          display={liveStatusLabel}
        />
      );
    }
    case false: {
      return (
        <GenericStatusCell
          containerClassName="bg-zinc-900 text-zinc-50"
          dotClassName="bg-zinc-50"
          display={draftStatusLabel}
        />
      );
    }
  }
};
