import { useStrapiOrganizationData } from '../hooks/useStrapiOrganizationData';
import OrganisationTableMain from './Table/OrganisationTableMain';
import TopNav from './TopNav';
import { SideBarLayoutPageContentWrapper } from '../../../components/Wrapper';
import useSessionTracker from '../../../hooks/use-session-tracker-hook';

const OrganisationMain = () => {
  useSessionTracker();
  const { topNav } = useStrapiOrganizationData();

  return (
    <main id="main-content" className="flex w-full flex-col items-center gap-5">
      <TopNav title={topNav.organisationTitle} isMain={true} />
      <SideBarLayoutPageContentWrapper>
        <OrganisationTableMain className="w-full" />
      </SideBarLayoutPageContentWrapper>
    </main>
  );
};
export { OrganisationMain };
