import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Dialog from '../../../../../../components/Dialog';
import { Label } from '../../../../../../components/FormElements';
import ErrorMessage from '../../../../../../components/FormElements/ErrorMessage';
import TextAreaInput from '../../../../../../components/FormElements/TextAreaInput';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { fieldHasError } from '../../../../../../utils/react-hook-form';
import { ENTERPRISE_ADMIN_REQUEST_ACTION } from '../../../../constants';
import useEnterpriseAdminRequestActionMutation from '../../../../hooks/useEnterpriseAdminRequestActionMutation';
import { useStrapiRequestData } from '../../../../hooks/useStrapiRequestData';
import FormActionButton from '../../../../../shared/components/RaiseARequestToHQModal/FormActionButton';

interface Props {
  requestId: string;
  setIsDialogOpen: (open: boolean) => void;
  showRejectActionDialogContent: (show: boolean) => void;
}

// Form Schema

function formSchema(
  rejectReasonError: string,
  rejectReasonLabel: string,
  rejectReasonMaxValue: number,
  rejectReasonMaxError: string
) {
  return yup.object({
    comment: yup
      .string()
      .trim()
      .required(rejectReasonError)
      .max(rejectReasonMaxValue, rejectReasonMaxError)
      .label(rejectReasonLabel),
  });
}

// Form Schema

const RejectRequestActionDialogContent = (props: Props) => {
  const { requestId, setIsDialogOpen, showRejectActionDialogContent } = props;
  const requestActionMutation = useEnterpriseAdminRequestActionMutation();

  const { rejectRequestModal } = useStrapiRequestData();
  const {
    title,
    rejectReasonLabel,
    backButton,
    rejectRequestButton,
    rejectReasonPlaceholder,
    rejectingRequestLabel,
    rejectReasonError,
    rejectReasonMaxValue,
    rejectReasonMaxError,
  } = rejectRequestModal;
  const schema = formSchema(
    rejectReasonError,
    rejectReasonLabel,
    rejectReasonMaxValue,
    rejectReasonMaxError
  );
  type RejectRequestFormData = yup.InferType<typeof schema>;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RejectRequestFormData>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      comment: '',
    },
  });

  const handleRequestRejectAction = (data: RejectRequestFormData) => {
    const { comment } = data;

    const params = {
      action: ENTERPRISE_ADMIN_REQUEST_ACTION.REJECT,
      requestId: parseInt(requestId),
      comment,
    };

    requestActionMutation.mutate(params, {
      onSuccess: () => {
        // Close the dialog when the action is rejected
        setIsDialogOpen(false);
        showRejectActionDialogContent(false);
      },
    });
  };

  return (
    <div className="box-border flex flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-6 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),_0px_10px_10px_-5px_rgba(0,0,0,0.04)]">
      <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
        {title}
      </Dialog.Title>
      <form
        onSubmit={handleSubmit(handleRequestRejectAction)}
        className="flex w-full flex-col gap-6"
      >
        <div className="flex w-full flex-col gap-2">
          <Label htmlFor="enterprise_admin_request_rejection_reason" label={rejectReasonLabel} />
          <TextAreaInput
            placeholder={rejectReasonPlaceholder}
            {...register('comment')}
            className="text-sm"
            rows={8}
            id="enterprise_admin_request_rejection_reason"
            hasError={fieldHasError(errors, 'comment')}
          />
          <HookFormErrorMessage
            name="comment"
            errors={errors}
            render={({ message }) => <ErrorMessage message={message} />}
          />
        </div>
        {/* Action Buttons */}
        <div className="flex flex-col items-center justify-end gap-2 xs:flex-row">
          <button
            onClick={() => showRejectActionDialogContent(false)}
            type="button"
            className="rounded bg-transparent py-2.5 px-[30px] text-center text-sm font-medium text-white"
          >
            {backButton}
          </button>
          <FormActionButton
            type="submit"
            className="bg-base-brand disabled:opacity-80"
            disabled={
              // When the mutation is in progress
              requestActionMutation.isLoading
            }
          >
            {requestActionMutation.isLoading ? (
              <div className="flex gap-2">
                <span>{rejectingRequestLabel}</span>
                <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
              </div>
            ) : (
              rejectRequestButton
            )}
          </FormActionButton>
        </div>
      </form>
    </div>
  );
};

export default RejectRequestActionDialogContent;
