import {
  Welcome,
  UserProfile,
  Success,
  InviteMembers,
  CategoryWrapper,
  GoalWrapper,
} from './OnboardingSteps/Steps';
import { Stepper } from '../Stepper';
import { USHG_HQ_APP_ROLE } from '../../../../constants';
import { useHasAccess } from '../../../../hooks';
import { useStrapiOnBoardingData } from '../../hooks/useStrapiOnBoardingData';
import { useCategoryQuery, useGoalsQuery, useGoalCategoryQuery } from '../../hooks';
import { ENTERPRISE_ONBOARDING_TYPE, getStepMapping, STEPS } from '../../constants';
import { useMemo, useState } from 'react';
import { Category, Goal } from '../../types';
import { useLanguage } from '../../../shared/context/languageContext';

interface OnboardingProps {
  currentStep: string;
  setCurrentStep: (currentStep: string) => void;
}
const Onboarding = ({ currentStep, setCurrentStep }: OnboardingProps) => {
  const [selectedGoals, setSelectedGoals] = useState<Goal[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const { language } = useLanguage();

  // TODO: Revisit
  // const prevSelectedGoalsRef = useDelayRef({ value: selectedGoals });
  // const prevSelectedCategoriesRef = useDelayRef({ value: selectedCategories });

  // Fetch all  goals
  const goalsQuery = useGoalsQuery({
    type: ENTERPRISE_ONBOARDING_TYPE.ALL,
    language,
  });

  // Fetch all categories
  const categoryQuery = useCategoryQuery({
    type: ENTERPRISE_ONBOARDING_TYPE.ALL,
    language,
  });

  // Fetch goal category mapper and prepare recommended categories based on the selected goals
  const goalCategory = useGoalCategoryQuery();

  const recommendedCategories = useMemo(() => {
    if (goalCategory.data && selectedGoals) {
      const selectedGoalIds = selectedGoals.map((selectedGoal) => selectedGoal.id);
      return goalCategory.data
        .filter((goalCategory) => selectedGoalIds.includes(goalCategory.goalId))
        .map((goalCategory) => goalCategory.categoryId);
    }
    return [];
  }, [goalCategory.data, selectedGoals]);

  // This check is essentially to filter out the steps that are not admin, leader or manager focused.
  const hasAccess = useHasAccess([
    USHG_HQ_APP_ROLE.INDIVIDUAL_USER,
    USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER,
    USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
    USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
  ]);
  const { tabs } = useStrapiOnBoardingData();
  const {
    welcomeTab: WELCOME,
    goalsTab: GOALS,
    categoriesTab: CATEGORIES,
    profileTab: PROFILE,
    inviteMemberTab: INVITE_MEMBERS,
    successTab: ALL_DONE,
  } = tabs;

  const steps: string[] = [
    WELCOME,
    GOALS,
    CATEGORIES,
    PROFILE,
    ...(!hasAccess ? [INVITE_MEMBERS] : []),
    ALL_DONE,
  ];

  const stepsConstant: string[] = [
    STEPS.WELCOME,
    STEPS.GOALS,
    STEPS.CATEGORIES,
    STEPS.PROFILE,
    ...(!hasAccess ? [STEPS.INVITE_MEMBERS] : []),
    STEPS.ALL_DONE,
  ];

  const displayStep = (step: string) => {
    switch (step) {
      case STEPS.WELCOME:
        return (
          <Welcome
            key={WELCOME}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            steps={stepsConstant}
          />
        );

      case STEPS.GOALS:
        return (
          <GoalWrapper
            key={GOALS}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            steps={stepsConstant}
            selectedGoals={selectedGoals}
            setSelectedGoals={setSelectedGoals}
            // prevSelectedGoals={prevSelectedGoalsRef.current}
            goalsQuery={goalsQuery}
          />
        );

      case STEPS.CATEGORIES:
        return (
          <CategoryWrapper
            key={CATEGORIES}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            categoryQuery={categoryQuery}
            recommendedCategories={recommendedCategories}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            // prevSelectedCategories={prevSelectedCategoriesRef.current}
            steps={stepsConstant}
          />
        );
      case STEPS.PROFILE:
        return (
          <UserProfile
            key={PROFILE}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            steps={stepsConstant}
          />
        );
      case STEPS.INVITE_MEMBERS:
        return (
          <InviteMembers
            key={INVITE_MEMBERS}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            steps={stepsConstant}
          />
        );
      case STEPS.ALL_DONE:
        return <Success key={ALL_DONE} />;
      default:
    }
  };

  const stepMapping = getStepMapping(tabs);

  return (
    <div className="min-h-full w-full rounded-lg bg-card-bg p-2 shadow-xl sm:p-4 md:py-8 md:px-16 lg:w-[904px]">
      {/* Stepper */}
      <Stepper
        aria-label="Onboarding Stepper"
        steps={steps}
        currentStep={stepMapping[currentStep]}
      />
      {/* Render component here */}
      <div className="mt-12 px-2">{displayStep(currentStep)}</div>
    </div>
  );
};

export default Onboarding;
