import clsx from 'clsx';
import { Plus } from 'phosphor-react';
import React, { useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Dialog from '../../../../../../components/Dialog';
import AddNewMemberMain from '../../../../../teams/components/Modal/AddNewMemberModal/AddNewMemberMain';
import { RoleOptions } from '../../../../../teams/types/interface';
import BulkInviteUserDialog from './BulkInviteUserDialog';
import '../../../../../../styles/header.css';
import useStrapiMembersData from '../../../../hooks/useStrapiMembersData';
import { USHG_HQ_APP_ROLE } from '../../../../../../constants';
interface ActionButtonProps {
  actionItem: {
    label: string;
    action: () => void;
  };
  className?: string;
  iconClassName?: string;
}

const ActionButton = ({ actionItem, className, iconClassName }: ActionButtonProps) => {
  return (
    <button
      onClick={() => {
        actionItem.action && actionItem.action();
      }}
      className={twMerge('flex items-center gap-1.5 rounded bg-zinc-900 py-2 px-4', className)}
    >
      <Plus size={13} color="currentColor" weight="bold" className={iconClassName} />
      <span className="whitespace-nowrap text-xs font-medium text-zinc-200">
        {actionItem.label}
      </span>
    </button>
  );
};

interface PageHeaderProps {
  className?: string;
  role: string;
  tenantId?: string;
}

type DialogType = 'ADD_NEW_MEMBER' | 'BULK_USER_INVITE';

const PageHeader = (props: PageHeaderProps) => {
  const { className, tenantId, role } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { title, addMemberButton, bulkUploadButton } = useStrapiMembersData().topNav;

  const [activeDialogType, setActiveDialogType] = useState<DialogType | null>(null);

  const ActiveDialogContent = useMemo(() => {
    switch (activeDialogType) {
      case 'ADD_NEW_MEMBER': {
        return (
          <AddNewMemberMain
            isAllMembers={true}
            isOnBoarding={false}
            setIsModalOpen={() => {
              setIsDialogOpen(true);
            }}
            dropDownOptions={RoleOptions.ALL_MEMBERS}
            isLoading={false}
          />
        );
      }
      case 'BULK_USER_INVITE': {
        return (
          <BulkInviteUserDialog closeDialog={() => setIsDialogOpen(false)} tenantId={tenantId} />
        );
      }
    }
  }, [activeDialogType]);

  return (
    <>
      <div
        className={clsx(
          'flex flex-col items-center justify-between gap-2 border-b border-zinc-800 bg-card-bg xxs:flex-row xxs:gap-0',
          className
        )}
      >
        <h1 className="header-title">{title}</h1>
        <div className="flex h-max flex-col items-center gap-2 sm:flex-row">
          <ActionButton
            actionItem={{
              action: () => {
                setActiveDialogType('BULK_USER_INVITE');
                setIsDialogOpen(true);
              },
              label: bulkUploadButton,
            }}
            iconClassName="text-zinc-300"
            className="w-full py-[9px]"
          ></ActionButton>
          {role !== USHG_HQ_APP_ROLE.USHG_ADMIN && (
            <ActionButton
              actionItem={{
                action: () => {
                  setActiveDialogType('ADD_NEW_MEMBER');
                  setIsDialogOpen(true);
                },
                label: addMemberButton,
              }}
              iconClassName="text-zinc-300"
              className="w-full bg-base-brand"
            ></ActionButton>
          )}
        </div>
      </div>
      {/* Dialog */}
      <Dialog
        containerClassName="max-h-[80vh] overflow-y-auto"
        open={isDialogOpen}
        onOpenChange={(open) => setIsDialogOpen(open)}
        Content={ActiveDialogContent}
        onInteractOutside={(event) => {
          event.preventDefault();
        }}
      ></Dialog>
    </>
  );
};

export default PageHeader;
