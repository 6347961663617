import { RouteObject } from 'react-router-dom';
import USHGAdminBadgePage from '../components/USHGAdminBadgeView/USHGAdminBadgePage';
import BadgeMain from '../pages/BadgeMain';
import BadgesMain from '../pages/BadgesMain';

const routes: RouteObject[] = [
  {
    path: '/manage/badges',
    element: <USHGAdminBadgePage />,
  },
  {
    path: '/badges/:id',
    element: <BadgeMain />,
  },
  {
    path: '/badges',
    element: <BadgesMain />,
  },
];

export default routes;
