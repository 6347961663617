import { useMemo } from 'react';
import { EnterpriseAdminRequestCreateBy } from '../../../../../types';
import AvatarAndNameCell from '../../../../common/TableCells/AvatarAndNameCell';
import { generateCDNMediaAssetURLWithKey } from '../../../../../../shared/helpers';

interface RaisedByCellProps {
  createdByUser: EnterpriseAdminRequestCreateBy;
}

const RaisedByCell = (props: RaisedByCellProps) => {
  const { createdByUser } = props;

  // TODO: Revisit this logic
  const name = createdByUser.fullName ?? `${createdByUser?.lastName} ${createdByUser?.firstName}`;

  const profilePictureURL = useMemo(() => {
    const pictureUrl = createdByUser.pictureUrl;
    if (!pictureUrl) {
      return undefined;
    }

    const cdnProfilePictureUrl = generateCDNMediaAssetURLWithKey({ key: pictureUrl });
    return cdnProfilePictureUrl;
  }, [createdByUser.pictureUrl]);

  return <AvatarAndNameCell name={name} profilePictureURL={profilePictureURL} />;
};

export default RaisedByCell;
