import clsx from 'clsx';
import { ClosedCaptionsType } from '../../../../types';
import { useStrapiHQAdminCoursesData } from '../../../../hooks/useStrapiHQAdminCourseData';
import { useEffect, useState } from 'react';
import Select from '../../../../../../components/Select';

interface Props {
  data: ClosedCaptionsType[];
  className?: string;
  setClosedCaptions: (data: ClosedCaptionsType[]) => void;
}

const ClosedCaptionsTable = ({ data, className, setClosedCaptions }: Props) => {
  const { moduleModal } = useStrapiHQAdminCoursesData();

  const {
    subtitleFilenameLabel,
    subtitleLanguageLabel,
    subtitleRemoveLabel,
    subtitleRemoveButtonLabel,
    LanguageOptions,
  } = moduleModal;

  const [disabledLanguages, setDisabledLanguages] = useState<string[]>([]);

  useEffect(() => {
    const initialDisabledLanguages = data.map((subtitle) => subtitle.language);
    setDisabledLanguages(initialDisabledLanguages);
  }, [data]);

  const handleLanguageChange = (index: number, selectedLanguage: string) => {
    // Find the value corresponding to the selected language display name
    const languageValue = LanguageOptions.find(
      (option: { display: string; value: string | null }) => option.display === selectedLanguage
    )?.value;

    const newData = [...data];
    newData[index].language = languageValue ? languageValue : '';

    const newDisabledLanguages = disabledLanguages.filter(
      (lang) => lang !== newData[index].language
    );
    setDisabledLanguages([...newDisabledLanguages, newData[index].language]);

    setClosedCaptions(newData);
  };

  const handleRemoveItem = (index: number) => {
    const newData = [...data];
    newData.splice(index, 1);
    setClosedCaptions(newData);
  };

  return (
    <table className={clsx('w-full', className)}>
      <thead className="w-full">
        <tr>
          <th className="bg-zinc-900 px-2 py-3 text-start text-[12px] font-semibold leading-[16px] text-zinc-400">
            {subtitleFilenameLabel}
          </th>
          <th className="bg-zinc-900 px-2 py-3 text-start text-[12px] font-semibold leading-[16px] text-zinc-400">
            {subtitleLanguageLabel}
          </th>
          <th className="bg-zinc-900 px-2 py-3 text-start text-[12px] font-semibold leading-[16px] text-zinc-400">
            {subtitleRemoveLabel}
          </th>
        </tr>
      </thead>
      {data.length > 0 ? (
        <tbody>
          {data.map((subtitle, index) => (
            <tr key={index} className={index % 2 === 0 ? '' : 'bg-zinc-900'}>
              <td className="px-2 py-3 text-[12px] font-normal leading-[16px] text-zinc-100">
                {subtitle.FileName}
              </td>
              <td className="px-2 py-3 text-[12px] font-normal leading-[16px] text-zinc-100">
                <Select
                  options={LanguageOptions}
                  value={subtitle.language}
                  onChange={(e: any) => handleLanguageChange(index, e.target.value)}
                  className={index % 2 === 0 ? 'bg-card-bg ' : 'border-zinc-900 bg-zinc-900'}
                  disabledOptions={disabledLanguages.filter((lang) => lang !== null)}
                />
              </td>
              <td className="px-2 py-3 text-[12px] font-normal leading-[16px]">
                <button
                  className="text-red-500"
                  type="button"
                  onClick={(e) => {
                    handleRemoveItem(index);
                    e.stopPropagation();
                  }}
                >
                  {subtitleRemoveButtonLabel}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td
              className="justify-center px-2 py-3  text-center text-[12px] font-normal leading-[16px] text-zinc-100"
              colSpan={3}
            >
              No data available
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default ClosedCaptionsTable;
