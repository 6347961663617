import { useMutation } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { uploadToS3 } from '../../../api';
import { Toast } from '../../../components/Toast';
import { S3_HEADER, S3_TAG_KEY, S3_TAG_VALUE } from '../../../constants';
import { ERROR_TYPES } from '../../../constants/error-types.constants';
import useStrapiMembersData from '../../members/hooks/useStrapiMembersData';

export interface useFileUploadMutationFnArgs {
  file: File;
  uploadUrl: string;
  signal?: AbortController['signal'];
}

const useFileUploadMutation = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const {
    toastMessage: { fileUploadCancelled, fileUploadSuccess, fileUploadFailed },
  } = useStrapiMembersData();

  /**
   * Handle File Upload Progress events from axios
   */
  const onUploadProgress: AxiosRequestConfig['onUploadProgress'] = useCallback(
    (progressEvent: { loaded: number; total: number }) => {
      const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setUploadProgress(uploadProgress);
    },
    []
  );

  const {
    error,
    isLoading,
    isError,
    reset: resetMutation,
    mutateAsync,
    isSuccess,
  } = useMutation({
    mutationFn: async (args: useFileUploadMutationFnArgs) => {
      const { file, uploadUrl, signal } = args;

      const fileTags = {
        [S3_TAG_KEY.isTemp]: S3_TAG_VALUE.true,
      };

      const response = await uploadToS3({
        uploadUrl,
        fileContents: file,
        config: {
          onUploadProgress,
          headers: {
            [S3_HEADER.X_AMZ_TAGGING]: qs.stringify(fileTags),
          },
          signal,
        },
      });

      return response;
    },
    onSuccess: (_, variables) => {
      toast.custom((t) => (
        <Toast
          Title={`${variables.file.name} ${fileUploadSuccess}`}
          toastInstance={t}
          variant="success"
        />
      ));
    },
    onError: (error, variables) => {
      if (axios.isAxiosError(error)) {
        const { code } = error;
        switch (code) {
          case ERROR_TYPES.ERR_CANCELED: {
            // If we get error canceled code that
            // means the user has request for cancellation
            toast.custom((t) => (
              <Toast Title={fileUploadCancelled} variant="warning" toastInstance={t} />
            ));
            return;
          }
        }
      }

      toast.custom((t) => (
        <Toast
          Title={`${fileUploadFailed} ${variables.file.name}`}
          toastInstance={t}
          variant="error"
        />
      ));
    },
  });

  /**
   * Wrapper to reset both the upload progress and the mutation
   */
  const reset = () => {
    setUploadProgress(0);
    resetMutation();
  };

  return {
    reset,
    uploadProgress,
    isLoading,
    isError,
    error,
    mutateAsync,
    isSuccess,
  };
};

export default useFileUploadMutation;
