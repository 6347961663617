import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import { Plus } from 'phosphor-react';
import { useFieldArray, useForm } from 'react-hook-form';
import Dialog from '../../../../../../../components/Dialog';
import {
  DropdownInput,
  Label,
  TextAreaInput,
  TextInput,
} from '../../../../../../../components/FormElements';
import ErrorMessage from '../../../../../../../components/FormElements/ErrorMessage';
import { BasicSpinner } from '../../../../../../../components/Spinners';
import { fieldHasError } from '../../../../../../../utils/react-hook-form';
import {
  useAddAssessmentQuestionInMultiLangMutation,
  useAddAssessmentQuestionMutation,
  useUpdateAssessmentQuestionMutation,
} from '../../../../../hooks';
import { useStrapiHQAdminCoursesData } from '../../../../../hooks/useStrapiHQAdminCourseData';
import {
  ModalType,
  AssessmentQuestionArgs,
  optionItemsType,
  updateQuestionArgs,
  AssessmentQuestionInMultiLangArgs,
} from '../../../../../types';
import { FormActionButton, FormFieldWrapper } from '../../../../common/FormControls';
import { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { COURSES_ERROR_CODE } from '../../../../../constants/courses-management';
import cn from '../../../../../../../utils/cn';
import { ASSESSMENT_QUESTION_ACTION } from '../../../../../constants';

// New Question Form Schema section starts

type GenerateNewQuestionFormSchemaParams = {
  correctOptionRequired: string;
  optionValueRequired: string;
  questionMaxError: string;
  questionMaxValue: number;
  questionMinError: string;
  questionMinValue: number;
  questionRequired: string;
};

const generateNewQuestionFormSchema = (params: GenerateNewQuestionFormSchemaParams) => {
  const {
    correctOptionRequired,
    optionValueRequired,
    questionMaxError,
    questionMaxValue,
    questionMinError,
    questionMinValue,
    questionRequired,
  } = params;

  const schema = yup.object({
    question: yup
      .string()
      .trim()
      .min(questionMinValue, questionMinError)
      .max(questionMaxValue, questionMaxError)
      .required(questionRequired),
    options: yup
      .array(
        yup.object({
          id: yup.number().nullable(),
          // at least a character in the option
          value: yup.string().trim().required(optionValueRequired),
        })
      )
      .required(),
    // when there is no input it is undefined, getting should be number error
    // so added typeError that will show when the type doesn't match
    correctOption: yup.number().required(correctOptionRequired).typeError(correctOptionRequired),
  });

  return schema;
};

type NewQuestionFormData = yup.InferType<ReturnType<typeof generateNewQuestionFormSchema>>;

// New Question Form Schema section ends

interface Props {
  setIsAssessmentQuestionModalOpen: (open: boolean) => void;
  formActionButtonLabel: string;
  buttonIcon?: React.ReactNode;
  questionsPrefetchData?: Record<string, any>;
  defaultLanguageData?: Record<string, any>;
  assessmentId?: number;
  modalType: ModalType;
  refetch: () => void;
  language: string;
  errorType?: string | null;
  setErrorType?: (error: string | null) => void;
  setActionInProgress: (a: keyof typeof ASSESSMENT_QUESTION_ACTION | null) => void;
}

const AssessmentQuestionForm = ({
  setIsAssessmentQuestionModalOpen,
  questionsPrefetchData,
  defaultLanguageData,
  formActionButtonLabel,
  buttonIcon,
  assessmentId,
  modalType,
  refetch,
  language,
  errorType,
  setErrorType,
  setActionInProgress,
}: Props) => {
  // State to manage field disabling
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);
  const [optionsList, setOptionsList] = useState<{ label: string; value: number | null }[]>([]);

  // create add new question Mutation
  const addAssessmentQuestionMutation = useAddAssessmentQuestionMutation();
  const addAssessmentQuestionInMultiLangMutation = useAddAssessmentQuestionInMultiLangMutation();

  // create update new question Mutation
  const updateAssessmentQuestionMutation = useUpdateAssessmentQuestionMutation();

  const {
    questionLabel,
    questionRequired,
    questionPlaceholder,
    questionMinValue,
    questionMinError,
    questionMaxValue,
    questionMaxError,
    optionsLabel,
    optionsPlaceholder,
    optionValueRequired,
    correctOptionLabel,
    correctOptionRequired,
    correctOptionPlaceholder,
    addMoreOptionsButton,
    removeButton,
    cancelButton,
  } = useStrapiHQAdminCoursesData().questionModal;

  const formSchema = useMemo(() => {
    const schema = generateNewQuestionFormSchema({
      correctOptionRequired,
      optionValueRequired,
      questionMaxError,
      questionMaxValue,
      questionMinError,
      questionMinValue,
      questionRequired,
    });

    return schema;
  }, [
    correctOptionRequired,
    optionValueRequired,
    questionMaxError,
    questionMaxValue,
    questionMinError,
    questionMinValue,
    questionRequired,
  ]);

  // Form initialization
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<NewQuestionFormData>({
    resolver: yupResolver(formSchema),
  });

  const { fields, append, remove } = useFieldArray({
    name: 'options',
    keyName: 'key',
    control,
  });

  useEffect(() => {
    const initializeOptions = (options: { id: number; value: string }[], isCreateMode: boolean) => {
      const initialOptions = options.map((option: { id: number | null; value: string }) => ({
        id: option.id,
        value: isCreateMode ? '' : option.value,
      }));

      // Ensure there are at least two options
      while (initialOptions.length < 2) {
        initialOptions.push({ id: null, value: '' });
      }

      return initialOptions;
    };

    const setCreateQuestionValues = () => {
      if (questionsPrefetchData?.data) {
        const initialOptions = initializeOptions(questionsPrefetchData.data.options, true);
        reset({
          options: initialOptions,
          correctOption: questionsPrefetchData.data.correctOption,
        });
      }
    };

    const setEditQuestionValues = () => {
      if (questionsPrefetchData?.data) {
        const selectedLangOptions = questionsPrefetchData.data.options || [];
        let mergedOptions = [...selectedLangOptions];

        if (modalType === 'manage' && defaultLanguageData?.data) {
          const defaultLangOptions = defaultLanguageData.data.options || [];

          if (defaultLangOptions.length > selectedLangOptions.length) {
            const extraFields = defaultLangOptions
              .slice(selectedLangOptions.length)
              .map((option: { id: number }) => ({ id: option.id || null, value: '' }));

            mergedOptions = [...selectedLangOptions, ...extraFields];
          }

          reset({
            options: initializeOptions(mergedOptions, false),
            question: questionsPrefetchData.data.question || '',
            correctOption: defaultLanguageData.data.correctOption || undefined, // Use the correctOption from the default language
          });
        } else {
          // Populate the form for the edit modal
          reset({
            options: initializeOptions(selectedLangOptions, false),
            question: questionsPrefetchData.data.question || '',
            correctOption: questionsPrefetchData.data.correctOption || undefined,
          });
        }
      }
    };
    reset({
      options: [
        { id: null, value: '' },
        { id: null, value: '' },
      ],
    });
    if (questionsPrefetchData?.data) {
      if (modalType === 'manage' && errorType === COURSES_ERROR_CODE.QUESTION_ID_NOT_FOUND) {
        // Handle Create Mode
        setIsFieldDisabled(true); // Disable fields in create mode
        setCreateQuestionValues(); // Prepopulate fields in create mode
      } else if (modalType === 'manage' || modalType === 'update') {
        setIsFieldDisabled(modalType === 'manage'); // Disable fields in manage mode, enable in update mode
        setEditQuestionValues(); // Set all values for manage or update mode
      }
    }
  }, [questionsPrefetchData?.data, defaultLanguageData?.data, errorType, modalType, reset]);

  // Watch options and build the options list
  const options = watch('options');
  const correctOption = watch('correctOption');

  useEffect(() => {
    if (options && options.length > 0) {
      const maxValidOptionIndex = options.length;

      if (correctOption && (correctOption > maxValidOptionIndex || correctOption < 1)) {
        reset({
          correctOption: maxValidOptionIndex, // Reset to the last valid option index
        });
      }
    }
  }, [options, correctOption, reset]);

  useEffect(() => {
    if (options && options.length > 0) {
      const list = options.map((value: any, index: number) => ({
        label: String(index + 1),
        value: index + 1,
      }));

      setOptionsList([
        {
          label: correctOptionPlaceholder,
          value: null,
        },
        ...list,
      ]);
    }
  }, [options, correctOptionPlaceholder]);

  const addAssessmentQuestionHandler = (data: NewQuestionFormData) => {
    if (assessmentId) {
      const params: AssessmentQuestionArgs = {
        moduleId: assessmentId,
        question: data.question,
        options: data.options.map((option) => option.value),
        correctOption: data.correctOption,
      };
      setActionInProgress('add');
      // add new question
      addAssessmentQuestionMutation.mutate(params, {
        onSuccess: () => {
          refetch();
          // Close modal if the mutation is successful
          setIsAssessmentQuestionModalOpen(false);
        },
        onSettled: () => {
          setActionInProgress(null);
        },
      });
    }
    return;
  };

  const addAssessmentQuestionInMultiLangHandler = (data: NewQuestionFormData) => {
    if (questionsPrefetchData?.data) {
      const params: AssessmentQuestionInMultiLangArgs = {
        questionId: questionsPrefetchData.data.questionId,
        question: data.question,
        options: data.options.map((option) => option.value),
        language: language,
      };

      setActionInProgress('add');
      // add new question
      addAssessmentQuestionInMultiLangMutation.mutate(params, {
        onSuccess: () => {
          refetch();
          setErrorType && setErrorType(null);
          // Close modal if the mutation is successful
          setIsAssessmentQuestionModalOpen(false);
        },
        onSettled: () => {
          setActionInProgress(null);
        },
      });
    }
    return;
  };

  const updateAssessmentHandler = (data: NewQuestionFormData) => {
    const { options: newOptions, correctOption } = data;
    const options = defaultLanguageData?.data?.options || [];

    const updatedOptions: optionItemsType[] = [];

    newOptions.forEach((item: any, index: number) => {
      const originalOption = options.find((opt: { id: number }) => opt.id === item.id);

      updatedOptions.push({
        id: originalOption ? originalOption.id : null, // Retain the original ID
        score: index + 1 == correctOption ? 1 : 0,
        value: item.value,
      });
    });
    const params: updateQuestionArgs = {
      questionId: questionsPrefetchData?.data.questionId
        ? questionsPrefetchData?.data.questionId
        : null,
      question: data.question,
      options: updatedOptions,
      language: language,
    };

    setActionInProgress('update');
    // update question
    updateAssessmentQuestionMutation.mutate(params, {
      onSuccess: () => {
        refetch();
        // Close modal if the mutation is successful
        setIsAssessmentQuestionModalOpen(false);
      },
      onSettled: () => {
        setActionInProgress(null);
      },
    });
    return;
  };

  return (
    <form
      className="flex flex-col gap-4 overflow-y-auto"
      onSubmit={(e) => {
        e.preventDefault();
        if (modalType === 'add') {
          handleSubmit(addAssessmentQuestionHandler)(e);
        } else if (modalType === 'update') {
          handleSubmit(updateAssessmentHandler)(e);
        } else if (modalType === 'manage') {
          if (errorType === COURSES_ERROR_CODE.QUESTION_ID_NOT_FOUND) {
            handleSubmit(addAssessmentQuestionInMultiLangHandler)(e);
          } else {
            handleSubmit(updateAssessmentHandler)(e);
          }
        }
      }}
    >
      {/* Assessment Question */}
      <FormFieldWrapper>
        <Label htmlFor="assessment_question" label={questionLabel}></Label>
        <TextAreaInput
          id="assessment_question"
          placeholder={questionPlaceholder}
          rows={6}
          aria-invalid={fieldHasError(errors, 'question') ? 'true' : 'false'}
          hasError={fieldHasError(errors, 'question')}
          {...register('question')}
        ></TextAreaInput>
        <HookFormErrorMessage
          name="question"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </FormFieldWrapper>

      {/* Options Input */}
      <FormFieldWrapper>
        <Label htmlFor="assessment_options" label={optionsLabel}></Label>
        {fields.map((field: any, index: number) => {
          return (
            <div key={field.key}>
              <div className="flex items-center justify-center gap-4">
                <span className="list-decimal text-xs font-medium text-zinc-300">{index + 1}</span>
                <input type="hidden" {...register(`options.${index}.id`)} />
                <TextInput
                  className="block h-9 appearance-none rounded border-[1px] border-zinc-700 bg-zinc-900  py-2 px-4 leading-tight text-zinc-50 placeholder:text-xs focus:border-gray-500  focus:outline-none"
                  type="text"
                  id="assessment_options"
                  placeholder={`${optionsPlaceholder} ${index + 1}`}
                  hasError={fieldHasError(errors, `options.${index}.value`)}
                  {...register(`options.${index}.value`)}
                />
              </div>

              {errors.options?.[index]?.value &&
                errors.options?.[index]?.value?.type === 'required' && (
                  <ErrorMessage className="pl-6" message={`Option ${index + 1} is required`} />
                )}

              {fields.length > 2 && modalType != 'manage' && (
                <button
                  type="button"
                  onClick={() => {
                    {
                      remove(index);
                    }
                  }}
                  className="pl-6 text-xs text-base-brand"
                >
                  <div className="flex text-xs font-medium">{removeButton}</div>
                </button>
              )}
            </div>
          );
        })}
      </FormFieldWrapper>
      {fields.length < 6 && modalType != 'manage' && (
        <button
          type="button"
          onClick={() => {
            append({ id: null, value: '' });
          }}
          className="text-base-brand"
        >
          <div className="flex items-center gap-x-2 text-sm font-medium">
            <Plus size={20} /> {addMoreOptionsButton}
          </div>
        </button>
      )}

      {/* Correct option Input */}
      <FormFieldWrapper className={cn(isFieldDisabled && 'opacity-50')}>
        <Label htmlFor="assessment_correct_option" label={correctOptionLabel}></Label>
        <DropdownInput
          options={optionsList}
          value={correctOption}
          type="select"
          id="assessment_correct_option"
          hasError={fieldHasError(errors, 'correctOption')}
          disabled={isFieldDisabled}
          {...register('correctOption')}
        />
        <HookFormErrorMessage
          name="correctOption"
          errors={errors}
          render={({ message }) => <ErrorMessage message={message} />}
        />
      </FormFieldWrapper>

      {/* Action Buttons */}
      <div className="flex flex-col items-center justify-end gap-2 xs:flex-row">
        <Dialog.Close
          type="button"
          className="rounded bg-transparent py-2.5 px-[30px] text-center text-sm font-medium text-white"
        >
          {cancelButton}
        </Dialog.Close>
        <FormActionButton
          type="submit"
          className="bg-base-brand disabled:opacity-80"
          disabled={
            // When the mutation is in progress
            addAssessmentQuestionMutation.isLoading ||
            addAssessmentQuestionInMultiLangMutation.isLoading ||
            updateAssessmentQuestionMutation.isLoading
          }
        >
          {addAssessmentQuestionMutation.isLoading ||
          addAssessmentQuestionInMultiLangMutation.isLoading ||
          updateAssessmentQuestionMutation.isLoading ||
          questionsPrefetchData?.isLoading ? (
            <div className="flex gap-2">
              <span>{formActionButtonLabel}</span>
              <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <span>{formActionButtonLabel}</span>
              {buttonIcon}
            </div>
          )}
        </FormActionButton>
      </div>
    </form>
  );
};

export default AssessmentQuestionForm;
