import { ERROR_CODE } from '../../members/constants/errors';

interface GetInitBulkInviteErrorMessageArgs {
  errorCodeToMessageMap: Record<string, string>;
  errorCode: string;
  errorMessage: string;
  isOnboarding: boolean;
}

const getInitBulkInviteErrorMessage = (args: GetInitBulkInviteErrorMessageArgs) => {
  const { errorCode, errorMessage, errorCodeToMessageMap, isOnboarding } = args;

  let message: string | null = null;

  // Function to extract emails from  error message
  function extractEmails(errorMessage: string) {
    const regex = /\[([^\]]*)\]/; // Regex to match content inside square brackets
    const match = errorMessage.match(regex); // Extract matched content

    if (match && match[1]) {
      return match[1]; // Return the emails without brackets
    }

    return '';
  }

  // Function to replace placeholder with actual email list
  function replaceEmailPlaceholder(template: string, emailList: string) {
    return template.replace('{{emailList}}', emailList);
  }

  switch (errorCode) {
    case ERROR_CODE.BULK_UPLOAD_VALIDATION_FAILED: {
      message = errorCodeToMessageMap[errorMessage];
      break;
    }
    case ERROR_CODE.SUBSCRIPTION_LICENSES_EXCEEDS:
    case ERROR_CODE.TRIAL_LIMIT_EXCEEDED: {
      if (isOnboarding) {
        message = errorCodeToMessageMap[`${errorCode}_ONBOARDING`];
      } else {
        message = errorCodeToMessageMap[errorCode];
      }
      break;
    }
    case ERROR_CODE.USER_ALREADY_EXIST: {
      // Extract email list from error message
      const emailList = extractEmails(errorMessage);
      // Replace placeholder with the extracted email list
      message = replaceEmailPlaceholder(errorCodeToMessageMap[errorCode], emailList);
      break;
    }
  }

  message = message ?? errorCodeToMessageMap[ERROR_CODE.BULK_UPLOAD_UNKNOWN_ERROR];

  return message;
};

export { getInitBulkInviteErrorMessage };
