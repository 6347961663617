import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { AUTH_API_PATHS } from '../constants';
import { PreviewSubscription, PreviewSubscriptionResponse } from '../types/preview';

/**
 * Invite enterprise
 */
const previewSubscription = async (
  args: PreviewSubscription
): Promise<PreviewSubscriptionResponse> => {
  const path = AUTH_API_PATHS.REVIEW_SUBSCRIPTION;
  const body = args;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body,
    response: true,
  });
  return response.data;
};

export { previewSubscription };
