import useSessionTracker from '../../../../hooks/use-session-tracker-hook';
import LearningJourneysHeading from './LearningJourneysHeading';
import LearningJourneysMain from './LearningJourneysMain/LearningJourneysMain';

const LearningJourneysListPage = () => {
  useSessionTracker();
  return (
    <div className="flex w-full flex-col">
      <LearningJourneysHeading />
      <LearningJourneysMain />
    </div>
  );
};

export default LearningJourneysListPage;
