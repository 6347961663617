import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PaginationFooter } from '../../../../components/DataTables';
import { BasicSpinner } from '../../../../components/Spinners';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { RootState } from '../../../../store';

import OrganisationTable from './OrganisationTable';
import useDebounce from '../../../../hooks/use-debounce';
import OrganisationMainHeader from './OrganisationMainHeader';
import OrganisationEmptyState from './OrganisationEmptyPage';
import useGetAllOrganisationQuery from '../../hooks/useGetAllOrganisationQuery';
import {
  ENT_ADMIN_DEFAULT_PAGE,
  ENT_ADMIN_MIN_PAGE_SIZE,
  ENT_ADMIN_MAX_PAGE_SIZE,
  ENT_ADMIN_PAGE_SIZE_INCREMENT,
} from '../../../teams/constants/enterpriseAdmin';
import { triggerDataRefresh } from '../../slice/organisation.slice';
import OrganisationErrorState from './OrganisationErrorpage';

interface Props {
  className?: string;
}

const organisationStateSelector = (state: RootState) => state.organisation;

const OrganisationTableMain = ({ className }: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Parse the filters from the url query parameters
  const { sortKey, sortOrder, dataRefreshState } = useAppSelector(organisationStateSelector);
  const dispatch = useAppDispatch();
  const status: string | null | undefined = searchParams.get('status') ?? undefined;
  let _isFilterApplied = false;

  const search = searchParams.get('search') ?? undefined;
  const debounceSearch = useDebounce(search);
  // Current Page
  const [page, setPage] = useState(ENT_ADMIN_DEFAULT_PAGE);
  // Page Size
  const [pageSize, setPageSize] = useState(ENT_ADMIN_MIN_PAGE_SIZE);
  // sort
  let newSortKey: string | undefined;
  let newSortOrder: string | boolean | undefined;
  if (sortKey) {
    if (sortKey === 'members') {
      if (sortOrder === false) {
        // we will be getting the sort Order for columns which contain String values as asc:asc desc:false and false:desc
        newSortOrder = 'asc';
        newSortKey = sortKey;
      } else if (sortOrder === 'asc') {
        newSortKey === '';
        newSortOrder === '';
      } else {
        newSortOrder = 'desc';
        newSortKey = sortKey;
      }
    } else {
      if (sortOrder === false) {
        // we will be getting the sort Order for columns which contain String values as asc:asc desc:false and false:desc
        newSortOrder = 'desc';
        newSortKey = sortKey;
      } else if (sortOrder === 'desc') {
        newSortKey === '';
        newSortOrder === '';
      } else {
        newSortOrder = 'asc';
        newSortKey = sortKey;
      }
    }
    // we will be getting the sort Order for columns which contain number values as asc:false desc:desc and false:asc
  }
  // Build the organisationAPIData filters from the available filters
  const filters = useMemo(() => {
    return {
      healthStatus: status,
      search: debounceSearch,
      start: (page - 1) * pageSize,
      size: pageSize,
      sortOrder: newSortOrder,
      sortKey: newSortKey,
    };
  }, [status, page, pageSize, debounceSearch, newSortOrder, newSortKey]);

  if (!filters.healthStatus || status === 'all') {
    // remove if the filter is empty
    delete filters.healthStatus;
  }
  if (!filters.search) {
    delete filters.search;
  }
  const organisationAPIData = useGetAllOrganisationQuery({ filters });

  const displayLoader = useMemo(
    () =>
      (organisationAPIData.isLoading && !organisationAPIData.data) ||
      (organisationAPIData.isFetching && organisationAPIData.data?.totalCount === 0),
    [organisationAPIData.data, organisationAPIData.isFetching, organisationAPIData.isLoading]
  );

  const isEmptyState = useMemo(
    () => organisationAPIData.data && organisationAPIData.data.totalCount === 0,
    [organisationAPIData.data]
  );

  _isFilterApplied = status !== undefined || search !== undefined;

  // Refresh the Table on data changes
  useEffect(() => {
    if (dataRefreshState) {
      organisationAPIData.refetch().then(() => {
        dispatch(triggerDataRefresh(false));
      });
    }
  }, [dataRefreshState, dispatch, organisationAPIData]);

  return (
    <main
      id="main-content"
      className={clsx(
        'flex flex-col items-start gap-4 rounded-sm bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)] md:p-5 lg:p-6',
        className
      )}
    >
      <OrganisationMainHeader
        status={status}
        search={search ?? ''}
        isFilterApplied={_isFilterApplied}
      />
      {/* Is Loading and We donot have data */}
      {organisationAPIData.isLoading && !organisationAPIData.data && (
        <div className="flex w-full justify-center py-4">
          <BasicSpinner className="text-white" />
        </div>
      )}
      {/* We have data */}
      {organisationAPIData.data && organisationAPIData.data?.totalCount > 0 && (
        <div
          className={clsx(
            'flex w-full flex-col gap-4',
            organisationAPIData.isPreviousData &&
              organisationAPIData.isFetching &&
              'pointer-events-none opacity-50'
          )}
        >
          <div className="relative w-full overflow-y-scroll">
            <OrganisationTable data={organisationAPIData.data?.results} />
          </div>
          <PaginationFooter
            setPage={setPage}
            activePage={page}
            totalItems={organisationAPIData?.data?.totalCount}
            pageSize={pageSize}
            setPageSize={setPageSize}
            maxPageSize={ENT_ADMIN_MAX_PAGE_SIZE}
            minPageSize={ENT_ADMIN_MIN_PAGE_SIZE}
            pageSizeIncrement={ENT_ADMIN_PAGE_SIZE_INCREMENT}
          />
        </div>
      )}
      {/* Empty State */}
      {isEmptyState && !displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <OrganisationEmptyState isFilterApplied={_isFilterApplied} />
        </div>
      )}
      {/* Error State */}
      {organisationAPIData.isError && !organisationAPIData.data && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <OrganisationErrorState
            isRefetching={organisationAPIData.isRefetching}
            refetch={organisationAPIData.refetch}
          />
        </div>
      )}
    </main>
  );
};

export default OrganisationTableMain;
