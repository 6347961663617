import { gql } from '@apollo/client';

const FormFragment = gql`
  fragment FormFragment on FormEntityResponse {
    data {
      attributes {
        FormData {
          ... on ComponentPageFormSubscriptionDetails {
            toPayNow
            changePlan
            title
            title_trial
            freeTrialAmount
            discountAppliedText
            subscriptionDeatilsList {
              subscriptionDetail
              slug
            }
            formHeading
          }
          ... on ComponentPageFormData {
            FormHeading
            slug
            Label {
              label
              slug
              defaultValue
              placeholder
              type
              role
              min
              max
              config {
                required {
                  value
                  message
                }
                minLength {
                  value
                  message
                }
                maxLength {
                  value
                  message
                }
                maxLength {
                  value
                  message
                }
                pattern {
                  value
                  message
                }
                validate
              }
              primaryInfo
              secondaryInfo
              options {
                value
                label
              }
            }
            role
            FormFooter {
              FormFooter
              Description
            }
          }
        }
      }
    }
  }
`;
export { FormFragment };
