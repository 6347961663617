import { format, formatDuration } from 'date-fns';
import { useLanguage } from '../../../../shared/context/languageContext';
import { getLocale } from '../../../../../utils';

export const formatDueDate = (dueDate: string) => {
  const { language } = useLanguage();
  const date = format(new Date(dueDate), 'd MMM yyyy', { locale: getLocale(language) });
  return date;
};

/**
 * Method to construct duration format
 * @param durationInMin - duration
 * @param hoursLabel - Hours Label from Strapi - hrs
 * @param minsLabel - Mins Label from Strapi - mins
 * @returns Formatted Duration
 */
export const calcTimeString = (
  durationInMin: number,
  hoursLabel = 'h',
  minsLabel = 'm',
  singularHoursLabel = 'h',
  singularMinsLabel = 'm'
) => {
  const hours = Math.floor(durationInMin / 60);
  const minutes = durationInMin - hours * 60;
  const hrs = hours === 1 ? singularHoursLabel : hoursLabel;
  const mins = minutes === 1 ? singularMinsLabel : minsLabel;
  const formatDistanceLocale = {
    xMinutes: `{{count}} ${mins}`,
    xHours: `{{count}} ${hrs}`,
  };
  const shortEnLocale = {
    formatDistance: (token: keyof typeof formatDistanceLocale, count: any) => {
      return formatDistanceLocale[token].replace('{{count}}', count);
    },
  };
  const result = formatDuration({ hours, minutes }, { locale: shortEnLocale });
  return result;
};
