import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import qs from 'qs';
import { CourseHistoryResponse, PartialCourseHistoryFilter } from '../types/interface';

const getCourseHistory = async (
  username: PartialCourseHistoryFilter
): Promise<CourseHistoryResponse> => {
  const path = '/users/user/course-history';
  const queryString = qs.stringify(username);

  try {
    const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
      response: true,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export { getCourseHistory };
