import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SUBSCRIPTION, USHG_HQ_APP_ROLE } from '../constants';
import {
  useAppSelector,
  useReduxAuthState,
  useShouldSetupMFA,
  useShouldUserRegister,
} from '../hooks';
import { RootState } from '../store';
import usePendingAccountProvision from '../hooks/use-pending-account-provision';

const subscriptionSelector = (state: RootState) => state.subscription;

const PrivateRoute = () => {
  const location = useLocation();
  const auth = useReduxAuthState();
  const { subscriptionData } = useAppSelector(subscriptionSelector);
  const shouldSetupMFA = useShouldSetupMFA();
  const shouldUserRegister = useShouldUserRegister();
  const pendingAccountProvision = usePendingAccountProvision();

  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.isAuthenticated) {
      return;
    }

    if (
      auth.user['custom:is_onboarded'] !== 'true' &&
      !(auth.user.role === USHG_HQ_APP_ROLE.USHG_ADMIN)
    ) {
      navigate('/onboarding');
    }
  }, [auth.isAuthenticated, auth.user, navigate]);

  const path = location.pathname + location.search;

  if (
    (subscriptionData?.status === SUBSCRIPTION.STATUS.PAST_DUE ||
      subscriptionData?.status === SUBSCRIPTION.STATUS.CANCELED) &&
    path !== '/settings?view=billing'
  ) {
    return (
      <Navigate
        to="/settings?view=billing"
        state={{
          fromPath: location.pathname,
        }}
        replace={true}
      />
    );
  }

  // Whether the user should go through registration process
  if (shouldUserRegister) {
    return (
      <Navigate
        to="/auth/register/subscription"
        state={{
          fromPath: location.pathname,
        }}
        replace={true}
      />
    );
  }

  if (pendingAccountProvision) {
    return (
      <Navigate
        to="/auth/register/progress"
        state={{
          fromPath: location.pathname,
        }}
        replace={true}
      />
    );
  }

  // If the user has to setup MFA then redirect the user to the MFA page
  if (shouldSetupMFA) {
    return <Navigate to="/settings?view=security" replace={true} />;
  }

  // TODO: based on the route the user is trying to
  // TODO: access we could redirect to either Admin Login or Common Login
  // We could possibly use the location.pathname to redirect at later point after authentication
  return auth.isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate
      to="/auth/login"
      state={{
        fromPath: location.pathname,
      }}
      // the page the user was trying to visit
      // would not be recorded in the history stack
      replace={true}
    />
  );
};

export default PrivateRoute;
