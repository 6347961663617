import { CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight } from 'phosphor-react';
import { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Button } from '../../../../../../components/Buttons';
import { NoDataDisplay } from '../../../../../../components/DataTables';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { useAppSelector } from '../../../../../../hooks';
import { RootState } from '../../../../../../store';
import { AssessmentQuestionsType, SubmitAssessmentArgsType } from '../../types/SingleCourseTypes';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';
type Props = {
  questions: AssessmentQuestionsType[];
  submitAssessment: (payload: SubmitAssessmentArgsType) => void;
  isSubmitting: boolean;
};

const assessmentFormSchema = yup.object({
  answerResponse: yup
    .array(
      yup.object({
        answerId: yup.string().required(),
        questionId: yup.string().required(),
      })
    )
    .required(),
});

type _ASSESSMENT_FORM_DATA = yup.InferType<typeof assessmentFormSchema>;

const QuizFormComponent = ({ questions, submitAssessment, isSubmitting }: Props) => {
  const singleCourseSelector = (state: RootState) => state.singleCourse;
  const { currentModuleDetails } = useAppSelector(singleCourseSelector);
  const PAGE_SIZE = 10;
  const totalPages = Math.ceil(questions.length / PAGE_SIZE);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      answerResponse: questions.map((item) => {
        return {
          answerId: null,
          questionId: item.id,
        };
      }),
      resolver: yupResolver(assessmentFormSchema),
    },
  });
  useFieldArray({
    control,
    name: 'answerResponse',
  });

  // Checking if all questions are answered
  const validateAllQuestions = (answerResponse: any[]) => {
    let unAnsweredFlag = false;
    answerResponse.forEach((element: any, index: number) => {
      if (element.answerId === null) {
        if (!unAnsweredFlag) {
          // Moving to the first page where question is unanswered
          const pageNumber = Math.floor(index / PAGE_SIZE) + 1;
          setCurrentPage(pageNumber);
        }
        unAnsweredFlag = true;
        // setting error for unanswered questions
        setError(`answerResponse.${index}.answerId`, { type: 'required' });
      }
    });

    return unAnsweredFlag ? false : true;
  };
  const onSubmit = (data: any) => {
    if (validateAllQuestions(data.answerResponse)) {
      if (currentModuleDetails.id) {
        const payload = {
          assessmentId: currentModuleDetails.id,
          response: data.answerResponse,
        };
        submitAssessment(payload);
      } else {
        console.log(' Inavalid assessment id');
      }
    }
  };

  const [currentPage, setCurrentPage] = useState<number>(1);

  const firstPageHandler = () => {
    setCurrentPage(1);
  };

  const lastPageHandler = () => {
    setCurrentPage(totalPages);
  };

  const nextPageHandler = () => {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) setCurrentPage(nextPage);
  };
  const previousPageHandler = () => {
    const previousPage = currentPage - 1;
    if (previousPage >= 1) setCurrentPage(previousPage);
  };
  // strapi content
  const { singleAssessment } = useStrapiCoursesData();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col">
        <div className="h-[31.25rem] w-[100%] overflow-scroll">
          {questions.length === 0 && (
            <div className="flex h-max flex-col items-center justify-center">
              <NoDataDisplay message={singleAssessment.noQuestionsErrorMessage} />
            </div>
          )}
          {questions.length > 0 && (
            <div className="flex flex-col">
              {questions.map((quesItem, quesIndex) => {
                if (
                  quesIndex >= (currentPage - 1) * PAGE_SIZE &&
                  quesIndex < currentPage * PAGE_SIZE
                )
                  return (
                    <div key={`question_${quesIndex}`}>
                      <div className="mx-5 my-6 flex flex-row">
                        <span className="mr-2 text-lg font-semibold text-zinc-200">
                          {quesIndex + 1}.
                        </span>
                        <span className="flex items-center text-base text-zinc-200 ">
                          {quesItem.question}
                        </span>
                      </div>
                      {quesItem.options.map((optionsItem: any, optionsIndex: number) => {
                        return (
                          <div key={`question ${quesIndex} options ${optionsIndex}`}>
                            <div className=" mb-1 mt-5 flex flex-row">
                              <div className="w-max">
                                <span className="invisible mx-5 mr-4 text-lg font-semibold text-zinc-200">
                                  1
                                </span>
                              </div>
                              <div className="w-max">
                                <input
                                  type="radio"
                                  className="bg-zinc-700 text-base-brand ring-zinc-700 ring-offset-zinc-700 checked:bg-base-brand hover:bg-base-brand"
                                  id={`Option${optionsItem.id}`}
                                  value={optionsItem.id}
                                  {...register(`answerResponse.${quesIndex}.answerId`, {
                                    required: true,
                                  })}
                                />
                              </div>
                              <div className="mr-6 ml-2 w-full">
                                <label
                                  htmlFor={`Option${optionsItem.id}`}
                                  className="ml-2 text-center"
                                >
                                  {optionsItem.value}
                                </label>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
              })}
            </div>
          )}
        </div>
        {errors.answerResponse && (
          <div className="w-full bg-base-brand py-2 px-4 text-xs">
            {' '}
            <span>{singleAssessment.noAnswersErrorMessage}</span>
          </div>
        )}
        <div className="flex w-[100%] flex-row items-center justify-between rounded-b-lg border-t border-zinc-700 bg-component-bg-dark p-2 text-xs md:px-6">
          <div className="flex h-max flex-row items-center gap-4">
            <div className="cursor-pointer" onClick={firstPageHandler}>
              <CaretDoubleLeft color="#71717A" className={`${currentPage == 1 ? 'hidden' : ''}`} />
            </div>
            <div className="cursor-pointer" onClick={previousPageHandler}>
              <CaretLeft color="#71717A" className={`${currentPage == 1 ? 'hidden' : ''}`} />
            </div>
            <div className="rounded-sm bg-zinc-900 py-1 px-4 text-white">
              <span className="select-none">{currentPage}</span>
            </div>
            <div className="cursor-pointer" onClick={nextPageHandler}>
              <CaretRight
                color="#71717A"
                className={`${currentPage >= totalPages ? 'hidden' : ''}`}
              />
            </div>
            <div className="cursor-pointer">
              <CaretDoubleRight
                color="#71717A"
                onClick={lastPageHandler}
                className={`${currentPage >= totalPages ? 'hidden' : ''}`}
              />
            </div>
          </div>
          <Button
            type="submit"
            className="flex flex-row bg-base-brand px-4 py-2"
            disabled={isSubmitting}
            contentWrapperClassName="gap-x-2 items-center"
          >
            <span>{singleAssessment.submitButton}</span>
            {isSubmitting && <BasicSpinner className="!m-0 h-[1rem] w-[1rem] text-zinc-50" />}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default QuizFormComponent;
