import { Link } from 'react-router-dom';
import ActionResponse from '../../../../components/ActionResponse';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';

const UserConfirmAccountMessage = () => {
  const data: any = useStrapiDataHelper();
  const attributes = data?.login.data.attributes;
  const { UserConfirmAccountSuccess } = attributes;
  const { subTitle, onClickLabel, title } = UserConfirmAccountSuccess;
  const subTitleSplit = subTitle.split('{{onClick}}');
  return (
    <div className="flex w-full flex-col items-center pt-[6.5vw]">
      <ActionResponse
        title={title}
        subtitle={
          <>
            {subTitleSplit[0]}{' '}
            <Link to="/auth/login" className="underline">
              {onClickLabel}
            </Link>{' '}
            {subTitleSplit[1]}
          </>
        }
        variant="success"
      />
    </div>
  );
};

export default UserConfirmAccountMessage;
