import toast from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { ToastVariant } from '../../../types';
import { TOAST_VARIANT_DURATION_MAP } from '../constants/toast';

type ShowToastParams = {
  variant: ToastVariant;
  title: string;
  body?: string;
  id?: string;
};

const showToast = (params: ShowToastParams) => {
  const { variant, title, id, body } = params;

  const toastId = toast.custom(
    (t) => <Toast toastInstance={t} variant={variant} Title={title} SubTitle={body} />,
    {
      duration: TOAST_VARIANT_DURATION_MAP[variant],
      id: id,
    }
  );

  return toastId;
};

export { showToast };
