import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React from 'react';
import cn from '../../../../utils/cn';

type LanguageItemProps = {
  className?: string;
  onSelect?: ((event: Event) => void) | undefined;
};

const LanguageItem = (props: React.PropsWithChildren<LanguageItemProps>) => {
  const { children, className, onSelect } = props;

  return (
    <DropdownMenu.Item
      className={cn(
        'w-full rounded-md px-2.5 py-2 hover:focus:bg-zinc-700 hover:focus:outline-none',
        className
      )}
      onSelect={onSelect}
    >
      {children}
    </DropdownMenu.Item>
  );
};

export { LanguageItem };
