export const UPLOAD_REQUEST_TYPE = {
  MULTI_PART: 'multi_part',
  SINGLE_OBJECT: 'single_object',
};
export const MEDIA_UPLOAD_FORMAT = {
  IMAGE: ['png', 'jpg', 'jpeg', 'webp'],
  VIDEO: ['mov', 'mp4', 'mpeg'],
  DOCUMENT: ['pdf'],
  AUDIO: ['mp3', 'mpeg'],
};
export const MEDIA_TYPE_FORMAT: any = {
  audio: 'Audio',
  video: 'Video',
  application: 'Document',
  image: 'Image',
  vtt: 'Vtt',
};
