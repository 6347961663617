import { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router-dom';
import { Loader } from './components/Loader/Loader';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ushgApiConfig } from './config/aws-config';
import { useAmplifyReduxBridge } from './features/auth';
import { configureAmplifyAPI } from './features/auth/utils';
import { useAppDispatch, useHasAccess, useReduxAuthState } from './hooks';
import useStrapiData from './hooks/use-strapi-data';
import { AmplifyAPIOptions } from './types';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ChimeMessagingServiceProvider } from './features/messaging/provider';
import { resetMessagingState } from './features/messaging/slices';
import InviteFriendModal from './features/shared/components/AppSideBar/components/InviteFriendModal';
import { resetChimeClient } from './features/messaging/helpers';
import useFetchSubscription from './features/shared/hooks/useFetchSubscription';
import { ERROR_TYPES } from './constants/error-types.constants';
import { USHG_HQ_APP_ROLE } from './constants';
import ErrorPage from './components/ErrorPage/ErrorPage';
import { SOMETHING_WENT_WRONG } from './features/shared/constants/error';
import ApplicationErrorBoundary from './components/ErrorBoundary/ApplicationErrorBoundary';
import { useGetAnnouncementQuery } from './features/shared/hooks';
import usePendingAccountProvision from './hooks/use-pending-account-provision';
import useCurrentAuthenticatedUser from './hooks/use-current-authenticated-user';
import SkipToMain from './features/shared/components/SkipToMain';
import { useLanguage } from './features/shared/context/languageContext';

// Amplify API config
const amplifyApiConfig = ushgApiConfig as AmplifyAPIOptions;
configureAmplifyAPI(amplifyApiConfig);

function App() {
  const auth = useReduxAuthState();
  const appDispatch = useAppDispatch();
  const { language } = useLanguage();

  // Whether the account provision is yet to be completed
  const pendingAccountProvision = usePendingAccountProvision();

  const currentAuthenticatedUserQuery = useCurrentAuthenticatedUser({
    bypassCache: true,
    enabled: pendingAccountProvision,
  });

  const isCurrentAuthenticatedUserQueryLoading =
    currentAuthenticatedUserQuery.isLoading && pendingAccountProvision;

  // Load up-to-date user information from cognito if the user account is not provisioned

  // Load up-to-date user information from cognito if the user account is not provisioned

  const isSubscriptionAccessible = useHasAccess([
    USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
    USHG_HQ_APP_ROLE.INDIVIDUAL_USER,
  ]);

  // const isUSHGAdmin = useHasAccess([USHG_HQ_APP_ROLE.USHG_ADMIN]);

  const isAuthStateInitializing = auth.isInitializing;
  const isFetchSubscriptionEnabled =
    isSubscriptionAccessible && !pendingAccountProvision && !isAuthStateInitializing;

  const subscriptionDetails = useFetchSubscription({
    enabled: isFetchSubscriptionEnabled,
  });

  const isFetchAnnouncementEnabled =
    auth.isAuthenticated && !pendingAccountProvision && !isAuthStateInitializing;

  // We need the announcement as soon as possible
  useGetAnnouncementQuery({
    // Enabled when the user is authenticated
    enabled: isFetchAnnouncementEnabled,
  });

  useAmplifyReduxBridge();

  // getting data from strapi for login and registration during app mount
  const { loading: isStrapiDataLoading, error } = useStrapiData(language);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      // Send the reset messaging state action when the user logs out
      appDispatch(resetMessagingState());
      // Reset chime client
      resetChimeClient();
    }
  }, [appDispatch, auth.isAuthenticated]);

  const isLoadingSubscriptionData = isFetchSubscriptionEnabled
    ? subscriptionDetails.isLoading
    : false;

  if (
    isStrapiDataLoading ||
    isAuthStateInitializing ||
    isLoadingSubscriptionData ||
    isCurrentAuthenticatedUserQueryLoading
  ) {
    return <Loader />;
  }

  if (error) {
    console.error('Strapi Main page error', error);
  }

  if (subscriptionDetails.isError) {
    return (
      <ErrorPage
        refetch={subscriptionDetails.refetch}
        isRefetching={subscriptionDetails.isRefetching}
        allowsRefetch={true}
        type={ERROR_TYPES.SOMETHING_WENT_WRONG}
        message={SOMETHING_WENT_WRONG}
      />
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ApplicationErrorBoundary} resetKeys={[location.pathname]}>
      {/* Skip To Main Content Link */}
      <SkipToMain />
      <ChimeMessagingServiceProvider>
        <Outlet />
      </ChimeMessagingServiceProvider>
      <Toaster position="bottom-right" gutter={5} />
      <InviteFriendModal />
      {/* Will be excluded from build when process.env.mode === "production" */}
      {/* Uncomment if required */}
      {/* <ReactQueryDevtools initialIsOpen={false} panelPosition="left" position="top-left" /> */}
    </ErrorBoundary>
  );
}

export default App;
