import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from '@apollo/client';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_STRAPI_GRAPH_URL });

const authLink = new ApolloLink((operation, forward) => {
  // Read authorization token from env.
  const token = process.env.REACT_APP_STRAPI_READ_ONLY_TOKEN;

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink), // Chain it with the HttpLink
  cache: new InMemoryCache(),
});

export default client;
