import { useQuery } from '@tanstack/react-query';
import { USERS_API_PATHS } from '../constants';
import { QUERY_STALE_TIME } from '../constants/react-query';
import { getAnnouncement } from '../api/announcement';

type Props = {
  enabled?: boolean;
};

const useGetAnnouncementQuery = (props?: Props) => {
  const { enabled = true } = props ?? {};

  const query = useQuery({
    queryKey: [
      {
        scope: 'shared',
        item: 'announcement',
      },
    ],
    queryFn: async () => {
      const response = await getAnnouncement();
      return response;
    },
    staleTime: QUERY_STALE_TIME[USERS_API_PATHS.GET_ANNOUNCEMENT],
    refetchOnMount: false,
    enabled,
  });

  return query;
};

export default useGetAnnouncementQuery;
