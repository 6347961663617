import { ONBOARDING_API_PATHS } from '../constants';
import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { InviteEnterprise, InviteTeamResponse } from '../types';

/**
 * Invite enterprise
 */
const inviteEnterpise = async (args: InviteEnterprise): Promise<InviteTeamResponse> => {
  const path = ONBOARDING_API_PATHS.INVITE_TEAM.INVITE_TEAM;

  const body = args;
  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body,
    response: true,
    withCredentials: true,
  });
  return response.data;
};

export { inviteEnterpise };
