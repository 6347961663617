import type { CognitoUser } from '@aws-amplify/auth';
import { useState } from 'react';
import { CognitoAuthChallenge } from '../../../../../constants';
import {
  cognitoUserContext,
  loginAuthChallengeContext,
  userLoginCredentialsContext,
} from '../../../context';
import UserLoginMain from './UserLoginMain';

type AuthChallenge = keyof typeof CognitoAuthChallenge;

const UserLoginMainContainer = () => {
  // state
  const [loginCredentials, setLoginCredentials] = useState<{
    username: string;
    password: string;
  } | null>(null);

  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [authChallenge, setAuthChallenge] = useState<AuthChallenge | null>(null);
  // state

  return (
    <userLoginCredentialsContext.Provider value={{ loginCredentials, setLoginCredentials }}>
      <cognitoUserContext.Provider
        value={{
          cognitoUser,
          setCognitoUser,
        }}
      >
        <loginAuthChallengeContext.Provider value={{ authChallenge, setAuthChallenge }}>
          <UserLoginMain />
        </loginAuthChallengeContext.Provider>
      </cognitoUserContext.Provider>
    </userLoginCredentialsContext.Provider>
  );
};

export default UserLoginMainContainer;
