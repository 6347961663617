import { format } from 'date-fns';
import { X } from 'phosphor-react';
import Popover from '../../../../components/Popover';
import { CalendarItem as CalendarItemType } from '../../types';
import CalendarItem from './CalendarItem';
import { getLocale } from '../../../../utils';
import { useLanguage } from '../../../shared/context/languageContext';

interface Props {
  calendarDate: Date;
  calendarItems: CalendarItemType[];
  // To display the close icon to close the popover
  isInPopover?: boolean;
}

const CalendarItemsList = (props: Props) => {
  const { calendarDate, calendarItems, isInPopover = false } = props;
  const { language } = useLanguage();

  const formattedCalendarDate = format(calendarDate, 'MMM dd, yyyy', {
    locale: getLocale(language),
  });

  return (
    <article className="flex flex-col gap-1.5">
      <div className="flex items-center justify-between text-zinc-400">
        <span className="text-xs font-normal">{formattedCalendarDate}</span>
        {isInPopover && (
          <Popover.Close>
            <X className="cursor-pointer text-inherit" color="currentColor" size={16} />
          </Popover.Close>
        )}
      </div>
      <div className="flex flex-col gap-1.5">
        {calendarItems.map((calendarItem) => {
          return <CalendarItem calendarItem={calendarItem} key={calendarItem.id} />;
        })}
      </div>
    </article>
  );
};

export default CalendarItemsList;
