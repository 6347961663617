import { filterType } from '../types/Filter.Helper';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getCoursesById } from '../api/courses';
import { useEffect, useState } from 'react';
import { PAGE_LENGTH } from '../constants/data';
import { UsersStatsFilter } from '../../teams/types/interface';

type useFetchCoursesQueryProps = {
  tab: string;
  filters: filterType[];
  id?: Partial<UsersStatsFilter>;
};

const nextPageParam = (lastPage: any) =>
  lastPage.page + lastPage.pageSize < lastPage.totalCount ? lastPage.page + PAGE_LENGTH : undefined;

const useFetchCoursesByIdQuery = ({ tab, filters, id }: useFetchCoursesQueryProps) => {
  const fetchCourses = ({ pageParam = 0 }) => {
    return getCoursesById(filters, tab, pageParam, id);
  };
  const [totalCount, setTotalCount] = useState();
  const { isLoading, error, data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery(['courses', tab, id, filters], fetchCourses, {
      getNextPageParam: nextPageParam,
    });

  useEffect(() => {
    if (data) {
      setTotalCount(data.pages[0]?.totalCount);
    }
  }, [data]);

  return { isLoading, error, data, fetchNextPage, totalCount, isFetchingNextPage, hasNextPage };
};

export default useFetchCoursesByIdQuery;
