import { useMemo, useState } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { BsLayoutThreeColumns } from 'react-icons/bs';
import GenericNameCell from './TableCell/GenericNameCell';
import CommonTextCell from './TableCell/CommonTextCell';
import { ListSpeakersResults } from '../../../types/GetSpeakerApiResponse';
import RowActionsPopUp from './RowActionsPopUp';
import ListSpeakersTableHeaderRow from './ListSpeakersTableHeaderRow';
import TableDataRow from '../../../../../components/DataTables/TableDataRow';
import { useSpeakerStrapiData } from '../../../hooks/useSpeakerStrapiData';
import Dialog from '../../../../../components/Dialog';
import SpeakerModal from '../../Modal/SpeakerModal';
import { SpeakerViewModalProps } from '../../../../../features/courses/components/SingleCourseView/types/SingleCourseTypes';
interface Props {
  className?: string;
  data: ListSpeakersResults[];
}

const columnHelper = createColumnHelper<ListSpeakersResults>();

const ListSpeakerTable = ({ className, data }: Props) => {
  const [modalState, setModalState] = useState<boolean>(false);
  const [modalData, setModalData] = useState<SpeakerViewModalProps>();
  const {
    tableHeaders: { speaker, description, position, courses },
  } = useSpeakerStrapiData();

  const memoizedData = useMemo(() => data, [data]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('firstName', {
        header: speaker,
        cell: ({ getValue, row }) => <GenericNameCell name={getValue() as string} row={row} />,
        id: 'fullName',
      }),
      columnHelper.accessor('bio', {
        header: description,
        cell: ({ getValue }) => (
          <CommonTextCell
            spanClassName="line-clamp-1"
            text={getValue() as string}
            isLowercase={true}
          />
        ),
      }),
      columnHelper.accessor('jobTitle', {
        header: position,
        cell: ({ getValue }) => <CommonTextCell text={getValue() as string} />,
      }),
      columnHelper.accessor('courses', {
        header: courses,
        cell: ({ getValue }) => <CommonTextCell text={getValue()} isCoursesDisplay={true} />,
      }),
      columnHelper.display({
        cell: ({ row }) => (
          <div onClick={(event) => event.stopPropagation()}>
            <RowActionsPopUp key={row.id} speakerData={row.original} />
          </div>
        ),
        header: () => <BsLayoutThreeColumns />,
        id: 'options',
        enableSorting: false,
      }),
    ],
    [speaker, description, position, courses]
  );

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiSort: true,
    data: memoizedData,
  });

  const renderModal = (speaker: ListSpeakersResults) => {
    const { fullName, picture, courses, jobTitle, bio } = speaker;
    const speakerViewProps: SpeakerViewModalProps = {
      speakerName: fullName,
      picture: picture,
      numberOfCourses: courses,
      speakerDesignation: jobTitle,
      speakerDescription: bio,
    };
    setModalData(speakerViewProps);
    setModalState((prev) => !prev);
  };

  return (
    <table className={clsx('w-full', className)}>
      <thead className="w-full">
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr
              key={headerGroup.id}
              className="grid w-full grid-cols-[minmax(10rem,1fr)_minmax(10rem,2fr)_minmax(6.25rem,1fr)_minmax(6.25rem,1fr)_0.25fr]"
            >
              {headerGroup.headers.map((header) => (
                <ListSpeakersTableHeaderRow header={header} key={header.id} isSortable={false} />
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody className="w-full">
        {getRowModel().rows.map((row) => (
          <TableDataRow
            className="grid w-full cursor-pointer grid-cols-[minmax(10rem,1fr)_minmax(10rem,2fr)_minmax(6.25rem,1fr)_minmax(6.25rem,1fr)_0.25fr] hover:bg-page-bg-dark/20"
            tdClassName="flex items-center"
            row={row}
            key={row.id}
            onClick={() => renderModal(row.original)}
          />
        ))}
        <Dialog
          open={modalState}
          onOpenChange={() => setModalState(false)}
          triggerClassName="flex justify-center basis-0 grow-0"
          Content={<SpeakerModal {...modalData} />}
          containerClassName="max-w-7xl"
        ></Dialog>
      </tbody>
    </table>
  );
};

export default ListSpeakerTable;
