import { useNavigate } from 'react-router-dom';
import Alert from '../../../../../../components/Alert';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { useResendAccountConfirmationCode } from '../../../../hooks/use-resend-account-confirmation-code';
import { LoginExceptionCode } from '../../../../types';

interface Props {
  code: LoginExceptionCode;
  username: string;
}

const LoginExceptionAlert = ({ code, username }: Props) => {
  const navigate = useNavigate();
  const data: any = useStrapiDataHelper();

  const strapiErrorMessage = data.errorsMap;
  const strapiErrorActionContent = data.errorActionContentMap;

  const navigateToForgotPasswordPage = () => {
    navigate('/auth/forgot-password');
  };
  const { resendConfirmationCodeHandler } = useResendAccountConfirmationCode();
  const navigateToContactUsPage = () => {
    navigate('/contact-us');
  };

  switch (code) {
    case 'NotAuthorizedException': {
      const errorCode = 'NotAuthorizedException';
      const errorMessage = strapiErrorMessage[errorCode];
      const errorActionContent = strapiErrorActionContent[errorCode];
      return (
        <Alert
          variant="error"
          message={errorMessage}
          action={{
            content: errorActionContent,
            onAction: navigateToForgotPasswordPage,
          }}
        ></Alert>
      );
    }
    case 'UserNotConfirmedException': {
      const errorCode = 'UserNotConfirmedException';
      const errorMessage = strapiErrorMessage[errorCode];
      const errorActionContent = strapiErrorActionContent[errorCode];
      return (
        <Alert
          variant="error"
          message={errorMessage}
          action={{
            content: errorActionContent,
            onAction: () => resendConfirmationCodeHandler(username),
          }}
        ></Alert>
      );
    }
    case 'UnHandledLoginError': {
      const errorCode = 'UnHandledLoginError';
      const errorMessage = strapiErrorMessage[errorCode];
      const errorActionContent = strapiErrorActionContent[errorCode];
      return (
        <Alert
          variant="error"
          message={errorMessage}
          action={{
            content: errorActionContent,
            onAction: navigateToContactUsPage,
          }}
        ></Alert>
      );
    }
  }
};

export default LoginExceptionAlert;
