import { submitFeedbackFormType } from '../../../../types/SingleCourseTypes';
import RatingComponent from '../RatingComponent/RatingComponent';

interface Props {
  submitFormHandler: (data: submitFeedbackFormType) => void;
  isSubmitting: boolean;
}

const RatingModal = ({ submitFormHandler, isSubmitting }: Props) => {
  return (
    <div className="mx-6 flex h-max max-h-[80vh] flex-col overflow-scroll rounded-lg border border-zinc-900 bg-card-bg p-6 text-white shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)]">
      <RatingComponent submitFormHandler={submitFormHandler} isSubmitting={isSubmitting} />
    </div>
  );
};

export default RatingModal;
