import { useQuery } from '@tanstack/react-query';
import { getUserStats } from '../api/individual';
import { GET_INDIVIDUAL_QUERY_KEY, STALE_TIME } from '../constants/common';

interface Props {
  username: string;
}

const useGetUserStatsQuery = ({ username }: Props) => {
  const { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching } =
    useQuery(
      [GET_INDIVIDUAL_QUERY_KEY, username],
      async () => {
        return getUserStats(username);
      },
      {
        keepPreviousData: true,
        staleTime: STALE_TIME,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching };
};

export default useGetUserStatsQuery;
