import { useMemo } from 'react';
import InfiniteScrollV1 from '../../../../../../components/InfiniteScroll/InfiniteScrollV1';
import cn from '../../../../../../utils/cn';
import useListAllLearningJourneys from '../../../../hooks/use-list-all-learning-journeys';
import useStrapiLearningJourneyData from '../../../../hooks/use-strapi-learning-journey-data';
import LearningJourneyCard from './LearningJourneyCard';
import EmptyState from '../../../EmptyState';

interface Props {
  className?: string;
}

const LearningJourneysList = (props: Props) => {
  const { className } = props;

  // strapi
  const { ListAllLearningJourneys: cms } = useStrapiLearningJourneyData();

  // tanstack query
  const query = useListAllLearningJourneys();

  // derived state
  const hasData = query.data && query.data.pages.length > 0;
  const learningJourneys = useMemo(() => {
    if (!hasData) {
      return [];
    }

    return query.data.pages.map((page) => [...page.data]).flat();
  }, [query.data, hasData]);

  if (hasData && learningJourneys.length === 0) {
    return (
      <EmptyState
        action={{
          message: cms.noJourneysActionLabel,
          path: '/courses?view=all',
        }}
        message={cms.noJourneysMessage}
      />
    );
  }

  return (
    <InfiniteScrollV1
      className="relative items-stretch p-4 xs:p-5 sm:p-6 md:p-7 lg:px-9"
      fetchNextPage={query.fetchNextPage}
      isError={query.isError}
      isFetchingNextPage={query.isFetchingNextPage}
      isInitialLoading={query.isLoading}
      hasNextPage={query.hasNextPage ?? false}
      allItemsFetchedMessage={cms.allLearningJourneysFetched}
      loadingMoreItemsMessage={cms.loadingMoreLearningJourneys}
      errorMessage={cms.errorLoadingLearningJourneys}
      refetch={query.refetch}
      isRefetching={query.isRefetching}
      hasData={hasData}
      allowsRefetch={true}
      isMoreThanOnePage={query.data?.pages && query.data.pages.length > 1}
      rootMargin="240px"
      showReachedEndMessage={false}
    >
      <ul
        className={cn(
          'grid grid-cols-[repeat(auto-fill,minmax(var(--card-width),1fr))] items-stretch gap-3.5 [--card-width:calc(var(--min-thumbnail-width)+2*var(--card-padding-x))] lg:gap-4',
          '[--min-thumbnail-width:180px] xxs:[--min-thumbnail-width:200px] md:[--min-thumbnail-width:240px] lg:[--min-thumbnail-width:256px] xl:[--min-thumbnail-width:316px]',
          '[--card-padding-y:1.375rem] sm:[--card-padding-y:1.5rem] md:[--card-padding-y:1.875rem]',
          '[--card-padding-x:1.375rem] sm:[--card-padding-x:1.5rem] md:[--card-padding-x:1.875rem]',
          '[--min-thumbnail-height:calc(var(--min-thumbnail-width)/var(--lj-card-thumbnail-aspect-ratio))]',
          '[--lj-card-thumbnail-aspect-ratio:800/300]',
          className
        )}
      >
        {learningJourneys.map((learningJourney) => {
          return (
            <li key={learningJourney.id} className="flex w-full items-stretch">
              <LearningJourneyCard learningJourney={learningJourney} />
            </li>
          );
        })}
      </ul>
    </InfiniteScrollV1>
  );
};

export default LearningJourneysList;
