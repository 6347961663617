import { useCallback } from 'react';
import { MFAMethod } from '../../../../../../constants';
import { useSetUserPreferredMFAMutation } from '../../../../../shared/hooks';
import { useStrapiSettingsData } from '../../../../hooks/useStrapiSettingsData';
import ActionDialogContent from '../../components/ActionDialogContent';

type Props = {
  setShowDialog: (show: boolean) => void;
};

const DisableMFADialogContent = (props: Props) => {
  const { setShowDialog } = props;

  //   Mutations
  const setUserPreferredMFAMutation = useSetUserPreferredMFAMutation();
  //   Mutations

  // strapi content

  const {
    mfa: { DisableMFADialog: disableMFADialogCMS },
  } = useStrapiSettingsData();

  // strapi content

  //   actions

  const cancelAction = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const disableSMSMFA = useCallback(async () => {
    try {
      await setUserPreferredMFAMutation.mutateAsync({
        mfaMethod: MFAMethod.NOMFA,
      });

      setShowDialog(false);
    } catch (error) {
      console.error('Error updating MFA configuration', error);
    }
  }, [setShowDialog, setUserPreferredMFAMutation]);

  //   actions

  return (
    <ActionDialogContent
      heading={disableMFADialogCMS.heading}
      Description={disableMFADialogCMS.description}
      Content={disableMFADialogCMS.content ? disableMFADialogCMS.content : undefined}
      primaryAction={{
        handler: disableSMSMFA,
        label: disableMFADialogCMS.disableMFABtnLabel,
        isLoading: setUserPreferredMFAMutation.isLoading,
      }}
      secondaryAction={{
        handler: cancelAction,
        label: disableMFADialogCMS.cancelBtnLabel,
      }}
    ></ActionDialogContent>
  );
};

export default DisableMFADialogContent;
