import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;
export const useSpeakerStrapiData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);

  const strapiSpeakersHQAdmin = data.speaker.data.attributes.HQ_ADMIN;
  let table;
  let topNavData;
  let modalStrapiData;
  let deleteSpeakerStrapiData;
  let toastMessage;
  let errorMessages;
  for (let i = 0; i <= strapiSpeakersHQAdmin?.length; i++) {
    if (strapiSpeakersHQAdmin[i]?.__typename === 'ComponentSpeakersSpeakersTable') {
      table = (strapiSpeakersHQAdmin ?? [])[i];
    } else if (strapiSpeakersHQAdmin[i]?.__typename === 'ComponentSpeakersTopNav') {
      topNavData = (strapiSpeakersHQAdmin ?? [])[i];
    } else if (strapiSpeakersHQAdmin[i]?.__typename === 'ComponentSpeakersCreateAndEditModal') {
      modalStrapiData = (strapiSpeakersHQAdmin ?? [])[i];
    } else if (strapiSpeakersHQAdmin[i]?.__typename === 'ComponentSpeakersToastMessage') {
      toastMessage = (strapiSpeakersHQAdmin ?? [])[i];
    } else if (strapiSpeakersHQAdmin[i]?.__typename === 'ComponentSpeakersDeleteSpeaker') {
      deleteSpeakerStrapiData = (strapiSpeakersHQAdmin ?? [])[i];
    } else if (strapiSpeakersHQAdmin[i]?.__typename === 'ComponentSpeakersErrorMessage') {
      errorMessages = (strapiSpeakersHQAdmin ?? [])[i];
    }
  }
  const { TableHeaders: tableHeaders, tableProps } = table;
  return {
    tableHeaders,
    errorMessages,
    tableProps,
    topNavData,
    modalStrapiData,
    deleteSpeakerStrapiData,
    toastMessage,
  };
};
