import { Navigate, RouteObject } from 'react-router-dom';
import loginRoutes from './loginRoutes';
import registerRoutes from './registerRoutes';
import individualRegisterRoutes from './individualRegisterRoutes';

const routes: RouteObject[] = [
  {
    path: 'auth',
    children: [
      {
        index: true,
        element: <Navigate to="login" />,
      },
      loginRoutes,
      registerRoutes,
      individualRegisterRoutes,
    ],
  },
];

export default routes;
