import { Outlet } from 'react-router-dom';
import AuthIllustration from '../../../components/AuthIllustration';
import { Navbar } from '../../../components/Navbar';
import HelpWidget from '../../shared/components/HelpWidget/HelpWidget';
import SEOHelmetWrapper from '../../../components/SEOHelmet/SEOHelmet';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
import { SEOProps } from '../../../types';

// Base Layout to be used for login, forgot password and other
// login related pages
const LoginBaseLayout = () => {
  // strapi
  const data = useStrapiDataHelper();
  const loginPageSEO = data.login.data.attributes.Seo as SEOProps;
  // strapi

  return (
    <div className="flex min-h-screen w-screen flex-col bg-page-bg-dark text-white">
      {/* SEOHelmetWrapper Component: Dynamically sets the document's head elements such as title, meta description, and other meta tags for Search Engine Optimization (SEO) purposes. */}
      {loginPageSEO && <SEOHelmetWrapper seo={loginPageSEO} />}
      <Navbar></Navbar>
      <div className="grid h-full w-full grow grid-cols-1 grid-rows-1 justify-between p-5 xl:grid-cols-[2.5fr,2fr] xl:p-0">
        <Outlet />
        <section className="hidden h-full grow justify-end xl:flex">
          <AuthIllustration className="m-0"></AuthIllustration>
        </section>
      </div>
      <HelpWidget triggerClassName="fixed left-5 bottom-7 z-50"></HelpWidget>
    </div>
  );
};

export default LoginBaseLayout;
