import { SortDirection } from '@tanstack/react-table';
import { SortAscending, SortDescending } from 'phosphor-react';

interface Props {
  sortDirection: SortDirection | false;
  // would be displayed when there is no sorting
  fallback: React.ReactNode;
}

const DynamicSortIcon = ({ sortDirection, fallback }: Props) => {
  switch (sortDirection) {
    case false: {
      return <>{fallback}</>;
    }
    case 'asc': {
      return <SortAscending size={16} color="currentColor" className="text-zinc-100" />;
    }
    case 'desc': {
      return <SortDescending size={16} color="currentColor" className="text-zinc-100" />;
    }
  }
};

export default DynamicSortIcon;
