import { CognitoUserAttributes } from '../constants';
import { AccountProvisionStatus } from '../constants/user';
import useReduxAuthState from './use-redux-auth-state';

const useAccountProvisionStatus = () => {
  const auth = useReduxAuthState();

  const userProvisionState = auth.user?.[CognitoUserAttributes.ACCOUNT_PROVISION_STATUS];
  return userProvisionState as keyof typeof AccountProvisionStatus | undefined;
};

export default useAccountProvisionStatus;
