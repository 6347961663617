import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;
export const useStrapiSettingsData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const strapiSettingsCommon = data.setting.data.attributes.COMMON;
  const strapiSettingsEnterpriseAdmin = data.setting.data.attributes.ENTERPRISE_ADMIN;
  const strapiSettingsHQAdmin = data.setting.data.attributes.HQ_ADMIN;

  let tabs;
  let profile;
  let billing;
  let password;
  let topNav;
  let toastMessage;
  let mfa;
  let enterpriseAdminMfaMandate;
  let hqAdminPlatformMaintenanceMode;
  let hqAdminPlatformAnnouncement;

  for (let i = 0; i <= strapiSettingsCommon?.length; i++) {
    if (strapiSettingsCommon[i]?.__typename === 'ComponentSettingSettingsTab') {
      tabs = (strapiSettingsCommon ?? [])[i];
    } else if (strapiSettingsCommon[i]?.__typename === 'ComponentOnBoardingProfile') {
      profile = (strapiSettingsCommon ?? [])[i];
    } else if (strapiSettingsCommon[i]?.__typename === 'ComponentSettingBilling') {
      billing = (strapiSettingsCommon ?? [])[i];
    } else if (strapiSettingsCommon[i]?.__typename === 'ComponentSettingPassword') {
      password = (strapiSettingsCommon ?? [])[i];
    } else if (strapiSettingsCommon[i]?.__typename === 'ComponentSettingTopNav') {
      topNav = (strapiSettingsCommon ?? [])[i];
    } else if (strapiSettingsCommon[i]?.__typename === 'ComponentSettingToastMessages') {
      toastMessage = (strapiSettingsCommon ?? [])[i];
    } else if (strapiSettingsCommon[i]?.__typename === 'ComponentSettingsSecurityMfa') {
      mfa = (strapiSettingsCommon ?? [])[i];
    }
  }

  for (let i = 0; i <= strapiSettingsEnterpriseAdmin?.length; i++) {
    if (strapiSettingsEnterpriseAdmin[i]?.__typename === 'ComponentSettingOrgMfaMandate') {
      enterpriseAdminMfaMandate = (strapiSettingsEnterpriseAdmin ?? [])[i];
    }
  }

  for (let i = 0; i <= strapiSettingsHQAdmin?.length; i++) {
    if (strapiSettingsHQAdmin[i]?.__typename === 'ComponentSettingsPlatformMainteMode') {
      hqAdminPlatformMaintenanceMode = (strapiSettingsHQAdmin ?? [])[i];
    }
    if (strapiSettingsHQAdmin[i]?.__typename === 'ComponentSettingsPlatformAnnouncement') {
      hqAdminPlatformAnnouncement = (strapiSettingsHQAdmin ?? [])[i];
    }
  }

  const {
    billingHistory,
    currentPlan,
    paymentMethod,
    changePlan,
    manageActiveLicence,
    CanelSubscription,
    CancelSubscriptionReasons,
  } = billing;

  return {
    toastMessage,
    tabs,
    topNav,
    profile,
    billingHistory,
    currentPlan,
    paymentMethod,
    password,
    changePlanStrapiData: changePlan,
    manageActiveLicence,
    CanelSubscription,
    CancelSubscriptionReasons,
    mfa,
    enterpriseAdminMfaMandate,
    hqAdminPlatformMaintenanceMode,
    hqAdminPlatformAnnouncement,
  };
};
