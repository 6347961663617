import React from 'react';

interface Props {
  badgesEarned: number;
}

const BadgesEarnedCell = ({ badgesEarned }: Props) => {
  return <span className="whitespace-nowrap">{badgesEarned}</span>;
};

export default BadgesEarnedCell;
