import { gql } from '@apollo/client';

export const OrganizationFragment = gql`
  fragment OrganizationFragment on OrganizationEntityResponse {
    data {
      attributes {
        ToastMessages {
          inviteFailed
          inviteSuccess
        }
        TopNav {
          organisationTitle
          individualTitle
          inviteOrganizationButton
        }
        OrganisationTable {
          TableProps {
            allStatusLabel
            inRiskStatusLabel
            noDataLabel
            healthyStatusLabel
            statusPlaceholder
          }
          TableHeader {
            membersTableHeader
            healthRiskTableHeader
            emailTableHeader
            departmentTableHeader
            organisationTableHeader
            paymentModeTableHeader
          }
        }
        IndividualTable {
          emailTableHeader
          paymentModeTableHeader
          individualTableHeader
          healthRiskTableHeader
          signUpTableHeader
        }
        SingleView {
          Statistics {
            courseCompletedCardLabel
            averageRatingCardLabel
            engagementCardLabel
            churnRankLabel
            title
          }
          TopNav {
            backButton
            departmentLabel
          }
          TableHeadings {
            teamTableHeader
            membersTableHeader
            billingTableHeader
          }
        }
        ErrorMessages {
          emailRequired
          emailMinError
          emailMinValue
          emailMaxError
          emailMaxValue
          validEmailError
          departmentRequired
          departmentMinError
          departmentMinValue
          departmentMaxError
          departmentMaxValue
          organisationNameRequired
          organisationNameMinError
          organisationNameMinValue
          organisationNameMaxValue
          organisationNameMaxError
          activeLearnersRequired
          activeLearnersMinError
          activeLearnersMinValue
          activeLearnersMaxError
        }
        InviteOrganizationModal {
          title
          InviteButton
          cancelButton
          emailLabel
          departmentLabel
          organisationNameLabel
          activeLearnersLabel
          organisationSizeLabel
          discountPercentageLabel
          emailPlaceholder
          departmentPlaceholder
          organisationNamePlaceholder
          activeLearnersPlaceholder
          discountPercentagePlaceholder
          isAnuallyCheckBoxLabel
        }
      }
    }
  }
`;
