import clsx from 'clsx';
import { PropsWithChildren } from 'react';
interface FormFieldWrapperProps {
  className?: string;
}

const FormFieldWrapper = ({ children, className }: PropsWithChildren<FormFieldWrapperProps>) => {
  return <div className={clsx('flex flex-col gap-2', className)}>{children}</div>;
};

export default FormFieldWrapper;
