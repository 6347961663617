import { ApolloError, useQuery } from '@apollo/client';
import React from 'react';
import toast from 'react-hot-toast';
import { Toast } from '../components/Toast';
import client from '../config/strapi-config';
import { setStrapiData, setLoading } from '../features/auth/slices/strapi.slice';
import { STRAPI_STALE_TIME } from '../features/shared/constants';
import Query from '../strapiQueries/Query';
import useAppDispatch from './use-app-dispatch';
import { ReportTooltipDataType } from '../features/shared/types';

const useStrapiData = (locale: string) => {
  const dispatch = useAppDispatch();

  const handleQueryCompletion = (data: any, loading: boolean) => {
    const errorsMap: Record<string, string> = {};
    const errorActionContentMap: Record<string, string> = {}; // New map for action contents

    data.apiErrors?.data?.forEach(({ attributes }: any) => {
      const { errorCode, errorMessage, errorActionContent } = attributes as {
        errorCode: string;
        errorMessage: string;
        errorActionContent: string;
      };
      errorsMap[errorCode] = errorMessage;
      errorActionContentMap[errorCode] = errorActionContent;
    });

    const reportTooltipContentMap: ReportTooltipDataType = {};
    data.reportTooltipContents?.data?.forEach(({ attributes }: any) => {
      const { toolTipDescription, reportType, userPersona } = attributes as {
        toolTipDescription: string;
        reportType: string;
        userPersona: string;
      };

      // Initialize the nested object if it does not exist
      if (!reportTooltipContentMap[reportType]) {
        reportTooltipContentMap[reportType] = {};
      }

      // Assign the description to the corresponding userPersona under the correct reportType
      reportTooltipContentMap[reportType][userPersona] = toolTipDescription;
    });
    data = { ...data, errorsMap, errorActionContentMap, reportTooltipContentMap };
    dispatch(setStrapiData(data));
    dispatch(setLoading(loading));
  };

  const handleQueryError = async (error: ApolloError, locale: string): Promise<void> => {
    const fallbackDataModule = await import(`../strapiQueries/fallbackData${locale.toUpperCase()}`);
    const fallbackData = fallbackDataModule[`fallbackData${locale.toUpperCase()}`];

    dispatch(setStrapiData(fallbackData as any));
    toast.custom(
      (t) => (
        <Toast
          toastInstance={t}
          Title="Strapi Error "
          SubTitle={error.message + error.name}
          variant="error"
        />
      ),
      {
        id: 'StrapiError',
        duration: STRAPI_STALE_TIME,
      }
    );
  };
  const { loading, error } = useQuery(Query, {
    client: client,
    variables: { locale },
    onCompleted: async (data) => {
      handleQueryCompletion(data, loading);
    },
    onError: (error) => handleQueryError(error, locale),
  });

  return { loading, error };
};

export default useStrapiData;
