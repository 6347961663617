import { UserCard } from '../../../../components/Cards';
import { COGNITO_USER_ROLES, MANAGER } from '../../../../constants/userTypes';
import { Manager } from '../../types/interface';
import { RootState } from '../../../../store';
import { useAppSelector } from '../../../../hooks';

type ManagerCardProps = {
  managers: Manager[];
};
function ManagerCard({ managers }: ManagerCardProps) {
  const authStateSelector = (state: RootState) => state.auth;
  const { user } = useAppSelector(authStateSelector);
  const renderList = managers?.map((manager) => {
    const isClickable =
      user &&
      (user.email === manager.id ||
        user.role === COGNITO_USER_ROLES.LEADER ||
        user.role === COGNITO_USER_ROLES.MANAGER);
    const ManagerDetails = {
      type: MANAGER,
      details: {
        id: manager.id,
        name: manager.name,
        profileImage: manager.profileImage,
      },
    };
    const hasAccess = {
      hasAccess: !!isClickable,
      restrictionToast: {
        title: 'Not Authorized',
        message: 'You are not authorized to access this record',
      },
    };
    return (
      <UserCard
        key={manager.id}
        user={ManagerDetails}
        classNames={'min-w-[172px]'}
        hasAccess={hasAccess}
      />
    );
  });
  return <>{renderList}</>;
}

export default ManagerCard;
