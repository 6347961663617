import { useMemo } from 'react';
import { ErrorDisplay, NoDataDisplay } from '../../../../components/DataTables';
import ManagerCard from '../../components/ManagerCard/ManagerCard';
import MemberCard from '../../components/MembersCard/MemberCard';
import SubTeamCardWrapper from '../../components/SubTeamCard/SubTeamCardWrapper';
import TopNav from '../../components/TopNav/TopNav';
import LeaderTeamsDetailedViewCircleGroup from '../../components/UserCircle/LeaderTeamsDetailedViewCircleGroup';

import useEnterpriseLeaderTeamsQuery from '../../hooks/useEnterpriseLeaderTeamsQuery';
import useEnterpriseLeaderTeamUsersQuery from '../../hooks/useEnterpriseLeaderTeamUsersQuery';
import {
  PartialEnterpriseLeaderTeamManagerFilter,
  PartialEnterpriseLeaderTeamsFilter,
  PartialMostEarnedBadgesFilter,
} from '../../types/interface';

import useEnterpriseLeaderTeamsStatsQuery from '../../hooks/useEnterpriseLeaderTeamsStatsQuery';
import { BasicSpinner } from '../../../../components/Spinners';
import { FadeLoader } from 'react-spinners';
import useTeamNameQuery from '../../hooks/useTeamNameQuery';
import { ERROR_TYPES } from '../../../../constants/error-types.constants';
import { useStrapiTeamData } from '../../hooks/useStrapiTeamData';
import useMostEarnedBadgesQuery from '../../hooks/useMostEarnedBadgesQuery';
import clsx from 'clsx';
import MostEarnedBadgeCardDisplay from '../../common/MostEarnedBadgesCardDisplay';
import {
  DEFAULT_MOST_EARNED_BADGES_SIZE,
  DEFAULT_MOST_EARNED_BADGES_START,
} from '../../constants/singleteam';
import useStrapiBadgesData from '../../../badges/hooks/useStrapiBadgeData';
import { calcTimeString } from '../../../shared/helpers';
import { SideBarLayoutPageContentWrapper } from '../../../../components/Wrapper';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import { formatNumber } from '../../../../utils';
import pluralize from 'pluralize';
interface Props {
  id?: string;
  showBackButton?: boolean;
}
function SingleTeamView({ id, showBackButton }: Props) {
  const filters: PartialEnterpriseLeaderTeamsFilter = useMemo(() => {
    return {
      parentTeamId: id,
    };
  }, [id]);

  const badgesQueryParam: PartialMostEarnedBadgesFilter = useMemo(() => {
    return {
      teamId: id,
      start: DEFAULT_MOST_EARNED_BADGES_START,
      size: DEFAULT_MOST_EARNED_BADGES_SIZE,
    };
  }, [id]);

  const managerQueryParam: PartialEnterpriseLeaderTeamManagerFilter = useMemo(() => {
    return {
      teamId: id,
    };
  }, [id]);
  const teamName = useTeamNameQuery({
    teamId: parseInt(id as string),
  });
  const subTeamsData = useEnterpriseLeaderTeamsQuery({ filters });
  const usersData = useEnterpriseLeaderTeamUsersQuery({ managerQueryParam });
  const stats = useEnterpriseLeaderTeamsStatsQuery({ managerQueryParam });
  const mostEarnedBadges = useMostEarnedBadgesQuery({ badgesQueryParam });
  const { singleTeam } = useStrapiTeamData();

  const {
    singleTeamSubTeamLabel,
    singleTeamManagerLabel,
    singleTeamMemberLabel,
    singleTeamAvgSessionLabel,
    singleTeamCoursesAssignedLabel,
    singleTeamCoursesCompletionLabel,
    singleTeamCourseCompletedLabel,
    singleTeamMemberCountLabel,
    singleTeamMostPopularBadgesLabel,
    noTeamMembersMessage,
  } = singleTeam;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  let displayUsers;
  if (usersData.data?.members) {
    const users = usersData?.data?.members?.rows;
    displayUsers = [...users];

    if (users?.length > 4) {
      displayUsers = users?.slice(0, 4);
      const excessUserCount = users.length - 4;
      const dummyUser = {
        excessUser: true,
        count: excessUserCount,
      };
      displayUsers.push(dummyUser);
    }
  }
  const { errorMessages } = useStrapiBadgesData();
  return !stats.isLoading && !usersData.isLoading && !subTeamsData.isLoading ? (
    <div className="w-full">
      <TopNav
        teamId={parseInt(id as string)}
        title={stats?.data?.teamName || (teamName.data?.teamName as string)}
        isSingleTeamView={true}
        showBackButton={showBackButton}
        isSubTeam={teamName?.data?.isSubTeam}
      />
      {stats.isLoading ? (
        <div className="mt-4 flex min-h-[15.625rem] w-full items-center justify-center">
          <BasicSpinner className="text-white" />
        </div>
      ) : !stats.error && stats.data != undefined ? (
        <div className="grid grid-cols-1 bg-card-bg md:grid-cols-3">
          <div className="p-6">
            <div className="flex items-center gap-2">
              <LeaderTeamsDetailedViewCircleGroup displayUsers={displayUsers} />{' '}
              <div className="whitespace-nowrap text-end text-sm font-medium text-white">
                {usersData.data?.members?.count
                  ? pluralize(`${singleTeamMemberCountLabel}`, usersData.data?.members?.count, true)
                  : '' + `${singleTeamMemberCountLabel}`}
              </div>
            </div>
            <div className="order-first ml-0 mt-3 md:-order-none md:mt-6">
              <div className="mb-3 grid grid-cols-6 gap-4">
                <div className="col-start-1 col-end-5 text-xs text-zinc-400">
                  {singleTeamCoursesCompletionLabel}
                </div>
                <div className="col-span-1 col-end-9 text-xs text-zinc-400">
                  {(stats?.data?.overallProgress ? formatNumber(stats?.data?.overallProgress) : 0) +
                    '%'}
                </div>
              </div>
              <div className="flex w-full flex-none text-sm">
                <progress
                  className="progress progress-error w-full bg-zinc-700"
                  value={stats?.data?.overallProgress ? stats?.data?.overallProgress : '0'}
                  max="100"
                ></progress>
              </div>
            </div>
          </div>
          <div className="grid w-full grid-flow-row-dense grid-cols-1 p-6">
            <div className="flex w-full flex-none justify-center py-1 text-sm">
              <dt className="w-8/12 flex-[3] pr-1 text-sm font-medium !text-zinc-400">
                {singleTeamAvgSessionLabel}
              </dt>
              <dd className="flex-[2] text-sm font-semibold text-white">
                {' '}
                {calcTimeString(parseFloat(stats?.data?.averageSession))}
              </dd>
            </div>
            <div className="flex w-full flex-none justify-center py-1 text-sm">
              <dt className="w-8/12 flex-[3] pr-1 text-sm font-medium !text-zinc-400">
                {singleTeamCoursesAssignedLabel}
              </dt>
              <dd className="flex-[2] text-sm font-semibold text-white">
                {' '}
                {stats?.data?.coursesAssigned}
              </dd>
            </div>
            <div className="flex w-full flex-none justify-center py-1 text-sm">
              <dt className="w-8/12 flex-[3] pr-1 text-sm font-medium !text-zinc-400">
                {singleTeamCourseCompletedLabel}
              </dt>
              <dd className="flex-[2] text-sm font-semibold text-white">
                {' '}
                {stats?.data?.coursesCompleted}{' '}
              </dd>
            </div>
          </div>

          <div className="p-6 py-3 md:p-2">
            <div className="text-sm font-medium text-white">{singleTeamMostPopularBadgesLabel}</div>
            <div className=" items-center ">
              {mostEarnedBadges.error ? (
                <div className="flex w-full items-start justify-center text-center">
                  <ErrorDisplay
                    message={strapiErrorMessage['SYSTEM_ERROR']}
                    type={ERROR_TYPES.SOMETHING_WENT_WRONG}
                    refetch={usersData.refetch}
                    isRefetching={usersData.isRefetching}
                    className="mt-2 items-center gap-2"
                  />
                </div>
              ) : !mostEarnedBadges.isLoading && mostEarnedBadges.data ? (
                mostEarnedBadges?.data?.total != 0 ? (
                  <MostEarnedBadgeCardDisplay
                    badges={mostEarnedBadges.data.badges}
                    className="mt-3 gap-5"
                    badgeClassName="!border-none"
                  />
                ) : mostEarnedBadges?.data?.total == 0 ? (
                  <div className={clsx('mt-7 text-sm font-normal text-zinc-500')}>
                    {errorMessages.noMostEarnedBadges}
                  </div>
                ) : (
                  <div className="mt-7 flex w-full items-center justify-center">
                    <BasicSpinner className="text-white" />
                  </div>
                )
              ) : (
                <div className="mt-5 flex w-full items-center justify-center">
                  {mostEarnedBadges.isLoading && <BasicSpinner className="text-white" />}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-4 flex min-h-[15.625rem] w-full items-center justify-center">
          <ErrorDisplay
            message={strapiErrorMessage['SYSTEM_ERROR']}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            refetch={subTeamsData.refetch}
            isRefetching={subTeamsData.isRefetching}
          />
        </div>
      )}
      {/* Sub Teams Section */}
      <SideBarLayoutPageContentWrapper className="flex-col gap-5 py-6 md:grid-cols-2">
        {subTeamsData.data?.count != 0 && subTeamsData.data?.rows[0].isActive != false && (
          <div>
            <h2 className="font-bold text-white">{singleTeamSubTeamLabel}</h2>

            <div className="mt-4 flex flex-row gap-4">
              {subTeamsData.error ? (
                <div className="mt-4 flex min-h-[15.625rem] w-full items-center justify-center">
                  <ErrorDisplay
                    message={strapiErrorMessage['SYSTEM_ERROR']}
                    type={ERROR_TYPES.SOMETHING_WENT_WRONG}
                    refetch={subTeamsData.refetch}
                    isRefetching={subTeamsData.isRefetching}
                  />
                </div>
              ) : !subTeamsData.isLoading && subTeamsData.data ? (
                subTeamsData.data?.count !== 0 && (
                  <SubTeamCardWrapper subteams={subTeamsData.data?.rows} />
                )
              ) : (
                <div className="flex h-[16.98rem] w-full items-center justify-center">
                  {subTeamsData.isLoading && <BasicSpinner className="text-white" />}
                </div>
              )}
            </div>
          </div>
        )}
        {/* Managers */}
        <div>
          <h2 className="font-bold text-white">
            {singleTeamManagerLabel}{' '}
            {usersData?.data?.managers?.count != 0 && usersData?.data?.managers?.count
              ? '(' + usersData?.data?.managers?.count + ')'
              : ''}
          </h2>
          <div className="mt-4 flex flex-row gap-4 overflow-scroll">
            {usersData.error ? (
              <div className="mt-4 flex min-h-[15.625rem] w-full items-center justify-center">
                <ErrorDisplay
                  message={strapiErrorMessage['SYSTEM_ERROR']}
                  type={ERROR_TYPES.SOMETHING_WENT_WRONG}
                  refetch={usersData.refetch}
                  isRefetching={usersData.isRefetching}
                />
              </div>
            ) : !usersData.isLoading && usersData.data ? (
              usersData?.data?.managers?.count != 0 ? (
                <ManagerCard managers={usersData.data.managers.rows} />
              ) : usersData.data.managers.count == 0 ? (
                <div className="mt-4 flex min-h-[15.625rem] w-full items-center justify-center">
                  <NoDataDisplay message="No managers data available." />
                </div>
              ) : (
                <div className="flex h-[16.98rem] w-full items-center justify-center">
                  <BasicSpinner className="text-white" />
                </div>
              )
            ) : (
              <div className="flex h-[16.98rem] w-full items-center justify-center">
                {usersData.isLoading && <BasicSpinner className="text-white" />}
              </div>
            )}
          </div>
        </div>
        {/* Team Members */}
        <div>
          <h2 className="font-bold text-white">
            {singleTeamMemberLabel}{' '}
            {usersData?.data?.members?.count != 0 && usersData?.data?.members?.count
              ? '(' + usersData?.data?.members?.count + ')'
              : ''}
          </h2>
          <div className="mt-4 flex flex-row gap-4 overflow-scroll">
            {usersData.error ? (
              <div className="flex min-h-[15.625rem] w-full items-center justify-center">
                <ErrorDisplay
                  message={strapiErrorMessage['SYSTEM_ERROR']}
                  type={ERROR_TYPES.SOMETHING_WENT_WRONG}
                  refetch={usersData.refetch}
                  isRefetching={usersData.isRefetching}
                />
              </div>
            ) : !usersData.isLoading && usersData.data ? (
              usersData?.data?.members?.count != 0 ? (
                <MemberCard members={usersData.data.members.rows} />
              ) : usersData.data.members.count == 0 ? (
                <div className="flex min-h-[15.625rem] w-full items-center justify-center">
                  <NoDataDisplay message={noTeamMembersMessage} />
                </div>
              ) : (
                <div className="flex h-[16.98rem] w-full items-center justify-center">
                  <BasicSpinner className="text-white" />
                </div>
              )
            ) : (
              <div className="flex h-[16.98rem] w-full items-center justify-center">
                {usersData.isLoading && <BasicSpinner className="text-white" />}
              </div>
            )}
          </div>
        </div>
      </SideBarLayoutPageContentWrapper>
    </div>
  ) : (
    <div className="flex h-[16.98rem] w-full items-center justify-center">
      <FadeLoader color="#E4E4E7" width="3px" />
    </div>
  );
}
export default SingleTeamView;
