import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { PATHS } from '../constants/api';
import type {
  GetLearningJourneyDetail,
  ListAllLearningJourneyCoursesResponse,
  ListAllLearningJourneysResponse,
} from '../types/api';

type ListAllLearningJourneysParams = {
  pageSize: number;
  pageOffset: number;
};

const listAllLearningJourneys = async (
  params: ListAllLearningJourneysParams
): Promise<ListAllLearningJourneysResponse> => {
  const { pageSize, pageOffset } = params;

  const path = PATHS.LEARNING_JOURNEY.LIST;
  const queryParams = { size: pageSize, offset: pageOffset };
  const query = qs.stringify(queryParams);

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${query}`, {
    withCredentials: true,
  });

  return response;
};

type GetLearningJourneyDetailParams = {
  journeyId: string;
};

const getLearningJourneyDetail = async (
  params: GetLearningJourneyDetailParams
): Promise<GetLearningJourneyDetail> => {
  const { journeyId } = params;

  const path = PATHS.LEARNING_JOURNEY.DETAIL({ id: journeyId });

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
  });

  return response;
};

type ListAllLearningJourneyCoursesParams = {
  journeyId: string;
  pageSize: number;
  pageOffset: number;
};

const listAllLearningJourneyCourses = async (
  params: ListAllLearningJourneyCoursesParams
): Promise<ListAllLearningJourneyCoursesResponse> => {
  const { pageSize, pageOffset, journeyId } = params;

  const path = PATHS.LEARNING_JOURNEY.COURSES({ id: journeyId });
  const queryParams = { size: pageSize, offset: pageOffset };
  const query = qs.stringify(queryParams);

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${query}`, {
    withCredentials: true,
  });

  return response;
};

const getLearningJourneyMetaData = (journeyId: string, courseId: string) => {
  return API.get(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    PATHS.LEARNING_JOURNEY.META_DATA({ journeyId, courseId }),
    { withCredentials: true }
  );
};

export {
  getLearningJourneyDetail,
  listAllLearningJourneyCourses,
  listAllLearningJourneys,
  getLearningJourneyMetaData,
};
