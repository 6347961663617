import { Link, Navigate, useLocation } from 'react-router-dom';
import ActionResponse from '../../../../../components/ActionResponse';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';

const UserResetPasswordSuccess = () => {
  const location = useLocation();
  const isRedirectedFromForgotPasswordPage = location.state?.fromPath === '/auth/reset-password';
  const data: any = useStrapiDataHelper();
  const { resetSuccessPageTitle, resetSuccessPageDescription, resetSuccessPageLoginLabel } =
    data.resetpassword.data.attributes;
  const finalResetSuccessPageDescription = resetSuccessPageDescription.split('{{login}}');
  if (!isRedirectedFromForgotPasswordPage) {
    return <Navigate to=".." />;
  }

  return (
    <div className="flex w-full flex-col items-center pt-[10vw]">
      <ActionResponse
        title={resetSuccessPageTitle}
        subtitle={
          <div>
            {finalResetSuccessPageDescription[0]}
            <Link to="/auth/login" className="underline">
              {resetSuccessPageLoginLabel}
            </Link>
            {finalResetSuccessPageDescription[1]}
          </div>
        }
        variant="success"
      />
    </div>
  );
};

export default UserResetPasswordSuccess;
