enum PERFORMANCE_REPORT_TYPES {
  COURSE_PERFORMANCE_RATES = 'course_performance_rates',
  COURSE_PERFORMANCE = 'course_performance',
  ASSESSMENT_SCORES = 'assessment_scores',
  COURSE_PERFORMANCE_OVER_TIME = 'course_performance_over_time',
  COURSE_PERFORMANCE_STATISTICS = 'course_performance_statistics',
  COURSE_COMPLETED_ASSIGNED_STATISTICS = 'course_completed_assigned_statistics',
  INDIVIDUAL_LEADERBOARD = 'individual_leaderboard',
  TEAM_LEADERBOARD = 'team_leaderboard',
  TEAMS_LEADERBOARD = 'teams_leaderboard',
  TOP_EARNED_BADGES = 'top_earned_badges',
  CUSTOMER_LEADER_BOARD = 'customer_leaderboard',
  MOST_POPULAR_COURSES = 'most_popular_courses',
  OVERALL_CHURN_RATE = 'overall_churn_rate',
  TOP_CHURN_RISK = 'top_churn_risk',
}

enum ACTIVITY_REPORT_TYPES {
  LOGIN_FREQUENCY = 'login_frequency',
  ENGAGEMENT_BY_CATEGORIES = 'engagement_by_categories',
  HOURS_SPENT_FREQUENCY = 'hours_spent_frequency',
  HOURS_LOGGED = 'hours_logged',
  AVERAGE_WEEKLY_HOURS = 'average_weekly_hours',
}

enum REPORT_TYPES {
  // This enum is to map the Report names used in Strapi Collection Type - ReportDetail
  COURSE_PERFORMANCE_RATES = 'COURSE_PERFORMANCE_RATES',
  COURSE_PERFORMANCE = 'COURSE_PERFORMANCE',
  ASSESSMENT_SCORES = 'ASSESSMENT_SCORES',
  COURSE_PERFORMANCE_OVER_TIME = 'COURSE_PERFORMANCE_OVER_TIME',
  COURSE_PERFORMANCE_STATISTICS = 'use_labels_in_each_card', // We use the title of each card that is queried from Strapi as the value of field:reportType in CollectionType:ReportDetail
  COURSE_COMPLETED_ASSIGNED_STATISTICS = 'COURSE_COMPLETED_ASSIGNED_STATISTICS',
  INDIVIDUAL_LEADERBOARD = 'INDIVIDUAL_LEADERBOARD',
  TEAM_LEADERBOARD = 'TEAM_LEADERBOARD',
  TEAMS_LEADERBOARD = 'TEAMS_LEADERBOARD',
  TOP_EARNED_BADGES = 'TOP_EARNED_BADGES',
  CUSTOMER_LEADER_BOARD = 'CUSTOMER_LEADER_BOARD',
  MOST_POPULAR_COURSES = 'MOST_POPULAR_COURSES',
  OVERALL_CHURN_RATE = 'OVERALL_CHURN_RATE',
  TOP_CHURN_RISK = 'TOP_CHURN_RISK',
  MOST_POPULAR_INSTRUCTORS = 'MOST_POPULAR_INSTRUCTORS',
  LOGIN_FREQUENCY = 'LOGIN_FREQUENCY',
  ENGAGEMENT_BY_CATEGORIES = 'ENGAGEMENT_BY_CATEGORIES',
  HOURS_SPENT_FREQUENCY = 'HOURS_SPENT_FREQUENCY',
  HOURS_LOGGED = 'HOURS_LOGGED',
  AVERAGE_WEEKLY_HOURS = 'AVERAGE_WEEKLY_HOURS',
}

// consider the reports stale after 30 seconds
export const REPORTS_STALE_TIME = 30000;

const REPORTS_INTERVAL = {
  WEEK: 'WEEK',
  FORTNIGHT: 'FORTNIGHT',
  MONTH: 'MONTH',
} as const;

const REPORTS_X_AXIS_LABEL = {
  DAYS: 'Days',
  WEEKS: 'Weeks',
  MONTHS: 'Months',
} as const;

const PERIOD = {
  LAST_MONTH: 'LAST_MONTH',
  LAST_THREE_MONTHS: 'LAST_THREE_MONTHS',
  LAST_SIX_MONTHS: 'LAST_SIX_MONTHS',
} as const;

const REPORTS_DASHBOARD_LIMIT = 5;

const ENTERPRISE_LEADERBOARD_DEFAULT_LIMIT = 6;

const TOP_EARNED_BADGES_DEFAULT_LIMIT = 6;

const MOST_POPULAR_COURSES_DEFAULT_LIMIT = 5;

const DEFAULT_LOCALE = 'en';

export {
  PERFORMANCE_REPORT_TYPES,
  ACTIVITY_REPORT_TYPES,
  REPORTS_INTERVAL,
  REPORTS_X_AXIS_LABEL,
  PERIOD,
  REPORTS_DASHBOARD_LIMIT,
  ENTERPRISE_LEADERBOARD_DEFAULT_LIMIT,
  TOP_EARNED_BADGES_DEFAULT_LIMIT,
  MOST_POPULAR_COURSES_DEFAULT_LIMIT,
  DEFAULT_LOCALE,
  REPORT_TYPES,
};
