const BADGES_API_PATHS = {
  EARNED_BADGES_API_PATHS: '/gamification/earned-badges',
  UP_NEXT_BADGES_API_PATHS: '/gamification/badges/up-next',
  IN_PROGRESS_BADGES_API_PATHS: '/gamification/in-progress-badges',
  TO_EARN_BADGES_API_PATHS: '/gamification/to-earn-badges',
  BADGES_LEADERBOARD: '/gamification/leaderboard',
  BADGES_DETAILS_BY_ID: '/gamification/badge-details',
  GET_USHGADMIN_BADGES: '/gamification/hq-admin/badge-mgmt/badges',
  GET_USHGADMIN_BADGE_TYPES: '/gamification/hq-admin/badge-types',
  DELETE_BADGE_BY_ID: (id: number) => `/gamification/hq-admin/badge-mgmt/badge/${id}`,
  CREATE_BADGE: `/gamification/hq-admin/badge-mgmt/badge`,
  CREATE_BADGE_IN_MULTI_LANG: `/gamification/hq-admin/badge-mgmt/badge/multi-lang`,
  UPDATE_BADGE_BY_ID: (id: number) => `/gamification/hq-admin/badge-mgmt/badge/${id}`,
  GET_BADGE_RECORD_BY_ID: (id: number) => `/gamification/hq-admin/badge-mgmt/badge/${id}`,
};

export { BADGES_API_PATHS };
