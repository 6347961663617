import USHGAdminDashboardRequests from '../../../requests/components/USHGAdminDashboardRequests';
import { MostPopularCoursesTable } from '../../../shared/components/Reports/Tables';
import UserGreeting from '../common/UserGreeting';
import { OverAllChurnRate } from './OverAllChurnRateCard';
import CustomerLeaderboardMain from './Tables/CustomerLeaderboardTable/CustomerLeaderboardMain';
import TopChurnRisksTableMain from './Tables/TopChurnRiskTable/TopChurnRisksTableMain';
import { dashboardOptions } from '../../types/dashboard';
import { useStrapiReportsData } from '../../../shared/hooks/useStrapiReportData';
import { USHG_HQ_APP_ROLE } from '../../../../constants';

const USHGAdminDashboard = ({ lazyLoad = true }: dashboardOptions) => {
  const { ReportTooltipContent } = useStrapiReportsData();
  return (
    <main
      id="main-content"
      className="relative mb-4 grid grow auto-rows-min grid-cols-8 gap-x-3 gap-y-2 sm:gap-y-3 lg:gap-x-[13px] lg:gap-y-4 xl:gap-y-5"
    >
      <UserGreeting className="col-span-8 col-start-1 row-start-1 lg:col-span-6" />
      <OverAllChurnRate
        lazyLoad={lazyLoad}
        className="col-span-8 col-start-1 row-span-1 row-start-2 md:col-span-3 md:row-start-2 lg:col-span-2  lg:row-start-1"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.USHG_ADMIN}
      />
      {/* <ChurnRateViz
        className="col-span-8 col-start-1 row-start-3 overflow-hidden md:row-start-4 lg:col-span-6 lg:row-start-2 "
        heading="Churn Rate"
      /> */}
      <MostPopularCoursesTable
        lazyLoad={lazyLoad}
        className="col-span-8 col-start-1 row-start-3 overflow-hidden md:col-span-5 md:col-start-4 md:row-span-2 md:row-start-2 lg:col-span-6 lg:row-span-1 lg:row-start-2"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.USHG_ADMIN}
      />
      <TopChurnRisksTableMain
        lazyLoad={lazyLoad}
        className="col-span-8 col-start-1 row-start-4 md:col-span-3 md:row-start-3 lg:col-span-2 lg:row-start-2"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.USHG_ADMIN}
      />
      <CustomerLeaderboardMain
        lazyLoad={lazyLoad}
        className="col-span-8  col-start-1 row-start-5 lg:row-start-3 "
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.USHG_ADMIN}
      />
      <USHGAdminDashboardRequests
        lazyLoad={lazyLoad}
        className="col-span-8 col-start-1 row-start-6 lg:row-start-4"
      />
    </main>
  );
};

export default USHGAdminDashboard;
