import { useMutation } from '@tanstack/react-query';
import { VerifyCurrentUserAttributeParams, verifyCurrentUserAttribute } from '../utils';

const useVerifyCurrentUserAttributeMutation = () => {
  const mutation = useMutation({
    mutationFn: async (params: VerifyCurrentUserAttributeParams) => {
      const response = await verifyCurrentUserAttribute(params);
      return response;
    },
  });

  return mutation;
};

export default useVerifyCurrentUserAttributeMutation;
