import { Row } from '@tanstack/react-table';
import clsx from 'clsx';
import { useState } from 'react';
import { TableDataRow } from '../../../../../components/DataTables';
import { USHGAdminRequest } from '../../../types';
import RequestActionDialog from '../RequestActionDialog';

interface Props {
  row: Row<USHGAdminRequest>;
  className?: string;
}

const RequestsTableDataRow = (props: Props) => {
  const { row, className } = props;

  // Dialog that shows more details and options to enable and reject
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Open dialog on when the row is clicked
  const handleRowClick = () => {
    setIsDialogOpen(true);
  };

  return (
    <>
      <TableDataRow
        onClick={handleRowClick}
        row={row}
        className={clsx('w-full cursor-pointer hover:bg-page-bg-dark/20', className)}
      />
      <RequestActionDialog
        open={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        request={row.original}
      ></RequestActionDialog>
    </>
  );
};

export default RequestsTableDataRow;
