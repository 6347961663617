import clsx from 'clsx';
import { useFormContext, useWatch } from 'react-hook-form';
import { CheckboxInput } from '../../../../../components/FormElements';
import { NOTIFICATION_MODES, NOTIFICATION_MODES_ORDER } from '../../../constants';
import { useNotificationPreferencesStrapiData } from '../../../hooks';
import { SingleNotificationPreferenceDefinition } from '../../../types';

interface Props {
  preference: SingleNotificationPreferenceDefinition;
  categoryId: string;
}

/**
 * Renders a single notification preference type.
 */
const NotificationPreferenceNotificationTypeField = (props: Props) => {
  const { preference, categoryId } = props;
  const { register, control } = useFormContext();
  const { webLabel, emailLabel } = useNotificationPreferencesStrapiData();

  // {
  //   isWebEnabled: boolean,
  //   isEmailEnabled: boolean
  // }
  const notificationPreferenceValue = useWatch({
    control,
    name: `${categoryId}.${preference.notificationTypeId}`,
  });

  const NOTIFICATION_MODES_CONFIG = {
    [NOTIFICATION_MODES.WEB]: {
      key: 'isWebEnabled',
      displayName: webLabel,
    },
    [NOTIFICATION_MODES.EMAIL]: {
      key: 'isEmailEnabled',
      displayName: emailLabel,
    },
  } as const;
  return (
    <div
      className="flex flex-col items-start justify-between gap-2 sm:flex-row sm:items-center sm:gap-2"
      key={preference.notificationTypeId}
    >
      <span className="text-sm font-normal text-zinc-300">{preference.notificationTypeName}</span>
      <div className="flex gap-1">
        {NOTIFICATION_MODES_ORDER.map((notificationMode) => {
          const notificationModeConfig = NOTIFICATION_MODES_CONFIG[notificationMode];

          const isWebMode = notificationMode === NOTIFICATION_MODES.WEB;

          // category.preference.mode ex: COURSES_AND_BADGES.COURSE_ASSIGNED.isWebEnabled
          const fieldName = `${categoryId}.${preference.notificationTypeId}.${notificationModeConfig.key}`;

          const key = `${preference.notificationTypeId}_${notificationMode}`;

          const modePreference = notificationPreferenceValue?.[notificationModeConfig.key];
          const hasModePreference = modePreference !== undefined && modePreference !== null;

          const isEnabled = hasModePreference ? modePreference : isWebMode;

          // Checkbox that shows
          // Email Enabled and Web Enabled Checkboxes
          return (
            <div
              key={key}
              className={clsx(
                'flex h-max items-center gap-2 rounded-sm border border-transparent py-1 px-2',
                isEnabled && 'border-zinc-800 bg-zinc-900'
              )}
            >
              <CheckboxInput
                className="accent-base-brand"
                id={key}
                type="checkbox"
                {...(hasModePreference || !isWebMode ? {} : { defaultChecked: true })}
                {...register(fieldName)}
              />
              <label htmlFor={key} className="select-none text-xs font-normal text-zinc-300">
                {notificationModeConfig.displayName}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NotificationPreferenceNotificationTypeField;
