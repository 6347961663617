import { useStrapiCoursesData } from '../../../../hooks/useStrapiCourseData';
import { courseDetailsProps } from '../ModulesComponent/types/moduleTypes';
import SimilarCoursesComponent from '../CourseWrapper/CourseWrapperComponent';
import { CheckCircle } from 'phosphor-react';
const CourseDetails = ({ courseDetails, similarCoursesQuery }: courseDetailsProps) => {
  const { data: similarCoursesData, isError, isLoading } = similarCoursesQuery;
  const { courseDetails: strapiCourseDetails } = useStrapiCoursesData();
  const {
    descriptionLabel,
    categoryLabel,
    tagsLabel,
    courseOutComeLabel,
    noTagsLabel,
    similarCoursesLabel,
  } = strapiCourseDetails;

  return (
    <div className="flex flex-col gap-8">
      {/* Description */}
      <div className="flex flex-col gap-4">
        <h2 className="text-xl font-bold">{descriptionLabel}</h2>
        <div className="flex max-w-6xl flex-col gap-6 text-justify text-base text-zinc-400">
          {courseDetails.description.split('\n').map((item, index) => (
            <p key={`para-${index}`}>{item}</p>
          ))}
        </div>
      </div>
      {/* Category & Tags Section */}
      <div className="flex flex-col gap-4 sm:flex-row">
        <div className="flex flex-col gap-2 text-sm">
          <span className="text-zinc-400">{categoryLabel}</span>
          <span className="text-base-tangerine">{courseDetails.category}</span>
        </div>
        <div className="flex flex-col gap-2 text-sm">
          <span className="text-zinc-400">{tagsLabel}</span>
          <div className="flex flex-col gap-1 sm:flex-row">
            {!courseDetails.tags ||
              (courseDetails.tags.length == 0 && (
                <span className="mx-1 border-zinc-400 text-base-tangerine ">{noTagsLabel}</span>
              ))}
            {courseDetails.tags &&
              courseDetails.tags.map((item) => {
                return (
                  <span
                    key={item}
                    className="mr-1 cursor-pointer text-base-tangerine hover:text-white"
                  >
                    #{item}
                  </span>
                );
              })}
          </div>
        </div>
      </div>
      {/* What you'll learn */}
      {courseDetails.objectives.length !== 0 && (
        <div className="flex max-w-6xl flex-col gap-4">
          <h2 className="text-xl font-bold">{courseOutComeLabel}</h2>
          {courseDetails.objectives.map((item) => {
            return (
              <div key={item} className="flex flex-row items-start gap-2 text-base">
                <div className="flex h-6 shrink-0 items-center justify-center">
                  <CheckCircle weight="fill" color="#F3EAC7" size={18} />
                </div>
                <p className="text-zinc-400">{item}</p>
              </div>
            );
          })}
        </div>
      )}
      {/* Similar Courses */}
      {similarCoursesData && similarCoursesData.length > 0 && (
        <div className="hidden flex-col lg:flex">
          <SimilarCoursesComponent
            coursesData={similarCoursesData}
            isLoading={isLoading}
            isError={isError}
            label={similarCoursesLabel}
          />
        </div>
      )}
    </div>
  );
};

export default CourseDetails;
