const S3_HEADER = {
  X_AMZ_TAGGING: 'x-amz-tagging',
};

const S3_TAG_KEY = {
  isTemp: 'isTemp',
};

const S3_TAG_VALUE = {
  true: 'true',
};

export { S3_HEADER, S3_TAG_KEY, S3_TAG_VALUE };
