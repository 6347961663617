import { ChangeEvent, useState } from 'react';
import Dialog from '../../../../../../components/Dialog';
import Select from '../../../../../../components/Select';
import { ModalType } from '../../../../types';
import AssessmentForm from './AssessmentForm';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../../constants/user';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { ASSESSMENT_ACTION } from '../../../../constants';

interface Props {
  modalTitle?: string;
  setIsAssessmentModalOpen: (open: boolean) => void;
  assessmentBasicDetails?: Record<string, any>;
  formActionButtonLabel: string;
  buttonIcon?: React.ReactNode;
  modalType: ModalType;
  refetch: () => void;
  showLanguageDropdown?: boolean;
  mappedOptions?: { display: string; value: string }[];
  selectedLanguage?: string;
  handleLanguageChange?: (newLanguage: string) => void;
  errorType?: string | null;
  setErrorType?: (error: string | null) => void;
}

const AssessmentModal = ({
  modalTitle,
  setIsAssessmentModalOpen,
  assessmentBasicDetails,
  formActionButtonLabel,
  buttonIcon,
  modalType,
  refetch,
  mappedOptions,
  selectedLanguage,
  handleLanguageChange,
  errorType,
  setErrorType,
  showLanguageDropdown = false,
}: Props) => {
  // state

  // create, update of assessment details action in progress
  const [actionInProgress, setActionInProgress] = useState<keyof typeof ASSESSMENT_ACTION | null>(
    null
  );

  // state
  return (
    <div className="mx-1 flex flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <div className="flex items-center">
        <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
          {modalTitle}
        </Dialog.Title>
        {showLanguageDropdown && assessmentBasicDetails?.data && (
          <Select
            aria-label="Language options"
            options={mappedOptions?.length ? mappedOptions : []}
            value={selectedLanguage}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => handleLanguageChange?.(e.target.value)}
            disabledOptions={modalType === 'manage' ? [DEFAULT_PREFERRED_LANGUAGE] : []}
            className="ml-auto"
            disabled={!!actionInProgress}
          />
        )}
      </div>
      {assessmentBasicDetails?.isLoading || assessmentBasicDetails?.isFetching ? (
        <div className="flex h-full w-full items-center justify-center py-4">
          <BasicSpinner className="h-[30vh] text-white" />
        </div>
      ) : (
        (assessmentBasicDetails?.data || modalType === 'add') && (
          <AssessmentForm
            setIsAssessmentModalOpen={setIsAssessmentModalOpen}
            assessmentBasicDetails={assessmentBasicDetails}
            formActionButtonLabel={formActionButtonLabel}
            buttonIcon={buttonIcon}
            modalType={modalType}
            refetch={refetch}
            language={selectedLanguage ?? DEFAULT_PREFERRED_LANGUAGE}
            errorType={errorType}
            setErrorType={setErrorType}
            setActionInProgress={setActionInProgress}
          />
        )
      )}
    </div>
  );
};

export default AssessmentModal;
