import { gql } from '@apollo/client';

const NotificationAvatarFragment = gql`
  fragment NotificationAvatarFragment on UploadFileEntityResponse {
    data {
      attributes {
        url
        alternativeText
      }
    }
  }
`;

const NotificationFragment = gql`
  ${NotificationAvatarFragment}
  fragment NotificationFragment on NotificationEntityResponse {
    data {
      attributes {
        pageHeading
        notificationType {
          newBadgeEarned {
            title
            description
            avatar {
              ...NotificationAvatarFragment
            }
          }
          assignedCourseOverdue {
            title
            description
            avatar {
              ...NotificationAvatarFragment
            }
          }
          newCoursePublished {
            title
            description
            avatar {
              ...NotificationAvatarFragment
            }
          }
          newCourseAssigned {
            description
          }
          newEnterpriseUserOnboarded {
            title
            avatar {
              ...NotificationAvatarFragment
            }
          }
          newEnterpriseTeamCreated {
            title
            avatar {
              ...NotificationAvatarFragment
            }
          }
          hqNewIndividualSignup {
            description
          }
          hqNewOrganizationSignup {
            description
          }
          hqIndividualPaymentOverdue {
            description
          }
          hqOrganizationPaymentOverdue {
            description
          }
          hqNewCourseFeedbackAdded {
            description
          }
          hqNewSupportRequestRaised {
            description
          }
          hqIndividualSubscriptionCancelled {
            description
          }
          hqOrganizationSubscriptionCancelled {
            description
          }
          newEnterpriseTeamRequest {
            description
          }
          newEnterpriseUserRequest {
            description
            descriptionWithTeam
            descriptionWithName
            descriptionWithNameWithTeam
          }
          enterpriseEditTeamRequest {
            description
          }
          enterpriseUserTeamChange {
            prevManagerDescription
            currManagerDescription
            adminLeaderDescription
            viewMemberActionLabel
          }
        }
        errorMessages {
          notificationsNotFound
          notificationItemLoadingError
          notificationsListLoadingErrorTitle
          notificationsListLoadingErrorSubtitle
        }
        infiniteNotificationsList {
          loadingMoreMessage
          loadingMessage
          newNotificationsBadgeMessage
          reachedEndMessage
        }
        shared {
          viewRequestsLabel
          atLabel
        }
      }
    }
  }
`;

export { NotificationFragment };
