import clsx from 'clsx';
import ImageWithAcronymFallback from '../../../../../../components/ImageWithAcronymFallback';
import { USER_STATUS } from '../../../../../members/constants';

interface AvatarAndNameCellProps {
  name?: string;
  row?: any;
  className?: string;
}

const GenericNameCell = (props: AvatarAndNameCellProps) => {
  const { name, className, row } = props;

  const hasFullName = name && name !== '';
  const username = row.original.username;

  const acronymWord = hasFullName ? name : row.original.username;
  const fullName = !hasFullName
    ? row.original.status === USER_STATUS.INVITED
      ? 'Invited Member'
      : username
    : name;

  const imageKey = row.original.pictureUrl;

  return (
    <div className={clsx('flex items-center gap-2 whitespace-nowrap', className)}>
      <ImageWithAcronymFallback
        imageKey={imageKey}
        acronymWord={acronymWord.toUpperCase()}
        acronymLogoClassName="h-6 w-6 rounded-full border border-zinc-900 text-xs"
        aria-label={fullName}
        className="aspect-square h-6 w-6 rounded-full border border-zinc-900 object-cover object-center"
      />
      <span>{fullName}</span>
    </div>
  );
};

export default GenericNameCell;
