import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useDebounce from '../../../hooks/use-debounce';
import {
  ALL_MEMBERS_TABLE_DEFAULT_PAGE,
  ALL_MEMBERS_TABLE_DEFAULT_SIZE,
  ALL_MEMBERS_TABLE_FILTER_SEARCH_DEBOUNCE_MS,
  ALL_MEMBERS_TABLE_SEARCH_QUERY_PARAM,
  ALL_MEMBERS_TABLE_SORT_BY_QUERY_PARAM,
  ALL_MEMBERS_TABLE_SORT_DIR_QUERY_PARAM,
  ALL_MEMBERS_TABLE_USER_STATUS_QUERY_PARAM,
  USER_STATUS_VALUES,
} from '../constants';
import { GetAllMembersInOrganizationFilters, UserStatus } from '../types';
import { SortDirection } from '@tanstack/react-table';

/**
 * Construct the filters that are required for the all members table.
 */
const useAllMembersTableFilters = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let userStatus = searchParams.get(ALL_MEMBERS_TABLE_USER_STATUS_QUERY_PARAM) as UserStatus | null;
  let searchQuery = searchParams.get(ALL_MEMBERS_TABLE_SEARCH_QUERY_PARAM);

  const sortBy = searchParams.get(ALL_MEMBERS_TABLE_SORT_BY_QUERY_PARAM);
  const sortDir = searchParams.get(ALL_MEMBERS_TABLE_SORT_DIR_QUERY_PARAM) as SortDirection | null;

  //   If the user status is not valid userStatus set it to undefined
  if (userStatus && !USER_STATUS_VALUES.includes(userStatus)) {
    userStatus = null;
  }

  // An empty search string is same as null
  if (searchQuery === '') {
    searchQuery = null;
  }

  // Current Page
  const [page, setPage] = useState(ALL_MEMBERS_TABLE_DEFAULT_PAGE);
  // Page Size
  const [pageSize, setPageSize] = useState(ALL_MEMBERS_TABLE_DEFAULT_SIZE);

  // Debounce the fast changing search filter
  // so we would make the request only when there is some considerable pause
  const debouncedSearchQuery = useDebounce(
    searchQuery,
    ALL_MEMBERS_TABLE_FILTER_SEARCH_DEBOUNCE_MS
  );

  // If the user status or the search query changes change the page to the first page
  useEffect(() => {
    setPage(ALL_MEMBERS_TABLE_DEFAULT_PAGE);
  }, [userStatus, searchQuery]);

  const requestFilters: GetAllMembersInOrganizationFilters = useMemo(() => {
    const filters: GetAllMembersInOrganizationFilters = {
      size: pageSize,
      start: (page - 1) * pageSize,
      search: debouncedSearchQuery ?? undefined,
      // eslint-disable-next-line camelcase
      user_status: userStatus ?? undefined,
      // eslint-disable-next-line camelcase
      sort_by: sortBy ?? undefined,
      // eslint-disable-next-line camelcase
      sort_dir: sortDir ?? undefined,
    };

    return filters;
  }, [debouncedSearchQuery, page, pageSize, sortBy, sortDir, userStatus]);

  //   Filter is applied when the search filter is valid or the user status is valid
  const isFilterApplied = !!searchQuery || !!userStatus;

  return {
    page,
    setPage,
    pageSize,
    setPageSize,
    searchQuery,
    userStatus,
    requestFilters,
    isFilterApplied,
  };
};

export default useAllMembersTableFilters;
