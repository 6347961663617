import clsx from 'clsx';
import React from 'react';

interface Props {
  className?: string;
}

// Container for side bar items
const SideBarPanel = ({ children, className }: React.PropsWithChildren<Props>) => {
  return <section className={clsx('flex flex-col gap-2 p-0', className)}>{children}</section>;
};

export default SideBarPanel;
