import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Button } from '../../../../../components/Buttons';
import Dialog from '../../../../../components/Dialog';
import {
  CheckboxInput,
  DropdownInput,
  Label,
  TextAreaInput,
} from '../../../../../components/FormElements';
import { BasicSpinner } from '../../../../../components/Spinners';
import { Toast } from '../../../../../components/Toast';
import { queryClient } from '../../../../../config/react-query';
import { FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY } from '../../../../shared/constants';
import { cancelSubscription } from '../../../api/settings';
import useFetchDropdownOptions from '../../../hooks/useFetchDropdownOptions';
import { useStrapiSettingsData } from '../../../hooks/useStrapiSettingsData';
import { FormFieldWrapper } from '../../Form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

type Inputs = {
  reason: string;
  otherReason: string;
  checkbox: boolean;
};

// Cancel Subscription Form Schema section starts

type GetCancelSubscriptionFormSchemaParams = {
  reasonRequired: string;
  checkBoxRequired: string;
};

/**
 * Generate schema for cancel subscription form based on attributes from strapi
 */
const getCancelSubscriptionFormSchema = (params: GetCancelSubscriptionFormSchemaParams) => {
  const { reasonRequired, checkBoxRequired } = params;

  const schema = yup.object({
    reason: yup.string().required(reasonRequired),
    // TODO: have different error message
    otherReason: yup
      .string()
      .trim()
      .when(['reason'], {
        // TODO: fix this condition when we add slug to the options
        is: (reason: string) => reason === 'other',
        then: (schema) => schema.required(reasonRequired),
        otherwise: (schema) => schema.optional(),
      }),
    // the default value is false but this check should be made by the user
    // to continue forward
    checkbox: yup.boolean().oneOf([true], checkBoxRequired).required(checkBoxRequired),
  });

  return schema;
};

// Cancel Subscription Form Schema section ends

interface Props {
  setIsModalOpen: (open: boolean) => void;
}

const CancelSubscriptionModal = ({ setIsModalOpen }: Props) => {
  const dropDownOptions = useFetchDropdownOptions();
  const {
    toastMessage: { subscriptionCancelledSuccess, subscriptionCancelledFailed },
    CanelSubscription,
  } = useStrapiSettingsData();
  const {
    title,
    description,
    cancelButton,
    cancelSubscriptionButton,
    checkBoxLabel,
    ifOtherReasonLabel,
    reasonRequired,
    checkBoxRequired,
    ifOtherReasonPlaceholder,
  } = CanelSubscription;

  const cancelSubscriptionFormSchema = useMemo(() => {
    const schema = getCancelSubscriptionFormSchema({
      reasonRequired,
      checkBoxRequired,
    });

    return schema;
  }, [reasonRequired, checkBoxRequired]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      reason: '',
      otherReason: '',
      checkbox: false,
    },
    resolver: yupResolver(cancelSubscriptionFormSchema),
  });

  const [isOtherReason, setIsOtherReason] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const subscription = watch((value) =>
      // TODO: we should have a slug here?
      value.reason === 'other' ? setIsOtherReason(true) : setIsOtherReason(false)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (!data.checkbox) return;
    setIsLoading(true);
    const params = {
      reason: isOtherReason ? data.otherReason : data.reason,
    };
    cancelSubscription(params)
      .then(async (_) => {
        await queryClient.refetchQueries([FETCH_SUBSCRIPTION_DETAILS_QUERY_KEY]);
        toast.custom(
          (t) => (
            <Toast variant={'success'} Title={subscriptionCancelledSuccess} toastInstance={t} />
          ),
          { id: 'CANCEL_SUBSCRIPTION_TOAST_' + Math.floor(Math.random() * 3000).toString() }
        );
        setIsLoading(false);
        setIsModalOpen(false);
      })
      .catch((_) => {
        toast.custom(
          (t) => <Toast variant={'error'} Title={subscriptionCancelledFailed} toastInstance={t} />,
          { id: 'CANCEL_SUBSCRIPTION_TOAST_' + Math.floor(Math.random() * 3000).toString() }
        );
        setIsLoading(false);
        setIsModalOpen(false);
      });
  };

  return (
    <div className="mx-1 flex flex-col gap-2 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
        {title}
      </Dialog.Title>
      <form className="w-full">
        <div className="-mx-3 mb-6 flex flex-wrap">
          <div className="mt-4 w-full px-3">
            <label
              className="mb-2 block text-xs font-medium normal-case tracking-wide text-zinc-500"
              htmlFor="grid-password"
            >
              {description}
            </label>
            <DropdownInput type={'select'} options={dropDownOptions} {...register('reason')} />
            {errors.reason && errors.reason.message && (
              <span className="text-xs text-red-600">{errors.reason.message}</span>
            )}
          </div>
          {isOtherReason && (
            <FormFieldWrapper className="mt-4 w-full px-3">
              <Label htmlFor="hq_request_description" label={ifOtherReasonLabel}></Label>
              <TextAreaInput
                id="hq_request_description"
                placeholder={ifOtherReasonPlaceholder}
                className="border border-zinc-700 bg-zinc-900"
                rows={3}
                {...register('otherReason')}
              ></TextAreaInput>
              {errors.otherReason && errors.otherReason.message && (
                <span className="text-xs text-red-600">{errors.otherReason.message}</span>
              )}
            </FormFieldWrapper>
          )}
          <div className="mt-4 w-full px-3">
            <CheckboxInput
              className="grow-0 bg-zinc-600"
              id="required_assessment"
              type="checkbox"
              {...register('checkbox')}
            />
            <Label
              htmlFor="required_assessment"
              label={checkBoxLabel}
              className="ml-1 text-xs font-normal text-zinc-300"
            />
            {errors.checkbox && errors.checkbox.message && (
              <span className="block text-xs text-red-600">{errors.checkbox.message}</span>
            )}
          </div>
        </div>
      </form>
      <div className="flex justify-end">
        <Dialog.Close className="px-7 text-zinc-50">{cancelButton}</Dialog.Close>
        <Button
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit(onSubmit)}
          className="btn inline-block h-[40px] !rounded bg-base-brand px-6 py-2 text-sm font-medium normal-case leading-tight text-white hover:bg-base-brand disabled:bg-base-brand disabled:text-white"
        >
          {isLoading ? (
            <div className="flex gap-2">
              {cancelSubscriptionButton}{' '}
              <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
            </div>
          ) : (
            cancelSubscriptionButton
          )}
        </Button>
      </div>
    </div>
  );
};

export default CancelSubscriptionModal;
