import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

type Props = LinkProps & {
  renderLink: boolean;
};

const ConditionalLink = (props: React.PropsWithChildren<Props>) => {
  const { renderLink, children, ...linkProps } = props;

  if (renderLink) {
    return <Link {...linkProps}>{children}</Link>;
  }

  return <>{children}</>;
};

export default ConditionalLink;
