import { Avatar } from 'react-daisyui';

interface Props {
  badgeName: string;
  badgePic: string;
}

const BadgeName = ({ badgeName, badgePic }: Props) => {
  const profilePic =
    badgePic ??
    `https://ui-avatars.com/api/?name=${badgeName}&background=random&rounded=true&bold=true&size=64`;
  return (
    <div className={'flex items-center gap-2 whitespace-nowrap'}>
      <Avatar
        src={profilePic}
        size={24}
        className="rounded-full border border-zinc-900"
        shape="circle"
        role="img"
        aria-label={badgeName}
      ></Avatar>
      <span>{badgeName}</span>
    </div>
  );
};

export default BadgeName;
