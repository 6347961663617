import { useStrapiSettingsData } from '../../../../../settings/hooks/useStrapiSettingsData';

interface Props {
  invoice: string;
}

const InvoiceCell = ({ invoice }: Props) => {
  const { billingHistory } = useStrapiSettingsData();
  return (
    <a
      className="inline-block whitespace-nowrap lowercase text-base-brand first-letter:uppercase"
      href={invoice}
      download
    >
      {billingHistory.downloadButton}
    </a>
  );
};

export default InvoiceCell;
