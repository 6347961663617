import clsx from 'clsx';

interface CourseStatusCellProps {
  containerClassName: string;
  display: string;
}
const CourseStatusCell = (props: CourseStatusCellProps) => {
  const { containerClassName, display } = props;
  return (
    <div
      className={clsx(
        'flex w-min items-center gap-[9px] rounded-[100px] py-1 px-3',
        containerClassName
      )}
    >
      <span className="text-xs font-bold">{display}</span>
    </div>
  );
};

export default CourseStatusCell;
