import { Navbar } from 'react-daisyui';
import '../../../../styles/header.css';

function TopNav() {
  return (
    <div className="w-full">
      <Navbar className=" gap-6 bg-card-bg p-[10px]">
        <div className="flex flex-col">
          <div className="header-title">Courses</div>
        </div>
      </Navbar>
    </div>
  );
}

export default TopNav;
