import { RouteObject } from 'react-router-dom';
import AllTeamsPage from '../pages/allTeamsPage';
import MyTeamPage from '../pages/myTeamPage';
import SinglePersonProfilePage from '../pages/SinglePersonProfilePage';
import TeamPage from '../pages/teamPage';

const routes: RouteObject[] = [
  {
    path: '/teams',
    element: <AllTeamsPage />,
  },
  {
    path: '/team/:id',
    element: <TeamPage />,
  },
  {
    path: '/my-team',
    element: <MyTeamPage />,
  },

  {
    path: '/team/member/:id',
    element: <SinglePersonProfilePage />,
  },
];

export default routes;
