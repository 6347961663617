const COURSES_BASE_PATH = '/courses';
const REPORTS_BASE_PATH = '/reports';
const MANAGE_COURSES_BASE_PATH = `${COURSES_BASE_PATH}/hq`;

const COURSES_API_PATHS = {
  HQ: {
    CREATE_COURSE: MANAGE_COURSES_BASE_PATH,
    CREATE_COURSE_IN_MULTI_LANG: `${MANAGE_COURSES_BASE_PATH}/multi-lang`,
    GET_MODULES: (id: string) => `${MANAGE_COURSES_BASE_PATH}/${id}/basic-modules`,
    GET_COURSE_BASIC_DETAILS: (id: number) => `${MANAGE_COURSES_BASE_PATH}/${id}/details`,
    UPDATE_COURSE_DETAILS: (id: number) => `${MANAGE_COURSES_BASE_PATH}/${id}/details`,
    PUBLISH_COURSE: (id: number) => `${MANAGE_COURSES_BASE_PATH}/${id}/live`,
    GET_ASSESSMENT_BASIC_DETAILS: (assesmentId: number) =>
      `${MANAGE_COURSES_BASE_PATH}/assessment/${assesmentId}`,
    GET_ASSESSMENT_QUESTIONS: (assesmentId: number) =>
      `${MANAGE_COURSES_BASE_PATH}/assessment/${assesmentId}/questions`,
    GET_ASSESSMENT_QUESTIONS_BY_ID: (questionId: number) =>
      `${MANAGE_COURSES_BASE_PATH}/questions/${questionId}`,
    CREATE_MODULE: `${MANAGE_COURSES_BASE_PATH}/module`,
    CREATE_MODULE_IN_MULTI_LANG: `${MANAGE_COURSES_BASE_PATH}/module/multi-lang`,
    UPDATE_MODULE: `${MANAGE_COURSES_BASE_PATH}/module`,
    GET_MODULE_BASIC_DETAILS: (moduleId: number) =>
      `${MANAGE_COURSES_BASE_PATH}/module/${moduleId}`,
    UPDATE_MODULE_ORDER: `${MANAGE_COURSES_BASE_PATH}/order-modules`,
    UPDATE_ASSESSMENT_QUESTION_ORDER: `${MANAGE_COURSES_BASE_PATH}/order-questions`,
    ASSESSMENT_QUESTION: `${MANAGE_COURSES_BASE_PATH}/question`,
    ASSESSMENT_QUESTION_IN_MULTI_LANG: `${MANAGE_COURSES_BASE_PATH}/question/multi-lang`,
    MODULE: `${MANAGE_COURSES_BASE_PATH}/module`,
    GET_USHG_ADMIN_COURSE_MODULES: (courseId: number) =>
      `${MANAGE_COURSES_BASE_PATH}/v1/${courseId}/modules`,
    GET_USHG_ADMIN_COURSE_FEEDBACKS: (courseId: number) =>
      `${MANAGE_COURSES_BASE_PATH}/v1/${courseId}/feedbacks`,
  },
  GET_SPEAKERS: `${COURSES_BASE_PATH}/speakers`,
  GET_CATEGORIES: `${COURSES_BASE_PATH}/categories`,
  GET_TAGS: `${COURSES_BASE_PATH}/tags`,
  GET_COURSE_STATISTICS: (id: number) => `${REPORTS_BASE_PATH}/hq/v1/course/statistic/${id}`,
};

export { COURSES_API_PATHS, MANAGE_COURSES_BASE_PATH };
