import { Info, Plus } from 'phosphor-react';
import * as yup from 'yup';
import React, { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks';
import { RootState } from '../../../../../../../store';
import { INVITEE_ROLE, Member, MEMBER_ROLE } from '../../../../../types';
import { removeMemberFromTeam, removeTeam } from '../../../../../slices/onboarding.slice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStrapiOnBoardingData } from '../../../../../hooks/useStrapiOnBoardingData';
import Dialog from '../../../../../../../components/Dialog';
import ModalErrorAlert from '../../../../../../teams/components/Modal/ModalAlert/ModalErrorAlert';
import AddTeamMain from '../../../../../../teams/components/Modal/AddTeamModal/AddTeamMain';
import AddNewMemberMain from '../../../../../../teams/components/Modal/AddNewMemberModal/AddNewMemberMain';
import { RoleOptions } from '../../../../../../teams/types/interface';
import TooltipWrapper from '../../../../../../../components/TooltipWrapper/TooltipWrapper';
import useUserTypeToDisplay from '../../../../../../../hooks/useUserTypeToDisplay';

// Team Form Schema section starts

// Yup Schema

const inviteTeamFormSchema = yup.object({
  teamName: yup
    .string()
    .min(3, 'Team Name must have a minimum of 3 characters')
    .max(60, 'Team Name should be a maximum of 60 characters')
    .required('Team Name is required')
    .label('Team Name and Members'),
  teamAlreadyExist: yup.string(),
});

type INVITE_TEAM_FORM_DATA = yup.InferType<typeof inviteTeamFormSchema>;

const Teams = () => {
  // Form initialization
  const {
    formState: { errors },
  } = useForm<INVITE_TEAM_FORM_DATA>({
    mode: 'onChange',
    resolver: yupResolver(inviteTeamFormSchema),
  });

  // strapi
  const {
    inviteMember,
    teamTooltip: { tooltipContent, tooltipSubContent, tooltipSRLabel },
  } = useStrapiOnBoardingData();
  const { teamInviteTitle, addTeamMemberButton, addTeamButton, removeButton, addTeamTitle } =
    inviteMember;
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useAppDispatch();
  const onboardingStateSelector = (state: RootState) => state.onboarding;
  const { teams } = useAppSelector(onboardingStateSelector);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const style = {
    fontFeatureSettings: '"salt" off',
  };

  // Whether we are allowed to remove the manager/learner from the team
  const allowRemoveForTeam = useMemo(() => {
    const allowRemoveForTeam: Record<number, Record<string, boolean>> = {};
    teams.forEach((team, index) => {
      const roles = team.members.map((m) => String(m.role));
      const allowRemoveTeamLearner = roles.filter((r) => r === MEMBER_ROLE.User).length > 1;
      const allowRemoveTeamManager = roles.filter((r) => r === MEMBER_ROLE.Manager).length > 1;

      allowRemoveForTeam[index] = {
        [MEMBER_ROLE.User]: allowRemoveTeamLearner,
        [MEMBER_ROLE.Manager]: allowRemoveTeamManager,
      };
    });

    return allowRemoveForTeam;
  }, [teams]);
  const { USER_ROLE_DISPLAY_NAME_MAP } = useUserTypeToDisplay();

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex items-center gap-2">
        <h1 className="text-lg font-bold text-zinc-50 lg:text-xl">{teamInviteTitle}</h1>
        <TooltipWrapper
          content={tooltipContent}
          subContent={tooltipSubContent}
          ariaLabel={tooltipSRLabel}
          direction={'top'}
          alignment={'start'}
        >
          <Info size={20} color={'white'} />
        </TooltipWrapper>
      </div>
      <div className="rounded-lg bg-zinc-900 p-4">
        {teams.map((team, teamIndex) => (
          <React.Fragment key={teamIndex}>
            <div key={teamIndex} className="p-2">
              <form style={style} className="flex flex-col gap-3">
                {team.teamName && (
                  <div className="flex items-start justify-between">
                    <div className="flex flex-col gap-1.5">
                      <span className="text-xs font-semibold text-zinc-400">{addTeamTitle}</span>
                      <span className="break-words text-sm font-normal text-zinc-300">
                        {team.teamName}
                      </span>
                    </div>
                    <span
                      className="cursor-pointer text-sm font-semibold text-base-brand"
                      onClick={() => dispatch(removeTeam(currentIndex))}
                    >
                      {removeButton}
                    </span>
                  </div>
                )}
                {team.members.length > 0 && (
                  <div className="flex flex-col gap-1.5">
                    {/* TODO: Strapi Pending */}
                    <span className="text-xs font-semibold text-zinc-400">Members</span>
                    <div className="flex flex-col justify-between gap-0.5">
                      {team.members.map((data: Member, index) => {
                        return (
                          <div key={index} className="flex items-start justify-between gap-1">
                            <div className="break-all text-sm font-normal text-zinc-300">
                              {`${data.email} (${USER_ROLE_DISPLAY_NAME_MAP[String(data.role)]})`}
                            </div>
                            {allowRemoveForTeam[teamIndex][String(data.role)] && (
                              <span
                                key={index}
                                className="cursor-pointer text-sm font-semibold text-base-brand"
                                onClick={() =>
                                  dispatch(
                                    removeMemberFromTeam({
                                      index: currentIndex,
                                      memberEmail: data.email,
                                    })
                                  )
                                }
                              >
                                {removeButton}
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                <ModalErrorAlert errors={errors} index={0} />
              </form>
            </div>
            <Dialog
              containerClassName="max-h-[80vh] overflow-y-auto"
              triggerClassName="p-2 text-base-brand"
              open={isAddMemberModalOpen}
              onOpenChange={(open) => setIsAddMemberModalOpen(open)}
              Content={
                <AddNewMemberMain
                  currentIndex={teamIndex}
                  setCurrentIndex={setCurrentIndex}
                  setIsModalOpen={setIsAddMemberModalOpen}
                  INVITEE={INVITEE_ROLE.MEMBER}
                  isOnBoarding={true}
                  dropDownOptions={RoleOptions.MEMBER_MANAGER}
                  isLoading={false}
                  isAllMembers={false}
                />
              }
              onInteractOutside={(e) => {
                e.preventDefault();
              }}
            >
              <div className="flex items-center gap-2">
                <Plus size={16} color="#F7634F" weight="bold" />
                <p className="text-sm font-medium">{addTeamMemberButton}</p>
              </div>
            </Dialog>
          </React.Fragment>
        ))}
        {teams.length === 0 && (
          <Dialog
            containerClassName="max-h-[80vh] items-center overflow-y-scroll"
            triggerClassName="p-2 text-base-brand"
            open={isAddMemberModalOpen}
            onOpenChange={(open) => setIsAddMemberModalOpen(open)}
            Content={
              <AddTeamMain
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                setIsModalOpen={setIsAddMemberModalOpen}
                isOnBoarding={true}
              />
            }
            onInteractOutside={(e) => {
              e.preventDefault();
            }}
          >
            <div className="flex items-center gap-2">
              <Plus size={16} color="#F7634F" weight="bold" />
              <p className="text-sm font-medium">{addTeamButton}</p>
            </div>
          </Dialog>
        )}
      </div>
    </div>
  );
};
export default Teams;
