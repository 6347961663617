import { useQuery } from '@tanstack/react-query';
import { getCategories } from '../api';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import React from 'react';
import { useStrapiHQAdminCoursesData } from './useStrapiHQAdminCourseData';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';

interface Props {
  language?: string;
  isEnabled?: boolean;
}

const useGetCategoriesQuery = ({
  language = DEFAULT_PREFERRED_LANGUAGE,
  isEnabled = true,
}: Props) => {
  const {
    toastMessages: { categoriesFetchErrorTitle, categoriesFetchErrorMessage },
  } = useStrapiHQAdminCoursesData();
  const { data, isLoading } = useQuery({
    queryKey: ['get-all-categories', language],
    queryFn: () => getCategories(language),
    select: (data: any) =>
      data.map((categories: { name: string; id: number }) => ({
        label: categories.name,
        value: categories.id,
      })),
    onError: (error) => {
      console.error('Error while fetching categories %o', error);
      toast.custom((t) => (
        <Toast
          variant="error"
          Title={categoriesFetchErrorTitle}
          SubTitle={categoriesFetchErrorMessage}
          toastInstance={t}
        />
      ));
    },
    enabled: isEnabled,
  });
  return { data, isLoading };
};

export default useGetCategoriesQuery;
