import Dialog from '../../../../../../../../components/Dialog';
import React from 'react';
import { Star } from 'phosphor-react';
type feedbackViewType = {
  feedback: any;
};
const FeedbackView = ({ feedback }: feedbackViewType) => {
  return (
    <div className="mx-3 flex h-fit flex-col gap-5 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] sm:h-fit xs:p-6 ">
      <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
        Feedback
      </Dialog.Title>
      <div className="flex flex-col gap-1">
        <p className="text-sm font-medium text-zinc-400">Feedback Given</p>
        <p className="text-sm font-normal text-zinc-50">{feedback.comments}</p>
      </div>
      <div className="flex flex-col gap-1">
        <p className="text-sm font-medium text-zinc-400">Rating</p>
        <div className="flex flex-row gap-2">
          <div className="flex flex-row items-center gap-2">
            {[1, 2, 3, 4, 5].map((value) => {
              const isFilled = value <= feedback.rating;
              return (
                <Star
                  size={18}
                  key={value}
                  color={isFilled ? '#FBBF24' : '#52525B'}
                  weight={isFilled ? 'fill' : undefined}
                />
              );
            })}
          </div>
          <p className="text-sm text-zinc-300">
            {feedback.rating % 1 === 0 ? parseInt(feedback.rating) : feedback.rating}
            &nbsp;
            {feedback.rating === 1 ? 'Star' : 'Stars'}
          </p>
        </div>
      </div>
      <div className="border-t border-zinc-700" />
      <div className="flex flex-wrap gap-5">
        <div className="flex flex-col">
          <p className="text-sm font-medium text-zinc-400">Submitted by</p>
          <p className="text-sm font-normal text-zinc-50">{feedback.createdBy}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-sm font-medium text-zinc-400">Organisation</p>
          <p className="text-sm font-normal text-zinc-50">{feedback.organizationName}</p>
        </div>
      </div>
      <Dialog.Close
        type="button"
        className="w-fit self-end rounded bg-zinc-900 px-8 py-2 text-xs font-medium text-white"
      >
        Close
      </Dialog.Close>
    </div>
  );
};

export default FeedbackView;
