import { useStrapiSettingsData } from './useStrapiSettingsData';

const useFetchDropdownOptions = () => {
  const { CancelSubscriptionReasons } = useStrapiSettingsData();
  const dropdownOptions: { value: string; label: string }[] = [];
  CancelSubscriptionReasons.forEach(
    (element: { __typename: string; value: string; label: string }) => {
      if (element.__typename === 'ComponentSettingCanceSubDropDown') {
        dropdownOptions.push({ value: element.value, label: element.label });
      }
    }
  );
  return dropdownOptions;
};
export default useFetchDropdownOptions;
