import clsx from 'clsx';
import { InView } from 'react-intersection-observer';
import FadeLoader from 'react-spinners/FadeLoader';
import cn from '../../utils/cn';
import { ErrorDisplay } from '../DataTables';
import LoadingMoreMessage from './LoadingMoreMessage';
import ReachedEndMessage from './ReachedEndMessage';

interface Props {
  className?: string;
  fetchNextPage: () => void;
  hasData?: boolean;
  isError: boolean;
  isFetchingNextPage: boolean;
  isInitialLoading: boolean;
  hasNextPage: boolean;
  isMoreThanOnePage?: boolean;
  allItemsFetchedMessage?: string;
  loadingMoreItemsMessage: string;
  errorMessage: string;
  rootMargin?: string;
  refetch: () => void;
  allowsRefetch?: boolean;
  isRefetching: boolean;
  initialLoaderClassName?: string;
  errorDisplayClassName?: string;
  showReachedEndMessage?: boolean;
}

const InfiniteScrollV1 = (props: React.PropsWithChildren<Props>) => {
  const {
    className,
    hasData,
    isError,
    isFetchingNextPage,
    isInitialLoading,
    hasNextPage,
    isMoreThanOnePage,
    allItemsFetchedMessage,
    loadingMoreItemsMessage,
    errorMessage,
    rootMargin,
    refetch,
    allowsRefetch,
    isRefetching,
    children,
    fetchNextPage,
    initialLoaderClassName,
    errorDisplayClassName,
    showReachedEndMessage = true,
  } = props;

  // List Loading State
  if (isInitialLoading) {
    return (
      <div
        className={clsx(
          'flex h-full min-h-[256px] items-center justify-center text-[#E4E4E7]',
          initialLoaderClassName
        )}
      >
        <FadeLoader color="currentColor" width={2} height={12} radius={3} />
      </div>
    );
  }

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      {/* has Data */}
      {hasData ? (
        <>
          {/* Should render a list */}
          {children}
          {!isError ? (
            <>
              <InView
                as="div"
                onChange={(inView) => {
                  if (inView && hasNextPage) {
                    fetchNextPage();
                  }
                }}
                rootMargin={rootMargin ?? '0px 0px 120px 0px'}
                className="block"
                role="presentation"
              ></InView>
              {isFetchingNextPage && <LoadingMoreMessage message={loadingMoreItemsMessage} />}
              {/* When there are no more pages and fetched pages are more than one */}
              {showReachedEndMessage && !hasNextPage && (isMoreThanOnePage ?? true) && (
                <ReachedEndMessage message={allItemsFetchedMessage} />
              )}
            </>
          ) : null}
        </>
      ) : null}
      {/* has Error */}
      {isError && (
        <div className="flex min-h-[128px] justify-center">
          <ErrorDisplay
            refetch={refetch}
            message={errorMessage}
            allowsRefetch={allowsRefetch ?? true}
            isRefetching={isRefetching}
            className={cn('gap-2 py-4', errorDisplayClassName)}
          ></ErrorDisplay>
        </div>
      )}
    </div>
  );
};

export default InfiniteScrollV1;
