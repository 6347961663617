import * as React from 'react';
import { MessageUpdateCallback } from '../hooks';

interface ChimeMessagingServiceContextValue {
  closeSession: () => void;
  subscribeToMessageUpdate: (cb: MessageUpdateCallback) => void;
  unsubscribeFromMessageUpdate: (cb: MessageUpdateCallback) => void;
}

const chimeMessagingServiceContext = React.createContext<ChimeMessagingServiceContextValue | null>(
  null
);

export default chimeMessagingServiceContext;
