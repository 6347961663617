import clsx from 'clsx';
import { CaretDoubleLeft, CaretDoubleRight } from 'phosphor-react';
import { useContext } from 'react';
import { useStrapiSideNavData } from '../../hooks/useStrapiSideNavData';
import sideBarContext from './context/sideBarContext';
import SideBarActionItem from './SideBarActionItem';

interface SideBarCollapseItemProps {
  className?: string;
  children?: React.ReactNode[];
}

// SideBar collapse option
const SideBarCollapse = ({ className, children }: SideBarCollapseItemProps) => {
  const { setIsSidebarCollapsed, isSidebarCollapsed } = useContext(sideBarContext);

  const LeadingIcon = isSidebarCollapsed ? CaretDoubleRight : CaretDoubleLeft;

  const toggleSidebarCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };
  const { buttons } = useStrapiSideNavData();
  return (
    <SideBarActionItem
      onClick={toggleSidebarCollapse}
      className={clsx('cursor-pointer hover:bg-[rgba(64,64,73,0.5)]', className)}
    >
      {children ?? (
        <>
          <LeadingIcon className="text-zinc-500" size={20} />
          {!isSidebarCollapsed && (
            <span className="text-sm font-normal text-white">{buttons.collapseButton}</span>
          )}
        </>
      )}
    </SideBarActionItem>
  );
};

export default SideBarCollapse;
