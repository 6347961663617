import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { USERS_API_PATHS } from '../constants';
import { AllMembers, AllMembersFilters } from '../types';

/**
 * Get all members from the organization or team based on the role of the authenticated user
 */

const getAllMembers = async (filters: AllMembersFilters): Promise<AllMembers> => {
  const queryString = qs.stringify(filters);
  const path = USERS_API_PATHS.GET_ALL_MEMBERS;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

const getTeams = (includeSubTeams = true) => {
  const queryString = qs.stringify({ includeSubTeams });
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${USERS_API_PATHS.GET_TEAMS}?${queryString}`, {
    response: true,
    withCredentials: true,
  });
};
export { getAllMembers, getTeams };
