import { Option } from '../../../components/Select';

const ENT_ADMIN_STATUS_SELECT_OPTIONS: Option[] = [
  {
    display: 'Open',
    value: 'ACTIVE',
  },
  {
    display: 'Completed',
    value: 'COMPLETED',
  },
];

const USHG_ADMIN_STATUS_SELECT_OPTIONS: Option[] = [
  {
    display: 'Open',
    value: 'ACTIVE',
  },
  {
    display: 'Resolved',
    value: 'COMPLETED',
  },
];

const REQUESTS_SORT_SELECT_OPTIONS: Option[] = [
  {
    display: 'Most Recent',
    value: 'MOST_RECENT',
  },
  {
    display: 'Oldest',
    value: 'EARLIEST',
  },
];

export {
  REQUESTS_SORT_SELECT_OPTIONS,
  ENT_ADMIN_STATUS_SELECT_OPTIONS,
  USHG_ADMIN_STATUS_SELECT_OPTIONS,
};
