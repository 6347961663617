import React, { useState } from 'react';
import { TabGroup } from '../../../../components/Tabs';
import { useStrapiReportsData } from '../../../shared/hooks/useStrapiReportData';
import { InfiniteMembersList } from '../common/IndividualReports';
import PageHeader from '../common/PageHeader';
import EnterpriseLeaderOverallReports from './EnterpriseLeaderOverallReports';
import { TAB_GROUPS } from '../../../../constants/tabs-tag-constants';
type props = {
  lazyLoad?: boolean;
};
const EnterpriseLeaderReports = ({ lazyLoad = true }: props) => {
  const { Tabs, title } = useStrapiReportsData().topNav;
  const { overallPerformanceTabLabel, individualPerformanceTabLabel } = Tabs;
  const [queryParam, setQueryParam] = useState<string>();
  const TABS = [
    {
      title: overallPerformanceTabLabel,
      tag: TAB_GROUPS.OVERALL_PERFORMANCE,
      children: <EnterpriseLeaderOverallReports lazyLoad={lazyLoad} />,
    },
    {
      title: individualPerformanceTabLabel,
      tag: TAB_GROUPS.INDIVIDUAL_PERFORMANCE,
      children: <InfiniteMembersList />,
    },
  ];
  return (
    <main id="main-content" className="flex h-full w-full flex-col">
      <PageHeader
        reportsHeader={title}
        queryParam={queryParam}
        individualPerformanceTabLabel={individualPerformanceTabLabel}
      />
      <TabGroup
        className="flex h-full w-full justify-center px-3 py-6 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9"
        tabs={TABS}
        setQueryParam={setQueryParam}
        tabClassName="px-3 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9 shrink-0"
      />
    </main>
  );
};

export default EnterpriseLeaderReports;
