import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-hot-toast';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import {
  CourseCreateInMultiLangMutationType,
  CourseCreateMutationType,
  CourseUpdateMutationType,
  ModalType,
} from '../../../../types';
import {
  CheckboxInput,
  Label,
  TextAreaInput,
  TextInput,
} from '../../../../../../components/FormElements';
import { Toast } from '../../../../../../components/Toast';
import ErrorMessage from '../../../../../../components/FormElements/ErrorMessage';
import Dialog from '../../../../../../components/Dialog';
import { FormActionButton, FormFieldWrapper } from '../../../common/FormControls';
import {
  useCourseCreateInMultiLangMutation,
  useCourseCreateMutation,
  useCourseUpdateMutation,
  useGetCategoriesQuery,
  useGetSpeakersQuery,
  useMediaUploadManager,
} from '../../../../hooks';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { Uploader, UploaderInit } from '../../../../../../api/s3/multipart.helper';
import { fieldHasError } from '../../../../../../utils/react-hook-form';
import { finalizeMultipartUpload, getPreSignedUrlCourse } from '../../../../../../api';
import MediaUploadDropZone from '../../../../../../components/DropZone/MediaUploadDropZone';
import useUploadImageQuery from '../../../../../shared/hooks/useUploadImageQuery';
import { UPLOAD_REQUEST_TYPE } from '../../../../../shared/constants/imageUpload';
import { validateMediaFile } from '../../../../../shared/helpers/media.helper';
import useDebounce from '../../../../../../hooks/use-debounce';
import { useStrapiHQAdminCoursesData } from '../../../../hooks/useStrapiHQAdminCourseData';
import useGetAllTagsQuery from '../../../../hooks/useGetAllTagsQuery';
import { queryClient } from '../../../../../../config/react-query';
import { COURSES_ERROR_CODE } from '../../../../constants/courses-management';
import { generateCDNMediaAssetURLWithKey } from '../../../../../shared/helpers';
import { customStylesOne } from '../../../../../../styles/modalCustomStyle';
import cn from '../../../../../../utils/cn';
import { COURSE_ACTION } from '../../../../constants';
import { useStrapiSharedData } from '../../../../../shared/hooks/useStrapiSharedData';

interface CourseCreateModalProps {
  setIsCourseModalOpen: (open: boolean) => void;
  coursePreFetchData?: Record<string, any>;
  formActionButtonLabel: string;
  buttonIcon?: React.ReactNode;
  modalType: ModalType;
  refetch?: any;
  mappedOptions?: { display: string; value: string }[];
  language: string;
  errorType?: string | null;
  setErrorType?: (error: string | null) => void;
  setActionInProgress: (a: keyof typeof COURSE_ACTION | null) => void;
}
interface CourseModalSchema {
  nameRequired: string;
  nameMinValue: number;
  nameMinError: string;
  nameMaxValue: number;
  nameMaxError: string;
  descriptionRequired: string;
  descriptionMinValue: number;
  descriptionMinError: string;
  descriptionMaxValue: number;
  descriptionMaxError: string;
  speakerRequired: string;
  tagsMaxValue: number;
  tagsMaxError: string;
  tagsMinValue: number;
  tagsMinError: string;
  categoryRequired: string;
  coverImageRequired: string;
  trailerRequired: string;
  learnerGistMaxValue: number;
  learnerGistMaxError: string;
}
// for spliting the string and creating an array
const parseParagraphInput = (data: string) =>
  data.split('\n').filter((line) => line.trim().length !== 0);

// Yup Schema
function getSchema(data: CourseModalSchema) {
  const {
    nameRequired,
    nameMinValue,
    nameMinError,
    nameMaxValue,
    nameMaxError,
    descriptionRequired,
    descriptionMinValue,
    descriptionMinError,
    descriptionMaxValue,
    descriptionMaxError,
    speakerRequired,
    tagsMaxValue,
    tagsMaxError,
    tagsMinValue,
    tagsMinError,
    categoryRequired,
    coverImageRequired,
    learnerGistMaxValue,
    learnerGistMaxError,
  } = data;
  const courseFormSchema = yup.object({
    name: yup
      .string()
      .trim()
      .min(nameMinValue, nameMinError)
      .max(nameMaxValue, nameMaxError)
      .required(nameRequired)
      .label('name'),
    category: yup.string().required(categoryRequired).label('category'),
    tag: yup.array().min(tagsMinValue, tagsMinError).max(tagsMaxValue, tagsMaxError).label('tag'),
    speaker: yup.string().required(speakerRequired).label('headline speaker'),
    description: yup
      .string()
      .trim()
      .min(descriptionMinValue, descriptionMinError)
      .max(descriptionMaxValue, descriptionMaxError)
      .required(descriptionRequired)
      .label('description'),
    learnerGist: yup
      .string()
      .trim()
      .test('is-max-7-lines', learnerGistMaxError, (value) => {
        if (!value) {
          return true;
        }
        const lines = parseParagraphInput(value);
        if (lines.length > learnerGistMaxValue) {
          return false;
        } else {
          return true;
        }
      }),
    thumbnail: yup.string().required(coverImageRequired).label('thumbnail'),
    trailer: yup.string().label('trailer'),
    isFavorite: yup.boolean().default(false),
  });

  return courseFormSchema;
  // Yup Schema
}

// Form initialization
const CourseForm = ({
  setIsCourseModalOpen,
  coursePreFetchData,
  formActionButtonLabel,
  buttonIcon,
  modalType,
  refetch,
  mappedOptions,
  language,
  errorType,
  setErrorType,
  setActionInProgress,
}: CourseCreateModalProps) => {
  const navigate = useNavigate();

  const {
    courseModal,
    toastMessages: {
      createCourseSuccessTitle,
      createCourseFailureTitle,
      updateCourseSuccessTitle,
      updateCourseFailureTitle,
      createCourseSuccessMessage,
      createCourseFailureMessage,
      updateCourseSuccessMessage,
      updateCourseFailureMessage,
      createCourseMultLangSuccessMessage,
    },
  } = useStrapiHQAdminCoursesData();

  const {
    dropdownPlaceholderMessages: { loadingMessage, noOptionsMessage },
  } = useStrapiSharedData();

  const {
    nameLabel,
    namePlaceholder,
    descriptionLabel,
    descriptionPlaceholder,
    speakerLabel,
    speakerPlaceholder,
    tagsLabel,
    tagsPlaceholder,
    catergoryLabel,
    categoryPlaceholder,
    coverImageLabel,
    coverImageType,
    coverImageMaxValue,
    trailerType,
    trailerLabel,
    trailerMaxValue,
    leanerGistLabel,
    learnerGistPlaceholder,
    closeButton,
    isFeaturedCourseLabel,
  } = courseModal;
  const courseFormSchema = getSchema(courseModal);
  type COURSE_MODAL_FORM_DATA = yup.InferType<typeof courseFormSchema>;

  //  Form Declaration
  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    trigger,
    reset,
    formState: { errors },
  } = useForm<COURSE_MODAL_FORM_DATA>({
    mode: 'onSubmit',
    resolver: yupResolver(courseFormSchema),
  });

  // State to manage field disabling
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);

  //  Media uploading utilities

  //  Trailer upload
  const [trailerUploader, setTrailerUploader] = useState<any>(undefined);

  const {
    isProgress: trailerIsProgress,
    progress: trailerProgress,
    isUploaded: trailerIsUploaded,
    data: trailerData,
    initiating: trailerInitiating,
    aborted: trailerAborted,
  } = useMediaUploadManager({
    uploader: trailerUploader,
  });

  // State to manage media upload
  const [isMediaUploaded, setIsMediaUploaded] = useState(trailerIsUploaded);

  const onDropTrailer = useCallback(
    async (acceptedFiles: any) => {
      const { error, message, type } = validateMediaFile(acceptedFiles, 1024, 'video', 'Trailer');
      if (error) {
        setError('trailer', { message });
      } else {
        const videoUploaderOptions: UploaderInit = {
          file: acceptedFiles[0],
          fileExtension: type,
          finalizeMultipartUpload: finalizeMultipartUpload,
          preSignedUrlFetch: getPreSignedUrlCourse,
          module: 'course',
          fileType: acceptedFiles[0].type,
        };
        const uploader = new Uploader(videoUploaderOptions, {});
        setTrailerUploader(uploader);
      }
    },
    [setError]
  );

  const onTrailerUploadCancel = () => {
    if (trailerUploader) {
      trailerUploader.abort();
    }
  };

  useEffect(() => {
    if (trailerData) {
      setValue('trailer', trailerData.key);
      trigger('trailer');
    }
  }, [trailerData, setValue, trigger]);

  //  Thumbnail upload
  const [thumbnailUploader, setThumbnailUploader] = useState<any>(undefined);

  const {
    isProgress: thumbnailProgress,
    progress: thumbnailProgressPercent,
    initiating: thumbnailInitiating,
    isUploaded: thumbnailUploaded,
    data: thumbnailData,
    abort: thumbnailAbort,
  } = useUploadImageQuery({ upload: thumbnailUploader, type: 'course' });

  // State to manage thumbnail upload
  const [isThumbnailUploaded, setIsThumbnailUploaded] = useState(thumbnailUploaded);

  const onDropThumbnail = useCallback(
    (acceptedFiles: any) => {
      const { error, message, type } = validateMediaFile(acceptedFiles, 10, 'image', 'Thumbnail');
      if (error) {
        setError('thumbnail', { message });
      } else {
        const uploader = {
          fileType: type,
          requestType: UPLOAD_REQUEST_TYPE.SINGLE_OBJECT,
          file: acceptedFiles[0],
          module: 'course',
          fileExtension: acceptedFiles[0].type,
        };
        setThumbnailUploader(uploader);
      }
    },
    [setError]
  );

  const onThumbnailUploadCancel = () => {
    if (thumbnailUploader) {
      thumbnailAbort.abort();
    }
  };

  useEffect(() => {
    if (thumbnailData) {
      setValue('thumbnail', thumbnailData);
      trigger('thumbnail');
    }
  }, [thumbnailData, setValue, trigger]);

  useEffect(() => {
    setIsMediaUploaded(trailerIsUploaded);
  }, [trailerIsUploaded]);

  useEffect(() => {
    setIsThumbnailUploaded(thumbnailUploaded);
  }, [thumbnailUploaded]);

  //  Mutation Hooks
  const createCourseMutation = useCourseCreateMutation();
  const createCourseInMultiLangMutation = useCourseCreateInMultiLangMutation();
  const updateCourseMutation = useCourseUpdateMutation();

  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const debounceSearch = useDebounce(searchString);

  //  Drop down fetch hooks
  const speakersFetch = useGetSpeakersQuery();

  const categoriesFetch = useGetCategoriesQuery({ language: language });

  const allTagsData = useGetAllTagsQuery({
    search: debounceSearch,
    language: language,
  });

  const dropdownTagsData = useMemo(() => {
    return allTagsData.data?.pages.flatMap((x) => x.results) ?? [];
  }, [allTagsData.data]);

  useEffect(() => {
    const setCreateCourseValues = () => {
      if (coursePreFetchData?.data && categoriesFetch?.data) {
        const matchedCategory = categoriesFetch.data.find(
          (category: { value: number; label: string }) =>
            category.value === coursePreFetchData.data.category.id
        );
        setValue('category', matchedCategory.value);
        setValue('speaker', `${coursePreFetchData.data.headlineSpeaker.id}`);
      }
    };

    const setEditCourseValues = () => {
      if (coursePreFetchData?.data && categoriesFetch?.data) {
        const matchedCategory = categoriesFetch.data.find(
          (category: { value: number; label: string }) =>
            category.value === coursePreFetchData.data.category.id
        );
        setValue('category', matchedCategory ? matchedCategory.value : '', {
          shouldValidate: true,
        });
        setValue('name', coursePreFetchData.data.name || '');
        setValue(
          'tag',
          coursePreFetchData.data.tagsList
            ? coursePreFetchData.data.tagsList.map((tag: { name: string }) => ({
                label: tag.name,
                value: tag.name,
              }))
            : [],
          { shouldValidate: true }
        );
        setValue(
          'speaker',
          coursePreFetchData.data.headlineSpeaker
            ? `${coursePreFetchData.data.headlineSpeaker.id}`
            : '',
          { shouldValidate: true }
        );
        setValue('description', coursePreFetchData.data.description || '');
        setValue('learnerGist', coursePreFetchData.data.thingsToLearn.join('\n') || '');
        setValue('thumbnail', coursePreFetchData.data.thumbnailUrl || '');
        setValue('trailer', coursePreFetchData.data.previewUrl || '');
        setValue('isFavorite', coursePreFetchData.data.isFavorite || false);
      }
    };

    reset();

    if (coursePreFetchData?.data && categoriesFetch?.data) {
      if (modalType === 'manage' && errorType === COURSES_ERROR_CODE.COURSE_NOT_FOUND) {
        // Handle Create Mode
        setIsFieldDisabled(true); // Disable fields in create mode
        setCreateCourseValues(); // Prepopulate fields in create mode
      } else if (modalType === 'manage' || modalType === 'update') {
        setIsFieldDisabled(modalType === 'manage'); // Disable fields in manage mode, enable in update mode
        setEditCourseValues(); // Set all values for manage or update mode
      }
    }
  }, [coursePreFetchData?.data, errorType, categoriesFetch?.data, modalType, reset, setValue]);

  const onBottomScrollHandler = () => {
    if (allTagsData.hasNextPage) {
      allTagsData.fetchNextPage();
    }
  };

  const courseCreateHandler = async (data: COURSE_MODAL_FORM_DATA) => {
    const learnerGist = data.learnerGist ? parseParagraphInput(data.learnerGist.trim()) : undefined;
    const createCourse: CourseCreateMutationType = {
      tags: data.tag?.map((t) => t.value),
      categoryId: parseInt(data.category),
      description: data.description,
      name: data.name,
      thumbnailUrl: data.thumbnail,
      headlineSpeakerId: parseInt(data.speaker),
      thingsToLearn: learnerGist,
      previewUrl: data.trailer || '',
      isFavorite: data.isFavorite || false,
    };
    setActionInProgress('create');
    createCourseMutation.mutate(createCourse, {
      onSuccess: (course) => {
        toast.custom((t) => {
          setIsCourseModalOpen(false);
          return (
            <Toast
              variant="success"
              Title={createCourseSuccessTitle}
              SubTitle={createCourseSuccessMessage}
              toastInstance={t}
            />
          );
        });
        navigate(`/manage/courses/${course.data.data.id}/edit`);
      },
      onError: (error) => {
        console.error('Error while creating course %o', error);
        toast.custom((t) => (
          <Toast
            variant="error"
            Title={createCourseFailureTitle}
            SubTitle={createCourseFailureMessage}
            toastInstance={t}
          />
        ));
      },
      onSettled: () => {
        setActionInProgress(null);
      },
    });
  };

  const courseCreateInMultiLangHandler = async (data: COURSE_MODAL_FORM_DATA) => {
    if (coursePreFetchData?.data) {
      const learnerGist = data.learnerGist
        ? parseParagraphInput(data.learnerGist.trim())
        : undefined;
      const createCourseInMultiLang: CourseCreateInMultiLangMutationType = {
        courseId: coursePreFetchData?.data?.id,
        tags: data.tag?.map((t) => t.value),
        description: data.description,
        name: data.name,
        thumbnailUrl: data.thumbnail,
        thingsToLearn: learnerGist,
        previewUrl: data.trailer || '',
        language: language,
      };
      setActionInProgress('create');
      createCourseInMultiLangMutation.mutate(createCourseInMultiLang, {
        onSuccess: (course) => {
          toast.custom((t) => {
            setIsCourseModalOpen(false);
            return (
              <Toast
                variant="success"
                Title={createCourseSuccessTitle}
                SubTitle={createCourseMultLangSuccessMessage.replace(
                  '{{language}}',
                  mappedOptions?.find((option) => option.value === language)?.display
                )}
                toastInstance={t}
              />
            );
          });
          const courseBasicDetailsQueryKey = [
            {
              courseId: course.data.data.id,
              language,
              scope: 'courses',
              item: 'courses-basic-details',
            },
          ];

          queryClient.refetchQueries({ queryKey: courseBasicDetailsQueryKey });
          setErrorType && setErrorType(null);
          navigate(`/manage/courses/${course.data.data.id}/edit`);
        },
        onError: (error) => {
          console.error('Error while creating course %o', error);
          toast.custom((t) => (
            <Toast
              variant="error"
              Title={createCourseFailureTitle}
              SubTitle={createCourseFailureMessage}
              toastInstance={t}
            />
          ));
        },
        onSettled: () => {
          setActionInProgress(null);
        },
      });
    }
  };

  const courseUpdateHandler = async (data: COURSE_MODAL_FORM_DATA) => {
    if (coursePreFetchData?.data) {
      const learnerGist = data.learnerGist
        ? parseParagraphInput(data.learnerGist.trim())
        : undefined;
      const updateCourse: CourseUpdateMutationType = {
        id: coursePreFetchData?.data.id,
        tags: data.tag?.map((t) => t.value) || [],
        categoryId: parseInt(data.category),
        description: data.description,
        name: data.name,
        thumbnailUrl: data.thumbnail,
        headlineSpeakerId: parseInt(data.speaker),
        thingsToLearn: learnerGist,
        previewUrl: data.trailer || '',
        isFavorite: data.isFavorite || false,
        language: language,
      };
      setActionInProgress('update');
      updateCourseMutation.mutate(
        {
          id: coursePreFetchData.data.id,
          update: updateCourse,
        },
        {
          onSuccess: () => {
            refetch();
            setIsCourseModalOpen(false);
            toast.custom((t) => {
              return (
                <Toast
                  variant="success"
                  Title={updateCourseSuccessTitle}
                  SubTitle={updateCourseSuccessMessage}
                  toastInstance={t}
                />
              );
            });
          },
          onError: (error) => {
            console.error('Error while updating course %o', error);
            toast.custom((t) => (
              <Toast
                variant="error"
                Title={updateCourseFailureTitle}
                SubTitle={updateCourseFailureMessage}
                toastInstance={t}
              />
            ));
          },
          onSettled: () => {
            setActionInProgress(null);
          },
        }
      );
    }
  };

  return (
    <form
      className="flex flex-col gap-4 overflow-y-scroll"
      onSubmit={(e) => {
        e.preventDefault();
        if (modalType === 'create') {
          handleSubmit(courseCreateHandler)(e);
        } else if (modalType === 'update') {
          handleSubmit(courseUpdateHandler)(e);
        } else if (modalType === 'manage') {
          if (errorType === COURSES_ERROR_CODE.COURSE_NOT_FOUND) {
            handleSubmit(courseCreateInMultiLangHandler)(e);
          } else {
            handleSubmit(courseUpdateHandler)(e);
          }
        }
      }}
    >
      <div className="flex w-full flex-col gap-5">
        <div className="flex flex-col gap-3 sm:flex-row">
          <div className="flex w-full flex-col gap-2">
            {/* Name */}
            <FormFieldWrapper className="h-full grow">
              <Label htmlFor="course_name" label={nameLabel} />
              <TextInput
                type="text"
                id="course_name"
                className="mt-0.5 max-h-[38px] rounded-md"
                placeholder={namePlaceholder}
                aria-invalid={fieldHasError(errors, 'name') ? 'true' : 'false'}
                hasError={fieldHasError(errors, 'name')}
                {...register('name')}
              />
              <HookFormErrorMessage
                name="name"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </FormFieldWrapper>
            {/* Name */}

            {/* Category */}
            <FormFieldWrapper className={cn('grow', isFieldDisabled && 'opacity-50')}>
              <Label htmlFor="course_category" label={catergoryLabel} />
              <Controller
                name="category"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Select
                    id="course_category"
                    options={categoriesFetch.data}
                    placeholder={categoryPlaceholder}
                    isLoading={categoriesFetch.isLoading}
                    styles={{
                      ...customStylesOne,
                      control: (provided) => ({
                        ...provided,
                        background: '#18181B',
                        border: '1px solid #3F3F46 !important',
                        borderRadius: '6px !important',
                        boxShadow: 'none !important',
                      }),
                    }}
                    classNames={{
                      control: () => {
                        return 'mt-0.5';
                      },
                    }}
                    value={
                      categoriesFetch.data &&
                      categoriesFetch.data.find(
                        (category: { value: string; label: string }) => category.value === value
                      )
                    }
                    onChange={(data) => {
                      onChange(data.value);
                    }}
                    aria-invalid={errors?.category ? 'true' : 'false'}
                    isDisabled={isFieldDisabled}
                  />
                )}
              />
              <HookFormErrorMessage
                name="category"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </FormFieldWrapper>
            {/* Category */}
          </div>
          <div className="flex w-full flex-col gap-2">
            {/* Tags */}
            <FormFieldWrapper className="h-full grow">
              <Label htmlFor="course_tags" label={tagsLabel} />
              <Controller
                name="tag"
                control={control}
                defaultValue={[]}
                render={({ field: { onChange, value } }) => (
                  <CreatableSelect
                    id="course_tags"
                    isMulti
                    className="mt-0.5 rounded-md"
                    onMenuScrollToBottom={onBottomScrollHandler}
                    options={dropdownTagsData}
                    placeholder={tagsPlaceholder}
                    styles={customStylesOne}
                    isLoading={allTagsData.isLoading || allTagsData.isFetchingNextPage}
                    value={(() => {
                      return value?.map(
                        (val: { value: string; label: string }) =>
                          dropdownTagsData.find(
                            (tag: { value: string }) => tag.value === val.value
                          ) || val
                      );
                    })()}
                    aria-invalid={fieldHasError(errors, 'tag') ? 'true' : 'false'}
                    onInputChange={(data) => {
                      setSearchString(data.length > 0 ? data : undefined);
                    }}
                    onChange={(data) => {
                      onChange(data);
                      trigger('tag');
                    }}
                    classNames={{
                      multiValueLabel: () => "before:content-['#']",
                      control: () => `overflow-x-auto`,
                    }}
                  />
                )}
              />
              <HookFormErrorMessage
                name="tag"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </FormFieldWrapper>
            {/* Tags */}

            {/* Headline Speaker */}
            <FormFieldWrapper className={cn('grow', isFieldDisabled && 'opacity-50')}>
              <Label htmlFor="course_headline_speaker" label={speakerLabel} />
              <Controller
                name="speaker"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Select
                    id="course_headline_speaker"
                    loadingMessage={() => loadingMessage}
                    noOptionsMessage={() => noOptionsMessage}
                    className="rounded-md"
                    options={speakersFetch.data?.speakers}
                    placeholder={speakerPlaceholder}
                    styles={{
                      ...customStylesOne,
                      control: (provided) => ({
                        ...provided,
                        background: '#18181B',
                        border: '1px solid #3F3F46 !important',
                        borderRadius: '6px !important',
                        boxShadow: 'none !important',
                      }),
                    }}
                    isLoading={speakersFetch.isLoading}
                    classNames={{
                      control: () => 'mt-0.5',
                    }}
                    value={speakersFetch.data?.speakers.find(
                      (option: { value: string; label: string }) => option.value === value
                    )}
                    aria-invalid={fieldHasError(errors, 'speaker') ? 'true' : 'false'}
                    onChange={(data) => {
                      onChange(data.value);
                    }}
                    isDisabled={isFieldDisabled}
                  />
                )}
              />
              <HookFormErrorMessage
                name="speaker"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </FormFieldWrapper>
            {/* Headline Speaker */}
          </div>
        </div>
        <div className="flex flex-col gap-3 sm:flex-row">
          <div className="flex w-full flex-col gap-2">
            {/* Cover Image */}
            <FormFieldWrapper className="grow">
              <Label htmlFor="course_cover_image" label={coverImageLabel} />
              <Controller
                name="thumbnail"
                control={control}
                defaultValue=""
                render={({ field: { value } }) => (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <MediaUploadDropZone
                      fileType={coverImageType}
                      maxSize={coverImageMaxValue}
                      onDropCallback={onDropThumbnail}
                      isProgress={thumbnailProgress}
                      isUploaded={value && value.length > 0 ? true : isThumbnailUploaded}
                      progress={thumbnailProgressPercent}
                      cancel={onThumbnailUploadCancel}
                      initiatingUpload={thumbnailInitiating}
                      loader="progress"
                      isPreviewImage={true}
                      previewImage={
                        value
                          ? generateCDNMediaAssetURLWithKey({
                              key: value,
                            })
                          : undefined
                      }
                      resetMedia={() => {
                        setValue('thumbnail', '');
                        trigger('thumbnail');
                        setIsThumbnailUploaded(false);
                      }}
                    />
                  </div>
                )}
              />

              <HookFormErrorMessage
                name="thumbnail"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </FormFieldWrapper>
            {/* Cover Image */}
          </div>
          <div className="flex w-full flex-col gap-2">
            {/* Trailer Video */}
            <FormFieldWrapper className="grow">
              <Label htmlFor="course_trailer_video" label={trailerLabel} />
              <Controller
                name="trailer"
                control={control}
                defaultValue=""
                render={({ field: { value } }) => (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <MediaUploadDropZone
                      fileType={trailerType}
                      maxSize={trailerMaxValue}
                      onDropCallback={onDropTrailer}
                      isProgress={trailerIsProgress}
                      isUploaded={value && value.length > 0 ? true : isMediaUploaded}
                      progress={trailerProgress}
                      cancel={onTrailerUploadCancel}
                      aborted={trailerAborted}
                      initiatingUpload={trailerInitiating}
                      loader="progress"
                      isPreviewMedia={true}
                      previewMedia={
                        value
                          ? generateCDNMediaAssetURLWithKey({
                              key: value,
                            })
                          : undefined
                      }
                      resetMedia={() => {
                        setValue('trailer', '');
                        setIsMediaUploaded(false);
                      }}
                    />
                  </div>
                )}
              />
              <HookFormErrorMessage
                name="trailer"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </FormFieldWrapper>
            {/* Trailer Video */}
          </div>
        </div>
        <div className="flex flex-col gap-3 sm:flex-row">
          {/* Description */}
          <FormFieldWrapper className="h-full grow">
            <Label htmlFor="course_description" label={descriptionLabel} />
            <TextAreaInput
              id="course_description"
              style={{ resize: 'none' }}
              className="mt-0.5 h-28 w-full rounded-md"
              placeholder={descriptionPlaceholder}
              aria-invalid={fieldHasError(errors, 'description') ? 'true' : 'false'}
              hasError={fieldHasError(errors, 'description')}
              {...register('description')}
            />
            <HookFormErrorMessage
              name="description"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </FormFieldWrapper>
          {/* Description */}

          {/* Things to Learn */}
          <FormFieldWrapper className="h-full grow">
            <Label htmlFor="course_things_to_learn" label={leanerGistLabel} />
            <TextAreaInput
              id="course_things_to_learn"
              style={{ resize: 'none' }}
              className="mt-0.5 h-28 w-full resize-none rounded-md"
              placeholder={learnerGistPlaceholder}
              {...register('learnerGist')}
            />
            <HookFormErrorMessage
              name="learnerGist"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </FormFieldWrapper>
          {/* Things to Learn */}
        </div>
        {modalType !== 'manage' && (
          <div className="flex items-center gap-[6px]">
            <CheckboxInput
              className="grow-0"
              id="required_assessment"
              type="checkbox"
              {...register('isFavorite')}
            />
            <Label
              htmlFor="required_assessment"
              label={isFeaturedCourseLabel}
              className="text-xs font-normal text-zinc-300"
            />
          </div>
        )}
      </div>

      {/* Action Buttons */}
      <div className="flex flex-col items-center justify-end gap-2 xs:flex-row">
        <Dialog.Close
          type="button"
          className="rounded bg-transparent py-2.5 px-[30px] text-center text-sm font-medium text-white"
        >
          {closeButton}
        </Dialog.Close>
        <FormActionButton
          type="submit"
          className="bg-base-brand disabled:opacity-80"
          disabled={
            // When the mutation is in progress
            createCourseMutation.isLoading ||
            updateCourseMutation.isLoading ||
            createCourseInMultiLangMutation.isLoading ||
            // When the speakers, categories, tags are not loaded disable the button
            speakersFetch.isLoading ||
            categoriesFetch.isLoading ||
            allTagsData.isLoading ||
            trailerIsProgress ||
            thumbnailProgress ||
            Object.keys(errors).length !== 0
          }
        >
          {createCourseMutation.isLoading ||
          updateCourseMutation.isLoading ||
          createCourseInMultiLangMutation.isLoading ||
          coursePreFetchData?.isLoading ? (
            <div className="flex gap-2">
              <span>{formActionButtonLabel}</span>
              <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <span>{formActionButtonLabel}</span>
              {buttonIcon}
            </div>
          )}
        </FormActionButton>
      </div>
    </form>
  );
};

export default CourseForm;
