import { CHIME_CHANNEL_PRIVACY } from './../constants/chime-messaging';
import {
  DescribeChannelMembershipForAppInstanceUserCommand,
  DescribeChannelMembershipForAppInstanceUserCommandInput,
  GetMessagingSessionEndpointCommand,
  ListChannelMembershipsCommand,
  ListChannelMembershipsCommandInput,
  ListChannelMembershipsForAppInstanceUserCommand,
  ListChannelMembershipsForAppInstanceUserCommandInput,
  ListChannelMessagesCommand,
  ListChannelMessagesCommandInput,
  ListChannelsCommand,
  ListChannelsCommandInput,
  RedactChannelMessageCommand,
  RedactChannelMessageCommandInput,
  SendChannelMessageCommand,
  SendChannelMessageCommandInput,
  UpdateChannelCommand,
  UpdateChannelCommandInput,
  UpdateChannelReadMarkerCommand,
  UpdateChannelReadMarkerCommandInput,
} from '@aws-sdk/client-chime-sdk-messaging';
import {
  CHIME_CHANNEL_MEMBERSHIP_TYPE,
  CHIME_MESSAGE_PERSISTENCE,
  CHIME_MESSAGE_TYPE,
} from '../constants/chime-messaging';
import { sendChimeCommand } from './chime';

/**
 * Get Chime Messaging Session Endpoint.
 */
const getMessagingSessionEndpoint = async () => {
  const command = new GetMessagingSessionEndpointCommand({});
  const response = await sendChimeCommand(command);
  return response;
};

interface SendMessageToChannelArgs {
  channelArn: string;
  content: string;
  persistence: keyof typeof CHIME_MESSAGE_PERSISTENCE;
  type: keyof typeof CHIME_MESSAGE_TYPE;
  metadata?: string;
  chimeBearer: string;
}

/**
 * Send message to a chime messaging channel.
 */
const sendMessageToChannel = async (args: SendMessageToChannelArgs) => {
  const { channelArn, chimeBearer, content, persistence, type, metadata } = args;

  const commandInput: SendChannelMessageCommandInput = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearer,
    Content: content,
    Persistence: persistence,
    Type: type,
    Metadata: metadata,
  };

  const command = new SendChannelMessageCommand(commandInput);

  const response = await sendChimeCommand(command);

  return response;
};

interface ListChannelMessagesArgs {
  channelArn: string;
  // used for pagination
  nextToken?: string;
  sortOrder?: 'ASCENDING' | 'DESCENDING';
  maxResults?: number;
  chimeBearer: string;
}

/**
 * Returns a paginated list of ChannelMessages
 */
const listChannelMessages = async (args: ListChannelMessagesArgs) => {
  const { chimeBearer, channelArn, nextToken, sortOrder, maxResults } = args;

  const commandInput: ListChannelMessagesCommandInput = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearer,
    NextToken: nextToken,
    SortOrder: sortOrder,
    MaxResults: maxResults,
  };

  const command = new ListChannelMessagesCommand(commandInput);
  const response = await sendChimeCommand(command);

  return response;
};

interface ListChannelMembershipArgs {
  channelArn: string;
  nextToken?: string;
  type: keyof typeof CHIME_CHANNEL_MEMBERSHIP_TYPE;
  maxResults?: number;
  chimeBearer: string;
}

/**
 * List channel members in a chime channel.
 */
const listChannelMemberships = async (args: ListChannelMembershipArgs) => {
  const { channelArn, chimeBearer, nextToken, type = 'DEFAULT', maxResults } = args;

  const commandInput: ListChannelMembershipsCommandInput = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearer,
    NextToken: nextToken,
    Type: type,
    MaxResults: maxResults,
  };

  const command = new ListChannelMembershipsCommand(commandInput);
  const response = await sendChimeCommand(command);

  return response;
};

interface ListChannelMembershipsForAppInstanceUserArgs {
  nextToken?: string;
  maxResults?: number;
  chimeBearer: string;
}

/**
 * List channel members in a chime channel.
 */
const listChannelMembershipsForAppInstanceUser = async (
  args: ListChannelMembershipsForAppInstanceUserArgs
) => {
  const { chimeBearer, nextToken, maxResults } = args;

  const commandInput: ListChannelMembershipsForAppInstanceUserCommandInput = {
    ChimeBearer: chimeBearer,
    NextToken: nextToken,
    MaxResults: maxResults,
  };

  const command = new ListChannelMembershipsForAppInstanceUserCommand(commandInput);
  const response = await sendChimeCommand(command);

  return response;
};

interface UpdateChannelArgs {
  channelArn: string;
  name: string;
  mode?: string;
  metadata?: string;
  chimeBearer: string;
}

/**
 * Update Chime Channel.
 */
const updateChannel = async (args: UpdateChannelArgs) => {
  const { chimeBearer, channelArn, name, mode, metadata } = args;

  const commandInput: UpdateChannelCommandInput = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearer,
    Name: name,
    Mode: mode,
    Metadata: metadata,
  };

  const command = new UpdateChannelCommand(commandInput);
  const response = await sendChimeCommand(command);

  return response;
};

interface ListChannelsArgs {
  chimeBearer: string;
  nextToken?: string;
  privacy?: keyof typeof CHIME_CHANNEL_PRIVACY;
  appInstanceArn: string;
}

/**
 * List all channels.
 */
const listChannels = async (args: ListChannelsArgs) => {
  const { chimeBearer, nextToken, privacy = 'PUBLIC', appInstanceArn } = args;

  if (!appInstanceArn) {
    throw new Error('Invalid AppInstanceArn during listChannels call');
  }

  const commandInput: ListChannelsCommandInput = {
    AppInstanceArn: appInstanceArn,
    ChimeBearer: chimeBearer,
    NextToken: nextToken,
    Privacy: privacy,
  };
  const command = new ListChannelsCommand(commandInput);
  const response = await sendChimeCommand(command);
  return response;
};

interface RedactChannelMessageArgs {
  channelArn: string;
  messageId: string;
  chimeBearer: string;
}

/**
 * Redact channel message.
 */
const redactChannelMessage = async (args: RedactChannelMessageArgs) => {
  const { channelArn, messageId, chimeBearer } = args;

  const commandInput: RedactChannelMessageCommandInput = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearer,
    MessageId: messageId,
  };

  const command = new RedactChannelMessageCommand(commandInput);
  const response = await sendChimeCommand(command);
  return response;
};

interface UpdateChannelReadMarkerArgs {
  channelArn: string;
  chimeBearer: string;
}

/**
 * Update the team when the channel is last read by the user, used to calculate unread channels
 */
const updateChannelReadMarker = async (args: UpdateChannelReadMarkerArgs) => {
  const { channelArn, chimeBearer } = args;

  const commandInput: UpdateChannelReadMarkerCommandInput = {
    ChannelArn: channelArn,
    ChimeBearer: chimeBearer,
  };

  const command = new UpdateChannelReadMarkerCommand(commandInput);
  const response = await sendChimeCommand(command);
  return response;
};

interface DescribeChannelMembershipForAppInstanceUserArgs {
  channelArn: string;
  appInstanceUserArn?: string;
  chimeBearer: string;
}

/**
 * Brief description of the function here.
 */
const describeChannelMembershipForAppInstanceUser = async (
  args: DescribeChannelMembershipForAppInstanceUserArgs
) => {
  const { appInstanceUserArn, channelArn, chimeBearer } = args;

  const commandInput: DescribeChannelMembershipForAppInstanceUserCommandInput = {
    AppInstanceUserArn: appInstanceUserArn ?? chimeBearer,
    ChannelArn: channelArn,
    ChimeBearer: chimeBearer,
  };

  const command = new DescribeChannelMembershipForAppInstanceUserCommand(commandInput);
  const response = await sendChimeCommand(command);
  return response;
};

export {
  sendMessageToChannel,
  getMessagingSessionEndpoint,
  listChannelMessages,
  listChannelMemberships,
  updateChannel,
  listChannels,
  listChannelMembershipsForAppInstanceUser,
  redactChannelMessage,
  updateChannelReadMarker,
  describeChannelMembershipForAppInstanceUser,
};

export type {
  ListChannelMessagesArgs,
  SendMessageToChannelArgs,
  ListChannelMembershipArgs,
  UpdateChannelArgs,
  ListChannelsArgs,
  ListChannelMembershipsForAppInstanceUserArgs,
  RedactChannelMessageArgs,
  UpdateChannelReadMarkerArgs,
  DescribeChannelMembershipForAppInstanceUserArgs,
};
