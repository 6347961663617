import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';

import { TableDataRow } from '../../../../components/DataTables';
import { useNavigate } from 'react-router-dom';
import OrganisationTableHeader from './OrganisationTableHeader';

import { AllOrganisation } from '../../types';
import OrganisationNameCell from './TableCell/OrganisationNameCell';
import AdminEmailCell from './TableCell/AdminEmailCell';
import MemberCountCell from './TableCell/MemberCountCell';
import PaymentModeCell from './TableCell/PaymentModeCell';
import HealthRiskCell from './TableCell/HealthRiskCell';
import { useStrapiOrganizationData } from '../../hooks/useStrapiOrganizationData';

interface Props {
  className?: string;
  data: AllOrganisation[];
}
interface tableHeadingProps {
  membersTableHeader: string;
  healthRiskTableHeader: string;
  emailTableHeader: string;
  departmentTableHeader: string;
  organisationTableHeader: string;
  paymentModeTableHeader: string;
}
const getSchema = (data: tableHeadingProps) => {
  const {
    membersTableHeader,
    healthRiskTableHeader,
    emailTableHeader,
    departmentTableHeader,
    organisationTableHeader,
    paymentModeTableHeader,
  } = data;
  const columnHelper = createColumnHelper<AllOrganisation>();
  const columns = [
    columnHelper.accessor('name', {
      header: organisationTableHeader ?? '',
      cell: ({ getValue }) => <OrganisationNameCell OrganisationName={getValue() as string} />,
      id: 'name',
    }),
    columnHelper.accessor('department', {
      header: departmentTableHeader ?? '',
      cell: ({ getValue }) => <OrganisationNameCell OrganisationName={getValue() as string} />,
      id: 'department',
    }),
    columnHelper.accessor('adminEmail', {
      header: emailTableHeader ?? '',
      id: 'adminEmail',
      cell: ({ getValue }) => <AdminEmailCell adminEmail={getValue() as string} />,
    }),
    columnHelper.accessor('members', {
      header: membersTableHeader ?? '',
      cell: ({ getValue }) => <MemberCountCell number={getValue() as number} />,
      id: 'members',
    }),
    columnHelper.accessor('paymentMode', {
      header: paymentModeTableHeader ?? '',
      cell: ({ getValue }) => <PaymentModeCell mode={getValue() as string} />,
      id: 'paymentMode',
    }),
    columnHelper.accessor('healthRate', {
      header: healthRiskTableHeader ?? '',
      cell: ({ getValue }) => <HealthRiskCell status={getValue() as string} />,
      id: 'healthRate',
    }),
  ];
  return columns;
};

const OrganisationTable = ({ className, data }: Props) => {
  const navigate = useNavigate();
  const { organisationTable } = useStrapiOrganizationData();
  const { TableHeader } = organisationTable;
  const columns = getSchema(TableHeader);
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableMultiSort: false,
  });

  function rowClick(row: Row<AllOrganisation>) {
    const id = row.original.id;
    navigate(`/manage/organisation/${id}`);
  }
  return (
    <table className={clsx('w-full', className)}>
      <thead className="w-full">
        {getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <OrganisationTableHeader
                  isSortable={true}
                  allowMultiColSort={true}
                  header={header}
                  key={header.id}
                />
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <TableDataRow
            tdClassName={clsx('px-2 py-3')}
            className="cursor-pointer"
            row={row}
            key={row.original.id}
            onClick={() => rowClick(row)}
          />
        ))}
      </tbody>
    </table>
  );
};

export default OrganisationTable;
