import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';
import { getAllTeamMembers } from '../api';
import { PartialEnterpriseAdminViewAllTeamMembersFilter } from '../types/interface';

interface Props {
  filters: PartialEnterpriseAdminViewAllTeamMembersFilter;
}
const teamStateSelector = (state: RootState) => state.team;
const useAdminViewTeamMembersQuery = ({ filters }: Props) => {
  const { selectedTeam } = useAppSelector(teamStateSelector);
  const { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching } =
    useQuery(
      [
        {
          filters: filters,
          scope: 'teams',
          items: 'TeamMembers',
          teamId: selectedTeam.teamId,
        },
      ],
      async () => {
        return getAllTeamMembers(filters);
      },
      {
        keepPreviousData: true,
        // TODO: to be decided We are storing it in cache for 30 seconds
        staleTime: 2000,
      }
    );

  return { isLoading, data, isError, error, isPreviousData, isFetching, refetch, isRefetching };
};

export default useAdminViewTeamMembersQuery;
