import React from 'react';
import { Duration } from '../types';

interface DurationContextValue {
  duration: Duration;
  setDuration: React.Dispatch<React.SetStateAction<Duration>>;
}

const durationContext = React.createContext<DurationContextValue>({} as DurationContextValue);

export default durationContext;
