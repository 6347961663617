import cn from '../../utils/cn';

interface DividerProps {
  text: string;
  className?: string;
  textClassName?: string;
}
const Divider = ({ text, className, textClassName }: DividerProps) => {
  return (
    <div className={cn('relative flex w-full items-center', className)}>
      <div className="grow border-t border-zinc-700"></div>
      <span className={cn('mx-2 shrink font-bold leading-3', textClassName)}>{text}</span>
      <div className="grow border-t border-zinc-700"></div>
    </div>
  );
};

export default Divider;
