import { useMutation } from '@tanstack/react-query';
import { resendInviteToMember } from '../api';

const useResendInviteMutation = () => {
  const { data, error, isLoading, isError, mutate, isSuccess } = useMutation({
    mutationFn: resendInviteToMember,
  });
  return { data, error, isLoading, isError, mutate, isSuccess };
};

export default useResendInviteMutation;
