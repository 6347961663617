import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReduxAuthState } from '../../../../../../hooks';
import { removeCountryCodeFromPhoneNumber } from '../../../../../../utils';
import { useStrapiSharedData } from '../../../../../shared/hooks/useStrapiSharedData';
import { useStrapiSettingsData } from '../../../../hooks/useStrapiSettingsData';
import ActionDialogContent from '../../components/ActionDialogContent';

type Props = {
  setShowDialog: (show: boolean) => void;
};

const VerifyOrAddPhoneDialogContent = (props: Props) => {
  const { setShowDialog } = props;

  //   hooks
  const navigate = useNavigate();
  //   hooks

  //   redux state
  const auth = useReduxAuthState();
  //   redux state

  // strapi content

  const {
    mfa: { VerifyOrAddPhoneDialog: verifyOrAddPhoneDialogCMS },
  } = useStrapiSettingsData();

  const { featureFlags } = useStrapiSharedData();
  const { enableUSPhoneNumberFormatting = true } = featureFlags ?? {};

  // strapi content

  //   derived state
  const phoneNumber =
    auth.user?.phone_number && enableUSPhoneNumberFormatting
      ? removeCountryCodeFromPhoneNumber(auth.user?.phone_number)
      : auth.user?.phone_number;

  //   const isPhoneNumberVerified = auth.user?.phone_number_verified === true;
  const userHasPhoneNumber =
    phoneNumber !== undefined && phoneNumber !== null && phoneNumber !== '';

  //   derived state

  //   actions

  const cancelAction = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  const navigateToProfile = useCallback(async () => {
    setShowDialog(false);
    navigate('/settings?view=profile');
  }, [navigate, setShowDialog]);

  //   actions

  return (
    <ActionDialogContent
      heading={verifyOrAddPhoneDialogCMS.heading}
      Description={verifyOrAddPhoneDialogCMS.description}
      Content={
        userHasPhoneNumber
          ? verifyOrAddPhoneDialogCMS.verifyPhoneInProfileDescription
          : verifyOrAddPhoneDialogCMS.addPhoneInProfileDescription
      }
      primaryAction={{
        handler: navigateToProfile,
        label: userHasPhoneNumber
          ? verifyOrAddPhoneDialogCMS.verifyNumberBtnLabel
          : verifyOrAddPhoneDialogCMS.addNumberBtnLabel,
      }}
      secondaryAction={{
        label: verifyOrAddPhoneDialogCMS.cancelBtnLabel,
        handler: cancelAction,
      }}
    ></ActionDialogContent>
  );
};

export default VerifyOrAddPhoneDialogContent;
