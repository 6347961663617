export type CourseType = {
  id: number;
  name: string;
  thumbnail: string;
  speakers: speakerType;
  duration: number;
  progress: number;
  isInProgress: boolean;
  isAssigned: boolean;
  isOverdue: boolean;
  isRecommended: boolean;
  isCompleted: boolean;
  tags: string[];
  category: string;
};

export type speakerType = {
  id?: number;
  name: string;
  profile: string;
  totalCourses?: number;
};

export interface MyCoursesFilter {
  limit: number;
  offset: number;
  orderBy?: string;
  orderField?: string;
  type: (typeof USER_COURSE_STATUS)[keyof typeof USER_COURSE_STATUS];
}

export interface MyCourses {
  limit: number;
  offset: number;
  status: (typeof USER_COURSE_STATUS)[keyof typeof USER_COURSE_STATUS];
  total: number;
  results: CourseType[];
}

export const USER_COURSE_STATUS = {
  COMPLETED: 'COMPLETED',
  RECOMMENDED: 'RECOMMENDED',
  OVERDUE: 'OVERDUE',
  IN_PROGRESS: 'IN_PROGRESS',
  ASSIGNED: 'ASSIGNED',
};

export const SORT_FIELD = {
  COURSE: {
    LastViewed: 'lastViewed',
    NAME: 'name',
    DURATION: 'duration',
    DUE_DATE: 'dueOn',
  },
  ORDER: {
    ASC: 'ASC',
    DESC: 'DESC',
  },
};
