import clsx from 'clsx';
import { useContext } from 'react';
import BrandLogo from '../Brand/BrandLogo';
import BrandLogoImage from '../Brand/BrandLogoImage';
import sideBarContext from './context/sideBarContext';

interface Props {
  className?: string;
}

const SideBarLogoBox = ({ className }: Props) => {
  const { isSidebarCollapsed } = useContext(sideBarContext);

  if (isSidebarCollapsed) {
    return (
      <div className={clsx('flex items-center justify-center', className)}>
        <BrandLogo width={32} height={32}></BrandLogo>
      </div>
    );
  }

  return (
    <div className={clsx('flex w-36 flex-col items-start justify-center py-4 px-2', className)}>
      <BrandLogoImage />
    </div>
  );
};

export default SideBarLogoBox;
