export interface InviteTeam {
  teamName: string;
  members: Member[];
}

export interface Member {
  email: string;
  role: keyof typeof MemberRole | string;
  countryCode?: string;
  firstName?: string;
  jobTitle?: string;
  lastName?: string;
  phoneNumber?: string;
}

export type Leader = {
  email: string;
  countryCode?: string;
  firstName?: string;
  jobTitle?: string;
  lastName?: string;
  phoneNumber?: string;
};

export const MEMBER_ROLE = {
  User: 'User',
  Manager: 'Manager',
  Leader: 'Leader',
} as const;

export const MemberRole = ['User', 'Manager', 'Leader'] as const;

export type Roles = (typeof MemberRole)[number];

export enum RequestType {
  NEW_MEMBER = 'NEW_MEMBER',
  NEW_TEAM = 'NEW_TEAM',
}

export enum INVITEE_ROLE {
  LEADER = 'LEADER',
  MEMBER = 'MEMBER',
}

export interface InviteEnterprise {
  team: InviteTeam[];
  member?: Member;
  leader: Leader[];
  requestType: RequestType;
}

export interface InviteTeamResponse {
  message: string;
}
