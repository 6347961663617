import { API } from 'aws-amplify';
import { USERS_API_PATHS } from '../constants';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';

export type AdminDisableMFAForUserParams = {
  username: string;
};

/**
 * Admin disable MFA for a particular user.
 */
const adminDisableMFAForUser = async (params: AdminDisableMFAForUserParams) => {
  const { username } = params;
  const path = USERS_API_PATHS.ADMIN_DISABLE_MFA;

  const requestBody = {
    username,
  };

  const response = await API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body: requestBody,
    response: true,
  });

  return response.data;
};

export { adminDisableMFAForUser };
