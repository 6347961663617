import { ArrowRight, Plus } from 'phosphor-react';
import React, { useState } from 'react';
import Dialog from '../../../../../components/Dialog';
import { useStrapiHQAdminCoursesData } from '../../../hooks/useStrapiHQAdminCourseData';
import CourseModal from './CourseModal';
import '../../../../../styles/header.css';

const PageHeader = () => {
  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);

  const { courseModal, topNav } = useStrapiHQAdminCoursesData();
  const { courseTitle, addCourseButton } = topNav;
  const { addCourseTitle, addCourseButton: addCourseModalButton } = courseModal;

  return (
    <header className="flex w-full flex-col justify-between gap-3 border-b border-b-zinc-800 bg-card-bg px-3 py-6 xs:flex-row xs:items-center xs:gap-0 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
      <span className="header-title">{courseTitle}</span>
      <Dialog
        containerClassName="!max-w-4xl"
        open={isCourseModalOpen}
        onOpenChange={(open) => setIsCourseModalOpen(open)}
        triggerClassName="flex justify-end basis-0 grow-0"
        Content={
          <CourseModal
            modalTitle={addCourseTitle}
            setIsCourseModalOpen={setIsCourseModalOpen}
            formActionButtonLabel={addCourseModalButton}
            buttonIcon={<ArrowRight weight="bold" />}
            modalType="create"
          />
        }
        onInteractOutside={(e) => {
          // Donot close the Modal when we click outside the Dialog Content
          e.preventDefault();
        }}
      >
        <span className="flex w-full flex-row items-center justify-center gap-[6.7px] whitespace-nowrap rounded border-[0.837607px] border-base-brand bg-base-brand py-[6.7px] px-[13px] text-[11.7px] font-normal leading-[17px] text-white xs:w-fit">
          <Plus size={12} color="currentColor" className="text-inherit" />
          {addCourseButton}
        </span>
      </Dialog>
    </header>
  );
};

export default PageHeader;
