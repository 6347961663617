import { $convertFromMarkdownString, Transformer } from '@lexical/markdown';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import ErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { useCallback, useEffect, useRef } from 'react';
import cn from '../../../../utils/cn';
import { useStrapiSharedData } from '../../hooks/useStrapiSharedData';
import Toolbar from './Toolbar';
import { $getRoot } from 'lexical';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../constants/user';

type Props = {
  className?: string;
  initialMarkdownString?: string;
  isEditable?: boolean;
  transformers?: Transformer[];
  placeholder?: string;
  selectedLanguage: string;
  hasAnnouncementAlready: boolean;
};

const RichTextEditor = (props: Props) => {
  const {
    className,
    initialMarkdownString,
    isEditable = true,
    transformers = [],
    placeholder,
    selectedLanguage,
    hasAnnouncementAlready,
  } = props;

  // ref
  const textEditorInitializedRef = useRef(false);
  // ref

  // context
  const [editor] = useLexicalComposerContext();
  // context

  // Strapi Content
  const { RichTextEditor: richTextEditorCMS } = useStrapiSharedData();
  // Strapi Content

  // effects

  useEffect(() => {
    if (initialMarkdownString && !textEditorInitializedRef.current) {
      editor.update(() => {
        $convertFromMarkdownString(initialMarkdownString, transformers);
      });
    }

    textEditorInitializedRef.current = true;
  }, [editor, initialMarkdownString, transformers]);

  useEffect(() => {
    // Reset the editor's initialized flag when the language changes
    textEditorInitializedRef.current = false;
  }, [initialMarkdownString]);

  useEffect(() => {
    if (selectedLanguage !== DEFAULT_PREFERRED_LANGUAGE && !hasAnnouncementAlready) {
      // Reset the editor's initialized flag when switching to a language without an announcement
      textEditorInitializedRef.current = false;

      // Clear the markdown content when the announcement doesn't exist for the selected language
      editor.update(() => {
        const root = $getRoot();
        root.clear(); // Clears the content of the editor
      });
    }
  }, [selectedLanguage, hasAnnouncementAlready, editor]);

  // Toggle the editor state as the isLoading flag changes
  useEffect(() => {
    editor.setEditable(isEditable);
  }, [isEditable, editor]);

  // effects

  // handlers

  const revertChanges = useCallback(() => {
    editor.update(() => {
      // clear the editor
      const root = $getRoot();
      root.clear();

      // If there is an initial markdown string
      // then update the editor with the content of that
      if (initialMarkdownString) {
        $convertFromMarkdownString(initialMarkdownString, transformers);
      }
    });
  }, [editor, initialMarkdownString, transformers]);

  // handlers

  return (
    <div className={cn('relative flex w-full flex-col [--rte-padding-x:8px]', className)}>
      <Toolbar
        className="rounded-t-sm py-1.5 px-[var(--rte-padding-x)]"
        isEditable={isEditable}
        revertChanges={revertChanges}
      />
      <div className="relative w-full [--rte-padding-y:8px]">
        <RichTextPlugin
          contentEditable={
            <ContentEditable className="relative h-[var(--rte-height,128px)] w-full overflow-y-auto rounded-b-sm border border-zinc-700 bg-zinc-900 py-[var(--rte-padding-y)] px-[var(--rte-padding-x)] text-white outline-none" />
          }
          ErrorBoundary={ErrorBoundary}
          placeholder={
            <span className="pointer-events-none absolute top-[var(--rte-padding-y)] left-[calc(var(--rte-padding-x)+2px)] text-zinc-600">
              {placeholder ?? richTextEditorCMS.placeholder}
            </span>
          }
        />
      </div>
      <HistoryPlugin />
      <LinkPlugin />
      <LexicalClickableLinkPlugin newTab={true} />
    </div>
  );
};

export default RichTextEditor;
