import { CourseCard } from '../../../../../../components/Cards';

type RecommendedCoursesProps = {
  className?: string;
  courseFetch: any;
  heading: string;
};
const RecommendedCourseView = ({ courseFetch, heading }: RecommendedCoursesProps) => {
  return (
    <div className="flex flex-col gap-6">
      <p className="text-base font-semibold text-zinc-100">{heading}</p>
      <div className="grid grid-cols-[repeat(auto-fill,_minmax(15rem,1fr))] gap-5">
        {courseFetch &&
          courseFetch.results.map((course: any) => <CourseCard key={course.id} course={course} />)}
      </div>
    </div>
  );
};

export default RecommendedCourseView;
