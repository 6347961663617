import { useQuery } from '@tanstack/react-query';
import { getUSHGAdminCourseFeedbacks } from '../api';
import { MODULE_TABLE_STALE_TIME } from '../constants/course-module-table';
import { PartialUSHGAdminCourseFeedbacksFilter } from '../types';

interface Props {
  filters: PartialUSHGAdminCourseFeedbacksFilter;
  courseId: number;
}

const useUSHGAdminCourseFeedbackQuery = ({ filters, courseId }: Props) => {
  const courseModule = useQuery(
    [
      {
        scope: 'single-course',
        item: 'feedbacks',
        filters,
        courseId,
      },
    ],
    async ({ queryKey }) => {
      const { filters, courseId } = queryKey[0];
      return getUSHGAdminCourseFeedbacks({ filters, courseId });
    },
    {
      keepPreviousData: true,
      staleTime: MODULE_TABLE_STALE_TIME,
    }
  );

  return courseModule;
};

export default useUSHGAdminCourseFeedbackQuery;
