import { ArrowLeft } from 'phosphor-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../../../components/Buttons';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { useStrapiHQAdminCoursesData } from '../../../../hooks/useStrapiHQAdminCourseData';
import CourseStatusCell from '../../../CourseStatusCell';

interface Props {
  name?: string;
  id: number;
  courseBasicDetailsQuery?: any;
}

const PageHeader = ({ id, courseBasicDetailsQuery }: Props) => {
  const navigate = useNavigate();

  const navigateToManageAllCoursesPage = () => {
    navigate('/manage/courses');
  };

  const navigateToCourseEditPage = () => {
    navigate(`/manage/courses/${id}/edit`);
  };
  const data = useStrapiDataHelper();
  const strapiErrorMessage = data.errorsMap;
  const {
    topNav: { editCourseButton, backButton, draftStatusLabel },
    emptyMessages: { emptyCourse },
  } = useStrapiHQAdminCoursesData();

  const displayLoader = courseBasicDetailsQuery.isLoading && !courseBasicDetailsQuery.data;

  // Empty State
  const isEmptyState =
    courseBasicDetailsQuery.data && Object.keys(courseBasicDetailsQuery).length === 0;

  // Error State
  const errorState = courseBasicDetailsQuery.isError && !courseBasicDetailsQuery.data;
  return (
    <div className="flex w-full flex-col justify-between gap-3 border-b border-b-zinc-800 bg-card-bg px-3 py-6 xs:flex-row xs:items-center xs:gap-0 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9">
      <div className="flex flex-col gap-1 xs:gap-0">
        <div
          className="flex cursor-pointer flex-row items-center"
          onClick={navigateToManageAllCoursesPage}
        >
          <ArrowLeft size={12} className="text-zinc-400" />
          <p className="font-sans text-xs font-normal leading-[150%] text-zinc-400">
            &nbsp;{backButton}
          </p>
        </div>

        <div className="flex gap-x-4">
          {/* Course Details loading */}
          {displayLoader && (
            <div className="flex h-full w-full items-center justify-center py-4">
              <BasicSpinner className="h-4 w-4 text-white" />
            </div>
          )}

          {/* Empty State */}
          {isEmptyState && !displayLoader && <span>{emptyCourse}</span>}

          {/* Error State */}
          {errorState && (
            <div className="flex h-full items-center justify-center">
              <span className=" text-rose-200">{strapiErrorMessage['SYSTEM_ERROR']}</span>
            </div>
          )}
          {/* Course Details loaded */}
          {id && courseBasicDetailsQuery.data && !displayLoader && (
            <>
              <p className="font-sans text-base font-semibold text-white xs:font-medium lg:text-xl">
                <span>{courseBasicDetailsQuery.data.name}</span>
              </p>
              {!courseBasicDetailsQuery.data.isActive && (
                <CourseStatusCell
                  containerClassName="bg-zinc-900 text-zinc-50"
                  display={draftStatusLabel}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div>
        <Button
          className="flex w-full flex-row items-center justify-center gap-2 bg-base-brand py-2 text-xs font-medium text-white xs:w-fit"
          onClick={navigateToCourseEditPage}
        >
          {editCourseButton}
        </Button>
      </div>
    </div>
  );
};

export default PageHeader;
