import clsx from 'clsx';
import React, { MutableRefObject, useRef } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { mergeRefs } from 'react-merge-refs';
import {
  ARROW_LEFT_KEY,
  ARROW_LEFT_KEY_CODE,
  ARROW_RIGHT_KEY,
  ARROW_RIGHT_KEY_CODE,
  BACKSPACE_KEY,
  BACKSPACE_KEY_CODE,
  SINGLE_DIGIT_REGEX_PATTERN,
} from '../../../features/auth/constants';
import CodeSingleInput from './CodeSingleInput';
import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
  singleInputClassName?: string;
  register: ReturnType<typeof useForm<any>>['register'];
  control: ReturnType<typeof useForm<any>>['control'];
  htmlId?: string;
}

const CodeInput = ({ register, control, className, htmlId, singleInputClassName }: Props) => {
  const { fields: codeFields } = useFieldArray({
    control,
    name: 'code',
  });

  // Collection of all the input Refs
  const inputRefs = useRef<MutableRefObject<HTMLInputElement | undefined>[]>([]);

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { target } = e;

    if (target) {
      const { value, nextElementSibling } = target;
      if (value.length == 1) {
        if (value.match(SINGLE_DIGIT_REGEX_PATTERN)) {
          if (nextElementSibling !== null) {
            (nextElementSibling as HTMLInputElement)?.focus();
          }
        } else {
          e.target.value = '';
        }
      }
    }
  };

  const focusAndSelectInputElement = (t: HTMLInputElement) => {
    if (t) {
      t.focus();
      t.select();
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;
    if (target) {
      const { previousElementSibling, value, nextElementSibling } = target;
      const key = e.key;

      // KeyCode is deprecated but would be used as fallback when key is not present
      const keyCode = e.keyCode;

      if (key === ARROW_LEFT_KEY || keyCode === ARROW_LEFT_KEY_CODE) {
        // Focus and Select the Previous Element
        focusAndSelectInputElement(previousElementSibling as HTMLInputElement);
        e.preventDefault();
      } else if (key === ARROW_RIGHT_KEY || keyCode === ARROW_RIGHT_KEY_CODE) {
        // Focus and Select the Next Element
        focusAndSelectInputElement(nextElementSibling as HTMLInputElement);
        e.preventDefault();
      } else if (key === BACKSPACE_KEY || keyCode === BACKSPACE_KEY_CODE) {
        // If the current block has no value,
        // Then clear the previous block
        if (value === '') {
          if (previousElementSibling) {
            // Clear the value of previous element and focus onto it
            const t = previousElementSibling as HTMLInputElement;
            t.value = '';
            t.focus();
            e.preventDefault();
          }
        } else {
          // Clear the current node value
          target.value = '';
        }
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedCode = e.clipboardData.getData('Text');

    if (!pastedCode || pastedCode.length == 0) {
      return;
    }

    let currentElement = e.target as HTMLInputElement;

    if (!currentElement) {
      return false;
    }

    for (let i = 0; i < pastedCode.length; i++) {
      const character = pastedCode.charAt(i);

      // If the character matches the
      if (character.match(SINGLE_DIGIT_REGEX_PATTERN)) {
        const currInputCharacter = currentElement?.value;

        // If the current code digit is empty
        // set the character and if there is next sibiling focus on it
        if (!currInputCharacter) {
          currentElement.value = character;
          const nextElementSibling = currentElement.nextElementSibling as HTMLInputElement;
          if (nextElementSibling) {
            if (i !== pastedCode.length - 1) {
              currentElement = nextElementSibling;
              currentElement.focus();
            }
          } else {
            break;
          }
        }
      }
    }
  };

  return (
    <div className={twMerge(clsx('grid grid-cols-6 grid-rows-1 gap-3', className))}>
      {codeFields.map((field, index) => {
        const { onChange, ref, ...rest } = register(`code.${index}.value`);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const localRef = useRef<HTMLInputElement>();
        inputRefs.current.push(localRef);

        const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
          onChange(event);
          handleOnChange(event);
        };

        return (
          <React.Fragment key={field.id}>
            <label htmlFor={`${htmlId}_${index + 1}`} className="sr-only">{`Code digit ${
              index + 1
            }`}</label>
            <CodeSingleInput
              key={field.id}
              // Attach the Id to the first code input
              // so when the user clicks on the label the first input
              // would be focussed
              id={index === 0 ? htmlId : `${htmlId}_${index + 1}`}
              aria-label={`code digit ${index + 1}`}
              onKeyDown={handleKeyDown}
              onChange={handleInputChange}
              onPaste={handlePaste}
              ref={mergeRefs([ref, localRef])}
              className={singleInputClassName}
              {...rest}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default CodeInput;
