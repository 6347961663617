import { CognitoUserAttributes } from '../constants';
import { AccountProvisionStatus } from '../constants/user';
import useReduxAuthState from './use-redux-auth-state';

const useShouldUserRegister = () => {
  // hooks
  const auth = useReduxAuthState();
  // hooks

  // If not federated user or user is not logged in return false
  if (!auth.isAuthenticated) {
    return false;
  }

  const accountProvisionStatus = auth.user[CognitoUserAttributes.ACCOUNT_PROVISION_STATUS];

  // If the user provision status is NOT_REGISTERED
  // then return true else false
  if (accountProvisionStatus === AccountProvisionStatus.NOT_REGISTERED) {
    return true;
  } else {
    return false;
  }
};

export default useShouldUserRegister;
