import React, { useEffect, useState } from 'react';
import ErrorMessage from '../../../../../../components/FormElements/ErrorMessage';
import Label from '../../../../../../components/FormElements/Label';
import * as yup from 'yup';
import TextInput from '../../../../../../components/FormElements/TextInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../../../../../components/Buttons';
import { DeviceMobileCamera, EnvelopeSimple } from 'phosphor-react';
import PhoneNumberInput from '../../../../../../components/FormElements/PhoneInput';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import {
  EMAIL_REGEX_PATTERN,
  FIRSTNAME_REGEX_PATTERN,
  LASTNAME_REGEX_PATTERN,
  PHONE_NUMBER_REGEX_PATTERN,
} from '../../../../../../constants';
import { fieldHasError } from '../../../../../../utils/react-hook-form';
import {
  appendCountryCodeToPhoneNumber,
  removeCountryCodeFromPhoneNumber,
  handleFileChange,
} from '../../../../../../utils';
import { Toast } from '../../../../../../components/Toast';
import toast from 'react-hot-toast';
import { StepperControl } from '../../../Stepper';
import {
  generateCDNMediaAssetURLWithKey,
  getAccessTokenHelper,
} from '../../../../../shared/helpers';
import { useProfileQuery, useUpdateProfileMutation } from '../../../../../shared/hooks';
import { FadeLoader } from 'react-spinners';
import { FormFieldWrapper } from '../../../Form';
import clsx from 'clsx';
import { getPresignedUrl, uploadToS3 } from '../../../../../../api';
import { GetPresignedUrlFilters, MODULE_TYPES } from '../../../../../../types';
import { useStrapiOnBoardingData } from '../../../../hooks/useStrapiOnBoardingData';
import { useStrapiSharedData } from '../../../../../shared/hooks/useStrapiSharedData';
import { ProfilePictureSquare } from '../../../../../../components/Icons';
import { DropdownInput } from '../../../../../../components/FormElements';
import { useLanguage } from '../../../../../shared/context/languageContext';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../../constants/user';

interface Props {
  currentStep: string;
  setCurrentStep: any;
  steps: string[];
}

// User Profile Form Schema section starts
interface FormValidations {
  firstNameRequired: string;
  firstNameMinValue: number;
  firstNameMinError: string;
  firstNameMaxValue: number;
  firstNameMaxError: string;
  firstNameTypeError: string;
  lastNameRequired: string;
  lastNameMinValue: number;
  lastNameMinError: string;
  lastNameMaxValue: number;
  lastNameMaxError: string;
  lastNameTypeError: string;
  emailRequired: string;
  validEmail: string;
  phoneNumberRequired: string;
  isPhoneNumberRequired: boolean;
  validPhoneNumber: string;
}
// Yup Schema
const getSchema = (data: FormValidations) => {
  const {
    firstNameRequired,
    firstNameMinValue,
    firstNameMinError,
    firstNameMaxValue,
    firstNameMaxError,
    firstNameTypeError,
    lastNameRequired,
    lastNameMinValue,
    lastNameMinError,
    lastNameMaxValue,
    lastNameMaxError,
    lastNameTypeError,
    emailRequired,
    validEmail,
    validPhoneNumber,
    phoneNumberRequired,
    isPhoneNumberRequired,
  } = data;
  const userProfileSchema = yup.object({
    firstName: yup
      .string()
      .trim()
      .required(firstNameRequired)
      .min(firstNameMinValue, firstNameMinError)
      .max(firstNameMaxValue, firstNameMaxError)
      .matches(new RegExp(FIRSTNAME_REGEX_PATTERN), firstNameTypeError)
      .label('First name'),
    lastName: yup
      .string()
      .trim()
      .required(lastNameRequired)
      .min(lastNameMinValue, lastNameMinError)
      .max(lastNameMaxValue, lastNameMaxError)
      .matches(new RegExp(LASTNAME_REGEX_PATTERN), lastNameTypeError)
      .label('Last name'),
    emailAddress: yup
      .string()
      .email(validEmail)
      .required(emailRequired)
      .matches(new RegExp(EMAIL_REGEX_PATTERN), validEmail)
      .label('Email address'),
    isPhoneNumberRequired: yup.boolean().default(isPhoneNumberRequired),
    phone: yup
      .string()
      .when('isPhoneNumberRequired', {
        is: true,
        then: yup.string().required(phoneNumberRequired),
      })
      .test('regex-validation', validPhoneNumber, function (value) {
        // This function validates the Phonenumber everytime it has a value and disables validation when Phonenumber is not required and has no value
        return !value || new RegExp(PHONE_NUMBER_REGEX_PATTERN).test(value);
      }),
    profileUrlKey: yup.string().default(''),
    language: yup.string(),
  });
  return userProfileSchema;
};

const UserProfile = ({ currentStep, setCurrentStep, steps }: Props) => {
  const { language } = useLanguage();
  // Fetch the profile details
  const profileQuery = useProfileQuery();
  const profile = profileQuery.data;
  // strapi content
  const {
    userDefaultProfilePicture,
    language: { StrapiLanguageOptions },
    featureFlags: { showLanguageOptions },
  } = useStrapiSharedData();
  const userDefaultProfilePictureUrl = userDefaultProfilePicture?.data?.attributes?.url;

  const { profile: strapiProfileData, toastMessages } = useStrapiOnBoardingData();
  const { avatarUploadFailure } = toastMessages;
  const {
    profileTitle,
    profileDescription,
    avatarUploadInstruction,
    uploadAvatarButton,
    removeAvatarButton,
    form: strapiFormData,
  } = strapiProfileData;
  const {
    firstNameLabel,
    lastNameLabel,
    emailLabel,
    phoneNumberLabel,
    languageLabel,
    phoneNumberPlaceHolder,
  } = strapiFormData;
  const userProfileSchema = getSchema(strapiFormData);
  type USER_PROFILE_FORM_DATA = yup.InferType<typeof userProfileSchema>;
  const [imageUrl, setImageUrl] = useState(userDefaultProfilePictureUrl);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Create update profile Mutation
  const updateProfileMutation = useUpdateProfileMutation();

  // Display loader
  const displayLoader = isLoading || profileQuery.isLoading;

  // Form initialization
  const {
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<USER_PROFILE_FORM_DATA>({
    mode: 'onChange',
    resolver: yupResolver(userProfileSchema),
    defaultValues: {
      language: language,
    },
  });

  useEffect(() => {
    if (profile?.pictureUrl) {
      const pictureUrl = profile.pictureUrl;
      setValue('profileUrlKey', pictureUrl);

      // Construct the image url with the image key
      const pictureImageUrl =
        pictureUrl && pictureUrl !== ''
          ? generateCDNMediaAssetURLWithKey({ key: pictureUrl })
          : userDefaultProfilePictureUrl;
      setImageUrl(pictureImageUrl);
    }
  }, [profile?.pictureUrl, setValue, userDefaultProfilePictureUrl]);

  useEffect(() => {
    // reset form with user data
    reset({
      firstName: profile?.firstName || '',
      lastName: profile?.lastName || '',
      emailAddress: profile?.emailAddress || '',
      phone: removeCountryCodeFromPhoneNumber(profile?.phone) || '',
      profileUrlKey: profile?.pictureUrl || '',
    });
  }, [profile, reset]);

  // Next/Prev step controls

  const stepperNextControl = () => {
    handleSubmit(onSubmit, onError)();
  };

  const stepperPrevControl = () => {
    let currIndex = steps.indexOf(currentStep);
    const newStep = --currIndex;
    if (newStep !== -1) {
      setCurrentStep(steps[newStep]);
    }
  };

  // Next/Prev step controls

  const onSubmit = (data: USER_PROFILE_FORM_DATA) => {
    handleFormSubmission(data);
  };

  const onError = (error: any) => {
    console.error('Error with form validation  %o', error);
  };

  const handleFormSubmission = async (data: USER_PROFILE_FORM_DATA) => {
    const { firstName, lastName, phone, language } = data;
    const params = {
      accessToken: await getAccessTokenHelper(),
      firstName,
      lastName,
      phoneNumber: phone && appendCountryCodeToPhoneNumber(phone),
      preferredLanguage: language || DEFAULT_PREFERRED_LANGUAGE,
      profileUrl: data.profileUrlKey,
    };
    // update profile data
    updateProfileMutation.mutate(params, {
      onSuccess: async () => {
        let currIndex = steps.indexOf(currentStep);
        const newStep = ++currIndex;
        if (newStep <= steps.length - 1) {
          setCurrentStep(steps[newStep]);
        }
      },
    });
  };

  // Avatar upload/remove controls

  // Avatar upload/remove controls
  const onAvatarUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentImageUrl = imageUrl;
    try {
      const response = await handleFileChange(event);
      const { fileType, fileContents } = response ?? {};

      if (fileType && fileContents) {
        setIsLoading(true);
        setImageUrl(URL.createObjectURL(fileContents));
        // construct payload for getting s3 presigned url
        const presignedUrlFilters: GetPresignedUrlFilters = {
          module: MODULE_TYPES.USER,
        };

        // Get Presigned URL
        const presignedUrlResponse = await getPresignedUrl({ presignedUrlFilters });

        // Upload to S3
        await uploadToS3({
          uploadUrl: presignedUrlResponse.url,
          fileContents,
        });
        setValue('profileUrlKey', presignedUrlResponse.key);
      }
    } catch (error: any) {
      setImageUrl(currentImageUrl);
      toast.custom(
        (t) => (
          <Toast
            variant={'error'}
            Title={avatarUploadFailure}
            SubTitle={error.message}
            toastInstance={t}
          />
        ),
        {
          duration: 3000,
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onAvatarRemove = () => {
    // Reset image key and url to default values
    setValue('profileUrlKey', '');
    setImageUrl(userDefaultProfilePictureUrl);
  };

  // handleClick basically empty the target.value i.e. event.target.value = ""
  const handleClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = '';
  };

  // Avatar upload/remove controls

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <h1 className="text-lg font-semibold text-zinc-50 lg:text-xl">{profileTitle}</h1>
        <p className="text-sm leading-5 text-zinc-400">{profileDescription}</p>
      </div>
      <div
        className={clsx(
          'relative rounded-sm bg-zinc-900 px-3 pt-4 pb-6 sm:px-6',
          profileQuery.isLoading && !profileQuery.data && 'pointer-events-none cursor-not-allowed'
        )}
      >
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          {profileQuery.isLoading && !profileQuery.data && (
            <FadeLoader color="#E4E4E7" width="3px" height="10px" />
          )}
        </div>
        <p className="text-xs leading-5 text-zinc-400 sm:text-sm">{avatarUploadInstruction}</p>
        <div className="my-6 flex items-center gap-3 rounded-md bg-zinc-800 px-4 py-2">
          {profileQuery.isLoading && (
            <ProfilePictureSquare className="h-14 w-14 animate-pulse rounded-full border border-white text-gray-400" />
          )}
          {!profileQuery.isLoading && (
            <img
              aria-label="Avatar"
              alt="Avatar"
              className={clsx(
                'h-14 w-14 rounded-full border border-white object-cover',
                isLoading && 'animate-pulse'
              )}
              src={imageUrl}
            ></img>
          )}
          <div className="inline-block space-y-2 space-x-2 font-medium xs:space-y-0">
            <label htmlFor="select-image">
              <span className="cursor-pointer rounded-[4px] bg-base-brand py-1 px-2 text-xs text-white">
                {uploadAvatarButton}
              </span>
              <input
                type="file"
                id="select-image"
                className="hidden"
                accept=".jpg, .jpeg, .png"
                onChange={onAvatarUpload}
                onClick={handleClick}
              />
            </label>
            <Button
              onClick={onAvatarRemove}
              className="rounded-[4px] bg-zinc-700 px-2 py-1 text-xs text-zinc-200"
              disabled={imageUrl === userDefaultProfilePictureUrl}
            >
              {removeAvatarButton}
            </Button>
          </div>
        </div>
        {/* Profile Section */}
        <form className="mt-4 grid grid-cols-1 gap-4 text-white md:grid-cols-2">
          {/* First Name Input */}
          <FormFieldWrapper>
            <Label
              className="font-semibold !text-zinc-400"
              htmlFor="first_name_input"
              label={firstNameLabel}
            />
            <TextInput
              className="grow-0 rounded-md"
              id="first_name_input"
              type="text"
              hasError={fieldHasError(errors, 'firstName')}
              {...register('firstName')}
            />
            <HookFormErrorMessage
              name="firstName"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </FormFieldWrapper>

          {/* Last Name Input */}
          <FormFieldWrapper>
            <Label
              className="font-semibold !text-zinc-400"
              htmlFor="last_name_input"
              label={lastNameLabel}
            />
            <TextInput
              className="grow-0 rounded-md"
              id="last_name_input"
              type="text"
              hasError={fieldHasError(errors, 'lastName')}
              {...register('lastName')}
            />
            <HookFormErrorMessage
              name="lastName"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </FormFieldWrapper>

          {/* Email Address Input */}
          <FormFieldWrapper>
            <Label
              className="font-semibold !text-zinc-400"
              htmlFor="email_input"
              label={emailLabel}
            />
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <EnvelopeSimple size={20} color="#A1A1AA" weight="bold" />
              </div>
              <TextInput
                className="grow-0 rounded-md border !border-zinc-700 !bg-zinc-700 pl-10 disabled:text-zinc-400"
                id="email_input"
                type="text"
                disabled={!!profile?.emailAddress}
                hasError={fieldHasError(errors, 'emailAddress')}
                {...register('emailAddress')}
              />
            </div>
            <HookFormErrorMessage
              name="emailAddress"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </FormFieldWrapper>

          {/* Phone Number Input */}
          <FormFieldWrapper>
            <Label
              className="font-semibold !text-zinc-400"
              htmlFor="phone_number_input"
              label={phoneNumberLabel}
            />
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <DeviceMobileCamera size={20} color="#A1A1AA" weight="bold" />
              </div>
              <PhoneNumberInput
                className="grow-0 rounded-md pl-10"
                id="phone_number_input"
                placeholder={phoneNumberPlaceHolder}
                type="tel"
                hasError={fieldHasError(errors, 'phone')}
                {...register('phone')}
              />
            </div>
            <HookFormErrorMessage
              name="phone"
              errors={errors}
              render={({ message }) => <ErrorMessage message={message} />}
            />
          </FormFieldWrapper>
          {/* Preferred Language */}
          {showLanguageOptions && (
            <FormFieldWrapper>
              <Label htmlFor="language_input" label={languageLabel} className="text-left" />
              <div className="relative w-full">
                <DropdownInput
                  className="grow-0 rounded-md text-xs placeholder:text-xs disabled:text-zinc-500 md:text-sm md:placeholder:text-xs"
                  options={StrapiLanguageOptions}
                  type="select"
                  id="language_input"
                  placeholder="Preferred Language"
                  {...register('language')}
                />
              </div>
            </FormFieldWrapper>
          )}
        </form>
      </div>

      {/* navigation button */}
      <StepperControl
        isStepLoading={displayLoader}
        aria-label="Goal Step controls"
        stepperPrevControl={stepperPrevControl}
        stepperNextControl={stepperNextControl}
        currentStep={currentStep}
        isNextStepLoading={updateProfileMutation.isLoading}
      />
    </div>
  );
};

export default UserProfile;
