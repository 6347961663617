import clsx from 'clsx';
import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterDropDown } from '../../../../../../../components/DataTables';
import SearchInput from '../../../../../../../components/SearchInput';
import {
  FEEDBACK_FILTER_QUERY_PARAM,
  FEEDBACK_RATING_QUERY_PARAM,
} from '../../../../../constants/course-feedback';
import { Option } from '../../../../../../../components/Select';
import { ComponentHeading } from '../../../../../../dashboard/components/common';
import { useStrapiHQAdminCoursesData } from '../../../../../hooks/useStrapiHQAdminCourseData';
import { useStrapiSharedData } from '../../../../../../shared/hooks/useStrapiSharedData';

interface Props {
  className?: string;
  filter: string | undefined;
  isFilterApplied: boolean;
  ratingsSelectOptions: Option[];
  ratings: string | undefined;
  filterIsVisible: boolean;
}

const FeedbacksMainHeader = ({
  className,
  filter,
  isFilterApplied,
  ratingsSelectOptions,
  ratings,
  filterIsVisible,
}: Props) => {
  const { ratingFilterLabel } = useStrapiHQAdminCoursesData().singleCourseTableView.FeedbackTable;
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const handleQChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    searchParams.set(FEEDBACK_FILTER_QUERY_PARAM, value);
    navigate('?' + searchParams.toString());
  };

  const handleStatusChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const value = event.target.value;
    searchParams.set(FEEDBACK_RATING_QUERY_PARAM, value);
    navigate('?' + searchParams.toString());
  };

  const handleResetFiltersBtnClick = () => {
    // This will remove all the query params (i.e. how filters are set)
    navigate('.');
  };
  const { title } = useStrapiHQAdminCoursesData().singleCourseTableView.FeedbackTable;
  const {
    tableProps: { clearAllFiltersButton, searchPlaceHolder },
  } = useStrapiSharedData();
  return (
    <div className={clsx('w-full  text-zinc-100 ', className)}>
      <ComponentHeading heading={title} className="text-lg font-medium" />
      {filterIsVisible && (
        <div className="mt-2 flex flex-col justify-between gap-3 text-[14px] font-normal leading-[20px] md:flex-row md:gap-2">
          <div className="order-1 flex w-full flex-col gap-3 md:w-min md:flex-row md:gap-2">
            <SearchInput
              placeholder={searchPlaceHolder}
              containerClassName="min-w-[200px]"
              iconClassName="left-2"
              className="h-9 rounded-sm border border-zinc-900 bg-zinc-900 p-2 pl-9 text-[14px] font-normal leading-[20px] text-zinc-100 placeholder:text-zinc-500"
              value={filter ?? ''}
              onChange={handleQChange}
            />
            <FilterDropDown
              value={ratings ?? undefined}
              options={ratingsSelectOptions}
              onChange={handleStatusChange}
              selectItemLabel={ratingFilterLabel}
              aria-label="Feedback ratings filter options"
              className="font-light"
            />
          </div>
          {isFilterApplied && (
            <div className="order-3 flex w-full grow items-center justify-center md:order-2 md:justify-start">
              <button
                className="h-min self-center justify-self-start rounded-[32px] bg-zinc-700 py-1.5 px-2.5 font-sans text-[10px] font-medium leading-[105%] text-zinc-200"
                onClick={handleResetFiltersBtnClick}
                // When there are no query params disable it
                // As we are setting the filters using the search params
              >
                {clearAllFiltersButton}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(FeedbacksMainHeader);
