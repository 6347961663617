import { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useStrapiLoginMFAContent } from '../../../../../hooks';
import UserLoginMainLayout from '../../UserLoginMainLayout';
import SMSMFABottom from './SMSMFABottom';
import SMSMFAForm from './SMSMFAForm';
import SMSMFAHeader from './SMSMFAHeader';
import { loginAuthChallengeContext } from '../../../../../context';

interface Props {
  onLoginSuccess?: () => void;
}

const SMSMFA = (props: Props) => {
  // TODO: Handle onLoginSuccess
  const { onLoginSuccess: _ } = props;

  // strapi
  const loginMFACMS = useStrapiLoginMFAContent();
  // strapi

  // context
  const { setAuthChallenge } = useContext(loginAuthChallengeContext);
  // context

  const goBackToLogin = useCallback(() => {
    setAuthChallenge(null);
  }, [setAuthChallenge]);

  return (
    <UserLoginMainLayout containerClassName="justify-between pb-4 max-w-md">
      {/* For Layout */}
      <div></div>
      {/* Heading, Form */}
      <div className="flex flex-col items-center gap-8">
        <div className="flex flex-col gap-9">
          <SMSMFAHeader className="max-w-xs" />
          <SMSMFAForm
            className="max-w-xs"
            // onLoginSuccess={onLoginSuccess}
          />
        </div>
        <SMSMFABottom goBackToLogin={goBackToLogin} className="max-w-lg" />
      </div>
      {/* Contact us link */}
      <footer className="flex flex-row gap-1 justify-self-end text-sm font-normal leading-6">
        <span className="text-zinc-400">{loginMFACMS.Shared.anyProblemLabel}</span>
        <Link className="text-zinc-50" to={loginMFACMS.Shared.contactUsLink}>
          {loginMFACMS.Shared.contactUsLabel}
        </Link>
      </footer>
    </UserLoginMainLayout>
  );
};

export default SMSMFA;
