import { useMemo } from 'react';
import { ErrorDisplay } from '../../../../../components/DataTables';
import { generateDateToCalendarItemsMap } from '../../../helpers';
import { useMyCalendarItemsQuery } from '../../../hooks';
import CalendarItemsList from '../CalendarItemsList';
import UpcomingEventsBaseLayout from './UpcomingEventsBaseLayout';
import { UPCOMING_EVENTS_DISPLAY_COUNT } from '../../../constants';
import { useStrapiDashboardData } from '../../../../shared/hooks';
import ScaleLoader from '../../../../../components/Loader/ScaleLoader';

const UpcomingEvents = () => {
  const rangeStartDate = useMemo(() => {
    const now = new Date();
    return now;
  }, []);
  const {
    calendar: { errorLoadingUpcomingEvents, noUpcomingEventsMessage },
  } = useStrapiDashboardData();
  const upcomingCalendarEventsQuery = useMyCalendarItemsQuery({
    rangeStartDate,
    limit: UPCOMING_EVENTS_DISPLAY_COUNT,
  });

  const calendarItems = upcomingCalendarEventsQuery.data?.calendarItems;
  const hasUpcomingEvents = calendarItems && calendarItems.length > 0;

  // convert events list to date to event map
  const dateToCalendarItemsMap = useMemo(() => {
    if (!hasUpcomingEvents) {
      return null;
    }

    const response = generateDateToCalendarItemsMap({
      items: calendarItems,
    });

    return response['dateItemsMap'];
  }, [calendarItems, hasUpcomingEvents]);

  // If upcoming events are loading
  if (upcomingCalendarEventsQuery.isLoading) {
    return (
      <UpcomingEventsBaseLayout>
        <div className="flex w-full justify-center py-2">
          <ScaleLoader />
        </div>
      </UpcomingEventsBaseLayout>
    );
  }

  // If there is an error
  if (upcomingCalendarEventsQuery.isError) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <ErrorDisplay
          refetch={upcomingCalendarEventsQuery.refetch}
          allowsRefetch={true}
          isRefetching={upcomingCalendarEventsQuery.isRefetching}
          message={errorLoadingUpcomingEvents}
          className="gap-2"
        />
      </div>
    );
  }

  if (!hasUpcomingEvents || dateToCalendarItemsMap == null) {
    return (
      <UpcomingEventsBaseLayout>
        <span className="text-xs font-normal text-zinc-200">{noUpcomingEventsMessage}</span>
      </UpcomingEventsBaseLayout>
    );
  }

  return (
    <UpcomingEventsBaseLayout>
      {Object.keys(dateToCalendarItemsMap).map((dateStr) => {
        const calendarDate = new Date(dateStr);

        const calendarItems = dateToCalendarItemsMap[dateStr];

        return (
          <CalendarItemsList
            key={`upcoming_events_${dateStr}`}
            calendarDate={calendarDate}
            calendarItems={calendarItems}
          />
        );
      })}
    </UpcomingEventsBaseLayout>
  );
};

export default UpcomingEvents;
