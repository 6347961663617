import { Link } from 'react-router-dom';
import { useStrapiDataHelper } from '../../../../../hooks/useStrapiData';

const UserForgotPasswordMainFooter = () => {
  const data: any = useStrapiDataHelper();
  const { backToLoginButton } = data.forgotpassword.data.attributes;
  return (
    <div className="flex flex-col items-center gap-3">
      <Link to="/auth/login" className="text-sm font-normal leading-[23px] text-white">
        {backToLoginButton}
      </Link>
    </div>
  );
};

export default UserForgotPasswordMainFooter;
