import clsx from 'clsx';
import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { twMerge } from 'tailwind-merge';
import { ErrorDisplay, NoDataDisplay } from '../../../../../../components/DataTables';
import { ERROR_TYPES } from '../../../../../../constants/error-types.constants';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { REPORTS_DASHBOARD_LIMIT, REPORT_TYPES } from '../../../../../shared/constants';
import { useStrapiReportsData } from '../../../../../shared/hooks/useStrapiReportData';
import {
  HEALTH_RISK_STATUS,
  USHG_ADMIN_CUSTOMER_LEADERBOARD_DEFAULT_SORT_KEY,
  USHG_ADMIN_CUSTOMER_LEADERBOARD_DEFAULT_SORT_ORDER,
} from '../../../../constants';
import { useUSHGAdminCustomerLeaderboardQuery } from '../../../../hooks';
import { CustomerLeaderboardFilters } from '../../../../types';
import TableHeader from '../TableHeader';
import CustomerLeaderboardTable from './CustomerLeaderboardTable';
import ScaleLoader from '../../../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../../../components/ReportToolTip';
import { ReportTooltipDataType } from '../../../../../shared/types';

interface CustomerLeaderboardMainProps {
  className?: string;
  tableWrapperClassName?: string;
  lazyLoad?: boolean;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}

const CustomerLeaderboardMain = ({
  className,
  tableWrapperClassName,
  lazyLoad = true,
  reportTooltipContent,
  userPersona,
}: CustomerLeaderboardMainProps) => {
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);

  const strapidata = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;

  // Build the filter
  const filters: Partial<CustomerLeaderboardFilters> = {
    size: REPORTS_DASHBOARD_LIMIT,
    sortKey: USHG_ADMIN_CUSTOMER_LEADERBOARD_DEFAULT_SORT_KEY,
    sortOrder: USHG_ADMIN_CUSTOMER_LEADERBOARD_DEFAULT_SORT_ORDER,
    healthStatus: HEALTH_RISK_STATUS.HEALTHY,
  };

  const customerLeaderboard = useUSHGAdminCustomerLeaderboardQuery({
    filters,
    isEnabled: isInView,
  });

  const displayLoader =
    (customerLeaderboard.isLoading && !customerLeaderboard.data) ||
    (customerLeaderboard.isFetching && customerLeaderboard.data?.totalCount === 0);

  const hasCustomerLeaderboard =
    customerLeaderboard.data && customerLeaderboard.data.totalCount > 0;

  const hasNoCustomerLeaderboard =
    customerLeaderboard.data && customerLeaderboard.data.totalCount === 0;
  const { title } = useStrapiReportsData().CustomerLeaderboard;
  return (
    <InView
      as="div"
      className={clsx(
        'relative flex h-full max-h-[320px] min-h-[320px] w-full flex-col gap-4 overflow-hidden rounded-lg bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)]',
        className
      )}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <>
        <div className="flex gap-2">
          <TableHeader title={title} />
          {reportTooltipContent?.[REPORT_TYPES.CUSTOMER_LEADER_BOARD]?.[userPersona] && (
            <ReportTooltip
              content={reportTooltipContent[REPORT_TYPES.CUSTOMER_LEADER_BOARD][userPersona]}
            />
          )}
        </div>
        {/* Table component */}
        {hasCustomerLeaderboard && (
          <div className={twMerge('w-full overflow-hidden pb-2', tableWrapperClassName)}>
            {customerLeaderboard.data.results && (
              <CustomerLeaderboardTable data={customerLeaderboard.data.results} />
            )}
          </div>
        )}
        {/* Empty State */}
        {hasNoCustomerLeaderboard && !displayLoader && (
          <div className="flex grow items-center justify-center">
            <NoDataDisplay message={'Top Churn Risks not found.'} />
          </div>
        )}
        {/* Loader */}
        {customerLeaderboard.isLoading && (
          <div className="flex grow items-center justify-center">
            <ScaleLoader />
          </div>
        )}
        {/* Error State */}
        {customerLeaderboard.error && !customerLeaderboard.data && (
          <div className="flex grow items-center justify-center">
            <ErrorDisplay
              refetch={customerLeaderboard.refetch}
              isRefetching={customerLeaderboard.isRefetching}
              allowsRefetch={true}
              type={ERROR_TYPES.SOMETHING_WENT_WRONG}
              message={strapiErrorMessage['SYSTEM_ERROR']}
            />
          </div>
        )}
      </>
    </InView>
  );
};

export default CustomerLeaderboardMain;
