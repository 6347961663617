import clsx from 'clsx';
import UserRegistrationMainHeader from './UserRegistrationMainHeader';
import { CollapseComponent } from '../../../../../../components/Collapse/Collapse';
import Form from './Form';
import Registration from '../../../../data/Registration';
import { Button } from '../../../../../../components/Buttons';
import { ArrowRight } from 'phosphor-react';
import { useAppDispatch, useAppSelector, useIsFederatedUser } from '../../../../../../hooks';
import { DynamicFieldData } from '../../../../../../types/dynamic-control-types';
import * as registrationSlice from '../../../../slices/registration.slice';
import { checkoutSession } from '../../../../helpers/register';
import { useEffect, useMemo, useState } from 'react';
import { RootState } from '../../../../../../store';
import { BasicSpinner } from '../../../../../../components/Spinners';
import {
  BasicDetails,
  CheckoutSession,
  CompanyDetails,
  EnterpriseRegistration,
  FreeTrail,
  Registration as RegistrationArgs,
  SubscriptionType,
} from '../../../../types';
import { UserSubscriptionDetail } from './UserSubscriptionDetail';
import { LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY } from '../../../../constants';
import { useLocation } from 'react-router-dom';
import { LOCAL_STORAGE_USER_PREFERRED_LANGUAGE } from '../../../../../../constants/user';

interface Props {
  role: string;
  registrationFormData?: Partial<RegistrationArgs>;
  disablePopulatedFields?: boolean;
  enterpriseInvite: Partial<EnterpriseRegistration> | undefined;
}

const UserRegistrationForm = ({
  role,
  registrationFormData,
  enterpriseInvite,
  disablePopulatedFields = false,
}: React.PropsWithChildren<Props>) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const isFederatedUser = useIsFederatedUser();

  const { form, nextStepLabel, completeSubscriptionLabel } = Registration();
  const registrationStateSelector = (state: RootState) => state.register;
  const { currentIndex, registration, changePlanEvent } = useAppSelector(registrationStateSelector);
  const pricingStateSelector = (state: RootState) => state.pricing;
  const { pricing, tenure, licenses } = useAppSelector(pricingStateSelector);
  const { freeTrail, subscriptionType } = pricing;
  const [nextStepClicked, setNextStepClicked] = useState<boolean | null>(null);
  const [nextStepToggle, setNextStepToggle] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [render, setRender] = useState<any>([]);
  const queryParams = new URLSearchParams(location.search);
  const inviteId = queryParams.get('inviteId') || enterpriseInvite?.inviteId;

  const formSubmitHandler = async () => {
    let obj: any = {};
    registration.forEach((item: any) => {
      if (item.type === 'form') {
        obj[item.slug] = { ...item.data };
      }
    });
    obj = {
      ...obj,
      freeTrail,
      subscriptionType,
      role,
    };

    // trim all the fields and perform transforms

    obj.basicDetails.firstName = obj.basicDetails.firstName.trim();
    obj.basicDetails.lastName = obj.basicDetails.lastName.trim();
    obj.basicDetails.preferredLanguage = localStorage.getItem(
      LOCAL_STORAGE_USER_PREFERRED_LANGUAGE
    );

    if (obj.subscriptionType === 'basic') {
      obj.isFederatedLoginUser = isFederatedUser;
      obj.basicDetails.personalEmail = obj.basicDetails.personalEmail.trim();
      obj.basicDetails.corporateOptionalEmail &&
        (obj.basicDetails.corporateOptionalEmail = obj.basicDetails.corporateOptionalEmail.trim());
    }

    if (obj.subscriptionType === 'team') {
      obj.basicDetails.corporateEmail = obj.basicDetails.corporateEmail.trim();
      obj.companyDetails.companySize = parseInt(obj.companyDetails.companySize);
      obj.companyDetails.companyName = obj.companyDetails.companyName.trim();
      obj.companyDetails.department = obj.companyDetails.department.trim();
    }

    // cache registration payload to localStorage
    localStorage.setItem(LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY, JSON.stringify(obj));

    // delete confirm password from request args
    delete obj.basicDetails.confirmPassword;
    await checkoutSessionHandler(obj);
  };

  const checkoutSessionHandler = async (data: {
    freeTrail: FreeTrail;
    subscriptionType: SubscriptionType;
    basicDetails: BasicDetails;
    companyDetails: CompanyDetails;
  }) => {
    try {
      const { freeTrail, subscriptionType, basicDetails, companyDetails } = data;
      const { corporateEmail, personalEmail, firstName, lastName, preferredLanguage } =
        basicDetails || {};
      const { companyName } = companyDetails || {};
      const checkoutSessionArgs = {
        email: corporateEmail || personalEmail,
        licenses: subscriptionType === 'basic' ? 1 : licenses?.toString(),
        name: firstName + ' ' + lastName,
        pricingType: tenure,
        subscriptionType,
        freeTrail,
        inviteId: inviteId ? inviteId : undefined,
        companyName,
        preferredLanguage,
      } as CheckoutSession;
      setIsSubmitting(true);
      const response = await checkoutSession(checkoutSessionArgs);
      if (response?.sessionUrl) {
        window.open(response.sessionUrl, '_self');
      }
    } catch (error) {
      // Todo: Improve Error handling
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onNextStepClicked = () => {
    if (currentIndex === render.length - 1) {
      formSubmitHandler();
    }
    setNextStepClicked(true);
    setNextStepToggle(!nextStepToggle);
  };

  useEffect(() => {
    // remove key from local/session storage if exists
    localStorage.removeItem(LOCAL_STORAGE_USHG_HQ_REGISTRATION_KEY);
    const filteredArray = form.filter((item: any) => item.role.includes(role));
    setRender(filteredArray);

    // change plan
    if (role !== changePlanEvent.role) {
      // reset form data
      const payload = form.map((key: any, index: number) => {
        return {
          touched: index === 0,
          index,
          slug: key.slug,
          data: {},
          errors: [],
          type: key.type,
        };
      });
      dispatch(registrationSlice.setValue(payload));
      dispatch(registrationSlice.setCurrentIndex(0)); // set currentIndex to 0 : Initial val
    }
  }, [role]);

  const memoizedForm = useMemo(() => form, [form]);

  useEffect(() => {
    const filteredArray = memoizedForm.filter((item: any) => item.role.includes(role));
    setRender(filteredArray);
  }, [memoizedForm]);

  useEffect(() => {
    if (nextStepClicked === false) {
      const nextValue = currentIndex + 1;

      setTouched(nextValue);
      dispatch(registrationSlice.setCurrentIndex(nextValue));
    }
  }, [nextStepClicked]);

  const setTouched = (index: number) => {
    const registrationArray = JSON.parse(JSON.stringify(registration));
    registrationArray[index].touched = true;
    dispatch(registrationSlice.setValue(registrationArray));
  };

  const setErrors = (value: object, formKey: number) => {
    if (Object.keys(value).length) {
      const registerCopy = JSON.parse(JSON.stringify(registration));
      registerCopy[formKey].errors = Object.keys(value);
      dispatch(registrationSlice.setValue(registerCopy));
    }
  };

  const validateForm = (
    formKey: number,
    data: object,
    isSubmitting: boolean,
    isValid: boolean,
    errors: object
  ) => {
    const registerCopy = JSON.parse(JSON.stringify(registration));
    registerCopy[formKey] = {
      ...registerCopy[formKey],
      data: data,
      errors: Object.keys(errors),
      isValid: isValid,
    };
    dispatch(registrationSlice.setValue(registerCopy));
    if (isValid && Object.keys(errors).length === 0) {
      setNextStepClicked(false);
    }
  };

  return (
    <main id="main-content" className="mx-auto flex w-full max-w-4xl flex-col gap-10 p-4 md:w-3/4">
      <UserRegistrationMainHeader />
      {render?.map((item: any, index: number) => (
        <CollapseComponent
          showTick={registration[index]?.isValid && registration[index].errors.length === 0}
          collapseKey={index}
          key={index}
          title={item.title}
          className="rounded-lg border border-base-bright-green/30 bg-zinc-900 px-2"
        >
          {item.type === 'component' ? (
            <UserSubscriptionDetail
              role={role}
              setIsSubmitting={setIsSubmitting}
              enterpriseInviteId={inviteId}
            />
          ) : (
            <Form
              key={index}
              formKey={index}
              className="mt-2 grid grid-cols-1 gap-4 text-white lg:grid-cols-2"
              defaultValue={
                changePlanEvent.role
                  ? registration[index]?.data
                  : (registrationFormData?.[
                      item?.slug as keyof RegistrationArgs
                    ] as Partial<RegistrationArgs>)
              }
              nextStepClicked={nextStepClicked}
              nextStepToggle={nextStepToggle}
              validateForm={validateForm}
              setErrors={setErrors}
              setIsSubmitting={setIsSubmitting}
              fields={(item?.content as DynamicFieldData[])?.filter(
                (i: DynamicFieldData) =>
                  !Object.prototype.hasOwnProperty.call(i, 'role') ||
                  i?.role?.split(',').includes(role) ||
                  i?.role === null
              )}
              enterpriseInvite={
                enterpriseInvite?.[
                  item?.slug as keyof RegistrationArgs
                ] as Partial<RegistrationArgs>
              }
              disablePopulatedFields={disablePopulatedFields}
            />
          )}
        </CollapseComponent>
      ))}
      <Button
        onClick={onNextStepClicked}
        className={clsx(
          'ml-auto flex cursor-pointer items-center justify-center gap-2 whitespace-nowrap bg-base-brand text-center text-sm font-semibold leading-[25px] text-white',
          render.length - 1 && 'w-52'
        )}
      >
        {isSubmitting ? (
          <BasicSpinner className="mr-0 h-[25px] w-[25px] text-white" />
        ) : currentIndex === render.length - 1 ? (
          <>
            <p>{completeSubscriptionLabel}</p>
            <ArrowRight weight="bold" />
          </>
        ) : (
          <p>{nextStepLabel}</p>
        )}
      </Button>
    </main>
  );
};
export default UserRegistrationForm;
