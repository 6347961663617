import { Option } from '../../../components/Select';

export const LIST_INDIVIDUALS_DEFAULT_PAGE = 1;
export const LIST_INDIVIDUALS_DEFAULT_START = 0;
export const LIST_INDIVIDUALS_MIN_PAGE_SIZE = 10;
export const LIST_INDIVIDUALS_MAX_PAGE_SIZE = 50;
export const LIST_INDIVIDUALS_PAGE_SIZE_INCREMENT = 10;
export const GET_INDIVIDUAL_QUERY_KEY = 'GET_INDIVIDUAL_QUERY_KEY';
export const STALE_TIME = 2000;
export const INDIVIDUAL_API_PATHS = {
  GET_INDIVIDUALS: '/users/hq/v1/individuals',
  GET_USER_STATS: '/reports/hq/v1/individual/statistic/{username}',
};
export const INDIVIDUAL_STATUS_VALUES: Option[] = [
  {
    display: 'All',
    value: 'All',
  },
  {
    display: 'In Risk',
    value: 'Unhealthy',
  },
  {
    display: 'Healthy',
    value: 'Healthy',
  },
  {
    display: 'No Data',
    value: 'NoData',
  },
];
