import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
import { NotificationFragmentFragment } from '../types';

const useNotificationsStrapiData = () => {
  const { notification } = useStrapiDataHelper() as { notification: NotificationFragmentFragment };

  const { pageHeading, infiniteNotificationsList, notificationType, errorMessages, shared } =
    notification.data.attributes;

  return { pageHeading, infiniteNotificationsList, notificationType, errorMessages, shared };
};

export default useNotificationsStrapiData;
