import clsx from 'clsx';
import { CoursesCardDisplay } from '../../../../components/Wrapper';
import { useMyCoursesQuery } from '../../hooks';
import { ComponentHeading } from '../common';
import { SORT_FIELD, USER_COURSE_STATUS } from '../../types/CourseType';
import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import NoDataFallback from '../../../shared/components/NoDataFallback';
import { ErrorDisplay } from '../../../../components/DataTables';
import { useStrapiDashboardData } from '../../../shared/hooks';
import { useStrapiDataHelper } from '../../../../hooks/useStrapiData';
import { Link } from 'react-router-dom';
import ScaleLoader from '../../../../components/Loader/ScaleLoader';

interface Props {
  className?: string;
  lazyLoad?: boolean;
}

const AssignedCourses = ({ className, lazyLoad = true }: Props) => {
  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);
  // Build the filter
  const inProgressCourseFilter = {
    offset: 0,
    limit: 5,
    type: USER_COURSE_STATUS.ASSIGNED,
    sort: SORT_FIELD.COURSE.DUE_DATE,
  };

  const inProgressCoursesQuery = useMyCoursesQuery({
    item: 'assigned-courses',
    filters: inProgressCourseFilter,
    isEnabled: isInView,
  });

  // Loading State
  const isLoadingInProgressCourses = inProgressCoursesQuery.isLoading;

  const hasInProgressCourses =
    inProgressCoursesQuery.data && inProgressCoursesQuery.data?.total > 0;

  const ProgressCoursesData = hasInProgressCourses ? inProgressCoursesQuery.data?.results : null;

  const hasNoInprogressCourses =
    inProgressCoursesQuery.data && inProgressCoursesQuery.data?.total === 0;
  const { title, emptyMessage, errorMessage } = useStrapiDashboardData().continueWhereYouLeft;
  const finalEmptyMessage = emptyMessage.split('{{clickhere}}');
  const data = useStrapiDataHelper();
  const { clickHereLabel } = data.errormessage.data.attributes;
  return (
    <>
      {ProgressCoursesData ? (
        <InView
          as="div"
          className={clsx(
            'flex h-full min-h-[320px] w-full flex-col rounded-lg bg-card-bg p-4',
            className
          )}
          onChange={(inView) => {
            // default inView local state would be false,
            // set it to true when it enters into the viewport and it stays the same
            if (inView) {
              setIsInView(inView);
            }
          }}
          // this will disabled the intersection observer once we are in view
          // since we only need it until once the card comes into view
          skip={isInView}
        >
          <div className="flex grow flex-col gap-4">
            <>
              <ComponentHeading heading={title} />
              {/* Display Loader */}
              {isLoadingInProgressCourses && !hasInProgressCourses && (
                <div className="flex grow items-center justify-center">
                  <ScaleLoader />
                </div>
              )}
              {/* We have in-progress courses */}
              {hasInProgressCourses && <CoursesCardDisplay coursesList={ProgressCoursesData} />}
              {hasNoInprogressCourses && !isLoadingInProgressCourses && (
                <NoDataFallback
                  className="text-base"
                  message={
                    <>
                      {finalEmptyMessage[0]}
                      <Link to="/courses?view=all" className="underline underline-offset-1">
                        {clickHereLabel}
                      </Link>
                      {finalEmptyMessage[1]}
                    </>
                  }
                />
              )}
              {inProgressCoursesQuery.error && !inProgressCoursesQuery.data && (
                <div className="flex grow items-center justify-center">
                  <ErrorDisplay
                    message={errorMessage}
                    refetch={inProgressCoursesQuery.refetch}
                    allowsRefetch={true}
                    isRefetching={inProgressCoursesQuery.isRefetching}
                  />
                </div>
              )}
            </>
          </div>
        </InView>
      ) : null}
    </>
  );
};

export default AssignedCourses;
