import { useCallback } from 'react';
import { TENANT_MFA_CONFIG } from '../../../../constants';
import { useUpdateTenantMFAConfigMutation } from '../../../../hooks';
import ActionDialogContent from '../../../common/components/ActionDialogContent';
import { useStrapiSettingsData } from '../../../../hooks/useStrapiSettingsData';

type Props = {
  setShowDialog: (open: boolean) => void;
};

const DisableMFAMandate = (props: Props) => {
  const { setShowDialog } = props;

  // mutations
  const updateTenantMFAConfigMutation = useUpdateTenantMFAConfigMutation();
  // mutations

  // strapi content

  const {
    enterpriseAdminMfaMandate: { DisableMFAMandateDialog: disableMFAMandateCMS },
  } = useStrapiSettingsData();

  // strapi content

  // handlers
  const handleConfirm = useCallback(async () => {
    try {
      await updateTenantMFAConfigMutation.mutateAsync({
        mfaConfig: TENANT_MFA_CONFIG.OPTIONAL,
      });
      setShowDialog(false);
    } catch (error) {
      console.error(error);
    }
  }, [setShowDialog, updateTenantMFAConfigMutation]);
  // handlers

  return (
    <ActionDialogContent
      heading={disableMFAMandateCMS.heading}
      Description={disableMFAMandateCMS.description}
      primaryAction={{
        handler: handleConfirm,
        label: disableMFAMandateCMS.confirmLabel,
        isLoading: updateTenantMFAConfigMutation.isLoading,
        className: 'bg-red-600 font-normal',
      }}
      secondaryAction={{
        handler: () => {
          setShowDialog(false);
        },
        label: disableMFAMandateCMS.cancelLabel,
      }}
    ></ActionDialogContent>
  );
};

export default DisableMFAMandate;
