import { PaperPlaneTilt } from 'phosphor-react';
import { HelpWidgetItem } from './HelpWidgetItem';
import { HelpWidgetItemContent } from './HelpWidgetItemContent';
import { useStrapiSharedData } from '../../hooks/useStrapiSharedData';

type RaiseRequestToHQWidgetProps = {
  onSelect?: ((event: Event) => void) | undefined;
};

const RaiseRequestToHQWidget = (props: RaiseRequestToHQWidgetProps) => {
  const { onSelect } = props;

  const { helpAndResourcesWidget: cms } = useStrapiSharedData();

  return (
    <HelpWidgetItem onSelect={onSelect} className="cursor-pointer">
      <HelpWidgetItemContent Icon={PaperPlaneTilt} label={cms.raiseRequestToHQLabel} />
    </HelpWidgetItem>
  );
};

export default RaiseRequestToHQWidget;
