interface Props {
  badgeId: number;
  className?: string;
}
import React, { useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { DotsThreeVertical, PencilSimple, Trash } from 'phosphor-react';

import Dialog from '../../../../../components/Dialog';
import DeleteBadgeModal from '../deleteBadge';
import clsx from 'clsx';
import EditAndManageBadgeModal from '../EditAndManageBadge';
import useStrapiBadgesData from '../../../hooks/useStrapiBadgeData';

const BadgeTableDropDown = ({ className, badgeId }: Props) => {
  const toggleDeleteBadgeModalVisibility = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);

  // strapi
  const { modal, table } = useStrapiBadgesData();
  const { editBadgeTitle, editBadgeButton, manageLanguagesTitle } = modal;
  const { editOptionLabel, deleteOptionLabel, i18nOptionLabel } = table.TableDropDownOptions;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button aria-label="Customise options">
          <DotsThreeVertical size={16} />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          side={'bottom'}
          align={'start'}
          alignOffset={-100}
          className="z-20 min-w-[125px] gap-1 !rounded-md border border-zinc-500 !bg-zinc-800 p-2 text-xs"
        >
          <DropdownMenu.Item
            onClick={() => {
              setIsEditModalOpen(true);
            }}
            className={clsx(
              'flex cursor-pointer gap-x-2 text-xs font-medium text-zinc-50 outline-none hover:rounded-sm hover:focus:bg-base-brand',
              className
            )}
          >
            <span className="flex items-center gap-1 p-1">
              <PencilSimple size={14} />
              <span>{editOptionLabel}</span>
            </span>
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onClick={() => {
              setIsManageModalOpen(true);
            }}
            className={clsx(
              'flex cursor-pointer gap-x-2 text-xs font-medium text-zinc-50 outline-none hover:rounded-sm hover:focus:bg-base-brand',
              className
            )}
          >
            <span className="flex items-center gap-1 p-1">
              <PencilSimple size={14} />
              <span>{i18nOptionLabel}</span>
            </span>
          </DropdownMenu.Item>
          <DropdownMenu.Item
            className={clsx(
              'flex cursor-pointer  gap-x-2 text-xs font-medium text-zinc-50 outline-none hover:rounded-sm hover:focus:bg-base-brand',
              className
            )}
            onClick={() => {
              setIsDeleteModalOpen(true);
            }}
          >
            <span className="flex items-center gap-1 p-1">
              <Trash size={14} />
              <span>{deleteOptionLabel}</span>
            </span>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
      <DeleteBadgeModal
        badgeId={badgeId}
        open={isDeleteModalOpen}
        toggleVisibility={toggleDeleteBadgeModalVisibility}
      />
      <Dialog
        open={isEditModalOpen}
        onOpenChange={(open) => {
          setIsEditModalOpen(open);
        }}
        Content={
          <EditAndManageBadgeModal
            modalTitle={editBadgeTitle}
            isEditModalOpen={isEditModalOpen}
            setIsBadgeModalOpen={setIsEditModalOpen}
            formActionButtonLabel={editBadgeButton}
            modalType={'update'}
            badgeId={badgeId}
          />
        }
      ></Dialog>
      <Dialog
        open={isManageModalOpen}
        onOpenChange={(open) => {
          setIsManageModalOpen(open);
        }}
        Content={
          <EditAndManageBadgeModal
            modalTitle={manageLanguagesTitle}
            isManageModalOpen={isManageModalOpen}
            setIsBadgeModalOpen={setIsManageModalOpen}
            modalType={'manage'}
            badgeId={badgeId}
            showLanguageDropdown={true}
          />
        }
      ></Dialog>
    </DropdownMenu.Root>
  );
};

export default BadgeTableDropDown;
