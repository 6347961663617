import { useInfiniteQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import useDebounce from '../../../../hooks/use-debounce';
import { getEnterpriseLeaderTeams } from '../../api/EnterpriseLeaderTeams';
import {
  ENT_LEADER_TEAMS_DEFAULT_PAGE,
  ENT_LEADER_TEAMS_FILTER_QUERY_PARAM,
  ENT_LEADER_TEAMS_FILTER_SEARCH_DEBOUNCE_MS,
  ENT_LEADER_TEAMS_MIN_PAGE_SIZE,
} from '../../constants/enterpriseLeader';
import { GET_ALL_TEAMS_FOR_LEADERS } from '../../constants/react-query';
import { useStrapiTeamData } from '../../hooks/useStrapiTeamData';
import { toggleAddNewTeamModal } from '../../slices/team.slice';
import { EnterpriseLeaderTeam, PartialEnterpriseLeaderTeamsFilter } from '../../types/interface';
import TeamCard from '../TeamCard/TeamCard';
import TeamsEmptyState from '../TeamsEmptyState';
import TeamsErrorState from '../TeamsErrorState';

type TeamsProps = {
  classNameProps: string;
};
const Teams = ({ classNameProps }: TeamsProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let isFilterApplied = false;

  const search = searchParams.get(ENT_LEADER_TEAMS_FILTER_QUERY_PARAM) ?? undefined;
  // If a filter string is present set isFilterApplied to true
  isFilterApplied = search !== undefined;
  const [page, _setPage] = useState(ENT_LEADER_TEAMS_DEFAULT_PAGE);

  const [pageSize, _setPageSize] = useState(ENT_LEADER_TEAMS_MIN_PAGE_SIZE);
  const debouncedFilter = useDebounce(search, ENT_LEADER_TEAMS_FILTER_SEARCH_DEBOUNCE_MS);

  const filters: PartialEnterpriseLeaderTeamsFilter = useMemo(() => {
    return {
      search: debouncedFilter,
    };
  }, [debouncedFilter, page, pageSize]);

  const fetchTeams = async ({ pageParam = { start: 0, size: 10 } }) => {
    const filterFinal = { ...filters, ...pageParam };

    return await getEnterpriseLeaderTeams(filterFinal);
  };
  const { isLoading, error, data, fetchNextPage, refetch, isRefetching, isFetchingNextPage } =
    useInfiniteQuery([GET_ALL_TEAMS_FOR_LEADERS, filters], fetchTeams, {
      getNextPageParam: (lastPage, pages) => {
        const totalCount = lastPage.count;
        const maximumPages = Math.ceil(totalCount / 10);

        if (pages.length >= maximumPages) {
          return undefined;
        }
        return { start: pages.length * 10, size: 10 };
      },
    });

  const cardObserver: any = useRef();
  const _cardObserverCallBack = useCallback(
    (card: any) => {
      if (isLoading) return;
      if (cardObserver.current) cardObserver.current.disconnect();
      cardObserver.current = new IntersectionObserver((cardElement) => {
        if (cardElement[0].isIntersecting) {
          fetchNextPage();
        }
      });
      if (card) cardObserver.current.observe(card);
    },
    [isLoading]
  );
  const dispatch = useDispatch();
  const _toggleVisible = () => {
    dispatch(toggleAddNewTeamModal());
  };
  const { loadingMoreTeams } = useStrapiTeamData().loadingMessages;
  return (
    <div className={clsx('flex w-full flex-col gap-4')}>
      {error ? (
        <div className="flex h-full items-center justify-center text-zinc-200">
          <TeamsErrorState isRefetching={isRefetching} refetch={refetch} />{' '}
        </div>
      ) : !isLoading && data ? (
        data.pages[0].count !== 0 ? (
          <div className={classNameProps}>
            {data.pages.map((page) =>
              page.rows.map((team: EnterpriseLeaderTeam, index: number) => {
                if (index + 1 === page.rows.length)
                  return (
                    <div key={team.teamId} ref={_cardObserverCallBack}>
                      <TeamCard key={team.teamId} team={team} />
                    </div>
                  );
                return (
                  <div key={team.teamId}>
                    <TeamCard key={team.teamId} team={team} />
                  </div>
                );
              })
            )}
          </div>
        ) : data.pages[0].count == 0 ? (
          <div className="mt-4 flex min-h-[250px] w-full items-center justify-center">
            <TeamsEmptyState isFilterApplied={isFilterApplied} />
          </div>
        ) : (
          <div className="flex h-[16.98rem] w-full items-center justify-center">
            <FadeLoader color="#E4E4E7" width="3px" />
          </div>
        )
      ) : (
        <div className="flex h-[16.98rem] w-full items-center justify-center">
          {isLoading && <FadeLoader color="#E4E4E7" width="3px" />}
        </div>
      )}
      {isFetchingNextPage && (
        <div className="mb-5 flex justify-center text-zinc-200">
          <p>{loadingMoreTeams}</p>
        </div>
      )}
    </div>
  );
};

export default Teams;
