import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
import { previewSubscription } from '../api/subscription';
import { PreviewSubscription, PreviewSubscriptionResponse } from '../types/preview';

const usePreviewSubscriptionMutation = () => {
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const mutation = useMutation({
    mutationFn: async (args: PreviewSubscription): Promise<PreviewSubscriptionResponse> => {
      const response = await previewSubscription(args);
      return response;
    },
    onError: () => {
      toast.custom((t) => (
        <Toast variant="error" Title={strapiErrorMessage['SYSTEM_ERROR']} toastInstance={t} />
      ));
    },
  });

  return mutation;
};

export default usePreviewSubscriptionMutation;
