import { useEffect, useState } from 'react';

type useMediaUploadManagerProps = {
  uploader: any;
};

type uploadStates = {
  isProgress: boolean;
  progress: number;
  isError: boolean;
  error: string;
  isUploaded: boolean;
  initiating: boolean;
  aborted: boolean;
  data: any;
};

const useMediaUploadManager = ({ uploader }: useMediaUploadManagerProps) => {
  const [uploadState, setUploadState] = useState<uploadStates>({
    isProgress: false,
    progress: 0,
    isError: false,
    error: '',
    isUploaded: false,
    initiating: false,
    aborted: false,
    data: undefined,
  });
  useEffect(() => {
    if (uploader) {
      let percentage: any = undefined;
      uploader
        .onProgress(({ percentage: newPercentage }: any) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            // setProgress(percentage);
            setUploadState({
              isProgress: true,
              progress: percentage,
              isError: false,
              error: '',
              isUploaded: false,
              initiating: false,
              aborted: false,
              data: undefined,
            });
          }
        })
        .onError((error: any) => {
          console.log(error);
          if (uploader.aborted) {
            setUploadState({
              isProgress: false,
              progress: percentage,
              isError: true,
              error: error,
              isUploaded: false,
              initiating: false,
              aborted: true,
              data: undefined,
            });
          } else {
            setUploadState({
              isProgress: false,
              progress: percentage,
              isError: true,
              error: error,
              isUploaded: false,
              initiating: false,
              aborted: false,
              data: undefined,
            });
          }
        })
        .onComplete((response: any) => {
          setUploadState({
            isProgress: false,
            progress: percentage,
            isError: false,
            error: '',
            isUploaded: true,
            initiating: false,
            aborted: false,
            data: response.data,
          });
        })
        .onInitiate((initiate: boolean) => {
          setUploadState({
            isProgress: false,
            progress: percentage,
            isError: false,
            error: '',
            isUploaded: false,
            initiating: initiate,
            aborted: false,
            data: undefined,
          });
        });
      setUploadState({
        isProgress: true,
        progress: percentage,
        isError: false,
        error: '',
        isUploaded: false,
        initiating: false,
        aborted: false,
        data: undefined,
      });
      uploader.start();
    }
  }, [uploader]);

  return uploadState;
};

export default useMediaUploadManager;
