import { useStrapiSideNavData } from '../../hooks/useStrapiSideNavData';

const BrandLogoImage = () => {
  const { routes } = useStrapiSideNavData();
  const { logo } = routes;

  // TODO: get it from strapi @saairaam
  return <img src={logo} alt="Brand Logo" />;
};

export default BrandLogoImage;
