import Dialog from '../../../../../components/Dialog';
import UserRoleUpdateModal from './UserRoleUpdateModal';
import useStrapiMembersData from '../../../hooks/useStrapiMembersData';
type props = {
  setIsModalOpen: any;
  username: string;
  fullName: string;
  currentRole: string;
};
const UserRoleUpdate = ({ setIsModalOpen, username, currentRole, fullName }: props) => {
  const { userRoleUpdate, userTeamChange } = useStrapiMembersData();
  return (
    <div className="mx-3 flex flex-col gap-6 rounded-lg border border-zinc-900 bg-card-bg p-4 shadow-[0px_20px_25px_-5px_rgba(0,0,0,0.1),0px_10px_10px_-5px_rgba(0,0,0,0.04)] xs:p-6">
      <Dialog.Title className="text-lg font-semibold leading-[24px] text-white">
        {userRoleUpdate.changeRoleModalTitle} -{' '}
        {fullName && fullName.length !== 0 ? fullName : username}
      </Dialog.Title>
      <UserRoleUpdateModal
        currentRole={currentRole}
        setIsModalOpen={setIsModalOpen}
        username={username}
        userRoleUpdate={userRoleUpdate}
        userTeamChange={userTeamChange}
      />
    </div>
  );
};

export default UserRoleUpdate;
