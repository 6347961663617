import React from 'react';
import { CalendarItemType } from '../../../types';
import { CalendarItemDot } from '../common';

interface Props {
  calendarItemType: CalendarItemType;
}

const BaseCalendarItem = (props: React.PropsWithChildren<Props>) => {
  const { children, calendarItemType } = props;

  return (
    <div className="flex gap-2">
      <div className="flex h-5 items-center">
        <CalendarItemDot calendarItemType={calendarItemType} />
      </div>
      <div className="flex items-center gap-1 text-sm font-normal text-zinc-200">{children}</div>
    </div>
  );
};

export default BaseCalendarItem;
