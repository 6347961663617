import { FadeLoader } from 'react-spinners';
import { ErrorDisplay } from '../../../components/DataTables';
import { ERROR_TYPES } from '../../../constants/error-types.constants';
import { useReduxAuthState } from '../../../hooks';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
import useGetManagerTeamIdQuery from '../hooks/useGetManagerTeamIdQuery';
import SingleTeamView from './nonAdminPages/SingleTeamView';
import useSessionTracker from '../../../hooks/use-session-tracker-hook';
// api call to get team Id

function MyTeamPage() {
  useSessionTracker();
  const auth = useReduxAuthState();
  const managerTeamId = useGetManagerTeamIdQuery({ username: auth.user?.username });
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  {
    /* Is Loading and We donot have data */
  }
  if (managerTeamId.isLoading && !managerTeamId.data) {
    return (
      <div className="flex h-[16.98rem] w-full items-center justify-center">
        <FadeLoader color="#E4E4E7" width="3px" />
      </div>
    );
  }

  // Error handling
  if (managerTeamId.isError && !managerTeamId.data) {
    return (
      <div className="flex min-h-[250px] w-full items-center justify-center">
        <ErrorDisplay
          refetch={managerTeamId.refetch}
          isRefetching={managerTeamId.isRefetching}
          allowsRefetch={true}
          message={strapiErrorMessage['SYSTEM_ERROR']}
          type={ERROR_TYPES.SOMETHING_WENT_WRONG}
        />
      </div>
    );
  }

  return (
    <>
      {/* We have data */}
      {managerTeamId.data && (
        <SingleTeamView id={managerTeamId?.data?.teamId} showBackButton={false} />
      )}
    </>
  );
}
export default MyTeamPage;
