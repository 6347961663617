import { StylesConfig } from 'react-select';

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export const customStylesOne: StylesConfig<any> = {
  multiValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      borderRadius: '32px !important',
      fontSize: '10px !important',
      backgroundColor: '#27272a !important',
    };
  },
  multiValueRemove: (provided) => {
    return {
      ...provided,
      color: 'white',
      '&:hover': {
        background: 'none',
        color: '#e4e4e7',
      },
    };
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      color: 'white',
      fontSize: '11px',
    };
  },
  indicatorSeparator: (provided) => {
    return {
      ...provided,
      width: '1px',
      padding: '0px',
      display: 'none',
    };
  },
  control: (provided, { isDisabled }) => {
    return {
      ...provided,
      height: '100%',
      background: isDisabled ? '#3c3c44' : '#18181B',
      border: '1px solid #3F3F46 !important',
      borderRadius: '6px !important',
      boxShadow: 'none !important',
    };
  },
  menu: (provided) => {
    return {
      ...provided,
      background: '#18181B',
      color: 'white',
      border: '1px solid #3F3F46 !important',
      borderRadius: '4px !important',
      '&:hover': {
        background: '#18181B',
        color: 'white',
      },
    };
  },
  option: (provided) => {
    return {
      ...provided,
      background: '#18181B',
      color: 'white',
      '&:hover': {
        background: '#252528',
      },
    };
  },
  menuList: (provided) => {
    return {
      ...provided,
      borderRadius: '4px !important',
      fontSize: '11px !important',
      minHeight: 'fit-content !important',
      maxHeight: 'clamp(200px, 18vh, 220px) !important',
    };
  },
  input: (provided) => {
    return {
      ...provided,
      color: 'white',
      boxShadow: 'none !important',
      fontSize: '11px  !important',
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      fontSize: '12px !important',
    };
  },
  menuPortal: (provided) => {
    return {
      ...provided,
      margin: '0 !important',
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      color: 'white',
      fontSize: '12px',
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: '#71717a',
    };
  },
  clearIndicator: (provided) => {
    return {
      ...provided,
      color: '#71717a',
      padding: 0,
    };
  },
};

export const customStylesTwo: StylesConfig<any> = {
  multiValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      borderRadius: '0.125rem !important',
      fontSize: '14px !important',
      backgroundColor: '#27272a !important',
    };
  },
  multiValueRemove: (provided) => {
    return {
      ...provided,
      color: 'white',
      '&:hover': {
        background: 'none',
        color: '#e4e4e7',
      },
    };
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      color: 'white',
      fontSize: '14px',
    };
  },
  indicatorSeparator: (provided) => {
    return {
      ...provided,
      width: '1px',
      padding: '0px',
      display: 'none',
    };
  },
  control: (provided, { isDisabled }) => {
    return {
      ...provided,
      height: '100%',
      background: isDisabled ? '#3c3c44' : '#18181B',
      border: '1px solid #18181b !important',
      borderRadius: '0.125rem !important',
      boxShadow: 'none !important',
    };
  },
  menu: (provided) => {
    return {
      ...provided,
      background: '#18181B',
      color: 'white',
      border: '1px solid #3F3F46 !important',
      borderRadius: '4px !important',
      '&:hover': {
        background: '#18181B',
        color: 'white',
      },
    };
  },
  option: (provided) => {
    return {
      ...provided,
      background: '#18181B',
      color: 'white',
      '&:hover': {
        background: '#252528',
      },
    };
  },
  menuList: (provided) => {
    return {
      ...provided,
      borderRadius: '4px !important',
      fontSize: '11px !important',
      minHeight: 'fit-content !important',
      maxHeight: 'clamp(200px, 18vh, 220px) !important',
    };
  },
  input: (provided) => {
    return {
      ...provided,
      color: 'white',
      boxShadow: 'none !important',
      fontSize: '11px  !important',
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      fontSize: '14px !important',
    };
  },
  menuPortal: (provided) => {
    return {
      ...provided,
      margin: '0 !important',
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      color: 'white',
      fontSize: '14px',
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: '#71717a',
    };
  },
  clearIndicator: (provided) => {
    return {
      ...provided,
      color: '#71717a',
      padding: 0,
    };
  },
};
