import { gql } from '@apollo/client';

export const IndividualSignUpFragment = gql`
  fragment IndividualSignUpFragment on IndividualsignupEntityResponse {
    data {
      attributes {
        signUpTitle
        signUpDesc
        signUpButton
        signInContext
        signInLabel
      }
    }
  }
`;
