import clsx from 'clsx';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useStrapiOnBoardingData } from '../../../../../hooks/useStrapiOnBoardingData';
import { Category } from '../../../../../types';
import './styles.css';
import { generateCDNMediaAssetURLWithKey } from '../../../../../../shared/helpers';
import { ENTERPRISE_ONBOARDING_TYPE } from '../../../../../constants';

type AllCategoriesCardDisplayProps = {
  style: React.CSSProperties;
  handleSelectedCategories: (category: Category) => void;
  selectedCategories: Category[];
  categories: Category[];
  widgetType: keyof typeof ENTERPRISE_ONBOARDING_TYPE;
};
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    paritialVisibilityGutter: 20,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    paritialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    paritialVisibilityGutter: 20,
  },
};

const AllCategoriesCardDisplay = ({
  style,
  categories,
  selectedCategories,
  handleSelectedCategories,
  widgetType,
}: AllCategoriesCardDisplayProps) => {
  // Set Categories on card click
  const onCardClick = (category: Category) => {
    handleSelectedCategories(category);
  };

  const isCategorySelected = (category: Category, selectedCategories: Category[]): boolean => {
    return selectedCategories.some(
      (selectedCategory) =>
        selectedCategory.id === category.id && selectedCategory.type === widgetType
    );
  };

  const { leaderCategory } = useStrapiOnBoardingData();
  const { courseCountLabel } = leaderCategory;
  return (
    <div className="w-full">
      <Carousel responsive={responsive} infinite={true} partialVisible={true}>
        {categories.map((category, index) => (
          <div
            key={index}
            className="h-fit w-full cursor-pointer"
            onClick={() => onCardClick(category)}
          >
            <div
              className={clsx(
                'relative h-[192px] w-full overflow-hidden rounded-[4px] border-0 md:h-[241px]',
                isCategorySelected(category, selectedCategories) && 'border-4 border-emerald-500'
              )}
            >
              <img
                className="h-[192px] w-full object-cover md:h-[241px]"
                src={generateCDNMediaAssetURLWithKey({ key: category.image })}
                alt={category.name}
                loading="lazy"
              />
              <div className="absolute top-0 h-full w-full bg-gradient-to-t from-[#010211] via-[#03072B]/[0.35] transition group-hover:scale-105 group-hover:bg-[#010314C7]/[0.58]"></div>
              <div className="absolute top-0 h-full w-full">
                <div className="absolute inset-x-0 bottom-10 flex flex-col items-center justify-between text-center">
                  <div className="flex flex-col gap-y-2">
                    <div style={style}>
                      <p className="text-[14px] font-bold leading-[17px] tracking-tight text-white md:text-[17px]">
                        {category.name}
                      </p>
                    </div>
                  </div>
                </div>
                <p className="absolute inset-x-0 bottom-2 p-2 text-center text-[10px] leading-3 text-zinc-400">
                  {category.courseCount} {courseCountLabel}
                </p>
              </div>
              {isCategorySelected(category, selectedCategories) && (
                <div className="absolute top-2 right-2 flex h-6 w-6 items-center justify-center rounded-full bg-emerald-500 py-3 font-bold text-white">
                  <span className="text-xl font-normal text-white">&#10003;</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default AllCategoriesCardDisplay;
