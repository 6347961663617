import { gql } from '@apollo/client';

export const LoginMFAFragment = gql`
  fragment LoginMFAFragment on ComponentLoginMfa {
    Shared {
      heading
      authCodeLabel
      confirmAuthBtnLabel
      backToLoginLabel
      lostAccessLabel
      anyProblemLabel
      contactUsLabel
      contactUsLink
      codeRequiredErrorMessage
      loginSuccessToastMessage
      sessionExpiredErrorMessage
      somethingWentWrongErrorMessage
    }
    SMSMFA {
      description
      timeBetweenResend
      resendCodeBtnLabel
      resendAllowedInLabel
      resendingCodeToast
      resendingMFACodeSuccessToast
      resendingMFACodeErrorToast
    }
  }
`;
