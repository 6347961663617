import clsx from 'clsx';
import { ArrowRight, ArrowLeft } from 'phosphor-react';
import { Button } from '../../../../components/Buttons';
import { BasicSpinner } from '../../../../components/Spinners';
import { STEPS } from '../../constants';
import { useStrapiOnBoardingData } from '../../hooks/useStrapiOnBoardingData';

interface Props {
  stepperNextControl: () => void;
  stepperPrevControl: () => void;
  skipControl?: () => void;
  currentStep: string;
  isStepLoading: boolean;
  isNextStepLoading: boolean;
  doNotShowNext?: boolean;
  className?: string;
}
const StepperControl = ({
  stepperPrevControl,
  stepperNextControl,
  skipControl,
  currentStep,
  isStepLoading,
  isNextStepLoading,
  className,
  doNotShowNext,
}: Props) => {
  const { buttons } = useStrapiOnBoardingData();
  const { nextButton, backButton, goToDashboardButton, skipButton } = buttons;

  return (
    <div className={clsx('mt-4 mb-8 flex w-full justify-between gap-x-2', className)}>
      <Button
        onClick={() => stepperPrevControl()}
        className="flex w-[138px] items-center justify-center gap-2 rounded	border border-zinc-700 text-sm font-semibold text-zinc-400 transition duration-200 ease-in-out disabled:cursor-not-allowed lg:w-[106px]"
        aria-label="Go to previous page"
        disabled={isStepLoading}
      >
        <ArrowLeft />
        <p>{backButton}</p>
      </Button>
      <div className="flex gap-x-1">
        {currentStep === STEPS.INVITE_MEMBERS && (
          <Button
            onClick={() => skipControl!()}
            className="flex items-center justify-center gap-2 rounded	border border-zinc-700 text-sm font-semibold text-zinc-400 transition duration-200 ease-in-out disabled:cursor-not-allowed"
            aria-label="Skip the current page"
            disabled={isStepLoading}
          >
            <p>{skipButton}</p>
          </Button>
        )}
        {!doNotShowNext && (
          <Button
            type="submit"
            onClick={() => stepperNextControl()}
            className="flex w-[138px] items-center justify-center gap-2 rounded bg-base-brand text-sm font-semibold text-white transition duration-200 ease-in-out disabled:cursor-not-allowed lg:w-[106px]"
            aria-label="Go to next page"
            disabled={isStepLoading || isNextStepLoading}
          >
            {isNextStepLoading ? (
              <div className="flex gap-2">
                <span>{currentStep === STEPS.ALL_DONE ? goToDashboardButton : nextButton}</span>
                <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
              </div>
            ) : (
              <>{currentStep === STEPS.ALL_DONE ? goToDashboardButton : nextButton}</>
            )}
            {!isNextStepLoading ? <ArrowRight weight="bold" /> : null}
          </Button>
        )}
      </div>
    </div>
  );
};

export default StepperControl;
