import { twMerge } from 'tailwind-merge';
import { useSpeakerStrapiData } from '../../../../hooks/useSpeakerStrapiData';

interface Props {
  text: string | number;
  isLowercase?: boolean;
  isCoursesDisplay?: boolean;
  spanClassName?: string;
}

const CommonTextCell = ({
  text,
  isLowercase = false,
  isCoursesDisplay = false,
  spanClassName = '',
}: Props) => {
  const { tableProps } = useSpeakerStrapiData();
  return (
    <span
      className={twMerge(
        'inline-block whitespace-nowrap lowercase' + isLowercase && 'first-letter:uppercase',
        spanClassName
      )}
    >
      {text + (isCoursesDisplay ? ' ' + tableProps.coursesLabel : '')}
    </span>
  );
};

export default CommonTextCell;
