import { useQuery } from '@tanstack/react-query';

import { toast } from 'react-hot-toast';
import { Toast } from '../../../components/Toast';
import React from 'react';
import { getBadgeCriterias } from '../api/badges';
import { GetBadgeTypesResponse, GetBadgeTypesResponseItem } from '../constants/badges';
import useStrapiBadgesData from './useStrapiBadgeData';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';

type Props = {
  language?: string;
};

const useGetBadgeCriteriasQuery = (props?: Props) => {
  const { language = DEFAULT_PREFERRED_LANGUAGE } = props ?? {};
  const { badgeCriteriaFailed } = useStrapiBadgesData().hqToast;
  const { data, isLoading } = useQuery({
    queryKey: [
      {
        scope: 'badges',
        item: 'get-all-badge-criterias',
        language,
      },
    ],
    queryFn: async ({ queryKey }) => {
      const { language } = queryKey[0];
      return await getBadgeCriterias({ language });
    },
    select: (data: GetBadgeTypesResponse) =>
      data.map((criteria: GetBadgeTypesResponseItem) => ({
        label: criteria.name,
        value: criteria.badgeTypeKey,
        typeId: criteria.typeId,
      })),
    onError: (error) => {
      console.error('Error while fetching badge criterias %o', error);
      toast.custom((t) => <Toast variant="error" Title={badgeCriteriaFailed} toastInstance={t} />);
    },
  });
  return { data, isLoading };
};

export default useGetBadgeCriteriasQuery;
