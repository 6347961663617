import { Books, CalendarCheck, GraduationCap } from 'phosphor-react';
import React, { useContext, useMemo, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import { formatNumber } from '../../../../utils';
import durationContext from '../../context/durationContext';
import { useCoursePerformanceStatisticsQuery } from '../../hooks';
import { useStrapiReportsData } from '../../hooks/useStrapiReportData';
import { DashboardReportsFilter, ReportTooltipDataType } from '../../types';
import ErrorFallback from '../ErrorFallback';
import ScaleLoader from '../../../../components/Loader/ScaleLoader';
import ReportTooltip from '../../../../components/ReportToolTip';

interface Props {
  className: string;
  lazyLoad?: boolean;
  teamId?: string;
  reportTooltipContent?: ReportTooltipDataType;
  userPersona: string;
}
const CourseAssessmentStatisticCards = ({
  className,
  lazyLoad = true,
  teamId,
  reportTooltipContent,
  userPersona,
}: Props) => {
  const { id } = useParams();

  const { duration } = useContext(durationContext);

  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);

  // Build the filter
  let filters: Partial<DashboardReportsFilter> = useMemo(() => {
    return {
      fromDate: duration.fromDate,
      teamId: teamId && teamId !== '1' ? teamId : undefined,
    };
  }, [duration, teamId]);

  // include username in filter if exists in route path
  if (id) {
    filters = { ...filters, username: id };
  }

  const courseAssessmentStatistics = useCoursePerformanceStatisticsQuery({
    filters,
    isEnabled: isInView,
  });

  const hasCourseAssessmentStatistics =
    courseAssessmentStatistics.data &&
    courseAssessmentStatistics.data.result &&
    Object.keys(courseAssessmentStatistics.data.result).length > 0;

  const isLoadingCourseAssessmentStatistics = courseAssessmentStatistics.isLoading;

  const courseAssessmentStatisticsData = courseAssessmentStatistics?.data?.result;
  const {
    courseCompletedCardLabel,
    assessmentsCompletedCardLabel,
    averageAssessmentScoreCardLabel,
  } = useStrapiReportsData().statistics;

  const statisticCards = [
    {
      cardLabel: courseCompletedCardLabel,
      value: courseAssessmentStatisticsData?.coursesCompleted ?? 0,
      icon: <Books size={30} color="#F3F4F6" />,
    },
    {
      cardLabel: assessmentsCompletedCardLabel,
      value: courseAssessmentStatisticsData?.assessmentsCompleted ?? 0,
      icon: <GraduationCap size={30} color="#F3F4F6" />,
    },
    {
      cardLabel: averageAssessmentScoreCardLabel,
      value: `${formatNumber(courseAssessmentStatisticsData?.averageAssessmentScore)}%`,
      icon: <CalendarCheck size={30} color="#F3F4F6" />,
    },
  ];

  return (
    <InView
      as="div"
      className={className}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <div className="grid h-full auto-cols-fr items-center gap-4 gap-x-3 gap-y-2 sm:gap-y-3 md:grid-cols-3 md:grid-rows-1 lg:grid-flow-col lg:grid-cols-3 lg:grid-rows-1 lg:gap-x-[13px] lg:gap-y-4 xl:gap-y-5">
        {statisticCards.map((statisticCard) => (
          <div
            key={statisticCard.cardLabel}
            className="flex h-full min-h-[6rem] w-full items-center gap-4 rounded-lg border border-zinc-700 bg-card-bg p-4 xs:flex-nowrap"
          >
            <div className="flex h-12 w-12 items-center justify-center rounded-md bg-base-brand">
              <span className="m-auto">{statisticCard.icon}</span>
            </div>

            <div className="flex flex-col gap-1">
              <div className="flex w-full items-center gap-2">
                <p className="text-sm font-medium text-zinc-300">{statisticCard.cardLabel} </p>
                {reportTooltipContent?.[statisticCard.cardLabel]?.[userPersona] && (
                  <ReportTooltip
                    content={reportTooltipContent[statisticCard.cardLabel][userPersona]}
                  />
                )}
              </div>
              {isLoadingCourseAssessmentStatistics && !hasCourseAssessmentStatistics && (
                <div className="flex grow pl-1">
                  <ScaleLoader height={24} />
                </div>
              )}
              {/* Error State */}
              <>
                {courseAssessmentStatistics.error && !courseAssessmentStatistics.data && (
                  <ErrorFallback
                    message="Error fetching course statistics."
                    refetch={courseAssessmentStatistics.refetch}
                    allowsRefetch={true}
                    isRefetching={courseAssessmentStatistics.isRefetching}
                  />
                )}
              </>
              {hasCourseAssessmentStatistics && (
                <p className="text-xl font-semibold text-white">{statisticCard.value}</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </InView>
  );
};

export default CourseAssessmentStatisticCards;
