import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

interface CustomProps {
  className?: string;
}

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  CustomProps;

const CodeSingleInput = React.forwardRef(
  ({ className, ...rest }: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    return (
      <input
        maxLength={1}
        ref={ref}
        type="tel"
        className={twMerge(
          clsx(
            'box-border aspect-square w-full rounded-sm border border-zinc-700 bg-zinc-800 p-3 text-center text-base',
            className
          )
        )}
        {...rest}
      />
    );
  }
);

CodeSingleInput.displayName = 'CodeSingleInput';

export default CodeSingleInput;
