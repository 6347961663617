import { gql } from '@apollo/client';

export const RegistrationFragment = gql`
  fragment RegistrationFragment on RegistrationEntityResponse {
    data {
      attributes {
        pricingTable {
          Pricecard {
            name
            value
            AvatarForpricing {
              data {
                attributes {
                  url
                }
              }
            }
            pricing {
              monthly {
                price
                label
                value
                tenure
              }
              annually {
                price
                label
                value
                tenure
              }
            }
            features {
              Listitem
            }
            buttonWrapperClassName
            buttons {
              label
              value
              className
            }
            role
            description
            className
            badgelabel
            pricingAltText
          }
        }
        monthy {
          label
          value
          tenure
        }
        annually {
          label
          value
          tenure
        }
        pageHeader
        pageDescription
        signupLabel
        subscriptionLabel
        planLabel
        backLabel
        backToLogin
        contactUsEmailSubject
        registeringYourAccountLabel
        loadingEnterpriseAccountMessage
        SubFormsHeader {
          changeCurrPlanLabel
          freeTrialLabel
          changeCurrPlanDialogTitle
        }
        RegistrationFailed {
          title
          description
          paymentBadgeTitle
        }
        RegistrationSuccess {
          title
          description
          paymentBadgeTitle
        }
        AccountProvisionStatus {
          provisionInProgressDescription
          provisionInProgressTitle
          provisionFailedTitle
          provisionFailedDescription
        }
        Seo {
          pageTitle
          pageDescription
          ogTitle
          ogDescription
          ogSiteName
          canonicalUrl
          twitterTitle
          twitterDescription
          twitterUsername
          socialMediaImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          ogType
          twitterCardType
          preventIndexing
        }
      }
    }
  }
`;
