export const CHIME_AWS_REGION = 'us-east-1';

export const LOCAL_STORAGE_CHIME_APP_INSTANCE_ARN_KEY = 'ushg-hq-app-instance-arn';

export const LOCAL_STORAGE_USER_TENANT_ID = 'ushg-hq-user-tenant-id';

export const CHIME_SERVICE_WORKER_NAME = 'enterprise-chat-admin-service-worker';

export const CHIME_MESSAGE_PERSISTENCE = {
  PERSISTENT: 'PERSISTENT',
  NON_PERSISTENT: 'NON_PERSISTENT',
} as const;

export const CHIME_MESSAGE_TYPE = {
  STANDARD: 'STANDARD',
  CONTROL: 'CONTROL',
} as const;

export const CHIME_CHANNEL_MEMBERSHIP_TYPE = {
  HIDDEN: 'HIDDEN',
  DEFAULT: 'DEFAULT',
};

export const CHIME_CHANNEL_PRIVACY = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};

export const MESSAGE_DIRECTION = {
  OUTGOING: 'OUTGOING',
  INCOMING: 'INCOMING',
} as const;

export const CHIME_MESSAGING_EVENT_TYPE = {
  CHANNEL_DETAILS: 'CHANNEL_DETAILS',
  CREATE_CHANNEL_MESSAGE: 'CREATE_CHANNEL_MESSAGE',
  REDACT_CHANNEL_MESSAGE: 'REDACT_CHANNEL_MESSAGE',
  UPDATE_CHANNEL_MESSAGE: 'UPDATE_CHANNEL_MESSAGE',
  DELETE_CHANNEL_MESSAGE: 'DELETE_CHANNEL_MESSAGE',
  PENDING_CREATE_CHANNEL_MESSAGE: 'PENDING_CREATE_CHANNEL_MESSAGE',
  PENDING_UPDATE_CHANNEL_MESSAGE: 'PENDING_UPDATE_CHANNEL_MESSAGE',
  FAILED_CREATE_CHANNEL_MESSAGE: 'FAILED_CREATE_CHANNEL_MESSAGE',
  FAILED_UPDATE_CHANNEL_MESSAGE: 'FAILED_UPDATE_CHANNEL_MESSAGE',
  DENIED_CREATE_CHANNEL_MESSAGE: 'DENIED_CREATE_CHANNEL_MESSAGE',
  DENIED_UPDATE_CHANNEL_MESSAGE: 'DENIED_UPDATE_CHANNEL_MESSAGE',
} as const;

export const CHIME_MESSAGE_EVENT_TYPE_HEADER = 'x-amz-chime-event-type';

export const CHIME_MESSAGE_GROUPING_TIME_DIFFERENCE_IN_MINS = 1;

export const SHARE_MESSAGE_FORMAT = {
  // common prefix
  PREFIX: 'HQ|Messaging|Share',
  COURSE: {
    PREFIX: 'Course',
    generate: (courseId: number) => {
      const elements = [SHARE_MESSAGE_FORMAT.PREFIX, SHARE_MESSAGE_FORMAT.COURSE.PREFIX, courseId];
      return elements.join('|');
    },
    test: (val: string) => {
      const isCourseShare = val.startsWith(
        [SHARE_MESSAGE_FORMAT.PREFIX, SHARE_MESSAGE_FORMAT.COURSE.PREFIX].join('|')
      );
      return isCourseShare;
    },
    parse: (val: string) => {
      const splits = val.split('|');
      const courseId = splits[splits.length - 1];
      return courseId;
    },
  },
};
