import { useQuery } from '@tanstack/react-query';
import { USHG_HQ_APP_ROLE } from '../../../constants';
import { getUSHGAdminRequests } from '../api';
import { REQUESTS_STALE_TIME } from '../constants';
import { PartialUSHGAdminRequestsFilter } from '../types';

interface Props {
  filters: PartialUSHGAdminRequestsFilter;
  isEnabled?: boolean;
}

const useUSHGAdminRequestsQuery = ({ filters, isEnabled = true }: Props) => {
  const request = useQuery(
    [
      {
        persona: USHG_HQ_APP_ROLE.USHG_ADMIN,
        scope: 'requests',
        item: 'requests',
        filters: filters,
      },
    ],
    async () => {
      return getUSHGAdminRequests(filters);
    },
    {
      keepPreviousData: true,
      staleTime: REQUESTS_STALE_TIME,
      enabled: isEnabled,
    }
  );

  return request;
};

export default useUSHGAdminRequestsQuery;
