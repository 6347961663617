import { useQuery } from '@tanstack/react-query';
import { getAllGoals } from '../api';
import { ENTERPRISE_ONBOARDING_TYPE, QUERY_STATE_TIME } from '../constants';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../constants/user';
interface Props {
  type: keyof typeof ENTERPRISE_ONBOARDING_TYPE;
  language?: string;
}

const useGoalsQuery = ({ type, language = DEFAULT_PREFERRED_LANGUAGE }: Props) => {
  const result = useQuery(
    [
      {
        scope: 'goals',
        item: 'get-goals',
        type,
        language,
      },
    ],

    async () => {
      return getAllGoals({ type, language });
    },
    {
      staleTime: QUERY_STATE_TIME,
    }
  );

  return result;
};

export default useGoalsQuery;
