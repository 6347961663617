import { Filter, filterType } from '../../../../types/Filter.Helper';
import FilteredByWidgets from '../FilteredByWidgets/FilteredByWidgets';
import FilterWidgets from '../FilterWidgets/FilterWidgets';
import { twMerge } from 'tailwind-merge';
import { useState } from 'react';
import { useStrapiTeamData } from '../../../../../teams/hooks/useStrapiTeamData';

type CourseHeaderProps = {
  appliedFilters: filterType[];
  setAppliedFilters: any;
  className?: string;
  showFilteredByWidgets?: boolean;
};

const AssignedCoursesHeader = ({
  appliedFilters,
  setAppliedFilters,
  className,
  showFilteredByWidgets,
}: CourseHeaderProps) => {
  const filterApplyHandler = (filter: filterType) => {
    const isExist = appliedFilters.find((f) => f.value === filter.value && filter.type === f.type);
    if (!isExist && filter.value !== Filter.NONE) {
      if (
        !(
          filter.type === Filter.DURATION ||
          filter.type === Filter.SORT ||
          filter.type === Filter.SEARCH
        )
      ) {
        setAppliedFilters([...appliedFilters, filter]);
      } else {
        let isAdded = false;
        const filters = appliedFilters.map((f) => {
          if (f.type === filter.type || f.type === filter.type) {
            isAdded = true;
            return filter;
          }
          return f;
        });
        if (!isAdded) filters.push(filter);
        setAppliedFilters(filters);
      }
    }
  };
  const { singlePerson } = useStrapiTeamData();
  const { courseAssignedTitle } = singlePerson;
  const [search, setSearch] = useState<string>('');

  const filterRemoveHandler = (filter: filterType) => {
    setAppliedFilters(
      appliedFilters.filter((f) => !(f.value === filter.value && filter.type === f.type))
    );
  };

  const clearSearch = () => {
    setAppliedFilters(appliedFilters.filter((f) => !(f.type === Filter.SEARCH)));
  };

  const clearFiltersHandler = () => {
    setSearch('');
    setAppliedFilters([]);
  };

  return (
    <div className={twMerge(`flex flex-col gap-3.5 bg-card-bg px-1 py-1 ${className}`)}>
      <p className="font-sans text-base font-bold text-white">{courseAssignedTitle}</p>
      <FilterWidgets
        searchClear={clearSearch}
        filterApplyHandler={filterApplyHandler}
        search={search}
        setSearch={setSearch}
      />
      {showFilteredByWidgets && (
        <FilteredByWidgets
          filters={appliedFilters}
          filterRemoveHandler={filterRemoveHandler}
          clearFiltersHandler={clearFiltersHandler}
        />
      )}
    </div>
  );
};

export default AssignedCoursesHeader;
