import Calendar from '../Calendar';
import clsx from 'clsx';
import { TeamLeaderboardTable } from '../../../shared/components/Reports/Tables';
import { RecommendedCourses, UserGreeting } from '../common';
import { CoursePerformanceViz, HoursLoggedInViz } from '../../../shared/components/charts';
import { AssignedCourses } from '../Courses';
import { CourseCardWrapper } from '../Courses/CourseCardWrapper';
import { useStrapiReportsData } from '../../../shared/hooks/useStrapiReportData';
import MyBadgesDashBoardComponent from '../common/MyBadgesDashBoardComponent';
import { USHG_HQ_APP_ROLE } from '../../../../constants';

interface Props {
  className?: string;
  lazyLoad?: boolean;
}
const EnterpriseLearnerDashboard = ({ className, lazyLoad = true }: Props) => {
  const { MyViewingActivity, MyCoursePerformance, ReportTooltipContent } = useStrapiReportsData();
  return (
    <main
      id="main-content"
      className={clsx(
        'relative mb-4 grid grow auto-rows-min grid-cols-8 gap-x-3 gap-y-2 sm:gap-y-3 lg:gap-x-[13px] lg:gap-y-4 xl:gap-y-5',
        className
      )}
    >
      <UserGreeting className="col-span-8 col-start-1 row-start-1 lg:col-span-6" />
      <MyBadgesDashBoardComponent
        className="col-span-8 col-start-1 row-start-2 lg:col-span-6 lg:row-span-2"
        lazyLoad={lazyLoad}
      />
      <CourseCardWrapper
        className="col-span-8 col-start-1 row-start-3 overflow-hidden md:row-start-3 lg:col-span-6 lg:row-span-2 lg:row-start-4"
        lazyLoad={lazyLoad}
      />
      <Calendar className="col-span-8 col-start-1 row-start-4 md:col-span-4 md:row-start-4 lg:col-span-2 lg:col-start-7 lg:row-span-4 lg:row-start-1" />
      <HoursLoggedInViz
        lazyLoad={lazyLoad}
        key1={MyViewingActivity.key1}
        key2={MyViewingActivity.key2}
        xAxisLabel={MyViewingActivity.xAxisLabel}
        yAxisLabel={MyViewingActivity.yAxisLabel}
        emptyMessage={MyViewingActivity.emptyChart}
        errorMessage={MyViewingActivity.errorChart}
        emptyChartIconUrl={MyViewingActivity.emptyChartIcon.data.attributes.url}
        heading={MyViewingActivity.title}
        className="col-span-8 col-start-1 row-start-6 overflow-hidden md:row-start-5 lg:col-span-6 lg:row-start-6"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER}
      />
      <TeamLeaderboardTable
        lazyLoad={lazyLoad}
        className="col-span-8 col-start-1 row-start-5 md:col-span-4 md:row-start-4 lg:col-span-2 lg:col-start-7 lg:row-span-2 lg:row-start-5"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER}
      />
      <CoursePerformanceViz
        lazyLoad={lazyLoad}
        key1={MyCoursePerformance.key1}
        key2={MyCoursePerformance.key2}
        key3={MyCoursePerformance.key3}
        emptyMessage={MyCoursePerformance.emptyChart}
        errorMessage={MyCoursePerformance.errorMessage}
        emptyChartIconUrl={MyCoursePerformance.emptyChartIcon.data.attributes.url}
        heading={MyCoursePerformance.title}
        className="col-span-8 col-start-1 row-start-7 overflow-hidden md:row-start-6 lg:row-span-4 lg:row-start-[7]"
        reportTooltipContent={ReportTooltipContent}
        userPersona={USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER}
      />
      <AssignedCourses
        lazyLoad={lazyLoad}
        className="col-span-8 row-start-[8] overflow-hidden lg:row-start-[11]"
      />
      <RecommendedCourses
        lazyLoad={lazyLoad}
        className="col-span-8 row-start-[9] overflow-hidden lg:row-start-[12]"
      />
    </main>
  );
};

export default EnterpriseLearnerDashboard;
