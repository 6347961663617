import { useMemo } from 'react';
import { Avatar } from 'react-daisyui';
import { generateCDNMediaAssetURLWithKey } from '../../../../helpers';

interface NameCellProps {
  badgeName: string;
  pictureUrl: string;
}

const BadgeNameCell = ({ badgeName, pictureUrl }: NameCellProps) => {
  const badgePictureURL = useMemo(() => {
    if (!pictureUrl) {
      return undefined;
    }

    const cdnProfilePictureUrl = generateCDNMediaAssetURLWithKey({ key: pictureUrl });
    return cdnProfilePictureUrl;
  }, [pictureUrl]);

  return (
    <div className="flex items-center gap-2 whitespace-nowrap">
      {badgePictureURL && (
        <Avatar
          src={badgePictureURL}
          size={24}
          className="rounded-full border border-zinc-900"
          shape="circle"
          role="img"
          aria-label={badgeName}
        ></Avatar>
      )}
      <span>{badgeName}</span>
    </div>
  );
};

export default BadgeNameCell;
