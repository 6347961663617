import { useNavigate, useParams } from 'react-router-dom';
import { AllMembersTableMain } from '../../members/components/common';
import BillingHistoryMain from '../../shared/components/BillingHistory/BillingHistoryTableMain';
import TeamsTableMain from '../../teams/components/TeamTable/TeamsTableMain';
import { TENANT_TYPE } from '../constants/Billing';
import useGetOrganisationNameQuery from '../hooks/useGetOrganisationNameQuery';
import SingleOrganisationStatistics from './SingleOrganisationStatistics';
import TopNav from './TopNav';
import { SideBarLayoutPageContentWrapper } from '../../../components/Wrapper';
import {
  useAllMembersTableFilters,
  useGetAllMembersInOrganizationQuery,
} from '../../members/hooks';
import OrganisationEmptyState from './OrganisationEmptyState';
import PageHeader from '../../members/components/EnterpriseAdminAllMembers/components/PageHeader';
import { USHG_HQ_APP_ROLE } from '../../../constants/ushg-hq-app-roles';
import useSessionTracker from '../../../hooks/use-session-tracker-hook';

const SingleOrganisationView = () => {
  useSessionTracker();
  const { id: tenantId } = useParams();
  const organisation = useGetOrganisationNameQuery({ tenantId: tenantId as string });
  const { requestFilters } = useAllMembersTableFilters();
  const getAllMembersQuery = useGetAllMembersInOrganizationQuery({
    tenantId: tenantId as string,
    filters: requestFilters,
  });
  const navigate = useNavigate();

  const errorResponseData = (getAllMembersQuery.error as any)?.response?.data;
  const notExistError =
    getAllMembersQuery.isError &&
    !getAllMembersQuery.data &&
    errorResponseData?.errorCode == 'ORGANIZATION_NOT_FOUND';

  if (notExistError) {
    return (
      <div className="flex w-full">
        <OrganisationEmptyState
          message={
            <div className="flex flex-col items-center gap-y-8">
              <span>{"We couldn't find any results."}</span>
              <button
                className="w-min whitespace-nowrap rounded-lg border border-zinc-800 bg-zinc-900 py-2 px-4"
                onClick={() => navigate('/manage/organisations')}
              >
                {'Back to organisations'}
              </button>
            </div>
          }
        />
      </div>
    );
  }
  return (
    <div className="flex w-full flex-col items-center gap-5">
      <TopNav
        title={organisation.data?.data.name as string}
        department={organisation.data?.data.department as string}
        isMain={false}
      />
      <PageHeader
        className="w-full py-6 px-3 xs:px-4 sm:px-5 md:py-5 md:px-6 lg:py-6 lg:px-7 xl:py-7 xl:px-8 2xl:px-9"
        tenantId={tenantId as string}
        role={USHG_HQ_APP_ROLE.USHG_ADMIN}
      />

      <SideBarLayoutPageContentWrapper className="flex-col gap-5">
        <SingleOrganisationStatistics />
        <TeamsTableMain isOrganizationView={true} className="w-full" />
        <AllMembersTableMain
          tenantId={tenantId as string}
          isOrganization={true}
          className="flex w-full flex-col items-start gap-4 rounded-sm bg-card-bg p-4 shadow-[0px_16px_24px_rgba(0,0,0,0.15)] md:p-5 lg:p-6"
        />
        <BillingHistoryMain
          isOrganizationView={true}
          tenantId={tenantId}
          className="w-full"
          tenantType={TENANT_TYPE.ENTERPRISE}
        />
      </SideBarLayoutPageContentWrapper>
    </div>
  );
};
export { SingleOrganisationView };
