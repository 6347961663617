import { useParams } from 'react-router-dom';
import useLearningJourneyDetail from '../../hooks/use-learning-journey-detail';
import LearningJourneyDetailHeading from './LearningJourneyDetailHeading';
import LearningJourneyDetailMain from './LearningJourneyDetailMain/LearningJourneyDetailMain';
import EmptyState from '../EmptyState';
import useSessionTracker from '../../../../hooks/use-session-tracker-hook';

const LearningJourneyDetailPage = () => {
  useSessionTracker();
  const { id } = useParams() as { id: string };
  // strapi
  const LearningJourneyDetailquery = useLearningJourneyDetail({
    id: id,
  });

  const errorResponseData = (LearningJourneyDetailquery.error as any)?.response?.data;
  const notExistError =
    LearningJourneyDetailquery.isError &&
    !LearningJourneyDetailquery.data &&
    errorResponseData?.errorCode == 'LEARNING_JOURNEY_NOT_FOUND';
  if (notExistError) {
    return (
      <EmptyState
        action={{
          message: 'Back to All Learning Journeys',
          path: '/learning-journeys',
        }}
        message={'We could not find any results'}
      />
    );
  }
  return (
    <div className="flex w-full flex-col">
      <LearningJourneyDetailHeading query={LearningJourneyDetailquery} />
      <LearningJourneyDetailMain />
    </div>
  );
};

export default LearningJourneyDetailPage;
