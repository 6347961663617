import { gql } from '@apollo/client';

export const TopNavFragment = gql`
  fragment TopNavFragment on NavEntityResponse {
    data {
      attributes {
        logInLabel
        signUpLabel
        logoutLabel
        hqHomeLinkSRLabel
      }
    }
  }
`;
