import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Navbar } from '../../../components/Navbar';
import { Page } from '../../../components/Pages/Page';
import { useReduxAuthState } from '../../../hooks';
import usePendingAccountProvision from '../../../hooks/use-pending-account-provision';
import HelpWidget from '../../shared/components/HelpWidget/HelpWidget';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
import { SEOProps } from '../../../types';
import SEOHelmetWrapper from '../../../components/SEOHelmet/SEOHelmet';

// Base Layout to be used for registration related pages
const RegistrationBaseLayout = () => {
  const location = useLocation();

  const pendingAccountProvision = usePendingAccountProvision();
  const auth = useReduxAuthState();
  // strapi
  const data = useStrapiDataHelper();
  const registrationPageSEO = data.registration.data.attributes.Seo as SEOProps;
  // strapi

  // If the user is authenticated
  // and if the user should register
  if (auth.isAuthenticated) {
    if (!pendingAccountProvision) {
      return <Navigate to="/dashboard" replace={true} />;
    }

    // If the user has to register
    // Then he/she can go only to the success page
    // and the subscription pages
    if (pendingAccountProvision) {
      const allowedPaths = [
        '/auth/register/subscription',
        '/auth/register/success',
        '/auth/register/progress',
      ];

      if (!allowedPaths.includes(location.pathname)) {
        return <Navigate to="/auth/register/subscription" replace={true} />;
      }
    }
  }

  return (
    <Page showIllustrator={true} childrenWrapperClassName="grow">
      {/* SEOHelmetWrapper Component: Dynamically sets the document's head elements such as title, meta description, and other meta tags for Search Engine Optimization (SEO) purposes. */}
      {registrationPageSEO && <SEOHelmetWrapper seo={registrationPageSEO} />}
      <Navbar></Navbar>
      <div className="grid w-full grow grid-cols-[1fr,auto] grid-rows-1 overflow-scroll">
        <Outlet />
      </div>
      <HelpWidget triggerClassName="fixed left-5 bottom-7 z-50"></HelpWidget>
    </Page>
  );
};

export default RegistrationBaseLayout;
