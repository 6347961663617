const API_BASE_URL = process.env.REACT_APP_USHG_API_BASE_URL;

// Apmify Endpoint Name
const AMPLIFY_USHG_API_ENDPOINT_NAME = 'USHG_API_BASE_URL';

const AUTH_API_BASE_URL = `${API_BASE_URL}/auth/`;
const COURSES_API_BASE_URL = `${API_BASE_URL}/courses`;
const GAMIFICATION_API_BASE_URL = `${API_BASE_URL}/gamification`;
const USERS_API_BASE_URL = `${API_BASE_URL}/users`;

const REGISTER_API_PATH = '/auth/register';

const CHECKOUT_SESSION_API_PATH = '/payments/v1/checkout-session';
const S3_API_PATHS = {
  PRESIGNED_URL: {
    GET_PRESIGNED_URL: '/users/pre-signed-url/upload',
    DOWNLOAD_PRESIGNED_URL: '/users/pre-signed-url/download',
  },
  PRESIGNED_COURSE_URL: {
    GET_PRESIGNED_URL: `/courses/pre-signed-url`,
    FINALIZE_MULTIPART_UPLOAD: '/courses/multi-pre-signed/finalize',
  },
};

export {
  API_BASE_URL,
  COURSES_API_BASE_URL,
  AUTH_API_BASE_URL,
  USERS_API_BASE_URL,
  GAMIFICATION_API_BASE_URL,
  REGISTER_API_PATH,
  CHECKOUT_SESSION_API_PATH,
  AMPLIFY_USHG_API_ENDPOINT_NAME,
  S3_API_PATHS,
};
