import { useQuery } from '@tanstack/react-query';
import { ACTIVITY_REPORT_TYPES, REPORTS_STALE_TIME } from '../constants';
import { getHoursLogged } from '../api';
import { DashboardReportsFilter } from '../types';

interface Props {
  filters: DashboardReportsFilter;
  isEnabled: boolean;
}
const useHoursLoggedQuery = ({ filters, isEnabled }: Props) => {
  const query = useQuery(
    [
      {
        filters: filters,
        scope: 'dashboard',
        item: ACTIVITY_REPORT_TYPES.HOURS_LOGGED,
      },
    ],
    async ({ queryKey }) => {
      const { filters } = queryKey[0];
      return await getHoursLogged(filters);
    },
    {
      staleTime: REPORTS_STALE_TIME,
      enabled: isEnabled,
    }
  );
  return query;
};

export default useHoursLoggedQuery;
