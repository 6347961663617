import { useMutation } from '@tanstack/react-query';
import { updateUserRoleOrTeam } from '../api';

const useUpdateRoleOrTeamMutation = () => {
  const { data, error, isLoading, isError, mutate, isSuccess } = useMutation({
    mutationFn: updateUserRoleOrTeam,
  });
  return { data, error, isLoading, isError, mutate, isSuccess };
};

export default useUpdateRoleOrTeamMutation;
