import { flexRender, Header, SortDirection } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DynamicSortIcon } from '../../../../../components/DataTables';
import { ListSpeakersResults } from '../../../types/GetSpeakerApiResponse';
interface Props {
  header: Header<ListSpeakersResults, unknown>;
  isSortable?: boolean;
}

const ListSpeakersTableHeaderRow = ({ header }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const searchKey = searchParams.get('key');
  const searchOrder = searchParams.get('order');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [isDisplaySortIcon, setDisplaySortIcon] = useState(false);
  const toggleColumnSorting = () => {
    if (header.id === 'options' || header.id === 'bio') {
      return;
    }
    if (sortDirection === 'ASC' || sortDirection === 'asc') {
      setSortDirection('desc');
    } else {
      setSortDirection('asc');
    }
    // isSortable && header.column.toggleSorting(undefined, true)
    searchParams.set('order', sortDirection.toLowerCase());
    searchParams.set('key', header.id);
    navigate('?' + searchParams.toString());
  };

  useEffect(() => {
    if (searchKey == header.id) {
      setDisplaySortIcon(true);
    }
    if (searchKey != header.id) {
      setDisplaySortIcon(false);
    }
  }, [searchKey, header]);
  return (
    <th
      className="cursor-pointer bg-zinc-900 px-2 py-3 text-start text-[12px] font-semibold leading-[16px] text-zinc-200"
      key={header.id}
      colSpan={header.colSpan}
      onClick={toggleColumnSorting}
    >
      <div className="flex w-full items-center gap-2">
        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
        {isDisplaySortIcon && !(header.id === 'edit' || header.id === 'bio') && (
          <DynamicSortIcon
            fallback={<div className="h-4 w-4 bg-transparent"></div>}
            sortDirection={searchOrder as SortDirection}
          />
        )}
      </div>
    </th>
  );
};

export default ListSpeakersTableHeaderRow;
