import { useQuery } from '@tanstack/react-query';
import { useReduxAuthState } from '../../../hooks';
import { getTenantMFAConfiguration } from '../api';
import { SETTING_API_PATHS } from '../constants';
import { QUERY_STALE_TIME } from '../constants/query';

const useGetTenantMFAConfigQuery = () => {
  const auth = useReduxAuthState();

  const query = useQuery({
    queryKey: [
      {
        scope: 'settings',
        item: 'tenant-mfa-configuration',
        // we should have the user here since at this point the user is authenticated
        tenantId: auth.user?.['custom:tenant_id'],
      },
    ],
    queryFn: async () => {
      const response = getTenantMFAConfiguration();
      return response;
    },
    staleTime: QUERY_STALE_TIME[SETTING_API_PATHS.GET_TENANT_MFA_CONFIGURATION],
    refetchOnMount: false,
  });

  return query;
};

export default useGetTenantMFAConfigQuery;
