import { useQuery } from '@tanstack/react-query';
import { getPotentialBadges } from '../api/courses';
type usePotentialBadgesQueryProps = {
  id: number;
};
const usePotentialBadgesQuery = ({ id }: usePotentialBadgesQueryProps) => {
  const { isLoading, isError, data, error, refetch, isRefetching } = useQuery({
    queryKey: ['potential-badges', id],
    queryFn: () => getPotentialBadges(id),
  });
  return { isLoading, isError, data, error, refetch, isRefetching };
};

export default usePotentialBadgesQuery;
