import { useMutation } from '@tanstack/react-query';
import { SendMessageToChannelArgs, sendMessageToChannel } from '../helpers';

const useSendChatMessageMutation = () => {
  const mutation = useMutation({
    mutationFn: async (args: SendMessageToChannelArgs) => {
      const response = await sendMessageToChannel(args);
      return response;
    },
  });

  return mutation;
};

export default useSendChatMessageMutation;
