import { flexRender, Row } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { PartialMembers } from '../../../types/interface';

interface Props {
  row: Row<PartialMembers>;
  className?: string;
}

const AdminViewTeamMembersTableDataRow = ({ row, className }: Props) => {
  // Dialog that shows more details and options to enable and reject
  const navigate = useNavigate();
  const handleClick = (id: any | undefined) => {
    navigate(`/team/member/${id}`);
  };
  return (
    <>
      <tr className={className} onClick={() => handleClick(row.original.username)}>
        {row.getVisibleCells().map((cell) => {
          return (
            <td
              key={cell.id}
              className="px-2 py-1 text-[12px] font-normal leading-[16px] text-zinc-100"
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default AdminViewTeamMembersTableDataRow;
