import React, { useEffect, useRef, useState } from 'react';

import { useStrapiDashboardData } from '../../../shared/hooks';
import { FaVolumeMute, FaVolumeUp, FaPlay, FaPause } from 'react-icons/fa';
import { MediaController, MediaLoadingIndicator } from 'media-chrome/dist/react';
import { LOCAL_STORAGE_DASHBOARD_VIDEO_PLAYED } from '../../../auth/constants';

export default function SizzleVideoCompotent() {
  const {
    dashboardSizzleVideo,
    dashboardSizzlePoster,
    hideVideoBottom,
    playVideoSRLabel,
    pauseVideoSRLabel,
    muteVideoSRLabel,
    unmuteVideoSRLabel,
  } = useStrapiDashboardData().sizzleVideo;
  const [playing, setPlaying] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [bottomOffset, setBottomOffset] = useState('35'); // Default bottom offset
  const [buttonSize, setButtonSize] = useState(8);

  const sizzleVideoUrl = dashboardSizzleVideo?.data?.attributes?.url;
  const siszzlePosterUrl = dashboardSizzlePoster?.data?.attributes?.url;

  const [muted, setMuted] = useState(true);

  useEffect(() => {
    const isMuted = localStorage.getItem(LOCAL_STORAGE_DASHBOARD_VIDEO_PLAYED);
    if (isMuted == null) {
      setMuted(false);
      if (videoRef.current) {
        videoRef.current.currentTime = 0; // Restart the video
        videoRef.current.play(); // Play the video
      }
      setPlaying(true);
      localStorage.setItem(LOCAL_STORAGE_DASHBOARD_VIDEO_PLAYED, 'true');
    }
  }, []);

  useEffect(() => {
    // Check if the video is not playing even when it should be
    const checkVideoPlaying = () => {
      if (videoRef.current && playing && videoRef.current.paused) {
        // If video is not playing but should be, mute and play again
        videoRef.current.muted = true;
        setMuted(true);
        videoRef.current.play();
      }
    };

    // Listen for events to check video playing status
    const video = videoRef.current;
    if (video) {
      video.addEventListener('playing', checkVideoPlaying);
      video.addEventListener('pause', checkVideoPlaying);
    }

    return () => {
      if (video) {
        video.removeEventListener('playing', checkVideoPlaying);
        video.removeEventListener('pause', checkVideoPlaying);
      }
    };
  }, [playing]);

  useEffect(() => {
    function handleResize() {
      // Adjust button size based on screen width
      if (window.innerWidth < 768) {
        setBottomOffset('25');
        setButtonSize(6); // Small size for mobile
      } else if (window.innerWidth < 1024) {
        setBottomOffset('25');
        setButtonSize(8); // Regular size for larger screens
      } else if (window.innerWidth < 1440) {
        setBottomOffset('35');
        setButtonSize(10); // Regular size for larger screens
      } else {
        setBottomOffset('35');
        setButtonSize(12); // Default size
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMute = () => {
    const newMuted = !muted;
    setMuted(newMuted);
  };

  const togglePlay = () => {
    const newPlaying = !playing;
    setPlaying(newPlaying);
    if (videoRef.current) {
      if (newPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const handleVideoEnded = () => {
    if (!muted) {
      setMuted(true); // Mute video once it ends if it wasn't muted
    }
    setPlaying(true);
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Restart the video
      videoRef.current.play(); // Play the video
    }
  };

  return (
    <div className="relative h-[55.25vw] md:h-[40.25vw] lg:h-[30.25vw]">
      <div className="h-[69.25vw] md:h-[48.25vw] lg:h-[44.25vw]">
        <MediaController
          video
          className={`aspect-video h-full w-full   `}
          role="region"
          aria-label="video player"
        >
          <video
            onClick={togglePlay}
            playsInline
            slot="media"
            preload="auto"
            ref={videoRef}
            poster={siszzlePosterUrl}
            className="h-full w-full items-center object-cover brightness-[100%] transition duration-500"
            muted={muted}
            autoPlay={true}
            controls={false}
            onEnded={handleVideoEnded}
          >
            <source src={sizzleVideoUrl} type="video/mp4" />
          </video>
          <MediaLoadingIndicator
            slot="centered-chrome"
            className=" bottom-10  opacity-50 hover:opacity-100 lg:block "
            isLoading
          ></MediaLoadingIndicator>
          <div className=" relative bottom-0 left-0 h-10 w-full bg-gradient-to-t from-page-bg-dark to-transparent opacity-100"></div>
          {hideVideoBottom && (
            <div className=" relative bottom-0 left-0 h-0 w-full bg-page-bg-dark bg-gradient-to-t opacity-100 md:h-0  lg:h-20 xl:h-40"></div>
          )}
          <div
            style={{ bottom: `${bottomOffset}%` }}
            className={`absolute right-4 z-10 flex opacity-50 hover:opacity-100`}
          >
            <div
              role="button"
              aria-label={playing ? pauseVideoSRLabel : playVideoSRLabel}
              className={`w-${buttonSize} h-${buttonSize} flex cursor-pointer items-center  justify-center rounded-full border-2 border-white opacity-70 transition-opacity duration-300 hover:opacity-100`}
              onClick={togglePlay}
            >
              {playing ? (
                <FaPause size={buttonSize + 3} color="white" />
              ) : (
                <FaPlay size={buttonSize + 3} color="white" />
              )}
            </div>

            <div
              role="button"
              aria-label={muted ? unmuteVideoSRLabel : muteVideoSRLabel}
              className={`w-${buttonSize} h-${buttonSize} ml-4 flex cursor-pointer items-center justify-center rounded-full border-2 border-white opacity-70 transition-opacity duration-300 hover:opacity-100`}
              onClick={toggleMute}
            >
              {muted ? (
                <FaVolumeMute size={buttonSize + 3} color="white" />
              ) : (
                <FaVolumeUp size={buttonSize + 3} color="white" />
              )}
            </div>
          </div>
        </MediaController>
      </div>
    </div>
  );
}
