import { useEffect, useState } from 'react';
import Dialog from '../../../../../components/Dialog';
import { useStrapiSettingsData } from '../../../hooks/useStrapiSettingsData';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import ChangePlanModal from './ChangePlanModal';
import { useAppSelector } from '../../../../../hooks';
import { RootState } from '../../../../../store';
import ManageActiveLicence from './ManageActiveLicenceModal';
import { MONTH } from '../../../constants/settings';
import { useReactivatePlanMutation } from '../../../hooks/useReactivatePlanMutation';
import { BasicSpinner } from '../../../../../components/Spinners';
import { format } from 'date-fns';
import { ArrowRight } from 'phosphor-react';
import { useCancelTrialMutation } from '../../../hooks/useCancelTrialMutation';
import { useRenewPlanMutation } from '../../../hooks/useRenewPlanMutation';
import { getLocale } from '../../../../../utils';
import { useLanguage } from '../../../../shared/context/languageContext';
const subscriptionState = (state: RootState) => state.subscription.subscriptionData;
const authState = (state: RootState) => state.auth;

type props = {
  enableUpdate: boolean;
};

function CurrentPlan({ enableUpdate }: props) {
  const [isAdmin, setIsAdmin] = useState(false);
  const { user } = useAppSelector(authState);
  const [cancelSubscriptionModalOpen, setCancelSubscriptionModalOpen] = useState(false);
  const [changePlanModalOpen, setChangePlanModalOpen] = useState(false);
  const [isManageActiveLicenceModalOpen, setManageActiveLicenceModal] = useState(false);
  const subscription = useAppSelector(subscriptionState);
  const { currentPlan } = useStrapiSettingsData();
  const cancelTrial = useCancelTrialMutation();
  const reactivatePlan = useReactivatePlanMutation();
  const { language } = useLanguage();
  const handleReactivatePlan = () => {
    reactivatePlan.mutate();
  };
  const renewPlan = useRenewPlanMutation();
  const handleRenewPlan = () => {
    renewPlan.mutate();
  };
  const handleCancelTrial = () => {
    if (subscription?.cancelAtPeriodEnd) {
      reactivatePlan.mutate();
    } else {
      cancelTrial.mutate();
    }
  };
  useEffect(() => {
    if (user?.role === 'ENTERPRISE_ADMIN') {
      setIsAdmin(true);
    }
  }, [user]);
  let subcriptionInfo = currentPlan.subscriptionInfoText;
  subcriptionInfo = subcriptionInfo.replace(`{{memberCount}}`, subscription?.totalLicenses || '0');
  subcriptionInfo = subcriptionInfo.replace(`{{amount}}`, subscription?.upcomingAmountDue || '0');
  subcriptionInfo = subcriptionInfo.replace(`{{className}}`, 'text-white');
  subcriptionInfo = subcriptionInfo.replace(
    `{{tenure}}`,
    subscription?.planCycle === MONTH ? currentPlan.monthlyTenure : currentPlan.yearlyTenure
  );
  subcriptionInfo = subcriptionInfo.replace(`{{currency}}`, subscription?.currency);
  let renewSubscriptionText = currentPlan.renewSubscriptionText;
  renewSubscriptionText = renewSubscriptionText.replace(
    `{{date}}`,
    subscription?.subscriptionEndedAt
      ? format(new Date(subscription?.subscriptionEndedAt * 1000), 'MMM dd,yyyy', {
          locale: getLocale(language),
        })
      : '-'
  );
  let reactivateSubscriptionText = currentPlan.reactivateSubscriptionText;
  reactivateSubscriptionText = reactivateSubscriptionText.replace(
    `{{date}}`,
    subscription?.currentPeriodEnd
      ? format(new Date(subscription?.currentPeriodEnd * 1000), 'MMM dd,yyyy', {
          locale: getLocale(language),
        })
      : '-'
  );
  function displayInfoText() {
    if (subscription?.status === 'trialing') {
      return currentPlan.trialInfoText;
    } else if (subscription?.status === 'canceled') {
      return renewSubscriptionText;
    } else if (subscription?.cancelAtPeriodEnd) {
      return reactivateSubscriptionText;
    } else if (!subscription?.cancelAtPeriodEnd) {
      return subcriptionInfo;
    } else {
      return 'Loading...';
    }
  }
  return (
    <div className="mt-5 flex flex-col gap-4 self-stretch rounded-sm bg-card-bg p-6">
      <div className="flex flex-col gap-2 leading-5">
        <h2 className="text-xl font-semibold text-zinc-50">
          {subscription?.status === 'trialing' ? currentPlan.trialTitle : currentPlan.title}
        </h2>
        <p
          className="text-sm  text-zinc-300"
          dangerouslySetInnerHTML={{
            __html: displayInfoText(),
          }}
        ></p>
        {!subscription?.cancelAtPeriodEnd && subscription?.status === 'active' ? (
          <p className="mt-4 text-sm font-bold text-white ">
            {currentPlan.currentPlanLabel} : {subscription?.subscriptionType} (
            {subscription?.planCycle === MONTH
              ? currentPlan.monthlyTenure
              : currentPlan.yearlyTenure}
            )
          </p>
        ) : null}

        {enableUpdate &&
          (subscription?.status === 'trialing' ? (
            <div className="mt-2 flex flex-wrap gap-8 md:flex-nowrap">
              <button
                type="submit"
                disabled={cancelTrial.isLoading || reactivatePlan.isLoading}
                onClick={handleCancelTrial}
                className="btn inline-block  items-center justify-center self-center !rounded bg-base-brand px-6 py-3 text-sm font-medium normal-case leading-5 text-white hover:bg-base-brand disabled:bg-base-brand disabled:text-white "
              >
                <span className="flex gap-2">
                  {currentPlan.trialButtonText}{' '}
                  {cancelTrial.isLoading || reactivatePlan.isLoading ? (
                    <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
                  ) : (
                    <ArrowRight className="!m-0 inline-block self-center leading-[14px] text-zinc-100" />
                  )}
                </span>
              </button>
              {!subscription?.cancelAtPeriodEnd && (
                <Dialog
                  triggerClassName="!rounded bg-zinc-700 px-6 py-2 text-sm font-medium normal-case leading-tight text-white hover:bg-zinc-700"
                  open={cancelSubscriptionModalOpen}
                  onOpenChange={(open) => setCancelSubscriptionModalOpen(open)}
                  Content={
                    <CancelSubscriptionModal setIsModalOpen={setCancelSubscriptionModalOpen} />
                  }
                  onInteractOutside={(e) => {
                    e.preventDefault();
                  }}
                >
                  {currentPlan.cancelButton}
                </Dialog>
              )}
            </div>
          ) : subscription?.status === 'canceled' ? (
            <div className="mt-2 flex justify-start">
              <button
                type="submit"
                disabled={renewPlan.isLoading}
                className="btn inline-block  items-center justify-center self-center !rounded bg-base-brand px-6 py-3 text-sm font-medium normal-case leading-5 text-white hover:bg-base-brand disabled:bg-base-brand disabled:text-white "
                onClick={handleRenewPlan}
              >
                <span className="flex gap-2">
                  {currentPlan.renewButtonText}{' '}
                  {renewPlan.isLoading ? (
                    <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
                  ) : null}
                </span>
              </button>
            </div>
          ) : !subscription?.cancelAtPeriodEnd ? (
            <div className="mt-4 flex w-full justify-between">
              <div className="flex flex-wrap gap-4 md:flex-nowrap">
                {isAdmin && (
                  <Dialog
                    containerClassName="w-"
                    triggerClassName="mt-1.5 btn inline-block min-h-[40px] max-w-[215px] items-center justify-center self-center !rounded bg-base-brand  px-6 py-2 text-sm font-medium normal-case leading-tight text-white hover:bg-base-brand"
                    open={isManageActiveLicenceModalOpen}
                    onOpenChange={(open) => setManageActiveLicenceModal(open)}
                    Content={<ManageActiveLicence setIsModalOpen={setManageActiveLicenceModal} />}
                    onInteractOutside={(e) => {
                      // Donot close the Modal when we click outside the Dialog Content
                      e.preventDefault();
                    }}
                  >
                    {currentPlan.manageActiveLicensesButton}
                  </Dialog>
                )}
                <Dialog
                  triggerClassName="mt-1.5 btn inline-block min-h-[40px] max-w-[137px] items-center justify-center self-center !rounded bg-zinc-700 px-6 py-2 text-sm font-medium normal-case leading-tight text-white hover:bg-zinc-700"
                  containerClassName="w-"
                  open={changePlanModalOpen}
                  onOpenChange={(open) => setChangePlanModalOpen(open)}
                  Content={<ChangePlanModal setIsModalOpen={setChangePlanModalOpen} />}
                  onInteractOutside={() => {
                    setChangePlanModalOpen(false);
                  }}
                >
                  {currentPlan.changePlanButton}
                </Dialog>
              </div>
              <a className="self-center text-base-brand">
                <Dialog
                  open={cancelSubscriptionModalOpen}
                  onOpenChange={(open) => setCancelSubscriptionModalOpen(open)}
                  Content={
                    <CancelSubscriptionModal setIsModalOpen={setCancelSubscriptionModalOpen} />
                  }
                  onInteractOutside={(e) => {
                    // Donot close the Modal when we click outside the Dialog Content
                    e.preventDefault();
                  }}
                >
                  {currentPlan.cancelButton}
                </Dialog>
              </a>
            </div>
          ) : (
            <div className="mt-2 flex justify-start">
              <button
                type="submit"
                disabled={reactivatePlan.isLoading}
                className="btn inline-block  items-center justify-center self-center !rounded bg-base-brand px-6 py-3 text-sm font-medium normal-case leading-5 text-white hover:bg-base-brand disabled:bg-base-brand disabled:text-white "
                onClick={handleReactivatePlan}
              >
                <span className="flex gap-2">
                  {currentPlan.reactivateButtonText}{' '}
                  {reactivatePlan.isLoading ? (
                    <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
                  ) : null}
                </span>
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}
export default CurrentPlan;
