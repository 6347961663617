import React from 'react';
import './Styles/TextInputNumberStyle.css';
import { twMerge } from 'tailwind-merge';

type Props = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  className?: string;
  hasError?: boolean;
};

const TextAreaInput = React.forwardRef(
  (
    { className, hasError = false, ...otherProps }: Props,
    ref: React.ForwardedRef<HTMLTextAreaElement>
  ) => {
    return (
      <textarea
        className={twMerge(
          `focus:bg-zinc-80 w-full grow scroll-pb-2 rounded-[4px] border border-zinc-700 bg-zinc-900 p-2 text-white placeholder:text-sm placeholder:font-normal placeholder:text-zinc-500 focus-within:bg-zinc-800 focus:border-zinc-700 focus:ring-0
          ${className}
          ${
            hasError
              ? 'border-error-border bg-error-bg invalid:border-error-border invalid:text-white focus:border-error-border focus:outline-none focus:invalid:border-error-border'
              : ''
          }'}`
        )}
        ref={ref}
        {...otherProps}
      ></textarea>
    );
  }
);

TextAreaInput.displayName = 'TextAreaInput';

export default TextAreaInput;
export type { Props as TextAreaInputProps };
