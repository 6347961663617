interface Props {
  rating: string;
}

const RatingsDropDownDisplayComponent = ({ rating }: Props) => {
  return (
    <div className="flex items-center gap-1">
      <span className="text-xs font-normal">{rating} </span>
      <span className="text-sm text-[#71717A]"> &#9733;</span>
    </div>
  );
};

export default RatingsDropDownDisplayComponent;
