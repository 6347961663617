interface MemberCountCellProps {
  number: number;
}

const MemberCountCell = (props: MemberCountCellProps) => {
  const { number } = props;
  return <span className="whitespace-nowrap">{number}</span>;
};

export default MemberCountCell;
