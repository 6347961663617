import { ErrorBoundary } from 'react-error-boundary';
import cn from '../../../../../utils/cn';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';
import LearningJourneysList from './LearningJourneysList/LearningJourneysList';

interface Props {
  className?: string;
}
const LearningJourneysMain = (props: Props) => {
  const { className } = props;

  return (
    <main id="main-content" className={cn(className)}>
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <LearningJourneysList />
      </ErrorBoundary>
    </main>
  );
};

export default LearningJourneysMain;
