import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorDisplay, NoDataDisplay, PaginationFooter } from '../../../components/DataTables';
import { BasicSpinner } from '../../../components/Spinners';
import { ERROR_TYPES } from '../../../constants/error-types.constants';
import { useStrapiDataHelper } from '../../../hooks/useStrapiData';
import { BADGES_DEFAULT_PAGE_NUMBER, BADGES_DEFAULT_PAGE_SIZE } from '../constants/badges';
import useGetEarnedBadgesQuery from '../hooks/useGetEarnedBadgesQuery';
import useStrapiBadgesData from '../hooks/useStrapiBadgeData';

import { PartialBadgesFilter } from '../types/badges';
import BadgeComponent from './BadgeComponent';
import BadgesErrorState from './BadgesErrorState';

const EarnedBadges = () => {
  const { id } = useParams() ?? undefined;

  // Current Page
  const [page, setPage] = useState(BADGES_DEFAULT_PAGE_NUMBER);

  // Build the badge filters from the available filters
  const filters: PartialBadgesFilter = useMemo(() => {
    return {
      start: (page - 1) * BADGES_DEFAULT_PAGE_SIZE.EARNED_BADGES,
      size: BADGES_DEFAULT_PAGE_SIZE.EARNED_BADGES,
      username: id,
    };
  }, [page, id]);

  const earnedBadges = useGetEarnedBadgesQuery({ filters });

  const displayLoader =
    (earnedBadges.isLoading && !earnedBadges.data) ||
    (earnedBadges.isFetching && earnedBadges.data?.count === 0);
  const isEmptyState = earnedBadges.data && earnedBadges.data.count == 0;
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  const { myBadges, errorMessages } = useStrapiBadgesData();
  const { earnedBadges: strapiEarnedBadges } = myBadges;

  return (
    <div className="rounded-lg bg-[#252528] p-2">
      <p className="p-2 font-medium text-[#FFFFFF]">{strapiEarnedBadges.title}</p>
      {displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center py-4">
          <BasicSpinner className="text-white" />
        </div>
      )}
      {/* We have data */}
      {earnedBadges.data && earnedBadges.data.count > 0 && (
        <div
          className={clsx(
            'relative flex w-full flex-col gap-4',
            earnedBadges.isPreviousData &&
              earnedBadges.isFetching &&
              'pointer-events-none opacity-70'
          )}
        >
          {earnedBadges.isPreviousData && earnedBadges.isFetching && (
            <div className="flex w-full justify-center">
              <BasicSpinner className="text-center text-white" />
            </div>
          )}

          <div className="relative w-full overflow-y-scroll">
            {earnedBadges.isError ? (
              <BadgesErrorState
                isRefetching={earnedBadges.isRefetching}
                refetch={earnedBadges.refetch}
              />
            ) : (
              <BadgeComponent data={earnedBadges.data.rows} />
            )}
          </div>

          <PaginationFooter
            className="items-center justify-center"
            disablePageSizeSelector={true}
            activePage={page}
            totalItems={earnedBadges.data.count}
            setPage={setPage}
            pageSize={BADGES_DEFAULT_PAGE_SIZE.UP_NEXT_BADGES}
          />
        </div>
      )}

      {/* Empty State */}
      {isEmptyState && !displayLoader && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <NoDataDisplay message={errorMessages.noEarnedbadges} />
        </div>
      )}

      {earnedBadges.isError && !earnedBadges.data && (
        <div className="flex min-h-[250px] w-full items-center justify-center">
          <ErrorDisplay
            refetch={earnedBadges.refetch}
            isRefetching={earnedBadges.isRefetching}
            allowsRefetch={true}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            message={strapiErrorMessage['SYSTEM_ERROR']}
          />
        </div>
      )}
    </div>
  );
};

export default EarnedBadges;
