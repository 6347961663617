import { API } from 'aws-amplify';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { FlattenedAnnouncement } from '../../shared/types/announcement';
import { SETTING_API_PATHS } from '../constants';
import qs from 'qs';

// types

export type GetAnnouncementResponse = FlattenedAnnouncement;

export type GetAnnouncementParams = {
  language: string;
};

export type CreateAnnouncementParams = {
  markdownContent: string;
  language?: string;
};

export type CreateAnnouncementInMultiLangParams = {
  announcementId: number;
  markdownContent: string;
  language: string;
};

export type CreateAnnouncementRequestBody = {
  markdownContent: string;
  language?: string;
};

export type CreateAnnouncementInMultiLangRequestBody = {
  announcementId: number;
  markdownContent: string;
  language: string;
};

export type CreateAnnouncementResponse = FlattenedAnnouncement;

export type UpdateAnnouncementParams = CreateAnnouncementParams;

export type UpdateAnnouncementRequestBody = CreateAnnouncementRequestBody;

export type UpdateAnnouncementResponse = FlattenedAnnouncement;

export type DeleteAnnouncementResponse = {
  success: true;
};

// types

/**
 * Get Announcement
 */

const getAnnouncement = async (params: GetAnnouncementParams): Promise<GetAnnouncementResponse> => {
  const path = SETTING_API_PATHS.HQ_ANNOUNCEMENT;
  const { language } = params;
  const query = qs.stringify({ language });

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${query}`, {
    response: true,
    withCredentials: true,
  });

  return response.data;
};

/**
 * Create Announcement
 */
const createAnnouncement = async (
  params: CreateAnnouncementParams
): Promise<CreateAnnouncementResponse> => {
  const path = SETTING_API_PATHS.HQ_ANNOUNCEMENT;
  const { markdownContent } = params;

  const body: CreateAnnouncementRequestBody = {
    markdownContent,
  };

  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    withCredentials: true,
    body: body,
  });

  return response.data;
};

/**
 * Create Announcement in multi lang
 */
const createAnnouncementInMultiLang = async (
  params: CreateAnnouncementInMultiLangParams
): Promise<CreateAnnouncementResponse> => {
  const path = SETTING_API_PATHS.HQ_ANNOUNCEMENT_MULTI_LANG;
  const { announcementId, markdownContent, language } = params;

  const body: CreateAnnouncementInMultiLangRequestBody = {
    announcementId,
    markdownContent,
    language,
  };

  const response = await API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    withCredentials: true,
    body: body,
  });

  return response.data;
};

/**
 * Update Announcement
 */
const updateAnnouncement = async (
  params: UpdateAnnouncementParams
): Promise<UpdateAnnouncementResponse> => {
  const path = SETTING_API_PATHS.HQ_ANNOUNCEMENT;
  const { markdownContent, language } = params;

  const body: UpdateAnnouncementRequestBody = {
    markdownContent,
    language,
  };

  const response = await API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    withCredentials: true,
    body: body,
  });

  return response.data;
};

/**
 * Delete Announcement
 */
const deleteAnnouncement = async (): Promise<DeleteAnnouncementResponse> => {
  const path = SETTING_API_PATHS.HQ_ANNOUNCEMENT;

  const response = await API.del(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    response: true,
    withCredentials: true,
  });

  return response.data;
};

export {
  getAnnouncement,
  createAnnouncement,
  createAnnouncementInMultiLang,
  deleteAnnouncement,
  updateAnnouncement,
};
