import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { CALENDAR_API_PATHS } from '../constants';
import { GetMyCalendarItemsResponse } from '../types';

type GetMyCalendarItemsArgs = {
  rangeStartDate: string;
  rangeEndDate?: string;
  offset?: number;
  limit?: number;
};

const getMyCalendarItems = async (
  args: GetMyCalendarItemsArgs
): Promise<GetMyCalendarItemsResponse> => {
  const queryString = qs.stringify(args);
  const path = CALENDAR_API_PATHS.MY_CALENDAR_ITEMS;

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${queryString}`, {
    response: true,
    withCredentials: true,
  });

  return response.data;
};

export { getMyCalendarItems };
export type { GetMyCalendarItemsArgs };
