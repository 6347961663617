import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
};

const SideBarLayoutPageContentWrapper = ({
  className,
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <div
      className={twMerge(
        'flex w-full justify-center px-3 xs:px-4 sm:px-5 md:px-6 lg:px-7 xl:px-8 2xl:px-9',
        // update he following when the padding is updated
        '[--sidebar-base-layout-px:0.75rem] xs:[--sidebar-base-layout-px:1rem] sm:[--sidebar-base-layout-px:1.25rem] md:[--sidebar-base-layout-px:1.5rem]  lg:[--sidebar-base-layout-px:1.75rem] xl:[--sidebar-base-layout-px:2rem] 2xl:[--sidebar-base-layout-px:2.25rem]',
        className
      )}
    >
      {children}
    </div>
  );
};

export default SideBarLayoutPageContentWrapper;
