import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getUserBasedImagePreSignedUrl } from '../api/user';
import { getPreSignedUrlCourse } from '../../../api';

interface UploadToS3Request {
  uploadUrl: string;
  fileContents: File;
}

type uploadStates = {
  isProgress: boolean;
  progress: number;
  isError: boolean;
  error: string;
  isUploaded: boolean;
  initiating: boolean;
  data: any;
  abort: any;
};

type USER = 'user';

type COURSE = 'course';

type useUploadImageQueryProps = {
  type?: USER | COURSE;
  upload: any;
};

const useUploadImageQuery = ({ upload, type = 'user' }: useUploadImageQueryProps) => {
  const [controller, setController] = useState<any>();
  useEffect(() => {
    setController(new AbortController());
  }, [upload]);

  const [uploadStates, setUploadStates] = useState<uploadStates>({
    isProgress: false,
    isError: false,
    progress: 0,
    error: '',
    isUploaded: false,
    initiating: false,
    data: undefined,
    abort: controller,
  });

  const [preSignedData, setPreSignedData] = useState<any>();

  const progressCallBack = (progress: any) => {
    setUploadStates({
      isProgress: true,
      progress: progress,
      isError: false,
      error: '',
      isUploaded: false,
      initiating: false,
      data: undefined,
      abort: controller,
    });
  };

  const uploadToS3 = async ({ uploadUrl, fileContents }: UploadToS3Request) => {
    const options = {
      headers: { 'x-amz-tagging': 'isTemp=true', 'content-type': `${fileContents.type}` },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        progressCallBack &&
          progressCallBack(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      },
      signal: controller.signal,
    };
    const response = await axios.put(uploadUrl, fileContents, options);

    return response.data;
  };

  const getPreSignedUrl = async (upload: any) => {
    setUploadStates({
      isProgress: false,
      isError: false,
      error: '',
      progress: 0,
      isUploaded: false,
      initiating: true,
      data: undefined,
      abort: controller,
    });
    try {
      const data =
        type === 'user'
          ? await getUserBasedImagePreSignedUrl(upload)
          : await getPreSignedUrlCourse(upload);
      setPreSignedData(data);
      setUploadStates({
        isProgress: true,
        isError: false,
        progress: 0,
        error: '',
        isUploaded: false,
        initiating: false,
        data: undefined,
        abort: controller,
      });
    } catch (error) {
      setUploadStates({
        isProgress: false,
        isError: true,
        progress: 0,
        error: `${error}`,
        isUploaded: false,
        initiating: false,
        data: undefined,
        abort: controller,
      });
    }
  };

  useEffect(() => {
    if (upload) {
      getPreSignedUrl(upload);
    }
  }, [upload]);

  useEffect(() => {
    if (preSignedData) {
      const payload = {
        uploadUrl: preSignedData.url,
        fileContents: upload.file,
      };
      mutate(payload);
    }
  }, [preSignedData]);

  const { error, isError, mutate, isSuccess } = useMutation({
    mutationFn: (object: any) => uploadToS3(object),
  });

  useEffect(() => {
    if (isError) {
      setUploadStates({
        isProgress: false,
        isError: true,
        error: `${error}`,
        progress: 0,
        isUploaded: false,
        initiating: false,
        data: undefined,
        abort: controller,
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess && preSignedData) {
      setUploadStates({
        isProgress: false,
        isError: false,
        progress: 0,
        error: ``,
        isUploaded: true,
        initiating: false,
        data: preSignedData.key,
        abort: controller,
      });
    }
  }, [isSuccess]);

  return uploadStates;
};

export default useUploadImageQuery;
