import { Avatar } from 'react-daisyui';

interface Props {
  speakerName: string;
  speakerPic: string;
}

const SpeakerName = ({ speakerName, speakerPic }: Props) => {
  const profilePic =
    speakerPic ??
    `https://ui-avatars.com/api/?name=${speakerName}&background=random&rounded=true&bold=true&size=64`;
  return (
    <div className={'flex items-center gap-2 whitespace-nowrap'}>
      <Avatar
        src={profilePic}
        size={24}
        className="rounded-full border border-zinc-900"
        shape="circle"
        role="img"
        aria-label={speakerName}
      ></Avatar>
      <span>{speakerName}</span>
    </div>
  );
};

export default SpeakerName;
