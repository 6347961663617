import PageLevelErrorBoundaryFallback from './PageLevelErrorBoundaryFallback';

interface SideBarBaseLayoutErrorBoundaryProps {
  error: Error;
}

/**
 * Error Boundary Fallback for SideBarBaseLayout
 */
const SideBarBaseLayoutErrorBoundary = ({ error }: SideBarBaseLayoutErrorBoundaryProps) => {
  return <PageLevelErrorBoundaryFallback error={error} />;
};

export default SideBarBaseLayoutErrorBoundary;
