import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { ENT_ADMIN_DEFAULT_REQUESTS_SORT_BY } from '../../constants';
import { useEnterpriseAdminRequestsQuery } from '../../hooks';
import { EnterpriseAdminRequestsFilter } from '../../types';
import DashboardRequestsMain from '../common/RequestsDashboard/DashboardRequestsMain';
import DashboardRequestsTable from './components/DashboardRequestsTable';

interface Props {
  className: string;
  lazyLoad?: boolean;
}

const EnterpriseAdminDashboardRequests = ({ className, lazyLoad = true }: Props) => {
  const filters: Partial<EnterpriseAdminRequestsFilter> = {
    sort: ENT_ADMIN_DEFAULT_REQUESTS_SORT_BY,
    start: 0,
    size: 10,
  };

  const [isInView, setIsInView] = useState<boolean>(!lazyLoad);

  const request = useEnterpriseAdminRequestsQuery({ filters, isEnabled: isInView });

  return (
    <InView
      as="div"
      className={className}
      onChange={(inView) => {
        // default inView local state would be false,
        // set it to true when it enters into the viewport and it stays the same
        if (inView) {
          setIsInView(inView);
        }
      }}
      // this will disabled the intersection observer once we are in view
      // since we only need it until once the card comes into view
      skip={isInView}
    >
      <DashboardRequestsMain
        request={request}
        tableWrapperClassName="overflow-y-scroll"
        bottomGradientDivClassName="pointer-events-none"
        RequestsTable={request.data && <DashboardRequestsTable data={request.data.data} />}
      />
    </InView>
  );
};

export default EnterpriseAdminDashboardRequests;
