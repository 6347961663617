import { useStrapiSharedData } from '../features/shared/hooks/useStrapiSharedData';

const useUserTypeToDisplay = () => {
  const {
    userType: {
      adminType,
      learnerType,
      leaderType,
      individualLearnerType,
      managerType,
      hqAdminType,
    },
  } = useStrapiSharedData();
  const USER_ROLE_DISPLAY_NAME_MAP: { [key: string]: string } = {
    User: learnerType,
    Manager: managerType,
    Leader: leaderType,
    Admin: adminType,
    INDIVIDUAL: individualLearnerType,
    USHG: hqAdminType,
  };
  return { USER_ROLE_DISPLAY_NAME_MAP };
};
export default useUserTypeToDisplay;
