import ImageWithAcronymFallback from '../../../../../../../../../components/ImageWithAcronymFallback';
import { USHGAdminFeedback } from '../../../../../../../constants/course-feedback';

interface Props {
  feedback: USHGAdminFeedback;
}

const SubmittedNameCell = ({ feedback }: Props) => {
  return (
    <div className="flex items-center gap-2">
      <ImageWithAcronymFallback
        acronymWord={feedback.createdBy}
        imageKey={feedback.profileUrl}
        acronymLogoClassName="aspect-square h-6 w-6 font-semibold text-sm object-cover object-center"
        className="aspect-square h-6 w-6 rounded-xl"
      />
      <span>{feedback.createdBy}</span>
    </div>
  );
};

export default SubmittedNameCell;
