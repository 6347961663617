import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ErrorDisplay } from '../../../../../../components/DataTables';
import Dialog from '../../../../../../components/Dialog';
import { BasicSpinner } from '../../../../../../components/Spinners';
import { TENANT_MFA_CONFIG } from '../../../../constants';
import { useGetTenantMFAConfigQuery } from '../../../../hooks';
import ActionButton from '../../../common/components/ActionButton';
import DisableMFAMandate from './DisableMFAMandate';
import EnableMFAMandate from './EnableMFAMandate';
import { useStrapiSettingsData } from '../../../../hooks/useStrapiSettingsData';

type Props = {
  className?: string;
};

type ActiveDialogType = 'confirm-mfa-mandate-enable' | 'confirm-mfa-mandate-disable';

const TenantMFAConfiguration = (props: Props) => {
  const { className } = props;

  // state
  const [showDialog, setShowDialog] = useState(false);
  const [activeDialogType, setActiveDialogType] = useState<ActiveDialogType | null>(null);
  // state

  // queries
  const tenantMFAConfigQuery = useGetTenantMFAConfigQuery();
  // queries

  // derived state

  const isMFAMandatedForTenant =
    tenantMFAConfigQuery.data?.mfaConfig === TENANT_MFA_CONFIG.REQUIRED;

  const ActiveDialogContent = useMemo(() => {
    if (!showDialog) {
      return null;
    }

    switch (activeDialogType) {
      case 'confirm-mfa-mandate-enable': {
        return <EnableMFAMandate setShowDialog={setShowDialog} />;
      }
      case 'confirm-mfa-mandate-disable': {
        return <DisableMFAMandate setShowDialog={setShowDialog} />;
      }
    }
  }, [showDialog, activeDialogType]);

  // derived state

  //   strapi content

  const { enterpriseAdminMfaMandate: mfaMandateCMS } = useStrapiSettingsData();

  //   strapi content

  // handlers

  const showConfirmationDialog = useCallback((type: 'enable-mandate' | 'disable-mandate') => {
    switch (type) {
      case 'enable-mandate': {
        setActiveDialogType('confirm-mfa-mandate-enable');
        break;
      }
      case 'disable-mandate': {
        setActiveDialogType('confirm-mfa-mandate-disable');
        break;
      }
    }

    setShowDialog(true);
  }, []);

  // handlers

  return (
    <section className={twMerge(clsx('flex flex-col gap-4 rounded-sm bg-card-bg p-6', className))}>
      {/* Heading and Sub Heading */}
      <div className="flex flex-col">
        <div className="flex w-full items-center gap-2">
          <h3 className="text-xl font-semibold leading-8 text-zinc-50">{mfaMandateCMS.heading}</h3>
          {mfaMandateCMS.showIsNewFeature && (
            <div className="flex h-min items-center justify-center rounded-[3.5rem] bg-base-brand p-[0.0625rem_0.3125rem] text-[0.625rem] font-bold leading-normal text-white">
              {mfaMandateCMS.newFeatureLabel}
            </div>
          )}
        </div>
        <span className="text-sm font-normal text-zinc-500">{mfaMandateCMS.description}</span>
      </div>
      {/* content */}
      <div className="text-sm font-normal text-zinc-500">{mfaMandateCMS.content}</div>
      <>
        {/* Loading */}
        {tenantMFAConfigQuery.isLoading && <BasicSpinner className="!m-0 text-white" />}
        {/* Have Data */}
        {tenantMFAConfigQuery.data && (
          <div className="flex justify-start">
            <ActionButton
              type="primary"
              label={
                isMFAMandatedForTenant ? mfaMandateCMS.disableLabel : mfaMandateCMS.enableLabel
              }
              className={clsx(
                'px-4 py-2',
                isMFAMandatedForTenant ? 'bg-red-600 font-normal' : 'bg-base-brand'
              )}
              handler={() => {
                const confirmationType = isMFAMandatedForTenant
                  ? 'disable-mandate'
                  : 'enable-mandate';
                showConfirmationDialog(confirmationType);
              }}
            ></ActionButton>
          </div>
        )}
        {/* Error */}
        {tenantMFAConfigQuery.error && (
          <div>
            <ErrorDisplay
              message={mfaMandateCMS.getOrgMFAConfigErrorMessage}
              refetch={tenantMFAConfigQuery.refetch}
              allowsRefetch={true}
              isRefetching={tenantMFAConfigQuery.isRefetching}
              className="items-start gap-2 p-0 text-sm"
            />
          </div>
        )}
      </>
      {/* Dialog */}
      <Dialog
        open={showDialog}
        onOpenChange={(open) => setShowDialog(open)}
        Content={ActiveDialogContent}
        containerClassName="max-h-[80vh] overflow-y-auto px-2"
      ></Dialog>
    </section>
  );
};

export default TenantMFAConfiguration;
