import clsx from 'clsx';
import { useStrapiSettingsData } from '../../../../../settings/hooks/useStrapiSettingsData';
import { BILLING_STATUS } from '../../../../constants/billings';
import { getBillingStatus } from '../../../../utils/getBillingStatus';

interface StatusCellProps {
  isPaid: boolean;
  isOverDue: boolean;
}

interface GenericStatusCellProps {
  containerClassName: string;
  dotClassName: string;
  display: string;
}

const GenericStatusCell = (props: GenericStatusCellProps) => {
  const { containerClassName, dotClassName, display } = props;
  return (
    <div
      className={clsx(
        'flex w-min items-center gap-1 rounded-[100px] py-[2px] pr-2 pl-1.5',
        containerClassName
      )}
    >
      <span className={clsx('h-1.5 w-1.5 rounded-full', dotClassName)}></span>
      <span>{display}</span>
    </div>
  );
};

const StatusCell = (props: StatusCellProps) => {
  const { isOverDue, isPaid } = props;
  const status = getBillingStatus(isPaid, isOverDue);
  const {
    billingHistory: { paidStatusLabel, pendingStatusLabel, overDueStatusLabel },
  } = useStrapiSettingsData();
  switch (status) {
    case BILLING_STATUS.PENDING: {
      return (
        <GenericStatusCell
          containerClassName="bg-amber-900 text-emerald-100"
          dotClassName="bg-amber-100"
          display={pendingStatusLabel}
        />
      );
      break;
    }
    case BILLING_STATUS.PAID: {
      return (
        <GenericStatusCell
          containerClassName="bg-emerald-900  text-amber-100"
          dotClassName="bg-emerald-100"
          display={paidStatusLabel}
        />
      );
      break;
    }
    case BILLING_STATUS.OVER_DUE: {
      return (
        <GenericStatusCell
          containerClassName="bg-amber-900 text-emerald-100"
          dotClassName="bg-amber-100"
          display={overDueStatusLabel}
        />
      );
      break;
    }
    default: {
      return (
        <GenericStatusCell
          containerClassName="bg-amber-900 text-emerald-100"
          dotClassName="bg-amber-100"
          display="-"
        />
      );
    }
  }
};

export default StatusCell;
