import { useEffect, useMemo } from 'react';
import { PageSelector } from '../../../../components/DataTables';
import Select, { Option } from '../../../../components/Select';
import { ENT_ADMIN_MAX_PAGE_SIZE } from '../../../teams/constants/enterpriseAdmin';
import {
  LIST_INDIVIDUALS_MIN_PAGE_SIZE,
  LIST_INDIVIDUALS_PAGE_SIZE_INCREMENT,
} from '../../constants/common';

interface Props {
  activePage: number;
  totalItems: number;
  setPage: (page: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  rowLabel: string;
  pageSizeSelectorLabel: string;
}

const computePageSizeOptions = (totalItems: number, rowLabel: string) => {
  const PAGE_SIZES = [LIST_INDIVIDUALS_MIN_PAGE_SIZE];
  let remainingPages = totalItems - LIST_INDIVIDUALS_PAGE_SIZE_INCREMENT;

  while (remainingPages > 0) {
    const newPageSize = PAGE_SIZES[PAGE_SIZES.length - 1] + 10;
    PAGE_SIZES.push(newPageSize);
    if (newPageSize >= ENT_ADMIN_MAX_PAGE_SIZE) {
      break;
    }
    remainingPages = remainingPages - LIST_INDIVIDUALS_PAGE_SIZE_INCREMENT;
  }

  const PAGE_SIZE_OPTIONS: Option[] = PAGE_SIZES.map((pageSize) => {
    return {
      display: pageSize.toString() + ` ${rowLabel}`,
      value: pageSize.toString(),
    };
  });

  return PAGE_SIZE_OPTIONS;
};

const ListIndividualTableFooter = ({
  activePage,
  totalItems,
  setPage,
  pageSize,
  setPageSize,
  rowLabel,
  pageSizeSelectorLabel,
}: Props) => {
  const PAGES_SIZE_SELECT_OPTIONS = useMemo(() => {
    return computePageSizeOptions(totalItems, rowLabel);
  }, [totalItems]);

  function handlePageSizeChange(e: { target: { value: any } }) {
    const pageSize = e.target.value;
    setPage(1);
    setPageSize(Number(pageSize));
  }

  const totalPages = Math.ceil(totalItems / pageSize);

  useEffect(() => {
    if (activePage > totalPages) {
      setPage(totalPages);
    }
  }, [activePage, totalPages]);

  return (
    <>
      <div className="flex w-full flex-col items-center justify-between gap-2 lg:flex-row">
        <PageSelector
          className="justify-center lg:justify-start"
          activePage={activePage}
          totalPages={totalPages}
          setPage={setPage}
        />
        <div className="flex gap-2">
          <span className="self-center whitespace-nowrap text-xs font-normal leading-[20px] text-zinc-100">
            {pageSizeSelectorLabel}
          </span>
          <Select
            value={pageSize.toString()}
            aria-label="Requests page size options"
            options={PAGES_SIZE_SELECT_OPTIONS}
            onChange={handlePageSizeChange}
          />
        </div>
      </div>
    </>
  );
};

export default ListIndividualTableFooter;
