import clsx from 'clsx';
import { CaretLeft, CaretRight, DotsThree } from 'phosphor-react';
import React, { memo } from 'react';
import { getPaginationItems, PAGE_SEPERATOR_DOT_VALUE } from '../../utils';

// CaretIcon (used for navigating to previous and next page) Wrapper

type HTMLButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type CaretIconWrapperButtonProps = HTMLButtonProps & {
  className?: string;
};

const CaretIconWrapperButton = (props: React.PropsWithChildren<CaretIconWrapperButtonProps>) => {
  const { children, className, ...buttonProps } = props;

  return (
    <button
      className={clsx(
        'flex h-6 w-6 cursor-pointer items-center justify-center rounded-sm bg-zinc-900 p-1 text-zinc-200 disabled:cursor-not-allowed disabled:text-zinc-500',
        className
      )}
      {...buttonProps}
    >
      {children}
    </button>
  );
};

// CaretIcon (used for navigating to previous and next page) Wrapper

interface Props {
  activePage: number;
  totalPages: number;
  setPage: (page: number) => void;
  className?: string;
  visiblePagesCount?: number;
}

const PageSelector = ({
  activePage,
  totalPages,
  setPage,
  className,
  visiblePagesCount = 15,
}: Props) => {
  const pages = getPaginationItems(activePage, totalPages, visiblePagesCount);

  const gotoNextPage = () => {
    setPage(activePage + 1);
  };

  const gotoPreviousPage = () => {
    setPage(activePage - 1);
  };

  const goToPage = (page: number) => {
    setPage(page);
  };

  return (
    <div className={clsx('flex w-full flex-row items-center gap-4', className)}>
      <CaretIconWrapperButton
        onClick={gotoPreviousPage}
        disabled={activePage === 1}
        aria-disabled={activePage === 1}
        aria-label="Go to previous page"
      >
        <CaretLeft
          size={24}
          weight="bold"
          color="currentColor"
          className="stroke-[12px] p-1 text-inherit"
        />
      </CaretIconWrapperButton>
      <div className="flex flex-row items-center gap-4">
        {pages.map((page, idx) => {
          if (page == PAGE_SEPERATOR_DOT_VALUE) {
            return (
              <div key={`${idx}${totalPages}${visiblePagesCount}`} className="flex items-center">
                <DotsThree size={16} color="currentColor" className="text-zinc-100" />
              </div>
            );
          }
          return (
            <button
              key={page}
              onClick={() => goToPage(page as number)}
              className={clsx(
                'flex aspect-square h-5 w-5 items-center justify-center rounded-full text-xs font-normal',
                page === activePage ? 'bg-base-brand text-zinc-50' : 'bg-transparent text-zinc-100'
              )}
            >
              {page}
            </button>
          );
        })}
      </div>
      <CaretIconWrapperButton
        disabled={activePage === totalPages}
        aria-disabled={activePage === totalPages}
        onClick={gotoNextPage}
        aria-label="Go to next page"
      >
        <CaretRight
          size={24}
          weight="bold"
          color="currentColor"
          className="stroke-[24px] p-1 text-inherit"
        />
      </CaretIconWrapperButton>
    </div>
  );
};

export default memo(PageSelector);
