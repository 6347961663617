export const USHG_ADMIN_FEEDBACK_SORT_BY_VALUES = ['MOST_RECENT', 'EARLIEST'] as const;

export const USHG_ADMIN_FEEDBACK_RATING_BY_VALUES = ['1', '2', '3', '4', '5'] as const;

export const USHG_ADMIN_DEFAULT_FEEDBACK_SORT_BY = 'MOST_RECENT';

// Filters

export interface USHGAdminFeedbackFilter {
  start: number;
  ratings: (typeof USHG_ADMIN_FEEDBACK_RATING_BY_VALUES)[number];
  // Free Text Search
  search: string;
  sortOrder: string | boolean;
  sortKey: string;
  size: number;
}

export type PartialUSHGAdminFeedbackFilter = Partial<USHGAdminFeedbackFilter>;

// DEBOUNCE on FEEDBACK made for search input in milliseconds
export const FEEDBACK_FILTER_SEARCH_DEBOUNCE_MS = 500;
// DEBOUNCE on FEEDBACK made for search input

// Pagination
export const FEEDBACK_PAGE_SIZE_INCREMENT = 10;
export const FEEDBACK_MAX_PAGE_SIZE = 50;
export const FEEDBACK_MIN_PAGE_SIZE = 10;

export const FEEDBACK_DEFAULT_PAGE = 1;
// Pagination

// Query Params
export const FEEDBACK_RATING_QUERY_PARAM = 'rating';
export const FEEDBACK_SORT_QUERY_PARAM = 'sort';
export const FEEDBACK_FILTER_QUERY_PARAM = 'feedbackFilter';
// Query Params

// consider the FEEDBACK stale after 30 seconds
export const FEEDBACK_STALE_TIME = 30000;

// one min as they would not probably change
export const USHG_ADMIN_FEEDBACK_TYPES_FETCH_STALE_TIME = 60000;

// FEEDBACK

export interface USHGAdminFeedback {
  comments: string;
  rating: string;
  createdBy: string;
  userId: string;
  organizationName: string;
  createdOn: string;
  profileUrl: string;
}
