import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckCircle, DeviceMobileCamera, EnvelopeSimple } from 'phosphor-react';
import {
  FIRSTNAME_REGEX_PATTERN,
  LASTNAME_REGEX_PATTERN,
  PHONE_NUMBER_REGEX_PATTERN,
} from '../../../../../constants/regex-patterns';
import { DropdownInput, Label, TextInput } from '../../../../../components/FormElements';
import ErrorMessage from '../../../../../components/FormElements/ErrorMessage';
import PhoneNumberInput from '../../../../../components/FormElements/PhoneInput';
import { Button } from '../../../../../components/Buttons';
import toast from 'react-hot-toast';
import { Toast } from '../../../../../components/Toast';
import { BasicSpinner } from '../../../../../components/Spinners';
import { BadgeComponent } from '../../../../../components/Badge';
import {
  removeCountryCodeFromPhoneNumber,
  handleFileChange,
  appendCountryCodeToPhoneNumber,
} from '../../../../../utils';
import FormFieldWrapper from '../FormFieldWrapper';
import { fieldHasError } from '../../../../../utils/react-hook-form';
import { UserProfile } from '../../../../shared';
import { generateCDNMediaAssetURLWithKey, getAccessTokenHelper } from '../../../../shared/helpers';
import { useSetUserPreferredMFAMutation, useUpdateProfileMutation } from '../../../../shared/hooks';
import { GetPresignedUrlFilters, MODULE_TYPES } from '../../../../../types';
import { getPresignedUrl, uploadToS3 } from '../../../../../api';
import { useStrapiSettingsData } from '../../../hooks/useStrapiSettingsData';
import clsx from 'clsx';
import useUserTypeToDisplay from '../../../../../hooks/useUserTypeToDisplay';
import VerifyPhoneNumberDialogContent from './VerifyPhoneNumberDialogContent';
import Dialog from '../../../../../components/Dialog';
import { useStrapiSharedData } from '../../../../shared/hooks/useStrapiSharedData';
import { useHasAccess, useReduxAuthState } from '../../../../../hooks';
import useVerifyCurrentUserAttributeMutation from '../../../hooks/useVerifyCurrentUserAttributeMutation';
import {
  CognitoException,
  CognitoUserAttributes,
  MFAMethod,
  USHG_HQ_APP_ROLE,
} from '../../../../../constants';
import ConfirmPhoneChange from './ConfirmPhoneChange';
import { DEFAULT_PREFERRED_LANGUAGE } from '../../../../../constants/user';

// User Profile Form Schema section starts
interface FormValidations {
  firstNameRequired: string;
  firstNameMinValue: number;
  firstNameMinError: string;
  firstNameMaxValue: number;
  firstNameMaxError: string;
  firstNameTypeError: string;
  lastNameRequired: string;
  lastNameMinValue: number;
  lastNameMinError: string;
  lastNameMaxValue: number;
  lastNameMaxError: string;
  lastNameTypeError: string;
  emailRequired: string;
  validEmail: string;
  phoneNumberRequired: string;
  validPhoneNumber: string;
  jobTitleRequired: string;
  departmentRequired: string;
}

type GetSchemaOptions = {
  enablePhoneNumberValidation?: boolean;
};

// Form Schema
const getSchema = (data: FormValidations, opts?: GetSchemaOptions) => {
  const { enablePhoneNumberValidation = true } = opts ?? {};

  const {
    firstNameRequired,
    firstNameMinValue,
    firstNameMinError,
    firstNameMaxValue,
    firstNameMaxError,
    firstNameTypeError,
    lastNameRequired,
    lastNameMinValue,
    lastNameMinError,
    lastNameMaxValue,
    lastNameMaxError,
    lastNameTypeError,
    emailRequired,
    validEmail,
    // phoneNumberRequired,
    validPhoneNumber,
    jobTitleRequired,
    departmentRequired,
  } = data;
  const userProfileSchema = yup.object({
    firstName: yup
      .string()
      .trim()
      .required(firstNameRequired)
      .min(firstNameMinValue, firstNameMinError)
      .max(firstNameMaxValue, firstNameMaxError)
      .matches(new RegExp(FIRSTNAME_REGEX_PATTERN), firstNameTypeError)
      .label('First name'),
    lastName: yup
      .string()
      .trim()
      .required(lastNameRequired)
      .min(lastNameMinValue, lastNameMinError)
      .max(lastNameMaxValue, lastNameMaxError)
      .matches(new RegExp(LASTNAME_REGEX_PATTERN), lastNameTypeError)
      .label('Last name'),
    emailAddress: yup.string().email(validEmail).required(emailRequired).label('Email address'),
    phone: yup.string().test('regex-validation', validPhoneNumber, function (value) {
      if (enablePhoneNumberValidation) {
        // This function validates the PhoneNumber everytime it has a value
        return !value || new RegExp(PHONE_NUMBER_REGEX_PATTERN).test(value);
      }

      return !!value;
    }),
    jobTitle: yup.string().trim().required(jobTitleRequired),
    department: yup.string().trim().required(departmentRequired),
    profileUrlKey: yup.string().default(''),
    language: yup.string(),
  });
  return userProfileSchema;
};
// Todo: Pull these messages from Strapi

// Form Schema

// types

interface Props {
  profile: UserProfile;
}

type ActiveDialogType = 'verify-phone-number' | 'confirm-phone-change';

type USER_PROFILE_FORM_DATA = yup.InferType<ReturnType<typeof getSchema>>;

// types

// utils

type ParseFormPhoneNumberProps = {
  isFormattedAsUSNumber?: boolean;
};

const parseFormPhoneNumber = (
  phoneNumber: string | undefined,
  opts: ParseFormPhoneNumberProps | undefined
) => {
  const { isFormattedAsUSNumber = true } = opts ?? {};

  if (!phoneNumber) {
    return phoneNumber;
  }

  const formPhoneNumber =
    isFormattedAsUSNumber && phoneNumber
      ? appendCountryCodeToPhoneNumber(phoneNumber)
      : phoneNumber;

  return formPhoneNumber;
};

// utils

const UserProfileForm = ({ profile }: Props) => {
  // strapi
  const {
    userDefaultProfilePicture,
    language: { StrapiLanguageOptions },
    featureFlags: { showLanguageOptions },
  } = useStrapiSharedData();
  const userDefaultProfilePictureUrl = userDefaultProfilePicture?.data?.attributes?.url;
  // strapi

  // State

  // Dialog
  const [showDialog, setShowDialog] = useState(false);
  const [activeDialogType, setActiveDialogType] = useState<ActiveDialogType | null>(null);

  const [profilePictureUrl, setProfilePictureUrl] = useState<string>(() => {
    // If profile.pictureUrl is not empty string then use the cdn url generated with it
    // else fallback to CDN URL
    let cdnURL = userDefaultProfilePictureUrl;
    if (profile && profile.pictureUrl && profile.pictureUrl !== '') {
      cdnURL = generateCDNMediaAssetURLWithKey({ key: profile.pictureUrl });
    }
    return cdnURL;
  });

  // Whether the image upload is in progress
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Redux
  const auth = useReduxAuthState();
  const isPhoneNumberVerified = auth.user?.['phone_number_verified']
    ? auth.user.phone_number_verified
    : false;

  const languageRef = useRef<string>();

  // State

  // Mutations

  const verifyCurrentUserAttributeMutation = useVerifyCurrentUserAttributeMutation();
  const setUserPreferredMFAMutation = useSetUserPreferredMFAMutation();

  const updateProfileMutation = useUpdateProfileMutation();

  // Mutations

  // strapi content

  const {
    profile: strapiProfile,
    toastMessage: { profilePictureUploadFailed },
  } = useStrapiSettingsData();
  const { featureFlags, Exception: exceptionCMS } = useStrapiSharedData();
  const { enableUSPhoneNumberFormatting = true } = featureFlags ?? {};

  const {
    description,
    title,
    form: strapiForm,
    avatarTitle,
    avatarUploadInstruction,
    uploadAvatarButton,
    removeAvatarButton,
    saveButton,
    invalidPhoneForVerificationToastMessage,
    initPhoneVerifySomethingWentWrongMessage,
  } = strapiProfile;
  const {
    firstNameLabel,
    lastNameLabel,
    emailLabel,
    jobTitleLabel,
    emailPlaceholder,
    departmentLabel,
    phoneNumberLabel,
    verifyPhoneLabel,
    phoneNotAvailableToastMessage,
    updatePhoneBeforeVerifyToastMessage,
    phoneVerifiedLabel,
    languageLabel,
  } = strapiForm;

  // strapi content

  // Form

  const userProfileSchema = getSchema(strapiForm, {
    enablePhoneNumberValidation: enableUSPhoneNumberFormatting,
  });

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm<USER_PROFILE_FORM_DATA>({
    mode: 'onChange',
    resolver: yupResolver(userProfileSchema),
    defaultValues: {
      firstName: profile?.firstName || '',
      lastName: profile?.lastName || '',
      emailAddress: profile?.emailAddress || '',
      phone: enableUSPhoneNumberFormatting
        ? removeCountryCodeFromPhoneNumber(profile?.phone) || ''
        : profile?.phone,
      jobTitle: profile?.jobTitle || '',
      department: profile?.department || '',
      profileUrlKey: profile?.pictureUrl || '',
      language: profile?.preferredLang || DEFAULT_PREFERRED_LANGUAGE,
    },
  });

  // Watch the language field
  const selectedLanguage = watch('language');

  // Store the initial value on first render
  useEffect(() => {
    languageRef.current = selectedLanguage;
  }, []);

  // Form

  // Handlers

  const handleFormSubmit = useCallback(
    async (data: USER_PROFILE_FORM_DATA) => {
      const { firstName, lastName, phone, language } = data;
      const params = {
        accessToken: await getAccessTokenHelper(),
        firstName,
        lastName,
        phoneNumber: enableUSPhoneNumberFormatting
          ? appendCountryCodeToPhoneNumber(phone as string)
          : phone,
        preferredLanguage: language || DEFAULT_PREFERRED_LANGUAGE,
        profileUrl: data.profileUrlKey,
        department: data.department,
        jobTitle: data.jobTitle,
      };
      // update profile data
      updateProfileMutation.mutate(params);
    },
    [enableUSPhoneNumberFormatting, updateProfileMutation]
  );

  // Perform any checks needed and do respective actions before submitting the form
  const performChecksAndHandleFormSubmit = async (data: USER_PROFILE_FORM_DATA) => {
    // TODO: Check if the number in the form is same as the number we are getting from the backend
    // If not ask the user to enter a valid phone number
    const currentPhoneNumber = auth.user?.phone_number;

    const formPhoneNumber = parseFormPhoneNumber(getValues('phone'), {
      isFormattedAsUSNumber: enableUSPhoneNumberFormatting,
    });

    // When SMS MFA is enabled
    // We should show a confirmation message if the number is being changed
    if (currentPhoneNumber !== formPhoneNumber && auth.user?.preferredMFA === MFAMethod.SMS_MFA) {
      setActiveDialogType('confirm-phone-change');
      setShowDialog(true);
      return;
    }

    const response = await handleFormSubmit(data);
    return response;
  };

  // Avatar upload/remove controls
  const onAvatarUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // store the current profile picture url incase the upload fails then we can revert back
    const currentProfilePictureUrl = profilePictureUrl;

    try {
      const response = await handleFileChange(event);
      const { fileType, fileContents } = response ?? {};

      if (fileType && fileContents) {
        setIsLoading(true);
        setProfilePictureUrl(URL.createObjectURL(fileContents));
        // construct payload for getting s3 presigned url
        const presignedUrlFilters: GetPresignedUrlFilters = {
          module: MODULE_TYPES.USER,
        };
        // Get Presigned URL
        const presignedUrlResponse = await getPresignedUrl({ presignedUrlFilters });

        // Upload to S3
        await uploadToS3({
          uploadUrl: presignedUrlResponse.url,
          fileContents,
        });

        // Update the form profile picture key only on success
        setValue('profileUrlKey', presignedUrlResponse.key);
      }
    } catch (error: any) {
      // Revert back the profile picture URL on error
      setProfilePictureUrl(currentProfilePictureUrl);
      toast.custom((t) => (
        <Toast
          variant={'error'}
          Title={profilePictureUploadFailed}
          SubTitle={error.message}
          toastInstance={t}
        />
      ));
    } finally {
      setIsLoading(false);
    }
  };

  const onAvatarRemove = () => {
    // Reset form profile url key and url to default values
    setProfilePictureUrl(userDefaultProfilePictureUrl);
    setValue('profileUrlKey', '');
  };

  // handleClick basically empty the target.value i.e. event.target.value = ""
  const handleClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = '';
  };

  // Attribute Verification

  // To send out a code to verify the phone number attribute
  const initiateVerifyUserPhoneNumber = async () => {
    // TODO: Check if the number in the form is same as the number we are getting from the backend
    // If not ask the user to enter a valid phone number
    const currentPhoneNumber = auth.user?.phone_number;

    const formPhoneNumber = parseFormPhoneNumber(getValues('phone'), {
      isFormattedAsUSNumber: enableUSPhoneNumberFormatting,
    });

    // If there is already no phone number
    // and there is no input added the form
    if (!currentPhoneNumber && !formPhoneNumber) {
      // TODO: Integrate with strapi
      toast.custom(
        (t) => <Toast Title={phoneNotAvailableToastMessage} toastInstance={t} variant="warning" />,
        {
          duration: 5000,
          id: 'UserProfileForm_no-phone-number-to-verify',
        }
      );
      return;
    }

    // Current user phone number and the value in form doesn't match
    // or we don't already have a phone number and the user has entered a value in form
    if (formPhoneNumber !== currentPhoneNumber) {
      // TODO: Integrate with strapi
      toast.custom(
        (t) => (
          <Toast Title={updatePhoneBeforeVerifyToastMessage} toastInstance={t} variant="warning" />
        ),
        {
          duration: 3000,
          id: 'UserProfileForm_update-before-verify',
        }
      );
      return;
    }

    // If the phone number value in the form and the current phone number in cognito match
    // then we can proceed with verification i.e. send out the code and open the form

    try {
      await verifyCurrentUserAttributeMutation.mutateAsync({
        attributeName: CognitoUserAttributes.PHONE_NUMBER,
      });

      setActiveDialogType('verify-phone-number');
      setShowDialog(true);
    } catch (error) {
      let errorMessage = initPhoneVerifySomethingWentWrongMessage;

      if (error && typeof error === 'object') {
        const { code } = error as { code: string; message: string };
        if (code) {
          switch (code) {
            case CognitoException.InvalidParameterException: {
              errorMessage = invalidPhoneForVerificationToastMessage;
              break;
            }
            // CognitoException.LimitExceededException: {
            default: {
              const exceptionErrorMessage = exceptionCMS[code];
              errorMessage = exceptionErrorMessage ?? errorMessage;
              break;
            }
          }
        }

        toast.custom((t) => <Toast toastInstance={t} Title={errorMessage} variant="error" />, {
          duration: 3000,
        });
      }
      console.log(error, (error as any).message);
    }
  };

  // Attribute Verification

  // Handlers

  // Dialog Content to show

  const DialogContent = useMemo(() => {
    if (!showDialog) {
      return null;
    }

    // We would have the phone number at this point
    const phoneNumber =
      auth.user?.phone_number && enableUSPhoneNumberFormatting
        ? removeCountryCodeFromPhoneNumber(auth.user?.phone_number)
        : auth.user?.phone_number;

    const closeDialog = () => {
      setShowDialog(false);
      setActiveDialogType(null);
    };

    switch (activeDialogType) {
      case 'verify-phone-number': {
        // TODO: Make sure we check the phoneNumber before we come here
        // in initiateVerifyUserPhoneNumber
        return (
          <VerifyPhoneNumberDialogContent
            phoneNumber={phoneNumber as string}
            // close dialog on successful verification
            onVerificationSuccess={closeDialog}
          />
        );
      }
      case 'confirm-phone-change': {
        const data = getValues();
        return (
          <ConfirmPhoneChange
            onConfirm={async () => {
              setShowDialog(false);
              try {
                await setUserPreferredMFAMutation.mutateAsync({
                  mfaMethod: MFAMethod.NOMFA,
                });
                await handleFormSubmit(data);
              } catch (error) {
                console.error('Error confirming phone change', error);
              }
            }}
            onCancel={() => {
              setShowDialog(false);
            }}
          />
        );
      }
      default:
        return null;
    }
  }, [
    activeDialogType,
    auth.user?.phone_number,
    enableUSPhoneNumberFormatting,
    getValues,
    handleFormSubmit,
    setUserPreferredMFAMutation,
    showDialog,
  ]);

  // Dialog Content to show

  // Display loader
  const displayLoader =
    updateProfileMutation.isLoading || setUserPreferredMFAMutation.isLoading || isLoading;
  const { USER_ROLE_DISPLAY_NAME_MAP } = useUserTypeToDisplay();

  // TODO: Tech Debt -- To remove once we add the ability to add country code
  const currentPhoneNumber = auth.user?.phone_number;

  // If we enabled form formatting remove it and also add country code
  const rawFormPhoneNumber = watch('phone');
  const formPhoneNumber = parseFormPhoneNumber(rawFormPhoneNumber, {
    isFormattedAsUSNumber: enableUSPhoneNumberFormatting,
  });

  // Compare the phone number in form vs the phone number in cognito user profile
  const isPhoneNumberBeingModified = formPhoneNumber !== currentPhoneNumber;
  // whether the phone number verification is in progress
  const isInitiatingPhoneNumberVerification = verifyCurrentUserAttributeMutation.isLoading;

  // Whether to show the verification component
  // For USHG HQ Admins since we are doing SSO
  // We have not allowed MFA
  const showVerifyPhoneNumber = useHasAccess([
    USHG_HQ_APP_ROLE.ENTERPRISE_ADMIN,
    USHG_HQ_APP_ROLE.ENTERPRISE_LEADER,
    USHG_HQ_APP_ROLE.ENTERPRISE_MANAGER,
    USHG_HQ_APP_ROLE.ENTERPRISE_LEARNER,
    USHG_HQ_APP_ROLE.INDIVIDUAL_USER,
  ]);

  return (
    <div className="m-auto flex w-full flex-col gap-8 md:max-w-2xl">
      {/* Avatar Section */}
      <div className="order-1 flex flex-col gap-4 self-stretch rounded-sm bg-card-bg p-6">
        <div className="flex flex-col gap-2 leading-5">
          <h1 className="text-xl font-semibold text-zinc-50">{avatarTitle}</h1>
          <p className="text-sm text-zinc-400">{avatarUploadInstruction}</p>
        </div>
        <div className="flex items-center gap-2 rounded-md bg-zinc-900 px-4 py-2">
          <img
            aria-label="Avatar"
            alt="Avatar"
            className={clsx(
              'h-14 w-14 rounded-full border border-white object-cover',
              isLoading && 'animate-pulse'
            )}
            src={profilePictureUrl}
          ></img>
          <div className="flex items-center gap-2">
            <label htmlFor="select-image" className="flex items-center">
              <span className="cursor-pointer rounded-[4px] bg-base-brand py-1 px-2 text-xs text-white">
                {uploadAvatarButton}
              </span>
              <input
                type="file"
                id="select-image"
                className="hidden"
                accept=".jpg, .jpeg, .png"
                onChange={onAvatarUpload}
                onClick={handleClick}
              />
            </label>
            <Button
              onClick={() => onAvatarRemove()}
              className="rounded border border-zinc-700 bg-zinc-800 p-2 text-xs text-zinc-200 sm:py-1"
              disabled={profilePictureUrl === userDefaultProfilePictureUrl}
            >
              {removeAvatarButton}
            </Button>
          </div>
        </div>
      </div>

      {/* Profile Section */}
      <div className="order-2 rounded-sm bg-card-bg p-6">
        {profile?.userType && (
          <BadgeComponent
            className="float-right border-0 bg-base-brand py-2.5 text-[10px] font-extrabold text-white transition group-hover:hidden"
            label={USER_ROLE_DISPLAY_NAME_MAP[profile?.userType] || ''}
            size="md"
          />
        )}
        <div className="flex flex-col gap-y-1">
          <h1 className="text-base font-semibold text-zinc-50 md:text-xl">{title}</h1>
          <p className="text-xs text-zinc-500 md:text-sm">{description}</p>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(performChecksAndHandleFormSubmit)(e);
          }}
        >
          <div className="mt-4 grid grid-cols-1 gap-4 text-white md:grid-cols-2">
            {/* First Name Input */}
            <FormFieldWrapper>
              <Label htmlFor="first_name_input" label={firstNameLabel} />
              <TextInput
                className="grow-0 rounded-md text-xs placeholder:text-xs disabled:text-zinc-500 md:text-sm md:placeholder:text-xs"
                id="first_name_input"
                type="text"
                hasError={fieldHasError(errors, 'firstName')}
                {...register('firstName')}
              />
              <HookFormErrorMessage
                name="firstName"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </FormFieldWrapper>

            {/* Last Name Input */}
            <FormFieldWrapper>
              <Label htmlFor="last_name_input" label={lastNameLabel} />
              <TextInput
                className="grow-0 rounded-md text-xs placeholder:text-xs disabled:text-zinc-500 md:text-sm md:placeholder:text-xs"
                id="last_name_input"
                type="text"
                hasError={fieldHasError(errors, 'lastName')}
                {...register('lastName')}
              />
              <HookFormErrorMessage
                name="lastName"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </FormFieldWrapper>

            {/* Email Address Input */}
            <FormFieldWrapper>
              <Label htmlFor="email_input" label={emailLabel} />
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <EnvelopeSimple size={20} color="#A1A1AA" weight="bold" />
                </div>
                <TextInput
                  className="grow-0 rounded-md pl-10 text-xs placeholder:text-xs disabled:text-zinc-500 md:text-sm md:placeholder:text-xs"
                  id="email_input"
                  placeholder={emailPlaceholder}
                  type="text"
                  disabled={!!profile?.emailAddress}
                  hasError={fieldHasError(errors, 'emailAddress')}
                  {...register('emailAddress')}
                />
              </div>
              <HookFormErrorMessage
                name="emailAddress"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </FormFieldWrapper>

            {/* Phone Number Input */}
            <FormFieldWrapper>
              <div className="flex items-center justify-between">
                <Label htmlFor="phone_number_input" label={phoneNumberLabel} />
                {/* whether to show the verification button */}
                {showVerifyPhoneNumber && (
                  <>
                    {/* The phone number is verified and the phone number is not being modified */}
                    {isPhoneNumberVerified && !isPhoneNumberBeingModified ? (
                      <>
                        {/* TODO: Integrate with strapi */}
                        <div className="flex items-center gap-[2] rounded-[56px] bg-base-brand p-[1px_3px_1px_1px]">
                          <CheckCircle
                            color="currentColor"
                            className="text-white"
                            weight="fill"
                            size={13}
                          />
                          <span className="text-[10px] font-bold leading-3">
                            {phoneVerifiedLabel}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          className="flex items-center gap-1 text-xs font-medium text-base-brand"
                          type="button"
                          onClick={initiateVerifyUserPhoneNumber}
                          disabled={isInitiatingPhoneNumberVerification}
                        >
                          {isInitiatingPhoneNumberVerification ? (
                            <BasicSpinner className="!m-0 h-3 w-3" />
                          ) : (
                            <></>
                          )}
                          <span>{verifyPhoneLabel}</span>
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <DeviceMobileCamera size={20} color="#A1A1AA" weight="bold" />
                </div>
                <PhoneNumberInput
                  className="grow-0 rounded-md pl-10 text-xs placeholder:text-xs md:text-sm md:placeholder:text-xs"
                  id="phone_number_input"
                  placeholder="(555) 764-1982"
                  type="tel"
                  hasError={fieldHasError(errors, 'phone')}
                  {...register('phone')}
                  enableFormatting={enableUSPhoneNumberFormatting}
                />
              </div>
              <HookFormErrorMessage
                name="phone"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </FormFieldWrapper>

            {/* Job Title Input */}
            <FormFieldWrapper>
              <Label htmlFor="jobtitle_input" label={jobTitleLabel} />
              <TextInput
                className="grow-0 rounded-md text-xs placeholder:text-xs disabled:text-zinc-500 md:text-sm md:placeholder:text-xs"
                id="jobtitle_input"
                type="text"
                disabled={!!profile?.jobTitle}
                hasError={fieldHasError(errors, 'jobTitle')}
                {...register('jobTitle')}
              />
              <HookFormErrorMessage
                name="jobTitle"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </FormFieldWrapper>

            {/* Department Input */}
            <FormFieldWrapper>
              <Label htmlFor="department_input" label={departmentLabel} />
              <TextInput
                className="grow-0 rounded-md text-xs placeholder:text-xs disabled:text-zinc-500 md:text-sm md:placeholder:text-xs"
                id="department_input"
                type="text"
                disabled={!!profile?.department}
                hasError={fieldHasError(errors, 'department')}
                {...register('department')}
              />
              <HookFormErrorMessage
                name="department"
                errors={errors}
                render={({ message }) => <ErrorMessage message={message} />}
              />
            </FormFieldWrapper>

            {/* Preferred Language */}
            {auth?.user?.role !== USHG_HQ_APP_ROLE.USHG_ADMIN && showLanguageOptions && (
              <FormFieldWrapper>
                <Label htmlFor="language_input" label={languageLabel} className="text-left" />
                <div className="relative w-full">
                  <DropdownInput
                    className="grow-0 rounded-md text-xs placeholder:text-xs disabled:text-zinc-500 md:text-sm md:placeholder:text-xs"
                    options={StrapiLanguageOptions}
                    type="select"
                    id="language_input"
                    {...register('language')}
                  />
                </div>
              </FormFieldWrapper>
            )}
          </div>
          {/* Form Action */}
          <Button
            type="submit"
            disabled={
              // When the mutation is in progress
              displayLoader
            }
            className="order-1 my-4 flex cursor-pointer items-center justify-center rounded-md bg-base-brand py-2 text-center text-sm leading-[20px] text-white disabled:cursor-not-allowed disabled:opacity-80"
          >
            {updateProfileMutation.isLoading || setUserPreferredMFAMutation.isLoading ? (
              <div className="flex gap-2">
                <span>{saveButton}</span>
                <BasicSpinner className="!m-0 leading-[14px] text-zinc-100" />
              </div>
            ) : (
              saveButton
            )}
          </Button>
        </form>
      </div>

      {/* Dialog */}
      <Dialog
        containerClassName="max-w-xl px-2"
        Content={DialogContent}
        open={showDialog}
        onOpenChange={(open) => setShowDialog(open)}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      ></Dialog>
    </div>
  );
};

export default UserProfileForm;
